var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sidebarData.isLoading),expression:"!sidebarData.isLoading"}],staticClass:"NavProfileEvents"},[(!_vm.sidebarData.isLoading)?_c('div',{class:{ active: !_vm.participantId }},[_c('div',{staticClass:"nav-box"},[_c('ul',[_c('router-link',{attrs:{"to":{
            name: 'ProfileApplication',
            params: {
              profileId: _vm.sidebarData.profileId + ''
            },
            query: {
              id: _vm.sidebarData.participantId + '',
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"nav__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#edit2","alt":"Application"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Application")])])])]}}],null,false,3018890612)}),_c('router-link',{attrs:{"to":{
            name: 'ProfileTodos',
            params: {
              profileId: _vm.sidebarData.profileId + ''
            },
            query: {
              id: _vm.sidebarData.participantId + '',
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"nav__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#folder","alt":"To Dos"}})]),_c('div',{staticClass:"item__title"},[_vm._v("To Dos")])])])]}}],null,false,489609420)}),_c('router-link',{attrs:{"to":{
            name: 'ProfileRoommates',
            params: {
              profileId: _vm.sidebarData.profileId + ''
            },
            query: {
              id: _vm.sidebarData.participantId + '',
              eventId: _vm.eventId,
              pTypeId: _vm.data.pTypeId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"nav__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#users","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Roommates")])])])]}}],null,false,2635483746)}),(_vm.$allowedFunctions.includes(_vm.$userFunctions.Transactions))?_c('router-link',{attrs:{"to":{
            name: 'ProfileFinancial',
            params: {
              profileId: _vm.sidebarData.profileId + ''
            },
            query: {
              id: _vm.sidebarData.participantId + '',
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"nav__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#credit-card","alt":"Financial"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Financial")])])])]}}],null,false,4039468046)}):_vm._e(),_c('router-link',{attrs:{"to":{
            name: 'ProfileMedicals',
            params: {
              profileId: _vm.sidebarData.profileId + ''
            },
            query: {
              participantId: _vm.sidebarData.participantId + '',
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"nav__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#life_buoy","alt":"Medical/Incidents"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Medical/Incidents")])])])]}}],null,false,2292037410)}),(_vm.showTransfer)?_c('li',[_c('a',{staticClass:"nav__item",attrs:{"href":"#","title":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.transfer($event)}}},[_vm._m(0),_c('div',{staticClass:"item__title"},[_vm._v("Transfer")])])]):_vm._e()],1)])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#credit","alt":"Transfer"}})])}]

export { render, staticRenderFns }