import { ref } from "@vue/composition-api";
import { ApiHelper, EventHelper, ProfileHelper } from "@/helpers";
import { AlertMessageError, FormText, PagerItem, SelectOption } from "@/types";
import FormTextarea from "@/types/FormTextarea";
import FormSelect from "@/types/FormSelect";
import { ProfileListRows } from "@/helpers/estimateNoOfListRows";
import Vue from "vue";
import FormTags from "@/types/FormTags";

type profilePanel = {
  isShow: boolean;
  data: {
    id: number;
    logo: string;
    firstName: string;
    lastName: string;
    photo: string;
    totalActions: number;
    balance: number;
    subProfiles: {
      id: number;
      typeId: number;
      firstName: string;
      lastName: string;
      age: number;
      logo: string;
    }[];
  };
};

type Profile = {
  id: number;
  status: number;
  isChecked: boolean;
  logo: string;
  firstName: string;
  lastName: string;
  age: string;
  gender: string;
  balance: string;
  events: string;
  familyName: string;
};

type PageData = {
  isLoading: boolean;
  isAddFilterGender: boolean;
  isAddFilterEvent: boolean;
  isAddFilterFamily: boolean;
  skip: number;
  take: number;
  items: Profile[];
  activeTab: string;
  arrTags: SelectOption[];
  arrProfiles: SelectOption[];
  arrBalances: SelectOption[];
  openItemId: number;
  sort: {
    order: string;
    direction: string;
  };
  filter: {
    profile: string;
    unassignedFamily: any;
    family: SelectOption[];
    age: string;
    gender: SelectOption[];
    tag: SelectOption[];
    events: SelectOption[];
    unassignedEvent: any;
    balance: string;
    minAge: string;
    maxAge: string;
    minBalance: string;
    maxBalance: string;
    email: string;
    phone: string;
  };
  showHeadActions: boolean;
  pager: {
    showPagerItems: boolean;
    page: number;
    totalPages: number;
    total: number;
    items: PagerItem[];
  };
  canDeleteProfile: boolean;
  // families
  searchFamilies: Function;
  searchFamiliesValue: {
    value: string;
  };
  selectedFamilies: SelectOption[];
  selectedFamiliesLoading: boolean;
  // events
  searchEventsValue: {
    value: string;
  };
  searchEvents: Function;
  searchEventsLoading: boolean;
};

type PopupTags = {
  show: boolean;
  controls: {
    tags: FormTags;
  };
  data: {
    input: string;
    items: {
      id: string;
      text: string;
      checked: boolean;
    }[];
  };
};

export function useProfilesStore(context: any) {
  const selectedItems = ref<any[]>([]);
  const tagFilter = ref<{
    options: SelectOption[];
    value: any[];
    onChange: any;
  }>({
    options: [],
    value: [],
    onChange: undefined
  });

  const popupTags = ref<PopupTags>({
    show: false,
    controls: {
      tags: {
        error: "",
        label: "",
        placeholder: "Enter a tag",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: []
      }
    },
    data: {
      input: "",
      items: []
    }
  });
  const popupArchives = ref<{
    show: boolean;
  }>({
    show: false
  });

  const profilePanel = ref<profilePanel>({
    isShow: false,
    data: {
      id: 0,
      logo: "",
      firstName: "",
      lastName: "",
      photo: "",
      totalActions: 0,
      balance: 0,
      subProfiles: []
    }
  });

  const pageData = ref<PageData>({
    isLoading: true,
    isAddFilterGender: false,
    isAddFilterFamily: false,
    isAddFilterEvent: false,
    skip: 0,
    take: ProfileListRows(),
    activeTab: "",
    arrTags: [],
    arrProfiles: [],
    arrBalances: [],
    openItemId: 0,
    sort: {
      order: context.root.$route.query.order || "1",
      direction: context.root.$route.query.direction || "1"
    },
    filter: {
      profile: context.root.$route.query.profile || "",
      email: context.root.$route.query.email || "",
      phone: context.root.$route.query.phone || "",
      unassignedFamily: null,
      family: [],
      tag: [],
      age:
        (context.root.$route.query.minAge || "") +
        "-" +
        (context.root.$route.query.maxAge || ""),
      gender: [],
      events: [],
      unassignedEvent: null,
      balance:
        (context.root.$route.query.minBalance || "") +
        "@" +
        (context.root.$route.query.maxBalance || ""),
      minAge: context.root.$route.query.minAge || "",
      maxAge: context.root.$route.query.maxAge || "",
      minBalance: context.root.$route.query.minBalance || "",
      maxBalance: context.root.$route.query.maxBalance || ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: context.root.$route.query.page
        ? parseInt(context.root.$route.query.page)
        : 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    canDeleteProfile: false,
    // families
    searchFamiliesValue: {
      value: ""
    },
    selectedFamilies: [],
    selectedFamiliesLoading: false,
    searchFamilies: async (searchValue: string) => {
      pageData.value.selectedFamiliesLoading = true;
      const familyIds = (context.root.$route.query.family || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.unassignedFamily = {
          id: "0",
          text: "UN-ASSIGNED TO FAMILIES",
          selected: familyIds.includes(0)
        };
        pageData.value.filter.family = await ApiHelper.getProfileFamilyOptions({
          getAll: 1
        });
      } else {
        pageData.value.filter.family = await ApiHelper.getProfileFamilyOptions({
          familyName: searchValue
        });
        if (familyIds.includes(0)) {
          pageData.value.filter.unassignedFamily = {
            id: "0",
            text: "UN-ASSIGNED TO FAMILIES",
            selected: familyIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedFamily = null;
        }
      }
      pageData.value.selectedFamiliesLoading = false;
      pageData.value.filter.family = pageData.value.filter.family.map(item => ({
        ...item,
        selected: familyIds.includes(item.id)
      }));
    },
    searchEventsValue: {
      value: ""
    },
    searchEvents: async (searchValue: string) => {
      pageData.value.searchEventsLoading = true;
      const eventIds = (context.root.$route.query.event || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1
        });
        pageData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS",
          selected: eventIds.includes(0)
        };
      } else {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue
        });
        if (eventIds.includes(0)) {
          pageData.value.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedEvent = null;
        }
      }
      pageData.value.searchEventsLoading = false;
      pageData.value.filter.events = pageData.value.filter.events.map(item => ({
        ...item,
        selected: eventIds.includes(item.id)
      }));
    },
    searchEventsLoading: false
  });

  const toggleValue = ref<string>("archive");
  const userSettings = ref<any>({});

  const updateRouters = () => {
    const eventOptions = [];
    if (pageData.value.filter.unassignedEvent) {
      eventOptions.push(pageData.value.filter.unassignedEvent);
    }
    pageData.value.filter.events.map(item => {
      eventOptions.push(item);
    });

    const familyOptions = [];
    if (pageData.value.filter.unassignedFamily) {
      familyOptions.push(pageData.value.filter.unassignedFamily);
    }
    pageData.value.filter.family.map(item => {
      familyOptions.push(item);
    });
    // Check is filter
    let filterGender = pageData.value.filter.gender;
    let filterEvent = eventOptions;
    let filterFamily = familyOptions;
    if (!pageData.value.isAddFilterEvent) {
      filterEvent = [];
    }
    if (!pageData.value.isAddFilterGender) {
      filterGender = [];
    }
    if (!pageData.value.isAddFilterFamily) {
      filterFamily = [];
    }
    context.root.$router
      .replace({
        name: context.root.$route.name,
        query: {
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          event:
            ApiHelper.convertSelectedOptionsToString(filterEvent) || undefined,
          family:
            ApiHelper.convertSelectedOptionsToString(filterFamily) || undefined,
          tag:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.tag
            ) || undefined,
          gender:
            ApiHelper.convertSelectedOptionsToString(filterGender) || undefined,
          profile: pageData.value.filter.profile || undefined,
          email: pageData.value.filter.email || undefined,
          phone: pageData.value.filter.phone || undefined,
          minAge: pageData.value.filter.minAge || undefined,
          maxAge: pageData.value.filter.maxAge || undefined,
          minBalance: pageData.value.filter.minBalance || undefined,
          maxBalance: pageData.value.filter.maxBalance || undefined,
          searchFamilies: pageData.value.searchFamiliesValue.value || undefined,
          searchEvents: pageData.value.searchEventsValue.value || undefined
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadList = () => {
    const selectedProfileIds = selectedItems.value.map(item => item.id);

    const tagIds = context.root.$route.query.tag
      ? (context.root.$route.query.tag || "").split(",")
      : [];
    pageData.value.filter.tag.forEach((item: any) => {
      if (tagIds.indexOf(item.id.toString()) > -1) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    tagFilter.value.value = tagIds;

    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    (async () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getFiltersData();
      const result = await ApiHelper.callApi(
        "get",
        "/profiles",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          event: context.root.$route.query.event || "",
          tag: context.root.$route.query.tag || "",
          key: pageData.value.filter.profile || "",
          email: pageData.value.filter.email || "",
          phone: pageData.value.filter.phone || "",
          family: context.root.$route.query.family || "",
          gender: context.root.$route.query.gender || "",
          ageRange: pageData.value.filter.age || "",
          balanceRange: pageData.value.filter.balance || "",
          minAge: pageData.value.filter.minAge || "",
          maxAge: pageData.value.filter.maxAge || "",
          minBalance: pageData.value.filter.minBalance || "",
          maxBalance: pageData.value.filter.maxBalance || ""
        }
      );
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;

        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        // pageData.value.items = result.data.profiles.map((item: any) => {
        pageData.value.items = result.data.data.map((item: any) => {
          // Sub balance status waitlist
          /*
          if (item.totalWaitlist) {
            item.balance = item.balance - item.totalWaitlist;
          }
          */

          return {
            id: parseInt(item.id),
            status: item.isActive,
            isChecked: selectedProfileIds.includes(item.id),
            fullName: ApiHelper.getFullName(item.firstName, item.lastName),
            firstName: item.firstName,
            lastName: item.lastName,
            logo: item.logo,
            age: item.age,
            families: item.families || [],
            email: item.email,
            phone: item.phone,
            // familyID: item.familyID || 0,
            // familyName: item.familyName || item.lastName + " Family",
            // totalSubProfiles: item.totalSubProfiles,
            gender: ApiHelper.getGenderName(item.gender),
            balanceFormatted: ApiHelper.dollarFormat(item.balance, false, {
              useBrackets: true
            }),
            type: "",
            typeError: "",
            balance: item.balance || 0,
            activeEvents: item.activeEvents,
            totalEvents: item.totalEvents
          };
        });
      } else if (result.status === 401) {
        context.root.$router.push({ name: "Login" });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };

  const onClosePanel = async () => {
    profilePanel.value.isShow = false;
  };

  const onUpdateProfileAvatar = async (id: string, newImage: string) => {
    if (newImage !== "") {
      pageData.value.items.forEach(item => {
        if (item.id === parseInt(id)) {
          item.logo = newImage;
        }
      });
    }
  };

  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
    // loadData(parseInt(page));
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = event.target.value;
    updateRouters();
    // loadData(parseInt(event.target.value));
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
      // loadData(pageData.value.pager.page - 1);
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
      // loadData(pageData.value.pager.page + 1);
    }
  };

  const onViewDetails = async (profileId: number, familyID: number) => {
    ApiHelper.setDataLoading(true);
    try {
      const profileResult = await ApiHelper.callApi(
        "get",
        "/profiles/" + profileId + "/summary",
        {},
        {
          id: profileId,
          familyId: familyID,
          getInfo:
            "totalActions,actionsLast10Days,balance12MonthsForProfile,linkedProfiles"
        }
      );
      if (profileResult.status === 1) {
        const balances = profileResult.data.balance12MonthsForProfile.map(
          (item: any) => parseFloat(item.split("|")[1])
        );

        let balance12Months = 0;
        for (const i in balances) {
          balance12Months += parseFloat(balances[i]);
        }

        profilePanel.value.data = profileResult.data || {};
        profilePanel.value.data.balance = balance12Months;
        profilePanel.value.isShow = true;
        ApiHelper.setDataLoading(false);
      }
    } catch (err) {
      console.log(err);
      // alert(JSON.stringify(err));
      ApiHelper.setDataLoading(false);
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const toggleHeadActions = () => {
    pageData.value.showHeadActions = !pageData.value.showHeadActions;
  };

  const hideHeadActions = () => {
    pageData.value.showHeadActions = false;
  };

  const selectItem = (item: any) => {
    const existedItem = selectedItems.value.find(
      (value: any) => value.id == item.id
    );
    if (item.isChecked) {
      pageData.value.items.map(value => {
        if (value.id == item.id) {
          value.isChecked = true;
        }
      });
      if (!existedItem) {
        selectedItems.value.push(item);
      }
    } else {
      pageData.value.items.map(value => {
        if (value.id == item.id) {
          value.isChecked = false;
        }
      });
      selectedItems.value = selectedItems.value.filter(
        value => value.id != item.id
      );
    }
  };

  const getCheckedItems = () => {
    return selectedItems.value;
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };
  //
  // const updateSelectedFamilies = async (init = false) => {
  //   if (init) {
  //     pageData.value.selectedFamiliesLoading = true;
  //     const searchFamiliesValue =
  //       context.root.$route.query.searchFamilies || "";
  //     if (searchFamiliesValue != "") {
  //       pageData.value.searchFamiliesValue.value = searchFamiliesValue;
  //       pageData.value.filter.family = await ApiHelper.getProfileFamilyOptions({
  //         familyName: searchFamiliesValue
  //       });
  //     }
  //     const familyIds = (context.root.$route.query.family || "")
  //       .split(",")
  //       .map((id: string) => parseInt(id));
  //     if (familyIds.length) {
  //       pageData.value.filter.family = pageData.value.filter.family.map(
  //         item => ({
  //           ...item,
  //           selected: familyIds.includes(item.id)
  //         })
  //       );
  //       pageData.value.selectedFamilies = [
  //         ...pageData.value.filter.family
  //       ].filter(item => item.selected);
  //     }
  //     pageData.value.selectedFamiliesLoading = false;
  //   } else {
  //     pageData.value.selectedFamilies = [
  //       ...pageData.value.filter.family
  //     ].filter(item => item.selected);
  //   }
  // };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }

    if (pageData.value.activeTab == "family") {
      if (pageData.value.filter.family.length === 0) {
        await pageData.value.searchFamilies(
          pageData.value.searchFamiliesValue.value
        );
      }
    }

    if (pageData.value.activeTab == "events") {
      if (pageData.value.filter.events.length === 0) {
        await pageData.value.searchEvents(
          pageData.value.searchEventsValue.value
        );
      }
    }
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.profile !== "") {
      filters.push({
        label: "PEOPLE",
        key: pageData.value.filter.profile + Math.random(),
        value: pageData.value.filter.profile,
        reset: () => {
          pageData.value.filter.profile = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.email !== "") {
      filters.push({
        label: "EMAIL",
        key: pageData.value.filter.email + Math.random(),
        value: pageData.value.filter.email,
        reset: () => {
          pageData.value.filter.email = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.phone !== "") {
      filters.push({
        label: "PHONE",
        key: pageData.value.filter.phone + Math.random(),
        value: pageData.value.filter.phone,
        reset: () => {
          pageData.value.filter.phone = "";
          updateRouters();
        }
      });
    }
    const selectedFamilies = [];
    if (
      pageData.value.filter.unassignedFamily &&
      pageData.value.filter.unassignedFamily.selected
    ) {
      selectedFamilies.push(pageData.value.filter.unassignedFamily.text);
    }
    pageData.value.filter.family
      .filter((item: any) => item.selected)
      .map((item: any) => {
        selectedFamilies.push(item.text);
      });

    if (selectedFamilies.length > 0 && pageData.value.isAddFilterFamily) {
      filters.push({
        label: "FAMILY",
        key: selectedFamilies.join("-") + Math.random(),
        value: selectedFamilies.join(", "),
        reset: () => {
          pageData.value.isAddFilterFamily = false;
          pageData.value.filter.unassignedFamily = null;
          pageData.value.filter.family = [];
          pageData.value.selectedFamilies = [];
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    /*
    const selectedTags = pageData.value.filter.tag
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedTags.length > 0) {
      filters.push({
        label: "TAG",
        key: selectedTags.join("-") + Math.random(),
        value: selectedTags.join(", "),
        reset: () => {
          pageData.value.filter.tag.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
     */
    if (
      pageData.value.filter.minAge !== "" ||
      pageData.value.filter.maxAge !== ""
    ) {
      let ageRange = "";
      if (pageData.value.filter.minAge && pageData.value.filter.maxAge) {
        ageRange =
          pageData.value.filter.minAge + "-" + pageData.value.filter.maxAge;
      } else if (pageData.value.filter.minAge) {
        ageRange = pageData.value.filter.minAge + "+";
      } else if (pageData.value.filter.maxAge) {
        ageRange = pageData.value.filter.maxAge + "-";
      }
      filters.push({
        label: "AGE",
        key: pageData.value.filter.age + Math.random(),
        value: ageRange,
        reset: () => {
          pageData.value.filter.age = "";
          pageData.value.filter.minAge = "";
          pageData.value.filter.maxAge = "";
          updateRouters();
        }
      });
    }

    const selectedEvents = [];
    if (
      pageData.value.filter.unassignedEvent &&
      pageData.value.filter.unassignedEvent.selected
    ) {
      selectedEvents.push(pageData.value.filter.unassignedEvent.text);
    }
    pageData.value.filter.events
      .filter((item: any) => item.selected)
      .map((item: any) => {
        selectedEvents.push(item.text);
      });
    if (selectedEvents.length > 0 && pageData.value.isAddFilterEvent) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.isAddFilterEvent = false;
          pageData.value.filter.unassignedEvent = null;
          pageData.value.filter.events = [];
          pageData.value.searchEventsValue.value = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    let selectedGendersUrl = [];
    const selectedGenders: any[] = [];
    if (context.root.$route.query.gender) {
      selectedGendersUrl = context.root.$route.query.gender.split(",");
    }
    selectedGendersUrl.forEach((item: any) => {
      if (parseInt(item) === 2) {
        selectedGenders.push("Female");
      } else {
        selectedGenders.push("Male");
      }
    });
    if (selectedGenders.length > 0 && pageData.value.isAddFilterGender) {
      filters.push({
        label: "GENDER",
        key: selectedGenders.join("-") + Math.random(),
        value: selectedGenders.join(", "),
        reset: () => {
          pageData.value.isAddFilterGender = false;
          pageData.value.filter.gender.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          // updateRouters();
        }
      });
    }
    if (
      pageData.value.filter.minBalance !== "" ||
      pageData.value.filter.maxBalance !== ""
    ) {
      const displayValue =
        (pageData.value.filter.minBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minBalance, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (pageData.value.filter.maxBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxBalance, 10),
              false,
              { useBrackets: true }
            )
          : "");
      filters.push({
        label: "BALANCE",
        key: pageData.value.filter.balance + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.balance = "";
          pageData.value.filter.minBalance = "";
          pageData.value.filter.maxBalance = "";
          updateRouters();
        }
      });
    }
    return {
      list: filters
    };
  };

  const applyFilters = () => {
    loadData(1);
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "profile":
        pageData.value.filter.profile = value;
        break;
      case "email":
        pageData.value.filter.email = value;
        break;
      case "phone":
        pageData.value.filter.phone = value;
        break;
      // case "family":
      //   pageData.value.filter.family = value;
      //   break;
      // case "events":
      //   pageData.value.filter.events = value;
      //   break;
      // case "gender":
      //   pageData.value.filter.gender = value;
      //   break;
      case "age": {
        pageData.value.filter.age = value;
        const ageArr = value.split("-");
        pageData.value.filter.minAge = ageArr[0] != undefined ? ageArr[0] : "";
        pageData.value.filter.maxAge = ageArr[1] != undefined ? ageArr[1] : "";
        break;
      }
      case "balance": {
        pageData.value.filter.balance = value;
        const balanceArr = value.split("@");
        pageData.value.filter.minBalance =
          balanceArr[0] != undefined ? balanceArr[0] : "";
        pageData.value.filter.maxBalance =
          balanceArr[1] === undefined ? "" : balanceArr[1];
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const selectAllGenders = () => {
    pageData.value.filter.gender = pageData.value.filter.gender.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    pageData.value.isAddFilterGender = true;
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const resetGenders = () => {
    pageData.value.filter.gender = pageData.value.filter.gender.map(
      (item: any) => {
        item.selected = false;
        return item;
      }
    );
    pageData.value.isAddFilterGender = false;
    updateRouters();
  };

  const selectAllEvents = () => {
    if (pageData.value.filter.unassignedEvent) {
      pageData.value.filter.unassignedEvent.selected = true;
    }
    pageData.value.filter.events = pageData.value.filter.events.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    pageData.value.isAddFilterEvent = true;
    updateRouters();
  };

  const resetEvents = () => {
    pageData.value.isAddFilterEvent = false;
    pageData.value.filter.unassignedEvent = null;
    pageData.value.filter.events = [];
    pageData.value.searchEventsValue.value = "";
    updateRouters();
  };

  const selectAllFamilies = () => {
    if (pageData.value.filter.unassignedFamily) {
      pageData.value.filter.unassignedFamily.selected = true;
    }
    pageData.value.filter.family = pageData.value.filter.family.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    pageData.value.isAddFilterFamily = true;
    updateRouters();
  };

  const resetFamilies = () => {
    pageData.value.isAddFilterFamily = false;
    pageData.value.filter.unassignedFamily = null;
    pageData.value.filter.family = [];
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const showStatusDropdown = (id: number) => {
    if (id !== pageData.value.openItemId) {
      pageData.value.openItemId = id;
    } else {
      pageData.value.openItemId = 0;
    }
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const loadTags = async () => {
    const inIds = context.root.$route.query.tag || "";
    if (inIds) {
      const result = await ApiHelper.callApi(
        "get",
        "/tags/search",
        {},
        {
          inIds: inIds
        }
      );
      if (result.status === 1) {
        pageData.value.filter.tag = tagFilter.value.options = result.data.tags.map(
          (item: any) => {
            return {
              id: item.id,
              text: item.text,
              selected: true
            };
          }
        );
      }
    }
  };

  // const searchFamilies = async (searchValue: string) => {
  //   pageData.value.selectedFamiliesLoading = true;
  //   if (searchValue == "") {
  //     // load top 20 families
  //     pageData.value.filter.family = await ApiHelper.getProfileFamilyOptions({
  //       take: 20
  //     });
  //   } else {
  //     pageData.value.filter.family = await ApiHelper.getProfileFamilyOptions({
  //       familyName: searchValue
  //     });
  //   }
  //   const familyIds = (context.root.$route.query.family || "")
  //     .split(",")
  //     .map((id: string) => parseInt(id));
  //   pageData.value.filter.family = pageData.value.filter.family.map(item => ({
  //     ...item,
  //     selected: familyIds.includes(item.id)
  //   }));
  //   pageData.value.selectedFamiliesLoading = false;
  // };

  const getUserSettings = async () => {
    // userSettings
    const result = await ApiHelper.callApi(
      "get",
      "/users/userSettings",
      {},
      {},
      "core"
    );
    if (result.status === 1) {
      let profilesCustomView = result.data.profilesCustomView || [];
      const isReload = profilesCustomView.find(
        (item: any) => item.name == "Family" && !item.optional
      );
      if (isReload || Object.keys(profilesCustomView).length == 0) {
        // set defaul custom view
        profilesCustomView = [
          {
            name: "People",
            optional: false,
            checked: true
          },
          {
            name: "Family",
            optional: true,
            checked: true
          },
          {
            name: "Age",
            optional: true,
            checked: true
          },
          {
            name: "Phone",
            optional: true,
            checked: true
          },
          {
            name: "Email",
            optional: true,
            checked: true
          },
          {
            name: "Gender",
            optional: true,
            checked: true
          },
          {
            name: "Event",
            optional: true,
            checked: true
          },
          {
            name: "Balance",
            optional: true,
            checked: true
          }
        ];
      }
      userSettings.value.profilesCustomView = profilesCustomView;
    }
  };

  // init data
  (async () => {
    pageData.value.searchEventsValue.value =
      context.root.$route.query.searchEvents || "";
    if (context.root.$route.query.event) {
      await pageData.value.searchEvents(pageData.value.searchEventsValue.value);
    }
  })();
  (async () => {
    pageData.value.searchFamiliesValue.value =
      context.root.$route.query.searchFamilies || "";
    if (context.root.$route.query.family) {
      await pageData.value.searchFamilies(
        pageData.value.searchFamiliesValue.value
      );
    }
  })();
  (async () => {
    try {
      const threads = [loadTags(), getUserSettings()];
      await Promise.all(threads);
    } catch (err) {
      // console.log("err", err);
    }
    loadData(1);
    // await updateSelectedFamilies(true);

    pageData.value.filter.gender = ApiHelper.getGenderOptions();
    const genderIds = (context.root.$route.query.gender || "").split(",");
    pageData.value.filter.gender.forEach((item: any) => {
      if (genderIds.indexOf(item.id) > -1) {
        item.selected = true;
      }
    });
  })();

  // POPUPS
  const closePopupTags = () => {
    popupTags.value.show = false;
    popupTags.value.controls.tags.key = "";
  };

  const closePopupArchives = () => {
    popupArchives.value.show = false;
  };

  const showPopupArchives = async () => {
    pageData.value.canDeleteProfile = false;
    toggleValue.value = "archive";
    const selectedProfileIds = getCheckedItems().map(item => {
      return item.id;
    });
    if (selectedProfileIds.length == 0) return;

    try {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/canDeleteCheck",
        {},
        {
          selectedProfileIds: selectedProfileIds.join(",")
        }
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        pageData.value.canDeleteProfile = result.data.canDelete || false;
      }
      popupArchives.value.show = true;
    } catch (error) {
      // console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const doDeleteSelectedItems = async ($root: any) => {
    const warningMessage =
      toggleValue.value == "archive"
        ? "Are you sure you want to archive the selected people?"
        : "Are you sure you want to delete the selected people?";
    const isAgreed = await Vue.swal({
      html: warningMessage,
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then(result => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      return result.isConfirmed;
    });

    if (isAgreed) {
      ApiHelper.setDataLoading(true);
      const selectedProfileIds = getCheckedItems().map(item => {
        return item.id;
      });
      const result = await ProfileHelper.deleteSelectedProfiles(
        selectedProfileIds,
        toggleValue.value
      );
      ApiHelper.setDataLoading(false);
      popupArchives.value.show = false;
      if (result.status == 1) {
        selectedItems.value = [];
        const successMessage =
          toggleValue.value == "archive"
            ? "Archived the selected people"
            : "Deleted the selected people";
        ApiHelper.showSuccessMessage(successMessage);
        loadData(1);
        // update userStats
        const children = $root.$children || [];
        const layout = children.length > 0 ? children[0] : undefined;
        if (layout && layout.$children.length > 0) {
          layout.$children[0].loadData();
        }
      } else {
        if (toggleValue.value == "archive") {
          ApiHelper.showErrorMessage(result.message);
        } else {
          const owersHasChildren = result.data?.owersHasChildren || [];
          let message = result.message;
          if (owersHasChildren.length) {
            // prepare message
            message = `It seems you selected to delete onwer of family has some members.`;
          }
          ApiHelper.showErrorMessage(message);
        }
      }
    } else {
      popupArchives.value.show = false;
    }
  };

  const doTagSelectedItems = () => {
    popupTags.value.controls.tags.error = "";
    popupTags.value.controls.tags.value = [];
    popupTags.value.show = true;
  };

  const doSubmitTagSelectedProfiles = (tags: any[], callback: any) => {
    const selectedProfileIds = getCheckedItems().map(item => {
      return item.id;
    });
    const finalTags = popupTags.value.controls.tags.value;
    if (finalTags.length == 0) {
      popupTags.value.controls.tags.error = "Please select tags";
      // ApiHelper.showErrorMessage("Please select tags");
    } else {
      ApiHelper.setDataLoading(true);
      (async () => {
        const result = await ProfileHelper.tagProfiles(
          selectedProfileIds,
          finalTags
        );
        ApiHelper.setDataLoading(false);
        if (result.status == 1) {
          selectedItems.value = [];
          ApiHelper.showSuccessMessage("The selected people have been tagged");
          loadData(1);
          if (callback) {
            callback();
          }
          popupTags.value.controls.tags.value = [];
          popupTags.value.show = false;
        } else if (result.status == 2) {
          selectedItems.value = [];
          loadData(1);
          if (callback) {
            callback();
          }
          popupTags.value.controls.tags.value = [];
          popupTags.value.show = false;
          Vue.swal("", result?.message, "warning");
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      })();
    }
  };

  // popup new message
  const messageData = ref({
    profileIds: "",
    message: ""
  });

  const syncMessageData = (formData: any) => {
    messageData.value.message = formData.controls.message.value;
    const selectedProfileIds = getCheckedItems().map(item => {
      return item.id;
    });
    messageData.value.profileIds = selectedProfileIds.join(",");
  };

  const resetFamilyData = (formData: any) => {
    messageData.value.message = "";
    formData.controls.message.error = "";
    formData.controls.message.value = "";
  };

  const formNewMessageData = ref<{
    isLoading: boolean;
    controls: {
      message: FormTextarea;
    };
  }>({
    isLoading: true,
    controls: {
      message: {
        name: "pmMessage",
        error: "",
        placeholder: "Enter message",
        type: "textarea",
        value: ""
      }
    }
  });
  const popupNewMessage = ref<{
    show: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
  }>({
    show: false,
    formData: formNewMessageData,
    removeFieldError: async () => {
      // nothing
    },
    onSubmit: async () => {
      let hasError = false;
      syncMessageData(formNewMessageData.value);
      if (messageData.value.message === "") {
        hasError = true;
        formNewMessageData.value.controls.message.error =
          "Message is required!";
      } else {
        formNewMessageData.value.controls.message.error = "";
      }
      if (!hasError) {
        ApiHelper.setDataLoading(true);
        const result = await ApiHelper.callApi(
          "post",
          "/messages/multi",
          {
            message: messageData.value.message,
            profileIds: messageData.value.profileIds
          },
          {}
        );
        ApiHelper.setDataLoading(false);
        if (result.status == 1) {
          selectedItems.value = [];
          popupNewMessage.value.show = false;
          resetFamilyData(formNewMessageData.value);
          loadData(1);
          ApiHelper.showSuccessMessage("Message Sent");
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      }
    }
  });
  // popup new tribe
  const selectedProfiles = ref<any[]>([]);
  const familyTypeOptions = ref<any[]>([]);
  const tribeData = ref({
    profile: 0,
    name: "",
    participants: ""
  });

  const syncTribeData = (formData: any) => {
    tribeData.value.profile = parseInt(formData.controls.profile.value) || 0;
    tribeData.value.name = formData.controls.name.value;
    tribeData.value.participants = selectedProfiles.value
      .filter(item => {
        return item.id !== tribeData.value.profile;
      })
      .map(item => {
        return item.id + ":" + (item.type || 0);
      })
      .join(",");
    selectedProfiles.value.map(item => {
      if (!item.type && item.id !== tribeData.value.profile) {
        item.typeError = "Type is required.";
      } else {
        item.typeError = "";
      }
      return item;
    });
  };

  (async () => {
    familyTypeOptions.value = await ApiHelper.getFamilyTypeOptions();
  })();

  const updateSelectedProfiles = () => {
    selectedProfiles.value = getCheckedItems();
    selectedProfiles.value.map(item => {
      if (item) {
        item.type = "";
        item.typeError = "";
      }
    });
  };

  const resetTribeData = (formData: any) => {
    tribeData.value.name = "";
    formData.controls.name.error = "";
    formData.controls.name.value = "";
  };

  const formNewTribeData = ref<{
    isLoading: boolean;
    controls: {
      profile: FormSelect;
      name: FormText;
      participants: FormTextarea;
    };
  }>({
    isLoading: true,
    controls: {
      profile: {
        name: "pmTribeProfile",
        error: "",
        label: "Person",
        placeholder: "Person",
        type: "select",
        options: [],
        value: ""
      },
      name: {
        name: "pmTribeName",
        error: "",
        label: "Tribe/Family Name",
        placeholder: "Enter Name",
        type: "text",
        value: ""
      },
      participants: {
        name: "pmTribeParticipants",
        error: "",
        label: "Participants",
        placeholder: "Enter Participants",
        type: "textarea",
        value: ""
      }
    }
  });

  const popupNewTribe = ref<{
    show: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
  }>({
    show: false,
    formData: formNewTribeData,
    removeFieldError: async () => {
      // nothing
    },
    onSubmit: async () => {
      let hasError = false;
      syncTribeData(formNewTribeData.value);
      if (tribeData.value.profile === 0) {
        hasError = true;
        formNewTribeData.value.controls.profile.error = "Person is required!";
      } else {
        formNewTribeData.value.controls.profile.error = "";
      }
      if (tribeData.value.name === "") {
        hasError = true;
        formNewTribeData.value.controls.name.error = "Name is required!";
      } else {
        formNewTribeData.value.controls.name.error = "";
      }

      const findTypeError = selectedProfiles.value.find(item => {
        return item.typeError;
      });
      if (findTypeError) {
        hasError = true;
      }
      if (!hasError) {
        const result = await ApiHelper.callApi(
          "post",
          "/families/tribe",
          tribeData.value,
          {}
        );
        if (result.status == 1) {
          selectedItems.value = [];
          popupNewTribe.value.show = false;
          resetTribeData(formNewTribeData.value);
          loadData(1);
          ApiHelper.showSuccessMessage("Saved");
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      }
    }
  });

  tagFilter.value.onChange = () => {
    pageData.value.filter.tag = tagFilter.value.options;
    updateRouters();
  };
  const updateLastInput = (key: string) => {
    popupTags.value.data.input = key;
  };

  const popupMergeParticipants = ref<{
    show: boolean;
    profileIds: string;
  }>({
    show: false,
    profileIds: ""
  });

  const mergeParticipants = async () => {
    const selectedParticipants = selectedItems.value;
    const profileIds: number[] = [];
    selectedParticipants.map((item: any) => {
      if (!profileIds.includes(item.id)) {
        profileIds.push(item.id);
      }
    });
    if (profileIds.length < 2) {
      ApiHelper.showErrorMessage("Please select more than 1 account to merge");
      return false;
    }

    popupMergeParticipants.value.profileIds = profileIds.join(",");
    popupMergeParticipants.value.show = true;
    return false;
  };

  const showMergeButton = () => {
    const selectedParticipants = selectedItems.value;
    const participantIDs: number[] = [];
    selectedParticipants.map((item: any) => {
      if (!participantIDs.includes(item.id)) {
        participantIDs.push(item.id);
      }
    });
    return participantIDs.length > 1;
  };

  const pActiveSwitch = () => {
    toggleValue.value = toggleValue.value == "archive" ? "delete" : "archive";
  };

  const updateProfilesCustomView = async () => {
    const profilesCustomView = userSettings.value.profilesCustomView || [];
    if (profilesCustomView.length == 0) return;

    const result = await ApiHelper.callApi(
      "put",
      "/users/userSettings",
      {
        profilesCustomView
      },
      {},
      "core"
    );
    if (result.status === 1) {
      if (context.refs.profilesTable) {
        context.refs.profilesTable.$forceUpdate();
      }
    }
  };

  return {
    selectedItems,
    popupMergeParticipants,
    showMergeButton,
    mergeParticipants,
    updateLastInput,
    tagFilter,
    loadTags,
    loadList,
    updateRouters,
    // sort
    updateSortValue,
    profilePanel,
    pageData,
    showStatusDropdown,
    // filters
    selectAllGenders,
    resetGenders,
    selectAllEvents,
    resetEvents,
    selectAllFamilies,
    resetFamilies,
    // pager
    gotoPage,
    getCheckedItems,
    togglePagerItems,
    toggleHeadActions,
    hideHeadActions,
    onViewDetails,
    onClosePanel,
    onUpdateProfileAvatar,
    onClickPrev,
    onClickNext,
    onPagerChange,
    setActiveFilterTab,
    closeFilterTab,
    getFiltersData,
    applyFilters,
    updateFilterValue,
    doDeleteSelectedItems,
    doTagSelectedItems,
    selectItem,
    // popups
    familyTypeOptions,
    selectedProfiles,
    popupNewMessage,
    popupNewTribe,
    updateSelectedProfiles,
    showPopupArchives,
    popupTags,
    closePopupTags,
    popupArchives,
    closePopupArchives,
    doSubmitTagSelectedProfiles,
    toggleValue,
    pActiveSwitch,
    // searchFamilies,
    // updateSelectedFamilies,
    userSettings,
    updateProfilesCustomView,
    getUserSettings
  };
}
