import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormPassword, FormText } from "@/types";

export function useSettingsUserEditStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const headerData = ref({
    title: selectedId > 0 ? "" : "Add New User",
    subTitle: "Users"
  });

  const formData = ref<{
    isLoading: boolean;
    isUserLogin: number;
    controls: {
      email: FormText;
      username: FormText;
      firstName: FormText;
      lastName: FormText;
      currentPassword: FormPassword;
      password: FormPassword;
      confirmPassword: FormPassword;
      active: {
        label?: string;
        error: string;
        type: string;
        value: string;
        yesValue: string;
        noValue: string;
        defaultValue: string;
      };
      groups: {
        label: string;
        placeholder: string;
        style: string;
        showDropdown: boolean;
        disabled?: boolean;
        value: string;
        error: string;
        defaultValues: number[];
        options: any[];
      };
    };
    validateAll: any;
    actions: {
      onSubmit: any;
    };
  }>({
    isLoading: true,
    isUserLogin: selectedId == parseInt(ApiHelper.getLoggedUser().id) ? 1 : 0,
    controls: {
      username: {
        required: false,
        label: "Username",
        placeholder: "Username",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100
      },
      email: {
        required: true,
        label: "Email",
        placeholder: "Email",
        style: "custom",
        value: "",
        error: "",
        maxlength: 150
      },
      firstName: {
        required: true,
        label: "First Name",
        placeholder: "First Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 150
      },
      lastName: {
        required: true,
        label: "Last Name",
        placeholder: "Last Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 150
      },
      active: {
        label: "Active?",
        error: "",
        type: "radios",
        value: "no",
        yesValue: "yes",
        noValue: "no",
        defaultValue: ""
      },
      currentPassword: {
        required: true,
        error: "",
        label: "Current Password",
        placeholder: "Current Password",
        type: "password",
        value: "",
        style: "custom"
      },
      password: {
        required: true,
        label: selectedId ? "New Password" : "Password",
        value: "",
        type: "password",
        error: "",
        style: "custom",
        mode: "password",
        placeholder: selectedId ? "New Password" : "Password",
        hideIcon: true,
        maxlength: 150
      },
      confirmPassword: {
        required: true,
        label: "Confirm Password",
        value: "",
        type: "password",
        error: "",
        style: "custom",
        mode: "password",
        placeholder: "Confirm Password",
        hideIcon: true,
        maxlength: 150
      },
      groups: {
        label: "Security Groups",
        placeholder: "Enter the group name",
        style: "custom",
        showDropdown: false,
        defaultValues: [],
        value: "",
        error: "",
        options: []
      }
    },
    validateAll: () => {
      let hasError = false;
      // if (formData.value.controls.username.value === "") {
      //   formData.value.controls.username.error = "Username is required";
      //   hasError = true;
      // }
      if (formData.value.controls.email.value === "") {
        formData.value.controls.email.error = "Email is required";
        hasError = true;
      } else if (
        !ApiHelper.validateEmail(formData.value.controls.email.value)
      ) {
        formData.value.controls.email.error = "Email is invalid";
        hasError = true;
      }
      if (formData.value.controls.firstName.value === "") {
        formData.value.controls.firstName.error = "First Name is required";
        hasError = true;
      }
      if (formData.value.controls.lastName.value === "") {
        formData.value.controls.lastName.error = "Last Name is required";
        hasError = true;
      }
      if (selectedId === 0) {
        if (formData.value.controls.password.value === "") {
          formData.value.controls.password.error = "Password is required";
          hasError = true;
        }
        if (formData.value.controls.confirmPassword.value === "") {
          formData.value.controls.confirmPassword.error =
            "Confirm Password is required";
          hasError = true;
        }
        if (
          formData.value.controls.password.value !== "" &&
          formData.value.controls.confirmPassword.value !== "" &&
          formData.value.controls.confirmPassword.value !==
            formData.value.controls.password.value
        ) {
          formData.value.controls.confirmPassword.error =
            "Confirm Password is not match";
          hasError = true;
        }
        if (
          formData.value.controls.password.value !== "" &&
          !formData.value.controls.password.valid
        ) {
          formData.value.controls.password.error = "Password is invalid";
          hasError = true;
        }
      } else {
        if (formData.value.controls.password.value !== "") {
          if (formData.value.controls.confirmPassword.value === "") {
            formData.value.controls.confirmPassword.error =
              "Confirm Password is required";
            hasError = true;
          }
          if (
            formData.value.controls.password.value !== "" &&
            formData.value.controls.confirmPassword.value !== "" &&
            formData.value.controls.confirmPassword.value !==
              formData.value.controls.password.value
          ) {
            formData.value.controls.confirmPassword.error =
              "Confirm Password is not match";
            hasError = true;
          }
          if (!formData.value.controls.password.valid) {
            formData.value.controls.password.error = "Password is invalid";
            hasError = true;
          }
        }
        if (
          formData.value.controls.password.value &&
          formData.value.isUserLogin &&
          !formData.value.controls.currentPassword.value
        ) {
          hasError = true;
          formData.value.controls.currentPassword.error =
            "Current Password is required!";
        }
      }
      return hasError;
    },
    actions: {
      onSubmit: async () => {
        const hasError = formData.value.validateAll();
        if (hasError) {
          return;
        }
        const result = await ApiHelper.callApi(
          "put",
          "/users/" + selectedId + "/edit",
          {
            username: formData.value.controls.username.value,
            email: formData.value.controls.email.value.toLowerCase(),
            firstName: formData.value.controls.firstName.value,
            lastName: formData.value.controls.lastName.value,
            password: formData.value.controls.password.value,
            confirmPassword: formData.value.controls.confirmPassword.value,
            active: formData.value.controls.active.value === "yes" ? 1 : 0,
            groups: formData.value.controls.groups.options,
            currentPassword: formData.value.controls.currentPassword.value
          },
          {},
          "core"
        );
        if (result.status !== 1) {
          const messageCode = result.messageCode || "";
          switch (messageCode) {
            case "existedUsername":
              formData.value.controls.username.error =
                "The username already exists";
              break;
            case "existedEmail":
              formData.value.controls.email.error = "The email already exists";
              break;
            default:
              ApiHelper.showErrorMessage(result.message, "Oops");
              break;
          }

          return;
        }
        ApiHelper.showSuccessMessage(selectedId ? "Updated" : "Added");
        ApiHelper.gotoPage(context, {
          name: "SettingsUsers"
        });
      }
    }
  });

  const pageData = ref<{
    isLoading: boolean;
  }>({
    isLoading: true
  });

  const loadData = async () => {
    if (selectedId > 0) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/users/" + selectedId,
        {},
        {},
        "core"
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const resultData = result.data || null;
        if (resultData) {
          headerData.value.title = ApiHelper.getFullName(
            resultData.firstName || "",
            resultData.lastName || ""
          );
          headerData.value.subTitle = "Users";
          formData.value.controls.username.disabled = !!resultData.username;
          formData.value.controls.username.value = resultData.username || "";
          formData.value.controls.email.value = resultData.email || "";
          formData.value.controls.firstName.value = resultData.firstName || "";
          formData.value.controls.lastName.value = resultData.lastName || "";
          formData.value.controls.active.value =
            resultData.active == 1 ? "yes" : "no";
          formData.value.controls.groups.defaultValues =
            resultData.groupIds || [];
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "SettingsUsers" });
      }
    } else {
      ApiHelper.setDataLoading(false);
    }
  };

  const loadGroups = async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/securities/groups",
      {},
      {},
      "core"
    );
    if (result.status === 1) {
      formData.value.controls.groups.options = result.data.groups || [];
      formData.value.controls.groups.options.map(item => {
        item.title = item.name;
        if (formData.value.controls.groups.defaultValues.includes(item.id)) {
          item.selected = true;
        }
      });
    }
  };

  (async () => {
    await loadData();
    await loadGroups();
  })();

  return {
    loadData,
    headerData,
    pageData,
    formData
  };
}
