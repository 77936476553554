



































import { defineComponent } from "@vue/composition-api";

export type PageData<Item> = {
  pager: {
    items: Item[];
    page: number;
    showPagerItems: boolean;
  };
};

export default defineComponent({
  name: "Pager",
  props: {
    pageData: Object,
    togglePagerItems: Function,
    gotoPage: Function,
    onClickPrev: Function,
    onClickNext: Function
  },
  setup(props, context) {
    const onChange = (event: any) => {
      if (props.gotoPage) {
        props.gotoPage(event.target.value);
      }
    };
    return {
      onChange
    };
  }
});
