var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    noLabel: !_vm.data.label,
    'tag-single-selection': !_vm.multiSelection,
    'selected-values': (_vm.data.value || []).length
  }},[(_vm.data.label)?_c('div',{staticClass:"group"},[_c('label',{staticClass:"label__full",class:{
        isRequired: (_vm.data.required || false) == true
      }},[_vm._v(" "+_vm._s(_vm.data.label)+" ")])]):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"group"},[_c('div',{staticClass:"form-group",staticStyle:{"margin-bottom":"0"}},[(!_vm.disabled)?[_c('div',{staticClass:"tag-input",class:{
            'border-danger': _vm.data.error,
            small: _vm.hasBorder,
            noneBackground: _vm.noneBackground
          },staticStyle:{"border":"1px solid transparent"}},[_vm._l((_vm.data.value),function(tag,index){return _c('div',{key:index + '-' + tag.text,staticClass:"tag-input__tag",class:{
              textOverflow: _vm.data.textOverflow
            },attrs:{"title":tag.text + (tag.data ? ' - ' + tag.data.typeName : '')}},[_c('span',{staticClass:"btn-delete",on:{"click":function () {
                  !_vm.disabled ? _vm.onRemoveTag(index) : undefined;
                }}},[_vm._v("x")]),_vm._v(" "+_vm._s(tag.text)+" ")])}),(_vm.isNumber)?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:({ regex: '[0-9]{1,50}?$' }),expression:"{ regex: '[0-9]{1,50}?$' }"},{name:"model",rawName:"v-model.trim",value:(_vm.data.key),expression:"data.key",modifiers:{"trim":true}}],staticClass:"tag-input__text",class:[
              'tag-box',
              {
                'input-loader': false
              }
            ],attrs:{"type":"text","autocomplete":"new-password","placeholder":_vm.data.placeholder,"disabled":_vm.disabled || _vm.disabledTagInputText},domProps:{"value":(_vm.data.key)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doAddTag($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.onRemoveLastTag($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return (function (e) { return _vm.$emit('onTab', e); })($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.onClickOutsidec($event)}],"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "key", $event.target.value.trim())},_vm.onInput],"focus":_vm.onFocus,"blur":function($event){return _vm.$forceUpdate()}}}):_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.data.key),expression:"data.key",modifiers:{"trim":true}}],staticClass:"tag-input__text min",class:[
              'tag-box',
              {
                'input-loader': false,
                'w-100': !_vm.multiSelection
              }
            ],attrs:{"type":"text","autocomplete":"new-password","placeholder":_vm.data.placeholder,"disabled":_vm.disabled || _vm.disabledTagInputText},domProps:{"value":(_vm.data.key)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doAddTag($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.onRemoveLastTag($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return (function (e) { return _vm.$emit('onTab', e); })($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.onClickOutside($event)}],"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "key", $event.target.value.trim())},_vm.onInput],"focus":_vm.onFocus,"blur":function($event){return _vm.$forceUpdate()}}})],2),(_vm.hasQuestion)?_c('span',{staticClass:"position-relative question-tooltip"},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"src":require("@/assets/images/question.svg"),"alt":"Question","title":"Enter your event type to associate this to other events of the same type"}})]):_vm._e()]:_c('div',{staticClass:"tag-input flex align-items-center pt-2 pb-2"},[_c('div',{class:{ textOverflow: _vm.nowrap },staticStyle:{"line-height":"20px","font-weight":"bold"},attrs:{"title":_vm.nowrap ? _vm.data.value.map(function (tag) { return tag.text; }).join(', ') : ''}},[(_vm.data.value.length)?_c('span',{class:{
              allowAddColor: _vm.allowAddColor
            }},[_vm._v(" "+_vm._s(_vm.data.value.map(function (tag) { return tag.text; }).join(", "))+" ")]):_c('input',{staticClass:"tag-input__text w-100",attrs:{"placeholder":_vm.data.placeholder,"disabled":"disabled"}})])])],2),_c('div',{staticClass:"autocomplete-input",class:{
        disabled: _vm.disabled || _vm.disabledTagInputText,
        addTopPopOver: _vm.addTopPopOver,
        addTopPopOverTag: _vm.addTopPopOverTag,
        addTopPopOverPtype: _vm.addTopPopOverPtype
      }},[(_vm.show && !_vm.loading && !_vm.isACILoading)?_c('div',{staticClass:"box_content",class:{
          allowAddNew: _vm.allowAddNew,
          addTopPopOverToolTip: _vm.addTopPopOverToolTip,
          autoTop: _vm.autoTop
        }},[(_vm.hint !== '')?_c('div',{staticClass:"hint"},[_vm._v(_vm._s(_vm.hint))]):(_vm.data.suggestTags.length)?_c('VScroller',{attrs:{"fadeScroll":false}},[_c('ul',{staticClass:"results-box",attrs:{"slot":"default"},slot:"default"},[_vm._l((_vm.data.suggestTags),function(item,index){return _c('li',{key:index + '-' + item.text,staticClass:"text-uppercase",attrs:{"data-id":item.id},on:{"click":function($event){return _vm.onSelectTag(item)}}},[((item.html || '') != '')?_c('div',{class:{ textOverflow: _vm.nowrapSuggestedItems },attrs:{"title":_vm.nowrapSuggestedItems ? item.text : ''},domProps:{"innerHTML":_vm._s(item.html)}}):_c('div',{class:{ textOverflow: _vm.nowrapSuggestedItems },attrs:{"title":_vm.nowrapSuggestedItems ? item.text : ''},domProps:{"innerHTML":_vm._s(item.text)}})])}),(_vm.endItemMessage != '')?_c('li',{staticClass:"end-custom-message"},[_vm._v(" "+_vm._s(_vm.endItemMessage)+" ")]):_vm._e()],2)]):_vm._e(),(
            !_vm.hideNoData &&
              _vm.data.suggestTags.length === 0 &&
              (_vm.data.key || _vm.data.showNoData)
          )?_c('div',{staticClass:"noData2",class:{
            sm: _vm.size === 'sm',
            normal: _vm.isNormal
          },domProps:{"innerHTML":_vm._s(_vm.noRecordMessage)},on:{"click":function($event){return _vm.$emit('addNewClick')}}}):_vm._e()],1):_vm._e()]),(_vm.data.error && !_vm.noTextDanger)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e()]),(_vm.showPopup)?_c('Modal',{staticClass:"popup-tag-types",attrs:{"title":'Tag type',"is-loading":_vm.modalLoading,"on-close":function () {
        _vm.data.key = '';
        _vm.showPopup = false;
      }}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('FormSelect',{attrs:{"data":_vm.formType}})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('a',{staticClass:"btn btn-primary",on:{"click":_vm.saveTag}},[_vm._v("Save")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }