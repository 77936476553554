









































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Header from "@/components/Common/Header.vue";
import { useFinancialOutstandingBalancesStore } from "@/stores/Financial/FinancialOutstandingBalancesStore";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import PopupPayment from "@/components/Financial/PopupPayment.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import { ApiHelper } from "@/helpers/index";

export default defineComponent({
  name: "OutstandingBalances",
  components: {
    Loading,
    Header,
    Pager,
    PopupPayment,
    FilterDateColumn,
    FilterSelectColumn,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterAgesColumn,
    FilterNumberRangeColumn
  },
  setup(props, context) {
    const {
      loadData,
      pageData,
      // headerData,
      // pager
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      setActiveFilterTab,
      closeFilterTab,
      selectAllEvents,
      resetEvents,
      updateFilterValue,
      updateSortValue,
      selectItem,
      inSelectedIds,
      updateSelectedToPay,
      resetFilters,
      updateRouters,
      getFiltersData
    } = useFinancialOutstandingBalancesStore(context);

    const popupData = ref({
      profileId: 0,
      eventId: 0,
      show: false
    });

    const closePaymentModal = (options: any) => {
      if (options.clearSelectedToPay != undefined) {
        pageData.value.selectedToPay = [];
      }
      popupData.value.show = false;
      resetFilters();
      loadData(1);
    };

    const setIsPaying = (status: boolean) => {
      pageData.value.paying = status;
    };

    const checkIsFamily = async () => {
      const selected = pageData.value.selectedToPay;
      if (selected.length > 0) {
        try {
          let totalCost = 0;
          for (const i in selected) {
            const item: any = selected[i];
            if ((item.sourceType || 1) == 1) {
              // total of registration cost
              if (item.pRegistrationStep == 1) {
                totalCost += item.participantCost;

                // if using scholarship as a discount before
                if (
                  (item.paidAmount || 0) == 0 &&
                  (item.participantDiscountCost || 0) > 0
                ) {
                  totalCost -= item.participantDiscountCost;
                }
              } else if (item.pRegistrationStep > 1) {
                if (item.participants && item.participants.length) {
                  let totalBalance = 0;
                  for (const p of item.participants) {
                    totalBalance += p.balance || 0;
                  }
                  totalBalance = parseFloat(totalBalance.toFixed(2));
                  totalCost += totalBalance;
                } else {
                  totalCost += item.balance;
                }
              }
            } else if ((item.sourceType || 1) == 2) {
              totalCost += parseFloat(item.cost || 0) || 0;
            }
          }
          totalCost = parseFloat(totalCost.toFixed(2));

          if (totalCost > 0) {
            const eventIds = selected.map((item: any) => {
              let tempIds = item.eventId || 0;
              if (item.participants && item.participants.length) {
                tempIds = item.participants
                  .map((p: any) => p.eventId)
                  .join(",");
              }
              return tempIds;
            });

            const requestObj = {
              eventIds: eventIds.join(","),
              total: totalCost,
              hasDeposit: 0,
              participantsJSON: JSON.stringify(
                selected.map((item: any) => {
                  let total = 0;
                  let additionalCosts: any = [];
                  let participantIds = [];
                  let profileIds = [];
                  if (item.sourceType == 2) {
                    // fund bucket
                    total = parseFloat(item.cost);
                    additionalCosts = [];
                  } else {
                    // normal registrations
                    if (item.pRegistrationStep == 1) {
                      total = item.participantCost;
                      participantIds.push(item.participantId);
                      profileIds.push(item.profileId);

                      // if using scholarship as a discount before
                      if (
                        (item.paidAmount || 0) == 0 &&
                        (item.participantDiscountCost || 0) > 0
                      ) {
                        total -= item.participantDiscountCost;
                      }
                    } else if (item.pRegistrationStep > 1) {
                      if (item.participants && item.participants.length) {
                        let totalBalance = 0;
                        for (const p of item.participants) {
                          totalBalance += p.balance || 0;
                        }
                        totalBalance = parseFloat(totalBalance.toFixed(2));
                        total = totalBalance;
                        participantIds = item.participants.map(
                          (p: any) => p.participantId
                        );
                        profileIds = item.participants.map(
                          (p: any) => p.profileId
                        );
                      } else {
                        total = item.balance;
                        participantIds.push(item.participantId);
                        profileIds.push(item.profileId);
                      }
                    }

                    additionalCosts =
                      item.pRegistrationStep > 1
                        ? []
                        : (item.additionalCosts || []).map((item: any) => ({
                            serviceName: item.serviceName,
                            cost: item.cost,
                            chargeMonthly: item.chargeMonthly || 0
                          }));

                    // add total funds
                    if (
                      item.pRegistrationStep == 1 &&
                      (item.totalFunds || 0) > 0
                    ) {
                      additionalCosts.push({
                        serviceName: "Total Camp Store Funds",
                        cost: item.totalFunds,
                        isTotalFunds: true
                      });
                    }
                  }

                  return {
                    sourceType: item.sourceType,
                    eventId: item.eventId,
                    pTypeId: item.participantTypeId || 0,
                    participantIds: participantIds.join(","),
                    profileIds: profileIds.join(","),
                    total,
                    additionalCosts
                  };
                })
              )
            };

            const response = await ApiHelper.callApi(
              "get",
              `/balances/paymentInfo`,
              {},
              requestObj
            );

            if (response.status == 1) {
              popupData.value.show = true;
            } else {
              const errorCode = response.errorCode || "";
              if (errorCode == "DIFF_FAMILY") {
                ApiHelper.showErrorMessage(
                  "You selected registrations in different families. Please select registrations in same family",
                  "Oops"
                );
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    return {
      loadData,
      popupData,
      pageData,
      // headerData,
      // pager
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      setActiveFilterTab,
      closeFilterTab,
      selectAllEvents,
      resetEvents,
      updateFilterValue,
      updateSortValue,
      selectItem,
      ApiHelper,
      inSelectedIds,
      updateSelectedToPay,
      closePaymentModal,
      setIsPaying,
      updateRouters,
      getFiltersData,
      checkIsFamily
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
