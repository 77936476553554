import { render, staticRenderFns } from "./FormTextarea.vue?vue&type=template&id=23d1e1fe&scoped=true&"
import script from "./FormTextarea.vue?vue&type=script&lang=ts&"
export * from "./FormTextarea.vue?vue&type=script&lang=ts&"
import style0 from "./FormTextarea.vue?vue&type=style&index=0&id=23d1e1fe&lang=scss&scoped=true&"
import style1 from "./FormTextarea.vue?vue&type=style&index=1&id=23d1e1fe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d1e1fe",
  null
  
)

export default component.exports