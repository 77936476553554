import { ref } from "@vue/composition-api";
import { ApiHelper, ProfileHelper } from "@/helpers";
import { FormSelect, FormText, PagerItem, SelectOption } from "@/types";
import Vue from "vue";
import { dollarFormat, getMedicationStatusText } from "../../helpers/ApiHelper";

export function useProfileMedicalsStore(context: any) {
  const query = context.root.$route.query;
  const selectedProfileId = parseInt(context.root.$route.params.profileId) || 0;
  const selectedMedicalId = ref(0);
  const parentProfile = ref({
    profileId: 0,
    firstName: ""
  });
  const sidebarData = ref({
    isLoading: true,
    logo: "",
    name: "",
    profileId: 0,
    participantId: 0,
    status: 0,
    eventId: 0,
    eventName: "",
    pTypeName: "",
    paidAmount: 0,
    paidAmountFormatted: "",
    financials: {
      totalPaid: 0
    }
  });
  const headerData = ref({
    title: "",
    subTitle: ""
  });
  const pageData = ref<{
    isLoading: boolean;
    isAddNewSuccess: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      route: SelectOption[];
      frequency: SelectOption[];
      incidentType: SelectOption[];
      symptoms: SelectOption[];
      name: string;
      user: string;
      type: string;
      summary: string;
      dateEntered: string;
      minDateEntered: string;
      maxDateEntered: string;
      dispenseMethods: SelectOption[];
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    paying: boolean;
    transferVisible: boolean;
    searchSymptoms: Function;
    searchSymptomsLoading: boolean;
    searchSymptomsValue: {
      value: string;
    };
    sendEmailVisible: boolean;
    parentEmail: FormText;
    medicalOfficeEmail: FormText;
    sendEmailOptions: FormSelect;
    allergiesNotifyVisible: boolean;
    allergies: any;
    notifiedAllergies: number;
  }>({
    isLoading: true,
    isAddNewSuccess: false,
    skip: 0,
    take: 8,
    activeTab: "",
    sort: {
      order: context.root.$route.query.order || "9",
      direction: context.root.$route.query.direction || "2"
    },
    filter: {
      route: [],
      frequency: [],
      incidentType: [],
      symptoms: [],
      name: context.root.$route.query.name || "",
      user: context.root.$route.query.user || "",
      type: context.root.$route.query.type || "1",
      summary: "",
      dateEntered: "",
      minDateEntered: query.minDateEntered || "",
      maxDateEntered: query.maxDateEntered || "",
      dispenseMethods: []
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    paying: false,
    transferVisible: false,
    searchSymptomsLoading: false,
    searchSymptomsValue: {
      value: ""
    },
    searchSymptoms: async (searchValue: string) => {
      pageData.value.searchSymptomsLoading = true;
      if (searchValue == "") {
        pageData.value.filter.symptoms = await ApiHelper.getSymptomOptions({
          getAll: 1
        });
      } else {
        pageData.value.filter.symptoms = await ApiHelper.getSymptomOptions({
          symptomName: searchValue
        });
      }
      const profileIds = (context.root.$route.query.symptom || "")
        .split(",")
        .map((id: string) => parseInt(id));
      pageData.value.filter.symptoms = pageData.value.filter.symptoms.map(
        (item: any) => ({
          ...item,
          selected: profileIds.includes(item.id)
        })
      );
      pageData.value.searchSymptomsLoading = false;
    },
    sendEmailVisible: false,
    parentEmail: {
      error: "",
      label: "Parent email",
      placeholder: "Parent email",
      type: "text",
      value: "",
      style: "custom",
      disabled: true,
      required: true
    },
    medicalOfficeEmail: {
      error: "",
      label: "Medical office email",
      placeholder: "Medical office email",
      type: "text",
      value: "",
      style: "custom",
      required: true
    },
    sendEmailOptions: {
      error: "",
      label: "Send email to",
      placeholder: "",
      type: "select",
      value: "1",
      style: "custom",
      required: true,
      options: [
        {
          id: "1",
          text: "Send to parent"
        },
        {
          id: "2",
          text: "Send to medical office"
        }
      ]
    },
    allergiesNotifyVisible: false,
    allergies: [],
    notifiedAllergies: 0
  });
  const isArchived = ref(false);

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: context.root.$route.name,
        query: {
          page: pageData.value.pager.page + "" || undefined,
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          tab: context.root.$route.query.tab,
          type: pageData.value.filter.type || undefined,
          name: pageData.value.filter.name || undefined,
          user: pageData.value.filter.user || undefined,
          route:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.route
            ) || undefined,
          symptom:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.symptoms
            ) || undefined,
          searchSymptoms: pageData.value.searchSymptomsValue.value || undefined,
          frequency:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.frequency
            ) || undefined,
          incidentType:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.incidentType
            ) || undefined,
          participantId: context.root.$route.query.participantId,
          eventId: context.root.$route.query.eventId,
          minDateEntered: pageData.value.filter.minDateEntered || "",
          maxDateEntered: pageData.value.filter.maxDateEntered || "",
          dispenseMethods:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.dispenseMethods
            ) || undefined,
          notifiedAllergies: pageData.value.notifiedAllergies || 0
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  };

  const loadList = async () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;

    const selectedParticipantId = context.root.$route.query.participantId
      ? Number.parseInt(context.root.$route.query.participantId, 10)
      : undefined;
    const result = await ApiHelper.callApi(
      "get",
      "/medicals",
      {},
      {
        skip: pageData.value.skip,
        take: pageData.value.take,
        order: parseInt(pageData.value.sort.order),
        direction: parseInt(pageData.value.sort.direction),
        profileId: selectedProfileId,
        participantId: selectedParticipantId,
        type: parseInt(context.root.$route.query.type) || 1,
        key: pageData.value.filter.name || "",
        user: pageData.value.filter.user || "",
        symptom: context.root.$route.query.symptom || "",
        route: context.root.$route.query.route || "",
        frequency: context.root.$route.query.frequency || "",
        incidentType: context.root.$route.query.incidentType || "",
        exportDate: pageData.value.filter.minDateEntered || "",
        exportToDate: pageData.value.filter.maxDateEntered || "",
        dispenseMethods: context.root.$route.query.dispenseMethods || ""
      }
    );
    ApiHelper.setDataLoading(false);
    pageData.value.isLoading = false;
    if (result.status === 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.pager.totalPages = Math.ceil(
        totalCount / pageData.value.take
      );
      pageData.value.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      const items = result.data.items || [];
      pageData.value.items = items.map((item: any, key: number) => {
        const addTopPopOverToolTip =
          items.length > 4 && key > items.length - 4 ? true : false;
        item.addTopPopOverToolTip = addTopPopOverToolTip;
        let summary = item.medicalName ? item.medicalName : item.medicationName;
        if (summary && item.notes) {
          summary = summary + ", ";
        }
        item.summary = summary + (item.notes || "");
        return item;
      });
    } else if (result.status === 401) {
      ApiHelper.gotoPage(context, { name: "Login" });
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
    }
  };

  const loadData = async (page: number) => {
    if (pageData.value.pager.page !== page) {
      pageData.value.pager.page = page;
      updateRouters();
    } else {
      await loadList();
    }
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };
  // filters
  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
    if (pageData.value.activeTab == "symptom") {
      if (pageData.value.filter.symptoms.length === 0) {
        await pageData.value.searchSymptoms(
          pageData.value.searchSymptomsValue.value
        );
      }
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "name":
        pageData.value.filter.name = value;
        break;
      case "user":
        pageData.value.filter.user = value;
        break;
      case "summary":
        pageData.value.filter.summary = value;
        break;
      case "dateEntered": {
        pageData.value.pager.page = 1;
        pageData.value.filter.dateEntered = value;
        const dateArr = value.split("-");
        let minDate = dateArr[0];
        if (minDate) {
          minDate = `${minDate.substring(0, 4)}-${minDate.substring(
            4,
            6
          )}-${minDate.substring(6, 8)}`;
        }
        pageData.value.filter.minDateEntered = minDate;
        let maxDate = dateArr[1] !== undefined ? dateArr[1] : "";
        if (maxDate) {
          maxDate = `${maxDate.substring(0, 4)}-${maxDate.substring(
            4,
            6
          )}-${maxDate.substring(6, 8)}`;
        }
        pageData.value.filter.maxDateEntered = maxDate;
        break;
      }
    }

    pageData.value.pager.page = 1;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.name !== "") {
      filters.push({
        label: "NAME",
        key: pageData.value.filter.name + Math.random(),
        value: pageData.value.filter.name,
        reset: () => {
          pageData.value.filter.name = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    if (pageData.value.filter.summary !== "") {
      filters.push({
        label: "SUMMARY",
        key: pageData.value.filter.summary + Math.random(),
        value: pageData.value.filter.summary,
        reset: () => {
          pageData.value.filter.summary = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    const selectedRoutes = pageData.value.filter.route
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedRoutes.length > 0) {
      filters.push({
        label: "ROUTE",
        key: selectedRoutes.join("-") + Math.random(),
        value: selectedRoutes.join(", "),
        reset: () => {
          pageData.value.filter.route.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    const selectedSymptoms = pageData.value.filter.symptoms
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedSymptoms.length > 0) {
      filters.push({
        label: "SYMPTOM",
        key: selectedSymptoms.join("-") + Math.random(),
        value: selectedSymptoms.join(", "),
        reset: () => {
          pageData.value.filter.symptoms.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.user !== "") {
      filters.push({
        label: "USER",
        key: pageData.value.filter.user + Math.random(),
        value: pageData.value.filter.user,
        reset: () => {
          pageData.value.filter.user = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedFrequencies = pageData.value.filter.frequency
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedFrequencies.length > 0) {
      filters.push({
        label: "FREQUENCY",
        key: selectedFrequencies.join("-") + Math.random(),
        value: selectedFrequencies.join(", "),
        reset: () => {
          pageData.value.filter.frequency.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedIncidentTypes = pageData.value.filter.incidentType
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedIncidentTypes.length > 0) {
      filters.push({
        label: "INCIDENT TYPE",
        key: selectedIncidentTypes.join("-") + Math.random(),
        value: selectedIncidentTypes.join(", "),
        reset: () => {
          pageData.value.filter.incidentType.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (
      pageData.value.filter.dateEntered !== "" &&
      pageData.value.filter.dateEntered !== "-"
    ) {
      const arr = pageData.value.filter.dateEntered.split("-");
      let displayValue = "";
      if (arr.length === 2) {
        displayValue =
          (arr[0] != ""
            ? ApiHelper.convertYYYYMMDDtoDDMMYYString(arr[0])
            : "") +
          " - " +
          (arr[1] != "" ? ApiHelper.convertYYYYMMDDtoDDMMYYString(arr[1]) : "");
      }

      filters.push({
        label: "DATE ENTERED",
        key: pageData.value.filter.dateEntered + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.dateEntered = "";
          pageData.value.filter.minDateEntered = "";
          pageData.value.filter.maxDateEntered = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    // dispense methods
    const selectedDispense = pageData.value.filter.dispenseMethods
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedDispense.length > 0) {
      filters.push({
        label: "DISPENSE METHODS",
        key: selectedDispense.join("-") + Math.random(),
        value: selectedDispense.join(", "),
        reset: () => {
          pageData.value.filter.dispenseMethods.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const updateMedicationStatus = async (
    item: any,
    statusOption: any,
    callBack?: any
  ) => {
    const medicalId = item.medicalId;
    if (item.status == statusOption.value || !medicalId) {
      if (callBack) {
        callBack();
      }
      return;
    }

    const confirmMessage = `Are you sure you want to mark this medication as <strong>${ApiHelper.getMedicationStatusText(
      statusOption.value
    ).toLocaleLowerCase()}</strong>?`;
    const confirm = await Vue.swal({
      html: confirmMessage,
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    });
    if (!confirm.isConfirmed) {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      return;
    }

    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "patch",
      "/medicals/" + medicalId + "/status",
      {
        medicationStatus: statusOption.value
      },
      {}
    );

    if (result.status === 1) {
      ApiHelper.setDataLoading(false);
      if (callBack) {
        callBack();
      }
      item.statusText = getMedicationStatusText(item.status);
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      ApiHelper.setDataLoading(false);
    }
  };

  const sendIncidentEmail = async () => {
    const sendTo = pageData.value.sendEmailOptions.value;
    const email =
      sendTo == "1"
        ? pageData.value.parentEmail.value
        : pageData.value.medicalOfficeEmail.value;
    if (email == "") {
      const message = "Email is required!";
      if (sendTo == "1") {
        pageData.value.parentEmail.error = message;
      } else {
        pageData.value.medicalOfficeEmail.error = message;
      }
      return;
    }

    if (!email || !ApiHelper.validateEmail(email)) {
      const message = "Email is invalid!";
      if (sendTo == "1") {
        pageData.value.parentEmail.error = message;
      } else {
        pageData.value.medicalOfficeEmail.error = message;
      }

      return;
    }

    ApiHelper.setDataLoading(true);
    const requestObject: any = {
      sendIncidentToEmail: email,
      medicalId: selectedMedicalId.value
    };
    if (sendTo == "1") {
      requestObject.parentProfileId = parentProfile.value.profileId || 0;
      requestObject.parentFirstName = parentProfile.value.firstName || "";
    }
    const result = await ApiHelper.callApi(
      "post",
      "/medicals/sendIncidentEmail",
      requestObject
    );
    if (result.status == 1) {
      ApiHelper.showSuccessMessage(`Sent email to ${email}`);
      pageData.value.sendEmailVisible = false;
    }
    ApiHelper.setDataLoading(false);
  };

  const exportPdf = async (medicalId: number) => {
    ApiHelper.setDataLoading(true);
    const requestObject: any = {
      medicalId: medicalId
    };
    const result = await ApiHelper.callApi(
      "post",
      "/medicals/exportPdf",
      requestObject
    );
    ApiHelper.setDataLoading(false);
    if (result.status == 1) {
      const data = result.data || {};
      const pdfUrl = data.pdfUrl || "";
      if (pdfUrl) {
        window.open(pdfUrl, "_blank")!.focus();
      }
      ApiHelper.showSuccessMessage(`Exported PDF`);
    } else {
      ApiHelper.showErrorMessage(result.message);
    }
  };

  const showSendIncidentEmail = async (
    profileId: number,
    medicalId: number
  ) => {
    // get parent info of this profile
    if (!profileId) return;

    // reset
    parentProfile.value.profileId = 0;
    parentProfile.value.firstName = "";
    pageData.value.parentEmail.value = "";
    pageData.value.parentEmail.error = "";
    pageData.value.medicalOfficeEmail.value = "";
    pageData.value.medicalOfficeEmail.error = "";

    selectedMedicalId.value = medicalId;
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "get",
      "/families/getOwnerByProfile",
      {},
      { profileId }
    );
    if (result.status == 1) {
      const parentEmail = result.data.email || "";
      if (parentEmail) {
        pageData.value.parentEmail.value = parentEmail;
        pageData.value.sendEmailOptions.value = "1";
        parentProfile.value.profileId = result.data.profileId || 0;
        parentProfile.value.firstName = result.data.firstName || "";
      } else {
        // send to medical office
        pageData.value.sendEmailOptions.value = "2";
        pageData.value.sendEmailOptions.options = pageData.value.sendEmailOptions.options.filter(
          item => item.id == "2"
        );
      }
    }
    ApiHelper.setDataLoading(false);
    pageData.value.sendEmailVisible = true;
  };

  const deleteItem = async (item: any, $parent: any) => {
    Vue.swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then((result: any) => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      if (result.isConfirmed) {
        (async () => {
          ApiHelper.setDataLoading(true);
          const result = await ApiHelper.callApi(
            "delete",
            "/medicals/" + item.medicalId,
            {
              profileId: item.profileId
            },
            {}
          );
          ApiHelper.setDataLoading(false);
          if (result.status == 1) {
            pageData.value.isAddNewSuccess = true;
            loadData(1);
            ApiHelper.showSuccessMessage(
              "The medical has been deleted.",
              "Deleted"
            );
            const parent = $parent;
            if (parent.$refs.SidebarProfileRef) {
              parent.$refs.SidebarProfileRef.loadData();
            }
          } else {
            ApiHelper.showErrorMessage(result.message);
          }
        })();
      }
    });
  };

  // init data
  (async () => {
    let dateEntered =
      (query.minDateEntered || "").replace(/-/g, "") +
      "-" +
      (query.maxDateEntered || "").replace(/-/g, "");
    if (dateEntered == "-") {
      dateEntered = "";
    }
    pageData.value.filter.dateEntered = dateEntered;
    pageData.value.notifiedAllergies = parseInt(
      context.root.$route.query.notifiedAllergies || 0
    );

    await loadData(1);
  })();

  (async () => {
    const profileInfo = await ProfileHelper.getProfileInfo(selectedProfileId);
    isArchived.value = profileInfo.status === 2;
  })();

  (async () => {
    const participantId =
      Number.parseInt(context.root.$route.query.participantId, 10) || 0;
    if (participantId > 0) {
      const result = await ApiHelper.callApi(
        "get",
        "/participants/" + participantId,
        {},
        {}
      );
      if (result.status === 1) {
        headerData.value.subTitle =
          ApiHelper.getFullName(
            result.data.firstName || "",
            result.data.lastName || ""
          ) +
            " - " +
            result.data.eventName || "";
        sidebarData.value.name = result.data.eventName;
        sidebarData.value.profileId = result.data.profileId || 0;
        sidebarData.value.participantId = result.data.participantId || 0;
        sidebarData.value.eventId = result.data.eventId || 0;
        sidebarData.value.eventName = result.data.eventName || "";
        sidebarData.value.pTypeName = result.data.participantTypeName || "";
        sidebarData.value.status = result.data.participantStatus || 0;
        sidebarData.value.paidAmount = result.data.totalPaid || 0;
        sidebarData.value.paidAmountFormatted = dollarFormat(
          sidebarData.value.paidAmount
        );
        sidebarData.value.financials = {
          totalPaid: sidebarData.value.paidAmount
        };
        sidebarData.value.isLoading = false;
      }
    } else {
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/" + selectedProfileId + "/info",
        {},
        {}
      );
      if (result.status === 1) {
        headerData.value.subTitle = ApiHelper.getFullName(
          result.data.firstName || "",
          result.data.lastName || ""
        );
      }
    }
  })();

  (async () => {
    pageData.value.filter.route = ApiHelper.getMedicationRouteOptions();
    const routeIds = (context.root.$route.query.route || "").split(",");
    pageData.value.filter.route.forEach((item: any) => {
      if (routeIds.indexOf(item.id) > -1) {
        item.selected = true;
      }
    });

    pageData.value.filter.frequency = ApiHelper.getFrequencyOptions();
    const frequencyIds = (context.root.$route.query.frequency || "").split(",");
    pageData.value.filter.frequency.forEach((item: any) => {
      if (frequencyIds.indexOf(item.id) > -1) {
        item.selected = true;
      }
    });

    pageData.value.filter.incidentType = ApiHelper.getIncidentTypeOptions();
    const incidentTypeIds = (
      context.root.$route.query.incidentType || ""
    ).split(",");
    pageData.value.filter.incidentType.forEach((item: any) => {
      if (incidentTypeIds.indexOf(item.id) > -1) {
        item.selected = true;
      }
    });
  })();

  (async () => {
    pageData.value.searchSymptomsValue.value =
      context.root.$route.query.searchSymptoms || "";
    if (context.root.$route.query.symptom) {
      await pageData.value.searchSymptoms(
        pageData.value.searchSymptomsValue.value
      );
    }

    // dispense methods
    pageData.value.filter.dispenseMethods = await ApiHelper.getMedicationDispenseMethods();
    const dispenseMethodIds = (
      context.root.$route.query.dispenseMethods || ""
    ).split(",");
    for (const item of pageData.value.filter.dispenseMethods) {
      if (dispenseMethodIds.includes(`${item.id}`)) {
        item.selected = true;
      }
    }
  })();

  (async () => {
    const notifiedAllergies = parseInt(
      `${context.root.$route.query.notifiedAllergies || 0}`
    );
    if (notifiedAllergies) return;

    const participantId = parseInt(
      `${context.root.$route.query.participantId || 0}`
    );
    if (!participantId) return;

    const result = await ApiHelper.callApi(
      "get",
      "/reports/allergies",
      {},
      {
        participantId
      }
    );

    if (result.status === 1) {
      const items = result.data.items || [];
      if (items.length) {
        pageData.value.allergies = items;
        pageData.value.allergiesNotifyVisible = true;
        pageData.value.notifiedAllergies = 1;
      }
    }
  })();

  return {
    updateRouters,
    sidebarData,
    isArchived,
    // sort
    updateSortValue,
    headerData,
    pageData,
    // --- pager
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    // --- filters
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab,
    // actions
    deleteItem,
    updateMedicationStatus,
    sendIncidentEmail,
    exportPdf,
    showSendIncidentEmail
  };
}
