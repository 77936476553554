























































import { defineComponent, ref } from "@vue/composition-api";
import moment from "moment";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "DateSelect",
  components: {
    //
  },
  props: {
    strDate: {
      type: String,
      default: ""
    },
    uuid: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isDob: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dayVal = ref("");
    const monthVal = ref("");
    const yearVal = ref("");
    const days: any = ref([]);
    const months: any = ref([]);
    const years: any = ref([]);

    const dateChange = () => {
      // change days list based on month/year
      const daysInMonth = moment(
        `${yearVal.value}-${monthVal.value}`,
        "YYYY-MM"
      ).daysInMonth();

      days.value = [];
      for (let _y = 1; _y <= daysInMonth; _y++) {
        days.value.push(_y);
      }
      if (dayVal.value != "" && daysInMonth < parseInt(dayVal.value)) {
        dayVal.value = "";
      }
      const newVal =
        yearVal.value && monthVal.value && dayVal.value
          ? `${yearVal.value}-${monthVal.value}-${dayVal.value}`
          : "";

      if (props.isDob) {
        const given = moment(newVal, "YYYY-MM-DD");
        const current = moment().startOf("day");
        const totalDays = moment.duration(given.diff(current)).asDays();
        if (totalDays > 0) {
          ApiHelper.showErrorMessage("DOB is invalid");
          yearVal.value = "";
        } else {
          context.emit("update", newVal);
        }
      } else {
        context.emit("update", newVal);
      }
    };

    // init data
    (async () => {
      const current = moment();
      // for (let _y = 1971; _y <= current.year(); _y++) {
      for (let _y = current.year(); _y >= 1971; _y--) {
        years.value.push(_y);
      }
      for (let _y = 1; _y <= 12; _y++) {
        months.value.push({ month: _y, monthName: _y });
      }
      for (let _y = 1; _y <= 31; _y++) {
        days.value.push(_y);
      }

      if (props.strDate != "") {
        const arr = props.strDate.split("/");
        yearVal.value = arr[0];
        monthVal.value = arr[1];
        dayVal.value = arr[2];
      }
    })();

    return {
      dayVal,
      monthVal,
      yearVal,
      days,
      months,
      years,
      dateChange
    };
  }
});
