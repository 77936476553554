

























import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "DetailTooltip",
  components: {},
  props: {
    list: {
      type: Array,
      defaultValue: []
    },
    loading: Boolean,
    maxRows: Number,
    indexValue: Number,
    type: String,
    nodataText: String,
    addTopPopOverToolTip: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    return {};
  }
});
