import { AlertMessageError, PagerItem, SelectOption } from "@/types";

import { ApiHelper, ProfileHelper } from "@/helpers";
import moment from "moment";
import { ref } from "@vue/composition-api";
import { TimelinesListRows } from "@/helpers/estimateNoOfListRows";
import TimelineTableFilters from "@/types/TimelineTableFilters";

export function useProfileDashboardStore(context: any) {
  const selectedProfileId = parseInt(context.root.$route.params.profileId);
  const selectedEventId =
    parseInt(context.root.$route.query?.eventId || 0) || 0;
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    itemsType: string;
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: TimelineTableFilters;
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    stats: any;
    searchEventsValue: {
      value: string;
    };
    searchEvents: Function;
    searchEventsLoading: boolean;
    searchProfilesValue: {
      value: string;
    };
    searchProfiles: Function;
    searchProfilesLoading: boolean;
  }>({
    isLoading: false,
    skip: 0,
    take: TimelinesListRows(),
    activeTab: "",
    sort: {
      order: context.root.$route.query.order || "4",
      direction: context.root.$route.query.direction || "2"
    },
    filter: {
      type: ApiHelper.getActionTypeOptions(),
      status: ApiHelper.getParticipantStatusOptions({
        getDeniedStatus: true,
        getRefundStatus: true,
        getStartedStatus: true,
        getDeletedStatus: true,
        getTransferedStatus: true
      }),
      unassignedEvent: undefined,
      unassignedProfile: undefined,
      events: [],
      profile: [],
      description: "",
      date:
        (context.root.$route.query.minDate || "") +
        "-" +
        (context.root.$route.query.maxDate || ""),
      minDate: context.root.$route.query.minDate || "",
      maxDate: context.root.$route.query.maxDate || ""
    },
    items: [],
    itemsType: "profile_actions",
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    stats: {
      totalPaid: 0,
      totalPaidFormatted: "$0.00"
    },
    searchEventsLoading: false,
    searchEventsValue: {
      value: ""
    },
    searchEvents: async (searchValue: string) => {
      pageData.value.searchEventsLoading = true;
      const eventIds = (
        context.root.$route.query.event ||
        context.root.$route.query.eventId ||
        ""
      )
        .split(",")
        .map((id: string) => parseInt(id));
      const profileId =
        context.root.$route.params.profileId ||
        context.root.$route.params.profileId ||
        0;
      if (searchValue == "") {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1,
          profileId: profileId
        });
        pageData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS",
          selected: eventIds.includes(0)
        };
      } else {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue,
          profileId: profileId
        });
        if (eventIds.includes(0)) {
          pageData.value.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedEvent = null;
        }
      }
      pageData.value.filter.events = pageData.value.filter.events.map(item => ({
        ...item,
        selected: eventIds.includes(item.id)
      }));
      pageData.value.searchEventsLoading = false;
    },
    searchProfilesLoading: false,
    searchProfilesValue: {
      value: ""
    },
    searchProfiles: async (searchValue: string) => {
      pageData.value.searchProfilesLoading = true;
      const eventIds = (context.root.$route.query.profile || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.profile = await ApiHelper.getEventOptions({
          take: 20
        });
      } else {
        pageData.value.filter.profile = await ApiHelper.getProfileOptions({
          key: searchValue
        });
      }
      pageData.value.filter.profile = pageData.value.filter.profile.map(
        item => ({
          ...item,
          selected: eventIds.includes(item.id)
        })
      );
      pageData.value.searchProfilesLoading = false;
    }
  });

  const errors = ref<AlertMessageError[]>([]);
  const isArchived = ref(false);

  const updateRouters = () => {
    const eventOptions = [];
    if (pageData.value.filter.unassignedEvent) {
      eventOptions.push(pageData.value.filter.unassignedEvent);
    }
    pageData.value.filter.events.map((item: any) => {
      eventOptions.push(item);
    });
    context.root.$router
      .replace({
        name: context.root.$route.name,
        query: {
          page: pageData.value.pager.page + "" || undefined,
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          event:
            ApiHelper.convertSelectedOptionsToString(eventOptions) || undefined,
          status:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.status
            ) || undefined,
          type:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.type
            ) || undefined,
          searchEvents: pageData.value.searchEventsValue.value || undefined,
          minDate: pageData.value.filter.minDate || undefined,
          maxDate: pageData.value.filter.maxDate || undefined
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  };

  const loadList = async () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;

    const filterValues = {
      skip: pageData.value.skip,
      take: pageData.value.take,
      order: parseInt(pageData.value.sort.order),
      direction: parseInt(pageData.value.sort.direction),
      profileId: selectedProfileId,
      eventId: selectedEventId,
      profile: context.root.$route.query.profile || "",
      event: context.root.$route.query.event || "",
      type: context.root.$route.query.type || "",
      status: context.root.$route.query.status || "",
      minDate: pageData.value.filter.minDate,
      maxDate: pageData.value.filter.maxDate
    };

    const result = await ApiHelper.callApi(
      "get",
      "/timelines",
      {},
      filterValues
    );
    ApiHelper.setDataLoading(false);
    pageData.value.isLoading = false;
    if (result.status == 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.pager.totalPages = Math.ceil(
        totalCount / pageData.value.take
      );
      pageData.value.pager.total = result.data.totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      const list = result.data.timelines.map((item: any) => {
        return {
          id: item.actiontype + "" + item.profileId + "" + item.participantId,
          isChecked: false,
          atid: item.atid || 0,
          actiontype: item.actiontype || "",
          actiondesc: (item.actiondesc || "").replace(
            "Got payment transfer ",
            "Received transfer of "
          ),
          actionname: item.actionname,
          profileId: item.profileId || 0,
          fullname: ApiHelper.getFullName(item.firstName, item.lastName),
          userId: item.profileId || 0,
          username: item.username || "",
          userFullName: ApiHelper.getFullName(
            item.userFirstName,
            item.userLastName
          ),
          participantId: item.participantId || "",
          participantStatus: item.participantStatus || 0,
          actionTs: item.actionTs
            ? moment(item.actionTs).format("MM/DD/YYYY")
            : "",
          actionTsFormatted: item.actionTsFormatted,
          eventId: item.eventId,
          eventName: item.eventName,
          isNew: item.isNew,
          actionId: item.actionId || 0
        };
      });
      pageData.value.items = list;
    }
  };

  const loadData = async (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page) || 1;
    updateRouters();
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = event.target.value;
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const toggleHeadActions = () => {
    pageData.value.showHeadActions = !pageData.value.showHeadActions;
  };

  const hideHeadActions = () => {
    pageData.value.showHeadActions = false;
  };

  const getCheckedItems = () => {
    return pageData.value.items.filter(item => item.isChecked);
  };

  // filter functions
  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "description":
        pageData.value.filter.description = value;
        break;
      case "date": {
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.filter.minDate =
          dateArr[0] !== undefined ? dateArr[0] : "";
        pageData.value.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];
    const selectedTypes = pageData.value.filter.type
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedTypes.length > 0) {
      filters.push({
        label: "TYPE",
        key: selectedTypes.join("-") + Math.random(),
        value: selectedTypes.join(", "),
        reset: () => {
          pageData.value.filter.type.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedStatuses = pageData.value.filter.status
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedStatuses.length > 0) {
      filters.push({
        label: "STATUS",
        key: selectedStatuses.join("-") + Math.random(),
        value: selectedStatuses.join(", "),
        reset: () => {
          pageData.value.filter.status.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.description !== "") {
      filters.push({
        label: "DESCRIPTION",
        key: pageData.value.filter.description + Math.random(),
        value: pageData.value.filter.description,
        reset: () => {
          pageData.value.filter.description = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (
      pageData.value.filter.minDate !== "" ||
      pageData.value.filter.maxDate !== ""
    ) {
      let date = "";
      if (pageData.value.filter.minDate && pageData.value.filter.maxDate) {
        date =
          pageData.value.filter.minDate + "-" + pageData.value.filter.maxDate;
      } else if (pageData.value.filter.minDate) {
        date = pageData.value.filter.minDate + "-";
      } else if (pageData.value.filter.maxDate) {
        date = pageData.value.filter.maxDate + "-";
      }
      const displayValue =
        (pageData.value.filter.minDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.minDate
            )
          : "") +
        " - " +
        (pageData.value.filter.maxDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.maxDate
            )
          : "");

      filters.push({
        label: "DATE",
        key: date + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          updateRouters();
        }
      });
    }

    const selectedEvents: any[] = [];
    if (
      pageData.value.filter.unassignedEvent &&
      pageData.value.filter.unassignedEvent.selected
    ) {
      selectedEvents.push(pageData.value.filter.unassignedEvent.text);
    }
    pageData.value.filter.events
      .filter((item: any) => item.selected)
      .map((item: any) => {
        selectedEvents.push(item.text);
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.filter.unassignedEvent = null;
          pageData.value.filter.events = [];
          pageData.value.searchEventsValue.value = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
    if (pageData.value.activeTab == "events") {
      if (pageData.value.filter.events.length === 0) {
        await pageData.value.searchEvents(
          pageData.value.searchEventsValue.value
        );
      }
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const getProfileStats = async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/profiles/" + selectedProfileId + "/dashboard",
      {},
      {
        // eventId: selectedEventId
      }
    );
    if (result.status == 1) {
      const data = result.data;
      pageData.value.stats = data.dashboard || {};
      pageData.value.stats.totalPaid = data.totalPaid || 0;
      pageData.value.stats.totalPaidFormatted = ApiHelper.dollarFormat(
        data.totalPaid || 0,
        false,
        { useBrackets: true }
      );
      // data.totalPaidFormatted || "$0.00";
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      ApiHelper.gotoPage(context, {
        name: "Profiles"
      });
    }
  };

  const openPreview = async (e: Event) => {
    e.stopPropagation();
    e.preventDefault();

    try {
      // get app modules list
      const result = await ApiHelper.callApi(
        "get",
        `/profiles/${selectedProfileId}/preview`
      );
      if (result.status == 1) {
        const portalLink = result.data.portalLink;
        window.open(portalLink || "", "_blank")!.focus();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // init data
  (async () => {
    const threads = [
      getProfileStats(),
      loadData(1),
      ProfileHelper.getProfileInfo(selectedProfileId)
    ];
    const [stats, timelinesData, profileInfo] = await Promise.all(threads);
    isArchived.value = profileInfo.status === 2;
    context.root.$forceUpdate();
  })();

  (async () => {
    pageData.value.searchEventsValue.value =
      context.root.$route.query.searchEvents || "";
    if (context.root.$route.query.event || context.root.$route.query.eventId) {
      await pageData.value.searchEvents(pageData.value.searchEventsValue.value);
    }
  })();

  (async () => {
    const statusIds = (context.root.$route.query.status || "").split(",");
    pageData.value.filter.status.forEach((item: any) => {
      if (statusIds.includes(item.id)) {
        item.selected = true;
      }
    });
  })();

  (async () => {
    const typeIds = (context.root.$route.query.type || "").split(",");
    pageData.value.filter.type.forEach((item: any) => {
      if (typeIds.includes(item.id)) {
        item.selected = true;
      }
    });
  })();

  return {
    updateSortValue,
    updateRouters,
    isArchived,
    errors,
    pageData,
    // filters
    updateFilterValue,
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    // pager
    gotoPage,
    getCheckedItems,
    togglePagerItems,
    toggleHeadActions,
    hideHeadActions,
    onClickPrev,
    onClickNext,
    onPagerChange,
    openPreview
  };
}
