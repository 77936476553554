


































import Vue from "vue";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import { defineComponent } from "@vue/composition-api";

Vue.use(PerfectScrollbar);

export default defineComponent({
  name: "VScroller",
  props: {
    options: {
      type: Object,
      default: () => ({
        wheelPropagation: false,
        suppressScrollX: true,
        minScrollbarLength: 15
      })
    },
    onScroll: Function,
    isMW1300: {
      type: Boolean,
      default: true
    },
    fadeScroll: {
      type: Boolean,
      default: true
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    paddingRight: {
      type: Boolean,
      default: false
    }
  }
});
