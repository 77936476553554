






































































import { defineComponent, ref } from "@vue/composition-api";
import $ from "jquery";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";

type option = {
  id: number;
  text: string;
  data: any;
};
export default defineComponent({
  name: "FormTagAutoComplete",
  components: {
    VScroller
  },
  props: {
    data: Object,
    placeholder: String,
    options: Array,
    inputClass: String,
    tags: {
      type: Array,
      defaultValue: []
    },
    emptySearchOnSelect: {
      type: Boolean,
      default: false
    },
    showError: {
      type: Boolean,
      default: true
    },
    allowAddNew: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    alwaysEmitInput: {
      type: Boolean,
      default: false
    },
    initValue: {
      type: String,
      default: ""
    },
    suggestOnFocus: {
      type: Boolean,
      default: false
    },
    endItemMessage: {
      type: String,
      default: ""
    },
    nowrap: {
      type: Boolean,
      default: false
    },
    showLineTitle: {
      type: Boolean,
      default: false
    },
    isACILoading: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    // allow arrow up/down function
    const el = this.$el;
    $(el)
      .find(".search-box")
      .off("keydown")
      .keydown(function(e) {
        if (e.which == 40 || e.which == 38) {
          e.preventDefault();
        }
        const results = $(el).find(".results-box");
        if (results.find("li").length) {
          const current = results.find("li.current");
          if (e.which == 40) {
            if (!current.length || !current.next().length) {
              current.removeClass("current");
              results.find("li:first-child").addClass("current");
            } else {
              current
                .removeClass("current")
                .next()
                .addClass("current");
            }
          } else if (e.which == 38) {
            if (!current.length || !current.prev().length) {
              current.removeClass("current");
              results.find("li:last-child").addClass("current");
            } else {
              current
                .removeClass("current")
                .prev()
                .addClass("current");
            }
          }
        }
      });
  },
  setup(props, context) {
    const key = ref<string>("");
    const timer = ref<any>(null);
    const show = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const hint = ref<string>("");
    const focusing = ref<boolean>(false);

    const onInput = async () => {
      if (timer.value) {
        clearTimeout(timer.value);
      }
      if (key.value == "") {
        show.value = false;
        hint.value = "";
        if (props.alwaysEmitInput) {
          loading.value = true;
          await context.emit("input", key.value);
          loading.value = false;
          // show a default suggested list when empty search box
          if (props.suggestOnFocus && focusing.value == true) {
            show.value = true;
          }
        }
      } else {
        show.value = false;
        timer.value = setTimeout(async () => {
          if (key.value.length < 3) {
            hint.value = "Please type at least 3 characters to search";
          } else {
            hint.value = "";
            loading.value = true;
            await context.emit("input", key.value);
            loading.value = false;
          }
          if (focusing.value == true) {
            show.value = true;
          }
        }, 300);
      }
    };

    const onFocus = async () => {
      focusing.value = true;
      if (props.suggestOnFocus) {
        if (timer.value) {
          clearTimeout(timer.value);
        }
        show.value = false;
        hint.value = "";
        await context.emit("focus", key.value);
        if (focusing.value == true) {
          show.value = true;
        }
      }
    };

    const onSelect = (item: any) => {
      if (props.emptySearchOnSelect) {
        key.value = "";
        $(context.root.$el)
          .find(".search-box")
          .focus();
      } else {
        key.value = item.text;
      }
      show.value = false;
      context.emit("select", item);
    };

    const onRemove = (item: any) => {
      context.emit("remove", item);
    };

    const onClickOutside = () => {
      show.value = false;
      hint.value = "";
    };

    const onTab = () => {
      show.value = false;
      focusing.value = false;
    };

    const selectLineItem = () => {
      const current = $(context.root.$el).find(".results-box li.current");
      if (current.length) {
        const item = props.options?.find(
          (item: any) => item.id == current.data("id")
        );
        if (item) {
          onSelect(item);
        }
      }
    };

    // init data
    (async () => {
      key.value = props.initValue != "" ? props.initValue : "";
      show.value = false;
    })();

    return {
      stripTags: ApiHelper.stripTags,
      onInput,
      key,
      onClickOutside,
      show,
      onRemove,
      onSelect,
      loading,
      hint,
      selectLineItem,
      onFocus,
      onTab
    };
  }
});
