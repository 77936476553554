













































































































































































































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import HeaderFilters from "@/components/Common/HeaderFilters.vue";
import Pager from "@/components/Common/Pager.vue";
import Modal from "@/components/Common/Modal.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import HeaderActions from "@/components/Common/HeaderActions.vue";
import { ApiHelper } from "@/helpers/index";
import BackButton from "@/components/Common/BackButton.vue";
import { useEventMedicalStore } from "@/stores/Event/EventMedicalStore";
import FormButton from "@/components/Form/FormButton.vue";
import FormTags from "@/components/Form/FormTags.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import NavEventDetails from "@/components/Event/NavEventDetails.vue";
import FormDatePicker3 from "@/components/Form/FormDatePicker3.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";

export default defineComponent({
  name: "EventMedicalPage",
  components: {
    BackButton,
    HeaderEventDetails,
    SidebarEventDetails,
    VAlertMessage,
    Loading,
    Modal,
    Select2,
    HeaderFilters,
    HeaderActions,
    Avatar,
    FilterCheckboxesColumn,
    FilterSearchColumn,
    FilterNumberRangeColumn,
    Pager,
    FormButton,
    FormTags,
    FormSelect,
    NavEventDetails,
    FormDatePicker3,
    TableHeaderColumn
  },
  setup(props, context) {
    const {
      updateRouters,
      // sort
      updateSortValue,
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      exportSymptoms,
      suggestSymptom,
      addSymptom,
      removeLastSymptom,
      removeSymptom,
      selectSymptom,
      exportMedications,
      exportByType
    } = useEventMedicalStore(context);

    return {
      updateRouters,
      // sort
      updateSortValue,
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      ApiHelper,
      exportSymptoms,
      suggestSymptom,
      addSymptom,
      removeLastSymptom,
      removeSymptom,
      selectSymptom,
      exportMedications,
      exportByType
    };
  }
});
