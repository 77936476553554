

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { computed, defineComponent, ref } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import { useEventDetailsStore } from "@/stores/Event/EventDetailsStore";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import DropdownParticipantStatus from "@/components/Participant/DropdownParticipantStatus.vue";
import Modal from "@/components/Common/Modal.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";

import { ApiHelper } from "@/helpers";
import VScroller from "@/components/Common/VScroller.vue";
import ConfirmModal from "@/components/Common/ConfirmModal.vue";
import SidebarProfileEvents from "@/components/Profile/SidebarProfileEvents.vue";
import { ParticipantStatus } from "@/helpers/ApiHelper";
import FormCheckboxes from "@/components/Form/FormCheckboxes.vue";
import TransferModal from "@/components/Financial/TransferModal.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import SecurityGroupRowTooltip from "@/components/SecurityGroupRowTooltip.vue";
import PopupEditApplication from "@/components/Participant/PopupEditApplication.vue";
import PopupSendMail from "@/components/Event/PopupSendMail.vue";

export default defineComponent({
  name: "EventDetailsPage",
  components: {
    PopupSendMail,
    TableHeaderColumn,
    VAlertMessage,
    SidebarEventDetails,
    HeaderEventDetails,
    Loading,
    Modal,
    Pager,
    Avatar,
    VScroller,
    Select2,
    AutoCompleteInput,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    FilterSelectColumn,
    FilterAgesColumn,
    FilterDateColumn,
    DropdownParticipantStatus,
    ConfirmModal,
    SidebarProfileEvents,
    FormCheckboxes,
    TransferModal,
    SecurityGroupRowTooltip,
    PopupEditApplication
  },
  setup(props, context) {
    const {
      popupSendMail,
      loadList,
      popupEditApplication,
      selectedEventStatus,
      updateRouters,
      openNewPopup,
      showMergeButton,
      mergeParticipants,
      cabinOptions,
      openPopupAddToCabin,
      selectEvent,
      searchProfiles,
      selectProfile,
      popupNewItem,
      // sort
      updateSortValue,
      pageData,
      showStatusDropdown,
      updateParticipantStatus,
      // filters
      selectAllStatuses,
      resetStatuses,
      selectAllGenders,
      resetGenders,
      selectAllCabins,
      resetCabins,
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      hideHeadActions,
      addToCabin,
      selectAllPTypes,
      resetPTypes,
      isACILoading,
      participantInfo,
      loadData,
      exportCSV,
      userSettings,
      updateParticipantsCustomView,
      getUserSettings,
      toggleCustomize,
      hideCustomize,
      customizeVisible,
      showColumn,
      onApply,
      addPayment,
      showExportOptions
    } = useEventDetailsStore(context);

    const sidebarData = ref(null);
    const showEventsSidebar = async (item: any, headerEventDetails: any) => {
      const eventName = headerEventDetails.pageTitle || "";
      sidebarData.value = { ...item, name: eventName };
    };

    const setIsPaying = (status: boolean) => {
      pageData.value.paying = status;
    };

    const allowSelection = computed(() => {
      const $this: any = context.root;
      if (!$this.$allowedFunctions.includes($this.$userFunctions.EditEvent)) {
        return false;
      }

      return true;
    });

    const reloadData = () => {
      const currentPage = parseInt(`${context.root.$route.query.page || 1}`);
      loadData(currentPage);
    };

    const reUpdateStatusDenied = () => {
      pageData.value.pager.selectedItem.totalPaid = 0;
      updateParticipantStatus(
        pageData.value.pager.selectedItem,
        pageData.value.pager.selectedStatusOption,
        false,
        false,
        true
      );
    };

    const sidebarShowTransfer = (data: any = {}) => {
      pageData.value.transferVisible = true;
      participantInfo.value = data;
    };

    const isTooltipOpen = ref(false);
    // Allergies
    const allergiesTooltipVisibleIndex = ref(-1);
    const allergiesTooltipLoading = ref<boolean>(false);
    const allergiesTooltipList = ref([]);

    const showTooltipAllergies = async (item: any, index: number) => {
      if (!isTooltipOpen.value) {
        allergiesTooltipVisibleIndex.value = index;
        allergiesTooltipLoading.value = true;
        const result = await ApiHelper.callApi(
          "get",
          `/participants/allergies`,
          {},
          {
            getAll: 1,
            profileId: item.profileId,
            participantId: item.participantId
          }
        );
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          allergiesTooltipVisibleIndex.value = -1;
          return false;
        }
        allergiesTooltipList.value = result.data.allergies.map((t: any) => {
          let content = t.content;
          if (t.allergiesCategoryText != "") {
            content += " (";
            content += `${t.allergiesCategoryText}`;
            if (t.allergiesSeverityText != "") {
              content += `, ${t.allergiesSeverityText}`;
            }
            content += ")";
          }

          return {
            id: t.id,
            text: content
          };
        });

        allergiesTooltipLoading.value = false;
        isTooltipOpen.value = true;
      }
    };

    const hideTooltipAllergies = () => {
      isTooltipOpen.value = false;
      allergiesTooltipLoading.value = false;
      allergiesTooltipVisibleIndex.value = -1;
    };

    // Todos
    const todosTooltipVisibleIndex = ref(-1);
    const todosTooltipLoading = ref<boolean>(false);
    const todosTooltipList = ref([]);

    const showTooltipTodos = async (item: any, index: number) => {
      if (!isTooltipOpen.value) {
        todosTooltipVisibleIndex.value = index;
        todosTooltipLoading.value = true;
        const result = await ApiHelper.callApi(
          "get",
          `/participants/todos`,
          {},
          {
            participantId: item.id,
            profileId: item.profileId,
            status: "0,1,2"
          }
        );
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          todosTooltipVisibleIndex.value = -1;
          return false;
        }

        todosTooltipList.value = result.data.todos.map((func: any) => {
          let statusText = "";
          switch (func.linkEpfStatus) {
            case 2:
              statusText = "Completed";
              break;
            case 1:
              statusText = "Pending";
              break;
            default:
              statusText = "Incomplete";
              break;
          }
          return {
            id: func.formId,
            text: `${func.formName} (${statusText})`
          };
        });

        todosTooltipLoading.value = false;
        isTooltipOpen.value = true;
      }
    };

    const hideTooltipTodos = () => {
      isTooltipOpen.value = false;
      todosTooltipLoading.value = false;
      todosTooltipVisibleIndex.value = -1;
    };

    const isExistedCabinName = computed(() => {
      const sameName = cabinOptions.value.find(item => {
        return (
          item.text.toUpperCase() == pageData.value.addToCabinName.toUpperCase()
        );
      });
      if (sameName) {
        return true;
      }
      return false;
    });

    return {
      popupSendMail,
      isExistedCabinName,
      loadList,
      loadData,
      popupEditApplication,
      selectedEventStatus,
      isTooltipOpen,
      // functions
      allergiesTooltipVisibleIndex,
      allergiesTooltipLoading,
      allergiesTooltipList,
      showTooltipAllergies,
      hideTooltipAllergies,
      // todos
      todosTooltipVisibleIndex,
      todosTooltipLoading,
      todosTooltipList,
      showTooltipTodos,
      hideTooltipTodos,

      updateRouters,
      openNewPopup,
      sidebarData,
      showEventsSidebar,
      showMergeButton,
      mergeParticipants,
      cabinOptions,
      openPopupAddToCabin,
      selectEvent,
      searchProfiles,
      selectProfile,
      popupNewItem,
      // sort
      updateSortValue,
      pageData,
      showStatusDropdown,
      updateParticipantStatus,
      // filters
      selectAllStatuses,
      resetStatuses,
      selectAllGenders,
      resetGenders,
      selectAllCabins,
      resetCabins,
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      hideHeadActions,
      addToCabin,
      selectAllPTypes,
      resetPTypes,
      getFullName: ApiHelper.getFullName,
      isACILoading,
      ParticipantStatus,
      participantInfo,
      setIsPaying,
      allowSelection,
      reloadData,
      reUpdateStatusDenied,
      exportCSV,
      userSettings,
      updateParticipantsCustomView,
      getUserSettings,
      toggleCustomize,
      hideCustomize,
      customizeVisible,
      showColumn,
      sidebarShowTransfer,
      onApply,
      addPayment,
      showExportOptions
    };
  },
  methods: {
    refresh() {
      this.$forceUpdate();
    }
  }
});
