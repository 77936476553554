




















import { defineComponent, ref } from "@vue/composition-api";
import Select2 from "v-select2-component/src/Select2.vue";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "FormSelect2",
  components: {
    Select2
  },
  props: {
    data: Object,
    onChange: Function
  },
  setup(props) {
    const randomId = ref<number>(ApiHelper.randomFormId());
    const doChange = () => {
      if (props.data) {
        props.data.error = "";
      }
      if (props.onChange) {
        props.onChange();
      }
    };
    return {
      randomId: randomId.value.toString(),
      doChange
    };
  }
});
