var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},_vm._l((_vm.tabs),function(item){return _c('router-link',{key:item.label,attrs:{"to":item.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"mr-[55px] pb-6 hover:cursor-pointer",class:{
        'border-b-2-[#670477]': isActive
      },attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(item.label)+" ")])]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }