







































































































































import { defineComponent, ref } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import { ApiHelper } from "@/helpers";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import VScroller from "@/components/Common/VScroller.vue";

export default defineComponent({
  name: "HeaderActions",
  props: {
    type: String,
    getViewOptions: {
      type: Boolean,
      default: true
    }
  },
  components: {
    AutoCompleteInput,
    Loading,
    VScroller
  },
  setup(props, context) {
    const isACILoading = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const viewsListVisible = ref<boolean>(false);
    const componentData = ref<{
      showPin: boolean;
      showSearch: string;
      endItemMessage: string;
      foundItems: any[];
    }>({
      showPin: false,
      showSearch: "",
      endItemMessage: "",
      foundItems: []
    });
    const initValue = ref("");
    const viewName = ref<string>("");
    const viewOptions = ref<any>([]);
    const selectedViewId = ref<number>(0);
    const selectedViewName = ref("Saved views...");

    const boldString = (str: string, substr: string) => {
      // if (str.toLowerCase().indexOf(substr.toLowerCase()) != -1) {
      //   const strRegExp = new RegExp(substr, "g");
      //   return str.replace(strRegExp, "<b>" + substr + "</b>");
      // }
      // return substr;
      if (str.toLowerCase().indexOf(substr.toLowerCase()) == -1) {
        return str;
      }
      const n = str.toUpperCase();
      const q = substr.toUpperCase();
      const x = n.indexOf(q);
      const l = q.length;
      return (
        str.substr(0, x) + "<b>" + str.substr(x, l) + "</b>" + str.substr(x + l)
      );
    };

    const suggestItems = async (key: string, isFocus = false) => {
      if (isFocus && key == "") {
        const globalSearch: any = context.refs.globalSearch;
        key = globalSearch?.key || "";
      }

      initValue.value = key;
      isACILoading.value = true;
      try {
        const result = await ApiHelper.callApi(
          "get",
          "/entities/items/search",
          {},
          {
            key: key
          }
        );
        if (result.status === 1) {
          const data: any = result.data || [];
          const profiles = (data.profiles || []).map((item: any) => {
            const phone = item.phone || "";
            const email = item.email || "";
            if (phone != "") {
              item.title += `, ${phone}`;
            }
            if (email != "") {
              item.title += `, ${email}`;
            }

            return item;
          });
          const tags = data.tags || [];
          const events = data.events || [];
          componentData.value.foundItems = [
            ...profiles,
            ...tags,
            ...events,
            ...(data.families || [])
          ].map((item: any) => ({
            ...item,
            html: `
              <div class="d-flex justify-content-between">
                <span class="search-result-item">
                  ${boldString(item.title, key)}
                  ${
                    (item.type == "profile" && (item.pActive || 1) == 2) ||
                    (item.type == "event" && (item.status || 1) == 2)
                      ? "<span>(Archived)</span>"
                      : ""
                  }
                </span> 
                <span class="type">${item.type}</span>
              </div>
            `,
            data: item
          }));

          const total = componentData.value.foundItems.length;
          componentData.value.endItemMessage = "";
          if (key == "" && total > 9) {
            componentData.value.endItemMessage = `Top ${total} results are listed, search to see more items`;
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        isACILoading.value = false;
      }
    };

    const selectItem = async (data: any) => {
      let params: any = {};
      if (data.type === "tag") {
        params = {
          name: "Profiles",
          query: {
            tag: data.id.toString()
          }
        };
      }
      if (data.type === "profile") {
        params = {
          name: "ProfileDashboard",
          params: {
            profileId: data.id.toString()
          }
        };
      }
      if (data.type === "event") {
        params = {
          name: "EventOverview",
          params: {
            eventId: data.id.toString()
          }
        };
      }
      if (data.type === "family") {
        params = {
          name: "FamilyDetails",
          params: {
            Id: data.id.toString()
          }
        };
      }

      // go to new page
      componentData.value.showSearch = "";
      ApiHelper.gotoPage(context, params);
    };

    const saveView = async () => {
      if (viewName.value == "") return;

      // specify view type
      const viewType = props.type || "";

      // build params
      const routeQuery = context.root.$route.query;
      // make sure remove viewId
      if (routeQuery.viewId) {
        delete routeQuery.viewId;
      }
      const params = {
        name: context.root.$route.name,
        params: context.root.$route.params,
        query: routeQuery
      };

      try {
        isLoading.value = true;
        const result = await ApiHelper.callApi("post", "/entities/views", {
          viewName: viewName.value,
          type: viewType,
          params: JSON.stringify(params)
        });

        if ((result.status || 0) == 1) {
          ApiHelper.showSuccessMessage(result.message);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await getViews();
          const viewId = result.data.viewId || 0;
          selectedViewId.value = viewId;
          selectedViewName.value = viewName.value;
          viewsListVisible.value = false;
          componentData.value.showPin = false;
        } else {
          await ApiHelper.showErrorMessage(result.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
        viewName.value = "";
      }
    };

    const getViews = async () => {
      try {
        const result = await ApiHelper.callApi("get", "/entities/views");
        if (result.status == 1) {
          viewOptions.value = result.data.views || [];

          // specify selected view
          const viewId = parseInt(`${context.root.$route.query.viewId || 0}`);
          if (viewId > 0) {
            const inList = viewOptions.value.find(
              (item: any) => item.viewId == viewId
            );
            if (inList) {
              selectedViewId.value = viewId;
              selectedViewName.value = inList.viewName;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const loadView = async (viewId: number) => {
      viewsListVisible.value = false;
      if (viewId == 0 || viewId == selectedViewId.value) return;

      selectedViewId.value = viewId;
      // get saved view params
      try {
        const result = await ApiHelper.callApi(
          "get",
          `/entities/views/${selectedViewId.value}`
        );
        if (result.status == 1) {
          const savedView = JSON.parse(result.data?.params || "{}");
          if (savedView.name) {
            if (result.data.viewName) {
              selectedViewName.value = result.data.viewName;
            }
            savedView.query.viewId = selectedViewId.value;
            context.root.$router.push(savedView).catch((error: any) => {
              // do nothing
            });
          }
        } else {
          if (result.message) {
            ApiHelper.showErrorMessage(result.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const toggleViewsList = () => {
      viewsListVisible.value = !viewsListVisible.value;
    };

    const hideViewsList = () => {
      viewsListVisible.value = false;
    };

    const removeView = async (viewId: number) => {
      if (viewId == 0) return;

      const result = await ApiHelper.callApi(
        "delete",
        `/entities/views/${viewId}`
      );
      if (result.status == 1) {
        viewOptions.value = viewOptions.value.filter(
          (item: any) => item.viewId != viewId
        );
        if (selectedViewId.value == viewId) {
          // reset
          selectedViewId.value = 0;
          selectedViewName.value = "Saved views...";
        }
      }
    };

    // init data
    (async () => {
      // if (props.getViewOptions) {
      //   getViews();
      // }
    })();

    return {
      initValue,
      suggestItems,
      selectItem,
      componentData,
      isACILoading,
      viewName,
      saveView,
      isLoading,
      viewOptions,
      selectedViewId,
      selectedViewName,
      loadView,
      toggleViewsList,
      viewsListVisible,
      hideViewsList,
      removeView
    };
  }
});
