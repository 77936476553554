







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import ProfileTabs from "@/components/Profile/ProfileTabs.vue";
import Loading from "@/components/Common/Loading.vue";
import { useMedicalDashboardStore } from "@/stores/Medical/MedicalDashboardStore";
import Pager from "@/components/Common/Pager.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import Header from "@/components/Common/Header.vue";
import Modal from "@/components/Common/Modal.vue";
import FormItem from "@/components/Form/FormItem.vue";
import FormButton from "@/components/Form/FormButton.vue";
import { ApiHelper } from "@/helpers";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormInput from "@/components/Form/FormInput.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import SecurityGroupRowTooltip from "@/components/SecurityGroupRowTooltip.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormDatePicker from "@/components/Form/FormDatePicker.vue";
import VScroller from "@/components/Common/VScroller.vue";
import DispenseMedication from "@/components/DispenseMedication.vue";

export default defineComponent({
  name: "MedicalDashboardPage",
  components: {
    FormSelect,
    FormInput,
    FormItem,
    FormButton,
    ProfileTabs,
    Loading,
    Header,
    Modal,
    Avatar,
    AutoCompleteInput,
    Pager,
    FilterSearchColumn,
    FilterAgesColumn,
    FilterSelectColumn,
    FilterNumberRangeColumn,
    FilterCheckboxesColumn,
    TableHeaderColumn,
    FilterDateColumn,
    SecurityGroupRowTooltip,
    FormTextarea,
    FormDatePicker,
    VScroller,
    DispenseMedication
  },
  setup(props, context) {
    const {
      updateRouters,
      // sort
      updateSortValue,
      pageData,
      // filters
      // pager
      gotoPage,
      onClickPrev,
      onClickNext,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      updateFilterValue,
      // popups
      getFollowUp,
      getMedicalLogs,
      selectAllSymptoms,
      resetSymptoms,
      searchSymptoms,
      updateSelectedSymptoms,
      selectAllEvents,
      resetEvents,
      searchEvents,
      updateSelectedEvents,
      selectAllFamilies,
      resetFamilies,
      searchFamilies,
      updateSelectedFamilies,
      selectAllGenders,
      resetGenders,
      getParticipants,
      selectAllPEvents,
      resetPEvents,
      searchPEvents,
      updateSelectedPEvents,
      selectAllCabins,
      resetCabins,
      searchCabins,
      updateSelectedCabins,
      inSelectedProfileIds,
      exportMedicalLogs,
      showFollowUpModal,
      saveFollowUp,
      changeResolved,
      reloadData
    } = useMedicalDashboardStore(context);

    const isTooltipOpen = ref(false);
    const allergiesTooltipVisibleIndex = ref(-1);
    const allergiesTooltipLoading = ref<boolean>(false);
    const allergiesTooltipList = ref([]);
    const medicationsTooltipVisibleIndex = ref(-1);
    const medicationsTooltipLoading = ref<boolean>(false);
    const medicationsTooltipList = ref([]);

    const showTooltipAllergies = async (item: any, index: number) => {
      if (!isTooltipOpen.value) {
        allergiesTooltipVisibleIndex.value = index;
        allergiesTooltipList.value = (item.allergiesList || "")
          .split("|")
          .map((name: string, i: number) => ({
            id: i,
            text: name
          }));
        isTooltipOpen.value = true;
      }
    };

    const hideTooltipAllergies = () => {
      isTooltipOpen.value = false;
      allergiesTooltipLoading.value = false;
      allergiesTooltipVisibleIndex.value = -1;
    };

    const showTooltipMedications = async (item: any, index: number) => {
      if (!isTooltipOpen.value) {
        medicationsTooltipVisibleIndex.value = index;
        medicationsTooltipList.value = (item.medicationNames || "")
          .split("|")
          .map((name: string, i: number) => ({
            id: i,
            text: name
          }));
        isTooltipOpen.value = true;
      }
    };

    const hideTooltipMedications = () => {
      isTooltipOpen.value = false;
      medicationsTooltipLoading.value = false;
      medicationsTooltipVisibleIndex.value = -1;
    };

    const setIsLoading = (status: boolean) => {
      pageData.value.isLoading = status;
    };

    return {
      updateRouters,
      // sort
      updateSortValue,
      pageData,
      // filters
      // pager
      gotoPage,
      onClickPrev,
      onClickNext,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      updateFilterValue,
      // popups
      ApiHelper,
      getFollowUp,
      getMedicalLogs,
      selectAllSymptoms,
      resetSymptoms,
      searchSymptoms,
      updateSelectedSymptoms,
      selectAllEvents,
      resetEvents,
      searchEvents,
      updateSelectedEvents,
      selectAllFamilies,
      resetFamilies,
      searchFamilies,
      updateSelectedFamilies,
      selectAllGenders,
      resetGenders,
      getParticipants,
      selectAllPEvents,
      resetPEvents,
      searchPEvents,
      updateSelectedPEvents,
      selectAllCabins,
      resetCabins,
      searchCabins,
      updateSelectedCabins,
      showTooltipAllergies,
      hideTooltipAllergies,
      allergiesTooltipVisibleIndex,
      allergiesTooltipLoading,
      allergiesTooltipList,
      showTooltipMedications,
      hideTooltipMedications,
      medicationsTooltipVisibleIndex,
      medicationsTooltipLoading,
      medicationsTooltipList,
      inSelectedProfileIds,
      exportMedicalLogs,
      showFollowUpModal,
      saveFollowUp,
      changeResolved,
      setIsLoading,
      reloadData
    };
  },
  methods: {
    toggleDetails(item: any) {
      item.expanded = !(item.expanded || false);
      this.$forceUpdate();
    }
  }
});
