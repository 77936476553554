


















































































































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import { useReportMedicalStore } from "@/stores/Report/ReportMedicalStore";
import Loading from "@/components/Common/Loading.vue";
import Header from "@/components/Common/Header.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormDatePicker from "@/components/Form/FormDatePicker.vue";
import BackButton from "@/components/Common/BackButton.vue";
import Pager from "@/components/Common/Pager.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import moment from "moment";

export default defineComponent({
  name: "ReportMedicalGeneral",
  components: {
    Loading,
    Header,
    FormSelect,
    FormDatePicker,
    BackButton,
    Pager,
    FilterDateColumn,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    TableHeaderColumn
  },
  setup(props, context) {
    const {
      pageData,
      getMedicalLogs,
      onChangeEvents,
      gotoPage,
      onClickPrev,
      onClickNext,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      updateFilterValue,
      selectAllSymptoms,
      resetSymptoms,
      searchSymptoms,
      updateRouters,
      updateSelectedSymptoms,
      updateSortValue
    } = useReportMedicalStore(context);

    const getReportParams = () => {
      const query = context.root.$route.query;
      return JSON.stringify(query);
    };

    return {
      pageData,
      getMedicalLogs,
      onChangeEvents,
      gotoPage,
      onClickPrev,
      onClickNext,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      updateFilterValue,
      selectAllSymptoms,
      resetSymptoms,
      searchSymptoms,
      ApiHelper,
      updateRouters,
      updateSelectedSymptoms,
      updateSortValue,
      getReportParams
    };
  }
});
