






































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import Vue from "vue";
import $ from "jquery";

export default defineComponent({
  name: "PopupMergeParticipants",
  props: {
    profileIds: {
      type: String,
      defaultValue: ""
    }
  },
  components: {
    TableHeaderColumn,
    Modal,
    VScroller
  },
  setup(props, context) {
    const componentData = ref({
      profiles: [],
      profileIndex: {
        firstName: 0,
        lastName: 0,
        email: 0,
        phone: 0,
        dob: 0,
        address: 0,
        city: 0,
        state: 0,
        zip: 0,
        logo: 0
      },
      events: [],
      members: [],
      isLoading: false
    });
    const loadData = async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/merge",
        {},
        {
          profiles: props.profileIds || ""
        }
      );
      if (result.status === 1) {
        componentData.value.profiles = result.data.profiles;
        componentData.value.events = result.data.events.map((item: any) => {
          return { ...item, checked: true };
        });
        componentData.value.members = result.data.members.map((item: any) => {
          return { ...item, checked: true };
        });
      }
    };
    const onSubmit = async () => {
      const selectedEventIds = componentData.value.events
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.eventId;
        });
      for (const item of componentData.value.events) {
        const event: any = item;
        // check duplicate events
        if (
          event.checked &&
          ApiHelper.countOccurrences(selectedEventIds, event.eventId) > 1
        ) {
          ApiHelper.showErrorMessage(
            `Event ${event.eventName} should be unique!`
          );
          return false;
        }
      }

      const selectedMembers = componentData.value.members
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.profileId;
        });
      for (const item of componentData.value.members || []) {
        const member: any = item;
        // check duplicate member
        if (
          member.checked &&
          ApiHelper.countOccurrences(selectedMembers, member.profileId) > 1
        ) {
          ApiHelper.showErrorMessage(
            `Member ${ApiHelper.getFullName(
              member.firstName || "",
              member.lastName || ""
            )} should be unique!`
          );
          return false;
        }
      }
      const isConfirm = await Vue.swal({
        text: `Are you sure want to to merge the selected accounts?`,
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });
      if (!isConfirm) {
        return false;
      }
      const profiles: any = componentData.value.profiles || [];
      const profileData = {
        profileId:
          (profiles[componentData.value.profileIndex.email] || {}).id || 0,
        email:
          (profiles[componentData.value.profileIndex.email] || {}).email || "",
        firstName:
          (profiles[componentData.value.profileIndex.firstName] || {})
            .firstName || "",
        lastName:
          (profiles[componentData.value.profileIndex.lastName] || {})
            .lastName || "",
        phone:
          (profiles[componentData.value.profileIndex.phone] || {}).phone || "",
        address:
          (profiles[componentData.value.profileIndex.address] || {}).address ||
          "",
        city:
          (profiles[componentData.value.profileIndex.city] || {}).city || "",
        state:
          (profiles[componentData.value.profileIndex.state] || {}).state || "",
        zip: (profiles[componentData.value.profileIndex.zip] || {}).zip || "",
        dob:
          (profiles[componentData.value.profileIndex.dob] || {}).dobFormatted ||
          "",
        logo: (profiles[componentData.value.profileIndex.logo] || {}).logo || ""
      };
      const result = await ApiHelper.callApi(
        "post",
        "/participants/merge",
        {
          mergedProfile: profileData,
          profiles: componentData.value.profiles,
          events: componentData.value.events,
          members: componentData.value.members
        },
        {}
      );
      if (result.status !== 1) {
        ApiHelper.showErrorMessage(result.message, "Oops");
        return;
      }
      ApiHelper.showSuccessMessage("Merged accounts");
      context.emit("callback");
    };
    (async () => {
      await loadData();
    })();
    return {
      onSubmit,
      componentData,
      ApiHelper: ApiHelper
    };
  }
});
