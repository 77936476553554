
















































import { defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import FormPhoto from "@/components/Form/FormPhoto.vue";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "PopupProfileSummary",
  props: {
    profileId: Number,
    defaultPhoto: {
      type: String,
      value: ""
    }
  },
  components: {
    Modal,
    FormPhoto
  },
  setup(props, context) {
    const formData = ref<{
      isLoading: boolean;
      controls: {
        photo: {
          label?: string;
          error: string;
          type: string;
          value: string;
          preview: string;
          s3url: string;
          delete?: boolean;
        };
      };
    }>({
      isLoading: true,
      controls: {
        photo: {
          error: "",
          type: "upload",
          value: "",
          preview: props.defaultPhoto || "",
          s3url: "",
          delete: false
        }
      }
    });

    const changeProfileAvatar = async (s3url: string) => {
      formData.value.controls.photo.s3url = s3url;
      formData.value.controls.photo.preview = s3url;
    };

    const isProcessing = ref(false);
    const onChangeFile = async () => {
      isProcessing.value = true;
      const result = await ApiHelper.callApi(
        "put",
        "/profiles/" + props.profileId + "/logo",
        {
          s3url: formData.value.controls.photo.s3url
        },
        {}
      );
      if (result.status === 1) {
        isProcessing.value = false;
        context.emit("dismiss");
        context.emit(
          "updatePhoto",
          result.data.logo || formData.value.controls.photo.s3url
        );
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    };

    return {
      isProcessing,
      onChangeFile,
      changeProfileAvatar,
      formData
    };
  }
});
