var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SettingsUsersPage"},[_c('Header',{attrs:{"sub-title":_vm.headerData.subTitle,"title":_vm.headerData.title}},[_c('h2',{staticClass:"head_heading head_heading__other",attrs:{"slot":"head_heading__other"},slot:"head_heading__other"},[_vm._v(" "+_vm._s(_vm.headerData.title || "")+" "),(_vm.pageData.pager.total > 0)?_c('span',{staticClass:"heading__number"},[_vm._v(_vm._s(_vm.pageData.pager.total))]):_vm._e()]),_c('div',{attrs:{"slot":"head__nav"},slot:"head__nav"},[_c('NavFamilyDetails')],1)]),_c('div',{staticClass:"content-inside"},[_c('div',{attrs:{"id":"list__view"}},[_c('div',{staticClass:"content__table__scroll pt60"},[_c('div',{staticClass:"content_table table__items"},[_c('div',{staticClass:"table__head not_dropdown"},[_c('div',{staticClass:"items row"},[_c('div',{staticClass:"item col col-2"},[_c('TableHeaderColumn',{attrs:{"label":""}})],1),_c('div',{staticClass:"item col col-2 has__arrow"},[_c('TableHeaderColumn',{attrs:{"label":"People"}})],1),_c('div',{staticClass:"item col col-3 has__arrow"},[_c('TableHeaderColumn',{attrs:{"label":"File Name"}})],1),_c('div',{staticClass:"item col col-4 has__arrow"},[_c('TableHeaderColumn',{attrs:{"label":"File Path"}})],1),_c('div',{staticClass:"item col col-1 has__arrow table__center"},[_c('TableHeaderColumn',{attrs:{"label":"Actions"}})],1)])]),_c('div',{staticClass:"table__body no-stretch"},[(!_vm.pageData.isLoading && _vm.pageData.items.length < 1)?_c('div',{staticClass:"noData"},[_vm._v(" Nothing to see here quite yet...zero files. ")]):_vm._e(),_vm._l((_vm.pageData.items),function(item){return _c('div',{key:item.id,staticClass:"items row",class:{ checked: item.isChecked },attrs:{"data-id":item.id}},[_c('div',{staticClass:"item col col-2 table__center"},[(
                    ['png', 'jpg', 'jpeg', 'gif'].includes(item.extension)
                  )?_c('img',{attrs:{"src":item.filePath}}):_c('div',{staticClass:"type-icon"},[_vm._v(_vm._s(item.extension))])]),_c('div',{staticClass:"item col col-2"},[_c('router-link',{staticClass:"textOverflow",attrs:{"to":{
                    name: 'ProfileFiles',
                    params: {
                      profileId: item.profileId
                    }
                  }}},[_vm._v(_vm._s(item.firstName + " " + item.lastName))])],1),_c('div',{staticClass:"item col col-3",attrs:{"title":("" + (item.fileName || '-'))}},[_c('span',{staticClass:"textOverflow"},[_vm._v(_vm._s(item.fileName || ""))])]),_c('div',{staticClass:"item col col-4"},[_c('span',{staticClass:"textOverflow"},[_vm._v(_vm._s(item.filePath || "-"))])]),_c('div',{staticClass:"item col col-1 table__center"},[_c('a',{staticClass:"remove-link mr-3 cursor-pointer",on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('img',{attrs:{"src":require("../../assets/images/icon/icon-download-16.png"),"alt":"Download"}})]),(_vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson))?_c('a',{staticClass:"remove-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.onRemoveItem(item, _vm.$parent)}}},[_c('img',{attrs:{"src":require("../../assets/images/delete.png"),"alt":"Remove"}})]):_vm._e()])])})],2)])]),_c('Pager',{attrs:{"goto-page":_vm.gotoPage,"on-click-next":_vm.onClickNext,"page-data":_vm.pageData,"on-click-prev":_vm.onClickPrev,"toggle-pager-items":_vm.togglePagerItems}})],1)]),(_vm.showPopup)?_c('Modal',{attrs:{"size":"large","is-loading":_vm.formData.isProcessing,"hide-footer":true}},[_c('div',{staticClass:"modal-loader"}),_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"modal-header",attrs:{"slot":"header"},slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Upload Image")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){_vm.showPopup = false}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('FormPhoto',{attrs:{"buttonTitle":'Upload Image',"profile-id":_vm.selectedProfileId,"data":_vm.formData.controls.photo},on:{"change":_vm.changeProfileAvatar}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }