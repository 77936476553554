



































































































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Header from "@/components/Common/Header.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import { useSettingsBillingStore } from "@/stores/Settings/Billing/SettingsBillingStore";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "SettingsBilling",
  components: {
    Header,
    Loading,
    FormSelect
  },
  setup(props, context) {
    const {
      pageData,
      changeBillDate,
      exportBillDetails,
      printBillDetails
    } = useSettingsBillingStore(context);
    return {
      pageData,
      changeBillDate,
      exportBillDetails,
      printBillDetails,
      ApiHelper
    };
  },

  beforeRouteUpdate(to, from, next) {
    next();
  }
});
