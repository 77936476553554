















































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import { useEventFinancialStore } from "@/stores/Event/EventFinancialStore";
import HeaderActions from "@/components/Common/HeaderActions.vue";
import ApexChart from "@/components/Chart/ApexChart.vue";
import ApexDashboard from "@/components/Chart/ApexDashboard.vue";
import BackButton from "@/components/Common/BackButton.vue";
import PopupPayment from "@/components/Financial/PopupPayment.vue";
import { ApiHelper } from "@/helpers";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import Pager from "@/components/Common/Pager.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import PopupEditApplication from "@/components/Participant/PopupEditApplication.vue";
import DiscountDetails from "@/components/Financial/DiscountDetails.vue";
import TransactionDetails from "@/components/Financial/TransactionDetails.vue";

export default defineComponent({
  name: "EventFinancialPage",
  components: {
    HeaderActions,
    HeaderEventDetails,
    SidebarEventDetails,
    VAlertMessage,
    ApexChart,
    Loading,
    BackButton,
    PopupPayment,
    ApexDashboard,
    TableHeaderColumn,
    Pager,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    PopupEditApplication,
    DiscountDetails,
    TransactionDetails
  },
  setup(props, context) {
    const {
      barData,
      chartData,
      pageData,
      loadData,
      getDiscounts,
      getFundBucket,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      updateFilterValue,
      selectAllServices,
      resetServices,
      searchServices,
      updateSortValue,
      updateSelectedServices,
      updateRouters,
      showDiscountDetailsModal,
      toggleTransactionDetails,
      isTieEvent
    } = useEventFinancialStore(context);

    const popupData = ref({
      profileId: 0,
      eventId: parseInt(context.root.$route.params.eventId) || 0,
      show: false
    });

    const popupEditApplication = ref({
      show: false,
      profileId: 0,
      participantId: 0
    });

    const closePaymentModal = (options: any) => {
      popupData.value.show = false;
      loadData();
    };

    const setIsPaying = (status: boolean) => {
      pageData.value.paying = status;
    };

    const getDiscountType = (type: number) => {
      if (type == 1) {
        return "Portal";
      } else if (type == 2) {
        return "Admin";
      }

      return "";
    };

    const getDiscountName = (item: any) => {
      let ret = item.discountName;
      if ((item.discountCode || "") != "") {
        ret += ` (${item.discountCode})`;
      }

      return ret;
    };

    const getRemain = (item: any) => {
      const maxUse = item.maxUse || 0;
      if (maxUse) {
        return maxUse - (item.totalUsed || 0);
      }
      return "";
    };

    const showPopupEditApplication = (options: any) => {
      popupEditApplication.value.profileId = options.profileId;
      popupEditApplication.value.participantId = options.participantId;
      popupEditApplication.value.show = true;
    };

    const setIsLoading = (status: boolean) => {
      pageData.value.isLoading = status;
    };

    const inSelectedFundbuckets = (item: any) => {
      const selectedId = `fb-${item.addonServiceId}-${item.participantId}`;
      const existedItem = pageData.value.selectedFundbuckets.find(
        (id: any) => id == selectedId
      );
      if (existedItem) {
        return true;
      }
      return false;
    };

    const updateFundBucketRefundInfo = (item: any) => {
      const selectedId = `fb-${item.addonServiceId}-${item.participantId}`;

      // just allow select an item at a time (see as a radio button)
      pageData.value.selectedFundbuckets = pageData.value.selectedFundbuckets.filter(
        (id: string) => id == selectedId
      );
      pageData.value.refundInfo = [];
      if (inSelectedFundbuckets(item)) {
        pageData.value.refundInfo.push({
          fundBucketRefund: true,
          serviceName: item.serviceName,
          profileId: item.profileId,
          participantId: item.participantId,
          addonServiceId: item.addonServiceId,
          transactionId: 0,
          transactionAmount: item.totalFunds,
          transactionAmountFormatted: item.totalFundsFormatted,
          externalPlanId: "",
          transactionTypeId: 0,
          details: []
          // transactionId: selectedId,
          // transactionAmount: item.transactionAmount,
          // transactionAmountFormatted: item.transactionAmountFormatted,
          // externalPlanId: item.planId ? item.externalId : "",
          // transactionTypeId: item.transactionTypeId,
        });
      }
    };

    const allowRefundFundBucket = (item: any) => {
      let allow = false;
      if (item.totalFunds > 0) {
        allow = true;
      }

      return allow;
    };

    const showTab = (tabName: string) => {
      if (pageData.value.tab != tabName) {
        // reset selectedIds
        pageData.value.selectedFundbuckets = [];
      }

      if (tabName == "plan") {
        pageData.value.tab = "plan";
        // pageData.value.selectedFundbuckets = [];
      } else if (tabName == "transaction") {
        pageData.value.tab = "transaction";
        // pageData.value.selectedFundbuckets = [];
      } else if (tabName == "discounts") {
        pageData.value.tab = "discounts";
        // pageData.value.selectedFundbuckets = [];
        getDiscounts();
      } else if (tabName == "fund_bucket") {
        pageData.value.tab = "fund_bucket";
        getFundBucket(1);
      }
    };

    return {
      chartData,
      loadData,
      popupData,
      barData,
      pageData,
      setIsPaying,
      closePaymentModal,
      ApiHelper,
      getDiscounts,
      getFundBucket,
      getDiscountType,
      getDiscountName,
      getRemain,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      updateFilterValue,
      selectAllServices,
      resetServices,
      searchServices,
      updateSortValue,
      updateSelectedServices,
      updateRouters,
      popupEditApplication,
      showPopupEditApplication,
      showDiscountDetailsModal,
      setIsLoading,
      inSelectedFundbuckets,
      updateFundBucketRefundInfo,
      allowRefundFundBucket,
      showTab,
      toggleTransactionDetails,
      isTieEvent
    };
  }
});
