



































































import Vue from "vue";

export default Vue.extend({
  name: "ProfileTabs",
  props: {
    tab: String,
    profileId: String
  }
});
