




















































import findIndex from "lodash/findIndex";
import generateId from "./lib/generateId";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "FlowyApp",
  components: {},
  data: () => {
    return {
      holder: [],
      blocks: [
        {
          preview: {
            title: "New visitor",
            description: "Triggers when somebody visits a specified page",
            icon: "eye"
          },
          node: {
            title: "New visitor",
            description:
              '<span>When a <span class="font-bold">new visitor</span> goes to <span class="font-bold">Site 1</span></span>',
            icon: "eyeblue"
          }
        },
        {
          preview: {
            title: "Update database",
            description: "Triggers when somebody performs a specified action",
            icon: "error"
          },
          node: {
            title: "Update database",
            description:
              '<span>Triggers when somebody performs a <span class="font-bold">specified action</span></span>',
            icon: "errorred"
          }
        },
        {
          preview: {
            title: "Time has passed",
            description: "Triggers after a specified amount of time",
            icon: "database"
          },
          node: {
            title: "Time has passed",
            description:
              'Triggers after a specified <span class="font-bold">amount</span> of time',
            icon: "databaseorange"
          }
        }
      ],
      nodes: [
        {
          id: "1",
          parentId: -1,
          nodeComponent: "demo-node",
          data: {
            width: 320,
            text: "Parent block",
            title: "New Visitor",
            description:
              '<span>When a <span class="font-bold">new visitor</span> goes to <span class="font-bold">Site 1</span></span>',
            icon: "eyeblue"
          }
        },
        {
          id: "2",
          parentId: "1",
          nodeComponent: "demo-node",
          data: {
            text: "Parent block",
            title: "New Visitor",
            description:
              '<span>When a <span class="font-bold">new visitor</span> goes to <span class="font-bold">Site 1</span></span>',
            icon: "eyeblue"
          }
        },
        {
          id: "3",
          parentId: "1",
          nodeComponent: "demo-node",
          data: {
            width: 320,
            text: "Parent block",
            title: "New Visitor",
            description:
              '<span>When a <span class="font-bold">new visitor</span> goes to <span class="font-bold">Site 1</span></span>',
            icon: "eyeblue"
          }
        },
        {
          id: "4",
          parentId: "3",
          nodeComponent: "demo-node",
          data: {
            width: 200,
            text: "Parent block",
            title: "New Visitor",
            description:
              '<span>When a <span class="font-bold">new visitor</span> goes to <span class="font-bold">Site 1</span></span>',
            icon: "eyeblue"
          }
        }
      ],
      newDraggingBlock: null
    };
  },
  methods: {
    onDragStartNewBlock(event: any) {
      console.log("onDragStartNewBlock", event);
      this.newDraggingBlock = event;
    },
    onDragStopNewBlock(event: any) {
      console.log("onDragStopNewBlock", event);
      this.newDraggingBlock = null;
    },
    onDropBlock(_event: any) {
      // do nothing
    },
    beforeAdd() {
      console.log("before add");
      return true;
    },
    afterAdd() {
      // do nothing
    },
    onEnterDrop(event: any) {
      console.log("entered drop");
      return true;
    },
    beforeMove({ to, from }: any) {
      console.log(to, from);
      if (from && from.id === "1") {
        return false;
      }
      return true;
    },
    onEnter() {
      // do nothing
    },
    addNode(_event: any) {
      const id = generateId(this.nodes);
      this.nodes.push({
        ..._event.node,
        id
      });
    },
    remove(event: any) {
      const nodeIndex = findIndex(this.nodes, { id: event.node.id });
      this.nodes.splice(nodeIndex, 1);
    },
    move(event: any) {
      console.log("move", event);
      const { dragged, to } = event;
      dragged.parentId = to.id;
    },
    add(event: any) {
      const id = generateId(this.nodes);
      this.nodes.push({
        id,
        ...event.node
      });
    }
  },
  watch: {},
  setup(props, context) {
    const dragging = ref<boolean>(false);
    const onDragStart = (event: any) => {
      console.log("onDragStart", event);
      dragging.value = true;
    };

    return {
      dragging,
      onDragStart
    };
  }
});
