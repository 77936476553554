var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"EventMedicalsPage"},[_c('HeaderProfileDetails',{ref:"childComponentHeaderRef",attrs:{"title":"Notes","profile-id":_vm.$route.params.profileId,"back-route":_vm.$route.query.eventId
        ? {
            name: 'EventDetails',
            params: {
              eventId: _vm.$route.query.eventId
            },
            query: {
              tab: _vm.$route.query.tab
            }
          }
        : _vm.$route.query.id
        ? {
            name: 'ProfileEvents',
            params: { profileId: _vm.$route.params.profileId }
          }
        : undefined}},[(_vm.pageData.pager.total > 0)?_c('span',{staticClass:"heading__number",attrs:{"slot":"heading__number"},slot:"heading__number"},[_vm._v(" "+_vm._s(_vm.pageData.pager.total)+" ")]):_vm._e(),_c('template',{slot:"heading__actions"},[(
          !_vm.isArchived && _vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson)
        )?_c('router-link',{staticClass:"addNewItem",attrs:{"to":{
          name: 'ProfileNoteEdit',
          params: { profileId: _vm.$route.params.profileId, noteId: '0' }
        }}},[_c('img',{attrs:{"src":require("../../assets/images/icon/add-icon.png"),"alt":"Add New Medical"}}),_c('span',[_vm._v("Add New Note")])]):_vm._e()],1)],2),_c('div',{staticClass:"content-inside"},[_c('div',{staticClass:"mw-1300 profile-basic-data"},[(_vm.isArchived)?_c('div',{staticStyle:{"padding-top":"20px"}},[_vm._m(0)]):_vm._e()]),_c('div',{attrs:{"id":"list__view"}},[_vm._m(1),_c('div',{staticClass:"content__table__scroll"},[_c('div',{staticClass:"content_table table__items"},[_c('div',{staticClass:"table__head not_dropdown"},[_c('div',{staticClass:"items row"},[_vm._m(2),_c('div',{staticClass:"item col col-2"},[_c('TableHeaderColumn',{attrs:{"label":"Created By"}})],1),_c('div',{staticClass:"item col col-2"},[_c('TableHeaderColumn',{attrs:{"label":"Type"}})],1),_c('div',{staticClass:"item col col-2 table__center"},[_c('TableHeaderColumn',{attrs:{"label":"Creation date"}})],1),(_vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson))?_c('div',{staticClass:"item col col-1 table__center"},[_c('TableHeaderColumn',{attrs:{"label":"Delete"}})],1):_vm._e()])]),_c('div',{staticClass:"table__body no-stretch"},[(!_vm.pageData.isLoading && _vm.pageData.items.length < 1)?_c('div',{staticClass:"noData"},[_vm._v(" Nothing to see here quite yet...zero notes. ")]):_vm._e(),_vm._l((_vm.pageData.items),function(item){return _c('div',{key:item.id,staticClass:"items row",attrs:{"data-id":item.id}},[_c('div',{staticClass:"item col col-5"},[_c('router-link',{staticClass:"textOverflow",attrs:{"to":{
                    name: 'ProfileNoteEdit',
                    params: {
                      profileId: item.profileId + '',
                      noteId: item.id + ''
                    },
                    query: {
                      participantId: _vm.$route.query.participantId,
                      fromProfileId: _vm.$route.params.profileId
                    }
                  }}},[_c('span',{domProps:{"innerHTML":_vm._s(item.message)}})])],1),_c('div',{staticClass:"item col col-2"},[_c('span',[_vm._v(_vm._s(item.userFullName))])]),_c('div',{staticClass:"item col col-2"},[_c('span',[_vm._v(_vm._s(item.isfamilyUsed ? "Family" : "Individual"))])]),_c('div',{staticClass:"item col col-2 table__center"},[_c('span',[_vm._v(_vm._s(item.shortTsFormatted))])]),(_vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson))?_c('div',{staticClass:"item  col col-1 table__center"},[_c('a',{staticClass:"remove-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.onRemoveItem(item, _vm.$parent)}}},[_c('img',{attrs:{"src":require("../../assets/images/delete.png"),"alt":"Remove"}})])]):_vm._e()])})],2)])]),_c('Pager',{attrs:{"goto-page":_vm.gotoPage,"on-click-next":_vm.onClickNext,"page-data":_vm.pageData,"on-click-prev":_vm.onClickPrev,"toggle-pager-items":_vm.togglePagerItems}})],1),_c('div',{staticClass:"custom__view",staticStyle:{"display":"none"}})]),(_vm.$route.query.participantId && !_vm.sidebarData.isLoading)?_c('SidebarProfileEvents',{attrs:{"data":_vm.sidebarData,"showSidebar":true,"participantId":_vm.$route.query.participantId,"eventId":_vm.$route.query.eventId}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-danger",staticStyle:{"padding":"20px","border":"1px solid #999","background-color":"#eee"}},[_c('h5',[_vm._v("This person was archived!")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter__icon__mobile",attrs:{"data-id":"filter__1"}},[_c('img',{attrs:{"src":require("../../assets/images/icon-list.png"),"alt":"Icon"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item col col-5 has__arrow"},[_c('div',{staticClass:"item__filter"},[_c('div',{staticClass:"filter__gray large__size"},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"filter__span"},[_vm._v(" Note Content ")])])])])])}]

export { render, staticRenderFns }