var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"RevenueSpendCategoryPage"},[_c('div',{attrs:{"id":"content"}},[_c('Header',{attrs:{"sub-title":'Accounting',"title":'Revenue Spend Category',"hide-back-button":true,"filters":_vm.getFiltersData()}},[_c('h2',{staticClass:"head_heading head_heading__other",attrs:{"slot":"head_heading__other"},slot:"head_heading__other"},[_vm._v(" Revenue Spend Category "),(_vm.pageData.pager.total > 0)?_c('span',{staticClass:"heading__number"},[_vm._v(" "+_vm._s(_vm.pageData.pager.total)+" ")]):_vm._e(),_c('router-link',{staticClass:"addNewItem",attrs:{"to":{ name: 'RevenueSpendCategoryEdit', params: { id: '0' } }}},[_c('img',{attrs:{"src":require("../../../assets/images/icon/add-icon.png"),"alt":"Icon"}}),_c('span',[_vm._v("Add Category")])])],1),_c('HeaderTabs',{attrs:{"slot":"head__tabs","tabs":[
          {
            label: 'Cost Center',
            route: {
              name: 'FinancialAccounting'
            }
          },
          {
            label: 'Posting/GL Code',
            route: {
              name: 'FinancialPosting'
            }
          },
          {
            label: 'Transaction Type',
            route: {
              name: 'TransactionPostings'
            }
          }
        ]},slot:"head__tabs"})],1),_c('div',{staticClass:"content-inside"},[_c('div',{attrs:{"id":"list__view"}},[_c('div',{staticClass:"content__table__scroll"},[_c('div',{staticClass:"content_table table__items"},[_c('div',{staticClass:"table__head not_dropdown"},[_c('div',{staticClass:"items row"},[_c('div',{staticClass:"item col col-6 has__arrow"},[_c('FilterSearchColumn',{attrs:{"label":'Category Name',"active-tab":_vm.pageData.activeTab,"active-value":'key',"data":{},"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.filter.key,"on-change-value":_vm.updateFilterValue,"sort-data":_vm.pageData.sort,"sort-key":'1',"on-change-sort-value":_vm.updateSortValue}})],1),_c('div',{staticClass:"item col col-2 has__arrow"},[_c('TableHeaderColumn',{attrs:{"label":"Created Date"}})],1),_c('div',{staticClass:"item col col-2 has__arrow"},[_c('TableHeaderColumn',{attrs:{"label":"Updated Date"}})],1),_c('div',{staticClass:"item col col-2 table__center"},[_c('TableHeaderColumn',{attrs:{"label":""}})],1)])]),_c('div',{staticClass:"table__body no-stretch"},[(!_vm.pageData.isLoading && _vm.pageData.items.length < 1)?_c('div',{staticClass:"noData"},[_vm._v(" Nothing to see here quite yet...zero Categories. ")]):_vm._e(),_vm._l((_vm.pageData.items),function(item,index){return _c('div',{key:index,staticClass:"items row"},[_c('div',{staticClass:"item col col-6",attrs:{"title":("" + (item.revenueCategoryName || '-'))}},[_c('router-link',{staticClass:"textOverflow",attrs:{"to":{
                      name: 'RevenueSpendCategoryEdit',
                      params: { id: ("" + (item.revenueCategoryId)) }
                    }}},[_c('span',[_vm._v(" "+_vm._s(item.revenueCategoryName)+" ")])])],1),_c('div',{staticClass:"item col-2"},[_c('span',[_vm._v(_vm._s(item.createdTsFormatted || "-"))])]),_c('div',{staticClass:"item col-2"},[_c('span',[_vm._v(_vm._s(item.updatedTsFormatted || "-"))])]),_c('div',{staticClass:"item col-2 table__center"},[_c('a',{staticClass:"remove-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.updateItemStatus(item, { value: 0 })}}},[_c('img',{attrs:{"src":require("../../../assets/images/delete.png"),"alt":"Remove"}})])])])})],2)])]),_c('Pager',{attrs:{"goto-page":_vm.gotoPage,"on-click-next":_vm.onClickNext,"page-data":_vm.pageData,"on-click-prev":_vm.onClickPrev,"toggle-pager-items":_vm.togglePagerItems}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }