var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ProfileSettingsPage"},[_c('HeaderProfileDetails',{attrs:{"title":"Settings","profile-id":_vm.$route.params.profileId}}),_c('div',{staticClass:"content-inside mw-1300"},[(_vm.isArchived)?_c('div',{staticStyle:{"padding-top":"20px"}},[_vm._m(0)]):_c('div',{staticClass:"content_add"},[_c('div',{staticClass:"content__form"},[_c('form',{attrs:{"accept-charset":"utf-8","autocomplete":"new-password"}},[_c('div',{staticClass:"form__info"},[_c('div',{staticClass:"group"},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-8 col-lg-6"},[_c('FormYesNo',{attrs:{"data":_vm.formData.controls.createUser}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.controls.createUser.value == 'yes'),expression:"formData.controls.createUser.value == 'yes'"}],staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-8 col-lg-6"},[_c('div',{staticClass:"password_field"},[_c('FormPassword',{attrs:{"data":_vm.formData.controls.password},on:{"input":function($event){return _vm.checkStrongPassword(_vm.formData.controls.password.value)}}})],1)]),(_vm.formData.controls.password.value)?_c('div',{staticClass:"col col-12 col-md-8 col-lg-6"},[_c('div',{staticStyle:{"border":"1px solid rgb(238, 238, 238)","padding":"10px","max-width":"100%","min-height":"100%","background-color":"rgb(255, 250, 240)"}},[_c('ul',{staticClass:"password-rules",class:{ checking: _vm.formData.controls.password.value }},[_c('li',{class:{
                          valid: _vm.passwordData.has8characters,
                          invalid: !_vm.passwordData.has8characters
                        }},[_vm._v(" PASSWORD MUST BE AT LEAST 8 CHARACTERS ")]),_c('li',{class:{
                          valid: _vm.passwordData.hasCapital,
                          invalid: !_vm.passwordData.hasCapital
                        }},[_vm._v(" PASSWORD MUST HAVE 1 UPPERCASE LETTER ")]),_c('li',{class:{
                          valid: _vm.passwordData.hasNumber,
                          invalid: !_vm.passwordData.hasNumber
                        }},[_vm._v(" PASSWORD MUST AT LEAST HAVE 1 NUMBER ")]),_c('li',{class:{
                          valid: _vm.passwordData.nonHtml,
                          invalid: !_vm.passwordData.nonHtml
                        }},[_vm._v(" NON HTML CONTENT ")])])])]):_vm._e()]),_c('div',{staticClass:"row"},[(_vm.formData.controls.createUser.value == 'yes')?_c('div',{staticClass:"col col-12",staticStyle:{"padding-top":"20px"}},[(
                      _vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson)
                    )?_c('a',{staticClass:"btn btn-lg btn-outline-primary mr-1",on:{"click":_vm.onSendResetLink}},[_vm._v(" Send Password Reset ")]):_vm._e(),(
                      _vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson)
                    )?_c('a',{staticClass:"btn btn-lg btn-outline-success",on:{"click":_vm.onSubmit}},[_vm._v(" Update Password ")]):_vm._e()]):_c('div',{staticClass:"col col-12",staticStyle:{"padding-top":"20px"}},[_c('a',{staticClass:"btn btn-lg btn-outline-primary",on:{"click":_vm.onSavePermission}},[_vm._v(" Save permission ")])])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-danger",staticStyle:{"padding":"20px","border":"1px solid #999","background-color":"#eee"}},[_c('h5',[_vm._v("This person was archived!")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',[_vm._v("Reset Password")])])])}]

export { render, staticRenderFns }