var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-medical-general-page d-flex flex-column h-100"},[_c('Header',{attrs:{"sub-title":'Reports',"title":'Medical General',"hideBackButton":true},scopedSlots:_vm._u([{key:"head_left",fn:function(){return [_c('div',{staticClass:"head_left hide__ipad",class:{ hasBackButton: !_vm.hideBackButton }},[(_vm.backRoute)?_c('BackButton',{attrs:{"route":_vm.backRoute}}):_c('BackButton'),_c('h3',{staticClass:"head_heading__small TEST2"},[_vm._v(" Reports ")]),_c('h2',{staticClass:"head_heading head_heading__other"},[_vm._v(" Medical General "),(_vm.pageData.medicalLogs.pager.total > 0)?_c('span',{staticClass:"heading__number"},[_vm._v(" "+_vm._s(_vm.pageData.medicalLogs.pager.total)+" ")]):_vm._e()])],1)]},proxy:true},{key:"head_right",fn:function(){return [_c('div',{staticClass:"head_right"},[_c('div',{staticClass:"head__button"},[_c('button',{staticClass:"btn btn-lg btn-outline-primary",on:{"click":function($event){_vm.updateRouters();
              _vm.getMedicalLogs(1);}}},[_vm._v(" Submit ")])])])]},proxy:true}])}),_c('div',{staticClass:"content-inside pt60"},[_c('div',{staticClass:"content_add"},[_c('div',{staticClass:"content__form"},[_c('div',{attrs:{"action":"event-add_submit"}},[_c('div',{},[_c('div',{staticClass:"group"},[_c('section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormSelect',{attrs:{"data":_vm.pageData.controls.events,"onChange":_vm.onChangeEvents}})],1),_c('div',{staticClass:"col col-6"},[_c('FormSelect',{attrs:{"data":_vm.pageData.controls.cabins}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormDatePicker',{attrs:{"data":_vm.pageData.controls.startDate}})],1),_c('div',{staticClass:"col col-6"},[_c('FormDatePicker',{attrs:{"data":_vm.pageData.controls.endDate}})],1)])])])])])])]),_c('div',{staticClass:"content__table__scroll"},[_c('div',{staticClass:"content_table table__items medical-logs-items"},[_c('div',{staticClass:"table__head not_dropdown"},[_c('div',{staticClass:"items row"},[_c('div',{staticClass:"item col col-2 has__arrow"},[_c('FilterCheckboxesColumn',{attrs:{"label":'Incident Type',"active-tab":_vm.pageData.activeTab,"active-value":'incidentType',"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.medicalLogs.filter.incidentType,"close-filter-tab":_vm.closeFilterTab,"on-select-all":function () {
                    _vm.pageData.medicalLogs.filter.incidentType = _vm.pageData.medicalLogs.filter.incidentType.map(
                      function (item) {
                        item.selected = true;
                        return item;
                      }
                    );
                    _vm.updateRouters();
                    _vm.getMedicalLogs(1);
                  },"on-reset-value":function () {
                    _vm.pageData.medicalLogs.filter.incidentType = _vm.pageData.medicalLogs.filter.incidentType.map(
                      function (item) {
                        item.selected = false;
                        return item;
                      }
                    );
                    _vm.updateRouters();
                    _vm.getMedicalLogs(1);
                  },"on-change-value":function () {
                    _vm.pageData.medicalLogs.pager.page = 1;
                    _vm.updateRouters();
                    _vm.getMedicalLogs(1);
                  },"sort-data":_vm.pageData.medicalLogs.sort,"sort-key":'4',"on-change-sort-value":function (sort, direction) { return _vm.updateSortValue(sort, direction, 'medicalLogs'); }}})],1),_c('div',{staticClass:"item col col-2"},[_c('FilterSearchColumn',{attrs:{"label":'Name',"active-tab":_vm.pageData.activeTab,"active-value":'medicalLogsProfile',"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.medicalLogs.filter.medicalLogsProfile,"on-change-value":function (name, value) {
                    _vm.updateFilterValue(name, value);
                    _vm.getMedicalLogs(1);
                  },"sort-data":_vm.pageData.medicalLogs.sort,"sort-key":'7',"on-change-sort-value":function (sort, direction) { return _vm.updateSortValue(sort, direction, 'medicalLogs'); }}})],1),_c('div',{staticClass:"item col col-2"},[_c('FilterSearchColumn',{attrs:{"label":'Summary',"active-tab":_vm.pageData.activeTab,"active-value":'medicalLogsSummary',"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.medicalLogs.filter.medicalLogsSummary,"on-change-value":function (name, value) {
                    _vm.updateFilterValue(name, value);
                    _vm.getMedicalLogs(1);
                  },"sort-data":_vm.pageData.medicalLogs.sort,"sort-key":'1',"on-change-sort-value":function (sort, direction) { return _vm.updateSortValue(sort, direction, 'medicalLogs'); }}})],1),_c('div',{staticClass:"item col col-2"},[_c('FilterCheckboxesColumn',{attrs:{"label":'Symptom',"isACILoading":_vm.pageData.searchSymptomsLoading,"active-tab":_vm.pageData.activeTab,"active-value":'Symptom',"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.medicalLogs.filter.symptoms,"close-filter-tab":_vm.closeFilterTab,"on-select-all":_vm.selectAllSymptoms,"on-reset-value":_vm.resetSymptoms,"on-change-value":function () {
                    _vm.pageData.medicalLogs.pager.page = 1;
                    _vm.updateSelectedSymptoms();
                    _vm.updateRouters();
                    _vm.getMedicalLogs(1);
                  },"sort-data":_vm.pageData.medicalLogs.sort,"sort-key":'8',"on-change-sort-value":function (sort, direction) { return _vm.updateSortValue(sort, direction, 'medicalLogs'); },"lazyLoad":true,"lazyLoadPlaceholder":"Search symptoms...","searchValue":_vm.pageData.searchSymptomsValue},on:{"searchItems":_vm.searchSymptoms}})],1),_c('div',{staticClass:"item col col-2"},[_c('TableHeaderColumn',{attrs:{"label":"Emergency Info"}})],1),_c('div',{staticClass:"item col col-2 has__arrow"},[_c('TableHeaderColumn',{attrs:{"label":"Date & Time","sort-data":_vm.pageData.medicalLogs.sort,"sort-key":'9',"on-change-sort-value":function (sort, direction) { return _vm.updateSortValue(sort, direction, 'medicalLogs'); }}})],1)])]),_c('div',{staticClass:"table__body no-stretch"},[(
              !_vm.pageData.isLoading && _vm.pageData.medicalLogs.items.length < 1
            )?_c('div',{staticClass:"noData"},[_vm._v(" Nothing to see here quite yet...zero logs. ")]):_vm._e(),_vm._l((_vm.pageData.medicalLogs.items),function(item,index){return _c('div',{key:index,staticClass:"items row",attrs:{"data-id":item.id}},[_c('div',{staticClass:"item col col-2"},[((item.medicalAlert || 0) == 1)?_c('span',{staticClass:"medical-alert-ico",attrs:{"title":"Medical alert"}}):_vm._e(),_c('router-link',{staticClass:"textOverflow",attrs:{"to":{
                  name: 'ProfileMedicalEdit',
                  params: {
                    profileId: item.profileId + '',
                    medicalId: item.medicalId + ''
                  },
                  query: {
                    participantId: _vm.$route.query.participantId,
                    eventId: _vm.$route.query.eventId,
                    tab: _vm.$route.query.tab,
                    type: 2,
                    callFrom: 'ReportMedicalGeneral',
                    reportParams: _vm.getReportParams()
                  }
                }}},[_c('span',[_vm._v(" "+_vm._s(item.incidenttype === 2 ? "Incident" : "Dispensed")+" ")])])],1),_c('div',{staticClass:"item col col-2"},[_c('router-link',{staticClass:"textOverflow",attrs:{"to":{
                  name: 'ProfileDetails',
                  params: {
                    profileId: item.profileId.toString()
                  }
                }}},[_vm._v(" "+_vm._s(_vm.ApiHelper.getFullName(item.firstName, item.lastName))+" ")])],1),_c('div',{staticClass:"item col col-2"},[_c('router-link',{staticClass:"textOverflow",attrs:{"to":{
                  name: 'ProfileMedicalEdit',
                  params: {
                    profileId: item.profileId + '',
                    medicalId: item.medicalId + ''
                  },
                  query: {
                    participantId: _vm.$route.query.participantId,
                    eventId: _vm.$route.query.eventId,
                    type: 2,
                    callFrom: 'ReportMedicalGeneral',
                    reportParams: _vm.getReportParams()
                  }
                }}},[(item.medicalName)?_c('span',[_vm._v(" "+_vm._s(item.medicalName)+_vm._s(item.notes ? "," : "")+" ")]):(item.medicationName)?_c('span',[_vm._v(" "+_vm._s(item.medicationName)+_vm._s(item.notes ? "," : "")+" ")]):_vm._e(),_c('span',{attrs:{"title":item.notes}},[_vm._v(" "+_vm._s(item.notes)+" ")])])],1),_c('div',{staticClass:"item col col-2",attrs:{"title":_vm.ApiHelper.getSymptomTexts(item.symptomsList || [])}},[_c('span',{staticClass:"textOverflow"},[_vm._v(" "+_vm._s(_vm.ApiHelper.getSymptomTexts(item.symptomsList || []))+" ")])]),_c('div',{staticClass:"item col col-2"},[((item.emergencyInfo.profileOwnerId || 0) > 0)?[_vm._v(" "+_vm._s(_vm.ApiHelper.getFullName( item.emergencyInfo.firstName, item.emergencyInfo.lastName ))+" "),(item.emergencyInfo.email)?_c('div',{attrs:{"title":item.emergencyInfo.email}},[_vm._v(" "+_vm._s(item.emergencyInfo.email)+" ")]):_vm._e(),(item.emergencyInfo.phone)?_c('div',[_vm._v(" "+_vm._s(item.emergencyInfo.phone)+" ")]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"item col col-2"},[_c('span',[_vm._v(" "+_vm._s(item.startDateFormatted)+" ")])])])})],2)])]),_c('Pager',{attrs:{"goto-page":function (page) { return _vm.gotoPage(page, 'medicalLogs'); },"on-click-next":function () { return _vm.onClickNext('medicalLogs'); },"page-data":_vm.pageData.medicalLogs,"on-click-prev":function () { return _vm.onClickPrev('medicalLogs'); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }