






















































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Header from "@/components/Common/Header.vue";
import Loading from "@/components/Common/Loading.vue";
import { ApiHelper } from "@/helpers";
import TransactionDetails from "@/components/Financial/TransactionDetails.vue";

export default defineComponent({
  name: "FinancialPlanDetails",
  components: {
    Loading,
    Header,
    TransactionDetails
  },
  setup(props, context) {
    const selectedProfileId =
      parseInt(context.root.$route.query.profileId + "") || 0;
    const selectedPlanId = context.root.$route.query.planId || "";
    const headerData = ref({
      subTitle: "Plan",
      title: "#" + selectedPlanId
    });
    const pageData = ref({
      hasError: false,
      errorMessage: "",
      isLoading: false,
      transactions: [],
      detailsVisible: -1
    });
    const planDetails = ref([]);
    const planInfo = ref<{
      planId: string;
      planInterval: string;
      total: number;
      amount: number;
      completeAt?: Date;
      planTs?: Date;
      participantIDs: string;
      subState: string;
      totalRecurring: number;
      planLength: number;
      paidAmount: number;
      statusText: string;
      participantAmountFormatted: string;
      recurringAmountFormatted: string;
      paidAmountFormatted: string;
      createdTSFormatted: string;
      completeAtFormatted: string;
    }>({
      planId: "",
      planInterval: "",
      total: 0,
      amount: 0,
      completeAt: undefined,
      planTs: undefined,
      participantIDs: "",
      subState: "",
      totalRecurring: 0,
      planLength: 0,
      paidAmount: 0,
      statusText: "",
      participantAmountFormatted: "",
      recurringAmountFormatted: "",
      paidAmountFormatted: "",
      createdTSFormatted: "",
      completeAtFormatted: ""
    });

    const loadData = () => {
      (async () => {
        pageData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "get",
          "/plans/details",
          {},
          {
            profileId: selectedProfileId,
            planId: selectedPlanId
          }
        );
        pageData.value.isLoading = false;
        if (result.status === 1) {
          planDetails.value = result.data.planDetails || [];
          planInfo.value = result.data.planInfo || {};
          pageData.value.transactions = result.data.transactions || [];
        } else {
          ApiHelper.showErrorMessage("Plan info is invalid", "Oops");
          ApiHelper.gotoPage(context, { name: "FinancialPlans" });
        }
      })();
    };

    const toggleTransactionDetails = async (item: any, index: number) => {
      if (pageData.value.detailsVisible == index) {
        pageData.value.detailsVisible = -1;
      } else {
        pageData.value.detailsVisible = index;
      }
      if (pageData.value.detailsVisible != -1 && !("details" in item)) {
        // get item details
        pageData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "get",
          "/transactions/details",
          {},
          {
            transactionId: item.transactionId,
            transactionTypeId: item.transactionTypeId,
            // tieEvent: parseInt(item.eventId) > 0 ? 1 : 0,
            // profileId: parseInt(item.profileId)
            transactionIds: item.transactionIds,
            participantIds: item.participantIds
          }
        );
        pageData.value.isLoading = false;
        if (result.status === 1) {
          item.details = {
            transactionDetails: result.data.transactionDetails || [],
            transactionLines: result.data.transactionLines || [],
            transactionInfos: result.data.transactionInfos || [],
            refundLines: result.data.refundLines || [],
            events: result.data.events || []
          };
        } else {
          ApiHelper.showErrorMessage("Transaction not found", "Oops");
        }
      }
    };

    return {
      headerData,
      loadData,
      pageData,
      planDetails,
      planInfo,
      toggleTransactionDetails
    };
  },
  mounted() {
    this.loadData();
  },
  beforeRouteUpdate(to, from, next) {
    next();
  }
});
