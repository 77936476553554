



























































































import { defineComponent } from "@vue/composition-api";
import VScroller from "@/components/Common/VScroller.vue";
import Loading from "@/components/Common/Loading.vue";
import ErrorMessage from "@/components/Common/ErrorMessage.vue";
import { useProfileMessagesStore } from "@/stores/Profile/ProfileMessagesStore";
import Header from "@/components/Common/Header.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";

export default defineComponent({
  name: "MessagingInboxPage",
  components: {
    HeaderProfileDetails,
    VScroller,
    Loading,
    ErrorMessage,
    Header
  },
  setup(props, context) {
    const {
      replyQuestion,
      selectedId,
      onKeydown,
      popupNewItem,
      // sort
      updateSortValue,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab
    } = useProfileMessagesStore(context);
    return {
      replyQuestion,
      selectedId,
      onKeydown,
      popupNewItem,
      // sort
      updateSortValue,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.gotoPage("1");
  }
});
