






































































































































































































































































































































































































































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormDate from "@/components/Form/FormDate.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import { useProfileMedicalEditStore } from "@/stores/Profile/ProfileMedicalEditStore";
import BackButton from "@/components/Common/BackButton.vue";
import { ApiHelper } from "@/helpers";
import SidebarProfileEvents from "@/components/Profile/SidebarProfileEvents.vue";
import FormToggle from "@/components/Form/FormToggle.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormDatePicker from "@/components/Form/FormDatePicker.vue";
import FormTags from "@/components/Form/FormTags.vue";
import Modal from "@/components/Common/Modal.vue";
import FormButton from "@/components/Form/FormButton.vue";
import VScroller from "@/components/Common/VScroller.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";

export default defineComponent({
  name: "ParticipantMedicalEdit",
  components: {
    HeaderProfileDetails,
    FormTextarea,
    Loading,
    FormInput,
    FormSelect,
    FormDate,
    FormDatePicker,
    FormNumber,
    BackButton,
    SidebarProfileEvents,
    FormToggle,
    FormTags,
    Modal,
    FormButton,
    VScroller,
    AutoCompleteInput
  },
  setup(props, context) {
    const {
      formData,
      onSubmit,
      onChangeIncidentType,
      suggestSymptom,
      addSymptom,
      removeLastSymptom,
      removeSymptom,
      selectSymptom,
      // onChangeFollowup,
      addFollowUp,
      closeFollowUp,
      showFollowUp,
      followupChange,
      changeResolved,
      dosagesChange,
      suggestMedications,
      selectMedication,
      resetMedication
    } = useProfileMedicalEditStore(context);

    const profile = ref({
      id: "-",
      firstName: "-",
      lastName: "-",
      tags: []
    });
    const headerData = ref({
      title: "",
      subTitle: ""
    });
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/" + context.root.$route.params.profileId + "/info",
        {},
        {}
      );
      if (result.status === 1) {
        profile.value = result.data || { id: 0 };
      }
    })();

    (async () => {
      const selectedProfileId =
        Number.parseInt(context.root.$route.params.profileId, 10) || 0;
      const participantId =
        Number.parseInt(context.root.$route.query.participantId + "", 10) || 0;
      if (participantId > 0) {
        const result = await ApiHelper.callApi(
          "get",
          "/participants/" + participantId,
          {},
          {}
        );
        if (result.status === 1) {
          headerData.value.subTitle =
            ApiHelper.getFullName(
              result.data.firstName || "",
              result.data.lastName || ""
            ) +
              " - " +
              result.data.eventName || "";
        }
      } else {
        const result = await ApiHelper.callApi(
          "get",
          "/profiles/" + selectedProfileId + "/info",
          {},
          {}
        );
        if (result.status === 1) {
          headerData.value.subTitle = ApiHelper.getFullName(
            result.data.firstName || "",
            result.data.lastName || ""
          );
        }
      }
    })();

    const allowEdit = computed(() => {
      const $this: any = context.root;
      const participantId = context.root.$route.query.participantId || 0;
      const profileId = context.root.$route.params.profileId || 0;
      if (participantId > 0) {
        // medical for participant
        if (
          !(
            $this.$allowedFunctions.includes($this.$userFunctions.EditEvent) ||
            $this.$allowedFunctions.includes(
              $this.$userFunctions.CheckInPermissions
            )
          )
        ) {
          return false;
        }
      } else if (profileId > 0) {
        // medical for profile
        if (
          !$this.$allowedFunctions.includes($this.$userFunctions.EditPerson)
        ) {
          return false;
        }
      }

      return true;
    });

    return {
      headerData,
      profile,
      formData,
      onSubmit,
      onChangeIncidentType,
      allowEdit,
      suggestSymptom,
      addSymptom,
      removeLastSymptom,
      removeSymptom,
      selectSymptom,
      // onChangeFollowup,
      addFollowUp,
      closeFollowUp,
      showFollowUp,
      followupChange,
      changeResolved,
      dosagesChange,
      suggestMedications,
      selectMedication,
      resetMedication
    };
  }
});
