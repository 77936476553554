import { ref } from "@vue/composition-api";
import { FormSelect } from "@/types";
import { ApiHelper } from "@/helpers";
import moment from "moment";
import { ApiType } from "@/helpers/ApiHelper";

export function useSettingsBillingStore(context: any) {
  const pageData = ref<{
    isLoading: boolean;
    billing: {
      total: string;
    };
    billDates: {
      id: string;
      month: string;
      total: string;
    }[];
    priorBills: any;
    currentBill: any;
    controls: {
      date: FormSelect;
    };
    printData: any;
  }>({
    isLoading: true,
    billing: {
      total: ""
    },
    billDates: [],
    priorBills: [],
    currentBill: {
      invoiceId: 0,
      month: "",
      year: "",
      totalAmount: 0,
      dateFormat: "",
      totalAmountFormatted: "$0.00"
    },
    controls: {
      date: {
        style: "custom",
        // label: "Date",
        placeholder: "Select...",
        value: "",
        error: "",
        options: []
      }
    },
    printData: []
  });

  // const formData = ref<{
  //   actions: {
  //     exportCsv: Function;
  //     print: Function;
  //   };
  // }>({
  //   actions: {
  //     exportCsv: () => {
  //       ApiHelper.showSuccessMessage("Coming soon", "Export CSV");
  //     },
  //     print: () => {
  //       ApiHelper.showSuccessMessage("Coming soon", "Print");
  //     }
  //   }
  // });

  const loadData = async (page: number) => {
    ApiHelper.setDataLoading(false);
    pageData.value.billing.total = ApiHelper.dollarFormat(
      ApiHelper.randomNumber(1000, 10000)
    );
    pageData.value.billDates = [];

    for (let i = 0; i < 12; i++) {
      const date = moment().subtract(i, "month");
      pageData.value.billDates.push({
        id: date.format("MM-yyyy"),
        month: date.format("MMMM yyyy"),
        total: ApiHelper.dollarFormat(ApiHelper.randomNumber(1000, 10000))
      });
    }

    // get bills
    try {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/entities/bills",
        {},
        {},
        ApiType.CORE
      );
      if (result.status == 1) {
        pageData.value.priorBills = result.data.priorBills || [];
        pageData.value.currentBill = result.data.currentBill;
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const changeBillDate = async () => {
    pageData.value.controls.date.error = "";
    // get bills
    // try {
    //   ApiHelper.setDataLoading(true);
    //   const result = await ApiHelper.callApi(
    //     "get",
    //     "/entities/billByDate",
    //     {},
    //     {
    //       billDate: pageData.value.controls.date.value
    //     },
    //     ApiType.CORE
    //   );
    //   if (result.status == 1) {
    //     pageData.value.currentBill = result.data.currentBill;
    //   }
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   ApiHelper.setDataLoading(false);
    // }
  };

  const exportBillDetails = async () => {
    const billDate = pageData.value.controls.date.value;
    if (billDate == "") {
      pageData.value.controls.date.error = "Billing date is required";
      return;
    }

    try {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/entities/billDetails",
        {},
        {
          billDate
        },
        ApiType.CORE
      );
      if (result.status == 1) {
        const billDetails = result.data.billDetails || [];
        const exportData = billDetails.map((item: any) => {
          return [
            ApiHelper.getFullName(item.firstName, item.lastName),
            item.amountFormatted
          ];
        });
        const headers = ["PARTICIPANT", "AMOUNT"];
        const fileName = `Billing${billDate}_${moment().format(
          "MMDDYYYY_HHMMSS"
        )}`;
        await ApiHelper.generateCsv(exportData, headers, fileName);
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
      pageData.value.controls.date.value = "";
    }
  };

  const printBillDetails = async () => {
    const billDate = pageData.value.controls.date.value;
    if (billDate == "") {
      pageData.value.controls.date.error = "Billing date is required";
      return;
    }

    try {
      ApiHelper.setDataLoading(true);
      pageData.value.printData = [];
      const result = await ApiHelper.callApi(
        "get",
        "/entities/billDetails",
        {},
        {
          billDate,
          print: 1
        },
        ApiType.CORE
      );
      if (result.status == 1) {
        const downloadFileUrl = result.data.downloadFileUrl;
        if (downloadFileUrl != "") {
          ApiHelper.downloadFileUrl(downloadFileUrl);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
      pageData.value.controls.date.value = "";
    }
  };

  // init data
  (async () => {
    pageData.value.controls.date.options = [];
    for (let i = 0; i < 12; i++) {
      const date = moment().subtract(i, "month");
      pageData.value.controls.date.options.push({
        id: date.format("MM-yyyy"),
        text: date.format("MMMM yyyy")
      });
    }
    await loadData(1);
  })();

  (async () => {
    await ApiHelper.synzUserViewLogs(context);
  })();

  return {
    // formData,
    pageData,
    changeBillDate,
    exportBillDetails,
    printBillDetails
  };
}
