import { h, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { dollarFormat } from "../../helpers/ApiHelper";
export function useProfileApplicationStore(context: any) {
  const selectedProfileId = parseInt(context.root.$route.params.profileId);
  const selectedParticipantId = parseInt(context.root.$route.query.id);
  const popupEditApplication = ref({
    show: false,
    profileId: selectedProfileId,
    participantId: selectedParticipantId
  });
  const sidebarData = ref({
    isLoading: true,
    logo: "",
    name: "",
    profileId: 0,
    participantId: 0,
    status: 0,
    eventId: 0,
    eventName: "",
    pTypeName: "",
    paidAmount: 0,
    paidAmountFormatted: "",
    financials: {
      totalPaid: 0
    }
  });
  const headerData = ref({
    isLoaded: false,
    title: "",
    subTitle: "",
    participantStatus: 2
  });
  const pageData = ref<{
    isLoading: boolean;
    app: any;
    appMenu: any[];
    paying: boolean;
    transferVisible: boolean;
    birthdaySelectUID: string;
    birthday: string;
    totalPercent: number;
    questionsNeeded: number;
    questionsDone: number;
  }>({
    isLoading: true,
    app: null,
    appMenu: [],
    paying: false,
    transferVisible: false,
    birthdaySelectUID: "",
    birthday: "",
    totalPercent: 0,
    questionsNeeded: 0,
    questionsDone: 0
  });
  const siderbar = ref<any[]>([]);
  const app = ref<any>({
    addOns: [],
    dynamicAdditionalCosts: []
  });

  const loadData = async () => {
    ApiHelper.setDataLoading(true);
    const participantId = context.root.$route.query.id
      ? Number.parseInt(context.root.$route.query.id + "", 10) || 0
      : 0;
    const result = await ApiHelper.callApi(
      "get",
      "/participants/application",
      {},
      {
        participantId: participantId,
        profileId: selectedProfileId
      }
    );
    ApiHelper.setDataLoading(false);
    headerData.value.isLoaded = false;
    if (result.status !== 1) {
      ApiHelper.showErrorMessage(result.message, "Oops");
      // ApiHelper.gotoPage(context, { name: 'ProfileEvents', params: { profileId: selectedProfileId + '' }})
      return;
    }
    const appInfo = result.data.app || {};
    const appMenu = result.data.appMenu || [];
    const participant = result.data.participantInfo?.participant || {};
    const totalPaid = result.data.participantInfo?.totalPaid || 0;
    headerData.value.participantStatus = participant.participantStatus || 0;
    headerData.value.subTitle =
      ApiHelper.getFullName(appInfo.firstName || "", appInfo.lastName || "") +
      " - " +
      appInfo.eventName;
    headerData.value.title = "Application"; // appInfo.eventName;
    headerData.value.isLoaded = true;
    pageData.value.app = appInfo;
    pageData.value.appMenu = appMenu;

    // sidebar data
    sidebarData.value.name = appInfo.eventName;
    sidebarData.value.profileId = selectedProfileId;
    sidebarData.value.participantId = participantId;
    sidebarData.value.eventId = participant.eventId || 0;
    sidebarData.value.eventName = participant.eventName || "";
    sidebarData.value.pTypeName = participant.participantTypeName || "";
    sidebarData.value.status = participant.participantStatus || 0;
    sidebarData.value.paidAmount = totalPaid || 0;
    sidebarData.value.paidAmountFormatted = dollarFormat(
      sidebarData.value.paidAmount
    );
    sidebarData.value.financials = {
      totalPaid: sidebarData.value.paidAmount
    };

    sidebarData.value.isLoading = false;

    siderbar.value = pageData.value.appMenu.map((item: any) => {
      item.formRenderInstance = undefined;
      return item;
    });

    // data for profile details module
    app.value.fName = participant.firstName || "";
    app.value.lName = participant.lastName || "";
    app.value.pEmail = participant.email || "";
    app.value.pPhone = participant.phone || "";
    app.value.pGender = participant.gender || "";
    app.value.pAddress = participant.address || "";
    app.value.city = participant.city || "";
    app.value.state = participant.state || "";
    app.value.zip = participant.zip || "";
    app.value.participantId = participantId;
    if (participant.dobFormatted != "") {
      const dobArray = participant.dobFormatted.split("-");
      const pBirthdayM = dobArray[1];
      const pBirthdayD = dobArray[2];
      const pBirthdayY = dobArray[0];
      app.value.birthday = `${pBirthdayY}/${pBirthdayM}/${pBirthdayD}`;
    } else {
      app.value.birthday = "";
    }
  };

  const gotoEditApp = async () => {
    siderbar.value = []; // reset modules to fix conflict with popup
    popupEditApplication.value.show = true;
    /*
    try {
      const result = await ApiHelper.callApi(
        "get",
        `/applications/portalEditLink`,
        {},
        { participantId: selectedParticipantId }
      );
      if (result.status == 1) {
        const portalLink = result.data.portalLink;
        window.open(portalLink || "", "_blank")?.focus();
      }
    } catch (err) {
      console.log(err);
    }
    */
  };

  return {
    popupEditApplication,
    sidebarData,
    siderbar,
    headerData,
    pageData,
    loadData,
    gotoEditApp,
    app
  };
}
