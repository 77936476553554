import { ref } from "@vue/composition-api";
import { PagerItem, SelectOption } from "@/types";
import { ApiHelper } from "@/helpers";
import { FinancialListRows } from "@/helpers/estimateNoOfListRows";
import { dollarFormat } from "../../helpers/ApiHelper";

export function useProfileFinancesStore(context: any) {
  const query = context.root.$route.query;
  const selectedProfileId = parseInt(
    context.root.$route.params.profileId || "0"
  );
  const sidebarData = ref({
    isLoading: true,
    logo: "",
    name: "",
    profileId: 0,
    participantId: 0,
    status: 2,
    eventId: 0,
    eventName: "",
    pTypeName: "",
    paidAmount: 0,
    paidAmountFormatted: "",
    financials: {
      totalPaid: 0
    }
  });
  const headerData = ref({
    isLoaded: false,
    title: "Financial",
    subTitle: ""
  });
  const filterData = ref<{
    participants: SelectOption[];
    events: SelectOption[];
    types: SelectOption[];
    eventId: number;
    profileId: number;
    profile: string;
    amount: string;
    minAmount: string;
    maxAmount: string;
    date: string;
    minDate: string;
    maxDate: string;
  }>({
    participants: [],
    types: [],
    events: [],
    eventId: parseInt(context.root.$route.query.event) || 0,
    profileId: parseInt(context.root.$route.query.profile) || 0,
    profile: context.root.$route.query.key || "",
    amount:
      (context.root.$route.query.minAmount || "") +
      "-" +
      (context.root.$route.query.maxAmount || ""),
    minAmount: context.root.$route.query.minAmount || "",
    maxAmount: context.root.$route.query.maxAmount || "",
    date: context.root.$route.query.key || "",
    minDate: context.root.$route.query.minDate || "",
    maxDate: context.root.$route.query.maxDate || ""
  });
  const currentRoute = "ProfileFinances";
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: any;
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    paying: boolean;
    transferVisible: boolean;
    participantStats: {
      totalCost: number;
      totalCostFormatted: string;
      totalDiscounts: number;
      totalDiscountsFormatted: string;
      balance: number;
      balanceFormatted: string;
    };
    detailsVisible: number;
  }>({
    isLoading: true,
    skip: 0,
    take: FinancialListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "2"
    },
    filter: filterData.value,
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    paying: false,
    transferVisible: false,
    participantStats: {
      totalCost: 0,
      totalCostFormatted: dollarFormat(0),
      totalDiscounts: 0,
      totalDiscountsFormatted: dollarFormat(0),
      balance: 0,
      balanceFormatted: dollarFormat(0)
    },
    detailsVisible: -1
  });

  const popupData = ref({
    profileId: 0,
    participantId: parseInt(context.root.$route.query.id) || 0,
    eventId: parseInt(query.eventId || 0),
    show: false
  });

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: currentRoute,
        params: {
          profileId: selectedProfileId + ""
        },
        query: {
          page: pageData.value.pager.page + "" || undefined,
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          key: pageData.value.filter.profile || undefined,
          profile: (pageData.value.filter.profileId || 0) + "" || undefined,
          event: (pageData.value.filter.eventId || 0) + "" || undefined,
          id: context.root.$route.query.id || undefined,
          events: ApiHelper.convertSelectedOptionsToString(
            pageData.value.filter.events
          ),
          participants:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.participants
            ) || undefined,
          types:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.types
            ) || undefined,
          minAmount: pageData.value.filter.minAmount || undefined,
          maxAmount: pageData.value.filter.maxAmount || undefined,
          minDate: pageData.value.filter.minDate || undefined,
          maxDate: pageData.value.filter.maxDate || undefined
        }
      })
      .catch((err: any) => {
        // nothing
      });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;

    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/transactions",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          profileId: selectedProfileId + "" || undefined,
          participantId: context.root.$route.query.id
            ? Number.parseInt(context.root.$route.query.id + "", 10)
            : undefined,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          eventId: pageData.value.filter.eventId || undefined,
          minAmount: pageData.value.filter.minAmount || undefined,
          maxAmount: pageData.value.filter.maxAmount || undefined,
          minDate: pageData.value.filter.minDate || undefined,
          maxDate: pageData.value.filter.maxDate || undefined,
          participants: context.root.$route.query.participants || undefined,
          events: context.root.$route.query.events || undefined,
          types: context.root.$route.query.types || undefined,
          separateItems: 1
        }
      );
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.items.map((item: any) => {
          const discountInfo = item.discountInfo || [];
          // group by discountId
          const discounts: any = [];
          for (const discount of discountInfo) {
            // for (const t of discount) {
            const inList = discounts.find(
              (d: any) => d.discountId == discount.discountId
            );
            if (!inList) {
              discounts.push({
                discountId: discount.discountId,
                discountName: discount.discountName,
                discountCode: discount.discountCode || "",
                discountAmount: discount.discountAmount,
                discountAmountFormatted: dollarFormat(discount.discountAmount)
              });
            } else {
              inList.discountAmount += discount.discountAmount;
              inList.discountAmount = parseFloat(
                inList.discountAmount.toFixed(2)
              );
              inList.discountAmountFormatted = dollarFormat(
                inList.discountAmount
              );
            }
            // }
          }

          return {
            ...item,
            discountInfo: discounts
          };
        });

        ApiHelper.setDataLoading(false);
      } else if (result.status === -1) {
        ApiHelper.gotoPage(context, { name: "Login" });
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = event.target.value;
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  // load profile info
  const getParticipantInfo = async () => {
    const participantId =
      Number.parseInt(context.root.$route.query.id, 10) || 0;
    if (participantId > 0) {
      const result = await ApiHelper.callApi(
        "get",
        "/participants/" + participantId,
        {},
        {}
      );
      if (result.status === 1) {
        headerData.value.subTitle =
          ApiHelper.getFullName(
            result.data.firstName || "",
            result.data.lastName || ""
          ) +
            " - " +
            result.data.eventName || "";
        sidebarData.value.name = result.data.eventName;
        sidebarData.value.profileId = result.data.profileId || 0;
        sidebarData.value.participantId = result.data.participantId || 0;
        sidebarData.value.eventId = result.data.eventId || 0;
        sidebarData.value.eventName = result.data.eventName || "";
        sidebarData.value.pTypeName = result.data.participantTypeName || "";
        sidebarData.value.status = result.data.participantStatus || 0;
        sidebarData.value.paidAmount = result.data.totalPaid || 0;
        sidebarData.value.paidAmountFormatted = dollarFormat(
          sidebarData.value.paidAmount
        );
        sidebarData.value.financials = {
          totalPaid: sidebarData.value.paidAmount
        };
        sidebarData.value.isLoading = false;
        if (result.data.participantStats) {
          pageData.value.participantStats = result.data.participantStats;
        }
      }
    } else {
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/" + selectedProfileId + "/info",
        {},
        {}
      );
      if (result.status === 1) {
        headerData.value.subTitle = ApiHelper.getFullName(
          result.data.firstName || "",
          result.data.lastName || ""
        );
      }
    }
  };

  // filters
  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const getFiltersData = () => {
    const filters = [];
    const selectedParticipants = pageData.value.filter.participants
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedParticipants.length > 0) {
      filters.push({
        label: "PARTICIPANT",
        key: selectedParticipants.join("-") + Math.random(),
        value: selectedParticipants.join(", "),
        reset: () => {
          pageData.value.filter.participants.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedEvents = pageData.value.filter.events
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.filter.events.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedTypes = pageData.value.filter.types
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedTypes.length > 0) {
      filters.push({
        label: "TYPE",
        key: selectedTypes.join("-") + Math.random(),
        value: selectedTypes.join(", "),
        reset: () => {
          pageData.value.filter.types.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.minAmount || pageData.value.filter.maxAmount) {
      filters.push({
        label: "AMOUNT",
        key: pageData.value.filter.amount + Math.random(),
        value: pageData.value.filter.amount,
        reset: () => {
          pageData.value.filter.amount = "";
          pageData.value.filter.minAmount = "";
          pageData.value.filter.maxAmount = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.date !== "") {
      filters.push({
        label: "DATE",
        key: pageData.value.filter.date + Math.random(),
        value: pageData.value.filter.date,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          loadData(1);
        }
      });
    }

    return {
      list: filters
    };
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "date": {
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.filter.minDate = dateArr[0];
        pageData.value.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        break;
      }
      case "amount": {
        pageData.value.filter.amount = value;
        const amountArr = value.split("-");
        pageData.value.filter.minAmount = amountArr[0];
        pageData.value.filter.maxAmount =
          amountArr[1] !== undefined ? amountArr[1] : "";
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const closePaymentModal = (options: any) => {
    popupData.value.show = false;
    loadData(1);
  };

  const toggleTransactionDetails = async (item: any, index: number) => {
    if (pageData.value.detailsVisible == index) {
      pageData.value.detailsVisible = -1;
    } else {
      pageData.value.detailsVisible = index;
    }
    if (pageData.value.detailsVisible != -1 && !("details" in item)) {
      // get item details
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/transactions/details",
        {},
        {
          transactionId: item.transactionId
        }
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        item.details = {
          transactionDetails: result.data.transactionDetails || [],
          transactionLines: result.data.transactionLines || [],
          transactionInfos: result.data.transactionInfos || [],
          refundLines: result.data.refundLines || [],
          events: result.data.events || []
        };
      } else {
        ApiHelper.showErrorMessage("Transaction not found", "Oops");
      }
    }
  };

  // init data
  (async () => {
    getParticipantInfo();
    loadData(1);

    const result = await ApiHelper.callApi(
      "get",
      "/transactions/filters",
      {},
      {}
    );
    if (result.status === 1) {
      const profileIds = ApiHelper.convertIdsToArray(
        context.root.$route.query.participants || ""
      );
      pageData.value.filter.participants = await result.data.profiles.map(
        (item: any) => {
          return {
            id: item.profileId || 0,
            selected: profileIds.includes(item.profileId),
            text: ApiHelper.getFullName(item.firstName, item.lastName)
          };
        }
      );
      const eventIds = ApiHelper.convertIdsToArray(
        context.root.$route.query.events || ""
      );
      pageData.value.filter.events = await result.data.events.map(
        (item: any) => {
          return {
            id: item.eventId || 0,
            selected: eventIds.includes(item.eventId) ? true : false,
            text: item.eventName || ""
          };
        }
      );
      const typeIds = ApiHelper.convertIdsToArray(
        context.root.$route.query.types || ""
      );
      pageData.value.filter.types = await result.data.transactionTypes.map(
        (item: any) => {
          return {
            id: item.transactionTypeId || 0,
            selected: typeIds.includes(item.transactionTypeId),
            text: item.transactionTypeName || ""
          };
        }
      );
    }
  })();

  return {
    sidebarData,
    loadData,
    headerData,
    pageData,
    updateRouters,
    // filters
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    updateSortValue,
    // pager
    loadList,
    gotoPage,
    onPagerChange,
    onClickPrev,
    onClickNext,
    togglePagerItems,
    popupData,
    closePaymentModal,
    toggleTransactionDetails
  };
}
