import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { PagerItem } from "@/types";
import { SettingsListRows } from "@/helpers/estimateNoOfListRows";
import Vue from "vue";

export function useSettingsUsersStore(context: any) {
  const headerData = ref({
    title: "Users",
    subTitle: "Settings"
  });
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      key: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    foundApps: object[];
    endItemMessage: string;
  }>({
    isLoading: true,
    skip: 0,
    take: SettingsListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {
      key: ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    foundApps: [],
    endItemMessage: ""
  });

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: "SettingsUsers",
        query: {
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          key: pageData.value.filter.key || undefined
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/users",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          event: context.root.$route.query.event || ""
        },
        "core"
      );
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;

        const list = result.data.users.map((item: any) => {
          return {
            id: item.id,
            isChecked: false,
            username: item.username || "",
            name: ApiHelper.getFullName(
              item.firstName || "",
              item.lastName || ""
            ),
            email: item.email || "",
            type: item.type || 0,
            active: item.active ? "Active" : "Inactive",
            isActive: item.active,
            status: item.active,
            groups: item.groups || [],
            isOwner: item.isOwner || false
          };
        });

        pageData.value.items = list;
      } else if (result.status === -1) {
        ApiHelper.gotoPage(context, {
          name: "Login",
          query: { destination: "/settings/users" }
        });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops..");
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };
  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const getFiltersData = () => {
    const filters = [];

    if (pageData.value.filter.key !== "") {
      filters.push({
        label: "KEYWORD",
        key: pageData.value.filter.key + Math.random(),
        value: pageData.value.filter.key,
        reset: () => {
          pageData.value.filter.key = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const doUpdateStatus = async (item: any) => {
    const active = item.isActive;
    if (active) {
      const isConfirmed = await Vue.swal({
        html: "Are you sure you want to disable this user?",
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });
      if (!isConfirmed) {
        return;
      }
    }
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "patch",
      "/users/" + item.id + "/active",
      {
        active: active ? 0 : 1
      },
      {},
      "core"
    );
    ApiHelper.setDataLoading(false);
    if (result.status !== 1) {
      ApiHelper.showErrorMessage(result.message, "Oops");
      return;
    }
    loadList();
  };

  const doResetPassword = async (item: any) => {
    const active = item.isActive;
    if (active) {
      const isConfirmed = await Vue.swal({
        title: "Are you sure?",
        html: `A reset password email will be sent to <strong>${item.email}</strong>.`,
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });
      if (!isConfirmed) {
        return;
      }
    }
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "post",
      "/users/" + item.id + "/reset-password",
      {
        active: active ? 0 : 1
      },
      {},
      "core"
    );
    ApiHelper.setDataLoading(false);
    if (result.status !== 1) {
      ApiHelper.showErrorMessage(result.message, "Oops");
      return;
    }
    ApiHelper.showSuccessMessage("The email was sent");
  };

  // init data
  (async () => {
    loadData(1);
    pageData.value.filter.key = context.root.$route.query.key || "";
  })();

  (async () => {
    await ApiHelper.synzUserViewLogs(context);
  })();

  return {
    doResetPassword,
    doUpdateStatus,
    // page
    headerData,
    pageData,
    // filters
    // --- pager
    loadList,
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData
  };
}
