import { ref } from "@vue/composition-api";

import { ApiHelper } from "@/helpers";
import { FormSelect, FormText } from "@/types";

export function useSettingsParticipantTypesEditStore(context: any) {
  const selectedTypeId = parseInt(context.root.$route.params.typeId) || 0;
  const headerData = ref({
    title: "-",
    subTitle: "-"
  });

  const pageData = ref<{
    isLoading: boolean;
    details: {
      jsonData: string;
    };
  }>({
    isLoading: true,
    details: {
      jsonData: ""
    }
  });

  const participantTypeEditInput = ref({
    name: "",
    photo: "",
    photoBase64: "",
    typeages: "",
    active: 0,
    entityId: 0,
    cost: 0,
    postingcode: "",
    requirephoto: 0
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      glCode: FormText;
      glName: FormText;
      desc: FormText;
      postingCodes: {
        error: string;
        label: string;
        textOverflow: boolean;
        placeholder: string;
        type: string;
        key: string;
        value: any[];
        options: any[];
        suggestTags: any[];
      };
      name: FormText;
      photo: {
        value: string;
        preview: string;
        base64: string;
        label: string;
        isDeleted: boolean;
        error: string;
        required: boolean;
      };
      active: FormSelect;
      requirePhoto: FormSelect;
      emailContent: FormText;
      portalContent: FormText;
      cost: FormText;
      ages: FormText;
      ageFrom: {
        error: string;
        value: string;
      };
      ageTo: {
        error: string;
        value: string;
      };
    };
    removeFieldError: any;
  }>({
    isLoading: true,
    controls: {
      glCode: {
        required: false,
        label: "GL Code",
        placeholder: "GL Code",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100,
        disabled: true
      },
      glName: {
        required: false,
        label: "GL Name",
        placeholder: "GL Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100,
        disabled: true
      },
      desc: {
        required: false,
        label: "Posting Code Name",
        placeholder: "Posting Code Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 255,
        disabled: true
      },
      postingCodes: {
        error: "",
        label: "",
        textOverflow: true,
        placeholder: "Posting Code",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: []
      },
      name: {
        label: "Name",
        required: true,
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 100
      },
      photo: {
        label: "Photo",
        preview: "",
        isDeleted: false,
        base64: "",
        value: "",
        required: true,
        error: ""
      },
      active: {
        label: "Active?",
        error: "",
        type: "dropdown",
        value: "",
        required: false,
        options: [
          {
            id: "1",
            text: "Yes"
          },
          {
            id: "0",
            text: "No"
          }
        ]
      },
      requirePhoto: {
        label: "Require Photo?",
        error: "",
        type: "dropdown",
        value: "",
        required: false,
        options: [
          {
            id: "1",
            text: "Yes"
          },
          {
            id: "0",
            text: "No"
          }
        ]
      },
      emailContent: {
        error: "",
        type: "text",
        value: ""
      },
      portalContent: {
        error: "",
        type: "text",
        value: ""
      },
      cost: {
        error: "",
        type: "text",
        value: ""
      },
      ages: {
        error: "",
        type: "text",
        value: ""
      },
      ageFrom: {
        error: "",
        value: ""
      },
      ageTo: {
        error: "",
        value: ""
      }
    },
    removeFieldError: (name: string) => {
      switch (name) {
        case "name":
          formData.value.controls.name.error = "";
          break;
        case "active":
          formData.value.controls.active.error = "";
          break;
        case "emailContent":
          formData.value.controls.emailContent.error = "";
          break;
        case "portalContent":
          formData.value.controls.portalContent.error = "";
          break;
        case "cost":
          formData.value.controls.cost.error = "";
          break;
        case "ages":
          formData.value.controls.ages.error = "";
          break;
        default:
          // do nothing
          break;
      }
    }
  });

  const loadData = () => {
    ApiHelper.setDataLoading(true);
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/participants/ptypes/" + selectedTypeId,
        {},
        {}
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const resultData = result.data || null;
        if (resultData) {
          headerData.value.title = resultData.name || "";
          headerData.value.subTitle = "Settings - Participant Types";
          // init forms
          formData.value.controls.active.value = resultData.active || "0";
          formData.value.controls.requirePhoto.value =
            resultData.requirephoto || "0";
          formData.value.controls.name.value = resultData.name || "";
          formData.value.controls.photo.value = resultData.photo || "";
          formData.value.controls.photo.preview = resultData.photo || "";

          formData.value.controls.cost.value = resultData.cost || "";
          formData.value.controls.ages.value = resultData.typeages || "";
          formData.value.controls.ageFrom.value =
            resultData.typeages.split("-")[0] || "";
          formData.value.controls.ageTo.value =
            resultData.typeages.split("-")[1] || "";
          formData.value.controls.postingCodes.value = resultData.postingcode
            ? [
                {
                  id: resultData.postingcode,
                  text: `${resultData.postingcode}`
                }
              ]
            : [];
          formData.value.controls.desc.value = resultData.postingCodeDesc || "";
          formData.value.controls.desc.disabled = true;
          formData.value.controls.glCode.value = resultData.glCode || "";
          formData.value.controls.glCode.disabled = true;
          formData.value.controls.glName.value = resultData.glName || "";
          formData.value.controls.glName.disabled = true;
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "SettingsParticipantTypes" });
      }
    })();
  };

  // init data
  (async () => {
    loadData();
  })();

  const onFormSubmit = () => {
    participantTypeEditInput.value.name = formData.value.controls.name.value.trim();
    participantTypeEditInput.value.photoBase64 =
      formData.value.controls.photo.base64;
    participantTypeEditInput.value.photo = formData.value.controls.photo
      .isDeleted
      ? ""
      : formData.value.controls.photo.value;
    participantTypeEditInput.value.cost = parseFloat(
      formData.value.controls.cost.value
    );
    participantTypeEditInput.value.typeages =
      formData.value.controls.ages.value;
    participantTypeEditInput.value.active = parseInt(
      formData.value.controls.active.value
    );
    participantTypeEditInput.value.requirephoto = parseInt(
      formData.value.controls.requirePhoto.value
    );
    participantTypeEditInput.value.entityId = 1;
    let hasError = false;
    if (participantTypeEditInput.value.name === "") {
      hasError = true;
      formData.value.controls.name.error = "Name is required!";
    }
    // Handle age wrong
    if (
      parseInt(formData.value.controls.ageFrom.value) >
      parseInt(formData.value.controls.ageTo.value)
    ) {
      formData.value.controls.ageTo.error = "Oop";
      hasError = true;
    }
    // if (
    //   !participantTypeEditInput.value.photo &&
    //   !participantTypeEditInput.value.photoBase64
    // ) {
    //   hasError = true;
    //   formData.value.controls.photo.error = "Photo is required!";
    // }

    if (participantTypeEditInput.value.cost < 0) {
      hasError = true;
      formData.value.controls.cost.error = "Cost must greater than 0";
    } else {
      formData.value.controls.cost.error = "";
    }
    participantTypeEditInput.value.postingcode = formData.value.controls
      .postingCodes.value.length
      ? formData.value.controls.postingCodes.value[0].text || ""
      : "";

    if (
      participantTypeEditInput.value.postingcode ||
      formData.value.controls.glCode.value ||
      formData.value.controls.desc.value ||
      formData.value.controls.glName.value
    ) {
      if (!participantTypeEditInput.value.postingcode) {
        formData.value.controls.postingCodes.error =
          "Posting code is required!";
        hasError = true;
      }
      if (!formData.value.controls.glCode.value) {
        formData.value.controls.glCode.error = "GL code is required!";
        formData.value.controls.glCode.disabled = false;
        hasError = true;
      }
      if (!formData.value.controls.glName.value) {
        formData.value.controls.glName.error = "GL code is required!";
        formData.value.controls.glName.disabled = false;
        hasError = true;
      }
      if (!formData.value.controls.desc.value) {
        formData.value.controls.desc.error = "Name code is required!";
        formData.value.controls.desc.disabled = false;
        hasError = true;
      }
    }

    if (!hasError) {
      ApiHelper.setDataLoading(true);
      (async () => {
        const result = await ApiHelper.callApi(
          "put",
          "/participants/ptypes/" + selectedTypeId,
          participantTypeEditInput.value,
          {}
        );
        ApiHelper.setDataLoading(false);
        if (result.status == 1) {
          ApiHelper.showSuccessMessage("Updated");
          context.root.$router.replace({ name: "SettingsParticipantTypes" });
          // Call API create posting code/GL
          if (
            participantTypeEditInput.value.postingcode != "" &&
            formData.value.controls.glCode.value &&
            formData.value.controls.glName.value &&
            formData.value.controls.desc.value
          ) {
            await ApiHelper.callApi(
              "put",
              `/accounting/postingcodes/0`,
              {
                code: participantTypeEditInput.value.postingcode,
                glCode: formData.value.controls.glCode.value,
                glName: formData.value.controls.glName.value,
                desc: formData.value.controls.desc.value
              },
              {}
            );
          }
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      })();
    }
  };

  return {
    formData,
    onFormSubmit,
    headerData,
    pageData
  };
}
