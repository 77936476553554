
















































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import DatePicker from "vue2-datepicker";
import Inputmask from "inputmask";
import $ from "jquery";

export default defineComponent({
  name: "FormDatePicker",
  components: {
    DatePicker
  },
  props: {
    data: Object,
    addTopPopOver: {
      type: Boolean,
      default: false
    },
    onChange: Function,
    disabled: {
      type: Boolean,
      defaultValue: false
    }
  },
  setup(props, context) {
    const randomId = ref<string>(ApiHelper.randomFormId().toString());
    const doChange = () => {
      if (props.onChange) {
        props.onChange();
      }
    };
    const disabledDate = (date: any) => {
      if (
        props.data &&
        (props.data.disabledBefore || props.data.disabledAfter)
      ) {
        if (props.data.disabledBefore && date < props.data.disabledBefore) {
          return true;
        }
        if (props.data.disabledAfter && date > props.data.disabledAfter) {
          return true;
        }
        return false;
      }
      return false;
    };
    return {
      disabledDate,
      randomId,
      doChange
    };
  },
  mounted() {
    if ($("#datepicker-" + this.randomId).hasClass("date")) {
      Inputmask("99/99/9999", {
        alias: "date",
        placeholder: "MM/DD/YYYY",
        insertMode: false
      }).mask($("#datepicker-" + this.randomId).find(".mx-input"));
    }
  }
});
