import { ApiHelper } from "@/helpers";
import { PopupDormGeneratorConfirm } from "./EventCabinsStore";

export const eventCabinDormGeneratorStore: {
  state: PopupDormGeneratorConfirm;
  setData: (
    dorm: any,
    requestRoomates: any,
    inputData: any,
    participantTypes: any,
    pGroupList: any
  ) => void;
  getSubmitData: () => any;
  appendRoomates: any;
} = {
  state: {
    show: false,
    showConfirmRoommate: false,
    data: [],
    dragElement: {},
    dropList: [],
    dragDorm: "",
    dropDorm: "",
    maxParticipant: 0,
    roommateRequests: [],
    isLoading: false
  },
  setData(dorm, requestRoomates, inputData, participantTypes, pGroupList) {
    this.state.data = dorm;
    this.state.roommateRequests = requestRoomates;
    this.state.inputData = inputData;
    this.state.participantTypes = participantTypes;
    this.state.pGroupList = pGroupList;
  },
  appendRoomates(participantList: any) {
    const allParticipanList = participantList.reduce((list: any, item: any) => {
      const participantId = item.id;
      const relatedRoommates: any = this.state?.roommateRequests
        .filter((rm: any) => rm.toParticipantId == participantId)
        .map((rm: any) => ({
          profileId: rm.invitedProfileId,
          id: rm.invitedParticipantId,
          name: ApiHelper.getFullName(rm.invitedFname, rm.invitedLname),
          age: rm.invitedAge,
          gender: rm.invitedGender,
          requestId: rm.requestId,
          state: rm.invitedState
        }));
      return [
        ...list,
        { ...item, roomMateRequestCount: relatedRoommates.length },
        ...relatedRoommates
      ];
    }, []);

    console.log({ allParticipanList });
    return allParticipanList;
  },
  getSubmitData() {
    const data = this.state.data.map(mdorm => ({
      ...mdorm
      // participantList: mdorm.participants.filter(
      //   (fitem: any) => !fitem.requestId
      // )
    }));
    const roommateRequests = this.state.data.reduce(
      (rlist, mdorm) => [
        ...rlist,
        mdorm.participants.filter((fitem: any) => fitem.requestId)
      ],
      []
    );
    return { ...this.state, data, roommateRequests };
  }
};
