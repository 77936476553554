




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import DatePicker from "vue2-datepicker";

import Loading from "@/components/Common/Loading.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import { useEventsAddStore } from "@/stores/Event/EventsAddStore";
import FormButton from "@/components/Form/FormButton.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import Modal from "@/components/Common/Modal.vue";
import { ApiHelper } from "@/helpers";
import FormDate from "@/components/Form/FormDate.vue";
import EventAddons from "@/components/Event/EventAddons.vue";
import directives from "@/helpers/directives";
import moment from "moment";
import FormToggle from "@/components/Form/FormToggle.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import EventAddDiscounts from "@/components/Event/EventAddDiscounts.vue";
import CopyEventSettings from "@/components/Event/CopyEventSettings.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormTags from "@/components/Form/FormTags.vue";
import FormDatePicker from "@/components/Form/FormDatePicker.vue";
import DropdownBox from "@/components/Common/DropdownBox.vue";
import FormText from "@/types/FormText";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import VScroller from "@/components/Common/VScroller.vue";
import PopupEditCostCenter from "@/components/Event/PopupEditCostCenter.vue";
import EventAddPtypes from "@/components/Event/EventAddPtypes.vue";
import EventAddServices from "@/components/Event/EventAddServices.vue";
import PopupEditEventType from "@/components/Event/PopupEditEventType.vue";
import EventGroups from "@/components/Event/EventGroups.vue";

export default defineComponent({
  name: "EventsAddPage",
  components: {
    EventAddServices,
    EventAddPtypes,
    PopupEditCostCenter,
    VScroller,
    DropdownBox,
    FormDatePicker,
    FormInput,
    EventAddDiscounts,
    FormDate,
    DatePicker,
    Loading,
    Select2,
    FormButton,
    AutoCompleteInput,
    Modal,
    EventAddons,
    FormToggle,
    FormSelect,
    FormTags,
    CopyEventSettings,
    FormTextarea,
    PopupEditEventType,
    EventGroups
  },
  directives: directives,
  setup(props, context) {
    const isShowEventService = ref<boolean>(true);
    const isShowEventPType = ref<boolean>(true);
    const isShowEventDiscount = ref<boolean>(true);
    const dataEventService = ref<string>("");
    const dataEventPType = ref<string>("");
    const dataEventDiscount = ref<string>("");
    const isShowEventGroup = ref<boolean>(true);
    const dataEventGroup = ref<string>("");

    const {
      pageData,
      updatePtypesList,
      updateServicesList,
      copyEventSettings,
      allowDeletePtype,
      onAddTag,
      onSelectTag,
      onRemoveLastTag,
      onRemoveTag,
      onSuggestTags,
      discountsStore,
      updateDiscounts,
      suggestLocation,
      updateLocation,
      formData,
      linkEventParticipants,
      linkEventServices,
      // showLinkParticipant,
      removeFieldError,
      onSubmit,
      suggestPTypes,
      selectPType,
      popupNewPTypeCol,
      capacityInput,
      isACILoading,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onRemoveCostCenter,
      onSelectCostCenter,
      onAddNewCostCenter,
      newPostingCode,
      glCodes,
      componentData,
      popupCostCenter,
      onTabPostingCostCenter,
      isAllowAddNewEventType,
      onAddNewEventType,
      popupEventType,
      onRemoveEventType,
      onSuggestEventType,
      onRemoveLastEventType,
      onSelectEventType
    } = useEventsAddStore(context);

    const updateEndDate = () => {
      if (formData.value.controls.tsStart.value) {
        const selectedStartDate = moment(
          formData.value.controls.tsStart.value
        ).add(7, "day");
        formData.value.controls.tsEnd.value = selectedStartDate.toDate();
        const tsEndDateChild: any = context.refs.tsEndDatePicker;
        if (tsEndDateChild) {
          tsEndDateChild.openDate(tsEndDateChild.randomId);
        }
      }
      formData.value.controls.tsStart.error = "";
      formData.value.controls.tsEnd.error = "";
    };

    const popupCopySettings = ref({
      show: false
    });

    // posting codes
    const onSuggestPostingCodes = async (key: string, selectedItem: any) => {
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/finances/postingCodes",
        {},
        {
          key,
          notInIds
        }
      );
      if (result.status === 1) {
        selectedItem.suggestTags = result.data.items
          .filter((item: any) => item.postingCodeDesc != "")
          .map((item: any) => {
            return {
              id: item.postingCode,
              // text: `${item.postingCode} / ${item.glCode}`,
              text: item.postingCodeDesc,
              data: item
            };
          });
      }
    };

    const onRemoveLastPostingCode = (selectedItem: any) => {
      // if (selectedItem.value.length > 0 && selectedItem.key === "") {
      //   const index = selectedItem.value.length - 1;
      //   selectedItem.value.splice(index, 1);
      // }
    };
    const onRemovePostingCodes = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectPostingCode = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        // text: item.text
        text: `${item.data.postingCode} / ${item.data.glCode}`
      });
    };

    const showNewPostingCodeModal = (
      postingCode: string,
      selectedAddon: any,
      index: number
    ) => {
      if (postingCode == "") return;

      const postingCodeRef: any = context.refs.postingCodeRef;

      // auto selecting if existed in suggested items
      const existed = selectedAddon.postingCodes.suggestTags.find(
        (item: any) => `${item.id}`.toLowerCase() == postingCode.toLowerCase()
      );
      if (existed) {
        // auto select
        selectedAddon.postingCodes.value.push({
          id: existed.id,
          text: existed.text
        });
        selectedAddon.postingCodes.key = "";
        if (postingCodeRef[index]) {
          postingCodeRef[index].show = false;
        }

        return;
      }
      componentData.value.controls.postingCode.value = postingCode;
      newPostingCode.value.modalVisible = true;
      newPostingCode.value.postingCode = postingCode;
      newPostingCode.value.currentAddOn = selectedAddon;
      if (postingCodeRef[index]) {
        postingCodeRef[index].show = false;
      }
    };

    const onSuggestGLCode = async () => {
      const notInIds = glCodes.value.value
        .map((item: any) => item.id)
        .join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/glCodes",
        {},
        {
          gl: glCodes.value.key || "",
          notInIds
        }
      );
      if (result.status === 1) {
        glCodes.value.suggestTags = result.data.items.map((code: string) => {
          return {
            id: code,
            text: code
          };
        });
      }
    };

    const onSelectGLCode = (selectedValue: any) => {
      glCodes.value.error = "";
      glCodes.value.key = selectedValue.id;
      glCodes.value.suggestTags = [];
    };

    const savePostingCode = async () => {
      // const postingCode = newPostingCode.value.postingCode || "";
      const postingCode = componentData.value.controls.postingCode.value;
      const glCode = glCodes.value.key || "";
      const addonServiceId = newPostingCode.value.currentAddOn.uuid || 0;
      // reset
      glCodes.value.error = "";

      // validate
      let valid = true;
      // if (postingCode == "") return;
      if (glCode == "") {
        valid = false;
        glCodes.value.error = "GL Code is required!";
      }
      if (componentData.value.controls.postingCode.value == "") {
        valid = false;
        componentData.value.controls.postingCode.error =
          "Posting Code is required!";
      }
      if (componentData.value.controls.postingCodeDesc.value == "") {
        valid = false;
        componentData.value.controls.postingCodeDesc.error =
          "Posting Code Name is required!";
      }
      const glName = componentData.value.controls.glName.value;
      if (glName == "") {
        valid = false;
        componentData.value.controls.glName.error = "GL Name is required!";
      }

      if (valid) {
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/postingcodes/0`,
          {
            code: postingCode,
            glCode,
            glName,
            desc: componentData.value.controls.postingCodeDesc.value
          },
          {}
        );
        if (result.status == 1) {
          // update posting codes for current addon
          const currentAddOn: any = linkEventParticipants.value.find(
            item => item.uuid == addonServiceId
          );
          if (currentAddOn) {
            currentAddOn.postingCodes.value.push({
              id: postingCode,
              text: `${postingCode} / ${glCode}`
            });
            currentAddOn.postingCodes.key = "";
          }
          newPostingCode.value.postingCode = "";
          componentData.value.controls.postingCodeDesc.value = "";
          glCodes.value.key = "";
          glCodes.value.value = [];
          glCodes.value.suggestTags = [];
          newPostingCode.value.modalVisible = false;
          newPostingCode.value.currentAddOn = {};
        } else {
          ApiHelper.showErrorMessage(
            result.message || `Can't create new Posting Code`
          );
        }
      }
    };

    // ptype cost center
    const pTypeonSuggestCostCenters = async (
      key: string,
      selectedItem: any
    ) => {
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/costcenters",
        {},
        {
          getAll: 1,
          order: 1,
          direction: 1,
          key,
          notInIds
        }
      );
      isACILoading.value = false;
      if (result.status === 1) {
        selectedItem.suggestTags = result.data.items.map((item: any) => {
          return {
            id: item.costCenterNumber,
            text: item.costCenterName || item.costCenterNumber,
            data: item
          };
        });
      }
    };

    const pTypeonRemoveLastCostCenter = (selectedItem: any) => {
      if (selectedItem.value.length > 0 && selectedItem.key === "") {
        const index = selectedItem.value.length - 1;
        selectedItem.value.splice(index, 1);
      }
    };
    const pTypeonRemoveCostCenter = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const pTypeonSelectCostCenter = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value = [];
      selectedItem.value.push({
        id: item.id,
        text: item.id
      });
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      subItemSelectCostCenter(item);
    };

    const pTypeonAddNewCostCenter = async (
      key: string,
      selectedItem: any,
      index: number
    ) => {
      if (key.trim() == "" || isNaN(key as any)) return;

      const costCenterRef: any = context.refs.costCenterRef;

      // auto selecting if cost center number existed in suggested items
      const existed = selectedItem.suggestTags.find(
        (item: any) => `${item.id}`.toLowerCase() == key.toLowerCase()
      );
      if (existed) {
        // auto select
        selectedItem.value.push({
          id: existed.id,
          text: existed.text
        });
        selectedItem.key = "";
        if (costCenterRef[index]) {
          costCenterRef[index].show = false;
        }
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        subItemSelectCostCenter(existed);

        return;
      }

      // add new cost center number
      const result = await ApiHelper.callApi(
        "put",
        `/accounting/costcenters/0`,
        {
          number: key
        },
        {}
      );
      if (result.status == 1) {
        // update cost centers for current addon
        selectedItem.value.push({
          id: key,
          text: key
        });
        selectedItem.key = "";
        if (costCenterRef[index]) {
          costCenterRef[index].show = false;
        }
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        subItemSelectCostCenter({
          id: key,
          text: key
        });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        return;
      }
    };

    const subItemSelectCostCenter = (item: any) => {
      const costCenterNumber = item.id || "";
      if (costCenterNumber == "") return;

      if (formData.value.controls.eventCostCenters.value.length == 0) {
        formData.value.controls.eventCostCenters.value = [
          {
            id: costCenterNumber,
            text: costCenterNumber
          }
        ];
      }
    };

    const pTypeonFocusCoscenter = (index: number, item: any) => {
      const eventCoscenterNumber: any =
        formData.value.controls.eventCostCenters.value;
      if (
        eventCoscenterNumber.length &&
        item.value.length == 0 &&
        item.key == "" &&
        item.firstFocus
      ) {
        item.firstFocus = false;
        // auto fill coscenter number
        // item.key = eventCoscenterNumber[0].id;
        // auto select
        item.value = [
          {
            id: eventCoscenterNumber[0].id,
            text: eventCoscenterNumber[0].id
          }
        ];
      }
    };

    const isAllowAddNewCostCenter = (item: any, onlyNumber = true) => {
      const regExp = /[a-zA-Z]/g;
      if (
        (item.suggestTags || []).length == 0 &&
        item.key != "" &&
        (!regExp.test(item.key) || !onlyNumber)
      ) {
        return true;
      }

      return false;
    };

    const isAllowAddNewPostingCode = (item: any) => {
      if ((item.suggestTags || []).length == 0 && item.key != "") {
        return true;
      }

      return false;
    };

    const addEventTypeCallBack = (item: any) => {
      if (item) {
        formData.value.controls.eventTypes.value = item;
      }
      formData.value.controls.eventTypes.key = "";
      const costCenterNumber = item[0].data.costCenterNumber || "";
      if (costCenterNumber) {
        formData.value.controls.eventCostCenters.value = [
          {
            id: costCenterNumber,
            text: costCenterNumber
          }
        ];
      }
      popupEventType.value.show = false;
    };

    return {
      pageData,
      updatePtypesList,
      updateServicesList,
      popupCostCenter,
      copyEventSettings,
      popupCopySettings,
      allowDeletePtype,
      onAddTag,
      onSelectTag,
      onRemoveLastTag,
      onRemoveTag,
      onSuggestTags,
      discountsStore,
      updateDiscounts,
      updateEndDate,
      stripTags: ApiHelper.stripTags,
      suggestLocation,
      updateLocation,
      formData,
      linkEventParticipants,
      linkEventServices,
      // showLinkParticipant,
      removeFieldError,
      onSubmit,
      suggestPTypes,
      selectPType,
      popupNewPTypeCol,
      capacityInput,
      isACILoading,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onRemoveCostCenter,
      onSelectCostCenter,
      onAddNewCostCenter,
      // ptype
      onSuggestPostingCodes,
      onRemoveLastPostingCode,
      onRemovePostingCodes,
      onSelectPostingCode,
      showNewPostingCodeModal,
      newPostingCode,
      glCodes,
      onSuggestGLCode,
      onSelectGLCode,
      savePostingCode,
      pTypeonSuggestCostCenters,
      pTypeonRemoveLastCostCenter,
      pTypeonRemoveCostCenter,
      pTypeonSelectCostCenter,
      pTypeonAddNewCostCenter,
      componentData,
      subItemSelectCostCenter,
      pTypeonFocusCoscenter,
      isAllowAddNewCostCenter,
      isAllowAddNewPostingCode,
      onTabPostingCostCenter,
      isShowEventService,
      dataEventService,
      isShowEventPType,
      dataEventPType,
      isShowEventDiscount,
      dataEventDiscount,
      isShowEventGroup,
      dataEventGroup,
      isAllowAddNewEventType,
      onAddNewEventType,
      popupEventType,
      onRemoveEventType,
      onSuggestEventType,
      onRemoveLastEventType,
      onSelectEventType,
      addEventTypeCallBack
    };
  },
  updated() {
    //Load data total on header
    if (this.formData.eventIdCopy) {
      const pType: any = this.$refs.childComponentEventAddPTypes;
      if (pType) {
        pType.loadData();
      }
      const chilService: any = this.$refs.childComponentEventAddServicet;
      if (chilService) {
        chilService.loadData();
        this.formData.eventIdCopy = 0;
      }
    }

    if (this.formData.controls.eventCostCenters.isFirstFocus) {
      const pType: any = this.$refs.childComponentEventAddPTypes;
      if (pType) {
        pType.resetFirtfocus();
      }

      const childDiscount: any = this.$refs.childComponentEventAddDiscount;
      if (childDiscount) {
        childDiscount.resetFirtfocus();
      }

      const chilService: any = this.$refs.childComponentEventAddServicet;
      if (chilService) {
        chilService.resetFirtfocus();
      }

      this.formData.controls.eventCostCenters.isFirstFocus = false;
    }
  },
  methods: {
    ShowEventService(type: string) {
      this.CheckAutoClose();
      if (this.isShowEventService) {
        const chilService: any = this.$refs.childComponentEventAddServicet;
        if (chilService) {
          if (type === "add") {
            chilService.addNewRow();
          } else if (type === "edit") {
            chilService.allowEdit();
          }
          this.dataEventService = JSON.stringify(
            chilService.componentData.items
          );
          this.isShowEventGroup = false;
          this.isShowEventDiscount = false;
          this.isShowEventPType = false;
        }
      } else {
        ApiHelper.showErrorMessage(
          "Please, Complete the section or cancel it",
          "Oops"
        );
      }
    },
    ShowEventPtype(type: string) {
      this.CheckAutoClose();
      if (this.isShowEventPType) {
        const pType: any = this.$refs.childComponentEventAddPTypes;
        if (pType) {
          if (type == "add") {
            pType.addNewRow();
          } else if (type == "edit") {
            pType.allowEdit();
          }
          this.dataEventPType = JSON.stringify(pType.componentData.items);
          this.isShowEventGroup = false;
          this.isShowEventDiscount = false;
          this.isShowEventService = false;
        }
      } else {
        ApiHelper.showErrorMessage(
          "Please, Complete the section or cancel it",
          "Oops"
        );
      }
    },
    ShowEventDiscount(type: string) {
      this.CheckAutoClose();
      if (this.isShowEventDiscount) {
        const discount: any = this.$refs.childComponentEventAddDiscount;
        if (discount) {
          if (type === "add") {
            discount.addNewRow();
          } else if (type === "edit") {
            discount.allowEdit();
          }
          this.dataEventDiscount = JSON.stringify(discount.driver.items);
          this.isShowEventGroup = false;
          this.isShowEventPType = false;
          this.isShowEventService = false;
        }
      } else {
        ApiHelper.showErrorMessage(
          "Please, Complete the section or cancel it",
          "Oops"
        );
      }
    },
    HideEventService() {
      this.isShowEventGroup = true;
      this.isShowEventService = true;
      this.isShowEventDiscount = true;
      this.isShowEventPType = true;
    },
    ShowEventGroup(type: string) {
      this.CheckAutoClose();
      if (this.isShowEventGroup) {
        const group: any = this.$refs.eventGroupsRef;
        if (group) {
          if (type === "add") {
            group.addNewRow();
          } else if (type === "edit") {
            group.allowEdit();
          }
          this.dataEventGroup = JSON.stringify(group.componentData.items);
          this.isShowEventPType = false;
          this.isShowEventDiscount = false;
          this.isShowEventService = false;
        }
      } else {
        ApiHelper.showErrorMessage(
          "Please, Complete the section or cancel it",
          "Oops"
        );
      }
    },
    HideEventGroup() {
      this.isShowEventGroup = true;
      this.isShowEventService = true;
      this.isShowEventDiscount = true;
      this.isShowEventPType = true;
    },
    CheckAutoClose() {
      if (
        this.isShowEventPType &&
        this.isShowEventService &&
        this.isShowEventDiscount &&
        this.isShowEventGroup
      ) {
        return;
      }
      let chilComponent: any = this.$refs.childComponentEventAddServicet;
      let dataCurrentPopup = this.dataEventService;
      if (this.isShowEventDiscount) {
        chilComponent = this.$refs.childComponentEventAddDiscount;
        dataCurrentPopup = this.dataEventDiscount;
      } else if (this.isShowEventPType) {
        chilComponent = this.$refs.childComponentEventAddPTypes;
        dataCurrentPopup = this.dataEventPType;
      } else if (this.isShowEventGroup) {
        chilComponent = this.$refs.eventGroupsRef;
        dataCurrentPopup = this.dataEventGroup;
      }

      if (chilComponent) {
        let itemsData = chilComponent.componentData.items;
        if (chilComponent.driver) {
          itemsData = chilComponent.driver.items;
        }
        if (JSON.stringify(itemsData) == dataCurrentPopup) {
          if (parseInt(chilComponent.componentData.itemEdit.index) == -1) {
            chilComponent.componentData.itemEdit.item =
              itemsData[itemsData.length - 1];
          }
          // Close popup Event
          chilComponent.checkAllowClose(
            chilComponent.componentData.itemEdit.index,
            chilComponent.componentData.itemEdit.item
          );
        }
      }
    }
  }
});
