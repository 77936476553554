




import { Circ, TweenMax } from "gsap";
import { ApiHelper } from "@/helpers";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Counter",
  props: {
    value: Number
  },
  setup() {
    return {};
  },
  mounted() {
    const numberCounters = this.$el.getElementsByClassName(
      "grid__number__count"
    );
    for (let i = 0; i < numberCounters.length; i++) {
      const item = numberCounters[i] || undefined;
      if (item) {
        const counter = { var: 0 };
        const itemAbc = item.attributes.getNamedItem("data-number");
        if (itemAbc) {
          const total = itemAbc.value || 0;
          TweenMax.to(counter, 2, {
            var: total,
            onUpdate: () => {
              const nwc = ApiHelper.numberFormat(counter.var);
              item.innerHTML = nwc;
            },
            ease: Circ.easeOut
          });
        }
      }
    }
  }
});
