var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data.style === 'custom')?_c('div',{staticClass:"group",class:{
      isRequired: _vm.data.required,
      hasError: _vm.data.error !== '',
      'd-flex': _vm.data.isInline,
      'pb-2': _vm.data.isInline
    }},[_c('label',{staticClass:"label__full",class:{
        'w-50': _vm.data.isInline
      },attrs:{"for":_vm.randomId}},[_vm._v(_vm._s(_vm.data.label))]),_c('div',{staticClass:"group__input",class:{
        'w-50': _vm.data.isInline
      }},[_c('input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"},{name:"model",rawName:"v-model",value:(_vm.data.value),expression:"data.value"}],staticClass:"input__full",attrs:{"id":_vm.randomId,"placeholder":_vm.data.placeholder,"pattern":"^[1-9]+[0-9]*$","readonly":_vm.data.readonly,"maxlength":_vm.data.maxlength,"max":_vm.data.max,"min":_vm.data.min},domProps:{"value":(_vm.data.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "value", $event.target.value)},function($event){_vm.data.error = ''}]}}),_c('span'),(_vm.data.error)?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e()])]):_vm._e(),(_vm.data.style !== 'custom')?_c('div',{staticClass:"form-group",class:{
      isRequired: _vm.data.required,
      hasError: _vm.data.error !== ''
    }},[(_vm.data.label)?_c('label',{attrs:{"for":_vm.randomId}},[_vm._v(_vm._s(_vm.data.label))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.value),expression:"data.value"},{name:"numericOnly",rawName:"v-numericOnly"}],staticClass:"form-control",attrs:{"id":_vm.randomId,"placeholder":_vm.data.placeholder,"readonly":_vm.data.readonly,"maxlength":_vm.data.maxlength,"max":_vm.data.max,"min":_vm.data.min,"pattern":"^[1-9]+[0-9]*$"},domProps:{"value":(_vm.data.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "value", $event.target.value)},function($event){_vm.data.error = ''}]}}),(_vm.data.error !== '')?_c('span',{staticClass:"error text-danger"},[_vm._v(_vm._s(_vm.data.error))]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }