





























import Vue from "vue";

export default Vue.extend({
  name: "Pager",
  props: {
    tabs: Array
  },
  setup(props, context) {
    const toggleCustomize = () => {
      context.emit("toggleCustomize");
    };
    return {
      toggleCustomize
    };
  }
});
