var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"size":"large","is-loading":_vm.componentData.isLoading}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"modal-header",attrs:{"slot":"header"},slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Add New Event Type")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.$emit('dismiss')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),_c('div',{staticStyle:{"max-height":"calc(100vh - 300px)"},attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"content_add"},[_c('div',{staticClass:"content__form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-6"},[_c('FormInput',{attrs:{"data":_vm.formData.controls.name}})],1)]),_c('div',{staticClass:"row"},[(_vm.componentData.showCostCenter)?_c('div',{staticClass:"col col-12 col-md-6"},[_c('FormTags',{ref:"eventCostCenterRef",staticClass:"cost-centers-list",class:{
                'allow-add-new': _vm.isAllowAddNewCostCenter(
                  _vm.formData.controls.costCenter,
                  false
                )
              },attrs:{"addNew":_vm.onAddNewCostCenter,"onRemoveTag":_vm.onRemoveCostCenter,"onSuggestTags":_vm.onSuggestCostCenters,"onRemoveLastTag":_vm.onRemoveLastCostCenter,"onSelectTag":_vm.onSelectCostCenter,"suggestOnFocus":true,"disabled":false,"data":_vm.formData.controls.costCenter,"inputTimeoutSecond":300,"noRecordMessage":!_vm.isAllowAddNewCostCenter(_vm.formData.controls.costCenter, false)
                  ? "No Cost Centers Found"
                  : ("<div class='cursor-pointer d-flex textOverflow' style='max-width: 90%;'>\n                              <strong title='" + (_vm.formData.controls.costCenter.key) + "'>\n                                " + (_vm.formData.controls.costCenter.key) + "\n                              </strong>\n                            </div>\n                          "),"isNormal":true,"multiSelection":false,"nowrapSuggestedItems":true},on:{"focus":_vm.onSuggestCostCenters,"addNewClick":function($event){return _vm.onAddNewCostCenter(_vm.formData.controls.costCenter.key)},"onTab":function (e) { return _vm.onTabPostingCostCenter(
                    e,
                    'costcenter',
                    _vm.formData.controls.costCenter,
                    0,
                    function () {
                      _vm.onAddNewCostCenter(_vm.formData.controls.costCenter.key);
                    }
                  ); }}})],1):_vm._e()])])]),(_vm.popupCostCenter.show)?_c('PopupEditCostCenter',{staticClass:"edit-cost-center-modal",attrs:{"defaultName":isNaN(_vm.popupCostCenter.key) ? _vm.popupCostCenter.key : '',"defaultNumber":isNaN(_vm.popupCostCenter.key) ? '' : _vm.popupCostCenter.key},on:{"dismiss":function () {
          _vm.popupCostCenter.show = false;
        },"callback":function (item) {
          if (item) {
            _vm.formData.controls.costCenter.value = item;
          }
          _vm.formData.controls.costCenter.key = '';
          _vm.popupCostCenter.show = false;
        }}}):_vm._e()],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"form-buttons"},[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.formData.actions.onSubmit}},[_vm._v(" Save ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }