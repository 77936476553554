var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"UserForgotPasswordPage"},[(_vm.pageData.form.isLoading)?_c('Loading'):_vm._e(),_c('div',{staticClass:"content_add"},[_c('div',{staticClass:"content__form"},[_c('form',{attrs:{"accept-charset":"utf-8","autocomplete":"new-password"},on:{"submit":_vm.formData.actions.onSubmit}},[_c('div',{staticClass:"form__info"},[_c('div',{staticClass:"group"},[_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.controls.password.value),expression:"formData.controls.password.value"}],staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-12 col-lg-12 pb-3"},[_c('div',{staticStyle:{"border":"1px solid #eee","padding":"10px","max-width":"100%","min-height":"100%","background-color":"#FFFAF0"}},[_c('ul',{staticClass:"password-rules",class:{
                      checking: _vm.formData.controls.password.value
                    }},[_c('li',{class:{
                        valid: _vm.passwordData.has8characters,
                        invalid: !_vm.passwordData.has8characters
                      }},[_vm._v(" PASSWORD MUST BE AT LEAST 8 CHARACTERS ")]),_c('li',{class:{
                        valid: _vm.passwordData.hasCapital,
                        invalid: !_vm.passwordData.hasCapital
                      }},[_vm._v(" PASSWORD MUST HAVE 1 UPPERCASE LETTER ")]),_c('li',{class:{
                        valid: _vm.passwordData.hasNumber,
                        invalid: !_vm.passwordData.hasNumber
                      }},[_vm._v(" PASSWORD MUST AT LEAST HAVE 1 NUMBER ")]),_c('li',{class:{
                        valid: _vm.passwordData.nonHtml,
                        invalid: !_vm.passwordData.nonHtml
                      }},[_vm._v(" NON HTML CONTENT ")]),_c('li',{class:{
                        valid:
                          _vm.formData.controls.password.value ==
                          _vm.formData.controls.confirmPassword.value,
                        invalid:
                          _vm.formData.controls.password.value !=
                          _vm.formData.controls.confirmPassword.value
                      }},[_vm._v(" PASSWORD MUST MATCH ")])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-12 col-lg-12"},[_c('FormPassword',{attrs:{"data":_vm.formData.controls.password},on:{"input":function($event){return _vm.checkStrongPassword(_vm.formData.controls.password.value)}}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-12 col-lg-12"},[_c('FormPassword',{attrs:{"data":_vm.formData.controls.confirmPassword}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12",staticStyle:{"padding-top":"20px"}},[_c('FormButton',{attrs:{"type":"primary","label":"Reset Password "}})],1)])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',{staticClass:"text-center"},[_vm._v("Password Reset")])])])}]

export { render, staticRenderFns }