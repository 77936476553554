import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { SelectOption } from "@/types";
import moment from "moment";
export function useSettingsApplicationsDetailsStore(context: any) {
  const selectedAppId = parseInt(context.root.$route.params.appId) || 0;
  const headerData = ref({
    title: "-",
    subTitle: "-"
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      name: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      json: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      events: {
        label?: string;
        error: string;
        type: string;
        value: string[];
        options: SelectOption[];
      };
      participantTypes: {
        label?: string;
        error: string;
        type: string;
        value: string[];
        options: SelectOption[];
      };
    };
  }>({
    isLoading: true,
    controls: {
      name: {
        error: "",
        type: "text",
        value: ""
      },
      json: {
        error: "",
        type: "text",
        value: ""
      },
      events: {
        error: "",
        type: "select2",
        value: [],
        options: []
      },
      participantTypes: {
        error: "",
        type: "select2",
        value: [],
        options: []
      }
    }
  });

  const pageData = ref<{
    isLoading: boolean;
    details: {
      jsonData: string;
      events: any[];
      participantTypes: any[];
    };
    modules: any;
  }>({
    isLoading: true,
    details: {
      jsonData: "",
      events: [],
      participantTypes: []
    },
    modules: []
  });

  const loadData = async () => {
    try {
      ApiHelper.setDataLoading(true);
      if (selectedAppId > 0) {
        // get app
        const result = await ApiHelper.callApi(
          "get",
          `/applications/${selectedAppId}`
        );
        if (result.status == 1) {
          headerData.value.title = result.data.appName || "";
          headerData.value.subTitle = "Settings - Applications";
          pageData.value.modules = (result.data.modules || []).map(
            (item: any) => {
              const moduleTs = moment(item.moduleTs);
              let lastUpdated = "N/A";
              if (moduleTs.isValid()) {
                lastUpdated = moduleTs.format("MM/DD/YY");
              }
              return {
                ...item,
                formBuilder: null,
                searchFieldTimeout: null,
                loadInAppsContentTimeout: null,
                jsonData: JSON.parse(item.moduleVersionJson || "[]"),
                lastUpdated
              };
            }
          );
          pageData.value.details.events = result.data.events || [];
          pageData.value.details.participantTypes =
            result.data.participantTypes || [];
        } else {
          // not found
          ApiHelper.showErrorMessage(result.message, "Oops");
          ApiHelper.gotoPage(context, { name: "SettingsApplications" });
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  return {
    headerData,
    pageData,
    loadData
  };
}
