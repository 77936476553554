


























































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormInput from "@/components/Form/FormPhone.vue";
import { ApiHelper } from "@/helpers";
import directives from "@/helpers/directives";
import { PagerItem } from "@/types";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import VScroller from "@/components/Common/VScroller.vue";
import Pager from "@/components/Common/Pager.vue";

export default defineComponent({
  name: "DiscountDetails",
  props: {
    onDismiss: Function,
    onCallback: Function,
    eventId: Number,
    discountId: Number,
    discountDetails: Object
  },
  directives,
  components: {
    Modal,
    FormSelect,
    FormNumber,
    FormTextarea,
    FormInput,
    TableHeaderColumn,
    FilterCheckboxesColumn,
    FilterSearchColumn,
    FilterNumberRangeColumn,
    VScroller,
    Pager
  },
  setup(props, context) {
    const pageData = ref<{
      isLoading: boolean;
      skip: number;
      take: number;
      items: any[];
      activeTab: string;
      sort: {
        order: string;
        direction: string;
      };
      filter: {
        profile: string;
      };
      pager: {
        showPagerItems: boolean;
        page: number;
        totalPages: number;
        total: number;
        items: PagerItem[];
      };
    }>({
      isLoading: true,
      skip: 0,
      take: 8,
      items: [],
      activeTab: "",
      sort: {
        order: "1",
        direction: "2"
      },
      filter: {
        profile: ""
      },
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      }
    });

    const closePopup = () => {
      if (props.onDismiss) {
        props.onDismiss();
      }
    };

    const getAppliedDiscountDetails = async (page: number) => {
      pageData.value.pager.page = page || 1;
      pageData.value.isLoading = true;
      pageData.value.skip =
        (pageData.value.pager.page - 1) * pageData.value.take;
      await context.emit("setIsLoading", true);
      const result = await ApiHelper.callApi(
        "get",
        "/events/discountDetails",
        {},
        {
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          skip: pageData.value.skip,
          take: pageData.value.take,
          eventId: props.eventId,
          discountId: props.discountId,
          profile: pageData.value.filter.profile || ""
          // key: pageData.value.filter.medication || "",
          // route: pageData.value.filter.dosage || "",
          // frequency: pageData.value.filter.frequency || "",
          // ignoreMedicationStatus: "2"
        }
      );
      pageData.value.isLoading = false;
      await context.emit("setIsLoading", false);
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.items || [];
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    };

    // pager
    const gotoPage = (page: string) => {
      pageData.value.pager.page = parseInt(page);
      getAppliedDiscountDetails(pageData.value.pager.page);
    };

    const onClickPrev = () => {
      if (pageData.value.pager.page > 1) {
        pageData.value.pager.page -= 1;
        getAppliedDiscountDetails(pageData.value.pager.page);
      }
    };

    const onClickNext = () => {
      if (pageData.value.pager.page < pageData.value.pager.totalPages) {
        pageData.value.pager.page += 1;
        getAppliedDiscountDetails(pageData.value.pager.page);
      }
    };

    // filters
    const setActiveFilterTab = (tab: string) => {
      if (pageData.value.activeTab !== tab) {
        pageData.value.activeTab = tab;
      } else {
        pageData.value.activeTab = "";
      }
    };

    const closeFilterTab = () => {
      getAppliedDiscountDetails(1);
      pageData.value.activeTab = "";
    };

    const updateFilterValue = (name: string, value: string) => {
      switch (name) {
        case "profile":
          pageData.value.filter.profile = value;
          break;
        // case "medication":
        //   pageData.value.filter.medication = value;
        //   break;
      }
      pageData.value.pager.page = 1;
      getAppliedDiscountDetails(pageData.value.pager.page);
    };

    const getFiltersData = () => {
      const filters = [];
      if (pageData.value.filter.profile !== "") {
        filters.push({
          label: "PROFILE",
          key: pageData.value.filter.profile + Math.random(),
          value: pageData.value.filter.profile,
          reset: () => {
            pageData.value.filter.profile = "";
            pageData.value.activeTab = "";
          }
        });
      }
      // if (pageData.value.filter.medication !== "") {
      //   filters.push({
      //     label: "MEDICATION NAME",
      //     key: pageData.value.filter.medication + Math.random(),
      //     value: pageData.value.filter.medication,
      //     reset: () => {
      //       pageData.value.filter.medication = "";
      //       pageData.value.activeTab = "";
      //     }
      //   });
      // }

      return {
        list: filters
      };
    };

    const updateSortValue = (sort: string, direction: string) => {
      pageData.value.sort.order = sort;
      pageData.value.sort.direction = direction;
      getAppliedDiscountDetails(pageData.value.pager.page);
    };

    // init data
    (async () => {
      await getAppliedDiscountDetails(1);
    })();

    return {
      closePopup,
      pageData,
      gotoPage,
      onClickPrev,
      onClickNext,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      getFiltersData,
      updateSortValue,
      ApiHelper
    };
  }
});
