var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SidebarProfileDetailsComponent"},[(!_vm.sidebarData.isLoading)?_c('div',{staticClass:"profileDetails",attrs:{"id":"menu-participants"}},[_c('div',{staticClass:"menu-box"},[_vm._m(0),_c('VScroller',{staticClass:"box_content"},[_c('ul',{attrs:{"slot":"default"},slot:"default"},[_c('router-link',{attrs:{"to":{ name: 'FamilyDetails', params: { Id: _vm.$route.params.Id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ active: isActive || isExactActive }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-2.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Members")])])])]}}],null,false,3288725851)}),_c('router-link',{attrs:{"to":{ name: 'FamilyFiles', params: { Id: _vm.$route.params.Id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ active: isActive || isExactActive }},[_c('a',{staticClass:"box__item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-5.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Files")])])])]}}],null,false,1942539260)}),_c('router-link',{attrs:{"to":{ name: 'FamilyEvents', params: { Id: _vm.$route.params.Id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ active: isActive || isExactActive }},[_c('a',{staticClass:"box__item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-3.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Events")])])])]}}],null,false,2898324112)})],1)])],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box_head"},[_c('div',{staticClass:"box__icon"})])}]

export { render, staticRenderFns }