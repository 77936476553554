




















































































import { defineComponent } from "@vue/composition-api";

import $ from "jquery";
import { useSettingsApplicationsDetailsStore } from "@/stores/Settings/Applications/SettingsApplicationsDetailsStore";
import Loading from "@/components/Common/Loading.vue";
import Modal from "@/components/Common/Modal.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import BackButton from "@/components/Common/BackButton.vue";
import ProfileDetails from "@/components/modules/ProfileDetails.vue";
import RoommateRequest from "@/components/modules/RoommateRequest.vue";
import CampStoreFund from "@/components/modules/CampStoreFund.vue";
import AddOns from "@/components/modules/AddOns.vue";
import SignaturePad from "signature_pad";
import Header from "@/components/Common/Header.vue";
import ParentGuardian from "@/components/modules/ParentGuardian.vue";
declare const window: any;
require("formBuilder/dist/form-render.min.js");

require("jquery-ui-sortable");
export default defineComponent({
  name: "SettingsApplicationsDetailsPage",
  components: {
    Header,
    Loading,
    Modal,
    Select2,
    BackButton,
    ProfileDetails,
    RoommateRequest,
    ParentGuardian,
    CampStoreFund,
    AddOns
  },
  setup(props, context) {
    const {
      headerData,
      pageData,
      loadData
    } = useSettingsApplicationsDetailsStore(context);

    const getSysModuleName = (module: any) => {
      const pathArray = (module.moduleVersionTemplatePath || "").split("/");
      return pathArray[pathArray.length - 1].replace(".vue", "");
    };

    const applyCustomWidth = (renderedForm: any) => {
      renderedForm.find("> div").addClass("col-12");
      // apply custom width for each module
      renderedForm
        .find("[class*='custom-w-']")
        .each(function(i: number, obj: any) {
          const classList = obj.className.split(" ");
          const customW = classList.find(
            (item: any) => item.indexOf("custom-w-") != -1
          );
          const customWClass = customW ? customW.replace("custom-w-", "") : "";
          if (customWClass != "") {
            const type = $(obj).prop("type");
            let parentNode = null;
            if (type == "checkbox" || type == "radio") {
              parentNode = $(obj).closest(".form-group");
            } else {
              parentNode = $(obj).parent();
            }
            if (parentNode.length) {
              parentNode.addClass(customWClass);
              if (customWClass != "col-12") {
                parentNode.removeClass("col-12");
              }
              $(obj).removeClass(customWClass);
            }
          }
        });
    };

    // init data
    (async () => {
      await loadData();
      if (pageData.value.modules.length) {
        window.fbControls = [];
        window.fbControls.push((controlClass: any) => {
          class ControlSignature extends controlClass {
            build() {
              return `
                    <div>
                        <div style="background-color: #eee; padding: 10px;">
                          <canvas id="${this.config.name}" style="touch-action: none; background: white;" height="65" width="352"></canvas>
                        </div>
                    </div>`;
            }
            onRender() {
              const canvas: any = document.getElementById(this.config.name);
              new SignaturePad(canvas, {
                backgroundColor: "rgb(255, 255, 255)"
              });
            }
          }
          controlClass.register("signature", ControlSignature);
          return ControlSignature;
        });

        window.fbControls.push((controlClass: any) => {
          class ControlDob extends controlClass {
            build() {
              const months = [];
              for (let i = 1; i < 13; i++) {
                months.push(i);
              }
              const days = [];
              for (let i = 1; i < 32; i++) {
                days.push(i);
              }
              const years = [];
              for (let i = 1971; i < new Date().getFullYear() + 1; i++) {
                years.push(i);
              }

              return `
              <div class="row form-group ${
                this.config.className
              } shadow-none pr-0 d-flex" style="padding:0; margin: 0; border: none">
                <div class="col-lg-4 col-12 pl-0">
                  <select type="text" class="form-control px-3 text-center">
                    <option value="">Month</option>
                    ${months.map(value => {
                      return `<option value="${value}">${value}</option>`;
                    })}
                  </select>
                </div>
                <div class="col-lg-4 col-12 p-0">
                  <select class="form-control input__full px-3 text-center">
                    <option value="">Day</option>
                    ${days.map(value => {
                      return `<option value="${value}">${value}</option>`;
                    })}
                  </select></div>
                <div class="col-lg-4 col-12 pr-0">
                  <select type="text" class="form-control px-3 text-center">
                    <option value="">Year</option>
                    ${years.map(value => {
                      return `<option value="${value}">${value}</option>`;
                    })}
                  </select>
                </div>
              </div>`;
            }
          }
          controlClass.register("dob", ControlDob);
          return ControlDob;
        });

        for (const module of pageData.value.modules) {
          const moduleBuilderId = `module-${module.moduleId}`;
          const formRender: any = $(`#module-${module.moduleId}`);
          if (formRender.length) {
            const appModuleJson = module.jsonData || [];
            appModuleJson.map((control: any) => {
              if (control.type == "file" && control.subtype == "fineuploader") {
                control.subtype = "file";
              }
            });
            await formRender.formRender({
              formData: JSON.stringify(appModuleJson)
            });

            const renderedForm = formRender.find(
              "> .rendered-form:first-child"
            );
            if (renderedForm.length) {
              renderedForm.addClass("row");
              applyCustomWidth(renderedForm);
            }

            // apply custom sub labels for text fields
            const fieldsHaveSubLabels = appModuleJson.filter(
              (t: any) =>
                (t.type || "") == "text" && (t.subLabel || "").trim() != ""
            );
            if (fieldsHaveSubLabels.length) {
              for (const fieldData of fieldsHaveSubLabels) {
                const field = $(
                  `#${moduleBuilderId} .formbuilder-text.field-${fieldData.name}`
                );
                if (field.length == 0) continue;
                const fieldLabel = field.find("label.formbuilder-text-label");
                if (
                  (fieldData.label || "").trim() != "" ||
                  fieldData.required ||
                  false
                ) {
                  fieldLabel.addClass("has-label-text");
                }
                fieldLabel.append(
                  `<span class="formbuilder-text-sublabel">(${fieldData.subLabel})</span>`
                );
              }
            }
          }
        }
      }
    })();

    return {
      headerData,
      pageData,
      getSysModuleName
    };
  }
});
