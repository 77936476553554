import { ref } from "@vue/composition-api";

import { ApiHelper } from "@/helpers";
import { FormPassword } from "@/types";
import Vue from "vue";

export function useUserResetPasswordStore(context: any) {
  const resetPasswordInput = ref({
    token: context.root.$route.query.token || "",
    password: "",
    confirmPassword: ""
  });
  const syncFormData = (formControls: any) => {
    resetPasswordInput.value.password = formControls.password.value;
    resetPasswordInput.value.confirmPassword =
      formControls.confirmPassword.value;
  };
  const validateForm = (formControls: any) => {
    let hasError = false;
    if (formControls.password.value === "") {
      hasError = true;
      formControls.password.error = "Password is required!";
    }
    /*if (formControls.confirmPassword.value === "") {
      hasError = true;
      formControls.confirmPassword.error = "Confirm Password is required!";
    }

    if (
      formControls.password.value !== "" &&
      formControls.confirmPassword.value !== "" &&
      formControls.password.value !== formControls.confirmPassword.value
    ) {
      hasError = true;
      formControls.confirmPassword.error = "Confirm Password is not match!";
    }*/

    return !hasError;
  };

  const resetForm = (formData: any) => {
    formData.controls.password.error = "";
    formData.controls.password.value = "";
    formData.controls.confirmPassword.error = "";
    formData.controls.confirmPassword.value = "";
  };

  const submitForm = async (dataInput: any, formData: any) => {
    formData.isLoading = true;
    const result = await ApiHelper.callApi(
      "post",
      "/auth/resetpassword",
      {
        type: 1,
        token: dataInput.token,
        password: dataInput.password,
        confirmPassword: dataInput.confirmPassword
      },
      {},
      "core"
    );
    formData.isLoading = false;
    if (result.status == 1) {
      ApiHelper.showSuccessMessage(`Your password was updated.`);
      resetForm(formData);
      ApiHelper.gotoPage(context, {
        name: "UserLogin"
      });
    } else {
      ApiHelper.showErrorMessage(result.message);
    }
  };

  const formData = ref<{
    isLoading: boolean;
    controls: {
      password: FormPassword;
      confirmPassword: FormPassword;
    };
    actions: {
      onSubmit: any;
      onSendResetLink: any;
    };
  }>({
    isLoading: false,
    controls: {
      password: {
        name: "password",
        type: "password",
        label: `New Password`,
        placeholder: "New Password",
        error: "",
        mode: "password",
        value: ""
      },
      confirmPassword: {
        name: "confirmPassword",
        type: "password",
        label: `Confirm Password`,
        placeholder: "Confirm Password",
        error: "",
        mode: "password",
        value: ""
      }
    },
    actions: {
      onSubmit: async (event: Event) => {
        event.preventDefault();
        syncFormData(formData.value.controls);
        if (validateForm(formData.value.controls)) {
          await submitForm(resetPasswordInput.value, formData.value);
        }
      },
      onSendResetLink: async () => {
        Vue.swal({
          title: "Are you sure?",
          text:
            "An <strong>auto generated password</strong> will be sent to your email!",
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then((result: any) => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          if (result.isConfirmed) {
            (async () => {
              formData.value.isLoading = true;
              const result = await ApiHelper.callApi(
                "post",
                "/auth/resetpassword",
                {
                  type: 2,
                  token: resetPasswordInput.value.token
                },
                {},
                "core"
              );
              formData.value.isLoading = false;
              if (result.status == 1) {
                ApiHelper.showSuccessMessage(`Password was sent!`);
                ApiHelper.gotoPage(context, {
                  name: "UserLogin"
                });
              } else {
                ApiHelper.showErrorMessage(result.message);
              }
            })();
          }
        });
      }
    }
  });

  const pageData = ref({
    form: formData
  });

  return {
    pageData,
    formData
  };
}
