var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":'builder-container-' + _vm.randomId}},[_c('div',{staticClass:"grapesjs-container",class:{
      error: _vm.formData.template.error
    },attrs:{"id":'grapesjs-container-' + _vm.randomId}},[_c('div',{staticStyle:{"display":"none"},attrs:{"id":"gjs"}}),_c('div',{staticClass:"position-relative",staticStyle:{"display":"none"},attrs:{"id":"preview"}},[_c('textarea',{staticClass:"html-preview",on:{"input":function($event){_vm.formData.template.error = ''}}})]),_vm._m(0),(_vm.popupTest.show)?_c('Modal',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"modal-header",attrs:{"slot":"header"},slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Test your Newsletter")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function () {
                _vm.popupTest.show = false;
              }}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('FormInput',{attrs:{"data":_vm.popupTest.controls.subject}}),_c('FormEmail',{attrs:{"data":_vm.popupTest.controls.email}})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('FormButton',{attrs:{"is-processing":_vm.popupTest.isProcessing,"on-submit":_vm.popupTest.onSubmit,"label":"Test","type":"primary"}})],1)]):_vm._e(),(_vm.popupSave.show)?_c('Modal',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"modal-header",attrs:{"slot":"header"},slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Save Current Template")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function () {
                _vm.popupSave.show = false;
              }}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('FormInput',{attrs:{"data":_vm.popupSave.controls.name}}),_c('FormEmail',{attrs:{"data":_vm.popupSave.controls.email}})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('FormButton',{attrs:{"is-processing":_vm.popupSave.isProcessing,"on-submit":_vm.popupSave.onSubmit,"label":"Save Template","type":"primary"}})],1)]):_vm._e(),(_vm.popupLoadTemplate.show)?_c('Modal',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"modal-header",attrs:{"slot":"header"},slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Load Template(s)")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function () {
                _vm.popupLoadTemplate.show = false;
              }}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('FormEmail',{attrs:{"data":_vm.popupLoadTemplate.controls.email}}),(_vm.popupLoadTemplate.loaded)?_c('div',{attrs:{"id":"loadTemplateContainer"}},[_c('div',{staticClass:"content__table__scroll"},[_c('div',{staticClass:"content_table mt-3"},[(_vm.popupLoadTemplate.controls.items.length > 0)?_c('div',{staticClass:"table__head not_dropdown d-none"},[_c('div',{staticClass:"items row"},[_c('div',{staticClass:"item col col-9"},[_c('TableHeaderColumn',{attrs:{"label":"Template name"}})],1),_c('div',{staticClass:"item col col-3"},[_c('TableHeaderColumn',{attrs:{"label":""}})],1)])]):_vm._e(),_c('div',{staticClass:"table__body"},[(_vm.popupLoadTemplate.controls.items.length < 1)?_c('div',{staticClass:"noData"},[_vm._v(" No Records Found ")]):_vm._e(),_vm._l((_vm.popupLoadTemplate.controls.items),function(item,index){return _c('div',{key:'template-item-' + index,staticClass:"items row p-2 gjs-template",attrs:{"data-html":item.content,"data-css":item.css}},[_c('div',{staticClass:"item col col-9"},[_c('span',[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"item col col-3 d-flex justify-content-end"},[_c('a',{staticClass:"text-right"},[_c('img',{attrs:{"src":require("../../assets/images/icon/page_copy.png"),"alt":"Import"}})])])])})],2)])])]):_vm._e()],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('FormButton',{attrs:{"is-processing":_vm.popupLoadTemplate.isProcessing,"on-submit":_vm.popupLoadTemplate.onSubmit,"label":"Find Templates","type":"primary"}})],1)]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"none"},attrs:{"id":"test-modal"}},[_c('div',{staticClass:"gjs-sm-property"},[_c('div',{staticClass:"gjs-field"},[_c('span',[_c('input',{staticStyle:{"background-color":"white"},attrs:{"id":"sendTestEmail","type":"email","name":"email","placeholder":"Email"}})])])]),_c('br'),_c('div',{staticClass:"gjs-sm-property"},[_c('div',{staticClass:"gjs-field"},[_c('span',[_c('input',{staticStyle:{"background-color":"white"},attrs:{"id":"sendTestSubject","type":"text","name":"subject","placeholder":"Subject"}})])])]),_c('input',{attrs:{"type":"hidden","name":"body","id":"sendTestBody"}}),_c('button',{staticClass:"gjs-btn-prim gjs-btn-import",staticStyle:{"width":"100%","background-color":"#d2d2d2"},attrs:{"id":"sendTest"}},[_vm._v(" TEST ")])])}]

export { render, staticRenderFns }