





























































































































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import directives from "@/helpers/directives";
import BackButton from "@/components/Common/BackButton.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormSelect2 from "@/components/Form/FormSelect2.vue";
import { useSettingsGroupEditStore } from "@/stores/Settings/Groups/SettingsGroupEditStore";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
import Header from "@/components/Common/Header.vue";

export default defineComponent({
  name: "SettingsGroupEditPage",
  components: {
    Header,
    FormMultiSelect,
    FormInput,
    FormSelect2,
    Loading,
    BackButton
  },
  directives: directives,
  setup(props, context) {
    const {
      removeOption,
      changeOption,
      loadData,
      headerData,
      pageData,
      formData
    } = useSettingsGroupEditStore(context);
    return {
      removeOption,
      changeOption,
      loadData,
      headerData,
      pageData,
      formData
    };
  }
});
