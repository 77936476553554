import { ref } from "@vue/composition-api";
import { ApiHelper, ProfileHelper } from "@/helpers";
import { PagerItem, SelectOption } from "@/types";
import Vue from "vue";

export function useProfileNotesStore(context: any) {
  const selectedProfileId = parseInt(context.root.$route.params.profileId) || 0;
  const sidebarData = ref({
    isLoading: true,
    logo: "",
    name: "",
    profileId: 0,
    participantId: 0
  });
  const headerData = ref({
    title: "",
    subTitle: ""
  });
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      status: SelectOption[];
      cabin: string;
      type: string;
      participants: string;
      schedule: string;
      rating: string;
      minRating: string;
      maxRating: string;
    };
    showHeadActions: boolean;
    isAddNewSuccess: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
  }>({
    isLoading: true,
    skip: 0,
    take: 8,
    activeTab: "",
    sort: {
      order: "2",
      direction: "1"
    },
    filter: {
      status: [],
      cabin: "",
      type: "",
      participants: "",
      schedule: "",
      rating: "",
      minRating: "",
      maxRating: ""
    },
    items: [],
    showHeadActions: false,
    isAddNewSuccess: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    }
  });

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: context.root.$route.name,
        query: {
          page: pageData.value.pager.page + "" || undefined,
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          type: pageData.value.filter.type || undefined,
          participantId: context.root.$route.query.participantId,
          eventId: context.root.$route.query.eventId
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  };

  const loadList = async () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    const result = await ApiHelper.callApi(
      "get",
      "/messages",
      {},
      {
        skip: pageData.value.skip,
        take: pageData.value.take,
        profileId: selectedProfileId,
        typeId: 2
      }
    );
    ApiHelper.setDataLoading(false);
    pageData.value.isLoading = false;
    if (result.status === 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.pager.totalPages = Math.ceil(
        totalCount / pageData.value.take
      );
      pageData.value.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      pageData.value.items = result.data.messages || [];
    } else if (result.status === 401) {
      ApiHelper.gotoPage(context, { name: "Login" });
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
    }
  };

  const loadData = async (page: number) => {
    if (pageData.value.pager.page !== page) {
      pageData.value.pager.page = page;
      updateRouters();
    } else {
      await loadList();
    }
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };
  // filters
  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "cabin":
        pageData.value.filter.cabin = value;
        break;
      case "participants":
        pageData.value.filter.participants = value;
        break;
      case "schedule":
        pageData.value.filter.schedule = value;
        break;
      case "rating": {
        pageData.value.filter.rating = value;
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.cabin !== "") {
      filters.push({
        label: "CABIN",
        key: pageData.value.filter.cabin + Math.random(),
        value: pageData.value.filter.cabin,
        reset: () => {
          pageData.value.filter.cabin = "";
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.participants !== "") {
      filters.push({
        label: "PARTICIPANTS",
        key: pageData.value.filter.participants + Math.random(),
        value: pageData.value.filter.participants,
        reset: () => {
          pageData.value.filter.participants = "";
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.schedule !== "") {
      filters.push({
        label: "SCHEDULE",
        key: pageData.value.filter.schedule + Math.random(),
        value: pageData.value.filter.schedule,
        reset: () => {
          pageData.value.filter.schedule = "";
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.rating !== "") {
      filters.push({
        label: "RATING",
        key: pageData.value.filter.rating + Math.random(),
        value: pageData.value.filter.rating,
        reset: () => {
          pageData.value.filter.rating = "";
          loadData(1);
        }
      });
    }
    return {
      list: filters
    };
  };

  // pageData.value.filter.status = ApiHelper.getParticipantStatusOptions();
  const selectAllStatuses = () => {
    pageData.value.filter.status.map((item: any) => {
      item.selected = true;
    });
    loadData(1);
  };
  const resetStatuses = () => {
    pageData.value.filter.status.map((item: any) => {
      item.selected = false;
    });
    loadData(1);
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    loadData(1);
  };

  // init data
  (async () => {
    pageData.value.filter.status = ApiHelper.getParticipantStatusOptions();
    loadData(1);
  })();

  const deleteItem = async (item: any, $parent: any) => {
    Vue.swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then((result: any) => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      if (result.isConfirmed) {
        (async () => {
          ApiHelper.setDataLoading(true);
          const result = await ApiHelper.callApi(
            "delete",
            "/medicals/" + item.medicalId,
            {
              profileId: item.profileId
            },
            {}
          );
          ApiHelper.setDataLoading(false);
          if (result.status == 1) {
            loadData(1);
            ApiHelper.showSuccessMessage(
              "The medical has been deleted.",
              "Deleted"
            );
            const parent = $parent;
            if (parent.$refs.SidebarProfileRef) {
              parent.$refs.SidebarProfileRef.loadData();
            }
          } else {
            ApiHelper.showErrorMessage(result.message);
          }
        })();
      }
    });
  };

  const isArchived = ref(false);
  (async () => {
    const profileInfo = await ProfileHelper.getProfileInfo(selectedProfileId);
    isArchived.value = profileInfo.status === 2;
  })();

  (async () => {
    const participantId =
      Number.parseInt(context.root.$route.query.participantId, 10) || 0;
    if (participantId > 0) {
      const result = await ApiHelper.callApi(
        "get",
        "/participants/" + participantId,
        {},
        {}
      );
      if (result.status === 1) {
        headerData.value.subTitle =
          ApiHelper.getFullName(
            result.data.firstName || "",
            result.data.lastName || ""
          ) +
            " - " +
            result.data.eventName || "";
        sidebarData.value.name = result.data.eventName;
        sidebarData.value.profileId = result.data.profileId || 0;
        sidebarData.value.participantId = result.data.participantId || 0;
        sidebarData.value.isLoading = false;
      }
    } else {
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/" + selectedProfileId + "/info",
        {},
        {}
      );
      if (result.status === 1) {
        headerData.value.subTitle = ApiHelper.getFullName(
          result.data.firstName || "",
          result.data.lastName || ""
        );
      }
    }
  })();

  return {
    loadData,
    updateRouters,
    sidebarData,
    isArchived,
    // sort
    updateSortValue,
    headerData,
    pageData,
    // filters
    selectAllStatuses,
    resetStatuses,
    // --- pager
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    // --- filters
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab,
    // actions
    deleteItem
  };
}
