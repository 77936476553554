























































































































































































































































































































































import { defineComponent } from "@vue/composition-api";

import ProfileTabs from "@/components/Profile/ProfileTabs.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import SidebarProfileDetails from "@/components/Profile/SidebarProfileDetails.vue";
import { ApiHelper } from "@/helpers";
import Loading from "@/components/Common/Loading.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import Pager from "@/components/Common/Pager.vue";
import { useProfileEventsStore } from "@/stores/Profile/ProfileEventsStore";
import Modal from "@/components/Common/Modal.vue";
import VScroller from "@/components/Common/VScroller.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import DropdownParticipantStatus from "@/components/Participant/DropdownParticipantStatus.vue";
import TransferModal from "@/components/Financial/TransferModal.vue";
import PopupEditApplication from "@/components/Participant/PopupEditApplication.vue";

type Profile = {
  id: string;
  firstName: string;
  lastName: string;
  tags: {
    id: number;
    name: string;
  }[];
};

export default defineComponent({
  name: "ProfileEventsPage",
  components: {
    TableHeaderColumn,
    ProfileTabs,
    Loading,
    Modal,
    VScroller,
    SidebarProfileDetails,
    HeaderProfileDetails,
    FilterDateColumn,
    FilterSelectColumn,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterAgesColumn,
    FilterNumberRangeColumn,
    Pager,
    DropdownParticipantStatus,
    TransferModal,
    PopupEditApplication
  },
  setup(props, context) {
    const {
      popupEditApplication,
      loadAvailableEvents,
      isArchived,
      selectEvent,
      popupNewEvent,
      errors,
      pageData,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      getFiltersData,
      updateParticipantStatus,
      sidebarData,
      participantInfo,
      loadData,
      getItemBalance
    } = useProfileEventsStore(context);

    const showEventsSidebar = async (item: any) => {
      sidebarData.value = item;

      participantInfo.value = {
        participantId: item.participantId || 0,
        profileId: item.profileId || 0,
        paidAmount: item.financials.totalPaid || 0,
        paidAmountFormatted: item.financials.income || "",
        fullName: "",
        eventId: item.id,
        eventName: item.name || "",
        pTypeName: item.pTypeName
      };
    };

    const setIsPaying = (status: boolean) => {
      pageData.value.paying = status;
    };

    const reUpdateStatusDenied = () => {
      pageData.value.pager.selectedItem.financials.totalPaid = 0;
      updateParticipantStatus(
        pageData.value.pager.selectedItem,
        pageData.value.pager.selectedStatusOption,
        false,
        true
      );
    };

    return {
      popupEditApplication,
      sidebarData,
      showEventsSidebar,
      loadAvailableEvents,
      isArchived,
      selectEvent,
      // popups
      popupNewEvent,
      errors,
      pageData,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      getFiltersData,
      updateParticipantStatus,
      participantInfo,
      setIsPaying,
      reUpdateStatusDenied,
      loadData,
      ApiHelper,
      getItemBalance
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.gotoPage("1");
  },
  updated() {
    //Load data total on header
    if (this.popupNewEvent.isAddNewSuccess) {
      const sidebar: any = this.$refs.childComponentHeaderRef;
      if (sidebar) {
        sidebar.$refs.SidebarProfileRef.loadData();
        this.popupNewEvent.isAddNewSuccess = false;
      }
    }
  }
});
