var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"headerContainer"}},[_c('div',{staticClass:"content-head have__tabs",class:{
        noPadding: _vm.noPadding,
        noZIndex: _vm.noZIndex
      }},[_c('div',{staticClass:"content-head have__tabs event__detail"},[_c('div',{staticClass:"head_left hide__ipad hasBackButton"},[_c('BackButton'),_c('h3',{staticClass:"head_heading__small"},[_vm._v(" "+_vm._s(_vm.$route.query.id || _vm.$route.query.participantId ? "Events" : "Person")+" ")]),_vm._t("head_heading__other",[_vm._t("head_heading__other",[_c('h2',{staticClass:"head_heading head_heading__other"},[_c('Avatar',{staticClass:"mr-3",attrs:{"src":_vm.forceProfilePhoto || _vm.logo,"username":_vm.forceProfileName || _vm.pageTitle,"size":44}}),(_vm.breadcrumbs && _vm.breadcrumbs.length)?_c('div',{staticClass:"breadcrumbs header__breadcrumbs"},[_vm._l((_vm.breadcrumbs),function(item,key){return _c('router-link',{key:key,staticClass:"breadcrumbs__item",attrs:{"to":item.route}},[_c('span',{staticClass:"dot-bottom"},[_vm._v(_vm._s(item.title))])])}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
                      function () {
                        _vm.showDropdown = false;
                      }
                    ),expression:"\n                      () => {\n                        showDropdown = false;\n                      }\n                    "}],staticClass:"breadcrumb__item"},[_c('div',{staticClass:"select field-group__item"},[_c('a',{staticClass:"select__button field",class:{
                          select__button_active: _vm.showDropdown
                        }},[_c('router-link',{staticStyle:{"border-bottom":"1px dotted","color":"#2f6b46"},attrs:{"to":{
                            name: 'ProfileDetails',
                            params: {
                              profileId: _vm.$route.params.profileId
                            }
                          }}},[_c('span',[_vm._v(_vm._s(_vm.forceProfileName || _vm.pageTitle || ""))])]),_c('span',{staticClass:"button",on:{"click":function($event){_vm.showDropdown = !_vm.showDropdown}}})],1),(_vm.showDropdown)?_c('ul',{staticClass:"select__list has-scrollbar has-scrollbar_sm",class:{
                          select__list_visible: _vm.showDropdown
                        }},[_c('li',{staticClass:"select__item",staticStyle:{"padding":"10px 20px"}},[_c('div',{staticClass:"search-container"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.keyword),expression:"keyword",modifiers:{"trim":true}}],staticClass:"txt-key",attrs:{"type":"text","placeholder":"TYPE TO SEARCH FOR A PARTICIPANT..."},domProps:{"value":(_vm.keyword)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.keyword=$event.target.value.trim()},_vm.doSearch],"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"select__list__content"},[_vm._l((_vm.events),function(event,key){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(event.show),expression:"event.show"}],key:'ref-event-item-' + key,staticClass:"select__item"},[_c('router-link',{attrs:{"to":{
                                name: _vm.$route.name,
                                params: {
                                  profileId: ("" + (event.profileId))
                                },
                                query: {
                                  id: _vm.$route.query.id
                                    ? event.participantId + ''
                                    : undefined,
                                  participantId: _vm.$route.query.participantId
                                    ? event.participantId + ''
                                    : undefined,
                                  eventId: _vm.$route.query.eventId
                                }
                              }}},[_vm._v(" "+_vm._s(event.fullName)+" ")])],1)}),(
                              _vm.events.filter(function (item) { return item.show; }).length == 0
                            )?_c('li',[_c('div',{staticClass:"noData2"},[_vm._v(" No Participants Found ")])]):_vm._e()],2)]):_vm._e()])])],2):[_c('DropdownBox',{attrs:{"green":true,"leftToRight":true,"show":_vm.sidebarData.show,"label":_vm.forceProfileName || _vm.pageTitle || '',"is-loading":!_vm.pageTitle},on:{"onClick":function () {
                        _vm.sidebarData.show = !_vm.sidebarData.show;
                      },"onDismiss":function () {
                        _vm.sidebarData.show = false;
                      }}},[_c('div',{attrs:{"slot":"dropdown_content"},slot:"dropdown_content"},[_c('div',{staticClass:"group"},[_c('AutoCompleteInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.sidebarData.showAci),expression:"sidebarData.showAci"}],staticClass:"sidebar-profiles-aci",attrs:{"placeholder":'Type a person name...',"options":_vm.sidebarData.foundProfiles,"nowrap":true,"showLineTitle":true,"isACILoading":_vm.isACILoading || false},on:{"input":function (key) { return _vm.suggestProfiles(key); },"select":function (item) { return _vm.selectProfile(item); }}})],1)])]),_c('a',{staticClass:"cursor-pointer",on:{"click":_vm.openPreview}},[_c('img',{staticClass:"ml-0",staticStyle:{"width":"25px","height":"25px"},attrs:{"src":require("../../assets/svg/preview.svg"),"alt":"Preview"}})])],_c('div',{staticClass:"d-none"},[_vm._t("heading__number")],2)],2)])])],2),_vm._t("head_right",[_c('div',{staticClass:"head_right",staticStyle:{"padding-top":"40px"}},[(!(_vm.$route.query.id || _vm.$route.query.participantId))?_c('NavProfileDetails',{ref:"SidebarProfileRef",attrs:{"profile-id":("" + (_vm.$route.params.profileId))}}):_vm._e()],1)])],2)])]),(_vm.title)?_c('div',{staticClass:"hearderPageTitle mw-1300"},[_c('h2',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.title)+" "),_vm._t("heading__actions")],2),_vm._t("hearderPageTitleRight")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }