var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"autocomplete-input form-group"},[_c('div',{staticClass:"tag-input"},[_vm._l((_vm.tags),function(tag,index){return _c('div',{key:tag.text,staticClass:"tag-input__tag"},[_c('span',{on:{"click":function($event){return _vm.removeTag(index)}}},[_vm._v("x")]),_vm._v(" "+_vm._s(tag.text)+" ")])}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.key),expression:"key",modifiers:{"trim":true}}],staticClass:"tag-input__text",class:[
        'tag-box',
        {
          'input-loader': false,
          'form-control': typeof _vm.inputClass == 'undefined'
        }
      ],attrs:{"type":"text","placeholder":_vm.placeholder},domProps:{"value":(_vm.key)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doAddTag($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.removeLastTag($event)}],"input":[function($event){if($event.target.composing){ return; }_vm.key=$event.target.value.trim()},_vm.onInput],"blur":function($event){return _vm.$forceUpdate()}}})],2),(_vm.data && _vm.data.error !== undefined && _vm.data.error !== '')?_c('span',{staticClass:"error text-danger"},[_vm._v(_vm._s(_vm.data.error))]):_vm._e(),(_vm.show && !_vm.loading)?_c('div',{staticClass:"box_content",class:{
      allowAddNew: _vm.allowAddNew
    }},[(_vm.hint != '')?_c('div',{staticClass:"hint"},[_vm._v(_vm._s(_vm.hint))]):(_vm.options.length)?_c('VScroller',[_c('ul',{staticClass:"results-box",attrs:{"slot":"default"},slot:"default"},_vm._l((_vm.options),function(item,index){return _c('li',{key:index,attrs:{"data-id":item.id},on:{"click":function($event){return _vm.onSelect(item)}}},[(typeof item.html != 'undefined')?_c('div',{domProps:{"innerHTML":_vm._s(item.html)}}):_c('div',{domProps:{"innerHTML":_vm._s(item.text)}})])}),0)]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }