import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { PagerItem, SelectOption } from "@/types";
import Vue from "vue";
import {
  getParticipantStatusText,
  ParticipantStatus
} from "@/helpers/ApiHelper";
import moment from "moment";
import { ProfileListRows } from "@/helpers/estimateNoOfListRows";

export function useEventDetailsStore(context: any) {
  const selectedEventId = parseInt(context.root.$route.params.eventId) || 0;
  const selectedEventStatus = ref(0);
  const selectedProfile = ref<any>({ id: 0 });
  const joinedEvents = ref([]);
  const cabinOptions = ref<
    {
      id: string | number;
      text: string;
    }[]
  >([]);
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    arrGenders: SelectOption[];
    arrCabins: SelectOption[];
    arrTribes: SelectOption[];
    openItemId: number;
    items: {
      id: number;
      isChecked: boolean;
      logo: string;
      firstName: string;
      lastName: string;
      age: string;
      gender: string;
      balance: string;
      events: string;
      familyName: string;
    }[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      tab: string;
      status: SelectOption[];
      isRemoveStatus: boolean;
      profile: string;
      balance: string;
      gender: SelectOption[];
      cabin: SelectOption[];
      tribe: string;
      age: string;
      minAge: string;
      maxAge: string;
      minBalance: string;
      maxBalance: string;
      abalance: string;
      minAbalance: string;
      maxAbalance: string;
      medications: string;
      minMedications: string;
      maxMedications: string;
      pTypes: SelectOption[];
      date: string;
      minDate: string;
      maxDate: string;
      todo: string;
      minTodo: string;
      maxTodo: string;
      allergies: string;
      minAllergies: string;
      maxAllergies: string;
      family: string;
      cityState: string;
      phone: string;
      email: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      totalPending: number;
      total: number;
      items: PagerItem[];
      selectedItem: any;
      selectedStatusOption: any;
    };
    addToCabinVisible: boolean;
    addToCabinID: number;
    addToCabinName: string;
    addToCabinErrMessage: string;
    addToCabinNameErrMessage: string;
    ConfirmModalAddToCabinVisible: boolean;
    confirmAddToCabinMessage: string;
    transferVisible: boolean;
    paying: boolean;
    searchCabinsLoading: boolean;
    searchTypesLoading: boolean;
    addPaymentVisible: boolean;
    profileIdAddPayment: number;
  }>({
    isLoading: true,
    skip: 0,
    take: ProfileListRows("EventDetails"),
    activeTab: "",
    arrGenders: [],
    arrCabins: [],
    arrTribes: [],
    openItemId: 0,
    sort: {
      order: context.root.$route.query.order || "2",
      direction: context.root.$route.query.direction || "1"
    },
    filter: {
      tab: context.root.$route.query.tab || "1",
      status: [],
      isRemoveStatus:
        context.root.$route.query.isRemoveStatus == "1" ? true : false,
      profile: context.root.$route.query.profile || "",
      balance:
        (context.root.$route.query.minBalance || "") +
        "@" +
        (context.root.$route.query.maxBalance || ""),
      gender: [],
      cabin: [],
      tribe: "",
      age:
        (context.root.$route.query.minAge || "") +
        "-" +
        (context.root.$route.query.maxAge || ""),
      minAge: context.root.$route.query.minAge || "",
      maxAge: context.root.$route.query.maxAge || "",
      minBalance: context.root.$route.query.minBalance || "",
      maxBalance: context.root.$route.query.maxBalance,
      date:
        (context.root.$route.query.minDate || "") +
        "-" +
        (context.root.$route.query.maxDate || ""),
      minDate: context.root.$route.query.minDate || "",
      maxDate: context.root.$route.query.maxDate || "",
      todo:
        (context.root.$route.query.minTodo || "") +
        "-" +
        (context.root.$route.query.maxTodo || ""),
      minTodo: context.root.$route.query.minTodo || "",
      maxTodo: context.root.$route.query.maxTodo || "",
      allergies:
        (context.root.$route.query.minAllergies || "") +
        "-" +
        (context.root.$route.query.maxAllergies || ""),
      minAllergies: context.root.$route.query.minAllergies || "",
      maxAllergies: context.root.$route.query.maxAllergies || "",
      abalance:
        (context.root.$route.query.minAbalance || "") +
        "-" +
        (context.root.$route.query.maxAbalance || ""),
      minAbalance: context.root.$route.query.minAbalance || "",
      maxAbalance: context.root.$route.query.maxAbalance || "",
      medications:
        (context.root.$route.query.minMedications || "") +
        "-" +
        (context.root.$route.query.maxMedications || ""),
      minMedications: context.root.$route.query.minMedications || "",
      maxMedications: context.root.$route.query.maxMedications || "",
      pTypes: [],
      family: context.root.$route.query.family || "",
      cityState: context.root.$route.query.cityState || "",
      phone: context.root.$route.query.phone || "",
      email: context.root.$route.query.email || ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      totalPending: 0,
      items: [],
      selectedItem: {},
      selectedStatusOption: {}
    },
    addToCabinVisible: false,
    addToCabinID: 0,
    addToCabinName: "",
    addToCabinErrMessage: "",
    addToCabinNameErrMessage: "",
    ConfirmModalAddToCabinVisible: false,
    confirmAddToCabinMessage: "",
    transferVisible: false,
    paying: false,
    searchCabinsLoading: true,
    searchTypesLoading: false,
    addPaymentVisible: false,
    profileIdAddPayment: 0
  });
  const isACILoading = ref<boolean>(false);
  const participantInfo = ref<any>({
    participantId: 0,
    profileId: 0,
    paidAmount: 0,
    paidAmountFormatted: "",
    fullName: "",
    eventName: "",
    pTypeName: ""
  });
  const popupEditApplication = ref({
    show: false,
    profileId: 0,
    participantId: 0,
    requireFinishApp: false,
    participantStatus: 0,
    isAddingParticipant: false,
    callback: () => {
      // nothing
    }
  });
  const userSettings = ref<any>({});
  const customizeVisible = ref<boolean>(false);

  const loadPtypeOptions = async () => {
    pageData.value.searchTypesLoading = true;
    const result = await ApiHelper.callApi(
      "get",
      "/events/" + selectedEventId + "/ptypes",
      {},
      {}
    );
    if (result.status === 1) {
      const pTypesIds = (context.root.$route.query.pTypes || "")
        .split(",")
        .map((id: string) => parseInt(id));
      pageData.value.filter.pTypes = result.data.ptypes.map((item: any) => ({
        id: item.value,
        text: item.text,
        selected: pTypesIds.includes(item.value)
      }));
      pageData.value.searchTypesLoading = false;
    }
  };
  const loadCabinOptions = async () => {
    pageData.value.searchCabinsLoading = true;
    const cabinIds = (context.root.$route.query.cabin || "")
      .split(",")
      .map((id: string) => parseInt(id));
    pageData.value.filter.cabin = await ApiHelper.getCabinsOptions(
      selectedEventId
    );
    pageData.value.filter.cabin.forEach((item: any) => {
      if (cabinIds.indexOf(item.id) > -1) {
        item.selected = true;
      }
    });
    pageData.value.searchCabinsLoading = false;
  };

  const updateRouters = (updatedFilter = 1) => {
    context.root.$router
      .replace({
        name: context.root.$route.name,
        query: {
          page: pageData.value.pager.page + "" || undefined,
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          tab: pageData.value.filter.tab,
          status:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.status
            ) || undefined,
          isRemoveStatus: pageData.value.filter.isRemoveStatus
            ? "1"
            : undefined,
          profile: pageData.value.filter.profile || undefined,
          pTypes:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.pTypes
            ) || undefined,
          minAge: pageData.value.filter.minAge || undefined,
          maxAge: pageData.value.filter.maxAge || undefined,
          gender:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.gender
            ) || undefined,
          minTodo: pageData.value.filter.minTodo || undefined,
          maxTodo: pageData.value.filter.maxTodo || undefined,
          minAllergies: pageData.value.filter.minAllergies || undefined,
          maxAllergies: pageData.value.filter.maxAllergies || undefined,
          cabin:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.filter.cabin
            ) || undefined,
          minBalance: pageData.value.filter.minBalance || undefined,
          maxBalance: pageData.value.filter.maxBalance || undefined,
          minDate: pageData.value.filter.minDate || undefined,
          maxDate: pageData.value.filter.maxDate || undefined,
          minAbalance: pageData.value.filter.minAbalance || undefined,
          maxAbalance: pageData.value.filter.maxAbalance || undefined,
          minMedications: pageData.value.filter.minMedications || undefined,
          maxMedications: pageData.value.filter.maxMedications || undefined,
          updatedFilter: updatedFilter ? "1" : undefined,
          family: pageData.value.filter.family || undefined,
          cityState: pageData.value.filter.cityState || undefined,
          phone: pageData.value.filter.phone || undefined,
          email: pageData.value.filter.email || undefined
        }
      })
      .catch(() => {
        // nothing
      });
  };

  const getParticipants = async (getAll = 0, exportOption = "") => {
    const result = await ApiHelper.callApi(
      "get",
      "/participants",
      {},
      {
        skip: pageData.value.skip,
        take: pageData.value.take,
        order: parseInt(pageData.value.sort.order),
        direction: parseInt(pageData.value.sort.direction),
        eventId: selectedEventId,
        eventId2: selectedEventId,
        status:
          pageData.value.filter.status
            .filter(tmp => tmp.selected)
            .map(tmp => tmp.id)
            .join() || "-1",
        key:
          pageData.value.filter.profile !== ""
            ? pageData.value.filter.profile
            : "",
        gender: context.root.$route.query.gender || "",
        cabin: context.root.$route.query.cabin || "",
        ageRange: pageData.value.filter.age,
        balanceRange: pageData.value.filter.balance || "",
        minAge: pageData.value.filter.minAge || "",
        maxAge: pageData.value.filter.maxAge || "",
        minBalance: pageData.value.filter.minBalance || "",
        maxBalance: pageData.value.filter.maxBalance || "",
        minPdate: pageData.value.filter.minDate || "",
        maxPdate: pageData.value.filter.maxDate || "",
        minTodo: pageData.value.filter.minTodo || "",
        maxTodo: pageData.value.filter.maxTodo || "",
        minAllergies: pageData.value.filter.minAllergies || "",
        maxAllergies: pageData.value.filter.maxAllergies || "",
        minAbalance: pageData.value.filter.minAbalance || "",
        maxAbalance: pageData.value.filter.maxAbalance || "",
        minMedications: pageData.value.filter.minMedications || "",
        maxMedications: pageData.value.filter.maxMedications || "",
        viewtype: Number.parseInt(context.root.$route.query.tab, 10) || 1,
        pTypeIDs: pageData.value.filter.pTypes
          .filter(tmp => tmp.selected)
          .map(tmp => tmp.id)
          .join(),
        family: pageData.value.filter.family || "",
        cityState: pageData.value.filter.cityState || "",
        phone: pageData.value.filter.phone || "",
        email: pageData.value.filter.email || "",
        getAll,
        exportOption
      }
    );
    pageData.value.isLoading = false;
    return result;
  };

  const loadList = async () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;

    try {
      const resultData = await ApiHelper.callApi(
        "get",
        "/events/" + selectedEventId + "/info",
        {},
        {
          getInfo: "applications"
        }
      );

      const eventData = resultData.data || {};
      const eventId = Number.parseInt(eventData.id || "0") || 0;
      selectedEventStatus.value = eventData.status || 0;

      if (!eventId) {
        // ApiHelper.showErrorMessage(
        //   "Event #" + selectedEventId + " is not found"
        // );
        ApiHelper.showErrorMessage("Not found event", "Oops");
        ApiHelper.gotoPage(context, { name: "Events" });
        return;
      }

      const applications = eventData.applications || [];
      if (applications.length === 0) {
        ApiHelper.gotoPage(context, {
          name: "EventDashboard",
          params: {
            eventId: selectedEventId.toString()
          }
        });
      }

      const result = await getParticipants();
      const totalCount = result.data.totalCount || 0;
      const totalPending = result.data.pendingTotalCount || 0;
      pageData.value.pager.totalPages =
        Math.ceil(totalCount / pageData.value.take) + 1;
      // const paidTotalCount = result.data.paidTotalCount || 0;
      pageData.value.pager.totalPending = totalPending;
      pageData.value.pager.total = totalCount; // paidTotalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      const participants = result.data.participants || [];
      const list = participants.map((item: any, key: number) => {
        const addTopPopOverToolTip =
          participants.length > 4 && key > participants.length - 3
            ? true
            : false;
        return {
          addTopPopOverToolTip,
          id: item.id,
          participantId: item.id,
          isChecked: false,
          percent: `${item.pPercent}%`,
          profileId: item.profileId,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          eventName: item.eventName,
          eventStartDate: item.eventStartDate,
          eventEndDate: item.eventEndDate,
          todoNamesMissing: item.todoNamesMissing,
          genderId: item.gender,
          gender: ApiHelper.getGenderName(item.gender),
          age: item.age < 0 ? "" : item.age,
          outsideAge: item.outsideAge,
          cabin: item.cabinName || "",
          cabinId:
            (item.cabinName || "") != "Pending requested group"
              ? item.cabinId || 0
              : 0,
          balanceFormatted: ApiHelper.dollarFormat(item.balance, false, {
            useBrackets: true
          }),
          balance: item.balance || 0,
          storeBalanceFormatted: ApiHelper.dollarFormat(
            item.storeBalance || 0,
            false,
            { useBrackets: true }
          ),
          storeBalance: item.storeBalance || 0,
          todosDone: item.todosDone || 0,
          todosNeeded: item.todosNeeded || 0,
          medications: item.medications || "0",
          allergiesDone: item.allergiesDone || 0,
          allergiesNeeded: item.allergiesTotal || 0,
          todosPercent:
            ApiHelper.getPercent(item.todosDone, item.todosNeeded) + "%",
          status: item.status,
          pdatetimeFormatted: item.pdatetimeFormatted || "",
          statusText: ApiHelper.getParticipantStatusText(item.status),
          groupID: item.groupID || 0,
          pTypeId: item.participantTypeId || 0,
          participantTypeName: item.participantTypeName || "",
          pRegistrationStep: item.pRegistrationStep || 0,
          // totalPaid: (item.totalPaid || 0) - (item.totalRefund || 0),
          totalPaid: item.totalPaid || 0,
          totalPaidFormatted: item.totalPaidFormatted || "",
          planId: item.planId || "",
          subState: item.subState || "",
          state: item.state || "",
          pCity: item.pCity || "",
          pPhone: item.pPhone || "",
          pPhoneFormatted: item.pPhoneFormatted || "",
          familyName: item.familyName || "",
          cityState: item.cityState || "",
          isPriorEvent: item.isPriorEvent || 0
        };
      });
      pageData.value.items = list;
      ApiHelper.setDataLoading(false);
    } catch (err) {
      console.log(err);
      pageData.value.items = [];
      ApiHelper.setDataLoading(false);
    }
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };
  // const onPagerChange = (event: any) => {
  //   pageData.value.pager.page = event.target.value;
  //   updateRouters();
  // };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };
  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };
  // filters
  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
    if (tab === "cabin" && pageData.value.filter.cabin.length == 0) {
      await loadCabinOptions();
    }
    if (tab === "ptypes" && pageData.value.filter.pTypes.length == 0) {
      await loadPtypeOptions();
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "profile":
        pageData.value.filter.profile = value;
        break;
      case "balance": {
        pageData.value.filter.balance = value;
        const balanceArr = value.split("@");
        pageData.value.filter.minBalance = balanceArr[0];
        pageData.value.filter.maxBalance =
          balanceArr[1] !== undefined ? balanceArr[1] : "";
        break;
      }
      case "abalance": {
        pageData.value.filter.abalance = value;
        const abalanceArr = value.split("-");
        pageData.value.filter.minAbalance = abalanceArr[0];
        pageData.value.filter.maxAbalance =
          abalanceArr[1] !== undefined ? abalanceArr[1] : "";
        break;
      }
      case "medications": {
        pageData.value.filter.medications = value;
        const medicationsArr = value.split("-");
        pageData.value.filter.minMedications = medicationsArr[0];
        pageData.value.filter.maxMedications =
          medicationsArr[1] !== undefined ? medicationsArr[1] : "";
        break;
      }
      case "tribe":
        pageData.value.filter.tribe = value;
        break;
      // case "gender":
      //   pageData.value.filter.gender = value;
      //   break;
      case "date": {
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.filter.minDate = dateArr[0];
        pageData.value.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        break;
      }
      case "age": {
        pageData.value.filter.age = value;
        const ageArr = value.split("-");
        pageData.value.filter.minAge = ageArr[0];
        pageData.value.filter.maxAge = ageArr[1] !== undefined ? ageArr[1] : "";
        pageData.value.sort.order = "4";
        pageData.value.sort.direction = "1";
        break;
      }
      case "todo": {
        pageData.value.filter.todo = value;
        const todoArr = value.split("-");
        pageData.value.filter.minTodo = todoArr[0];
        pageData.value.filter.maxTodo =
          todoArr[1] !== undefined ? todoArr[1] : "";
        pageData.value.sort.order = "10";
        pageData.value.sort.direction = "2";
        break;
      }
      case "allergies": {
        pageData.value.filter.allergies = value;
        const todoArr = value.split("-");
        pageData.value.filter.minAllergies = todoArr[0];
        pageData.value.filter.maxAllergies =
          todoArr[1] !== undefined ? todoArr[1] : "";
        pageData.value.sort.order = "13";
        pageData.value.sort.direction = "2";
        break;
      }
      case "Family": {
        pageData.value.filter.family = value;
        break;
      }
      case "City, State": {
        pageData.value.filter.cityState = value;
        break;
      }
      case "email": {
        pageData.value.filter.email = value;
        break;
      }
      case "phone": {
        pageData.value.filter.phone = value;
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];
    const selectedStatuses = pageData.value.filter.status
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedStatuses.length > 0 && !pageData.value.filter.isRemoveStatus) {
      filters.push({
        label: "STATUS",
        key: selectedStatuses.join("-") + Math.random(),
        value: selectedStatuses.join(", "),
        reset: () => {
          pageData.value.filter.status.map((item: any) => {
            item.selected = true;
          });
          pageData.value.activeTab = "";
          pageData.value.filter.isRemoveStatus = true;
          updateRouters(0);
        }
      });
    }
    if (pageData.value.filter.profile !== "") {
      filters.push({
        label: "PARTICIPANT",
        key: pageData.value.filter.profile + Math.random(),
        value: pageData.value.filter.profile,
        reset: () => {
          pageData.value.filter.profile = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    const selectedGenders = pageData.value.filter.gender
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedGenders.length > 0) {
      filters.push({
        label: "GENDER",
        key: selectedGenders.join("-") + Math.random(),
        value: selectedGenders.join(", "),
        reset: () => {
          pageData.value.filter.gender.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    const selectedCabins = pageData.value.filter.cabin
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedCabins.length > 0) {
      filters.push({
        label: "CABIN",
        key: selectedCabins.join("-") + Math.random(),
        value: selectedCabins.join(", "),
        reset: () => {
          pageData.value.filter.cabin.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedTypes = pageData.value.filter.pTypes
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedTypes.length > 0) {
      filters.push({
        label: "TYPE",
        key: selectedTypes.join("-") + Math.random(),
        value: selectedTypes.join(", "),
        reset: () => {
          pageData.value.filter.pTypes.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.minAge !== "" || pageData.value.filter.maxAge) {
      filters.push({
        label: "AGE",
        key: pageData.value.filter.age + Math.random(),
        value: pageData.value.filter.age,
        reset: () => {
          pageData.value.filter.minAge = "";
          pageData.value.filter.maxAge = "";
          pageData.value.filter.age = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.minTodo !== "" || pageData.value.filter.maxTodo) {
      filters.push({
        label: "TODO",
        key: pageData.value.filter.todo + Math.random(),
        value: pageData.value.filter.todo,
        reset: () => {
          pageData.value.filter.minTodo = "";
          pageData.value.filter.maxTodo = "";
          pageData.value.filter.todo = "";
          updateRouters();
        }
      });
    }
    if (
      pageData.value.filter.minAllergies !== "" ||
      pageData.value.filter.maxAllergies
    ) {
      filters.push({
        label: "ALLERGIES",
        key: pageData.value.filter.allergies + Math.random(),
        value: pageData.value.filter.allergies,
        reset: () => {
          pageData.value.filter.minAllergies = "";
          pageData.value.filter.maxAllergies = "";
          pageData.value.filter.allergies = "";
          updateRouters();
        }
      });
    }
    if (pageData.value.filter.tribe !== "") {
      filters.push({
        label: "TRIBE",
        key: pageData.value.filter.tribe + Math.random(),
        value: pageData.value.filter.tribe,
        reset: () => {
          pageData.value.filter.tribe = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    if (
      Number.parseInt(pageData.value.filter.minBalance, 10) > 0 ||
      Number.parseInt(pageData.value.filter.maxBalance, 10) > 0
    ) {
      const displayValue =
        (Number.parseInt(pageData.value.filter.minBalance, 10) > 0
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minBalance, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (Number.parseInt(pageData.value.filter.maxBalance, 10) > 0
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxBalance, 10),
              false,
              { useBrackets: true }
            )
          : "");
      filters.push({
        label: "BALANCE",
        key: pageData.value.filter.balance + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.minBalance = "";
          pageData.value.filter.maxBalance = "";
          pageData.value.filter.balance = "";
          updateRouters();
        }
      });
    }
    if (
      pageData.value.filter.minAbalance !== "" ||
      pageData.value.filter.maxAbalance
    ) {
      const displayValue =
        (pageData.value.filter.minAbalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minAbalance, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (pageData.value.filter.maxAbalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxAbalance, 10),
              false,
              { useBrackets: true }
            )
          : "");

      filters.push({
        label: "STORE BALANCE",
        key: pageData.value.filter.abalance + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.minAbalance = "";
          pageData.value.filter.maxAbalance = "";
          pageData.value.filter.abalance = "";
          updateRouters();
        }
      });
    }
    if (
      pageData.value.filter.minMedications !== "" ||
      pageData.value.filter.maxMedications
    ) {
      filters.push({
        label: "MEDICATIONS",
        key: pageData.value.filter.medications + Math.random(),
        value: pageData.value.filter.medications,
        reset: () => {
          pageData.value.filter.minMedications = "";
          pageData.value.filter.maxMedications = "";
          pageData.value.filter.medications = "";
          updateRouters();
        }
      });
    }
    if (
      pageData.value.filter.date !== "" &&
      pageData.value.filter.date !== "-"
    ) {
      const displayValue =
        (pageData.value.filter.minDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.minDate
            )
          : "") +
        " - " +
        (pageData.value.filter.maxDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.maxDate
            )
          : "");

      filters.push({
        label: "DATE",
        key: pageData.value.filter.date + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.family !== "") {
      filters.push({
        label: "FAMILY",
        key: pageData.value.filter.family + Math.random(),
        value: pageData.value.filter.family,
        reset: () => {
          pageData.value.filter.family = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.cityState !== "") {
      filters.push({
        label: "CITY, STATE",
        key: pageData.value.filter.cityState + Math.random(),
        value: pageData.value.filter.cityState,
        reset: () => {
          pageData.value.filter.cityState = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.phone !== "") {
      filters.push({
        label: "PHONE",
        key: pageData.value.filter.phone + Math.random(),
        value: pageData.value.filter.phone,
        reset: () => {
          pageData.value.filter.phone = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (pageData.value.filter.email !== "") {
      filters.push({
        label: "EMAIL",
        key: pageData.value.filter.email + Math.random(),
        value: pageData.value.filter.email,
        reset: () => {
          pageData.value.filter.email = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const selectAllStatuses = () => {
    pageData.value.filter.isRemoveStatus = false;
    pageData.value.filter.status.map((item: any) => {
      item.selected = true;
    });
    updateRouters();
  };
  const resetStatuses = () => {
    // pageData.value.filter.status.map((item: any) => {
    //   item.selected = false;
    // });
    pageData.value.filter.isRemoveStatus = false;
    pageData.value.filter.status.map(item => {
      if (
        ![ParticipantStatus.Denied, ParticipantStatus.Cancelled].includes(
          parseInt(`${item.id}`)
        )
      ) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    updateRouters();
  };

  // pageData.value.filter.gender = ApiHelper.getGenderOptions();
  const selectAllGenders = () => {
    pageData.value.filter.gender.map((item: any) => {
      item.selected = true;
    });
    updateRouters();
  };
  const resetGenders = () => {
    pageData.value.filter.gender.map((item: any) => {
      item.selected = false;
    });
    updateRouters();
  };
  const showStatusDropdown = (id: number) => {
    if (id !== pageData.value.openItemId) {
      pageData.value.openItemId = id;
    } else {
      pageData.value.openItemId = 0;
    }
  };

  // (async () => {
  //   pageData.value.filter.cabin = await ApiHelper.getCabinsOptions();
  // })();
  const selectAllCabins = () => {
    pageData.value.filter.cabin.map((item: any) => {
      item.selected = true;
    });
    updateRouters();
  };
  const resetCabins = () => {
    pageData.value.filter.cabin.map((item: any) => {
      item.selected = false;
    });
    updateRouters();
  };

  const selectAllPTypes = () => {
    pageData.value.filter.pTypes.map((item: any) => {
      item.selected = true;
    });
    updateRouters();
  };
  const resetPTypes = () => {
    pageData.value.filter.pTypes.map((item: any) => {
      item.selected = false;
    });
    updateRouters();
  };

  const onApply = () => {
    updateRouters();
  };

  const inCalculateTotal = (status: number, step: number) => {
    let ret = false;
    if (
      status == ParticipantStatus.Applied ||
      status == ParticipantStatus.Accepted ||
      step > 1
    ) {
      ret = true;
    }

    return ret;
  };

  const updateParticipantStatus = async (
    item: any,
    statusOption: any,
    callBack?: any,
    ignoreWaitlist?: boolean,
    noConfirm?: boolean
  ) => {
    const currentStatus = item.status || 0;
    const currentStep = item.pRegistrationStep || 0;
    const participantId = item.id;
    const participant: any = pageData.value.items.find(
      t => t.id == participantId
    );
    let ignoreWaitlistStatus = 0;
    if (currentStatus == ParticipantStatus.Waitlist) {
      /*
      popupEditApplication.value.profileId = participant.profileId || 0;
      popupEditApplication.value.participantId = participantId;
      popupEditApplication.value.requireFinishApp = true;
      popupEditApplication.value.callback = async () => {
        await updateParticipantStatus(item, statusOption, callBack, true);
      };
      popupEditApplication.value.show = true;
      return false;
      */
      ignoreWaitlistStatus = 1;
    }

    if (
      statusOption.value === ParticipantStatus.Denied ||
      statusOption.value == ParticipantStatus.Cancelled
    ) {
      let message = "Are you sure you want to deny this participant?";
      if (statusOption.value == ParticipantStatus.Cancelled) {
        message = "Are you sure you want to cancel this participant?";
      }

      if (item.planId != "" && item.subState == "active") {
        // notify if this participant belongs a plan
        message += `<div>Related plan <strong>#${item.planId}</strong> will be cancelled</div>`;
      }
      if (!noConfirm) {
        const isConfirm = await Vue.swal({
          title: "Are you sure?",
          html: message,
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          return result.isConfirmed;
        });
        if (!isConfirm) {
          return false;
        }
      }
    }

    if (!noConfirm) {
      if (
        (statusOption.value === ParticipantStatus.Denied ||
          statusOption.value === ParticipantStatus.Cancelled) &&
        item.totalPaid
      ) {
        // Force refund
        pageData.value.pager.selectedItem = item;
        pageData.value.pager.selectedStatusOption = statusOption;
        const eventName = context.refs.headerEventDetails?.pageTitle || "";
        // show transfer modal
        pageData.value.transferVisible = true;
        participantInfo.value = {
          participantId: item.participantId || 0,
          profileId: item.profileId || 0,
          paidAmount: item.totalPaid || 0,
          paidAmountFormatted: item.totalPaidFormatted || "",
          fullName: "",
          eventId: selectedEventId,
          eventName: eventName,
          pTypeName: item.participantTypeName
        };
        ApiHelper.showSuccessMessage(
          `Participant paid for event. Please you have to refund/transfer the transactions berfore ${
            statusOption.value === ParticipantStatus.Denied ? "deny" : "cancel"
          } #` + item.id
        );
        //End force refund

        // const root: any = context.root || {};
        // const allowedFunctions = root.$allowedFunctions || [];
        // const userFunctions = root.$userFunctions;
        // const confirm = await Vue.swal({
        //   title: "Denied successfully",
        //   html:
        //     "Participant paid for event. Please you have to refund/transfer the transactions berfore deny",
        //   icon: "warning",
        //   showCancelButton: true,
        //   showDenyButton: true,
        //   showConfirmButton: allowedFunctions.includes(
        //     userFunctions.Transactions
        //   ),
        //   confirmButtonColor: "#3085d6",
        //   cancelButtonColor: "#d33",
        //   confirmButtonText: "Check transactions",
        //   denyButtonText: "Transfer",
        //   customClass: {
        //     container: "swal2-actions-p0",
        //     denyButton: "swal2-blue-btn"
        //   }
        // });
        // if (confirm.isConfirmed) {
        //   context.root.$router.push({
        //     name: "FinancialTransactions",
        //     query: { participantIds: participantId }
        //   });
        //   return;
        // } else if (confirm.isDenied) {
        //   pageData.value.pager.selectedItem = item;
        //   pageData.value.pager.selectedStatusOption = statusOption;
        //   const eventName = context.refs.headerEventDetails?.pageTitle || "";
        //   // show transfer modal
        //   pageData.value.transferVisible = true;
        //   participantInfo.value = {
        //     participantId: item.participantId || 0,
        //     profileId: item.profileId || 0,
        //     paidAmount: item.totalPaid || 0,
        //     paidAmountFormatted: item.totalPaidFormatted || "",
        //     fullName: "",
        //     eventId: selectedEventId,
        //     eventName: eventName,
        //     pTypeName: item.participantTypeName
        //   };
        // } else {
        //   return false;
        // }
      }
    }
    // Check total paid before allow denied
    if (
      ((statusOption.value !== ParticipantStatus.Denied &&
        statusOption.value !== ParticipantStatus.Cancelled) ||
        !item.totalPaid) &&
      !pageData.value.transferVisible
    ) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "patch",
        "/participants/" + item.id + "/status",
        {
          status: statusOption.value,
          ignoreWaitlist: ignoreWaitlistStatus,
          planId: item.planId
        },
        {}
      );
      pageData.value.pager.selectedItem = {};
      pageData.value.pager.selectedStatusOption = {};
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        // const percents = result.data.percents || [];
        // const foundParticipant = percents.find((percentData: any) => {
        //   return percentData.participantId === item.id;
        // });
        // if (foundParticipant) {
        //   item.percent = `${parseInt(foundParticipant.pPercent)}%`;
        // }
        if (callBack) {
          callBack();
        }
        item.statusText = getParticipantStatusText(item.status);

        const parent: any = context.parent || null;
        if (parent && parent.$refs.SidebarEventDetailsRef) {
          parent.$refs.SidebarEventDetailsRef.loadData();
        }

        if (statusOption.value === ParticipantStatus.Denied) {
          // user set to denied status
          const deniedStatus: any = pageData.value.filter.status.find(
            item => item.id == ParticipantStatus.Denied
          );
          if (!(deniedStatus.selected || false)) {
            item.id = 0;
            // if (inCalculateTotal(currentStatus, currentStep)) {
            //   pageData.value.pager.total = pageData.value.pager.total - 1;
            // }
          }

          if (participant) {
            // removed from cabin if any
            participant.cabinId = 0;
            participant.cabin = "";
          }
          if (pageData.value.items.length == 1) {
            if (pageData.value.pager.page > 1) {
              pageData.value.pager.page = pageData.value.pager.page - 1;
              updateRouters();
            } else {
              loadList();
            }
          } else {
            loadList();
          }
        } else if (
          statusOption.value === ParticipantStatus.CheckedIn ||
          statusOption.value === ParticipantStatus.Incomplete ||
          statusOption.value === ParticipantStatus.Waitlist ||
          currentStatus === ParticipantStatus.CheckedIn ||
          currentStatus === ParticipantStatus.Waitlist ||
          !item.todosNeeded // have no p_params.Todos.Needed
        ) {
          loadList();
        }
        // else {
        //   // other statuses
        //   if (inCalculateTotal(currentStatus, currentStep)) {
        //     if (inCalculateTotal(statusOption.value, currentStep) == false) {
        //       // new status is not in calculate total
        //       pageData.value.pager.total -= 1;
        //     }
        //   } else {
        //     if (inCalculateTotal(statusOption.value, currentStep) == true) {
        //       // new status is in calculate total
        //       pageData.value.pager.total += 1;
        //     }
        //   }
        // }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    }
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const formData = ref<{
    isLoading: boolean;
    controls: {
      age: {
        value: number;
      };
      profile: {
        label?: string;
        error: string;
        placeholder?: string;
        value: string;
        options: SelectOption[];
      };
      type: {
        label?: string;
        error: string;
        value: string;
        disabled?: boolean;
        options: {
          value: number;
          title: string;
          disabled: boolean;
        }[];
      };
      availableEvents: {
        label?: string;
        error: string;
        type: string;
        value: number;
        values: any[];
      };
    };
  }>({
    isLoading: true,
    controls: {
      age: {
        value: 0
      },
      profile: {
        label: "Participant:",
        placeholder: "Type a participant name...",
        error: "",
        value: "",
        options: []
      },
      type: {
        label: "Select participant type:",
        error: "",
        value: "",
        disabled: true,
        options: []
      },
      availableEvents: {
        error: "",
        type: "text",
        value: 0,
        values: []
      }
    }
  });

  const participantNewInput = ref({
    eventId: 0,
    participantTypeId: 0,
    appJson: "[]",
    cost: 0,
    pdatetime: new Date(),
    entityId: 0,
    profileId: 0,
    entityAppId: 0
  });

  const syncFormData = () => {
    let hasError = false;
    let message = "";
    let participantTypeID = 0;
    let participantAmount = 0;
    let participantDeposit = 0;
    let profileId = 0;
    const selectedPtype = formData.value.controls.availableEvents.values.find(
      (item: any) => {
        return formData.value.controls.type.value === item.participantTypeID;
      }
    );
    if (selectedPtype) {
      participantAmount = selectedPtype.participantAmount;
      participantDeposit = selectedPtype.participantDeposit;
      participantTypeID =
        Number.parseInt(formData.value.controls.type.value + "", 10) || 0;
    }
    if (formData.value.controls.profile.value) {
      profileId = parseInt(formData.value.controls.profile.value);
    }
    participantNewInput.value.cost = participantAmount;
    participantNewInput.value.participantTypeId = participantTypeID;
    participantNewInput.value.profileId = profileId;
    participantNewInput.value.eventId = selectedEventId;
    participantNewInput.value.entityAppId = 1;
    participantNewInput.value.entityId = 1;

    if (participantNewInput.value.participantTypeId === 0) {
      hasError = true;
      formData.value.controls.type.error = message =
        "Participant Type is required";
    }
    if (participantNewInput.value.profileId === 0) {
      hasError = true;
      formData.value.controls.profile.error = message =
        "Participant is required";
    }

    return { hasError, message };
  };

  const resetFormData = () => {
    formData.value.controls.profile.value = "";
    formData.value.controls.availableEvents.values.map((item: any) => {
      item.registered = 0;
      item.checked = false;
      return item;
    });
  };

  const popupSendMail = ref<{
    participants: any[];
    show: boolean;
  }>({
    participants: [],
    show: false
  });

  const popupNewItem = ref<{
    show: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
  }>({
    show: false,
    formData: formData,
    removeFieldError: (name: string) => {
      switch (name) {
        case "profile":
          formData.value.controls.profile.error = "";
          break;
        case "availableEvents":
          formData.value.controls.availableEvents.error = "";
          break;
      }
    },
    onSubmit: async ($parent: any) => {
      const { hasError, message } = syncFormData();
      if (!hasError) {
        popupNewItem.value.show = false;
        ApiHelper.setDataLoading(true);

        const result = await ApiHelper.callApi(
          "post",
          "/participants",
          participantNewInput.value,
          {}
        );
        ApiHelper.setDataLoading(false);
        if (result.status == 1) {
          const newId = parseInt(result.data.id);
          popupEditApplication.value.profileId =
            participantNewInput.value.profileId;
          popupEditApplication.value.participantId = newId;
          popupEditApplication.value.requireFinishApp = false;
          popupEditApplication.value.callback = async () => {
            // nothing
          };
          popupEditApplication.value.show = true;
          popupEditApplication.value.isAddingParticipant = true;
          const totalApp = result.data.totalApp || 0;

          // ApiHelper.showSuccessMessage("Created new participant #" + newId);
          resetFormData();
          loadData(1);
          const parent = $parent;
          if (parent.$refs.SidebarEventDetailsRef) {
            parent.$refs.SidebarEventDetailsRef.loadData();
          }
          /*
          const confirm = await context.root.$swal.fire({
            icon: "warning",
            text: `Created new participant #${newId}`
            // showDenyButton: true,
            // denyButtonText: "Add Payment",
            // customClass: {
            //   denyButton: "swal2-blue-btn"
            // }
          });
          */
          if (totalApp) {
            popupEditApplication.value.show = true;
          }

          // if (confirm.isDenied) {
          // pageData.value.addPaymentVisible = true;
          // go to profile financial page

          //   pageData.value.profileIdAddPayment =
          //     participantNewInput.value.profileId;

          //   context.root.$router.push({
          //     name: "ProfileFinancial",
          //     params: {
          //       profileId: `${pageData.value.profileIdAddPayment}`,
          //     },
          //     query: {
          //       addPayment: 1,
          //       eventId: participantNewInput.value.eventId
          //     }
          //   });
          // }
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      }
    }
  });

  const loadPopupPtypes = async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/events/available",
      {},
      {
        eventId: selectedEventId
      }
    );
    if (result.status === 1) {
      const availableEvents = result.data.availableEvents || [];
      formData.value.controls.availableEvents.values = availableEvents.map(
        (item: any) => {
          return { ...item, checked: false, registered: 0 };
        }
      );
      formData.value.controls.type.options = availableEvents.map(
        (item: any) => {
          return {
            value: item.participantTypeID,
            title: item.participantTypeName,
            disabled: true,
            data: item
          };
        }
      );
    }
  };

  const openNewPopup = async () => {
    popupNewItem.value.formData.controls.type.error = "";
    popupNewItem.value.formData.controls.profile.error = "";
    popupNewItem.value.formData.controls.type.value = "";
    popupNewItem.value.formData.controls.profile.value = "";
    popupNewItem.value.formData.controls.type.disabled = true;
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "get",
      "/events/available",
      {},
      {
        eventId: selectedEventId
      }
    );
    ApiHelper.setDataLoading(false);
    if (result.status === 1) {
      const availableEvents = result.data.availableEvents || [];
      formData.value.controls.availableEvents.values = availableEvents.map(
        (item: any) => {
          return { ...item, checked: false, registered: 0 };
        }
      );
      formData.value.controls.type.options = availableEvents.map(
        (item: any) => {
          return {
            value: item.participantTypeID,
            title: item.participantTypeName,
            disabled: true,
            data: item
          };
        }
      );
      popupNewItem.value.show = true;
    }
  };

  const hideHeadActions = () => {
    pageData.value.showHeadActions = false;
  };

  // add multi participants to cabin
  const addToCabin = async (forceAdd = false) => {
    pageData.value.addToCabinErrMessage = "";
    pageData.value.addToCabinNameErrMessage = "";
    const selectedParticipants = pageData.value.items.filter(
      item => item.isChecked
    );
    const participantIDs = selectedParticipants.map((item: any) => {
      return `${item.id}-${item.profileId}`;
    });

    // validate
    let valid = true;
    if (
      pageData.value.addToCabinID == 0 &&
      pageData.value.addToCabinName === ""
    ) {
      pageData.value.addToCabinErrMessage = "Please select a cabin!";
      valid = false;
    }
    if (participantIDs.length == 0) {
      pageData.value.addToCabinErrMessage = "Please select a participant!";
      valid = false;
    }
    if (!valid) {
      return;
    }

    if (valid) {
      const foundCabin: any = cabinOptions.value.find(
        item => item.id == pageData.value.addToCabinID
      );
      if (foundCabin) {
        const linkedEntityCabin = foundCabin.data.linkedEntityCabin || {};
        const cabinGender = linkedEntityCabin.gender || 0;
        if (cabinGender) {
          const invalidParticipants = selectedParticipants.filter(
            (item: any) => item.genderId != cabinGender
          );
          if (invalidParticipants.length) {
            const genderName = ApiHelper.getGenderName(cabinGender);
            await Vue.swal({
              html: `This cabin ties to ${genderName.toLowerCase()} only. Please check gender of selected participants.`,
              showCloseButton: true,
              closeButtonHtml:
                '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">',
              timer: 5000
            }).then(result => {
              setTimeout(function() {
                $(".swal2-backdrop-hide").addClass("d-none");
              }, 200);
              return result.isConfirmed;
            });
            return false;
          }
        }
      }
      if (!forceAdd) {
        // show confirm box when move participants to other cabins from a cabin
        const inOtherCabins = selectedParticipants.filter((item: any) => {
          return (
            item.groupID != 0 && item.groupID != pageData.value.addToCabinID
          );
        });
        if (inOtherCabins.length) {
          pageData.value.confirmAddToCabinMessage = `You are switching: `;
          const messages = inOtherCabins.map((item: any, index: number) => {
            return (
              `<span><strong>"${[item.lastName, item.firstName].join(
                " "
              )}"</strong> out of <strong>"${item.cabin}"</strong></span>` +
              (index == inOtherCabins.length - 1
                ? ""
                : index == inOtherCabins.length - 2
                ? " and "
                : ", ")
            );
          });
          pageData.value.confirmAddToCabinMessage += messages.join("");
          pageData.value.confirmAddToCabinMessage += ``;
          // pageData.value.ConfirmModalAddToCabinVisible = true;

          pageData.value.addToCabinVisible = false;
          const isConfirmed = await Vue.swal({
            title: "Confirm Switching Cabin",
            html: pageData.value.confirmAddToCabinMessage,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            showCloseButton: true,
            closeButtonHtml:
              '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
          }).then((result: any) => {
            setTimeout(function() {
              $(".swal2-backdrop-hide").addClass("d-none");
            }, 200);
            return result.isConfirmed;
          });
          if (isConfirmed) {
            await addToCabin(true);
          }

          return;
        }
      }
      try {
        const selectedCabinId =
          Number.parseInt(pageData.value.addToCabinID + "", 10) || 0;
        const result = await ApiHelper.callApi(
          "post",
          "/cabins/" + selectedCabinId + "/participants",
          {
            participantsList: participantIDs.join(","),
            groupName: pageData.value.addToCabinName,
            eventId: selectedEventId,
            outOfCurrentCabin: true
          },
          {}
        );
        ApiHelper.setDataLoading(true);

        if (result.status === 1) {
          ApiHelper.showSuccessMessage("Added");
          loadData(1);
          const parent: any = context.parent || null;
          if (parent && parent.$refs.SidebarEventDetailsRef) {
            parent.$refs.SidebarEventDetailsRef.loadData();
          }
          pageData.value.addToCabinVisible = false;
        } else {
          ApiHelper.showErrorMessage(result.message, "Oops");
        }
      } catch (error) {
        console.log(error);
      } finally {
        ApiHelper.setDataLoading(false);
      }
    }
  };

  const getUserSettings = async () => {
    // userSettings
    try {
      userSettings.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "get",
        "/users/userSettings",
        {},
        { customizeType: "participantsCustomView" },
        "core"
      );
      if (result.status === 1) {
        const participantsCustomView = result.data.participantsCustomView || [];
        // default options
        const options = [
          {
            name: "Status",
            optional: false,
            checked: true
          },
          {
            name: "Participant",
            optional: false,
            checked: true
          },
          {
            name: "Types",
            optional: true,
            checked: true
          },
          {
            name: "Age",
            optional: true,
            checked: true
          },
          {
            name: "Gender",
            optional: true,
            checked: true
          },
          {
            name: "Todos",
            optional: true,
            checked: true
          },
          {
            name: "Allergies",
            optional: true,
            checked: true
          },
          {
            name: "Missing To-dos",
            optional: true,
            checked: true
          },
          {
            name: "Cabin",
            optional: true,
            checked: true
          },
          {
            name: "Balance",
            optional: true,
            checked: true
          },
          {
            name: "Date",
            optional: true,
            checked: true
          },
          {
            name: "Family",
            optional: true,
            checked: false
          },
          {
            name: "City, State",
            optional: true,
            checked: false
          },
          {
            name: "Phone Number",
            optional: true,
            checked: false
          },
          {
            name: "Email",
            optional: true,
            checked: false
          }
        ];
        if (participantsCustomView.length) {
          for (const item of options) {
            const savedData = participantsCustomView.find(
              (t: any) => t.name == item.name
            );
            if (savedData) {
              Object.assign(item, savedData);
            }
          }
        }

        userSettings.value.participantsCustomView = options;
      }
    } catch (error) {
      console.log(error);
    } finally {
      userSettings.value.isLoading = false;
    }
  };

  const showColumn = (itemName: string) => {
    const currentTab = parseInt(`${context.root.$route.query.tab || 1}`);
    if (currentTab == 2) return true;

    // check hide/show column at tab "list view"
    if (currentTab == 1) {
      const participantsCustomView =
        userSettings.value.participantsCustomView || [];
      const relatedItem: any = participantsCustomView.find(
        (item: any) => item.name.toLowerCase() == itemName.toLowerCase()
      );
      if (relatedItem) {
        return relatedItem.checked || false;
      }
    }

    return true;
  };

  const addPayment = async (item: any) => {
    if ((item.pRegistrationStep || 0) == 0) {
      // should fill application before payment
      const confirm = await Vue.swal({
        html:
          "Payment is not authorized until the participant's application is complete. Would you like to complete the application?",
        showCancelButton: true,
        confirmButtonText: "Complete Application",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">',
        customClass: {
          container: "swal2-actions-p0"
        }
      });
      if (confirm.isConfirmed) {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        popupEditApplication.value.profileId = item.profileId;
        popupEditApplication.value.participantId = item.id;
        popupEditApplication.value.show = true;
      }
    } else {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);

      const routeQuery: any = {
        id: item.id + "",
        eventId: context.root.$route.params.eventId,
        tab: context.root.$route.query.tab
      };
      if (!item.isPriorEvent || (item.isPriorEvent && item.balance != 0)) {
        routeQuery.payment = "true";
      }
      context.root.$router.push({
        name: "ProfileFinancial",
        params: {
          profileId: item.profileId.toString()
        },
        query: routeQuery
      });
    }
  };

  const searchProfiles = async (key: string) => {
    isACILoading.value = true;
    joinedEvents.value = [];
    formData.value.controls.profile.value = "";
    const result = await ApiHelper.callApi(
      "get",
      "/profiles/search",
      {},
      {
        order: 2,
        direction: 1,
        key: key,
        ignoreSections: formData.value.controls.type.options
          .map(item => {
            return `${selectedEventId}-${item.value}`;
          })
          .join(",")
      }
    );
    if (result.status === 1) {
      popupNewItem.value.formData.controls.profile.options = result.data.profiles.map(
        (item: any) => ({
          id: item.id,
          text: ApiHelper.getFullName(item.firstName, item.lastName),
          data: item
        })
      );
    }
    isACILoading.value = false;
  };

  const selectEvent = async (item: any) => {
    if (!formData.value.controls.profile.value) {
      return;
    }
    let minAge = 0;
    let maxAge = 0;
    const arr = (item.eventAges || "").split("-");
    if (arr.length == 2) {
      minAge = Number.parseInt(arr[0], 10) || 0;
      maxAge = Number.parseInt(arr[1], 10) || 0;
    } else if (item.eventAges) {
      const limitAge = Number.parseInt(item.eventAges, 10) || 0;
      if (limitAge > 0) {
        minAge = limitAge;
      }
    }
    let ageError = "";
    const memberAge = formData.value.controls.age.value;
    if (minAge) {
      if (memberAge < minAge) {
        ageError =
          "Age should be greater than <strong>" +
          minAge +
          "</strong>, would you like to proceed?";
      }
    }
    if (maxAge) {
      if (memberAge > maxAge) {
        ageError =
          "Age should be less than <strong>" +
          maxAge +
          "</strong>, would you like to proceed?";
      }
    }

    if (!item.checked && ageError) {
      const isConfirmed = await Vue.swal({
        title: "Are you sure?",
        html: ageError,
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });
      if (!isConfirmed) {
        return;
      }
    }

    if (item.checked) {
      item.checked = false;
      return false;
    }

    if (!item.disabled) {
      const foundEvent: any = joinedEvents.value.find((joinedEvent: any) => {
        return joinedEvent.eventID === item.data.eventID;
      });
      if (foundEvent) {
        const message =
          "<strong>" +
          (selectedProfile.value
            ? selectedProfile.value.firstName +
              " " +
              selectedProfile.value.lastName
            : "") +
          "</strong> is already registered for <strong>" +
          foundEvent.eventName +
          "</strong> as a <strong>" +
          foundEvent.participantTypeName +
          "</strong>, would you like to proceed?";
        Vue.swal({
          title: "Are you sure?",
          html: message,
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          if (result.isConfirmed) {
            formData.value.controls.availableEvents.values.map((event: any) => {
              if (event.registered !== 1) {
                event.checked = false;
              }
            });
            item.checked = true;
            formData.value.controls.availableEvents.error = "";
          } else {
            formData.value.controls.type.value = "";
          }
        });
      } else {
        formData.value.controls.availableEvents.values.map((event: any) => {
          if (event.registered !== 1) {
            event.checked = false;
          }
        });
        item.checked = true;
        formData.value.controls.availableEvents.error = "";
      }
    }
  };

  const selectProfile = (item: any) => {
    formData.value.controls.type.disabled = false;
    formData.value.controls.type.error = "";
    formData.value.controls.type.value = "";
    formData.value.controls.profile.error = "";
    try {
      joinedEvents.value = JSON.parse(item.data.joinedEvents || "[]");
    } catch (e) {
      joinedEvents.value = [];
    }
    selectedProfile.value = item.data;
    formData.value.controls.age.value = item.data.age || 0;
    const profileID = item.data.id || "0";
    formData.value.controls.profile.value = profileID;
    formData.value.controls.type.options.map((option: any) => {
      const foundEvent = joinedEvents.value.find((joinedEvent: any) => {
        return (
          joinedEvent.participant_typeID === option.value &&
          joinedEvent.eventID === selectedEventId
        );
      });

      if (foundEvent) {
        option.disabled = true;
      } else {
        option.disabled = false;
      }
    });
  };

  const openPopupAddToCabin = () => {
    (async () => {
      pageData.value.addToCabinID = 0;
      pageData.value.addToCabinVisible = true;
      pageData.value.addToCabinName = "";
      const cabins = await ApiHelper.getCabinsOptions(selectedEventId);
      cabinOptions.value = cabins.filter(item => {
        return item.id != 0;
      });
    })();
  };

  const mergeParticipants = async () => {
    const selectedParticipants = pageData.value.items.filter(
      item => item.isChecked
    );
    const participantIDs = selectedParticipants.map((item: any) => {
      return item.id;
    });
    if (participantIDs.length === 0) {
      ApiHelper.showErrorMessage("Please select participants to merge");
      return false;
    }
    const isConfirm = await Vue.swal({
      html: `Are you sure you want to merge the <strong>${participantIDs.length}</strong> selected participants?`,
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then(result => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      return result.isConfirmed;
    });
    if (!isConfirm) {
      return false;
    }
    const result = await ApiHelper.callApi(
      "post",
      "/participants/merge",
      {
        participants: participantIDs.join(",")
      },
      {}
    );
    if (result.status !== 1) {
      ApiHelper.showErrorMessage(result.message, "Oops");
      return false;
    }
    ApiHelper.showSuccessMessage("Merged participants");
    loadData(1);
  };

  const showMergeButton = () => {
    const selectedParticipants = pageData.value.items.filter(
      item => item.isChecked
    );
    const participantIDs: number[] = [];
    selectedParticipants.map((item: any) => {
      if (!participantIDs.includes(item.id)) {
        participantIDs.push(item.id);
      }
    });
    return participantIDs.length > 1;
  };

  const exportCSV = async () => {
    try {
      ApiHelper.setDataLoading(true);
      const result = await getParticipants(1);
      const participants = result.data.participants || [];
      const columns = [
        "Status",
        "Participant",
        "Types",
        "Age",
        "Gender",
        "Todos",
        "Missing To-dos",
        "Allergies",
        "Cabin",
        "Balance",
        "Date",
        "Family",
        "City, State",
        "Phone Number",
        "Email"
      ];

      const exportData = participants.map((item: any) => {
        const itemData = [];
        if (showColumn("Status")) {
          itemData.push(ApiHelper.getParticipantStatusText(item.status));
        }
        if (showColumn("Profile")) {
          // itemData.push(ApiHelper.getFullName(item.firstName, item.lastName));
          itemData.push(item.firstName);
          itemData.push(item.lastName);
        }
        if (showColumn("Types")) {
          itemData.push(item.participantTypeName);
        }
        if (showColumn("Age")) {
          itemData.push(item.age || "");
        }
        if (showColumn("Gender")) {
          itemData.push(ApiHelper.getGenderName(item.gender));
        }
        if (showColumn("Todos")) {
          itemData.push(
            item.todosNeeded ? ` ${item.todosDone} / ${item.todosNeeded}` : ""
          );
        }
        if (showColumn("Missing To-dos")) {
          itemData.push(item.todoNamesMissing || "");
        }
        if (showColumn("Allergies")) {
          itemData.push(item.allergiesTotal);
        }
        if (showColumn("Cabin")) {
          itemData.push(item.cabin || "");
        }
        if (showColumn("Balance")) {
          itemData.push(ApiHelper.dollarFormat(item.balance || 0));
        }
        if (showColumn("Date")) {
          itemData.push(item.pFullDatetimeFormatted || "");
        }
        if (showColumn("Family")) {
          itemData.push(item.familyName || "");
        }
        if (showColumn("City, State")) {
          // itemData.push(item.cityState || "");
          itemData.push(item.pCity || "");
          itemData.push(item.state || "");
        }
        if (showColumn("Phone Number")) {
          itemData.push(item.pPhoneFormatted || "");
        }
        if (showColumn("Email")) {
          itemData.push(item.email || "");
        }

        return itemData;
      });

      const headers: any = [];
      for (const column of columns) {
        if (showColumn(column)) {
          switch (column) {
            case "Profile":
            case "Participant":
              headers.push("First Name");
              headers.push("Last Name");
              break;
            case "City, State":
              headers.push("City");
              headers.push("State");
              break;
            default:
              headers.push(column);
              break;
          }
        }
      }
      const eventName = (
        context.refs.headerEventDetails?.pageTitle || ""
      ).replace(/\s/g, "");
      const fileName = `${eventName}_participants_${moment().format(
        "YYYYMMDDHHmmSS"
      )}`;
      await ApiHelper.generateCsv(exportData, headers, fileName);
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const exportCSVAppData = async () => {
    try {
      ApiHelper.setDataLoading(true);
      const result = await getParticipants(1, "app_data");
      const participants = result.data.participants || [];
      const columns = [
        "Status",
        "Participant",
        "Types",
        "Age",
        "Gender",
        "Todos",
        "Missing To-dos",
        "Allergies",
        "Cabin",
        "Balance",
        "Date",
        "Family",
        "City, State",
        "Phone Number",
        "Email"
      ];

      // get app fields
      let allAppData: any = [];
      for (const item of participants) {
        allAppData = [...allAppData, ...item.inputJson];
      }
      let moreFields = allAppData.map((item: any) => item.name);
      moreFields = [...new Set(moreFields)];

      const exportData = participants.map((item: any) => {
        const itemData = [];
        if (showColumn("Status")) {
          itemData.push(`"${ApiHelper.getParticipantStatusText(item.status)}"`);
        }
        if (showColumn("Profile")) {
          // itemData.push(ApiHelper.getFullName(item.firstName, item.lastName));
          itemData.push(`"${item.firstName}"`);
          itemData.push(`"${item.lastName}"`);
        }
        if (showColumn("Types")) {
          itemData.push(`"${item.participantTypeName}"`);
        }
        if (showColumn("Age")) {
          itemData.push(`"${item.age || ""}"`);
        }
        if (showColumn("Gender")) {
          itemData.push(`"${ApiHelper.getGenderName(item.gender)}"`);
        }
        if (showColumn("Todos")) {
          itemData.push(
            `"${
              item.todosNeeded ? ` ${item.todosDone} / ${item.todosNeeded}` : ""
            }"`
          );
        }
        if (showColumn("Missing To-dos")) {
          itemData.push(`"${item.todoNamesMissing || ""}"`);
        }
        if (showColumn("Allergies")) {
          itemData.push(`"${item.allergiesTotal}"`);
        }
        if (showColumn("Cabin")) {
          itemData.push(`"${item.cabin || ""}"`);
        }
        if (showColumn("Balance")) {
          itemData.push(`"${ApiHelper.dollarFormat(item.balance || 0)}"`);
        }
        if (showColumn("Date")) {
          itemData.push(`"${item.pFullDatetimeFormatted || ""}"`);
        }
        if (showColumn("Family")) {
          itemData.push(`"${item.familyName || ""}"`);
        }
        if (showColumn("City, State")) {
          // itemData.push(item.cityState || "");
          itemData.push(`"${item.pCity || ""}"`);
          itemData.push(`"${item.state || ""}"`);
        }
        if (showColumn("Phone Number")) {
          itemData.push(`"${item.pPhoneFormatted || ""}"`);
        }
        if (showColumn("Email")) {
          itemData.push(`"${item.email || ""}"`);
        }

        // append app data
        const inputJson = item.inputJson || [];
        for (const name of moreFields) {
          const inList = inputJson.find((t: any) => t.name == name);
          itemData.push(`"${inList?.value || ""}"`);
        }

        return itemData;
      });

      const headers: any = [];
      for (const column of columns) {
        if (showColumn(column)) {
          switch (column) {
            case "Profile":
            case "Participant":
              headers.push(`"First Name"`);
              headers.push(`"Last Name"`);
              break;
            case "City, State":
              headers.push(`"City"`);
              headers.push(`"State"`);
              break;
            default:
              headers.push(`"${column}"`);
              break;
          }
        }
      }

      // append app fields into headers
      for (const name of moreFields) {
        const inList = allAppData.find((t: any) => t.name == name);
        const label = ApiHelper.htmlParse(inList?.label || "");
        headers.push(`"${label}"`);
      }

      const eventName = (
        context.refs.headerEventDetails?.pageTitle || ""
      ).replace(/\s/g, "");
      const fileName = `${eventName}_participants_all_data_sets_${moment().format(
        "YYYYMMDDHHmmSS"
      )}`;
      await ApiHelper.generateCsv(exportData, headers, fileName, false);
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const updateParticipantsCustomView = async () => {
    const participantsCustomView =
      userSettings.value.participantsCustomView || [];
    if (participantsCustomView.length == 0) return;

    const result = await ApiHelper.callApi(
      "put",
      "/users/userSettings",
      {
        participantsCustomView,
        customizeType: "participantsCustomView"
      },
      {},
      "core"
    );
  };

  const toggleCustomize = () => {
    customizeVisible.value = !customizeVisible.value;
  };

  const hideCustomize = () => {
    customizeVisible.value = false;
  };

  const showExportOptions = async () => {
    const confirm = await context.root.$swal({
      html: "Select export option:",
      showDenyButton: true,
      confirmButtonText: "Export the current view",
      denyButtonText: "All data sets"
    });
    if (confirm.isConfirmed) {
      exportCSV();
    } else if (confirm.isDenied) {
      // exportCSV("app_data");
      exportCSVAppData();
    }
  };

  // check ptype filter
  (async () => {
    if (context.root.$route.query.pTypes) {
      await loadPtypeOptions();
    }
  })();
  // check cabin filter
  (async () => {
    if (context.root.$route.query.cabin) {
      await loadCabinOptions();
    }
  })();
  (async () => {
    await loadPopupPtypes();
  })();

  // init data
  (async () => {
    const query = context.root.$route.query;
    const updatedFilter = query.updatedFilter || 0;
    const currentTab = parseInt(`${context.root.$route.query.tab || 1}`);
    pageData.value.arrGenders = ApiHelper.getGenderOptions();
    pageData.value.filter.status = ApiHelper.getParticipantStatusOptions({
      getDeniedStatus: currentTab == 1 ? true : false
    });
    const statusIds = (context.root.$route.query.status || "")
      .split(",")
      .filter((id: string) => id != "");
    pageData.value.filter.status.forEach((item: any) => {
      if (statusIds.indexOf(item.id) > -1) {
        item.selected = true;
      }
    });

    // set default participant statuses list
    if (!updatedFilter) {
      if (query.status == undefined) {
        pageData.value.filter.status = pageData.value.filter.status.map(
          item => ({
            ...item,
            selected: ![
              ParticipantStatus.Denied,
              ParticipantStatus.Cancelled
            ].includes(parseInt(`${item.id}`))
              ? true
              : false
          })
        );
      }
    }

    pageData.value.filter.gender = ApiHelper.getGenderOptions();
    const genderIds = (context.root.$route.query.gender || "").split(",");
    pageData.value.filter.gender.forEach((item: any) => {
      if (genderIds.indexOf(item.id) > -1) {
        item.selected = true;
      }
    });

    if (currentTab == 1) {
      // get user settings for customize columns at list view
      await getUserSettings();
    }

    loadData(1);
  })();

  return {
    popupSendMail,
    loadList,
    popupEditApplication,
    selectedEventStatus,
    updateRouters,
    openNewPopup,
    selectedProfile,
    showMergeButton,
    mergeParticipants,
    cabinOptions,
    openPopupAddToCabin,
    searchProfiles,
    selectEvent,
    selectProfile,
    popupNewItem,
    // sort
    updateSortValue,
    pageData,
    showStatusDropdown,
    updateParticipantStatus,
    // filters
    selectAllStatuses,
    resetStatuses,
    selectAllGenders,
    resetGenders,
    selectAllCabins,
    resetCabins,
    onApply,
    // --- pager
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    // --- filters
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab,
    hideHeadActions,
    addToCabin,
    selectAllPTypes,
    resetPTypes,
    isACILoading,
    participantInfo,
    loadData,
    exportCSV,
    userSettings,
    updateParticipantsCustomView,
    getUserSettings,
    toggleCustomize,
    hideCustomize,
    customizeVisible,
    showColumn,
    addPayment,
    showExportOptions
  };
}
