
































import { defineComponent } from "@vue/composition-api";
import FormItem from "@/components/Form/FormItem.vue";
import FormButton from "@/components/Form/FormButton.vue";
import { useUserForgotPasswordStore } from "@/stores/User/UserForgotPasswordStore";
import Loading from "@/components/Common/Loading.vue";

export default defineComponent({
  name: "UserForgotPasswordPage",
  components: {
    Loading,
    FormButton,
    FormItem
  },

  /*   data: {
    clickMessage: ""
  }, */

  /*   methods: {
    clickButton: function() {
      this.clickMessage =
        "If your email was found in our system, we have sent you Password Reset instructions.";
    }
  }, */

  setup(props, context) {
    const { pageData } = useUserForgotPasswordStore(context);
    return {
      pageData
    };
  }
});
