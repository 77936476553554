



































































































import { defineComponent } from "@vue/composition-api";

import Loading from "@/components/Common/Loading.vue";
import EmailBuilder from "@/components/Messaging/grapesjs.vue";
import { useMessagingTemplatesEditStore } from "@/stores/Messaging/Template/MessagingTemplatesEditStore";
import Header from "@/components/Common/Header.vue";

export default defineComponent({
  name: "MessagingTemplatesEditPage",
  components: {
    Loading,
    EmailBuilder,
    Header
  },
  setup(props, context) {
    const {
      dataInput,
      pageData,
      loadData,
      saveTemplate,
      loadTemplate,
      sendTest
    } = useMessagingTemplatesEditStore(context);

    return {
      pageData,
      dataInput,
      loadData,
      saveTemplate,
      loadTemplate,
      sendTest
    };
  }
});
