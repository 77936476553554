var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SettingsParticipantTypesEditPage position-relative"},[_c('div',{staticClass:"content-head has__button"},[_c('div',{staticClass:"head_left"},[_c('h3',{staticClass:"head_heading__small"},[_vm._v(" "+_vm._s(_vm.headerData.subTitle || "")+" ")]),_c('h2',{staticClass:"head_heading__other"},[_vm._v(_vm._s(_vm.headerData.title || ""))])]),_c('div',{staticClass:"head_right"},[_c('div',{staticClass:"head__button"},[_c('a',{staticClass:"btn btn-lg btn-outline-primary",on:{"click":_vm.onFormSubmit}},[_vm._v(" Save ")]),_c('router-link',{staticClass:"btn btn-lg btn-outline-danger",staticStyle:{"margin-left":"10px"},attrs:{"to":{ name: 'SettingsParticipantTypes' }}},[_vm._v(" Cancel ")])],1)])]),_c('div',{staticClass:"content-inside mw-1300"},[_c('div',{staticClass:"content_add"},[_c('div',{staticClass:"content__form"},[_c('form',{attrs:{"method":"post","accept-charset":"utf-8"}},[_c('div',{staticClass:"form__info"},[_c('div',{staticClass:"group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormInput',{attrs:{"data":_vm.formData.controls.name}})],1)]),_c('div',{staticClass:"row d-none"},[_c('div',{staticClass:"col col-6"},[_c('FormUpload',{attrs:{"data":_vm.formData.controls.photo}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormSelect',{attrs:{"data":_vm.formData.controls.active}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormSelect',{attrs:{"data":_vm.formData.controls.requirePhoto}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6",class:{
                    hasError: _vm.formData.controls.cost.error !== ''
                  }},[_c('label',{staticClass:"label__full",attrs:{"for":"participantTypeCost"}},[_vm._v("Cost")]),_c('div',{staticClass:"group__input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.controls.cost.value),expression:"formData.controls.cost.value"},{name:"mask",rawName:"v-mask",value:({
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                        autoUnmask: true,
                        allowMinus: false
                      }),expression:"{\n                        alias: 'currency',\n                        prefix: '$',\n                        rightAlign: false,\n                        autoUnmask: true,\n                        allowMinus: false\n                      }"}],staticClass:"input__full",attrs:{"id":"participantTypeCost","maxlength":"20"},domProps:{"value":(_vm.formData.controls.cost.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData.controls.cost, "value", $event.target.value)},function($event){return _vm.formData.removeFieldError('cost')}]}}),_c('span'),(_vm.formData.controls.cost.error)?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.formData.controls.cost.error)+" ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6",class:{
                    hasError: _vm.formData.controls.ages.error !== ''
                  },staticStyle:{"margin-bottom":"25px"}},[_c('label',{staticClass:"label__full"},[_vm._v("Age Range")]),_c('div',{staticClass:"group__select"},[_c('Select2',{staticClass:"select2Left",class:{
                        hasErrorSelect: _vm.formData.controls.ageFrom.error
                      },attrs:{"placeholder":"Age","options":_vm.ageOptions,"settings":{ multiple: false }},on:{"change":_vm.checkRangeAge,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return (function (e) { return _vm.$emit('onTab', e); })($event)}},model:{value:(_vm.formData.controls.ageFrom.value),callback:function ($$v) {_vm.$set(_vm.formData.controls.ageFrom, "value", $$v)},expression:"formData.controls.ageFrom.value"}}),_vm._m(0),_c('Select2',{staticClass:"select2Right",class:{
                        hasErrorSelect: _vm.formData.controls.ageTo.error
                      },attrs:{"placeholder":"Age","options":_vm.ageOptions,"settings":{ multiple: false }},on:{"change":_vm.checkRangeAge,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return (function (e) { return _vm.$emit('onTab', e); })($event)}},model:{value:(_vm.formData.controls.ageTo.value),callback:function ($$v) {_vm.$set(_vm.formData.controls.ageTo, "value", $$v)},expression:"formData.controls.ageTo.value"}}),_c('span')],1)])]),_c('div',{staticClass:"row",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"col col-6"},[_c('label',{staticClass:"label__full"},[_vm._v("Posting Code")]),_c('div',{staticClass:"group__select",class:{
                      'text-danger-customize':
                        _vm.formData.controls.postingCodes.error
                    }},[_c('FormTags',{ref:"postingCodeRef",staticClass:"posting-codes-list",class:{
                        'allow-add-new': true
                      },attrs:{"noTextDanger":_vm.formData.controls.postingCodes.error,"allowAddColor":true,"has-border":true,"onRemoveTag":function (index0) {
                          _vm.onRemovePostingCodes(
                            index0,
                            _vm.formData.controls.postingCodes
                          );
                        },"onSuggestTags":function (key) {
                          _vm.onSuggestPostingCodes(
                            key,
                            _vm.formData.controls.postingCodes
                          );
                        },"onSelectTag":function (value) {
                          _vm.onSelectPostingCode(
                            value,
                            _vm.formData.controls.postingCodes
                          );
                        },"suggestOnFocus":true,"data":_vm.formData.controls.postingCodes,"inputTimeoutSecond":300,"multiSelection":false,"nowrap":true,"noRecordMessage":("<div class='cursor-pointer textOverflow' style='max-width: 70%;'>\n                              <div class='textOverflow'>\n                                <strong title='" + (_vm.formData.controls.postingCodes.key) + "'>\n                                  " + (_vm.formData.controls.postingCodes.key) + "\n                                </strong>\n                              </div>\n                            </div>\n                          "),"isNormal":true,"nowrapSuggestedItems":true},on:{"focus":function (key) {
                          _vm.onSuggestPostingCodes(
                            key,
                            _vm.formData.controls.postingCodes
                          );
                        },"addNewClick":function($event){return _vm.showNewPostingCodeModal(
                          _vm.formData.controls.postingCodes.key
                        )}}})],1)])]),_c('div',{staticClass:"row",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"col col-12 col-md-6"},[_c('FormInput',{attrs:{"noneBackground":_vm.formData.controls.desc.error ? true : false,"data":_vm.formData.controls.desc}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-6"},[_c('FormInput',{attrs:{"noneBackground":_vm.formData.controls.glName.error ? true : false,"data":_vm.formData.controls.glName}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-6"},[_c('FormInput',{attrs:{"noneBackground":_vm.formData.controls.glCode.error ? true : false,"data":_vm.formData.controls.glCode}})],1)])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"spanLeft"},[_c('b',[_vm._v(" - ")])])}]

export { render, staticRenderFns }