


























































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import { ApiHelper } from "@/helpers";
import { v4 as uuidv4 } from "uuid";
import directives from "@/helpers/directives";
import FormTags from "@/components/Form/FormTags.vue";
import Modal from "@/components/Common/Modal.vue";
import FormText from "@/types/FormText";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormDatePicker2 from "../Form/FormDatePicker2.vue";
import moment from "moment";

export default defineComponent({
  name: "EventAddons",
  props: {
    eventId: Number,
    items: Array as () => any[],
    data: Object,
    updateItems: Function,
    costcenters: {
      type: Array,
      defaultValue: []
    },
    eventCoscenterNumber: {
      type: Array,
      default: []
    },
    isAllowAddNewPostingCode: Function
  },
  directives: directives,
  components: {
    FormInput,
    AutoCompleteInput,
    FormTags,
    Modal,
    FormTextarea,
    FormDatePicker2
  },
  setup(props, context) {
    const isACILoading = ref<boolean>(false);
    const componentData = ref<{
      allowTop: boolean;
      // items: any[];
      // allowAdd: boolean;
      // isLoading: boolean;
      // editingIndex: number;
      controls: {
        postingCode: FormText;
        postingCodeDesc: FormText;
        glName: FormText;
      };
    }>({
      allowTop: false,
      // items: [],
      // allowAdd: true,
      // isLoading: false,
      // editingIndex: -1,
      controls: {
        postingCode: {
          required: true,
          label: "Posting Code",
          placeholder: "Posting Code",
          style: "custom",
          value: "",
          error: "",
          maxlength: 30
        },
        postingCodeDesc: {
          required: true,
          // label: "Description",
          label: "Posting Code Name",
          // placeholder: "Description",
          placeholder: "Posting Code Name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 255
        },
        glName: {
          required: true,
          label: "GL Name",
          placeholder: "GL Name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 100
        }
      }
    });
    const foundPTypes = ref<any[]>([]);
    const newPostingCode = ref<{
      modalVisible: boolean;
      postingCode: string;
      currentAddOn: any;
    }>({
      modalVisible: false,
      postingCode: "",
      currentAddOn: {}
    });
    const glCodes = ref<any>({
      required: true,
      error: "",
      label: "GL Code",
      placeholder: "Select or input a GL Code",
      type: "tags",
      key: "",
      value: [],
      options: [],
      suggestTags: []
    });

    const getPTypesHtml = (item: any) => {
      let ret = `${item.serviceName}`;
      if (item.serviceCost) {
        const moreInfo = [];
        if (item.serviceCost) {
          moreInfo.push(`Cost: ${ApiHelper.dollarFormat(item.serviceCost)}`);
        }
        ret = `${item.serviceName} - (${moreInfo.join(", ")})`;
      } else {
        ret = `${item.serviceName}`;
      }

      return ret;
    };

    const addPTypeRow = () => {
      if (props.items) {
        props.items.push({
          uuid: uuidv4(),
          id: 0,
          eventId: 0,
          addonServiceId: 0,
          serviceName: "",
          serviceDesc: "",
          serviceCost: "",
          linkAddonCost: "",
          costcenter: "0",
          isFundBucket: false,
          active: 0,
          showRemoveCntDown: false,
          totalParticipants: 0,
          cntDown: 3,
          error: false,
          tags: {
            error: "",
            label: "",
            placeholder: "Enter a tag",
            type: "tags",
            key: "",
            value: [],
            options: [],
            suggestTags: []
          },
          postingCodes: {
            error: "",
            label: "",
            textOverflow: true,
            placeholder: "Posting / GL Code",
            type: "tags",
            key: "",
            value: [],
            options: [],
            suggestTags: []
          },
          costCenters: {
            error: "",
            label: "",
            textOverflow: true,
            placeholder: "Cost Center Number",
            type: "tags",
            key: "",
            firstFocus: true,
            value: [],
            options: [],
            suggestTags: []
          },
          availableDate: {
            value: undefined,
            error: ""
          }
        });
      }
    };

    const suggestPTypes = async (key: string, item: any, index: number) => {
      // reset
      foundPTypes.value = [];
      item.serviceName = key;

      // add new row once they get down to the last row
      if (props.items) {
        if (index == props.items.length - 1) {
          addPTypeRow();
        }
      }

      if (key !== "") {
        // searching
        item.id = -1;

        const selectedPTypeNames = props.items
          ? props.items
              .filter(
                item =>
                  item.serviceName && item.serviceName != "" && item.id != -1
              )
              .map(item => item.serviceName)
          : [];
        try {
          isACILoading.value = true;
          const result = await ApiHelper.callApi(
            "get",
            "/services/search",
            {},
            {
              key: key,
              take: 25,
              selectedNames: selectedPTypeNames.length
                ? JSON.stringify(selectedPTypeNames)
                : ""
            }
          );
          if (result.status === 1) {
            foundPTypes.value = result.data.services.map((item: any) => ({
              id: item.addonServiceId,
              text: item.serviceName,
              html: getPTypesHtml(item),
              data: item
            }));
          }
        } catch (error) {
          console.log(error);
        } finally {
          item.id = 0;
          isACILoading.value = false;
        }
      }
    };
    const selectPType = (data: any, item: any) => {
      item.id = data.addonServiceId || 0;
      item.addonServiceId = data.addonServiceId || 0;
      item.name = data.serviceName || "";
      item.serviceName = data.serviceName || "";
      item.serviceDesc = data.serviceDesc || "";
      item.serviceCost = data.serviceCost || data.linkAddonCost || 0;
      item.isFundBucket = data.isFundBucket;
      item.tags.value = (data.tags || []).map((tag: any) => {
        return {
          id: tag.id,
          text: tag.name
        };
      });

      const postingCode = data.postingCode || "";
      const glCode = data.glCode || "";
      const costCenterNumber = data.costCenterNumber || "";
      item.postingCodes.value = [];
      if (postingCode != "" && glCode != "") {
        item.postingCodes.value = [
          {
            id: postingCode,
            text: `${postingCode} / ${glCode}`
          }
        ];
      }
      item.costCenters.value = [];
      if (costCenterNumber != "") {
        item.costCenters.value = [
          {
            id: costCenterNumber,
            text: costCenterNumber
          }
        ];
      }
    };

    const removePTypes = async (pType: any) => {
      if (props.updateItems && props.items) {
        const newLines = props.items.filter(
          (item: any) => item.uuid != pType.uuid
        );
        props.updateItems(newLines);
      }
    };

    const popupNewPTypeCol = ref<{
      isProcessing: boolean;
      show: boolean;
      newColName: string;
      removeFieldError: any;
      errMessage: string;
      showRemoveCntDown: any;
      delayCountDown: any;
      cancelRemovePType: any;
    }>({
      isProcessing: false,
      show: false,
      newColName: "",
      removeFieldError: (name: string) => {
        popupNewPTypeCol.value.errMessage = "";
      },
      errMessage: "",
      showRemoveCntDown: (item: any) => {
        item.showRemoveCntDown = true;
        item.cntDown = 3;
        popupNewPTypeCol.value.delayCountDown(item);
      },
      delayCountDown: (item: any) => {
        item.removeTimer = setTimeout(async () => {
          item.cntDown--;
          if (item.cntDown > 0) {
            popupNewPTypeCol.value.delayCountDown(item);
          } else {
            await removePTypes(item);
            if (props.items) {
              for (const ptype of props.items) {
                const aci: any = context.refs[`aci-${ptype.uuid}`];
                if (typeof aci[0] != "undefined") {
                  aci[0].key = ptype.serviceName;
                  aci[0].$forceUpdate();
                }
              }
            }
            // add more row?
            if (props.items && props.items.length == 0) {
              addPTypeRow();
            } else {
              const lastRow = props.items
                ? props.items[props.items.length - 1]
                : undefined;
              if (lastRow && lastRow.serviceName != "") {
                addPTypeRow();
              }
            }
          }
        }, 1000);
      },
      cancelRemovePType: (item: any) => {
        clearTimeout(item.removeTimer);
        item.showRemoveCntDown = false;
        item.cntDown = 3;
      }
    });

    const onSuggestTags = async (key: string, selectedItem: any) => {
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/tags/search",
        {},
        {
          // typeUse: "finance",
          key: key,
          notInIds: notInIds
        }
      );
      if (result?.status === 1) {
        selectedItem.suggestTags = result.data.tags.map((item: any) => {
          return {
            id: item.id,
            text: item.text,
            data: item
          };
        });
      }
    };

    const onAddTag = (selectedItem: any) => {
      const key = selectedItem.key.trim();
      if (key) {
        selectedItem.value.push({
          id: 0,
          text: key
        });
        selectedItem.key = "";
      }
    };

    const onRemoveLastTag = (selectedItem: any) => {
      if (selectedItem.value.length > 0 && selectedItem.key === "") {
        const index = selectedItem.value.length - 1;
        selectedItem.value.splice(index, 1);
      }
    };
    const onRemoveTag = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectTag = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        text: item.text,
        data: item.data || {}
      });
    };

    // posting codes
    const onSuggestPostingCodes = async (key: string, selectedItem: any) => {
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/finances/postingCodes",
        {},
        {
          key,
          notInIds
        }
      );
      if (result.status === 1) {
        selectedItem.suggestTags = result.data.items
          .filter((item: any) => item.postingCodeDesc != "")
          .map((item: any) => {
            return {
              id: item.postingCode,
              // text: `${item.postingCode} / ${item.glCode}`,
              text: item.postingCodeDesc,
              data: item
            };
          });
      }
    };

    const onRemoveLastPostingCode = (selectedItem: any) => {
      // if (selectedItem.value.length > 0 && selectedItem.key === "") {
      //   const index = selectedItem.value.length - 1;
      //   selectedItem.value.splice(index, 1);
      // }
    };
    const onRemovePostingCodes = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectPostingCode = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        // text: item.text
        text: `${item.data.postingCode} / ${item.data.glCode}`
      });
    };

    const showNewPostingCodeModal = (
      postingCode: string,
      selectedAddon: any,
      index: number
    ) => {
      if (postingCode == "") return;

      const postingCodeRef: any = context.refs.postingCodeRef;

      // auto selecting if existed in suggested items
      const existed = selectedAddon.postingCodes.suggestTags.find(
        (item: any) => `${item.id}`.toLowerCase() == postingCode.toLowerCase()
      );
      if (existed) {
        // auto select
        selectedAddon.postingCodes.value.push({
          id: existed.id,
          text: existed.text
        });
        selectedAddon.postingCodes.key = "";
        if (postingCodeRef[index]) {
          postingCodeRef[index].show = false;
        }

        return;
      }
      componentData.value.controls.postingCode.value = postingCode;
      newPostingCode.value.modalVisible = true;
      glCodes.value.error = "";
      glCodes.value.value = [];
      newPostingCode.value.postingCode = postingCode;
      newPostingCode.value.currentAddOn = selectedAddon;
      if (postingCodeRef[index]) {
        postingCodeRef[index].show = false;
      }
    };

    const onSuggestGLCode = async () => {
      const notInIds = glCodes.value.value
        .map((item: any) => item.id)
        .join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/glCodes",
        {},
        {
          gl: glCodes.value.key || "",
          notInIds
        }
      );
      if (result.status === 1) {
        glCodes.value.suggestTags = result.data.items.map((code: string) => {
          return {
            id: code,
            text: code
          };
        });
      }
    };

    const onSelectGLCode = (selectedValue: any) => {
      glCodes.value.error = "";
      glCodes.value.key = selectedValue.id;
      glCodes.value.suggestTags = [];
    };

    const savePostingCode = async () => {
      // const postingCode = newPostingCode.value.postingCode || "";
      const postingCode = componentData.value.controls.postingCode.value;
      const glCode = glCodes.value.key || "";
      const addonServiceId = newPostingCode.value.currentAddOn.uuid || 0;
      // reset
      glCodes.value.error = "";

      // validate
      let valid = true;
      // if (postingCode == "") return;
      if (glCode == "") {
        valid = false;
        glCodes.value.error = "GL Code is required!";
      }
      if (componentData.value.controls.postingCode.value == "") {
        valid = false;
        componentData.value.controls.postingCode.error =
          "Posting Code is required!";
      }
      if (componentData.value.controls.postingCodeDesc.value == "") {
        valid = false;
        componentData.value.controls.postingCodeDesc.error =
          "Posting Code Name is required!";
      }
      const glName = componentData.value.controls.glName.value;
      if (glName == "") {
        valid = false;
        componentData.value.controls.postingCodeDesc.error =
          "GL Name is required!";
      }

      if (valid) {
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/postingcodes/0`,
          {
            code: postingCode,
            glCode,
            glName,
            desc: componentData.value.controls.postingCodeDesc.value
          },
          {}
        );
        if (result.status == 1) {
          // update posting codes for current addon
          const currentAddOn = (props.items || []).find(
            item => item.uuid == addonServiceId
          );
          if (currentAddOn) {
            currentAddOn.postingCodes.value.push({
              id: postingCode,
              text: `${postingCode} / ${glCode}`
            });
            currentAddOn.postingCodes.key = "";
          }
          newPostingCode.value.postingCode = "";
          componentData.value.controls.postingCodeDesc.value = "";
          glCodes.value.key = "";
          glCodes.value.value = [];
          glCodes.value.suggestTags = [];
          newPostingCode.value.modalVisible = false;
          newPostingCode.value.currentAddOn = {};
        } else {
          ApiHelper.showErrorMessage(
            result.message || `Can't create new Posting Code`
          );
        }
      }
    };

    // cost centers
    const onSuggestCostCenters = async (key: string, selectedItem: any) => {
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/costcenters",
        {},
        {
          getAll: 1,
          order: 1,
          direction: 1,
          key,
          notInIds
        }
      );
      isACILoading.value = false;
      if (result.status === 1) {
        selectedItem.suggestTags = result.data.items.map((item: any) => {
          return {
            id: item.costCenterNumber,
            text: item.costCenterName || item.costCenterNumber,
            data: item
          };
        });
      }
    };

    const onRemoveLastCostCenter = (selectedItem: any) => {
      if (selectedItem.value.length > 0 && selectedItem.key === "") {
        const index = selectedItem.value.length - 1;
        selectedItem.value.splice(index, 1);
      }
    };
    const onRemoveCostCenter = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectCostCenter = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value = [];
      selectedItem.value.push({
        id: item.id,
        text: item.id
      });
      // context.emit("onSelectCostCenter", item);
    };

    const onAddNewCostCenter = async (
      key: string,
      selectedItem: any,
      index: number
    ) => {
      if (key.trim() == "" || isNaN(key as any)) return;

      const costCenterRef: any = context.refs.costCenterRef;

      // auto selecting if cost center number existed in suggested items
      const existed = selectedItem.suggestTags.find(
        (item: any) => `${item.id}`.toLowerCase() == key.toLowerCase()
      );
      if (existed) {
        // auto select
        selectedItem.value.push({
          id: existed.id,
          text: existed.text
        });
        selectedItem.key = "";
        if (costCenterRef[index]) {
          costCenterRef[index].show = false;
        }
        context.emit("onSelectCostCenter", existed);

        return;
      }

      // add new cost center number
      const result = await ApiHelper.callApi(
        "put",
        `/accounting/costcenters/0`,
        {
          number: key
        },
        {}
      );
      if (result.status == 1) {
        // update cost centers for current addon
        selectedItem.value.push({
          id: key,
          text: key
        });
        selectedItem.key = "";
        if (costCenterRef[index]) {
          costCenterRef[index].show = false;
        }
        context.emit("onSelectCostCenter", {
          id: key,
          text: key
        });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        return;
      }
    };

    const onFocusCoscenter = (index: number, item: any) => {
      const eventCoscenterNumber: any = props.eventCoscenterNumber;
      if (
        eventCoscenterNumber.length &&
        item.value.length == 0 &&
        item.key == "" &&
        item.firstFocus
      ) {
        item.firstFocus = false;
        // auto fill coscenter number
        // item.key = eventCoscenterNumber[0].id;
        // auto select
        item.value = [
          {
            id: eventCoscenterNumber[0].id,
            text: eventCoscenterNumber[0].id
          }
        ];
      }
    };

    // init data
    (async () => {
      if (props.eventId == 0) {
        addPTypeRow();
      }
    })();

    const isAllowAddNewCostCenter = (item: any) => {
      const regExp = /[a-zA-Z]/g;

      if (
        (item.suggestTags || []).length == 0 &&
        item.key != "" &&
        !regExp.test(item.key)
      ) {
        return true;
      }

      return false;
    };

    return {
      onSuggestTags,
      onAddTag,
      onRemoveLastTag,
      onRemoveTag,
      onSelectTag,
      stripTags: ApiHelper.stripTags,
      popupNewPTypeCol,
      selectPType,
      foundPTypes,
      suggestPTypes,
      isACILoading,
      onSuggestPostingCodes,
      onRemoveLastPostingCode,
      onRemovePostingCodes,
      onSelectPostingCode,
      showNewPostingCodeModal,
      newPostingCode,
      glCodes,
      onSuggestGLCode,
      onSelectGLCode,
      savePostingCode,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onRemoveCostCenter,
      onSelectCostCenter,
      onAddNewCostCenter,
      componentData,
      onFocusCoscenter,
      isAllowAddNewCostCenter
    };
  },
  methods: {
    hoverEnter(event: any) {
      if (event.clientY > 550) {
        this.componentData.allowTop = true;
      } else {
        this.componentData.allowTop = false;
      }
    }
  }
});
