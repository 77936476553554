








































































































































import { defineComponent, ref } from "@vue/composition-api";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";

import HeaderActions from "@/components/Common/HeaderActions.vue";
import BackButton from "@/components/Common/BackButton.vue";
import { ApiHelper } from "@/helpers";
import NavEventDetails from "@/components/Event/NavEventDetails.vue";
import DropdownBox from "@/components/Common/DropdownBox.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";

export default defineComponent({
  name: "HeaderEventDetailsComponent",
  props: {
    eventId: String,
    title: String,
    filters: Object,
    noPadding: {
      type: Boolean,
      defaultValue: false
    }
  },
  components: {
    HeaderTabs,
    HeaderActions,
    BackButton,
    NavEventDetails,
    DropdownBox,
    AutoCompleteInput
  },
  setup(props, context) {
    const pageTitle = ref<string>("");
    const loadData = () => {
      (async () => {
        try {
          const result = await ApiHelper.callApi(
            "get",
            "/events/" + props.eventId + "/info",
            {},
            {}
          );
          if (result.status === 1) {
            pageTitle.value = result.data.name || "";
          }
        } catch (err) {
          pageTitle.value = "";
        }
      })();
    };
    loadData();

    const sidebarData = ref<{
      show: boolean;
      isArchived: boolean;
      isLoading: boolean;
      foundEvents: any;
      showAci: boolean;
    }>({
      show: false,
      isArchived: false,
      isLoading: true,
      foundEvents: [],
      showAci: true
    });

    const isACILoading = ref<boolean>(false);
    const suggestEvents = async (key: string) => {
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/events/search",
        {},
        {
          order: 1,
          direction: 1,
          key: key,
          ignoreIds: props.eventId
        }
      );
      if (result.status === 1) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sidebarData.value.foundEvents = result.data.events.map((item: any) => ({
          id: item.id,
          text: item.name,
          data: item
        }));
      }
      isACILoading.value = false;
    };

    const selectEvent = (item: any) => {
      console.log(item);
      if (item.data) {
        const eventId = item?.data.id || 0;
        // goto profile details page
        ApiHelper.gotoPage(context, {
          name: context.root.$route.name,
          params: { eventId: eventId.toString() }
        });
      }
    };

    const isShowFilters = ref<boolean>(false);
    const hideFilters = () => {
      isShowFilters.value = false;
    };
    const toggleFilters = () => {
      if (props.filters && props.filters.list.length > 0) {
        isShowFilters.value = !isShowFilters.value;
      } else {
        isShowFilters.value = false;
      }
    };
    return {
      isShowFilters,
      hideFilters,
      toggleFilters,
      pageTitle,
      sidebarData,
      isACILoading,
      suggestEvents,
      selectEvent
    };
  }
});
