



























































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import directives from "@/helpers/directives";
import Vue from "vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import Modal from "@/components/Common/Modal.vue";
import FormButton from "@/components/Form/FormButton.vue";
import { FormText, SelectOption } from "@/types";
import Select2 from "v-select2-component/src/Select2.vue";
import FormTags from "@/components/Form/FormTags.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import $ from "jquery";

export default defineComponent({
  name: "EventPtypes",
  props: {
    eventId: Number,
    data: Object,
    eventDiscounts: Object,
    disabled: {
      type: Boolean,
      defaultValue: false
    },
    showCostCenter: {
      type: Boolean,
      defaultValue: false
    },
    showGlCodes: {
      type: Boolean,
      defaultValue: false
    },
    eventCoscenterNumber: {
      type: Array,
      default: []
    },
    isAllowAddNewCostCenter: Function,
    isAllowAddNewPostingCode: Function
  },
  directives: directives,
  components: {
    FormInput,
    AutoCompleteInput,
    Modal,
    FormButton,
    Select2,
    FormTags,
    FormTextarea,
    FormNumber
  },
  setup(props, context) {
    const isACILoading = ref<boolean>(false);
    const componentData = ref<{
      items: any[];
      allowAdd: boolean;
      allowTop: boolean;
      totalDataApplyTop: number;
      allowTopCostCenter: boolean;
      totalDataApplyTopCostCenter: number;
      allowTopPostCode: boolean;
      totalDataApplyTopPostCode: number;
      firstCost: boolean;
      isLoading: boolean;
      editingIndex: number;
      controls: {
        postingCode: FormText;
        postingCodeDesc: FormText;
        costCenterNumber: FormText;
        costCenterName: FormText;
        glName: FormText;
      };
      itemEdit: {
        item: any;
        index: number;
      };
    }>({
      items: [],
      allowAdd: true,
      allowTop: false,
      totalDataApplyTop: 0,
      allowTopCostCenter: false,
      totalDataApplyTopCostCenter: 0,
      allowTopPostCode: false,
      totalDataApplyTopPostCode: 0,
      firstCost: true,
      isLoading: false,
      editingIndex: -1,
      controls: {
        postingCode: {
          required: true,
          label: "Posting Code",
          placeholder: "Posting Code",
          style: "custom",
          value: "",
          error: "",
          maxlength: 30
        },
        postingCodeDesc: {
          required: true,
          // label: "Description",
          label: "Posting Code Name",
          // placeholder: "Description",
          placeholder: "Posting Code Name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 255
        },
        costCenterNumber: {
          required: true,
          label: "Number",
          placeholder: "Number",
          style: "custom",
          value: "",
          error: "",
          maxlength: 100
        },
        costCenterName: {
          required: false,
          label: "Cost center name",
          placeholder: "Cost center name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 150
        },
        glName: {
          required: true,
          label: "GL Name",
          placeholder: "GL Name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 100
        }
      },
      itemEdit: {
        item: [],
        index: -1
      }
    });
    const applicationOptions = ref<SelectOption[]>([]);
    const ageOptions = ref<SelectOption[]>([]);
    const amountTypeInput = ref("amount");
    const foundPTypes = ref<any[]>([]);
    const cfTypes = ref<any[]>([]);
    const newPostingCode = ref<{
      modalVisible: boolean;
      postingCode: string;
      currentAddOn: any;
    }>({
      modalVisible: false,
      postingCode: "",
      currentAddOn: {}
    });

    const newCostCenter = ref<{
      modalVisible: boolean;
      isProcessing: boolean;
      item: any;
      index: number;
    }>({
      modalVisible: false,
      isProcessing: false,
      item: {},
      index: 0
    });

    const glCodes = ref<any>({
      required: true,
      error: "",
      label: "GL Code",
      placeholder: "Select or input a GL Code",
      type: "tags",
      key: "",
      value: [],
      options: [],
      suggestTags: []
    });

    const popupNewPTypeCol = ref<{
      isProcessing: boolean;
      show: boolean;
      newColName: string;
      newColNameError: string;
      removeFieldError: any;
      errMessage: string;
      currentCFList: any;
      selectedCF: any;
      showModal: any;
      onSubmit: any;
      updateSelectedCF: any;
    }>({
      isProcessing: false,
      show: false,
      newColName: "",
      newColNameError: "",
      removeFieldError: (name: string) => {
        popupNewPTypeCol.value.errMessage = "";
      },
      errMessage: "",
      currentCFList: [],
      selectedCF: [],
      showModal: () => {
        popupNewPTypeCol.value.newColName = "";
        popupNewPTypeCol.value.show = true;
      },
      onSubmit: async () => {
        // validate
        let hasError = false;
        if (popupNewPTypeCol.value.newColName == "") {
          hasError = true;
        }

        if (!hasError && !popupNewPTypeCol.value.isProcessing) {
          popupNewPTypeCol.value.isProcessing = true;
          // check existed
          const tmp =
            componentData.value.items.length > 0
              ? componentData.value.items[0].customFields
              : [];
          const existedCF = tmp.find(
            (item: any) =>
              item.title.toLowerCase() ==
              popupNewPTypeCol.value.newColName.toLowerCase()
          );
          if (!tmp.length || !existedCF) {
            // persist a custom field
            const result = await ApiHelper.callApi(
              "post",
              "/events/ptypeCustomField",
              {
                name: popupNewPTypeCol.value.newColName,
                type: 1
              }
            );
            if (result.status !== 1) {
              popupNewPTypeCol.value.show = false;
              popupNewPTypeCol.value.isProcessing = false;
              ApiHelper.showErrorMessage(result.message, "Oops");
              return;
            }
            // add new column
            for (const item of componentData.value.items) {
              const cf = [...item.customFields];
              cf?.push({
                id: result.data.id,
                title: popupNewPTypeCol.value.newColName,
                error: "",
                value: "",
                show: true
              });
              item.customFields = cf;
            }

            // update current custom fields list
            const existed = popupNewPTypeCol.value.currentCFList.find(
              (item: any) =>
                item.cfName.toLowerCase() ==
                popupNewPTypeCol.value.newColName.toLowerCase()
            );
            if (!existed) {
              popupNewPTypeCol.value.currentCFList.push({
                cfId: result.data.id,
                cfName: popupNewPTypeCol.value.newColName,
                show: true
              });
              popupNewPTypeCol.value.selectedCF.push(result.data.id);
            }
            popupNewPTypeCol.value.show = false;
            popupNewPTypeCol.value.isProcessing = false;
          } else {
            popupNewPTypeCol.value.isProcessing = false;
            popupNewPTypeCol.value.newColNameError =
              "Column Name already exists.";
          }
        }
      },
      updateSelectedCF: () => {
        const selectedTitles = popupNewPTypeCol.value.currentCFList
          .filter((item: any) =>
            popupNewPTypeCol.value.selectedCF.includes(item.cfId)
          )
          .map((t: any) => t.cfName.toLowerCase());
        for (const item of popupNewPTypeCol.value.currentCFList) {
          item.show = popupNewPTypeCol.value.selectedCF.includes(item.cfId)
            ? true
            : false;
        }

        for (const item of componentData.value.items) {
          item.customFields = item.customFields.map((cf: any) => ({
            ...cf,
            show: popupNewPTypeCol.value.selectedCF.includes(cf.id)
              ? true
              : false
          }));
        }
      }
    });

    const getPTypesHtml = (item: any) => {
      let ret = `${item.name}`;
      if (item.cost) {
        const moreInfo = [];
        if (item.cost) {
          moreInfo.push(`Cost: ${ApiHelper.dollarFormat(item.cost)}`);
        }
        ret = `${item.name} - (${moreInfo.join(", ")})`;
      } else {
        ret = `${item.name}`;
      }

      return ret;
    };

    const loadData = async () => {
      const uncheckedCFs = popupNewPTypeCol.value.currentCFList
        .filter((item: any) => !item.show)
        .map((item: any) => item.cfId);

      componentData.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "get",
        "/events/" + props.eventId + "/participant-types",
        {},
        {}
      );
      componentData.value.isLoading = false;
      if (result.status === 1) {
        componentData.value.allowAdd = true;
        componentData.value.editingIndex = -1;
        cfTypes.value = (result.data.cfTypes || []).map((item: any) => {
          return {
            ...item,
            show: uncheckedCFs.includes(item.cfId) ? false : true
          };
        });
        // current participant type custom fields are using
        popupNewPTypeCol.value.currentCFList = cfTypes.value;

        const ptypes = (result.data.ptypes || []).map(
          (item: any, index: number) => {
            const cf: any[] = [];
            const cfIds = item.cfIds ? item.cfIds.split("---") : [];
            const cfNames = item.cfNames ? item.cfNames.split("---") : [];
            const cfValues = item.cfValues ? item.cfValues.split("---") : [];
            if (cfIds.length && cfNames.length && cfValues.length) {
              for (const i in cfIds) {
                cf.push({
                  id: cfIds[i],
                  title: cfNames[i],
                  value: cfValues[i]
                });
              }
            }
            return { ...item, cf: cf };
          }
        );

        popupNewPTypeCol.value.currentCFList.map((cfType: any) => {
          let isFound = false;
          componentData.value.items = ptypes.map((item2: any) => {
            const foundValue = item2.cf.find((cfItem: any) => {
              return cfItem.id + "" === cfType.cfId + "";
            });
            if (foundValue) {
              isFound = true;
            }
          });
          cfType.show = isFound;
        });

        componentData.value.items = ptypes.map((item: any, index: number) => {
          const customFields: any[] = [];
          popupNewPTypeCol.value.currentCFList.map((cfType: any) => {
            const foundValue = item.cf.find((cfItem: any) => {
              return cfItem.id + "" === cfType.cfId + "";
            });
            customFields.push({
              id: cfType.cfId,
              title: cfType.cfName,
              value: foundValue ? foundValue.value : "",
              error: "",
              show: cfType.show
            });
          });
          const costCenters = item.costCenterNumber
            ? [item.costCenterNumber]
            : [];
          return {
            id: item.id,
            participantTypeId: item.participantTypeId,
            name: {
              value: item.participantTypeName || "",
              error: ""
            },
            capacity: {
              value: item.capacity,
              error: ""
            },
            capacityMale: {
              value: item.capacityMale,
              error: ""
            },
            capacityFemale: {
              value: item.capacityFemale,
              error: ""
            },
            cost: {
              value: (item.cost || 0).toFixed(2),
              error: ""
            },
            age: {
              value: item.age || "",
              error: ""
            },
            ageFrom: {
              value: parseInt(item.age.split("-")[0] || 0),
              error: ""
            },
            ageTo: {
              value: parseInt(item.age.split("-")[1] || 0),
              error: ""
            },
            applications: {
              value: item.applications || "",
              error: ""
            },
            customFields: customFields,
            linkId: item.linkId || 0,
            totalParticipants: item.totalParticipants || 0,
            sortOrder: item.sortOrder || index + 1,
            isNew: false,
            isEditing: false,
            postingCodes: {
              error: "",
              label: "",
              textOverflow: true,
              placeholder: "Posting / GL Code",
              type: "tags",
              key: "",
              value: item.postingCode
                ? [
                    {
                      id: item.postingCode,
                      text: `${item.postingCode} / ${item.glCode}`
                    }
                  ]
                : [],
              options: [],
              suggestTags: []
            },
            costCenters: {
              error: "",
              label: "",
              textOverflow: true,
              placeholder: "Cost Center Number",
              type: "tags",
              key: "",
              firstFocus: true,
              value: costCenters.map((code: any) => {
                return {
                  id: code,
                  text: code
                };
              }),
              options: [],
              suggestTags: []
            }
          };
        });
        popupNewPTypeCol.value.selectedCF = popupNewPTypeCol.value.currentCFList
          .filter((item: any) => item.show)
          .map((item: any) => item.cfId);

        context.emit("updatePtypesList", ptypes);
      }
    };

    const addNewRow = () => {
      componentData.value.items.map((value: any) => {
        value.isEditing = false;
      });
      componentData.value.allowAdd = false;
      const customFields: any[] = [];
      popupNewPTypeCol.value.currentCFList.map((cfType: any) => {
        customFields.push({
          id: cfType.cfId,
          title: cfType.cfName,
          value: "",
          error: "",
          show: cfType.show
        });
      });
      componentData.value.items.push({
        id: 0,
        participantTypeId: 0,
        name: {
          value: "",
          error: ""
        },
        capacity: {
          value: "",
          error: ""
        },
        capacityMale: {
          value: "",
          error: ""
        },
        capacityFemale: {
          value: "",
          error: ""
        },
        cost: {
          value: "",
          error: ""
        },
        age: {
          value: "",
          error: ""
        },
        ageFrom: {
          value: 0,
          error: ""
        },
        ageTo: {
          value: 0,
          error: ""
        },
        applications: {
          value: "",
          error: ""
        },
        customFields: customFields,
        linkId: 0,
        totalParticipants: 0,
        sortOrder: componentData.value.items.length + 1,
        isNew: true,
        isEditing: true,
        postingCodes: {
          error: "",
          label: "",
          textOverflow: true,
          placeholder: "Posting Code",
          type: "tags",
          key: "",
          value: [],
          options: [],
          suggestTags: []
        },
        costCenters: {
          error: "",
          label: "",
          textOverflow: true,
          firstFocus: true,
          placeholder: "Cost Center Number",
          type: "tags",
          key: "",
          value: [],
          options: [],
          suggestTags: []
        }
      });
      componentData.value.editingIndex = componentData.value.items.length - 1;
    };

    const doCancelItem = (index: number, item: any) => {
      componentData.value.editingIndex = -1;
      componentData.value.allowAdd = true;
      item.isEditing = false;
      loadData();
    };

    const doSaveItem = async (
      index: number,
      item: any,
      forceUseNewCost = 0
    ) => {
      let hasError = false;
      if (
        item.name.value === "" ||
        parseInt(item.ageTo.value) < parseInt(item.ageFrom.value)
      ) {
        if (item.name.value === "") {
          item.name.error = "Participant type name is required";
        } else {
          item.ageTo.error = "Range age is wrong format";
        }

        hasError = true;
      } else {
        componentData.value.items.map((service: any, key: number) => {
          if (key !== index && service.name.value === item.name.value) {
            item.name.error =
              "Participant Type Name already exists. Please enter new information.";
            ApiHelper.showErrorMessage(
              "Participant Type Name already exists. Please enter new information.",
              "Oops"
            );
            hasError = true;
          }
        });
      }
      const capacity =
        item.capacity.value == ""
          ? null
          : Number.parseInt(item.capacity.value, 10) || 0;
      const capacityMale =
        item.capacityMale.value == ""
          ? null
          : Number.parseInt(item.capacityMale.value, 10) || 0;
      const capacityFemale =
        item.capacityFemale.value == ""
          ? null
          : Number.parseInt(item.capacityFemale.value, 10) || 0;
      const cost = Number.parseFloat(item.cost.value) || 0;
      // if (cost === 0) {
      //   item.cost.error = "Participant cost must greater than zero!";
      //   hasError = true;
      // }

      if (hasError) {
        return;
      }

      const linkOrders = componentData.value.items.map((value: any) => {
        return {
          linkId: value.linkId,
          sortOrder: value.sortOrder
        };
      });

      const customFields = item.customFields || [];

      // auto select cost center
      if (
        item.costCenters.value.length == 0 &&
        item.costCenters.key != "" &&
        item.costCenters.suggestTags.length &&
        item.firstFocus
      ) {
        const related = item.costCenters.suggestTags.find(
          (t: any) => t.id.toLowerCase() == item.costCenters.key.toLowerCase()
        );
        if (related) {
          item.costCenters.value = [
            {
              id: related.id,
              text: related.text
            }
          ];
        }
      }

      componentData.value.isLoading = true;
      const ptypeId = item.id > 0 ? item.id : 0;
      const result = await ApiHelper.callApi(
        "put",
        "/events/" + props.eventId + "/participant-types/" + ptypeId,
        {
          name: item.name.value,
          capacity: capacity,
          capacityMale: capacityMale,
          capacityFemale: capacityFemale,
          cost: cost,
          age: item.ageFrom.value + "-" + item.ageTo.value,
          customFields: customFields,
          applications: Number.parseInt(item.applications.value, 10) || 0,
          sortOrder: item.sortOrder,
          linkOrders: linkOrders,
          forceUseNewCost,
          postingCode: item.postingCodes.value.map((t: any) => t.id).join(","),
          costCenterNumber: item.costCenters.value
            .map((t: any) => t.id)
            .join(",")
        },
        {}
      );
      componentData.value.isLoading = false;
      if (result.status !== 1) {
        const needConfirm = (result.data || {}).needConfirm || 0;
        const total = (result.data || {}).totalIncompleteParticipants || 0;
        if (needConfirm) {
          const isConfirmed = await Vue.swal({
            html: `The ptype has <strong>${total}</strong> incomplete participants. Are you sure you want to update the new cost?`,
            showCancelButton: true,
            confirmButtonText: "Yes, do it!",
            showCloseButton: true,
            closeButtonHtml:
              '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
          }).then(result => {
            setTimeout(function() {
              $(".swal2-backdrop-hide").addClass("d-none");
            }, 200);
            return result.isConfirmed;
          });
          if (!isConfirmed) {
            return;
          }
          await doSaveItem(index, item, 1);
        } else {
          ApiHelper.showErrorMessage(result.message, "Oops");
        }
        return;
      }
      componentData.value.allowAdd = true;
      item.isEditing = false;
      componentData.value.editingIndex = -1;
      ApiHelper.showSuccessMessage(ptypeId ? "Updated" : "Added");
      loadData();
    };

    const doDeleteItem = async (index: number, item: any) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (item.totalParticipants > 0 || !allowDeletePtype(item)) {
        console.log(item.name);
        if (item.totalParticipants > 0) {
          ApiHelper.showErrorMessage(
            `"Participant type ${item.name.value}" has participants and cannot be deleted.`,
            "Oops"
          );
        } else {
          ApiHelper.showErrorMessage(
            `"Participant type ${item.name.value}" has discounts associated with it. Please remove the associated discounts to proceed.`,
            "Oops"
          );
        }
      } else {
        const isAgreed = await Vue.swal({
          html: "Are you sure you want to delete this participant type?",
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          return result.isConfirmed;
        });

        if (isAgreed) {
          if (item.id == 0) {
            return;
          }
          componentData.value.isLoading = true;
          const result = await ApiHelper.callApi(
            "delete",
            "/events/" + props.eventId + "/participant-types/" + item.id,
            {},
            {}
          );
          componentData.value.isLoading = false;
          if (result.status !== 1) {
            ApiHelper.showErrorMessage(result.message, "Oops");
            return;
          }
          componentData.value.editingIndex = -1;
          componentData.value.allowAdd = true;
          ApiHelper.showSuccessMessage("Deleted");
          loadData();
        } else {
          componentData.value.editingIndex = -1;
          componentData.value.allowAdd = true;
        }
      }
    };

    const doEditItem = (index: number, item: any) => {
      componentData.value.items.map((value: any) => {
        value.isEditing = false;
      });
      componentData.value.editingIndex = index;
      componentData.value.allowAdd = false;
      item.isEditing = true;
    };

    const amountTypeSwitch = () => {
      amountTypeInput.value =
        amountTypeInput.value == "amount" ? "rate" : "amount";
    };

    const getSelectPtypeCost = (ptypeId: number) => {
      let cost = 0;
      if (props.data && props.data.ptypes) {
        const findPtype = props.data.ptypes.find((item: any) => {
          return item.id === ptypeId;
        });
        if (findPtype) {
          cost = findPtype.cost || 0;
        }
      }
      return cost;
    };

    const changeDiscountAmount = (item: any) => {
      const ptypeCost = getSelectPtypeCost(parseInt(item.ptype.value) || 0);
      item.discountAmount.error = "";
      item.discountPercent.error = "";
      const discountAmount = parseFloat(item.discountAmount.value) || 0;

      if (discountAmount > ptypeCost) {
        item.discountAmount.error =
          "Discount amount must less than " + ApiHelper.dollarFormat(ptypeCost);
      }
      if (ptypeCost > 0) {
        item.discountPercent.value = (
          (discountAmount / ptypeCost) *
          100
        ).toFixed(2);
      }
    };

    const changeDiscountPercent = (item: any) => {
      const ptypeCost = getSelectPtypeCost(parseInt(item.ptype.value) || 0);
      item.discountAmount.error = "";
      item.discountPercent.error = "";
      let discountPercent = parseFloat(item.discountPercent.value) || 0;
      if (discountPercent > 100) {
        discountPercent = 100;
        item.discountPercent.value = discountPercent;
      }
      const discountAmount = ptypeCost * (discountPercent / 100);
      item.discountAmount.value = discountAmount.toFixed(2);
      if (discountAmount > ptypeCost) {
        item.discountAmount.error =
          "Discount amount must less than " + ApiHelper.dollarFormat(ptypeCost);
      }
    };

    const suggestPTypes = async (key: string, item: any, index: number) => {
      // reset
      foundPTypes.value = [];
      item.name.value = key;

      if (key !== "") {
        // searching
        item.id = -1;
        const selectedPTypeNames = componentData.value.items
          ? componentData.value.items
              .filter(
                option =>
                  option.name.value && option.name.value != "" && option.id > 0
              )
              .map(option => option.name.value)
          : [];
        isACILoading.value = true;
        const result = await ApiHelper.callApi(
          "get",
          "/participants/ptypes/search",
          {},
          {
            key: key,
            take: 25,
            selectedNames: selectedPTypeNames.length
              ? JSON.stringify(selectedPTypeNames)
              : ""
          }
        );

        if (result.status === 1) {
          foundPTypes.value = result.data.ptypes.map((item: any) => ({
            id: item.id,
            text: item.name,
            html: getPTypesHtml(item),
            data: item
          }));
        }
        isACILoading.value = false;
      }
    };

    const selectPType = (data: any, item: any) => {
      if (data) {
        item.id = data.id;
        item.name.value = data.name;
        item.capacity.value = data.capacity == null ? "" : data.capacity;
        item.capacityMale.value =
          data.capacityMale == null ? "" : data.capacityMale;
        item.capacityFemale.value =
          data.capacityFemale == null ? "" : data.capacityFemale;
        item.cost.value = data.cost;
        item.age.value = data.typeages || data.participant_typeages;
        item.ageFrom.value = parseInt(item.age.value.split("-")[0] || 0);
        item.ageTo.value = parseInt(item.age.value.split("-")[1] || 0);
        item.applications.value = data.applications;

        const postingCode = data.postingCode || "";
        const glCode = data.glCode || "";
        const costCenterNumber = data.costCenterNumber || "";
        item.postingCodes.value = [];
        if (postingCode != "" && glCode != "") {
          item.postingCodes.value = [
            {
              id: postingCode,
              text: `${postingCode} / ${glCode}`
            }
          ];
        }
        item.costCenters.value = [];
        if (costCenterNumber != "") {
          item.costCenters.value = [
            {
              id: costCenterNumber,
              text: costCenterNumber
            }
          ];
        }
        componentData.value.itemEdit.item = item;
      }
    };

    // auto fill for capacity
    const capacityInput = (column: string, item: any) => {
      try {
        const capacity = parseInt(item.capacity.value);
        const capacityMale = parseInt(item.capacityMale.value);
        const capacityFemale = parseInt(item.capacityFemale.value);
        if (capacityMale >= 0 && capacityFemale >= 0) {
          if (column == "capacityMale" || column == "capacityFemale") {
            // item.capacityMale.value = capacityMale || "";
            // item.capacityFemale.value = capacityFemale || "";
            item.capacity.value = capacityFemale + capacityMale;
          }
          if (column == "capacity") {
            // item.capacity.value = capacity || "";
            item.capacityMale.value = "";
            item.capacityFemale.value = "";
          }
        } else {
          if (column == "capacityMale") {
            if (capacityMale >= capacity) {
              item.capacityFemale.value = "0";
              item.capacity.value = capacityMale;
            }
            // item.capacityMale.value = capacityMale || "";
            // if (capacity === 0) {
            //   item.capacity.value = capacityFemale + capacityMale || "";
            // } else if (capacity < capacityMale + capacityFemale) {
            //   item.capacity.value = capacityMale + capacityFemale || "";
            // }
          }
          if (column == "capacityFemale") {
            if (capacityFemale >= capacity) {
              item.capacityMale.value = "0";
              item.capacity.value = capacityFemale;
            }
            // item.capacityFemale.value = capacityFemale || "";
          }
          if (column == "capacity") {
            // item.capacity.value = capacity || "";
            // item.capacityMale.value = "";
            // item.capacityFemale.value = "";
          }
        }
        context.root.$forceUpdate();
      } catch (error) {
        console.log(error);
      }
    };

    const allowDeletePtype = (item: any) => {
      if (props.eventDiscounts && props.eventDiscounts.componentData) {
        const foundEditingDiscount = props.eventDiscounts.componentData.items.find(
          (discount: any) => {
            return discount.isEditing;
          }
        );
        // if a discount item is editing
        if (foundEditingDiscount) {
          return false;
        }
        const foundDiscount = props.eventDiscounts.componentData.items.find(
          (discount: any) => {
            return discount.ptype.value == item.id;
          }
        );
        // if found a discount that is using ptype
        if (foundDiscount) {
          return false;
        }
      }
      return true;
    };

    // posting codes
    const onSuggestPostingCodes = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        componentData.value.totalDataApplyTopPostCode = 0;
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/finances/postingCodes",
        {},
        {
          key,
          notInIds
        }
      );
      if (result.status === 1) {
        componentData.value.totalDataApplyTopPostCode =
          result.data.items.length;
        selectedItem.suggestTags = result.data.items
          .filter((item: any) => item.postingCodeDesc != "")
          .map((item: any) => {
            return {
              id: item.postingCode,
              // text: `${item.postingCode} / ${item.glCode}`,
              text: item.postingCodeDesc,
              data: item
            };
          });
      }
    };

    const onRemoveLastPostingCode = (selectedItem: any) => {
      // if (selectedItem.value.length > 0 && selectedItem.key === "") {
      //   const index = selectedItem.value.length - 1;
      //   selectedItem.value.splice(index, 1);
      // }
    };
    const onRemovePostingCodes = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectPostingCode = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        // text: item.text
        text: `${item.data.postingCode} / ${item.data.glCode}`
      });
    };

    const showNewPostingCodeModal = (
      postingCode: string,
      selectedAddon: any,
      index: number
    ) => {
      if (postingCode == "") return;

      const postingCodeRef: any = context.refs.postingCodeRef;

      // auto selecting if existed in suggested items
      const existed = selectedAddon.postingCodes.suggestTags.find(
        (item: any) => `${item.id}`.toLowerCase() == postingCode.toLowerCase()
      );
      if (existed) {
        // auto select
        selectedAddon.postingCodes.value.push({
          id: existed.id,
          text: existed.text
        });
        selectedAddon.postingCodes.key = "";
        if (postingCodeRef[index]) {
          postingCodeRef[index].show = false;
        }

        return;
      }
      componentData.value.controls.postingCode.value = postingCode;
      newPostingCode.value.modalVisible = true;
      newPostingCode.value.postingCode = postingCode;
      newPostingCode.value.currentAddOn = selectedAddon;
      if (postingCodeRef[index]) {
        postingCodeRef[index].show = false;
      }
    };

    const onSuggestGLCode = async () => {
      const notInIds = glCodes.value.value
        .map((item: any) => item.id)
        .join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/glCodes",
        {},
        {
          gl: glCodes.value.key || "",
          notInIds
        }
      );
      if (result.status === 1) {
        glCodes.value.suggestTags = result.data.items.map((code: string) => {
          return {
            id: code,
            text: code
          };
        });
      }
    };

    const onSelectGLCode = (selectedValue: any) => {
      glCodes.value.error = "";
      glCodes.value.key = selectedValue.id;
      glCodes.value.suggestTags = [];
    };

    const savePostingCode = async () => {
      // const postingCode = newPostingCode.value.postingCode || "";
      const postingCode = componentData.value.controls.postingCode.value;
      const glCode = glCodes.value.key || "";
      const addonServiceId = newPostingCode.value.currentAddOn.id || 0;
      // reset
      glCodes.value.error = "";

      // validate
      let valid = true;
      // if (postingCode == "") return;
      if (glCode == "") {
        valid = false;
        glCodes.value.error = "GL Code is required!";
      }
      if (componentData.value.controls.postingCode.value == "") {
        valid = false;
        componentData.value.controls.postingCode.error =
          "Posting Code is required!";
      }
      if (componentData.value.controls.postingCodeDesc.value == "") {
        valid = false;
        componentData.value.controls.postingCodeDesc.error =
          "Posting Code Name is required!";
      }
      const glName = componentData.value.controls.glName.value;
      if (glName == "") {
        valid = false;
        componentData.value.controls.glName.error = "GL Name is required!";
      }

      if (valid) {
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/postingcodes/0`,
          {
            code: postingCode,
            glCode,
            glName,
            desc: componentData.value.controls.postingCodeDesc.value
          },
          {}
        );
        if (result.status == 1) {
          // update posting codes for current addon
          const currentAddOn = componentData.value.items.find(
            item => item.id == addonServiceId
          );
          if (currentAddOn) {
            currentAddOn.postingCodes.value.push({
              id: postingCode,
              text: `${postingCode} / ${glCode}`
            });
            currentAddOn.postingCodes.key = "";
          }
          newPostingCode.value.postingCode = "";
          componentData.value.controls.postingCodeDesc.value = "";
          glCodes.value.key = "";
          glCodes.value.value = [];
          glCodes.value.suggestTags = [];
          newPostingCode.value.modalVisible = false;
          newPostingCode.value.currentAddOn = {};
        } else {
          ApiHelper.showErrorMessage(
            result.message || `Can't create new Posting Code`
          );
        }
      }
    };

    // cost centers
    const onSuggestCostCenters = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        componentData.value.totalDataApplyTopCostCenter = 0;
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/costcenters",
        {},
        {
          getAll: 1,
          order: 1,
          direction: 1,
          key,
          notInIds
        }
      );
      isACILoading.value = false;
      if (result.status === 1) {
        componentData.value.totalDataApplyTopCostCenter =
          result.data.items.length;
        selectedItem.suggestTags = result.data.items.map((item: any) => {
          return {
            id: item.costCenterNumber,
            text: item.costCenterName || item.costCenterNumber,
            data: item
          };
        });
      }
    };

    const onRemoveLastCostCenter = (selectedItem: any) => {
      if (selectedItem.value.length > 0 && selectedItem.key === "") {
        const index = selectedItem.value.length - 1;
        selectedItem.value.splice(index, 1);
      }
    };
    const onRemoveCostCenter = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectCostCenter = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value = [];
      selectedItem.value.push({
        id: item.id,
        text: item.id
      });
      // context.emit("onSelectCostCenter", item);
    };

    const resetFormAddCostCenter = () => {
      newCostCenter.value.modalVisible = false;
      newCostCenter.value.isProcessing = false;
      componentData.value.controls.costCenterNumber.value = "";
      componentData.value.controls.costCenterName.value = "";
      componentData.value.controls.costCenterNumber.error = "";
    };

    const onAddNewCostCenter = async (
      key: string,
      selectedItem: any,
      index: number
    ) => {
      // Call from popup add new cost
      if (
        componentData.value.controls.costCenterNumber.value === "" &&
        key.trim() == ""
      ) {
        componentData.value.controls.costCenterNumber.error =
          "Number is required";
        newCostCenter.value.isProcessing = false;
        return;
      }
      if (key.trim() == "") return;

      if (!newCostCenter.value.isProcessing) {
        newCostCenter.value.modalVisible = true;
        newCostCenter.value.item = selectedItem;
        newCostCenter.value.index = index;
        // Handle name or number
        if (!isNaN(key as any)) {
          componentData.value.controls.costCenterNumber.value = key;
        } else {
          componentData.value.controls.costCenterName.value = key;
        }
      } else {
        // Add new cost center
        const costCenterRef: any = context.refs.costCenterRef;
        // auto selecting if cost center number existed in suggested items
        const existed = selectedItem.suggestTags.find(
          (item: any) => `${item.id}`.toLowerCase() == key.toLowerCase()
        );
        if (existed) {
          // auto select
          selectedItem.value.push({
            id: existed.id,
            text: existed.text
          });
          selectedItem.key = "";
          if (costCenterRef[index]) {
            costCenterRef[index].show = false;
          }
          context.emit("onSelectCostCenter", existed);

          return;
        }
        // call api add new cost center number
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/costcenters/0`,
          {
            number: componentData.value.controls.costCenterNumber.value,
            name: componentData.value.controls.costCenterName.value
          },
          {}
        );

        if (result.status == 1) {
          // Reset data after call api success
          resetFormAddCostCenter();
          // update cost centers for current addon
          selectedItem.value.push({
            id: key,
            text: key
          });
          selectedItem.key = "";
          if (costCenterRef[index]) {
            costCenterRef[index].show = false;
          }
          context.emit("onSelectCostCenter", {
            id: key,
            text: key
          });
        } else {
          // Reset button submit
          newCostCenter.value.isProcessing = false;
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
      }
    };

    const resetFirtfocus = () => {
      componentData.value.firstCost = true;
    };

    const onFocusCoscenter = (index: number, item: any) => {
      const eventCoscenterNumber: any = props.eventCoscenterNumber;
      if (
        eventCoscenterNumber.length &&
        item.value.length == 0 &&
        item.key == "" &&
        componentData.value.firstCost
      ) {
        componentData.value.firstCost = false;
        // auto fill coscenter number
        // item.key = eventCoscenterNumber[0].id;
        // auto select
        item.value = [
          {
            id: eventCoscenterNumber[0].id,
            text: eventCoscenterNumber[0].id
          }
        ];
      }
    };

    const checkRangeAge = (item: any) => {
      item.error = "";
      item.ageTo.error = "";
      if (parseInt(item.ageFrom.value) >= parseInt(item.ageTo.value)) {
        item.ageTo.error = "Oop";
      }
    };

    (async () => {
      applicationOptions.value = await ApiHelper.getEntityApplicationOptions();
      ageOptions.value = [];
      for (let age = 1; age < 100; age++) {
        ageOptions.value.push({ id: age, text: "" + age });
      }
    })();

    return {
      allowDeletePtype,
      capacityInput,
      applicationOptions,
      ageOptions,
      popupNewPTypeCol,
      cfTypes,
      foundPTypes,
      suggestPTypes,
      selectPType,
      changeDiscountAmount,
      changeDiscountPercent,
      loadData,
      addNewRow,
      doCancelItem,
      doSaveItem,
      doDeleteItem,
      doEditItem,
      componentData,
      stripTags: ApiHelper.stripTags,
      amountTypeSwitch,
      amountTypeInput,
      isACILoading,
      onSuggestPostingCodes,
      onRemoveLastPostingCode,
      onRemovePostingCodes,
      onSelectPostingCode,
      showNewPostingCodeModal,
      newPostingCode,
      newCostCenter,
      glCodes,
      onSuggestGLCode,
      onSelectGLCode,
      savePostingCode,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onRemoveCostCenter,
      onSelectCostCenter,
      onAddNewCostCenter,
      onFocusCoscenter,
      ApiHelper: ApiHelper,
      resetFirtfocus,
      resetFormAddCostCenter,
      checkRangeAge
    };
  },
  async mounted() {
    // init data
    await this.loadData();
  },
  methods: {
    hoverEnter(event: any) {
      if (event.clientY < 457 && this.componentData.totalDataApplyTop > 3) {
        this.componentData.allowTop = true;
        // Popup down
      } else {
        this.componentData.allowTop = false;
      }
    },
    hoverEnterCostCenter(event: any) {
      if (
        event.clientY > 570 &&
        this.componentData.totalDataApplyTopCostCenter > 3
      ) {
        this.componentData.allowTopCostCenter = true;
      } else {
        this.componentData.allowTopCostCenter = false;
      }
    },
    hoverEnterPostCode(event: any) {
      if (
        event.clientY > 570 &&
        this.componentData.totalDataApplyTopPostCode > 3
      ) {
        this.componentData.allowTopPostCode = true;
      } else {
        this.componentData.allowTopPostCode = false;
      }
    },
    checkAllowAdd() {
      this.$emit("button-added");
    },
    checkAllowEdit(index: number, item: any) {
      this.componentData.itemEdit.index = index;
      this.componentData.itemEdit.item = item;
      this.$emit("button-edited");
    },
    allowEdit() {
      this.doEditItem(
        this.componentData.itemEdit.index,
        this.componentData.itemEdit.item
      );
      // this.componentData.itemEdit.index = -1;
      // this.componentData.itemEdit.item = [];
    },
    checkAllowClose(index: number, item: any) {
      this.$emit("button-deleted");
      this.doCancelItem(index, item);
    },
    checkDoSaveItem(index: number, item: any) {
      this.$emit("button-deleted");
      this.doSaveItem(index, item);
    }
  }
});
