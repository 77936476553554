



























































import { defineComponent, ref } from "@vue/composition-api";
import $ from "jquery";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";

type option = {
  id: number;
  text: string;
  data: any;
};
export default defineComponent({
  name: "AutoCompleteInput",
  components: {
    VScroller
  },
  props: {
    tags: {
      type: Array
    },
    data: Object,
    placeholder: String,
    removeTag: Function,
    addTag: Function,
    options: Array,
    inputClass: String,
    updateLastInput: Function,
    emptySearchOnSelect: {
      type: Boolean,
      default: false
    },
    allowAddNew: {
      type: Boolean,
      default: false
    }
  },
  /*mounted() {
    // allow arrow up/down function
    const el = this.$el;
    $(el)
        .find(".tag-box")
        .off("keydown")
        .keydown(function (e) {
          if (e.which == 40 || e.which == 38) {
            e.preventDefault();
          }
          const results = $(el).find(".results-box");
          if (results.find("li").length) {
            const current = results.find("li.current");
            if (e.which == 40) {
              if (!current.length || !current.next().length) {
                current.removeClass("current");
                results.find("li:first-child").addClass("current");
              } else {
                current
                    .removeClass("current")
                    .next()
                    .addClass("current");
              }
            } else if (e.which == 38) {
              if (!current.length || !current.prev().length) {
                current.removeClass("current");
                results.find("li:last-child").addClass("current");
              } else {
                current
                    .removeClass("current")
                    .prev()
                    .addClass("current");
              }
            }
          }
        });
  },*/
  setup(props, context) {
    const key = ref<string>("");
    const timer = ref<any>(null);
    const show = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const hint = ref<string>("");

    const onInput = () => {
      if (props.updateLastInput) {
        props.updateLastInput(key.value);
      }

      if (timer.value) {
        clearTimeout(timer.value);
      }
      if (key.value == "") {
        show.value = false;
        hint.value = "";
      } else {
        timer.value = setTimeout(async () => {
          if (key.value.length < 1) {
            hint.value = "";
            // hint.value = "Please type at least 3 characters to search";
          } else {
            hint.value = "";
            loading.value = true;
            await context.emit("input", key.value);
            loading.value = false;
          }
          show.value = true;
        }, 500);
      }
    };

    const onSelect = (item: any) => {
      if (props.emptySearchOnSelect) {
        key.value = "";
        $(context.root.$el)
          .find(".tag-box")
          .focus();
      } else {
        key.value = item.text;
      }
      show.value = false;
      context.emit("select", item);
    };

    const onClickOutside = () => {
      show.value = false;
      hint.value = "";
    };

    const selectLineItem = () => {
      const current = $(context.root.$el).find(".results-box li.current");
      if (current.length) {
        const item = props.options?.find(
          (item: any) => item.id == current.data("id")
        );
        if (item) {
          onSelect(item);
        }
      }
    };

    // init data
    (async () => {
      key.value = "";
      show.value = false;
    })();

    const removeLastTag = (event: any) => {
      if (event.target.value.length === 0 && props.tags && props.removeTag) {
        props.removeTag(props.tags.length - 1);
      }
    };

    const doAddTag = (event: any) => {
      console.log("doAddTag", event);
      if (key.value.length < 3) {
        console.log("aaaaaaaa", key.value);
      } else {
        if (props.addTag) {
          props.addTag(key.value);
          key.value = "";
        }
      }
      event.preventDefault();
    };

    return {
      doAddTag,
      removeLastTag,
      onInput,
      key,
      onClickOutside,
      show,
      onSelect,
      loading,
      hint,
      selectLineItem
    };
  }
});
