




































































































































































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Header from "@/components/Common/Header.vue";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import Pager from "@/components/Common/Pager.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import { useRevenueSpendCategoryStore } from "@/stores/Financial/Accounting/RevenueSpendCategory/RevenueSpendCategoryStore";

type PageData = {
  isLoading: boolean;
};

export default defineComponent({
  name: "RevenueSpendCategory",
  components: {
    Pager,
    Header,
    HeaderTabs,
    FilterSearchColumn,
    TableHeaderColumn,
    Loading
  },
  setup(props, context) {
    const {
      updateRouters,
      updateItemStatus,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue
    } = useRevenueSpendCategoryStore(context);
    return {
      updateRouters,
      updateItemStatus,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      ApiHelper: ApiHelper
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
