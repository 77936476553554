var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head__tabs tab__3"},_vm._l((_vm.tabs),function(item){return _c('router-link',{key:item.label,attrs:{"to":item.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"tab__item d-flex align-items-center",class:{
        active: isExactActive || isActive
      },attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(item.label)+" ")]),(item.img)?_c('img',{staticStyle:{"margin-left":"-10px","margin-top":"9px"},attrs:{"src":require("@/assets/images/dashboard-arrow-down.svg"),"alt":"Icon"},on:{"click":_vm.toggleCustomize}}):_vm._e()]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }