
















































































































































































































































































































































































































































































































import { defineComponent } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import HeaderEvents from "@/components/Event/HeaderEvents.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import { useEventsStore } from "@/stores/Event/EventsStore";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import DropdownParticipantStatus from "@/components/Participant/DropdownParticipantStatus.vue";
import NoData from "@/components/Common/NoData.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import EventSync from "@/components/Common/EventSync.vue";

export default defineComponent({
  name: "Events",
  components: {
    NoData,
    VAlertMessage,
    HeaderEvents,
    FilterDateColumn,
    FilterSelectColumn,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterAgesColumn,
    FilterNumberRangeColumn,
    DropdownParticipantStatus,
    Pager,
    Loading,
    TableHeaderColumn,
    EventSync
  },
  setup(props, context) {
    const {
      errors,
      pageData,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      getFiltersData,
      updateSortValue,
      // functions
      updateEventStatus,
      toggleSync
    } = useEventsStore(context);

    const getPercent = (percent: number) => {
      return (percent || 0) > 100 ? 100 : percent;
    };

    return {
      errors,
      pageData,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      getFiltersData,
      // sort
      updateSortValue,
      updateEventStatus,
      getPercent,
      toggleSync
    };
  }
});
