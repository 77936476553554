import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import {
  AlertMessageError,
  PagerItem,
  SelectOption,
  FormSelect
} from "@/types";
import { EventListRows } from "@/helpers/estimateNoOfListRows";

export function useVposOrderCreateStore(context: any) {
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    sort: {
      order: string;
      direction: string;
    };
    items: {
      id: number;
      Name: string;
      Category?: { id: number; name: string };
      Sku?: string;
      Price?: number;
      Inventory?: number;
      Sales: number;
      Status: number;
      isChecked: false;
    }[];
    activeTab: string;
    arrStatuses: SelectOption[];
    filter: {
      status: string;
    };
    selectedIds: any;
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      total: number;
      totalCount: number;
      totalPages: number;
      items: PagerItem[];
    };
  }>({
    isLoading: true,
    skip: 0,
    take: EventListRows(),
    activeTab: "",
    arrStatuses: [],
    filter: {
      status: "Active"
    },
    // sort defaut by event start date
    sort: {
      order: "4",
      direction: "2"
    },
    items: [],
    showHeadActions: false,
    selectedIds: [],
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalCount: 0,
      totalPages: 1,
      items: []
    }
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      user: FormSelect;
      productError: string;
    };
  }>({
    isLoading: false,
    controls: {
      user: {
        label: "Store User",
        required: true,
        placeholder: "Select Store User",
        error: "",
        value: "",
        style: "custom",
        options: []
      },
      productError: ""
    }
  });

  const errors = ref<AlertMessageError[]>([]);
  const loadData = async (page: number, routeReplace = true) => {
    pageData.value.isLoading = true;
    pageData.value.pager.page = page;
    pageData.value.skip = (page - 1) * pageData.value.take;

    try {
      const requestObj = {
        skip: pageData.value.skip || 0,
        take: pageData.value.take || 8,
        order: parseInt(pageData.value.sort.order) || 1,
        direction: parseInt(pageData.value.sort.direction) || 1,
        callFrom: "storeproduct_page"
      };

      // update route path
      if (routeReplace) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateRouteQuery(page, requestObj);
      }
      const result = await ApiHelper.callApi(
        "get",
        "/store/products",
        {},
        requestObj
      );

      pageData.value.isLoading = false;

      if (result.status === 1) {
        const totalCount = result.totalCount || 0;

        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        pageData.value.pager.totalCount = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.map((item: any) => {
          return {
            ...item,
            priceFormatted: ApiHelper.dollarFormat(item.Price)
          };
        });
      } else if (result.status === 401) {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "Login" });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    } catch (err) {
      console.log("loadData:err", err);
      pageData.value.isLoading = false;
      errors.value = [err];
    }
  };

  const updateRouteQuery = (page: number, requestObj: any) => {
    const queryObject: any = { page, ...requestObj };
    delete queryObject.skip;
    delete queryObject.take;
    for (const i in queryObject) {
      if (queryObject[i] == "") {
        delete queryObject[i];
      }
    }
    context.root.$router
      .replace({
        query: queryObject
      })
      .catch(() => {
        // nothing
      });
  };

  const gotoPage = (page: string) => {
    loadData(parseInt(page));
  };

  const onPagerChange = (event: any) => {
    loadData(parseInt(event.target.value));
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      loadData(pageData.value.pager.page - 1);
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      loadData(pageData.value.pager.page + 1);
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const toggleHeadActions = () => {
    pageData.value.showHeadActions = !pageData.value.showHeadActions;
  };

  const hideHeadActions = () => {
    pageData.value.showHeadActions = false;
  };

  // const getCheckedItems = () => {
  //   return pageData.value.items.filter(item => item.isChecked);
  // };

  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "1":
        pageData.value.sort.order = "1";
        break;
      case "2":
        pageData.value.sort.order = "2";
        break;
      case "3":
        pageData.value.sort.order = "3";
        break;
      case "4":
        pageData.value.sort.order = "4";
        break;
    }
    loadData(1);
  };

  const getFiltersData = () => {
    const filters = [];
    const selectedStatuses = pageData.value.arrStatuses
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedStatuses.length > 0) {
      filters.push({
        label: "STATUS",
        key: selectedStatuses.join("-") + Math.random(),
        value: selectedStatuses.join(", "),
        reset: () => {
          pageData.value.arrStatuses.map((item: any) => {
            item.selected = false;
          });
          loadData(1);
        }
      });
    }
    return {
      list: filters
    };
  };

  // init data
  (async () => {
    // set init value if set on url
    const queryObject = context.root.$route.query;
    const currentPage = parseInt(`${queryObject.page || 1}`);
    pageData.value.filter.status = queryObject.key || "";
    if (queryObject.order) {
      pageData.value.sort.order = queryObject.order;
    }
    if (queryObject.direction) {
      pageData.value.sort.direction = queryObject.direction;
    }
    formData.value.controls.user.options = await ApiHelper.getUserOptions();
    loadData(currentPage, false);
  })();

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    loadData(1);
  };

  const inSelectedIds = (item: any) => {
    const existedItem = pageData.value.selectedIds.find(
      (id: any) => id == item.transactionId
    );
    if (existedItem) {
      return true;
    }
    return false;
  };

  const allowRefund = (item: any) => {
    return true;
  };
  const onDeleteProduct = async (id: number) => {
    await ApiHelper.callApi("delete", `/store/products`, { id });
    const queryObject = context.root.$route.query;
    const currentPage = parseInt(`${queryObject.page || 1}`);
    loadData(currentPage, false);
  };

  const onSubmit = async () => {
    pageData.value.isLoading = true;
    let totalProduct = 0;
    let hasError = false;
    pageData.value.items.forEach((item, index) => {
      if (item.isChecked) {
        totalProduct += 1;
      }
    });
    if (formData.value.controls.user.value == "") {
      formData.value.controls.user.error = "Please select store user";
      hasError = true;
    }
    if (totalProduct <= 0) {
      formData.value.controls.productError = "please select product";
      hasError = true;
    }
    if (hasError) {
      pageData.value.isLoading = false;
    } else {
      const dataInput = {
        storeUserId: formData.value.controls.user.value,
        accountid: 1,
        orderItems: pageData.value.items.map(item => {
          return {
            productId: item.id,
            quantity: 1,
            price: item.Price
          };
        })
      };
      const result = await ApiHelper.callApi(
        "post",
        "/store/orders",
        dataInput
      );
      if (result.status === 1) {
        ApiHelper.showSuccessMessage(result.message, "Store Order");
        ApiHelper.gotoPage(context, "/pos/storeorders");
      } else {
        ApiHelper.showErrorMessage("Oop! Can not create Order");
      }
    }
    formData.value.isLoading = false;
  };

  return {
    errors,
    pageData,
    formData,
    // pager
    gotoPage,
    // getCheckedItems,
    togglePagerItems,
    toggleHeadActions,
    hideHeadActions,
    onClickPrev,
    onClickNext,
    onPagerChange,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    getFiltersData,
    // sort
    updateSortValue,
    inSelectedIds,
    allowRefund,
    onDeleteProduct,
    onSubmit
  };
}
