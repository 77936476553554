


































































































import { defineComponent, ref } from "@vue/composition-api";
import $ from "jquery";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "RoommateRequest",
  components: {
    VScroller
  },
  props: {
    //
  },
  setup(props, context) {
    const app = ref<any>({});
    const inviteData = ref<any>({});
    const roommate = ref<any>([]);

    return {
      app,
      inviteData,
      roommate
    };
  }
});
