





































































































































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import Avatar from "vue-avatar/src/Avatar.vue";

import VScroller from "@/components/Common/VScroller.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import { ApiHelper } from "@/helpers";
import { ParticipantStatus } from "@/helpers/ApiHelper";

export default defineComponent({
  name: "SidebarProfileEvents",
  props: {
    showSidebar: {
      type: Boolean,
      defaultValue: true
    },
    profileId: String,
    participantId: String,
    eventId: String,
    data: Object
  },
  components: {
    VScroller,
    HeaderTabs,
    Avatar,
    AutoCompleteInput
  },
  setup(props, context) {
    const sidebarData = ref({
      isLoading: true,
      logo: "",
      name: "",
      profileId: 0,
      participantId: 0
    });
    const onClickOutside = () => {
      if (!props.participantId) {
        context.emit("dismiss");
      }
    };
    const loadData = async () => {
      if (props.data) {
        sidebarData.value.logo = props.data.logo || "";
        sidebarData.value.name = props.data.name || "";
        sidebarData.value.profileId = props.data.profileId || 0;
        sidebarData.value.participantId = props.data.participantId || 0;
        sidebarData.value.isLoading = props.data.isLoading || false;
        if (!sidebarData.value.participantId) {
          if (props.participantId) {
            const result = await ApiHelper.callApi(
              "get",
              "/participants/" + props.participantId,
              {},
              {}
            );
            if (result.status === 1) {
              sidebarData.value.name = result.data.eventName;
              sidebarData.value.profileId = result.data.profileId || 0;
              sidebarData.value.participantId = result.data.participantId || 0;
              sidebarData.value.isLoading = false;
            }
          }
        }
      }
    };

    const showTransfer = computed(() => {
      const totalPaid =
        props.data?.financials?.totalPaid || props.data?.totalPaid || 0;
      return props.data?.status == ParticipantStatus.Denied && totalPaid > 0;
    });

    const transfer = () => {
      // prepare participantInfo data for case user clicks "transfer" menu item at right sidebar on a denied participant at participants list
      const participantInfo = {
        participantId: props.data?.participantId || 0,
        profileId: props.data?.profileId || 0,
        paidAmount: props.data?.totalPaid || 0,
        paidAmountFormatted: props.data?.totalPaidFormatted || "",
        fullName: "",
        eventId: props.eventId || 0,
        eventName: props.data?.name || "",
        pTypeName: props.data?.participantTypeName || ""
      };
      context.emit("transfer", participantInfo);
    };

    (async () => {
      await loadData();
    })();

    return {
      onClickOutside,
      sidebarData,
      showTransfer,
      transfer
    };
  }
});
