var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SidebarEventDetailsComponent"},[(!_vm.sidebarData.isLoading)?_c('div',{staticClass:"profileDetails sidebar-profiles-details",attrs:{"id":"menu-participants"}},[_c('div',{staticClass:"menu-box"},[_c('div',{staticClass:"box_head"},[_c('div',{staticClass:"box__icon"},[(_vm.sidebarData.logo != '')?_c('img',{attrs:{"src":_vm.sidebarData.logo,"alt":"Icon"}}):_c('img',{attrs:{"src":require("../../assets/images/participants.png"),"alt":"Icon"}})]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"box__name",staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"textOverflow profile-fullname"},[_vm._v(_vm._s(_vm.sidebarData.name || "Event Details"))]),_c('span',{staticClass:"down-arrow",on:{"click":_vm.toggleAci}}),_c('AutoCompleteInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.sidebarData.showAci),expression:"sidebarData.showAci"}],staticClass:"sidebar-profiles-aci",attrs:{"placeholder":'Type an event name...',"options":_vm.sidebarData.foundEvents,"nowrap":true,"showLineTitle":true,"isACILoading":_vm.isACILoading || false},on:{"input":function (key) { return _vm.suggestEvents(key); },"select":function (item) { return _vm.selectEvent(item); }}})],1)]),_c('VScroller',{staticClass:"box_content is-event-details",style:({
          height: ("calc(100vh - 300px - " + (_vm.pTypeStats.length *
            34) + "px) !important")
        })},[_c('ul',{attrs:{"slot":"default"},slot:"default"},[_c('router-link',{attrs:{"to":{
              name: 'EventDashboard',
              params: {
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#dashboard","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Dashboard")]),_c('div',{staticClass:"item__text"},[_c('strong',[_vm._v(_vm._s(_vm.totalDaysLeft))]),_c('span',[_vm._v(" days left")])])])])]}}],null,false,3971732878)}),_c('router-link',{attrs:{"to":{
              name: 'EventDetails',
              params: {
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[(_vm.sidebarData.hasApplications)?_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#participants","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Participants")]),_c('div',{staticClass:"item__text"},[_c('div',{staticClass:"item__progress"},[_c('div',{staticClass:"progress__bar"},[_c('div',{staticClass:"progress__detail"},[_c('span',{staticStyle:{"width":"69%"},attrs:{"data-width":"69%"}})])])])])]):_c('a',{staticClass:"box__item",attrs:{"href":"javascript:void(0)","title":""},on:{"click":_vm.gotoDashboardPage}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#participants","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Participants")]),_c('div',{staticClass:"item__text"},[_c('div',{staticClass:"item__progress"},[_c('div',{staticClass:"progress__bar"},[_c('div',{staticClass:"progress__detail"},[_c('span',{staticStyle:{"width":"69%"},attrs:{"data-width":"69%"}})])])])])])])]}}],null,false,3369944570)}),_c('router-link',{attrs:{"to":{
              name: 'EventSchedule',
              params: {
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#schedule","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Schedule")])])])]}}],null,false,2735662012)}),_c('router-link',{attrs:{"to":{
              name: 'EventCabins',
              params: {
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#cabins","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v(" Cabins ")]),_c('div',{staticClass:"title__number"},[_vm._v(_vm._s(_vm.sidebarData.totalCabins))])])])]}}],null,false,738354782)}),_c('router-link',{attrs:{"to":{
              name: 'EventTransportation',
              params: {
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#transportation","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Transportation")])])])]}}],null,false,302212412)}),_c('router-link',{attrs:{"to":{
              name: 'EventMedical',
              params: {
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#medical","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Medical")]),_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalMedicals)+" ")])])])]}}],null,false,2906602418)}),(_vm.$allowedFunctions.includes(_vm.$userFunctions.Transactions))?_c('router-link',{attrs:{"to":{
              name: 'EventFinancial',
              params: {
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#financial","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Financial")])])])]}}],null,false,3378802108)}):_vm._e(),_c('router-link',{attrs:{"to":{
              name: 'EventSettings',
              params: {
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#settings","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Settings")])])])]}}],null,false,254904764)})],1)]),_c('div',{staticClass:"box_footer"},[_c('div',{staticClass:"box__items"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item__name"},[_vm._v(" Financials ")]),_c('div',{staticClass:"item__total"},[_c('span',{staticClass:"item__green"},[_vm._v(_vm._s(_vm.eventIncome))]),_c('span',{staticClass:"item__arrow"},[_vm._v("/")]),_c('span',{staticClass:"item__red"},[_vm._v(_vm._s(_vm.eventTotal))])])]),_vm._l((_vm.pTypeStats),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item__name textOverflow",staticStyle:{"max-width":"100px"},attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"item__people"},[_c('div',{staticClass:"people__total"},[_vm._v(" "+_vm._s(item.total)+" ")]),_c('div',{staticClass:"people__boy"},[_vm._v(_vm._s(item.capacityMale))]),_c('div',{staticClass:"people__girl"},[_vm._v(_vm._s(item.capacityFemale))]),_c('div',{staticClass:"people__other"},[_vm._v(_vm._s(item.capacityOther))])])])})],2)])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }