var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datepicker3"},[_c('div',{staticClass:"group",class:{ hasError: _vm.data.error !== '', isRequired: _vm.data.required }},[_c('label',{staticClass:"label__full margin-top-10",class:{
            'text-danger': _vm.data.error },attrs:{"for":'datepicker-' + _vm.randomId}},[_vm._v(_vm._s(_vm.data.label))]),_c('div',{staticClass:"group__input",class:{
            'text-danger': _vm.data.error
          }},[_c('DatePicker',{ref:"datepicker",staticClass:"form-control form-control-full",class:{
            'text-danger': _vm.data.error
          },attrs:{"id":'datepicker-' + _vm.randomId,"format":_vm.data.format || 'MM/DD/YYYY',"placeholder":_vm.data.placeholder || 'MM/DD/YYYY',"use12h":true,"disabled":_vm.data.disabled || _vm.disabled,"not-before":_vm.data.disabledBefore,"not-after":_vm.data.disabledAfter,"disabledDate":_vm.disabledDate,"clearable":false,"popup-style":{
          left: 0
        },"append-to-body":false,"type":_vm.data.type || 'date'},on:{"change":function () {
            _vm.data.error = '';
            _vm.doChange();
          },"focus":function () {
            // data.error = '';
          },"reset":function () {
            _vm.data.error = '';
            _vm.doChange();
          }},model:{value:(_vm.data.value),callback:function ($$v) {_vm.$set(_vm.data, "value", $$v)},expression:"data.value"}}),_c('span')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }