


















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Error",
  components: {},
  props: {
    message: {
      type: String,
      default: ""
    },
    redirectName: {
      type: String,
      default: ""
    },
    redirectQuery: {
      type: Object,
      default: {}
    }
  }
});
