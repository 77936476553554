



















































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Header from "@/components/Common/Header.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import Modal from "@/components/Common/Modal.vue";
import FormPhoto from "@/components/Form/FormPhoto.vue";
import { useProfileRoommatesStore } from "@/stores/Profile/ProfileRoommatesStore";
import Avatar from "vue-avatar/src/Avatar.vue";
import SidebarProfileEvents from "@/components/Profile/SidebarProfileEvents.vue";
import TransferModal from "@/components/Financial/TransferModal.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import VScroller from "@/components/Common/VScroller.vue";
// import ConfirmModal from "@/components/Common/ConfirmModal.vue";
import { ParticipantStatus } from "@/helpers/ApiHelper";
import FormItem from "@/components/Form/FormItem.vue";
import FormButton from "@/components/Form/FormButton.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import NavProfileEvents from "@/components/Profile/NavProfileEvents.vue";

export default defineComponent({
  name: "ProfileRoommates",
  components: {
    Header,
    Loading,
    Pager,
    TableHeaderColumn,
    Modal,
    FormPhoto,
    Avatar,
    SidebarProfileEvents,
    TransferModal,
    AutoCompleteInput,
    VScroller,
    // ConfirmModal,
    FormItem,
    FormButton,
    HeaderProfileDetails,
    NavProfileEvents
  },
  setup(props, context) {
    const selectedProfileId = parseInt(context.root.$route.params.profileId);
    const {
      sidebarData,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      pairParticipantPopup,
      searchProfiles,
      getFiltersData,
      updateRoommateStatus
    } = useProfileRoommatesStore(context);

    const setIsPaying = (status: boolean) => {
      pageData.value.paying = status;
    };

    const allowPair = () => {
      const participant = pageData.value.participantInfo;
      const pStatus = participant.participantStatus || 0;
      if (pStatus == ParticipantStatus.Denied) {
        // prevent pair if this participant is denied
        return false;
      }

      return true;
    };

    const showRoommateRequestsEdit = (item: any) => {
      pageData.value.roommateStatusEditVisible = true;
      pageData.value.roommateStatusList.value = item.requestStatus;
      pageData.value.selectedRoommateRequest = item;
    };

    return {
      sidebarData,
      selectedProfileId,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      pairParticipantPopup,
      searchProfiles,
      getFiltersData,
      ApiHelper: ApiHelper,
      setIsPaying,
      allowPair,
      showRoommateRequestsEdit,
      updateRoommateStatus
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
