import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { ApiType } from "@/helpers/ApiHelper";

export function useMessagingTemplatesEditStore(context: any) {
  const userData = ApiHelper.getLoggedUser();
  const pageData = ref({
    isLoading: false
  });
  const dataInput = ref<{
    id: number;
    name: string;
    css: string;
    userEmail: string;
    html: string;
    token: string;
  }>({
    id: parseInt(context.root.$route.params.id) || 0,
    name: "",
    css: "",
    userEmail: "",
    html: "",
    token: userData.mailToken
  });
  const saveTemplate = async (data: any) => {
    dataInput.value.name = data.name;
    dataInput.value.html = data.html;
    dataInput.value.css = data.css;
    dataInput.value.userEmail = data.userEmail;
    let status = 0;
    let statusCode = "";
    let hasError = false;
    let message = "";
    if (dataInput.value.name === "") {
      hasError = true;
      message = "Template name is required!";
    }
    if (dataInput.value.html === "") {
      hasError = true;
      message = "Html is required!";
    }
    let templateId = 0;
    if (!hasError) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "put",
        "/campaigns/templates/" + dataInput.value.id,
        dataInput.value,
        {},
        ApiType.CAMPAIGN
      );
      ApiHelper.setDataLoading(false);
      if (result.status == 1) {
        status = 1;
        message = "Success";
        templateId = result.data.templateId || 0;
        if (dataInput.value.id === 0) {
          dataInput.value.id = templateId;
          ApiHelper.gotoPage(context, {
            name: "MessagingTemplatesEdit",
            params: { id: templateId + "" }
          });
        }
      } else {
        status = 0;
        statusCode = result.statusCode || "";
        message = result.message;
      }
    } else {
      status = 0;
    }
    return {
      status: status,
      statusCode: statusCode,
      message: message,
      data: {
        templateId
      }
    };
  };
  const uploadFile = async (data: any) => {
    let status;
    let hasError = false;
    let message = "";
    hasError = false;
    if (!hasError) {
      status = 1;
      message = "Success";
    } else {
      status = 0;
    }
    return {
      status: status,
      message: message,
      data: {}
    };
  };
  const sendTest = async (data: any) => {
    const mailTo = data.TO;
    const mailSubject = data.SUBJECT;
    const mailMessage = data.MESSAGE;

    let status = 0;
    let hasError = false;
    let message = "";
    if (mailTo === "") {
      hasError = true;
      message = "Email address is required!";
    }
    if (mailSubject === "") {
      hasError = true;
      message = "Subject is required!";
    }
    if (mailMessage === "") {
      hasError = true;
      message = "Message is required!";
    }

    if (!hasError) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "post",
        "/campaigns/templates/test",
        {
          to: mailTo,
          subject: mailSubject,
          message: mailMessage
        },
        {},
        ApiType.CAMPAIGN
      );
      ApiHelper.setDataLoading(false);
      if (result.status == 1) {
        status = 1;
        message = "Success";
      } else {
        status = 0;
        message = result.message;
      }
    } else {
      ApiHelper.setDataLoading(false);
      status = 0;
    }
    return {
      status: status,
      message: message,
      data: {}
    };
  };
  const loadTemplate = async (email: string) => {
    let status = 0;
    let hasError = false;
    let message = "";
    let templates = [];
    if (email === "") {
      hasError = true;
      message = "Email is required!";
    }
    if (!hasError) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/campaigns/templates",
        {},
        {
          email: email,
          ignoreIds: `${dataInput.value.id}`
        },
        ApiType.CAMPAIGN
      );
      ApiHelper.setDataLoading(false);
      if (result.status == 1) {
        templates = result.data.templates || [];
        status = 1;
      } else {
        status = 0;
        message = result.message;
      }
    } else {
      status = 0;
    }
    return {
      status: status,
      message: message,
      data: {
        templates
      }
    };
  };
  const loadData = async () => {
    if (dataInput.value.id) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/campaigns/templates/" + dataInput.value.id,
        {},
        {},
        ApiType.CAMPAIGN
      );
      if (result.status === 1) {
        const item = result.data || {};
        dataInput.value.name = item.name || "";
        dataInput.value.html = item.content || "";
        dataInput.value.css = item.css || "";
        dataInput.value.userEmail = item.email || "";
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "MessagingTemplates" });
      }
      ApiHelper.setDataLoading(false);
    }
  };

  return {
    pageData,
    dataInput,
    uploadFile,
    loadData,
    saveTemplate,
    loadTemplate,
    sendTest
  };
}
