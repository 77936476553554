






























import { defineComponent } from "@vue/composition-api";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";

export default defineComponent({
  name: "EventDetailsMasterPage",
  components: {
    SidebarEventDetails
  },
  setup(props, context) {
    return {};
  }
});
