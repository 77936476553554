import { ref } from "@vue/composition-api";
import { ApiHelper, ProfileHelper } from "@/helpers";
import { FormNumber, FormText } from "@/types";
import FormDate from "@/types/FormDate";
import FormSelect from "@/types/FormSelect";
import moment from "moment";
import { MedicalIncidentType } from "@/helpers/ApiHelper";
import FormTags from "@/types/FormTags";

export function useProfileMedicalEditStore(context: any) {
  const query = context.root.$route.query;
  const selectedProfileId = parseInt(context.root.$route.params.profileId) || 0;
  const selectedMedicalId = parseInt(context.root.$route.params.medicalId) || 0;
  const itemEditInput = ref<{
    name: string;
    type: number;
    incidenttype: number;
    notes: string;
    dosage: string;
    frequency: string;
    prescribingDoctor: string;
    timeOfDays: number | undefined;
    startDate: Date | undefined | string;
    endDate: Date | undefined | string;
    participantId: number | undefined;
    isfamilyUsed: number | undefined;
  }>({
    name: "",
    type: 0,
    incidenttype: 0,
    notes: "",
    dosage: "",
    frequency: "",
    timeOfDays: undefined,
    prescribingDoctor: "",
    startDate: undefined,
    endDate: undefined,
    participantId: undefined,
    isfamilyUsed: undefined
  });
  const formData = ref<{
    isLoading: boolean;
    isACILoading: boolean;
    controls: {
      name: FormText;
      strength: FormText;
      dispenseMethod: FormSelect;
      dosages: {
        label?: string;
        error: string;
        value: number[];
        disabled?: boolean;
        required: boolean;
        options: {
          value: number;
          title: string;
          disabled?: boolean;
        }[];
      };
      dosageOther: FormText;
      medicationStart: FormDate;
      medicationEnd: FormDate;
      note: {
        error: string;
        label: string;
        labelOn?: string;
        labelOff?: string;
        size?: string;
        value: boolean;
        required: boolean;
      };
      type: {
        value: string;
      };
      incidenttype: FormSelect;
      dosage: FormSelect;
      frequency: FormSelect;
      timeOfDays: FormNumber;
      prescribingDoctor: FormText;
      notes: FormText;
      startDate: FormDate;
      endDate: FormDate;
      medications: FormSelect;
      symptom: FormTags;
      followupDate: FormDate;
      followupNotes: FormText;
      generalNotes: FormText;
      medicalAlert: {
        error: string;
        label: string;
        value: boolean;
      };
      events: FormSelect;
      med: FormText;
      specialInstructions: FormText;
    };
    endItemMessage: string;
    followupInfoVisible: boolean;
    selectedFollowup: {
      followupDate: any;
      followupDateFormatted: string;
      followupNotes: string;
      resolved: boolean;
    };
    resolved: boolean;
    followUpLogs: any;
    changedFollowup: boolean;
    foundMedications: any;
    prescriptionName: string;
    prescription: string;
  }>({
    isLoading: true,
    isACILoading: false,
    controls: {
      name: {
        error: "",
        label: "Medication Name",
        placeholder: "Enter the Medication Name",
        type: "text",
        value: "",
        style: "custom",
        required: true
      },
      strength: {
        error: "",
        label: "Strength",
        placeholder: "Medication Strength",
        type: "text",
        value: "",
        style: "custom",
        required: false,
        maxlength: 50
      },
      dispenseMethod: {
        error: "",
        label: "Dispense Method",
        placeholder: "Select Dispense Method",
        type: "select",
        value: "",
        style: "custom",
        required: true,
        options: []
      },
      dosages: {
        label: "Dosages",
        error: "",
        value: [],
        disabled: false,
        required: true,
        options: []
      },
      dosageOther: {
        error: "",
        label: "",
        placeholder: "Other",
        type: "text",
        value: "",
        style: "custom",
        required: false,
        maxlength: 50
      },
      medicationStart: {
        error: "",
        format: "MM/DD/YYYY",
        placeholder: "MM/DD/YYYY",
        label: "Start",
        type: "date",
        value: undefined,
        style: "custom",
        required: true
      },
      medicationEnd: {
        error: "",
        format: "MM/DD/YYYY",
        placeholder: "MM/DD/YYYY",
        label: "End",
        type: "date",
        value: undefined,
        style: "custom"
      },
      note: {
        error: "",
        label: "Notes",
        labelOn: "Family",
        labelOff: "Individual",
        size: "large",
        value: false,
        required: false
      },
      type: {
        value: context.root.$route.query.type
          ? context.root.$route.query.type + ""
          : "1"
      },
      incidenttype: {
        error: "",
        label: "Incident type",
        placeholder: "Select Type",
        type: "select",
        value: "",
        style: "custom",
        required: true,
        options: ApiHelper.getIncidentTypeOptions()
      },
      dosage: {
        error: "",
        label: "Medication Route",
        placeholder: "Select Route",
        type: "select",
        value: "",
        style: "custom",
        required: true,
        options: ApiHelper.getMedicationDosageOptions()
      },
      frequency: {
        error: "",
        label: "Frequency",
        placeholder: "Select Frequency",
        type: "select",
        value: "",
        style: "custom",
        required: true,
        options: ApiHelper.getMedicationFrequencyOptions()
      },
      timeOfDays: {
        error: "",
        label: "Time Of Days",
        placeholder: "Time Of Days",
        type: "number",
        value: "",
        style: "custom"
      },
      notes: {
        error: "",
        label: "Notes",
        placeholder: "Please enter any additional instructions",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 250
      },
      prescribingDoctor: {
        error: "",
        label: "Prescribing Doctor",
        placeholder: "Prescribing Doctor",
        type: "text",
        value: "",
        style: "custom",
        required: true
      },
      startDate: {
        error: "",
        format: "MM/DD/YYYY hh:mm A",
        placeholder: "MM/DD/YYYY hh:mm",
        label: "Medical Time",
        type: "datetime",
        value: undefined,
        style: "custom"
      },
      endDate: {
        error: "",
        label: "End Date",
        type: "date",
        value: undefined,
        style: "custom"
      },
      medications: {
        error: "",
        label: "Medications",
        placeholder: "Select Medication",
        type: "select",
        value: "",
        style: "custom",
        required: true,
        options: []
      },
      symptom: {
        error: "",
        label: "Symptoms",
        placeholder: "Select or create symptoms",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: []
      },
      followupDate: {
        error: "",
        format: "MM/DD/YYYY hh:mm A",
        placeholder: "MM/DD/YYYY hh:mm",
        label: "Follow Up Date & Time",
        type: "datetime",
        value: undefined,
        style: "custom",
        required: true
      },
      followupNotes: {
        error: "",
        label: "Notes",
        placeholder: "Follow up notes",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 250,
        required: true
      },
      generalNotes: {
        error: "",
        label: "Notes",
        placeholder: "Please enter any additional instructions",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 500
      },
      medicalAlert: {
        error: "",
        label: "Medical Alert",
        value: false
      },
      events: {
        error: "",
        label: "Events",
        placeholder: "Select Event",
        type: "select",
        value: "",
        style: "custom",
        required: true,
        options: []
      },
      med: {
        error: "",
        label: "Med",
        placeholder: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 150
      },
      specialInstructions: {
        error: "",
        label: "Special Instructions",
        placeholder: "Special Instructions",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 250
      }
    },
    endItemMessage: "",
    followupInfoVisible: false,
    selectedFollowup: {
      followupDate: undefined,
      followupDateFormatted: "",
      followupNotes: "",
      resolved: false
    },
    resolved: false,
    followUpLogs: [],
    changedFollowup: false,
    foundMedications: [],
    prescriptionName: "",
    prescription: ""
  });
  const isArchived = ref(false);

  const addMedication = async () => {
    const selectedParticipantId = context.root.$route.query.participantId
      ? Number.parseInt(context.root.$route.query.participantId, 10)
      : 0;
    formData.value.controls.name.value = ApiHelper.stripTags(
      formData.value.controls.name.value
    );
    formData.value.controls.prescribingDoctor.value = ApiHelper.stripTags(
      formData.value.controls.prescribingDoctor.value
    );
    const aciMedicationsRef: any = context.refs.aciMedicationsRef;
    const key = aciMedicationsRef?.key || "";
    const medicationName = formData.value.controls.name.value.trim() || key;
    const dispenseMethod =
      parseInt(formData.value.controls.dispenseMethod.value) || 0;
    const dosages = formData.value.controls.dosages.value || [];
    const dosageOther = formData.value.controls.dosageOther.value.trim() || "";
    const start = formData.value.controls.medicationStart.value
      ? formData.value.controls.medicationStart.value
      : undefined;
    const end = formData.value.controls.medicationEnd.value
      ? formData.value.controls.medicationEnd.value
      : undefined;
    const prescribingDoctor =
      formData.value.controls.prescribingDoctor.value.trim() || "";

    // validation
    let hasError = false;
    if (medicationName == "") {
      hasError = true;
      formData.value.controls.name.error = "Medication Name is required!";
    }
    if (!dispenseMethod) {
      hasError = true;
      formData.value.controls.dispenseMethod.error =
        "Dispense Method is required!";
    }
    if (!start) {
      hasError = true;
      formData.value.controls.medicationStart.error =
        "Medication Start is required!";
    }
    if (!dosages.length) {
      hasError = true;
      formData.value.controls.dosages.error = "Dosages is required!";
    } else if (dosages.includes(6) && dosageOther == "") {
      hasError = true;
      formData.value.controls.dosageOther.error = "Dosage Other is required!";
    }
    if (
      start &&
      end &&
      moment(start).isValid() &&
      moment(end).isValid() &&
      moment(end).isBefore(moment(start))
    ) {
      hasError = true;
      formData.value.controls.medicationEnd.error =
        "Medication end date is invalid!";
    }
    if (prescribingDoctor == "") {
      hasError = true;
      formData.value.controls.prescribingDoctor.error =
        "Prescribing Doctor is required!";
    }
    if (formData.value.controls.events.value == "") {
      hasError = true;
      formData.value.controls.events.error = "Event is required!";
    }

    if (!hasError) {
      formData.value.isLoading = true;
      let result;

      const requestObject: any = {
        participantId:
          selectedParticipantId || formData.value.controls.events.value || 0,
        profileId: selectedProfileId,
        name: medicationName,
        type: 1,
        prescribingDoctor: formData.value.controls.prescribingDoctor.value.trim(),
        strength: formData.value.controls.strength.value.trim(),
        dispenseMethod,
        dosages: dosages.join(","),
        dosageOther: dosages.includes(6) ? dosageOther : "",
        medicationStart:
          typeof start == "object" && moment(start).isValid()
            ? moment(start).format("YYYY-MM-DDTHH:mm:ss") + ".000Z"
            : undefined,
        medicationEnd:
          typeof end == "object" && moment(end).isValid()
            ? moment(end).format("YYYY-MM-DDTHH:mm:ss") + ".000Z"
            : undefined,
        specialInstructions: formData.value.controls.specialInstructions.value.trim()
      };

      if (!selectedMedicalId) {
        result = await ApiHelper.callApi(
          "post",
          "/medicals/",
          requestObject,
          {}
        );
      } else {
        result = await ApiHelper.callApi(
          "put",
          "/medicals/" + selectedMedicalId,
          requestObject,
          {}
        );
      }

      formData.value.isLoading = false;
      if (result.status == 1) {
        ApiHelper.showSuccessMessage("Saved!");
        context.root.$router.go(-1);
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    }
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    if (formData.value.controls.type.value == "1") {
      addMedication();
      return;
    }

    const selectedParticipantId = context.root.$route.query.participantId
      ? Number.parseInt(context.root.$route.query.participantId, 10)
      : 0;
    formData.value.controls.name.value = ApiHelper.stripTags(
      formData.value.controls.name.value
    );
    formData.value.controls.prescribingDoctor.value = ApiHelper.stripTags(
      formData.value.controls.prescribingDoctor.value
    );
    itemEditInput.value.name = formData.value.controls.name.value.trim();
    itemEditInput.value.notes = formData.value.controls.notes.value.trim();
    itemEditInput.value.type =
      parseInt(formData.value.controls.type.value) || 0;
    itemEditInput.value.incidenttype =
      parseInt(formData.value.controls.incidenttype.value) || 0;
    itemEditInput.value.dosage = formData.value.controls.dosage.value.trim();
    itemEditInput.value.frequency = formData.value.controls.frequency.value.trim();
    itemEditInput.value.prescribingDoctor = formData.value.controls.prescribingDoctor.value.trim();
    itemEditInput.value.participantId = selectedParticipantId;
    itemEditInput.value.isfamilyUsed = formData.value.controls.note.value
      ? 1
      : 0;
    itemEditInput.value.timeOfDays = formData.value.controls.timeOfDays.value
      ? parseInt(formData.value.controls.timeOfDays.value)
      : undefined;
    itemEditInput.value.startDate = formData.value.controls.startDate.value
      ? formData.value.controls.startDate.value
      : undefined;
    if (
      typeof itemEditInput.value.startDate == "object" &&
      moment(itemEditInput.value.startDate).isValid()
    ) {
      itemEditInput.value.startDate =
        moment(itemEditInput.value.startDate).format("YYYY-MM-DDTHH:mm:ss") +
        ".000Z";
    }
    itemEditInput.value.endDate = formData.value.controls.endDate.value
      ? moment(
          formData.value.controls.endDate.value + "T23:59:59+0000"
        ).toDate()
      : undefined;

    let hasError = false;
    if (
      itemEditInput.value.name === "" &&
      formData.value.controls.type.value != "2"
    ) {
      hasError = true;
      formData.value.controls.name.error = "Medication Name is required!";
    }
    if (
      itemEditInput.value.dosage === "" &&
      formData.value.controls.type.value != "2"
    ) {
      hasError = true;
      formData.value.controls.dosage.error = "Medication Route is required!";
    }
    if (
      formData.value.controls.incidenttype.value === "" &&
      formData.value.controls.type.value === "2"
    ) {
      hasError = true;
      formData.value.controls.incidenttype.error = "Type is required!";
    }
    if (
      itemEditInput.value.frequency === "" &&
      formData.value.controls.type.value != "2"
    ) {
      hasError = true;
      formData.value.controls.frequency.error = "Frequency is required!";
    }
    if (
      itemEditInput.value.timeOfDays &&
      itemEditInput.value.timeOfDays < 0 &&
      formData.value.controls.type.value != "2"
    ) {
      hasError = true;
      formData.value.controls.timeOfDays.error = "Time Of Days is invalid!";
    }

    if (formData.value.controls.events.value == "") {
      hasError = true;
      formData.value.controls.events.error = "Event is required!";
    }

    // validate for medical incident
    if (formData.value.controls.type.value == "2") {
      if (
        itemEditInput.value.notes === "" &&
        formData.value.controls.incidenttype.value != "1"
      ) {
        hasError = true;
        formData.value.controls.notes.error = "Notes is required!";
      }

      if (formData.value.controls.incidenttype.value == "1") {
        // require a medication
        if (formData.value.controls.medications.value == "") {
          hasError = true;
          formData.value.controls.medications.error = "Medication is required!";
        } else if (
          formData.value.controls.medications.value == "-1" &&
          formData.value.controls.name.value == ""
        ) {
          // selected other options
          hasError = true;
          formData.value.controls.name.error = "Medication Name is required!";
        }
      }
    }

    if (!hasError) {
      formData.value.isLoading = true;
      let result;
      const incidentType = parseInt(formData.value.controls.incidenttype.value);
      // if (selectedMedicalId) {
      let followupDate = formData.value.selectedFollowup.followupDate;
      followupDate =
        followupDate && moment(followupDate).isValid()
          ? moment(followupDate).format("YYYY-MM-DDTHH:mm:ss") + ".000Z"
          : "";
      const requestObject: any = {
        ...itemEditInput.value,
        profileId: selectedProfileId,
        followupDate,
        followupNotes: formData.value.selectedFollowup.followupNotes,
        med: formData.value.controls.med.value || "",
        resolved: formData.value.resolved ? 1 : 0
      };

      // participantId
      if (!selectedParticipantId) {
        requestObject.participantId = formData.value.controls.events.value || 0;
      }

      if (formData.value.controls.type.value == "2") {
        // medical log
        if (incidentType == MedicalIncidentType.MedicationDispensed) {
          requestObject.selectedMedication = parseInt(
            formData.value.controls.medications.value
          );
        }
        if (incidentType == MedicalIncidentType.MedicalIncident) {
          requestObject.symptom = formData.value.controls.symptom.value
            .map(t => t.id)
            .join(",");
          requestObject.generalNotes =
            formData.value.controls.generalNotes.value;
        }
        requestObject.medicalAlert = formData.value.controls.medicalAlert.value
          ? 1
          : 0;
      }

      if (!selectedMedicalId) {
        result = await ApiHelper.callApi(
          "post",
          "/medicals/",
          requestObject,
          {}
        );
      } else {
        result = await ApiHelper.callApi(
          "put",
          "/medicals/" + selectedMedicalId,
          requestObject,
          {}
        );
      }

      formData.value.isLoading = false;
      if (result.status == 1) {
        ApiHelper.showSuccessMessage("Saved!");
        const query = context.root.$route.query;
        const callFrom = query.callFrom || "";
        if (["MedicalDashboard", "ReportMedicalGeneral"].includes(callFrom)) {
          // back to callFrom
          let callFromQuery = {};
          if (callFrom == "ReportMedicalGeneral") {
            const reportParams = JSON.parse(query.reportParams || "{}");
            callFromQuery = { ...callFromQuery, ...reportParams };
          }
          // context.root.$router
          //   .push({
          //     name: callFrom,
          //     query: callFromQuery
          //   })
          //   .catch((error: any) => {
          //     //
          //   });
          context.root.$router.go(-1);
        } else {
          // ApiHelper.gotoPage(context, {
          //   name: "ProfileMedicals",
          //   params: { profileId: selectedProfileId.toString() },
          //   query: {
          //     participantId: selectedParticipantId
          //       ? selectedParticipantId + ""
          //       : undefined,
          //     type: itemEditInput.value.type + "",
          //     tab: context.root.$route.query.tab,
          //     eventId: context.root.$route.query.eventId
          //   }
          // });
          context.root.$router.go(-1);
        }
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    }
  };

  const onChangeIncidentType = async () => {
    if (
      parseInt(formData.value.controls.incidenttype.value) ==
      MedicalIncidentType.MedicationDispensed
    ) {
      formData.value.controls.notes.required = false;
      formData.value.controls.notes.error = "";

      // get medications
      const result = await ApiHelper.callApi(
        "get",
        "/medicals",
        {},
        {
          getAll: 1,
          profileId: selectedProfileId,
          // participantId: selectedParticipantId,
          type: 1
        }
      );

      if (result.status === 1) {
        let options = (result.data?.items || []).map((item: any) => ({
          id: item.medicalId,
          text: item.medicalName,
          data: item
        }));

        // ignore archived medications
        options = options
          // but still keep selected medication
          .filter(
            (item: any) =>
              item.data.status != 2 ||
              (item.data.status == 2 &&
                item.id == formData.value.controls.medications.value)
          )
          // disable selected medication if this is archived
          .map((item: any) => ({
            ...item,
            disabled: item.data.status == 2
          }));

        options.push({
          id: -1,
          text: "Other...",
          data: {}
        });
        formData.value.controls.medications.options = options;
      }
    } else {
      formData.value.controls.notes.required = true;
    }
  };

  const removeFieldError = (name: string) => {
    // do nothing
  };

  const suggestSymptom = async (key: string) => {
    formData.value.endItemMessage = "";
    const selectedIds = formData.value.controls.symptom.value.map(
      item => item.id
    );
    const take = 25;
    const result = await ApiHelper.callApi(
      "get",
      "/medicals/symptoms",
      {},
      {
        symptomName: key,
        notInIds: selectedIds.join(","),
        take
      }
    );
    if (result.status === 1) {
      formData.value.controls.symptom.suggestTags = result.data.items.map(
        (item: any) => {
          return {
            id: item.symptomId,
            text: item.symptomName,
            data: item
          };
        }
      );
      if ((result.data.totalCount || 0) > take) {
        formData.value.endItemMessage = `Top ${take} symptoms are listed, search to see more symptoms`;
      }
    }
  };

  const addSymptom = async (symptomName: string) => {
    if (symptomName == "") return;
    const result = await ApiHelper.callApi("post", "/medicals/symptoms", {
      symptomName
    });
    if (result.status == 1) {
      const symptomId = result.data.symptomId || 0;
      const inSelectedList = formData.value.controls.symptom.value.find(
        t => t.id == symptomId
      );
      if (!inSelectedList) {
        formData.value.controls.symptom.value.push({
          id: result.data.symptomId,
          text: symptomName
        });
      }
      formData.value.controls.symptom.key = "";
      context.refs.symptomList.show = false;
    }
  };

  const removeLastSymptom = (event: any) => {
    //
  };

  const removeSymptom = (index: number) => {
    if (formData.value.controls.symptom.value.length > index) {
      formData.value.controls.symptom.value.splice(index, 1);
      context.refs.symptomList.show = false;
    }
  };

  const selectSymptom = (item: any) => {
    formData.value.controls.symptom.key = "";
    formData.value.controls.symptom.suggestTags = [];
    formData.value.controls.symptom.value.push({
      id: item.id,
      text: item.text
    });
  };

  const addFollowUp = () => {
    // validate
    let isValid = true;
    const newDate = formData.value.controls.followupDate.value;
    if (newDate == undefined) {
      formData.value.controls.followupDate.error =
        "Follow up date & time is required!";
      isValid = false;
    } else {
      // new date should be greater than current date
      const currentDate = formData.value.selectedFollowup.followupDate;
      if (
        currentDate != undefined &&
        moment(currentDate).isValid() &&
        moment(newDate).isValid() &&
        moment(newDate).isBefore(moment(currentDate))
      ) {
        formData.value.controls.followupDate.error =
          "Follow up date & time should be greater than the latest follow up date & time!";
        isValid = false;
      }
    }

    // notes is required
    const notes = formData.value.controls.followupNotes.value;
    if (formData.value.controls.followupNotes.required && notes == "") {
      formData.value.controls.followupNotes.error =
        "Follow up notes is required!";
      isValid = false;
    }

    if (isValid) {
      formData.value.selectedFollowup = {
        ...formData.value.selectedFollowup,
        followupDate: formData.value.controls.followupDate.value,
        followupDateFormatted: moment(
          formData.value.controls.followupDate.value
        ).format("MM/DD/YYYY hh:mmA"),
        followupNotes: formData.value.controls.followupNotes.value,
        resolved: formData.value.resolved
      };
      formData.value.followupInfoVisible = false;
      formData.value.changedFollowup = true;
    }
  };

  const autoFillMed = () => {
    const isMedicalLog = formData.value.controls.type.value == "2";
    const isMedicationDispensed =
      parseInt(formData.value.controls.incidenttype.value) ==
      MedicalIncidentType.MedicationDispensed;
    if (isMedicalLog && isMedicationDispensed) {
      const latestLog = formData.value.followUpLogs[0] || null;
      const latestMed = latestLog?.med || "";
      let medicationName = "";
      if (latestMed) {
        medicationName = latestMed;
      } else {
        const medicationId = formData.value.controls.medications.value;
        if (medicationId == "-1") {
          // if selected other medication, fill with a inputted custom medication name
          medicationName = formData.value.controls.name.value;
        } else {
          medicationName =
            formData.value.controls.medications.options.find(
              item => item.id == medicationId
            )?.text || "";
        }
      }
      formData.value.controls.med.value = medicationName;
    }
  };

  const showFollowUp = async () => {
    formData.value.controls.followupDate.value =
      formData.value.selectedFollowup.followupDate;
    // formData.value.controls.followupNotes.value =
    //   formData.value.selectedFollowup.followupNotes;
    formData.value.controls.followupDate.error = "";
    formData.value.resolved = formData.value.selectedFollowup.resolved;
    formData.value.controls.followupNotes.value = "";
    formData.value.controls.followupNotes.error = "";
    formData.value.controls.followupNotes.required = formData.value.resolved
      ? false
      : true;
    formData.value.controls.med.value = "";
    formData.value.followupInfoVisible = true;

    // get followup logs
    try {
      formData.value.isLoading = true;
      formData.value.followUpLogs = [];
      const result = await ApiHelper.callApi(
        "get",
        `/medicals/followupLogs`,
        {},
        {
          medicalId: selectedMedicalId
        }
      );
      if (result.status === 1) {
        formData.value.followUpLogs = result.data.followUpLogs;
        autoFillMed();
      }
    } catch (error) {
      console.log(error);
    } finally {
      formData.value.isLoading = false;
    }
  };

  const closeFollowUp = () => {
    formData.value.followupInfoVisible = false;
  };

  const getEventOptions = async () => {
    const events = await ApiHelper.callApi(
      "get",
      "/medicals/events",
      {},
      {
        getAll: 1,
        order: 1,
        direction: 1,
        status: "1",
        profileId: selectedProfileId
      }
    );
    if (events.status == 1) {
      formData.value.controls.events.options = events.data.events.map(
        (item: any) => ({
          id: item.participantId,
          text: `${item.eventName} - ${item.pTypeName}`,
          data: item
        })
      );
      if ((query.participantId || 0) > 0) {
        formData.value.controls.events.value = query.participantId;
        formData.value.controls.events.disabled = true;
      }
    }
  };

  const followupChange = () => {
    formData.value.changedFollowup = true;
  };

  const changeResolved = () => {
    const required = formData.value.resolved ? false : true;
    formData.value.controls.followupNotes.required = required;
    if (!required) {
      formData.value.controls.followupNotes.error = "";
    }
  };

  const dosagesChange = () => {
    const dosages = formData.value.controls.dosages.value || [];
    if (dosages.length) {
      formData.value.controls.dosages.error = "";
    }
  };

  const resetMedication = () => {
    formData.value.controls.name.value = "";
    const aciMedicationsRef: any = context.refs.aciMedicationsRef;
    const key = aciMedicationsRef?.key || "";
    if (key != "") {
      formData.value.controls.name.error = "";
    }
  };

  const suggestMedications = async (key: string) => {
    // reset
    formData.value.foundMedications = [];
    formData.value.controls.name.error = "";
    formData.value.controls.name.value = "";

    // searching
    formData.value.isACILoading = true;
    const result = await ApiHelper.callApi(
      "get",
      "/medicals/suggestMedications",
      {},
      {
        key
      }
    );
    if (result.status === 1) {
      formData.value.foundMedications = (result.data.items || []).map(
        (item: any) => ({
          text: item.medicationName,
          html: item.medicationName,
          data: item
        })
      );
    }
    formData.value.isACILoading = false;
  };

  const selectMedication = async (data: any) => {
    const medicationName = data?.medicationName || "";
    if (data == undefined || medicationName == "") {
      return;
    }

    formData.value.controls.name.error = "";
    formData.value.controls.name.value = medicationName;
  };

  // init data
  (async () => {
    const isMedication = formData.value.controls.type.value == "1";
    const profileInfo = await ProfileHelper.getProfileInfo(selectedProfileId);
    isArchived.value = profileInfo.status === 2;
    getEventOptions();

    if (isMedication) {
      formData.value.controls.dispenseMethod.options = await ApiHelper.getMedicationDispenseMethods();
      formData.value.controls.dosages.options = ApiHelper.getMedicationDosages();
    }

    if (selectedMedicalId) {
      const result = await ApiHelper.callApi(
        "get",
        "/medicals/" + selectedMedicalId,
        {},
        {}
      );
      formData.value.isLoading = false;
      if (result.status === 1) {
        const item = result.data || {};
        formData.value.controls.name.value = item.name || "";
        formData.value.controls.dosage.value = item.dosage || "";
        formData.value.controls.frequency.value = item.frequency || "";
        formData.value.controls.type.value = (item.type || "") + "";
        formData.value.controls.incidenttype.value =
          (item.incidenttype || "") + "";
        formData.value.controls.note.value = !!item.isfamilyUsed;
        formData.value.controls.notes.value = item.notes || "";
        formData.value.controls.timeOfDays.value = item.timeOfDays || undefined;
        formData.value.controls.prescribingDoctor.value =
          item.prescribingDoctor || "";
        formData.value.controls.startDate.value = moment(
          item.startDate
        ).isValid()
          ? moment(item.startDate.replace(".000Z", "")).toDate()
          : undefined;
        formData.value.controls.endDate.value =
          item.endDateFormatted || undefined;
        if ((item.symptom || []).length) {
          formData.value.controls.symptom.value = item.symptom.map(
            (t: any) => ({
              id: t.symptomId,
              text: t.symptomName
            })
          );
        }
        formData.value.controls.generalNotes.value = item.generalNotes || "";
        formData.value.controls.medicalAlert.value =
          (item.medicalAlert || 0) == 1 ? true : false;

        if (
          formData.value.controls.type.value == "2" &&
          parseInt(formData.value.controls.incidenttype.value) ==
            MedicalIncidentType.MedicationDispensed
        ) {
          let relatedId = item.relatedId || 0;
          if (formData.value.controls.name.value != "") {
            relatedId = -1;
          }
          formData.value.controls.medications.value = relatedId;
          await onChangeIncidentType();
        }

        // medication
        if (isMedication) {
          const aciMedicationsRef: any = context.refs.aciMedicationsRef;
          if (aciMedicationsRef) {
            aciMedicationsRef.key = formData.value.controls.name.value;
          }
          formData.value.controls.strength.value = item.strength || "";
          formData.value.controls.dispenseMethod.value =
            item.dispenseMethod || "";
          formData.value.controls.dosages.value = (item.dosages || "")
            .split(",")
            .map((id: string) => parseInt(id));
          formData.value.controls.dosageOther.value = item.dosageOther || "";
          formData.value.controls.medicationStart.value = moment(
            item.medicationStart
          ).isValid()
            ? moment(item.medicationStart.replace(".000Z", "")).toDate()
            : undefined;
          formData.value.controls.medicationEnd.value = moment(
            item.medicationEnd
          ).isValid()
            ? moment(item.medicationEnd.replace(".000Z", "")).toDate()
            : undefined;
          formData.value.controls.specialInstructions.value =
            item.specialInstructions || "";
          formData.value.prescriptionName = item.prescriptionName || "";
          formData.value.prescription = item.prescription || "";
        }

        // update values for followup
        const followupDate =
          item.followupDate && moment(item.followupDate).isValid()
            ? moment(
                item.followupDate.replace(".000Z", "").replace("+00:00", "")
              ).toDate()
            : undefined;
        const resolved = item.resolved == 0 ? false : true;
        formData.value.controls.followupDate.value = followupDate;
        formData.value.controls.followupNotes.value = item.followupNotes || "";
        formData.value.resolved = resolved;
        formData.value.selectedFollowup = {
          ...formData.value.selectedFollowup,
          followupDate,
          followupDateFormatted:
            followupDate && moment(followupDate).isValid()
              ? moment(followupDate).format("MM/DD/YYYY hh:mmA")
              : "",
          followupNotes: item.followupNotes || "",
          resolved
        };
        formData.value.controls.events.value = item.participantId || "";
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    } else {
      formData.value.isLoading = false;
    }
  })();

  return {
    isArchived,
    formData,
    removeFieldError,
    onSubmit,
    onChangeIncidentType,
    suggestSymptom,
    addSymptom,
    removeLastSymptom,
    removeSymptom,
    selectSymptom,
    showFollowUp,
    addFollowUp,
    closeFollowUp,
    followupChange,
    changeResolved,
    dosagesChange,
    suggestMedications,
    selectMedication,
    resetMedication
  };
}
