


















import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "FormButton",
  props: {
    isProcessing: Boolean,
    label: String,
    onSubmit: Function,
    type: String,
    size: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const buttonType = ref("submit");
    const doClick = () => {
      if (props.onSubmit) {
        buttonType.value = "button";
        props.onSubmit();
      }
    };

    return {
      doClick,
      buttonType
    };
  }
});
