import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
export function useSettingsParticipantFormsDetailsStore(context: any) {
  const selectedFormId = parseInt(context.root.$route.params.formId) || 0;
  const headerData = ref({
    title: "-",
    subTitle: "-"
  });

  const pageData = ref<{
    isLoading: boolean;
    details: {
      jsonData: string;
      events: any[];
      participantTypes: any[];
    };
  }>({
    isLoading: true,
    details: {
      jsonData: "",
      events: [],
      participantTypes: []
    }
  });

  const loadData = (page: number) => {
    ApiHelper.setDataLoading(true);
    (async () => {
      try {
        const result = await ApiHelper.callApi(
          "get",
          "/todos/" + selectedFormId,
          {},
          {}
        );
        ApiHelper.setDataLoading(false);
        if (result.status === 1) {
          const resultData = result.data || null;
          if (resultData) {
            headerData.value.title = resultData.name || "";
            headerData.value.subTitle = "Settings - To Dos";
            pageData.value.details.jsonData = resultData.content || "";
            pageData.value.details.events = resultData.events || [];
            pageData.value.details.participantTypes =
              resultData.participantTypes || [];
          }
        }
      } catch (err) {
        ApiHelper.setDataLoading(false);
      }
    })();
  };

  // init data
  (async () => {
    loadData(1);
  })();

  return {
    headerData,
    pageData
  };
}
