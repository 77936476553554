







































































































































































































































































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Header from "@/components/Common/Header.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import { useSettingsTagsStore } from "@/stores/Settings/Tags/SettingsTagsStore";
import DropdownParticipantStatus from "@/components/Participant/DropdownParticipantStatus.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";

export default defineComponent({
  name: "SettingsTags",
  components: {
    Header,
    Loading,
    Pager,
    TableHeaderColumn,
    DropdownParticipantStatus,
    FilterSearchColumn,
    FilterCheckboxesColumn
  },
  setup(props, context) {
    const {
      updateRouters,
      updateItemStatus,
      deleteItem,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue
    } = useSettingsTagsStore(context);

    return {
      updateRouters,
      updateItemStatus,
      deleteItem,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      ApiHelper: ApiHelper
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
