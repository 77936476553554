import { ref } from "@vue/composition-api";

import { ApiHelper } from "@/helpers";
import { FormPassword } from "@/types";

export function useUserPasswordResetStore(context: any) {
  const resetPasswordInput = ref({
    email: context.root.$route.query.email || "",
    token: context.root.$route.query.token || "",
    password: "",
    confirmPassword: ""
  });
  const syncFormData = (formControls: any) => {
    resetPasswordInput.value.password = formControls.password.value;
    resetPasswordInput.value.confirmPassword =
      formControls.confirmPassword.value;
  };
  const validateForm = (formControls: any) => {
    let hasError = false;
    if (formControls.password.value === "") {
      hasError = true;
      formControls.password.error = "Password is required!";
    }
    if (formControls.confirmPassword.value === "") {
      hasError = true;
      formControls.confirmPassword.error = "Confirm Password is required!";
    }

    if (
      formControls.password.value !== "" &&
      formControls.confirmPassword.value !== "" &&
      formControls.password.value !== formControls.confirmPassword.value
    ) {
      hasError = true;
      formControls.confirmPassword.error = "Confirm Password is not match!";
    }
    if (formControls.password.value !== "" && !formControls.password.valid) {
      formControls.password.error = "Password is invalid";
      hasError = true;
    }

    return !hasError;
  };

  const resetForm = (formData: any) => {
    formData.controls.password.error = "";
    formData.controls.password.value = "";
    formData.controls.confirmPassword.error = "";
    formData.controls.confirmPassword.value = "";
  };

  const submitForm = async (dataInput: any, formData: any) => {
    formData.isLoading = true;
    const result = await ApiHelper.callApi(
      "post",
      "/auth/passwordreset",
      {
        email: dataInput.email,
        token: dataInput.token,
        password: dataInput.password,
        confirmPassword: dataInput.confirmPassword
      },
      {},
      "core"
    );
    formData.isLoading = false;
    if (result.status == 1) {
      ApiHelper.showSuccessMessage(`Your password was reset.`);
      resetForm(formData);
      ApiHelper.gotoPage(context, {
        name: "UserLogin",
        query: {
          username: result.data.username || undefined
        }
      });
    } else {
      ApiHelper.showErrorMessage(result.message);
    }
  };

  const formData = ref<{
    isLoading: boolean;
    controls: {
      password: FormPassword;
      confirmPassword: FormPassword;
    };
    actions: {
      onSubmit: any;
    };
  }>({
    isLoading: false,
    controls: {
      password: {
        name: "password",
        type: "password",
        label: `New Password`,
        placeholder: "New Password",
        error: "",
        mode: "password",
        value: ""
      },
      confirmPassword: {
        name: "confirmPassword",
        type: "password",
        label: `Confirm Password`,
        placeholder: "Confirm Password",
        error: "",
        mode: "password",
        value: ""
      }
    },
    actions: {
      onSubmit: async (event: Event) => {
        event.preventDefault();
        syncFormData(formData.value.controls);
        if (validateForm(formData.value.controls)) {
          await submitForm(resetPasswordInput.value, formData.value);
        }
      }
    }
  });

  const pageData = ref({
    form: formData
  });

  return {
    pageData,
    formData
  };
}
