
















import { ref, defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Header from "@/components/Common/Header.vue";
import ComingSoon from "@/components/Common/ComingSoon.vue";

type PageData = {
  isLoading: boolean;
};

export default defineComponent({
  name: "FinancialCostCenter",
  components: {
    ComingSoon,
    Header,
    Loading
  },
  setup(props, context) {
    const pageData = ref<PageData>({
      isLoading: false
    });

    return {
      pageData
    };
  }
});
