





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import directives from "@/helpers/directives";
import Vue from "vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import FormTags from "@/components/Form/FormTags.vue";
import Modal from "@/components/Common/Modal.vue";
import FormText from "@/types/FormText";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormDatePicker2 from "../Form/FormDatePicker2.vue";
import moment from "moment";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import { FormSelect as FormSelectType } from "@/types";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormButton from "@/components/Form/FormButton.vue";
import FormNumber from "@/components/Form/FormNumber.vue";

export default defineComponent({
  name: "EventAddServices",
  props: {
    eventId: Number,
    data: Object,
    updateItems: Function,
    costcenters: {
      type: Array,
      defaultValue: []
    },
    disabled: {
      type: Boolean,
      defaultValue: false
    },
    showCostCenter: {
      type: Boolean,
      defaultValue: false
    },
    showGlCodes: {
      type: Boolean,
      defaultValue: false
    },
    eventCoscenterNumber: {
      type: Array,
      default: []
    },
    isAllowAddNewCostCenter: Function,
    isAllowAddNewPostingCode: Function
  },
  directives: directives,
  components: {
    FormSelect,
    FormInput,
    FormTags,
    AutoCompleteInput,
    Modal,
    FormTextarea,
    FormDatePicker2,
    FormButton,
    FormNumber
  },
  setup(props, context) {
    const isACILoading = ref<boolean>(false);
    const componentData = ref<{
      items: {
        id: number;
        uuid: string;
        name: {
          value: string;
          error: string;
        };
        postingCodes: {
          error: string;
          label: string;
          textOverflow: boolean;
          placeholder: string;
          type: string;
          key: string;
          value: any[];
          options: any[];
          suggestTags: any[];
        };
        costCenters: {
          error: string;
          label: string;
          textOverflow: boolean;
          placeholder: string;
          type: string;
          key: string;
          firstFocus: boolean;
          value: any[];
          options: any[];
          suggestTags: any[];
        };
        cost: {
          value: string;
          error: string;
        };
        tags: {
          error: string;
          label: string;
          placeholder: string;
          type: string;
          key: string;
          value: any[];
          options: any[];
          suggestTags: any[];
        };
        description: {
          value: string;
          error: string;
        };
        costcenter: {
          value: string;
          error: string;
        };
        isFundBucket: {
          value: boolean;
          error: string;
        };
        sortOrder: number;
        totalParticipants: number;
        isNew: boolean;
        isEditing: boolean;
        availableDate: {
          value: any;
          error: string;
        };
        dueDate: FormSelectType;
        chargeMonthly: {
          value: boolean;
          error: string;
        };
      }[];
      allowAdd: boolean;
      firstCost: boolean;
      allowTop: boolean;
      allowSuggestOnFocus: boolean;
      totalDataApplyTop: number;
      isLoading: boolean;
      editingIndex: number;
      controls: {
        postingCode: FormText;
        postingCodeDesc: FormText;
        costCenterNumber: FormText;
        costCenterName: FormText;
        glName: FormText;
      };
      itemEdit: {
        item: any;
        index: number;
      };
    }>({
      items: [],
      allowAdd: true,
      firstCost: true,
      allowTop: false,
      allowSuggestOnFocus: true,
      totalDataApplyTop: 0,
      isLoading: false,
      editingIndex: -1,
      controls: {
        postingCode: {
          required: true,
          label: "Posting Code",
          placeholder: "Posting Code",
          style: "custom",
          value: "",
          error: "",
          maxlength: 30
        },
        postingCodeDesc: {
          required: true,
          // label: "Description",
          label: "Posting Code Name",
          // placeholder: "Description",
          placeholder: "Posting Code Name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 255
        },
        costCenterNumber: {
          required: true,
          label: "Number",
          placeholder: "Number",
          style: "custom",
          value: "",
          error: "",
          maxlength: 100
        },
        costCenterName: {
          required: false,
          label: "Cost center name",
          placeholder: "Cost center name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 150
        },
        glName: {
          required: true,
          label: "GL Name",
          placeholder: "GL Name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 100
        }
      },
      itemEdit: {
        item: [],
        index: -1
      }
    });
    const amountTypeInput = ref("amount");
    const foundPTypes = ref<any[]>([]);
    const newPostingCode = ref<{
      modalVisible: boolean;
      postingCode: string;
      currentAddOn: any;
    }>({
      modalVisible: false,
      postingCode: "",
      currentAddOn: {}
    });

    const newCostCenter = ref<{
      modalVisible: boolean;
      isProcessing: boolean;
      item: any;
      index: number;
    }>({
      modalVisible: false,
      isProcessing: false,
      item: {},
      index: 0
    });

    const glCodes = ref<any>({
      required: true,
      error: "",
      label: "GL Code",
      placeholder: "Select or input a GL Code",
      type: "tags",
      key: "",
      value: [],
      options: [],
      suggestTags: []
    });

    const getPTypesHtml = (item: any) => {
      let ret = `${item.serviceName}`;
      if (item.serviceCost) {
        const moreInfo = [];
        if (item.serviceCost) {
          moreInfo.push(`Cost: ${ApiHelper.dollarFormat(item.serviceCost)}`);
        }
        ret = `${item.serviceName} - (${moreInfo.join(", ")})`;
      } else {
        ret = `${item.serviceName}`;
      }

      return ret;
    };

    const loadData = () => {
      (async () => {
        if (componentData.value.items) {
          componentData.value.items = componentData.value.items.filter(
            (item: any) => !item.isDeleted
          );
        }
        if (props.eventId) {
          componentData.value.isLoading = true;
          const result = await ApiHelper.callApi(
            "get",
            "/events/" + props.eventId + "/services",
            {},
            {}
          );
          componentData.value.isLoading = false;
          if (result.status === 1) {
            componentData.value.allowAdd = true;
            componentData.value.editingIndex = -1;
            componentData.value.items = (result.data.services || []).map(
              (item: any, index: number) => {
                const tags = item.tags || [];
                // const postingCodes = item.postingCode ? [item.postingCode] : [];
                const costCenters = item.costCenterNumber
                  ? [item.costCenterNumber]
                  : [];
                const availableToDate = item.availableToDateFormatted
                  ? moment(item.availableToDateFormatted).toDate()
                  : undefined;

                return {
                  uuid: item.id || ApiHelper.uuid(),
                  id: item.addonServiceId,
                  name: {
                    value: item.serviceName || "",
                    error: ""
                  },
                  description: {
                    value: item.serviceDesc || "",
                    error: ""
                  },
                  postingCodes: {
                    error: "",
                    label: "",
                    textOverflow: true,
                    placeholder: "Posting / GL Code",
                    type: "tags",
                    key: "",
                    value: item.postingCode
                      ? [
                          {
                            id: item.postingCode,
                            text: `${item.postingCode} / ${item.glCode}`
                          }
                        ]
                      : [],
                    options: [],
                    suggestTags: []
                  },
                  costCenters: {
                    error: "",
                    label: "",
                    textOverflow: true,
                    placeholder: "Cost Center Number",
                    type: "tags",
                    key: "",
                    firstFocus: true,
                    value: costCenters.map((code: any) => {
                      return {
                        id: code,
                        text: code
                      };
                    }),
                    options: [],
                    suggestTags: []
                  },
                  cost: {
                    value: (item.serviceCost || 0).toFixed(2),
                    error: ""
                  },
                  tags: {
                    error: "",
                    label: "",
                    placeholder: "Enter a tag",
                    type: "tags",
                    key: "",
                    value: tags.map((tag: any) => {
                      return {
                        id: tag.id,
                        text: tag.name || "",
                        data: tag
                      };
                    }),
                    options: [],
                    suggestTags: []
                  },
                  costcenter: {
                    value: item.costcenterId || "",
                    error: ""
                  },
                  isFundBucket: {
                    value: item.isFundBucket,
                    error: ""
                  },
                  linkId: item.linkId || 0,
                  totalParticipants: item.totalParticipants || 0,
                  sortOrder: item.sortOrder || index + 1,
                  isNew: false,
                  isEditing: false,
                  availableDate: {
                    value: availableToDate,
                    error: ""
                  },
                  chargeMonthly: {
                    value: item.chargeMonthly || false,
                    error: ""
                  },
                  dueDate: {
                    style: "custom",
                    error: "",
                    type: "",
                    placeholder: "End Date",
                    required: false,
                    label: "End Date",
                    value: item.dueDate || "",
                    options: ApiHelper.getDueDateOptions()
                  }
                };
              }
            );
          }
        }
      })();
    };

    const addNewRow = () => {
      componentData.value.items.map((value: any) => {
        value.isEditing = false;
      });
      componentData.value.allowAdd = false;
      componentData.value.items.push({
        id: 0,
        uuid: ApiHelper.uuid(),
        name: {
          value: "",
          error: ""
        },
        postingCodes: {
          error: "",
          label: "",
          textOverflow: true,
          placeholder: "Posting Code",
          type: "tags",
          key: "",
          value: [],
          options: [],
          suggestTags: []
        },
        costCenters: {
          error: "",
          label: "",
          textOverflow: true,
          placeholder: "Cost Center Number",
          type: "tags",
          key: "",
          firstFocus: true,
          value: [],
          options: [],
          suggestTags: []
        },
        cost: {
          value: "",
          error: ""
        },
        tags: {
          error: "",
          label: "",
          placeholder: "Enter a tag",
          type: "tags",
          key: "",
          value: [],
          options: [],
          suggestTags: []
        },
        description: {
          value: "",
          error: ""
        },
        costcenter: {
          value: "",
          error: ""
        },
        isFundBucket: {
          value: false,
          error: ""
        },
        sortOrder: componentData.value.items.length + 1,
        totalParticipants: 0,
        isNew: true,
        isEditing: true,
        availableDate: {
          value: undefined,
          error: ""
        },
        dueDate: {
          style: "custom",
          error: "",
          type: "",
          placeholder: "End Date",
          required: false,
          label: "End Date",
          value: "",
          options: ApiHelper.getDueDateOptions()
        },
        chargeMonthly: {
          value: false,
          error: ""
        }
      });
      componentData.value.editingIndex = componentData.value.items.length - 1;
    };

    const saveItems = () => {
      if (props.updateItems) {
        const newLines = componentData.value.items.map(item => {
          const tags = item.tags.value || [];
          const availableToDate = item.availableDate.value;
          return {
            uuid: uuidv4(),
            id: 0,
            eventId: 0,
            addonServiceId: item.id != -1 ? item.id : 0,
            serviceName: item.name.value,
            serviceDesc: item.description.value,
            serviceCost: item.cost.value,
            linkAddonCost: item.cost.value,
            costcenter: item.costcenter.value,
            isFundBucket: !!item.isFundBucket.value,
            chargeMonthly: !!item.chargeMonthly.value,
            dueDate: item.dueDate.value,
            active: 1,
            showRemoveCntDown: false,
            totalParticipants: 0,
            cntDown: 3,
            error: false,
            tags: {
              error: "",
              label: "",
              placeholder: "Enter a tag",
              type: "tags",
              key: "",
              value: tags.map((tag: any) => {
                return {
                  id: tag.id,
                  text: tag.name || "",
                  data: tag
                };
              }),
              options: [],
              suggestTags: []
            },
            postingCodes: {
              error: "",
              label: "",
              textOverflow: true,
              placeholder: "Posting / GL Code",
              type: "tags",
              key: "",
              value: item.postingCodes.value.map((code: any) => {
                return {
                  id: code.id,
                  text: code.text
                };
              }),
              // item.postingCodes.value
              // ? [
              //     {
              //       id: item.postingCodes.value,
              //       text: `${item.postingCodes.value} / ${item.postingCodes.value}`
              //     }
              //   ]
              // : [],
              options: [],
              suggestTags: []
            },
            costCenters: {
              error: "",
              label: "",
              textOverflow: true,
              placeholder: "Cost Center Number",
              type: "tags",
              key: "",
              firstFocus: true,
              value: item.costCenters.value.map((code: any) => {
                return {
                  id: code.id,
                  text: code.text
                };
              }),
              options: [],
              suggestTags: []
            },
            availableDate: {
              value: availableToDate,
              error: ""
            }
          };
        });
        props.updateItems(newLines);
      }
    };

    const doCancelItem = (index: number, item: any) => {
      if (!item.name.value) {
        item.isDeleted = true;
      }
      componentData.value.editingIndex = -1;
      componentData.value.allowAdd = true;
      item.isEditing = false;
      loadData();
    };

    const doSaveItem = async (index: number, item: any) => {
      let hasError = false;
      if (item.name.value === "") {
        item.name.error = "Name is required";
        hasError = true;
      } else {
        componentData.value.items.map((service: any, key: number) => {
          if (
            key !== index &&
            service.name.value.toUpperCase() == item.name.value.toUpperCase()
          ) {
            item.name.newError = true;
            item.name.error =
              "Name already exists. Please enter new information.";
            ApiHelper.showErrorMessage(
              "Name already exists. Please enter new information.",
              "Oops"
            );
            hasError = true;
          }
        });
      }
      const cost = Number.parseFloat(item.cost.value) || 0;
      if (cost < 0) {
        item.cost.error = "Cost is invalid";
        hasError = true;
      }

      if (hasError) {
        return;
      }
      if (cost === 0 && !item.isFundBucket.value) {
        item.cost.error =
          'Amount of the service "' +
          item.name.value +
          '" must greater than $0 or check the Fund Bucket option';
        hasError = true;
        ApiHelper.showErrorMessage(item.cost.error, "Oops");
        return;
      }

      const linkOrders = componentData.value.items.map((value: any) => {
        return {
          linkId: value.linkId,
          sortOrder: value.sortOrder
        };
      });

      // auto select cost center
      if (
        item.costCenters.value.length == 0 &&
        item.costCenters.key != "" &&
        item.costCenters.suggestTags.length
      ) {
        const related = item.costCenters.suggestTags.find(
          (t: any) => t.id.toLowerCase() == item.costCenters.key.toLowerCase()
        );
        if (related) {
          item.costCenters.value = [
            {
              id: related.id,
              text: related.text
            }
          ];
        }
      }

      componentData.value.allowAdd = true;
      item.isEditing = false;
      componentData.value.editingIndex = -1;
      saveItems();
      loadData();
    };

    const doDeleteItem = async (index: number, item: any) => {
      const isAgreed = await Vue.swal({
        html: "Are you sure you want to delete this service?",
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });

      if (isAgreed) {
        if (item.id == 0) {
          return;
        }
        componentData.value.items = componentData.value.items.filter(
          item2 => item2.uuid != item.uuid
        );
        componentData.value.editingIndex = -1;
        componentData.value.allowAdd = true;
        ApiHelper.showSuccessMessage("Deleted");
        context.emit("updateServicesList", componentData.value.items);
        loadData();
      } else {
        componentData.value.editingIndex = -1;
        componentData.value.allowAdd = true;
      }
    };
    const doEditItem = (index: number, item: any) => {
      componentData.value.items.map((value: any) => {
        value.isEditing = false;
      });
      componentData.value.editingIndex = index;
      componentData.value.allowAdd = false;
      item.isEditing = true;
    };

    const amountTypeSwitch = () => {
      amountTypeInput.value =
        amountTypeInput.value == "amount" ? "rate" : "amount";
    };

    const getSelectPtypeCost = (ptypeId: number) => {
      let cost = 0;
      if (props.data && props.data.ptypes) {
        const findPtype = props.data.ptypes.find((item: any) => {
          return item.id === ptypeId;
        });
        if (findPtype) {
          cost = findPtype.cost || 0;
        }
      }
      return cost;
    };

    const changeDiscountAmount = (item: any) => {
      const ptypeCost = getSelectPtypeCost(parseInt(item.ptype.value) || 0);
      item.discountAmount.error = "";
      item.discountPercent.error = "";
      const discountAmount = parseFloat(item.discountAmount.value) || 0;

      if (discountAmount > ptypeCost) {
        item.discountAmount.error =
          "Discount amount must less than " + ApiHelper.dollarFormat(ptypeCost);
      }
      if (ptypeCost > 0) {
        item.discountPercent.value = (
          (discountAmount / ptypeCost) *
          100
        ).toFixed(2);
      }
    };

    const changeDiscountPercent = (item: any) => {
      const ptypeCost = getSelectPtypeCost(parseInt(item.ptype.value) || 0);
      item.discountAmount.error = "";
      item.discountPercent.error = "";
      let discountPercent = parseFloat(item.discountPercent.value) || 0;
      if (discountPercent > 100) {
        discountPercent = 100;
        item.discountPercent.value = discountPercent;
      }
      const discountAmount = ptypeCost * (discountPercent / 100);
      item.discountAmount.value = discountAmount.toFixed(2);
      if (discountAmount > ptypeCost) {
        item.discountAmount.error =
          "Discount amount must less than " + ApiHelper.dollarFormat(ptypeCost);
      }
    };

    const suggestPTypes = async (key: string, item: any, index: number) => {
      // reset
      foundPTypes.value = [];
      item.name.value = key;
      if (key.length < 5) {
        componentData.value.allowSuggestOnFocus = true;
      } else {
        componentData.value.allowSuggestOnFocus = false;
      }
      //if (key !== "")
      {
        // searching
        item.id = -1;
        const selectedPTypeNames = componentData.value.items
          ? componentData.value.items
              .filter(option => option.name.value && option.name.value != "")
              .map(option => option.name.value)
          : [];
        isACILoading.value = true;
        const result = await ApiHelper.callApi(
          "get",
          "/services/search",
          {},
          {
            key: key,
            take: 25
            // selectedNames: selectedPTypeNames.length
            //   ? JSON.stringify(selectedPTypeNames)
            //   : ""
          }
        );
        if (result.status === 1) {
          // componentData.value.allowSuggestOnFocus = true;
          foundPTypes.value = result.data.services.map((item: any) => ({
            id: item.addonServiceId,
            text: item.serviceName,
            html: getPTypesHtml(item),
            data: item
          }));
        }
        isACILoading.value = false;
      }
    };

    const selectPType = (data: any, item: any) => {
      componentData.value.allowSuggestOnFocus = false;
      item.id = data.addonServiceId;
      item.name.value = data.serviceName;
      item.description.value = data.serviceDesc;
      item.cost.value = data.serviceCost || data.linkAddonCost || 0;
      item.isFundBucket.value = !!data.isFundBucket;
      item.dueDate.value = data.dueDate || "";
      item.chargeMonthly.value = data.chargeMonthly || false;
      item.tags.value = (data.tags || []).map((tag: any) => {
        return {
          id: tag.id,
          text: tag.name
        };
      });

      const postingCode = data.postingCode || "";
      const glCode = data.glCode || "";
      const costCenterNumber = data.costCenterNumber || "";
      item.postingCodes.value = [];
      if (postingCode != "" && glCode != "") {
        item.postingCodes.value = [
          {
            id: postingCode,
            text: `${postingCode} / ${glCode}`
          }
        ];
      }
      item.costCenters.value = [];
      if (costCenterNumber != "") {
        item.costCenters.value = [
          {
            id: costCenterNumber,
            text: costCenterNumber
          }
        ];
      }
    };

    const onSuggestTags = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/tags/search",
        {},
        {
          // typeUse: "finance",
          key: key,
          notInIds: notInIds
        }
      );
      if (result?.status === 1) {
        selectedItem.suggestTags = result.data.tags.map((item: any) => {
          return {
            id: item.id,
            text: item.text,
            data: item
          };
        });
      }
    };

    const onAddTag = (selectedItem: any) => {
      const key = selectedItem.key.trim();
      if (key) {
        selectedItem.value.push({
          id: 0,
          text: key
        });
        selectedItem.key = "";
      }
    };

    const onRemoveLastTag = (selectedItem: any) => {
      if (selectedItem.value.length > 0 && selectedItem.key === "") {
        const index = selectedItem.value.length - 1;
        selectedItem.value.splice(index, 1);
      }
    };
    const onRemoveTag = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectTag = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        text: item.text,
        data: item.data || {}
      });
    };

    // posting codes
    const onSuggestPostingCodes = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        componentData.value.totalDataApplyTop = 0;
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/finances/postingCodes",
        {},
        {
          key,
          notInIds
        }
      );
      if (result.status === 1) {
        componentData.value.totalDataApplyTop = result.data.items.length;
        selectedItem.suggestTags = result.data.items
          .filter((item: any) => item.postingCodeDesc != "")
          .map((item: any) => {
            return {
              id: item.postingCode,
              // text: `${item.postingCode} / ${item.glCode}`,
              text: item.postingCodeDesc,
              data: item
            };
          });
      }
    };

    const onRemoveLastPostingCode = (selectedItem: any) => {
      // if (selectedItem.value.length > 0 && selectedItem.key === "") {
      //   const index = selectedItem.value.length - 1;
      //   selectedItem.value.splice(index, 1);
      // }
    };
    const onRemovePostingCodes = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectPostingCode = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        // text: item.text
        text: `${item.data.postingCode} / ${item.data.glCode}`
      });
    };

    const showNewPostingCodeModal = (
      postingCode: string,
      selectedAddon: any,
      index: number
    ) => {
      if (postingCode == "") return;

      const postingCodeRef: any = context.refs.postingCodeRef;

      // auto selecting if existed in suggested items
      const existed = selectedAddon.postingCodes.suggestTags.find(
        (item: any) => `${item.id}`.toLowerCase() == postingCode.toLowerCase()
      );
      if (existed) {
        // auto select
        selectedAddon.postingCodes.value.push({
          id: existed.id,
          text: existed.text
        });
        selectedAddon.postingCodes.key = "";
        if (postingCodeRef[index]) {
          postingCodeRef[index].show = false;
        }

        return;
      }
      componentData.value.controls.postingCode.value = postingCode;
      newPostingCode.value.modalVisible = true;
      newPostingCode.value.postingCode = postingCode;
      newPostingCode.value.currentAddOn = selectedAddon;
      if (postingCodeRef[index]) {
        postingCodeRef[index].show = false;
      }
    };

    const onSuggestGLCode = async () => {
      const notInIds = glCodes.value.value
        .map((item: any) => item.id)
        .join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/glCodes",
        {},
        {
          gl: glCodes.value.key || "",
          notInIds
        }
      );
      if (result.status === 1) {
        glCodes.value.suggestTags = result.data.items.map((code: string) => {
          return {
            id: code,
            text: code
          };
        });
      }
    };

    const onSelectGLCode = (selectedValue: any) => {
      glCodes.value.error = "";
      glCodes.value.key = selectedValue.id;
      glCodes.value.suggestTags = [];
    };

    const savePostingCode = async () => {
      // const postingCode = newPostingCode.value.postingCode || "";
      const postingCode = componentData.value.controls.postingCode.value;
      const glCode = glCodes.value.key || "";
      const addonServiceId = newPostingCode.value.currentAddOn.id || 0;
      // reset
      glCodes.value.error = "";

      // validate
      let valid = true;
      // if (postingCode == "") return;
      if (glCode == "") {
        valid = false;
        glCodes.value.error = "GL Code is required!";
      }
      if (componentData.value.controls.postingCode.value == "") {
        valid = false;
        componentData.value.controls.postingCode.error =
          "Posting Code is required!";
      }
      if (componentData.value.controls.postingCodeDesc.value == "") {
        valid = false;
        componentData.value.controls.postingCodeDesc.error =
          "Posting Code Name is required!";
      }
      const glName = componentData.value.controls.glName.value;
      if (glName == "") {
        valid = false;
        componentData.value.controls.glName.error = "GL Name is required!";
      }

      if (valid) {
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/postingcodes/0`,
          {
            code: postingCode,
            glCode,
            glName,
            desc: componentData.value.controls.postingCodeDesc.value
          },
          {}
        );
        if (result.status == 1) {
          // update posting codes for current addon
          const currentAddOn = componentData.value.items.find(
            item => item.id == addonServiceId
          );
          if (currentAddOn) {
            currentAddOn.postingCodes.value.push({
              id: postingCode,
              text: `${postingCode} / ${glCode}`
            });
            currentAddOn.postingCodes.key = "";
          }
          newPostingCode.value.postingCode = "";
          componentData.value.controls.postingCodeDesc.value = "";
          glCodes.value.key = "";
          glCodes.value.value = [];
          glCodes.value.suggestTags = [];
          newPostingCode.value.modalVisible = false;
          newPostingCode.value.currentAddOn = {};
        } else {
          ApiHelper.showErrorMessage(
            result.message || `Can't create new Posting Code`
          );
        }
      }
    };

    // cost centers
    const onSuggestCostCenters = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        componentData.value.totalDataApplyTop = 0;
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/costcenters",
        {},
        {
          getAll: 1,
          order: 1,
          direction: 1,
          key,
          notInIds
        }
      );
      isACILoading.value = false;
      if (result.status === 1) {
        componentData.value.totalDataApplyTop = result.data.items.length;
        selectedItem.suggestTags = result.data.items.map((item: any) => {
          return {
            id: item.costCenterNumber,
            text: item.costCenterName || item.costCenterNumber,
            data: item
          };
        });
      }
    };

    const onRemoveLastCostCenter = (selectedItem: any) => {
      if (selectedItem.value.length > 0 && selectedItem.key === "") {
        const index = selectedItem.value.length - 1;
        selectedItem.value.splice(index, 1);
      }
    };
    const onRemoveCostCenter = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectCostCenter = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value = [];
      selectedItem.value.push({
        id: item.id,
        text: item.id
      });
      // context.emit("onSelectCostCenter", item);
    };

    const resetFormAddCostCenter = () => {
      newCostCenter.value.modalVisible = false;
      newCostCenter.value.isProcessing = false;
      componentData.value.controls.costCenterNumber.value = "";
      componentData.value.controls.costCenterName.value = "";
      componentData.value.controls.costCenterNumber.error = "";
    };

    const onAddNewCostCenter = async (
      key: string,
      selectedItem: any,
      index: number
    ) => {
      // Call from popup add new cost
      if (
        componentData.value.controls.costCenterNumber.value === "" &&
        key.trim() == ""
      ) {
        componentData.value.controls.costCenterNumber.error =
          "Number is required";
        newCostCenter.value.isProcessing = false;
        return;
      }
      if (key.trim() == "") return;

      if (!newCostCenter.value.isProcessing) {
        newCostCenter.value.modalVisible = true;
        newCostCenter.value.item = selectedItem;
        newCostCenter.value.index = index;
        // Handle name or number
        if (!isNaN(key as any)) {
          componentData.value.controls.costCenterNumber.value = key;
        } else {
          componentData.value.controls.costCenterName.value = key;
        }
      } else {
        // Add new cost center
        const costCenterRef: any = context.refs.costCenterRef;
        // auto selecting if cost center number existed in suggested items
        const existed = selectedItem.suggestTags.find(
          (item: any) => `${item.id}`.toLowerCase() == key.toLowerCase()
        );
        if (existed) {
          // auto select
          selectedItem.value.push({
            id: existed.id,
            text: existed.text
          });
          selectedItem.key = "";
          if (costCenterRef[index]) {
            costCenterRef[index].show = false;
          }
          context.emit("onSelectCostCenter", existed);

          return;
        }
        // call api add new cost center number
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/costcenters/0`,
          {
            number: componentData.value.controls.costCenterNumber.value,
            name: componentData.value.controls.costCenterName.value
          },
          {}
        );

        if (result.status == 1) {
          // Reset data after call api success
          resetFormAddCostCenter();
          // update cost centers for current addon
          selectedItem.value.push({
            id: key,
            text: key
          });
          selectedItem.key = "";
          if (costCenterRef[index]) {
            costCenterRef[index].show = false;
          }
          context.emit("onSelectCostCenter", {
            id: key,
            text: key
          });
        } else {
          // Reset button submit
          newCostCenter.value.isProcessing = false;
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
      }
    };

    const resetFirtfocus = () => {
      componentData.value.firstCost = true;
    };

    const onFocusCoscenter = (index: number, item: any) => {
      const eventCoscenterNumber: any = props.eventCoscenterNumber;
      if (
        eventCoscenterNumber.length &&
        item.value.length == 0 &&
        item.key == "" &&
        componentData.value.firstCost
      ) {
        // auto fill coscenter number
        // item.key = eventCoscenterNumber[0].id;
        // auto select
        componentData.value.firstCost = false;
        item.value = [
          {
            id: eventCoscenterNumber[0].id,
            text: eventCoscenterNumber[0].id
          }
        ];
      }
    };

    const changeFundBucket = (item: any) => {
      item.cost.error = "";
      if (item.isFundBucket.value) {
        item.cost.value = "";
      }
    };

    return {
      changeFundBucket,
      onSuggestTags,
      onAddTag,
      onRemoveLastTag,
      onRemoveTag,
      onSelectTag,
      foundPTypes,
      suggestPTypes,
      selectPType,
      changeDiscountAmount,
      changeDiscountPercent,
      loadData,
      addNewRow,
      doCancelItem,
      doSaveItem,
      doDeleteItem,
      doEditItem,
      componentData,
      stripTags: ApiHelper.stripTags,
      amountTypeSwitch,
      amountTypeInput,
      isACILoading,
      onSuggestPostingCodes,
      onRemoveLastPostingCode,
      onRemovePostingCodes,
      onSelectPostingCode,
      showNewPostingCodeModal,
      newPostingCode,
      newCostCenter,
      glCodes,
      onSuggestGLCode,
      onSelectGLCode,
      savePostingCode,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onRemoveCostCenter,
      onSelectCostCenter,
      onAddNewCostCenter,
      onFocusCoscenter,
      ApiHelper: ApiHelper,
      resetFirtfocus,
      resetFormAddCostCenter
    };
  },
  async mounted() {
    // init data
    await this.loadData();
  },
  methods: {
    hoverEnter(event: any) {
      if (event.clientY > 740 && this.componentData.totalDataApplyTop > 3) {
        this.componentData.allowTop = true;
      } else {
        this.componentData.allowTop = false;
      }
    },
    checkAllowAdd() {
      this.componentData.itemEdit.index = -1;
      this.$emit("button-added");
    },
    checkAllowEdit(index: number, item: any) {
      this.componentData.itemEdit.index = index;
      this.componentData.itemEdit.item = item;
      this.$emit("button-edited");
    },
    allowEdit() {
      this.doEditItem(
        this.componentData.itemEdit.index,
        this.componentData.itemEdit.item
      );
      this.componentData.itemEdit.index = -1;
      this.componentData.itemEdit.item = [];
    },
    checkAllowClose(index: number, item: any) {
      this.$emit("button-deleted");
      this.doCancelItem(index, item);
    },
    checkDoSaveItem(index: number, item: any) {
      this.$emit("button-deleted");
      this.doSaveItem(index, item);
    }
  }
});
