import { ref } from "@vue/composition-api";
import {
  EventNewInput,
  FormSelect,
  FormText,
  LinkEventParticipant,
  SelectOption
} from "@/types";
import { ApiHelper } from "@/helpers";
import { countOccurrences } from "@/helpers/ApiHelper";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import FormTags from "@/types/FormTags";
import Vue from "vue";

type FormData = {
  isLoading: boolean;
  controls: {
    name: FormText;
    tsStart: {
      label?: string;
      error: string;
      type: string;
      value: Date | undefined | string;
    };
    tsEnd: {
      label?: string;
      error: string;
      type: string;
      value: Date | undefined | string;
    };
    location: {
      label?: string;
      error: string;
      type: string;
      value: string;
    };
    address: FormText;
    city: FormText;
    state: FormSelect;
    zipcode: FormText;
    entity: {
      label?: string;
      error: string;
      type: string;
      options: SelectOption[];
      value: string;
    };
    participantTypes: {
      label?: string;
      error: string;
      type: string;
      options: SelectOption[];
      value: string[];
    };
    services: {
      label?: string;
      error: string;
      type: string;
      options: SelectOption[];
      value: string[];
    };
    applications: {
      label?: string;
      error: string;
      type: string;
      options: SelectOption[];
      value: string[];
    };
    costcenter: FormSelect;
    forcePayment: {
      error: string;
      label: string;
      value: boolean;
    };
    tags: FormTags;
    eventCostCenters: {
      error: string;
      label: string;
      textOverflow: boolean;
      placeholder: string;
      type: string;
      key: string;
      value: { id: string; text: string }[];
      options: any;
      suggestTags: any;
      isFirstFocus: boolean;
    };
    eventTypes: {
      error: string;
      label: string;
      textOverflow: boolean;
      placeholder: string;
      type: string;
      key: string;
      value: { id: string; text: string }[];
      options: any;
      suggestTags: any;
      isFirstFocus: boolean;
    };
  };
  foundLocations: object[];
  foundPTypes: object[];
  arrGenders: SelectOption[];
  eventIdCopy: number;
  copiedGroupItems: any;
};

export function useEventsAddStore(context: any) {
  const isACILoading = ref<boolean>(false);
  const linkEventParticipants = ref<LinkEventParticipant[]>([]);
  const linkDiscounts = ref<any[]>([]);
  const linkEventServices = ref<any[]>([]);
  const discountsStore = ref<{
    items: any[];
    amountTypeInput: string;
    allowAdd: boolean;
    isLoading: boolean;
    editingIndex: number;
  }>({
    items: [],
    amountTypeInput: "amount",
    allowAdd: true,
    isLoading: false,
    editingIndex: -1
  });
  const formInput = ref<EventNewInput>({
    name: "",
    tsStart: undefined,
    tsEnd: undefined,
    locationId: 0,
    location: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    participantTypes: [],
    entityId: 0,
    tags: []
  });

  const formData = ref<FormData>({
    isLoading: false,
    controls: {
      name: {
        label: "Event Name",
        placeholder: "Enter Event name",
        maxlength: 150,
        style: "custom",
        required: true,
        error: "",
        type: "text",
        value: ""
      },
      tsStart: {
        label: "Event Start Date",
        error: "",
        type: "date",
        value: ""
      },
      tsEnd: {
        label: "Event End Date",
        error: "",
        type: "date",
        value: ""
      },
      location: {
        error: "",
        type: "date",
        value: ""
      },
      address: {
        label: "Address",
        style: "custom",
        maxlength: 150,
        error: "",
        type: "date",
        value: ""
      },
      city: {
        label: "City",
        placeholder: "Enter City",
        style: "custom",
        maxlength: 150,
        error: "",
        type: "date",
        value: ""
      },
      state: {
        error: "",
        value: "",
        label: "State",
        placeholder: "Select...",
        style: "custom",
        options: ApiHelper.getStateOptions()
      },
      zipcode: {
        error: "",
        type: "date",
        value: ""
      },
      entity: {
        error: "",
        type: "date",
        options: [],
        value: "1"
      },
      participantTypes: {
        error: "",
        type: "multiselect",
        options: [],
        value: []
      },
      services: {
        error: "",
        type: "multiselect",
        options: [],
        value: []
      },
      applications: {
        error: "",
        type: "select2",
        options: [],
        value: []
      },
      costcenter: {
        notRequired: true,
        label: "Cost Center",
        placeholder: "Please choose cost center",
        style: "custom",
        error: "",
        type: "select",
        options: [],
        value: ""
      },
      forcePayment: {
        error: "",
        label: "Force Payment",
        value: true
      },
      tags: {
        error: "",
        label: "Tags",
        placeholder: "Enter a tag",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: []
      },
      eventCostCenters: {
        error: "",
        label: "Cost Center Number",
        textOverflow: true,
        placeholder: "Cost Center Number",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: [],
        isFirstFocus: true
      },
      eventTypes: {
        error: "",
        label: "Event Type",
        textOverflow: true,
        placeholder: "Event Type",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: [],
        isFirstFocus: true
      }
    },
    foundLocations: [],
    foundPTypes: [],
    arrGenders: ApiHelper.getGenderOptions(),
    eventIdCopy: 0,
    copiedGroupItems: []
  });

  const newPostingCode = ref<{
    modalVisible: boolean;
    postingCode: string;
    currentAddOn: any;
  }>({
    modalVisible: false,
    postingCode: "",
    currentAddOn: {}
  });
  const glCodes = ref<any>({
    required: true,
    error: "",
    label: "GL Code",
    placeholder: "Select or input a GL Code",
    type: "tags",
    key: "",
    value: [],
    options: [],
    suggestTags: []
  });
  const componentData = ref<{
    items: any[];
    // allowAdd: boolean;
    // isLoading: boolean;
    // editingIndex: number;
    controls: {
      postingCode: FormText;
      postingCodeDesc: FormText;
      glName: FormText;
    };
  }>({
    items: [],
    // allowAdd: true,
    // isLoading: false,
    // editingIndex: -1,
    controls: {
      postingCode: {
        required: true,
        label: "Posting Code",
        placeholder: "Posting Code",
        style: "custom",
        value: "",
        error: "",
        maxlength: 30
      },
      postingCodeDesc: {
        required: true,
        // label: "Description",
        label: "Posting Code Name",
        // placeholder: "Description",
        placeholder: "Posting Code Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 255
      },
      glName: {
        required: true,
        label: "GL Name",
        placeholder: "GL Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100
      }
    }
  });

  const popupCostCenter = ref<{
    show: boolean;
    key: string;
    callback: Function;
  }>({
    show: false,
    key: "",
    callback: (item: any) => {
      // nothing
    }
  });

  const popupEventType = ref<{
    show: boolean;
    key: string;
    callback: Function;
  }>({
    show: false,
    key: "",
    callback: (item: any) => {
      // nothing
    }
  });

  const getSelectPtypeCost = (ptypeUuid: string) => {
    let cost = 0;
    if (linkEventParticipants.value) {
      const findPtype: any = linkEventParticipants.value.find((item: any) => {
        return item.uuid.toString() === ptypeUuid.toString();
      });
      if (findPtype) {
        cost = findPtype.cost || 0;
      }
    }
    return cost;
  };

  const onSubmit = async (root: any) => {
    let hasError = false;
    let msgHasError = "";
    // reset
    formData.value.controls.participantTypes.error = "";
    formData.value.controls.services.error = "";
    formData.value.controls.tsStart.error = "";
    formData.value.controls.tsEnd.error = "";
    formData.value.controls.name.value = ApiHelper.stripTags(
      formData.value.controls.name.value
    );
    formData.value.controls.address.value = ApiHelper.stripTags(
      formData.value.controls.address.value
    );
    formData.value.controls.city.value = ApiHelper.stripTags(
      formData.value.controls.city.value
    );
    formData.value.controls.state.value = ApiHelper.stripTags(
      formData.value.controls.state.value
    );

    formData.value.controls.zipcode.value = ApiHelper.stripTags(
      formData.value.controls.zipcode.value
    );

    formInput.value.name = formData.value.controls.name.value;
    if (formData.value.controls.tsStart.value) {
      formInput.value.tsStart = moment(
        moment(formData.value.controls.tsStart.value).format("YYYY-MM-DD") +
          "T00:00:00+0000"
      ).toDate();
    } else {
      formInput.value.tsStart = undefined;
    }
    if (formData.value.controls.tsEnd.value) {
      formInput.value.tsEnd = moment(
        moment(formData.value.controls.tsEnd.value).format("YYYY-MM-DD") +
          "T23:59:59+0000"
      ).toDate();
    } else {
      formInput.value.tsEnd = undefined;
    }
    formInput.value.location = formData.value.controls.location.value;
    formInput.value.address = formData.value.controls.address.value;
    formInput.value.city = formData.value.controls.city.value;
    formInput.value.state = formData.value.controls.state.value;
    formInput.value.zipcode = formData.value.controls.zipcode.value;
    formInput.value.entityId = parseInt(formData.value.controls.entity.value);
    formInput.value.participantTypes = formData.value.controls.participantTypes.value.map(
      value => {
        return parseInt(value);
      }
    );

    formInput.value.tags = formData.value.controls.tags.value.map(tag => {
      return {
        id: parseInt(tag.id),
        text: tag.text
      };
    });

    if (formInput.value.name === "") {
      hasError = true;
      formData.value.controls.name.error = "Event name is required!";
    }
    if (
      formInput.value.tsStart !== undefined ||
      formInput.value.tsEnd !== undefined
    ) {
      if (!formInput.value.tsStart) {
        hasError = true;
        formData.value.controls.tsStart.error = "Start Date is required";
      }
      if (!formInput.value.tsEnd) {
        hasError = true;
        formData.value.controls.tsEnd.error = "End Date is required";
      }
    }
    if (
      formInput.value.tsStart !== undefined &&
      formInput.value.tsEnd !== undefined
    ) {
      if (formInput.value.tsStart > formInput.value.tsEnd) {
        hasError = true;
        formData.value.controls.tsEnd.error = "End Date is invalid";
      }
    }

    // validate participant types
    let pTypes = linkEventParticipants.value.filter(
      item => item.participantTypeName.trim() != ""
    );
    if (pTypes.length > 0) {
      // other validation for participant types
      // duplicate
      const pTypeNames = pTypes.map(item =>
        item.participantTypeName.toLowerCase()
      );
      for (const item of pTypes) {
        if (item.participantTypeName.trim() == "") continue;
        const cost =
          item && item.cost ? parseFloat(item.cost.toString()) || 0 : 0;
        // if (!(cost > 0)) {
        //   formData.value.controls.participantTypes.error =
        //     "Participant cost must greater than zero!";
        //   hasError = true;
        // }
        if (
          countOccurrences(
            pTypeNames,
            item.participantTypeName.toLocaleLowerCase()
          ) > 1
        ) {
          formData.value.controls.participantTypes.error =
            "Participant Type Name already exists. Please enter new information.";
          hasError = true;
          break;
        }
      }

      // capacity / capacity male / capacity female / cost
      if (!hasError) {
        pTypes.map((item: any) => {
          if (
            (item.capacity != "" && isNaN(item.capacity)) ||
            (item.capacityMale != "" && isNaN(item.capacityMale)) ||
            (item.capacityFemale != "" && isNaN(item.capacityFemale)) ||
            (item.cost != "" && isNaN(item.cost))
          ) {
            hasError = true;
          }
          item.participantTypeName = ApiHelper.stripTags(
            item.participantTypeName
          );
          return item;
        });
      }
    }

    // validate participant types
    let services = linkEventServices.value.filter(
      item => item.serviceName.trim() != ""
    );
    // other validation for services
    // duplicate
    const servicesError = "";

    const discountNames = await discountsStore.value.items
      .filter(item => {
        return item.name.value + "_" + item.ptype.value;
      })
      .map(item => item.name.value.toLowerCase() + "_" + item.ptype.value);

    const discountCodes = discountsStore.value.items
      .filter(item => {
        return item.code.value + "_" + item.ptype.value;
      })
      .map(item => item.code.value.toLowerCase() + "_" + item.ptype.value);
    discountsStore.value.editingIndex = -1;
    discountsStore.value.items.map((item: any) => {
      item.isEditing = false;
      item.name.error = "";
      item.code.error = "";
      item.discountAmount.error = "";
      item.discountPercent.error = "";
      item.availableDate.error = "";
      item.active.error = "";
      item.ptype.error = "";
      item.maxUse.error = "";

      if (item.name.value === "") {
        item.name.error = "Name is required";
        hasError = true;
      }
      if (
        countOccurrences(
          discountNames,
          item.name.value.toLocaleLowerCase() + "_" + item.ptype.value
        ) > 1
      ) {
        item.name.error =
          "Discount Name already exists. Please enter new information.";
        hasError = true;
        msgHasError =
          "Discount Name already exists. Please enter new information.";
      }
      // if (item.availableDate.value === "") {
      //   item.availableDate.error = "Date is required";
      //   hasError = true;
      // }
      if (item.availableDate.value) {
        const selectedData = moment(item.availableDate.value).format(
          "YYYY-MM-DD"
        );
        const now = moment().format("YYYY-MM-DD");
        if (!(selectedData > now)) {
          item.availableDate.error = "Date is invalid";
          hasError = true;
          msgHasError +=
            item.name.value + "' The available until date is invalid. ";
        }
      }
      if (
        countOccurrences(
          discountCodes,
          item.code.value.toLocaleLowerCase() + "_" + item.ptype.value
        ) > 1
      ) {
        item.code.error =
          "Discount Code already exists. Please enter new information.";
        hasError = true;
        msgHasError +=
          "Discount Code already exists. Please enter new information.";
      }

      // if (item.code.value === "") {
      //   item.code.error = "Code is required";
      //   hasError = true;
      // }
      const discountType = Number.parseInt(item.discountType.value, 10) || 0;
      const discountAmount = Number.parseFloat(item.discountAmount.value) || 0;
      const discountPercent =
        Number.parseFloat(item.discountPercent.value) || 0;

      if (discountsStore.value.amountTypeInput === "amount") {
        const ptypeCost = getSelectPtypeCost(item.ptype.value);
        if (discountAmount === 0) {
          item.discountAmount.error = "Discount Amount must greater than 0";
          hasError = true;
        }
        if (discountAmount > ptypeCost) {
          item.discountAmount.error =
            "Discount Amount must less than " +
            ApiHelper.dollarFormat(ptypeCost);
          hasError = true;
          msgHasError +=
            item.name.value +
            "' Discount Amount must less than " +
            ApiHelper.dollarFormat(ptypeCost) +
            ".";
        }
      }
      if (discountsStore.value.amountTypeInput === "rate") {
        if (discountPercent == 0) {
          item.discountPercent.error = "Discount Percent must greater than 0";
          hasError = true;
        }
        if (discountPercent > 100) {
          item.discountPercent.error = "Discount Percent must less than 100%";
          hasError = true;
        }
      }
      const ptypeUuid = item.ptype.value;
      if (!ptypeUuid) {
        item.ptype.error = "Type is required";
        hasError = true;
      }
    });

    const group: any = context.refs.eventGroupsRef;
    let groupItems = [];
    if (group) {
      groupItems = (group.componentData.items || []).filter(
        (t: any) =>
          t.name.value != "" && t.ptype.value != "" && t.ptype.value != "0"
      );
    }

    if (!hasError) {
      if (servicesError !== "") {
        ApiHelper.showErrorMessage(servicesError, "Oops");
        return false;
      }
      formData.value.isLoading = true;

      const discounts = discountsStore.value.items.map(item => {
        const maxUse = Number.parseFloat(item.maxUse.value) || 0;
        const discountType = Number.parseInt(item.discountType.value, 10) || 0;
        const discountAmount =
          Number.parseFloat(item.discountAmount.value) || 0;
        const discountPercent =
          Number.parseFloat(item.discountPercent.value) || 0;

        // auto select cost center
        if (
          item.costCenters.value.length == 0 &&
          item.costCenters.key != "" &&
          item.costCenters.suggestTags.length
        ) {
          const related = item.costCenters.suggestTags.find(
            (t: any) => t.id.toLowerCase() == item.costCenters.key.toLowerCase()
          );
          if (related) {
            item.costCenters.value = [
              {
                id: related.id,
                text: related.text
              }
            ];
          }
        }

        return {
          discountName: item.name.value,
          discountCode: item.code.value,
          discountAmount: discountAmount,
          discountType: discountType,
          discountPercent: discountPercent,
          availableToDate: item.availableDate.value
            ? moment(
                moment(item.availableDate.value).format("YYYY-MM-DD") +
                  "T00:00:00.000Z"
              ).toDate()
            : "",
          active: item.active.value ? 1 : 0,
          maxUse: maxUse,
          ptypeUuid: item.ptype.value,
          tags: item.tags.value,
          postingCode: item.postingCodes.value.map((t: any) => t.id).join(","),
          costCenterNumber: item.costCenters.value
            .map((t: any) => t.id)
            .join(","),
          transactionType: parseInt(item.transactionType.value || 1)
        };
      });
      services = services.map((item: any) => {
        // auto select cost center
        if (
          item.costCenters.value.length == 0 &&
          item.costCenters.key != "" &&
          item.costCenters.suggestTags.length
        ) {
          const related = item.costCenters.suggestTags.find(
            (t: any) => t.id.toLowerCase() == item.costCenters.key.toLowerCase()
          );
          if (related) {
            item.costCenters.value = [
              {
                id: related.id,
                text: related.text
              }
            ];
          }
        }

        item.availableToDate = item.availableDate.value
          ? moment(
              moment(item.availableDate.value).format("YYYY-MM-DD") +
                "T00:00:00.000Z"
            ).toDate()
          : "";

        return item;
      });
      pTypes = pTypes.map((item: any) => {
        // auto select cost center
        if (
          item.costCenters.value.length == 0 &&
          item.costCenters.key != "" &&
          item.costCenters.suggestTags.length
        ) {
          const related = item.costCenters.suggestTags.find(
            (t: any) => t.id.toLowerCase() == item.costCenters.key.toLowerCase()
          );
          if (related) {
            item.costCenters.value = [
              {
                id: related.id,
                text: related.text
              }
            ];
          }
        }
        return item;
      });
      const result = await ApiHelper.callApi("post", "/events", {
        name: formInput.value.name,
        tsStart: formInput.value.tsStart,
        tsEnd: formInput.value.tsEnd,
        entityId: formInput.value.entityId,
        locationId: formInput.value.locationId,
        locationName: formInput.value.location,
        address: formInput.value.address,
        city: formInput.value.city,
        state: formInput.value.state,
        zipcode: formInput.value.zipcode,
        pTypes: pTypes.length ? JSON.stringify(pTypes) : "",
        discounts: discounts.length ? JSON.stringify(discounts) : "",
        addons: services.length ? JSON.stringify(services) : "",
        forcepayment: formData.value.controls.forcePayment.value ? 1 : 0,
        costcenterId: 0,
        tags: formInput.value.tags.map(tag => {
          return {
            id: tag.id,
            text: tag.text
          };
        }),
        costCenterNumber: formData.value.controls.eventCostCenters.value
          .map((t: any) => t.id)
          .join(","),
        eventTypeId:
          parseInt(formData.value.controls.eventTypes.value[0]?.id) || 0,
        groupItems: groupItems.length ? JSON.stringify(groupItems) : ""
      });
      formData.value.isLoading = false;
      if (result.status == 1) {
        const resultData = result.data || {};
        const newEventId = resultData.id || 0;
        ApiHelper.gotoPage(context, {
          name: "EventDashboard",
          params: {
            eventId: newEventId.toString()
          }
        });

        // update userStats
        const children = root.$children || [];
        const layout = children.length > 0 ? children[0] : undefined;
        if (layout && layout.$children.length > 0) {
          layout.$children[0].totalEvents = layout.$children[0].totalEvents + 1;
        }
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    } else {
      formData.value.isLoading = false;
      ApiHelper.showErrorMessage(
        msgHasError + " Please enter new information",
        "Oops"
      );
    }
  };

  const removeFieldError = (name: string) => {
    switch (name) {
      case "name":
        formData.value.controls.name.error = "";
        break;
      case "tsStart":
        formData.value.controls.tsStart.error = "";
        break;
      case "tsEnd":
        formData.value.controls.tsEnd.error = "";
        break;
      case "location":
        formData.value.controls.location.error = "";
        break;
      case "address":
        formData.value.controls.address.error = "";
        break;
      case "city":
        formData.value.controls.city.error = "";
        break;
      case "state":
        formData.value.controls.state.error = "";
        break;
      case "zipcode":
        formData.value.controls.zipcode.error = "";
        break;
      case "entity":
        formData.value.controls.entity.error = "";
        break;
      case "participantTypes":
        formData.value.controls.participantTypes.error = "";
        break;
    }
  };
  // const showLinkParticipant = (value: string) => {
  //   return formData.value.controls.participantTypes.value.includes(value);
  // };

  const suggestLocation = async (key: string) => {
    formData.value.controls.location.value = key;

    isACILoading.value = true;
    const result = await ApiHelper.callApi(
      "get",
      "/locations/search",
      {},
      {
        key: key
      }
    );
    if (result.status === 1) {
      formData.value.foundLocations = result.data.locations.map(
        (item: any) => ({
          id: item.id,
          text: item.name,
          data: item
        })
      );
      formInput.value.locationId = 0;
    }
    isACILoading.value = false;
  };

  const updateLocation = (data: any, type = "add") => {
    formInput.value.locationId = data.id || 0;
    formData.value.controls.location.value = data.name;
    formData.value.controls.address.value = data.address;
    formData.value.controls.city.value = data.city;
    formData.value.controls.state.value = data.state;
    formData.value.controls.zipcode.value = data.zipcode;

    // auto suggest cost center number
    const costCenterNumber = data.costCenterNumber || "";
    if (costCenterNumber != "") {
      formData.value.controls.eventCostCenters.value = [
        {
          id: costCenterNumber,
          text: costCenterNumber
        }
      ];
    }
  };

  const getPTypesHtml = (item: any) => {
    let ret = `${item.name}`;
    if (item.capacity || item.typeages || item.gender) {
      const moreInfo = [];
      if (item.capacity) {
        moreInfo.push(`Capacity: ${item.capacity}`);
      }
      if (item.typeages) {
        moreInfo.push(`Age: ${item.typeages}`);
      }
      if (item.gender) {
        moreInfo.push(`Gender: ${ApiHelper.getGenderName(item.gender)}`);
      }
      ret = `${item.name} - (${moreInfo.join(", ")})`;
    } else {
      ret = `${item.name}`;
    }

    return ret;
  };

  const initCustomFields = (item?: any): object[] => {
    let cf: any = [];
    if (item && item.cfIds && item.cfIds != "") {
      const cfIds = item.cfIds.split("---");
      const cfNames = item.cfNames.split("---");
      const cfValues = item.cfValues.split("---");
      if (cfIds.length && cfNames.length && cfValues.length) {
        for (const i in cfIds) {
          cf.push({
            id: cfIds[i],
            title: cfNames[i],
            value: cfValues[i],
            show: true
          });
        }
      }
    } else {
      if (linkEventParticipants.value[0]) {
        cf = linkEventParticipants.value[0].customFields || [];
        if (cf.length) {
          cf = cf.map((item: any) => ({
            ...item,
            id: 0,
            value: "",
            show: true
          }));

          return cf;
        }
      }
    }

    return cf;
  };

  const addPTypeRow = () => {
    linkEventParticipants.value.push({
      uuid: uuidv4(),
      id: 0,
      eventId: 0,
      participantTypeName: "",
      participantTypeId: 0,
      capacity: "",
      capacityMale: "",
      capacityFemale: "",
      cost: 0,
      gender: 0,
      age: "",
      active: 0,
      customFields: initCustomFields(),
      showRemoveCntDown: false,
      cntDown: 3,
      error: false,
      tags: {
        error: "",
        label: "",
        placeholder: "Enter a tag",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: []
      },
      postingCodes: {
        error: "",
        label: "",
        textOverflow: true,
        placeholder: "Posting / GL Code",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: []
      },
      costCenters: {
        error: "",
        label: "",
        textOverflow: true,
        placeholder: "Cost Center Number",
        type: "tags",
        key: "",
        firstFocus: true,
        value: [],
        options: [],
        suggestTags: []
      }
    });
  };

  const suggestPTypes = async (key: string, item: any, index: number) => {
    // reset
    formData.value.foundPTypes = [];
    item.participantTypeName = key;

    // add new row once they get down to the last row
    if (index == linkEventParticipants.value.length - 1) {
      addPTypeRow();
    }

    if (key !== "") {
      // searching
      item.id = -1;

      const selectedPTypeNames = linkEventParticipants.value
        .filter(item => item.participantTypeName != "" && item.id != -1)
        .map(item => item.participantTypeName);
      isACILoading.value = true;
      try {
        const result = await ApiHelper.callApi(
          "get",
          "/participants/ptypes/search",
          {},
          {
            key: key,
            take: 25,
            selectedNames: selectedPTypeNames.length
              ? JSON.stringify(selectedPTypeNames)
              : ""
          }
        );
        if (result.status === 1) {
          formData.value.foundPTypes = result.data.ptypes.map((item: any) => ({
            id: item.id,
            text: item.name,
            html: getPTypesHtml(item),
            data: item
          }));
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        item.id = 0;
        isACILoading.value = false;
      }
    }
  };

  const selectPType = (data: any, item: any) => {
    item.id = data.id || 0;
    item.participantTypeId = data.id || 0;
    item.participantTypeName = data.name || "";
    item.capacity = data.capacity || "";
    item.capacityMale = data.capacityMale || "";
    item.capacityFemale = data.capacityFemale || "";
    item.gender = data.gender || 0;
    item.cost = data.cost || 0;
    item.age = data.typeages || "";

    const postingCode = data.postingCode || "";
    const glCode = data.glCode || "";
    const costCenterNumber = data.costCenterNumber || "";
    item.postingCodes.value = [];
    if (postingCode != "" && glCode != "") {
      item.postingCodes.value = [
        {
          id: postingCode,
          text: `${postingCode} / ${glCode}`
        }
      ];
    }
    item.costCenters.value = [];
    if (costCenterNumber != "") {
      item.costCenters.value = [
        {
          id: costCenterNumber,
          text: costCenterNumber
        }
      ];
    }
  };

  const removePTypes = async (pType: any) => {
    const newLines = linkEventParticipants.value.filter(
      (item: any) => item.uuid != pType.uuid
    );
    linkEventParticipants.value = newLines;
  };

  const popupNewPTypeCol = ref<{
    isProcessing: boolean;
    show: boolean;
    newColName: string;
    newColNameError: string;
    removeFieldError: any;
    errMessage: string;
    currentCFList: any;
    selectedCF: any;
    showModal: any;
    onSubmit: any;
    updateSelectedCF: any;
    showRemoveCntDown: any;
    delayCountDown: any;
    cancelRemovePType: any;
  }>({
    isProcessing: false,
    show: false,
    newColName: "",
    newColNameError: "",
    removeFieldError: (name: string) => {
      popupNewPTypeCol.value.errMessage = "";
    },
    errMessage: "",
    currentCFList: [],
    selectedCF: [],
    showModal: () => {
      popupNewPTypeCol.value.newColName = "";
      popupNewPTypeCol.value.show = true;
    },
    onSubmit: async () => {
      // validate
      let hasError = false;
      if (popupNewPTypeCol.value.newColName == "") {
        hasError = true;
      }

      if (!hasError) {
        popupNewPTypeCol.value.isProcessing = true;
        // check existed
        const tmp = linkEventParticipants.value[0].customFields || [];
        const existedCF = tmp.find(
          (item: any) =>
            item.title.toLowerCase() ==
            popupNewPTypeCol.value.newColName.toLowerCase()
        );
        if (!tmp.length || !existedCF) {
          // persist a custom field
          const result = await ApiHelper.callApi(
            "post",
            "/events/ptypeCustomField",
            {
              name: popupNewPTypeCol.value.newColName,
              type: 1
            }
          );
          if (result.status !== 1) {
            popupNewPTypeCol.value.show = false;
            popupNewPTypeCol.value.isProcessing = false;
            ApiHelper.showErrorMessage(result.message, "Oops");
            return;
          }

          // add new column
          for (const item of linkEventParticipants.value) {
            const cf = [...item.customFields];
            cf?.push({
              id: result.data.id,
              title: popupNewPTypeCol.value.newColName,
              value: "",
              show: true
            });
            item.customFields = cf;
          }

          // update current custom fields list
          const existed = popupNewPTypeCol.value.currentCFList.find(
            (item: any) =>
              item.cfName.toLowerCase() ==
              popupNewPTypeCol.value.newColName.toLowerCase()
          );
          if (!existed) {
            popupNewPTypeCol.value.currentCFList.push({
              cfId: result.data.id,
              cfName: popupNewPTypeCol.value.newColName
            });
            popupNewPTypeCol.value.selectedCF.push(result.data.id);
          }
          popupNewPTypeCol.value.show = false;
          popupNewPTypeCol.value.isProcessing = false;
        } else {
          popupNewPTypeCol.value.isProcessing = false;
          popupNewPTypeCol.value.newColNameError =
            "Column Name already exists.";
        }
      }
    },
    updateSelectedCF: () => {
      const selectedTitles = popupNewPTypeCol.value.currentCFList
        .filter((item: any) =>
          popupNewPTypeCol.value.selectedCF.includes(item.cfId)
        )
        .map((t: any) => t.cfName.toLowerCase());
      for (const item of linkEventParticipants.value) {
        item.customFields = item.customFields.map((cf: any) => ({
          ...cf,
          show: selectedTitles.includes(cf.title.toLowerCase()) ? true : false
        }));
      }
    },
    showRemoveCntDown: (item: any) => {
      item.showRemoveCntDown = true;
      item.cntDown = 3;
      popupNewPTypeCol.value.delayCountDown(item);
    },
    delayCountDown: (item: any) => {
      item.removeTimer = setTimeout(async () => {
        item.cntDown--;
        if (item.cntDown > 0) {
          popupNewPTypeCol.value.delayCountDown(item);
        } else {
          await removePTypes(item);
          for (const ptype of linkEventParticipants.value) {
            const aci = context.refs[`aci-${ptype.uuid}`];
            if (typeof aci[0] != "undefined") {
              aci[0].key = ptype.participantTypeName;
              aci[0].$forceUpdate();
            }
          }
          // add more row?
          if (linkEventParticipants.value.length == 0) {
            addPTypeRow();
          } else {
            const lastRow =
              linkEventParticipants.value[
                linkEventParticipants.value.length - 1
              ];
            if (lastRow.participantTypeName != "") {
              addPTypeRow();
            }
          }
        }
      }, 1000);
    },
    cancelRemovePType: (item: any) => {
      clearTimeout(item.removeTimer);
      item.showRemoveCntDown = false;
      item.cntDown = 3;
    }
  });

  // auto fill for capacity
  const capacityInput = (column: string, item: any) => {
    try {
      const capacity = parseInt(item.capacity) || 0;
      const capacityMale = parseInt(item.capacityMale) || 0;
      const capacityFemale = parseInt(item.capacityFemale) || 0;
      if (
        !isNaN(capacityMale) &&
        capacityMale > 0 &&
        !isNaN(capacityFemale) &&
        capacityFemale > 0
      ) {
        if (column == "capacityMale" || column == "capacityFemale") {
          if (capacityMale && capacityFemale) {
            item.capacity = capacityFemale + capacityMale || "";
          }
        }
        // if (column == "capacity") {
        //   item.capacity = capacity || "";
        //   item.capacityMale = "";
        //   item.capacityFemale = "";
        // }
        context.root.$forceUpdate();
      } else {
        if (column == "capacityMale") {
          if (capacityMale >= capacity) {
            item.capacityFemale = "0";
            item.capacity = capacityMale;
          }
          // item.capacityMale = capacityMale || "";
          // if (capacity === 0) {
          //   item.capacity = capacityFemale + capacityMale || "";
          // } else if (capacity < capacityMale + capacityFemale) {
          //   item.capacity = capacityMale + capacityFemale || "";
          // }
        }
        if (column == "capacityFemale") {
          if (capacityFemale >= capacity) {
            item.capacityMale = "0";
            item.capacity = capacityFemale;
          }
          //   item.capacityFemale = capacityFemale || "";
          // }
          // if (column == "capacity") {
          //   item.capacity = capacity || "";
          //   item.capacityMale = "";
          //   item.capacityFemale = "";
        }
      }
      context.root.$forceUpdate();
    } catch (error) {
      console.log(error);
    }
  };

  // cost centers
  const onSuggestCostCenters = async (key: string) => {
    formData.value.controls.eventCostCenters.isFirstFocus = true;
    const costCenters = formData.value.controls.eventCostCenters;
    const notInIds = costCenters.value.map((item: any) => item.id).join(",");
    const result = await ApiHelper.callApi(
      "get",
      "/accounting/costcenters",
      {},
      {
        getAll: 1,
        order: 1,
        direction: 1,
        key,
        notInIds
      }
    );
    if (result.status === 1) {
      costCenters.suggestTags = result.data.items.map((item: any) => {
        return {
          id: item.costCenterNumber,
          text: item.costCenterName || item.costCenterNumber,
          data: item
        };
      });
    }
  };

  const onRemoveLastCostCenter = () => {
    const costCenters = formData.value.controls.eventCostCenters;
    if (costCenters.value.length > 0 && costCenters.key === "") {
      const index = costCenters.value.length - 1;
      costCenters.value.splice(index, 1);
    }
  };
  const onRemoveCostCenter = (index: number) => {
    const costCenters = formData.value.controls.eventCostCenters;
    if (costCenters.value.length > index) {
      costCenters.value.splice(index, 1);
    }
  };

  const onSelectCostCenter = (item: any) => {
    const costCenters = formData.value.controls.eventCostCenters;
    costCenters.key = "";
    costCenters.suggestTags = [];
    costCenters.value.push({
      id: item.id,
      text: item.id
    });
  };

  const onAddNewCostCenter = async (key: string) => {
    if (key.trim() == "") return;
    popupCostCenter.value.key = key;
    popupCostCenter.value.show = true;
    return;
    const costCenterRef: any = context.refs.eventCostCenterRef;
    const costCenters = formData.value.controls.eventCostCenters;

    // auto selecting if cost center number existed in suggested items
    const existed = costCenters.suggestTags.find(
      (item: any) => `${item.id}`.toLowerCase() == key.toLowerCase()
    );
    if (existed) {
      // auto select
      costCenters.value.push({
        id: existed.id,
        text: existed.text
      });
      costCenters.key = "";
      if (costCenterRef) {
        costCenterRef.show = false;
      }

      return;
    }

    // add new cost center number
    const result = await ApiHelper.callApi(
      "put",
      `/accounting/costcenters/0`,
      {
        number: key
      },
      {}
    );
    if (result.status == 1) {
      // update cost centers for current addon
      costCenters.value.push({
        id: key,
        text: key
      });
      costCenters.key = "";
      if (costCenterRef) {
        costCenterRef.show = false;
      }
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      return;
    }
  };

  const updateDiscounts = (discounts: any[]) => {
    linkDiscounts.value = discounts;
  };

  const allowDeletePtype = (item: any) => {
    if (item.participantTypeName) {
      const foundDiscount = discountsStore.value.items.find((discount: any) => {
        return discount.ptype.value == item.uuid;
      });
      if (foundDiscount) {
        return false;
      }
    }
    return true;
  };

  const onSuggestTags = async (key: string) => {
    const notInIds = formData.value.controls.tags.value
      .map(item => item.id)
      .join(",");
    const result = await ApiHelper.callApi(
      "get",
      "/tags/search",
      {},
      {
        // typeUse: "finance",
        key: key,
        notInIds: notInIds
      }
    );
    if (result?.status === 1) {
      formData.value.controls.tags.suggestTags = result.data.tags.map(
        (item: any) => {
          return {
            id: item.id,
            text: item.text,
            data: item
          };
        }
      );
    }
  };

  const onAddTag = () => {
    const key = formData.value.controls.tags.key.trim();
    if (key) {
      formData.value.controls.tags.value.push({
        id: 0,
        text: key,
        data: {}
      });
      formData.value.controls.tags.key = "";
    }
  };

  const onRemoveLastTag = (event: any) => {
    if (
      formData.value.controls.tags.value.length > 0 &&
      formData.value.controls.tags.key === ""
    ) {
      const index = formData.value.controls.tags.value.length - 1;
      formData.value.controls.tags.value.splice(index, 1);
    }
  };
  const onRemoveTag = (index: number) => {
    if (formData.value.controls.tags.value.length > index) {
      formData.value.controls.tags.value.splice(index, 1);
    }
  };

  const onSelectTag = (item: any) => {
    formData.value.controls.tags.key = "";
    formData.value.controls.tags.suggestTags = [];
    formData.value.controls.tags.value.push({
      id: item.id,
      text: item.text,
      data: item.data || {}
    });
  };

  const copyEventSettings = (eventData: any) => {
    formData.value.isLoading = true;
    formData.value.eventIdCopy = eventData.id;
    formData.value.controls.name.value = eventData.name + " (Copy)";

    // Remove error
    removeFieldError("name");
    formData.value.controls.location.value = ApiHelper.stripTags(
      eventData.locationName || ""
    );
    formData.value.controls.address.value = ApiHelper.stripTags(
      eventData.address || ""
    );
    formData.value.controls.city.value = ApiHelper.stripTags(
      eventData.city || ""
    );
    formData.value.controls.state.value = ApiHelper.stripTags(
      eventData.state || ""
    );
    formData.value.controls.zipcode.value = ApiHelper.stripTags(
      eventData.zipcode || ""
    );
    formData.value.controls.tsStart.value = eventData.tsStartFormatted
      ? moment(eventData.tsStartFormatted).toDate()
      : undefined;
    formData.value.controls.tsEnd.value = eventData.tsEndFormatted
      ? moment(eventData.tsEndFormatted).toDate()
      : undefined;

    formData.value.controls.forcePayment.value = !!eventData.forcepayment;
    formData.value.controls.costcenter.value = eventData.costcenterId || "";

    formData.value.controls.tags.options = eventData.tags.map((item: any) => {
      return {
        id: item.id,
        text: item.name || "",
        data: item
      };
    });

    formData.value.controls.tags.value = eventData.tags.map((item: any) => {
      return {
        id: item.id,
        text: item.name || "",
        data: item
      };
    });

    // cost center number
    const costCenterNumber = eventData.costCenterNumber || "";
    if (costCenterNumber) {
      formData.value.controls.eventCostCenters.value = [
        {
          id: costCenterNumber,
          text: costCenterNumber
        }
      ];
    }

    // event type
    const eventType = eventData.eventType || null;
    if ((eventType?.eventTypeId || 0) > 0) {
      let itemText = eventType.eventTypeName;
      if (eventType.costCenterNumber) {
        itemText += ` - ${eventType.costCenterNumber}`;
      }
      formData.value.controls.eventTypes.value = [
        {
          id: eventType.eventTypeId,
          text: itemText
        }
      ];
    }

    // current participant type custom fields are using
    popupNewPTypeCol.value.currentCFList = JSON.parse(
      eventData.pTypesCFJSON || "[]"
    );
    popupNewPTypeCol.value.selectedCF = popupNewPTypeCol.value.currentCFList.map(
      (item: any) => item.cfId
    );
    linkEventParticipants.value = [];
    eventData.ptypes.map((item: any, key: number) => {
      const uuid = item.id || uuidv4();
      linkEventParticipants.value.push({
        uuid: uuid,
        id: item.participantTypeId,
        eventId: 0,
        applications: item.applications,
        participantTypeName: item.participantTypeName,
        participantTypeId: item.participantTypeId,
        capacity: item.capacity,
        capacityMale: item.capacityMale,
        capacityFemale: item.capacityFemale,
        cost: item.cost,
        gender: item.gender,
        age: item.age,
        active: 1,
        customFields: initCustomFields(item),
        showRemoveCntDown: false,
        cntDown: 3,
        error: false,
        tags: {
          error: "",
          label: "",
          placeholder: "Enter a tag",
          type: "tags",
          key: "",
          value: [],
          options: [],
          suggestTags: []
        },
        postingCodes: {
          error: "",
          label: "",
          placeholder: "Posting / GL Code",
          type: "tags",
          key: "",
          value: item.postingCode
            ? [
                {
                  id: item.postingCode,
                  text: `${item.postingCode} / ${item.glCode}`
                }
              ]
            : [],
          options: [],
          suggestTags: []
        },
        costCenters: {
          error: "",
          label: "",
          placeholder: "Cost Center Number",
          type: "tags",
          key: "",
          value: item.costCenterNumber
            ? [
                {
                  id: item.costCenterNumber,
                  text: item.costCenterNumber
                }
              ]
            : [],
          options: [],
          suggestTags: []
        }
      });
    });
    // linkEventParticipants.value.push({
    //   uuid: uuidv4(),
    //   id: 0,
    //   eventId: 0,
    //   participantTypeName: "",
    //   participantTypeId: 0,
    //   capacity: "",
    //   capacityMale: "",
    //   capacityFemale: "",
    //   cost: 0,
    //   gender: 0,
    //   age: "",
    //   active: 0,
    //   customFields: initCustomFields(),
    //   showRemoveCntDown: false,
    //   cntDown: 3,
    //   error: false,
    //   tags: {
    //     error: "",
    //     label: "",
    //     placeholder: "Enter a tag",
    //     type: "tags",
    //     key: "",
    //     value: [],
    //     options: [],
    //     suggestTags: []
    //   },
    //   postingCodes: {
    //     error: "",
    //     label: "",
    //     textOverflow: true,
    //     placeholder: "Posting / GL Code",
    //     type: "tags",
    //     key: "",
    //     value: [],
    //     options: [],
    //     suggestTags: []
    //   },
    //   costCenters: {
    //     error: "",
    //     label: "",
    //     textOverflow: true,
    //     placeholder: "Cost Center Number",
    //     type: "tags",
    //     key: "",
    //     firstFocus: true,
    //     value: [],
    //     options: [],
    //     suggestTags: []
    //   }
    // });

    discountsStore.value.items = [];
    linkEventServices.value = eventData.services.map((item: any) => {
      const tags = item.tags || [];
      const costCenters = item.costCenterNumber ? [item.costCenterNumber] : [];
      const availableToDate = item.availableToDateFormatted
        ? moment(item.availableToDateFormatted).toDate()
        : undefined;
      return {
        uuid: uuidv4(),
        id: 0,
        eventId: 0,
        addonServiceId: item.addonServiceId,
        serviceName: item.serviceName,
        serviceDesc: item.serviceDesc,
        serviceCost: item.serviceCost,
        linkAddonCost: item.serviceCost,
        costcenter: item.costcenterId,
        isFundBucket: !!item.isFundBucket,
        chargeMonthly: item.chargeMonthly || false,
        active: 1,
        showRemoveCntDown: false,
        totalParticipants: 0,
        cntDown: 3,
        error: false,
        tags: {
          error: "",
          label: "",
          placeholder: "Enter a tag",
          type: "tags",
          key: "",
          value: tags.map((tag: any) => {
            return {
              id: tag.id,
              text: tag.name || "",
              data: tag
            };
          }),
          options: [],
          suggestTags: []
        },
        postingCodes: {
          error: "",
          label: "",
          textOverflow: true,
          placeholder: "Posting / GL Code",
          type: "tags",
          key: "",
          value: item.postingCode
            ? [
                {
                  id: item.postingCode,
                  text: `${item.postingCode} / ${item.glCode}`
                }
              ]
            : [],
          options: [],
          suggestTags: []
        },
        costCenters: {
          error: "",
          label: "",
          textOverflow: true,
          placeholder: "Cost Center Number",
          type: "tags",
          key: "",
          firstFocus: true,
          value: costCenters.map((code: any) => {
            return {
              id: code,
              text: code
            };
          }),
          options: [],
          suggestTags: []
        },
        availableDate: {
          value: availableToDate,
          error: ""
        }
      };
    });
    discountsStore.value.allowAdd = false;
    eventData.discounts.map((item: any) => {
      // availableFromDateFormatted: (...)
      // availableToDate: (...)
      // availableToDateFormatted: (...)
      // costcenterId: (...)

      const tags = item.tags || [];
      const availableToDate = item.availableToDateFormatted || undefined;

      const refPtype = linkEventParticipants.value.find((ptype: any) => {
        return ptype.id === item.participantTypeId;
      });

      discountsStore.value.items.push({
        uuid: uuidv4(),
        id: 0,
        name: {
          value: item.discountName,
          error: ""
        },
        maxUse: {
          value: item.maxUse,
          error: ""
        },
        discountType: {
          value: item.discountType,
          error: ""
        },
        discountAmount: {
          value: item.discountAmount,
          error: ""
        },
        discountPercent: {
          value: item.discountPercent,
          error: ""
        },
        code: {
          value: item.discountCode,
          error: ""
        },
        availableDate: {
          value: availableToDate,
          error: ""
        },
        ptype: {
          value: refPtype ? refPtype.uuid : "",
          id: item.participantTypeId,
          error: ""
        },
        active: {
          value: item.isActive,
          error: ""
        },
        tags: {
          error: "",
          label: "",
          textOverflow: true,
          placeholder: "Enter a tag",
          type: "tags",
          key: "",
          value: tags.map((tag: any) => {
            return {
              id: tag.id,
              text: tag.name || "",
              data: tag
            };
          }),
          options: [],
          suggestTags: []
        },
        postingCodes: {
          error: "",
          label: "",
          textOverflow: true,
          placeholder: "Posting / GL Code",
          type: "tags",
          key: "",
          value: item.postingCode
            ? [
                {
                  id: item.postingCode,
                  text: `${item.postingCode} / ${item.glCode}`
                }
              ]
            : [],
          options: [],
          suggestTags: []
        },
        costCenters: {
          error: "",
          label: "",
          textOverflow: true,
          placeholder: "Cost Center Number",
          type: "tags",
          key: "",
          firstFocus: true,
          value: item.costCenterNumber
            ? [
                {
                  id: item.costCenterNumber,
                  text: item.costCenterNumber
                }
              ]
            : [],
          options: [],
          suggestTags: []
        },
        totalUsed: 0,
        isDeleted: false,
        isEditing: false,
        transactionType: {
          value: item.transactionType,
          error: ""
        }
      });
    });
    discountsStore.value.editingIndex = -1;
    formData.value.copiedGroupItems = eventData.groupItems || [];

    setTimeout(() => {
      formData.value.isLoading = false;
    }, 500);
  };

  const updatePtypesList = (ptypesList: any) => {
    linkEventParticipants.value = [];
    for (const item of ptypesList) {
      linkEventParticipants.value.push({
        uuid: item.uuid,
        id: item.id == -1 ? 0 : item.id,
        linkId: item.linkId || 0,
        eventId: 0,
        participantTypeName: item.name.value || "",
        participantTypeId: item.participantTypeId || 0,
        capacity: item.capacity.value,
        capacityMale: item.capacityMale.value,
        capacityFemale: item.capacityFemale.value,
        cost: item.cost.value || 0,
        gender: item.gender || 0,
        // age: item.age.value || "",
        age: item.ageFrom.value + "-" + item.ageTo.value,
        applications: item.applications.value || "",
        totalParticipants: item.totalParticipants || 0,
        postingCodes: item.postingCodes,
        costCenters: item.costCenters,
        tags: item.tags,
        customFields: item.cf || [],
        showRemoveCntDown: false,
        cntDown: 3
      });
    }
    context.root.$forceUpdate();
  };
  const updateServicesList = (servicesList: any) => {
    linkEventServices.value = [];
    for (const item of servicesList) {
      linkEventServices.value.push({
        uuid: item.uuid,
        id: 0,
        eventId: 0,
        addonServiceId: item.id,
        serviceName: item.name.value,
        serviceDesc: item.description.value,
        serviceCost: parseInt(item.cost.value),
        linkAddonCost: parseInt(item.cost.value),
        costcenter: parseInt(item.costcenter.value || "0"),
        isFundBucket: item.isFundBucket.value || false,
        chargeMonthly: item.chargeMonthly.value || false,
        active: 1,
        showRemoveCntDown: false,
        totalParticipants: 0,
        cntDown: 3,
        error: false,
        tags: item.tags,
        postingCodes: item.postingCodes,
        costCenters: item.costCenters,
        availableDate: item.availableDate
      });
    }
    context.root.$forceUpdate();
  };

  const onTabPostingCostCenter = (
    e: any,
    type: string,
    item: any,
    index: number,
    callback: Function,
    allowTab = ""
  ) => {
    if (type == "costcenter") {
      if (item.key.trim() != "" && (item.suggestTags || []).length == 0) {
        if (!allowTab) {
          e.preventDefault();
        }
        if (callback) {
          callback();
        }
        setTimeout(() => {
          $(".edit-cost-center-modal input")
            .first()
            .focus();
        }, 300);
      }
    } else if (type == "posting") {
      if (
        item.postingCodes.key.trim() != "" &&
        (item.postingCodes.suggestTags || []).length == 0
      ) {
        if (!allowTab) {
          e.preventDefault();
        }
        if (callback) {
          callback();

          setTimeout(() => {
            $(".new-posting-code-modal input")
              .first()
              .focus();
          }, 300);
        }
      }
    }
  };

  const isAllowAddNewEventType = (item: any) => {
    if ((item.suggestTags || []).length == 0 && item.key != "") {
      return true;
    }

    return false;
  };

  const onAddNewEventType = async (key: string) => {
    if (key.trim() == "") return;
    popupEventType.value.key = key;
    popupEventType.value.show = true;
    return;
  };

  const onRemoveEventType = (index: number) => {
    const eventTypes = formData.value.controls.eventTypes;
    if (eventTypes.value.length > index) {
      eventTypes.value.splice(index, 1);
    }
  };

  const onSuggestEventType = async (key: string) => {
    // formData.value.controls.eventCostCenters.isFirstFocus = true;
    const eventTypes = formData.value.controls.eventTypes;
    const notInEventTypeIds = eventTypes.value
      .map((item: any) => item.id)
      .join(",");
    const result = await ApiHelper.callApi(
      "get",
      "/events/eventTypes",
      {},
      {
        getAll: 1,
        order: 1,
        direction: 1,
        eventTypeName: key,
        notInEventTypeIds
      }
    );
    if (result.status === 1) {
      eventTypes.suggestTags = result.data.items.map((item: any) => {
        let itemText = item.eventTypeName;
        if (item.costCenterNumber) {
          itemText += ` - ${item.costCenterNumber}`;
        }
        return {
          id: item.eventTypeId,
          text: itemText,
          data: item
        };
      });
    }
  };

  const onRemoveLastEventType = () => {
    const eventTypes = formData.value.controls.eventTypes;
    if (eventTypes.value.length > 0 && eventTypes.key === "") {
      const index = eventTypes.value.length - 1;
      eventTypes.value.splice(index, 1);
    }
  };

  const onSelectEventType = (item: any) => {
    const eventTypes = formData.value.controls.eventTypes;
    eventTypes.key = "";
    eventTypes.suggestTags = [];
    eventTypes.value.push({
      id: item.id,
      text: item.text
    });
    // If they do a cost center for this then they won't need to put one in for location
    // and we use the event type cost center at the default for other items
    const costCenterNumber = item.data.costCenterNumber || "";
    if (costCenterNumber) {
      formData.value.controls.eventCostCenters.value = [
        {
          id: costCenterNumber,
          text: costCenterNumber
        }
      ];
    }
  };

  // init data
  (async () => {
    // formData.value.controls.entity.options = await ApiHelper.getEntityOptions();
    // formData.value.controls.participantTypes.options = await ApiHelper.getParticipantTypeOptions();
    formData.value.controls.applications.options = await ApiHelper.getEntityApplicationOptions();
    // default 5 rows
    // for (let i = 0; i < 5; i++) {
    // addPTypeRow();
    // }
  })();

  (async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/entities/costcenters",
      {},
      {
        take: 50
      }
    );
    if (result.status === 1) {
      formData.value.controls.costcenter.options = result.data.costcenters.map(
        (item: any) => {
          return {
            id: item.id,
            text: item.name
          };
        }
      );
    }
  })();

  const pageData = ref<{
    showGlCodes?: boolean;
    showCostCenter?: boolean;
  }>({});
  (async () => {
    if (
      typeof pageData.value.showGlCodes == "undefined" &&
      typeof pageData.value.showCostCenter == "undefined"
    ) {
      const configData = await ApiHelper.getEntityConfig(true);
      pageData.value.showGlCodes = configData.showGlCodes;
      pageData.value.showCostCenter = configData.showCostCenter;
    }
  })();

  return {
    pageData,
    updatePtypesList,
    updateServicesList,
    popupCostCenter,
    copyEventSettings,
    allowDeletePtype,
    onSuggestTags,
    onAddTag,
    onRemoveTag,
    onSelectTag,
    onRemoveLastTag,
    discountsStore,
    updateDiscounts,
    suggestLocation,
    updateLocation,
    formData,
    linkEventParticipants,
    linkEventServices,
    // showLinkParticipant,
    removeFieldError,
    onSubmit,
    suggestPTypes,
    selectPType,
    popupNewPTypeCol,
    capacityInput,
    isACILoading,
    // updatePtypesList,
    onSuggestCostCenters,
    onRemoveLastCostCenter,
    onRemoveCostCenter,
    onSelectCostCenter,
    onAddNewCostCenter,
    newPostingCode,
    glCodes,
    componentData,
    onTabPostingCostCenter,
    isAllowAddNewEventType,
    onAddNewEventType,
    popupEventType,
    onRemoveEventType,
    onSuggestEventType,
    onRemoveLastEventType,
    onSelectEventType
  };
}
