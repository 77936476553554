

















































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import { EventDetailsSidebar, PTypeStats } from "@/types";
import { EventHelper, ApiHelper } from "@/helpers";
import moment from "moment";
import VScroller from "@/components/Common/VScroller.vue";

import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import $ from "jquery";
import Vue from "vue";

export default defineComponent({
  name: "SidebarEventDetailsComponent",
  props: {
    eventId: String,
    title: String
  },
  components: {
    VScroller,
    HeaderTabs,
    AutoCompleteInput
  },
  setup(props, context) {
    const eventId = parseInt(props.eventId || "0") || 0;
    const isACILoading = ref<boolean>(false);

    const sidebarData = ref<{
      isLoading: boolean;
      name: string;
      logo: string;
      stats: string;
      tsStart: string;
      totalLinkedProfiles: number;
      foundEvents: any;
      hasApplications: boolean;
      showAci: boolean;
      totalMedicals: number;
      totalCabins: number;
      pTypeStats: any[];
    }>({
      isLoading: true,
      name: "",
      stats: "",
      logo: "",
      totalLinkedProfiles: 0,
      foundEvents: [],
      showAci: false,
      hasApplications: false,
      tsStart: "",
      totalMedicals: 0,
      totalCabins: 0,
      pTypeStats: []
    });

    const totalCabin = ref(0);
    const totalDaysLeft = ref(0);
    const today = moment();
    const eventIncome = ref("");
    const eventTotal = ref("");
    const pTypeStats = ref<PTypeStats[]>([]);
    const loadData = () => {
      (async () => {
        const result = await EventHelper.getEventDetailsSidebarData(
          parseInt(props.eventId || "0")
        );
        sidebarData.value.isLoading = false;
        if (result.status == 1) {
          sidebarData.value.name = result.data.name || "";
          sidebarData.value.stats = result.data.stats || "";
          sidebarData.value.logo = result.data.logo || "";
          sidebarData.value.tsStart = result.data.tsStart || "";
          sidebarData.value.pTypeStats = result.data.pTypeStats || [];
          sidebarData.value.totalMedicals = result.data.totalMedicals || 0;
          sidebarData.value.totalCabins = result.data.totalCabins || 0;
          sidebarData.value.hasApplications =
            result.data.hasApplications || false;
          const eventDashboard = result.data.eventDashboard || {
            active: 0,
            percent: 0,
            total: 0,
            totalCost7d: 0,
            totalCost30d: 0,
            totalOwed7d: 0,
            totalOwned30d: 0,
            totalPaid7d: 0,
            totalPaid30d: 0,
            totalParticipants: 0
          };

          pTypeStats.value = sidebarData.value.pTypeStats || [];
          totalCabin.value = sidebarData.value.totalCabins || 0;
          try {
            // const stats = JSON.parse(sidebarData.value.stats);
            // const evIncome = parseFloat(stats.Income || 0);
            // const evOutstanding = parseFloat(stats.Outstanding || 0);
            // const evTotal = evIncome + evOutstanding;
            eventIncome.value = ApiHelper.dollarFormat(
              eventDashboard.totalPaid30d,
              true
            );
            eventTotal.value = ApiHelper.dollarFormat(
              eventDashboard.totalOwned30d,
              true,
              {
                useBrackets: true
              }
            );
          } catch (error) {
            eventIncome.value = ApiHelper.dollarFormat(0);
            eventTotal.value = ApiHelper.dollarFormat(0);
          }

          // days left
          if (
            sidebarData.value.tsStart != "" &&
            moment(sidebarData.value.tsStart).isValid()
          ) {
            totalDaysLeft.value = moment(sidebarData.value.tsStart).diff(
              today,
              "days"
            );
            if (totalDaysLeft.value < 0) {
              totalDaysLeft.value = 0;
            }
          }
        } else {
          console.log(
            "EventHelper.getEventDetailsSidebarData:error",
            result.message
          );
        }
      })();
    };
    loadData();

    const suggestEvents = async (key: string) => {
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/events/search",
        {},
        {
          order: 1,
          direction: 1,
          key: key,
          ignoreIds: props.eventId
        }
      );
      if (result.status === 1) {
        sidebarData.value.foundEvents = result.data.events
          .map((item: any) => ({
            id: item.id,
            text: item.name,
            data: item
          }))
          .filter((item: any) => item.id !== eventId.toString());
      }
      isACILoading.value = false;
    };

    const selectEvent = (item: any) => {
      const eventID = item.data.id.toString() || "0";
      // goto profile details page
      context.root.$router.push({
        name: "EventDetails",
        params: { eventId: eventID }
      });
    };

    const toggleAci = () => {
      console.log("toggleAci", sidebarData.value.showAci);
      sidebarData.value.showAci = !sidebarData.value.showAci;
      if (sidebarData.value.showAci) {
        setTimeout(() => {
          $(context.root.$el)
            .find(".sidebar-profiles-aci input.search-box")
            .focus();
        }, 100);
      }
    };

    const onClickOutside = () => {
      sidebarData.value.showAci = false;
    };

    const gotoDashboardPage = () => {
      Vue.swal({
        title: "",
        html: `<p>Please add an application to begin accepting participants</p>`,
        showCancelButton: true,
        confirmButtonText: "Create a new application"
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        if (result.isConfirmed) {
          ApiHelper.gotoPage(context, {
            name: "SettingsApplications",
            params: {},
            query: { event: eventId.toString() }
          });
        }
      });
      ApiHelper.gotoPage(context, {
        name: "EventDashboard",
        params: { eventId: eventId.toString() }
      });
    };

    return {
      loadData,
      gotoDashboardPage,
      selectEvent,
      toggleAci,
      suggestEvents,
      onClickOutside,
      sidebarData,
      totalCabin,
      totalDaysLeft,
      eventIncome,
      eventTotal,
      pTypeStats,
      isACILoading
    };
  }
});
