























































































































































































































































































import grapesjs from "grapesjs";
import CKEDITOR from "ckeditor4-vue";
import $ from "jquery";
import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import Modal from "@/components/Common/Modal.vue";
import { FormText as FormTextInput } from "@/types";
import FormInput from "@/components/Form/FormInput.vue";
import FormEmail from "@/components/Form/FormEmail.vue";
import FormButton from "@/components/Form/FormButton.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import Vue from "vue";

require("grapesjs/dist/css/grapes.min.css");
require("grapesjs-preset-newsletter");

export default defineComponent({
  name: "EmailBuilder",
  components: {
    TableHeaderColumn,
    Modal,
    FormInput,
    FormEmail,
    FormButton
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App"
    };
  },
  props: {
    data: {
      type: Object,
      default: {
        name: "",
        css: "",
        html: "",
        userEmail: ""
      }
    },
    loadData: Function,
    saveTemplate: Function,
    loadTemplate: Function,
    uploadFile: Function,
    sendTest: Function
  },
  setup(props, context) {
    const formData = ref({
      template: {
        error: ""
      }
    });
    const showLoading = () => {
      context.emit("showLoading");
    };
    const hideLoading = () => {
      context.emit("hideLoading");
    };
    const doSaveTemplate = async (data: any) => {
      if (props.saveTemplate) {
        const result = await props.saveTemplate(data);
        return result;
      } else {
        return {
          status: 0,
          message: "Cant load template. Please try later"
        };
      }
    };
    const doLoadTemplate = async (data: any) => {
      if (props.loadTemplate) {
        return await props.loadTemplate(data);
      } else {
        return {
          status: 0,
          message: "Cant load template. Please try later"
        };
      }
    };
    const doSendTest = async (data: any) => {
      if (props.sendTest) {
        return await props.sendTest(data);
      } else {
        return {
          status: 0,
          message: "Cant send test. Please try later"
        };
      }
    };
    const doLoadData = async () => {
      if (props.loadData) {
        showLoading();
        const result = await props.loadData();
        hideLoading();
        return result;
      } else {
        return {
          status: 0,
          message: "Cant send test. Please try later"
        };
      }
    };
    const randomId = ref<number>(
      Number.parseInt((Math.random() * 10000000).toString(), 10)
    );

    const editor = ref<any>(null);
    const popupSave = ref<{
      show: boolean;
      controls: {
        name: FormTextInput;
        email: FormTextInput;
        css: FormTextInput;
        html: FormTextInput;
      };
      isProcessing: boolean;
      onSubmit: Function;
    }>({
      show: false,
      controls: {
        name: {
          label: "Template Name",
          placeholder: "Template Name",
          style: "custom",
          value: props.data.name,
          noPadding: true,
          required: true,
          error: ""
        },
        email: {
          label: "Email",
          placeholder: "Email",
          style: "custom",
          value: props.data.userEmail,
          required: true,
          noPadding: true,
          error: ""
        },
        css: {
          label: "CSS",
          value: props.data.css,
          error: ""
        },
        html: {
          label: "HTML",
          value: props.data.html,
          error: ""
        }
      },
      isProcessing: false,
      onSubmit: () => {
        let hasError = false;
        if (popupSave.value.controls.name.value == "") {
          hasError = true;
          popupSave.value.controls.name.error = "Template Name is required";
        }
        if (popupSave.value.controls.email.value == "") {
          hasError = true;
          popupSave.value.controls.email.error = "Email is required";
        } else if (
          !ApiHelper.validateEmail(popupSave.value.controls.email.value)
        ) {
          hasError = true;
          popupSave.value.controls.email.error = "Email is invalid";
        }
        if (!hasError) {
          const template = {
            name: popupSave.value.controls.name.value,
            userEmail: popupSave.value.controls.email.value,
            html: popupSave.value.controls.html.value,
            css: popupSave.value.controls.css.value
          };
          showLoading();
          doSaveTemplate(template)
            .then(res => {
              hideLoading();
              if (res.status == 1) {
                const container = $("#grapesjs-container-" + randomId.value);
                popupSave.value.show = false;
                ApiHelper.showSuccessMessage("Saved");
                editor.value.setComponents(popupSave.value.controls.html.value);
                editor.value.setStyle(popupSave.value.controls.css.value);
                container
                  .find("textarea.html-preview")
                  .val(editor.value.getHtml());
                // $(".btn-switch-template").prop("checked", true);
                // container.find("#gjs .gjs-cv-canvas").show();
                // container.find(".head_right").show();
                // container.find("#preview").hide();
              } else {
                const statusCode = res.statusCode || "";
                if (statusCode == "nameExisted") {
                  popupSave.value.controls.name.error =
                    "Template Name already exists";
                } else {
                  ApiHelper.showErrorMessage(res.message, "Oops");
                }
              }
            })
            .catch(error => {
              ApiHelper.showErrorMessage(JSON.stringify(error), "Oops");
            });
        }
      }
    });
    const popupLoadTemplate = ref<{
      show: boolean;
      loaded: boolean;
      controls: {
        email: FormTextInput;
        items: {
          name: string;
          content: string;
          css: string;
          id: number;
        }[];
      };
      isProcessing: boolean;
      onSubmit: Function;
    }>({
      show: false,
      loaded: false,
      controls: {
        email: {
          label: "Email",
          placeholder: "Email",
          style: "custom",
          value: "",
          noPadding: true,
          required: true,
          error: ""
        },
        items: []
      },
      isProcessing: false,
      onSubmit: () => {
        let hasError = false;
        if (popupLoadTemplate.value.controls.email.value == "") {
          hasError = true;
          popupLoadTemplate.value.controls.email.error = "Email is required";
        } else if (
          !ApiHelper.validateEmail(popupLoadTemplate.value.controls.email.value)
        ) {
          hasError = true;
          popupLoadTemplate.value.controls.email.error = "Email is invalid";
        }
        if (!hasError) {
          const email = popupLoadTemplate.value.controls.email.value;
          popupLoadTemplate.value.loaded = false;
          popupLoadTemplate.value.controls.items = [];
          showLoading();
          doLoadTemplate(email)
            .then(res => {
              hideLoading();
              if (res.status == 1) {
                popupLoadTemplate.value.loaded = true;
                popupLoadTemplate.value.controls.items =
                  res.data.templates || [];
              } else {
                ApiHelper.showErrorMessage(res.message, "Oops");
              }
            })
            .catch(error => {
              ApiHelper.showErrorMessage(JSON.stringify(error), "Oops");
            });
        }
      }
    });
    const showPopupSave = (html: string, css: string, refEditor: any) => {
      popupSave.value.controls.name.error = "";
      popupSave.value.controls.name.value = props.data.name;
      popupSave.value.controls.email.error = "";
      popupSave.value.controls.email.value = props.data.userEmail;
      popupSave.value.controls.html.value = html;
      popupSave.value.controls.css.value = css;
      popupSave.value.show = true;
      editor.value = refEditor;
    };

    const popupTest = ref<{
      show: boolean;
      controls: {
        subject: FormTextInput;
        email: FormTextInput;
        css: FormTextInput;
        html: FormTextInput;
      };
      isProcessing: boolean;
      onSubmit: Function;
    }>({
      show: false,
      controls: {
        subject: {
          label: "Subject",
          placeholder: "Subject",
          style: "custom",
          value: "",
          noPadding: true,
          required: true,
          error: ""
        },
        email: {
          label: "Email",
          placeholder: "Email",
          style: "custom",
          value: "",
          required: true,
          noPadding: true,
          error: ""
        },
        css: {
          label: "CSS",
          value: "",
          error: ""
        },
        html: {
          label: "HTML",
          value: "",
          error: ""
        }
      },
      isProcessing: false,
      onSubmit: () => {
        let hasError = false;
        if (popupTest.value.controls.subject.value == "") {
          hasError = true;
          popupTest.value.controls.subject.error = "Subject is required";
        }
        if (popupTest.value.controls.email.value == "") {
          hasError = true;
          popupTest.value.controls.email.error = "Email is required";
        } else if (
          !ApiHelper.validateEmail(popupTest.value.controls.email.value)
        ) {
          hasError = true;
          popupTest.value.controls.email.error = "Email is invalid";
        }
        if (!hasError) {
          showLoading();
          const email = {
            TO: popupTest.value.controls.email.value,
            SUBJECT: popupTest.value.controls.subject.value,
            MESSAGE: popupTest.value.controls.html.value
          };

          doSendTest(email)
            .then(res => {
              if (res.status == 1) {
                hideLoading();
                popupTest.value.show = false;
                ApiHelper.showSuccessMessage(
                  "A test email was sent to " +
                    popupTest.value.controls.email.value
                );
              } else {
                ApiHelper.showErrorMessage(res.message, "Oops");
              }
            })
            .catch(error => {
              ApiHelper.showErrorMessage(JSON.stringify(error), "Oops");
            });
        }
      }
    });
    const showPopupTest = (html: string, css: string) => {
      popupTest.value.controls.subject.error = "";
      popupTest.value.controls.subject.value = "";
      popupTest.value.controls.email.error = "";
      popupTest.value.controls.email.value = "";
      popupTest.value.controls.html.value = html;
      popupTest.value.controls.css.value = css;
      popupTest.value.show = true;
    };

    const loadTemplateItem = (item: any) => {
      editor.value.setComponents(item.content);
      editor.value.setStyle(item.css);
    };

    return {
      formData,
      loadTemplateItem,
      popupLoadTemplate,
      editor,
      showPopupTest,
      popupTest,
      showPopupSave,
      popupSave,
      showLoading,
      hideLoading,
      randomId,
      doLoadData,
      doSaveTemplate,
      doLoadTemplate,
      doSendTest
    };
  },
  async mounted() {
    const container = $("#grapesjs-container-" + this.randomId);
    const builderContainer = $(".builder-container");
    await this.doLoadData();
    const doSaveTemplate = this.doSaveTemplate;
    const data: any = this.data;
    const _showPopupSave: any = this.showPopupSave;
    const _showPopupTest: any = this.showPopupTest;
    const _popupLoadTemplate: any = this.popupLoadTemplate;

    const doLoadTemplate = this.doLoadTemplate;
    const formData = this.formData;
    const doSendTest = this.doSendTest;
    const showLoading = this.showLoading;
    const hideLoading = this.hideLoading;
    const dtd = CKEDITOR.dtd || undefined;
    const editable: any = dtd ? dtd.$editable : undefined;
    if (editable) {
      editable.a = 1;
    }
    const editor = grapesjs.init({
      height: "100%",
      selectorManager: { componentFirst: true },
      styleManager: { clearProperties: 1 },
      storageManager: {
        id: "gjs-",
        type: "remote",
        autoload: false,
        autosave: false,
        contentTypeJson: true
      },
      assetManager: {
        assets: [],
        noAssets: "No images found, drag to upload",
        uploadFile: (e: any) => {
          const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          const fileData = files["0"] || undefined;
          if (fileData) {
            showLoading();
            ApiHelper.uploadFile(fileData.name, fileData)
              .then((res: any) => {
                hideLoading();
                if (res.status === 1) {
                  const resData = res.data || {};
                  const fileUrl = resData.fileUrl || "";
                  if (fileUrl) {
                    editor.AssetManager.add([resData.fileUrl]);
                  }
                } else {
                  ApiHelper.showErrorMessage(res.message, "Oops");
                }
              })
              .catch(error => {
                hideLoading();
                ApiHelper.showErrorMessage(JSON.stringify(error), "Oops");
              });
          } else {
            ApiHelper.showErrorMessage("No file", "Oops");
          }
        }
      },
      container: "#gjs",
      fromElement: false,
      plugins: ["gjs-preset-newsletter", "gjs-plugin-ckeditor"],
      pluginsOpts: {
        "gjs-preset-newsletter": {
          modalLabelImport: "Paste all your code here below and click import",
          modalLabelExport: "Copy the code and use it wherever you want",
          codeViewerTheme: "material",
          defaultTemplate: "",
          importPlaceholder:
            "<table class='table'><tr><td class='cell'>Hello world!</td></tr></table>",
          cellStyle: {
            "font-size": "12px",
            "font-weight": 300,
            "vertical-align": "top",
            color: "rgb(111, 119, 125)",
            margin: 0,
            padding: 0
          }
        },
        "gjs-plugin-ckeditor": {
          position: "center",
          options: {
            startupFocus: true,
            extraAllowedContent: "*(*);*{*}", // Allows any class and any inline style
            allowedContent: true, // Disable auto-formatting, class removing, etc.
            // enterMode: CKEDITOR.ENTER_BR,
            extraPlugins: "sharedspace,justify,colorbutton,panelbutton,font",
            toolbar: [
              { name: "styles", items: ["Font", "FontSize"] },
              ["Bold", "Italic", "Underline", "Strike"],
              { name: "paragraph", items: ["NumberedList", "BulletedList"] },
              { name: "links", items: ["Link", "Unlink"] },
              { name: "colors", items: ["TextColor", "BGColor"] }
            ]
          }
        }
      }
    });

    editor.BlockManager.add("participant-name", {
      category: "Data",
      label: "Person Name",
      attributes: { class: "fa fa-user" },
      content: `<div data-gjs-type="data">###PERSON_NAME###</div>`
    });
    editor.BlockManager.add("participant-email", {
      category: "Data",
      label: "Email",
      attributes: { class: "fa fa-fire" },
      content: `<div data-gjs-type="data">###EMAIL###</div>`
    });
    editor.BlockManager.add("event-name", {
      category: "Data",
      label: "Event",
      attributes: { class: "fa fa-fire" },
      content: `<div data-gjs-type="data">###EVENT_NAME###</div>`
    });
    editor.BlockManager.add("event-start-date", {
      category: "Data",
      label: "Event Start Date",
      attributes: { class: "fa fa-fire" },
      content: `<div data-gjs-type="data">###EVENT_START_DATE###</div>`
    });
    editor.BlockManager.add("event-end-date", {
      category: "Data",
      label: "Event End Date",
      attributes: { class: "fa fa-fire" },
      content: `<div data-gjs-type="data">###EVENT_END_DATE###</div>`
    });
    editor.BlockManager.add("content", {
      category: "Data",
      label: "Content",
      attributes: { class: "fa fa-paragraph" },
      content: `<div data-gjs-type="data">###CONTENT###</div>`
    });

    editor.on("block:drag:stop", (model: any) => {
      formData.template.error = "";
    });

    if (data.html != "") {
      editor.setComponents(data.html);
    }
    if (data.css != "") {
      editor.setStyle(data.css);
    }
    const waitForEl = (selector: any, callback: any) => {
      if ($(selector).length) {
        callback();
      } else {
        setTimeout(function() {
          waitForEl(selector, callback);
        }, 100);
      }
    };

    waitForEl(".gjs-pn-views-container", function() {
      $(".gjs-pn-panels").prepend(
        `<div class="tabs"><input name="tabs" type="radio" id="tab-1" checked="checked" class="input"/><label for="tab-1" class="label first">Elements</label><div class="panel panel-elements"></div><input name="tabs" type="radio" id="tab-2" class="input"/><label for="tab-2" class="label last">Actions</label><div class="panel panel-actions"></div></div>`
      );
      setTimeout(function() {
        //$(".gjs-pn-views, .gjs-pn-views-container, .gjs-pn-devices-c").appendTo(".panel-elements");
        $(".gjs-pn-views, .gjs-pn-views-container").appendTo(".panel-elements");
        $(".head_right .gjs-pn-devices-c").remove();
        $(".gjs-pn-devices-c").appendTo(".template-devices");
        $(".gjs-pn-options").appendTo(".panel-actions");
        $("#loader").css("display", "none");
        $("#gjs").css("display", "block");
      }, 100);
    });

    const mdlClass = "gjs-mdl-dialog-sm";
    const pnm = editor.Panels;
    const cmdm = editor.Commands;
    const md = editor.Modal;

    function getCurrentHtml() {
      let templateHtml = "";
      if ($(".btn-switch-template").is(":checked")) {
        templateHtml = editor.getHtml();
      } else {
        const txt = container.find("textarea.html-preview");
        templateHtml = txt != undefined ? txt.val() + "" : "";
      }
      return templateHtml;
    }

    cmdm.add("send-test", {
      run(editor: any, sender: any) {
        const cmdGetCode = cmdm.get("gjs-get-inlined-html");
        const html = getCurrentHtml();
        if (!html) {
          formData.template.error = "Content is required";
          return;
        }
        _showPopupTest(cmdGetCode.run(editor), editor.getCss());
        /*
        sender && sender.set("active");
        const mdlDialog: any = document.querySelector(".gjs-mdl-dialog");
        if (mdlDialog) {
          const cmdGetCode = cmdm.get("gjs-get-inlined-html");
          $("#test-modal input[name=body]").val(
            cmdGetCode && cmdGetCode.run(editor)
          );
          const testContainer = $("#test-modal").html();
          mdlDialog.className += " " + mdlClass;
          md.setTitle("Test your Newsletter");
          md.setContent(testContainer);
          md.open();
          md.getModel().once("change:open", function() {
            mdlDialog.className = mdlDialog.className.replace(mdlClass, "");
            //clean status
          });
        }
        */
      }
    });
    cmdm.add("load-template", {
      run(editor: any, sender: any) {
        _popupLoadTemplate.show = true;
        _popupLoadTemplate.controls.email.value = "";
        _popupLoadTemplate.controls.email.error = "";
        _popupLoadTemplate.controls.items = [];
        _popupLoadTemplate.loaded = false;
        /*
        sender && sender.set("active");
        const mdlDialog: any = document.querySelector(".gjs-mdl-dialog");
        if (mdlDialog) {
          const testContainer = $("#load-modal").html();
          mdlDialog.className += " " + mdlClass;
          md.setTitle("Load Template(s)");
          md.setContent(testContainer);
          md.open();
          md.getModel().once("change:open", function() {
            mdlDialog.className = mdlDialog.className.replace(mdlClass, "");
            //clean status
          });
        }
        */
      }
    });
    cmdm.add("save-template", {
      run(editor: any, sender: any) {
        // sender && sender.set("active");
        // const mdlDialog: any = document.querySelector(".gjs-mdl-dialog");
        const templateHtml = getCurrentHtml();
        if (!templateHtml) {
          formData.template.error = "Content is required";
          return;
        }
        _showPopupSave(templateHtml, editor.getCss(), editor);

        /*const cmdGetCode = cmdm.get("gjs-get-inlined-html");
        $("#save-modal input[name=body]").val(cmdGetCode && cmdGetCode.run(editor));*/
        /*
        if (mdlDialog) {
          let testContainer = $("#save-modal").html();
          testContainer = testContainer.replaceAll(
            "###_DEFAULT_NAME_###",
            data.name || ""
          );
          testContainer = testContainer.replaceAll(
            "###_DEFAULT_EMAIL_###",
            data.userEmail || ""
          );
          mdlDialog.className += " " + mdlClass;
          md.setTitle("Save Current Template");
          md.setContent(testContainer);
          md.open();
          md.getModel().once("change:open", function() {
            mdlDialog.className = mdlDialog.className.replace(mdlClass, "");
            //clean status
          });
        }
        */
      }
    });

    // Add undo/redo buttons
    pnm.addButton("options", {
      id: "undo",
      className: "fa fa-undo",
      command: "undo",
      attributes: { title: "Undo" }
    });
    pnm.addButton("options", {
      id: "redo",
      className: "fa fa-repeat",
      command: "redo",
      attributes: { title: "Redo" }
    });
    pnm.addButton("options", {
      id: "save-template",
      className: "fa fa-floppy-o",
      command: "save-template",
      attributes: {
        title: "Save Template",
        "data-tooltip-pos": "bottom"
      }
    });
    pnm.addButton("options", {
      id: "load-template",
      className: "fa fa-cloud-upload",
      command: "load-template",
      attributes: {
        title: "Load Template",
        "data-tooltip-pos": "bottom"
      }
    });
    pnm.addButton("options", {
      id: "send-test",
      className: "fa fa-paper-plane",
      command: "send-test",
      attributes: {
        title: "Test Newsletter",
        "data-tooltip-pos": "bottom"
      }
    });

    container.ready(function() {
      // Beautify tooltips
      $("*[title]").each(function() {
        const el: any = $(this);
        const title = el.attr("title").trim();
        if (!title) return;
        el.attr("data-tooltip", el.attr("title"));
        el.attr("title", "");
      });
    });
    /*
    container.on("click", "button#sendTest", function() {
      if (
        $(".gjs-mdl-dialog input:not([type=hidden])").filter(function() {
          return !$(this).val();
        }).length == 0
      ) {
        const mailTo = $("#sendTestEmail").val();
        const email = {
          TO: mailTo,
          SUBJECT: $("#sendTestSubject").val(),
          MESSAGE: $("#sendTestBody").val()
        };

        doSendTest(email)
          .then(res => {
            if (res.status == 1) {
              console.log("doSendTest:res", res);
              md.setContent("Email Sent!");
              md.close();
              ApiHelper.showSuccessMessage(
                "A test email was sent to " + mailTo
              );
            } else {
              ApiHelper.showErrorMessage(res.message, "Oops");
            }
          })
          .catch(error => {
            ApiHelper.showErrorMessage(JSON.stringify(error), "Oops");
          });
      }
    });
    */
    /*
    container.on("click", "button#saveTemplate", function(event) {
      event.preventDefault();

      const templateName = ApiHelper.removeHtml(
        $("#saveTemplateName").val() + ""
      );
      const saveTemplateEmail = ApiHelper.removeHtml(
        $("#saveTemplateEmail").val() + ""
      );
      const templateHtml = editor.getHtml();
      if (templateName === "") {
        ApiHelper.showErrorMessage("Template name is required.", "Oops");
        return;
      }
      if (saveTemplateEmail === "") {
        ApiHelper.showErrorMessage("Email is required.", "Oops");
        return;
      }
      if (!ApiHelper.validateEmail(saveTemplateEmail)) {
        ApiHelper.showErrorMessage("Email is invalid.", "Oops");
        return;
      }
      if (templateHtml === "") {
        ApiHelper.showErrorMessage("Template Content is required.", "Oops");
        return;
      }
      $("#saveTemplateName").val(templateName);
      $("#saveTemplateEmail").val(saveTemplateEmail);
      const template = {
        name: templateName,
        userEmail: saveTemplateEmail,
        html: templateHtml,
        css: editor.getCss()
      };
      doSaveTemplate(template)
        .then(res => {
          if (res.status == 1) {
            md.setContent("Template Saved!");
            md.close();
            ApiHelper.showSuccessMessage("Saved");
          } else {
            ApiHelper.showErrorMessage(res.message, "Oops");
          }
        })
        .catch(error => {
          ApiHelper.showErrorMessage(JSON.stringify(error), "Oops");
        });
    });
    */

    container.on("click", "button#loadTemplate", function(event) {
      event.preventDefault();
      formData.template.error = "";
      if (
        $(".gjs-mdl-dialog input:not([type=hidden])").filter(function() {
          return !$(this).val();
        }).length == 0
      ) {
        const data = $("#loadTemplateEmail").val();
        doLoadTemplate(data)
          .then(res => {
            if (res.status == 1) {
              const templates: any = res.data.templates || [];
              if (templates.length > 0) {
                templates.map((item: any) => {
                  const newRow =
                    "<div class='gjs-template' data-id='" +
                    item.id +
                    "' data-html='" +
                    item.content +
                    "' data-css='" +
                    item.css +
                    "'>" +
                    item.name +
                    "</div>";
                  $("#loadTemplateContainer").append(newRow);
                });
              } else {
                ApiHelper.showErrorMessage("Not found any templates", "Oops");
              }
            } else {
              ApiHelper.showErrorMessage(res.message, "Oops");
            }
          })
          .catch(error => {
            ApiHelper.showErrorMessage(JSON.stringify(error), "Oops");
          });
      }
    });

    container.on("click", "#loadTemplateContainer .gjs-template", function(
      event
    ) {
      event.preventDefault();
      formData.template.error = "";
      editor.setComponents($(this).attr("data-html"));
      editor.setStyle($(this).attr("data-css"));
      container.find("textarea.html-preview").val(editor.getHtml());
      _popupLoadTemplate.show = false;
      md.close();
    });

    builderContainer.on("change", ".btn-switch-template", function() {
      formData.template.error = "";
      if ($(".btn-switch-template").is(":checked")) {
        const oldHtml = editor.getHtml();
        const oldCss = editor.getCss();
        const newHtml = container.find("textarea.html-preview").val();
        if (oldHtml != newHtml) {
          Vue.swal({
            html:
              "Are you sure you want to abandon the code? All of your data will be lost",
            showCancelButton: true,
            confirmButtonText: "Yes, do it!",
            showCloseButton: true,
            closeButtonHtml:
              '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
          }).then(result => {
            setTimeout(function() {
              $(".swal2-backdrop-hide").addClass("d-none");
            }, 200);
            if (result.isConfirmed) {
              container.find("#gjs .gjs-cv-canvas").show();
              container.find(".head_right").show();
              container.find("#preview").hide();
              // editor.setComponents(newHtml);
              // editor.setStyle(oldCss);
            } else {
              $(".btn-switch-template").prop("checked", false);
            }
          });
        } else {
          container.find("#gjs .gjs-cv-canvas").show();
          container.find(".head_right").show();
          container.find("#preview").hide();
        }
      } else {
        container.find("textarea.html-preview").val(editor.getHtml());
        container.find(".head_right").hide();
        container.find("#gjs .gjs-cv-canvas").hide();
        container.find("#preview").show();
      }
    });

    /*
    container.on("focusout", "#saveTemplateName", () => {
      const templateName = $("#saveTemplateName").val() + "" || "";
      $("#saveTemplateName").val(ApiHelper.removeHtml(templateName));
    });
    container.on("focusout", "#saveTemplateEmail", () => {
      const templateName = $("#saveTemplateEmail").val() + "" || "";
      $("#saveTemplateEmail").val(ApiHelper.removeHtml(templateName));
    });
    */
  }
});
