var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-inside"},[_c('div',{staticClass:"content__table__scroll"},[_c('div',{staticClass:"content_table"},[(_vm.showTotal)?_c('div',{staticClass:"section-title"},[_vm._v(" Action Logs "),(_vm.pageData.pager.total)?_c('span',{staticClass:"total-number"},[_vm._v(" "+_vm._s(_vm.pageData.pager.total)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"table__head"},[_c('div',{staticClass:"items row"},[(!_vm.isProfileActions)?_c('div',{staticClass:"item col-2"},[_c('FilterCheckboxesColumn',{attrs:{"label":'Name',"active-tab":_vm.pageData.activeTab,"active-value":'profile',"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.filter.profile,"close-filter-tab":_vm.closeFilterTab,"on-select-all":function () {
                  if (_vm.pageData.filter.unassignedProfile) {
                    _vm.pageData.filter.unassignedProfile.selected = true;
                  }
                  _vm.pageData.filter.profile.map(function (item) {
                    item.selected = true;
                  });
                  _vm.updateRouters();
                },"on-reset-value":function () {
                  _vm.pageData.filter.unassignedProfile = null;
                  _vm.pageData.searchProfilesValue.value = '';
                  _vm.pageData.filter.profile = [];
                  _vm.updateRouters();
                },"on-change-value":function () {
                  _vm.pageData.pager.page = 1;
                  _vm.updateRouters();
                },"lazyLoad":true,"lazyLoadPlaceholder":"Search participant...","other-option":_vm.pageData.filter.unassignedProfile,"searchValue":_vm.pageData.searchProfilesValue,"isACILoading":_vm.pageData.searchProfilesLoading,"sort-data":_vm.pageData.sort,"sort-key":'1',"on-change-sort-value":_vm.updateSortValue},on:{"searchItems":_vm.pageData.searchProfiles}})],1):_vm._e(),_c('div',{staticClass:"item col-2 has__arrow"},[_c('FilterCheckboxesColumn',{attrs:{"label":'Type',"active-tab":_vm.pageData.activeTab,"active-value":'type',"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.filter.type,"close-filter-tab":_vm.closeFilterTab,"on-select-all":function () {
                  _vm.pageData.filter.type = _vm.pageData.filter.type.map(function (item) {
                    item.selected = true;
                    return item;
                  });
                  _vm.updateRouters();
                },"on-reset-value":function () {
                  _vm.pageData.filter.type = _vm.pageData.filter.type.map(function (item) {
                    item.selected = false;
                    return item;
                  });
                  _vm.updateRouters();
                },"on-change-value":function (item) {
                  _vm.pageData.pager.page = 1;
                  _vm.updateRouters();
                },"sort-data":_vm.pageData.sort,"sort-key":'2',"on-change-sort-value":_vm.updateSortValue}})],1),_c('div',{staticClass:"item col-4 has__arrow"},[_c('TableHeaderColumn',{attrs:{"label":"Description"}})],1),_c('div',{staticClass:"item col-2 has__arrow"},[_c('FilterCheckboxesColumn',{attrs:{"label":'Status',"active-tab":_vm.pageData.activeTab,"active-value":'status',"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.filter.status,"close-filter-tab":_vm.closeFilterTab,"on-select-all":function () {
                  _vm.pageData.filter.status = _vm.pageData.filter.status.map(
                    function (item) {
                      item.selected = true;
                      return item;
                    }
                  );
                  _vm.updateRouters();
                },"on-reset-value":function () {
                  _vm.pageData.filter.status = _vm.pageData.filter.status.map(
                    function (item) {
                      item.selected = false;
                      return item;
                    }
                  );
                  _vm.updateRouters();
                },"on-change-value":function (item) {
                  _vm.pageData.pager.page = 1;
                  _vm.updateRouters();
                },"sort-data":_vm.pageData.sort,"sort-key":'3',"on-change-sort-value":_vm.updateSortValue}})],1),_c('div',{staticClass:"item col-2 table__center"},[_c('FilterDateColumn',{attrs:{"label":'Registered Date',"active-tab":_vm.pageData.activeTab,"active-value":'date',"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.filter.date,"on-change-value":_vm.updateFilterValue,"sort-data":_vm.pageData.sort,"sort-key":'4',"on-change-sort-value":_vm.updateSortValue}})],1),(_vm.isProfileActions)?_c('div',{staticClass:"item col-2"},[_c('FilterCheckboxesColumn',{attrs:{"label":'Event',"active-tab":_vm.pageData.activeTab,"active-value":'events',"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.filter.events,"other-option":_vm.pageData.filter.unassignedEvent,"close-filter-tab":_vm.closeFilterTab,"on-select-all":function () {
                  _vm.pageData.pager.page = 1;
                  if (_vm.pageData.filter.unassignedEvent) {
                    _vm.pageData.filter.unassignedEvent.selected = true;
                  }
                  _vm.pageData.filter.events.map(function (item) {
                    item.selected = true;
                  });
                  _vm.updateRouters();
                },"on-reset-value":function () {
                  if (_vm.pageData.filter.unassignedEvent) {
                    _vm.pageData.filter.unassignedEvent = null;
                  }
                  _vm.pageData.searchEventsValue.value = '';
                  _vm.pageData.filter.events = [];
                  _vm.updateRouters();
                },"on-change-value":function () {
                  _vm.pageData.pager.page = 1;
                  _vm.updateRouters();
                },"lazyLoad":true,"lazyLoadPlaceholder":"Search event...","searchValue":_vm.pageData.searchEventsValue,"isACILoading":_vm.pageData.searchEventsLoading,"sort-data":_vm.pageData.sort,"sort-key":'5',"on-change-sort-value":_vm.updateSortValue},on:{"searchItems":_vm.pageData.searchEvents}})],1):_vm._e()])]),_c('div',{staticClass:"table__body no-stretch"},[(!_vm.pageData.isLoading & (_vm.pageData.items.length < 1))?_c('div',{staticClass:"noData"},[_vm._v(" Nothing to see here quite yet...zero timelines. ")]):_vm._e(),_vm._l((_vm.pageData.items),function(item,index){return _c('div',{key:index,class:[
            'items row',
            { 'is-new': item.isNew || false, checked: item.isChecked }
          ],attrs:{"data-aid":item.actionId}},[(!_vm.isProfileActions)?_c('div',{staticClass:"item col-2"},[(item.profileId > 0)?_c('a',{staticClass:"see-link textOverflow",attrs:{"href":("#/profiles/" + (item.profileId) + "/details"),"title":item.fullname}},[_vm._v(_vm._s(item.fullname))]):_c('span',[_vm._v(_vm._s(item.userFullName))])]):_vm._e(),_c('div',{staticClass:"item col-2 col-action-type"},[_c('span',[_vm._v(_vm._s(item.actiontype))])]),_c('div',{staticClass:"item col-4"},[(item.actiondesc === 'Participant accepted')?_c('span',[_vm._v("Participant Registered")]):(item.actiondesc === 'Participant applied')?_c('span',[_vm._v("Participant Registered")]):_c('span',[_vm._v(" "+_vm._s(item.actiondesc)+" ")])]),_c('div',{staticClass:"item col-2"},[(item.atid === 8)?_c('span',[_vm._v("Created")]):(item.atid === 10)?_c('span',[_vm._v("Updated")]):(item.atid === 22)?_c('span',[_vm._v("Deleted")]):_c('span',[_vm._v(" "+_vm._s(_vm.ApiHelper.getParticipantStatusText(item.participantStatus))+" ")])]),_c('div',{staticClass:"item col-2 table__center"},[_c('span',[_vm._v(_vm._s(item.actionTsFormatted))])]),(_vm.isProfileActions)?_c('div',{staticClass:"item col-2"},[_c('a',{staticClass:"see-link textOverflow",attrs:{"href":("#/events/" + (item.eventId) + "/details"),"title":item.eventName}},[_vm._v(_vm._s(item.eventName))])]):_vm._e()])})],2)])]),_c('Pager',{attrs:{"goto-page":_vm.gotoPage,"on-click-next":_vm.onClickNext,"page-data":_vm.pageData,"on-click-prev":_vm.onClickPrev,"toggle-pager-items":_vm.togglePagerItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }