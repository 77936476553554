var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"NavEventDetails"},[_c('div',[_c('div',{staticClass:"nav-box"},[_c('ul',[_c('router-link',{attrs:{"to":{
            name: 'EventDashboard',
            params: {
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active:
                ((isActive || isExactActive) && _vm.activeMenu == '') ||
                _vm.activeMenu == 'EventDashboard'
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"mouseenter":function($event){return _vm.setActiveMenu('EventDashboard')},"mouseleave":function($event){return _vm.setActiveMenu('')},"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#dashboard","alt":"Dashboard"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Dashboard")])])])]}}])}),_c('router-link',{attrs:{"to":{
            name: 'EventDetails',
            params: {
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active:
                ((isActive || isExactActive) && _vm.activeMenu == '') ||
                _vm.activeMenu == 'EventDetails'
            },on:{"mouseenter":function($event){return _vm.setActiveMenu('EventDetails')},"mouseleave":function($event){return _vm.setActiveMenu('')}}},[(_vm.sidebarData.hasApplications)?_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#participants","alt":"Participants"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Participants")]),_c('div',{staticClass:"item__text"},[_c('div',{staticClass:"item__progress"},[_c('div',{staticClass:"progress__bar"},[_c('div',{staticClass:"progress__detail"},[_c('span',{staticStyle:{"width":"69%"},attrs:{"data-width":"69%"}})])])])])]):_c('a',{staticClass:"box__item",attrs:{"href":"javascript:void(0)","title":""},on:{"click":_vm.gotoDashboardPage}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#participants","alt":"Participants"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Participants")]),_c('div',{staticClass:"item__text"},[_c('div',{staticClass:"item__progress"},[_c('div',{staticClass:"progress__bar"},[_c('div',{staticClass:"progress__detail"},[_c('span',{staticStyle:{"width":"69%"},attrs:{"data-width":"69%"}})])])])])])])]}}])}),_c('router-link',{attrs:{"to":{
            name: 'EventSchedule',
            params: {
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active:
                ((isActive || isExactActive) && _vm.activeMenu == '') ||
                _vm.activeMenu == 'EventSchedule'
            },on:{"mouseenter":function($event){return _vm.setActiveMenu('EventSchedule')},"mouseleave":function($event){return _vm.setActiveMenu('')}}},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#schedule","alt":"Schedule"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Schedule")])])])]}}])}),_c('router-link',{attrs:{"to":{
            name: 'EventCabins',
            params: {
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active:
                ((isActive || isExactActive) && _vm.activeMenu == '') ||
                _vm.activeMenu == 'EventCabins'
            },on:{"mouseenter":function($event){return _vm.setActiveMenu('EventCabins')},"mouseleave":function($event){return _vm.setActiveMenu('')}}},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#cabins","alt":"Cabins"}})]),_c('div',{staticClass:"item__title"},[_vm._v(" Cabins ")]),(!_vm.sidebarData.isLoading)?_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalCabins)+" ")]):_vm._e()])])]}}])}),_c('router-link',{attrs:{"to":{
            name: 'EventTransportation',
            params: {
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active:
                ((isActive || isExactActive) && _vm.activeMenu == '') ||
                _vm.activeMenu == 'EventTransportation'
            },on:{"mouseenter":function($event){return _vm.setActiveMenu('EventTransportation')},"mouseleave":function($event){return _vm.setActiveMenu('')}}},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#transportation","alt":"Transportation"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Transportation")])])])]}}])}),_c('router-link',{attrs:{"to":{
            name: 'EventMedical',
            params: {
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active:
                ((isActive || isExactActive) && _vm.activeMenu == '') ||
                _vm.activeMenu == 'EventMedical'
            },on:{"mouseenter":function($event){return _vm.setActiveMenu('EventMedical')},"mouseleave":function($event){return _vm.setActiveMenu('')}}},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#medical","alt":"Medical"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Medical")]),(!_vm.sidebarData.isLoading)?_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalMedicals)+" ")]):_vm._e()])])]}}])}),(_vm.$allowedFunctions.includes(_vm.$userFunctions.Transactions))?_c('router-link',{attrs:{"to":{
            name: 'EventFinancial',
            params: {
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active:
                ((isActive || isExactActive) && _vm.activeMenu == '') ||
                _vm.activeMenu == 'EventFinancial'
            },on:{"mouseenter":function($event){return _vm.setActiveMenu('EventFinancial')},"mouseleave":function($event){return _vm.setActiveMenu('')}}},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#financial","alt":"Financial"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Financial")])])])]}}],null,false,1732016848)}):_vm._e(),_c('router-link',{attrs:{"to":{
            name: 'EventSettings',
            params: {
              eventId: _vm.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active:
                ((isActive || isExactActive) && _vm.activeMenu == '') ||
                _vm.activeMenu == 'EventSettings'
            },on:{"mouseenter":function($event){return _vm.setActiveMenu('EventSettings')},"mouseleave":function($event){return _vm.setActiveMenu('')}}},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#settings","alt":"Settings"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Settings")])])])]}}])})],1),_c('div',{staticClass:"box_footer"},[_c('div',{staticClass:"box__items"},[_c('div',{staticClass:"box-item"},[_c('div',{staticClass:"item__name"},[_vm._v(" Financials ")]),_c('div',{staticClass:"item__total"},[_c('span',{staticClass:"item__green"},[_vm._v(_vm._s(_vm.eventIncome))]),_c('span',{staticClass:"item__arrow"},[_vm._v("/")]),_c('span',{staticClass:"item__red"},[_vm._v(_vm._s(_vm.eventTotal))])])]),_vm._l((_vm.pTypeStats),function(item,index){return _c('div',{key:index,staticClass:"box-item"},[_c('div',{staticClass:"item__name textOverflow",staticStyle:{"max-width":"100px"},attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"item__people"},[_c('div',{staticClass:"people__total"},[_vm._v(" "+_vm._s(item.total)+" ")]),_c('div',{staticClass:"people__boy"},[_vm._v(_vm._s(item.capacityMale))]),_c('div',{staticClass:"people__girl"},[_vm._v(_vm._s(item.capacityFemale))]),_c('div',{staticClass:"people__other"},[_vm._v(_vm._s(item.capacityOther))])])])})],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }