












import { defineComponent } from "@vue/composition-api";
import VScroller from "@/components/Common/VScroller.vue";
import $ from "jquery";
/*import SidebarSettings from "@/components/Settings/SidebarSettings.vue";*/

function checkForSidebar() {
  if ($(".hasSidebar").length != 0) {
    $(".SettingsMasterPage #content").addClass("sidebar");
  } else {
    $(".SettingsMasterPage #content").removeClass("sidebar");
  }
}

export default defineComponent({
  name: "SettingsMasterPage",
  components: {
    VScroller
    /*SidebarSettings*/
  },
  setup(props, context) {
    return {};
  },
  mounted() {
    checkForSidebar();
  },
  updated() {
    checkForSidebar();
  }
});
