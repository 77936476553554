











































































































































import { defineComponent, ref } from "@vue/composition-api";

import ImageUploader from "vue-image-upload-resize/src/components/ImageUploader.vue";
import SidebarProfileDetails from "@/components/Profile/SidebarProfileDetails.vue";
import Loading from "@/components/Common/Loading.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FormButton from "@/components/Form/FormButton.vue";
import { useProfileSettingsStore } from "@/stores/Profile/ProfileSettingsStore";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import FormYesNo from "@/components/Form/FormYesNo.vue";
import FormPassword from "@/components/Form/FormPassword.vue";
import { ApiHelper } from "@/helpers";
import VScroller from "@/components/Common/VScroller.vue";

export default defineComponent({
  name: "ProfileSettingsPage",
  components: {
    ImageUploader,
    SidebarProfileDetails,
    HeaderProfileDetails,
    Loading,
    Select2,
    FormYesNo,
    FormPassword,
    FormButton,
    VScroller
  },
  setup(props, context) {
    const random = Math.random();

    const {
      isArchived,
      formData,
      removeFieldError,
      onSubmit,
      onSendResetLink,
      onSavePermission
    } = useProfileSettingsStore(context);
    const passwordData = ref({
      hasCapital: false,
      has8characters: false,
      hasNumber: false,
      nonHtml: false,
      valid: false
    });
    const checkStrongPassword = (password: string) => {
      passwordData.value = ApiHelper.getPasswordInfo(password);
      formData.value.controls.password.valid = passwordData.value.valid;
    };
    return {
      passwordData,
      checkStrongPassword,
      isArchived,
      random,
      formData,
      removeFieldError,
      onSubmit,
      onSendResetLink,
      onSavePermission
    };
  }
});
