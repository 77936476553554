













































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Header from "@/components/Common/Header.vue";
import { ApiHelper } from "@/helpers";
import moment from "moment";
import { useDashboardStore } from "@/stores/Dashboard/DashboardStore";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import Counter from "@/components/Common/Counter.vue";
import HeaderActions from "@/components/Common/HeaderActions.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import Loading from "@/components/Common/Loading.vue";
import ApexDashboard2 from "@/components/Chart/ApexDashboard2.vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    TableHeaderColumn,
    HeaderActions,
    Counter,
    Header,
    Avatar,
    Loading,
    ApexDashboard2
  },
  data: function() {
    return {
      chartOptions: {
        chart: {
          height: 300,
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        forecastDataPoints: {
          count: 3,
          dashArray: 7
        },
        stroke: {
          width: [10, 4],
          curve: "smooth",
          dashArray: [0, 7]
        },
        colors: ["#3F5447", "#D8D8D8"],
        fill: {
          type: ["gradient", "solid"],
          gradient: {
            shade: "dark",
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
            colorStops: [
              {
                offset: 0,
                color: "#A4CEB4",
                opacity: 1
              },
              {
                offset: 73,
                color: "#3F5447",
                opacity: 1
              },
              {
                offset: 73,
                color: "#BBBBBB",
                opacity: 1
              },
              {
                offset: 100,
                color: "#3F5447",
                opacity: 1
              }
            ]
          }
        },
        yaxis: {
          show: false
        },
        xaxis: {
          categories: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC"
          ],
          labels: {
            // hideOverlappingLabels: true,
            style: {
              colors: "#BFBFBF",
              fontSize: "10px",
              fontWeight: 700,
              fontFamily: "Roboto, sans-serif",
              cssClass: "apexcharts-xaxis-label"
            }
          },
          tooltip: {
            offsetY: 0,
            style: {
              fontSize: "10px"
            }
          }
        },
        grid: {
          show: false
        },
        legend: {
          show: false
        },
        markers: {
          size: [12, 0.1],
          colors: ["#D9D9D9", "#BBBBBB"],
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3
          }
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 200
              },
              forecastDataPoints: {
                dashArray: 5
              },
              stroke: {
                width: [4, 2],
                curve: "smooth",
                dashArray: [0, 5]
              },
              markers: {
                size: [6, 0.1],
                hover: {
                  size: 7,
                  sizeOffset: 2
                }
              }
            }
          }
        ]
      },
      series: [
        {
          name: "Line 1",
          data: [4, 3, 10, 9, 20, 19, 22, 9, 12, 7, 19, 5]
        },
        {
          name: "Line 2",
          data: [20, -2, 4, 3, 10, 30, 15, 3, 6]
        }
      ]
    };
  },
  setup(props, context) {
    const {
      pageData,
      exportCSV,
      chartData,
      barData,
      toggleFilterSelect,
      closeFilterSelect,
      updateFilterView,
      getFilterView,
      loadView,
      showViewItems,
      totalHiddenViewOptions,
      selectedSummaryOptions
    } = useDashboardStore(context);
    return {
      pageData,
      chartData,
      exportCSV,
      barData,
      ApiHelper,
      moment,
      toggleFilterSelect,
      closeFilterSelect,
      updateFilterView,
      getFilterView,
      loadView,
      showViewItems,
      totalHiddenViewOptions,
      selectedSummaryOptions
    };
  }
});
