var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],ref:"itemStatus",staticClass:"item__status have__promt d-flex align-items-center justify-items-center",class:{
    addTopPopOverToolTip: _vm.addTopPopOverToolTip,
    active: _vm.item.id == _vm.openItemId,
    'no-change-permit': _vm.preventChange == true
  }},[_c('div',{staticClass:"status__current",class:{ 'hide-status-options': _vm.hideStatusOptions },on:{"click":_vm.toggleDropdown}},[_c('span',{class:_vm.getLabelClass(_vm.item),attrs:{"title":_vm.item.statusText}})]),(_vm.hideStatusOptions == false)?_c('div',{staticClass:"status__box"},[_c('div',{staticClass:"box__popover"},[_c('ul',_vm._l((_vm.options),function(option){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(!option.disabled),expression:"!option.disabled"}],key:'option-item' + option.value,on:{"click":function () {
              _vm.doChange(_vm.item, option);
              _vm.onClickOutside();
            }}},[_c('div',{staticClass:"li__icon"},[_c('span',{staticClass:"icon",class:_vm.getDropdownClass(option)})]),_c('div',{staticClass:"li__text"},[_vm._v(_vm._s(option.label))])])}),0)])]):_vm._e(),(_vm.hidePercent == false)?_vm._t("text",[_c('div',{staticClass:"status__percent"},[_vm._v(" "+_vm._s(_vm.item.percent || "-")+" ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }