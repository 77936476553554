var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-tabs"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
          name: 'ProfileDetails',
          params: {
            profileId: _vm.profileId
          }
        }}},[_vm._v(" Details ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
          name: 'ProfileDashboard',
          params: {
            profileId: _vm.profileId
          }
        }}},[_vm._v(" Dashboard ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
          name: 'ProfileTags',
          params: {
            profileId: _vm.profileId
          }
        }}},[_vm._v(" Tags ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
          name: 'ProfileEvents',
          params: {
            profileId: _vm.profileId
          }
        }}},[_vm._v(" Events ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
          name: 'ProfileTribes',
          params: {
            profileId: _vm.profileId
          }
        }}},[_vm._v(" Tribes ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }