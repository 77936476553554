

















































































































































































































import { defineComponent, ref } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import Header from "@/components/Common/Header.vue";
import Modal from "@/components/Common/Modal.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import { useSettingsDomainsStore } from "@/stores/Settings/Domains/SettingsDomainsStore";
import { ApiHelper } from "@/helpers";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormToggle from "@/components/Form/FormToggle.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";

export default defineComponent({
  name: "SettingsDomains",
  components: {
    TableHeaderColumn,
    FormToggle,
    FormInput,
    FormSelect,
    Header,
    VAlertMessage,
    SidebarEventDetails,
    HeaderEventDetails,
    Loading,
    Modal,
    Pager,
    Avatar,
    Select2,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    FilterSelectColumn,
    FilterAgesColumn,
    FilterDateColumn
  },
  setup(props, context) {
    const {
      loadList,
      // sort
      updateSortValue,
      popupNewItem,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab
    } = useSettingsDomainsStore(context);

    const timer = ref<any>(null);
    const show = ref<boolean>(false);
    const loading = ref<boolean>(false);

    const checkDomain = async () => {
      popupNewItem.value.formData.controls.siteName.error = "";
      const siteName = popupNewItem.value.formData.controls.siteName.value;
      const domainName = popupNewItem.value.formData.controls.domainName.value;
      if (siteName && domainName) {
        const domain = (siteName + "." + domainName).toLowerCase();
        const result = await ApiHelper.callApi(
          "post",
          "/entities/checkDomain",
          {
            domain,
            id: popupNewItem.value.formData.controls.id.value
          },
          {},
          "core"
        );
        popupNewItem.value.message = result.message || "";
        if (result.status === 1) {
          popupNewItem.value.formData.valid = true;
        } else {
          popupNewItem.value.formData.valid = false;
          popupNewItem.value.formData.controls.siteName.error = result.message;
        }
      }
    };

    const onInputSiteName = async () => {
      popupNewItem.value.formData.controls.siteName.value = popupNewItem.value.formData.controls.siteName.value
        .replace(/[^\w-]+/g, "")
        .replace("_", "");

      if (timer.value) {
        clearTimeout(timer.value);
      }
      if (popupNewItem.value.formData.controls.siteName.value == "") {
        show.value = false;
        popupNewItem.value.formData.controls.siteName.error = "";
      } else {
        show.value = false;
        timer.value = setTimeout(async () => {
          if (popupNewItem.value.formData.controls.siteName.value.length < 3) {
            popupNewItem.value.formData.controls.siteName.error =
              "Please type at least 3 characters";
          } else {
            popupNewItem.value.formData.controls.siteName.error = "";
            loading.value = true;
            await checkDomain();
            loading.value = false;
          }
        }, 300);
      }
    };

    const getDomainNameOptions = async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/entities/platforms",
        {},
        {},
        "core"
      );
      if (result.status === 1) {
        const platforms: { id: number; domain: string }[] =
          result.data.platforms || [];
        popupNewItem.value.formData.controls.domainName.options = platforms.map(
          item => {
            return {
              id: item.domain,
              text: item.domain,
              data: item
            };
          }
        );
      }
    };

    const openPopup = async (id: number) => {
      popupNewItem.value.formData.controls.id.value = id;
      popupNewItem.value.formData.controls.siteName.error = "";
      popupNewItem.value.formData.controls.domainName.error = "";
      popupNewItem.value.formData.controls.siteName.value = "";
      popupNewItem.value.formData.controls.domainName.value = "";
      popupNewItem.value.formData.controls.isDefault.value = false;
      popupNewItem.value.formData.valid = false;
      pageData.value.isLoading = true;
      if (
        popupNewItem.value.formData.controls.domainName.options.length === 0
      ) {
        await getDomainNameOptions();
      }
      if (id) {
        const result = await ApiHelper.callApi(
          "get",
          "/entities/domains/" + id,
          {},
          {},
          "core"
        );
        if (result.status === 1) {
          const domain = result?.data.domain || "";
          const isDefault = result?.data.isDefault || false;
          let siteName = "";
          const tmp: string[] = [];
          const arr = domain.split(".");
          arr.map((value: string, key: number) => {
            if (key == 0) {
              siteName = value;
            } else {
              tmp.push(value);
            }
          });
          const domainName = tmp.join(".");
          popupNewItem.value.formData.controls.siteName.value = siteName;
          popupNewItem.value.formData.controls.domainName.value = domainName;
          popupNewItem.value.formData.controls.isDefault.value = isDefault;
          popupNewItem.value.formData.valid = true;
        } else {
          popupNewItem.value.formData.valid = false;
          ApiHelper.showErrorMessage(result.message, "Oops");
        }
      }
      pageData.value.isLoading = false;
      popupNewItem.value.show = true;
    };

    return {
      loadList,
      openPopup,
      onInputSiteName,
      checkDomain,
      // sort
      updateSortValue,
      popupNewItem,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
