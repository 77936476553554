

















































































import { defineComponent, ref } from "@vue/composition-api";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import Avatar from "vue-avatar/src/Avatar.vue";

import VScroller from "@/components/Common/VScroller.vue";

export default defineComponent({
  name: "SidebarProfileDetailsComponent",
  props: {
    profileId: String,
    title: String
  },
  components: {
    VScroller,
    HeaderTabs,
    Avatar
  },
  setup(props, context) {
    const profileId = parseInt(props.profileId || "0") || 0;

    const sidebarData = ref<{
      isLoading: boolean;
      name: string;
      logo: string;
      totalLinkedProfiles: number;
    }>({
      isLoading: true,
      name: "",
      logo: "",
      totalLinkedProfiles: 0
    });
    (async () => {
      try {
        /*const result = await apolloClient.query({
          query: gql`
            query profile($id: Float!, $getInfo: String!) {
              profile(id: $id, getInfo: $getInfo) {
                id
                firstName
                lastName
                logo
                # subProfiles {
                #   id
                #   firstName
                #   lastName
                #   typeId
                # }
                linkedProfiles {
                  id
                }
              }
            }
          `,
          variables: {
            id: profileId,
            getInfo: "linkedProfiles"
          }
        });
        sidebarData.value.logo = result.data.profile.logo || "";
        sidebarData.value.name =
          result.data.profile.firstName + " " + result.data.profile.lastName;
        sidebarData.value.totalLinkedProfiles =
          result.data.profile.linkedProfiles.length;*/
        sidebarData.value.isLoading = false;
      } catch (err) {
        sidebarData.value.name = "";
        sidebarData.value.isLoading = false;
      }
    })();
    const gotoLink = (route: any) => {
      context.root.$router.replace(route).catch(() => {
        console.log("err");
      });
    };
    return {
      sidebarData,
      gotoLink
    };
  }
});
