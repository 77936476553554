























































import { defineComponent, ref } from "@vue/composition-api";
import OrderByButton from "@/components/Common/OrderByButton.vue";

export default defineComponent({
  name: "FilterSearchColumn",
  components: {
    OrderByButton
  },
  props: {
    label: String,
    placeholder: String,
    model: String,
    data: Object,
    activeTab: String,
    activeValue: String,
    setActiveFilterTab: Function,
    closeFilterTab: Function,
    onChangeValue: Function,
    sortKey: String,
    sortKey2: String,
    sortData: Object,
    onChangeSortValue: Function,
    sortKeyTitle: {
      type: String,
      default: ""
    },
    sortKey2Title: {
      type: String,
      default: ""
    },
    minCharacters: {
      type: Number,
      default: 0
    }
  },
  watch: {
    model: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.searchValue = val;
        }
      }
    }
  },
  setup(props, context) {
    const searchValue = ref<string>(props.model || "");
    const timer = ref<any>(null);
    const searchTimeOut = () => {
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = null;
      }
      timer.value = setTimeout(() => {
        if (props.onChangeValue) {
          props.onChangeValue(props.activeValue, searchValue.value);
        }
      }, 800);
    };

    const onInput = (event: any) => {
      searchValue.value = event.target.value;
    };

    const doClose = () => {
      if (props.setActiveFilterTab) {
        props.setActiveFilterTab("");
      }
    };
    const doSearch = () => {
      if (
        searchValue.value.trim() == "" ||
        (props.minCharacters && searchValue.value.length < props.minCharacters)
      )
        return;

      if (props.onChangeValue) {
        props.onChangeValue(props.activeValue, searchValue.value);
        doClose();
      }
    };

    const doReset = () => {
      searchValue.value = "";
      if (props.onChangeValue) {
        props.onChangeValue(props.activeValue, searchValue.value);
      }
    };

    const onKeyup = (event: any) => {
      if (
        searchValue.value.trim() == "" ||
        (props.minCharacters && searchValue.value.length < props.minCharacters)
      )
        return;

      if (event.keyCode === 13 && props.onChangeValue) {
        props.onChangeValue(props.activeValue, searchValue.value);
        doClose();
      }
    };
    const onClickOutside = () => {
      if (props.activeTab === props.activeValue) {
        doClose();
      }
    };

    const onClickSorting = () => {
      if (props.onChangeSortValue) {
        const currentOrder = props.sortData ? props.sortData.order || "" : "";
        const currentDirection = props.sortData
          ? props.sortData.direction || "2"
          : "2";
        if (props.sortKey !== currentOrder) {
          props.onChangeSortValue(props.sortKey, "2");
        } else {
          props.onChangeSortValue(
            props.sortKey,
            currentDirection === "1" ? "2" : "1"
          );
        }
      }
    };

    const onClickSorting2 = () => {
      if (props.onChangeSortValue) {
        const currentOrder = props.sortData ? props.sortData.order || "" : "";
        const currentDirection = props.sortData
          ? props.sortData.direction || "2"
          : "2";
        if (props.sortKey2 !== currentOrder) {
          props.onChangeSortValue(props.sortKey2, "2");
        } else {
          props.onChangeSortValue(
            props.sortKey2,
            currentDirection === "1" ? "2" : "1"
          );
        }
      }
    };

    return {
      onClickSorting,
      onClickOutside,
      searchValue,
      onInput,
      onKeyup,
      doSearch,
      doReset,
      doClose,
      searchTimeOut,
      onClickSorting2
    };
  }
});
