var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-group"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"group"},[_c('label',{staticClass:"label__full",attrs:{"title":item.reason,"for":_vm.randomId}},[_vm._v(_vm._s(item.p1Name + " - " + item.p2Name))]),_c('div',{staticClass:"group__radio"},[_c('b-form-radio-group',{staticClass:"mb-3",attrs:{"id":((item.p1Id) + "_" + (item.p2Id)),"options":_vm.options,"value-field":"item","text-field":"name","disabled-field":"notEnabled"},on:{"change":function($event){return _vm.changeCallBack($event, item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)])}),0),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"confirm-action",on:{"click":function () {
          _vm.onSelectAll(1);
        }}},[_vm._v(" Accept All Sender's Profile ")]),_c('div',{staticClass:"confirm-action",on:{"click":function () {
          _vm.onSelectAll(2);
        }}},[_vm._v(" Accept All Recevier's Profile ")]),_c('div',{staticClass:"confirm-action",on:{"click":function () {
          _vm.onSelectAll(3);
        }}},[_vm._v(" Ignore All ")]),_c('div',{staticClass:"confirm-action",on:{"click":function () {
          _vm.onSelectAll(4);
        }}},[_vm._v(" Exclude All ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }