













import { defineComponent } from "@vue/composition-api";
import SidebarFamilyDetails from "@/views/Family/SidebarFamilyDetails.vue";
export default defineComponent({
  name: "FamilyDetailsMaster",
  components: {
    SidebarFamilyDetails
  },
  setup() {
    return {};
  }
});
