var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"NavProfileDetails"},[_c('div',{attrs:{"id":"nav-participants"}},[_c('div',{staticClass:"nav-box"},[_c('ul',[_c('router-link',{attrs:{"to":{
            name: 'ProfileEdit',
            params: {
              profileId: _vm.profileId.toString()
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#info","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Profile")])])])]}}])}),_c('router-link',{attrs:{"to":{
            name: 'ProfileDetails',
            params: {
              profileId: _vm.profileId.toString()
            },
            query: _vm.linkQuery
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/event-icons.svg") + "#dashboard","alt":"Dashboard"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Dashboard")])])])]}}])}),_c('router-link',{attrs:{"to":{
            name: 'ProfileEvents',
            params: {
              profileId: _vm.profileId.toString()
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#map","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Events")]),(!_vm.sidebarData.isLoading)?_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalEvents)+" ")]):_vm._e()])])]}}])}),_c('router-link',{attrs:{"to":{
            name: 'ProfileTribes',
            params: {
              profileId: _vm.profileId.toString()
            },
            query: {
              family: (_vm.selectedFamilyId || _vm.sidebarData.familyID || 0) + ''
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#users","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Family/Friends")]),(!_vm.sidebarData.isLoading)?_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalLinkedProfiles)+" ")]):_vm._e()])])]}}])}),_c('router-link',{staticClass:"d-none",attrs:{"to":{
            name: 'ProfileFiles',
            params: {
              profileId: _vm.profileId.toString()
            },
            query: _vm.linkQuery
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#folder","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Files")]),_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalFiles)+" ")])])])]}}])}),_c('router-link',{attrs:{"to":{
            name: 'ProfileTodos',
            params: {
              profileId: _vm.profileId.toString()
            },
            query: _vm.linkQuery
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#folder","alt":"To Dos"}})]),_c('div',{staticClass:"item__title"},[_vm._v("To Do/Files")]),(!_vm.sidebarData.isLoading)?_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalTodos)+" ")]):_vm._e()])])]}}])}),_c('router-link',{attrs:{"to":{
            name: 'ProfileMedicals',
            params: {
              profileId: _vm.profileId.toString()
            },
            query: _vm.linkQuery
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#life_buoy","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Medical")]),(!_vm.sidebarData.isLoading)?_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalMedicals)+" ")]):_vm._e()])])]}}])}),(_vm.$allowedFunctions.includes(_vm.$userFunctions.Message))?_c('router-link',{attrs:{"to":{
            name: 'ProfileMessages',
            params: {
              profileId: _vm.profileId.toString()
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{staticStyle:{"min-width":"22px"},attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#message","alt":"Message"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Message")])])])]}}],null,false,4091416465)}):_vm._e(),(_vm.$allowedFunctions.includes(_vm.$userFunctions.Transactions))?_c('router-link',{attrs:{"to":{
            name: 'ProfileFinancial',
            params: {
              profileId: _vm.profileId.toString()
            },
            query: {
              eventId: _vm.$route.query.eventId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#credit","alt":"Financial"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Financial")])])])]}}],null,false,4247032347)}):_vm._e(),_c('router-link',{attrs:{"to":{
            name: 'ProfileNotes',
            params: {
              profileId: _vm.profileId.toString()
            },
            query: _vm.linkQuery
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#edit2","alt":"Notes"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Notes")]),(!_vm.sidebarData.isLoading)?_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalNotes)+" ")]):_vm._e()])])]}}])}),_c('router-link',{attrs:{"to":{
            name: 'ProfileSettings',
            params: {
              profileId: _vm.profileId.toString()
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{class:{
              active: isActive || isExactActive
            }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#settings","alt":"Settings"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Settings")])])])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }