var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"ProfilePanelComponent"},[_c('div',{staticClass:"preview-screen pl-0",class:{ active: !_vm.isLoading }},[(_vm.isLoading)?_c('div',[_vm._v("Loading...")]):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"box_preview"},[_c('div',{staticClass:"preview__close",on:{"click":_vm.onClose}}),_c('div',{staticClass:"preview__top"},[_c('div',{staticClass:"preview__info"},[_c('div',{staticClass:"info__avatar"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.popupProfilePhoto.show = true}}},[_c('Avatar',{attrs:{"src":_vm.newAvatarUrl || _vm.data.logo,"username":(_vm.data.firstName || '-') + ' ' + (_vm.data.lastName || '-'),"size":60}})],1)]),_c('div',{staticClass:"info__name"},[_vm._v(" "+_vm._s([_vm.data.firstName, _vm.data.lastName].join(" "))+" ")]),_c('div',{staticClass:"info__text"},[_vm._v(" "+_vm._s(_vm.getProfileAge(_vm.data.age))+" / "+_vm._s(_vm.ApiHelper.getGenderName(_vm.data.gender).toLowerCase())+" ")])]),_c('div',{staticClass:"preview__chart"},[_c('div',{staticClass:"chart__item"},[_c('div',{staticClass:"item__img"},[_c('apexchart',{attrs:{"type":"line","height":"35","width":"100","options":{
                  chart: {
                    type: 'line',
                    width: 100,
                    height: 35,
                    sparkline: {
                      enabled: true
                    }
                  },
                  colors: ['#d8a08a'],
                  stroke: {
                    curve: 'smooth',
                    width: 2
                  },
                  tooltip: {
                    fixed: {
                      enabled: false
                    },
                    x: {
                      show: true
                    },
                    y: {
                      formatter: function(y) {
                        return y.toFixed(0);
                      },
                      title: {
                        formatter: function(seriesName) {
                          return 'Actions:';
                        }
                      }
                    },
                    marker: {
                      show: false
                    }
                  },
                  xaxis: {
                    categories: _vm.actionsLast10DaysLabel
                  }
                },"series":[
                  {
                    data: _vm.actionsLast10Days
                  }
                ]}})],1),_c('div',{staticClass:"item__parameter color"},[_vm._v(" "+_vm._s(_vm.data.totalActions || 0)+" Actions ")]),_c('div',{staticClass:"item__title color__gray__bold"},[_vm._v("ACTIVITY (1 YEAR)")])]),_c('div',{staticClass:"chart__item"},[_c('div',{staticClass:"item__img"},[_c('apexchart',{attrs:{"type":"line","height":"35","width":"100","options":{
                  chart: {
                    type: 'line',
                    width: 100,
                    height: 35,
                    sparkline: {
                      enabled: true
                    }
                  },
                  colors: ['#d8a08a'],
                  stroke: {
                    curve: 'smooth',
                    width: 2
                  },
                  tooltip: {
                    fixed: {
                      enabled: false
                    },
                    x: {
                      show: true
                    },
                    y: {
                      formatter: function(y) {
                        return y.toFixed(2);
                      },
                      title: {
                        formatter: function(seriesName) {
                          return 'Balance:';
                        }
                      }
                    },
                    marker: {
                      show: false
                    }
                  },
                  xaxis: {
                    categories: _vm.balance12MonthsLabel
                  }
                },"series":[
                  {
                    data: _vm.balance12Months
                  }
                ]}})],1),_c('div',{staticClass:"item__parameter color",class:{ 'text-danger': _vm.data.balance < 0 }},[_vm._v(" "+_vm._s(_vm.ApiHelper.dollarFormat(_vm.data.balance, false, { useBrackets: true }))+" ")]),_c('div',{staticClass:"item__title color__gray__bold"},[_vm._v(" $ BALANCE (1 YEAR) ")])])])]),((_vm.data.families || []).length > 0)?_c('div',{staticClass:"preview__bottom bg__light"},[_c('VScroller',[_c('div',{staticClass:"family-content",attrs:{"slot":"default"},slot:"default"},_vm._l((_vm.data.families),function(family,familyIndex){return _c('div',{key:familyIndex,staticClass:"clearfix",class:{ 'mt-3': familyIndex > 0 }},[_c('router-link',{staticClass:"bottom__family bg__white color__text",attrs:{"to":{
                  name: 'ProfileTribes',
                  params: { profileId: _vm.data.id },
                  query: { family: family.familyId.toString() }
                }}},[_c('span',[_vm._v(_vm._s(family.familyName))])]),(typeof family.owner != 'undefined')?_c('div',{staticClass:"bottom__info d-flex justify-content-between"},[_c('router-link',{staticClass:"info__text",attrs:{"to":{
                    name: 'ProfileTribes',
                    params: { profileId: family.owner.profileId.toString() },
                    query: { family: family.familyId.toString() }
                  }}},[_c('span',[_vm._v(" "+_vm._s(family.owner.firstName)+" "+_vm._s(family.owner.lastName)+" ")]),(family.owner.age)?_c('span',[_vm._v(", "+_vm._s(family.owner.age))]):_vm._e()]),_c('router-link',{staticClass:"info__arrow",attrs:{"to":{
                    name: 'ProfileDashboard',
                    params: { profileId: family.owner.profileId.toString() }
                  }}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(family.owner.relationTypeName))])])],1):_vm._e(),_vm._l((family.members),function(member,memberIndex){return _c('div',{key:memberIndex,staticClass:"bottom__info d-flex justify-content-between"},[_c('router-link',{staticClass:"info__text",attrs:{"to":{
                    name: 'ProfileDashboard',
                    params: { profileId: member.profileId.toString() }
                  },"title":((member.firstName) + " " + (member.lastName))}},[_c('span',[_vm._v(_vm._s(member.firstName)+" "+_vm._s(member.lastName))]),(member.age)?_c('span',[_vm._v(", "+_vm._s(member.age))]):_vm._e()]),_c('router-link',{staticClass:"info__arrow",attrs:{"to":{
                    name: 'ProfileDashboard',
                    params: { profileId: member.profileId.toString() }
                  }}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(member.relationTypeName))])])],1)})],2)}),0)])],1):_vm._e()]):_vm._e()]),(_vm.popupProfilePhoto.show)?_c('PopupProfileSummary',{attrs:{"profileId":parseInt(_vm.data.id) || 0,"defaultPhoto":_vm.newAvatarUrl || _vm.data.logo},on:{"updatePhoto":function (url) {
        _vm.newAvatarUrl = url;
        _vm.onUpdateProfileAvatar(_vm.data.id, url);
      },"dismiss":function () {
        _vm.popupProfilePhoto.show = false;
      }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }