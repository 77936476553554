




















































































































































































































































































import { defineComponent } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import Counter from "@/components/Common/Counter.vue";
import HeaderActions from "@/components/Common/HeaderActions.vue";
import ApexChart from "@/components/Chart/ApexChart.vue";
import ApexDashboard from "@/components/Chart/ApexDashboard.vue";
import { ApiHelper } from "@/helpers";
import moment from "moment";
import { useFinancialDashboardStore } from "@/stores/Financial/FinancialDashboardStore";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import Header from "@/components/Common/Header.vue";

export default defineComponent({
  name: "FinancialDashboard",
  components: {
    Header,
    TableHeaderColumn,
    HeaderActions,
    VAlertMessage,
    ApexChart,
    Counter,
    ApexDashboard
  },
  setup(props, context) {
    const {
      pageData,
      chartData,
      barData,
      toggleFilterSelect,
      closeFilterSelect,
      updateFilterView,
      getFilterView
    } = useFinancialDashboardStore(context);

    return {
      pageData,
      chartData,
      barData,
      ApiHelper,
      moment,
      toggleFilterSelect,
      closeFilterSelect,
      updateFilterView,
      getFilterView
    };
  }
});
