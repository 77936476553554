var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"group",class:{
      isRequired: _vm.data.required,
      hasError: _vm.data.error !== '',
      'd-flex': _vm.data.isInline,
      'pb-2': _vm.data.isInline
    }},[_c('label',{staticClass:"label__full",class:{
        'w-50': _vm.data.isInline
      },attrs:{"for":_vm.randomId}},[_vm._v(_vm._s(_vm.data.label))]),_c('div',{staticClass:"group__input",class:{
        'w-50': _vm.data.isInline
      }},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:({
          alias: 'currency',
          prefix: '$',
          rightAlign: false,
          autoUnmask: true,
          allowMinus: false
        }),expression:"{\n          alias: 'currency',\n          prefix: '$',\n          rightAlign: false,\n          autoUnmask: true,\n          allowMinus: false\n        }"},{name:"model",rawName:"v-model.trim",value:(_vm.data.value),expression:"data.value",modifiers:{"trim":true}}],staticClass:"input__full",attrs:{"id":_vm.randomId,"placeholder":_vm.data.placeholder,"readonly":_vm.data.readonly,"maxlength":_vm.data.maxlength,"max":_vm.data.max,"min":_vm.data.min},domProps:{"value":(_vm.data.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "value", $event.target.value.trim())},function($event){_vm.data.error = ''}],"blur":function($event){return _vm.$forceUpdate()}}}),_c('span'),(_vm.data.error)?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }