



































































import { defineComponent, ref } from "@vue/composition-api";
import Loader from "@/components/Loader.vue";

export default defineComponent({
  name: "AutoCompleteInput",
  components: {
    Loader
  },
  props: {
    list: {
      type: Array,
      defaultValue: []
    },
    loading: Boolean,
    maxRows: Number,
    indexValue: Number,
    type: String,
    nodataText: String,
    addTopPopOverToolTip: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const limitRows = ref(props.maxRows);
    const renderlist = () => {
      const list = props.list || [];
      const maxRows = limitRows.value || 0;
      if (list.length <= maxRows) {
        return list;
      }

      return list.slice(0, maxRows);
    };

    const moreMsg = () => {
      const list = props.list || [];
      const maxRows = limitRows.value || 0;
      if (list.length <= maxRows) {
        return "";
      }

      return `+ ${list.length - maxRows} More`;
    };

    return {
      limitRows,
      renderlist,
      moreMsg
    };
  }
});
