

















































































































































































































































































































import { ApiHelper } from "@/helpers";
import { defineComponent, ref } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import ImageUploader from "vue-image-upload-resize/src/components/ImageUploader.vue";
import PopupProfileSummary from "@/components/Profile/PopupProfileSummary.vue";
import VScroller from "@/components/Common/VScroller.vue";

export default defineComponent({
  name: "ProfilePanelComponent",
  components: {
    Loading,
    Avatar,
    ImageUploader,
    PopupProfileSummary,
    VScroller
  },
  props: {
    isLoading: Boolean,
    onClose: Function,
    onUpdateProfileAvatar: Function,
    data: Object
  },
  setup(props, context) {
    const newAvatarUrl = ref<string>("");
    const isProcessing = ref<boolean>(false);
    const actionsLast10Days = ref<string[]>(
      props.data?.actionsLast10Days.map((item: string) => item.split("-")[1])
    );
    const actionsLast10DaysLabel = ref<string[]>(
      props.data?.actionsLast10Days.map((item: string) => item.split("-")[0])
    );
    const balance12Months = ref<string[]>(
      props.data?.balance12MonthsForProfile.map(
        (item: string) => item.split("|")[1]
      )
    );
    const balance12MonthsLabel = ref<string[]>(
      props.data?.balance12MonthsForProfile.map(
        (item: string) => item.split("|")[0]
      )
    );

    const getProfileAge = (age: any) => {
      return age ? age + ` year${age > 1 ? "s" : ""} old` : "-";
    };

    const getSubProfileTypeName = (type: any) => {
      return ApiHelper.getSubProfileTypeName(parseInt(type));
    };

    const onChangeFile2 = async (base64: string) => {
      const profileId = props.data ? parseInt(props.data.id) : 0;
      const agreed = confirm("Do you want to change the avatar?");
      if (agreed) {
        isProcessing.value = true;
        const fileBase64 = base64;
        if (fileBase64) {
          const result = await ApiHelper.callApi(
            "put",
            "/profiles/" + profileId + "/logo",
            {
              logoBase64: fileBase64
            },
            {}
          );
          if (result.status === 1) {
            newAvatarUrl.value = result.data.logo || "";
            isProcessing.value = false;
            if (props.data && props.onUpdateProfileAvatar) {
              props.onUpdateProfileAvatar(props.data.id, newAvatarUrl.value);
            }
          } else {
            ApiHelper.showErrorMessage(result.message, "Oops");
          }
        } else {
          alert("Cant get the file");
        }
      }
    };

    const onClickOutside = () => {
      context.emit("close");
    };

    const popupProfilePhoto = ref({
      show: false
    });

    return {
      popupProfilePhoto,
      newAvatarUrl,
      isProcessing,
      onChangeFile2,
      getProfileAge,
      getSubProfileTypeName,
      ApiHelper,
      actionsLast10Days,
      actionsLast10DaysLabel,
      balance12Months,
      balance12MonthsLabel,
      onClickOutside
    };
  }
});
