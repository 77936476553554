var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group",class:{ hasError: _vm.data.error !== '' }},[_c('label',{staticClass:"label__full",class:{ isRequired: _vm.data.required },attrs:{"for":_vm.randomId}},[_vm._v(_vm._s(_vm.data.label))]),_c('div',{staticClass:"group__input"},[_c('AutoCompleteInput',{attrs:{"id":_vm.randomId,"placeholder":_vm.data.placeholder || 'Enter key to search...',"options":_vm.data.foundItems,"inputClass":'input__full ui-autocomplete-input',"emptySearchOnSelect":_vm.data.emptySearchOnSelect || false,"suggestOnFocus":_vm.data.suggestOnFocus || false,"alwaysEmitInput":_vm.data.alwaysEmitInput || true,"endItemMessage":_vm.data.endItemMessage || '',"isACILoading":_vm.data.loading || false || _vm.isACILoading,"allowAddNew":true},on:{"input":function (key) {
          _vm.onSuggestItems(key);
          _vm.data.error = '';
        },"select":function (item) {
          _vm.data.selectItem(item.data);
          _vm.data.error = '';
        },"focus":function (key) {
          _vm.onSuggestItems(key);
        }}}),(_vm.data.error)?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }