import { ref } from "@vue/composition-api";
import { AlertMessageError } from "@/components/VAlertMessage.vue";
import { ApiHelper } from "@/helpers";
import { FormSelect, FormText, PagerItem } from "@/types";
import { SettingsListRows } from "@/helpers/estimateNoOfListRows";

export function useSettingsDomainsStore(context: any) {
  const headerData = ref({
    title: "Domains",
    subTitle: "Settings"
  });
  const formData = ref<{
    valid: boolean;
    isLoading: boolean;
    controls: {
      id: {
        value: number;
      };
      domain: FormText;
      siteName: FormText;
      domainName: FormSelect;
      isDefault: {
        error: string;
        label: string;
        labelOn?: string;
        labelOff?: string;
        size?: string;
        value: boolean;
      };
    };
  }>({
    valid: false,
    isLoading: true,
    controls: {
      id: {
        value: 0
      },
      domain: {
        error: "",
        type: "text",
        value: ""
      },
      siteName: {
        label: "SITE NAME (HTTPS://)",
        value: "",
        placeholder: "Site Name",
        style: "custom",
        error: "",
        maxlength: 50
      },
      domainName: {
        label: "DOMAIN NAME",
        value: "",
        placeholder: "Select a domain",
        error: "",
        style: "custom",
        options: []
      },
      isDefault: {
        error: "",
        label: "IS DEFAULT",
        labelOn: "Yes",
        labelOff: "No",
        size: "",
        value: false
      }
    }
  });
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: {
      id: number;
      isChecked: boolean;
      logo: string;
      firstName: string;
      lastName: string;
      age: string;
      gender: string;
      balance: string;
      events: string;
      familyName: string;
    }[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      name: string;
      active: string;
      age: string;
      cost: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
  }>({
    isLoading: true,
    skip: 0,
    take: SettingsListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {
      name: "",
      active: "",
      cost: "",
      age: ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    }
  });

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: "SettingsDomains",
        params: {},
        query: {
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/entities/domains",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction)
        },
        "core"
      );
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        const list = result.data.domains.map((item: any) => {
          return {
            id: parseInt(item.id),
            isChecked: false,
            domain: item.domain || "",
            preview: item.domain ? "https://" + item.domain : "",
            active: item.active ? "Yes" : "No",
            isDefault: item.isDefault ? "Yes" : "No"
          };
        });

        pageData.value.items = list;
      } else if (result.status === -1) {
        ApiHelper.gotoPage(context, {
          name: "Login",
          query: { destination: "/settings/domains" }
        });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops..");
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };
  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };
  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };
  // filters
  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "name":
        pageData.value.filter.name = value;
        break;
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];

    if (pageData.value.filter.name !== "") {
      filters.push({
        label: "NAME",
        key: pageData.value.filter.name + Math.random(),
        value: pageData.value.filter.name,
        reset: () => {
          pageData.value.filter.name = "";
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }

    return {
      list: filters
    };
  };

  const popupNewItem = ref<{
    message: string;
    show: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
  }>({
    message: "",
    show: false,
    formData: formData,
    removeFieldError: async () => {
      // nothing
    },
    onSubmit: async () => {
      let hasError = false;
      const domain = (
        formData.value.controls.siteName.value +
        "." +
        formData.value.controls.domainName.value
      ).toLowerCase();
      formData.value.controls.domain.error = "";

      if (formData.value.controls.siteName.value === "") {
        hasError = true;
        formData.value.controls.siteName.error = "Site name is required!";
      }
      if (formData.value.controls.domainName.value === "") {
        hasError = true;
        formData.value.controls.domainName.error = "Domain name is required!";
      }
      if (!hasError) {
        ApiHelper.setDataLoading(true);
        const result = await ApiHelper.callApi(
          "put",
          "/entities/domains/" + formData.value.controls.id.value,
          {
            prefix: formData.value.controls.siteName.value,
            domainName: formData.value.controls.domainName.value,
            domain: domain,
            isDefault: formData.value.controls.isDefault.value
          },
          {},
          "core"
        );
        ApiHelper.setDataLoading(false);
        if (result.status == 1) {
          popupNewItem.value.message = "";
          popupNewItem.value.show = false;
          formData.value.controls.domainName.value = "";
          formData.value.controls.siteName.value = "";
          // const newId = parseInt(result.data.id);
          ApiHelper.showSuccessMessage(
            formData.value.controls.id.value ? "Saved" : "Added"
          );
          loadData(1);
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      }
    }
  });

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    loadData(1);
  };

  // init data
  (async () => {
    loadData(1);
  })();

  (async () => {
    await ApiHelper.synzUserViewLogs(context);
  })();

  return {
    loadList,
    // sort
    updateSortValue,
    popupNewItem,
    // page
    headerData,
    pageData,
    // filters
    // --- pager
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    // --- filters
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab
  };
}
