







































import { ref, defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "Login",
  components: {
    Loading
  },
  // apollo:
  setup(props, context) {
    const username = ref("");
    const password = ref("");
    const isLoading = ref(false);
    const error = ref<Error | null>(null);

    const onSubmit = async () => {
      isLoading.value = true;
      try {
        // const loginResult = await apolloClient.mutate({
        //   mutation: gql`
        //     mutation Login($creds: AuthLoginArguments!) {
        //       login(creds: $creds) {
        //         id
        //         username
        //         token
        //         type
        //       }
        //     }
        //   `,
        //   variables: {
        //     creds: {
        //       username: username.value,
        //       password: password.value
        //     }
        //   }
        // });
        // isLoading.value = false;
        // const token = loginResult.data.login.token;
        // const type = loginResult.data.login.type;
        // await onLogin(apolloClient, token);
        // ApiHelper.saveLoggedUser(loginResult.data.login);
        // if (type === "0") {
        //   context.root.$router.push({ name: "Participants" });
        // } else {
        //   context.root.$router.push({ name: "Profiles" });
        // }
      } catch (err) {
        isLoading.value = false;
        error.value = err;
      }
    };

    return {
      error,
      isLoading,
      username,
      password,
      onSubmit
    };
  }
});
