


































































































































































import { defineComponent, ref } from "@vue/composition-api";

import Loading from "@/components/Common/Loading.vue";
import { useSettingsProfileStore } from "@/stores/Settings/SettingsProfileStore";
import { ApiHelper } from "@/helpers";
import FormInput from "@/components/Form/FormInput.vue";
import FormEmail from "@/components/Form/FormEmail.vue";
import FormPassword from "@/components/Form/FormPassword.vue";
import FormSelect from "@/components/Form/FormSelect.vue";

export default defineComponent({
  name: "SettingsProfile",
  components: {
    FormSelect,
    FormPassword,
    FormEmail,
    FormInput,
    Loading
  },
  setup(props, context) {
    const { formData, removeFieldError, onSubmit } = useSettingsProfileStore(
      context
    );
    const passwordData = ref({
      hasCapital: false,
      has8characters: false,
      hasNumber: false,
      nonHtml: false,
      valid: false
    });
    const checkStrongPassword = (password: string) => {
      passwordData.value = ApiHelper.getPasswordInfo(password);
      formData.value.controls.newPassword.valid = passwordData.value.valid;
    };
    return {
      passwordData,
      checkStrongPassword,
      formData,
      removeFieldError,
      onSubmit
    };
  }
});
