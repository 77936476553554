














import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "NoData",
  props: {
    text: {
      type: String,
      defaultValue: "No Records Found"
    },
    size: {
      type: String,
      defaultValue: ""
    }
  },
  setup() {
    return {};
  }
});
