import { ref } from "@vue/composition-api";

import { ApiHelper } from "@/helpers";
import { FormPassword, FormText } from "@/types";
import $ from "jquery";
declare const Stripe: any;

export function useSettingsStripeCredsStoreStore(context: any) {
  const headerData = ref({
    title: "Stripe Configuration",
    subTitle: "Settings"
  });
  const stripeCredsData = ref({
    stripeSecretKey: "",
    stripePublishableKey: ""
  });

  const pageData = ref<{
    isLoading: boolean;
    hasError: boolean;
    message: string;
  }>({
    isLoading: true,
    hasError: false,
    message: ""
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      stripeSecretKey: FormPassword;
      stripePublishableKey: FormText;
    };
  }>({
    isLoading: true,
    controls: {
      stripeSecretKey: {
        required: true,
        error: "",
        label: "Secret Key",
        placeholder: "Secret Key",
        type: "password",
        style: "custom",
        value: "",
        maxlength: 150
      },
      stripePublishableKey: {
        required: true,
        error: "",
        label: "Publishable Key",
        placeholder: "Publishable Key",
        type: "text",
        style: "custom",
        value: "",
        maxlength: 150
      }
    }
  });

  const loadData = async () => {
    pageData.value.hasError = false;
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "get",
      "/entities/stripecreds",
      {},
      {},
      "core"
    );
    ApiHelper.setDataLoading(false);

    if (result.status === 1) {
      pageData.value.hasError = false;
      const resultData = result.data || null;
      if (resultData) {
        const jsonData = resultData || {};
        formData.value.controls.stripeSecretKey.value =
          jsonData.stripeSecretKey || "";
        formData.value.controls.stripePublishableKey.value =
          jsonData.stripePublishableKey || "";
      }
    } else {
      pageData.value.hasError = true;
      pageData.value.message = result.message;
      // ApiHelper.showErrorMessage(result.message);
    }
  };

  const updateStripeCreds = async () => {
    let hasError = false;
    stripeCredsData.value.stripeSecretKey =
      formData.value.controls.stripeSecretKey.value;
    stripeCredsData.value.stripePublishableKey =
      formData.value.controls.stripePublishableKey.value;
    if (stripeCredsData.value.stripeSecretKey === "") {
      hasError = true;
      formData.value.controls.stripeSecretKey.error = "Secret Key is required!";
    }
    if (stripeCredsData.value.stripePublishableKey === "") {
      hasError = true;
      formData.value.controls.stripePublishableKey.error =
        "Publishable Key is required!";
    }
    if (hasError) {
      return;
    }

    if (typeof Stripe == "undefined") {
      // load stripe
      await $.getScript("https://js.stripe.com/v2/", function() {
        // do nothing
      }).fail(function() {
        // console.log("Stripe load failes");
      });
    }
    Stripe.setPublishableKey(stripeCredsData.value.stripePublishableKey);

    Stripe.card.createToken(
      $("#stripeFrmTest"),
      async (status: any, response: any) => {
        const errorData = response.error || {};

        if (!errorData.code || "") {
          ApiHelper.showErrorMessage("Invalid Credentitals", "Oops");
          formData.value.controls.stripePublishableKey.error =
            "Invalid Publishable Key";
          return;
        }
        ApiHelper.setDataLoading(true);
        const result = await ApiHelper.callApi(
          "put",
          "/entities/stripecreds",
          {
            stripeSecretKey: stripeCredsData.value.stripeSecretKey,
            stripePublishableKey: stripeCredsData.value.stripePublishableKey
          },
          {},
          "core"
        );
        ApiHelper.setDataLoading(false);
        if (result.status == 1) {
          ApiHelper.showSuccessMessage("Saved");
        } else {
          ApiHelper.showErrorMessage("Invalid Credentitals", "Oops");
          formData.value.controls.stripeSecretKey.error = "invalid Secret Key";
        }
      }
    );
  };

  (async () => {
    await ApiHelper.synzUserViewLogs(context);
  })();

  return {
    formData,
    // methods
    updateStripeCreds,
    loadData,
    // data
    headerData,
    pageData
  };
}
