<template>
  <div id="q-app">
    <div class="q-ma-md">
      <div class="column">
        <div>
          <div class="q-mb-md">
            Drag blocks to the node tree below using the drag handle
          </div>
          <!-- <div class="row">
	          <div
	            v-for="(block, index) in blocks"
	            :key="index"
	            class="q-mr-md"
	            @drag-start="onDragStartNewBlock"
	            @drag-stop="onDragStopNewBlock"
	          >
	            <template v-slot:preview="{}">
	              <demo-block
	                :title="block.preview.title"
	                :description="block.preview.description"
	              />
	            </template>
	            <template v-slot:node="{}">
	              <demo-node
	                :title="block.node.title"
	                :description="block.node.description"
	                :custom-attribute="block.node.canBeAdded"
	              />
	            </template>
	          </div>
	        </div> -->
        </div>
        <hr />

        <div class="flex-grow overflow-auto" style="width:100%;">
          <!--          <flowy
            class="q-mx-auto"
            :nodes="nodes"
            :beforeMove="beforeMove"
            :beforeAdd="beforeAdd"
            @add="add"
            @move="move"
            @remove="remove"
            @drag-start="onDragStart"
          ></flowy>-->

          <FlowyApp />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import _ from "lodash";
import FlowyApp from "@/components/Flowy/FlowyApp";

/* Vue.use(VueLodash, { lodash: lodash });

const DemoNode = {
  data() {
    return {
      text: "This is component A"
    };
  },
  props: ["remove", "node", "title", "description"],
  template: `
    <q-card flat bordered class="my-card bg-white q-pa-md">
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-h6">{{ title }}</div>
        </div>

        <div class="col-auto">
          <flowy-drag-handle>
            <q-btn size="sm" color="grey-7" round flat icon="drag_handle" />
          </flowy-drag-handle>
        </div>
      </div>

      <div class="q-py-md" v-html="description"/>
      <q-btn color="primary" class="q-pa-none" no-caps @click="remove()">Remove</q-btn>
    </q-card>
  `
};

const DemoBlock = {
  data() {
    return {
      text: "This is component A"
    };
  },
  props: ["remove", "node", "title", "description"],
  template: `
    <q-card flat bordered class="q-px-md q-py-sm row items-center justify-between">
      <div class="text-subtitle1">{{ title }}</div>
      <flowy-drag-handle>
        <q-btn color="grey-7" size="sm" class="q-ml-md" round flat icon="drag_handle" />
      </flowy-drag-handle>
    </q-card>
  `
};

Vue.component("demo-block", DemoBlock);
Vue.component("demo-node", DemoNode);*/

export default {
  name: "Flowy",
  components: {
    FlowyApp
    /*DemoNode,
		DemoBlock*/
  },
  methods: {}
};
</script>
