import { render, staticRenderFns } from "./TransactionDetails.vue?vue&type=template&id=b636ead8&scoped=true&"
import script from "./TransactionDetails.vue?vue&type=script&lang=ts&"
export * from "./TransactionDetails.vue?vue&type=script&lang=ts&"
import style0 from "./TransactionDetails.vue?vue&type=style&index=0&id=b636ead8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b636ead8",
  null
  
)

export default component.exports