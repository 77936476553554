








import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "MessagingDashboardPage",
  components: {},
  setup(props, context) {
    return {};
  },
  beforeRouteUpdate(to, from, next) {
    next();
  }
});
