var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.$route.fullPath,staticClass:"EventDetailsMasterPage MasterPage"},[_c('div',{staticClass:"has_participants",attrs:{"id":"content"}},[_c('router-view',{ref:"insideMasterRef"})],1),(
      ![
        'EventDetails',
        'EventDashboard',
        'EventMedical',
        'EventCabins',
        'EventCabinDetails',
        'EventAtCamp',
        'EventTransportation',
        'EventSettings',
        'EventFinancial',
        'EventCabinsDormGenerate',
        'EventSchedule'
      ].includes(_vm.$route.name)
    )?_c('SidebarEventDetails',{ref:"SidebarEventDetailsRef",attrs:{"event-id":_vm.$route.params.eventId}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }