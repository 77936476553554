



































































/*import { defineComponent } from "@vue/composition-api";

import Loading from "@/components/Common/Loading.vue";
import Select2 from "v-select2-component/src/Select2.vue";*/
import SidebarCampaignAdd from "@/components/Messaging/SidebarCampaignAdd.vue";
import Flowy from "@/components/Messaging/flowy.vue";

export default {
  name: "MessagingCampaignsAddWorkflow",
  components: {
    /*Loading,
    Select2,*/
    SidebarCampaignAdd,
    Flowy
  }
};
