




























































































































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import OrderByButton from "@/components/Common/OrderByButton.vue";
import VScroller from "@/components/Common/VScroller.vue";

export default defineComponent({
  name: "FilterCheckboxesColumn",
  components: {
    VScroller,
    OrderByButton
  },
  props: {
    label: String,
    selectAllLabel: String,
    subLabel: {
      type: String,
      default: ""
    },
    placeholder: String,
    model: String,
    data: Object,
    otherOption: Object,
    options: Array,
    activeTab: String,
    activeValue: String,
    setActiveFilterTab: Function,
    closeFilterTab: Function,
    onChangeValue: Function,
    onResetValue: Function,
    onSelectAll: Function,
    onClearAll: Function,
    onApply: Function,
    sortKey: String,
    sortData: Object,
    onChangeSortValue: Function,
    isShowApplyButton: {
      type: Boolean,
      default: true
    },
    isShowClearAllButton: {
      type: Boolean,
      default: false
    },
    lazyLoad: {
      type: Boolean,
      default: false
    },
    isACILoading: {
      type: Boolean,
      default: false
    },
    lazyLoadPlaceholder: {
      type: String,
      default: ""
    },
    searchValue: {
      type: Object,
      default: () => ({
        value: ""
      })
    },
    position: {
      type: String,
      default: ""
    },
    hideFilter: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const timer = ref<any>(null);
    const isActive = ref<boolean>(true);
    const doClose = () => {
      if (props.setActiveFilterTab) {
        props.setActiveFilterTab("");
      }
    };
    const onClickOutside = () => {
      if (props.activeTab === props.activeValue) {
        doClose();
      }
    };
    const onMouseOver = (e: any) => {
      if (timer.value) {
        clearTimeout(timer.value);
      }
      isActive.value = true;
    };
    const onMouseLeave = () => {
      isActive.value = false;
      timer.value = setTimeout(() => {
        if (props.activeTab === props.activeValue && !isActive.value) {
          // doClose();
        }
      }, 1000);
    };
    const onClickSorting = () => {
      if (props.onChangeSortValue) {
        const currentOrder = props.sortData ? props.sortData.order || "" : "";
        const currentDirection = props.sortData
          ? props.sortData.direction || "2"
          : "2";
        if (props.sortKey !== currentOrder) {
          props.onChangeSortValue(props.sortKey, "2");
        } else {
          props.onChangeSortValue(
            props.sortKey,
            currentDirection === "1" ? "2" : "1"
          );
        }
      }
    };
    const searchItems = () => {
      if (timer.value) {
        clearTimeout(timer.value);
      }
      timer.value = setTimeout(async () => {
        context.emit("searchItems", props.searchValue.value);
      }, 300);
    };

    const doReset = () => {
      props.searchValue.value = "";
      context.emit("searchItems", "");
    };

    const totalOptions = computed(() => {
      let options = [...(props.options || [])];
      options = options.filter((item: any) => (item.hide || false) == false);
      return options.length;
    });

    const doChangeValue = (item: any) => {
      if (props.onChangeValue) {
        props.onChangeValue(item);
      }
    };

    return {
      isActive,
      onClickSorting,
      onMouseOver,
      onMouseLeave,
      onClickOutside,
      searchItems,
      doReset,
      doChangeValue,
      totalOptions
    };
  }
});
