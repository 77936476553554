var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popover-box",class:{
        addTopPopOverToolTipP: _vm.addTopPopOverToolTip
      },attrs:{"id":"lasttooltip"}},[_c('div',{staticClass:"popover"},[_c('div',{staticClass:"arrow",class:{
            addTopPopOverToolTipA: _vm.addTopPopOverToolTip
        }}),_c('div',{staticClass:"popover-content"},[_c('div',{staticClass:"popover-content-inner"},[(_vm.loading)?_c('Loader'):(_vm.list.length === 0)?_c('div',{staticClass:"noData",staticStyle:{"margin":"auto","margin-top":"24px"}},[_vm._v(" "+_vm._s(_vm.nodataText || "No Data Found")+" ")]):_c('perfect-scrollbar',{staticClass:"ps",attrs:{"options":{
            wheelPropagation: false,
            suppressScrollX: true,
            maxScrollbarLength: 50
          }}},[_c('ul',{staticClass:"list-group list-group-flush"},[_vm._l((_vm.renderlist()),function(item){return _c('li',{key:item.id,staticClass:"list-group-item text-left"},[(_vm.type == 'user')?_c('router-link',{staticClass:"removeGrpTblHover text-left",attrs:{"to":{
                  name: 'SettingsUserEdit',
                  params: { id: item.id + '' },
                  query: { tab: 'accountTab' }
                }}},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('a',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),(_vm.moreMsg)?_c('li',{staticClass:"list-group-item no-border-bottom",on:{"click":function($event){_vm.limitRows = _vm.limitRows + _vm.maxRows}}},[_c('i',{staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(_vm.moreMsg()))])]):_vm._e()],2)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }