









import { computed, defineComponent } from "@vue/composition-api";
import $ from "jquery";

function requirementsMessage() {
  $("#screenSizeWarning").remove();
  $("body").prepend(
    '<div id="screenSizeWarning"><div class="tooSmallOverlay"><div class="overlayContainer">THE CAMP360 ADMIN SYSTEM IS QUITE ROBUST AND, AS SUCH, <span style="color: #FF5C00;">REQUIRES A DESKTOP TO MANAGE THE FEATURESET</span>.  AN UPCOMING RELEASE WILL ALLOW FOR MOBILE DASHBOARDING AND MINIMAL MANAGEMENT FUNCTIONS AS WE ROLL OUT A MORE COMPREHENSIVE MOBILE EXPERIENCE.  STAY TUNED, AND IN THE MEANTIME, PLEASE ACCESS THE PORTAL FROM A NON-MOBILE DEVICE.</div></div></div>'
  );
}

function checkScreenSize() {
  let winWidth = $(window).width() || 0;
  const winHeight = $(window).height() || 0;
  const contentHeight = $("#wrap-content").height() || 0;
  const scrollBar = 17;

  if (contentHeight > winHeight) {
    winWidth = winWidth + scrollBar;
  }

  $("body").attr("window-width", winWidth);
  $("body").attr("window-height", winHeight);

  if (winWidth < 1366 || winHeight < 768) {
    requirementsMessage();
  } else {
    if (!$("#launch-manager").is(":visible")) {
      $("body").removeClass("no-scroll");
    }

    $("#screenSizeWarning").remove();
  }
}

$(window).on("resize", function() {
  checkScreenSize();
});

export default defineComponent({
  name: "App",
  components: {},
  setup(props, context) {
    const loadBrowserCheck = () => {
      checkScreenSize();
    };

    const pageClass = computed(() => {
      const routeName = context.root.$route.name;
      return `${routeName}-route`;
    });

    return {
      loadBrowserCheck,
      pageClass
    };
  },
  mounted() {
    this.loadBrowserCheck();
  }
});
