





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import { useProfilesAddStore } from "@/stores/Profile/ProfilesAddStore";

import ImageUploader from "vue-image-upload-resize/src/components/ImageUploader.vue";
import DatePicker from "vue2-datepicker";
import Loading from "@/components/Common/Loading.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import { ApiHelper } from "@/helpers";
import $ from "jquery";
import FormDate from "@/components/Form/FormDate.vue";
import directives from "@/helpers/directives";
import FormYesNo from "@/components/Form/FormYesNo.vue";
import FormPassword from "@/components/Form/FormPassword.vue";
import FormPhoto from "@/components/Form/FormPhoto.vue";
import Inputmask from "inputmask";
import FormInput from "@/components/Form/FormInput.vue";
import FormEmail from "@/components/Form/FormEmail.vue";
import FormTags from "@/components/Form/FormTags.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormDatePicker from "@/components/Form/FormDatePicker.vue";
import VScroller from "@/components/Common/VScroller.vue";

export default defineComponent({
  name: "ProfileNewPage",
  components: {
    FormEmail,
    FormInput,
    FormYesNo,
    FormDate,
    ImageUploader,
    DatePicker,
    Loading,
    Select2,
    AutoCompleteInput,
    FormPassword,
    FormPhoto,
    FormTags,
    FormSelect,
    FormDatePicker,
    VScroller
  },
  directives: directives,
  setup(props, context) {
    const {
      onAddTag,
      onSelectTag,
      onRemoveLastTag,
      onRemoveTag,
      onSuggestTags,
      changeProfileAvatar,
      formData,
      removeFieldError,
      onChangeFile,
      onResetFile,
      onSubmit,
      suggestFamily,
      closeFamilyFound,
      showConfirmRelation,
      closeAllFamilyConfirm,
      confirmRelationtype,
      updateFamilyMembers,
      suggestFamilyMember,
      familyTypeOptions,
      isACILoading
    } = useProfilesAddStore(context);
    return {
      onAddTag,
      onSelectTag,
      onRemoveLastTag,
      onRemoveTag,
      onSuggestTags,
      changeProfileAvatar,
      stripTags: ApiHelper.stripTags,
      formData,
      removeFieldError,
      onChangeFile,
      onResetFile,
      onSubmit,
      suggestFamily,
      closeFamilyFound,
      showConfirmRelation,
      closeAllFamilyConfirm,
      confirmRelationtype,
      updateFamilyMembers,
      suggestFamilyMember,
      familyTypeOptions,
      isACILoading
    };
  },
  mounted() {
    const _formData = this.formData;
    // apply mask for phone
    $(`input[inputmask=phonenumber]`).each(function(i, obj) {
      Inputmask("(999) 999-9999", {
        autoUnmask: true,
        onBeforePaste: (pastedValue: string, opts: any) => {
          _formData.controls.phone.value = pastedValue;
          $(obj).val(pastedValue);
          return pastedValue;
        }
      }).mask(obj);
    });
  }
});
