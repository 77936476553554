import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormEmail, FormPassword, FormSelect, FormText } from "@/types";

export function useSettingsProfileStore(context: any) {
  const selectedId = parseInt(ApiHelper.getLoggedUser().id) || 0;
  if (selectedId == 0) {
    ApiHelper.gotoPage(context, { name: "Login" });
  }
  const itemEditInput = ref<{
    email: string;
    firstName: string;
    lastName: string;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    defaultMainPage: string;
    selectedTimezone: number;
  }>({
    email: "",
    firstName: "",
    lastName: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    defaultMainPage: "",
    selectedTimezone: 0
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      email: FormEmail;
      firstName: FormText;
      lastName: FormText;
      currentPassword: FormPassword;
      newPassword: FormPassword;
      confirmPassword: FormPassword;
      defaultMainPage: FormSelect;
      timezones: FormSelect;
    };
  }>({
    isLoading: true,
    controls: {
      email: {
        required: true,
        error: "",
        label: "Email",
        placeholder: "Enter Email Address",
        type: "email",
        value: "",
        style: "custom"
      },
      firstName: {
        required: true,
        error: "",
        label: "First Name",
        placeholder: "First Name",
        maxlength: 150,
        type: "text",
        value: "",
        style: "custom"
      },
      lastName: {
        required: true,
        error: "",
        label: "Last Name",
        placeholder: "Last Name",
        maxlength: 150,
        type: "text",
        value: "",
        style: "custom"
      },
      currentPassword: {
        required: true,
        error: "",
        label: "Current Password",
        placeholder: "Current Password",
        type: "password",
        value: "",
        style: "custom"
      },
      newPassword: {
        required: true,
        error: "",
        label: "New Password",
        placeholder: "New Password",
        type: "password",
        value: "",
        style: "custom"
      },
      confirmPassword: {
        required: true,
        error: "",
        label: "Confirm Password",
        placeholder: "Confirm Password",
        type: "password",
        value: "",
        style: "custom"
      },
      defaultMainPage: {
        error: "",
        label: "Default page when logged in",
        value: "Profiles",
        style: "custom",
        options: []
      },
      timezones: {
        error: "",
        label: "Timezones",
        value: "",
        style: "custom",
        options: []
      }
    }
  });
  const onSubmit = async (event: any) => {
    event.preventDefault();
    itemEditInput.value.email = formData.value.controls.email.value
      .trim()
      .toLowerCase();
    itemEditInput.value.firstName = formData.value.controls.firstName.value.trim();
    itemEditInput.value.lastName = formData.value.controls.lastName.value.trim();
    itemEditInput.value.currentPassword = formData.value.controls.currentPassword.value.trim();
    itemEditInput.value.newPassword = formData.value.controls.newPassword.value.trim();
    itemEditInput.value.confirmPassword = formData.value.controls.confirmPassword.value.trim();
    itemEditInput.value.defaultMainPage =
      formData.value.controls.defaultMainPage.value;
    itemEditInput.value.selectedTimezone = parseInt(
      formData.value.controls.timezones.value
    );

    let hasError = false;
    if (itemEditInput.value.email === "") {
      hasError = true;
      formData.value.controls.email.error = "Email is required!";
    } else {
      if (!ApiHelper.validateEmail(itemEditInput.value.email)) {
        hasError = true;
        formData.value.controls.email.error = "Email is invalid!";
      }
    }
    if (itemEditInput.value.firstName === "") {
      hasError = true;
      formData.value.controls.firstName.error = "First Name is required!";
    }
    if (itemEditInput.value.lastName === "") {
      hasError = true;
      formData.value.controls.lastName.error = "Last Name is required!";
    }

    if (itemEditInput.value.currentPassword !== "") {
      if (itemEditInput.value.newPassword === "") {
        hasError = true;
        formData.value.controls.newPassword.error = "New Password is required!";
      }
      if (itemEditInput.value.confirmPassword === "") {
        hasError = true;
        formData.value.controls.confirmPassword.error =
          "Confirm Password is required!";
      }
      if (
        itemEditInput.value.newPassword !== "" &&
        itemEditInput.value.confirmPassword !== "" &&
        itemEditInput.value.newPassword !== itemEditInput.value.confirmPassword
      ) {
        hasError = true;
        formData.value.controls.confirmPassword.error =
          "Confirm Password is not match!";
      }
    } else {
      if (
        itemEditInput.value.newPassword ||
        itemEditInput.value.confirmPassword
      ) {
        hasError = true;
        formData.value.controls.currentPassword.error =
          "Current Password is required!";
      }
    }

    if (!hasError) {
      if (
        itemEditInput.value.currentPassword &&
        itemEditInput.value.newPassword &&
        !formData.value.controls.newPassword.valid
      ) {
        formData.value.controls.newPassword.error =
          "The new password is invalid. Please check and update it.";
        ApiHelper.showErrorMessage(
          "The new password is invalid. Please check and update it.",
          "Oops"
        );
        return;
      }

      formData.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "put",
        "/users/profile",
        itemEditInput.value,
        {},
        "core"
      );
      formData.value.isLoading = false;
      if (result.status == 1) {
        formData.value.controls.currentPassword.value = "";
        formData.value.controls.newPassword.value = "";
        formData.value.controls.confirmPassword.value = "";
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateLoginTZO(
          result.data.tzo || undefined,
          result.data.newToken || ""
        );
        ApiHelper.showSuccessMessage(result.message);
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    }
  };

  const initDefaultMainPageOptions = () => {
    // set defaultMainPage based on permission
    const $this: any = context.root;
    const options = [
      {
        id: "Dashboard",
        text: "Dashboard"
      }
    ];
    if ($this.$allowedFunctions.includes($this.$userFunctions.ViewPeople)) {
      options.push({
        id: "Profiles",
        text: "People"
      });
    }
    if ($this.$allowedFunctions.includes($this.$userFunctions.ViewEvents)) {
      options.push({
        id: "Events",
        text: "Events"
      });
    }
    if ($this.$allowedFunctions.includes($this.$userFunctions.Dashboard)) {
      options.push({
        id: "FinancialDashboard",
        text: "Finance"
      });
    }
    if ($this.$allowedFunctions.includes($this.$userFunctions.ViewReports)) {
      options.push({
        id: "Reports",
        text: "Reports"
      });
    }
    formData.value.controls.defaultMainPage.options = options;
  };

  // init data
  (async () => {
    initDefaultMainPageOptions();

    const result = await ApiHelper.callApi(
      "get",
      "/users/profile",
      {},
      {},
      "core"
    );
    formData.value.isLoading = false;
    if (result.status === 1) {
      const item = result.data || {};
      formData.value.controls.email.value = item.email || "";
      formData.value.controls.firstName.value = item.firstName || "";
      formData.value.controls.lastName.value = item.lastName || "";

      const defaultMainPage = item.defaultMainPage || "";
      if (defaultMainPage != "") {
        formData.value.controls.defaultMainPage.value = defaultMainPage;
      }

      formData.value.controls.timezones.options = (item.timezones || []).map(
        (t: any) => ({
          id: t.tzid,
          text: t.displayname
        })
      );
      if (item.selectedTimezone) {
        formData.value.controls.timezones.value = item.selectedTimezone;
      }
    } else {
      ApiHelper.showErrorMessage(result.message);
    }
  })();

  const removeFieldError = (name: string) => {
    // do nothing
  };

  const updateLoginTZO = (tzo: number, newToken: string) => {
    const userData = ApiHelper.getLoggedUser();
    const currentTZO = userData.tzo || undefined;
    if ((!currentTZO || currentTZO != tzo) && tzo) {
      userData.tzo = tzo;
      if (newToken != "") {
        userData.token = newToken;
      }
      ApiHelper.saveLoggedUser(userData);
    }
  };

  (async () => {
    await ApiHelper.synzUserViewLogs(context);
  })();

  return {
    formData,
    removeFieldError,
    onSubmit
  };
}
