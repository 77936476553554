








































































import { defineComponent } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import VScroller from "@/components/Common/VScroller.vue";

declare const window: any;
require("formBuilder/dist/form-render.min.js");
import ProfileDetails from "@/components/modules/ProfileDetails.vue";
import RoommateRequest from "@/components/modules/RoommateRequest.vue";
import CampStoreFund from "@/components/modules/CampStoreFund.vue";
import ParentGuardian from "@/components/modules/ParentGuardian.vue";
import AddOns from "@/components/modules/AddOns.vue";
import SignaturePad from "signature_pad";
import $ from "jquery";

export default defineComponent({
  name: "PopupPreviewApplication",
  props: {
    siderbar: Array,
    title: String
  },
  components: {
    Modal,
    VScroller,
    ProfileDetails,
    RoommateRequest,
    CampStoreFund,
    ParentGuardian,
    AddOns
  },
  setup(props, context) {
    const applyCustomWidth = (renderedForm: any) => {
      renderedForm.find("> div").addClass("col-12");
      // apply custom width for each module
      renderedForm
        .find("[class*='custom-w-']")
        .each(function(i: number, obj: any) {
          const classList = obj.className.split(" ");
          const customW = classList.find(
            (item: any) => item.indexOf("custom-w-") != -1
          );
          const customWClass = customW ? customW.replace("custom-w-", "") : "";
          if (customWClass != "") {
            const type = $(obj).prop("type");
            let parentNode = null;
            if (type == "checkbox" || type == "radio") {
              parentNode = $(obj).closest(".form-group");
            } else {
              parentNode = $(obj).parent();
            }
            if (parentNode.length) {
              parentNode.addClass(customWClass);
              if (customWClass != "col-12") {
                parentNode.removeClass("col-12");
              }
              $(obj).removeClass(customWClass);
            }
          }
        });
    };
    const previewApplication = async () => {
      // render forms
      setTimeout(async () => {
        for (const item0 of props.siderbar || []) {
          const item: any = item0;
          if ((item.isSystem || false) == false) {
            const popupModuleBuilder: any = $(
              "#moduleBuilder-item-" + item.entityAppModuleId
            );
            item.formRenderInstance = await popupModuleBuilder.formRender({
              formData: item.appModuleJson
            });
            const renderedForm = popupModuleBuilder.find(
              "> .rendered-form:first-child"
            );
            if (renderedForm.length) {
              renderedForm.addClass("row");
              applyCustomWidth(renderedForm);
            }
          }
        }
      }, 50);
    };
    return {
      previewApplication
    };
  },
  async mounted() {
    window.fbControls = [];
    window.fbControls.push((controlClass: any) => {
      class ControlSignature extends controlClass {
        build() {
          return `
          <div>
              <div style="background-color: #eee; padding: 10px;">
                <canvas id="${this.config.name}"></canvas>
              </div>
          </div>`;
        }

        onRender() {
          const canvas: any = document.getElementById(this.config.name);
          new SignaturePad(canvas, {
            backgroundColor: "rgb(255, 255, 255)"
          });
        }
      }

      controlClass.register("signature", ControlSignature);
      return ControlSignature;
    });
    window.fbControls.push((controlClass: any) => {
      class ControlDob extends controlClass {
        build() {
          const months = [];
          for (let i = 1; i < 13; i++) {
            months.push(i);
          }
          const days = [];
          for (let i = 1; i < 32; i++) {
            days.push(i);
          }
          const years = [];
          for (let i = 1971; i < new Date().getFullYear() + 1; i++) {
            years.push(i);
          }

          return `
              <div class="row form-group ${
                this.config.className
              } shadow-none pr-0 d-flex" style="padding:0; margin: 0; border: none">
                <div class="col-lg-4 col-12 pl-0">
                  <select type="text" class="form-control px-3 text-center">
                    <option value="">Month</option>
                    ${months.map(value => {
                      return `<option value="${value}">${value}</option>`;
                    })}
                  </select>
                </div>
                <div class="col-lg-3 col-12 p-0">
                  <select class="form-control px-3 text-center input__full">
                    <option value="">Day</option>
                    ${days.map(value => {
                      return `<option value="${value}">${value}</option>`;
                    })}
                  </select></div>
                <div class="col-lg-4 col-12 pr-0">
                  <select type="text" class="form-control px-3 text-center">
                    <option value="">Year</option>
                    ${days.map(value => {
                      return `<option value="${value}">${value}</option>`;
                    })}
                  </select>
                </div>
              </div>`;
        }
      }

      controlClass.register("dob", ControlDob);
      return ControlDob;
    });
    window.fbControls.push((controlClass: any) => {
      class ControlParentGuardian extends controlClass {
        build() {
          return `
                    <div class="row form-group ${this.config.className} shadow-none pr-0 d-flex" style="padding:0; margin: 0; border: none">
                      <h1>ParentGuardian</h1>
                    </div>`;
        }
      }

      controlClass.register("parent_guardian", ControlParentGuardian);
      return ControlParentGuardian;
    });
    await this.previewApplication();
  }
});
