




























































































import { defineComponent, ref } from "@vue/composition-api";
import Header from "@/components/Common/Header.vue";
import { useReportEditStore } from "@/stores/Report/ReportEditStore";
import { ApiHelper } from "@/helpers";
import Loading from "@/components/Common/Loading.vue";
import VScroller from "@/components/Common/VScroller.vue";
import $ from "jquery";

export default defineComponent({
  name: "ReportDetail",
  components: {
    Loading,
    Header,
    VScroller
  },
  setup(props, context) {
    const { headerData, pageData, loadData, saveReport } = useReportEditStore(
      context
    );
    const selectedId = parseInt(context.root.$route.params.id) || 0;
    const headers = ref([]);
    const labels = ref([]);
    const reportData = ref([]);

    // init data
    (async () => {
      const query: any = context.root.$route.query;
      await loadData();
      pageData.value.isLoading = true;
      const response = await ApiHelper.callApi(
        "get",
        `/reports/${selectedId}/preview`,
        {},
        { isCoreReport: parseInt(query.isCoreReport || 0) }
      );
      if (response.status == 1) {
        headers.value = (response.data.columnHeaders || "")
          .toUpperCase()
          .split(",");
        labels.value = (response.data.columnLabels || "").split("|");
        reportData.value = response.data.items || [];
      }
      pageData.value.isLoading = false;
      setTimeout(() => {
        try {
          const vscroller: any = context.refs.vscroller;
          const scrollbar: any = vscroller.$refs.scrollbar;
          scrollbar.update();
        } catch (error) {
          // console.log(error);
        }
        $(".ReportDetailPage .content-inside.loading").removeClass("loading");
      }, 300);
    })();

    return {
      headerData,
      pageData,
      loadData,
      saveReport,
      reportData,
      headers,
      labels
    };
  }
});
