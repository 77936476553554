





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { computed, defineComponent, ref } from "@vue/composition-api";

import Loading from "@/components/Common/Loading.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import { useEventSettingsStore } from "@/stores/Event/EventSettingsStore";
import FormButton from "@/components/Form/FormButton.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import Modal from "@/components/Common/Modal.vue";
import BackButton from "@/components/Common/BackButton.vue";
import { ApiHelper } from "@/helpers";
import FormDate from "@/components/Form/FormDate.vue";
import EventAddons from "@/components/Event/EventAddons.vue";
import directives from "@/helpers/directives";
import moment from "moment";
import EventDiscounts from "@/components/Event/EventDiscounts.vue";
import EventServices from "@/components/Event/EventServices.vue";
import EventPtypes from "@/components/Event/EventPtypes.vue";
import FormToggle from "@/components/Form/FormToggle.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormTags from "@/components/Form/FormTags.vue";
import FormDatePicker from "@/components/Form/FormDatePicker.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import VScroller from "@/components/Common/VScroller.vue";
import PopupEditCostCenter from "@/components/Event/PopupEditCostCenter.vue";
import PopupEditEventType from "@/components/Event/PopupEditEventType.vue";
import EventGroups from "@/components/Event/EventGroups.vue";

export default defineComponent({
  name: "EventSettingsPage",
  components: {
    FormInput,
    FormSelect,
    FormDate,
    FormDatePicker,
    SidebarEventDetails,
    Loading,
    Select2,
    AutoCompleteInput,
    Modal,
    FormButton,
    BackButton,
    EventAddons,
    EventDiscounts,
    EventServices,
    EventPtypes,
    FormToggle,
    FormTags,
    HeaderEventDetails,
    VScroller,
    PopupEditCostCenter,
    PopupEditEventType,
    EventGroups
  },
  directives: directives,
  setup(__props, context) {
    const isShowEventService = ref<boolean>(true);
    const dataEventService = ref<string>("");
    const isShowEventPType = ref<boolean>(true);
    const dataEventPType = ref<string>("");
    const isShowEventDiscount = ref<boolean>(true);
    const dataEventDiscount = ref<string>("");
    const isShowEventGroup = ref<boolean>(true);
    const dataEventGroup = ref<string>("");
    const {
      pageData,
      onSuggestTags,
      onAddTag,
      onRemoveTag,
      onSelectTag,
      onRemoveLastTag,
      updateLocation,
      suggestLocation,
      formData,
      linkEventServices,
      linkEventParticipants,
      // showLinkParticipant,
      removeFieldError,
      onSubmit,
      suggestPTypes,
      selectPType,
      popupNewPTypeCol,
      capacityInput,
      isACILoading,
      updatePtypesList,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onRemoveCostCenter,
      onSelectCostCenter,
      onAddNewCostCenter,
      popupCostCenter,
      onTabPostingCostCenter,
      isAllowAddNewEventType,
      onAddNewEventType,
      popupEventType,
      onRemoveEventType,
      onSuggestEventType,
      onRemoveLastEventType,
      onSelectEventType
    } = useEventSettingsStore(context);

    const updateEndDate = () => {
      if (formData.value.controls.tsStart.value) {
        const selectedStartDate = moment(
          formData.value.controls.tsStart.value
        ).add(7, "day");
        formData.value.controls.tsEnd.value = selectedStartDate.toDate();
        const tsEndDateChild: any = context.refs.tsEndDatePicker;
        if (tsEndDateChild) {
          tsEndDateChild.openDate(tsEndDateChild.randomId);
        }
      }
      formData.value.controls.tsStart.error = "";
      formData.value.controls.tsEnd.error = "";
    };

    const subItemSelectCostCenter = (item: any) => {
      const costCenterNumber = item.id || "";
      if (costCenterNumber == "") return;

      if (formData.value.controls.eventCostCenters.value.length == 0) {
        formData.value.controls.eventCostCenters.value = [
          {
            id: costCenterNumber,
            text: costCenterNumber
          }
        ];
      }
    };

    const isAllowAddNewCostCenter = (item: any, onlyNumber = true) => {
      const regExp = /[a-zA-Z]/g;
      if (
        (item.suggestTags || []).length == 0 &&
        item.key != "" &&
        (!regExp.test(item.key) || !onlyNumber)
      ) {
        return true;
      }

      return false;
    };

    const isAllowAddNewPostingCode = (item: any) => {
      if ((item.suggestTags || []).length == 0 && item.key != "") {
        return true;
      }

      return false;
    };

    return {
      pageData,
      popupCostCenter,
      onSuggestTags,
      onAddTag,
      onRemoveTag,
      onSelectTag,
      onRemoveLastTag,
      updateEndDate,
      stripTags: ApiHelper.stripTags,
      updateLocation,
      suggestLocation,
      formData,
      linkEventServices,
      linkEventParticipants,
      // showLinkParticipant,
      removeFieldError,
      onSubmit,
      suggestPTypes,
      selectPType,
      popupNewPTypeCol,
      capacityInput,
      isACILoading,
      updatePtypesList,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onRemoveCostCenter,
      onSelectCostCenter,
      onAddNewCostCenter,
      subItemSelectCostCenter,
      isAllowAddNewCostCenter,
      isAllowAddNewPostingCode,
      onTabPostingCostCenter,
      isShowEventService,
      dataEventService,
      isShowEventPType,
      dataEventPType,
      isShowEventDiscount,
      dataEventDiscount,
      isShowEventGroup,
      dataEventGroup,
      isAllowAddNewEventType,
      onAddNewEventType,
      popupEventType,
      onRemoveEventType,
      onSuggestEventType,
      onRemoveLastEventType,
      onSelectEventType
    };
  },
  methods: {
    ShowEventService(type: string) {
      this.CheckAutoClose();
      if (this.isShowEventService) {
        const chilService: any = this.$refs.childComponentEventAddServicet;
        if (chilService) {
          if (type === "add") {
            chilService.addNewRow();
          } else if (type === "edit") {
            chilService.allowEdit();
          }
          this.dataEventService = JSON.stringify(
            chilService.componentData.itemEdit.item
          );
          this.isShowEventGroup = false;
          this.isShowEventDiscount = false;
          this.isShowEventPType = false;
        }
      } else {
        ApiHelper.showErrorMessage(
          "Please, Complete the section or cancel it",
          "Oops"
        );
      }
    },
    ShowEventPtype(type: string) {
      this.CheckAutoClose();
      if (this.isShowEventPType) {
        const pType: any = this.$refs.childComponentEventAddPTypes;
        if (pType) {
          if (type === "add") {
            pType.addNewRow();
          } else if (type === "edit") {
            pType.allowEdit();
          }
          this.dataEventPType = JSON.stringify(
            pType.componentData.itemEdit.item
          );
          this.isShowEventGroup = false;
          this.isShowEventDiscount = false;
          this.isShowEventService = false;
        }
      } else {
        ApiHelper.showErrorMessage(
          "Please, Complete the section or cancel it",
          "Oops"
        );
      }
    },
    ShowEventDiscount(type: string) {
      this.CheckAutoClose();
      if (this.isShowEventDiscount) {
        const discount: any = this.$refs.eventDiscounts;
        if (discount) {
          if (type === "add") {
            discount.addNewRow();
          } else if (type === "edit") {
            discount.allowEdit();
          }
          this.dataEventDiscount = JSON.stringify(
            discount.componentData.itemEdit.item
          );
          this.isShowEventGroup = false;
          this.isShowEventPType = false;
          this.isShowEventService = false;
        }
      } else {
        ApiHelper.showErrorMessage(
          "Please, Complete the section or cancel it",
          "Oops"
        );
      }
    },
    HideEventService() {
      this.isShowEventGroup = true;
      this.isShowEventService = true;
      this.isShowEventDiscount = true;
      this.isShowEventPType = true;
    },
    ShowEventGroup(type: string) {
      this.CheckAutoClose();
      if (this.isShowEventGroup) {
        const group: any = this.$refs.eventGroupsRef;
        if (group) {
          if (type === "add") {
            group.addNewRow();
          } else if (type === "edit") {
            group.allowEdit();
          }
          this.dataEventGroup = JSON.stringify(
            group.componentData.itemEdit.item
          );
          this.isShowEventPType = false;
          this.isShowEventDiscount = false;
          this.isShowEventService = false;
        }
      } else {
        ApiHelper.showErrorMessage(
          "Please, Complete the section or cancel it",
          "Oops"
        );
      }
    },
    HideEventGroup() {
      this.isShowEventGroup = true;
      this.isShowEventService = true;
      this.isShowEventDiscount = true;
      this.isShowEventPType = true;
    },
    CheckAutoClose() {
      if (
        this.isShowEventPType &&
        this.isShowEventService &&
        this.isShowEventDiscount &&
        this.isShowEventGroup
      ) {
        return;
      }
      let chilComponent: any = this.$refs.childComponentEventAddServicet;
      let dataCurrentPopup = this.dataEventService;
      if (this.isShowEventDiscount) {
        chilComponent = this.$refs.eventDiscounts;
        dataCurrentPopup = this.dataEventDiscount;
      } else if (this.isShowEventPType) {
        chilComponent = this.$refs.childComponentEventAddPTypes;
        dataCurrentPopup = this.dataEventPType;
      } else if (this.isShowEventGroup) {
        chilComponent = this.$refs.eventGroupsRef;
        dataCurrentPopup = this.dataEventGroup;
      }
      if (chilComponent) {
        if (
          JSON.stringify(chilComponent.componentData.itemEdit.item) ==
          dataCurrentPopup
        ) {
          // Close popup Event
          chilComponent.checkAllowClose(
            chilComponent.componentData.itemEdit.index,
            chilComponent.componentData.itemEdit.item
          );
        }
      }
    }
  }
});
