import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

import {
  FormAutoComplete,
  FormSelect,
  FormText,
  FormYesNo,
  SelectOption
} from "@/types";
import { ApiType } from "@/helpers/ApiHelper";
import Vue from "vue";

export function useMessagingCampaignsAddStore(context: any) {
  const newTemplateId = ref(0);
  const campaignInput = ref<{
    campaignName: string;
    emails: string;
    sendToLists: number;
    workflowOn: number;
    messageSubject: string;
    templateId: number;
    template: number;
    CurrentUser: number;
    CurrentCompany: number;
    bounced: number;
    allowSameName: boolean;
  }>({
    campaignName: "",
    emails: "",
    sendToLists: 0,
    workflowOn: 0,
    messageSubject: "",
    templateId: 0,
    template: 0,
    CurrentUser: 0,
    CurrentCompany: 0,
    bounced: 0,
    allowSameName: false
  });

  const workflowInput = ref<{
    workflowData: string;
  }>({
    workflowData: ""
  });

  const sendMailInput = ref<{
    from: string;
    emails: string;
    id: number;
    tempID: number;
    messageSubject: string;
  }>({
    from: "",
    emails: "",
    id: 0,
    tempID: 0,
    messageSubject: ""
  });

  const previewData = ref<{
    campaignName: string;
    emails: string;
    from: string;
    sendToLists: {
      name: string;
      contacts: any[];
    };
    workflow: string;
    messageSubject: string;
    messageBody: string;
  }>({
    campaignName: "",
    emails: "",
    from: "",
    sendToLists: {
      contacts: [],
      name: ""
    },
    workflow: "",
    messageSubject: "",
    messageBody: ""
  });

  const pageData = ref<{
    tab: string;
    isLoading: boolean;
  }>({
    tab: "info",
    isLoading: true
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      name: FormText;
      sender: FormSelect;
      sendTo: FormAutoComplete;
      workflow: FormYesNo;
      subject: FormText;
      template: FormAutoComplete;
      emails: FormText;
    };
  }>({
    isLoading: false,
    controls: {
      name: {
        required: true,
        label: "Campaign Name",
        placeholder: "Campaign Name",
        error: "",
        type: "text",
        value: "",
        disabled: false,
        style: "custom"
      },
      sender: {
        required: true,
        error: "",
        type: "text",
        value: "",
        placeholder: "Select Sender",
        label: "From",
        options: [],
        style: "custom"
      },
      sendTo: {
        required: true,
        loading: false,
        error: "",
        type: "text",
        value: "",
        label: "Audience",
        placeholder: "Select a list",
        foundItems: [],
        alwaysEmitInput: true,
        suggestOnFocus: true,
        suggestItems: async (key: string) => {
          formData.value.controls.sendTo.loading = true;
          const result = await ApiHelper.callApi(
            "get",
            "/contacts/lists",
            {},
            {
              key: key
            },
            ApiType.CAMPAIGN
          );
          formData.value.controls.sendTo.loading = false;
          if (result.status != 1) {
            formData.value.controls.sendTo.error = result.message;
            return;
          }
          const lists: {
            id: string;
            name: string;
            content: string;
          }[] = result?.data.lists || [];
          formData.value.controls.sendTo.foundItems = lists.map(item => {
            return {
              id: item.id,
              text: item.name,
              data: item
            };
          });
        },
        selectItem: (data: any) => {
          campaignInput.value.sendToLists = data.id;
          previewData.value.sendToLists.name = data.name;
        }
      },
      workflow: {
        label: "Workflow?",
        value: "no",
        error: "",
        yesValue: "yes",
        noValue: "no",
        disabled: false,
        placeholder: ""
      },
      subject: {
        required: true,
        label: "Message Subject",
        placeholder: "Message Subject",
        error: "",
        type: "text",
        value: "",
        style: "custom"
      },
      template: {
        loading: false,
        required: true,
        error: "",
        value: "",
        label: "Template",
        placeholder: "Select a template",
        foundItems: [],
        alwaysEmitInput: true,
        suggestOnFocus: true,
        suggestItems: async (key: string) => {
          if (!key && campaignInput.value.templateId > 0) {
            campaignInput.value.templateId = 0;
          }
          formData.value.controls.template.loading = true;
          const result = await ApiHelper.callApi(
            "get",
            "/campaigns/templates",
            {},
            {
              key: key
            },
            ApiType.CAMPAIGN
          );
          formData.value.controls.template.loading = false;
          if (result.status != 1) {
            formData.value.controls.template.error = result.message;
            return;
          }
          const templates: {
            id: string;
            name: string;
            content: string;
          }[] = result?.data.templates || [];
          formData.value.controls.template.foundItems = templates.map(item => {
            return {
              id: item.id,
              text: item.name,
              data: item
            };
          });
        },
        selectItem: (data: any) => {
          campaignInput.value.templateId = data.id;
          sendMailInput.value.tempID = data.id;
          previewData.value.messageBody = data.content;
        }
      },
      emails: {
        error: "",
        value: ""
      }
    }
  });

  const modalPreview = ref({
    show: false,
    items: []
  });

  const audienceData = ref<{
    isLoading: boolean;
    activeTab: string;
    filter: {
      eventIds: string;
      tagIds: string;
      typeIds: string;
      statusIds: string;
      unassignedEvent?: SelectOption | null;
      events: SelectOption[];
      types: SelectOption[];
      statuses: SelectOption[];
      tags: SelectOption[];
      age: string;
      minAge: string;
      maxAge: string;
      balance: string;
      minBalance: string;
      maxBalance: string;
      notUpcomingEvents: boolean;
      hasIncompleteMedicationTodo: boolean;
      inUpcomingEvents: boolean;
      hasAttendedPastEvent: boolean;
    };
    selectedEvents: SelectOption[];
    selectedTypes: SelectOption[];
    selectedStatuses: SelectOption[];
    searchEventsValue: {
      value: string;
    };
    searchEvents: Function;
    searchEventsLoading: boolean;
    selectedTags: SelectOption[];
    searchTags: Function;
    searchTagsValue: {
      value: string;
    };
    searchTagsLoading: boolean;
    setActiveFilterTab: Function;
    updateSelectedEvents: Function;
    updateSelectedTypes: Function;
    updateSelectedStatuses: Function;
    updateSelectedTags: Function;
    updateFilterValue: Function;
    updatePreview: Function;
  }>({
    isLoading: false,
    activeTab: "",
    filter: {
      unassignedEvent: null,
      eventIds: "",
      tagIds: "",
      typeIds: "",
      statusIds: "",
      events: [],
      types: [],
      statuses: ApiHelper.getParticipantStatusOptions({
        getDeniedStatus: true
      }),
      tags: [],
      age: "",
      minAge: "",
      maxAge: "",
      balance: "",
      minBalance: "",
      maxBalance: "",
      notUpcomingEvents: false,
      inUpcomingEvents: false,
      hasAttendedPastEvent: false,
      hasIncompleteMedicationTodo: false
    },
    selectedEvents: [],
    selectedTypes: [],
    selectedStatuses: [],
    selectedTags: [],
    updateSelectedEvents: () => {
      const eventOptions = [];
      if (audienceData.value.filter.unassignedEvent) {
        eventOptions.push(audienceData.value.filter.unassignedEvent);
      }
      audienceData.value.filter.events.map((item: any) => {
        eventOptions.push(item);
      });

      eventOptions.map((item: any) => {
        const selectedItem:
          | SelectOption
          | undefined = audienceData.value.selectedEvents.find((item2: any) => {
          return item2.id == item.id;
        });
        if (item.selected) {
          if (!selectedItem) {
            audienceData.value.selectedEvents.push(item);
          }
        } else {
          if (selectedItem) {
            selectedItem.selected = false;
          }
        }
        audienceData.value.selectedEvents = audienceData.value.selectedEvents.filter(
          item => item.selected
        );
      });
    },
    updateSelectedTypes: () => {
      const typeOptions: SelectOption[] = [];
      audienceData.value.filter.types.map((item: any) => {
        typeOptions.push(item);
      });

      typeOptions.map((item: any) => {
        const selectedItem:
          | SelectOption
          | undefined = audienceData.value.selectedTypes.find((item2: any) => {
          return item2.id == item.id;
        });
        if (item.selected) {
          if (!selectedItem) {
            audienceData.value.selectedTypes.push(item);
          }
        } else {
          if (selectedItem) {
            selectedItem.selected = false;
          }
        }
        audienceData.value.selectedTypes = audienceData.value.selectedTypes.filter(
          item => item.selected
        );
      });
    },
    updateSelectedStatuses: () => {
      const statusOptions: SelectOption[] = [];
      audienceData.value.filter.statuses.map((item: any) => {
        statusOptions.push(item);
      });

      statusOptions.map((item: any) => {
        const selectedItem:
          | SelectOption
          | undefined = audienceData.value.selectedStatuses.find(
          (item2: any) => {
            return item2.id == item.id;
          }
        );
        if (item.selected) {
          if (!selectedItem) {
            audienceData.value.selectedStatuses.push(item);
          }
        } else {
          if (selectedItem) {
            selectedItem.selected = false;
          }
        }
        audienceData.value.selectedStatuses = audienceData.value.selectedStatuses.filter(
          item => item.selected
        );
      });
    },
    updateSelectedTags: () => {
      audienceData.value.filter.tags.map((item: any) => {
        const selectedItem:
          | SelectOption
          | undefined = audienceData.value.selectedTags.find((item2: any) => {
          return item2.id == item.id;
        });
        if (item.selected) {
          if (!selectedItem) {
            audienceData.value.selectedTags.push(item);
          }
        } else {
          if (selectedItem) {
            selectedItem.selected = false;
          }
        }
        audienceData.value.selectedTags = audienceData.value.selectedTags.filter(
          item => item.selected
        );
      });
    },
    updateFilterValue: (name: string, value: string) => {
      switch (name) {
        case "age": {
          audienceData.value.filter.age = value;
          const ageArr = value.split("-");
          audienceData.value.filter.minAge =
            ageArr[0] != undefined ? ageArr[0] : "";
          audienceData.value.filter.maxAge =
            ageArr[1] != undefined ? ageArr[1] : "";
          break;
        }
        case "balance": {
          audienceData.value.filter.balance = value;
          const ageArr = value.split("@");
          audienceData.value.filter.minBalance =
            ageArr[0] != undefined ? ageArr[0] : "";
          audienceData.value.filter.maxBalance =
            ageArr[1] != undefined ? ageArr[1] : "";
          break;
        }
      }
      audienceData.value.updatePreview();
    },
    setActiveFilterTab: async (tab: string) => {
      if (audienceData.value.activeTab !== tab) {
        audienceData.value.activeTab = tab;
      } else {
        audienceData.value.activeTab = "";
      }
      if (audienceData.value.activeTab == "events") {
        if (audienceData.value.filter.events.length === 0) {
          await audienceData.value.searchEvents(
            audienceData.value.searchEventsValue.value
          );
        }
      }
      if (audienceData.value.activeTab == "tags") {
        if (audienceData.value.filter.tags.length === 0) {
          await audienceData.value.searchTags(
            audienceData.value.searchTagsValue.value
          );
        }
      }
    },

    searchEventsValue: {
      value: ""
    },
    searchEvents: async (searchValue: string) => {
      audienceData.value.searchEventsLoading = true;
      const eventIds: any[] = audienceData.value.filter.eventIds
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        audienceData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1
        });
        audienceData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS",
          selected: eventIds.includes(0)
        };
      } else {
        audienceData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue
        });
        if (eventIds.includes(0)) {
          audienceData.value.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          audienceData.value.filter.unassignedEvent = null;
        }
      }
      audienceData.value.searchEventsLoading = false;
      audienceData.value.filter.events = audienceData.value.filter.events.map(
        item => ({
          ...item,
          selected: eventIds.includes(item.id)
        })
      );
    },
    searchEventsLoading: false,

    searchTagsValue: {
      value: ""
    },
    searchTags: async (searchValue: string) => {
      audienceData.value.searchTagsLoading = true;
      const tagIds: any[] = audienceData.value.filter.tagIds
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        audienceData.value.filter.tags = await ApiHelper.getTagsOptions({
          getAll: 1,
          type: "profile"
        });
      } else {
        audienceData.value.filter.tags = await ApiHelper.getTagsOptions({
          key: searchValue,
          type: "profile"
        });
      }
      audienceData.value.searchTagsLoading = false;
      audienceData.value.filter.tags = audienceData.value.filter.tags.map(
        item => ({
          ...item,
          selected: tagIds.includes(item.id)
        })
      );
    },
    searchTagsLoading: false,
    updatePreview: (force = false) => {
      audienceData.value.updateSelectedEvents();
      audienceData.value.updateSelectedTags();
      audienceData.value.updateSelectedTypes();
      audienceData.value.updateSelectedStatuses();
      formData.value.controls.emails.error = "";
      (async () => {
        audienceData.value.filter.eventIds =
          ApiHelper.convertSelectedOptionsToString(
            audienceData.value.selectedEvents
          ) || "";
        audienceData.value.filter.tagIds =
          ApiHelper.convertSelectedOptionsToString(
            audienceData.value.selectedTags
          ) || "";
        audienceData.value.filter.typeIds =
          ApiHelper.convertSelectedOptionsToString(
            audienceData.value.selectedTypes
          ) || "";
        audienceData.value.filter.statusIds =
          ApiHelper.convertSelectedOptionsToString(
            audienceData.value.selectedStatuses
          ) || "";
        if (
          audienceData.value.filter.eventIds ||
          audienceData.value.filter.tagIds ||
          audienceData.value.filter.typeIds ||
          audienceData.value.filter.statusIds ||
          audienceData.value.filter.minAge ||
          audienceData.value.filter.maxAge ||
          audienceData.value.filter.minBalance != "" ||
          audienceData.value.filter.maxBalance != "" ||
          audienceData.value.filter.hasAttendedPastEvent ||
          audienceData.value.filter.inUpcomingEvents ||
          audienceData.value.filter.notUpcomingEvents ||
          audienceData.value.filter.hasIncompleteMedicationTodo
        ) {
          audienceData.value.isLoading = true;
          const result = await ApiHelper.callApi(
            "get",
            "/profiles",
            {},
            {
              getAll: 1,
              // onlyEmail: 1,
              order: "1",
              direction: "1",
              event: audienceData.value.filter.eventIds,
              pStatus: audienceData.value.filter.statusIds,
              pType: audienceData.value.filter.typeIds,
              tag: audienceData.value.filter.tagIds,
              minAge: audienceData.value.filter.minAge,
              maxAge: audienceData.value.filter.maxAge,
              minBalance: audienceData.value.filter.minBalance,
              maxBalance: audienceData.value.filter.maxBalance,
              notUpcomingEvents: audienceData.value.filter.notUpcomingEvents
                ? 1
                : 0,
              inUpcomingEvents: audienceData.value.filter.inUpcomingEvents
                ? 1
                : 0,
              hasAttendedPastEvent: audienceData.value.filter
                .hasAttendedPastEvent
                ? 1
                : 0,
              hasIncompleteMedicationTodo: audienceData.value.filter
                .hasIncompleteMedicationTodo
                ? 1
                : 0,
              excludeDeniedParticipants: 1
            }
          );
          audienceData.value.isLoading = false;
          if (result.status == 1) {
            modalPreview.value.items = (result?.data.data || []).map(
              (item: any) => {
                if (item.email) {
                  item.selected = true;
                }
                return item;
              }
            );
            formData.value.controls.emails.value = modalPreview.value.items
              .map((item: any) => {
                return item.email;
              })
              .join(",");
          } else {
            modalPreview.value.items = [];
          }
        } else {
          modalPreview.value.items = [];
        }
      })();
    }
  });

  const getSelectedParticipants = () => {
    return modalPreview.value.items.filter((item: any) => {
      return item.selected;
    });
  };

  const onSaveInfo = async (allowSameName = false) => {
    formData.value.controls.sender.error = "";
    formData.value.controls.name.error = "";
    formData.value.controls.template.error = "";
    formData.value.controls.sendTo.error = "";
    const selectedParticipants = getSelectedParticipants();
    let selectedEmails = selectedParticipants.map((item: any) => {
      return item.email;
    });
    selectedEmails = [...new Set(selectedEmails)]; // remove duplicate emails
    formData.value.controls.emails.value = selectedEmails.join(",");
    campaignInput.value.campaignName = formData.value.controls.name.value;
    campaignInput.value.emails = formData.value.controls.emails.value;
    previewData.value.emails = formData.value.controls.emails.value;
    sendMailInput.value.messageSubject = formData.value.controls.subject.value;
    campaignInput.value.messageSubject = formData.value.controls.subject.value;
    sendMailInput.value.from = formData.value.controls.sender.value;
    campaignInput.value.allowSameName = allowSameName;
    campaignInput.value.workflowOn =
      formData.value.controls.workflow.value === "yes" ? 1 : 0;
    let hasError = false;
    if (campaignInput.value.campaignName === "") {
      hasError = true;
      formData.value.controls.name.error = "Campaign name is required!";
    }
    // if (campaignInput.value.sendToLists === 0) {
    //   hasError = true;
    //   formData.value.controls.sendTo.error = "Audience is required!";
    // }
    if (sendMailInput.value.from === "") {
      hasError = true;
      formData.value.controls.sender.error = "Sender is required!";
    }
    if (campaignInput.value.emails === "") {
      hasError = true;
      formData.value.controls.emails.error = "Audience is required!";
    }

    if (campaignInput.value.workflowOn === 0) {
      if (campaignInput.value.templateId === 0) {
        hasError = true;
        formData.value.controls.template.error = "Template is required!";
      }
      if (sendMailInput.value.messageSubject === "") {
        hasError = true;
        formData.value.controls.subject.error = "Subject is required!";
      }
    }

    if (!hasError) {
      if (newTemplateId.value) {
        if (formData.value.controls.workflow.value == "yes") {
          pageData.value.tab = "workflow";
        } else {
          pageData.value.tab = "preview";
        }
      } else {
        ApiHelper.setDataLoading(true);
        const result = await ApiHelper.callApi(
          "post",
          "/campaigns",
          campaignInput.value,
          {},
          ApiType.CAMPAIGN
        );
        ApiHelper.setDataLoading(false);
        if (result.status === 1) {
          newTemplateId.value = result.data.generatedKey || 0;

          previewData.value.campaignName = campaignInput.value.campaignName;
          previewData.value.from = sendMailInput.value.from;
          previewData.value.workflow =
            formData.value.controls.workflow.value == "yes" ? "On" : "Off";
          previewData.value.messageSubject = campaignInput.value.messageSubject;

          formData.value.controls.name.disabled = true;
          formData.value.controls.workflow.disabled = true;
          if (formData.value.controls.workflow.value == "yes") {
            pageData.value.tab = "workflow";
          } else {
            pageData.value.tab = "preview";
          }
        } else if (result.status === 2) {
          const isAgreed = await Vue.swal({
            html:
              "You already have a campaign with the same name. Would you like to save this campaign?",
            showCancelButton: true,
            confirmButtonText: "Yes, do it!",
            showCloseButton: true,
            closeButtonHtml:
              '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
          }).then(result => {
            setTimeout(function() {
              $(".swal2-backdrop-hide").addClass("d-none");
            }, 200);
            return result.isConfirmed;
          });
          if (isAgreed) {
            await onSaveInfo(true);
          }
        } else {
          ApiHelper.showErrorMessage(result.message, "Oops");
        }
      }
    }
  };

  const onSaveWorkflow = async () => {
    const hasError = false;
    if (!hasError) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "put",
        "/campaigns/" + newTemplateId.value,
        workflowInput.value,
        {},
        ApiType.CAMPAIGN
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        pageData.value.tab = "preview";
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    }
  };

  const onSendEmail = async () => {
    const hasError = false;
    if (!hasError) {
      sendMailInput.value.id = newTemplateId.value;
      sendMailInput.value.messageSubject =
        formData.value.controls.subject.value;
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "post",
        "/campaigns",
        sendMailInput.value,
        {},
        ApiType.CAMPAIGN
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        ApiHelper.showSuccessMessage("Sent emails");
        ApiHelper.gotoPage(context, { name: "MessagingCampaigns" });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    }
  };

  const loadPageData = async () => {
    ApiHelper.setDataLoading(false);
  };

  (async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/companies/senders",
      {},
      {},
      ApiType.CAMPAIGN
    );
    if (result.status != 1) {
      formData.value.controls.sender.error = result.message;
      return;
    }
    const senders: {
      senderId: string;
      senderMail: string;
    }[] = result?.data.senders || [];
    formData.value.controls.sender.options = senders.map(item => {
      return {
        id: item.senderMail,
        text: item.senderMail,
        data: item
      };
    });
  })();

  const loadPtypeOptions = async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/participants/ptypes",
      {},
      {
        getAll: 1
      }
    );
    if (result.status === 1) {
      audienceData.value.filter.types = result.data.ptypes.map((item: any) => ({
        id: item.id,
        text: item.name,
        selected: false
      }));
    }
  };
  (async () => {
    await loadPtypeOptions();
  })();

  return {
    getSelectedParticipants,
    audienceData,
    modalPreview,
    newTemplateId,
    pageData,
    formData,
    previewData,
    // methods
    loadPageData,
    onSaveInfo,
    onSaveWorkflow,
    onSendEmail
  };
}
