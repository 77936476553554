



























































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import OrderByButton from "@/components/Common/OrderByButton.vue";
import $ from "jquery";

export default defineComponent({
  name: "FilterDateColumn",
  components: {
    OrderByButton
  },
  props: {
    label: String,
    subLabel: {
      type: String,
      default: ""
    },
    model: String,
    data: Object,
    activeTab: String,
    activeValue: String,
    setActiveFilterTab: Function,
    closeFilterTab: Function,
    onChangeValue: Function,
    sortKey: String,
    sortData: Object,
    onChangeSortValue: Function
  },
  setup(props, context) {
    const fromDateValue = ref<string | null | undefined>("");
    const fromDateError = ref<string>("");
    const toDateValue = ref<string | null | undefined>("");
    const toDateError = ref<string>("");
    const searchValue = ref<string>("");
    const inputType = ref<string>("date");
    const timer = ref<any>(null);
    const searchTimeOut = () => {
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = null;
      }
      timer.value = setTimeout(() => {
        if (props.onChangeValue) {
          props.onChangeValue(props.activeValue, searchValue.value);
        }
      }, 800);
    };

    const onFromDateChange = (event: any) => {
      fromDateError.value = "";
      toDateError.value = "";
      fromDateValue.value = event.target.value;
      if (
        fromDateValue.value &&
        toDateValue.value &&
        fromDateValue.value > toDateValue.value
      ) {
        fromDateError.value = "From Date is invalid";
      }
      searchValue.value =
        ApiHelper.convertDateToDateID(fromDateValue.value || "") +
        "-" +
        ApiHelper.convertDateToDateID(toDateValue.value || "");
    };

    const onToDateChange = (event: any) => {
      fromDateError.value = "";
      toDateError.value = "";
      toDateValue.value = event.target.value;
      if (
        fromDateValue.value &&
        toDateValue.value &&
        fromDateValue.value > toDateValue.value
      ) {
        toDateError.value = "To Date is invalid";
      }
      searchValue.value =
        ApiHelper.convertDateToDateID(fromDateValue.value || "") +
        "-" +
        ApiHelper.convertDateToDateID(toDateValue.value || "");
    };

    const doClose = () => {
      fromDateError.value = "";
      toDateError.value = "";
      if (props.setActiveFilterTab) {
        props.setActiveFilterTab("");
      }
    };
    const doFilter = () => {
      if (props.onChangeValue) {
        const fromDate: any = $(context.refs.fromDate);
        const toDate: any = $(context.refs.toDate);
        let fromDateBadInput = false;
        let toDateBadInput = false;
        if (fromDate.length) {
          fromDateBadInput = fromDate.get(0).validity?.badInput || false;
          if (fromDateBadInput) {
            fromDateError.value = "From Date is invalid";
          }
        }
        if (toDate.length) {
          toDateBadInput = toDate.get(0).validity?.badInput || false;
          if (toDateBadInput) {
            toDateError.value = "To Date is invalid";
          }
        }
        if (fromDateBadInput || toDateBadInput) {
          return;
        }

        if (fromDateError.value || toDateError.value) {
          return;
        }

        if (
          fromDateValue.value &&
          toDateValue.value &&
          fromDateValue.value > toDateValue.value
        ) {
          toDateError.value = "To Date is invalid";
          return;
        }

        if (fromDateError.value || toDateError.value) {
          return;
        }
        searchValue.value =
          ApiHelper.convertDateToDateID(fromDateValue.value || "") +
          "-" +
          ApiHelper.convertDateToDateID(toDateValue.value || "");
        props.onChangeValue(props.activeValue, searchValue.value);
        doClose();
      }
    };

    const doReset = () => {
      inputType.value = "text";
      fromDateError.value = "";
      toDateError.value = "";
      searchValue.value = "";
      fromDateValue.value = "";
      toDateValue.value = "";
      setTimeout(() => {
        inputType.value = "date";
      }, 10);

      if (props.onChangeValue) {
        props.onChangeValue(props.activeValue, searchValue.value);
      }
      doClose();
    };

    const getFromDate = (date: string) => {
      const arr = date.split("-");
      if (arr.length === 2) {
        return ApiHelper.convertYYYYMMDDtoDateString(arr[0]);
      } else {
        return null;
      }
    };

    const getToDate = (date: string) => {
      const arr = date.split("-");
      if (arr.length === 2) {
        return ApiHelper.convertYYYYMMDDtoDateString(arr[1]);
      } else {
        return null;
      }
    };

    const onClickOutside = () => {
      fromDateValue.value = getFromDate(props.model || "-");
      toDateValue.value = getToDate(props.model || "-");
      searchValue.value =
        ApiHelper.convertDateToDateID(fromDateValue.value || "") +
        "-" +
        ApiHelper.convertDateToDateID(toDateValue.value || "");
      if (props.activeTab === props.activeValue) {
        doClose();
      }
    };
    const onClickSorting = () => {
      if (props.onChangeSortValue) {
        const currentOrder = props.sortData ? props.sortData.order || "" : "";
        const currentDirection = props.sortData
          ? props.sortData.direction || "2"
          : "2";
        if (props.sortKey !== currentOrder) {
          props.onChangeSortValue(props.sortKey, "2");
        } else {
          props.onChangeSortValue(
            props.sortKey,
            currentDirection === "1" ? "2" : "1"
          );
        }
      }
    };
    (async () => {
      fromDateValue.value = getFromDate(props.model || "-");
      toDateValue.value = getToDate(props.model || "-");
    })();
    return {
      inputType,
      fromDateError,
      toDateError,
      onClickSorting,
      onClickOutside,
      fromDateValue,
      toDateValue,
      onFromDateChange,
      onToDateChange,
      doFilter,
      doReset,
      doClose,
      searchTimeOut,
      getFromDate,
      getToDate
    };
  }
});
