




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import directives from "@/helpers/directives";
import Vue from "vue";
import moment from "moment";
import FormTags from "@/components/Form/FormTags.vue";
import { FormText } from "@/types";
import Modal from "@/components/Common/Modal.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import { v4 as uuidv4 } from "uuid";
import FormDatePicker2 from "../Form/FormDatePicker2.vue";
import FormInput from "@/components/Form/FormInput.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import FormButton from "@/components/Form/FormButton.vue";

import $ from "jquery";

export default defineComponent({
  name: "EventAddDiscounts",
  props: {
    eventId: Number,
    ptypes: Array,
    driver: Object,
    data: Object,
    disabled: {
      type: Boolean,
      defaultValue: false
    },
    showCostCenter: {
      type: Boolean,
      defaultValue: false
    },
    showGlCodes: {
      type: Boolean,
      defaultValue: false
    },
    isAllowAddNewPostingCode: Function,
    eventCoscenterNumber: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  directives: directives,
  components: {
    FormInput,
    FormTags,
    Modal,
    FormTextarea,
    FormDatePicker2,
    AutoCompleteInput,
    FormNumber,
    FormButton
  },
  setup(props, context) {
    const isACILoading = ref<boolean>(false);
    const componentData = ref<{
      allowTop: boolean;
      firstCost: boolean;
      totalDataApplyTop: number;
      totalDataApplyTopTag: number;
      allowTopTag: boolean;
      totalDataApplyTopCostCenter: number;
      allowTopCostCenter: boolean;
      totalDataApplyTopPostCode: number;
      allowTopPostCode: boolean;
      controls: {
        postingCode: FormText;
        postingCodeDesc: FormText;
        costCenterNumber: FormText;
        costCenterName: FormText;
        glName: FormText;
      };
      itemEdit: {
        item: any;
        index: number;
      };
    }>({
      allowTop: true,
      firstCost: true,
      totalDataApplyTop: 0,
      totalDataApplyTopTag: 0,
      allowTopTag: false,
      totalDataApplyTopCostCenter: 0,
      allowTopCostCenter: false,
      totalDataApplyTopPostCode: 0,
      allowTopPostCode: false,
      controls: {
        postingCode: {
          required: true,
          label: "Posting Code",
          placeholder: "Posting Code",
          style: "custom",
          value: "",
          error: "",
          maxlength: 30
        },
        postingCodeDesc: {
          required: true,
          // label: "Description",
          label: "Posting Code Name",
          // placeholder: "Description",
          placeholder: "Posting Code Name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 255
        },
        costCenterNumber: {
          required: true,
          label: "Number",
          placeholder: "Number",
          style: "custom",
          value: "",
          error: "",
          maxlength: 100
        },
        costCenterName: {
          required: false,
          label: "Cost center name",
          placeholder: "Cost center name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 150
        },
        glName: {
          required: true,
          label: "GL Name",
          placeholder: "GL Name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 100
        }
      },
      itemEdit: {
        item: [],
        index: -1
      }
    });
    const foundPTypes = ref<any[]>([]);
    const newPostingCode = ref<{
      modalVisible: boolean;
      postingCode: string;
      currentAddOn: any;
    }>({
      modalVisible: false,
      postingCode: "",
      currentAddOn: {}
    });

    const newCostCenter = ref<{
      modalVisible: boolean;
      isProcessing: boolean;
      item: any;
      index: number;
    }>({
      modalVisible: false,
      isProcessing: false,
      item: {},
      index: 0
    });

    const glCodes = ref<any>({
      required: true,
      error: "",
      label: "GL Code",
      placeholder: "Select or input a GL Code",
      type: "tags",
      key: "",
      value: [],
      options: [],
      suggestTags: []
    });

    const loadData = () => {
      (async () => {
        if (props.driver) {
          props.driver.allowAdd = true;
          props.driver.editingIndex = -1;
          props.driver.items = props.driver.items.filter(
            (item: any) => !item.isDeleted
          );
          return;
        }
      })();
    };

    const addNewRow = () => {
      if (props.driver) {
        props.driver.items.map((value: any) => {
          value.isEditing = false;
        });
        props.driver.allowAdd = false;
        props.driver.items.push({
          uuid: uuidv4(),
          id: 0,
          name: {
            value: "",
            error: ""
          },
          maxUse: {
            value: "0",
            error: ""
          },
          discountType: {
            value: "",
            error: ""
          },
          discountAmount: {
            value: "",
            error: ""
          },
          discountPercent: {
            value: "",
            error: ""
          },
          code: {
            value: "",
            error: ""
          },
          availableDate: {
            value: "",
            error: ""
          },
          ptype: {
            value: "",
            id: "",
            error: ""
          },
          active: {
            value: true,
            error: ""
          },
          tags: {
            error: "",
            label: "",
            textOverflow: true,
            placeholder: "Enter a tag",
            type: "tags",
            key: "",
            value: [],
            options: [],
            suggestTags: []
          },
          postingCodes: {
            error: "",
            label: "",
            textOverflow: true,
            placeholder: "Posting / GL Code",
            type: "tags",
            key: "",
            value: [],
            options: [],
            suggestTags: []
          },
          costCenters: {
            error: "",
            label: "",
            textOverflow: true,
            placeholder: "Cost Center Number",
            type: "tags",
            key: "",
            firstFocus: true,
            value: [],
            options: [],
            suggestTags: []
          },
          totalUsed: 0,
          isDeleted: false,
          isEditing: true,
          transactionType: {
            value: 1,
            error: ""
          }
        });
        props.driver.editingIndex = props.driver.items.length - 1;
      }
    };

    const getSelectPtypeCost = (ptypeUuid: string) => {
      let cost = 0;
      if (props.ptypes) {
        const findPtype: any = props.ptypes.find((item: any) => {
          return item.uuid.toString() === ptypeUuid.toString();
        });
        if (findPtype) {
          cost = findPtype.cost || 0;
        }
      }
      return cost;
    };

    const doCancelItem = (index: number, item: any) => {
      if (props.driver) {
        if (!item.name.value) {
          item.isDeleted = true;
        }
        props.driver.editingIndex = -1;
        props.driver.allowAdd = true;
        item.isEditing = false;
        loadData();
        return;
      }
    };

    const doSaveItem = async (index: number, item: any) => {
      item.name.error = "";
      item.code.error = "";
      item.discountAmount.error = "";
      item.discountPercent.error = "";
      item.discountType.error = "";
      item.availableDate.error = "";
      item.active.error = "";
      item.ptype.error = "";
      item.maxUse.error = "";

      let hasError = false;
      const name = item.name.value || "";
      const code = item.code.value || "";
      const ptype = item.ptype.value || "";
      if (name === "") {
        item.name.error = "Name is required";
        hasError = true;
      }
      if (props.driver) {
        const existedItems =
          props.driver.items.filter((value: any) => !value.isEditing) || [];
        if (name) {
          const sameName = existedItems.find((value: any) => {
            // return value.name.value === name;
            return (
              value.name.value.toUpperCase() === name.toUpperCase() &&
              parseInt(item.ptype.value) === parseInt(value.ptype.value)
            );
          });
          if (sameName) {
            item.name.error = "Name already exists.";
            hasError = true;
          }
        }
        if (code) {
          const sameCode = existedItems.find((value: any) => {
            return (
              value.code.value.toLowerCase() === code.toLowerCase() &&
              value.ptype.value == ptype
            );
          });
          if (sameCode) {
            item.code.error = "Code already exists.";
            hasError = true;
          }
        }
      }

      // if (item.availableDate.value === "") {
      //   item.availableDate.error = "Date is required";
      //   hasError = true;
      // }
      if (item.availableDate.value) {
        const selectedData = moment(item.availableDate.value).format(
          "YYYY-MM-DD"
        );
        const now = moment().format("YYYY-MM-DD");
        if (!(selectedData > now)) {
          item.availableDate.error = "Date is invalid";
          hasError = true;
        }
      }
      if (
        item.code.value === "" &&
        !item.availableDate.value &&
        parseInt(item.discountType.value) != 2
      ) {
        item.code.error = "Code is required";
        hasError = true;
      }

      const discountType = Number.parseInt(item.discountType.value, 10) || 0;
      if (discountType === 0) {
        item.discountType.error = "Type is required";
        hasError = true;
      }
      const discountAmount = Number.parseFloat(item.discountAmount.value) || 0;
      const discountPercent =
        Number.parseFloat(item.discountPercent.value) || 0;

      if (props.driver && props.driver.amountTypeInput === "amount") {
        const ptypeCost = getSelectPtypeCost(item.ptype.value);
        if (discountAmount === 0) {
          item.discountAmount.error = "Discount Amount must greater than 0";
          hasError = true;
        }
        if (discountAmount > ptypeCost) {
          item.discountAmount.error =
            "Discount Amount must less than " +
            ApiHelper.dollarFormat(ptypeCost);
          hasError = true;
        }
      }
      if (props.driver && props.driver.amountTypeInput === "rate") {
        if (discountPercent == 0) {
          item.discountPercent.error = "Discount Percent must greater than 0";
          hasError = true;
        }
        if (discountPercent > 100) {
          item.discountPercent.error = "Discount Percent must less than 100%";
          hasError = true;
        }
      }
      const ptypeUuid = item.ptype.value;
      if (!ptypeUuid) {
        item.ptype.error = "Type is required";
        hasError = true;
      }
      if (hasError) {
        return;
      }
      if (props.driver) {
        props.driver.allowAdd = true;
        props.driver.editingIndex = -1;
      }
      item.isEditing = false;
      loadData();
    };

    const doDeleteItem = async (index: number, item: any) => {
      if (props.driver) {
        const isAgreed = await Vue.swal({
          html: "Are you sure you want to delete this discount?",
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          return result.isConfirmed;
        });

        if (isAgreed) {
          if (!props.eventId) {
            item.isDeleted = true;
            loadData();
            return;
          }
          if (item.id == 0) {
            loadData();
            return;
          }
          props.driver.items = props.driver.items.filter(
            (item2: any) => item2.uuid != item.uuid
          );
          props.driver.editingIndex = -1;
          props.driver.allowAdd = true;
          ApiHelper.showSuccessMessage("Deleted");
          loadData();
        } else {
          props.driver.editingIndex = -1;
          props.driver.allowAdd = true;
        }
      }
    };
    const doEditItem = (index: number, item: any) => {
      if (props.driver) {
        props.driver.items.map((value: any) => {
          value.isEditing = false;
        });
        props.driver.editingIndex = index;
        props.driver.allowAdd = false;
        item.isEditing = true;
      }
    };

    const amountTypeSwitch = () => {
      if (props.driver) {
        props.driver.amountTypeInput =
          props.driver.amountTypeInput == "amount" ? "rate" : "amount";
      }
    };

    const changeDiscountAmount = (item: any) => {
      const ptypeCost = getSelectPtypeCost(item.ptype.value);
      item.discountAmount.error = "";
      item.discountPercent.error = "";
      const discountAmount = parseFloat(item.discountAmount.value) || 0;

      if (discountAmount > ptypeCost) {
        item.discountAmount.error =
          "Discount amount must less than " + ApiHelper.dollarFormat(ptypeCost);
      }
      if (ptypeCost > 0) {
        item.discountPercent.value = (
          (discountAmount / ptypeCost) *
          100
        ).toFixed(2);
      }
    };

    const changeDiscountPercent = (item: any) => {
      const ptypeCost = getSelectPtypeCost(item.ptype.value);
      item.discountAmount.error = "";
      item.discountPercent.error = "";
      let discountPercent = parseFloat(item.discountPercent.value) || 0;
      if (discountPercent > 100) {
        discountPercent = 100;
        item.discountPercent.value = discountPercent;
      }
      const discountAmount = ptypeCost * (discountPercent / 100);
      item.discountAmount.value = discountAmount.toFixed(2);
      if (discountAmount > ptypeCost) {
        item.discountAmount.error =
          "Discount amount must less than " + ApiHelper.dollarFormat(ptypeCost);
      }
    };

    const getPTypesHtml = (item: any) => {
      let ret = `${item.discountName}`;
      const moreInfo = [];
      if (item.discountCode) {
        moreInfo.push(`Code: ${item.discountCode}`);
      }
      if (moreInfo.length) {
        ret = `${ret} - (${moreInfo.join(", ")})`;
      }

      return ret;
    };

    const suggestPTypes = async (key: string, item: any, index: number) => {
      // reset
      foundPTypes.value = [];
      item.name.value = key;

      if (key !== "") {
        // searching
        // item.id = -1;
        const items: any = props.driver?.items || [];
        const selectedPTypeNames = items
          .filter((option: any) => (option.id || 0) > 0)
          .map((option: any) => option.id);

        isACILoading.value = true;
        const result = await ApiHelper.callApi(
          "get",
          "/events/discountSearch",
          {},
          {
            key: key,
            take: 25,
            selectedIds: selectedPTypeNames.join(",")
          }
        );
        if (result.status === 1) {
          foundPTypes.value = (result.data.discounts || []).map(
            (item: any) => ({
              id: item.discountName,
              text: item.discountName,
              html: getPTypesHtml(item),
              data: item
            })
          );
        }
        isACILoading.value = false;
      }
    };

    const selectPType = (data: any, item: any) => {
      if (data) {
        item.name.value = data.discountName;
        item.code.value = data.discountCode;

        const postingCode = data.postingCode || "";
        const glCode = data.glCode || "";
        const costCenterNumber = data.costCenterNumber || "";
        item.postingCodes.value = [];
        if (postingCode != "" && glCode != "") {
          item.postingCodes.value = [
            {
              id: postingCode,
              text: `${postingCode} / ${glCode}`
            }
          ];
        }
        item.costCenters.value = [];
        if (costCenterNumber != "") {
          item.costCenters.value = [
            {
              id: costCenterNumber,
              text: costCenterNumber
            }
          ];
        }
      }
    };

    const onSuggestTags = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        componentData.value.totalDataApplyTopTag = 0;
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/tags/search",
        {},
        {
          // typeUse: "finance",
          key: key,
          notInIds: notInIds
        }
      );
      if (result?.status === 1) {
        componentData.value.totalDataApplyTopTag = result.data.tags.length;
        selectedItem.suggestTags = result.data.tags.map((item: any) => {
          return {
            id: item.id,
            text: item.text,
            data: item
          };
        });
      }
    };

    const onAddTag = (selectedItem: any) => {
      const key = selectedItem.key.trim();
      if (key) {
        selectedItem.value.push({
          id: 0,
          text: key
        });
        selectedItem.key = "";
      }
    };

    const onRemoveLastTag = (selectedItem: any) => {
      if (selectedItem.value.length > 0 && selectedItem.key === "") {
        const index = selectedItem.value.length - 1;
        selectedItem.value.splice(index, 1);
      }
    };
    const onRemoveTag = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectTag = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        text: item.text,
        data: item.data || {}
      });
    };

    // posting codes
    const onSuggestPostingCodes = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        componentData.value.totalDataApplyTopPostCode = 0;
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/finances/postingCodes",
        {},
        {
          key,
          notInIds
        }
      );
      if (result.status === 1) {
        componentData.value.totalDataApplyTopPostCode =
          result.data.items.length;
        selectedItem.suggestTags = result.data.items
          .filter((item: any) => item.postingCodeDesc != "")
          .map((item: any) => {
            return {
              id: item.postingCode,
              // text: `${item.postingCode} / ${item.glCode}`,
              text: item.postingCodeDesc,
              data: item
            };
          });
      }
    };

    const onRemoveLastPostingCode = (selectedItem: any) => {
      // if (selectedItem.value.length > 0 && selectedItem.key === "") {
      //   const index = selectedItem.value.length - 1;
      //   selectedItem.value.splice(index, 1);
      // }
    };
    const onRemovePostingCodes = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectPostingCode = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        // text: item.text
        text: `${item.data.postingCode} / ${item.data.glCode}`
      });
    };

    const showNewPostingCodeModal = (
      postingCode: string,
      selectedAddon: any,
      index: number
    ) => {
      if (postingCode == "") return;

      const postingCodeRef: any = context.refs.postingCodeRef;

      // auto selecting if existed in suggested items
      const existed = selectedAddon.postingCodes.suggestTags.find(
        (item: any) => `${item.id}`.toLowerCase() == postingCode.toLowerCase()
      );
      if (existed) {
        // auto select
        selectedAddon.postingCodes.value.push({
          id: existed.id,
          text: existed.text
        });
        selectedAddon.postingCodes.key = "";
        if (postingCodeRef[index]) {
          postingCodeRef[index].show = false;
        }

        return;
      }
      componentData.value.controls.postingCode.value = postingCode;
      newPostingCode.value.modalVisible = true;
      newPostingCode.value.postingCode = postingCode;
      newPostingCode.value.currentAddOn = selectedAddon;
      if (postingCodeRef[index]) {
        postingCodeRef[index].show = false;
      }
    };

    const onSuggestGLCode = async () => {
      const notInIds = glCodes.value.value
        .map((item: any) => item.id)
        .join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/glCodes",
        {},
        {
          gl: glCodes.value.key || "",
          notInIds
        }
      );
      if (result.status === 1) {
        glCodes.value.suggestTags = result.data.items.map((code: string) => {
          return {
            id: code,
            text: code
          };
        });
      }
    };

    const onSelectGLCode = (selectedValue: any) => {
      glCodes.value.error = "";
      glCodes.value.key = selectedValue.id;
      glCodes.value.suggestTags = [];
    };

    const savePostingCode = async () => {
      // const postingCode = newPostingCode.value.postingCode || "";
      const postingCode = componentData.value.controls.postingCode.value;
      const glCode = glCodes.value.key || "";
      const addonServiceId = newPostingCode.value.currentAddOn.uuid || 0;
      // reset
      glCodes.value.error = "";

      // validate
      let valid = true;
      // if (postingCode == "") return;
      if (glCode == "") {
        valid = false;
        glCodes.value.error = "GL Code is required!";
      }
      if (componentData.value.controls.postingCode.value == "") {
        valid = false;
        componentData.value.controls.postingCode.error =
          "Posting Code is required!";
      }
      if (componentData.value.controls.postingCodeDesc.value == "") {
        valid = false;
        componentData.value.controls.postingCodeDesc.error =
          "Posting Code Name is required!";
      }
      const glName = componentData.value.controls.glName.value;
      if (glName == "") {
        valid = false;
        componentData.value.controls.glName.error = "GL Name is required!";
      }

      if (valid) {
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/postingcodes/0`,
          {
            code: postingCode,
            glCode,
            glName,
            desc: componentData.value.controls.postingCodeDesc.value
          },
          {}
        );
        if (result.status == 1) {
          // update posting codes for current addon
          const currentAddOn = (props.driver?.items || []).find(
            (item: any) => item.uuid == addonServiceId
          );
          if (currentAddOn) {
            currentAddOn.postingCodes.value.push({
              id: postingCode,
              text: `${postingCode} / ${glCode}`
            });
            currentAddOn.postingCodes.key = "";
          }
          newPostingCode.value.postingCode = "";
          componentData.value.controls.postingCodeDesc.value = "";
          glCodes.value.key = "";
          glCodes.value.value = [];
          glCodes.value.suggestTags = [];
          newPostingCode.value.modalVisible = false;
          newPostingCode.value.currentAddOn = {};
        } else {
          ApiHelper.showErrorMessage(
            result.message || `Can't create new Posting Code`
          );
        }
      }
    };

    // cost centers
    const onSuggestCostCenters = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        componentData.value.totalDataApplyTopCostCenter = 0;
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/costcenters",
        {},
        {
          getAll: 1,
          order: 1,
          direction: 1,
          key,
          notInIds
        }
      );
      isACILoading.value = false;
      if (result.status === 1) {
        componentData.value.totalDataApplyTopCostCenter =
          result.data.items.length;
        selectedItem.suggestTags = result.data.items.map((item: any) => {
          return {
            id: item.costCenterNumber,
            text: item.costCenterName || item.costCenterNumber,
            data: item
          };
        });
      }
    };

    const onRemoveLastCostCenter = (selectedItem: any) => {
      if (selectedItem.value.length > 0 && selectedItem.key === "") {
        const index = selectedItem.value.length - 1;
        selectedItem.value.splice(index, 1);
      }
    };
    const onRemoveCostCenter = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectCostCenter = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value = [];
      selectedItem.value.push({
        id: item.id,
        text: item.id
      });
      // context.emit("onSelectCostCenter", item);
    };

    const resetFormAddCostCenter = () => {
      newCostCenter.value.modalVisible = false;
      newCostCenter.value.isProcessing = false;
      componentData.value.controls.costCenterNumber.value = "";
      componentData.value.controls.costCenterName.value = "";
      componentData.value.controls.costCenterNumber.error = "";
    };

    const onAddNewCostCenter = async (
      key: string,
      selectedItem: any,
      index: number
    ) => {
      // Call from popup add new cost
      if (
        componentData.value.controls.costCenterNumber.value === "" &&
        key.trim() == ""
      ) {
        componentData.value.controls.costCenterNumber.error =
          "Number is required";
        newCostCenter.value.isProcessing = false;
        return;
      }
      if (key.trim() == "") return;

      if (!newCostCenter.value.isProcessing) {
        newCostCenter.value.modalVisible = true;
        newCostCenter.value.item = selectedItem;
        newCostCenter.value.index = index;
        // Handle name or number
        if (!isNaN(key as any)) {
          componentData.value.controls.costCenterNumber.value = key;
        } else {
          componentData.value.controls.costCenterName.value = key;
        }
      } else {
        // Add new cost center
        const costCenterRef: any = context.refs.costCenterRef;
        // auto selecting if cost center number existed in suggested items
        const existed = selectedItem.suggestTags.find(
          (item: any) => `${item.id}`.toLowerCase() == key.toLowerCase()
        );
        if (existed) {
          // auto select
          selectedItem.value.push({
            id: existed.id,
            text: existed.text
          });
          selectedItem.key = "";
          if (costCenterRef[index]) {
            costCenterRef[index].show = false;
          }
          context.emit("onSelectCostCenter", existed);

          return;
        }
        // call api add new cost center number
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/costcenters/0`,
          {
            number: componentData.value.controls.costCenterNumber.value,
            name: componentData.value.controls.costCenterName.value
          },
          {}
        );

        if (result.status == 1) {
          // Reset data after call api success
          resetFormAddCostCenter();
          // update cost centers for current addon
          selectedItem.value.push({
            id: key,
            text: key
          });
          selectedItem.key = "";
          if (costCenterRef[index]) {
            costCenterRef[index].show = false;
          }
          context.emit("onSelectCostCenter", {
            id: key,
            text: key
          });
        } else {
          // Reset button submit
          newCostCenter.value.isProcessing = false;
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
      }
    };

    const isEditingDiscount = () => {
      const isEditing = (props.driver?.items || []).find(
        (item: any) => (item.isEditing || false) == true
      );
      if (isEditing) {
        return true;
      }

      return false;
    };

    const onFocusCoscenter = (index: number, item: any) => {
      const eventCoscenterNumber: any = props.eventCoscenterNumber;
      if (
        eventCoscenterNumber.length &&
        item.value.length == 0 &&
        item.key == "" &&
        componentData.value.firstCost
      ) {
        componentData.value.firstCost = false;
        // auto fill coscenter number
        // item.key = eventCoscenterNumber[0].id;
        // auto select
        item.value = [
          {
            id: eventCoscenterNumber[0].id,
            text: eventCoscenterNumber[0].id
          }
        ];
      }
    };

    const isAllowAddNewCostCenter = (item: any) => {
      const regExp = /[a-zA-Z]/g;

      if (
        (item.suggestTags || []).length == 0 &&
        item.key != "" &&
        !regExp.test(item.key)
      ) {
        return true;
      }

      return false;
    };

    const getPtypeName = (id: string) => {
      const options: any[] = props.ptypes || [];
      const optionValue = options.find(item => {
        return item.id + "" === id;
      });
      if (optionValue) {
        return optionValue.name;
      }
      return "";
    };

    const resetFirtfocus = () => {
      componentData.value.firstCost = true;
    };

    const checkDiscountType = (item: any) => {
      item.discountType.error = "";
      if (parseInt(item.discountType.value) === 2) {
        item.code.error = "";
      }
    };

    return {
      getPtypeName,
      ApiHelper: ApiHelper,
      onSuggestTags,
      onAddTag,
      onRemoveLastTag,
      onRemoveTag,
      onSelectTag,
      changeDiscountAmount,
      changeDiscountPercent,
      loadData,
      addNewRow,
      doCancelItem,
      doSaveItem,
      doDeleteItem,
      doEditItem,
      stripTags: ApiHelper.stripTags,
      amountTypeSwitch,
      onSuggestPostingCodes,
      onRemoveLastPostingCode,
      onRemovePostingCodes,
      onSelectPostingCode,
      showNewPostingCodeModal,
      newPostingCode,
      newCostCenter,
      glCodes,
      onSuggestGLCode,
      onSelectGLCode,
      savePostingCode,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onRemoveCostCenter,
      onSelectCostCenter,
      onAddNewCostCenter,
      componentData,
      isEditingDiscount,
      onFocusCoscenter,
      isACILoading,
      foundPTypes,
      suggestPTypes,
      selectPType,
      isAllowAddNewCostCenter,
      resetFirtfocus,
      resetFormAddCostCenter,
      checkDiscountType
    };
  },
  async mounted() {
    // init data
    await this.loadData();
  },
  methods: {
    hoverEnter(event: any) {
      if (event.clientY > 550 && this.componentData.totalDataApplyTop > 3) {
        this.componentData.allowTop = true;
      } else {
        this.componentData.allowTop = false;
      }
    },
    hoverEnterTag(event: any) {
      if (event.clientY > 550 && this.componentData.totalDataApplyTopTag > 3) {
        this.componentData.allowTopTag = true;
      } else {
        this.componentData.allowTopTag = false;
      }
    },
    hoverEnterPostCode(event: any) {
      if (
        event.clientY > 550 &&
        this.componentData.totalDataApplyTopPostCode > 3
      ) {
        this.componentData.allowTopPostCode = true;
      } else {
        this.componentData.allowTopPostCode = false;
      }
    },
    hoverEnterCostCenter(event: any) {
      if (
        event.clientY > 550 &&
        this.componentData.totalDataApplyTopCostCenter > 3
      ) {
        this.componentData.allowTopCostCenter = true;
      } else {
        this.componentData.allowTopCostCenter = false;
      }
    },
    checkAllowAdd() {
      this.componentData.itemEdit.index = -1;
      // this.componentData.itemEdit.item = item;
      this.$emit("button-added");
    },
    checkAllowEdit(index: number, item: any) {
      this.componentData.itemEdit.index = index;
      this.componentData.itemEdit.item = item;
      this.$emit("button-edited");
    },
    allowEdit() {
      this.doEditItem(
        this.componentData.itemEdit.index,
        this.componentData.itemEdit.item
      );
      // this.componentData.itemEdit.index = -1;
      // this.componentData.itemEdit.item = [];
    },
    checkAllowClose(index: number, item: any) {
      this.$emit("button-deleted");
      this.doCancelItem(index, item);
    },
    checkDoSaveItem(index: number, item: any) {
      this.$emit("button-deleted");
      this.doSaveItem(index, item);
    }
  }
});
