import { render, staticRenderFns } from "./EventCabinDetails.vue?vue&type=template&id=b0984172&scoped=true&"
import script from "./EventCabinDetails.vue?vue&type=script&lang=ts&"
export * from "./EventCabinDetails.vue?vue&type=script&lang=ts&"
import style0 from "./EventCabinDetails.vue?vue&type=style&index=0&id=b0984172&lang=scss&scoped=true&"
import style1 from "./EventCabinDetails.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0984172",
  null
  
)

export default component.exports