import { ref } from "@vue/composition-api";

import { ApiHelper } from "@/helpers";
import { FormSelect, FormText, SelectOption } from "@/types";
import FormTextarea from "@/types/FormTextarea";
import { v4 as uuidv4 } from "uuid";
import Vue from "vue";
import FormDate from "@/types/FormDate";
export function useSettingsParticipantFormsEditStore(context: any) {
  const isACILoading = ref<boolean>(false);
  let selectedFormId = parseInt(context.root.$route.params.formId) || 0;
  const headerData = ref({
    title: "",
    subTitle: "To Dos",
    recycled: 0
  });

  const pageData = ref<{
    isLoading: boolean;
    details: {
      jsonData: string;
    };
    form: {
      controls: {
        name: FormText;
        required: FormSelect;
        fileUnder: FormSelect;
        allEventsRequired: FormSelect;
        isVerify: FormSelect;
        dueDate: FormDate;
        dueDateDays: FormSelect;
        json: FormTextarea;
      };
    };
    presetFieldsData: object[];
  }>({
    isLoading: true,
    details: {
      jsonData: ""
    },
    form: {
      controls: {
        name: {
          required: true,
          label: "Form Name",
          style: "custom",
          placeholder: "Type your form name in here...",
          value: "",
          type: "text",
          error: ""
        },
        required: {
          error: "",
          style: "custom",
          label: "Required",
          value: "1",
          options: [
            {
              id: "1",
              text: "Yes",
              selected: false
            },
            {
              id: "0",
              text: "No",
              selected: false
            }
          ]
        },
        fileUnder: {
          error: "",
          style: "custom",
          type: "select",
          value: "0",
          label: "File Under",
          options: [
            {
              id: "0",
              text: "General",
              selected: false
            },
            {
              id: "1",
              text: "General & Medical",
              selected: false
            }
          ]
        },
        allEventsRequired: {
          error: "",
          type: "select",
          value: "1",
          label: "",
          style: "custom",
          options: []
        },
        isVerify: {
          style: "custom",
          error: "",
          label: "Require Admin Verification",
          value: "1",
          options: [
            {
              id: "1",
              text: "Yes",
              selected: false
            },
            {
              id: "0",
              text: "No",
              selected: false
            }
          ]
        },
        dueDate: {
          required: true,
          error: "",
          type: "date",
          value: undefined
        },
        dueDateDays: {
          style: "custom",
          error: "",
          type: "",
          placeholder: "Prior",
          required: true,
          label: "Due Date",
          value: "",
          options: [
            {
              id: "30",
              text: "1 month prior",
              selected: false
            },
            {
              id: "28",
              text: "4 weeks prior",
              selected: false
            },
            {
              id: "21",
              text: "3 weeks prior",
              selected: false
            },
            {
              id: "14",
              text: "2 weeks prior",
              selected: false
            },
            {
              id: "7",
              text: "1 week prior",
              selected: false
            },
            {
              id: "6",
              text: "6 days prior",
              selected: false
            },
            {
              id: "5",
              text: "5 days prior",
              selected: false
            },
            {
              id: "4",
              text: "4 days prior",
              selected: false
            },
            {
              id: "3",
              text: "3 days prior",
              selected: false
            },
            {
              id: "2",
              text: "2 days prior",
              selected: false
            },
            {
              id: "1",
              text: "1 day prior",
              selected: false
            }
          ]
        },
        json: {
          label: "",
          placeholder: "",
          value: "[]",
          error: "",
          type: "textarea"
        }
      }
    },
    presetFieldsData: []
  });
  const linkEvents = ref<any[]>([]);

  const getPresetFields = async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/applications/presetFields",
      {},
      {
        type: "participant_form"
      }
    );

    if (result.status === 1) {
      pageData.value.presetFieldsData = result.data.presetFields;
    }
  };

  const loadData = async (page: number) => {
    if (selectedFormId > 0) {
      ApiHelper.setDataLoading(true);
      try {
        const result = await ApiHelper.callApi(
          "get",
          "/todos/" + selectedFormId,
          {},
          {}
        );
        ApiHelper.setDataLoading(false);
        if (result.status === 1) {
          const resultData = result.data || null;
          if (resultData) {
            headerData.value.title = resultData.name || "";
            headerData.value.subTitle = "Settings - To Dos";
            pageData.value.form.controls.name.value = resultData.name || "";
            pageData.value.form.controls.required.value = resultData.required
              ? "1"
              : "0";
            pageData.value.form.controls.fileUnder.value =
              resultData.fileUnder || 0;
            pageData.value.form.controls.allEventsRequired.value = resultData.allEventsRequired
              ? "1"
              : "0";
            pageData.value.form.controls.isVerify.value = resultData.isVerify
              ? "1"
              : "0";
            headerData.value.recycled = resultData.isRecycled ? 1 : 0;
            pageData.value.form.controls.dueDate.value = resultData.duedate
              ? new Date(resultData.duedate).toISOString().substr(0, 10)
              : undefined;
            pageData.value.form.controls.dueDateDays.value =
              resultData.dueDateDays || "";

            const jsonData = JSON.parse(resultData.content || "[]").filter(
              (item: any) =>
                (item.name || "").indexOf("explain-conditionalOptions-") == -1
            );
            pageData.value.form.controls.json.value = JSON.stringify(jsonData);
          }
        } else {
          const message = result.message || "";
          if (message) {
            ApiHelper.showErrorMessage(message, "Oops");
          }
          const errorCode = result.errorCode || "";
          if (errorCode == "not_found") {
            ApiHelper.gotoPage(context, {
              name: "SettingsParticipantForms"
            });
          }
        }
      } catch (err) {
        ApiHelper.setDataLoading(false);
      }
    } else {
      ApiHelper.setDataLoading(false);
    }

    // get app preset fields
    await getPresetFields();
  };

  // init data
  /*(async () => {
    loadData(1);
  })();*/

  const removePType = async (pType: any) => {
    const newLines = linkEvents.value.filter(
      (item: any) => item.uuid != pType.uuid
    );
    linkEvents.value = newLines;
  };

  const modalLinkEvent = ref<{
    foundEvents: object[];
    isProcessing: boolean;
    show: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
    suggestEvents: any;
    selectEvent: any;
    applySelectedItems: any;
    removePType: any;
    endItemMessage?: string;
  }>({
    foundEvents: [],
    isProcessing: false,
    show: false,
    formData: {},
    removeFieldError: (name: string) => {
      // switch (name) {
      //   case "email":
      //     formData.value.controls.email.error = "";
      //     break;
      //   case "firstName":
      //     formData.value.controls.firstName.error = "";
      //     break;
      // }
    },
    onSubmit: async () => {
      // validate
      const hasError = false;
      const selectedPTypeIDs = linkEvents.value.filter(
        (item: any) => item.eventId != ""
      );

      if (!hasError && selectedPTypeIDs.length) {
        try {
          const result = await ApiHelper.callApi(
            "put",
            "/todos/" + selectedFormId + "/links",
            {
              pTypes: JSON.stringify(selectedPTypeIDs)
            },
            {}
          );
        } catch (error) {
          ApiHelper.showErrorMessage(JSON.stringify(error));
        }
      } else if (selectedPTypeIDs.length == 0) {
        modalLinkEvent.value.show = false;
      }
      ApiHelper.gotoPage(context, {
        name: "SettingsParticipantForms",
        query: { order: "1", direction: "2" }
      });
    },
    suggestEvents: async (key: string, onFocus = false) => {
      if (onFocus && key) {
        // ignore search
        return;
      }
      modalLinkEvent.value.foundEvents = [];
      if (onFocus) {
        await ApiHelper.sleep(100);
      }
      modalLinkEvent.value.endItemMessage = key
        ? ""
        : "Top 5 last event are listed, search to see more event";

      const selectedPTypeIDs = linkEvents.value
        .filter((item: any) => item.participantTypeID != "")
        .map((item: any) => `${item.eventID}-${item.participantTypeID}`);
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/todos/eventparticipants",
        {},
        {
          key: key,
          notInPTypeIDs: selectedPTypeIDs.join(",")
        }
      );
      isACILoading.value = false;
      if (result.status !== 1) {
        return;
      }
      const data = result.data.items || [];
      if (data.length) {
        modalLinkEvent.value.foundEvents = data.map((item: any) => ({
          id: item.LINK_Event_Participant,
          html: `${item.ev_name} - ${item.participant_typename}`,
          text: item.ev_name,
          selected: false,
          data: item
        }));
      }
    },
    selectEvent: (data: any) => {
      if (data) {
        linkEvents.value.push({
          uuid: uuidv4(),
          eventID: data.eventID || "",
          eventName: data.ev_name || "",
          participantTypeID: data.participant_typeid || "",
          participantTypeName: data.participant_typename || ""
        });
      }
    },
    applySelectedItems: () => {
      modalLinkEvent.value.foundEvents
        .filter((item: any) => item.selected)
        .map((item: any) => {
          const data = item.data;
          linkEvents.value.push({
            uuid: uuidv4(),
            eventID: data.eventID || "",
            eventName: data.ev_name || "",
            participantTypeID: data.participant_typeid || "",
            participantTypeName: data.participant_typename || ""
          });
        });
      modalLinkEvent.value.foundEvents = [];
    },
    removePType: async (pType: any) => {
      await removePType(pType);
    },
    endItemMessage: ""
  });

  const onSaveForm = async (evt: any, formData: any) => {
    let hasError = false;
    if (pageData.value.form.controls.name.value === "") {
      hasError = true;
      pageData.value.form.controls.name.error = "Name is required.";
    }
    if (pageData.value.form.controls.dueDateDays.value === "") {
      hasError = true;
      pageData.value.form.controls.dueDateDays.error =
        "Due Date Days is required.";
    }
    if (!hasError) {
      let formDataArray = JSON.parse(formData || "[]");
      formDataArray = formDataArray.filter(
        (item: any) =>
          (item.name || "").indexOf("explain-conditionalOptions-") == -1
      );
      const jsonData = [];
      for (const item of formDataArray) {
        // fix: all options of checkbox group should have a value
        if (item.type == "checkbox-group") {
          item.values = item.values.map((t: any) => ({
            ...t,
            value: t.value.trim() || t.label.trim()
          }));
        }

        jsonData.push(item);

        // add more content for item conditionalOptions
        if ((item.type || "") == "conditionalOptions") {
          jsonData.push({
            type: "text",
            required: item.required || false,
            label: item.explainLabel || "Please explain",
            className: "form-control",
            name: `explain-${item.name}`,
            subtype: "text",
            placeholder: item.explainPlaceHolder || ""
          });
        }
      }

      const result = await ApiHelper.callApi(
        "put",
        "/todos/" + selectedFormId,
        {
          name: pageData.value.form.controls.name.value,
          required: parseInt(pageData.value.form.controls.required.value),
          isVerify: parseInt(pageData.value.form.controls.isVerify.value),
          allEventsRequired: 0 /* parseInt(
            pageData.value.form.controls.allEventsRequired.value
          ) */,
          dueDate: pageData.value.form.controls.dueDate.value
            ? new Date(pageData.value.form.controls.dueDate.value)
            : undefined,
          dueDateDays: Number.parseInt(
            pageData.value.form.controls.dueDateDays.value,
            10
          ),
          content: JSON.stringify(jsonData),
          fileUnder: parseInt(pageData.value.form.controls.fileUnder.value),
          isRecycled: headerData.value.recycled
        },
        {}
      );
      if (result.status == 1) {
        selectedFormId = parseInt(result.data.id);
        if ((parseInt(context.root.$route.params.formId) || 0) == 0) {
          modalLinkEvent.value.show = true;
        } else {
          ApiHelper.showSuccessMessage("Updated");

          // update preset fields
          await getPresetFields();
          $(".panel-fields ul.frmb-control li .my-cf-in-apps").each(function() {
            const fieldID = $(this)
              .closest("li")
              .data("fieldid");
            const theField: any = pageData.value.presetFieldsData.find(
              (item: any) => item.eAppFieldId == fieldID
            );
            if (theField) {
              $(this).html(theField.inApps || 0);
            }
          });
        }
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    }
  };

  const duplicateForm = async (evt: any) => {
    const hasError = false;
    const isConfirmed = await Vue.swal({
      text: "Are you sure you want to duplicate this form?",
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then(result => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      return result.isConfirmed;
    });
    if (!isConfirmed) {
      return;
    }

    if (!hasError) {
      const result = await ApiHelper.callApi(
        "put",
        `/todos/${selectedFormId}/duplicate`,
        {},
        {}
      );
      if (result.status == 1) {
        const newFormId = result.data.id || 0;
        Vue.swal({
          html: `Duplicated form #${newFormId}`,
          timer: 5000
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          if (result.isConfirmed) {
            ApiHelper.gotoPage(context, {
              name: "SettingsParticipantFormsEdit",
              params: { formId: `${newFormId}` }
            });
          }
        });

        ApiHelper.gotoPage(context, {
          name: "SettingsParticipantForms",
          query: { order: "1", direction: "2" }
        });
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    }
  };

  const loadInApps = async (fieldId: number) => {
    const result = await ApiHelper.callApi(
      "get",
      "/applications/inApps",
      {},
      {
        fieldId,
        type: "participant_form"
      }
    );
    if (result.status === 1) {
      return result.data;
    }

    return [];
  };

  return {
    selectedFormId,
    duplicateForm,
    loadInApps,
    linkEvents,
    modalLinkEvent,
    loadData,
    onSaveForm,
    headerData,
    pageData,
    isACILoading
  };
}
