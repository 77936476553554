





























































import { defineComponent, ref } from "@vue/composition-api";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import VScroller from "@/components/Common/VScroller.vue";

export default defineComponent({
  name: "SidebarFamilyDetails",
  props: {
    familyId: String,
    title: String
  },
  components: {
    VScroller,
    HeaderTabs,
    Avatar
  },
  setup(props, context) {
    const sidebarData = ref<{
      isLoading: boolean;
      name: string;
      logo: string;
      totalLinkedProfiles: number;
    }>({
      isLoading: false,
      name: "",
      logo: "",
      totalLinkedProfiles: 0
    });

    return {
      sidebarData
    };
  }
});
