var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MessagingInboxPage MasterPage"},[_c('div',{staticClass:"pt-0",attrs:{"id":"content"}},[(_vm.selectedId == 0)?_c('div',{staticClass:"chatbox",class:{ hasSidebar: true }},[_vm._m(0)]):_vm._e(),(_vm.selectedId != 0)?_c('div',{staticClass:"chatbox"},[_c('div',{staticClass:"content-head chat__header d-flex align-items-center",staticStyle:{"max-width":"1250px"}},[_c('div',{staticClass:"head_left"},[_c('h2',{staticClass:"head_heading"},[(_vm.selectedId != -1)?_c('span',{staticStyle:{"color":"#2F6B46"}},[_vm._v(_vm._s(_vm.headerData.title))]):[_c('div',{staticClass:"d-flex align-items-center newMessage"},[_c('span',{staticClass:"mr-3"},[_vm._v("New Message:")]),_c('DropdownBox',{attrs:{"green":true,"leftToRight":true,"show":_vm.newData.show,"label":_vm.newData.name || '---'},on:{"onClick":function () {
                      _vm.newData.show = !_vm.newData.show;
                    },"onDismiss":function () {
                      _vm.newData.show = false;
                    }}},[_c('div',{attrs:{"slot":"dropdown_content"},slot:"dropdown_content"},[_c('div',{staticClass:"group"},[_c('AutoCompleteInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.newData.showAci),expression:"newData.showAci"}],staticClass:"sidebar-profiles-aci",attrs:{"placeholder":'Type a person name...',"options":_vm.newData.foundProfiles,"nowrap":true,"showLineTitle":true,"isACILoading":_vm.isACILoading || false},on:{"input":function (key) { return _vm.suggestProfiles(key); },"select":function (item) { return _vm.selectProfile(item); }}})],1)])])],1)]],2)])]),(_vm.headerData.status == 2)?_c('div',{staticClass:"mw-1300"},[_vm._m(1)]):_vm._e(),(_vm.headerData.status !== 2)?_c('VScroller',{staticClass:"chat__body",class:{
          replyTodo: _vm.popupNewItem.formData.controls.participant.value
        }},[_c('div',{staticStyle:{"max-width":"1200px","margin":"auto"}},[_c('div',{staticClass:"messages",staticStyle:{"margin-top":"-37px !important"},attrs:{"slot":"default"},slot:"default"},[(!_vm.pageData.isLoading && _vm.pageData.items.length < 1)?_c('div',{staticClass:"noData"},[_vm._v(" Nothing to see here quite yet...zero messages. ")]):_vm._e(),_vm._l((_vm.pageData.items),function(item,index){return _c('div',{key:'message-item-' + index + '-' + item.id,staticClass:"message_item",class:{
                owner: item.isAdmin
              }},[(item.participantId)?_c('div',{staticClass:"message-tags"},[_c('span',{staticClass:"message-tag"},[_vm._v(" "+_vm._s(item.eventName)+" / "+_vm._s(item.participantTypeName)+_vm._s(item.formName ? " - " + item.formName : "")+" ")]),(!item.isAdmin && item.formId)?_c('a',{staticClass:"btn-reply",on:{"click":function($event){return _vm.replyQuestion(item)}}},[_vm._v(" Reply ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"message_header"},[_c('strong',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(item.tsFormatted))])]),_c('div',{staticClass:"message-body",domProps:{"innerHTML":_vm._s(item.message)}})])})],2),_c('div',{staticClass:"end-messages"})])]):_vm._e(),(_vm.headerData.status !== 2)?_c('div',{staticClass:"chat__footer"},[_c('div',{staticClass:"alignment-msg"},[(_vm.popupNewItem.formData.controls.participant.value)?_c('div',{staticClass:"sub-description "},[_c('span',[_vm._v("Replying for \""),_c('strong',[_vm._v(_vm._s(_vm.popupNewItem.formData.controls.participant.label))]),_vm._v("\"")]),_c('span',{staticClass:"btn-remove",on:{"click":function () {
                  _vm.popupNewItem.formData.controls.participant.value = '';
                  _vm.popupNewItem.formData.controls.form.value = '';
                  _vm.popupNewItem.formData.controls.participant.label = '';
                }}},[_vm._v("×")])]):_vm._e(),_c('div',{},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.popupNewItem.formData.controls.message.value),expression:"popupNewItem.formData.controls.message.value"}],attrs:{"placeholder":"TYPE A NEW MESSAGE AND PRESS ENTER...","disabled":_vm.popupNewItem.isProcessing},domProps:{"value":(_vm.popupNewItem.formData.controls.message.value)},on:{"keydown":function (event) { return _vm.onKeydown(event, _vm.$root); },"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.popupNewItem.formData.controls.message, "value", $event.target.value)}}})])])]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"setting-panel setting-pannel-msg z-index",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"inbox-sidebar setting-panel-items p-0"},[_c('div',{staticClass:"box_content item__smenu"},[_c('div',{staticClass:"sidebar-container",attrs:{"slot":"default"},slot:"default"},[_c('div',{staticClass:"filters-container"},[_c('div',{staticClass:"filter-item",staticStyle:{"margin":"10px"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center bd-highlight mb-3",staticStyle:{"margin-left":"30px"}},[_c('h3',{staticClass:"mb-0 sidebar-title"},[_c('router-link',{attrs:{"to":{ name: 'MessagingInbox' }}},[_vm._v("Inbox")])],1),_c('div',{staticStyle:{"display":"inline-block"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.pageData.sort.order),expression:"pageData.sort.order"}],staticStyle:{"background-color":"transparent","border":"none","margin-left":"10px","float":"right"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.pageData.sort, "order", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function () {
                          _vm.loadGroups();
                        }]}},[_c('option',{attrs:{"value":"1"}},[_vm._v("DATE")]),_c('option',{attrs:{"value":"2"}},[_vm._v("NAME")])])]),_c('router-link',{attrs:{"to":{
                      name: 'MessagingInbox',
                      query: { id: '-1' }
                    }}},[_c('img',{attrs:{"src":require("../../../assets/svg/plus.svg"),"title":"Add"}})])],1)])]),_c('VScroller',{staticStyle:{"max-height":"calc(100vh - 150px)"}},[_c('div',{staticClass:"group-item",class:{
                  active: _vm.$route.query.id == '-1'
                },staticStyle:{"margin-top":"10px"}},[(_vm.selectedId == -1)?_c('a',[_c('div',{staticClass:"chat-group-item"},[_c('strong',[_vm._v("New Message")]),_c('span',{staticClass:"ml-1 date"}),_c('span',{staticClass:"newMessages d-none"})]),_c('div',{staticClass:"message-body"})]):_vm._e()]),_vm._l((_vm.pageData.groups),function(item,index){return _c('div',{key:'message-group-' + index + '-' + item.messageId,staticClass:"group-item",class:{
                  active:
                    item.profileId.toString() === (_vm.$route.query.id || '0')
                }},[_c('router-link',{attrs:{"to":{
                    name: 'MessagingInbox',
                    query: { id: item.profileId.toString() }
                  }}},[(item.participantId)?_c('div',{staticClass:"message-tags"},[_c('span',{staticClass:"message-tag"},[_vm._v(" "+_vm._s(item.eventName)+" - "+_vm._s(item.participantTypeName)+" ")])]):_vm._e(),_c('div',{staticClass:"chat-group-item"},[_c('strong',{attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"ml-1 date"},[_vm._v(_vm._s(item.createTsFormatted))]),(item.total && _vm.selectedId !== item.profileId)?_c('span',{staticClass:"newMessages"},[_vm._v(" "+_vm._s(item.total)+" ")]):_vm._e()]),_c('div',{staticClass:"message-body",domProps:{"innerHTML":_vm._s(item.message)}})])],1)})],2)],1)])]),_vm._m(2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","align-items":"center","min-height":"60vh","justify-content":"center"}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_c('img',{attrs:{"src":require("../../../assets/images/svg/inbox.svg")}})]),_c('div',{staticStyle:{"padding":"10px","font-weight":"bold"}},[_vm._v(" Select an item to read ")]),_c('div',[_vm._v("Nothing is selected")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"-60px"}},[_c('div',{staticClass:"text-danger",staticStyle:{"padding":"20px","border":"1px solid #999","background-color":"#eee"}},[_c('h5',[_vm._v("This person was archived!")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newMenu sidebar_icons"},[_c('div',{staticClass:"item"})])}]

export { render, staticRenderFns }