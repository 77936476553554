<template>
  <div ng-include="errorPageCtrl.errorPageUrl" class="ng-scope">
    <div class="error-404-non-branded ng-scope">
      <div class="error-contents">
        <svg
          class="image-404"
          viewBox="0 0 429.4 301.63"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="cls-1"
            d="M353.1 7c-60.4-21.62-127.4 8.47-149.65 67.22-15.42 40.74-5.28 84.56 22.53 114.8a114.63 114.63 0 0 0 15.17 13.8c9.74 10.83-17.63 23-28.06 26.84a1 1 0 0 0 .22 1.96c68.73 7.63 114.1-2.8 137.62-11.1l.98-.36.56-.2q5.14-1.8 10.07-4.08a80.2 80.2 0 0 0 7.25-3.65 113.82 113.82 0 0 0 52.34-59.66C444.42 93.82 413.5 28.65 353.1 7z"
          ></path>
          <path
            class="cls-2"
            d="M348.8 7a113.38 113.38 0 0 0-108.87 195.83c9.47 10.82-17.16 22.98-27.3 26.83a1.02 1.02 0 0 0 .27 1.96c66.85 7.63 110.98-2.8 133.86-11.1l.95-.36.6-.2q5-1.8 9.8-4.08a77.23 77.23 0 0 0 7.05-3.65A113.33 113.33 0 0 0 348.8 7z"
          ></path>
          <path
            class="cls-3"
            d="M31.9 278.16a4.96 4.96 0 0 1-4.6-4.92v-99.46a4.7 4.7 0 0 1 4.6-4.62h130.67a4.82 4.82 0 0 1 4.74 4.62v99.46a5.1 5.1 0 0 1-4.7 4.92z"
          ></path>
          <path
            class="cls-2"
            d="M162.57 170.16a3.72 3.72 0 0 1 3.74 3.62v99.46a3.98 3.98 0 0 1-3.7 3.92H31.9a3.85 3.85 0 0 1-3.6-3.92v-99.46a3.6 3.6 0 0 1 3.6-3.62h130.4m.27-2H31.9a5.8 5.8 0 0 0-5.6 5.62v100.46c0 2.96 2.64 4.92 5.6 4.92h130.67c2.96 0 5.74-1.96 5.74-4.92V173.78a5.92 5.92 0 0 0-5.7-5.62z"
          ></path>
          <path
            class="cls-4"
            d="M164.3 260.4v10.5c0 1.96-1.95 3.26-3.9 3.26H34.07a3.5 3.5 0 0 1-3.75-3.26v-97.2a3.73 3.73 0 0 1 3.76-3.54H160.4a3.86 3.86 0 0 1 3.9 3.53v86.7z"
          ></path>
          <path
            class="cls-4"
            d="M32.37 170.16H162a2.3 2.3 0 0 1 2.3 2.3v15.7h-134v-15.94a2.06 2.06 0 0 1 2.07-2.06z"
          ></path>
          <path class="cls-5" d="M28.3 188.16h138v2h-138z"></path>
          <circle class="cls-6" cx="39.18" cy="179.11" r="2.52"></circle>
          <circle class="cls-6" cx="47" cy="179.11" r="2.52"></circle>
          <circle class="cls-6" cx="54.82" cy="179.11" r="2.52"></circle>
          <path
            class="cls-7"
            d="M109.65 254.16a3.94 3.94 0 0 0 3.6-5.02 17.14 17.14 0 0 0-33 .06 3.9 3.9 0 0 0 3.6 4.96z"
          ></path>
          <path
            class="cls-7"
            d="M109.65 254.16a3.94 3.94 0 0 0 3.6-5.02 17.14 17.14 0 0 0-33 .06 3.9 3.9 0 0 0 3.6 4.96z"
          ></path>
          <path
            class="cls-8"
            d="M106.83 254.16a3.08 3.08 0 0 0 2.83-3.92 13.4 13.4 0 0 0-25.8.04 3.04 3.04 0 0 0 2.82 3.88z"
          ></path>
          <circle class="cls-9" cx="64.42" cy="235.5" r="5.15"></circle>
          <circle class="cls-9" cx="130.05" cy="235.5" r="5.15"></circle>
          <circle class="cls-10" cx="123.99" cy="217.4" r="4.68"></circle>
          <circle class="cls-11" cx="127.08" cy="215.92" r="1.48"></circle>
          <circle class="cls-10" cx="70.49" cy="217.4" r="4.68"></circle>
          <circle class="cls-11" cx="73.58" cy="215.92" r="1.48"></circle>
          <ellipse
            class="cls-12"
            cx="96.55"
            cy="297.08"
            rx="85.78"
            ry="4.55"
          ></ellipse>
          <path
            class="cls-11"
            d="M55.7 195.16H35.4a1 1 0 0 1 0-2h20.3a1 1 0 0 1 0 2zm104.34 79h-8.1c-.47 0-.86-.02-.86-.5s.4-.5.87-.5h8.1a2.58 2.58 0 0 0 2.26-2.53v-7.17a1 1 0 0 1 2 0v7.17c0 2.16-2.1 3.53-4.22 3.53z"
          ></path>
          <path
            class="cls-5"
            d="M18.56 160.9a1.73 1.73 0 0 1-1.18-.45l-16.05-14.8a1.74 1.74 0 0 1 2.35-2.55l16.06 14.8a1.74 1.74 0 0 1-1.18 3zm5.8-6.6a1.74 1.74 0 0 1-1.7-1.4l-2.28-11.7a1.74 1.74 0 1 1 3.4-.67l2.3 11.7a1.74 1.74 0 0 1-1.4 2.05 1.7 1.7 0 0 1-.32.03zM1.74 170a1.74 1.74 0 0 1-.16-3.47l14.55-1.37a1.74 1.74 0 0 1 .32 3.47L1.9 170h-.16z"
          ></path>
          <text class="error-code" x="244" y="140">404</text>
        </svg>
        <div class="not-found" translate="non_branded_page_not_found_title">
          PAGE NOT FOUND
        </div>
        <div class="description" translate="non_branded_page_not_found_text">
          We looked everywhere for this page. <br />Are you sure the website URL
          is correct?<br />Get in touch with the site owner.
        </div>
        <div style="padding-top: 20px;">
          <router-link to="/" class="button">Go Back Home</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped lang="scss">
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

.link-arrow,
.text-arrow {
  margin-left: 8px;
  width: 4px;
  height: 8px;
  position: absolute;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.secondary-background {
  background-color: #151519;
}

.text {
  color: #363636;
}

.page-subtitle {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh,
    HelveticaNeueW10-45Ligh, sans-serif;
  font-size: 34px;
  line-height: 48px;
}

.page-title,
.published-not-available .page-secondary-title {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma,
    HelveticaNeueW10-55Roma, sans-serif;
  font-size: 45px;
  line-height: 50px;
}

.error-code {
  margin-top: 30px;
  font-size: 22px;
  color: #929292;
  line-height: 35px;
}

.page-message {
  margin-top: 14px;
  font-size: 22px;
  line-height: 26px;
}

.contact-support {
  font-size: 18px;
  margin-top: 87px;
  line-height: 30px;
}

.secondary-font {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh,
    HelveticaNeueW10-45Ligh, sans-serif;
}

.authorization-not-supported .help-link,
.error-400 .help-link,
.header-link,
.invite-expired .help-link,
.support-link,
.thick-font,
.undocumented-error .help-link,
.unsupported-browser .continue-link {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi,
    HelveticaNeueW10-65Medi, sans-serif;
}

.support-link,
.unsupported-browser .continue-link {
  color: #fb7d33;
}

.link-arrow {
  //background: url(../../images/error-pages/link-arrow.png) no-repeat;
  margin-top: 13px;
}

body[lang="de"] .link-arrow {
  margin-top: 11px;
}

.text-arrow {
  //background: url(../../images/error-pages/text-arrow.png) no-repeat;
  margin-top: 15px;
}

.pink-arrow {
  width: 4px;
  height: 8px;
  position: absolute;
  //background: url(../../images/error-pages/pink-arrow.png) no-repeat;
  margin-top: 13px;
  margin-left: 5px;
  float: right;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.bg-img,
.cable-spaghetti-bg {
  z-index: -1;
  width: 1219px;
  height: 457px;
  //background: url(../../images/error-pages/default-bg.jpg) no-repeat;
  position: fixed;
  top: 200px;
  margin-left: 340px;
}

body[lang="ja"] .bg-img,
body[lang="ja"] .cable-spaghetti-bg {
  margin-left: 530px !important;
}

.cable-spaghetti-bg {
  width: 1138px;
  height: 776px;
  //background: url(../../images/error-pages/cable-spaghetti-bg.jpg) no-repeat;
  top: 60px;
  margin-left: 420px;
}

.non-angular-supported-browser-wrapper {
  text-align: center;
}

.non-angular-supported-browser-header {
  font-size: 40px;
  text-decoration: underline;
}

.non-angular-supported-browser-link {
  font-size: 18px;
  margin-top: 50px;
}

.header {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.header-link {
  transition: all linear 0.3s;
  border-top: 4px solid transparent;
  padding: 17px 15px 0;
  color: #555;
}

.header-link:hover {
  color: #0f0f0f;
  text-decoration: none;
  border-top: 4px solid #fc0;
}

.wix-logo {
  position: absolute;
  top: 10px;
  width: 121px;
  height: 33px;
}

.error-page-app {
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

.content {
  z-index: 10;
  position: relative;
  margin: 0 auto;
  width: 1060px;
}

.page-content-wrapper {
  margin-top: 204px;
  width: 560px;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

@media only screen and (max-width: 1060px) {
  .content {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .header-link {
    font-size: 11px;
  }
  .wix-logo {
    top: 18px;
    width: 70px;
    height: 19px;
  }
  .header nav {
    margin-left: 50px;
  }
  .page-content-wrapper,
  .page-title,
  .published-not-available .page-secondary-title {
    width: 100%;
  }
  .page-title,
  .published-not-available .page-secondary-title {
    font-size: 44px;
  }
  .published-not-available .undocumented-error .page-secondary-title,
  .undocumented-error .page-title,
  .undocumented-error .published-not-available .page-secondary-title {
    font-size: 34px;
  }
  body[lang="ja"] .bg-img,
  body[lang="ja"] .cable-spaghetti-bg {
    margin-left: inherit !important;
  }
  body[lang="ja"] .page-content-wrapper {
    width: inherit !important;
  }
  .published-not-available body[lang="ja"] .page-secondary-title,
  body[lang="ja"] .page-title,
  body[lang="ja"] .published-not-available .page-secondary-title {
    font-size: 24px;
  }
  .bg-img,
  .cable-spaghetti-bg {
    width: 100%;
    height: 100%;
    top: 90px;
    margin-left: 100px;
  }
  .page-content-wrapper {
    margin-top: 110px !important;
    margin-left: 20px;
  }
  .contact-support {
    margin-top: 67px;
    padding-bottom: 70px;
  }
  .connect-your-domain .page-content-wrapper,
  .connect-your-domain .page-title,
  .connect-your-domain .published-not-available .page-secondary-title,
  .published-not-available .connect-your-domain .page-secondary-title,
  .published-not-available .unsupported-browser .page-secondary-title,
  .unsupported-browser .page-content-wrapper,
  .unsupported-browser .page-title,
  .unsupported-browser .published-not-available .page-secondary-title {
    width: 70%;
  }
  .connect-your-domain .bg-img,
  .connect-your-domain .cable-spaghetti-bg,
  .unsupported-browser .bg-img,
  .unsupported-browser .cable-spaghetti-bg {
    margin-left: 130px;
  }
  .connect-your-domain .continue-link,
  .connect-your-domain .update-options-list,
  .unsupported-browser .continue-link,
  .unsupported-browser .update-options-list {
    margin-top: 10px !important;
  }
  .connect-your-domain .page-title,
  .connect-your-domain .published-not-available .page-secondary-title,
  .published-not-available .connect-your-domain .page-secondary-title,
  .published-not-available .unsupported-browser .page-secondary-title,
  .unsupported-browser .page-title,
  .unsupported-browser .published-not-available .page-secondary-title {
    font-size: 24px;
    line-height: 30px;
  }
  .connect-your-domain .page-subtitle,
  .unsupported-browser .page-subtitle {
    font-size: 19px;
    line-height: 20px;
  }
  .published-not-available .page-secondary-title,
  .published-not-available .page-title {
    font-size: 25px;
  }
  .published-not-available .page-content-wrapper {
    width: 335px !important;
  }
  .published-not-available .bg-img,
  .published-not-available .cable-spaghetti-bg {
    margin-left: -190px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1060px) {
  .page-title,
  .published-not-available .page-secondary-title {
    font-size: 50px;
  }
  .bg-img,
  .cable-spaghetti-bg {
    margin-left: 100px !important;
  }
  .page-content-wrapper {
    margin-top: 110px;
    margin-left: 20px;
  }
  .contact-support {
    margin-top: 67px;
  }
  .connect-your-domain .bg-img,
  .connect-your-domain .cable-spaghetti-bg,
  .unsupported-browser .bg-img,
  .unsupported-browser .cable-spaghetti-bg {
    margin-left: 320px !important;
  }
}

@media only screen and (min-width: 1061px) and (max-width: 1440px) {
  body[lang="ja"] .bg-img,
  body[lang="ja"] .cable-spaghetti-bg {
    margin-left: 490px !important;
  }
}

.published-not-available body[lang="it"] .page-secondary-title,
body[lang="it"] .page-title,
body[lang="it"] .published-not-available .page-secondary-title {
  width: 530px;
}

.unsupported-browser .browser-icon {
  width: 21px;
  height: 21px;
  margin-top: 8px;
  display: inline-block;
  position: absolute;
}

.unsupported-browser .update-option {
  line-height: 35px;
  height: 35px;
}

.unsupported-browser .update-text {
  font-size: 18px;
  margin-left: 30px;
  display: inline-block;
}

.unsupported-browser .continue-link {
  font-size: 18px;
  position: absolute;
  margin-top: 60px;
  cursor: pointer;
}

.unsupported-browser .continue-link .link-arrow {
  top: -6px;
  right: -10px;
}

.unsupported-browser .update-options-list {
  margin-top: 60px;
}

.unsupported-browser .page-content-wrapper {
  margin-top: 111px;
}

.unsupported-browser .page-message {
  margin-top: 22px;
  font-size: 18px;
}

.unsupported-browser-Chrome,
.unsupported-browser-Edge,
.unsupported-browser-Firefox,
.unsupported-browser-Safari,
.unsupported-browser-sprite {
  //background-image: url(../../images/generated/icons/unsupported-browser-s0168b02e23.png);
  background-repeat: no-repeat;
}

.unsupported-browser-Chrome {
  background-position: 0 0;
}

.unsupported-browser-Edge {
  background-position: 0 -21px;
}

.unsupported-browser-Firefox {
  background-position: 0 -41px;
}

.unsupported-browser-Safari {
  background-position: 0 -62px;
}

.published-not-available .too-many-cookies .page-secondary-title,
.too-many-cookies .page-title,
.too-many-cookies .published-not-available .page-secondary-title {
  font-family: HelveticaNeueW01-35Thin, HelveticaNeueW02-35Thin,
    HelveticaNeueW10-35Thin, sans-serif;
}

.published-not-available .page-secondary-title,
.too-many-cookies .help.title {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi,
    HelveticaNeueW10-65Medi, sans-serif;
}

.too-many-cookies .instructions-link,
.too-many-cookies .wix-support-link {
  color: #3899ec;
}

.too-many-cookies .page-message {
  font-size: 18px;
}

.too-many-cookies .more-help {
  margin-top: 85px;
}

.too-many-cookies .help {
  line-height: 22px;
  font-size: 18px;
}

.invite-expired .page-title,
.invite-expired .published-not-available .page-secondary-title,
.published-not-available .invite-expired .page-secondary-title {
  max-width: 290px;
}

.invite-expired .p-separator {
  margin-top: 25px;
}

.invite-expired .mail-link {
  color: #aa4dc8;
  font-size: 22px;
}

.authorization-not-supported .bg-img,
.authorization-not-supported .cable-spaghetti-bg,
.error-400 .bg-img,
.error-400 .cable-spaghetti-bg,
.invite-expired .bg-img,
.invite-expired .cable-spaghetti-bg,
.undocumented-error .bg-img,
.undocumented-error .cable-spaghetti-bg {
  z-index: -1;
  width: 1313px;
  height: 721px;
  //background: url(../../images/error-pages/400_BG.jpg) no-repeat;
  position: fixed;
  top: 61px;
  margin-left: 350px;
}

.authorization-not-supported .links-list-400-page,
.error-400 .links-list-400-page,
.invite-expired .links-list-400-page,
.undocumented-error .links-list-400-page {
  margin-top: 53px;
}

.authorization-not-supported .help-link,
.error-400 .help-link,
.invite-expired .help-link,
.undocumented-error .help-link {
  position: relative;
  color: #aa4dc8;
  font-size: 18px;
  line-height: 30px;
}

.authorization-not-supported .page-content-wrapper,
.error-400 .page-content-wrapper,
.invite-expired .page-content-wrapper,
.undocumented-error .page-content-wrapper {
  margin-top: 177px;
}

.authorization-not-supported .page-title,
.authorization-not-supported .published-not-available .page-secondary-title,
.error-400 .page-title,
.error-400 .published-not-available .page-secondary-title,
.invite-expired .page-title,
.invite-expired .published-not-available .page-secondary-title,
.published-not-available .authorization-not-supported .page-secondary-title,
.published-not-available .error-400 .page-secondary-title,
.published-not-available .invite-expired .page-secondary-title,
.published-not-available .undocumented-error .page-secondary-title,
.undocumented-error .page-title,
.undocumented-error .published-not-available .page-secondary-title {
  display: inline;
}

.authorization-not-supported .error-code,
.error-400 .error-code,
.invite-expired .error-code,
.undocumented-error .error-code {
  margin-top: 0;
}

.authorization-not-supported .page-message,
.error-400 .page-message,
.invite-expired .page-message,
.undocumented-error .page-message {
  margin-top: 22px;
}

.authorization-not-supported .page-content-wrapper {
  width: 600px;
}

.not-published .bg-img,
.not-published .cable-spaghetti-bg {
  z-index: -1;
  width: 1920px;
  height: 765px;
  //background: url(../../images/error-pages/not-published-bg.jpg) no-repeat;
  position: fixed;
  top: 60px;
}

.not-published .how-to-steps {
  list-style: decimal inside;
}

.not-published .steps-to-publish {
  margin-top: 55px;
}

.not-published .my-sites-link {
  color: #3899ec;
}

.not-published .text {
  color: #fff;
}

.not-published .page-subtitle {
  line-height: 38px;
}

.not-published .page-content-wrapper {
  margin-top: 146px;
}

.connect-your-domain .how-to-steps {
  margin-top: 20px;
  list-style: decimal inside;
}

.connect-your-domain .steps-to-connect {
  margin-top: 30px;
}

.connect-your-domain .my-sites-link {
  color: #fb7d33;
}

.connect-your-domain .contact-support {
  line-height: 25px;
}

.connect-your-domain .link-arrow {
  margin-top: 10px;
}

.connect-your-domain .page-title,
.connect-your-domain .published-not-available .page-secondary-title,
.published-not-available .connect-your-domain .page-secondary-title {
  font-size: 34px;
  line-height: 40px;
}

.connect-your-domain .page-content-wrapper {
  margin-top: 187px;
  width: 600px;
}

body[lang="ja"] .page-content-wrapper {
  width: 700px;
}

.published-not-available .bg-img,
.published-not-available .cable-spaghetti-bg {
  z-index: -1;
  width: 1375px;
  height: 742px;
  //background: url(../../images/error-pages/published-not-available.jpg) no-repeat;
  position: fixed;
  top: 61px;
  margin-left: 150px;
}

.published-not-available .page-content-wrapper {
  margin-top: 161px;
  width: 650px;
}

.error-404-non-branded {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.error-404-non-branded > .error-contents {
  margin: 84px auto 0;
}

.error-404-non-branded > .error-contents > .image-404 {
  width: 318px;
  margin-left: -20px;
}

@media (max-width: 612px) and (min-width: 380px) {
  .error-404-non-branded > .error-contents {
    margin-top: 14vw;
  }
  .error-404-non-branded > .error-contents > .image-404 {
    margin-left: -2.9vw;
    width: 52vw;
  }
}

@media (max-width: 380px) {
  .error-404-non-branded > .error-contents {
    margin-top: 64px;
    text-align: center;
  }
  .error-404-non-branded > .error-contents > .image-404 {
    width: 216px;
  }
}

.error-404-non-branded > .error-contents > .image-404 .cls-1 {
  fill: #3ba6cc;
}

.error-404-non-branded > .error-contents > .image-404 .cls-2 {
  fill: #3eb8eb;
}

.error-404-non-branded > .error-contents > .image-404 .cls-3 {
  fill: #c3d4d8;
}

.error-404-non-branded > .error-contents > .image-404 .cls-4 {
  fill: #e1ebef;
}

.error-404-non-branded > .error-contents > .image-404 .cls-5 {
  fill: #37b7ea;
}

.error-404-non-branded > .error-contents > .image-404 .cls-6 {
  fill: #a2b7bc;
}

.error-404-non-branded > .error-contents > .image-404 .cls-7 {
  fill: #a9a9a9;
}

.error-404-non-branded > .error-contents > .image-404 .cls-8 {
  fill: #9c9b9b;
}

.error-404-non-branded > .error-contents > .image-404 .cls-9 {
  fill: #f197be;
}

.error-404-non-branded > .error-contents > .image-404 .cls-10 {
  fill: #565656;
}

.error-404-non-branded > .error-contents > .image-404 .cls-11 {
  fill: #fff;
}

.error-404-non-branded > .error-contents > .image-404 .cls-12 {
  fill: #e9eff2;
}

.error-404-non-branded > .error-contents > .image-404 .error-code {
  fill: #fff;
  font-family: HelveticaNeueW01-45Ligh;
  font-size: 80px;
}

.error-404-non-branded > .error-contents > .not-found {
  margin-top: 62px;
  color: #3eb8ea;
  font-size: 66px;
  font-family: HelveticaNeueW01-45Ligh;
  letter-spacing: 1px;
  line-height: 90px;
}

@media (max-width: 612px) and (min-width: 380px) {
  .error-404-non-branded > .error-contents > .not-found {
    margin-top: 10vw;
    font-size: 10vw;
    line-height: 13vw;
  }
}

@media (max-width: 380px) {
  .error-404-non-branded > .error-contents > .not-found {
    font-size: 31px;
    line-height: 50px;
  }
}

.error-404-non-branded > .error-contents > .description {
  margin-top: 19px;
  font-family: HelveticaNeueW01-45Ligh;
  letter-spacing: 1px;
  font-size: 20px;
  line-height: 39px;
  color: #184a56;
}

@media (max-width: 612px) and (min-width: 380px) {
  .error-404-non-branded > .error-contents > .description {
    font-size: 3.4vw;
    line-height: 6.8vw;
  }
}

@media (max-width: 380px) {
  .error-404-non-branded > .error-contents > .description {
    font-size: 14px;
    line-height: 24px;
  }
}

.error-404-non-branded > .error-contents .button {
  margin-top: 37px;
  outline: 0;
  height: 56px;
  letter-spacing: 0.8px;
  line-height: 50px;
  font-size: 22px;
  text-align: center;
  border-radius: 28px;
  font-family: HelveticaNeueW01-45Ligh;
  cursor: pointer;
  border: 2px solid #3eb8ea;
  background-color: #fff;
  color: #3eb8ea;
  box-sizing: border-box;
  transition-property: color, background-color;
  transition-duration: 0.2s;
  padding: 10px 20px;
}

@media (max-width: 612px) and (min-width: 380px) {
  .error-404-non-branded > .error-contents .button {
    height: 9vw;
    font-size: 3.7vw;
    line-height: 8vw;
    width: auto;
    padding: 0 4vw;
  }
}

@media (max-width: 380px) {
  .error-404-non-branded > .error-contents .button {
    width: 167px;
    height: 44px;
    border-radius: 22px;
    line-height: 37px;
    font-size: 16px;
  }
}

.error-404-non-branded > .error-contents .button:hover {
  background-color: #3eb8ea;
  color: #fff;
}
</style>
