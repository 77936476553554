























































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";

import ProfileTabs from "@/components/Profile/ProfileTabs.vue";
import Loading from "@/components/Common/Loading.vue";
import { useProfilesTribesStore } from "@/stores/Profile/ProfilesTribesStore";
import Pager from "@/components/Common/Pager.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import Header from "@/components/Common/Header.vue";
import Modal from "@/components/Common/Modal.vue";
import FormItem from "@/components/Form/FormItem.vue";
import FormButton from "@/components/Form/FormButton.vue";

import { ApiHelper } from "@/helpers";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormInput from "@/components/Form/FormInput.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";

type Profile = {
  id: string;
  firstName: string;
  lastName: string;
  tags: {
    id: number;
    name: string;
  }[];
};

export default defineComponent({
  name: "ProfileTribes",
  components: {
    TableHeaderColumn,
    FormSelect,
    FormInput,
    FormItem,
    FormButton,
    ProfileTabs,
    Loading,
    Header,
    Modal,
    Avatar,
    AutoCompleteInput,
    Pager,
    FilterSearchColumn,
    FilterAgesColumn,
    FilterSelectColumn,
    FilterNumberRangeColumn,
    FilterCheckboxesColumn,
    HeaderProfileDetails
  },
  setup(props, context) {
    const {
      loadList,
      updateRouters,
      loadData,
      isArchived,
      popupNewItem,
      // sort
      updateSortValue,
      headerData,
      pageData,
      // filters
      selectAllGenders,
      resetGenders,
      selectAllEvents,
      resetEvents,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      onClickPrev,
      onClickNext,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      updateFilterValue,
      // popups
      deleteItem,
      updateRoommateStatus
    } = useProfilesTribesStore(context);

    const isACILoading = ref<boolean>(false);
    const searchProfiles = async (key: string) => {
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/search",
        {},
        {
          order: 2,
          direction: 1,
          onlyActive: 1,
          notInFamilyId: parseInt(context.root.$route.query.family + "") || 0,
          key: key
        }
      );
      if (result.status === 1) {
        popupNewItem.value.formData.controls.profile.options = result.data.profiles.map(
          (item: any) => ({
            id: item.id,
            text: ApiHelper.getFullName(item.firstName, item.lastName),
            data: item
          })
        );
      }
      isACILoading.value = false;
    };

    const selectProfile = (item: any) => {
      const profileID = item.data.id || "0";
      popupNewItem.value.formData.controls.profile.value = profileID;
      popupNewItem.value.formData.controls.profile.error = "";
    };

    const popupEditTribe = ref<any>({
      show: false,
      controls: {
        id: {
          value: 0
        },
        subProfileId: {
          value: 0
        },
        name: {
          label: "Member name",
          disabled: true,
          value: "",
          error: ""
        },
        type: {
          label: "Relation type",
          placeholder: "- Select type -",
          error: "",
          type: "select",
          value: "",
          options: []
        }
      },
      actions: {
        onSubmit: async () => {
          let hasError = false;
          const relationTypeId =
            parseInt(popupEditTribe.value.controls.type.value) || 0;
          if (relationTypeId === 0) {
            popupEditTribe.value.controls.type.error =
              "Relation type is required.";
            hasError = true;
          }
          if (hasError) {
            return;
          }
          pageData.value.isLoading = true;
          const profileId =
            parseInt(context.root.$route.params.profileId + "") || 0;
          const familyId = parseInt(context.root.$route.query.family + "") || 0;
          const result = await ApiHelper.callApi(
            "put",
            "/profiles/tribes/" + popupEditTribe.value.controls.id.value,
            {
              subProfileId: popupEditTribe.value.controls.subProfileId.value,
              profileId: profileId,
              familyId: familyId,
              typeId: relationTypeId
            },
            {}
          );
          pageData.value.isLoading = false;
          if (result.status !== 1) {
            ApiHelper.showErrorMessage(result.message, "Oops");
            return;
          }
          popupEditTribe.value.show = false;
          await loadData(1);
          ApiHelper.showSuccessMessage("Updated");
        }
      }
    });

    const openEditPopup = (item: any) => {
      popupEditTribe.value.show = true;
      popupEditTribe.value.controls.id.value = item.linkId || 0;
      popupEditTribe.value.controls.subProfileId.value = item.id;
      popupEditTribe.value.controls.name.value = ApiHelper.getFullName(
        item.firstName,
        item.lastName
      );
      popupEditTribe.value.controls.type.value = (item.typeId || 0) + "";
      popupEditTribe.value.controls.type.options =
        popupNewItem.value.formData.controls.type.options;
    };

    const openNewPopup = () => {
      popupNewItem.value.formData.controls.profile.error = "";
      popupNewItem.value.formData.controls.profile.value = "";
      popupNewItem.value.formData.controls.type.error = "";
      popupNewItem.value.formData.controls.type.value = "";
      popupNewItem.value.show = true;
    };

    const showRoommateRequestsEdit = (item: any) => {
      pageData.value.roommateStatusEditVisible = true;
      pageData.value.roommateStatusList.value = item.requestStatus;
      pageData.value.selectedRoommateRequest = item;
    };

    return {
      loadList,
      updateRouters,
      openNewPopup,
      openEditPopup,
      popupEditTribe,
      isArchived,
      searchProfiles,
      selectProfile,
      popupNewItem,
      // sort
      updateSortValue,
      headerData,
      pageData,
      // filters
      selectAllGenders,
      resetGenders,
      selectAllEvents,
      resetEvents,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      onClickPrev,
      onClickNext,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      updateFilterValue,
      // popups
      deleteItem,
      ApiHelper,
      isACILoading,
      showRoommateRequestsEdit,
      updateRoommateStatus,
      getSelectedItems: ApiHelper.getSelectedItems
    };
  },
  updated() {
    //Load data total on header
    if (this.popupNewItem.isAddNewSuccess) {
      const sidebar: any = this.$refs.childComponentHeaderRef;
      if (sidebar) {
        sidebar.$refs.SidebarProfileRef.loadData();
        this.popupNewItem.isAddNewSuccess = false;
      }
    }
  }
});
