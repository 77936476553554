

























































































































































































































































































































































































































































































































































































































import { computed, defineComponent, ref } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import ProfilePanel from "@/components/Profile/ProfilePanel.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import HeaderProfiles from "@/components/Profile/HeaderProfiles.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import { useProfilesFamilyStore } from "@/stores/Profile/ProfilesFamilyStore";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import Modal from "@/components/Common/Modal.vue";
import DetailTooltip from "@/components/DetailTooltip.vue";
import EventGroupRowTooltip from "@/components/EventGroupRowTooltip.vue";

export default defineComponent({
  name: "ProfilesFamilyPage",
  components: {
    DetailTooltip,
    VAlertMessage,
    ProfilePanel,
    Loading,
    HeaderProfiles,
    Pager,
    Avatar,
    Modal,
    FilterSearchColumn,
    FilterNumberRangeColumn,
    FilterCheckboxesColumn,
    EventGroupRowTooltip
  },
  setup(props, context) {
    const {
      updateRouters,
      // sort
      updateSortValue,
      pageData,
      // popups
      popupNewTribe,
      selectedProfiles,
      updateSelectedProfiles,
      selectedTribeProfiles,
      updateSelectedParticipants,
      familyTypeOptions,
      // filters
      updateFilterValue,
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      selectAllEvents,
      resetEvents,
      // pager
      loadList,
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange
    } = useProfilesFamilyStore(context);

    const allowSelection = computed(() => {
      const $this: any = context.root;
      if (!$this.$allowedFunctions.includes($this.$userFunctions.EditPerson)) {
        return false;
      }

      return true;
    });

    // Todos
    const isTooltipOpen = ref(false);
    const todosTooltipVisibleIndex = ref(-1);
    const todosTooltipLoading = ref<boolean>(false);
    const isEventActive = ref<boolean>(false);
    const showTooltipTodos = async (
      profile: any,
      index: number,
      type: string
    ) => {
      if (!isTooltipOpen.value) {
        todosTooltipVisibleIndex.value = index * 1000 + profile.id;
        todosTooltipLoading.value = true;
        todosTooltipLoading.value = false;
        isTooltipOpen.value = true;
      }
      if (type == "event") {
        todosTooltipVisibleIndex.value = index;
      }
    };

    const showTooltipEvents = async (index: number, isActive: boolean) => {
      isEventActive.value = isActive;
      todosTooltipVisibleIndex.value = index;
    };

    const hideTooltipTodos = () => {
      isTooltipOpen.value = false;
      todosTooltipLoading.value = false;
      todosTooltipVisibleIndex.value = -1;
    };

    return {
      todosTooltipVisibleIndex,
      isEventActive,
      showTooltipEvents,
      todosTooltipLoading,
      showTooltipTodos,
      hideTooltipTodos,
      isTooltipOpen,
      updateRouters,
      // sort
      updateSortValue,
      pageData,
      // popups
      popupNewTribe,
      selectedProfiles,
      updateSelectedProfiles,
      familyTypeOptions,
      selectedTribeProfiles,
      updateSelectedParticipants,
      // filters
      updateFilterValue,
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      selectAllEvents,
      resetEvents,
      // pager
      loadList,
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange,
      allowSelection
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
