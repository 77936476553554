import { ref } from "@vue/composition-api";
import { FormSelect, PagerItem, SelectOption } from "@/types";
import { ApiHelper } from "@/helpers";
import { FinancialListRows } from "@/helpers/estimateNoOfListRows";
import { dollarFormat, TransactionType } from "@/helpers/ApiHelper";
import moment from "moment";
import FormDate from "@/types/FormDate";

export function useCategoryBalanceStore(context: any) {
  const currentRoute = "CategoryBalance";
  const pageData = ref<{
    isLoading: boolean;
    showGlCodes?: boolean;
    showCostCenter?: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: any;
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
      totalAmount: string;
    };
    paying: boolean;
    selectedIds: any;
    paymentType: string;
    refundInfo: any;
    searchEventsValue: {
      value: string;
    };
    searchEvents: Function;
    searchParticipantsValue: {
      value: string;
    };
    selectedParticipants: SelectOption[];
    searchParticipants: Function;
    selectedEvents: SelectOption[];
    searchParticipantsLoading: boolean;
    searchTypesValue: {
      value: string;
    };
    searchTypes: Function;
    selectedTypes: SelectOption[];
    searchTypesLoading: boolean;
    searchEventsLoading: boolean;
    detailsVisible: number;
    summation: {
      credit: number;
      creditFormatted: string;
      debit: number;
      debitFormatted: string;
      deferred: {
        credit: number;
        debit: number;
        creditFormatted: string;
        debitFormatted: string;
      };
      deferredFormatted?: string;
    };
    filterView: number;
    controls: {
      endDate: FormDate;
      evStartDate: FormDate;
      evEndDate: FormDate;
      eventType: FormSelect;
    };
  }>({
    isLoading: true,
    skip: 0,
    take: FinancialListRows("FinancialTransactions"),
    activeTab: "",
    sort: {
      order: "7",
      direction: "2"
    },
    filter: {
      selectedEvents: []
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      totalAmount: "",
      items: []
    },
    paying: false,
    selectedIds: [],
    paymentType: "manual",
    refundInfo: [],
    searchEventsLoading: false,
    searchEventsValue: {
      value: ""
    },
    selectedEvents: [],
    searchEvents: async (searchValue: string) => {
      pageData.value.searchEventsLoading = true;
      const eventIds = (context.root.$route.query.events || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1
        });
        pageData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS",
          selected: eventIds.includes(0)
        };
      } else {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue
        });
        if (eventIds.includes(0)) {
          pageData.value.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedEvent = null;
        }
      }
      pageData.value.filter.events = pageData.value.filter.events.map(
        (item: any) => ({
          ...item,
          selected: eventIds.includes(item.id)
        })
      );
      pageData.value.searchEventsLoading = false;
    },
    searchParticipantsLoading: false,
    searchParticipantsValue: {
      value: ""
    },
    selectedParticipants: [],
    searchParticipants: async (searchValue: string) => {
      pageData.value.searchParticipantsLoading = true;
      if (searchValue == "") {
        pageData.value.filter.profiles = await ApiHelper.getProfileOptions({
          getAll: 1,
          inIds: context.root.$route.query.profiles,
          includeDenied: 1
        });
      } else {
        pageData.value.filter.profiles = await ApiHelper.getProfileOptions({
          key: searchValue,
          includeDenied: 1
        });
      }
      const profileIds = (context.root.$route.query.profiles || "")
        .split(",")
        .map((id: string) => parseInt(id));
      pageData.value.filter.profiles = pageData.value.filter.profiles.map(
        (item: any) => ({
          ...item,
          selected: profileIds.includes(item.id)
        })
      );
      pageData.value.searchParticipantsLoading = false;
    },
    searchTypesLoading: false,
    searchTypesValue: {
      value: ""
    },
    selectedTypes: [],
    searchTypes: async (searchValue: string) => {
      pageData.value.searchTypesLoading = true;
      if (searchValue == "") {
        // load top 20 types
        pageData.value.filter.types = await ApiHelper.getTransactionTypeOptions(
          {
            take: 20
          }
        );
      } else {
        pageData.value.filter.types = await ApiHelper.getTransactionTypeOptions(
          {
            key: searchValue
          }
        );
      }
      const typeIds = (context.root.$route.query.types || "")
        .split(",")
        .map((id: string) => parseInt(id));
      pageData.value.filter.types = pageData.value.filter.types.map(
        (item: any) => ({
          ...item,
          selected: typeIds.includes(item.id)
        })
      );
      pageData.value.searchTypesLoading = false;
    },
    detailsVisible: -1,

    summation: {
      credit: 0,
      creditFormatted: "",
      debit: 0,
      debitFormatted: "",
      deferred: {
        credit: 0,
        debit: 0,
        creditFormatted: dollarFormat(0),
        debitFormatted: dollarFormat(0)
      }
    },
    filterView: 1,
    controls: {
      endDate: {
        error: "",
        format: "MM/DD/YYYY",
        placeholder: "MM/DD/YYYY",
        label: "",
        type: "date",
        value: "",
        style: "custom"
      },
      evStartDate: {
        error: "",
        format: "MM/DD/YYYY",
        placeholder: "MM/DD/YYYY",
        label: "Event From Date",
        type: "date",
        value: "",
        style: "custom"
      },
      evEndDate: {
        error: "",
        format: "MM/DD/YYYY",
        placeholder: "MM/DD/YYYY",
        label: "Event To Date",
        type: "date",
        value: "",
        style: "custom"
      },
      eventType: {
        label: "Event Type",
        value: "",
        options: [],
        placeholder: "Event Type",
        notRequired: true,
        size: "sm",
        style: "custom",
        error: ""
      }
    }
  });
  const userSettings = ref<any>({});
  const eventData = ref<{
    show: boolean;
    isArchived: boolean;
    isLoading: boolean;
    foundEvents: any;
    showAci: boolean;
  }>({
    show: false,
    isArchived: false,
    isLoading: true,
    foundEvents: [],
    showAci: true
  });

  const updateRouters = () => {
    const queryObject: any = {
      page: pageData.value.pager.page + "" || undefined,
      order: pageData.value.sort.order,
      direction: pageData.value.sort.direction,
      id: pageData.value.filter.id || undefined,
      key: pageData.value.filter.key || undefined,
      profile: pageData.value.filter.profileId
        ? (pageData.value.filter.profileId || 0) + ""
        : undefined,
      event: pageData.value.filter.eventId
        ? (pageData.value.filter.eventId || 0) + ""
        : undefined,
      events:
        ApiHelper.convertSelectedOptionsToString(
          pageData.value.selectedEvents
        ) || undefined,
      profiles:
        ApiHelper.convertSelectedOptionsToString(
          pageData.value.selectedParticipants
        ) || undefined,
      types:
        ApiHelper.convertSelectedOptionsToString(
          pageData.value.selectedTypes
        ) || undefined,
      minAmount: pageData.value.filter.minAmount || undefined,
      maxAmount: pageData.value.filter.maxAmount || undefined,
      minDiscountAmount: pageData.value.filter.minDiscountAmount || undefined,
      maxDiscountAmount: pageData.value.filter.maxDiscountAmount || undefined,
      minDate: pageData.value.filter.minDate || undefined,
      maxDate: pageData.value.filter.maxDate || undefined,
      searchEvents: pageData.value.searchEventsValue.value || undefined,
      searchParticipants:
        pageData.value.searchParticipantsValue.value || undefined,
      searchTypes: pageData.value.searchTypesValue.value || undefined,
      t: pageData.value.filter.t || undefined
    };

    if (pageData.value.filter.participantId && pageData.value.filter.fullName) {
      queryObject.participantId = pageData.value.filter.participantId;
      queryObject.fullName = pageData.value.filter.fullName;
    }
    if (
      pageData.value.filter.addonServiceId &&
      pageData.value.filter.serviceName
    ) {
      queryObject.addonServiceId = pageData.value.filter.addonServiceId;
      queryObject.serviceName = pageData.value.filter.serviceName;
    }

    context.root.$router
      .replace({
        name: currentRoute,
        query: queryObject
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadList = async () => {
    // reset current filter
    pageData.value.controls.endDate.value = "";

    // pageData.value.pager.page = context.root.$route.query.page
    //   ? parseInt(context.root.$route.query.page)
    //   : 1;
    // ApiHelper.setDataLoading(true);
    // pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;

    // event date range
    const start = moment(pageData.value.controls.evStartDate.value);
    const end = moment(pageData.value.controls.evEndDate.value);

    const result = await ApiHelper.callApi(
      "get",
      "/accounting/categoryBalance",
      {},
      {
        getAll: 1,
        order: parseInt(pageData.value.sort.order),
        direction: parseInt(pageData.value.sort.direction),
        eventTypeId:
          parseInt(pageData.value.controls.eventType.value) || undefined,
        eventIds: pageData.value.filter.selectedEvents
          .map((item: any) => item.id)
          .join(","),
        evStartDate: start.isValid() ? start.format("YYYY/MM/DD") : "",
        evEndDate: end.isValid() ? end.format("YYYY/MM/DD") : ""
      }
    );
    if (result.status === 1) {
      // const totalCount = result.data.totalCount || 0;
      // pageData.value.pager.totalPages = Math.ceil(
      //   totalCount / pageData.value.take
      // );
      // pageData.value.pager.total = totalCount;
      // const pagerList = [];
      // for (let i = 0; i < pageData.value.pager.totalPages; i++) {
      //   const pagerItem: PagerItem = {
      //     label: i + 1 + "",
      //     value: i + 1,
      //     active: i + 1 === pageData.value.pager.page
      //   };
      //   pagerList.push(pagerItem);
      // }
      // pageData.value.pager.items = pagerList;
      pageData.value.items = result.data.items;
      pageData.value.summation = result.summation;

      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
    } else {
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      ApiHelper.showErrorMessage(result.message);
    }
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = event.target.value;
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  // filters
  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }

    if (pageData.value.activeTab == "events") {
      if (pageData.value.filter.events.length === 0) {
        await pageData.value.searchEvents(
          pageData.value.searchEventsValue.value
        );
      }
    }

    if (pageData.value.activeTab == "profiles") {
      if (pageData.value.filter.profiles.length === 0) {
        await pageData.value.searchParticipants(
          pageData.value.searchParticipantsValue.value
        );
      }
    }

    if (pageData.value.activeTab == "types") {
      if (pageData.value.filter.types.length === 0) {
        await pageData.value.searchTypes(pageData.value.searchTypesValue.value);
      }
    }
  };

  const getFiltersData = () => {
    const filters: any = [];
    if (pageData.value.filter.selectedEvents.length) {
      const selectedEvents = pageData.value.filter.selectedEvents;
      filters.push({
        label: "EVENT",
        key:
          selectedEvents.map((item: any) => item.id).join("_") + Math.random(),
        value: selectedEvents.map((item: any) => item.name).join(", "),
        reset: () => {
          pageData.value.filter.selectedEvents = [];
          loadData(1);
          // updateRouters();
        }
      });
    }

    const end = moment(pageData.value.controls.endDate.value);
    const endFormatted = end.isValid() ? end.format("YYYY/MM/DD") : "";
    if (endFormatted) {
      filters.push({
        label: "END OF EVENT",
        key: endFormatted + Math.random(),
        value: end.format("MM/DD/YY"),
        reset: () => {
          pageData.value.filterView = 1;
          pageData.value.controls.endDate.value = "";
          loadData(1);
          // updateRouters();
        }
      });
    }

    const evStart = moment(pageData.value.controls.evStartDate.value);
    const evEnd = moment(pageData.value.controls.evEndDate.value);
    if (evStart.isValid() || evEnd.isValid()) {
      filters.push({
        label: "EVENT START DATE",
        key: "eventRangeDate" + Math.random(),
        value:
          (evStart.isValid() ? evStart.format("MM/DD/YY") : "") +
          " - " +
          (evEnd.isValid() ? evEnd.format("MM/DD/YY") : ""),
        reset: () => {
          pageData.value.filterView = 1;
          pageData.value.controls.endDate.value = "";
          pageData.value.controls.evStartDate.value = "";
          pageData.value.controls.evEndDate.value = "";
          pageData.value.filter.selectedEvents = [];
          loadData(1);
          // updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const updateFilterValue = (name: string, value: string) => {
    pageData.value.filter.t = "";
    switch (name) {
      case "eventId": {
        pageData.value.filter.eventId = value;
        break;
      }
      // case "date": {
      //   pageData.value.filter.date = value;
      //   const dateArr = value.split("-");
      //   pageData.value.filter.minDate = dateArr[0];
      //   pageData.value.filter.maxDate =
      //     dateArr[1] !== undefined ? dateArr[1] : "";
      //   pageData.value.filter.t = ApiHelper.randomFormId().toString();
      //   break;
      // }
      // case "amount": {
      //   pageData.value.filter.amount = value;
      //   const amountArr = value.split("-");
      //   pageData.value.filter.minAmount = amountArr[0];
      //   pageData.value.filter.maxAmount =
      //     amountArr[1] !== undefined ? amountArr[1] : "";
      //   break;
      // }
      // case "discountAmount": {
      //   pageData.value.filter.discountAmount = value;
      //   const amountArr = value.split("-");
      //   pageData.value.filter.minDiscountAmount = amountArr[0];
      //   pageData.value.filter.maxDiscountAmount =
      //     amountArr[1] !== undefined ? amountArr[1] : "";
      //   break;
      // }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const inSelectedIds = (item: any) => {
    const existedItem = pageData.value.selectedIds.find(
      (id: any) => id == item.transactionId
    );
    if (existedItem) {
      return true;
    }
    return false;
  };

  const showColumn = (itemName: string) => {
    const transactionsCustomView =
      userSettings.value.transactionsCustomView || [];
    const relatedItem: any = transactionsCustomView.find(
      (item: any) => item.name.toLowerCase() == itemName.toLowerCase()
    );
    if (relatedItem) {
      return relatedItem.checked || false;
    }

    return true;
  };

  const exportCSV = async () => {
    try {
      let valid = false;

      const requestObject: any = {
        getAll: 1,
        order: parseInt(pageData.value.sort.order),
        direction: parseInt(pageData.value.sort.direction)
      };
      if (pageData.value.filterView == 1) {
        // category balance view
        // event date range
        const start = moment(pageData.value.controls.evStartDate.value);
        const end = moment(pageData.value.controls.evEndDate.value);

        requestObject.eventIds =
          pageData.value.filter.selectedEvents
            .map((item: any) => item.id)
            .join(",") || "";
        requestObject.evStartDate = start.isValid()
          ? start.format("YYYY/MM/DD")
          : "";
        requestObject.evEndDate = end.isValid() ? end.format("YYYY/MM/DD") : "";
        valid = true;
      } else {
        // filter by end event date view
        const end = moment(pageData.value.controls.endDate.value);
        const endFormatted = end.isValid() ? end.format("YYYY/MM/DD") : "";
        requestObject.eventEndDate = endFormatted;
        requestObject.filterByEndDate = 1;
        if (endFormatted) {
          valid = true;
        }
      }

      if (!valid) return;

      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/categoryBalance",
        {},
        requestObject
      );
      if (result.status === 1) {
        const summation = result.summation;
        const exportData = (result.data.items || []).map((item: any) => {
          return [
            `"${item.postingCode.toUpperCase()}"`,
            `"${item.postingCodeDesc}"`,
            `"${item.glCode}"`,
            `"${item.costcenterNumber}"`,
            `"${item.debit ? item.debitFormatted : ""}"`,
            `"${item.creditFormatted}"`
          ];
        });

        // empty line
        exportData.push([""]);

        // add deferred line
        exportData.push([
          "",
          "",
          "",
          "DEFERRED",
          `"${
            summation.deferred.debit ? summation.deferred.debitFormatted : ""
          }"`,
          `"${
            summation.deferred.credit ? summation.deferred.creditFormatted : ""
          }"`
        ]);

        exportData.push([
          "",
          "",
          "",
          "SUMMATION",
          `"${summation.debitFormatted}"`,
          `"${summation.creditFormatted}"`
        ]);

        const headers: any = [
          "POSTING CODE",
          "POSTING CODE NAME",
          "GL CODE",
          "COST CENTER",
          "DEBITS (PAYMENTS AGAINST)",
          "CREDITS (SUM OF VALUE)"
        ];

        const fileName = ["CategoryBalance"];
        fileName.push(moment().format("MMDDYYYY_HHMMSS"));
        await ApiHelper.generateCsv(
          exportData,
          headers,
          fileName.join("_"),
          false
        );
        ApiHelper.setDataLoading(false);
      } else {
        ApiHelper.setDataLoading(false);
        ApiHelper.showErrorMessage(result.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const endDateChange = async () => {
    // filter for end of event
    const end = moment(pageData.value.controls.endDate.value);
    const endFormatted = end.isValid() ? end.format("YYYY/MM/DD") : "";
    if (endFormatted) {
      pageData.value.filter.selectedEvents = [];
      pageData.value.controls.evStartDate.value = "";
      pageData.value.controls.evEndDate.value = "";

      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/categoryBalance",
        {},
        {
          getAll: 1,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          eventEndDate: endFormatted,
          filterByEndDate: 1
        }
      );
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      if (result.status === 1) {
        pageData.value.items = result.data.items;
        pageData.value.summation = result.summation;
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    }
  };

  const filterViewChange = () => {
    pageData.value.controls.endDate.value = "";
    if (pageData.value.filterView == 2) {
      pageData.value.filter.selectedEvents = [];
      setTimeout(() => {
        $(".filter-views .datepicker3 .mx-input").focus();
      }, 100);
    } else {
      loadData(1);
    }
  };

  const allowFilterByDateRange = () => {
    const start = moment(pageData.value.controls.evStartDate.value);
    const end = moment(pageData.value.controls.evEndDate.value);
    if (start.isValid() || end.isValid()) {
      return true;
    }

    return false;
  };

  const filterByDateRange = () => {
    // validate
    const start = moment(pageData.value.controls.evStartDate.value);
    const end = moment(pageData.value.controls.evEndDate.value);
    let valid = false;
    if (start.isValid() || end.isValid()) {
      valid = true;
    }

    if (valid) {
      pageData.value.filter.selectedEvents = [];
      pageData.value.controls.endDate.value = "";
      loadData(1);
      eventData.value.show = false;
    }
  };

  // init data
  (async () => {
    loadData(1);
  })();

  (async () => {
    if (
      typeof pageData.value.showGlCodes == "undefined" &&
      typeof pageData.value.showCostCenter == "undefined"
    ) {
      const configData = await ApiHelper.getEntityConfig(true);
      pageData.value.showGlCodes = configData.showGlCodes;
      pageData.value.showCostCenter = configData.showCostCenter;
    }
  })();

  (async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/events/eventTypes",
      {},
      {
        getAll: 1,
        order: 1,
        direction: 1
      }
    );
    if (result.status === 1) {
      pageData.value.controls.eventType.options = result.data.items.map(
        (item: any) => {
          let itemText = item.eventTypeName;
          if (item.costCenterNumber) {
            itemText += ` - ${item.costCenterNumber}`;
          }
          return {
            id: item.eventTypeId,
            text: itemText,
            data: item
          };
        }
      );
    }
  })();

  return {
    loadData,
    pageData,
    updateRouters,
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    updateSortValue,
    loadList,
    gotoPage,
    onPagerChange,
    onClickPrev,
    onClickNext,
    togglePagerItems,
    inSelectedIds,
    userSettings,
    showColumn,
    exportCSV,
    endDateChange,
    filterViewChange,
    filterByDateRange,
    allowFilterByDateRange,
    eventData
  };
}
