import { ref } from "@vue/composition-api";
import { FormSelect, FormText, ProfileEditInput, SelectOption } from "@/types";

import { ApiHelper, ProfileHelper } from "@/helpers";
import FormTags from "@/types/FormTags";
import moment from "moment";
import Vue from "vue";

export function useProfileEditStore(context: any) {
  const selectedProfileId = parseInt(context.root.$route.params.profileId);
  const editInput = ref<ProfileEditInput>({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    dob: undefined,
    registrationDate: undefined,
    phone: "",
    receiveMessage: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    photo: "",
    photoS3url: "",
    linkSubProfiles: [],
    tags: []
  });
  const formData = ref<{
    isLoading: boolean;
    isLock: boolean;
    controls: {
      firstName: FormText;
      lastName: FormText;
      email: FormText;
      gender: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      dob: {
        label?: string;
        error: string;
        type: string;
        value: Date | undefined;
        editable: boolean;
        disabledBefore: Date | undefined;
        disabledAfter: Date | undefined;
      };
      registrationDate: {
        label?: string;
        error: string;
        type: string;
        readonly?: boolean;
        value: Date | undefined;
      };
      phone: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      receiveMessage: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      address: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      city: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      state: FormSelect;
      zipcode: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      photo: {
        label?: string;
        error: string;
        type: string;
        value: string;
        preview: string;
        s3url: string;
        delete?: boolean;
      };
      tribes: {
        label?: string;
        error: string;
        type: string;
        options: SelectOption[];
        value: string[];
      };
      tags: FormTags;
      createUser: {
        label?: string;
        error: string;
        type: string;
        value: string;
        yesValue: string;
        noValue: string;
        defaultValue: string;
      };
      password: {
        label?: string;
        placeholder?: string;
        style?: string;
        error: string;
        mode: string;
        type: string;
        value: string;
      };
    };
    useSameEmail: {
      forceUsing: boolean;
      familyOnwerId: number;
      familyId: number;
    };
  }>({
    isLoading: true,
    isLock: false,
    controls: {
      firstName: {
        label: "First Name",
        required: true,
        placeholder: "Enter first name",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 150
      },
      lastName: {
        label: "Last Name",
        required: true,
        placeholder: "Enter last name",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 150
      },
      email: {
        label: "Email",
        required: true,
        placeholder: "Enter an email address",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 200
      },
      gender: {
        error: "",
        type: "radios",
        value: ""
      },
      dob: {
        label: "Birthdate",
        error: "",
        type: "date",
        value: undefined,
        editable: true,
        disabledBefore: moment()
          .subtract(100, "years")
          .toDate(),
        disabledAfter: new Date()
      },
      registrationDate: {
        label: "Registration Date",
        readonly: true,
        error: "",
        type: "date",
        value: undefined
      },
      phone: {
        error: "",
        type: "text",
        value: ""
      },
      receiveMessage: {
        error: "",
        type: "radios",
        value: "yes"
      },
      address: {
        error: "",
        type: "text",
        value: ""
      },
      city: {
        error: "",
        type: "text",
        value: ""
      },
      state: {
        error: "",
        value: "",
        label: "State",
        placeholder: "Select...",
        style: "custom",
        notRequired: true,
        options: ApiHelper.getStateOptions()
      },
      zipcode: {
        error: "",
        type: "text",
        value: ""
      },
      photo: {
        error: "",
        type: "upload",
        value: "",
        preview: "",
        s3url: "",
        delete: false
      },
      tribes: {
        error: "",
        type: "multiselect",
        options: [],
        value: []
      },
      tags: {
        error: "",
        label: "Tags",
        placeholder: "Enter a tag",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: []
      },
      createUser: {
        label: "Allow admin permissions?",
        error: "",
        type: "radios",
        value: "no",
        yesValue: "yes",
        noValue: "no",
        defaultValue: ""
      },
      password: {
        error: "",
        style: "custom",
        mode: "password",
        label: "Password",
        placeholder: "Password",
        value: "",
        type: "password"
      }
    },
    useSameEmail: {
      forceUsing: false,
      familyOnwerId: 0,
      familyId: 0
    }
  });

  const loadData = async () => {
    formData.value.controls.tags.options = await ApiHelper.getTagsOptions();
    // formData.value.controls.tribes.options = await ApiHelper.getProfileOptions(
    //   selectedProfileId.toString()
    // );
    formData.value.isLoading = true;
    const result = await ApiHelper.callApi(
      "get",
      "/profiles/" + selectedProfileId,
      {},
      {}
    );
    formData.value.isLoading = false;
    if (result.status !== 1) {
      ApiHelper.showErrorMessage(result.message, "Oops");
      ApiHelper.gotoPage(context, {
        name: "Profiles"
      });
      return;
    }

    const profileData = result.data || {};
    formData.value.controls.tags.value = profileData.tags.map((item: any) => {
      return {
        id: item.id,
        text: item.name || "",
        data: item
      };
    });
    formData.value.isLock = profileData.isActive == 2 ? true : false;
    formData.value.controls.firstName.value = ApiHelper.stripTags(
      profileData.firstName || ""
    );
    formData.value.controls.lastName.value = ApiHelper.stripTags(
      profileData.lastName || ""
    );
    formData.value.controls.gender.value = profileData.gender || "0";
    formData.value.controls.email.value = ApiHelper.stripTags(
      profileData.email || ""
    );
    formData.value.controls.address.value = ApiHelper.stripTags(
      profileData.address || ""
    );
    formData.value.controls.city.value = ApiHelper.stripTags(
      profileData.city || ""
    );
    formData.value.controls.state.value = ApiHelper.stripTags(
      profileData.state || ""
    );
    formData.value.controls.zipcode.value = ApiHelper.stripTags(
      profileData.zip || ""
    );
    formData.value.controls.phone.value = ApiHelper.stripTags(
      profileData.phone || ""
    );
    formData.value.controls.photo.preview = profileData.logo || "";
    formData.value.controls.photo.value = profileData.logo || "";
    formData.value.controls.dob.value = profileData.dobFormatted
      ? new Date(profileData.dobFormatted)
      : undefined;
    formData.value.controls.registrationDate.value =
      profileData.tsFormatted || "";
    formData.value.controls.receiveMessage.value = profileData.receiveMessage
      ? "yes"
      : "no";
    formData.value.controls.tribes.value = profileData.subProfiles.map(
      (item: any) => parseInt(item.id)
    );

    formData.value.controls.createUser.value =
      profileData.type == 1 ? "yes" : "no";
    formData.value.controls.createUser.defaultValue =
      profileData.type == 1 ? "yes" : "no";

    editInput.value.linkSubProfiles = profileData.subProfiles.map(
      (item: any) => {
        return {
          id: parseInt(item.id),
          profileId: parseInt(item.id),
          type: parseInt(item.typeId),
          isDelete: false
        };
      }
    );

    // update total linked profiles for right side bar
    // const SidebarProfileRef = context.parent.$refs.SidebarProfileRef;
    // SidebarProfileRef.sidebarData.totalLinkedProfiles =
    //   profileData.linkedProfiles.length;
  };

  const onSubmit = async (parent: any) => {
    formData.value.controls.firstName.value = ApiHelper.stripTags(
      formData.value.controls.firstName.value
    );
    formData.value.controls.lastName.value = ApiHelper.stripTags(
      formData.value.controls.lastName.value
    );
    formData.value.controls.email.value = ApiHelper.stripTags(
      formData.value.controls.email.value
    );
    formData.value.controls.photo.value = ApiHelper.stripTags(
      formData.value.controls.photo.value
    );
    formData.value.controls.address.value = ApiHelper.stripTags(
      formData.value.controls.address.value
    );
    formData.value.controls.city.value = ApiHelper.stripTags(
      formData.value.controls.city.value
    );
    formData.value.controls.state.value = ApiHelper.stripTags(
      formData.value.controls.state.value
    );
    formData.value.controls.zipcode.value = ApiHelper.stripTags(
      formData.value.controls.zipcode.value
    );
    editInput.value.firstName = formData.value.controls.firstName.value;
    editInput.value.lastName = formData.value.controls.lastName.value;
    editInput.value.email = formData.value.controls.email.value;
    editInput.value.gender = formData.value.controls.gender.value;
    editInput.value.dob = formData.value.controls.dob.value
      ? moment(
          moment(formData.value.controls.dob.value).format("YYYY-MM-DD") +
            "T00:00:00+0000"
        ).toDate()
      : undefined;
    editInput.value.registrationDate =
      formData.value.controls.registrationDate.value;
    editInput.value.phone = formData.value.controls.phone.value;
    editInput.value.photoS3url = formData.value.controls.photo.s3url;
    editInput.value.receiveMessage =
      formData.value.controls.receiveMessage.value;
    editInput.value.address = formData.value.controls.address.value;
    editInput.value.city = formData.value.controls.city.value;
    editInput.value.state = formData.value.controls.state.value;
    editInput.value.zipcode = formData.value.controls.zipcode.value;
    editInput.value.photo = formData.value.controls.photo.value;
    editInput.value.tags = formData.value.controls.tags.value.map(tag => {
      return {
        id: parseInt(tag.id),
        text: tag.text
      };
    });
    if (formData.value.controls.tags.key.trim() !== "") {
      editInput.value.tags.push({
        id: 0,
        text: formData.value.controls.tags.key
      });
      formData.value.controls.tags.key = "";
    }

    editInput.value.linkSubProfiles.forEach(item => {
      item.isDelete = true;
    });
    const selectedIds = editInput.value.linkSubProfiles.map(item =>
      (item.profileId || 0).toString()
    );
    formData.value.controls.tribes.value.forEach(value => {
      if (!selectedIds.includes(value.toString())) {
        editInput.value.linkSubProfiles.push({
          id: 0,
          profileId: parseInt(value),
          type: 1,
          isDelete: false
        });
      }
    });

    editInput.value.linkSubProfiles.forEach(item => {
      if (
        formData.value.controls.tribes.value.includes(
          (item.profileId || 0).toString()
        )
      ) {
        item.isDelete = false;
      }
    });

    let hasError = false;
    if (editInput.value.firstName === "") {
      hasError = true;
      formData.value.controls.firstName.error = "First name is required!";
    }
    if (editInput.value.lastName === "") {
      hasError = true;
      formData.value.controls.lastName.error = "Last Name is required!";
    }
    if (editInput.value.email === "") {
      hasError = true;
      formData.value.controls.email.error = "Email is required!";
    } else {
      if (!ApiHelper.validateEmail(editInput.value.email)) {
        hasError = true;
        formData.value.controls.email.error = "Email is invalid!";
      }
    }
    if (formData.value.controls.phone.value) {
      const phone: any = $("input[inputmask=phonenumber]");
      if (
        phone.length &&
        phone.get(0).inputmask &&
        phone.get(0).inputmask.isComplete() == false
      ) {
        hasError = true;
        formData.value.controls.phone.error = "Phone is invalid";
      }
    }

    /*
    if (editInput.value.address === "") {
      hasError = true;
      formData.value.controls.address.error = "Address is required!";
    }
    if (editInput.value.city === "") {
      hasError = true;
      formData.value.controls.city.error = "City is required!";
    }
    if (editInput.value.state === "") {
      hasError = true;
      formData.value.controls.state.error = "State is required!";
    }
    if (editInput.value.zipcode === "") {
      hasError = true;
      formData.value.controls.zipcode.error = "Zipcode is required!";
    }*/
    if (editInput.value.dob !== undefined && editInput.value.dob) {
      const nowDate = new Date();
      const minYear = nowDate.getFullYear() - 100;

      if (
        editInput.value.dob.getTime() > nowDate.getTime() ||
        editInput.value.dob.getFullYear() < minYear
      ) {
        hasError = true;
        formData.value.controls.dob.error = "DOB is invalid!";
      }
    }
    if (formData.value.controls.createUser.value == "yes") {
      if (formData.value.controls.email.value.trim() === "") {
        hasError = true;
        formData.value.controls.email.error = "Email is required";
      }
      if (formData.value.controls.createUser.defaultValue == "no") {
        if (formData.value.controls.password.value.trim() === "") {
          hasError = true;
          formData.value.controls.password.error = "Password is required";
        }
      }
      if (formData.value.controls.password.value.trim() !== "") {
        const pwd = new String(formData.value.controls.password.value.trim());
        if (pwd.length < 4) {
          hasError = true;
          formData.value.controls.password.error =
            "Password must more 3 characters";
        }
      }
    }

    if (!hasError) {
      formData.value.isLoading = true;
      // const linkSubProfiles:

      const result = await ApiHelper.callApi(
        "put",
        "/profiles/" + selectedProfileId,
        {
          firstName: editInput.value.firstName,
          lastName: editInput.value.lastName,
          email: editInput.value.email,
          phone: editInput.value.phone,
          receiveMessage: editInput.value.receiveMessage === "yes" ? 1 : 0,
          gender: parseInt(editInput.value.gender),
          dob: editInput.value.dob || undefined,
          registrationDate: editInput.value.registrationDate || undefined,
          address: editInput.value.address,
          city: editInput.value.city,
          state: editInput.value.state,
          zip: editInput.value.zipcode,
          // logoBase64: editInput.value.photo,
          photoS3url: editInput.value.photoS3url,
          deleteLogo: formData.value.controls.photo.delete ? 1 : 0,
          linkSubProfiles: editInput.value.linkSubProfiles,
          tags: editInput.value.tags,
          type: formData.value.controls.createUser.value == "yes" ? 1 : 2,
          password:
            formData.value.controls.createUser.value == "yes"
              ? formData.value.controls.password.value
              : undefined,
          forceUseSameEmail: formData.value.useSameEmail.forceUsing
        },
        {}
      );

      formData.value.useSameEmail.forceUsing = false;

      if (result.status == 1) {
        await loadData();
        // reload profileStats after save
        const SidebarProfileRef = parent.$refs.SidebarProfileRef;
        if (SidebarProfileRef) {
          SidebarProfileRef.loadData();
        }
        if (parent.$root) {
          const children = parent.$root.$children || [];
          const layout = children.length > 0 ? children[0] : undefined;
          if (layout && layout.$children.length > 0) {
            layout.$children[0].loadData();
          }
        }

        ApiHelper.showSuccessMessage("Updated person");
      } else if (result.message == "Email already existed.") {
        formData.value.isLoading = false;
        formData.value.controls.email.error = "Email already existed";
      } else {
        formData.value.isLoading = false;
        const errorCode = result.errorCode || "";
        if (errorCode == "email_existed") {
          // confirm if admin wants to use same email for profiles in same family
          const confirm = await context.root.$swal({
            html: result.message,
            icon: "error",
            title: "Oops",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, do it!"
          });
          if (confirm.isConfirmed) {
            // recall save function
            formData.value.useSameEmail.forceUsing = true;
            await onSubmit(parent);
          }
        } else {
          ApiHelper.showErrorMessage(result.message, "Oops");
        }
      }
    }
  };

  const removeFieldError = (name: string) => {
    switch (name) {
      case "firstName":
        formData.value.controls.firstName.error = "";
        break;
      case "lastName":
        formData.value.controls.lastName.error = "";
        break;
      case "email":
        formData.value.controls.email.error = "";
        break;
      case "gender":
        formData.value.controls.gender.error = "";
        break;
      case "dob":
        formData.value.controls.dob.error = "";
        break;
      case "phone":
        formData.value.controls.phone.error = "";
        break;
      case "address":
        formData.value.controls.address.error = "";
        break;
      case "city":
        formData.value.controls.city.error = "";
        break;
      case "state":
        formData.value.controls.state.error = "";
        break;
      case "zipcode":
        formData.value.controls.zipcode.error = "";
        break;
      case "photo":
        formData.value.controls.photo.error = "";
        break;
    }
  };

  const disabledBeforeTodayAndAfterAWeek = (date: Date) => {
    console.log("Data DOB");
    console.log(formData.value.controls.dob.value);
    // const date = formData.value.controls.dob.value;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (
      date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000)
    );
  };

  const onResetFile = async () => {
    formData.value.controls.photo.delete = true;
    formData.value.controls.photo.preview = ""; // formData.value.controls.photo.value;
    formData.value.controls.photo.value = "";
  };

  const onChangeFile = async (base64: string) => {
    formData.value.controls.photo.value = base64;
    formData.value.controls.photo.preview = base64;
    formData.value.controls.photo.delete = false;
  };

  // init data

  (async () => {
    await loadData();
  })();

  const onSuggestTags = async (key: string) => {
    const notInIds = formData.value.controls.tags.value
      .map(item => item.id)
      .join(",");
    const result = await ApiHelper.callApi(
      "get",
      "/tags/search",
      {},
      {
        key: key,
        notInIds: notInIds,
        typeUse: "profile"
      }
    );
    if (result?.status === 1) {
      formData.value.controls.tags.suggestTags = result.data.tags.map(
        (item: any) => {
          return {
            id: item.id,
            text: item.text,
            data: item
          };
        }
      );
    }
  };

  const onAddTag = () => {
    const key = formData.value.controls.tags.key.trim();
    if (key) {
      formData.value.controls.tags.value.push({
        id: 0,
        text: key,
        data: {}
      });
      formData.value.controls.tags.key = "";
    }
  };

  const onRemoveLastTag = (event: any) => {
    if (
      formData.value.controls.tags.value.length > 0 &&
      formData.value.controls.tags.key === ""
    ) {
      const index = formData.value.controls.tags.value.length - 1;
      formData.value.controls.tags.value.splice(index, 1);
    }
  };
  const onRemoveTag = (index: number) => {
    if (formData.value.controls.tags.value.length > index) {
      formData.value.controls.tags.value.splice(index, 1);
    }
  };

  const onSelectTag = (item: any) => {
    formData.value.controls.tags.key = "";
    formData.value.controls.tags.suggestTags = [];
    formData.value.controls.tags.value.push({
      id: item.id,
      text: item.text,
      data: item.data || {}
    });
  };

  const changeProfileAvatar = async (s3url: string) => {
    formData.value.controls.photo.value = s3url;
    formData.value.controls.photo.preview = s3url;
    formData.value.controls.photo.s3url = s3url;
    formData.value.controls.photo.delete = false;
  };

  const isArchived = ref(false);
  (async () => {
    const profileInfo = await ProfileHelper.getProfileInfo(selectedProfileId);
    isArchived.value = profileInfo.status === 2;
  })();

  return {
    changeProfileAvatar,
    isArchived,
    onSuggestTags,
    onAddTag,
    onRemoveTag,
    onSelectTag,
    onRemoveLastTag,
    formData,
    removeFieldError,
    onChangeFile,
    onResetFile,
    onSubmit,
    disabledBeforeTodayAndAfterAWeek
  };
}
