import { render, staticRenderFns } from "./VScroller.vue?vue&type=template&id=2091214e&scoped=true&"
import script from "./VScroller.vue?vue&type=script&lang=tsx&"
export * from "./VScroller.vue?vue&type=script&lang=tsx&"
import style0 from "./VScroller.vue?vue&type=style&index=0&id=2091214e&scoped=true&lang=scss&"
import style1 from "./VScroller.vue?vue&type=style&index=1&id=2091214e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2091214e",
  null
  
)

export default component.exports