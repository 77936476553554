var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"headerContainer"}},[_c('div',{staticClass:"content-head have__tabs"},[_c('div',{staticClass:"head_left hide__ipad"},[_c('h2',{staticClass:"head_heading"},[_vm._v(" Events "),(_vm.totalEvents > 0)?_c('span',{staticClass:"heading__number"},[_vm._v(_vm._s(_vm.totalEvents))]):_vm._e()])]),_c('div',{staticClass:"head_right"},[_c('div',{staticClass:"head__box"},[(_vm.filters && _vm.filters.list)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideFilters),expression:"hideFilters"}],staticClass:"head__filter hide__ipad",class:{ active: _vm.isShowFilters && _vm.filters.list.length > 0 }},[_c('div',{staticClass:"filter__label d-flex align-items-center",on:{"click":_vm.toggleFilters}},[_vm._v(" Filters "),_c('span',{staticClass:"filter__number"},[_vm._v(_vm._s(_vm.filters.list.length || 0))])]),_c('div',{staticClass:"filter__dropdown"},[_c('ul',{staticClass:"filter__items"},_vm._l((_vm.filters.list),function(item){return _c('li',{key:item.key},[_vm._v(" "+_vm._s(item.label || "")+": "),_c('span',{staticClass:"item__content"},[_vm._v(_vm._s(item.value || ""))]),_c('span',{staticClass:"item__delete",on:{"click":item.reset}})])}),0)])]):_vm._e(),_c('HeaderTabs',{attrs:{"tabs":[
            {
              label: 'OVERVIEW VIEW',
              route: '/events/overview'
            },
            {
              label: 'LIST VIEW',
              route: '/events'
            },
            {
              label: 'CUSTOM VIEW',
              route: '/events/custom'
            }
          ]}}),_c('HeaderActions',{attrs:{"type":"event"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }