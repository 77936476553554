import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { PagerItem } from "@/types";
import { TodosListRows } from "@/helpers/estimateNoOfListRows";

export function useProfileAllergiesStore(context: any) {
  const selectedProfileId = context.root.$route.params.profileId
    ? Number.parseInt(context.root.$route.params.profileId, 10)
    : 0;
  const sidebarData = ref({
    isLoading: true,
    logo: "",
    name: "",
    profileId: 0,
    participantId: 0,
    status: 0,
    eventId: 0,
    eventName: "",
    pTypeName: "",
    paidAmount: 0,
    paidAmountFormatted: "",
    financials: {
      totalPaid: 0
    }
  });
  const headerData = ref({
    title: "Allergies",
    subTitle: ""
  });
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      key: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    foundApps: object[];
    endItemMessage: string;
    paying: boolean;
    transferVisible: boolean;
  }>({
    isLoading: true,
    skip: 0,
    take: TodosListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "2"
    },
    filter: {
      key: ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    foundApps: [],
    endItemMessage: "",
    paying: false,
    transferVisible: false
  });

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: "ProfileAllergies",
        params: {
          profileId: selectedProfileId.toString()
        },
        query: {
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          key: pageData.value.filter.key || undefined,
          id: context.root.$route.query.id
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        `/participants/allergies`,
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          profileId: selectedProfileId,
          participantId: context.root.$route.query.id
            ? Number.parseInt(context.root.$route.query.id, 10)
            : undefined
        }
      );
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.allergies;
      } else if (result.status === -1) {
        ApiHelper.gotoPage(context, {
          name: "Login",
          query: { destination: "/settings/users" }
        });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops..");
        ApiHelper.gotoPage(context, {
          name: "Profiles"
        });
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };
  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  // init data
  (async () => {
    loadData(1);
    pageData.value.filter.key = context.root.$route.query.key || "";
  })();

  return {
    sidebarData,
    // page
    headerData,
    pageData,
    // --- pager
    loadList,
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems
  };
}
