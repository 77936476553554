































































































































































































import { defineComponent, ref } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import HeaderFilters from "@/components/Common/HeaderFilters.vue";
import Pager from "@/components/Common/Pager.vue";
import Modal from "@/components/Common/Modal.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import HeaderActions from "@/components/Common/HeaderActions.vue";
import { ApiHelper } from "@/helpers/index";
import BackButton from "@/components/Common/BackButton.vue";
import SidebarProfileEvents from "@/components/Profile/SidebarProfileEvents.vue";
import { useProfileNotesStore } from "@/stores/Profile/ProfileNotesStore";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import Vue from "vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import $ from "jquery";

export default defineComponent({
  name: "ProfileNotes",
  components: {
    TableHeaderColumn,
    BackButton,
    HeaderEventDetails,
    SidebarEventDetails,
    VAlertMessage,
    Loading,
    Modal,
    Select2,
    HeaderFilters,
    HeaderActions,
    Avatar,
    FilterCheckboxesColumn,
    FilterSearchColumn,
    FilterNumberRangeColumn,
    Pager,
    SidebarProfileEvents,
    HeaderProfileDetails
  },
  setup(props, context) {
    const {
      loadData,
      updateRouters,
      sidebarData,
      isArchived,
      // sort
      updateSortValue,
      headerData,
      pageData,
      // filters
      selectAllStatuses,
      resetStatuses,
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      deleteItem
    } = useProfileNotesStore(context);

    const onRemoveItem = (item: any, $parent: any) => {
      (async () => {
        const isAgreed = await Vue.swal({
          html: "Are you sure you want to delete this note?",
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          return result.isConfirmed;
        });

        if (isAgreed) {
          pageData.value.isLoading = true;
          const result = await ApiHelper.callApi(
            "delete",
            "/messages/" + item.id + "",
            {},
            {}
          );
          pageData.value.isLoading = false;
          if (result.status == 1) {
            pageData.value.isAddNewSuccess = true;
            ApiHelper.showSuccessMessage("Deleted");
            await loadData(1);
            const parent = $parent;
            if (parent.$refs.SidebarProfileRef) {
              parent.$refs.SidebarProfileRef.sidebarData.totalNotes =
                pageData.value.pager.total;
            }
          } else {
            ApiHelper.showErrorMessage(result.message);
          }
        }
      })();
    };

    return {
      onRemoveItem,
      updateRouters,
      sidebarData,
      isArchived,
      // sort
      updateSortValue,
      headerData,
      pageData,
      // filters
      selectAllStatuses,
      resetStatuses,
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      ApiHelper,
      deleteItem
    };
  },
  updated() {
    //Load data total on header
    if (this.pageData.isAddNewSuccess) {
      const sidebar: any = this.$refs.childComponentHeaderRef;
      if (sidebar) {
        sidebar.$refs.SidebarProfileRef.loadData();
        this.pageData.isAddNewSuccess = false;
      }
    }
  }
});
