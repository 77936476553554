










































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Header from "@/components/Common/Header.vue";
import { useCategoryBalanceStore } from "@/stores/Financial/CategoryBalanceStore";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import PopupPayment from "@/components/Financial/PopupPayment.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import { ApiHelper } from "@/helpers";
import PopupEditApplication from "@/components/Participant/PopupEditApplication.vue";
import TransactionDetails from "@/components/Financial/TransactionDetails.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import HeaderFilters from "@/components/Common/HeaderFilters.vue";
import DropdownBox from "@/components/Common/DropdownBox.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import FormDatePicker3 from "@/components/Form/FormDatePicker3.vue";
import FormSelect from "@/components/Form/FormSelect.vue";

export default defineComponent({
  name: "CategoryBalance",
  components: {
    FormSelect,
    TableHeaderColumn,
    Loading,
    Header,
    Pager,
    PopupPayment,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    FilterDateColumn,
    PopupEditApplication,
    TransactionDetails,
    HeaderTabs,
    HeaderFilters,
    DropdownBox,
    AutoCompleteInput,
    FormDatePicker3
  },
  setup(props, context) {
    const {
      loadData,
      pageData,
      updateRouters,
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      inSelectedIds,
      userSettings,
      showColumn,
      exportCSV,
      endDateChange,
      filterViewChange,
      filterByDateRange,
      allowFilterByDateRange,
      eventData
    } = useCategoryBalanceStore(context);

    const isACILoading = ref<boolean>(false);
    const suggestEvents = async (key: string) => {
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/events/search",
        {},
        {
          order: 1,
          direction: 1,
          // ignoreIds: props.eventId
          eventTypeId: pageData.value.controls.eventType.value,
          key: key
        }
      );
      if (result.status === 1) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        eventData.value.foundEvents = result.data.events.map((item: any) => ({
          id: item.id,
          text: item.name,
          data: item
        }));
      }
      isACILoading.value = false;
    };

    const selectEvent = (data: any) => {
      if (!data) return;

      const eventId = data.id || 0;
      const found = pageData.value.filter.selectedEvents.find(
        (item: any) => item.id == eventId
      );
      if (!found) {
        // reset other search conditions
        pageData.value.controls.evStartDate.value = "";
        pageData.value.controls.evEndDate.value = "";
        pageData.value.controls.endDate.value = "";

        pageData.value.filter.selectedEvents.push(data);
        loadData(1);
      }

      eventData.value.show = false;
    };

    const toggleSuggestedEvents = () => {
      eventData.value.show = !eventData.value.show;
      const suggestEventsRef: any = context.refs.suggestEventsRef;
      suggestEventsRef.key = "";
    };

    const onChangeEventType = () => {
      const suggestEventsRef: any = context.refs.suggestEventsRef;
      suggestEventsRef.key = "";
      pageData.value.filter.selectedEvents = [];
      eventData.value.show = false;
      loadData(1);
    };

    return {
      onChangeEventType,
      pageData,
      updateRouters,
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      inSelectedIds,
      getSelectedItems: ApiHelper.getSelectedItems,
      userSettings,
      showColumn,
      exportCSV,
      eventData,
      isACILoading,
      suggestEvents,
      selectEvent,
      toggleSuggestedEvents,
      filterViewChange,
      endDateChange,
      filterByDateRange,
      allowFilterByDateRange
    };
  }
});
