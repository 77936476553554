





import { defineComponent } from "@vue/composition-api";
import Vue from "vue";
import $ from "jquery";
export default defineComponent({
  name: "DeleteButton",
  components: {},
  props: {
    message: String,
    onConfirm: Function
  },
  setup(props) {
    const doDeleteItem = async (e: any) => {
      e.preventDefault();
      const isAgreed = await Vue.swal({
        html: props.message,
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });
      if (isAgreed && props.onConfirm) {
        props.onConfirm();
      }
    };
    return {
      doDeleteItem
    };
  }
});
