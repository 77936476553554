


























































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import { eventCabinDormGeneratorStore } from "@/stores/Event/EventCabinDormGeneratorStore";
import Avatar from "vue-avatar/src/Avatar.vue";
import draggable from "vuedraggable";
import { ApiHelper } from "@/helpers";
import Loading from "@/components/Common/Loading.vue";
import { DormGroup, Genders, numberFormat } from "@/helpers/ApiHelper";
import Modal from "@/components/Common/Modal.vue";
import $ from "jquery";
import LeaderLine from "leader-line-vue";
import VScroller from "@/components/Common/VScroller.vue";

const LINE_DEFAULT_OPTIONS = {
  color: "#c2c0c0",
  size: 2,
  dash: { animation: false },
  startPlug: "behind",
  endPlug: "behind",
  startSocketGravity: [-100, 0],
  endSocketGravity: [-100, 0]
};
const LINE_HOVER_OPTION = {
  color: "#670478",
  size: 2,
  dash: { animation: true, duration: 500 },
  startPlug: "behind",
  endPlug: "behind",
  startSocketGravity: [-100, 0],
  endSocketGravity: [-100, 0]
};

export default defineComponent({
  name: "EventCabinsDormGenerate",
  components: {
    draggable,
    Avatar,
    Loading,
    Modal,
    VScroller
  },
  setup(props, context) {
    const dormGeneratorDataRef = ref(eventCabinDormGeneratorStore);
    const dormGeneratorData = dormGeneratorDataRef.value.state;
    const criteria = ref("");
    const openDormList = ref<any>([]);
    const lines = ref<
      {
        key: string;
        line: any;
      }[]
    >([]);
    const movedItem = ref<{
      participantId: number;
      fromDormId: string;
      toDormId: string;
      participant: any;
      roommates: any;
      otherRommates: any;
    }>({
      participantId: 0,
      fromDormId: "",
      toDormId: "",
      participant: null,
      roommates: [],
      otherRommates: []
    });
    const allParticipants = ref<any>([]);
    const confirmMoveRoommatesVisible = ref(false);
    const hilightPIds = ref<any>([]);

    const getParticipantTitle = (item: any) => {
      let ret = `${item.name} (Age: ${
        item.age != 0 ? item.age : "N/A"
      }, Gender: ${item.genderName}, State: ${item.state})`;
      const roommates = [];
      const participantId = item.id;
      // check related roommate requests
      const relatedRoommates: any = dormGeneratorData?.roommateRequests.filter(
        (t: any) =>
          t.toParticipantId == participantId ||
          t.invitedParticipantId == participantId
      );
      for (const rm of relatedRoommates) {
        if (rm.toParticipantId == participantId) {
          // was invited by other
          roommates.push({
            profileId: rm.invitedProfileId,
            participantId: rm.invitedParticipantId,
            firstName: rm.invitedFname,
            lastName: rm.invitedLname,
            fullName: ApiHelper.getFullName(rm.invitedFname, rm.invitedLname)
          });
        } else if (rm.invitedParticipantId == participantId) {
          // invited the roommate request
          roommates.push({
            profileId: rm.profileId,
            participantId: rm.toParticipantId,
            firstName: rm.firstName,
            lastName: rm.lastName,
            fullName: ApiHelper.getFullName(rm.firstName, rm.lastName)
          });
        }
      }
      if (roommates.length) {
        ret += ` pairs with ${roommates.map(t => t.fullName).join(", ")}`;
      }

      return ret;
    };

    const appendRoomates = (participantList: any) => {
      const allParticipanList = participantList.reduce(
        (list: any, item: any) => {
          const participantId = item.id;
          const relatedRoommates: any = dormGeneratorData?.roommateRequests
            .filter((rm: any) => rm.toParticipantId == participantId)
            .map((rm: any) => ({
              profileId: rm.invitedProfileId,
              id: rm.invitedParticipantId,
              name: ApiHelper.getFullName(rm.invitedFname, rm.invitedLname),
              age: rm.invitedAge,
              gender: rm.invitedGender,
              requestId: rm.requestId,
              state: rm.invitedState
            }));
          return [
            ...list,
            { ...item, roomMateRequestCount: relatedRoommates.length },
            ...relatedRoommates
          ];
        },
        []
      );
      return allParticipanList;
    };

    const onScroll = () => {
      // reposition for roommates lines
      for (const item of lines.value) {
        item.line.position();
      }
    };

    const modelBack = () => {
      context.root.$router.push(
        `/events/${context.root.$route.params.eventId}/cabins`
      );
    };

    const onSubmit = async () => {
      dormGeneratorData.isLoading = true;
      const selectedEventId = parseInt(context.root.$route.params.eventId);
      const data = dormGeneratorDataRef.value.getSubmitData();
      const dorms = data.data;
      // append open dorm
      if (openDormList.value.length) {
        dorms.push({
          dormName: "Open Dorm",
          participants: openDormList.value
        });
      }

      const result = await ApiHelper.callApi(
        "post",
        `/events/${selectedEventId}/saveGeneratedDorms`,
        {
          data: dorms
        }
      );
      dormGeneratorData.isLoading = false;
      if (result.status !== 1) {
        ApiHelper.showErrorMessage(result.message, "Oops");
        return false;
      }
      if (result.status === 1) {
        ApiHelper.showSuccessMessage(result.message, "Dorm Generator");
      }
      modelBack();
    };

    const getConfirmRoommateMsg = (ids: any[]) => {
      let msg = "<ul>";
      ids.forEach((id, index) => {
        const roommate: any = dormGeneratorData.roommateRequests.find(
          (r: any) => r.toParticipantId === id
        );
        if (roommate) {
          msg = msg + `<li>${roommate.firstName} ${roommate.lastName}</li>`;
        }
      });
      msg = msg + "</ul>";
      return msg;
    };

    const getDormGenders = (dorm: any) => {
      const femaleCount = dorm.participants.filter(
        (fitem: any) => fitem.gender === Genders.Female
      ).length;
      const maleCount = dorm.participants.filter(
        (fitem: any) => fitem.gender === Genders.Male
      ).length;
      const undefinedCount = dorm.participants.filter(
        (fitem: any) => ![Genders.Male, Genders.Female].includes(fitem.gender)
      ).length;

      // update stats for dorm
      dorm.total = dorm.participants.length;
      dorm.maleCount = maleCount;
      dorm.femaleCount = femaleCount;
      dorm.undefinedCount = undefinedCount;

      return [
        { name: "FEMALE", count: femaleCount },
        { name: "MALE", count: maleCount },
        { name: "UNDEFINED", count: undefinedCount }
      ]
        .filter(fitem => fitem.count)
        .map(item => `${item.count} ${item.name}`)
        .join(", ");
    };

    const getDormAgeAverage = (dorm: any) => {
      const ageArray = dorm.participants.map((item: any) => item.age);
      const sum = ageArray.reduce((rsum: any, num: any) => rsum + num, 0);
      const avg = Math.round(sum / ageArray.length);
      return avg ? `${avg} YEARS OLD AVERAGE AGE` : "";
    };

    const getDormRequestCount = (dorm: any) => {
      const sum = dorm.participants.reduce(
        (sum: any, item: any) => sum + item.roommatesIds.length,
        0
      );
      return sum ? `${sum} ROOMMATE REQUEST` : "";
    };

    const getDormState = (dorm: any) => {
      const groupByState =
        dormGeneratorData.inputData.groupSimilarities == DormGroup.ByState;
      if (!groupByState) return "";

      const states = [
        ...new Set(dorm.participants.map((item: any) => item.state || ""))
      ].filter(state => state != "");
      if (states.length) {
        return `STATE: ${states.join(", ")}`;
      }

      return "";
    };

    const getRelatedLines = (participantId: number) => {
      return lines.value.filter(item =>
        item.key.split("-").includes(`${participantId}`)
      );
    };

    const resetLines = () => {
      for (const item of lines.value) {
        const participantIds = item.key.split("-");
        const fromId = participantIds[0];
        const toId = participantIds[1];
        const start = $(`#participant${fromId} .achor-point`).get(0);
        const end = $(`#participant${toId} .achor-point`).get(0);
        item.line?.remove();
        item.line = LeaderLine.setLine(start, end, LINE_DEFAULT_OPTIONS);
      }
    };

    const onAddParticipant = (evt: any) => {
      // update current moved item
      const participantId = parseInt($(evt.clone).data("id"));
      movedItem.value = {
        participantId,
        fromDormId: $(evt.from).data("uuid"),
        toDormId: $(evt.to).data("uuid"),
        participant: null,
        roommates: [],
        otherRommates: []
      };
      resetLines();

      // ignore if not selected pair roommate requests option
      const pairRoommateRequests =
        dormGeneratorData.inputData.pairRoommateRequests || 0;
      if (!pairRoommateRequests) {
        return;
      }

      // check dependencies/roommates
      const participant = allParticipants.value.find(
        (item: any) => item.id == participantId
      );

      if (participant) {
        const currentDorm = dormGeneratorData.data.find(
          item => item.uuid == movedItem.value.toDormId
        );
        if (
          !currentDorm ||
          currentDorm.dormName == "Open Dorm" ||
          !participant.roommatesIds.length
        ) {
          return;
        }

        // main roommates (invited or was invited)
        const roommatesIds = participant.roommatesIds || [];
        let roommates = allParticipants.value.filter((item: any) =>
          roommatesIds.includes(item.id)
        );
        // other dependencies (roommates of roommates)
        let otherRoommateIds = (dormGeneratorData.pGroupList || [])
          .find((ids: number[]) => ids.includes(participantId))
          .filter(
            (id: number) => !roommatesIds.includes(id) && id != participantId
          );

        // just care if there are roommates in other dorms
        const ignores: number[] = [];
        for (const id of [...roommatesIds, ...otherRoommateIds]) {
          const inDorm = currentDorm.participants.find((p: any) => p.id == id);
          if (inDorm) {
            ignores.push(id);
          }
        }
        roommates = roommates.filter((item: any) => !ignores.includes(item.id));
        otherRoommateIds = otherRoommateIds.filter(
          (id: number) => !ignores.includes(id)
        );
        if (!roommates.length && !otherRoommateIds.length) {
          return;
        }

        // show confirm box if want to move these roommates too
        movedItem.value.participant = participant;
        movedItem.value.roommates = roommates;
        movedItem.value.otherRommates = allParticipants.value.filter(
          (item: any) => otherRoommateIds.includes(item.id)
        );
        confirmMoveRoommatesVisible.value = true;
      }
    };

    const onDragEnd = () => {
      resetLines();
    };

    const hilightRelatedLines = (participantId: number, hilight: boolean) => {
      if (!participantId) return;

      // get requests that this participant has sent
      const sentRequest = dormGeneratorData.roommateRequests.filter(
        (item: any) => item.invitedParticipantId == participantId
      );
      if (sentRequest.length) {
        const relatedLineKeys = sentRequest
          .map(
            (item: any) =>
              `${item.invitedParticipantId}-${item.toParticipantId},${item.toParticipantId}-${item.invitedParticipantId}`
          )
          .join(",")
          .split(",");
        const relatedLines = lines.value.filter(item =>
          relatedLineKeys.includes(item.key)
        );

        for (const item of relatedLines) {
          item.line?.setOptions(
            hilight ? LINE_HOVER_OPTION : LINE_DEFAULT_OPTIONS
          );
        }

        // remember hilight participants
        hilightPIds.value = hilight
          ? relatedLines
              .map(item => item.key)
              .join("-")
              .split("-")
              .map(id => parseInt(id))
          : [];
      }
    };

    const inHilightPIds = (participantId: number) => {
      if (
        hilightPIds.value.length &&
        hilightPIds.value.includes(participantId)
      ) {
        return true;
      }

      return false;
    };

    const moveToOpenDorm = async (dorm: any, participant: any) => {
      dorm.participants = dorm.participants.filter(
        (p: any) => p.id != participant.id
      );
      openDormList.value.push(participant);
      await Promise.all([context.root.$forceUpdate()]);
      resetLines();
    };

    const getRoommatesMoveConfirmTitle = () => {
      const roommates = movedItem.value.roommates || [];
      const otherRommates = movedItem.value.otherRommates || [];
      const participant = movedItem.value.participant || null;
      if ((!roommates.length && !otherRommates.length) || !participant) {
        return;
      }
      let ret = "";
      if (roommates.length) {
        ret = `${participant.name} has ${roommates.length} dependencies:`;
      } else if (otherRommates.length) {
        ret = `${participant.name} dependencies:`;
      }

      return ret;
    };

    const cancelMoveParticipant = async () => {
      const participantId = movedItem.value.participantId || 0;
      const fromDorm = dormGeneratorData.data.find(
        item => item.uuid == movedItem.value.fromDormId
      );
      const toDorm = dormGeneratorData.data.find(
        item => item.uuid == movedItem.value.toDormId
      );
      const participant = (toDorm?.participants || []).find(
        (item: any) => item.id == participantId
      );
      if (toDorm && participant) {
        // remove participant from toDorm
        toDorm.participants = toDorm.participants.filter(
          (item: any) => item.id != participantId
        );
        // put back moved participant for fromDorm
        if (fromDorm) {
          fromDorm.participants.push(participant);
        } else {
          // back to open dorm
          openDormList.value.push(participant);
        }

        await Promise.all([context.root.$forceUpdate()]);
        resetLines();
      }
      confirmMoveRoommatesVisible.value = false;
      hilightPIds.value = [];
    };

    const moveRoommates = async () => {
      const toDorm = dormGeneratorData.data.find(
        item => item.uuid == movedItem.value.toDormId
      );
      const allRoommates = [
        ...movedItem.value.roommates,
        ...movedItem.value.otherRommates
      ];
      const roommateIds = allRoommates.map((item: any) => item.id);
      const roommates = allParticipants.value.filter((item: any) =>
        roommateIds.includes(item.id)
      );
      // move roomates out of the current dorms
      for (const rm of roommates) {
        const relatedDorm = dormGeneratorData.data.find(d =>
          d.participants.map((p: any) => p.id).includes(rm.id)
        );
        if (relatedDorm) {
          relatedDorm.participants = relatedDorm.participants.filter(
            (p: any) => p.id != rm.id
          );
        }
      }
      // append roommates to same dorm with this participant
      toDorm.participants = [...toDorm.participants, ...roommates];
      confirmMoveRoommatesVisible.value = false;
      await Promise.all([context.root.$forceUpdate()]);
      resetLines();
      hilightPIds.value = [];
    };

    const leaveRoommates = () => {
      // just accept this moving, and let roommates still are in the current dorms
      confirmMoveRoommatesVisible.value = false;
      hilightPIds.value = [];
    };

    const getCurrentDorm = (participantId: number) => {
      const relatedDorm = dormGeneratorData.data.find(d =>
        d.participants.map((p: any) => p.id).includes(participantId)
      );
      if (relatedDorm) {
        return relatedDorm.dormName;
      }

      return "";
    };

    const getInvitedBy = (participantId: number) => {
      const roommateRequests = dormGeneratorData?.roommateRequests || [];
      const invitedBy = roommateRequests.filter(
        (t: any) => t.toParticipantId == participantId
      );
      if (invitedBy.length) {
        // return roommates that invited this participant
        const names = invitedBy.map((item: any) =>
          `${item.invitedFname} ${item.invitedLname}`.trim()
        );
        return `Was invited by ${names.join(", ")}`;
      } else {
        // return roommates that this participant invited
        const invited = roommateRequests.filter(
          (t: any) => t.invitedParticipantId == participantId
        );
        const names = invited.map((item: any) =>
          `${item.firstName} ${item.lastName}`.trim()
        );
        if (invited.length) {
          return `Invited ${names.join(", ")}`;
        }
      }
      return "";
    };

    // init data
    (async () => {
      // get all participants
      for (const dorm of dormGeneratorData.data) {
        allParticipants.value = [
          ...allParticipants.value,
          ...dorm.participants
        ];
      }

      const openDormName = "Open Dorm";
      const openDorm = dormGeneratorData.data.find(
        (item: any) => item.dormName == openDormName
      );
      if ((openDorm?.participants || []).length) {
        openDormList.value = [...openDorm.participants];
      }
      dormGeneratorData.data = dormGeneratorData.data.filter(
        (item: any) => item.dormName != openDormName
      );
      const groupSimilaritiesMap: any = {
        "1": "Age",
        "2": "State"
      };
      const sameRequirementsMap: any = {
        "1": "Gender"
      };
      const participantTypesMap = (
        dormGeneratorData.participantTypes || []
      ).reduce((map: any, item: any) => ({ ...map, [item.id]: item.text }), {});
      const {
        groupSimilarities,
        pType,
        sameRequirements
      } = dormGeneratorData.inputData;

      const criteriaArrays = [
        groupSimilaritiesMap[groupSimilarities],
        sameRequirementsMap[sameRequirements],
        participantTypesMap[pType],
        dormGeneratorData.inputData.evenlyDistributeDorms
          ? "Evenly distribute dorms"
          : undefined,
        dormGeneratorData.inputData.pairRoommateRequests
          ? "Pair roommate requests"
          : undefined
      ].filter(fitem => !!fitem);
      criteria.value =
        criteriaArrays.length > 0
          ? `Grouped by ${criteriaArrays.join(", ")}`
          : "";
    })();

    return {
      dormGeneratorDataRef,
      getParticipantTitle,
      dormGeneratorData,
      onScroll,
      appendRoomates,
      onSubmit,
      modelBack,
      openDormList,
      getConfirmRoommateMsg,
      getDormGenders,
      getDormRequestCount,
      getDormAgeAverage,
      criteria,
      lines,
      hilightRelatedLines,
      ApiHelper,
      onAddParticipant,
      onDragEnd,
      resetLines,
      moveToOpenDorm,
      allParticipants,
      getRoommatesMoveConfirmTitle,
      confirmMoveRoommatesVisible,
      cancelMoveParticipant,
      moveRoommates,
      leaveRoommates,
      movedItem,
      getCurrentDorm,
      getInvitedBy,
      getDormState,
      inHilightPIds,
      hilightPIds
    };
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // draw lines for roommates
    for (const p of this.allParticipants) {
      for (const roommatePId of p.roommatesIds) {
        const relatedLine = this.lines.find(item =>
          [`${p.id}-${roommatePId}`, `${roommatePId}-${p.id}`].includes(
            item.key
          )
        );
        if (!relatedLine) {
          const start = $(`#participant${p.id} .achor-point`).get(0);
          const end = $(`#participant${roommatePId} .achor-point`).get(0);
          const key = `${p.id}-${roommatePId}`;
          const line = LeaderLine.setLine(start, end, LINE_DEFAULT_OPTIONS);
          this.lines.push({ key, line });
        }
      }
    }

    // reset lines when resizing
    $(document).on("resize", async function() {
      if ($(".preview-generated-dorms").length) {
        await Promise.all([self.$forceUpdate()]);
        self.resetLines();
      }
    });
  },
  beforeDestroy() {
    // make sure remove lines
    for (const item of this.lines) {
      item.line?.remove();
    }
  }
});
