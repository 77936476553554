var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"group text-uppercase",class:{
    isRequired: _vm.data.required,
    hasError: _vm.data.error !== ''
  }},[(_vm.data.label)?_c('label',{staticClass:"label__full",attrs:{"for":_vm.randomId}},[_vm._v(" "+_vm._s(_vm.data.label)+" ")]):_vm._e(),_c('div',{staticClass:"multiselect-control",staticStyle:{"clear":"both"}},[_c('div',{staticClass:"selection-container",on:{"click":function($event){_vm.data.showDropdown = !_vm.data.showDropdown}}},[_c('div',{staticClass:"selection"},[_c('span',[_vm._v(_vm._s(_vm.data.options.filter(function (item) { return item.selected; }).length)+" Selected")]),_c('div',{staticClass:"arrow"})]),_c('div',{staticClass:"selection-label"},[_vm._v(" "+_vm._s(_vm.selectionLabel || "Select Item(s)")+" ")])])]),(_vm.data.showDropdown)?_c('div',{staticClass:"dropdown-container multiSelectFooter"},[_vm._m(0),_c('VScroller',{class:{ 'alway-show-scroll': _vm.alwayShowScroll },attrs:{"fade-scroll":false}},[(_vm.data.options.length > 0)?_c('ul',{staticClass:"list"},[_vm._l((_vm.data.options),function(value,key){return [_c('li',{key:'user-option-' + key,staticClass:"item"},[_c('label',{staticClass:"label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(value.selected),expression:"value.selected"}],staticClass:"item-checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(value.selected)?_vm._i(value.selected,null)>-1:(value.selected)},on:{"change":[function($event){var $$a=value.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(value, "selected", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(value, "selected", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(value, "selected", $$c)}},function () {
                    _vm.data.error = '';
                    _vm.$forceUpdate();
                    _vm.doChange();
                  }]}}),_c('span',{staticClass:"text pl-1"},[_vm._v(_vm._s(value.title))])])])]})],2):_c('div',{staticClass:"noData"},[_vm._v(" "+_vm._s(_vm.noDataMsg)+" ")])]),(_vm.data.options.length > 0)?_c('div',{staticClass:"footer"},[_c('div',{staticClass:"btn-select-all",on:{"click":function($event){_vm.data.options.map(function (item) {
            item.selected = true;
          });
          _vm.$forceUpdate();
          _vm.doChange();}}},[_vm._v(" SELECT ALL ")]),_c('div',{staticClass:"btn-reset",on:{"click":function($event){_vm.data.options.map(function (item) {
            item.selected = false;
          });
          _vm.$forceUpdate();
          _vm.doChange();}}},[_vm._v(" DESELECT ALL ")])]):_vm._e()],1):(!_vm.hideSelection)?_c('div',[_vm._l((_vm.data.options.filter(function (item) { return item.selected; })),function(value,key){return [_c('div',{key:'selected-option-' + key,staticClass:"select2-selection__choice"},[_c('span',{staticClass:"select2-selection__choice__remove",attrs:{"role":"presentation"},on:{"click":function () {
              value.selected = false;
              _vm.$forceUpdate();
            }}},[_vm._v("×")]),_vm._v(" "+_vm._s(value.title)+" ")])]})],2):_vm._e(),(_vm.data.error)?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header",staticStyle:{"display":"none"}},[_c('div',{staticClass:"input-wrapper"},[_c('input',{staticClass:"search-input",attrs:{"placeholder":"Search Users"}})]),_c('div',{staticClass:"filter-wrapper"},[_vm._v(" Show: "),_c('label',{staticClass:"label"},[_c('input',{staticClass:"input",attrs:{"type":"checkbox","name":"select-employee-radio-group"}}),_c('span',{staticClass:"checkmark"}),_vm._v(" Selected ")])])])}]

export { render, staticRenderFns }