













import { defineComponent, ref } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import HeaderEvents from "@/components/Event/HeaderEvents.vue";
import ComingSoon from "@/components/Common/ComingSoon.vue";

export default defineComponent({
  name: "EventsOverview",
  components: {
    HeaderEvents,
    ComingSoon,
    Loading
  },
  setup() {
    const pageData = ref({
      isLoading: false
    });
    return {
      pageData
    };
  }
});
