









































































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import directives from "@/helpers/directives";

export default defineComponent({
  name: "FormNumber",
  props: {
    data: Object
  },
  directives: directives,
  setup() {
    const randomId = ref<number>(ApiHelper.randomFormId());
    return {
      randomId: randomId.value.toString()
    };
  }
});
