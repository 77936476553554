


























































































































































































































import { defineComponent } from "@vue/composition-api";

import $ from "jquery";
import { useSettingsParticipantFormsEditStore } from "@/stores/Settings/ParticipantForms/SettingsParticipantFormsEditStore";
import Loading from "@/components/Common/Loading.vue";
import Modal from "@/components/Common/Modal.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import FormButton from "@/components/Form/FormButton.vue";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";
import SignaturePad from "signature_pad";
import Vue from "vue";
import BackButton from "@/components/Common/BackButton.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import Inputmask from "inputmask";

require("jquery-ui-sortable");
require("formBuilder");
export default defineComponent({
  name: "SettingsParticipantFormsEditPage",
  components: {
    BackButton,
    Loading,
    Modal,
    AutoCompleteInput,
    FormSelect,
    FormButton,
    VScroller
  },
  setup(props, context) {
    const {
      selectedFormId,
      duplicateForm,
      modalLinkEvent,
      linkEvents,
      loadData,
      onSaveForm,
      headerData,
      pageData,
      loadInApps,
      isACILoading
    } = useSettingsParticipantFormsEditStore(context);
    const onClickChangerecycled = async () => {
      if (headerData.value.recycled) {
        headerData.value.recycled = 0;
      } else {
        headerData.value.recycled = 1;
      }
    };
    return {
      selectedFormId,
      duplicateForm,
      modalLinkEvent,
      linkEvents,
      loadData,
      onSaveForm,
      headerData,
      pageData,
      loadInApps,
      stripTags: ApiHelper.stripTags,
      isACILoading,
      onClickChangerecycled
    };
  },
  async mounted() {
    async function checkCharcount(length: any, max: any, e: any) {
      if (e.which != 8 && length >= max) {
        e.preventDefault();
      }
    }

    $(document).on("change", '[contenteditable="true"]', function(e) {
      let max = 999999999;
      if ($(this).attr("type") == "text") {
        max = 255;
      }

      if (e.which != 8 && $(this).html().length > max) {
        $(this).html(
          $(this)
            .html()
            .substring(0, max)
        );
      }

      checkCharcount($(this).html().length, max, e);
    });

    const pageData = this.pageData;
    const duplicateForm = this.duplicateForm;
    // define local funtions
    let loadInAppsContentTimeout: any = null;
    const loadInAppsContent = async (fieldID: number) => {
      const result = await this.loadInApps(fieldID);
      return result;
    };

    await this.loadData(1);
    function getPreview(filePath: string) {
      let html = "";
      const ext = ApiHelper.getFileExt(filePath).toLowerCase();
      const getFileName = ApiHelper.getFileName(filePath);
      if (ext == "jpg" || ext == "png" || ext == "gif") {
        html = `<img width="150" src="${filePath}" />`;
      } else {
        html += `<strong>${getFileName}</strong>`;
      }
      return html;
    }
    // Find favorite add class margin
    if ($(".form-wrap.form-builder").length == 0) {
      const devBuilder: any = $(this.$refs.formBuilder);
      const formRenderData = JSON.stringify(devBuilder.data("json"));

      const hiddenRender: any = $("#hiddenRender");

      const fields0 = [];
      fields0.push({
        label: "Signature",
        attrs: {
          type: "signature"
        }
      });
      const templates: any = {
        signature: (fieldData: any) => {
          return {
            field: `
                <div>
                  <div style="background-color: #eee; padding: 10px;">
                    <canvas id="${fieldData.name}" style="touch-action: none; background: white;" height="65" width="352"></canvas>
                  </div>
                </div>`,
            onRender: function() {
              const canvas: any = document.getElementById(fieldData.name);
              const signaturePad = new SignaturePad(canvas, {
                backgroundColor: "rgb(255, 255, 255)"
              });
            }
          };
        },
        dob: (fieldData: any) => {
          return {
            field: `<div></div>`
          };
        },
        conditionalOptions: (fieldData: any) => {
          const id = fieldData.id;
          return {
            field: `
              <div class="radio-group">
                <div class="formbuilder-radio">
                  <input name="${id}" id="${id}-0" value="Yes" type="radio">
                  <label for="${id}-0">Yes</label>
                </div>
                <div class="formbuilder-radio">
                  <input name="${id}" class="" id="${id}-1" value="No" type="radio">
                  <label for="${id}-1">No</label>
                </div>
              </div>
            `,
            onRender: () => {
              // do nothing
            }
          };
        },
        medication: (fieldData: any) => {
          const id = fieldData.id;
          const returnHTML: any = [];
          const userData = fieldData.userData || [];
          const noneOptionChecked =
            (fieldData.noneOptionChecked || false) == true;
          if (!userData.length) {
            // init
            userData.push({
              medicalId: 0,
              medicationName: "",
              // medicationRoute: "",
              // frequency: "",
              // notes: "",
              medicationStrength: "",
              dispenseMethod: "",
              dosages: "",
              dosageOther: "",
              prescribingDoctor: "",
              specialInstructions: "",
              attachPrescription: ""
            });
          }

          for (const data of userData) {
            // saved data
            const medicalId = data.medicalId || 0;
            const medicationNameVal = data.medicationName || "";
            // const medicationRouteVal = data.medicationRoute || "";
            // const frequencyVal = data.frequency || "";
            // const notesVal = data.notes || "";
            const strengthVal = data.medicationStrength || "";
            const dispenseMethodVal = data.dispenseMethod || "";
            const dosagesVal = data.dosages || "";
            const dosageOtherVal = data.dosageOther || "";
            const specialInstructionsval = data.specialInstructions || "";
            const prescribingDoctorVal = data.prescribingDoctor || "";
            const prescriptionName = data.prescriptionName || "";
            const prescriptionVal = data.prescription || "";
            let files: any = [];
            if (prescriptionName != "" && prescriptionVal != "") {
              files = [
                {
                  name: prescriptionName,
                  path: prescriptionVal
                }
              ];
            }

            // ids
            const randomId = Math.floor(Math.random() * 10000000);
            const medicationName = `${id}-medication-name-${randomId}`;
            // const medicationRoute = `${id}-medication-route-${randomId}`;
            // const frequency = `${id}-frequency-${randomId}`;
            // const notes = `${id}-notes-${randomId}`;
            const strengthId = `${id}-medication-strength-${randomId}`;
            const dispenseMethodId = `${id}-medication-dispense-method-${randomId}`;
            const dosagesId = `${id}-medication-dosages-${randomId}`;
            const dosageOtherId = `${id}-medication-other-dosage-${randomId}`;
            const instructionsId = `${id}-medication-instructions-${randomId}`;
            const prescribingDoctorId = `${id}-prescribing-doctor-${randomId}`;
            const attachPrescriptionId = `${id}-attach-prescription-${randomId}`;

            const requiredAttr = fieldData.required
              ? 'required="required" aria-required="true"'
              : "";
            const requireTag = fieldData.required
              ? '<span class="formbuilder-required">*</span>'
              : "";
            const dispenseMethodOptions: any = ApiHelper.getDispenseMethods();
            const dispenseMethodOptionsHTML = [
              `<option value="">Select Dispense Method</option>`
            ];
            for (const i in dispenseMethodOptions) {
              dispenseMethodOptionsHTML.push(`
                <option value="${
                  dispenseMethodOptions[i].id
                }" id="${dispenseMethodId}-${i}"
                  ${
                    dispenseMethodOptions[i].id == dispenseMethodVal
                      ? "selected"
                      : ""
                  }
                >
                  ${dispenseMethodOptions[i].text}
                </option>
              `);
            }
            const dosagesOptions: any = ApiHelper.getMedicationDosages();
            const dosagesOptionsHTML = [];
            for (const i in dosagesOptions) {
              dosagesOptionsHTML.push(`
                <div class="formbuilder-checkbox-inline form-check">
                  <input class="form-check-input" name="${dosagesId}[]" multiple-selections="true" id="${dosagesId}-${i}" 
                    value="${dosagesOptions[i].value}" 
                    type="checkbox" 
                    ${
                      dosagesVal.includes(`${dosagesOptions[i].value}`)
                        ? "checked"
                        : ""
                    } 
                  />
                    <label class="form-check-label" for="${dosagesId}-${i}">
                      ${dosagesOptions[i].title}
                    </label>
                </div>
              `);
            }

            // const routeOptions = ApiHelper.getMedicationDosageOptions();
            // const routeOptionsHTML = [`<option value="">Select Route</option>`];
            // const frequencyOptions = ApiHelper.getMedicationFrequencyOptions();
            // const frequencyOptionsHTML = [
            //   `<option value="">Select Frequency</option>`
            // ];
            // for (const i in routeOptions) {
            //   routeOptionsHTML.push(`
            //   <option value="${routeOptions[i].text}"
            //     id="${medicationRoute}-${i}"
            //     ${routeOptions[i].text == medicationRouteVal ? "selected" : ""}
            //   >
            //     ${routeOptions[i].text}
            //   </option>
            // `);
            // }
            // for (const i in frequencyOptions) {
            //   frequencyOptionsHTML.push(`
            //   <option value="${frequencyOptions[i].text}"
            //     id="${frequency}-${i}"
            //     ${frequencyOptions[i].text == frequencyVal ? "selected" : ""}
            //   >
            //     ${frequencyOptions[i].text}
            //   </option>
            // `);
            // }

            // <div class="formbuilder-select ${formgroup} field-select-${medicationRoute} col-6">
            //   <label for="${medicationRoute}" class="formbuilder-select-label field-label">
            //     Medication Route${requireTag}
            //   </label>
            //   <select class="form-control input__full" name="${medicationRoute}" id="${medicationRoute}" ${requiredAttr}>
            //     ${routeOptionsHTML.join("")}
            //   </select>
            // </div>
            // <div class="formbuilder-select ${formgroup} field-select-${frequency} col-6">
            //   <label for="${frequency}" class="formbuilder-select-label field-label">
            //     Frequency${requireTag}
            //   </label>
            //   <select class="form-control input__full" name="${frequency}" id="${frequency}" ${requiredAttr}>
            //     ${frequencyOptionsHTML.join("")}
            //   </select>
            // </div>
            // <div class="formbuilder-text ${formgroup} field-text-${notes} col-6 medication-notes">
            //   <label for="${notes}" class="formbuilder-text-label field-label">Medication notes</label>
            //   <input type="text" value="${notesVal}" class="form-control" name="${notes}" inputmask="none" id="${notes}">
            // </div>

            const formgroup = $(".todo-edit-page").length ? "" : "form-group";
            const dosageOtherHide = (dosagesVal || []).includes("6")
              ? ""
              : "d-none";
            returnHTML.push(`
              <div class="medication-info row" data-medical-id="${medicalId}">
                <span class="remove-medication-btn" title="Remove">x</span>
                <div class="formbuilder-text ${formgroup} field-text-${medicationName} col-4">
                  <label for="${medicationName}" class="formbuilder-text-label field-label">Medication name${requireTag}</label>
                  <input type="text" value="${medicationNameVal}" class="form-control" name="${medicationName}" inputmask="none" id="${medicationName}" ${requiredAttr} maxlength="150">
                </div>
                <div class="formbuilder-text ${formgroup} field-text-${strengthId} col-4">
                  <label for="${strengthId}" class="formbuilder-text-label field-label">Strength</label>
                  <input type="text" value="${strengthVal}" class="form-control" name="${strengthId}" inputmask="none" id="${strengthId}" maxlength="50">
                </div>
                <div class="formbuilder-select ${formgroup} field-select-${dispenseMethodId} col-4">
                  <label for="${dispenseMethodId}" class="formbuilder-select-label field-label">
                    Dispense Method${requireTag}
                  </label>
                  <select class="form-control input__full" name="${dispenseMethodId}" id="${dispenseMethodId}" ${requiredAttr}>
                    ${dispenseMethodOptionsHTML.join("")}
                  </select>
                </div>
                <div class="formbuilder-checkbox-group ${formgroup} field-checkbox-group-${dosagesId} col-8 medication-dosages-cb">
                  <label for="${dosagesId}" class="field-label formbuilder-checkbox-group-label">
                    Dosages${requireTag}
                  </label>
                  <div class="checkbox-group">
                    ${dosagesOptionsHTML.join("")}
                  </div>
                </div>
                <div class="formbuilder-text form-group field-text-${dosageOtherId} col-4 ${dosageOtherHide}">
                  <label class="formbuilder-text-label field-label"></label>
                  <input type="text" value="${dosageOtherVal}" placeholder="Other" class="form-control" name="${dosageOtherId}" id="${dosageOtherId}" maxlength="50" ${requiredAttr} />
                </div>
                <div class="formbuilder-text ${formgroup} field-text-${prescribingDoctorId} col-12 medication-prescribing-doctor">
                  <label for="${prescribingDoctorId}" class="formbuilder-text-label field-label">Prescribing Doctor ${requireTag}</label>
                  <input type="text" value="${prescribingDoctorVal}" class="form-control" name="${prescribingDoctorId}" id="${prescribingDoctorId}" ${requiredAttr}>
                </div>
                <div class="formbuilder-text ${formgroup} field-text-${instructionsId} col-12 medication-instructions">
                  <label for="${instructionsId}" class="formbuilder-text-label field-label">Special Instructions</label>
                  <textarea class="form-control" name="${instructionsId}" id="${instructionsId}">${specialInstructionsval}</textarea>
                </div>
                <div class="formbuilder-text ${formgroup} field-text-${attachPrescriptionId} col-6 medication-attach-prescription">
                  <label>
                    <input type="file" class="form-control d-none" name="${attachPrescriptionId}" id="${attachPrescriptionId}" multiple="false" 
                      files='${JSON.stringify(files)}' 
                    />
                    <span>Attach Prescription</span>
                  </label>
                </div>
              </div>
            `);
          }

          return {
            field: `
              <div class="checkbox-group">
                <div class="formbuilder-checkbox">
                  <input name="${id}-none-option" id="${id}-none-option" type="checkbox" 
                    class="medication-none-option" ${
                      noneOptionChecked ? "checked" : ""
                    }>
                  <label for="${id}-none-option">N/A</label>
                </div>
              </div>
              ${returnHTML.join(`<hr class="medication-spliter" />`)}
              <div class="medication-addmore"><span class="addmore-btn">More Medication</span></div>
            `,
            onRender: () => {
              const field = $(`.field-${id}`);
              if (noneOptionChecked) {
                field.addClass("none-medication");
              }

              // exit if on todo edit page
              if ($(".todo-edit-page").length) return;

              $(document)
                .off("click", ".addmore-btn")
                .on("click", ".addmore-btn", e => {
                  if ($(".todo-edit-page").length) return;

                  const button = $(e.target);
                  const fieldMedication = button.closest(
                    ".formbuilder-medication"
                  );

                  const randomId = Math.floor(Math.random() * 10000000);
                  const sectionId = `medication-info-${randomId}`;
                  const medicationSection = fieldMedication
                    .find(".medication-info")
                    .last();

                  // just allow add more if filled for last medication
                  const lastMedicationName =
                    medicationSection
                      .find(`input[type=text][id*="medication-name"]`)
                      .val() || "";
                  // const lastMedicationRoute =
                  //   medicationSection.find(`select[id*="medication-route"]`).val() ||
                  //   "";
                  // const lastFrequency =
                  //   medicationSection.find(`select[id*="frequency"]`).val() || "";
                  const lastDispenseMethod =
                    medicationSection
                      .find(`select[id*="medication-dispense-method"]`)
                      .val() || "";
                  const lastDosages = medicationSection
                    .find(`.medication-dosages-cb input[type=checkbox]:checked`)
                    .map((i, e) => $(e).val())
                    .toArray();
                  const lastPrescribingDoctor =
                    medicationSection
                      .find(`input[type=text][id*="prescribing-doctor"]`)
                      .val() || "";
                  if (
                    `${lastMedicationName}`.trim() == "" ||
                    // lastMedicationRoute == "" ||
                    // lastFrequency == "" ||
                    lastDispenseMethod == "" ||
                    !lastDosages.length ||
                    `${lastPrescribingDoctor}`.trim() == ""
                  ) {
                    return;
                  }

                  const newSection = medicationSection.clone();
                  newSection.removeAttr("data-medical-id").addClass(sectionId);
                  medicationSection.after(`
                    <hr class="medication-spliter" />
                    ${newSection.prop("outerHTML")}
                  `);

                  // new ids/val for new section
                  const medicationName = `${id}-medication-name-${randomId}`;
                  // const medicationRoute = `${id}-medication-route-${randomId}`;
                  // const frequency = `${id}-frequency-${randomId}`;
                  // const notes = `${id}-notes-${randomId}`;
                  const strengthId = `${id}-medication-strength-${randomId}`;
                  const dispenseMethodId = `${id}-medication-dispense-method-${randomId}`;
                  const dosagesId = `${id}-medication-dosages-${randomId}`;
                  const instructionsId = `${id}-medication-instructions-${randomId}`;
                  const prescribingDoctorId = `${id}-prescribing-doctor-${randomId}`;
                  const attachPrescriptionId = `${id}-attach-prescription-${randomId}`;

                  $(`.${sectionId} > div[class*="formbuilder-"]`).each(
                    (i, obj) => {
                      const objClass = $(obj)?.attr("class") || "";
                      // medication name
                      if (objClass.indexOf("medication-name") != -1) {
                        $(obj).attr(
                          "class",
                          `formbuilder-text form-group field-text-${medicationName} col-4`
                        );
                        $(obj)
                          .find("label")
                          .attr("for", medicationName);
                        $(obj)
                          .find("input[type=text]")
                          .attr({
                            id: medicationName,
                            name: medicationName,
                            value: ""
                          });
                      }

                      // medication strength
                      if (objClass.indexOf("medication-strength") != -1) {
                        $(obj).attr(
                          "class",
                          `formbuilder-text form-group field-text-${strengthId} col-4`
                        );
                        $(obj)
                          .find("label")
                          .attr("for", strengthId);
                        $(obj)
                          .find("input[type=text]")
                          .attr({
                            id: strengthId,
                            name: strengthId,
                            value: ""
                          });
                      }

                      // Dispense Method
                      if (
                        objClass.indexOf("medication-dispense-method") != -1
                      ) {
                        $(obj).attr(
                          "class",
                          `formbuilder-select form-group field-select-${dispenseMethodId} col-4`
                        );
                        $(obj)
                          .find("label")
                          .attr("for", dispenseMethodId);
                        $(obj)
                          .find("select")
                          .attr({
                            id: dispenseMethodId,
                            name: dispenseMethodId
                          })
                          .val("");
                      }

                      // dosages
                      if (objClass.indexOf("medication-dosages") != -1) {
                        $(obj).attr(
                          "class",
                          `formbuilder-checkbox-group form-group field-checkbox-group-${dosagesId} col-8 medication-dosages-cb`
                        );
                        $(obj)
                          .find("label")
                          .attr("for", dosagesId);
                        $(obj)
                          .find(".form-check")
                          .each((i, e) => {
                            $(e)
                              .find("input.form-check-input")
                              .prop("checked", false)
                              .attr({
                                id: `${dosagesId}-${i}`,
                                name: `${dosagesId}[]`
                              });
                            $(e)
                              .find(".form-check-label")
                              .attr("for", `${dosagesId}-${i}`);
                          });
                      }

                      if (objClass.indexOf("medication-other-dosage") != -1) {
                        $(obj)
                          .addClass("d-none")
                          .find("input[type=text]")
                          .val("");
                      }

                      // Special Instructions
                      if (objClass.indexOf("medication-instructions") != -1) {
                        $(obj).attr(
                          "class",
                          `formbuilder-text form-group field-text-${instructionsId} col-12 medication-instructions`
                        );
                        $(obj)
                          .find("label")
                          .attr("for", instructionsId);
                        $(obj)
                          .find("textarea")
                          .attr({
                            id: instructionsId,
                            name: instructionsId
                          })
                          .val("");
                      }

                      // medication route
                      // if (objClass.indexOf("medication-route") != -1) {
                      //   $(obj).attr(
                      //     "class",
                      //     `formbuilder-select form-group field-select-${medicationRoute} col-6`
                      //   );
                      //   $(obj)
                      //     .find("label")
                      //     .attr("for", medicationRoute);
                      //   $(obj)
                      //     .find("select")
                      //     .attr({
                      //       id: medicationRoute,
                      //       name: medicationRoute
                      //     })
                      //     .val("");
                      // }

                      // frequency
                      // if (objClass.indexOf("frequency") != -1) {
                      //   $(obj).attr(
                      //     "class",
                      //     `formbuilder-select form-group field-select-${frequency} col-6`
                      //   );
                      //   $(obj)
                      //     .find("label")
                      //     .attr("for", frequency);
                      //   $(obj)
                      //     .find("select")
                      //     .attr({
                      //       id: frequency,
                      //       name: frequency
                      //     })
                      //     .val("");
                      // }

                      // medication notes
                      // if (objClass.indexOf("-notes") != -1) {
                      //   $(obj).attr(
                      //     "class",
                      //     `formbuilder-text form-group field-text-${notes} col-6 medication-notes`
                      //   );
                      //   $(obj)
                      //     .find("label")
                      //     .attr("for", notes);
                      //   $(obj)
                      //     .find("input[type=text]")
                      //     .attr({
                      //       id: notes,
                      //       name: notes,
                      //       value: ""
                      //     });
                      // }

                      // prescribing-doctor
                      if (objClass.indexOf("-prescribing-doctor") != -1) {
                        $(obj).attr(
                          "class",
                          `formbuilder-text form-group field-text-${prescribingDoctorId} col-12 medication-prescribing-doctor`
                        );
                        $(obj)
                          .find("label")
                          .attr("for", prescribingDoctorId);
                        $(obj)
                          .find("input[type=text]")
                          .attr({
                            id: prescribingDoctorId,
                            name: prescribingDoctorId,
                            value: ""
                          });
                      }

                      if (objClass.indexOf("-attach-prescription") != -1) {
                        $(obj).attr(
                          "class",
                          `formbuilder-text form-group field-text-${attachPrescriptionId} col-6 medication-attach-prescription`
                        );
                        $(obj)
                          .find(".file-preview")
                          .remove();
                        $(obj)
                          .find("input[type=file]")
                          .removeAttr("file-path")
                          .removeAttr("file-name")
                          .attr({
                            id: attachPrescriptionId,
                            name: attachPrescriptionId,
                            value: undefined,
                            files: "[]"
                          });
                      }
                    }
                  );
                  $(
                    `.${sectionId} .text-danger, .${sectionId} .border-danger`
                  ).removeClass("text-danger border-danger");
                });

              // has or has not medication information
              field
                .find(".medication-none-option")
                .off("change")
                .on("change", e => {
                  if ($(".todo-edit-page").length) return;

                  const obj = $(e.target);
                  if (obj.prop("checked") == true) {
                    field.addClass("none-medication");
                  } else {
                    field.removeClass("none-medication");
                  }
                });

              $(document)
                .off("click", ".remove-medication-btn")
                .on("click", ".remove-medication-btn", e => {
                  if ($(".todo-edit-page").length) return;

                  const btn = $(e.target);
                  const spliter = btn.closest(".medication-info").prev();
                  if (spliter.hasClass("medication-spliter")) {
                    spliter.remove();
                  }
                  btn.closest(".medication-info").remove();
                });

              $(document)
                .off(
                  "change",
                  "input.form-check-input[name*='medication-dosages']"
                )
                .on(
                  "change",
                  "input.form-check-input[name*='medication-dosages']",
                  obj => {
                    if ($(".todo-edit-page").length) return;

                    const cb = $(obj.target);
                    const medicationInfo = cb.closest(".medication-info");
                    const groupCb = cb.closest(".medication-dosages-cb");
                    const dosageOther = medicationInfo.find(
                      ".formbuilder-text[class*='medication-other-dosage']"
                    );
                    const dosagesVal = groupCb
                      .find(`input[type=checkbox]:checked`)
                      .map((i, e) => $(e).val())
                      .toArray();
                    if (dosagesVal.includes("6")) {
                      // show "other" option
                      dosageOther.removeClass("d-none");
                    } else {
                      // hide "other" option
                      dosageOther.addClass("d-none");
                    }
                  }
                );
            }
          };
        },
        allergies: (fieldData: any) => {
          const id = fieldData.id;
          const returnHTML: any = [];
          const userData = [];
          if (!userData.length) {
            // init
            userData.push({
              allergiesId: 0,
              allergiesName: "",
              allergiesCategory: "",
              allergiesSeverity: ""
            });
          }
          const requiredAttr = this.required
            ? 'required="required" aria-required="true"'
            : "";
          const requireTag = this.required
            ? '<span class="formbuilder-required">*</span>'
            : "";
          for (const data of userData) {
            // saved data
            const allergiesId = data.allergiesId || 0;
            const allergiesNameVal = data.allergiesName || "";
            const allergiesCategoryVal = data.allergiesCategory || "";
            const allergiesSeverityVal = data.allergiesSeverity || "";

            // ids
            const randomId = Math.floor(Math.random() * 10000000);
            const allergiesNameId = `${id}-allergies-name-${randomId}`;
            const allergiesCategoryId = `${id}-allergies-category-${randomId}`;
            const allergiesSeverityId = `${id}-allergies-severity-${randomId}`;

            const severityOptions: any = [];
            for (const s of ["Inhale", "Ingest", "On-Contact"]) {
              severityOptions.push(
                `<option value="${s}" 
                ${allergiesSeverityVal == s ? "selected" : ""}>
                ${s}
              </option>`
              );
            }

            const formgroup = $(".todo-edit-page").length ? "" : "form-group";
            returnHTML.push(`
              <div class="allergies-info row" data-allergies-id="${allergiesId}">
                <span class="remove-allergies-btn" title="Remove">x</span>
                <div class="formbuilder-text ${formgroup} field-text-${allergiesNameId} col-6">
                  <label for="${allergiesNameId}" class="formbuilder-text-label field-label">Allergy Name ${requireTag}</label>
                  <input type="text" value="${allergiesNameVal}" class="form-control" name="${allergiesNameId}" inputmask="none" id="${allergiesNameId}" ${requiredAttr} maxlength="255">
                </div>
                <div class="formbuilder-select ${formgroup} field-select-${allergiesCategoryId} col-6">
                  <label for="${allergiesCategoryId}" class="formbuilder-select-label field-label">
                    Allergy Category ${requireTag}
                  </label>
                  <select class="form-control input__full allergies-category" name="${allergiesCategoryId}" id="${allergiesCategoryId}" ${requiredAttr}>
                    <option value="">Select Category</option>
                    <option value="Food">Food</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div class="formbuilder-select ${formgroup} field-select-${allergiesSeverityId} col-6 allergies-severity-section d-none">
                  <label for="${allergiesSeverityId}" class="formbuilder-select-label field-label">
                    Severity ${requireTag}
                  </label>
                  <select class="form-control input__full" name="${allergiesSeverityId}" id="${allergiesSeverityId}" ${requiredAttr}>
                    <option value="">Select Severity</option>
                    ${severityOptions.join("")}
                  </select>
                </div>
              </div>
            `);
          }

          return {
            field: `
              <div class="checkbox-group">
                <div class="formbuilder-checkbox">
                  <input name="allergies-${id}-none-option" id="allergies-${id}-none-option" type="checkbox" class="allergies-none-option">
                  <label for="allergies-${id}-none-option">N/A</label>
                </div>
              </div>
              ${returnHTML.join(`<hr class="allergies-spliter" />`)}
              <div class="allergies-addmore"><span class="allergies-addmore-btn">More Allergies</span></div>
            `,
            onRender: () => {
              $(document)
                .off("change", ".allergies-category")
                .on("change", ".allergies-category", e => {
                  const select = $(e.target);
                  const infoSection = select.closest(".allergies-info");
                  const severitySection = infoSection
                    .find(".allergies-severity-section")
                    .addClass("d-none");
                  if (select.val() == "Food") {
                    severitySection.removeClass("d-none");
                  }
                });
            }
          };
        },
        transportation: (fieldData: any) => {
          const id = fieldData.id;
          const userData = {
            arriving: {
              value: "",
              whoDrivingYou: "",
              airlines: "",
              flightNumber: "",
              dateTime: "",
              other: ""
            },
            departing: {
              value: "",
              whoDrivingYou: "",
              airlines: "",
              flightNumber: "",
              dateTime: "",
              other: ""
            }
          };
          const arriving = userData.arriving;
          const departing = userData.departing;
          const randomId = Math.floor(Math.random() * 10000000);
          const textCarId = `text-${id}-car-selected`;
          const arrivingTextAirplaneId = `arriving-text-${id}-airplane-selected`;
          const departingTextAirplaneId = `departing-text-${id}-airplane-selected`;
          const arrivalRadioName = `${id}-${randomId}`;
          const departureRadioName = `${id}-${randomId}-1`;
          const requiredAttr = this.required
            ? 'required="required" aria-required="true"'
            : "";
          const requireTag = this.required
            ? '<span class="formbuilder-required">*</span>'
            : "";
          return {
            field: `
              <div class="arrival-section trans-info-section">
                <label class="field-label">
                  ${fieldData.arrivingLabel || ""}
                </label>
                <div class="form-group radio-group">
                  <div class="formbuilder-radio">
                    <input name="${arrivalRadioName}" id="${arrivalRadioName}-0" value="Car" type="radio">
                    <label for="${arrivalRadioName}-0">Car</label>
                  </div>
                  <div class="formbuilder-radio">
                    <input name="${arrivalRadioName}" class="" id="${arrivalRadioName}-1" value="Train" type="radio">
                    <label for="${arrivalRadioName}-1">Train</label>
                  </div>
                  <div class="formbuilder-radio">
                    <input name="${arrivalRadioName}" class="" id="${arrivalRadioName}-2" value="Airplane" type="radio">
                    <label for="${arrivalRadioName}-2">Airplane</label>
                  </div>
                  <div class="formbuilder-radio">
                    <input name="${arrivalRadioName}" class="" id="${arrivalRadioName}-3" value="Other" type="radio">
                    <label for="${arrivalRadioName}-3" class="mb-0">
                      <input type="text" placeholder="Other" class="form-control" />
                    </label>
                  </div>
                </div>
                <div class="formbuilder-text form-group field-text-${id}-car-selected text-car-selected d-none">
                  <label for="${textCarId}" class="formbuilder-text-label field-label">
                    <span>Please indicate who will be driving you</span>
                    ${requireTag}
                  </label>
                  <input type="text" class="form-control" name="${textCarId}" id="${textCarId}" ${requiredAttr}>
                </div>
                <div class="row text-airplane-selected 
                  ${arriving.value == "Airplane" ? "" : "d-none"}">
                  <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                    <label for="${arrivingTextAirplaneId}-airlines" class="formbuilder-text-label field-label">
                      <span>Please enter your airlines</span>
                      ${requireTag}
                    </label>
                    <input type="text" class="form-control" name="${arrivingTextAirplaneId}-airlines" id="${arrivingTextAirplaneId}-airlines" 
                      value="${arriving.airlines || ""}" ${requiredAttr}>
                  </div>
                  <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected"> 
                    <label for="${arrivingTextAirplaneId}-flightNumber" class="formbuilder-text-label field-label">
                      <span>Your flight number</span>
                      ${requireTag}
                    </label>
                    <input type="text" class="form-control" name="${arrivingTextAirplaneId}-flightNumber" id="${arrivingTextAirplaneId}-flightNumber" 
                      value="${arriving.flightNumber || ""}" ${requiredAttr}>
                  </div>
                  <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                    <label for="${arrivingTextAirplaneId}-dateTime" class="formbuilder-text-label field-label">
                      <span>Date and time of arrival</span>
                      ${requireTag}
                    </label>
                    <input type="text" class="form-control" name="${arrivingTextAirplaneId}-dateTime" id="${arrivingTextAirplaneId}-dateTime" 
                      value="${arriving.dateTime || ""}" ${requiredAttr}>
                  </div>
                </div>
              </div>

              <div class="departure-section trans-info-section">
                <label class="field-label">
                  ${fieldData.departingLabel || ""}
                </label>
                <div class="form-group radio-group">
                  <div class="formbuilder-radio">
                    <input name="${departureRadioName}" id="${departureRadioName}-0" value="Car" type="radio">
                    <label for="${departureRadioName}-0">Car</label>
                  </div>
                  <div class="formbuilder-radio">
                    <input name="${departureRadioName}" class="" id="${departureRadioName}-1" value="Train" type="radio">
                    <label for="${departureRadioName}-1">Train</label>
                  </div>
                  <div class="formbuilder-radio">
                    <input name="${departureRadioName}" class="" id="${departureRadioName}-2" value="Airplane" type="radio">
                    <label for="${departureRadioName}-2">Airplane</label>
                  </div>
                  <div class="formbuilder-radio">
                    <input name="${departureRadioName}" class="" id="${departureRadioName}-3" value="Other" type="radio">
                    <label for="${departureRadioName}-3" class="mb-0">
                      <input type="text" placeholder="Other" class="form-control" />
                    </label>
                  </div>
                </div>
                <div class="formbuilder-text form-group field-text-${id}-car-selected text-car-selected d-none">
                  <label for="${textCarId}" class="formbuilder-text-label field-label">
                    <span>Please indicate who will be driving you</span>
                    ${requireTag}
                  </label>
                  <input type="text" class="form-control" name="${textCarId}" id="${textCarId}" ${requiredAttr}>
                </div>
                <div class="row text-airplane-selected 
                  ${departing.value == "Airplane" ? "" : "d-none"}">
                  <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                    <label for="${departingTextAirplaneId}-airlines" class="formbuilder-text-label field-label">
                      <span>Please enter your airlines</span>
                      ${requireTag}
                    </label>
                    <input type="text" class="form-control" name="${departingTextAirplaneId}-airlines" id="${departingTextAirplaneId}-airlines" 
                      value="${departing.airlines || ""}"
                      ${requiredAttr}>
                  </div>
                  <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                    <label for="${departingTextAirplaneId}-flightNumber" class="formbuilder-text-label field-label">
                      <span>Your flight number</span>
                      ${requireTag}
                    </label>
                    <input type="text" class="form-control" name="${departingTextAirplaneId}-flightNumber" id="${departingTextAirplaneId}-flightNumber" 
                      value="${departing.flightNumber || ""}"
                      ${requiredAttr}>
                  </div>
                  <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                    <label for="${departingTextAirplaneId}-dateTime" class="formbuilder-text-label field-label">
                      <span>Date and time of departure</span>
                      ${requireTag}
                    </label>
                    <input type="text" class="form-control" name="${departingTextAirplaneId}-dateTime" id="${departingTextAirplaneId}-dateTime" 
                      value="${departing.dateTime || ""}"
                      ${requiredAttr}>
                  </div>
                </div>
              </div>
            `,
            onRender: () => {
              $(document)
                .off("click", ".trans-info-section input[type=radio]")
                .on("click", ".trans-info-section input[type=radio]", e => {
                  const radio = $(e.target);
                  const infoSection = radio.closest(".trans-info-section");
                  if (infoSection.closest(".todo-edit-page").length) {
                    return;
                  }
                  const textCar = infoSection
                    .find(".text-car-selected")
                    .addClass("d-none");
                  const textAirplane = infoSection
                    .find(".text-airplane-selected")
                    .addClass("d-none");
                  if (radio.val() == "Car") {
                    textCar.removeClass("d-none");
                  } else if (radio.val() == "Airplane") {
                    textAirplane.removeClass("d-none");
                  }

                  if (radio.val() != "Other") {
                    infoSection
                      .find(`input[type=text][class*="transportation-other"]`)
                      .val("");
                  }
                });

              $(document)
                .off("keyup", `.trans-info-section .transportation-other`)
                .on("keyup", `.trans-info-section .transportation-other`, e => {
                  const txt: any = $(e.target);
                  const infoSection = txt.closest(".trans-info-section");
                  if ((txt?.val().trim() || "") != "") {
                    infoSection
                      .find("input[type=radio][value='Other']")
                      .click();
                  }
                });
            }
          };
        },
        addressBlock: (fieldData: any) => {
          const id = fieldData.id;
          const returnHTML: any = [];
          const userData = [];
          if (!userData.length) {
            // init
            userData.push({
              lAddressInput: "",
              lAddressInput2: "",
              lCityInput: "",
              lStateInput: "",
              lZipInput: ""
            });
          }
          for (const data of userData) {
            // saved data
            const lAddressInputVal = data.lAddressInput || "";
            const lAddressInput2Val = data.lAddressInput2 || "";
            const lCityInputVal = data.lCityInput || "";
            const lStateInputVal = data.lStateInput || "";
            const lZipInputVal = data.lZipInput || "";

            // ids
            const randomId = Math.floor(Math.random() * 10000000);
            const lAddressInput = `${id}-address-block-address-${randomId}`;
            const lAddressInput2 = `${id}-address-block-address2-${randomId}`;
            const lCityInput = `${id}-address-block-city-${randomId}`;
            const lStateInput = `${id}-address-block-state-${randomId}`;
            const lZipInput = `${id}-address-block-zip-${randomId}`;

            const requiredAttr = this.required
              ? 'required="required" aria-required="true"'
              : "";
            const requireTag = this.required
              ? '<span class="formbuilder-required">*</span>'
              : "";

            const stateOptions = ApiHelper.getStateOptions();
            const stateOptionsHTML = [`<option value="">Select...</option>`];

            for (const i in stateOptions) {
              stateOptionsHTML.push(`
              <option value="${stateOptions[i].text}"
                id="${lStateInput}-${i}"
                ${stateOptions[i].text == lStateInputVal ? "selected" : ""}
              >
                ${stateOptions[i].text}
              </option>
            `);
            }
            const formgroup = $(".todo-edit-page").length ? "" : "form-group";
            returnHTML.push(`
              <div class="address-block-info row">
                <div class="formbuilder-text ${formgroup} field-text-${lAddressInput} col-6">
                  <label for="${lAddressInput}" class="formbuilder-text-label field-label">Address 1${requireTag}</label>
                  <input type="text" value="${lAddressInputVal}" class="form-control" name="${lAddressInput}" inputmask="none" id="${lAddressInput}" ${requiredAttr} maxlength="255">
                </div>
                <div class="formbuilder-text ${formgroup} field-text-${lAddressInput2} col-6">
                  <label for="${lAddressInput2}" class="formbuilder-text-label field-label">Address 2</label>
                  <input type="text" value="${lAddressInput2Val}" class="form-control" name="${lAddressInput2}" inputmask="none" id="${lAddressInput2}" ${requiredAttr} maxlength="255">
                </div>
                <div class="formbuilder-text ${formgroup} field-text-${lCityInput} col-4">
                  <label for="${lCityInput}" class="formbuilder-text-label field-label">City${requireTag}</label>
                  <input type="text" value="${lCityInputVal}" class="form-control" name="${lCityInput}" inputmask="none" id="${lCityInput}" ${requiredAttr} maxlength="255">
                </div>
                <div class="formbuilder-select ${formgroup} field-select-${lStateInput} col-4">
                  <label for="${lStateInput}" class="formbuilder-select-label field-label">
                    State${requireTag}
                  </label>
                  <select class="form-control input__full" name="${lStateInput}" id="${lStateInput}" ${requiredAttr}>
                    ${stateOptionsHTML.join("")}
                  </select>
                </div>
                <div class="formbuilder-text ${formgroup} field-text-${lZipInput} col-4">
                  <label for="${lZipInput}" class="formbuilder-text-label field-label">Zip${requireTag}</label>
                  <input type="text" value="${lZipInputVal}" class="form-control" name="${lZipInput}" inputmask="zipnumber" maxlength="5" id="${lZipInput}" ${requiredAttr}>
                </div>
              </div>
            `);
          }

          return {
            field: `
              ${returnHTML.join(`<hr class="address-block-spliter" />`)}
            `,
            onRender: () => {
              $(`input[inputmask=zipnumber]`).each(function(i, obj) {
                Inputmask("99999", {
                  autoUnmask: true
                }).mask(obj);
              });
            }
          };
        }
      };

      hiddenRender.formRender({
        fields: fields0,
        templates,
        disableFields: ["signature", "dob"],
        formData: formRenderData
      });
      const html = hiddenRender.formRender("html");

      const presetFieldsData = this.pageData.presetFieldsData || [];
      const fields = this.pageData.presetFieldsData.map((item: any) => {
        const field = JSON.parse(item.eAppFieldParams || "[]");
        const fieldID = `preset-field-${item.eAppFieldId}`;
        // add more custom classes
        if (typeof field.className != "undefined") {
          if (field.className.indexOf("my-cf") == -1) {
            field.className += " my-cf";
          }
        } else {
          field.className = `form-control my-cf`;
        }

        // add field id into className
        if (field.className.indexOf(fieldID) == -1) {
          field.className += ` ${fieldID}`;
        }

        // tmp put fieldID to icon
        field.icon = item.eAppFieldId;
        // remove name
        field.name = "";

        return field;
      });

      fields.push({
        label: "Signature",
        attrs: {
          type: "signature"
        },
        icon: ""
      });

      // add field conditional options
      fields.push({
        label: "Conditional Options",
        attrs: {
          type: "conditionalOptions"
        },
        icon: ""
      });

      // add field medication
      fields.push({
        label: "Medication",
        attrs: {
          type: "medication"
        },
        icon: ""
      });

      // add allergies field
      fields.push({
        label: "Allergies",
        attrs: {
          type: "allergies"
        },
        icon: ""
      });

      // add transportation field
      fields.push({
        label: "Transportation",
        attrs: {
          type: "transportation"
        },
        icon: ""
      });

      // add transportation field
      fields.push({
        label: "Address Block",
        attrs: {
          type: "addressBlock"
        },
        icon: ""
      });

      const thisFormBuilder = devBuilder.formBuilder({
        fields,
        templates,
        formData: formRenderData,
        onSave: this.onSaveForm,
        actionButtons: this.selectedFormId
          ? [
              {
                id: "copy",
                className: "btn btn-success btn-duplicate",
                label: "Duplicate",
                type: "button",
                events: {
                  click: async (evt: any) => {
                    await duplicateForm(evt);
                  }
                }
              }
            ]
          : [],
        disabledActionButtons: ["data"],
        disabledAttrs: [
          "access",
          "className",
          "value",
          "placeholder",
          "description",
          "name"
        ],
        disableFields: ["autocomplete", "button", "hidden", "number", "dob"],
        disabledSubtypes: {
          button: ["reset", "submit"],
          paragraph: ["blockquote", "canvas", "output"],
          file: ["fineuploader"],
          text: ["color", "tel"],
          textarea: ["tinymce", "quill"],
          header: ["h1", "h3", "h4", "h5", "h6"]
        },
        editOnAdd: true,
        fieldRemoveWarn: true,
        replaceFields: [
          {
            type: "checkbox-group",
            label: "Checkbox",
            values: [{ label: "", value: "" }]
          },
          {
            type: "file",
            label: "Upload"
          },
          {
            type: "select",
            label: "Drop Down"
          },
          {
            type: "header",
            label: "Section Title",
            value: "Section Title"
          }
        ],
        scrollToFieldOnAdd: true,
        sortableControls: true,
        stickyControls: {
          enable: true
        },
        typeUserDisabledAttrs: {
          button: ["style"],
          "checkbox-group": ["toggle"],
          select: ["multiple"]
        },
        typeUserAttrs: {
          text: {
            inputmask: {
              label: "Mask",
              options: {
                none: "None",
                zipcode: "Zip Code",
                phonenumber: "Phone Number",
                number: "Number"
                // transportation: "Transportation"
                // date: "Date",
                // email: "Email"
              }
            }
          },
          file: {
            download: {
              label: "Template file",
              value: "",
              placeholder: "Link to S3 url"
            }
          },
          conditionalOptions: {
            explainLabel: {
              label: "Explain label",
              value: "Please explain",
              placeholder: "Explain label"
            },
            explainPlaceHolder: {
              label: "Explain placeholder",
              value: "Enter your answer",
              placeholder: "Explain placeholder"
            }
          },
          "checkbox-group": {
            multipleSelections: {
              type: "checkbox",
              label: "Multiple Selections",
              value: false
            }
          },
          transportation: {
            arrivingLabel: {
              label: "Arriving Label",
              value: "How will you be arriving to camp?",
              placeholder: ""
            },
            departingLabel: {
              label: "Departing Label",
              value: "How will you be departing from camp?",
              placeholder: ""
            }
          }
        },
        onOpenFieldEdit: function(editPanel: any) {
          const control = $(editPanel);
          control.find(".fld-download:not(.loaded)").each((index, item) => {
            const control = $(item);
            control.addClass("loaded");
            control.hide();
            const parent = control.parents(".input-wrap");
            const controlId = control.attr("id");
            parent.append(`<div class="preview-container">
            <div class="file-preview"></div>
          </div><input class="upload-s3" type="file" target="${controlId}" />`);
            if (control.val()) {
              const filePath = control.val() + "";
              parent
                .find(".file-preview")
                .html(
                  `<a target="_blank" href="${filePath}">${getPreview(
                    filePath
                  )}</a> <button target="${controlId}" class="ml-2 btn btn-sm btn-danger btn-remove-file">Remove</button>`
                );
            }
            parent.find(".upload-s3").on("change", (e: any) => {
              const files = e.target.files || [];
              for (const file of files) {
                if (file.size > 5 * 1024 * 1024) {
                  ApiHelper.showErrorMessage(
                    file.name + " is greater than 5MB. Please use another file",
                    "Oops"
                  );
                } else {
                  if (file) {
                    pageData.isLoading = true;
                    ApiHelper.uploadFile(file.name, file)
                      .then((result: any) => {
                        pageData.isLoading = false;
                        if (result.status === 1) {
                          const filePath = result.data.fileUrl;
                          control.val(filePath);
                          parent
                            .find(".file-preview")
                            .html(
                              `<a target="_blank" href="${filePath}">${getPreview(
                                filePath
                              )}</a> <button target="${controlId}" class="ml-2 btn btn-sm btn-danger btn-remove-file">Remove</button>`
                            );
                        } else {
                          ApiHelper.showErrorMessage(
                            JSON.stringify(result.message),
                            "Oops"
                          );
                        }
                      })
                      .catch((error: any) => {
                        pageData.isLoading = false;
                        ApiHelper.showErrorMessage(
                          JSON.stringify(error),
                          "Oops"
                        );
                      });
                  }
                }
              }
            });
          });
        }
      });

      let searchFieldTimeout: any = null;
      thisFormBuilder.promise.then(function() {
        $(document).on("click", ".btn-remove-file", e => {
          const button = $(e.target.outerHTML);
          const control = $("#" + button.attr("target"));
          const parent = control.parents(".input-wrap");
          Vue.swal({
            text: "Are you sure you want to remove this file?",
            showCancelButton: true,
            confirmButtonText: "Yes, do it!",
            showCloseButton: true,
            closeButtonHtml:
              '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
          }).then(result => {
            setTimeout(function() {
              $(".swal2-backdrop-hide").addClass("d-none");
            }, 200);
            if (result.isConfirmed) {
              control.val("");
              parent.find(".file-preview").html("");
            }
          });
        });
        /* $(".cb-wrap").addClass("edit").prepend(`
            <div class="tabs">
              <input name="tabs" type="radio" id="tab-1" checked="checked" class="input"/>
              <label for="tab-1" class="label first">
                Elements
              </label>
              <div class="panel panel-elements" id="newElements">
              </div>
              <input name="tabs" type="radio" id="tab-2" class="input"/>
              <label for="tab-2" class="label">
                Fields
              </label>
              <div class="panel panel-fields">
                <input type="text" placeholder="Type a field name..." class="search-fields-box form-control" />
                <div class="notFound d-none">
                  <div class="noData">No records found</div>
                </div>
              </div>
              <input name="tabs" type="radio" id="tab-3" class="input"/>
              <label for="tab-3" class="label last" style="margin-right: 0;">
                Code
              </label>
              <div class="panel panel-code">
                <textarea>
                </textarea>
              </div>
            </div>
          `); */

        $(".frmb-control").appendTo(".panel-elements");
        // $(".form-actions.btn-group").appendTo(".panel-elements");
        $("button.save-template")
          .addClass("mr-2 me-2")
          .appendTo(".app-buttons");
        $("button.clear-all").appendTo(".app-buttons");
        $(".cb-wrap").remove();
        $(".panel-code textarea").html(html);

        // preset fields
        $(".frmb-control")
          .clone(true)
          .addClass("d-flex flex-column")
          .appendTo(".panel-fields")
          .find("> li")
          .addClass("w-100");

        $(
          ".panel-fields ul.frmb-control li[class*='formbuilder-icon']"
        ).remove();
        $(".panel-fields ul.frmb-control li span:first-child").addClass(
          "my-cf-name"
        );
        $(".panel-fields ul.frmb-control li").each(function() {
          $(this).attr(
            "title",
            $(this)
              .find("span:first-child")
              .text()
          );

          const fieldID = $(this)
            .find(".control-icon")
            .html();
          $(this).attr("data-fieldid", fieldID);
          $(this)
            .find(".control-icon")
            .remove();

          // specify field type
          const type = $("<span class='my-cf-type'></span>");
          if (
            $(this)
              .data("type")
              .indexOf("radio-group-") != -1
          ) {
            type.html("Radio Group");
          } else if (
            $(this)
              .data("type")
              .indexOf("text-") != -1
          ) {
            type.html("Text");
          }

          // init in apps
          const inApps = $("<span class='my-cf-in-apps'>0</span>");
          const theField: any = presetFieldsData.find(
            (item: any) => item.eAppFieldId == fieldID
          );
          if (theField) {
            inApps.html(theField.inApps || 0);
          }
          $(this)
            .append(type)
            .append(inApps);
        });

        // apply search preset field function
        clearTimeout(searchFieldTimeout);
        $(".panel-fields .search-fields-box").keyup(function() {
          const key = $(this).val() + "";
          searchFieldTimeout = setTimeout(() => {
            $(".panel-fields ul.frmb-control li span.my-cf-name").each(
              function() {
                const li = $(this).closest("li");
                if (
                  $(this)
                    .text()
                    .toLowerCase()
                    .indexOf(key.toLowerCase()) != -1
                ) {
                  li.removeClass("hidden-cf");
                } else {
                  li.addClass("hidden-cf");
                }
              }
            );
            if (
              $(".panel-fields ul.frmb-control li.hidden-cf").length ==
              $(".panel-fields ul.frmb-control li").length
            ) {
              $(".panel-fields .notFound").removeClass("d-none");
            } else {
              $(".panel-fields .notFound").addClass("d-none");
            }
          }, 100);
        });

        // hover on in apps numbers
        $(".my-cf-in-apps")
          .mouseover(function() {
            if (
              $(this).html() == "0" ||
              $(this).find(".in-apps-content").length
            ) {
              return;
            }
            clearTimeout(loadInAppsContentTimeout);
            loadInAppsContentTimeout = setTimeout(async () => {
              // $(".in-apps-content").remove();
              const li = $(this).closest("li");
              const fieldId = li.data("fieldid");
              const apps = await loadInAppsContent(fieldId);
              const div = $("<div class='in-apps-content'></div>");
              for (const item of apps) {
                div.append($(`<div>${item.entityAppName || ""}</div>`));
              }
              $(this).append(div);
            }, 300);
          })
          .mouseout(function() {
            // $(".in-apps-content").remove();
          });

        // case: not allow select multiple for the checkbox group
        $(document).on("click", "input[name*=checkbox-group-]", (e: any) => {
          const cb = $(e.target);
          const multiple = cb.attr("multiple-selections") || "false";
          const cbId = cb.prop("id") || "";
          if (multiple == "false" && cbId != "") {
            cb.closest(".checkbox-group")
              .find(`input[type=checkbox]:not(#${cbId})`)
              .prop("checked", false);
          }
        });
      });
    }

    // apply custom for formbuilder
    // auto set value for options of checkbox-group/select/radio if user let it empty
    $(document).on(
      "blur",
      ".form-builder li[type=checkbox-group] .form-group.field-options input[type=text].option-label",
      e => {
        const optionLabel: any = $(e.target);
        const optionValue: any = optionLabel.next();
        if (optionValue.length && optionValue.val().trim() == "") {
          optionValue.val((optionLabel?.val() || "").trim() || "");
        }
      }
    );
    $(document).on(
      "blur",
      ".form-builder li[type=select] .form-group.field-options input[type=text].option-label",
      e => {
        const optionLabel: any = $(e.target);
        const optionValue: any = optionLabel.next();
        if (optionValue.length && optionValue.val().trim() == "") {
          optionValue.val((optionLabel?.val() || "").trim() || "");
        }
      }
    );
    $(document).on(
      "blur",
      ".form-builder li[type=radio-group] .form-group.field-options input[type=text].option-label",
      e => {
        const optionLabel: any = $(e.target);
        const optionValue: any = optionLabel.next();
        if (optionValue.length && optionValue.val().trim() == "") {
          optionValue.val((optionLabel?.val() || "").trim() || "");
        }
      }
    );

    $(document).on(
      "change",
      ".form-builder select[name=inputmask]",
      function() {
        const element = $(this).closest(".form-elements");
        const maxlength = element.find("input[name=maxlength]");
        const subType = element.find("select[name=subtype]");
        // default maxlength/subtype
        if ($(this).val() == "phonenumber") {
          maxlength.val(14).prop("disabled", true);
          subType.val("text").prop("disabled", true);
        } else if ($(this).val() == "zipcode") {
          maxlength.val(5).prop("disabled", true);
          subType.val("text").prop("disabled", true);
        } else {
          maxlength.prop("disabled", false);
          subType.prop("disabled", false);
        }
      }
    );
  }
});
