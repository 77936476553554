

























































































































































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import VScroller from "@/components/Common/VScroller.vue";
import Modal from "@/components/Common/Modal.vue";
import { FormSelect as SelectType } from "@/types";
import FormSelect from "@/components/Form/FormSelect.vue";
import { ApiHelper } from "@/helpers";

type option = {
  id: number;
  text: string;
  data: any;
};
export default defineComponent({
  name: "FormTags",
  components: {
    FormSelect,
    VScroller,
    Modal
  },
  props: {
    tags: {
      type: Array
    },
    data: Object,
    addTag: Function,
    addNew: Function,
    inputClass: String,
    typeUse: String,
    onRemoveTag: Function,
    onSuggestTags: Function,
    onSelectTag: Function,
    onAddTag: Function,
    onRemoveLastTag: Function,
    updateLastInput: Function,
    hasBorder: {
      type: Boolean,
      default: false
    },
    suggestOnFocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    emptySearchOnSelect: {
      type: Boolean,
      default: false
    },
    allowAddNew: {
      type: Boolean,
      default: false
    },
    allowAddColor: {
      type: Boolean,
      default: false
    },
    noneBackground: {
      type: Boolean,
      default: false
    },
    inputTimeoutSecond: {
      type: Number,
      default: 500
    },
    alwaysEmitInput: {
      type: Boolean,
      default: false
    },
    noRecordMessage: {
      type: String,
      default: "No Tags Found"
    },
    size: {
      type: String,
      default: ""
    },
    preventEnter: {
      type: Boolean,
      default: false
    },
    isNumber: {
      type: Boolean,
      default: false
    },
    endItemMessage: {
      type: String,
      default: ""
    },
    multiSelection: {
      type: Boolean,
      default: true
    },
    hideNoData: {
      type: Boolean,
      default: false
    },
    noTextDanger: {
      type: Boolean,
      default: false
    },
    addTopPopOverToolTip: {
      type: Boolean,
      default: false
    },
    addTopPopOver: {
      type: Boolean,
      default: false
    },
    addTopPopOverTag: {
      type: Boolean,
      default: false
    },
    addTopPopOverPtype: {
      type: Boolean,
      default: false
    },
    nowrap: {
      type: Boolean,
      default: false
    },
    isNormal: {
      type: Boolean,
      default: false
    },
    isACILoading: {
      type: Boolean,
      default: false
    },
    nowrapSuggestedItems: {
      type: Boolean,
      default: false
    },
    autoTop: {
      type: Boolean,
      default: false
    },
    hasQuestion: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const timer = ref<any>(null);
    const show = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const modalLoading = ref<boolean>(false);
    const showPopup = ref<boolean>(false);
    const hint = ref<string>("");
    const onSelect = (item: any) => {
      const pData = props.data || {};
      const dataValue = pData.key || "";
    };

    const onClickOutside = () => {
      show.value = false;
      hint.value = "";
    };

    const selectLineItem = () => {
      console.log("selectLineItem");
    };

    const onInput = () => {
      context.emit("input", props.data?.key || "");
      if (timer.value) {
        clearTimeout(timer.value);
      }
      timer.value = setTimeout(async () => {
        if (
          props.onSuggestTags &&
          props.data &&
          (props.data.key || props.alwaysEmitInput || props.data.key == "")
        ) {
          props.onSuggestTags(props.data.key);
        }

        show.value = true;
      }, props.inputTimeoutSecond);
    };

    const focusing = ref<boolean>(false);
    const onFocus = async () => {
      focusing.value = true;
      if (props.suggestOnFocus && props.data) {
        if (timer.value) {
          clearTimeout(timer.value);
        }
        show.value = false;
        hint.value = "";
        await context.emit("focus", props.data.key);
        if (focusing.value == true) {
          show.value = true;
        }
      }
    };
    const formType = ref<SelectType>({
      options: [],
      value: "",
      label: "Type",
      placeholder: "Select tag type",
      error: ""
    });
    const doAddTag = async (event: any) => {
      event.preventDefault();
      if (props.addNew) {
        await props.addNew(props.data?.key || "");
        return;
      }
      if (props.preventEnter) return;

      if (props.data && props.data.key) {
        formType.value.value = "";
        formType.value.error = "";
        if (formType.value.options.length === 0) {
          modalLoading.value = true;
          const result = await ApiHelper.callApi(
            "get",
            "/tags/types",
            {},
            {
              status: "1",
              typeUse: props.typeUse
            }
          );
          modalLoading.value = false;
          if (result.status === 1) {
            formType.value.options = result.data.types.map((item: any) => {
              return {
                id: item.typeId,
                text: item.typeName,
                data: item
              };
            });
          }
        }
        props.data.suggestTags = [];
        showPopup.value = true;
      }
    };

    const saveTag = async () => {
      if (!props.data) {
        return;
      }
      let hasError = false;
      if (formType.value.value == "") {
        hasError = true;
        formType.value.error = "Type is required.";
      }

      const tagName = props.data.key || "";
      if (!hasError) {
        modalLoading.value = true;
        const result = await ApiHelper.callApi(
          "put",
          "/tags/0",
          {
            name: tagName,
            type: formType.value.value
          },
          {}
        );
        modalLoading.value = false;
        if (result.status === 1) {
          const tagId = result.data.id || 0;
          const typeId = formType.value.value;
          const typeData = formType.value.options.find(
            (item: any) => item.id == typeId
          );
          const typeName = typeData ? typeData.text : "";
          props.data.value.push({
            id: result.data.id || 0,
            text: tagName,
            data: {
              tagId: tagId,
              tagName: tagName,
              typeId: typeId,
              typeName: typeName
            }
          });
          props.data.key = "";
          showPopup.value = false;
        } else {
          ApiHelper.showErrorMessage(result.message || `Can't save tag`);
        }
      }
    };

    // const showTagInputText = computed(() => {
    //   let ret = true;
    //   if (!props.multiSelection && (props.data?.value || []).length) {
    //     ret = false;
    //   }

    //   return ret;
    // });
    const disabledTagInputText = computed(() => {
      // case single selection
      let ret = false;
      if (!props.multiSelection && (props.data?.value || []).length) {
        ret = true;
      }

      return ret;
    });

    // init data
    (async () => {
      show.value = false;
    })();

    return {
      modalLoading,
      saveTag,
      formType,
      showPopup,
      onFocus,
      doAddTag,
      onInput,
      onClickOutside,
      show,
      onSelect,
      loading,
      hint,
      selectLineItem,
      // showTagInputText
      disabledTagInputText
    };
  }
});
