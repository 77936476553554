import { ref } from "@vue/composition-api";
import { FormPassword } from "@/types";

import { ApiHelper, ProfileHelper } from "@/helpers";
import Vue from "vue";

export function useProfileSettingsStore(context: any) {
  const selectedProfileId = parseInt(context.root.$route.params.profileId);
  const formInput = ref({
    password: "",
    confirmPassword: ""
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      createUser: {
        label?: string;
        error: string;
        type: string;
        value: string;
        yesValue: string;
        noValue: string;
        defaultValue: string;
        hideNoOption: boolean;
      };
      password: FormPassword;
      confirmPassword: FormPassword;
    };
  }>({
    isLoading: false,
    controls: {
      createUser: {
        label: "Allow admin permissions?",
        error: "",
        type: "radios",
        value: "no",
        yesValue: "yes",
        noValue: "no",
        defaultValue: "",
        hideNoOption: true
      },
      password: {
        required: true,
        error: "",
        style: "custom",
        mode: "password",
        label: "Password",
        placeholder: "Password",
        value: "",
        type: "password",
        valid: false
      },
      confirmPassword: {
        error: "",
        type: "password",
        value: "",
        mode: "password"
      }
    }
  });
  const isArchived = ref(false);

  const loadData = () => {
    (async () => {
      try {
        formData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "get",
          "/profiles/" + selectedProfileId,
          {},
          {}
        );
        if (result.status === 1) {
          const profileData = result.data || {};
          formData.value.controls.createUser.value =
            profileData.type == 1 || context.root.$route.query.init
              ? "yes"
              : "no";
          formData.value.controls.createUser.defaultValue =
            profileData.type == 1 ? "yes" : "no";

          // hide option to set a family onwer to non-admin
          formData.value.controls.createUser.hideNoOption =
            (profileData.isFamilyOwner || false) == true ? true : false;
        } else {
          ApiHelper.showErrorMessage(result.message, "Oops");
        }
      } catch (err) {
        console.log("loadData:err", err);
      } finally {
        formData.value.isLoading = false;
      }
    })();
  };

  const onSubmit = async (event: Event) => {
    event.preventDefault();

    formInput.value.password = formData.value.controls.password.value;
    formInput.value.confirmPassword = formData.value.controls.password.value;
    // formData.value.controls.confirmPassword.value;

    let hasError = false;
    if (formInput.value.password === "") {
      hasError = true;
      formData.value.controls.password.error = "Password is required!";
    } else {
      if (!formData.value.controls.password.valid) {
        hasError = true;
        formData.value.controls.password.error = "Password is invalid!";
      }
    }
    if (formInput.value.confirmPassword === "") {
      hasError = true;
      formData.value.controls.confirmPassword.error =
        "Confirm Password is required!";
    }
    if (
      formInput.value.password !== "" &&
      formInput.value.confirmPassword !== "" &&
      formInput.value.password !== formInput.value.confirmPassword
    ) {
      hasError = true;
      formData.value.controls.confirmPassword.error =
        "Confirm Password is not match!";
    }

    if (!hasError) {
      formData.value.isLoading = true;
      // const linkSubProfiles:

      const result = await ApiHelper.callApi(
        "put",
        "/profiles/" + selectedProfileId + "/settings",
        {
          type: 1,
          password: formInput.value.password,
          action: 1
        },
        {}
      );
      formData.value.isLoading = false;

      if (result.status == 1) {
        formData.value.controls.password.value = "";
        formData.value.controls.confirmPassword.value = "";
        if (context.root.$route.query.init) {
          ApiHelper.replaceRouter(context, {
            name: "ProfileSettings",
            params: {
              profileId: `${selectedProfileId}`
            }
          });
        }
        ApiHelper.showSuccessMessage("Password was updated");
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    }
  };

  const onSendResetLink = async () => {
    const isConfirmed = await Vue.swal({
      title: "Are you sure?",
      html:
        "An <strong>auto generated password</strong> will be sent to profile email!",
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then((result: any) => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      return result.isConfirmed;
    });
    if (!isConfirmed) {
      return false;
    }
    formData.value.isLoading = true;
    const result = await ApiHelper.callApi(
      "put",
      "/profiles/" + selectedProfileId + "/settings",
      {
        type: 1,
        password: "",
        action: 2
      },
      {}
    );
    formData.value.isLoading = false;
    if (result.status == 1) {
      if (context.root.$route.query.init) {
        ApiHelper.replaceRouter(context, {
          name: "ProfileSettings",
          params: {
            profileId: `${selectedProfileId}`
          }
        });
      }
      ApiHelper.showSuccessMessage(`Password was sent!`);
    } else {
      ApiHelper.showErrorMessage(result.message);
    }
  };

  const onSavePermission = async () => {
    formData.value.isLoading = true;
    const result = await ApiHelper.callApi(
      "put",
      "/profiles/" + selectedProfileId + "/settings",
      {
        type: 2,
        password: "",
        action: 1
      },
      {}
    );
    formData.value.isLoading = false;
    if (result.status == 1) {
      if (context.root.$route.query.init) {
        ApiHelper.replaceRouter(context, {
          name: "ProfileSettings",
          params: {
            profileId: `${selectedProfileId}`
          }
        });
      }
      ApiHelper.showSuccessMessage(`Saved permission`);
    } else {
      ApiHelper.showErrorMessage(result.message);
    }
  };

  const removeFieldError = (name: string) => {
    switch (name) {
      case "password":
        formData.value.controls.password.error = "";
        break;
      case "confirmPassword":
        formData.value.controls.confirmPassword.error = "";
        break;
    }
  };

  // init data
  (async () => {
    loadData();
    const profileInfo = await ProfileHelper.getProfileInfo(selectedProfileId);
    isArchived.value = profileInfo.status === 2;
  })();

  return {
    isArchived,
    formData,
    removeFieldError,
    onSubmit,
    onSendResetLink,
    onSavePermission
  };
}
