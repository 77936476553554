import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { PagerItem, SelectOption } from "@/types";
import { SettingsListRows } from "@/helpers/estimateNoOfListRows";

import FormSelect from "@/types/FormSelect";
import { v4 as uuidv4 } from "uuid";
import { getPtypeOptions } from "@/helpers/ApiHelper";

export function useSettingsApplicationsStore(context: any) {
  const isACILoading = ref<boolean>(false);
  const linkEvents = ref<any[]>([]);
  const headerData = ref({
    title: "Applications",
    subTitle: "Settings"
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      application: FormSelect;
      events: {
        label?: string;
        error: string;
        type: string;
        value: string[];
        options: SelectOption[];
      };
      participantTypes: {
        label?: string;
        error: string;
        type: string;
        value: string[];
        options: SelectOption[];
      };
    };
  }>({
    isLoading: true,
    controls: {
      application: {
        error: "",
        type: "select",
        label: "Application",
        placeholder: "Select application",
        value: "",
        options: []
      },
      events: {
        error: "",
        type: "select2",
        value: [],
        options: []
      },
      participantTypes: {
        error: "",
        type: "select2",
        value: [],
        options: []
      }
    }
  });

  const pageData = ref<{
    isLoading: boolean;
    openApplicationPortal?: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      name: string;
      events: SelectOption[];
      unassignedEvent: any;
      types: SelectOption[];
      unassignedType: any;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    foundApps: object[];
    endItemMessage: string;
    searchEventsValue: {
      value: string;
    };
    searchEvents: Function;
    searchEventsLoading: boolean;
    searchPtypesValue: {
      value: string;
    };
    searchPtypes: Function;
    searchPtypesLoading: boolean;
  }>({
    isLoading: true,
    skip: 0,
    take: SettingsListRows(),
    activeTab: "",
    sort: {
      order: context.root.$route.query.order || "1",
      direction: context.root.$route.query.direction || "1"
    },
    filter: {
      name: context.root.$route.query.name || "",
      events: [],
      unassignedEvent: null,
      types: [],
      unassignedType: null
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    foundApps: [],
    endItemMessage: "",
    searchEventsLoading: false,
    searchEventsValue: {
      value: ""
    },
    searchEvents: async (searchValue: string) => {
      pageData.value.searchEventsLoading = true;
      const eventIds = (context.root.$route.query.events || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1
        });
        pageData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS",
          selected: eventIds.includes(0)
        };
      } else {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue
        });
        if (eventIds.includes(0)) {
          pageData.value.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedEvent = null;
        }
      }
      pageData.value.filter.events = pageData.value.filter.events.map(
        (item: any) => ({
          ...item,
          selected: eventIds.includes(item.id)
        })
      );
      pageData.value.searchEventsLoading = false;
    },
    searchPtypesLoading: false,
    searchPtypesValue: {
      value: ""
    },
    searchPtypes: async (searchValue: string) => {
      pageData.value.searchPtypesLoading = true;
      const typeIds = (context.root.$route.query.types || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.types = await ApiHelper.getPtypeOptions({
          getAll: 1
        });
        pageData.value.filter.unassignedType = {
          id: "0",
          text: "UN-ASSIGNED TO PARTICIPANTS",
          selected: typeIds.includes(0)
        };
      } else {
        pageData.value.filter.types = await ApiHelper.getPtypeOptions({
          key: searchValue
        });
        if (typeIds.includes(0)) {
          pageData.value.filter.unassignedType = {
            id: "0",
            text: "UN-ASSIGNED TO PARTICIPANTS",
            selected: typeIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedType = null;
        }
      }
      pageData.value.filter.types = pageData.value.filter.types.map(
        (item: any) => ({
          ...item,
          selected: typeIds.includes(item.id)
        })
      );
      pageData.value.searchPtypesLoading = false;
    }
  });
  const campLink = ref("");

  const updateRouters = () => {
    const eventOptions = [];
    if (pageData.value.filter.unassignedEvent) {
      eventOptions.push(pageData.value.filter.unassignedEvent);
    }
    pageData.value.filter.events.map((item: any) => {
      eventOptions.push(item);
    });
    const typeOptions = [];
    if (pageData.value.filter.unassignedType) {
      typeOptions.push(pageData.value.filter.unassignedType);
    }
    pageData.value.filter.types.map((item: any) => {
      typeOptions.push(item);
    });
    context.root.$router
      .replace({
        name: "SettingsApplications",
        query: {
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          name: pageData.value.filter.name || undefined,
          events:
            ApiHelper.convertSelectedOptionsToString(eventOptions) || undefined,
          types:
            ApiHelper.convertSelectedOptionsToString(typeOptions) || undefined,
          searchEvents: pageData.value.searchEventsValue.value || undefined,
          searchPtypes: pageData.value.searchPtypesValue.value || undefined
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/applications",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          name: context.root.$route.query.name || undefined,
          events: context.root.$route.query.events || undefined,
          types: context.root.$route.query.types || undefined
        }
      );
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;

        const list = result.data.applications.map((item: any) => {
          return {
            ...item,
            // id: parseInt(item.id),
            isChecked: false,
            // name: item.name || "",
            jsonData: item.appJson || ""
            // events: item.events || [],
            // participantTypes: item.participantTypes || []
          };
        });

        pageData.value.items = list;

        campLink.value = result.data.portalUrl
          ? "//" + result.data.portalUrl
          : "";
      } else if (result.status === -1) {
        ApiHelper.gotoPage(context, {
          name: "Login",
          query: { destination: "/settings/applications" }
        });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops..");
      }
    })();
  };

  const loadData = (page: number) => {
    if (pageData.value.pager.page !== page) {
      pageData.value.pager.page = page;
      updateRouters();
    } else {
      loadList();
    }
  };
  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  // filters
  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
    if (pageData.value.activeTab == "events") {
      if (pageData.value.filter.events.length === 0) {
        await pageData.value.searchEvents(
          pageData.value.searchEventsValue.value
        );
      }
    }
    if (pageData.value.activeTab == "types") {
      if (pageData.value.filter.types.length === 0) {
        await pageData.value.searchPtypes(
          pageData.value.searchPtypesValue.value
        );
      }
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.name !== "") {
      filters.push({
        label: "NAME",
        key: pageData.value.filter.name + Math.random(),
        value: pageData.value.filter.name,
        reset: () => {
          pageData.value.filter.name = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedEvents: any[] = [];
    if (
      pageData.value.filter.unassignedEvent &&
      pageData.value.filter.unassignedEvent.selected
    ) {
      selectedEvents.push(pageData.value.filter.unassignedEvent.text);
    }
    pageData.value.filter.events
      .filter((item: any) => item.selected)
      .map((item: any) => {
        selectedEvents.push(item.text);
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.searchEventsValue.value = "";
          pageData.value.filter.events.map((item: any) => {
            item.selected = false;
          });
          if (pageData.value.filter.unassignedEvent) {
            pageData.value.filter.unassignedEvent.selected = false;
          }
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    const selectedTypes: any[] = [];
    if (
      pageData.value.filter.unassignedType &&
      pageData.value.filter.unassignedType.selected
    ) {
      selectedTypes.push(pageData.value.filter.unassignedType.text);
    }
    pageData.value.filter.types
      .filter((item: any) => item.selected)
      .map((item: any) => {
        selectedTypes.push(item.text);
      });
    if (selectedTypes.length > 0) {
      filters.push({
        label: "TYPE",
        key: selectedTypes.join("-") + Math.random(),
        value: selectedTypes.join(", "),
        reset: () => {
          pageData.value.searchPtypesValue.value = "";
          pageData.value.filter.types.map((item: any) => {
            item.selected = false;
          });
          if (pageData.value.filter.unassignedType) {
            pageData.value.filter.unassignedType.selected = false;
          }

          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const applyFilters = () => {
    loadData(1);
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "name":
        pageData.value.filter.name = value;
        break;
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const suggestApps = async (key: string, onFocus?: boolean) => {
    formData.value.controls.application.error = "";
    // reset
    // formData.value.foundPTypes = [];
    // item.participantTypeName = key;
    if (onFocus && key) {
      // ignore search
      return;
    }
    formData.value.controls.application.value = "";
    linkEvents.value = [];
    isACILoading.value = true;
    pageData.value.endItemMessage =
      key == ""
        ? "Top 5 application are listed, search to see more application"
        : "";
    try {
      const result = await ApiHelper.callApi(
        "get",
        "/applications/find",
        {},
        {
          key: key,
          take: key == "" ? 5 : 25
        }
      );
      if (result.status === 1) {
        pageData.value.foundApps = result.data.applications.map(
          (item: any) => ({
            id: item.id,
            text: item.name,
            data: item
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      isACILoading.value = false;
    }
  };

  const selectApp = async (data: any) => {
    formData.value.controls.events.error = "";
    formData.value.controls.application.error = "";
    formData.value.controls.application.value = data.id || "";

    const result = await ApiHelper.callApi(
      "get",
      "/applications/" + data.id + "/links",
      {},
      {}
    );
    const linkEventParticipants = result.data.links || [];
    linkEvents.value = linkEventParticipants.map((item: any) => {
      return {
        uuid: item.id.toString(), // uuidv4(),
        eventID: item.eventId,
        eventName: item.eventName,
        participantTypeID: item.participantTypeId,
        participantTypeName: item.participantTypeName,
        isDelete: item.isDelete
      };
    });
  };

  // init data
  (async () => {
    loadData(1);
    pageData.value.filter.name = context.root.$route.query.name || "";
  })();

  const removePType = async (pType: any) => {
    const newLines = linkEvents.value.map((item: any) => {
      const tmp = item;
      if (item.uuid == pType.uuid) {
        tmp.isDeleted = 1;
      }
      return tmp;
    });
    linkEvents.value = newLines;
  };

  const modalLinkEvent = ref<{
    foundEvents: object[];
    isProcessing: boolean;
    show: boolean;
    selectedAppName?: string;
    formData: any;
    onSubmit: any;
    suggestEvents: any;
    selectEvent: any;
    removePType: any;
  }>({
    foundEvents: [],
    isProcessing: false,
    show: false,
    formData: formData.value,
    onSubmit: async () => {
      const applicationId =
        parseInt(formData.value.controls.application.value) || 0;
      const selectedPTypeIDs = linkEvents.value.filter(
        (item: any) => item.participantTypeID != ""
      );
      // validate
      let hasError = false;
      let message = "";

      if (selectedPTypeIDs.length == 0) {
        hasError = true;
        message = "Participant types/events are required";
        formData.value.controls.events.error =
          "Participant types/events are required";
      }
      if (applicationId == 0) {
        hasError = true;
        message = "Please select an application";
        formData.value.controls.application.error =
          "Please select an application";
      }
      if (!hasError) {
        try {
          const result = await ApiHelper.callApi(
            "post",
            "/applications/linkToPtypes",
            {
              appId: parseInt(formData.value.controls.application.value),
              pTypes: selectedPTypeIDs.map((item: any) => ({
                eventId: item.eventID,
                eventName: item.eventName,
                participantTypeId: item.participantTypeID || 0,
                participantTypeName: item.participantTypeName,
                isDeleted: item.isDeleted || 0
              }))
            }
          );
          if (result.status == 1) {
            modalLinkEvent.value.show = false;
            linkEvents.value = [];
          } else {
            ApiHelper.showErrorMessage(result.message);
          }
        } catch (error) {
          ApiHelper.showErrorMessage(JSON.stringify(error));
        }
        loadData(1);
      } else if (selectedPTypeIDs.length == 0 && applicationId) {
        ApiHelper.showErrorMessage(message, "Oops");
      }
    },
    suggestEvents: async (key: string) => {
      modalLinkEvent.value.foundEvents = [];
      const selectedPTypeIDs = linkEvents.value
        .filter((item: any) => item.participantTypeID != "" && !item.isDeleted)
        .map((item: any) => {
          return item.eventID + "-" + item.participantTypeID;
        });
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/events/eventsAndPTypes",
        {},
        {
          appId: parseInt(formData.value.controls.application.value) || 0,
          key: key,
          notInPTypeIds: selectedPTypeIDs.join(",")
        }
      );
      if (result.status === 1) {
        const data = result.data || [];
        if (data.length) {
          modalLinkEvent.value.foundEvents = data.map((item: any) => ({
            id: item.LINK_Event_Participant,
            html: `${item.ev_name} - ${item.participant_typename}`,
            text: item.ev_name,
            data: item
          }));
        }
      }
      isACILoading.value = false;
    },
    selectEvent: (data: any) => {
      linkEvents.value.push({
        uuid: uuidv4(),
        eventID: data.eventID || "",
        eventName: data.ev_name || "",
        participantTypeID: data.participant_typeid || "",
        participantTypeName: data.participant_typename || ""
      });
    },
    removePType: async (pType: any) => {
      await removePType(pType);
    }
  });

  const removeApplication = async (item: any) => {
    if (item.events.length > 0) return;
    const confirm = await context.root.$swal({
      // title: "Are you sure?",
      html: "Are you sure you want to delete this application?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!"
    });
    if (!confirm.isConfirmed) {
      return false;
    }

    // remove application
    try {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "delete",
        "/applications/" + item.id
      );
      if (result.status == 1) {
        loadData(1);
        ApiHelper.showSuccessMessage(
          "Application has been deleted.",
          "Deleted"
        );
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  (async () => {
    pageData.value.searchEventsValue.value =
      context.root.$route.query.searchEvents || "";
    if (context.root.$route.query.events) {
      await pageData.value.searchEvents(pageData.value.searchEventsValue.value);
    }
  })();

  (async () => {
    pageData.value.searchPtypesValue.value =
      context.root.$route.query.searchPtypes || "";
    if (context.root.$route.query.types) {
      await pageData.value.searchPtypes(pageData.value.searchPtypesValue.value);
    }
  })();

  (async () => {
    await ApiHelper.synzUserViewLogs(context);
  })();

  (async () => {
    if (typeof pageData.value.openApplicationPortal == "undefined") {
      const configData = await ApiHelper.getEntityConfig(true);
      pageData.value.openApplicationPortal = configData.openApplicationPortal;
    }
  })();

  return {
    updateRouters,
    campLink,
    linkEvents,
    modalLinkEvent,
    // page
    headerData,
    pageData,
    // filters
    setActiveFilterTab,
    closeFilterTab,
    applyFilters,
    updateFilterValue,
    updateSortValue,
    // --- pager
    loadList,
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    suggestApps,
    selectApp,
    isACILoading,
    removeApplication
  };
}
