











import Vue from "vue";

export default Vue.extend({
  name: "ErrorMessage",
  props: {
    error: String
  }
});
