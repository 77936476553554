







































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import ProfilePanel from "@/components/Profile/ProfilePanel.vue";
import ProfilesTable from "@/components/Profile/ProfilesTable.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import HeaderProfiles from "@/components/Profile/HeaderProfiles.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import Modal from "@/components/Common/Modal.vue";
import { useProfilesStore } from "@/stores/Profile/ProfilesStore";
import TagInput from "@/components/Form/TagInput.vue";
import PopupMergeParticipants from "@/components/Participant/PopupMergeParticipants.vue";
import { ApiHelper } from "@/helpers";
import FormTags from "@/components/Form/FormTags.vue";
import VScroller from "@/components/Common/VScroller.vue";
export default defineComponent({
  name: "ProfilesPage",
  components: {
    FormTags,
    ProfilePanel,
    HeaderProfiles,
    Loading,
    Pager,
    Avatar,
    Select2,
    FilterSearchColumn,
    FilterAgesColumn,
    FilterSelectColumn,
    FilterNumberRangeColumn,
    Modal,
    ProfilesTable,
    TagInput,
    PopupMergeParticipants,
    VScroller
  },
  setup(props, context) {
    const {
      selectedItems,
      popupMergeParticipants,
      showMergeButton,
      mergeParticipants,
      updateLastInput,
      tagFilter,
      loadTags,
      loadList,
      updateRouters,
      // sort
      updateSortValue,
      profilePanel,
      pageData,
      showStatusDropdown,
      // filters
      selectItem,
      selectAllGenders,
      resetGenders,
      selectAllEvents,
      resetEvents,
      selectAllFamilies,
      resetFamilies,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onViewDetails,
      onClosePanel,
      onUpdateProfileAvatar,
      onClickPrev,
      onClickNext,
      onPagerChange,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      applyFilters,
      updateFilterValue,
      doDeleteSelectedItems,
      doTagSelectedItems,
      // popups
      familyTypeOptions,
      selectedProfiles,
      popupNewMessage,
      popupNewTribe,
      updateSelectedProfiles,
      showPopupArchives,
      popupTags,
      closePopupTags,
      popupArchives,
      closePopupArchives,
      doSubmitTagSelectedProfiles,
      toggleValue,
      pActiveSwitch,
      userSettings,
      updateProfilesCustomView,
      getUserSettings
    } = useProfilesStore(context);

    const foundTags = ref<any[]>([]);
    const tags = ref<any[]>([]);

    const mainProfile = ref({
      id: 0,
      firstName: "",
      lastName: ""
    });

    const onChangeMainProfile = async () => {
      const foundProfile = selectedProfiles.value.find((item: any) => {
        return (
          item.id + "" === popupNewTribe.value.formData.controls.profile.value
        );
      });
      if (foundProfile) {
        mainProfile.value.id = foundProfile.id;
        mainProfile.value.firstName = foundProfile.firstName;
        mainProfile.value.lastName = foundProfile.lastName;
      } else {
        mainProfile.value.id = 0;
        mainProfile.value.firstName = "";
        mainProfile.value.lastName = "";
      }
      if (mainProfile.value.id) {
        const otherProfileIds = selectedProfiles.value
          .filter((item: any) => item.id !== mainProfile.value.id)
          .map((item: any) => item.id);
        pageData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "get",
          "/profiles/" + mainProfile.value.id + "/relationtypes",
          {},
          {
            profileIds: otherProfileIds.join(",")
          }
        );
        pageData.value.isLoading = false;
        if (result.status === 1) {
          const relationTypes = result?.data.types || [];
          selectedProfiles.value = selectedProfiles.value.map((item: any) => {
            const tmp = item;
            const foundOldRelation = relationTypes.find((type: any) => {
              return item.id === type.profileID;
            });
            if (foundOldRelation) {
              let typeId = foundOldRelation.relationtypeID;
              if (typeId === 9) {
                typeId = 6;
              }
              tmp.type = typeId || "";
              tmp.typeError = "";
            } else {
              tmp.type = "";
              tmp.typeError = "";
            }
            return tmp;
          });
        }
        context.root.$forceUpdate();
      }
    };

    const onSuggestTags = async (key: string) => {
      popupTags.value.controls.tags.error = "";
      const notInIds = popupTags.value.controls.tags.value
        .map(item => item.id)
        .join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/tags/search",
        {},
        {
          key: key,
          notInIds: notInIds,
          typeUse: "profile"
        }
      );
      if (result?.status === 1) {
        popupTags.value.controls.tags.suggestTags = result.data.tags.map(
          (item: any) => {
            return {
              id: item.id,
              text: item.text,
              data: item
            };
          }
        );
      }
      popupTags.value.controls.tags.showNoData = true;
    };

    const onAddTag = () => {
      const key = popupTags.value.controls.tags.key.trim();
      popupTags.value.controls.tags.error = "";
      if (key) {
        popupTags.value.controls.tags.value.push({
          id: 0,
          text: key,
          data: {}
        });
        popupTags.value.controls.tags.key = "";
      }
      popupTags.value.controls.tags.showNoData = false;
    };

    const onRemoveLastTag = (event: any) => {
      if (
        popupTags.value.controls.tags.value.length > 0 &&
        popupTags.value.controls.tags.key === ""
      ) {
        const index = popupTags.value.controls.tags.value.length - 1;
        popupTags.value.controls.tags.value.splice(index, 1);
      }
      popupTags.value.controls.tags.showNoData = false;
    };
    const onRemoveTag = (index: number) => {
      if (popupTags.value.controls.tags.value.length > index) {
        popupTags.value.controls.tags.value.splice(index, 1);
      }
      popupTags.value.controls.tags.showNoData = false;
    };

    const onSelectTag = (item: any) => {
      popupTags.value.controls.tags.key = "";
      popupTags.value.controls.tags.showNoData = false;
      popupTags.value.controls.tags.suggestTags = [];
      popupTags.value.controls.tags.value.push({
        id: item.id,
        text: item.text,
        data: item.data || {}
      });
    };

    return {
      onAddTag,
      onSelectTag,
      onRemoveLastTag,
      onRemoveTag,
      onSuggestTags,
      mainProfile,
      onChangeMainProfile,
      selectedItems,
      popupMergeParticipants,
      showMergeButton,
      mergeParticipants,
      updateLastInput,
      tagFilter,
      tags,
      foundTags,
      // page
      loadTags,
      loadList,
      updateRouters,
      // sort
      updateSortValue,
      profilePanel,
      pageData,
      showStatusDropdown,
      // filters
      selectItem,
      selectAllGenders,
      resetGenders,
      selectAllEvents,
      resetEvents,
      selectAllFamilies,
      resetFamilies,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onViewDetails,
      onClosePanel,
      onUpdateProfileAvatar,
      onClickPrev,
      onClickNext,
      onPagerChange,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      applyFilters,
      updateFilterValue,
      doDeleteSelectedItems,
      doTagSelectedItems,
      // popups
      familyTypeOptions,
      selectedProfiles,
      popupNewMessage,
      popupNewTribe,
      updateSelectedProfiles,
      showPopupArchives,
      popupTags,
      closePopupTags,
      popupArchives,
      closePopupArchives,
      doSubmitTagSelectedProfiles,
      toggleValue,
      pActiveSwitch,
      userSettings,
      updateProfilesCustomView,
      getUserSettings
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    if (to.query.ref === "nav") {
      this.pageData.items.map(item => {
        item.isChecked = false;
        return item;
      });
      this.selectedItems = [];
      this.loadTags();
    }
    this.loadList();
  }
});
