
















































import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "DropdownParticipantStatus",
  props: {
    onChange: Function,
    item: Object,
    options: Array,
    addTopPopOverToolTip: {
      type: Boolean,
      default: false
    },
    hideStatusOptions: {
      type: Boolean,
      default: false
    },
    hidePercent: {
      type: Boolean,
      default: false
    },
    preventChange: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup(props, context) {
    const openItemId = ref(0);
    const onClickOutside = () => {
      if (props && props.item) {
        if (openItemId.value === props.item.id) {
          openItemId.value = 0;
        }
      }
    };
    const getDropdownClass = (option: any) => {
      const classes: any = {};
      classes[option.dropdownClass] = true;
      return classes;
    };
    const getLabelClass = (item: any) => {
      const classes: any = {};
      if (props.options) {
        props.options.map((option: any) => {
          classes[option.dropdownClass] = item.status === option.value;
        });
      }
      return classes;
    };
    const doChange = (item: any, option: any) => {
      if (props.onChange) {
        props.onChange(item, option, () => {
          item.status = option.value;
          openItemId.value = 0;
        });
      }
    };

    const toggleDropdown = () => {
      if (props.preventChange) return;

      openItemId.value = openItemId.value ? 0 : props.item?.id;
    };

    return {
      doChange,
      getLabelClass,
      getDropdownClass,
      openItemId,
      onClickOutside,
      toggleDropdown
    };
  }
});
