import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormText } from "@/types";

export function useRevenueSpendCategoryEditStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const headerData = ref({
    id: selectedId,
    title: selectedId > 0 ? "" : "Add Category",
    subTitle: "Revenue Spend Category"
  });

  const pageData = ref<{
    isLoading: boolean;
  }>({
    isLoading: true
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      categoryName: FormText;
    };
    validateAll: any;
    actions: {
      onSubmit: any;
    };
  }>({
    isLoading: true,
    controls: {
      categoryName: {
        required: true,
        label: "Category Name",
        placeholder: "Category Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 150
      }
    },
    validateAll: () => {
      let hasError = false;
      if (formData.value.controls.categoryName.value === "") {
        formData.value.controls.categoryName.error =
          "Category Name is required";
        hasError = true;
      }
      return hasError;
    },
    actions: {
      onSubmit: async () => {
        const hasError = formData.value.validateAll();
        if (hasError) {
          return;
        }
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/revenueSpendCategory/${selectedId}`,
          {
            categoryName: formData.value.controls.categoryName.value
          },
          {}
        );
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
        ApiHelper.showSuccessMessage(selectedId ? "Updated" : "Added");
        ApiHelper.gotoPage(context, {
          name: "RevenueSpendCategory"
        });
      }
    }
  });

  const loadData = async () => {
    if (selectedId > 0) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        `/accounting/revenueSpendCategory/${selectedId}`,
        {},
        {}
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const resultData = result.data || null;
        if (resultData) {
          headerData.value.title = resultData.revenueCategoryName || "";
          formData.value.controls.categoryName.value =
            resultData.revenueCategoryName || "";
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "RevenueSpendCategory" });
      }
    } else {
      ApiHelper.setDataLoading(false);
    }
  };

  // init data
  (async () => {
    loadData();
  })();

  return {
    loadData,
    headerData,
    pageData,
    formData
  };
}
