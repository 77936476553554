
















































































































































































































































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import Header from "@/components/Common/Header.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import Modal from "@/components/Common/Modal.vue";
import { useSettingsParticipantFormsStore } from "@/stores/Settings/ParticipantForms/SettingsParticipantFormsStore";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormButton from "@/components/Form/FormButton.vue";
import VScroller from "@/components/Common/VScroller.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
export default defineComponent({
  name: "SettingsParticipantFormsPage",
  components: {
    TableHeaderColumn,
    Header,
    VAlertMessage,
    SidebarEventDetails,
    HeaderEventDetails,
    Loading,
    Modal,
    Pager,
    Avatar,
    Select2,
    FormSelect,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    FilterSelectColumn,
    FilterAgesColumn,
    FilterDateColumn,
    FormButton,
    VScroller,
    AutoCompleteInput
  },
  setup(props, context) {
    const {
      updateRouters,
      formData,
      linkEvents,
      modalLinkEvent,
      popupNewItem,
      suggestApps,
      selectApp,
      // sort
      updateSortValue,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab,
      isACILoading,
      removeForm
    } = useSettingsParticipantFormsStore(context);
    const openAttachPopup = () => {
      modalLinkEvent.value.show = !modalLinkEvent.value.show;
      linkEvents.value = [];
      formData.value.controls.form.value = "";
      formData.value.controls.form.error = "";
      formData.value.controls.events.error = "";
      context.root.$forceUpdate();
    };
    const resetEvents = async () => {
      pageData.value.filter.events = [];
      if (pageData.value.filter.unassignedEvent) {
        pageData.value.filter.unassignedEvent.selected = false;
      }
      updateRouters();
    };
    return {
      updateRouters,
      formData,
      openAttachPopup,
      linkEvents,
      modalLinkEvent,
      popupNewItem,
      suggestApps,
      selectApp,
      // sort
      updateSortValue,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab,
      isACILoading,
      removeForm,
      resetEvents
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
