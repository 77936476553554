


























































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Header from "@/components/Common/Header.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import Modal from "@/components/Common/Modal.vue";
import FormPhoto from "@/components/Form/FormPhoto.vue";
import $ from "jquery";
import { useProfileTodosStore } from "@/stores/Profile/ProfileTodosStore";
import VScroller from "@/components/Common/VScroller.vue";
import SidebarProfileEvents from "@/components/Profile/SidebarProfileEvents.vue";
import TransferModal from "@/components/Financial/TransferModal.vue";
import Vue from "vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import { ParticipantStatus, TodoStatus } from "@/helpers/ApiHelper";
import Inputmask from "inputmask";
import {
  registerAllergies,
  registerMedication,
  registerTransportation,
  registerAddressBlock
} from "@/helpers/FbControls";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import NavProfileEvents from "@/components/Profile/NavProfileEvents.vue";
import NavProfileDetails from "@/components/Profile/NavProfileDetails.vue";
import SignaturePad from "signature_pad";
import moment from "moment";
require("formBuilder/dist/form-render.min.js");
declare const window: any;

export default defineComponent({
  name: "ProfileTodos",
  components: {
    FormSelect,
    Header,
    Loading,
    Pager,
    TableHeaderColumn,
    Modal,
    FormPhoto,
    VScroller,
    SidebarProfileEvents,
    TransferModal,
    HeaderProfileDetails,
    NavProfileEvents,
    NavProfileDetails
  },
  setup(props, context) {
    const selectedProfileId = parseInt(context.root.$route.params.profileId);
    const {
      formData,
      sidebarData,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData
    } = useProfileTodosStore(context);
    const showPopup = ref(false);
    const popupTitle = ref("");
    const selectedIndex = ref(-1);
    const allowSave = ref(true);

    const updateFilePreview = (fileSelector: any) => {
      // const fileName = fileSelector.attr('file-name') || '';
      // const filePath = fileSelector.attr('file-path') || '';
      const id = fileSelector.prop("id") || "";
      let showThumbnail = true;
      let isPrescription = false;
      if (id.indexOf("attach-prescription") != -1) {
        showThumbnail = false;
        isPrescription = true;
      }

      const files = JSON.parse(fileSelector.attr("files") || "[]") || [];
      let previewHtml = "";
      for (const file of files) {
        const fileName = file.name || "";
        const filePath = file.path;
        const ext = ApiHelper.getFileExt(fileName).toLowerCase();
        if ((ext == "jpg" || ext == "png" || ext == "gif") && showThumbnail) {
          previewHtml += `<li><a href="${filePath}" target="_blank"><img src="${filePath}" alt="${fileName}" /></a> <a href="javascript:void(0)" class="delete-file" data-file="${fileName}">Delete</a></li>`;
        } else {
          previewHtml += `<li><a href="${filePath}" target="_blank">${fileName}</a> <a href="javascript:void(0)"  class="delete-file" data-file="${fileName}">Delete</a></li>`;
        }
      }
      if (fileSelector.parent().find(".file-preview").length > 0) {
        fileSelector
          .parent()
          .find(".file-preview ul")
          .html(previewHtml);
      } else {
        fileSelector
          .parent()
          .append(
            '<div class="file-preview"><ul>' + previewHtml + "</ul></div>"
          );
      }
    };

    const updateFileDownload = (fileSelector: any) => {
      const downloadUrl = fileSelector.attr("download") || "";
      if (downloadUrl) {
        const fileName = ApiHelper.getFileName(downloadUrl);
        const label = fileSelector.parent().find(".formbuilder-file-label");
        label.append(
          `<span class="pl-3"><a class="download-link" target="_blank" download href="${downloadUrl}">Download <strong>${fileName}</strong></a></span>`
        );
      }
    };

    const getFormValues = async (item: any) => {
      const renderDiv: any = $("#previewFormBuilder");
      const formContent = JSON.parse(item.formContent);
      const fileFields = formContent.filter(
        (content: any) => content.type === "file"
      );
      const formData: any = {};

      for (const fileField of fileFields) {
        const $input: HTMLInputElement = renderDiv.find(
          `input#${fileField.name}`
        ) as any;

        const files = ($input as any)[0].files || [];
        if (files.length === 0) {
          const existData = {
            files: renderDiv.find(`input#${fileField.name}`).attr("files"),
            filePath: renderDiv
              .find(`input#${fileField.name}`)
              .attr("file-path"),
            fileName: renderDiv
              .find(`input#${fileField.name}`)
              .attr("file-name")
          };
          // set to existing value
          formData[fileField.name] = existData;
          continue;
        }
      }
      const formArray: any = item.formRenderInstance.userData;
      const formControls = formArray.map((field: any) => {
        const tmp = field;
        const id = tmp.name || "";
        if (tmp.type === "file") {
          const foundControl = formData[field.name] || {};
          tmp.files = foundControl.files || undefined;
          tmp.fileName = foundControl.fileName || undefined;
          tmp.filePath = foundControl.filePath || undefined;

          const fileControl = formContent.find((control: any) => {
            return control.name == id;
          });
          if (fileControl) {
            fileControl.files = foundControl.files || undefined;
            fileControl.fileName = foundControl.fileName || undefined;
            fileControl.filePath = foundControl.filePath || undefined;
          }
        }

        // fix saving for checkbox group
        if (tmp.type == "checkbox-group") {
          const userData = tmp.userData || [];
          if (userData.length) {
            for (const val of tmp.values) {
              if (userData.includes(val.value)) {
                val.selected = true;
              } else {
                val.selected = false;
              }
            }
          }
          const other = $(`#${id}-other`);
          const otherValue: any = $(`#${id}-other-value`);
          if (other.length && other.prop("checked") && otherValue.length) {
            tmp.selectedOther = true;
            tmp.otherValue = otherValue.val().trim();
          } else {
            delete tmp.selectedOther;
            delete tmp.otherValue;
          }
        }

        // collect userData for medication element
        if (tmp.type == "medication") {
          const noneOption = $(`#${id}-none-option`);
          const noneOptionChecked = noneOption.prop("checked") == true;
          tmp.noneOptionChecked = noneOptionChecked;
          tmp.userData = [];
          $(`.field-${id} .medication-info`).each((i, obj) => {
            const medicationInfo: any = $(obj);
            let medicalId = medicationInfo.data("medical-id") || 0;
            // Handle using data rewind
            if (item.isRecycled === 2) {
              medicalId = 0;
            }
            const medicationName =
              medicationInfo
                .find(`input[type=text][id*="medication-name"]`)
                .val()
                .trim() || "";
            // const medicationRoute =
            //   medicationInfo.find(`select[id*="medication-route"]`).val() || "";
            // const frequency =
            //   medicationInfo.find(`select[id*="frequency"]`).val() || "";
            // const notes =
            //   medicationInfo
            //     .find(`input[type=text][id*="-notes"]`)
            //     .val()
            //     .trim() || "";
            const medicationStrength =
              medicationInfo
                .find(`input[type=text][id*="medication-strength"]`)
                .val()
                .trim() || "";
            const dispenseMethod =
              medicationInfo
                .find(`select[id*="medication-dispense-method"]`)
                .val() || "";
            const dosagesVal = medicationInfo
              .find(`.medication-dosages-cb input[type=checkbox]:checked`)
              .map((j: number, e: any) => $(e).val())
              .toArray();
            let dosageOther = "";
            if (dosagesVal.includes("6")) {
              dosageOther =
                medicationInfo
                  .find(`input[type=text][id*="medication-other-dosage"]`)
                  .val()
                  .trim() || "";
            }
            const specialInstructions =
              medicationInfo
                .find(`textarea[id*="medication-instructions"]`)
                .val()
                .trim() || "";
            const prescribingDoctor =
              medicationInfo
                .find(`input[type=text][id*="prescribing-doctor"]`)
                .val()
                .trim() || "";
            const prescriptionObj =
              medicationInfo
                .find(`input[type=file][id*="attach-prescription"]`)
                .attr("files") || "[]";
            const files = JSON.parse(prescriptionObj);
            let prescriptionName = "";
            let prescription = "";
            if (files.length) {
              prescriptionName = files[0].name || "";
              prescription = files[0].path || "";
            }

            tmp.userData.push({
              medicalId,
              medicationName,
              // medicationRoute,
              // frequency,
              // notes,
              medicationStrength,
              dispenseMethod,
              dosages: dosagesVal.join(","),
              dosageOther,
              specialInstructions,
              prescribingDoctor,
              prescriptionName,
              prescription
            });
          });

          // if checked N/A option
          const deletedIds =
            $(`.field-${id}`).attr("data-deleted-medicalids") || "";
          let currentMedicalId = [];
          if (noneOptionChecked) {
            currentMedicalId = tmp.userData
              .filter((t: any) => t.medicalId > 0)
              .map((t: any) => t.medicalId);
            tmp.userData = [];
          }

          // append deletedIds
          tmp.deletedIds = [
            ...deletedIds.split(",").filter(id => id != ""),
            ...currentMedicalId
          ].join(",");
        }

        // collect userData for allergies
        if (tmp.type == "allergies") {
          const noneOption = renderDiv.find(`#allergies-${id}-none-option`);
          const noneOptionChecked = noneOption.prop("checked") == true;
          tmp.noneOptionChecked = noneOptionChecked;
          tmp.userData = [];
          renderDiv
            .find(`.field-${id} .allergies-info`)
            .each((i: number, obj: any) => {
              const info: any = $(obj);
              let allergiesId = info.data("allergies-id") || 0;
              // Handle using data rewind
              if (item.isRecycled === 2) {
                allergiesId = 0;
              }
              const allergiesName =
                info
                  .find(`input[type=text][id*="allergies-name"]`)
                  .val()
                  .trim() || "";
              const allergiesCategory =
                info.find(`select[id*="allergies-category"]`).val() || "";
              const allergiesSeverity =
                info.find(`select[id*="allergies-severity"]`).val() || "";

              tmp.userData.push({
                allergiesId,
                allergiesName,
                allergiesCategory,
                allergiesSeverity
              });
            });

          // if checked N/A option
          const deletedIds =
            renderDiv.find(`.field-${id}`).attr("data-deleted-allergiesids") ||
            "";
          let currentAllergiesId = [];
          if (noneOptionChecked) {
            currentAllergiesId = tmp.userData
              .filter((t: any) => t.allergiesId > 0)
              .map((t: any) => t.allergiesId);
            tmp.userData = [];
          }

          // append deletedIds
          tmp.deletedIds = [
            ...deletedIds.split(",").filter((id: string) => id != ""),
            ...currentAllergiesId
          ].join(",");
        }

        // collect userData for transportation
        if (tmp.type == "transportation") {
          tmp.userData = {
            arriving: {
              value: "",
              whoDrivingYou: "",
              airlines: "",
              flightNumber: "",
              dateTime: "",
              other: ""
            },
            departing: {
              value: "",
              whoDrivingYou: "",
              airlines: "",
              flightNumber: "",
              dateTime: "",
              other: ""
            }
          };
          const field = renderDiv.find(`.field-${id}`);
          // arriving
          const arriving = field.find(".arrival-section");
          const selectedArriving = arriving.find("input:checked")?.val() || "";
          tmp.userData.arriving.value = selectedArriving;
          tmp.userData.arriving.whoDrivingYou =
            selectedArriving == "Car"
              ? arriving
                  .find(`input[type=text][id*="-car-selected"]`)
                  .val()
                  .trim() || ""
              : "";
          tmp.userData.arriving.airlines =
            selectedArriving == "Airplane"
              ? arriving
                  .find(`input[type=text][id*="-airplane-selected-airlines"]`)
                  .val()
                  .trim() || ""
              : "";
          tmp.userData.arriving.flightNumber =
            selectedArriving == "Airplane"
              ? arriving
                  .find(
                    `input[type=text][id*="-airplane-selected-flightNumber"]`
                  )
                  .val()
                  .trim() || ""
              : "";
          tmp.userData.arriving.dateTime =
            selectedArriving == "Airplane"
              ? arriving
                  .find(`input[type=text][id*="-airplane-selected-dateTime"]`)
                  .val()
                  .trim() || ""
              : "";
          tmp.userData.arriving.other =
            selectedArriving == "Other"
              ? arriving
                  .find(`input[type=text][class*="transportation-other"]`)
                  .val()
                  .trim() || ""
              : "";

          // departing
          const departing = field.find(".departure-section");
          const selectedDeparting =
            departing.find("input:checked")?.val() || "";
          tmp.userData.departing.value = selectedDeparting;
          tmp.userData.departing.whoDrivingYou =
            selectedDeparting == "Car"
              ? departing
                  .find(`input[type=text][id*="-car-selected"]`)
                  .val()
                  .trim() || ""
              : "";
          tmp.userData.departing.airlines =
            selectedDeparting == "Airplane"
              ? departing
                  .find(`input[type=text][id*="-airplane-selected-airlines"]`)
                  .val()
                  .trim() || ""
              : "";
          tmp.userData.departing.flightNumber =
            selectedDeparting == "Airplane"
              ? departing
                  .find(
                    `input[type=text][id*="-airplane-selected-flightNumber"]`
                  )
                  .val()
                  .trim() || ""
              : "";
          tmp.userData.departing.dateTime =
            selectedDeparting == "Airplane"
              ? departing
                  .find(`input[type=text][id*="-airplane-selected-dateTime"]`)
                  .val()
                  .trim() || ""
              : "";
          tmp.userData.departing.other =
            selectedDeparting == "Other"
              ? departing
                  .find(`input[type=text][class*="transportation-other"]`)
                  .val()
                  .trim() || ""
              : "";
        }

        // collect userData for addressBlock
        if (tmp.type == "addressBlock") {
          tmp.userData = [];
          $(`.field-${id} .address-block-info`).each((i, obj) => {
            const addressBlockInfo: any = $(obj);
            const lAddressInput =
              addressBlockInfo
                .find(`input[type=text][id*="address-block-address"]`)
                .val()
                .trim() || "";
            const lAddressInput2 =
              addressBlockInfo
                .find(`input[type=text][id*="address-block-address2"]`)
                .val()
                .trim() || "";
            const lStateInput =
              addressBlockInfo
                .find(`select[id*="address-block-state"]`)
                .val() || "";
            const lCityInput =
              addressBlockInfo
                .find(`input[type=text][id*="address-block-city"]`)
                .val()
                .trim() || "";
            const lZipInput =
              addressBlockInfo
                .find(`input[type=text][id*="address-block-zip"]`)
                .val()
                .trim() || "";

            tmp.userData.push({
              lAddressInput,
              lAddressInput2,
              lCityInput,
              lStateInput,
              lZipInput
            });
          });
        }

        if (tmp.type === "signature") {
          const divCanvas = $("canvas#" + field.name);
          const signatureData = divCanvas.attr("data-signature") || "";
          const signatureNew = divCanvas.attr("data-new") || "";
          if (signatureNew === "1") {
            // const result = await ApiHelper.apiPost('/uploadFileFromBase64', {
            //   uuid: ApiHelper.getUuid(),
            //   domain: ApiHelper.getDomain(),
            //   base64: signatureData,
            //   group: "profiles/" + item.profileID + '/signatures',
            //   id: item.profileID,
            //   data: {
            //     fileName: ''
            //   }
            // });
            // if (result.status === 1) {
            //   tmp.signature = result.data.url;
            //   divCanvas.attr('data-new', '');
            // } else {
            //   tmp.signature = signatureData;
            // }
          } else {
            tmp.signature = signatureData;
          }
        }

        return tmp;
      });

      return formControls;
    };

    const getPercent = (formItem: any) => {
      const ret = {
        questionsNeeded: 0,
        questionsDone: 0,
        percentComplete: 0
      };
      const data = formItem.formRenderInstance.userData;
      if (data.length) {
        const requires = data.filter(
          (item: any) =>
            item.required &&
            (item.name || "").indexOf("explain-conditionalOptions-") == -1
        );
        ret.questionsNeeded = requires.length;
        for (const item of requires) {
          const id = item.name || "";
          if (
            ["text", "date", "textarea"].includes(item.type) &&
            typeof (item.userData || [""])[0] == "string" &&
            (item.userData || [""])[0] != ""
          ) {
            ret.questionsDone += 1;
          }
          if (
            ["select", "checkbox-group", "radio-group"].includes(item.type) &&
            typeof item.userData != "undefined"
          ) {
            ret.questionsDone += 1;
          }
          if (item.type === "file") {
            try {
              const files = JSON.parse(
                $("#previewFormBuilder")
                  .find("#" + item.name)
                  .attr("files") || "[]"
              );
              if (files.length > 0) {
                ret.questionsDone += 1;
              }
            } catch (e) {
              // nothing
            }
          }

          // validate for conditional options item
          if (
            item.type == "conditionalOptions" &&
            (item.userData || []).length
          ) {
            const itemVal = item.userData[0] || "";
            if (itemVal == "Yes") {
              // check "please explain" textbox
              const pleaseExplanTxt = data.find(
                (t: any) => t.type == "text" && t.name == `explain-${item.name}`
              );
              const val = pleaseExplanTxt?.userData[0] || "";
              if (val != "") {
                ret.questionsDone += 1;
              }
            } else if (itemVal == "No") {
              ret.questionsDone += 1;
            }
          }

          // validate for medication
          if (item.type == "medication") {
            const noneOption = $(`#${id}-none-option`);
            const noneOptionChecked = noneOption.prop("checked") == true;
            if (noneOptionChecked) {
              ret.questionsDone += 1;
              continue;
            }

            let invalidMedication = false;
            $(`.field-${id} .medication-info`).each((i, obj) => {
              const medicationInfo: any = $(obj);
              const medicationName =
                medicationInfo
                  .find("input[type=text][id*=medication-name]")
                  .val()
                  .trim() || "";
              const dispenseMethod =
                medicationInfo
                  .find(`select[id*="medication-dispense-method"]`)
                  .val() || "";
              const dosagesVal = medicationInfo
                .find(`.medication-dosages-cb input[type=checkbox]:checked`)
                .map((j: number, e: any) => $(e).val())
                .toArray();
              let dosageOther = "";
              if (dosagesVal.includes("6")) {
                dosageOther =
                  medicationInfo
                    .find(`input[type=text][id*="medication-other-dosage"]`)
                    .val()
                    .trim() || "";
              }
              if (dispenseMethod == "") {
                invalidMedication = true;
              }
              if (
                !dosagesVal.length ||
                (dosagesVal.includes("6") && dosageOther == "")
              ) {
                invalidMedication = true;
              }
              // const medicationRoute =
              //   medicationInfo
              //     .find("select[id*=medication-route]")
              //     .val()
              //     .trim() || "";
              // const frequency =
              //   medicationInfo
              //     .find("select[id*=frequency]")
              //     .val()
              //     .trim() || "";
              const prescribingDoctor =
                medicationInfo
                  .find(`input[type=text][id*="prescribing-doctor"]`)
                  .val()
                  .trim() || "";

              if (medicationName == "") {
                invalidMedication = true;
              }
              // if (medicationRoute == "") {
              //   invalidMedication = true;
              // }
              // if (frequency == "") {
              //   invalidMedication = true;
              // }
              if (prescribingDoctor == "") {
                invalidMedication = true;
              }
            });
            if (!invalidMedication) {
              ret.questionsDone += 1;
            }
          }

          // validate for allergies
          if (item.type == "allergies") {
            const noneOption = $(`#allergies-${id}-none-option`);
            const noneOptionChecked = noneOption.prop("checked") == true;
            if (noneOptionChecked) {
              ret.questionsDone += 1;
              continue;
            }

            let invalid = false;
            $(`.field-${id} .allergies-info`).each((i, obj) => {
              const info: any = $(obj);
              const allergiesName =
                info
                  .find("input[type=text][id*=allergies-name]")
                  .val()
                  .trim() || "";
              const allergiesCategory =
                info
                  .find("select[id*=allergies-category]")
                  .val()
                  .trim() || "";
              const allergiesSeverity =
                info
                  .find("select[id*=allergies-severity]")
                  .val()
                  .trim() || "";
              if (
                allergiesName == "" ||
                allergiesCategory == "" ||
                (allergiesCategory == "Food" && allergiesSeverity == "")
              ) {
                invalid = true;
              }
            });
            if (!invalid) {
              ret.questionsDone += 1;
            }
          }

          // validate for transportation
          if (item.type == "transportation") {
            const field = $(`.field-${id}`);
            let invalid = false;
            // arriving
            const arriving: any = field.find(".arrival-section");
            const selectedArriving =
              arriving.find("input:checked")?.val() || "";
            if (selectedArriving != "") {
              if (selectedArriving == "Car") {
                const whoDrivingYou =
                  arriving
                    .find(`input[type=text][id*="-car-selected"]`)
                    .val()
                    .trim() || "";
                if (whoDrivingYou == "") {
                  invalid = true;
                }
              } else if (selectedArriving == "Airplane") {
                const airlines =
                  arriving
                    .find(`input[type=text][id*="-airplane-selected-airlines"]`)
                    .val()
                    .trim() || "";
                const flightNumber =
                  arriving
                    .find(
                      `input[type=text][id*="-airplane-selected-flightNumber"]`
                    )
                    .val()
                    .trim() || "";
                const dateTime =
                  arriving
                    .find(`input[type=text][id*="-airplane-selected-dateTime"]`)
                    .val()
                    .trim() || "";
                if (airlines == "" || flightNumber == "" || dateTime == "") {
                  invalid = true;
                }
              }
            } else {
              invalid = true;
            }

            // departing
            const departing: any = field.find(".departure-section");
            const selectedDeparting =
              departing.find("input:checked")?.val() || "";
            if (selectedDeparting != "") {
              if (selectedDeparting == "Car") {
                const whoDrivingYou =
                  departing
                    .find(`input[type=text][id*="-car-selected"]`)
                    .val()
                    .trim() || "";
                if (whoDrivingYou == "") {
                  invalid = true;
                }
              } else if (selectedDeparting == "Airplane") {
                const airlines =
                  departing
                    .find(`input[type=text][id*="-airplane-selected-airlines"]`)
                    .val()
                    .trim() || "";
                const flightNumber =
                  departing
                    .find(
                      `input[type=text][id*="-airplane-selected-flightNumber"]`
                    )
                    .val()
                    .trim() || "";
                const dateTime =
                  departing
                    .find(`input[type=text][id*="-airplane-selected-dateTime"]`)
                    .val()
                    .trim() || "";
                if (airlines == "" || flightNumber == "" || dateTime == "") {
                  invalid = true;
                }
              }
            } else {
              invalid = true;
            }

            if (!invalid) {
              ret.questionsDone += 1;
            }
          }

          // // validate for address block
          if (item.type == "addressBlock") {
            let invalidAddressBlock = false;
            $(`.field-${id} .address-block-info`).each((i, obj) => {
              const addressBlockInfo: any = $(obj);
              const lAddressInput =
                addressBlockInfo
                  .find(`input[type=text][id*="address-block-address"]`)
                  .val()
                  .trim() || "";
              const lStateInput =
                addressBlockInfo
                  .find(`select[id*="address-block-state"]`)
                  .val() || "";
              const lCityInput =
                addressBlockInfo
                  .find(`input[type=text][id*="address-block-city"]`)
                  .val()
                  .trim() || "";
              const lZipInput =
                addressBlockInfo
                  .find(`input[type=text][id*="address-block-zip"]`)
                  .val()
                  .trim() || "";

              if (lAddressInput == "") {
                invalidAddressBlock = true;
              }
              if (lStateInput == "") {
                invalidAddressBlock = true;
              }
              if (lCityInput == "") {
                invalidAddressBlock = true;
              }
              if (lZipInput == "") {
                invalidAddressBlock = true;
              }
            });
            if (!invalidAddressBlock) {
              ret.questionsDone += 1;
            }
          }

          if (["signature"].includes(item.type)) {
            const signatureCanvas = $("#" + item.name);
            const strSignature = signatureCanvas.attr("data-signature");
            if (strSignature) {
              ret.questionsDone += 1;
            }
          }
        }
        if (ret.questionsNeeded > 0) {
          ret.percentComplete = parseFloat(
            ((ret.questionsDone / ret.questionsNeeded) * 100).toFixed(2)
          );
        } else {
          ret.percentComplete = 100;
        }
      } else {
        ret.percentComplete = 100;
      }

      return ret;
    };

    const handleInputErrors = (formItem: any) => {
      let valid = true;
      const form: any = $("#previewFormBuilder");
      form
        .find(".rendered-form .border-danger, .rendered-form .text-danger")
        .removeClass("border-danger text-danger");
      form
        .find("input[type=radio][required]")
        .each(function(index: number, control: any) {
          const _input = $(control);
          if (_input.attr("type") == "radio") {
            const radioName = _input.attr("name");
            if (
              form.find('input[type=radio][name="' + radioName + '"]:checked')
                .length == 0 &&
              form.find('input[type=radio][name="' + radioName + '[]"]:checked')
                .length == 0
            ) {
              $(control).addClass("border-danger");
              $(control)
                .parents(".form-group")
                .addClass("text-danger");
              valid = false;
            } else {
              if (
                form
                  .find('input[type=radio][name="' + radioName + '"]:checked')
                  .val() == ""
              ) {
                $(control).addClass("border-danger");
                $(control)
                  .parents(".form-group")
                  .addClass("text-danger");
                valid = false;
              }
            }
          }
        });
      form
        .find(
          "input[type=text][required], input[type=email][required], input[type=date][required]"
        )
        .each(function(index: number, control: any) {
          if ($(control).val() == "") {
            $(control).addClass("border-danger");
            $(control)
              .parents(".form-group")
              .addClass("text-danger");
            valid = false;
          }
        });
      form
        .find("textarea[required]")
        .each(function(index: number, control: any) {
          if ($(control).val() == "") {
            $(control).addClass("border-danger");
            $(control)
              .parents(".form-group")
              .addClass("text-danger");
            valid = false;
          }
        });
      form
        .find(".formbuilder-radio-group")
        .each(function(index: number, control: any) {
          if (
            $(control).find("input[type=radio][required]").length > 0 &&
            $(control).find("input[type=radio][required]:checked").length == 0
          ) {
            $(control).addClass("border-danger");
            $(control)
              .parents(".form-group")
              .addClass("text-danger");
            valid = false;
          }
        });
      form
        .find(".formbuilder-checkbox-group")
        .each(function(index: number, control: any) {
          if (
            $(control).find("input[type=checkbox][required]").length > 0 &&
            $(control).find("input[type=checkbox][required]:checked").length ==
              0
          ) {
            $(control)
              .addClass("border-danger text-danger")
              .find("input[type=checkbox]")
              .addClass("border-danger");
            valid = false;
          }
        });
      form.find("select[required]").each(function(index: number, control: any) {
        if ($(control).val() == 0) {
          $(control).addClass("border-danger");
          $(control)
            .parent()
            .addClass("text-danger");
          valid = false;
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      $(".formbuilder-addressBlock.text-danger").each(function(index, control) {
        $(control).removeClass("text-danger");
        $(control)
          .find("input[type=text]")
          .removeClass("text-danger");
        if ($(control).find(".text-danger").length) {
          $(control)
            .find("label.formbuilder-addressBlock-label")
            .addClass("text-danger");
        }
      });

      // validate for formbuilder-medication
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      validateMedication(formItem);
      $(".formbuilder-medication.text-danger").each(function(index, control) {
        const noneOption = $(control).find(
          "input[type=checkbox][id*='none-option']"
        );
        const noneOptionChecked = noneOption.prop("checked") == true;
        if (noneOptionChecked) {
          $(control)
            .find(".text-danger, .border-danger")
            .removeClass("text-danger border-danger");
        }

        $(control).removeClass("text-danger");
        $(control)
          .find(".medication-notes")
          .removeClass("text-danger")
          .find("input[type=text]")
          .removeClass("border-danger");
        if ($(control).find(".text-danger").length) {
          $(control)
            .find("label.formbuilder-medication-label")
            .addClass("text-danger");
        }
      });

      // validate for formbuilder-allergies
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      validateAllergies(formItem);
      $(".formbuilder-allergies.text-danger").each(function(index, control) {
        const noneOption = $(control).find(
          "input[type=checkbox][id*='none-option']"
        );
        const noneOptionChecked = noneOption.prop("checked") == true;
        if (noneOptionChecked) {
          $(control)
            .find(".text-danger, .border-danger")
            .removeClass("text-danger border-danger");
        }

        $(control).removeClass("text-danger");
        if ($(control).find(".text-danger").length) {
          $(control)
            .find("label.formbuilder-allergies-label")
            .addClass("text-danger");
        }
      });

      // validate for transportation
      $(".formbuilder-transportation.text-danger").each(function(
        index,
        control
      ) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        validateTransportation($(control));
      });

      // if (!valid) {
      //   form.animate(
      //     {
      //       scrollTop:
      //         form.scrollTop() -
      //         form.offset().top +
      //         form.find(".border-danger:first").offset().top
      //     },
      //     {
      //       duration: "medium",
      //       easing: "swing"
      //     }
      //   );
      // }
    };

    const validateMedication = (formItem: any) => {
      // check if inputted medication info correctly
      // validate for medication not required, but not inputted full info for medication name/route/frequency
      let isValid = true;
      const data = formItem.formRenderInstance.userData;
      const medication = data.filter(
        (item: any) => item.type == "medication" && !item.required
      );
      for (const item of medication) {
        const id = item.name;
        const noneOption = $(`#${id}-none-option`);
        const noneOptionChecked = noneOption.prop("checked") == true;
        if (noneOptionChecked) continue;

        $(`.field-${id} .medication-info`).each((i, obj) => {
          const medicationInfo: any = $(obj);
          const medicationName = medicationInfo.find(
            "input[type=text][id*=medication-name]"
          );
          const medicationNameVal = (medicationName?.val() || "").trim();
          // const medicationRoute = medicationInfo.find(
          //   "select[id*=medication-route]"
          // );
          // const medicationRouteVal = (medicationRoute?.val() || "").trim();
          // const frequency = medicationInfo.find("select[id*=frequency]");
          // const frequencyVal = (frequency?.val() || "").trim();
          const dispenseMethod = medicationInfo.find(
            "select[id*=medication-dispense-method]"
          );
          const dispenseMethodVal = (dispenseMethod?.val() || "").trim();
          const dosagesVal = medicationInfo
            .find(`.medication-dosages-cb input[type=checkbox]:checked`)
            .map((i: number, e: any) => $(e).val())
            .toArray();
          const dosageOtherVal =
            medicationInfo
              .find(`input[type=text][id*="medication-other-dosage"]`)
              .val()
              .trim() || "";
          const prescribingDoctor = medicationInfo.find(
            `input[type=text][id*="prescribing-doctor"]`
          );
          const prescribingDoctorVal = (prescribingDoctor?.val() || "").trim();
          if (
            !(
              medicationNameVal == "" &&
              // medicationRouteVal == "" &&
              // frequencyVal == "" &&
              dispenseMethodVal == "" &&
              !dosagesVal.length &&
              prescribingDoctorVal == ""
            )
          ) {
            if (medicationNameVal == "") {
              medicationName
                .addClass("border-danger")
                .closest(".formbuilder-text")
                .addClass("text-danger");
              isValid = false;
            }
            if (dispenseMethodVal == "") {
              dispenseMethod
                .addClass("border-danger")
                .closest(".formbuilder-select")
                .addClass("text-danger");
              isValid = false;
            }
            if (!dosagesVal.length) {
              medicationInfo
                .find(`.medication-dosages-cb input[type=checkbox]`)
                .addClass("border-danger");
              isValid = false;
            } else if (dosagesVal.includes("6") && dosageOtherVal == "") {
              isValid = false;
            }
            // if (medicationRouteVal == "") {
            //   medicationRoute
            //     .addClass("border-danger")
            //     .closest(".formbuilder-select")
            //     .addClass("text-danger");
            //   isValid = false;
            // }
            // if (frequencyVal == "") {
            //   frequency
            //     .addClass("border-danger")
            //     .closest(".formbuilder-select")
            //     .addClass("text-danger");
            //   isValid = false;
            // }
            if (prescribingDoctorVal == "") {
              prescribingDoctor
                .addClass("border-danger")
                .closest(".formbuilder-text")
                .addClass("text-danger");
              isValid = false;
            }
          }
        });
      }

      return isValid;
    };

    const validateAllergies = (formItem: any) => {
      // check if inputted allergies info correctly
      // validate for allergies not required, but not inputted full info for name/category/severity
      let isValid = true;
      const renderDiv: any = $("#previewFormBuilder");
      const data = formItem.formRenderInstance.userData;
      const allergies = data.filter(
        (item: any) => item.type == "allergies" && !item.required
      );
      for (const item of allergies) {
        const id = item.name;
        const noneOption = renderDiv.find(`#${id}-none-option`);
        const noneOptionChecked = noneOption.prop("checked") == true;
        if (noneOptionChecked) continue;

        renderDiv
          .find(`.field-${id} .allergies-info`)
          .each((i: number, obj: any) => {
            const info: any = $(obj);
            const allergiesName = info.find(
              "input[type=text][id*=allergies-name]"
            );
            const allergiesCategory = info.find(
              "select[id*=allergies-category]"
            );
            const allergiesSeverity = info.find(
              "select[id*=allergies-severity]"
            );
            const allergiesNameVal = allergiesName.val().trim();
            const allergiesCategoryVal = allergiesCategory.val().trim();
            const allergiesSeverityVal = allergiesSeverity.val().trim();

            if (!(allergiesNameVal == "" && allergiesCategoryVal == "")) {
              if (allergiesNameVal == "") {
                allergiesName
                  .addClass("border-danger")
                  .closest(".formbuilder-text")
                  .addClass("text-danger");
                isValid = false;
              }
              if (allergiesCategoryVal == "") {
                allergiesCategory
                  .addClass("border-danger")
                  .closest(".formbuilder-select")
                  .addClass("text-danger");
                isValid = false;
              }
              if (
                allergiesCategoryVal == "Food" &&
                allergiesSeverityVal == ""
              ) {
                allergiesSeverity
                  .addClass("border-danger")
                  .closest(".formbuilder-select")
                  .addClass("text-danger");
                isValid = false;
              }
            }
          });
      }

      return isValid;
    };

    const validateTransportation = (transportationDiv: any) => {
      if (transportationDiv.find(".text-danger").length == 0) return;

      transportationDiv.removeClass("text-danger");
      const field = transportationDiv;
      let invalid = false;
      // arriving
      const arriving = field
        .find(".arrival-section")
        .removeClass("text-danger");
      const selectedArriving = arriving.find("input:checked")?.val() || "";
      if (selectedArriving != "") {
        if (selectedArriving == "Car") {
          const whoDrivingYou = arriving.find(
            `input[type=text][id*="-car-selected"]`
          );
          if ((whoDrivingYou?.val().trim() || "") == "") {
            invalid = true;
            whoDrivingYou
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
          }
        } else if (selectedArriving == "Airplane") {
          const airlines = arriving.find(
            `input[type=text][id*="-airplane-selected-airlines"]`
          );
          const flightNumber = arriving.find(
            `input[type=text][id*="-airplane-selected-flightNumber"]`
          );
          const dateTime = arriving.find(
            `input[type=text][id*="-airplane-selected-dateTime"]`
          );
          if ((airlines?.val().trim() || "") == "") {
            invalid = true;
            airlines
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
          }
          if ((flightNumber?.val().trim() || "") == "") {
            invalid = true;
            flightNumber
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
          }
          if ((dateTime?.val().trim() || "") == "") {
            invalid = true;
            dateTime
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
          }
        }
      } else {
        invalid = true;
        arriving.addClass("text-danger");
      }

      // departing
      const departing = field
        .find(".departure-section")
        .removeClass("text-danger");
      const selectedDeparting = departing.find("input:checked")?.val() || "";
      if (selectedDeparting != "") {
        if (selectedDeparting == "Car") {
          const whoDrivingYou = departing.find(
            `input[type=text][id*="-car-selected"]`
          );
          if ((whoDrivingYou?.val().trim() || "") == "") {
            invalid = true;
            whoDrivingYou
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
          }
        } else if (selectedDeparting == "Airplane") {
          const airlines = departing.find(
            `input[type=text][id*="-airplane-selected-airlines"]`
          );
          const flightNumber = departing.find(
            `input[type=text][id*="-airplane-selected-flightNumber"]`
          );
          const dateTime = departing.find(
            `input[type=text][id*="-airplane-selected-dateTime"]`
          );
          if ((airlines?.val().trim() || "") == "") {
            invalid = true;
            airlines
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
          }
          if ((flightNumber?.val().trim() || "") == "") {
            invalid = true;
            flightNumber
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
          }
          if ((dateTime?.val().trim() || "") == "") {
            invalid = true;
            dateTime
              .addClass("border-danger")
              .closest(".formbuilder-text")
              .addClass("text-danger");
          }
        }
      } else {
        invalid = true;
        departing.addClass("text-danger");
      }
      if (invalid) {
        field
          .find("label.formbuilder-transportation-label")
          .addClass("text-danger");
      } else {
        field.find(".text-danger").removeClass("text-danger");
      }
    };

    const saveForm = async () => {
      const item: any = pageData.value.items[selectedIndex.value] || null;
      if (item) {
        pageData.value.isLoading = true;
        let status = parseInt(formData.value.controls.status.value);
        const percentInfo = getPercent(item);
        if (status == TodoStatus.Complete) {
          if (percentInfo.percentComplete < 100) {
            pageData.value.isLoading = false;
            handleInputErrors(item);
            ApiHelper.showErrorMessage(
              `The status cannot be changed to complete until all mandatory fields are filled out`,
              "Oops"
            );
            return;
          } else {
            const isValidMedication = validateMedication(item);
            const isValidAllergies = validateAllergies(item);
            if (isValidMedication == false || isValidAllergies == false) {
              pageData.value.isLoading = false;
              ApiHelper.showErrorMessage("You have not completed the form yet");
              return;
            }
          }
        } else if (status == TodoStatus.Pending) {
          if (percentInfo.percentComplete < 100) {
            const isConfirmed = await Vue.swal({
              title: "Are you sure?",
              html: `The Todo is not yet complete. If saved, the status will revert back to incomplete.`,
              showCancelButton: true,
              confirmButtonText: "Yes, save it!",
              showCloseButton: true,
              closeButtonHtml:
                '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
            }).then((result: any) => {
              setTimeout(function() {
                $(".swal2-backdrop-hide").addClass("d-none");
              }, 200);
              return result.isConfirmed;
            });
            if (isConfirmed) {
              status = TodoStatus.Incomplete;
            } else {
              pageData.value.isLoading = false;
              return;
            }
          }
        }

        const formControls = await getFormValues(item);
        const result = await ApiHelper.callApi(
          "put",
          "/todos/task/" + (item.linkEpfId || 0),
          {
            profileId: item.profileId,
            formId: item.formId,
            participantId: item.participantId,
            linkContent: JSON.stringify(formControls),
            linkStatus: status
          }
        );
        pageData.value.isLoading = false;
        if (result.status === 1) {
          ApiHelper.showSuccessMessage("Saved");
          showPopup.value = false;
          loadList();
        } else {
          ApiHelper.showErrorMessage(result.message, "Oops");
        }
      } else {
        ApiHelper.showErrorMessage("Not found", "Oops");
      }
    };

    const openPopup = (item: any, index: number) => {
      // if (sidebarData.value.status == 2) {
      //   return;
      // }
      selectedIndex.value = index;
      pageData.value.isLoading = true;
      popupTitle.value = item.formName;
      showPopup.value = true;
      formData.value.controls.status.value = item.linkEpfStatus;
      // only allow update status for pending to-do
      allowSave.value = item.linkEpfStatus === 1;
      setTimeout(async () => {
        const moduleBuilder: any = $("#previewFormBuilder");
        item.formRenderInstance = await moduleBuilder.formRender({
          formData: item.formContent,
          notify: {
            error: (message: string) => {
              return message;
            },
            success: (message: string) => {
              const jsonData = JSON.parse(item.formContent);
              // remove dot for address block
              moduleBuilder
                .find("label.formbuilder-addressBlock-label span")
                .each((i: number, obj: any) => {
                  const field = $(obj);
                  field.addClass("display-none");
                });
              // apply mask
              moduleBuilder
                .find("input[inputmask=phonenumber]")
                .each((i: number, obj: any) => {
                  Inputmask("(999) 999-9999", {
                    autoUnmask: true
                  }).mask(obj);
                });
              // transportation: date and time of arrival
              setTimeout(() => {
                moduleBuilder
                  .find("input[id*=airplane-selected-dateTime]")
                  .each((i: number, obj: any) => {
                    Inputmask("datetime", {
                      alias: "mm/dd/yyyy",
                      inputFormat: "mm/dd/yyyy HH:MM",
                      autoUnmask: true,
                      clearIncomplete: true
                    }).mask(obj);
                  });
              }, 500);

              // const files = moduleBuilder.find("input[type=file]");
              // files.each((index: number, value: any) => {
              //   const $file = $(value);
              //   $file.attr(
              //     "accept",
              //     "application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/x-png,image/gif,image/jpeg"
              //   );
              //   updateFilePreview($file);
              //   updateFileDownload($file);
              // });

              moduleBuilder.on("click", ".delete-file", (e: any) => {
                const _this = $(e.target);
                Vue.swal({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonText: "Yes, delete it!",
                  showCloseButton: true,
                  closeButtonHtml:
                    '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
                }).then((result: any) => {
                  setTimeout(function() {
                    $(".swal2-backdrop-hide").addClass("d-none");
                  }, 200);
                  if (result.isConfirmed) {
                    const fileName = _this.attr("data-file");
                    const fileSelector = _this
                      .closest(".form-group")
                      .find("input[type=file]");
                    const arrFiles = JSON.parse(
                      fileSelector.attr("files") || "[]"
                    );
                    fileSelector.attr(
                      "files",
                      JSON.stringify(
                        arrFiles.filter((file: any) => {
                          return file.name != fileName;
                        })
                      )
                    );

                    if (
                      (_this.prop("id") || "").indexOf("attach-prescription") !=
                      -1
                    ) {
                      // medication: no allow upload multi files for prescription
                      fileSelector.attr("files", "[]");
                    }

                    updateFilePreview(fileSelector);
                  }
                });
              });

              moduleBuilder.on("change", "input[type=file]", (e: any) => {
                const _this = $("#" + e.target.id);
                const files = e.target.files || [];
                let arrFiles = JSON.parse(_this.attr("files") || "[]");
                for (const file of files) {
                  if (file.size > 5 * 1024 * 1024) {
                    ApiHelper.showErrorMessage(
                      file.name +
                        " is greater than 5MB. Please use another file",
                      "Oops"
                    );
                  } else {
                    if (file) {
                      pageData.value.isLoading = true;
                      ApiHelper.uploadFile(file.name, file, item.profileId)
                        .then((result: any) => {
                          if (result.status == 1) {
                            _this.attr("file-path", result.data.fileUrl);
                            _this.attr("file-name", file.name);

                            if (
                              (_this.prop("id") || "").indexOf(
                                "attach-prescription"
                              ) != -1
                            ) {
                              // medication: no allow upload multi files for prescription
                              arrFiles = [];
                            }

                            arrFiles.push({
                              path: result.data.fileUrl,
                              name: file.name
                            });
                            _this.attr("files", JSON.stringify(arrFiles));
                            _this.val("");
                            // ApiHelper.showSuccessMessage('Uploaded file');
                            updateFilePreview(_this);
                          } else {
                            ApiHelper.showErrorMessage(
                              result.message || `Can't upload file`,
                              "Oops"
                            );
                          }

                          pageData.value.isLoading = false;
                        })
                        .catch(error => {
                          ApiHelper.showErrorMessage(
                            JSON.stringify(error),
                            "Oops"
                          );
                          pageData.value.isLoading = true;
                        });
                    }
                  }
                }
                /* const file = e.target.files[0];
                if (file) {
                  const arrFiles = JSON.parse(_this.attr('files') || '[]');
                  this.loading = true;
                  ApiHelper.uploadFile(file.name, file).then((result: any) => {
                    _this.attr('file-path', result.filePath);
                    _this.attr('file-name', file.name);
                    arrFiles.push({
                      path: result.filePath,
                      name: file.name
                    });
                    _this.attr('files', JSON.stringify(arrFiles));
                    _this.val(undefined);
                    ApiHelper.showSuccessMessage('Uploaded file');
                    this.updateFilePreview(_this);
                    this.loading = false;
                  }).catch((error) => {
                    ApiHelper.showErrorMessage(JSON.stringify(error), 'Oops');
                    this.loading = true;
                  });
                } */
              });

              moduleBuilder.on(
                "change input",
                "input,textarea,select",
                (e: any) => {
                  const parent = $(e.target).closest(".form-group");
                  parent.find(".text-danger").removeClass("text-danger");
                  parent.find(".border-danger").removeClass("border-danger");
                  parent.removeClass("text-danger");
                  if (parent.parent().hasClass("formbuilder-transportation")) {
                    validateTransportation(parent.parent());
                  }
                }
              );
              // update for Conditional Options item
              const conditionalOptions = moduleBuilder.find(
                ".formbuilder-conditionalOptions"
              );
              if (conditionalOptions.length) {
                conditionalOptions.each((i: number, obj: any) => {
                  const field = $(obj);
                  const fieldId = $(
                    field.find(`input[type=radio]`).get(0)
                  ).prop("name");
                  const fieldData = jsonData.find(
                    (t: any) => t.name == fieldId
                  );
                  let val = "";
                  if (fieldData?.userData) {
                    val = fieldData?.userData[0] || "";
                  }
                  if (val != "") {
                    field.attr("data-value", val.toLowerCase());
                    field
                      .find(`input[type=radio][value=${val}]`)
                      .prop("checked", true);
                  }

                  field
                    .addClass("formbuilder-radio-group")
                    .find(`input[type=radio]`)
                    .each((j: number, objRadio: any) => {
                      $(objRadio).on("click", () => {
                        const val = `${$(objRadio)?.val() || ""}`.toLowerCase();
                        const id = $(objRadio)?.prop("name") || "";
                        field.attr("data-value", val);
                        if (val == "no") {
                          // clear explain textbox
                          $(`#explain-${id}`).val("");
                        }
                      });
                    });
                });
              }

              // case selected "other" option of a checkbox group
              $(
                "input[type=checkbox][name*=checkbox-group-][id*=-other][selected-other=true]"
              ).each((i, obj) => {
                const cb = $(obj);
                const otherValue = cb.attr("other-value") || "";
                cb.prop("checked", true);
                cb.next()
                  .find("input[type=text].other-val")
                  .val(otherValue.trim());
              });
              // update for medication item
              // const medication = moduleBuilder.find(".formbuilder-medication");
              // if (medication.length) {
              //   medication.each((i: number, obj: any) => {
              //     const field = $(obj);
              //     const fieldLabel = field.find(
              //       "label.formbuilder-medication-label"
              //     );
              //     const id = fieldLabel.attr("for") || "";
              //     const medicationData = jsonData.find(
              //       (t: any) => t.name == id
              //     );
              //     const userData = medicationData.userData || [];
              //     const noneOptionChecked =
              //       (medicationData.noneOptionChecked || false) == true;
              //     if (noneOptionChecked) {
              //       field.addClass("none-medication");
              //     }
              //     const returnHTML: any = [];
              //     if (!userData.length) {
              //       // init
              //       userData.push({
              //         medicalId: 0,
              //         medicationName: "",
              //         medicationRoute: "",
              //         frequency: "",
              //         notes: "",
              //         prescribingDoctor: "",
              //         attachPrescription: ""
              //       });
              //     }
              //     for (const data of userData) {
              //       // saved data
              //       const medicalId = data.medicalId || 0;
              //       const medicationNameVal = data.medicationName || "";
              //       const medicationRouteVal = data.medicationRoute || "";
              //       const frequencyVal = data.frequency || "";
              //       const notesVal = data.notes || "";
              //       const prescribingDoctorVal = data.prescribingDoctor || "";
              //       const prescriptionName = data.prescriptionName || "";
              //       const prescriptionVal = data.prescription || "";
              //       let files: any = [];
              //       if (prescriptionName != "" && prescriptionVal != "") {
              //         files = [
              //           {
              //             name: prescriptionName,
              //             path: prescriptionVal
              //           }
              //         ];
              //       }

              //       // ids
              //       const randomId = Math.floor(Math.random() * 10000000);
              //       const medicationName = `${id}-medication-name-${randomId}`;
              //       const medicationRoute = `${id}-medication-route-${randomId}`;
              //       const frequency = `${id}-frequency-${randomId}`;
              //       const notes = `${id}-notes-${randomId}`;
              //       const prescribingDoctorId = `${id}-prescribing-doctor-${randomId}`;
              //       const attachPrescriptionId = `${id}-attach-prescription-${randomId}`;

              //       const requiredAttr = medicationData.required
              //         ? 'required="required" aria-required="true"'
              //         : "";
              //       const requireTag = medicationData.required
              //         ? '<span class="formbuilder-required">*</span>'
              //         : "";
              //       const routeOptions = ApiHelper.getMedicationDosageOptions();
              //       const routeOptionsHTML = [
              //         `<option value="">Select Route</option>`
              //       ];
              //       const frequencyOptions = ApiHelper.getMedicationFrequencyOptions();
              //       const frequencyOptionsHTML = [
              //         `<option value="">Select Frequency</option>`
              //       ];
              //       for (const i in routeOptions) {
              //         routeOptionsHTML.push(`
              //           <option value="${routeOptions[i].text}"
              //             id="${medicationRoute}-${i}"
              //             ${
              //               routeOptions[i].text == medicationRouteVal
              //                 ? "selected"
              //                 : ""
              //             }
              //           >
              //             ${routeOptions[i].text}
              //           </option>
              //         `);
              //       }
              //       for (const i in frequencyOptions) {
              //         frequencyOptionsHTML.push(`
              //         <option value="${frequencyOptions[i].text}"
              //           id="${frequency}-${i}"
              //           ${
              //             frequencyOptions[i].text == frequencyVal
              //               ? "selected"
              //               : ""
              //           }
              //         >
              //           ${frequencyOptions[i].text}
              //         </option>
              //       `);
              //       }
              //       returnHTML.push(`
              //       <div class="medication-info row" data-medical-id="${medicalId}">
              //         <span class="remove-medication-btn" title="Remove">x</span>
              //         <div class="formbuilder-text form-group field-text-${medicationName} col-6">
              //           <label for="${medicationName}" class="formbuilder-text-label field-label">Medication name${requireTag}</label>
              //           <input type="text" value="${medicationNameVal}" class="form-control" name="${medicationName}" inputmask="none" id="${medicationName}" ${requiredAttr} maxlength="255">
              //         </div>
              //         <div class="formbuilder-select form-group field-select-${medicationRoute} col-6">
              //           <label for="${medicationRoute}" class="formbuilder-select-label field-label">
              //             Medication Route${requireTag}
              //           </label>
              //           <select class="form-control input__full" name="${medicationRoute}" id="${medicationRoute}" ${requiredAttr}>
              //             ${routeOptionsHTML.join("")}
              //           </select>
              //         </div>
              //         <div class="formbuilder-select form-group field-select-${frequency} col-6">
              //           <label for="${frequency}" class="formbuilder-select-label field-label">
              //             Frequency${requireTag}
              //           </label>
              //           <select class="form-control input__full" name="${frequency}" id="${frequency}" ${requiredAttr}>
              //             ${frequencyOptionsHTML.join("")}
              //           </select>
              //         </div>
              //         <div class="formbuilder-text form-group field-text-${notes} col-6 medication-notes">
              //           <label for="${notes}" class="formbuilder-text-label field-label">Medication notes</label>
              //           <input type="text" value="${notesVal}" class="form-control" name="${notes}" inputmask="none" id="${notes}">
              //         </div>
              //         <div class="formbuilder-text form-group field-text-${prescribingDoctorId} col-6 medication-prescribing-doctor">
              //           <label for="${prescribingDoctorId}" class="formbuilder-text-label field-label">Prescribing Doctor ${requireTag}</label>
              //           <input type="text" value="${prescribingDoctorVal}" class="form-control" name="${prescribingDoctorId}" id="${prescribingDoctorId}" ${requiredAttr}>
              //         </div>
              //         <div class="formbuilder-text form-group field-text-${attachPrescriptionId} col-6 medication-attach-prescription">
              //           <label>
              //             <input type="file" class="form-control d-none" name="${attachPrescriptionId}" id="${attachPrescriptionId}" multiple="false"
              //               files='${JSON.stringify(files)}'
              //             />
              //             <span>Attach Prescription</span>
              //           </label>
              //         </div>
              //       </div>
              //     `);
              //     }
              //     field.find(".medication-info, .medication-spliter").remove();
              //     field
              //       .find(`#${id}-none-option`)
              //       .closest(".checkbox-group")
              //       .remove();
              //     fieldLabel.after(`
              //       <div class="checkbox-group">
              //         <div class="formbuilder-checkbox">
              //           <input name="${id}-none-option" id="${id}-none-option" type="checkbox"
              //             class="medication-none-option" ${
              //               noneOptionChecked ? "checked" : ""
              //             }>
              //           <label for="${id}-none-option">N/A</label>
              //         </div>
              //       </div>
              //       ${returnHTML.join(`<hr class="medication-spliter" />`)}
              //     `);
              //     // <div class="medication-addmore"><span class="addmore-btn">More Medication</span></div>

              //     // has or has not medication information
              //     field
              //       .find(".medication-none-option")
              //       .off("change")
              //       .on("change", e => {
              //         if (field.closest(".todo-edit-page").length) return;
              //         const obj = $(e.target);
              //         if (obj.prop("checked") == true) {
              //           field.addClass("none-medication");
              //         } else {
              //           field.removeClass("none-medication");
              //         }
              //       });
              //   });
              // }

              // update for allergies item
              const allergies = moduleBuilder.find(".formbuilder-allergies");
              if (allergies.length) {
                allergies.each((i: number, obj: any) => {
                  const field = $(obj);
                  const fieldLabel = field.find(
                    "label.formbuilder-allergies-label"
                  );
                  const id = fieldLabel.attr("for") || "";
                  const allergiesData = jsonData.find((t: any) => t.name == id);
                  const userData = allergiesData.userData || [];
                  const noneOptionChecked =
                    (allergiesData.noneOptionChecked || false) == true;
                  if (noneOptionChecked) {
                    field.addClass("none-allergies");
                  }
                  const returnHTML: any = [];
                  if (!userData.length) {
                    // init
                    userData.push({
                      allergiesId: 0,
                      allergiesName: "",
                      allergiesCategory: "",
                      allergiesSeverity: ""
                    });
                  }
                  const requiredAttr = allergiesData.required
                    ? 'required="required" aria-required="true"'
                    : "";
                  const requireTag = allergiesData.required
                    ? '<span class="formbuilder-required">*</span>'
                    : "";
                  for (const data of userData) {
                    // saved data
                    const allergiesId = data.allergiesId || 0;
                    const allergiesNameVal = data.allergiesName || "";
                    const allergiesCategoryVal = data.allergiesCategory || "";
                    const allergiesSeverityVal = data.allergiesSeverity || "";

                    // ids
                    const randomId = Math.floor(Math.random() * 10000000);
                    const allergiesNameId = `${id}-allergies-name-${randomId}`;
                    const allergiesCategoryId = `${id}-allergies-category-${randomId}`;
                    const allergiesSeverityId = `${id}-allergies-severity-${randomId}`;

                    const severityOptions: any = [];
                    for (const s of ["Inhale", "Ingest", "On-Contact"]) {
                      severityOptions.push(
                        `<option value="${s}" 
                          ${allergiesSeverityVal == s ? "selected" : ""}>
                          ${s}
                        </option>`
                      );
                    }

                    const formgroup = $(".todo-edit-page").length
                      ? ""
                      : "form-group";
                    returnHTML.push(`
                      <div class="allergies-info row" data-allergies-id="${allergiesId}">
                        <span class="remove-allergies-btn" title="Remove">x</span>
                        <div class="formbuilder-text ${formgroup} field-text-${allergiesNameId} col-6">
                          <label for="${allergiesNameId}" class="formbuilder-text-label field-label">Allergy Name ${requireTag}</label>
                          <input type="text" value="${allergiesNameVal}" class="form-control" name="${allergiesNameId}" inputmask="none" id="${allergiesNameId}" ${requiredAttr} maxlength="255">
                        </div>
                        <div class="formbuilder-select ${formgroup} field-select-${allergiesCategoryId} col-6">
                          <label for="${allergiesCategoryId}" class="formbuilder-select-label field-label">
                            Allergy Category ${requireTag}
                          </label>
                          <select class="form-control input__full allergies-category" name="${allergiesCategoryId}" id="${allergiesCategoryId}" ${requiredAttr}>
                            <option value="">Select Category</option>
                            <option value="Food" ${
                              allergiesCategoryVal == "Food" ? "selected" : ""
                            }>Food</option>
                            <option value="Environmental" ${
                              allergiesCategoryVal == "Environmental"
                                ? "selected"
                                : ""
                            }>Environmental</option>
                            <option value="Other" ${
                              allergiesCategoryVal == "Other" ? "selected" : ""
                            }>Other</option>
                          </select>
                        </div>
                        <div class="formbuilder-select ${formgroup} field-select-${allergiesSeverityId} col-6 allergies-severity-section 
                          ${allergiesCategoryVal == "Food" ? "" : "d-none"}">
                          <label for="${allergiesSeverityId}" class="formbuilder-select-label field-label">
                            Severity ${requireTag}
                          </label>
                          <select class="form-control input__full" name="${allergiesSeverityId}" id="${allergiesSeverityId}" ${requiredAttr}>
                            <option value="">Select Severity</option>
                            ${severityOptions.join("")}
                          </select>
                        </div>
                      </div>
                    `);
                  }
                  field.find(".allergies-info, .allergies-spliter").remove();
                  field
                    .find(`#allergies-${id}-none-option`)
                    .closest(".checkbox-group")
                    .remove();
                  fieldLabel.after(`
                    <div class="checkbox-group">
                      <div class="formbuilder-checkbox">
                        <input name="allergies-${id}-none-option" id="allergies-${id}-none-option" type="checkbox" class="allergies-none-option" 
                          ${noneOptionChecked ? "checked" : ""}
                        >
                        <label for="allergies-${id}-none-option">N/A</label>
                      </div>
                    </div>
                    ${returnHTML.join(`<hr class="allergies-spliter" />`)}
                  `);
                });
              }

              // update for transportation
              const transportations = moduleBuilder.find(
                ".formbuilder-transportation"
              );
              if (transportations.length) {
                transportations.each((i: number, obj: any) => {
                  const field = $(obj);
                  const fieldLabel = field.find(
                    "label.formbuilder-transportation-label"
                  );
                  const id = fieldLabel.attr("for") || "";
                  const data = jsonData.find((t: any) => t.name == id);
                  const userData = data.userData || {
                    arriving: {
                      value: "",
                      whoDrivingYou: "",
                      airlines: "",
                      flightNumber: "",
                      dateTime: "",
                      other: ""
                    },
                    departing: {
                      value: "",
                      whoDrivingYou: "",
                      airlines: "",
                      flightNumber: "",
                      dateTime: "",
                      other: ""
                    }
                  };
                  const arriving = userData.arriving;
                  const departing = userData.departing;
                  const randomId = Math.floor(Math.random() * 10000000);
                  const textCarId = `text-${id}-car-selected`;
                  const arrivingTextAirplaneId = `arriving-text-${id}-airplane-selected`;
                  const departingTextAirplaneId = `departing-text-${id}-airplane-selected`;
                  const arrivalRadioName = `${id}-${randomId}`;
                  const departureRadioName = `${id}-${randomId}-1`;
                  const requiredAttr = data.required
                    ? 'required="required" aria-required="true"'
                    : "";
                  const requireTag = data.required
                    ? '<span class="formbuilder-required">*</span>'
                    : "";

                  const formgroup = $(".todo-edit-page").length
                    ? ""
                    : "form-group";
                  const returnHTML = `
                    <div class="formbuilder-radio-group form-group arrival-section trans-info-section">
                      <label class="field-label">
                        ${data.arrivingLabel || ""}
                      </label>
                      <div class="radio-group">
                        <div class="formbuilder-radio">
                          <input name="${arrivalRadioName}" id="${arrivalRadioName}-0" value="Car" 
                            ${
                              arriving.value == "Car" ? "checked='checked'" : ""
                            } 
                            type="radio" ${requiredAttr}>
                          <label for="${arrivalRadioName}-0">Car</label>
                        </div>
                        <div class="formbuilder-radio">
                          <input name="${arrivalRadioName}" class="" id="${arrivalRadioName}-1" value="Train" 
                            ${
                              arriving.value == "Train"
                                ? "checked='checked'"
                                : ""
                            } 
                            type="radio" ${requiredAttr}>
                          <label for="${arrivalRadioName}-1">Train</label>
                        </div>
                        <div class="formbuilder-radio">
                          <input name="${arrivalRadioName}" class="" id="${arrivalRadioName}-2" value="Airplane" 
                            ${
                              arriving.value == "Airplane"
                                ? "checked='checked'"
                                : ""
                            } 
                            type="radio" ${requiredAttr}>
                          <label for="${arrivalRadioName}-2">Airplane</label>
                        </div>
                        <div class="formbuilder-radio">
                          <input name="${arrivalRadioName}" class="" id="${arrivalRadioName}-3" value="Other" 
                            ${
                              arriving.value == "Other"
                                ? "checked='checked'"
                                : ""
                            } 
                            type="radio" ${requiredAttr}>
                          <label for="${arrivalRadioName}-3" class="mb-0">
                            <input type="text" placeholder="Other" class="form-control transportation-other" 
                              value="${arriving.other || ""}" />
                          </label>
                        </div>
                      </div>
                      <div class="formbuilder-text form-group field-text-${id}-car-selected text-car-selected 
                        ${arriving.value == "Car" ? "" : "d-none"}">
                        <label for="${textCarId}" class="formbuilder-text-label field-label">
                          <span>Please indicate who will be driving you</span>
                          ${requireTag}
                        </label>
                        <input type="text" class="form-control" name="${textCarId}" id="${textCarId}" 
                          value="${arriving.whoDrivingYou ||
                            ""}" ${requiredAttr}>
                      </div>
                      <div class="row text-airplane-selected 
                        ${arriving.value == "Airplane" ? "" : "d-none"}">
                        <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                          <label for="${arrivingTextAirplaneId}-airlines" class="formbuilder-text-label field-label">
                            <span>Please enter your airlines</span>
                            ${requireTag}
                          </label>
                          <input type="text" class="form-control" name="${arrivingTextAirplaneId}-airlines" id="${arrivingTextAirplaneId}-airlines" 
                            value="${arriving.airlines || ""}" ${requiredAttr}>
                        </div>
                        <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected"> 
                          <label for="${arrivingTextAirplaneId}-flightNumber" class="formbuilder-text-label field-label">
                            <span>Your flight number</span>
                            ${requireTag}
                          </label>
                          <input type="text" class="form-control" name="${arrivingTextAirplaneId}-flightNumber" id="${arrivingTextAirplaneId}-flightNumber" 
                            value="${arriving.flightNumber ||
                              ""}" ${requiredAttr}>
                        </div>
                        <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                          <label for="${arrivingTextAirplaneId}-dateTime" class="formbuilder-text-label field-label">
                            <span>Date and time of arrival</span>
                            ${requireTag}
                          </label>
                          <input type="text" class="form-control" name="${arrivingTextAirplaneId}-dateTime" id="${arrivingTextAirplaneId}-dateTime" 
                            value="${arriving.dateTime || ""}" ${requiredAttr}>
                        </div>
                      </div>
                    </div>
    
                    <div class="formbuilder-radio-group form-group departure-section trans-info-section">
                      <label class="field-label">
                        ${data.departingLabel || ""}
                      </label>
                      <div class="radio-group">
                        <div class="formbuilder-radio">
                          <input name="${departureRadioName}" id="${departureRadioName}-0" value="Car" 
                            ${
                              departing.value == "Car"
                                ? "checked='checked'"
                                : ""
                            } 
                            type="radio" ${requiredAttr}>
                          <label for="${departureRadioName}-0">Car</label>
                        </div>
                        <div class="formbuilder-radio">
                          <input name="${departureRadioName}" class="" id="${departureRadioName}-1" value="Train" 
                            ${
                              departing.value == "Train"
                                ? "checked='checked'"
                                : ""
                            } 
                            type="radio" ${requiredAttr}>
                          <label for="${departureRadioName}-1">Train</label>
                        </div>
                        <div class="formbuilder-radio">
                          <input name="${departureRadioName}" class="" id="${departureRadioName}-2" value="Airplane" 
                            ${
                              departing.value == "Airplane"
                                ? "checked='checked'"
                                : ""
                            } 
                            type="radio" ${requiredAttr}>
                          <label for="${departureRadioName}-2">Airplane</label>
                        </div>
                        <div class="formbuilder-radio">
                          <input name="${departureRadioName}" class="" id="${departureRadioName}-3" value="Other" 
                            ${
                              departing.value == "Other"
                                ? "checked='checked'"
                                : ""
                            } 
                            type="radio" ${requiredAttr}>
                          <label for="${departureRadioName}-3" class="mb-0">
                            <input type="text" placeholder="Other" class="form-control transportation-other" 
                              value="${departing.other || ""}" />
                          </label>
                        </div>
                      </div>
                      <div class="formbuilder-text form-group field-text-${id}-car-selected text-car-selected 
                        ${departing.value == "Car" ? "" : "d-none"}">
                        <label for="${textCarId}" class="formbuilder-text-label field-label">
                          <span>Please indicate who will be driving you</span>
                          ${requireTag}
                        </label>
                        <input type="text" class="form-control" name="${textCarId}" id="${textCarId}" 
                          value="${departing.whoDrivingYou || ""}"
                          ${requiredAttr}>
                      </div>
                      <div class="row text-airplane-selected 
                        ${departing.value == "Airplane" ? "" : "d-none"}">
                        <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                          <label for="${departingTextAirplaneId}-airlines" class="formbuilder-text-label field-label">
                            <span>Please enter your airlines</span>
                            ${requireTag}
                          </label>
                          <input type="text" class="form-control" name="${departingTextAirplaneId}-airlines" id="${departingTextAirplaneId}-airlines" 
                            value="${departing.airlines || ""}"
                            ${requiredAttr}>
                        </div>
                        <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                          <label for="${departingTextAirplaneId}-flightNumber" class="formbuilder-text-label field-label">
                            <span>Your flight number</span>
                            ${requireTag}
                          </label>
                          <input type="text" class="form-control" name="${departingTextAirplaneId}-flightNumber" id="${departingTextAirplaneId}-flightNumber" 
                            value="${departing.flightNumber || ""}"
                            ${requiredAttr}>
                        </div>
                        <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                          <label for="${departingTextAirplaneId}-dateTime" class="formbuilder-text-label field-label">
                            <span>Date and time of departure</span>
                            ${requireTag}
                          </label>
                          <input type="text" class="form-control" name="${departingTextAirplaneId}-dateTime" id="${departingTextAirplaneId}-dateTime" 
                            value="${departing.dateTime || ""}"
                            ${requiredAttr}>
                        </div>
                      </div>
                    </div>
                  `;

                  field.find(".trans-info-section").remove();
                  fieldLabel.after(returnHTML);
                });
              }

              // case: not allow select multiple for the checkbox group
              moduleBuilder.on(
                "click",
                "input[name*=checkbox-group-]",
                (e: any) => {
                  const cb = $(e.target);
                  const multiple = cb.attr("multiple-selections") || "true";
                  const cbId = cb.prop("id") || "";
                  if (multiple == "false" && cbId != "") {
                    cb.closest(".checkbox-group")
                      .find(`input[type=checkbox]:not(#${cbId})`)
                      .prop("checked", false);
                  }
                }
              );

              // remove <br> tag in .formbuilder-required
              $(".formbuilder-required").each((i, obj) => {
                $(obj)
                  .closest("label")
                  .addClass("field-label");
                const prev = $(obj).prev();
                if (prev.length && prev.prop("tagName") == "BR") {
                  prev.remove();
                }
              });

              const files = moduleBuilder.find("input[type=file]");
              files.each((index: number, value: any) => {
                const $file = $(value);
                $file.attr(
                  "accept",
                  "application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/x-png,image/gif,image/jpeg"
                );
                updateFilePreview($file);
                updateFileDownload($file);
              });

              return message;
            },
            warning: (message: string) => {
              return message;
            }
          }
        });
        // Once a participant has denied they can no longer edit their to-do but the to-do can be edited from the admin portal at any time.
        if (item.participantStatus == ParticipantStatus.Denied) {
          $("#previewFormBuilder")
            .addClass("participant-checked-in")
            .find("input, select, textarea")
            .prop("disabled", true);
        }
        pageData.value.isLoading = false;
      }, 50);
    };

    const setIsPaying = (status: boolean) => {
      pageData.value.paying = status;
    };

    return {
      formData,
      allowSave,
      saveForm,
      sidebarData,
      popupTitle,
      openPopup,
      selectedProfileId,
      showPopup,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      ApiHelper: ApiHelper,
      setIsPaying
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  },
  mounted() {
    const profileId = this.selectedProfileId;
    window.fbControls = [];
    window.fbControls.push((controlClass: any) => {
      class ControlSignature extends controlClass {
        build() {
          return `
          <div>
            <div class="signature-container signature-pad">
              <img src="${this.config.signature}" height="65" width="352" class="defaultImage" style="position: absolute" />
              <canvas id="${this.config.name}" style="touch-action: none; background: white;" height="65" width="352"></canvas>
              <div class="signatureButtons">
                <button class="btn-save"></button>
                <button class="btn-undo"></button>
                <button class="btn-clear"></button>
              </div>
              <div class="otherButtons">
                <label for="file_${this.config.name}" class="button">Upload Signature</label>
                <input type="file" class="file-upload"
                 accept="image/x-png,image/gif,image/jpeg"
                 id="file_${this.config.name}"
                 target="${this.config.name}"
                 />
              </div>
            </div>
          </div>
        `;
        }
        onRender() {
          const canvas: any = document.getElementById(this.config.name);
          const signaturePad = new SignaturePad(canvas, {
            backgroundColor: "rgb(255, 255, 255)"
          });
          signaturePad.off();
          const signatureCanvas = $("#" + this.config.name);
          const container = signatureCanvas.parents(".signature-container");
          if (this.config.signature) {
            signatureCanvas.attr("data-signature", this.config.signature);
            container.find(".defaultImage").show();
            container.find(".btn-undo").hide();
          } else {
            container.find(".defaultImage").hide();
            container.find(".btn-undo").show();
          }
          signaturePad.onEnd = () => {
            container.find(".defaultImage").hide();
            const signatureData = signaturePad.toDataURL();
            signatureCanvas.attr("data-signature", signatureData);
            signatureCanvas.attr("data-new", 1);
          };

          container.find(".btn-save").on("click", () => {
            ApiHelper.showSuccessMessage("Saved");
          });
          container.find(".file-upload").on("change", async (e: any) => {
            const files = e.target.files || [];
            const _this = $(e.target);
            if (files.length > 0) {
              const signatureCanvas2 = $("#" + _this.attr("target"));
              const file = files[0];
              const base64 = await ApiHelper.convertFileToBase64(file);
              const result = await ApiHelper.uploadFileFromBase64(
                base64,
                "profiles/" + profileId + "/signatures",
                profileId
              );
              container.find(".defaultImage").attr("src", "");
              if (result.status === 1) {
                container
                  .find(".defaultImage")
                  .attr("src", result.data.fileUrl)
                  .show();
                container.find(".btn-undo").hide();
                signatureCanvas2.attr("data-signature", result.data.fileUrl);
                signatureCanvas2.attr("data-new", 0);
                signaturePad.clear();
              }
            }
          });
          container.find(".btn-undo").on("click", () => {
            const data = signaturePad.toData();
            if (data) {
              data.pop(); // remove the last dot or line
              signaturePad.fromData(data);
            }
            if (data.length > 0) {
              const signatureData = signaturePad.toDataURL();
              signatureCanvas.attr("data-signature", signatureData);
            } else {
              signatureCanvas.attr("data-signature", "");
            }
            signatureCanvas.attr("data-new", 1);
          });
          container.find(".btn-clear").on("click", () => {
            container.find(".defaultImage").hide();
            container.find(".btn-undo").show();
            signaturePad.clear();
            signatureCanvas.attr("data-signature", "");
            signatureCanvas.attr("data-new", 1);
          });
        }
      }
      controlClass.register("signature", ControlSignature);
      return ControlSignature;
    });
    window.fbControls.push((controlClass: any) => {
      class ControlDob extends controlClass {
        build() {
          const months: number[] = [];
          for (let i = 1; i < 13; i++) {
            months.push(i);
          }
          const days: number[] = [];
          for (let i = 1; i < 32; i++) {
            days.push(i);
          }
          const years: number[] = [];
          for (let i = 1971; i < new Date().getFullYear() + 1; i++) {
            years.push(i);
          }
          return `
          <div
          id="date-${this.config.name}"
          data-userData="${JSON.stringify(this.config.userData)}"
          class="row form-group ${
            this.config.className
          } shadow-none pr-0 d-flex" style="padding:0; margin: 0; border: none">
            <input id="${this.config.name}" type="date" style="display: none" ${
            (this.config.required || "") === "required"
              ? 'aria-required="true" required="required"'
              : ""
          } />
            <div class="col-lg-4 col-12 pl-0">
              <select type="text" class="form-control px-3 text-center sel-month">
                <option value="">Month</option>
                ${months.map(
                  value => `<option value="${value}">${value}</option>`
                )}
              </select>
            </div>
            <div class="col-lg-4 col-12 p-0">
              <select class="form-control px-3 text-center sel-day input__full">
                <option value="">Day</option>
                ${days.map(
                  value => `<option value="${value}">${value}</option>`
                )}
              </select>
            </div>
            <div class="col-lg-4 col-12 pr-0">
              <select type="text" class="form-control px-3 text-center sel-year">
                <option value="">Year</option>
                ${years.map(
                  value => `<option value="${value}">${value}</option>`
                )}
              </select>
            </div>
          </div>
        `;
        }

        onRender() {
          const userData = this.config.userData || [];
          const dateString = userData.length > 0 ? userData[0] : "";
          const arr = dateString.split("-");
          if (arr.length === 3) {
            const defaultYear = parseInt(arr[0]);
            const defaultMonth = parseInt(arr[1]);
            const defaultDay = parseInt(arr[2]);
            if (defaultYear && defaultMonth && defaultDay) {
              $("#" + this.config.name).val(
                moment(dateString).format("YYYY-MM-DD")
              );
              $("#date-" + this.config.name)
                .find(".sel-year")
                .val(defaultYear);
              $("#date-" + this.config.name)
                .find(".sel-month")
                .val(defaultMonth);
              $("#date-" + this.config.name)
                .find(".sel-day")
                .val(defaultDay);
            }
          }
          $("#date-" + this.config.name).on(
            "change",
            ".sel-year,.sel-month,.sel-day",
            () => {
              const dateContainer = $("#date-" + this.config.name);
              const year = dateContainer.find(".sel-year").val();
              const month = dateContainer.find(".sel-month").val();
              const day = dateContainer.find(".sel-day").val();
              if (month && year && day) {
                const newDate = moment(year + "-" + month + "-" + day).format(
                  "YYYY-MM-DD"
                );
                $("#" + this.config.name).val(newDate);
              } else {
                $("#" + this.config.name).val("");
              }
            }
          );
        }
      }

      controlClass.register("dob", ControlDob);
      return ControlDob;
    });
    window.fbControls.push((controlClass: any) => {
      class ControlConditionalOptions extends controlClass {
        build() {
          const id = this.id;
          const required = this.required || false;
          const requiredAttr = required
            ? 'required="required" aria-required="true"'
            : "";
          return `
            <div class="radio-group">
              <div class="formbuilder-radio">
                <input name="${id}" id="${id}-0" ${requiredAttr} value="Yes" type="radio"><label for="${id}-0">Yes</label>
              </div>
              <div class="formbuilder-radio">
                <input name="${id}" id="${id}-1" ${requiredAttr} value="No" type="radio"><label for="${id}-1">No</label>
              </div>
            </div>
          `;
        }
        onRender() {
          // const id = this.id;
          // const field = $(`.field-${id}`);
          // const userData = this.config.userData || [];
          // if (field.length) {
          //   field.addClass("formbuilder-radio-group");
          //   if ((userData[0] || "") != "") {
          //     field
          //       .attr("data-value", userData[0].toLowerCase())
          //       .find(`input[type=radio][value=${userData[0]}]`)
          //       .prop("checked", true);
          //   }
          //   // field.find(`input[type=radio]`).each((i, obj) => {
          //   //   $(obj).on("click", () => {
          //   //     const val = `${$(obj)?.val() || ""}`.toLowerCase();
          //   //     field.attr("data-value", val);
          //   //     if (val == "no") {
          //   //       // clear explain textbox
          //   //       $(`#explain-${id}`).val("");
          //   //     }
          //   //   });
          //   // });
          // }
        }
      }

      // register this control
      controlClass.register("conditionalOptions", ControlConditionalOptions);
      return ControlConditionalOptions;
    });

    registerMedication();
    registerAllergies();
    registerTransportation();
    registerAddressBlock();
  }
});
