import { ApiHelper } from "@/helpers";
import { FormCheckbox, FormPassword, FormSelect, FormText } from "@/types";
import StoreProductInput from "@/types/StoreProductInput";
import { ref } from "@vue/composition-api";

export function useVposProductAddStore(context: any) {
  const isACILoading = ref<boolean>(false);
  const selectedUserId = parseInt(context.root.$route.params.id);
  const dataInput = ref<StoreProductInput>({
    Name: "",
    Category: { id: 0 },
    Sku: "",
    Price: 0,
    Inventory: 0
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      Name: FormText;
      Category: FormSelect;
      Sku: FormText;
      Price: FormText;
      Inventory: FormText;
      Status: FormSelect;
    };
  }>({
    isLoading: false,
    controls: {
      Name: {
        label: "Product Name",
        required: true,
        placeholder: "Enter Product Name",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 150
      },
      Category: {
        label: "Category",
        required: true,
        placeholder: "Select Category",
        error: "",
        value: "",
        style: "custom",
        options: []
      },
      Sku: {
        label: "Sku",
        required: true,
        placeholder: "Enter Sku",
        error: "",
        type: "text",
        value: "",
        style: "custom"
      },
      Price: {
        label: "Price",
        required: true,
        placeholder: "Price",
        error: "",
        type: "date",
        value: "",
        style: "custom",
        maxlength: 150
      },
      Inventory: {
        label: "Inventory",
        required: true,
        placeholder: "Inventory",
        error: "",
        type: "date",
        value: "",
        style: "custom",
        maxlength: 50
      },
      Status: {
        label: "Status",
        required: true,
        placeholder: "Select Status",
        error: "",
        value: "1",
        style: "custom",
        options: [
          {
            id: 1,
            text: "ACTIVE"
          },
          {
            id: 2,
            text: "INACTIVE"
          }
        ]
      }
    }
  });

  const initData = async () => {
    if (!isNaN(selectedUserId)) {
      const result = await ApiHelper.callApi(
        "get",
        `/store/products/${selectedUserId}`,
        {},
        {}
      );
      if (result.status === 1) {
        formData.value.controls.Name.value = result.data.p_name;
        formData.value.controls.Category.value = result.data?.productcategoryid?.toString();
        formData.value.controls.Sku.value = result.data.p_sku;
        formData.value.controls.Price.value = result.data.p_price;
        formData.value.controls.Inventory.value = result.data.p_inventory;
        formData.value.controls.Status.value = result.data.p_status;
      }
    }
    formData.value.controls.Category.options = await ApiHelper.getCategoryOptions();
  };

  const getPageTitle = () => {
    return isNaN(selectedUserId) ? "ADD" : "EDIT";
  };

  const validateData = () => {
    let hasError = false;
    if (formData.value.controls.Name.value === "") {
      formData.value.controls.Name.error = "Product Name Required";
      hasError = true;
    }
    // eslint-disable-next-line no-constant-condition
    if (formData.value.controls.Category.value === "") {
      formData.value.controls.Category.error = "Category Required";
      hasError = true;
    }
    if (formData.value.controls.Sku.value === "") {
      formData.value.controls.Sku.error = "Sku Required";
      hasError = true;
    }
    if (formData.value.controls.Price.value === "") {
      formData.value.controls.Price.error = "Price Required";
      hasError = true;
    }
    if (formData.value.controls.Inventory.value === "") {
      formData.value.controls.Inventory.error = "Inventory Required";
      hasError = true;
    }
    return hasError;
  };

  const onSubmit = async () => {
    formData.value.isLoading = true;
    const hasError = validateData();
    if (hasError) {
      formData.value.isLoading = false;
    } else {
      dataInput.value.Name = formData.value.controls.Name.value;
      dataInput.value.Category = {
        id: Number(formData.value.controls.Category.value)
      };
      dataInput.value.Sku = formData.value.controls.Sku.value;
      dataInput.value.Price = Number(formData.value.controls.Price.value);
      dataInput.value.Inventory = Number(
        formData.value.controls.Inventory.value
      );
      dataInput.value.Status = Number(formData.value.controls.Status.value);
      const synonym = isNaN(selectedUserId) ? "post" : "put";
      if (!isNaN(selectedUserId)) {
        dataInput.value.id = selectedUserId;
      }
      const result = await ApiHelper.callApi(
        synonym,
        "/store/products",
        dataInput.value
      );
      if (result.status === 1) {
        ApiHelper.showSuccessMessage(result.message, "Store product");
        ApiHelper.gotoPage(context, "/pos/storeproducts");
      } else {
        ApiHelper.showErrorMessage("Oop! Can not create Product");
      }
      formData.value.isLoading = false;
    }
  };

  return {
    selectedUserId,
    initData,
    dataInput,
    formData,
    getPageTitle,
    onSubmit
  };
}
