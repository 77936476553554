



















































































import { defineComponent } from "@vue/composition-api";
import { useVposProductAddStore } from "@/stores/vpos/VposProductAddStore";
import FormDate from "@/components/Form/FormDate.vue";
import directives from "@/helpers/directives";
import FormYesNo from "@/components/Form/FormYesNo.vue";
import FormPassword from "@/components/Form/FormPassword.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormEmail from "@/components/Form/FormEmail.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import Loading from "@/components/Common/Loading.vue";
import FormPrice from "@/components/Form/FormPrice.vue";

export default defineComponent({
  name: "StoreProductAdd",
  components: {
    FormPrice,
    FormEmail,
    FormInput,
    FormYesNo,
    FormDate,
    Loading,
    FormPassword,
    FormSelect,
    FormNumber
  },
  directives: directives,
  setup(props, context) {
    const {
      formData,
      dataInput,
      initData,
      getPageTitle,
      onSubmit
    } = useVposProductAddStore(context);
    return {
      formData,
      dataInput,
      initData,
      getPageTitle,
      onSubmit
    };
  },
  mounted() {
    this.initData();
  }
});
