








































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import FormButton from "@/components/Form/FormButton.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";
import BackButton from "@/components/Common/BackButton.vue";
import draggable from "vuedraggable";
import ProfileDetails from "@/components/modules/ProfileDetails.vue";
import RoommateRequest from "@/components/modules/RoommateRequest.vue";
import CampStoreFund from "@/components/modules/CampStoreFund.vue";
import AddOns from "@/components/modules/AddOns.vue";
import ComingSoon from "@/components/Common/ComingSoon.vue";
import { useReportEditStore } from "@/stores/Report/ReportEditStore";
import { v4 as uuidv4 } from "uuid";
import Loading from "@/components/Common/Loading.vue";
import $ from "jquery";

require("jquery-ui-sortable");
require("formBuilder");
require("formBuilder/dist/form-render.min.js");

export default defineComponent({
  name: "ReportEditPage",
  components: {
    ComingSoon,
    Modal,
    FormButton,
    AutoCompleteInput,
    VScroller,
    BackButton,
    draggable,
    // modules
    ProfileDetails,
    RoommateRequest,
    CampStoreFund,
    AddOns,
    Loading
  },
  setup(props, context) {
    const { headerData, pageData, loadData, saveReport } = useReportEditStore(
      context
    );
    const reportId = parseInt(context.root.$route.params.id) || 0;
    const headers = ref([]);
    const labels = ref([]);
    const reportData = ref([]);
    const gettingPreviewData = ref(false);

    const toggleModule = (item: any) => {
      if (pageData.value.activeModuleId == item.moduleId) {
        // close
        pageData.value.activeModuleId = 0;
      } else {
        // open
        pageData.value.activeModuleId = item.moduleId;
      }
    };

    // init data
    (async () => {
      await loadData();
    })();

    const cloneElement = (item: any) => {
      // backup cloned item
      const newItem = { ...item, uuid: uuidv4() };
      pageData.value.clonedElement = newItem;

      return newItem;
    };

    const onAddElements = (evt: any, target: string) => {
      pageData.value.selectedElementsError = "";
      if (target == "selectedElements") {
        // remove added element if duplicate
        const fieldId = pageData.value.clonedElement.eAppFieldId || "";
        const uuid = pageData.value.clonedElement.uuid || "";
        if (fieldId != "") {
          const duplidatedElements = pageData.value.selectedElements.filter(
            item => item.eAppFieldId == fieldId
          );
          if (duplidatedElements.length > 1) {
            pageData.value.selectedElements = pageData.value.selectedElements.filter(
              item => item.uuid != uuid
            );
          }
        }
      } else if (target == "elementConditions") {
        // remove added element if duplicate
        const fieldId = pageData.value.clonedElement.eAppFieldId || "";
        const uuid = pageData.value.clonedElement.uuid || "";
        if (fieldId != "") {
          const duplidatedElements = pageData.value.elementConditions.filter(
            item => item.eAppFieldId == fieldId
          );
          if (duplidatedElements.length > 1) {
            pageData.value.elementConditions = pageData.value.elementConditions.filter(
              item => item.uuid != uuid
            );
          }
        }

        // make sure element conditions have operator field
        pageData.value.elementConditions = pageData.value.elementConditions.map(
          item => {
            item.operator = item.operator || "";
            return item;
          }
        );
      }
    };

    const selectAllEvents = () => {
      pageData.value.eventsError = "";
      if (pageData.value.selectAll) {
        pageData.value.selectedEvents = pageData.value.events.map(
          e => e.eventId
        );
      } else {
        pageData.value.selectedEvents = [];
      }
    };

    const toggleSelectedEvents = () => {
      pageData.value.eventsError = "";
      pageData.value.selectAll =
        pageData.value.selectedEvents.length == pageData.value.events.length;
    };

    const removeElement = (element: any, target: string) => {
      if (target == "selectedElements") {
        pageData.value.selectedElements = pageData.value.selectedElements.filter(
          item => item.eAppFieldId != element.eAppFieldId
        );
      } else if (target == "elementConditions") {
        pageData.value.elementConditions = pageData.value.elementConditions.filter(
          item => item.eAppFieldId != element.eAppFieldId
        );
      }
    };

    const onPut = (to: any, from: any, elemntType: string) => {
      pageData.value.selectedElementsError = "";
      const el = $(from.el);
      if (elemntType == "elementConditions") {
        // prevent drag/drop from selected columns
        if (el.length && el.hasClass("selected-columns-drag-area")) {
          return false;
        }
      } else if (elemntType == "selectedElements") {
        // prevent drag/drop from condition columns
        if (el.length && el.hasClass("condition-drag-area")) {
          return false;
        }
      }
      return true;
    };

    const previewReport = async () => {
      // reset
      headers.value = [];
      labels.value = [];
      reportData.value = [];

      gettingPreviewData.value = true;
      const response = await ApiHelper.callApi(
        "get",
        `/reports/${reportId}/preview`
      );
      if (response.status == 1) {
        headers.value = (response.data.columnHeaders || "")
          .toUpperCase()
          .split(",");
        labels.value = (response.data.columnLabels || "").split("|");
        reportData.value = response.data.items || [];
        pageData.value.previewReportVisible = true;
      } else {
        // no record found
        pageData.value.previewReportVisible = true;
      }
      gettingPreviewData.value = false;
    };

    return {
      headerData,
      pageData,
      loadData,
      saveReport,
      toggleModule,
      cloneElement,
      onAddElements,
      selectAllEvents,
      toggleSelectedEvents,
      removeElement,
      onPut,
      previewReport,
      reportId,
      gettingPreviewData,
      headers,
      labels,
      reportData
    };
  }
});
