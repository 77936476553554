import { ref } from "@vue/composition-api";
import { ApiHelper, EventHelper, ProfileHelper } from "@/helpers";
import { AlertMessageError, PagerItem, SelectOption } from "@/types";
import Vue from "vue";
import {
  getParticipantStatusText,
  ParticipantStatus
} from "@/helpers/ApiHelper";
import { EventListRows } from "@/helpers/estimateNoOfListRows";

export function useProfileEventsStore(context: any) {
  const selectedProfileId = parseInt(context.root.$route.params.profileId);
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    sort: {
      order: string;
      direction: string;
    };
    items: {
      id: number;
      isChecked: boolean;
      name: string;
      participants: {
        current: number;
        total: number;
        percent: number;
      };
      ages: string;
      date: string;
      financials: {
        income: string;
        outstanding: string;
        total: string;
      };
    }[];
    activeTab: string;
    arrFromAges: SelectOption[];
    arrToAges: SelectOption[];
    arrStatuses: SelectOption[];
    filter: {
      status: string;
      event: string;
      participants: string;
      age: string;
      date: string;
      signupDate: string;
      minSignupDate: string;
      maxSignupDate: string;
      financial: string;
      minParticipant: string;
      maxParticipant: string;
      minAge: string;
      maxAge: string;
      minTotalIncome: string;
      maxTotalIncome: string;
      minDate: string;
      maxDate: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      total: number;
      totalPages: number;
      items: PagerItem[];
      selectedItem: any;
      selectedStatusOption: any;
    };
    transferVisible: boolean;
    paying: boolean;
  }>({
    isLoading: true,
    skip: 0,
    take: EventListRows("ProfileEvents"),
    sort: {
      order: "4",
      direction: "2"
    },
    activeTab: "",
    arrFromAges: [],
    arrToAges: [],
    arrStatuses: [],
    filter: {
      status: context.root.$route.query.status
        ? context.root.$route.query.status
        : "",
      event: "",
      age: "",
      date: "",
      signupDate: "",
      minSignupDate: "",
      maxSignupDate: "",
      participants: "",
      financial: "",
      minParticipant: "",
      maxParticipant: "",
      minAge: "",
      maxAge: "",
      minTotalIncome: "",
      maxTotalIncome: "",
      minDate: "",
      maxDate: ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: [],
      selectedItem: {},
      selectedStatusOption: {}
    },
    transferVisible: false,
    paying: false
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      age: {
        value: number;
      };
      availableEvents: {
        label?: string;
        error: string;
        type: string;
        value: number;
        values: any[];
      };
    };
  }>({
    isLoading: true,
    controls: {
      age: {
        value: 0
      },
      availableEvents: {
        error: "",
        type: "text",
        value: 0,
        values: []
      }
    }
  });
  const joinedEvents = ref([]);
  const selectedProfile = ref({ id: 0, firstName: "", lastName: "" });
  const errors = ref<AlertMessageError[]>([]);
  const sidebarData = ref<any>(null);
  const participantInfo = ref<any>({
    participantId: 0,
    profileId: 0,
    paidAmount: 0,
    paidAmountFormatted: "",
    fullName: "",
    eventName: "",
    pTypeName: ""
  });

  const getItemBalance = (item: any) => {
    let balance = 0;
    const totalPaid = item.financials.totalPaid || 0;
    const totalIncome = item.financials.totalIncome || 0;

    if (item.status != ParticipantStatus.Denied) {
      balance = totalIncome - totalPaid;
    } else if (totalPaid > 0) {
      balance = -totalPaid;
    }

    return ApiHelper.dollarFormat(balance, true, {
      useBrackets: true
    });
  };

  const loadData = (page: number) => {
    ApiHelper.setDataLoading(true);
    pageData.value.pager.page = page;
    pageData.value.skip = (page - 1) * pageData.value.take;
    (async () => {
      try {
        const result = await ApiHelper.callApi(
          "get",
          "/events",
          {},
          {
            selectedProfileId: selectedProfileId,
            skip: pageData.value.skip,
            take: pageData.value.take,
            order: parseInt(pageData.value.sort.order),
            direction: parseInt(pageData.value.sort.direction),
            profileId: parseInt(context.root.$route.params.profileId + ""),
            key:
              pageData.value.filter.event !== ""
                ? pageData.value.filter.event
                : "",
            // status: pageData.value.arrStatuses
            //   .filter((item: any) => item.selected)
            //   .map((item: any) => item.id)
            //   .join("-"),
            // status: pageData.value.filter.status || "",
            activeEvent: context.root.$route.query.status === "active" ? 1 : 0,
            minTotalIncome: pageData.value.filter.minTotalIncome || "",
            maxTotalIncome: pageData.value.filter.maxTotalIncome || "",
            minDate: pageData.value.filter.minDate || undefined,
            maxDate: pageData.value.filter.maxDate || undefined,
            minSignupDate: pageData.value.filter.minSignupDate || undefined,
            maxSignupDate: pageData.value.filter.maxSignupDate || undefined,
            getTransactions: 1
          }
        );
        pageData.value.isLoading = false;
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        const events = result.data.events || [];
        pageData.value.items = result.data.events.map(
          (item: any, key: number) => {
            const addTopPopOverToolTip =
              (events.length > 3 && key > events.length - 3) ||
              (key == events.length - 1 && events.length > 2)
                ? true
                : false;
            return {
              addTopPopOverToolTip,
              id: item.id,
              isActive: item.isActive || 0,
              status: item.participantStatus,
              statusText: getParticipantStatusText(item.participantStatus),
              isChecked: false,
              participantId: item.participantID,
              profileId: parseInt(context.root.$route.params.profileId + ""),
              name: item.name,
              date: item.tsStartFormatted + " - " + item.tsEndFormatted,
              financials: {
                totalPaid: item.income || 0,
                income: ApiHelper.dollarFormat(item.income, true),
                outstanding: ApiHelper.dollarFormat(
                  item.totalIncome - item.income,
                  true
                ),
                totalIncome: item.totalIncome,
                total: ApiHelper.dollarFormat(item.totalIncome, true),
                percent: ApiHelper.getPercent(item.income, item.totalIncome)
              },
              stats: item.stats,
              pdatetime: item.pdatetimeFormatted || "",
              pTypeId: item.pTypeId || 0,
              pTypeName: item.pTypeName || "",
              planId: item.planId || "",
              subState: item.subState || ""
            };
          }
        );
        joinedEvents.value = result.data.joinedEvents || [];
        try {
          formData.value.controls.availableEvents.values.map((event: any) => {
            event.checked = false;
            const foundEvent = joinedEvents.value.find((joinedEvent: any) => {
              return (
                joinedEvent.participant_typeID === event.participantTypeID &&
                joinedEvent.eventID === event.eventID
              );
            });
            if (foundEvent) {
              event.registered = 1;
            } else {
              event.registered = 0;
            }
          });
        } catch (e) {
          // joinedEvents.value = [];
        }

        ApiHelper.setDataLoading(false);
      } catch (err) {
        ApiHelper.setDataLoading(false);
        // errors.value = [err];
      }
    })();
  };

  const gotoPage = (page: string) => {
    loadData(parseInt(page));
  };

  const onPagerChange = (event: any) => {
    loadData(parseInt(event.target.value));
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      loadData(pageData.value.pager.page - 1);
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      loadData(pageData.value.pager.page + 1);
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const toggleHeadActions = () => {
    pageData.value.showHeadActions = !pageData.value.showHeadActions;
  };

  const hideHeadActions = () => {
    pageData.value.showHeadActions = false;
  };

  const getCheckedItems = () => {
    return pageData.value.items.filter(item => item.isChecked);
  };

  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "event":
        pageData.value.filter.event = value;
        break;
      case "participants": {
        pageData.value.filter.participants = value;
        const participantsArr = value.split("-");
        pageData.value.filter.minParticipant = participantsArr[0];
        pageData.value.filter.maxParticipant =
          participantsArr[1] !== undefined ? participantsArr[1] : "";
        break;
      }
      case "date": {
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.filter.minDate = dateArr[0];
        pageData.value.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        break;
      }
      case "signupDate": {
        pageData.value.filter.signupDate = value;
        const dateArr = value.split("-");
        pageData.value.filter.minSignupDate = dateArr[0];
        pageData.value.filter.maxSignupDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        break;
      }
      case "age": {
        pageData.value.filter.age = value;
        const ageArr = value.split("-");
        pageData.value.filter.minAge = ageArr[0];
        pageData.value.filter.maxAge = ageArr[1] !== undefined ? ageArr[1] : "";
        break;
      }
      case "financial": {
        pageData.value.filter.financial = value;
        const totalArr = value.split("-");
        pageData.value.filter.minTotalIncome = totalArr[0];
        pageData.value.filter.maxTotalIncome =
          totalArr[1] !== undefined ? totalArr[1] : "";
        break;
      }
    }
    loadData(1);
  };

  const getFiltersData = () => {
    const filters = [];
    const selectedStatuses = pageData.value.arrStatuses
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedStatuses.length > 0) {
      filters.push({
        label: "STATUS",
        key: selectedStatuses.join("-") + Math.random(),
        value: selectedStatuses.join(", "),
        reset: () => {
          pageData.value.arrStatuses.map((item: any) => {
            item.selected = false;
          });
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.participants !== "") {
      filters.push({
        label: "PARTICIPANTS",
        key: pageData.value.filter.participants + Math.random(),
        value: pageData.value.filter.participants,
        reset: () => {
          pageData.value.filter.participants = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.event !== "") {
      filters.push({
        label: "EVENT",
        key: pageData.value.filter.event + Math.random(),
        value: pageData.value.filter.event,
        reset: () => {
          pageData.value.filter.event = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.age !== "") {
      filters.push({
        label: "AGE",
        key: pageData.value.filter.age + Math.random(),
        value: pageData.value.filter.age,
        reset: () => {
          pageData.value.filter.age = "";
          loadData(1);
        }
      });
    }
    if (
      pageData.value.filter.minDate !== "" ||
      pageData.value.filter.maxDate !== ""
    ) {
      const displayValue =
        (pageData.value.filter.minDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.minDate
            )
          : "") +
        " - " +
        (pageData.value.filter.maxDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.maxDate
            )
          : "");

      filters.push({
        label: "DATE",
        key: pageData.value.filter.date + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          loadData(1);
        }
      });
    }
    if (
      pageData.value.filter.minSignupDate !== "" ||
      pageData.value.filter.maxSignupDate !== ""
    ) {
      const displayValue =
        (pageData.value.filter.minSignupDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.minSignupDate
            )
          : "") +
        " - " +
        (pageData.value.filter.maxSignupDate != ""
          ? ApiHelper.convertYYYYMMDDtoDDMMYYString(
              pageData.value.filter.maxSignupDate
            )
          : "");
      filters.push({
        label: "SIGNUP DATE",
        key: pageData.value.filter.signupDate + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.signupDate = "";
          pageData.value.filter.minSignupDate = "";
          pageData.value.filter.maxSignupDate = "";
          loadData(1);
        }
      });
    }
    if (
      pageData.value.filter.minTotalIncome !== "" ||
      pageData.value.filter.maxTotalIncome !== ""
    ) {
      filters.push({
        label: "FINANCIALS",
        key: pageData.value.filter.financial + Math.random(),
        value: pageData.value.filter.financial,
        reset: () => {
          pageData.value.filter.financial = "";
          pageData.value.filter.minTotalIncome = "";
          pageData.value.filter.maxTotalIncome = "";
          loadData(1);
        }
      });
    }
    return {
      list: filters
    };
  };

  const popupEditApplication = ref({
    show: false,
    profileId: 0,
    participantId: 0
  });

  const popupNewEvent = ref<{
    show: boolean;
    isAddNewSuccess: boolean;
    formData: any;
    onSubmit: any;
  }>({
    show: false,
    isAddNewSuccess: false,
    formData: formData,
    onSubmit: async ($parent: any) => {
      const selectedEvents = formData.value.controls.availableEvents.values.filter(
        item => item.checked
      );
      if (selectedEvents.length > 0) {
        popupNewEvent.value.show = false;
        ApiHelper.setDataLoading(true);
        const eventID = selectedEvents[0].eventID;
        const participantTypeID = selectedEvents[0].participantTypeID;
        const ParticipantAmount = selectedEvents[0].participantAmount;
        const ParticipantDeposit = selectedEvents[0].participantDeposit;
        const selectedFullName =
          selectedProfile.value.firstName +
          " " +
          selectedProfile.value.lastName;
        const eventName = selectedEvents[0].eventName;
        const result = await ApiHelper.callApi(
          "post",
          "/participants",
          {
            eventId: eventID,
            participantTypeId: participantTypeID,
            appJson: "[]",
            cost: ParticipantAmount,
            pdatetime: new Date(),
            entityId: 1,
            profileId: selectedProfileId,
            entityAppId: 1
          },
          {}
        );
        ApiHelper.setDataLoading(false);
        if (result.status == 1) {
          context.root.$router
            .replace({
              name: context.root.$route.name,
              params: context.root.$route.params,
              query: {
                ...context.root.$route.query,
                status: undefined
              }
            })
            .catch((err: any) => {
              console.log("update routers error");
            });

          const newId = parseInt(result.data.id);
          popupEditApplication.value.profileId = selectedProfileId;
          popupEditApplication.value.participantId = newId;
          const totalApp = result.data.totalApp || 0;
          if (totalApp) {
            popupEditApplication.value.show = true;
          }
          popupNewEvent.value.isAddNewSuccess = true;
          ApiHelper.showSuccessMessage(
            `"${selectedFullName}" has been added to "${eventName}"`
          );
          loadData(1);
          const parent = $parent;
          if (parent.$refs.SidebarProfileRef) {
            parent.$refs.SidebarProfileRef.loadData();
          }
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      } else {
        ApiHelper.showErrorMessage("Please select an event");
      }
    }
  });

  const selectEvent = async (item: any) => {
    let minAge = 0;
    let maxAge = 0;
    const arr = (item.eventAges || "").split("-");
    if (arr.length == 2) {
      minAge = Number.parseInt(arr[0], 10) || 0;
      maxAge = Number.parseInt(arr[1], 10) || 0;
    } else if (item.eventAges) {
      const limitAge = Number.parseInt(item.eventAges, 10) || 0;
      if (limitAge > 0) {
        minAge = limitAge;
      }
    }
    let ageError = "";
    const memberAge = formData.value.controls.age.value;
    if (minAge) {
      if (memberAge < minAge) {
        ageError =
          "Age should be greater than <strong>" +
          minAge +
          "</strong>, would you like to proceed?";
      }
    }
    if (maxAge) {
      if (memberAge > maxAge) {
        ageError =
          "Age should be less than <strong>" +
          maxAge +
          "</strong>, would you like to proceed?";
      }
    }

    if (!item.checked && ageError) {
      const isConfirmed = await Vue.swal({
        title: "Are you sure?",
        html: ageError,
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });
      if (!isConfirmed) {
        return;
      }
    }

    if (item.checked) {
      item.checked = false;
      return false;
    }
    if (item.registered == 0) {
      const foundEvent: any = joinedEvents.value.find((joinedEvent: any) => {
        return joinedEvent.eventID === item.eventID;
      });
      if (foundEvent) {
        const message =
          "<strong>" +
          (selectedProfile.value
            ? selectedProfile.value.firstName +
              " " +
              selectedProfile.value.lastName
            : "") +
          "</strong> is already registered for <strong>" +
          foundEvent.eventName +
          "</strong> as a <strong>" +
          foundEvent.participantTypeName +
          "</strong>, would you like to proceed?";
        Vue.swal({
          title: "Are you sure?",
          html: message,
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          if (result.isConfirmed) {
            formData.value.controls.availableEvents.values.map((event: any) => {
              if (event.registered !== 1) {
                event.checked = false;
              }
            });
            item.checked = true;
            formData.value.controls.availableEvents.error = "";
          }
        });
      } else {
        formData.value.controls.availableEvents.values.map((event: any) => {
          if (event.registered !== 1) {
            event.checked = false;
          }
        });
        item.checked = true;
        formData.value.controls.availableEvents.error = "";
      }
    }
  };

  // init data
  (async () => {
    pageData.value.arrFromAges = ApiHelper.getFromAgeOptions();
    pageData.value.arrToAges = ApiHelper.getToAgeOptions();
    pageData.value.arrStatuses = EventHelper.getEventStatusOptions();

    loadData(1);
  })();

  const isArchived = ref(false);
  (async () => {
    const profileInfo = await ProfileHelper.getProfileInfo(selectedProfileId);
    formData.value.controls.age.value = profileInfo.age || 0;
    selectedProfile.value.id = profileInfo.id;
    selectedProfile.value.lastName = profileInfo.lastName || "";
    selectedProfile.value.firstName = profileInfo.firstName || "";
    isArchived.value = profileInfo.status === 2;
  })();

  const loadAvailableEvents = () => {
    (async () => {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/events/available",
        {},
        {
          profileId: selectedProfileId
        }
      );
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      if (result.status === 1) {
        formData.value.controls.availableEvents.values = result.data.availableEvents.map(
          (item: any) => {
            return { ...item, checked: false, registered: 0 };
          }
        );
        popupNewEvent.value.show = true;
      }
    })();
  };

  const updateParticipantStatus = async (
    item: any,
    statusOption: any,
    callBack?: any,
    noConfirm?: boolean
  ) => {
    const currentStatus = item.status || 0;
    const participantId = item.participantId;
    let ignoreWaitlistStatus = 0;
    if (currentStatus == ParticipantStatus.Waitlist) {
      ignoreWaitlistStatus = 1;
    }
    if (
      statusOption.value === ParticipantStatus.Denied ||
      statusOption.value == ParticipantStatus.Cancelled
    ) {
      let message = "Are you sure you want to deny this participant?";
      if (statusOption.value == ParticipantStatus.Cancelled) {
        message = "Are you sure you want to cancel this participant?";
      }
      if (item.planId != "" && item.subState == "active") {
        // notify if this participant belongs a plan
        message += `<div>Related plan <strong>#${item.planId}</strong> will be cancelled</div>`;
      }
      if (!noConfirm) {
        const isConfirm = await Vue.swal({
          title: "Are you sure?",
          html: message,
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          return result.isConfirmed;
        });
        if (!isConfirm) {
          return false;
        }
      }
    }
    if (!noConfirm) {
      if (
        (statusOption.value === ParticipantStatus.Denied ||
          statusOption.value === ParticipantStatus.Cancelled) &&
        item.financials.totalPaid
      ) {
        // Force refund
        pageData.value.pager.selectedItem = item;
        pageData.value.pager.selectedStatusOption = statusOption;
        // show transfer modal
        sidebarData.value = null;
        pageData.value.transferVisible = true;
        participantInfo.value = {
          participantId: item.participantId || 0,
          profileId: item.profileId || 0,
          paidAmount: item.financials.totalPaid || 0,
          paidAmountFormatted: item.financials.income || "",
          fullName: "",
          eventId: item.id || 0,
          eventName: item.name || "",
          pTypeName: item.pTypeName
        };
        ApiHelper.showSuccessMessage(
          `Participant paid for event. Please you have to refund/transfer the transactions berfore ${
            statusOption.value === ParticipantStatus.Denied ? "deny" : "cancel"
          } #` + item.id
        );
        //End force refund
      }
    }
    // Check total paid before allow denied
    if (
      ((statusOption.value !== ParticipantStatus.Denied &&
        statusOption.value !== ParticipantStatus.Cancelled) ||
        !item.financials.totalPaid) &&
      !pageData.value.transferVisible
    ) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "patch",
        "/participants/" + participantId + "/status",
        {
          status: statusOption.value,
          ignoreWaitlist: ignoreWaitlistStatus,
          planId: item.planId
        },
        {}
      );
      if (result.status === 1) {
        popupNewEvent.value.isAddNewSuccess = true;
        ApiHelper.setDataLoading(false);
        if (callBack) {
          callBack();
        }
        item.statusText = getParticipantStatusText(item.status);

        const parent: any = context.parent || null;
        if (parent && parent.$refs.SidebarProfileRef) {
          parent.$refs.SidebarProfileRef.loadData();
        }
        if (
          statusOption.value === ParticipantStatus.Denied ||
          statusOption.value === ParticipantStatus.Cancelled
        ) {
          if (pageData.value.items.length > 1) {
            loadData(pageData.value.pager.page);
          } else {
            loadData(1);
          }

          // show refund message if paid for the event
          const totalPaid = result.data.totalPaid || 0;
          if (totalPaid > 0) {
            const root: any = context.root || {};
            const allowedFunctions = root.$allowedFunctions || [];
            const userFunctions = root.$userFunctions;
            const confirm = await Vue.swal({
              title: "Denied successfully",
              html:
                "Participant paid for event. Please check if you want to refund/transfer the transactions?",
              showCancelButton: true,
              showDenyButton: true,
              showConfirmButton: allowedFunctions.includes(
                userFunctions.Transactions
              ),
              confirmButtonText: "Check transactions",
              denyButtonText: "Transfer",
              showCloseButton: true,
              closeButtonHtml:
                '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">',
              customClass: {
                container: "swal2-actions-p0",
                denyButton: "swal2-blue-btn"
              }
            });
            if (confirm.isConfirmed) {
              setTimeout(function() {
                $(".swal2-backdrop-hide").addClass("d-none");
              }, 200);
              // goto transaction page for this participant
              // const routeData = context.root.$router.resolve({
              //   name: "FinancialTransactions",
              //   query: { participantIds: participantId }
              // });
              // window.open(routeData.href, "_blank");
              context.root.$router.push({
                name: "FinancialTransactions",
                query: { participantIds: participantId }
              });
            } else if (confirm.isDenied) {
              setTimeout(function() {
                $(".swal2-backdrop-hide").addClass("d-none");
              }, 200);
              // show transfer modal
              sidebarData.value = null;
              pageData.value.transferVisible = true;
              participantInfo.value = {
                participantId: item.participantId || 0,
                profileId: item.profileId || 0,
                paidAmount: item.financials.totalPaid || 0,
                paidAmountFormatted: item.financials.income || "",
                fullName: "",
                eventId: item.id || 0,
                eventName: item.name || "",
                pTypeName: item.pTypeName
              };
            }
          }
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.setDataLoading(false);
      }
    }
  };

  return {
    popupEditApplication,
    loadAvailableEvents,
    isArchived,
    selectEvent,
    popupNewEvent,
    errors,
    pageData,
    // pager
    gotoPage,
    getCheckedItems,
    togglePagerItems,
    toggleHeadActions,
    hideHeadActions,
    onClickPrev,
    onClickNext,
    onPagerChange,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    getFiltersData,
    updateParticipantStatus,
    sidebarData,
    participantInfo,
    loadData,
    getItemBalance
  };
}
