var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-inside"},[_vm._m(0),_c('div',{staticClass:"content__table__scroll pt60"},[_c('div',{staticClass:"content_table table__items table__persion"},[_c('div',{staticClass:"camp_table"},[_c('table',{staticClass:"table__checkbox"},[_c('thead',[_c('tr',[_c('th',{staticClass:"hasCheckbox",attrs:{"colspan":"2"}},[_c('FilterSearchSuggestColumn',{attrs:{"label":'People',"active-tab":_vm.pageData.activeTab,"active-value":'profile',"min-characters":3,"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.filter.profile,"on-change-value":_vm.updateFilterValue,"sort-data":_vm.pageData.sort,"sort-key":'1',"sortKeyTitle":'Sort by first names',"on-change-sort-value":_vm.updateSortValue,"sortKey2":'7',"sortKey2Title":'Sort by last names'}})],1),(_vm.showFamily())?_c('th',[_c('FilterCheckboxesColumn',{attrs:{"label":'Family',"isACILoading":_vm.pageData.selectedFamiliesLoading,"active-tab":_vm.pageData.activeTab,"active-value":'family',"other-option":_vm.pageData.filter.unassignedFamily,"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.filter.family,"close-filter-tab":_vm.closeFilterTab,"on-select-all":_vm.selectAllFamilies,"on-reset-value":_vm.resetFamilies,"on-change-value":function () {
                      _vm.pageData.isAddFilterFamily = true;
                      _vm.pageData.pager.page = 1;
                      _vm.updateRouters();
                    },"sort-data":_vm.pageData.sort,"sort-key":'2',"on-change-sort-value":_vm.updateSortValue,"lazyLoad":true,"lazyLoadPlaceholder":"Search family...","searchValue":_vm.pageData.searchFamiliesValue},on:{"searchItems":_vm.pageData.searchFamilies}})],1):_vm._e(),(_vm.showAge())?_c('th',{staticClass:"table__center"},[_c('FilterNumberRangeColumn',{attrs:{"label":'Age',"placeholder":'Age',"active-tab":_vm.pageData.activeTab,"active-value":'age',"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.filter.age,"on-change-value":_vm.updateFilterValue,"data":{},"sort-data":_vm.pageData.sort,"sort-key":'3',"on-change-sort-value":_vm.updateSortValue}})],1):_vm._e(),(_vm.showGender())?_c('th',[_c('FilterCheckboxesColumn',{attrs:{"label":'Gender',"active-tab":_vm.pageData.activeTab,"active-value":'gender',"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.filter.gender,"close-filter-tab":_vm.closeFilterTab,"on-select-all":_vm.selectAllGenders,"on-reset-value":_vm.resetGenders,"on-change-value":function () {
                      _vm.pageData.isAddFilterGender = true;
                      _vm.pageData.pager.page = 1;
                      _vm.updateRouters();
                    },"sort-data":_vm.pageData.sort,"sort-key":'4',"on-change-sort-value":_vm.updateSortValue}})],1):_vm._e(),(_vm.showEmail())?_c('th',[_c('FilterSearchColumn',{attrs:{"label":'Email',"active-tab":_vm.pageData.activeTab,"active-value":'email',"data":{},"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.filter.email,"on-change-value":_vm.updateFilterValue,"sort-data":_vm.pageData.sort,"sort-key":'8',"on-change-sort-value":_vm.updateSortValue}})],1):_vm._e(),(_vm.showPhone())?_c('th',[_c('FilterSearchColumn',{attrs:{"label":'Phone',"active-tab":_vm.pageData.activeTab,"active-value":'phone',"data":{},"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.filter.phone,"on-change-value":_vm.updateFilterValue,"sort-data":_vm.pageData.sort,"sort-key":'9',"on-change-sort-value":_vm.updateSortValue}})],1):_vm._e(),(_vm.showEvents())?_c('th',{staticClass:"table__center"},[_c('FilterCheckboxesColumn',{attrs:{"label":'Events (Total / Active)',"active-tab":_vm.pageData.activeTab,"isACILoading":_vm.pageData.searchEventsLoading,"active-value":'events',"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.filter.events,"other-option":_vm.pageData.filter.unassignedEvent,"close-filter-tab":_vm.closeFilterTab,"on-select-all":_vm.selectAllEvents,"on-reset-value":_vm.resetEvents,"on-change-value":function (item) {
                      _vm.pageData.isAddFilterEvent = true;
                      _vm.pageData.pager.page = 1;
                      _vm.updateRouters();
                    },"sort-data":_vm.pageData.sort,"sort-key":'5',"on-change-sort-value":_vm.updateSortValue,"lazyLoad":true,"lazyLoadPlaceholder":"Search event...","searchValue":_vm.pageData.searchEventsValue},on:{"searchItems":_vm.pageData.searchEvents}})],1):_vm._e(),(_vm.showBalance())?_c('th',{staticClass:"table__right"},[_c('FilterNumberRangeColumn',{attrs:{"label":'Balance',"placeholder":'Balance',"active-tab":_vm.pageData.activeTab,"active-value":'balance',"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.filter.balance,"on-change-value":_vm.updateFilterValue,"data":{},"sort-data":_vm.pageData.sort,"sort-key":'6',"on-change-sort-value":_vm.updateSortValue,"allow-negative-number":true}})],1):_vm._e()])]),_c('tbody',_vm._l((_vm.pageData.items),function(item,index){return _c('tr',{key:index,class:{ checked: item.isChecked }},[_c('td',{staticClass:"col__0"},[(_vm.allowSelection)?_c('div',{staticClass:"item__checkbox",class:{ checked: item.isChecked }},[_c('label',{class:{ checked: item.isChecked }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.isChecked),expression:"item.isChecked"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.isChecked)?_vm._i(item.isChecked,null)>-1:(item.isChecked)},on:{"change":[function($event){var $$a=item.isChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "isChecked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "isChecked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "isChecked", $$c)}},function($event){return _vm.selectItem(item)}]}})])]):_vm._e()]),_c('td',{staticClass:"pl-0"},[_c('router-link',{staticClass:"d-flex align-items-center justify-items-center",attrs:{"to":{
                    name: 'ProfileDetails',
                    params: {
                      profileId: item.id.toString()
                    }
                  }}},[_c('div',{staticClass:"item__avatar"},[_c('Avatar',{attrs:{"src":item.logo,"username":item.firstName + ' ' + item.lastName || '-',"size":31}})],1),_c('span',{staticClass:"textOverflow main-title pl-3",attrs:{"title":item.fullName}},[_vm._v(" "+_vm._s(item.fullName || "-")+" ")])])],1),(_vm.showFamily())?_c('td',{staticClass:"item"},[(item.families.length > 0)?_vm._l((item.families),function(family,familyIndex){return _c('router-link',{key:familyIndex,staticClass:"item__name text-link w-100",class:item.families.length == 1 ? 'm-0' : '',attrs:{"to":{
                      name: 'ProfileTribes',
                      params: {
                        profileId: item.id.toString()
                      },
                      query: {
                        family: family.familyId
                      }
                    }}},[_c('div',{staticClass:"item__name textOverflow d-flex",class:item.families.length == 1 ? 'm-0' : '',attrs:{"title":family.familyName}},[_vm._v(" "+_vm._s(family.familyName || "-")+" "),_c('span',{staticClass:"item__number"},[_vm._v(_vm._s(family.totalSubProfiles))])])])}):_vm._e()],2):_vm._e(),(_vm.showAge())?_c('td',{staticClass:"table__center"},[_c('span',[_vm._v(_vm._s(item.age || "N/A"))])]):_vm._e(),(_vm.showGender())?_c('td',[_c('span',[_vm._v(_vm._s(item.gender || "-"))])]):_vm._e(),(_vm.showEmail())?_c('td',[_c('span',{staticClass:"textOverflow",attrs:{"title":item.email}},[_vm._v(_vm._s(item.email || "-"))])]):_vm._e(),(_vm.showPhone())?_c('td',[_c('span',[_vm._v(_vm._s(item.phone || "-"))])]):_vm._e(),(_vm.showEvents())?_c('td',{staticClass:"table__center"},[_c('span',{staticClass:"event-active-total"},[(item.totalEvents)?_c('router-link',{staticClass:"text-link mr-4 underline",attrs:{"to":{
                      name: 'ProfileEvents',
                      params: {
                        profileId: item.id.toString()
                      }
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(item.totalEvents || 0)+" Total ")])]}}],null,true)}):_c('span',{staticClass:"mr-4 opacity-50"},[_vm._v("0 Total")]),(item.activeEvents)?_c('router-link',{staticClass:"text-link underline",attrs:{"to":{
                      name: 'ProfileEvents',
                      params: {
                        profileId: item.id.toString()
                      },
                      query: {
                        status: 'active'
                      }
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(item.activeEvents || 0)+" Active ")])]}}],null,true)}):_c('span',{staticClass:"opacity-50"},[_vm._v("0 Active")])],1)]):_vm._e(),(_vm.showBalance())?_c('td',{staticClass:"table__right"},[_c('span',{class:{
                    'text-danger': item.balance < 0
                  }},[_vm._v(_vm._s(item.balanceFormatted || "-"))])]):_vm._e()])}),0)])]),(!_vm.pageData.isLoading && _vm.pageData.items.length < 1)?_c('div',[_c('NoData',{attrs:{"size":"lg","text":'NOTHING TO SEE HERE QUITE YET...ZERO PEOPLE.'}})],1):_vm._e()])]),_c('Pager',{attrs:{"goto-page":_vm.gotoPage,"on-click-next":_vm.onClickNext,"page-data":_vm.pageData,"on-click-prev":_vm.onClickPrev,"toggle-pager-items":_vm.togglePagerItems}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"filter__mobile__heading"},[_vm._v(" Profiles"),_c('span',{staticClass:"filter__number"},[_vm._v("94")])])}]

export { render, staticRenderFns }