import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormSelect, FormText } from "@/types";
import moment from "moment";
import FormDate from "@/types/FormDate";

export function useSettingsDiscountEditStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const headerData = ref({
    title: selectedId > 0 ? "Edit Discount" : "Add Discount",
    subTitle: "Discounts"
  });

  const pageData = ref<{
    isLoading: boolean;
    controls: {
      name: FormText;
      code: FormText;
      discountAmount: FormText;
      discountPercent: FormText;
      event: FormSelect;
      participantType: FormSelect;
      transactionType: FormSelect;
      type: FormSelect;
      availableDate: FormDate;
      maxUse: FormText;
      active: FormText;
    };
    rmAllowAcross: boolean;
  }>({
    isLoading: true,
    controls: {
      name: {
        required: true,
        label: "Discount Name",
        placeholder: "Discount name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100
      },
      code: {
        required: false,
        label: "Discount Code",
        placeholder: "Discount Code",
        style: "custom",
        value: "",
        error: "",
        maxlength: 30
      },
      discountAmount: {
        required: true,
        label: "Discount Amount",
        placeholder: "Discount Amount",
        style: "custom",
        value: "",
        error: "",
        maxlength: 10
      },
      discountPercent: {
        required: true,
        label: "Discount Percent",
        placeholder: "Discount Percent",
        style: "custom",
        value: "",
        error: "",
        maxlength: 3
      },
      event: {
        notRequired: true,
        required: false,
        label: "Event",
        placeholder: "Event",
        style: "custom",
        value: "",
        error: "",
        options: []
      },
      participantType: {
        notRequired: true,
        required: false,
        label: "Participant Type",
        placeholder: "Participant Type",
        style: "custom",
        value: "",
        error: "",
        options: []
      },
      transactionType: {
        notRequired: true,
        required: false,
        label: "Transaction Type",
        placeholder: "Transaction Type",
        style: "custom",
        value: "",
        error: "",
        options: [
          {
            id: 1,
            text: "Discount"
          },
          {
            id: 2,
            text: "Scholarship"
          }
        ]
      },
      type: {
        required: true,
        label: "Type",
        placeholder: "Type",
        style: "custom",
        value: "",
        error: "",
        options: []
      },
      availableDate: {
        required: true,
        label: "Available Until",
        placeholder: "Available Until",
        style: "custom",
        type: "date",
        value: "",
        error: ""
      },
      maxUse: {
        required: true,
        label: "Max Use",
        placeholder: "Max Use",
        style: "custom",
        value: "",
        error: "",
        maxlength: 10
      },
      active: {
        required: true,
        label: "Active",
        placeholder: "Active",
        style: "custom",
        value: "",
        error: ""
      }
    },
    rmAllowAcross: false
  });

  const validateAll = () => {
    let hasError = false;
    if (pageData.value.controls.name.value === "") {
      pageData.value.controls.name.error = "Discount name is required";
      hasError = true;
    }
    if (pageData.value.controls.discountAmount.value === "") {
      pageData.value.controls.name.error = "Discount amount is required";
      hasError = true;
    }
    if (
      pageData.value.controls.event.value === "" &&
      pageData.value.controls.participantType.value === ""
    ) {
      pageData.value.controls.event.error = "Event is required";
      pageData.value.controls.participantType.error =
        "Participant Type is required";
      hasError = true;
    }
    return hasError;
  };

  const onSubmit = async () => {
    const hasError = validateAll();
    if (hasError) {
      return;
    }

    const result = await ApiHelper.callApi(
      "put",
      `/discounts/${selectedId}`,
      {
        name: pageData.value.controls.name.value,
        code: pageData.value.controls.code.value,
        participantTypeId: pageData.value.controls.participantType.value,
        eventId: pageData.value.controls.event.value,
        discountAmount: pageData.value.controls.discountAmount.value,
        discountPercent: pageData.value.controls.discountPercent.value,
        active: pageData.value.controls.active.value,
        availableDate: pageData.value.controls.availableDate.value
          ? moment(
              moment(pageData.value.controls.availableDate.value).format(
                "YYYY-MM-DD"
              ) + "T00:00:00.000Z"
            ).toDate()
          : "",
        transactionType: pageData.value.controls.transactionType.value,
        maxUse: pageData.value.controls.maxUse.value
      },
      {}
    );
    if (result.status == 1) {
      ApiHelper.showSuccessMessage(selectedId ? "Updated" : "Added");
      ApiHelper.gotoPage(context, {
        name: "SettingsDiscounts"
      });
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      return;
    }
  };
  const loadPtypeOptions = async (eventId: number) => {
    if (eventId) {
      const result = await ApiHelper.callApi(
        "get",
        `/events/${eventId}/ptypes`,
        {},
        {
          getAll: 1
        }
      );
      if (result.status === 1) {
        pageData.value.controls.participantType.options = result.data.ptypes.map(
          (item: any) => ({
            id: item.value,
            text: item.text
          })
        );
        const findItem = pageData.value.controls.participantType.options.find(
          item => item.id == pageData.value.controls.participantType.value
        );
        if (!findItem) {
          pageData.value.controls.participantType.value = "";
        }
      }
    } else {
      const result = await ApiHelper.callApi(
        "get",
        "/participants/ptypes",
        {},
        {
          getAll: 1
        }
      );
      if (result.status === 1) {
        pageData.value.controls.participantType.options = result.data.ptypes.map(
          (item: any) => ({
            id: item.id,
            text: item.name
          })
        );
      }
    }
  };
  const loadData = async () => {
    if (selectedId > 0) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        `/discounts/${selectedId}`,
        {},
        {}
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const cabin = result.data || {};
        pageData.value.controls.name.value = cabin.discountName || "";
        pageData.value.controls.code.value = cabin.discountCode || "";
        pageData.value.controls.maxUse.value = cabin.maxUse || "";
        pageData.value.controls.discountAmount.value =
          cabin.discountAmount || "";
        pageData.value.controls.discountPercent.value =
          cabin.discountPercent || "";
        pageData.value.controls.availableDate.value = cabin.availableToDateFormatted
          ? moment(cabin.availableToDateFormatted).toDate()
          : undefined;
        pageData.value.controls.event.value = cabin.eventId || "";
        pageData.value.controls.participantType.value =
          cabin.participantTypeId || "";
        pageData.value.controls.transactionType.value =
          cabin.transactionType || "";
        pageData.value.controls.maxUse.value = cabin.maxUse || "";
        pageData.value.controls.active.value = cabin.active || false;
        // load ptype options
        await loadPtypeOptions(cabin.eventId || 0);
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "SettingsDiscounts" });
      }
    } else {
      await loadPtypeOptions(0);
      ApiHelper.setDataLoading(false);
    }
  };

  // init data
  (async () => {
    loadData();
  })();

  (async () => {
    pageData.value.controls.event.options = await ApiHelper.getEventOptions({
      getAll: 1
    });
  })();

  return {
    loadPtypeOptions,
    loadData,
    headerData,
    pageData,
    onSubmit
  };
}
