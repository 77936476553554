



























































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "FormTextarea",
  props: {
    data: Object,
    forceRequired: Boolean,
    hideErrorMessage: Boolean,
    onChange: Function
  },
  setup(props, context) {
    const randomId = ref<number>(ApiHelper.randomFormId());

    const onChanged = () => {
      if (props.onChange) {
        props.onChange();
      }
    };

    return {
      randomId: randomId.value.toString(),
      stripTags: ApiHelper.stripTags,
      onChanged
    };
  }
});
