










import { defineComponent } from "@vue/composition-api";
import VScroller from "@/components/Common/VScroller.vue";

export default defineComponent({
  name: "ReportsMaster",
  components: {
    VScroller
  },
  setup(props, context) {
    return {};
  }
});
