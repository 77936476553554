
























import { ref, defineComponent } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import Header from "@/components/Common/Header.vue";
import ComingSoon from "@/components/Common/ComingSoon.vue";
import NavEventDetails from "@/components/Event/NavEventDetails.vue";

type PageData = {
  isLoading: boolean;
  pageTitle: string;
};

export default defineComponent({
  name: "EventAtCampPage",
  components: {
    ComingSoon,
    HeaderEventDetails,
    SidebarEventDetails,
    VAlertMessage,
    Loading,
    Header,
    NavEventDetails
  },
  setup(props, context) {
    const pageData = ref<PageData>({
      isLoading: false,
      pageTitle: ""
    });
    const getEventNameFromSidebar = ($parent: any) => {
      const SidebarEventDetailsRef = $parent.$refs.SidebarEventDetailsRef || {};
      const sidebarData = SidebarEventDetailsRef.sidebarData || {};
      return sidebarData.name || "";
    };

    return {
      getEventNameFromSidebar,
      pageData
    };
  }
});
