import { ref } from "@vue/composition-api";
import { AlertMessageError } from "@/components/VAlertMessage.vue";
import { PagerItem, SelectOption } from "@/types";
import { ApiHelper } from "@/helpers";
import Vue from "vue";
import { ApiType } from "@/helpers/ApiHelper";
import { ProfileListRows } from "@/helpers/estimateNoOfListRows";

export function useMessagingTemplatesStore(context: any) {
  const headerData = ref({
    title: "Templates",
    subTitle: "Messaging"
  });

  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: {
      id: number;
      isChecked: boolean;
      name: string;
    }[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      name: string;
      user: string;
      date: string;
      minDate: string;
      maxDate: string;
    };
    showHeadActions: boolean;
    openItemId: number;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
  }>({
    isLoading: true,
    skip: 0,
    take: ProfileListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {
      name: context.root.$route.query.name || "",
      user: context.root.$route.query.user || "",
      date:
        (context.root.$route.query.minDate || "") +
        "-" +
        (context.root.$route.query.maxDate || ""),
      minDate: context.root.$route.query.minDate || "",
      maxDate: context.root.$route.query.maxDate || ""
    },
    items: [],
    showHeadActions: false,
    openItemId: 0,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    }
  });

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: "MessagingTemplates",
        query: {
          page: pageData.value.pager.page + "" || undefined,
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          key:
            pageData.value.filter.name !== "" ? pageData.value.filter.name : "",
          user:
            pageData.value.filter.user !== "" ? pageData.value.filter.user : "",
          minDate: pageData.value.filter.minDate || "",
          maxDate: pageData.value.filter.maxDate || ""
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;

    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/campaigns/templates",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          key: pageData.value.filter.name,
          user: pageData.value.filter.user,
          minDate: pageData.value.filter.minDate || "",
          maxDate: pageData.value.filter.maxDate || ""
        },
        ApiType.CAMPAIGN
      );
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        const list = result.data.templates.map((item: any) => {
          return {
            id: parseInt(item.id),
            isChecked: false,
            name: item.name || "",
            createdDate: item.tsFormatted || "",
            userId: item.userID || 0,
            createdBy: item.createdBy || "",
            status: parseInt(item.status) || 0
          };
        });
        pageData.value.items = list;
      } else if (result.status === -1) {
        ApiHelper.gotoPage(context, { name: "Login" });
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    updateRouters();
  };

  const deleteItem = async (item: any) => {
    Vue.swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then((result: any) => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      if (result.isConfirmed) {
        (async () => {
          ApiHelper.setDataLoading(true);
          const result = await ApiHelper.callApi(
            "delete",
            "/campaigns/templates/" + item.id,
            {},
            {},
            ApiType.CAMPAIGN
          );
          ApiHelper.setDataLoading(false);
          if (result.status == 1) {
            loadData(1);
            ApiHelper.showSuccessMessage(
              "Your template has been deleted.",
              "Deleted"
            );
          } else {
            ApiHelper.showErrorMessage(result.message);
          }
        })();
      }
    });
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = event.target.value;
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };
  // filters
  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    pageData.value.pager.page = 1;
    switch (name) {
      case "name":
        pageData.value.filter.name = value;
        break;
      case "user":
        pageData.value.filter.user = value;
        break;
      case "date": {
        pageData.value.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.filter.minDate = dateArr[0];
        pageData.value.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        break;
      }
    }
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.name !== "") {
      filters.push({
        label: "NAME",
        key: pageData.value.filter.name + Math.random(),
        value: pageData.value.filter.name,
        reset: () => {
          pageData.value.filter.name = "";
          updateRouters();
        }
      });
    }
    if (
      pageData.value.filter.date !== "" &&
      pageData.value.filter.date !== "-"
    ) {
      const arr = pageData.value.filter.date.split("-");
      let displayValue = "";
      if (arr.length === 2) {
        displayValue =
          (pageData.value.filter.minDate != ""
            ? ApiHelper.convertYYYYMMDDtoDDMMYYString(arr[0])
            : "") +
          " - " +
          (pageData.value.filter.maxDate != ""
            ? ApiHelper.convertYYYYMMDDtoDDMMYYString(arr[1])
            : "");
      }

      filters.push({
        label: "DATE",
        key: pageData.value.filter.date + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.date = "";
          pageData.value.filter.minDate = "";
          pageData.value.filter.maxDate = "";
          updateRouters();
        }
      });
    }
    if (pageData.value.filter.user !== "") {
      filters.push({
        label: "USER",
        key: pageData.value.filter.user + Math.random(),
        value: pageData.value.filter.user,
        reset: () => {
          pageData.value.filter.user = "";
          updateRouters();
        }
      });
    }
    return {
      list: filters
    };
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  // init data
  (async () => {
    loadList();
  })();

  const showStatusDropdown = (id: number) => {
    if (id !== pageData.value.openItemId) {
      pageData.value.openItemId = id;
    } else {
      pageData.value.openItemId = 0;
    }
  };
  const updateStatus = (id: number, status: number) => {
    showStatusDropdown(0);
    ApiHelper.setDataLoading(true);
    (async () => {
      // const result = await apolloClient
      //   .mutate({
      //     mutation: gql`
      //       mutation updateCampaignStatus($id: Float!, $status: Float!) {
      //         updateCampaignStatus(id: $id, status: $status)
      //       }
      //     `,
      //     variables: {
      //       id: id,
      //       status: status
      //     }
      //   })
      //   .then((res: any) => {
      //     ApiHelper.setDataLoading(false);
      //   })
      //   .catch((err: any) => {
      //     ApiHelper.setDataLoading(false);
      //   });
    })();
  };

  return {
    loadList,
    showStatusDropdown,
    updateStatus,
    updateRouters,
    deleteItem,
    // sort
    updateSortValue,
    // page
    headerData,
    pageData,
    // filters
    // --- pager
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    // --- filters
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab
  };
}
