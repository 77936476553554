












































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import ProfileTabs from "@/components/Profile/ProfileTabs.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import SidebarProfileDetails from "@/components/Profile/SidebarProfileDetails.vue";
import Loading from "@/components/Common/Loading.vue";
import ProfilesTable from "@/components/Profile/ProfilesTable.vue";
import { useFamilyDetailsStore } from "@/stores/Family/FamilyDetailsStore";
import Pager from "@/components/Common/Pager.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import Header from "@/components/Common/Header.vue";
import Modal from "@/components/Common/Modal.vue";
import FormItem from "@/components/Form/FormItem.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormButton from "@/components/Form/FormButton.vue";
import FormSelect from "@/components/Form/FormSelect.vue";

import { ApiHelper } from "@/helpers";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import VScroller from "@/components/Common/VScroller.vue";
import NavFamilyDetails from "@/components/Family/NavFamilyDetails.vue";

type Profile = {
  id: string;
  firstName: string;
  lastName: string;
  tags: {
    id: number;
    name: string;
  }[];
};

export default defineComponent({
  name: "FamilyDetails",
  components: {
    NavFamilyDetails,
    FormItem,
    FormButton,
    VAlertMessage,
    ProfileTabs,
    Loading,
    SidebarProfileDetails,
    HeaderProfileDetails,
    Header,
    Modal,
    ProfilesTable,
    Avatar,
    AutoCompleteInput,
    Pager,
    FilterSearchColumn,
    FilterAgesColumn,
    FilterSelectColumn,
    FilterNumberRangeColumn,
    FilterCheckboxesColumn,
    VScroller,
    FormInput,
    FormSelect
  },
  setup(props, context) {
    const {
      loadData,
      deleteItem,
      popupNewItem,
      // sort
      updateSortValue,
      errors,
      profilePanel,
      headerData,
      pageData,
      showStatusDropdown,
      // filters
      selectAllGenders,
      resetGenders,
      selectAllEvents,
      resetEvents,
      selectAllFamilies,
      resetFamilies,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onViewDetails,
      onClosePanel,
      onUpdateProfileAvatar,
      onClickPrev,
      onClickNext,
      onPagerChange,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      applyFilters,
      updateFilterValue,
      doDeleteSelectedItems,
      doTagSelectedItems,
      doMessageSelectedItems,
      doFamilyUpSelectedItems,
      // popups
      showPopupArchives,
      popupTags,
      closePopupTags,
      popupArchives,
      closePopupArchives,
      familyTypeOptions
    } = useFamilyDetailsStore(context);

    const isACILoading = ref<boolean>(false);

    const searchProfiles = async (key: string) => {
      isACILoading.value = true;
      // const result = await ApiHelper.callApi(
      //   "get",
      //   "/profiles",
      //   {},
      //   {
      //     order: 2,
      //     direction: 1,
      //     key: key,
      //     notInFamilyId: parseInt(context.root.$route.params.Id)
      //   }
      // );

      const result = await ApiHelper.callApi(
        "get",
        "/profiles/search",
        {},
        {
          order: 2,
          direction: 1,
          onlyActive: 1,
          key: key,
          notInFamilyId: parseInt(context.root.$route.params.Id)
          // ignoreFamilyOnwers: 1
        }
      );

      if (result.status === 1) {
        popupNewItem.value.formData.controls.profile.options = result.data.profiles.map(
          (item: any) => ({
            id: item.id,
            text: ApiHelper.getFullName(item.firstName, item.lastName),
            data: item
          })
        );
      }
      isACILoading.value = false;
    };

    const selectProfile = (item: any) => {
      const profileID = item.data.id || "0";
      popupNewItem.value.formData.controls.profile.value = profileID;
      popupNewItem.value.formData.controls.profile.error = "";

      if (item.data.isFamilyOnwer || false) {
        popupNewItem.value.formData.controls.remove.value = false;
        pageData.value.removeDisabled = true;
      } else {
        pageData.value.removeDisabled = false;
      }
      // context.root.$forceUpdate();
    };

    const popupEditTribe = ref<any>({
      show: false,
      controls: {
        id: {
          value: 0
        },
        subProfileId: {
          value: 0
        },
        name: {
          label: "Member name",
          disabled: true,
          value: "",
          error: ""
        },
        type: {
          label: "Relation type",
          placeholder: "- Select type -",
          error: "",
          type: "select",
          value: "",
          options: []
        }
      },
      actions: {
        onSubmit: async () => {
          let hasError = false;
          const relationTypeId =
            parseInt(popupEditTribe.value.controls.type.value) || 0;
          if (relationTypeId === 0) {
            popupEditTribe.value.controls.type.error =
              "Relation type is required.";
            hasError = true;
          }
          if (hasError) {
            return;
          }
          pageData.value.isLoading = true;
          const result = await ApiHelper.callApi(
            "put",
            "/profiles/tribes/" + popupEditTribe.value.controls.id.value,
            {
              subProfileId: popupEditTribe.value.controls.subProfileId.value,
              profileId:
                parseInt(context.root.$route.params.profileId + "") || 0,
              familyId: parseInt(context.root.$route.query.family + "") || 0,
              typeId: relationTypeId
            },
            {}
          );
          pageData.value.isLoading = false;
          if (result.status !== 1) {
            ApiHelper.showErrorMessage(result.message, "Oops");
            return;
          }
          popupEditTribe.value.show = false;
          await loadData(1);
          ApiHelper.showSuccessMessage("Updated");
        }
      }
    });

    const openEditPopup = (item: any) => {
      popupEditTribe.value.show = true;
      popupEditTribe.value.controls.id.value = item.linkId || 0;
      popupEditTribe.value.controls.subProfileId.value = item.id;
      popupEditTribe.value.controls.name.value = ApiHelper.getFullName(
        item.firstName,
        item.lastName
      );
      popupEditTribe.value.controls.type.value = (item.typeId || 0) + "";
      popupEditTribe.value.controls.type.options = familyTypeOptions.value;
    };

    const popupEditFamily = ref({
      show: false,
      controls: {
        id: {
          value: parseInt(context.root.$route.params.Id) || 0
        },
        name: {
          label: "Member name",
          disabled: true,
          value: "",
          error: ""
        }
      },
      actions: {
        onSubmit: async () => {
          let hasError = false;
          if (popupEditFamily.value.controls.name.value == "") {
            popupEditFamily.value.controls.name.error = "Name is required.";
            hasError = true;
          }
          if (hasError) {
            return;
          }

          pageData.value.isLoading = true;
          const result = await ApiHelper.callApi(
            "put",
            "/families/" + popupEditFamily.value.controls.id.value,
            {
              name: popupEditFamily.value.controls.name.value.trim()
            },
            {}
          );
          pageData.value.isLoading = false;
          if (result.status !== 1) {
            ApiHelper.showErrorMessage(result.message, "Oops");
            return;
          }
          popupEditFamily.value.show = false;
          headerData.value.subTitle = popupEditFamily.value.controls.name.value;
          ApiHelper.showSuccessMessage("Updated");
        }
      }
    });

    return {
      popupEditFamily,
      openEditPopup,
      popupEditTribe,
      loadData,
      deleteItem,
      searchProfiles,
      selectProfile,
      popupNewItem,
      // sort
      updateSortValue,
      errors,
      profilePanel,
      headerData,
      pageData,
      showStatusDropdown,
      // filters
      selectAllGenders,
      resetGenders,
      selectAllEvents,
      resetEvents,
      selectAllFamilies,
      resetFamilies,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onViewDetails,
      onClosePanel,
      onUpdateProfileAvatar,
      onClickPrev,
      onClickNext,
      onPagerChange,
      setActiveFilterTab,
      closeFilterTab,
      getFiltersData,
      applyFilters,
      updateFilterValue,
      doDeleteSelectedItems,
      doTagSelectedItems,
      doMessageSelectedItems,
      doFamilyUpSelectedItems,
      // popups
      showPopupArchives,
      popupTags,
      closePopupTags,
      popupArchives,
      closePopupArchives,
      familyTypeOptions,
      isACILoading
    };
  }
});
