
















































































































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import Avatar from "vue-avatar/src/Avatar.vue";
export default defineComponent({
  name: "ParentGuardian",
  components: {
    Avatar
  },
  setup() {
    const componentData = ref({
      isLoading: false,
      items: []
    });
    const loadData = async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/profiles",
        {},
        {
          take: 3,
          order: "1",
          direction: "1"
        }
      );
      componentData.value.isLoading = false;
      if (result.status == 1) {
        componentData.value.items = (result?.data.data || []).map(
          (item: any) => {
            if (item.email) {
              item.selected = true;
            }
            return item;
          }
        );
      } else {
        componentData.value.items = [];
      }
    };
    (async () => {
      await loadData();
    })();
    return {
      componentData
    };
  }
});
