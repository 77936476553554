import { ApiHelper } from "@/helpers";
import { FormCheckbox, FormPassword, FormSelect, FormText } from "@/types";
import StoreUserInput from "@/types/StoreUserInput";
import { ref } from "@vue/composition-api";

export function useVposUserAdd(context: any) {
  const isACILoading = ref<boolean>(false);
  const selectedUserId = parseInt(context.root.$route.params.id);
  const dataInput = ref<StoreUserInput>({
    firstName: "",
    lastName: "",
    terminalId: 0,
    accountId: 0,
    email: "",
    password: "",
    pin: "",
    passCode: "",
    status: 1,
    ts: new Date()
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      firstName: FormText;
      lastName: FormText;
      terminalId: FormSelect;
      accountId: FormText;
      email: FormText;
      password: FormPassword;
      pin: FormText;
      passCode: FormText;
      status: FormSelect;
    };
  }>({
    isLoading: false,
    controls: {
      firstName: {
        label: "First Name",
        required: true,
        placeholder: "Enter First Name",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 150
      },
      lastName: {
        label: "Last Name",
        required: true,
        placeholder: "Enter Last Name",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 150
      },
      terminalId: {
        label: "Terminal",
        required: true,
        placeholder: "Select Terminal",
        error: "",
        value: "",
        style: "custom",
        options: []
      },
      accountId: {
        label: "Account ID",
        required: true,
        placeholder: "Enter Account ID",
        error: "",
        type: "text",
        value: "",
        style: "custom"
      },
      email: {
        label: "Email",
        required: true,
        placeholder: "Email",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 150
      },
      passCode: {
        label: "Passcode",
        required: true,
        placeholder: "Passcode",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 50
      },
      password: {
        label: "Password",
        required: true,
        placeholder: "Password",
        hideIcon: false,
        mode: "password",
        error: "",
        type: "password",
        value: "",
        style: "custom",
        maxlength: 150
      },
      pin: {
        label: "PIN",
        required: true,
        placeholder: "PIN",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 4
      },
      status: {
        label: "Status",
        required: true,
        error: "",
        placeholder: "Select Status",
        value: "",
        style: "custom",
        options: [
          {
            id: 1,
            text: "ACTIVE"
          },
          {
            id: 2,
            text: "INACTIVE"
          }
        ]
      }
    }
  });

  const initData = async () => {
    if (!isNaN(selectedUserId)) {
      const result = await ApiHelper.callApi(
        "get",
        `/store/users/${selectedUserId}`,
        {},
        {}
      );
      if (result.status === 1) {
        formData.value.controls.firstName.value = result.data.u_fname;
        formData.value.controls.lastName.value = result.data.u_lname;
        formData.value.controls.terminalId.value = result.data.t_id;
        formData.value.controls.accountId.value = result.data.accountid;
        formData.value.controls.email.value = result.data.u_email;
        formData.value.controls.password.value = result.data.u_passcode;
        formData.value.controls.pin.value = result.data.u_pin;
        formData.value.controls.passCode.value = result.data.u_passcode;
        formData.value.controls.status.value = result.data.u_status;
      }
    }
    formData.value.controls.terminalId.options = await ApiHelper.getTerminalOptions();
  };

  const getPageTitle = () => {
    return isNaN(selectedUserId) ? "ADD" : "EDIT";
  };

  const validateData = () => {
    let hasError = false;
    if (formData.value.controls.firstName.value === "") {
      formData.value.controls.firstName.error = "First Name Required";
      hasError = true;
    }
    // eslint-disable-next-line no-constant-condition
    if (formData.value.controls.lastName.value === "") {
      formData.value.controls.lastName.error = "Last Name Required";
      hasError = true;
    }
    if (formData.value.controls.accountId.value === "") {
      formData.value.controls.accountId.error = "Account ID Required";
      hasError = true;
    }
    if (formData.value.controls.terminalId.value === "") {
      formData.value.controls.terminalId.error = "Terminal Required";
      hasError = true;
    }
    if (formData.value.controls.email.value === "") {
      formData.value.controls.email.error = "Email is required";
      hasError = true;
    } else if (!ApiHelper.validateEmail(formData.value.controls.email.value)) {
      formData.value.controls.email.error = "Email is invalid";
      hasError = true;
    }
    if (formData.value.controls.password.value === "") {
      formData.value.controls.password.error = "Password Required";
      hasError = true;
    }
    if (formData.value.controls.passCode.value === "") {
      formData.value.controls.passCode.error = "Passcode Required";
      hasError = true;
    }
    if (formData.value.controls.password.value.trim() !== "") {
      const pwd = new String(formData.value.controls.password.value.trim());
      if (pwd.length < 4) {
        hasError = true;
        formData.value.controls.password.error =
          "Password must more 3 characters";
      }
    }
    if (formData.value.controls.pin.value === "") {
      formData.value.controls.pin.error = "PIN Required";
      hasError = true;
    }
    if (formData.value.controls.status.value === "") {
      formData.value.controls.status.error = "Status Required";
      hasError = true;
    }

    return hasError;
  };

  const onSubmit = async () => {
    formData.value.isLoading = true;
    const hasError = validateData();
    if (hasError) {
      formData.value.isLoading = false;
    } else {
      dataInput.value.firstName = formData.value.controls.firstName.value;
      dataInput.value.lastName = formData.value.controls.lastName.value;
      dataInput.value.accountId = Number(
        formData.value.controls.accountId.value
      );
      dataInput.value.terminalId = Number(
        formData.value.controls.terminalId.value
      );
      dataInput.value.email = formData.value.controls.email.value;
      dataInput.value.password = formData.value.controls.password.value;
      dataInput.value.passCode = formData.value.controls.passCode.value;
      dataInput.value.pin = formData.value.controls.pin.value;
      dataInput.value.status = Number(formData.value.controls.status.value);
      const synonym = isNaN(selectedUserId) ? "post" : "put";
      if (!isNaN(selectedUserId)) {
        dataInput.value.id = selectedUserId;
      }
      const result = await ApiHelper.callApi(
        synonym,
        "/store/users",
        dataInput.value
      );
      if (result.status === 1) {
        ApiHelper.showSuccessMessage(result.message, "Store User");
        ApiHelper.gotoPage(context, "/pos/storeusers");
      } else {
        ApiHelper.showErrorMessage(
          result.message || `Can't create new user`,
          "Oops"
        );
      }
      formData.value.isLoading = false;
    }
  };

  return {
    selectedUserId,
    initData,
    dataInput,
    formData,
    getPageTitle,
    onSubmit
  };
}
