



















































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import { useEventCabinsStore } from "@/stores/Event/EventCabinsStore";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import HeaderFilters from "@/components/Common/HeaderFilters.vue";
import Pager from "@/components/Common/Pager.vue";
import Modal from "@/components/Common/Modal.vue";
import HeaderActions from "@/components/Common/HeaderActions.vue";
import { ApiHelper } from "@/helpers/index";
import BackButton from "@/components/Common/BackButton.vue";
import DropdownParticipantStatus from "@/components/Participant/DropdownParticipantStatus.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import directives from "@/helpers/directives";
import FormYesNo from "@/components/Form/FormYesNo.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import VScroller from "@/components/Common/VScroller.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import draggable from "vuedraggable";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
import ConfirmModal from "@/components/Common/ConfirmModal.vue";
import DormGenerateConfirm from "./DormGenerateConfirm.vue";
import $ from "jquery";
import NavEventDetails from "@/components/Event/NavEventDetails.vue";

export default defineComponent({
  name: "EventCabinsPage",
  components: {
    FormNumber,
    FormSelect,
    FormMultiSelect,
    FormInput,
    FormYesNo,
    TableHeaderColumn,
    BackButton,
    HeaderEventDetails,
    SidebarEventDetails,
    VAlertMessage,
    Loading,
    Modal,
    AutoCompleteInput,
    HeaderFilters,
    HeaderActions,
    Avatar,
    FilterCheckboxesColumn,
    FilterSearchColumn,
    FilterNumberRangeColumn,
    Pager,
    DropdownParticipantStatus,
    VScroller,
    draggable,
    ConfirmModal,
    DormGenerateConfirm,
    NavEventDetails
  },
  directives: directives,
  setup(props, context) {
    const {
      loadData,
      updateCabinStatus,
      // popups
      popupNewItem,
      popupDormGenerator,
      // sort
      updateSortValue,
      headerData,
      pageData,
      formData,
      // filters
      selectAllStatuses,
      resetStatuses,
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      // allParticipants,
      lines,
      resetLines,
      hilightRelatedLines,
      onScroll,
      showAddNewCabin,
      entityCabinsChange
    } = useEventCabinsStore(context);

    const popupEditCabin = ref({
      show: false,
      selectedCabin: {
        linkedEntityCabin: {
          entityCabinId: 0
        }
      },
      controls: {
        id: {
          value: 0
        },
        name: {
          value: "",
          error: ""
        }
      },
      actions: {
        onSubmit: async () => {
          let hasError = false;
          if (popupEditCabin.value.controls.name.value === "") {
            popupEditCabin.value.controls.name.error =
              "Cabin name is required.";
            hasError = true;
          }
          if (hasError) {
            return;
          }
          pageData.value.isLoading = true;
          const entityCabinId =
            popupEditCabin.value.selectedCabin.linkedEntityCabin
              .entityCabinId || 0;
          const result = await ApiHelper.callApi(
            "put",
            "/cabins/" + popupEditCabin.value.controls.id.value,
            {
              name: popupEditCabin.value.controls.name.value,
              eventId: parseInt(context.root.$route.params.eventId) || 0,
              entityCabinId
            },
            {}
          );
          pageData.value.isLoading = false;
          if (result.status !== 1) {
            ApiHelper.showErrorMessage(result.message, "Oops");
            return;
          }
          ApiHelper.showSuccessMessage("Updated");
          loadData(1);
          popupEditCabin.value.show = false;
        }
      }
    });

    const isACILoading = ref(false);
    const searchEvents = async (key: string) => {
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/events/search",
        {},
        {
          order: 2,
          direction: 1,
          ignoreIds: `${context.root.$route.params.eventId}`,
          key: key
        }
      );
      if (result.status === 1) {
        popupNewItem.value.formData.controls.event.options = result.data.events.map(
          (item: any) => ({
            id: item.id,
            text: item.name,
            data: item
          })
        );
      }
      isACILoading.value = false;
    };

    const selectEvent = (item: any) => {
      popupNewItem.value.formData.controls.event.error = "";
      formData.value.controls.name.error = "";
      formData.value.controls.name.value = "";
      pageData.value.entityCabins.options = pageData.value.entityCabins.options.map(
        (item: any) => ({ ...item, selected: false })
      );
      popupNewItem.value.formData.controls.event.value = item.id;
    };

    const getEventNameFromSidebar = ($ref: any) => {
      const SidebarEventDetailsRef = $ref || {};
      const sidebarData = SidebarEventDetailsRef.sidebarData || {};
      return sidebarData.name || "";
    };

    const getRoommates = (participantId: number) => {
      const roommates = [];
      // check related roommate requests
      const relatedRoommates: any = pageData.value.roommateRequests.filter(
        (t: any) =>
          t.toParticipantId == participantId ||
          t.invitedParticipantId == participantId
      );
      for (const rm of relatedRoommates) {
        if (rm.toParticipantId == participantId) {
          // was invited by other
          roommates.push({
            profileId: rm.invitedProfileId,
            participantId: rm.invitedParticipantId,
            firstName: rm.invitedFname,
            lastName: rm.invitedLname,
            fullName: ApiHelper.getFullName(rm.invitedFname, rm.invitedLname)
          });
        } else if (rm.invitedParticipantId == participantId) {
          // invited the roommate request
          roommates.push({
            profileId: rm.profileId,
            participantId: rm.toParticipantId,
            firstName: rm.firstName,
            lastName: rm.lastName,
            fullName: ApiHelper.getFullName(rm.firstName, rm.lastName)
          });
        }
      }
      return roommates;
    };

    const getParticipantTitle = (item: any, i: number) => {
      let ret = `${item.names[i]} (Age: ${item.ages[i]}, Gender: ${item.genders[i]}, State: ${item.states[i]})`;
      const roommates = getRoommates(item.ids[i]);
      if (roommates.length) {
        ret += ` pairs with ${roommates.map(t => t.fullName).join(", ")}`;
      }

      return ret;
    };

    const getCabinParticipantTitle = (item: any) => {
      let ret = ApiHelper.getFullName(item.firstName, item.lastName);
      const roommates = getRoommates(item.participantId);
      if (roommates.length) {
        ret += ` pairs with ${roommates.map(t => t.fullName).join(", ")}`;
      }

      return ret;
    };

    const getTieToGender = (item: any) => {
      const gender = item.linkedEntityCabin.gender || 0;
      if (gender == 1 || gender == 2) {
        return `${ApiHelper.getGenderName(gender)} Only`;
      }
      return "";
    };

    return {
      popupDormGenerator,
      getEventNameFromSidebar,
      isACILoading,
      searchEvents,
      selectEvent,
      popupEditCabin,
      updateCabinStatus,
      popupNewItem,
      // sort
      updateSortValue,
      headerData,
      pageData,
      // filters
      selectAllStatuses,
      resetStatuses,
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      ApiHelper,
      getParticipantTitle,
      getCabinParticipantTitle,
      // allParticipants,
      // loadData,
      lines,
      resetLines,
      hilightRelatedLines,
      onScroll,
      showAddNewCabin,
      entityCabinsChange,
      getTieToGender
    };
  },
  async mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // reset lines when resizing
    $(document).on("resize", async function() {
      if ($(".EventCabinsPage").length) {
        await Promise.all([self.$forceUpdate()]);
        self.resetLines();
      }
    });

    $("#content").on("scroll", () => {
      self.onScroll();
    });
  },
  beforeDestroy() {
    // make sure remove lines
    for (const item of this.lines) {
      item.line?.remove();
    }
  },
  updated() {
    //Load data total on header
    if (this.popupNewItem.isAddNewSuccess) {
      const sidebar: any = this.$refs.childComponentHeaderRef;
      if (sidebar) {
        sidebar.$refs.SidebarEventDetailsRef.loadData();
        this.popupNewItem.isAddNewSuccess = false;
      }
    }
  }
});
