








import { defineComponent } from "@vue/composition-api";
import FormInput from "@/components/Form/FormInput.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormEmail from "@/components/Form/FormEmail.vue";
import FormPassword from "@/components/Form/FormPassword.vue";

export default defineComponent({
  name: "FormItem",
  components: { FormSelect, FormInput, FormEmail, FormPassword },
  props: {
    data: Object
  },
  setup() {
    return {};
  }
});
