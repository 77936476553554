var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"autocomplete-input form-group mb-0"},[_c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.key),expression:"key",modifiers:{"trim":true}}],class:[
        'search-box',
        'text-uppercase',
        _vm.inputClass,
        {
          'input-loader': _vm.loading,
          'border-danger': _vm.data && _vm.data.error,
          'form-control': typeof _vm.inputClass == 'undefined'
        }
      ],attrs:{"type":"text","placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":"150"},domProps:{"value":(_vm.key)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.key=$event.target.value.trim()},_vm.onInput],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doAddNew($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.selectLineItem($event)}],"focusout":function($event){_vm.key = _vm.stripTags(_vm.key)},"focus":_vm.onFocus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.onTab($event)},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.options.filter(function (item) { return item.selected; }).length)?_c('a',{staticClass:"btn btn-sm btn-primary position-absolute",staticStyle:{"right":"6px","top":"6px","padding":"2px 5px","font-size":"10px"},on:{"click":_vm.applySelectedItems}},[_vm._v(" Apply selected items ")]):_vm._e()]),(
      _vm.data &&
        _vm.data.error !== undefined &&
        _vm.data.error !== '' &&
        _vm.showError &&
        _vm.data.newError !== undefined
    )?_c('div',{staticClass:"error text-danger fix-overlay"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e(),(
      _vm.data &&
        _vm.data.error !== undefined &&
        _vm.data.error !== '' &&
        _vm.showError &&
        _vm.data.newError === undefined
    )?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e(),(_vm.show && !_vm.loading && !_vm.isACILoading)?_c('div',{staticClass:"box_content",class:{
      allowAddNew: _vm.allowAddNew,
      mt45px: _vm.mt45px,
      addTopPopOver: _vm.addTopPopOver
    }},[(_vm.hint != '')?_c('div',{staticClass:"hint"},[_vm._v(_vm._s(_vm.hint))]):(_vm.options.length)?_c('VScroller',{attrs:{"fade-scroll":false}},[_c('ul',{staticClass:"results-box",class:{ nowrap: _vm.nowrap },style:(_vm.resultMaxHeight != '' ? ("max-height: " + _vm.resultMaxHeight) : ''),attrs:{"slot":"default"},slot:"default"},[_vm._l((_vm.options),function(item,index){return _c('li',{key:'search-option-' + index,staticClass:"text-uppercase position-relative",attrs:{"data-id":item.id,"title":_vm.showLineTitle ? item.text : ''},on:{"click":function($event){return _vm.onSelect(item)}}},[(item.html != undefined)?_c('div',{domProps:{"innerHTML":_vm._s(item.html)}}):_c('div',{domProps:{"innerHTML":_vm._s(item.text)}}),(_vm.multiSelect)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.selected),expression:"item.selected"}],staticClass:"chk-item position-absolute",staticStyle:{"right":"20px","top":"10px"},attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.selected)?_vm._i(item.selected,null)>-1:(item.selected)},on:{"change":function($event){var $$a=item.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "selected", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "selected", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "selected", $$c)}}}}):_vm._e()])}),(_vm.endItemMessage != '')?_c('li',{staticClass:"end-custom-message"},[_vm._v(" "+_vm._s(_vm.endItemMessage)+" ")]):_vm._e()],2)]):_c('div',{staticClass:"noData2",class:{
        normal: _vm.isNormal
      },domProps:{"innerHTML":_vm._s(_vm.noDataMessage)},on:{"click":_vm.doAddNew}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }