
























































































import Vue from "vue";

export default Vue.extend({
  name: "Modal",
  props: {
    title: String,
    size: String,
    dormGenerator: Boolean,
    message: {
      type: String,
      default: ""
    }
  }
});
