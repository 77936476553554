




















































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";

import DatePicker from "vue2-datepicker";
import Loading from "@/components/Common/Loading.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FormButton from "@/components/Form/FormButton.vue";
import SidebarCampaignAdd from "@/components/Messaging/SidebarCampaignAdd.vue";
import { useMessagingCampaignsAddStore } from "@/stores/Messaging/Campaign/MessagingCampaignsAddStore";
import FormInput from "@/components/Form/FormInput.vue";
import FormAutoComplete from "@/components/Form/FormAutoComplete.vue";
import FormYesNo from "@/components/Form/FormYesNo.vue";
import VScroller from "@/components/Common/VScroller.vue";
import Flowy from "@/components/Messaging/flowy.vue";
import Header from "@/components/Common/Header.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import BackButton from "@/components/Common/BackButton.vue";
import Modal from "@/components/Common/Modal.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";

export default defineComponent({
  name: "MessagingCampaignsAddPage",
  components: {
    TableHeaderColumn,
    FormSelect,
    Flowy,
    FormInput,
    FormAutoComplete,
    FormYesNo,
    DatePicker,
    VScroller,
    Loading,
    Select2,
    FormButton,
    SidebarCampaignAdd,
    Header,
    BackButton,
    Modal,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn
  },
  setup(props, context) {
    const {
      getSelectedParticipants,
      audienceData,
      modalPreview,
      newTemplateId,
      previewData,
      pageData,
      formData,
      // methods
      loadPageData,
      onSaveInfo,
      onSaveWorkflow,
      onSendEmail
    } = useMessagingCampaignsAddStore(context);

    const getAgeRange = (minAge: string, maxAge: string) => {
      let ages = "-";
      if (minAge && maxAge) {
        ages = `${minAge}-${maxAge}`;
      } else if (minAge) {
        ages = `${minAge}+`;
      } else if (maxAge) {
        ages = `${maxAge}-`;
      }
      return ages;
    };

    const getBalanceRange = (minBalance: string, maxBalance: string) => {
      if (minBalance !== "" || maxBalance != "") {
        const displayValue =
          (minBalance != ""
            ? ApiHelper.dollarFormat(Number.parseInt(minBalance, 10), false, {
                useBrackets: true
              })
            : "") +
          " - " +
          (maxBalance != ""
            ? ApiHelper.dollarFormat(Number.parseInt(maxBalance, 10), false, {
                useBrackets: true
              })
            : "");
        return displayValue;
      }
      return "-";
    };

    return {
      getBalanceRange,
      getAgeRange,
      getSelectedParticipants,
      audienceData,
      modalPreview,
      newTemplateId,
      previewData,
      pageData,
      formData,
      // methods
      loadPageData,
      onSaveInfo,
      onSaveWorkflow,
      onSendEmail,
      ApiHelper: ApiHelper
    };
  },
  async mounted() {
    await this.loadPageData();
  }
});
