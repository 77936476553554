

































import { defineComponent, ref } from "@vue/composition-api";
import Select2 from "v-select2-component/src/Select2.vue";
import OrderByButton from "@/components/Common/OrderByButton.vue";

export default defineComponent({
  name: "FilterSelectColumn",
  components: {
    Select2,
    OrderByButton
  },
  props: {
    label: String,
    placeholder: String,
    model: String,
    data: Object,
    activeTab: String,
    activeValue: String,
    setActiveFilterTab: Function,
    closeFilterTab: Function,
    onChangeValue: Function,
    sortKey: String,
    sortData: Object,
    onChangeSortValue: Function
  },
  setup(props, context) {
    const filterValue = ref<string>("");
    const timer = ref<any>(null);
    const searchTimeOut = () => {
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = null;
      }
      timer.value = setTimeout(() => {
        if (props.onChangeValue) {
          props.onChangeValue(props.activeValue, filterValue.value);
        }
      }, 800);
    };

    const onInput = (event: any) => {
      filterValue.value = event.target.value;
    };

    const doClose = () => {
      if (props.setActiveFilterTab) {
        props.setActiveFilterTab("");
      }
    };

    const onChange = () => {
      if (props.onChangeValue) {
        props.onChangeValue(props.activeValue, filterValue.value);
        doClose();
      }
    };

    const doReset = () => {
      filterValue.value = "";
      if (props.onChangeValue) {
        props.onChangeValue(props.activeValue, filterValue.value);
      }
    };

    const onKeyup = (event: any) => {
      if (event.keyCode === 13 && props.onChangeValue) {
        props.onChangeValue(props.activeValue, filterValue.value);
        doClose();
      }
    };

    const getDefaultValue = (value: string) => {
      filterValue.value = value;
      return value;
    };

    const onClickOutside = () => {
      if (props.activeTab === props.activeValue) {
        doClose();
      }
    };

    const onClickSorting = () => {
      if (props.onChangeSortValue) {
        const currentOrder = props.sortData ? props.sortData.order || "" : "";
        const currentDirection = props.sortData
          ? props.sortData.direction || "2"
          : "2";
        if (props.sortKey !== currentOrder) {
          props.onChangeSortValue(props.sortKey, "2");
        } else {
          props.onChangeSortValue(
            props.sortKey,
            currentDirection === "1" ? "2" : "1"
          );
        }
      }
    };

    return {
      onClickSorting,
      onClickOutside,
      filterValue,
      onInput,
      onKeyup,
      onChange,
      doReset,
      doClose,
      searchTimeOut,
      getDefaultValue
    };
  }
});
