





























































































import { defineComponent } from "@vue/composition-api";

import Loading from "@/components/Common/Loading.vue";
import EmailBuilder from "@/components/Messaging/grapesjs.vue";
import { useMessagingTemplatesEditStore } from "@/stores/Messaging/Template/MessagingTemplatesEditStore";

export default defineComponent({
  name: "MessagingTemplatesAddPage",
  components: {
    Loading,
    EmailBuilder
  },
  setup(props, context) {
    const {
      dataInput,
      pageData,
      loadData,
      saveTemplate,
      uploadFile,
      loadTemplate,
      sendTest
    } = useMessagingTemplatesEditStore(context);
    return {
      pageData,
      dataInput,
      loadData,
      saveTemplate,
      uploadFile,
      loadTemplate,
      sendTest
    };
  }
});
