var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"EventDashboardPage"},[_c('HeaderEventDetails',{attrs:{"title":"Dashboard","event-id":_vm.$route.params.eventId,"filters":_vm.getFiltersData()}},[(_vm.pageData.pager.total)?_c('span',{staticClass:"heading__number",attrs:{"slot":"heading__number"},slot:"heading__number"},[_vm._v(" "+_vm._s(_vm.pageData.pager.total)+" ")]):_vm._e(),_c('span',{attrs:{"slot":"icon"},slot:"icon"}),_c('div',{staticClass:"head__tabs tab__2",attrs:{"slot":"head__tabs"},slot:"head__tabs"},[_c('div',{staticClass:"tab__item d-flex align-items-center active",attrs:{"data":"#list__view"}},[_vm._v(" List VIEW ")])]),_c('div',{attrs:{"slot":"head__box"},slot:"head__box"})]),_c('div',{staticClass:"content-inside"},[(_vm.pageData.dashboard.loaded)?_c('div',[(_vm.pageData.applications.length > 0)?_c('div',{staticClass:"dashboard-blocks mw-1300"},[_c('section',[_c('h3',[_vm._v("Participants")]),_c('div',[_vm._v("Total: "+_vm._s(_vm.pageData.dashboard.totalParticipants))]),_c('div',[_vm._v("Start Date: "+_vm._s(_vm.pageData.dashboard.startDate))]),_c('div',[_vm._v("End Date: "+_vm._s(_vm.pageData.dashboard.endDate))])]),_c('section',[_c('h3',[_vm._v("Last 7 days")]),_c('div',[_vm._v(" Total Cost : "),_c('span',[_vm._v(_vm._s(_vm.pageData.dashboard.totalCost7dFormatted))])]),_c('div',[_vm._v(" Total Paid: "),_c('span',[_vm._v(_vm._s(_vm.pageData.dashboard.totalPaid7dFormatted))])]),_c('div',[_vm._v(" Total Owed: "),_c('span',{class:{
                'text-danger': _vm.pageData.dashboard.totalOwed7d < 0
              }},[_vm._v(_vm._s(_vm.pageData.dashboard.totalOwed7dFormatted))])])]),_c('section',[_c('h3',[_vm._v("Last 30 days")]),_c('div',[_vm._v(" Total Cost : "),_c('span',[_vm._v(_vm._s(_vm.pageData.dashboard.totalCost30dFormatted))])]),_c('div',[_vm._v(" Total Paid: "),_c('span',[_vm._v(_vm._s(_vm.pageData.dashboard.totalPaid30dFormatted))])]),_c('div',[_vm._v(" Total Owed: "),_c('span',{class:{
                'text-danger': _vm.pageData.dashboard.totalOwed30d < 0
              }},[_vm._v(_vm._s(_vm.pageData.dashboard.totalOwed30dFormatted))])])])]):_c('div',{staticClass:"content-inside mw-1300",staticStyle:{"padding-top":"30px"}},[_c('section',{staticStyle:{"padding":"30px","border":"1px solid #999","background-color":"#eee"}},[_c('h3',[_vm._v("Congratulations! You have created a new event.")]),_c('div',{staticStyle:{"padding-top":"20px"}},[_c('p',[_c('router-link',{staticStyle:{"color":"#2c3e50","text-decoration":"underline","font-weight":"bold"},attrs:{"to":{
                  name: 'SettingsApplications',
                  params: {},
                  query: {
                    event: _vm.$route.params.eventId
                  }
                }}},[_vm._v("Create a new application")]),_vm._v(" to start accepting participants ")],1)])])])]):_vm._e(),_c('TimelineTable',{attrs:{"pageData":_vm.pageData,"gotoPage":_vm.gotoPage,"getCheckedItems":_vm.getCheckedItems,"togglePagerItems":_vm.togglePagerItems,"hideHeadActions":_vm.hideHeadActions,"onClickPrev":_vm.onClickPrev,"onClickNext":_vm.onClickNext,"onPagerChange":_vm.onPagerChange,"setActiveFilterTab":_vm.setActiveFilterTab,"closeFilterTab":_vm.closeFilterTab,"updateFilterValue":_vm.updateFilterValue,"updateRouters":_vm.updateRouters,"updateSortValue":_vm.updateSortValue}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }