


























import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default defineComponent({
  name: "FormDate",
  components: {
    DatePicker
  },
  props: {
    data: Object,
    onChange: Function
  },
  setup(props, context) {
    const randomId = ref<number>(ApiHelper.randomFormId());
    const maxDate = moment().format("YYYY-MM-DD");
    const minDate = moment()
      .subtract(100, "years")
      .format("YYYY-MM-DD");

    const doChange = () => {
      if (props.onChange) {
        props.onChange();
      }
    };

    return {
      randomId: randomId.value.toString(),
      doChange,
      maxDate: maxDate.toString(),
      minDate: minDate.toString()
    };
  }
});
