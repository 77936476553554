










































































































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "FormPassword",
  props: {
    data: Object
  },
  setup() {
    const random = Math.random();
    return { random };
  }
});
