
















































































































































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import directives from "@/helpers/directives";
import BackButton from "@/components/Common/BackButton.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import { useSettingsEventGroupEditStore } from "@/stores/Settings/EventGroups/SettingsEventGroupEditStore";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
import Header from "@/components/Common/Header.vue";
import Inputmask from "inputmask";
import $ from "jquery";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";

export default defineComponent({
  name: "SettingsEventGroupEditPage",
  components: {
    Header,
    FormMultiSelect,
    FormInput,
    Loading,
    BackButton,
    FormSelect,
    AutoCompleteInput,
    TableHeaderColumn
  },
  directives: directives,
  setup(props, context) {
    const {
      // removeOption,
      // changeOption,
      loadData,
      headerData,
      // pageData,
      formData,
      eventChange,
      capacityChange,
      suggestEventPTypes,
      selectEventPType,
      getPTypeHtml,
      removeLink
    } = useSettingsEventGroupEditStore(context);
    return {
      // removeOption,
      // changeOption,
      loadData,
      headerData,
      // pageData,
      formData,
      eventChange,
      capacityChange,
      suggestEventPTypes,
      selectEventPType,
      getPTypeHtml,
      removeLink
    };
  },
  mounted() {
    Inputmask.extendAliases({
      price: {
        prefix: "$",
        groupSeparator: ".",
        alias: "numeric",
        placeholder: "0",
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        clearMaskOnLostFocus: false,
        allowMinus: false
      }
    });

    $(document).ready(function() {
      Inputmask("price", { autoUnmask: true }).mask(
        $(".SettingsGroupEditPage .dollar_amount")
      );
    });
  }
});
