





























































































































































































































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Header from "@/components/Common/Header.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import DropdownParticipantStatus from "@/components/Participant/DropdownParticipantStatus.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import { useSettingsEventGroupsStore } from "@/stores/Settings/EventGroups/SettingsEventGroupsStore";

export default defineComponent({
  name: "SettingsEventGroups",
  components: {
    Header,
    Loading,
    Pager,
    TableHeaderColumn,
    DropdownParticipantStatus,
    FilterSearchColumn,
    FilterCheckboxesColumn
  },
  setup(props, context) {
    const {
      updateRouters,
      deleteGroup,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      selectAllEvents,
      resetEvents,
      searchEvents,
      updateSelectedEvents,
      loadData
    } = useSettingsEventGroupsStore(context);

    const getTieToGender = (item: any) => {
      const gender = item.gender || 0;
      if (gender == 1 || gender == 2) {
        return `${ApiHelper.getGenderName(gender)} Only`;
      }
      return "";
    };

    return {
      updateRouters,
      deleteGroup,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      ApiHelper: ApiHelper,
      getTieToGender,
      selectAllEvents,
      resetEvents,
      searchEvents,
      updateSelectedEvents,
      loadData
    };
  }
});
