


















































































































































import { defineComponent, ref } from "@vue/composition-api";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";
import DateSelect from "./SubModules/DateSelect.vue";

export default defineComponent({
  name: "ProfileDetails",
  components: {
    VScroller,
    DateSelect
  },
  setup() {
    const getStateOptions = () => {
      return ApiHelper.getStateOptions();
    };

    return {
      getStateOptions
    };
  },
  props: {
    //
  }
});
