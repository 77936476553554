
































































































import { defineComponent } from "@vue/composition-api";
import { useVposUserAdd } from "@/stores/vpos/VposUserAddStore";
import FormDate from "@/components/Form/FormDate.vue";
import directives from "@/helpers/directives";
import FormYesNo from "@/components/Form/FormYesNo.vue";
import FormPassword from "@/components/Form/FormPassword.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormEmail from "@/components/Form/FormEmail.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import Loading from "@/components/Common/Loading.vue";
import FormNumber from "@/components/Form/FormNumber.vue";

export default defineComponent({
  name: "StoreUserAdd",
  components: {
    FormNumber,
    FormEmail,
    FormInput,
    FormYesNo,
    FormDate,
    Loading,
    FormPassword,
    FormSelect
  },
  directives: directives,
  setup(props, context) {
    const {
      formData,
      dataInput,
      initData,
      getPageTitle,
      onSubmit
    } = useVposUserAdd(context);
    return {
      formData,
      dataInput,
      initData,
      getPageTitle,
      onSubmit
    };
  },
  mounted() {
    this.initData();
  }
});
