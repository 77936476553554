



































































































import { defineComponent, ref } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Header from "@/components/Common/Header.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import { useProfileAllergiesStore } from "@/stores/Profile/ProfileAllergiesStore";
import VScroller from "@/components/Common/VScroller.vue";
import SidebarProfileEvents from "@/components/Profile/SidebarProfileEvents.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import NavProfileEvents from "@/components/Profile/NavProfileEvents.vue";
import NavProfileDetails from "@/components/Profile/NavProfileDetails.vue";

export default defineComponent({
  name: "ProfileAllergies",
  components: {
    FormSelect,
    Header,
    Loading,
    Pager,
    TableHeaderColumn,
    VScroller,
    SidebarProfileEvents,
    HeaderProfileDetails,
    NavProfileEvents,
    NavProfileDetails
  },
  setup(props, context) {
    const selectedProfileId = parseInt(context.root.$route.params.profileId);
    const {
      sidebarData,
      headerData,
      pageData,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems
    } = useProfileAllergiesStore(context);
    const showPopup = ref(false);

    return {
      sidebarData,
      selectedProfileId,
      showPopup,
      headerData,
      pageData,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      ApiHelper: ApiHelper
    };
  }
});
