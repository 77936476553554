



























































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormInput from "@/components/Form/FormPhone.vue";
import FormToggle from "@/components/Form/FormToggle.vue";
import directives from "@/helpers/directives";
import { FormSelect as FormSelectType, PagerItem, SelectOption } from "@/types";
import { ApiHelper } from "@/helpers";
import Pager from "@/components/Common/Pager.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import VScroller from "@/components/Common/VScroller.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import SecurityGroupRowTooltip from "@/components/SecurityGroupRowTooltip.vue";

export default defineComponent({
  name: "DispenseMedication",
  props: {
    onDismiss: Function,
    onCallback: Function
  },
  directives,
  components: {
    Modal,
    FormSelect,
    FormNumber,
    FormTextarea,
    FormInput,
    FormToggle,
    Pager,
    FilterCheckboxesColumn,
    FilterSearchColumn,
    FilterNumberRangeColumn,
    VScroller,
    TableHeaderColumn,
    SecurityGroupRowTooltip
  },
  setup(props, context) {
    const pageData = ref<{
      isLoading: boolean;
      controls: {
        events: FormSelectType;
      };
      skip: number;
      take: number;
      items: any[];
      activeTab: string;
      sort: {
        order: string;
        direction: string;
      };
      filter: {
        profile: string;
        medication: string;
        dosage: SelectOption[];
        frequency: SelectOption[];
      };
      pager: {
        showPagerItems: boolean;
        page: number;
        totalPages: number;
        total: number;
        items: PagerItem[];
      };
      selectedMedications: number[];
    }>({
      isLoading: true,
      controls: {
        events: {
          error: "",
          label: "Events",
          placeholder: "Select Event",
          type: "select",
          value: "",
          style: "custom",
          required: true,
          options: []
        }
      },
      skip: 0,
      take: 8,
      items: [],
      activeTab: "",
      sort: {
        order: "11",
        direction: "2"
      },
      filter: {
        profile: "",
        medication: "",
        dosage: [],
        frequency: []
      },
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      },
      selectedMedications: []
    });

    const isTooltipOpen = ref(false);
    const dispensedLogVisibleIndex = ref(-1);
    const dispensedLogLoading = ref<boolean>(false);
    const dispensedLogList = ref([]);

    const closePopup = () => {
      if (props.onDismiss) {
        props.onDismiss();
      }
    };
    const enableButton = () => {
      if (pageData.value.selectedMedications.length) {
        pageData.value.isLoading = false;
      } else {
        pageData.value.isLoading = true;
      }
    };
    const dispenseMedication = async () => {
      if (!pageData.value.selectedMedications.length) return;

      pageData.value.isLoading = true;
      await context.emit("setIsLoading", true);
      const result = await ApiHelper.callApi(
        "post",
        "/medicals/dispenseMedication",
        {
          dispenseMedications: pageData.value.selectedMedications.join(","),
          eventId: pageData.value.controls.events.value
        },
        {}
      );
      pageData.value.isLoading = false;
      await context.emit("setIsLoading", false);
      if (result.status === 1) {
        ApiHelper.showSuccessMessage(result.message);
        context.emit("reloadData", false);
        closePopup();
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    };

    const getEventOptions = async () => {
      pageData.value.controls.events.options = [];
      await context.emit("setIsLoading", true);
      const events = await ApiHelper.callApi(
        "get",
        "/reports/events",
        {},
        {
          activeEvents: 1
        }
      );
      await context.emit("setIsLoading", false);

      if (events.status == 1) {
        pageData.value.controls.events.options = events.data.events.map(
          (item: any) => ({
            id: item.eventId,
            text: item.eventName
          })
        );
      }
    };

    const getMedication = async (page: number) => {
      pageData.value.pager.page = page || 1;
      pageData.value.isLoading = true;
      pageData.value.skip =
        (pageData.value.pager.page - 1) * pageData.value.take;
      await context.emit("setIsLoading", true);
      const result = await ApiHelper.callApi(
        "get",
        "/medicals",
        {},
        {
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          skip: pageData.value.skip,
          take: pageData.value.take,
          eventId: pageData.value.controls.events.value,
          profile: pageData.value.filter.profile || "",
          key: pageData.value.filter.medication || "",
          route: pageData.value.filter.dosage || "",
          frequency: pageData.value.filter.frequency || "",
          ignoreMedicationStatus: "2"
        }
      );
      // pageData.value.isLoading = false;
      await context.emit("setIsLoading", false);
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.items || [];
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    };

    const onChangeEvents = () => {
      pageData.value.selectedMedications = [];
      pageData.value.isLoading = true;
      getMedication(1);
    };

    // pager
    const gotoPage = (page: string) => {
      pageData.value.pager.page = parseInt(page);
      getMedication(pageData.value.pager.page);
    };

    const onClickPrev = () => {
      if (pageData.value.pager.page > 1) {
        pageData.value.pager.page -= 1;
      }
      getMedication(pageData.value.pager.page);
    };

    const onClickNext = () => {
      if (pageData.value.pager.page < pageData.value.pager.totalPages) {
        pageData.value.pager.page += 1;
      }
      getMedication(pageData.value.pager.page);
    };

    // filters
    const setActiveFilterTab = (tab: string) => {
      if (pageData.value.activeTab !== tab) {
        pageData.value.activeTab = tab;
      } else {
        pageData.value.activeTab = "";
      }
    };

    const closeFilterTab = () => {
      getMedication(1);
      pageData.value.activeTab = "";
    };

    const updateFilterValue = (name: string, value: string) => {
      switch (name) {
        case "profile":
          pageData.value.filter.profile = value;
          break;
        case "medication":
          pageData.value.filter.medication = value;
          break;
      }
      pageData.value.pager.page = 1;
      getMedication(pageData.value.pager.page);
      enableButton();
    };

    const getFiltersData = () => {
      const filters = [];
      if (pageData.value.filter.profile !== "") {
        filters.push({
          label: "PROFILE",
          key: pageData.value.filter.profile + Math.random(),
          value: pageData.value.filter.profile,
          reset: () => {
            pageData.value.filter.profile = "";
            pageData.value.activeTab = "";
          }
        });
      }
      if (pageData.value.filter.medication !== "") {
        filters.push({
          label: "MEDICATION NAME",
          key: pageData.value.filter.medication + Math.random(),
          value: pageData.value.filter.medication,
          reset: () => {
            pageData.value.filter.medication = "";
            pageData.value.activeTab = "";
          }
        });
      }
      const selectedRoutes = pageData.value.filter.dosage
        .filter((item: any) => item.selected)
        .map((item: any) => {
          return item.text;
        });
      if (selectedRoutes.length > 0) {
        filters.push({
          label: "DOSAGE",
          key: selectedRoutes.join("-") + Math.random(),
          value: selectedRoutes.join(", "),
          reset: () => {
            pageData.value.filter.dosage.map((item: any) => {
              item.selected = false;
            });
            pageData.value.activeTab = "";
          }
        });
      }
      const selectedFrequencies = pageData.value.filter.frequency
        .filter((item: any) => item.selected)
        .map((item: any) => {
          return item.text;
        });
      if (selectedFrequencies.length > 0) {
        filters.push({
          label: "FREQUENCY",
          key: selectedFrequencies.join("-") + Math.random(),
          value: selectedFrequencies.join(", "),
          reset: () => {
            pageData.value.filter.frequency.map((item: any) => {
              item.selected = false;
            });
            pageData.value.activeTab = "";
          }
        });
      }
      return {
        list: filters
      };
    };

    const updateSortValue = (sort: string, direction: string) => {
      pageData.value.sort.order = sort;
      pageData.value.sort.direction = direction;
      getMedication(pageData.value.pager.page);
    };

    const showDispensedLog = async (item: any, index: number) => {
      if (!isTooltipOpen.value) {
        dispensedLogVisibleIndex.value = index;
        dispensedLogLoading.value = true;
        const result = await ApiHelper.callApi(
          "get",
          `/medicals/dispensedLog`,
          {},
          {
            getAll: 1,
            medicalId: item.medicalId
          }
        );
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          dispensedLogVisibleIndex.value = -1;
          return false;
        }
        dispensedLogList.value = result.data.items.map((t: any) => {
          return {
            id: t.medicalId,
            text: t.startDateFormatted || ""
          };
        });

        dispensedLogLoading.value = false;
        isTooltipOpen.value = true;
      }
    };

    const hideDispensedLog = () => {
      isTooltipOpen.value = false;
      dispensedLogLoading.value = false;
      dispensedLogVisibleIndex.value = -1;
    };

    // init data
    (async () => {
      await getEventOptions();
    })();

    return {
      closePopup,
      pageData,
      dispenseMedication,
      onChangeEvents,
      getMedication,
      gotoPage,
      onClickPrev,
      onClickNext,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      getFiltersData,
      updateSortValue,
      ApiHelper,
      showDispensedLog,
      hideDispensedLog,
      dispensedLogVisibleIndex,
      dispensedLogLoading,
      dispensedLogList,
      enableButton
    };
  }
});
