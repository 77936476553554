




























































































































































































































































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import HeaderEventCabin from "@/components/Event/HeaderEventCabin.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import { useEventCabinDetailsStore } from "@/stores/Event/EventCabinDetailsStore";
import Header from "@/components/Common/Header.vue";
import Modal from "@/components/Common/Modal.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import VScroller from "@/components/Common/VScroller.vue";
import DropdownParticipantStatus from "@/components/Participant/DropdownParticipantStatus.vue";
import { ApiHelper } from "@/helpers";
import $ from "jquery";
import LeaderLine from "leader-line-vue";

export default defineComponent({
  name: "EventCabinDetailsPage",
  components: {
    VScroller,
    Header,
    VAlertMessage,
    SidebarEventDetails,
    HeaderEventCabin,
    Loading,
    Modal,
    Pager,
    Avatar,
    Select2,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    FilterSelectColumn,
    FilterAgesColumn,
    FilterDateColumn,
    AutoCompleteInput,
    DropdownParticipantStatus
  },
  setup(props, context) {
    const {
      loadAvailableParticipants,
      cabinData,
      updateItemStatus,
      openParticipantsPopup,
      // sort
      updateSortValue,
      popupNewItem,
      onRemoveFromGroup,
      // page
      headerData,
      pageData,
      showStatusDropdown,
      // filters
      selectAllStatuses,
      resetStatuses,
      selectAllGenders,
      resetGenders,
      selectAllCabins,
      resetCabins,
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      searchParticipants,
      selectParticipant,
      lines,
      hideLines,
      resetLines,
      hilightRelatedLines,
      onScroll,
      drawLines
    } = useEventCabinDetailsStore(context);

    const getEventNameFromSidebar = ($parent: any) => {
      const SidebarEventDetailsRef = $parent.$refs.SidebarEventDetailsRef || {};
      const sidebarData = SidebarEventDetailsRef.sidebarData || {};
      return sidebarData.name || "";
    };

    const getRoommates = (participantId: number) => {
      const roommates = [];
      // check related roommate requests
      const relatedRoommates: any = pageData.value.roommateRequests.filter(
        (t: any) =>
          t.toParticipantId == participantId ||
          t.invitedParticipantId == participantId
      );
      for (const rm of relatedRoommates) {
        if (rm.toParticipantId == participantId) {
          // was invited by other
          roommates.push({
            profileId: rm.invitedProfileId,
            participantId: rm.invitedParticipantId,
            firstName: rm.invitedFname,
            lastName: rm.invitedLname,
            fullName: ApiHelper.getFullName(rm.invitedFname, rm.invitedLname)
          });
        } else if (rm.invitedParticipantId == participantId) {
          // invited the roommate request
          roommates.push({
            profileId: rm.profileId,
            participantId: rm.toParticipantId,
            firstName: rm.firstName,
            lastName: rm.lastName,
            fullName: ApiHelper.getFullName(rm.firstName, rm.lastName)
          });
        }
      }
      return roommates;
    };

    const getParticipantTitle = (item: any) => {
      let ret = `${item.firstName} ${item.lastName}`;
      const roommates = getRoommates(item.id);
      if (roommates.length) {
        ret += ` pairs with ${roommates.map(t => t.fullName).join(", ")}`;
      }

      return ret;
    };

    return {
      loadAvailableParticipants,
      getEventNameFromSidebar,
      cabinData,
      updateItemStatus,
      openParticipantsPopup,
      // sort
      updateSortValue,
      popupNewItem,
      onRemoveFromGroup,
      // page
      headerData,
      pageData,
      showStatusDropdown,
      // filters
      selectAllStatuses,
      resetStatuses,
      selectAllGenders,
      resetGenders,
      selectAllCabins,
      resetCabins,
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      searchParticipants,
      selectParticipant,
      ApiHelper,
      getParticipantTitle,
      lines,
      resetLines,
      hideLines,
      hilightRelatedLines,
      onScroll,
      drawLines
    };
  },
  async mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // reset lines when resizing
    $(document).on("resize", async function() {
      if ($(".cabin-details-page").length) {
        await Promise.all([self.$forceUpdate()]);
        self.resetLines();
      }
    });

    $("#content").on("scroll", () => {
      self.onScroll();
    });
  },
  beforeDestroy() {
    // make sure remove lines
    for (const item of this.lines) {
      item.line?.remove();
    }
  }
});
