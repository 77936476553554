






















































































































































































































import { defineComponent } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import HeaderVPos from "@/components/vpos/HeaderVPos.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { useVposOrderCreateStore } from "@/stores/vpos/VposOrderCreateStore";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import DropdownParticipantStatus from "@/components/Participant/DropdownParticipantStatus.vue";
import FormSelect from "@/components/Form/FormSelect.vue";

export default defineComponent({
  name: "StoreOrderCreate",
  components: {
    VAlertMessage,
    HeaderVPos,
    FilterDateColumn,
    FilterSelectColumn,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterAgesColumn,
    FilterNumberRangeColumn,
    TableHeaderColumn,
    DropdownParticipantStatus,
    Pager,
    Loading,
    FormSelect
  },
  setup(props, context) {
    const {
      errors,
      pageData,
      formData,
      // pager
      gotoPage,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      getFiltersData,
      updateSortValue,
      inSelectedIds,
      allowRefund,
      onSubmit
    } = useVposOrderCreateStore(context);

    const getPercent = (percent: number) => {
      return (percent || 0) > 100 ? 100 : percent;
    };

    return {
      errors,
      pageData,
      formData,
      // pager
      gotoPage,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      getFiltersData,
      // sort
      updateSortValue,
      getPercent,
      inSelectedIds,
      allowRefund,
      onSubmit
    };
  }
});
