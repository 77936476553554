












































import { defineComponent, ref } from "@vue/composition-api";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "FormAutoComplete",
  components: {
    AutoCompleteInput
  },
  props: {
    data: Object
  },
  setup(props, context) {
    const isACILoading = ref(true);
    const randomId = ref<number>(ApiHelper.randomFormId());
    const onSuggestItems = async (key: string) => {
      if (props.data && props.data.suggestItems) {
        isACILoading.value = true;
        await props.data.suggestItems(key);
        isACILoading.value = false;
      }
    };
    return {
      onSuggestItems,
      isACILoading,
      randomId: randomId.value.toString()
    };
  }
});
