








































































import { defineComponent, ref } from "@vue/composition-api";

import Loading from "@/components/Common/Loading.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormDate from "@/components/Form/FormDate.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import BackButton from "@/components/Common/BackButton.vue";
import { ApiHelper } from "@/helpers";
import SidebarProfileEvents from "@/components/Profile/SidebarProfileEvents.vue";
import FormToggle from "@/components/Form/FormToggle.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import { useProfileNoteEditStore } from "@/stores/Profile/ProfileNotelEditStore";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";

export default defineComponent({
  name: "ParticipantNoteEdit",
  components: {
    HeaderProfileDetails,
    FormTextarea,
    Loading,
    FormInput,
    FormSelect,
    FormDate,
    FormNumber,
    BackButton,
    SidebarProfileEvents,
    FormToggle
  },
  setup(props, context) {
    const profile = ref({
      id: "-",
      firstName: "-",
      lastName: "-",
      tags: []
    });
    const headerData = ref({
      title: "",
      subTitle: ""
    });
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/" + context.root.$route.params.profileId + "/info",
        {},
        {}
      );
      if (result.status === 1) {
        profile.value = result.data || { id: 0 };
      }
    })();

    (async () => {
      const selectedProfileId =
        Number.parseInt(context.root.$route.params.profileId, 10) || 0;
      const participantId =
        Number.parseInt(context.root.$route.query.participantId + "", 10) || 0;
      if (participantId > 0) {
        const result = await ApiHelper.callApi(
          "get",
          "/participants/" + participantId,
          {},
          {}
        );
        if (result.status === 1) {
          headerData.value.subTitle =
            ApiHelper.getFullName(
              result.data.firstName || "",
              result.data.lastName || ""
            ) +
              " - " +
              result.data.eventName || "";
        }
      } else {
        const result = await ApiHelper.callApi(
          "get",
          "/profiles/" + selectedProfileId + "/info",
          {},
          {}
        );
        if (result.status === 1) {
          headerData.value.subTitle = ApiHelper.getFullName(
            result.data.firstName || "",
            result.data.lastName || ""
          );
        }
      }
    })();

    const { formData, removeFieldError, onSubmit } = useProfileNoteEditStore(
      context
    );
    return {
      headerData,
      profile,
      formData,
      removeFieldError,
      onSubmit
    };
  }
});
