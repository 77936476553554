





























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "DropdownBox",
  props: {
    onClick: Function,
    onDismiss: Function,
    label: String,
    show: Boolean,
    green: Boolean,
    leftToRight: Boolean,
    isLoading: Boolean
  },
  components: {},
  setup(props, content) {
    const doClick = () => {
      content.emit("onClick");
    };
    const onClickOutside = () => {
      content.emit("onDismiss");
    };
    return {
      doClick,
      onClickOutside
    };
  }
});
