var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.$route.fullPath,staticClass:"ProfileDetailsMasterPage MasterPage"},[_c('div',{attrs:{"id":"content"}},[_c('router-view')],1),(
      !(_vm.$route.query.id || _vm.$route.query.participantId) &&
        ![
          'ProfileDashboard',
          'ProfileDetails',
          'ProfileEvents',
          'ProfileTribes',
          'ProfileFiles',
          'ProfileTodos',
          'ProfileAtCamp',
          'ProfileFinancial',
          'ProfileNotes',
          'ProfileNoteEdit',
          'ProfileMedicals',
          'ProfileMedicalEdit',
          'ProfileSettings',
          'ProfileMessages',
          'ProfileEdit'
        ].includes(_vm.$route.name)
    )?_c('SidebarProfileDetails',{ref:"SidebarProfileRef",attrs:{"profile-id":("" + (_vm.$route.params.profileId))}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }