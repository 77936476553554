import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import FormSelect from "@/types/FormSelect";
import FormDate from "@/types/FormDate";
import SelectOption from "../../types/SelectOption";
import PagerItem from "../../types/PagerItem";
import moment from "moment";

export function useReportMedicalStore(context: any) {
  const query = context.root.$route.query;
  const pageData = ref<{
    isLoading: boolean;
    activeTab: string;
    controls: {
      events: FormSelect;
      cabins: FormSelect;
      startDate: FormDate;
      endDate: FormDate;
    };
    medicalLogs: {
      skip: number;
      take: number;
      items: any[];
      sort: {
        order: string;
        direction: string;
      };
      filter: {
        route: SelectOption[];
        frequency: SelectOption[];
        incidentType: SelectOption[];
        name: string;
        type: string;
        summary: string;
        medicalLogsSummary: string;
        symptoms: SelectOption[];
        medicalLogsProfile: string;
        date: string;
        minDate: string;
        maxDate: string;
      };
      showHeadActions: boolean;
      pager: {
        showPagerItems: boolean;
        page: number;
        totalPages: number;
        total: number;
        items: PagerItem[];
      };
    };
    selectedSymptoms: SelectOption[];
    searchSymptomsValue: {
      value: string;
    };
    searchSymptomsLoading: boolean;
  }>({
    isLoading: false,
    activeTab: "",
    controls: {
      events: {
        error: "",
        label: "Events",
        placeholder: "Select Event",
        type: "select",
        value: "",
        style: "custom",
        required: true,
        options: []
      },
      cabins: {
        error: "",
        label: "Cabins",
        placeholder: "Select Cabin",
        type: "select",
        value: "",
        style: "custom",
        required: false,
        notRequired: true,
        options: []
      },
      startDate: {
        error: "",
        format: "MM/DD/YYYY hh:mm A",
        placeholder: "MM/DD/YYYY hh:mm",
        label: "From Date Time",
        type: "datetime",
        value: undefined,
        style: "custom"
      },
      endDate: {
        error: "",
        format: "MM/DD/YYYY hh:mm A",
        placeholder: "MM/DD/YYYY hh:mm",
        label: "To Date Time",
        type: "datetime",
        value: undefined,
        style: "custom"
      }
    },
    medicalLogs: {
      skip: 0,
      take: 8,
      sort: {
        // default sort by startDate desc
        order: context.root.$route.query.order || "9",
        direction: context.root.$route.query.direction || "2"
      },
      filter: {
        route: [],
        frequency: [],
        incidentType: ApiHelper.getIncidentTypeOptions(),
        name: context.root.$route.query.name || "",
        type: context.root.$route.query.type || "1",
        summary: "",
        medicalLogsSummary: "",
        symptoms: [],
        medicalLogsProfile: "",
        date: "",
        minDate: "",
        maxDate: ""
      },
      items: [],
      showHeadActions: false,
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      }
    },
    selectedSymptoms: [],
    searchSymptomsValue: {
      value: query.searchSymptomsValue || ""
    },
    searchSymptomsLoading: false
  });

  const getEventOptions = async () => {
    const events = await ApiHelper.callApi("get", "/reports/events", {}, {});
    if (events.status == 1) {
      pageData.value.controls.events.options = events.data.events.map(
        (item: any) => ({
          id: item.eventId,
          text: item.eventName
        })
      );
    }
  };

  const getMedicalLogs = async (page: number) => {
    const eventId = parseInt(pageData.value.controls.events.value) || 0;
    const groupId = parseInt(pageData.value.controls.cabins.value) || 0;
    const startDate = pageData.value.controls.startDate.value;
    const endDate = pageData.value.controls.endDate.value;

    let isValid = true;
    // event is required
    if (eventId == 0) {
      isValid = false;
      pageData.value.controls.events.error = "Event is required!";
    }

    if (startDate && endDate) {
      if (startDate > endDate) {
        isValid = false;
        pageData.value.controls.endDate.error = "To Date Time is invalid";
      }
    }

    if (!isValid) return;

    pageData.value.medicalLogs.pager.page = page || 1;
    ApiHelper.setDataLoading(true);
    pageData.value.medicalLogs.skip =
      (pageData.value.medicalLogs.pager.page - 1) *
      pageData.value.medicalLogs.take;
    const result = await ApiHelper.callApi(
      "get",
      "/medicals",
      {},
      {
        eventIds: `${eventId}`,
        groupId,
        skip: pageData.value.medicalLogs.skip,
        take: pageData.value.medicalLogs.take,
        order: parseInt(pageData.value.medicalLogs.sort.order),
        direction: parseInt(pageData.value.medicalLogs.sort.direction),
        type: 2,
        key: pageData.value.medicalLogs.filter.name || "",
        incidentType:
          ApiHelper.convertSelectedOptionsToString(
            pageData.value.medicalLogs.filter.incidentType
          ) || undefined,
        medicalLogsSummary:
          pageData.value.medicalLogs.filter.medicalLogsSummary,
        symptom: pageData.value.selectedSymptoms
          .map((item: any) => item.id)
          .join(","),
        profile: pageData.value.medicalLogs.filter.medicalLogsProfile,
        fromDateTime:
          startDate && moment(startDate).isValid()
            ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss") + ".000Z"
            : undefined,
        toDateTime:
          endDate && moment(endDate).isValid()
            ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss") + ".000Z"
            : undefined,
        getInfo: "emergencyInfo"
      }
    );
    ApiHelper.setDataLoading(false);
    pageData.value.isLoading = false;
    if (result.status === 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.medicalLogs.pager.totalPages = Math.ceil(
        totalCount / pageData.value.medicalLogs.take
      );
      pageData.value.medicalLogs.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.medicalLogs.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.medicalLogs.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.medicalLogs.pager.items = pagerList;
      pageData.value.medicalLogs.items = result.data.items || [];
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
    }
  };

  const onChangeEvents = async () => {
    pageData.value.controls.cabins.options = [];
    pageData.value.controls.cabins.value = "";
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "get",
      "/reports/events/cabins",
      {},
      {
        eventId: pageData.value.controls.events.value
      }
    );
    if (result.status == 1) {
      pageData.value.controls.cabins.options = result.data.cabins.map(
        (item: any) => ({
          id: item.groupId,
          text: item.groupName
        })
      );
    }
    ApiHelper.setDataLoading(false);
  };

  const updateRouters = async () => {
    const eventId = parseInt(pageData.value.controls.events.value) || 0;
    const groupId = parseInt(pageData.value.controls.cabins.value) || 0;
    const startDate = pageData.value.controls.startDate.value;
    const endDate = pageData.value.controls.endDate.value;
    context.root.$router
      .replace({
        name: "ReportMedicalGeneral",
        query: {
          eventId,
          groupId,
          startDate:
            startDate && moment(startDate).isValid()
              ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss")
              : undefined,
          endDate:
            endDate && moment(endDate).isValid()
              ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
              : undefined,
          // medical logs params
          mlpage: pageData.value.medicalLogs.pager.page + "",
          mlorder: pageData.value.medicalLogs.sort.order,
          mldirection: pageData.value.medicalLogs.sort.direction,
          incidentType:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.medicalLogs.filter.incidentType
            ) || undefined,
          medicalLogsSummary:
            pageData.value.medicalLogs.filter.medicalLogsSummary,
          selectedSymptomIds: pageData.value.selectedSymptoms
            .map((item: any) => item.id)
            .join(","),
          searchSymptomsValue: pageData.value.searchSymptomsValue.value,
          medicalLogsProfile:
            pageData.value.medicalLogs.filter.medicalLogsProfile,
          minDateMedicalLogs: pageData.value.medicalLogs.filter.minDate || "",
          maxDateMedicalLogs: pageData.value.medicalLogs.filter.maxDate || ""
        }
      })
      .catch((err: any) => {
        // console.log("update routers error");
      });
  };

  const gotoPage = (page: string, type = "") => {
    if (type == "medicalLogs") {
      pageData.value.medicalLogs.pager.page = parseInt(page);
      updateRouters();
      getMedicalLogs(pageData.value.medicalLogs.pager.page);
    }
  };

  const onClickPrev = (type = "") => {
    if (type == "medicalLogs") {
      if (pageData.value.medicalLogs.pager.page > 1) {
        pageData.value.medicalLogs.pager.page -= 1;
        updateRouters();
        getMedicalLogs(pageData.value.medicalLogs.pager.page);
      }
    }
  };

  const onClickNext = (type = "") => {
    if (type == "medicalLogs") {
      if (
        pageData.value.medicalLogs.pager.page <
        pageData.value.medicalLogs.pager.totalPages
      ) {
        pageData.value.medicalLogs.pager.page += 1;
        updateRouters();
        getMedicalLogs(pageData.value.medicalLogs.pager.page);
      }
    }
  };

  const closeFilterTab = () => {
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }

    if (pageData.value.activeTab == "Symptom") {
      if (pageData.value.searchSymptomsValue.value != "") return;
      pageData.value.searchSymptomsLoading = true;
      pageData.value.medicalLogs.filter.symptoms = [];
      pageData.value.medicalLogs.filter.symptoms = await ApiHelper.getSymptomOptions(
        {
          getAll: 1
        }
      );
      const selectedSymptomIds = (
        context.root.$route.query.selectedSymptomIds || ""
      )
        .split(",")
        .map((id: string) => parseInt(id));
      pageData.value.medicalLogs.filter.symptoms = pageData.value.medicalLogs.filter.symptoms.map(
        item => ({
          ...item,
          selected: selectedSymptomIds.includes(item.id)
        })
      );
      pageData.value.searchSymptomsLoading = false;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await updateSelectedSymptoms();
    }
  };

  const getFiltersData = () => {
    const filters = [];
    const selectedIncidentTypes = pageData.value.medicalLogs.filter.incidentType
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedIncidentTypes.length > 0) {
      filters.push({
        label: "INCIDENT TYPE",
        key: selectedIncidentTypes.join("-") + Math.random(),
        value: selectedIncidentTypes.join(", "),
        reset: () => {
          pageData.value.medicalLogs.filter.incidentType.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
          getMedicalLogs(1);
        }
      });
    }
    const medicalLogsProfile =
      pageData.value.medicalLogs.filter.medicalLogsProfile || "";
    if (medicalLogsProfile !== "") {
      filters.push({
        label: "PROFILE",
        key: medicalLogsProfile + Math.random(),
        value: medicalLogsProfile,
        reset: () => {
          pageData.value.medicalLogs.filter.medicalLogsProfile = "";
          updateRouters();
          getMedicalLogs(1);
        }
      });
    }
    const medicalLogsSummary =
      pageData.value.medicalLogs.filter.medicalLogsSummary || "";
    if (medicalLogsSummary !== "") {
      filters.push({
        label: "MEDICAL SUMMARY",
        key: medicalLogsSummary + Math.random(),
        value: medicalLogsSummary,
        reset: () => {
          pageData.value.medicalLogs.filter.medicalLogsSummary = "";
          updateRouters();
          getMedicalLogs(1);
        }
      });
    }
    const selectedSymptoms = pageData.value.selectedSymptoms.map(
      (item: any) => {
        return item.text;
      }
    );
    if (selectedSymptoms.length > 0) {
      filters.push({
        label: "SYMPTOMS",
        key: selectedSymptoms.join("-") + Math.random(),
        value: selectedSymptoms.join(", "),
        reset: () => {
          pageData.value.selectedSymptoms = [];
          pageData.value.medicalLogs.filter.symptoms.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
          getMedicalLogs(1);
        }
      });
    }

    return {
      list: filters
    };
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "medicalLogsSummary":
        pageData.value.medicalLogs.pager.page = 1;
        pageData.value.medicalLogs.filter.medicalLogsSummary = value;
        break;
      case "medicalLogsProfile":
        pageData.value.medicalLogs.pager.page = 1;
        pageData.value.medicalLogs.filter.medicalLogsProfile = value;
        break;
      case "medicalLogsDate": {
        pageData.value.medicalLogs.pager.page = 1;
        pageData.value.medicalLogs.filter.date = value;
        const dateArr = value.split("-");
        let minDate = dateArr[0];
        if (minDate) {
          minDate = `${minDate.substring(0, 4)}-${minDate.substring(
            4,
            6
          )}-${minDate.substring(6, 8)}`;
        }
        pageData.value.medicalLogs.filter.minDate = minDate;
        let maxDate = dateArr[1] !== undefined ? dateArr[1] : "";
        if (maxDate) {
          maxDate = `${maxDate.substring(0, 4)}-${maxDate.substring(
            4,
            6
          )}-${maxDate.substring(6, 8)}`;
        }
        pageData.value.medicalLogs.filter.maxDate = maxDate;
        break;
      }
    }
    updateRouters();
  };

  const selectAllSymptoms = () => {
    pageData.value.medicalLogs.filter.symptoms = pageData.value.medicalLogs.filter.symptoms.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    pageData.value.selectedSymptoms = [
      ...pageData.value.medicalLogs.filter.symptoms
    ].filter(item => item.selected);
    updateRouters();
    getMedicalLogs(1);
  };

  const resetSymptoms = () => {
    pageData.value.medicalLogs.filter.symptoms = pageData.value.medicalLogs.filter.symptoms.map(
      (item: any) => {
        item.selected = false;
        return item;
      }
    );
    pageData.value.selectedSymptoms = [];
    pageData.value.medicalLogs.pager.page = 1;
    updateRouters();
    getMedicalLogs(1);
  };

  const searchSymptoms = async (searchValue: string) => {
    pageData.value.searchSymptomsLoading = true;
    let list: any = [];
    if (!pageData.value.medicalLogs.filter.symptoms.length) {
      list = await ApiHelper.getSymptomOptions({
        getAll: 1
      });
    } else {
      list = [...pageData.value.medicalLogs.filter.symptoms];
    }

    // show/hide items related search key
    const key = searchValue.toLowerCase();
    list = list.map((item: any) => ({
      ...item,
      hide: key == "" ? false : item.text.toLocaleLowerCase().indexOf(key) == -1
    }));
    pageData.value.medicalLogs.filter.symptoms = list;

    const selectedSymptomIds = (
      context.root.$route.query.selectedSymptomIds || ""
    )
      .split(",")
      .map((id: string) => parseInt(id));
    pageData.value.medicalLogs.filter.symptoms = pageData.value.medicalLogs.filter.symptoms.map(
      item => ({
        ...item,
        selected: selectedSymptomIds.includes(item.id)
      })
    );
    pageData.value.searchSymptomsLoading = false;
  };

  const updateSelectedSymptoms = async (init = false) => {
    if (init) {
      const searchValue = context.root.$route.query.searchSymptomsValue || "";
      if (searchValue != "") {
        await searchSymptoms(searchValue);
      }
    }
    pageData.value.selectedSymptoms = [
      ...pageData.value.medicalLogs.filter.symptoms
    ].filter(item => item.selected);
  };

  const updateSortValue = async (
    sort: string,
    direction: string,
    type = ""
  ) => {
    if (type == "medicalLogs") {
      pageData.value.medicalLogs.sort.order = sort;
      pageData.value.medicalLogs.sort.direction = direction;
      updateRouters();
      await getMedicalLogs(pageData.value.medicalLogs.pager.page);
    }
  };

  // init data
  (async () => {
    await getEventOptions();
    const eventId = query.eventId || 0;
    const groupId = query.groupId || 0;
    const startDate = query.startDate || undefined;
    const endDate = query.endDate || undefined;
    let loadData = false;
    if (eventId > 0 && pageData.value.controls.events.options.length) {
      pageData.value.controls.events.value = query.eventId;
      await onChangeEvents();
      if (groupId > 0 && pageData.value.controls.cabins.options.length) {
        pageData.value.controls.cabins.value = groupId;
      }
      loadData = true;
    }
    if (startDate) {
      pageData.value.controls.startDate.value = moment(startDate).isValid()
        ? moment(startDate).toDate()
        : undefined;
    }
    if (endDate) {
      pageData.value.controls.endDate.value = moment(endDate).isValid()
        ? moment(endDate).toDate()
        : undefined;
    }
    if (loadData == true) {
      getMedicalLogs(pageData.value.medicalLogs.pager.page || 1);
    }
  })();

  return {
    pageData,
    getMedicalLogs,
    onChangeEvents,
    gotoPage,
    onClickPrev,
    onClickNext,
    setActiveFilterTab,
    closeFilterTab,
    getFiltersData,
    updateFilterValue,
    selectAllSymptoms,
    resetSymptoms,
    searchSymptoms,
    updateRouters,
    updateSelectedSymptoms,
    updateSortValue
  };
}
