import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormText } from "@/types";

export function userAccountingPostingCodeEditStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const headerData = ref({
    id: selectedId,
    title: selectedId > 0 ? "" : "Add Posting/GL Code",
    subTitle: ""
  });

  const pageData = ref<{
    isLoading: boolean;
    isSystem: boolean;
    postingsListRouteName: string;
  }>({
    isLoading: true,
    isSystem: false,
    postingsListRouteName: ""
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      code: FormText;
      glCode: FormText;
      glName: FormText;
      desc: FormText;
      costCenter: {
        error: string;
        label: string;
        textOverflow: boolean;
        placeholder: string;
        type: string;
        key: string;
        value: { id: string; text: string }[];
        options: any;
        suggestTags: any;
        isFirstFocus: boolean;
      };
      revenueSpendCate: FormText;
    };
    validateAll: any;
    actions: {
      onSubmit: any;
    };
  }>({
    isLoading: true,
    controls: {
      code: {
        required: true,
        label: "Posting Code",
        placeholder: "Posting Code",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100
      },
      glCode: {
        required: true,
        label: "GL Code",
        placeholder: "GL Code",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100
      },
      glName: {
        required: true,
        label: "GL Name",
        placeholder: "GL Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100
      },
      desc: {
        required: true,
        // label: "Description",
        label: "Posting Code Name",
        // placeholder: "Description",
        placeholder: "Posting Code Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 255
      },
      costCenter: {
        error: "",
        label: "Cost Center Number",
        textOverflow: true,
        placeholder: "Cost Center Number",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: [],
        isFirstFocus: true
      },
      revenueSpendCate: {
        required: true,
        label: "Revenue Spend Category",
        placeholder: "Revenue spend category",
        style: "custom",
        value: "",
        error: "",
        maxlength: 255
      }
    },
    validateAll: () => {
      let hasError = false;
      if (formData.value.controls.code.value === "") {
        formData.value.controls.code.error = "Posting Code is required";
        hasError = true;
      }
      if (formData.value.controls.desc.value == "") {
        hasError = true;
        formData.value.controls.desc.error = "Posting Code Name is required!";
      }
      if (formData.value.controls.glCode.value === "") {
        formData.value.controls.glCode.error = "GL Code is required";
        hasError = true;
      }
      if (formData.value.controls.glName.value === "") {
        formData.value.controls.glName.error = "GL Name is required";
        hasError = true;
      }
      if (formData.value.controls.revenueSpendCate.value === "") {
        formData.value.controls.revenueSpendCate.error =
          "Revenue Spend Category is required";
        hasError = true;
      }
      return hasError;
    },
    actions: {
      onSubmit: async () => {
        const hasError = formData.value.validateAll();
        if (hasError) {
          return;
        }
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/postingcodes/${selectedId}`,
          {
            code: formData.value.controls.code.value,
            glCode: formData.value.controls.glCode.value,
            glName: formData.value.controls.glName.value,
            desc: formData.value.controls.desc.value,
            costCenterNumber: formData.value.controls.costCenter.value
              .map((t: any) => t.id)
              .join(","),
            revenueSpendCate: formData.value.controls.revenueSpendCate.value
          },
          {}
        );
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
        ApiHelper.showSuccessMessage(selectedId ? "Updated" : "Added");
        ApiHelper.gotoPage(context, {
          name: pageData.value.postingsListRouteName
        });
      }
    }
  });

  const popupCostCenter = ref<{
    show: boolean;
    key: string;
    callback: Function;
  }>({
    show: false,
    key: "",
    callback: (item: any) => {
      // nothing
    }
  });

  const loadData = async () => {
    if (selectedId > 0) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        `/accounting/postingcodes/${selectedId}`,
        {},
        {}
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const resultData = result.data || null;
        if (resultData) {
          headerData.value.title = resultData.postingCode || "";
          formData.value.controls.code.value = resultData.postingCode || "";
          formData.value.controls.glCode.value = resultData.glCode || "";
          formData.value.controls.glName.value = resultData.glName || "";
          formData.value.controls.desc.value = resultData.postingCodeDesc || "";
          pageData.value.isSystem = !!resultData.isSystem;
          headerData.value.subTitle = !pageData.value.isSystem
            ? "Posting/GL Codes"
            : "Transaction Type";
          pageData.value.postingsListRouteName = !pageData.value.isSystem
            ? "FinancialPosting"
            : "TransactionPostings";
          formData.value.controls.revenueSpendCate.value =
            resultData.revenueSpendCate;
          // cost center number
          const costCenterNumber = resultData.costCenterNumber || "";
          if (costCenterNumber) {
            formData.value.controls.costCenter.value = [
              {
                id: costCenterNumber,
                text: costCenterNumber
              }
            ];
          }
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, {
          name: pageData.value.postingsListRouteName
        });
      }
    } else {
      headerData.value.subTitle = !pageData.value.isSystem
        ? "Posting/GL Codes"
        : "Transaction Type";
      pageData.value.postingsListRouteName = !pageData.value.isSystem
        ? "FinancialPosting"
        : "TransactionPostings";
      ApiHelper.setDataLoading(false);
    }
  };

  const isAllowAddNewCostCenter = (item: any, onlyNumber = true) => {
    const regExp = /[a-zA-Z]/g;
    if (
      (item.suggestTags || []).length == 0 &&
      item.key != "" &&
      (!regExp.test(item.key) || !onlyNumber)
    ) {
      return true;
    }

    return false;
  };

  const onAddNewCostCenter = async (key: string) => {
    if (key.trim() == "") return;
    popupCostCenter.value.key = key;
    popupCostCenter.value.show = true;
  };

  const onSuggestCostCenters = async (key: string) => {
    formData.value.controls.costCenter.isFirstFocus = true;
    const costCenters = formData.value.controls.costCenter;
    const notInIds = costCenters.value.map((item: any) => item.id).join(",");
    const result = await ApiHelper.callApi(
      "get",
      "/accounting/costcenters",
      {},
      {
        getAll: 1,
        order: 1,
        direction: 1,
        key,
        notInIds
      }
    );
    if (result.status === 1) {
      costCenters.suggestTags = result.data.items.map((item: any) => {
        return {
          id: item.costCenterNumber,
          text: item.costCenterName || item.costCenterNumber,
          data: item
        };
      });
    }
  };

  const onRemoveLastCostCenter = () => {
    const costCenters = formData.value.controls.costCenter;
    if (costCenters.value.length > 0 && costCenters.key === "") {
      const index = costCenters.value.length - 1;
      costCenters.value.splice(index, 1);
    }
  };

  const onRemoveCostCenter = (index: number) => {
    const costCenter = formData.value.controls.costCenter;
    if (costCenter.value.length > index) {
      costCenter.value.splice(index, 1);
    }
  };

  const onSelectCostCenter = (item: any) => {
    const costCenters = formData.value.controls.costCenter;
    costCenters.key = "";
    costCenters.suggestTags = [];
    costCenters.value.push({
      id: item.id,
      text: item.id
    });
  };

  const onTabPostingCostCenter = (
    e: any,
    type: string,
    item: any,
    index: number,
    callback: Function,
    allowTab = ""
  ) => {
    if (type == "costcenter") {
      if (item.key.trim() != "" && (item.suggestTags || []).length == 0) {
        if (!allowTab) {
          e.preventDefault();
        }
        if (callback) {
          callback();
        }
        setTimeout(() => {
          $(".edit-cost-center-modal input")
            .first()
            .focus();
        }, 300);
      }
    }
  };

  // init data
  (async () => {
    loadData();
  })();

  return {
    loadData,
    headerData,
    pageData,
    formData,
    isAllowAddNewCostCenter,
    onAddNewCostCenter,
    popupCostCenter,
    onRemoveCostCenter,
    onSuggestCostCenters,
    onRemoveLastCostCenter,
    onSelectCostCenter,
    onTabPostingCostCenter
  };
}
