


















import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "ApexChart",
  props: {
    barData: Object
  },
  setup(props, context) {
    const chartData = ref({
      categories: [],
      label: "",
      data: [],
      series: []
    });
    console.log("barData", props.barData);
    if (props.barData) {
      const barData = props.barData || {
        data: {
          labels: [],
          datasets: [
            {
              label: "",
              data: []
            }
          ]
        }
      };
      chartData.value.categories = barData.data.labels;
      chartData.value.series = barData.data.datasets.map((item: any) => {
        return {
          name: item.label,
          data: item.data
        };
      });
    }
    return {
      chartData
    };
  }
});
