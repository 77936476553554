




























































































































































































































import { defineComponent } from "@vue/composition-api";
import FontPicker from "font-picker-vue";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

import { useSettingsPortalCustomizationStoreStore } from "@/stores/Settings/PortalCustomization/SettingsPortalCustomizationStore";
import Loading from "@/components/Common/Loading.vue";
import FormUpload from "@/components/Form/FormUpload.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import Header from "@/components/Common/Header.vue";

export default defineComponent({
  name: "PortalCustomization",
  components: {
    Header,
    FormNumber,
    FormUpload,
    Loading,
    VSwatches,
    FontPicker,
    FormInput,
    FormSelect
  },
  props: {
    callFrom: {
      type: String,
      default: ""
    },
    currentMenuItem: Object
  },
  mounted() {
    // init data
    this.loadData();
  },
  setup(props, context) {
    const {
      formData,
      headerData,
      // methods
      fontSelect,
      createCSSjson,
      loadData,
      // data
      settingsData,
      pageData
    } = useSettingsPortalCustomizationStoreStore(context);

    return {
      formData,
      headerData,
      // methods
      fontSelect,
      createCSSjson,
      loadData,
      // data
      settingsData,
      pageData
    };
  }
});
