var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SettingsUsersPage position-relative d-flex flex-column",staticStyle:{"flex":"1"}},[_c('Header',{attrs:{"has-padding-top":true,"title":_vm.headerData.title,"hideBackButton":true,"filters":_vm.getFiltersData(),"filters-position":'right'}},[_c('h2',{staticClass:"head_heading head_heading__other",attrs:{"slot":"head_heading__other"},slot:"head_heading__other"},[_vm._v(" "+_vm._s(_vm.headerData.title || "")+" "),(_vm.pageData.pager.total > 0)?_c('span',{staticClass:"heading__number"},[_vm._v(" "+_vm._s(_vm.pageData.pager.total)+" ")]):_vm._e(),_c('router-link',{staticClass:"addNewItem",attrs:{"to":{ name: 'SettingsCabinEdit', params: { id: '0' } }}},[_c('img',{attrs:{"src":require("../../../assets/images/icon/add-icon.png"),"alt":"Icon"}}),_c('span',[_vm._v("Add Cabin")])])],1)]),_c('div',{staticClass:"content-inside settings"},[_c('div',{attrs:{"id":"list__view"}},[_c('div',{staticClass:"content__table__scroll"},[_c('div',{staticClass:"content_table table__items"},[_c('div',{staticClass:"table__head not_dropdown"},[_c('div',{staticClass:"items row"},[_c('div',{staticClass:"item col col-4 has__arrow"},[_c('FilterSearchColumn',{attrs:{"label":'Cabin Name',"placeholder":'Search Cabin',"active-tab":_vm.pageData.activeTab,"active-value":'cabin',"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.filter.cabin,"on-change-value":_vm.updateFilterValue,"data":{}}})],1),_c('div',{staticClass:"item col col-6"},[_c('FilterCheckboxesColumn',{attrs:{"label":'Using By Events',"isACILoading":_vm.pageData.searchEventsLoading,"active-tab":_vm.pageData.activeTab,"active-value":'events',"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.filter.events,"close-filter-tab":_vm.closeFilterTab,"on-select-all":_vm.selectAllEvents,"on-reset-value":_vm.resetEvents,"on-change-value":function () {
                      _vm.pageData.pager.page = 1;
                      _vm.updateSelectedEvents();
                      _vm.updateRouters();
                      _vm.loadData(1);
                    },"lazyLoad":true,"lazyLoadPlaceholder":"Search events...","searchValue":_vm.pageData.searchEventsValue},on:{"searchItems":_vm.searchEvents}})],1),_c('div',{staticClass:"item col col-2"})])]),_c('div',{staticClass:"table__body no-stretch"},[(!_vm.pageData.isLoading && _vm.pageData.items.length < 1)?_c('div',{staticClass:"noData"},[_vm._v(" Nothing to see here quite yet...zero cabins. ")]):_vm._e(),_vm._l((_vm.pageData.items),function(item,index){return _c('div',{key:index,staticClass:"items row",attrs:{"data-id":item.cabinId}},[_c('div',{staticClass:"item col col-4 profile-name"},[_c('router-link',{attrs:{"to":{
                    name: 'SettingsCabinEdit',
                    params: {
                      id: item.cabinId
                    }
                  },"title":item.cabinName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
return [_c('a',{staticClass:"tab__item d-flex align-items-center",attrs:{"href":href}},[_vm._v(" "+_vm._s(item.cabinName)+" ")]),(_vm.getTieToGender(item))?_c('div',[_c('small',[_vm._v(_vm._s(_vm.getTieToGender(item)))])]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"item col col-6 event-links"},_vm._l((item.linkEvents),function(link,linkIndex){return _c('router-link',{key:linkIndex,attrs:{"to":{
                    name: 'EventCabins',
                    params: {
                      eventId: link.eventId
                    }
                  },"title":item.eventName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
return [_c('a',{staticClass:"tab__item d-flex align-items-center",attrs:{"href":href}},[_vm._v(" "+_vm._s(link.eventName || "")+" ")])]}}],null,true)})}),1),_c('div',{staticClass:"item col col-2 justify-content-end"},[((item.linkEventId || 0) == 0)?_c('a',{staticClass:"remove-link",attrs:{"href":"#","title":"Remove"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteCabin(item)}}},[_c('img',{attrs:{"src":require("@/assets/images/delete.png"),"alt":"Remove"}})]):_vm._e()])])})],2)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }