















































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";

import { useMessagingInboxStore } from "@/stores/Messaging/Inbox/MessagingInboxStore";
import VScroller from "@/components/Common/VScroller.vue";
import Loading from "@/components/Common/Loading.vue";
import DropdownBox from "@/components/Common/DropdownBox.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";

export default defineComponent({
  name: "MessagingInboxPage",
  components: {
    AutoCompleteInput,
    DropdownBox,
    VScroller,
    Loading
  },
  setup(props, context) {
    const {
      isACILoading,
      newData,
      pageTitle,
      suggestProfiles,
      selectProfile,
      replyQuestion,
      loadGroups,
      selectedId,
      onKeydown,
      popupNewItem,
      // sort
      updateSortValue,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab
    } = useMessagingInboxStore(context);

    return {
      isACILoading,
      newData,
      pageTitle,
      suggestProfiles,
      selectProfile,
      replyQuestion,
      loadGroups,
      selectedId,
      onKeydown,
      popupNewItem,
      // sort
      updateSortValue,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.popupNewItem.formData.controls.participant.value = "";
    this.popupNewItem.formData.controls.form.value = "";
    this.gotoPage("1");
    this.loadGroups();
  }
});
