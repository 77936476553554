








































































import { ref, defineComponent } from "@vue/composition-api";
import VAlertMessage, {
  AlertMessageError
} from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import Pager from "@/components/Common/Pager.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import { ApiHelper } from "@/helpers";

type Event = {
  id: number;
  isChecked: boolean;
  name: string;
  participants: {
    current: number;
    total: number;
    percent: number;
  };
  ages: string;
  date: string;
  financials: {
    income: string;
    outstanding: string;
    total: string;
  };
};

type PagerItem = {
  label: string;
  value: number;
  active: boolean;
};

type PageData = {
  isLoading: boolean;
  skip: number;
  take: number;
  items: Event[];
  filter: {
    entity: number;
  };
  showHeadActions: boolean;
  pager: {
    showPagerItems: boolean;
    page: number;
    total: number;
    totalPages: number;
    items: PagerItem[];
  };
};

export default defineComponent({
  name: "EventGroupsPage",
  components: {
    VAlertMessage,
    HeaderEventDetails,
    HeaderTabs,
    Pager,
    Loading
  },
  setup(props, context) {
    const pageData = ref<PageData>({
      isLoading: false,
      skip: 0,
      take: 8,
      filter: {
        entity: 0
      },
      items: [],
      showHeadActions: false,
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      }
    });
    const errors = ref<AlertMessageError[]>([]);
    const loadData = (page: number) => {
      pageData.value.isLoading = true;
      pageData.value.pager.page = page;
      pageData.value.skip = (page - 1) * pageData.value.take;
      (async () => {
        try {
          const result = await ApiHelper.callApi(
            "get",
            "/cabins",
            {},
            {
              skip: pageData.value.skip,
              take: pageData.value.take,
              eventId: parseInt(context.root.$route.params.eventId)
            }
          );
          pageData.value.isLoading = false;
          if (result.status === 1) {
            const totalCount = result.data.totalCount || 0;
            pageData.value.pager.totalPages = Math.round(
              totalCount / pageData.value.take
            );
            pageData.value.pager.total = totalCount;
            const pagerList = [];
            for (let i = 0; i < pageData.value.pager.totalPages; i++) {
              const pagerItem: PagerItem = {
                label: i + 1 + "",
                value: i + 1,
                active: i + 1 === page
              };
              pagerList.push(pagerItem);
            }
            pageData.value.pager.items = pagerList;
            const list = result.data.cabins.map((item: any) => {
              const tsStart = new Date(item.tsStart);
              const tsEnd = new Date(item.tsEnd);
              return {
                id: item.id,
                isChecked: false,
                name: item.name,
                type: item.type,
                active: item.active
              };
            });
            pageData.value.items = list;
          }
        } catch (err) {
          pageData.value.isLoading = false;
          // if (!isApolloError(err)) {
          //   errors.value = [err];
          //   return;
          // }

          // if (
          //   err.graphQLErrors &&
          //   err.graphQLErrors.findIndex(
          //     gerr =>
          //       gerr.message ===
          //       "Access denied! You don't have permission for this action!"
          //   ) !== -1
          // ) {
          //   context.root.$router.push({ name: "Login" });
          //   return;
          // }

          errors.value = [err];
        }
      })();
    };
    loadData(1);

    const gotoPage = (page: string) => {
      loadData(parseInt(page));
    };

    const onPagerChange = (event: any) => {
      loadData(parseInt(event.target.value));
    };

    const onClickPrev = () => {
      if (pageData.value.pager.page > 1) {
        loadData(pageData.value.pager.page - 1);
      }
    };

    const onClickNext = () => {
      if (pageData.value.pager.page < pageData.value.pager.totalPages) {
        loadData(pageData.value.pager.page + 1);
      }
    };

    const togglePagerItems = () => {
      pageData.value.pager.showPagerItems = !pageData.value.pager
        .showPagerItems;
    };

    const toggleHeadActions = () => {
      pageData.value.showHeadActions = !pageData.value.showHeadActions;
    };

    const hideHeadActions = () => {
      pageData.value.showHeadActions = false;
    };

    const getCheckedItems = () => {
      return pageData.value.items.filter(item => item.isChecked);
    };

    return {
      errors,
      pageData,
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange
    };
  }
});
