import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { PagerItem, SelectOption } from "@/types";
import Vue from "vue";
import { eventCabinDormGeneratorStore } from "./EventCabinDormGeneratorStore";
import LeaderLine from "leader-line-vue";
import { ApiType } from "@/helpers/ApiHelper";

export function useEventCabinsStore(context: any) {
  const selectedEventId = parseInt(context.root.$route.params.eventId) || 0;
  const headerData = ref({
    title: "",
    subTitle: ""
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      event: {
        label?: string;
        error: string;
        type: string;
        value: string;
        options: SelectOption[];
      };
      name: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
    };
  }>({
    isLoading: true,
    controls: {
      event: {
        error: "",
        type: "text",
        value: "",
        options: []
      },
      name: {
        error: "",
        type: "text",
        value: ""
      }
    }
  });
  const pageData = ref<{
    eventStatus: number;
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      status: SelectOption[];
      cabin: string;
      participants: string;
      schedule: string;
      rating: string;
      minRating: string;
      maxRating: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    roommateRequests: any;
    entityCabins: any;
    tsStart: string;
    tsEnd: string;
  }>({
    eventStatus: 1,
    isLoading: true,
    skip: 0,
    take: 8,
    activeTab: "",
    sort: {
      order: "4",
      direction: "2"
    },
    filter: {
      status: [],
      cabin: "",
      participants: "",
      schedule: "",
      rating: "",
      minRating: "",
      maxRating: ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    roommateRequests: [],
    entityCabins: {
      isInline: true,
      required: false,
      label: "",
      style: "custom",
      value: "",
      error: "",
      defaultValues: [],
      showDropdown: false,
      options: []
    },
    tsStart: "",
    tsEnd: ""
  });
  const allParticipants = ref<any>([]);
  const lines = ref<
    {
      key: string;
      line: any;
    }[]
  >([]);
  const LINE_DEFAULT_OPTIONS = {
    color: "#c2c0c0",
    size: 2,
    dash: { animation: false },
    startPlug: "behind",
    endPlug: "behind",
    startSocketGravity: [0, 50],
    endSocketGravity: [0, 50]
  };
  const LINE_HOVER_OPTION = {
    color: "#670478",
    size: 2,
    dash: { animation: true, duration: 500 },
    startPlug: "behind",
    endPlug: "behind",
    startSocketGravity: [0, 50],
    endSocketGravity: [0, 50]
  };

  const loadData = async (page: number, redrawLines = true) => {
    ApiHelper.setDataLoading(true);
    pageData.value.pager.page = page;
    pageData.value.skip = (page - 1) * pageData.value.take;
    try {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      hideLines();
      const result = await ApiHelper.callApi(
        "get",
        "/cabins",
        {},
        {
          // skip: pageData.value.skip,
          // take: pageData.value.take,
          getAll: 1,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          eventId: selectedEventId,
          cabin: pageData.value.filter.cabin || "",
          participant: pageData.value.filter.participants || ""
        }
      );
      pageData.value.isLoading = false;
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.eventStatus = result.data.eventStatus || 0;
        // pageData.value.pager.totalPages = Math.ceil(
        //   totalCount / pageData.value.take
        // );
        pageData.value.pager.total = totalCount;
        // const pagerList = [];
        // for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        //   const pagerItem: PagerItem = {
        //     label: i + 1 + "",
        //     value: i + 1,
        //     active: i + 1 === page
        //   };
        //   pagerList.push(pagerItem);
        // }
        // pageData.value.pager.items = pagerList;
        const cabins = result.data.cabins || [];
        const list = cabins.map((item: any, key: number) => {
          const addTopPopOverToolTip =
            cabins.length > 4 && key > cabins.length - 3 ? true : false;
          return { ...item, addTopPopOverToolTip };
        });
        pageData.value.items = list;
        pageData.value.roommateRequests = result.data.roommateRequests || [];
        pageData.value.tsStart = result.data.tsStart || "";
        pageData.value.tsEnd = result.data.tsEnd || "";

        // set participants list
        allParticipants.value = [];
        for (const item of cabins) {
          allParticipants.value = [...allParticipants.value, ...item.profiles];
        }
        // set roommatesIds for each participant
        allParticipants.value = allParticipants.value.map((item: any) => {
          const participantId = item.participantId || 0;
          const roommates = pageData.value.roommateRequests.filter(
            (t: any) =>
              t.toParticipantId == participantId ||
              t.invitedParticipantId == participantId
          );
          const roommatesIds = roommates.map((rm: any) =>
            rm.toParticipantId == participantId
              ? rm.invitedParticipantId
              : rm.toParticipantId
          );
          item.roommatesIds = roommatesIds;
          return item;
        });

        if (redrawLines) {
          await Promise.all([context.root.$forceUpdate()]);
          // draw lines for roommates
          await ApiHelper.sleep(700);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          drawLines();
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        const errorCode = result.errorCode || "";
        if (errorCode == "event_not_found") {
          context.root.$router.push({
            name: "Events"
          });
        }
      }
    } catch (err) {
      console.log(err);
      pageData.value.items = [];
      ApiHelper.setDataLoading(false);
    }
  };

  // pager
  const gotoPage = (page: string) => {
    loadData(parseInt(page));
  };
  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      loadData(pageData.value.pager.page - 1);
    }
  };
  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      loadData(pageData.value.pager.page + 1);
    }
  };
  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };
  // filters
  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "cabin":
        pageData.value.filter.cabin = value;
        break;
      case "participants":
        pageData.value.filter.participants = value;
        break;
      case "schedule":
        pageData.value.filter.schedule = value;
        break;
      case "rating": {
        pageData.value.filter.rating = value;
        break;
      }
    }
    loadData(1);
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.cabin !== "") {
      filters.push({
        label: "CABIN",
        key: pageData.value.filter.cabin + Math.random(),
        value: pageData.value.filter.cabin,
        reset: () => {
          pageData.value.filter.cabin = "";
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.participants !== "") {
      filters.push({
        label: "PARTICIPANTS",
        key: pageData.value.filter.participants + Math.random(),
        value: pageData.value.filter.participants,
        reset: () => {
          pageData.value.filter.participants = "";
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.schedule !== "") {
      filters.push({
        label: "SCHEDULE",
        key: pageData.value.filter.schedule + Math.random(),
        value: pageData.value.filter.schedule,
        reset: () => {
          pageData.value.filter.schedule = "";
          pageData.value.activeTab = "";
          loadData(1);
        }
      });
    }
    if (pageData.value.filter.rating !== "") {
      filters.push({
        label: "RATING",
        key: pageData.value.filter.rating + Math.random(),
        value: pageData.value.filter.rating,
        reset: () => {
          pageData.value.filter.rating = "";
          loadData(1);
        }
      });
    }
    return {
      list: filters
    };
  };

  // pageData.value.filter.status = ApiHelper.getParticipantStatusOptions();
  const selectAllStatuses = () => {
    pageData.value.filter.status.map((item: any) => {
      item.selected = true;
    });
    loadData(1);
  };
  const resetStatuses = () => {
    pageData.value.filter.status.map((item: any) => {
      item.selected = false;
    });
    loadData(1);
  };
  // const getEvent = async () => {
  //   const result = await ApiHelper.callApi(
  //     "get",
  //     "/events/" + context.root.$route.params.eventId + "/info",
  //     {},
  //     {}
  //   );
  //   if (result.status === 1) {
  //     return (
  //       result.data || {
  //         id: 0,
  //         name: ""
  //       }
  //     );
  //   }
  //   return {
  //     id: 0,
  //     name: ""
  //   };
  // };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    loadData(1);
  };

  const popupNewItem = ref<{
    show: boolean;
    isAddNewSuccess: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
    updateCabinName: Function;
    updateEvent: Function;
  }>({
    show: false,
    isAddNewSuccess: false,
    formData: formData,
    removeFieldError: async () => {
      formData.value.controls.name.error = "";
    },
    onSubmit: async ($parent: any) => {
      let hasError = false;
      const entityCabins = pageData.value.entityCabins.options.filter(
        (item: any) => item.selected
      );

      if (
        formData.value.controls.name.value === "" &&
        formData.value.controls.event.value === "" &&
        !entityCabins.length
      ) {
        hasError = true;
        formData.value.controls.name.error =
          "Please select an event to copy or add cabin with a name!";
      } else {
        formData.value.controls.name.error = "";
      }

      if (!hasError) {
        ApiHelper.setDataLoading(true);
        const result = await ApiHelper.callApi(
          "post",
          "/cabins",
          {
            copyFromEventId: parseInt(formData.value.controls.event.value),
            eventId: selectedEventId,
            name: formData.value.controls.name.value,
            entityCabins
          },
          {}
        );
        if (result.status == 1) {
          popupNewItem.value.isAddNewSuccess = true;
          popupNewItem.value.show = false;
          formData.value.controls.name.value = "";
          ApiHelper.showSuccessMessage("Added");
          loadData(1);
          const parent = $parent;
          if (parent.$refs.SidebarEventDetailsRef) {
            parent.$refs.SidebarEventDetailsRef.loadData();
          }
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
        ApiHelper.setDataLoading(false);
      }
    },
    updateCabinName: () => {
      if (formData.value.controls.name.value == "") return;

      formData.value.controls.event.value = "";
      const eventACIRef: any = context.refs.eventACIRef;
      if (eventACIRef) {
        eventACIRef.key = "";
      }
      pageData.value.entityCabins.options = pageData.value.entityCabins.options.map(
        (item: any) => ({ ...item, selected: false })
      );
    },
    updateEvent: () => {
      formData.value.controls.name.value = "";
    }
  });

  const dormGeneratorDataRef = ref(eventCabinDormGeneratorStore);
  const dormGeneratorData = dormGeneratorDataRef.value.state;

  const updateCabinStatus = async (
    item: any,
    statusOption: any,
    callBack?: any
  ) => {
    if (statusOption.value === 0) {
      if (item.profiles.length > 0) {
        ApiHelper.showErrorMessage(
          `Can't inactive this cabin. The cabin has participants.`
        );
        return false;
      }

      const isConfirm = await Vue.swal({
        html: "Are you sure you want to change? ",
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });
      if (!isConfirm) {
        return false;
      }
    }
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "patch",
      "/cabins/" + item.id + "/status",
      {
        status: statusOption.value,
        eventId: selectedEventId
      },
      {}
    );
    if (result.status === 1) {
      ApiHelper.setDataLoading(false);
      if (callBack) {
        callBack();
      }
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      ApiHelper.setDataLoading(false);
    }
  };

  const popupDormGenerator = ref({
    show: false,
    showConfirm: false,
    confirmModal: {
      title: "",
      items: [{}]
    },
    controls: {
      id: {
        value: 0
      },
      totalNumber: {
        isInline: true,
        required: true,
        maxlength: 10,
        value: "",
        error: "",
        label: "Total Number of Dorms",
        placeholder: "Total Number of Dorms",
        style: "custom"
      },
      maxParticipants: {
        isInline: true,
        required: false,
        maxlength: 10,
        value: "",
        error: "",
        label: "Max Participants Per Dorm",
        placeholder: "Max Participants Per Dorm",
        style: "custom"
      },
      participantTypes: {
        isInline: true,
        required: true,
        label: "Participant Type",
        style: "custom",
        value: "",
        error: "",
        placeholder: "Select...",
        options: []
      },
      status: {
        isInline: true,
        required: true,
        label: "Status",
        style: "custom",
        value: "",
        error: "",
        defaultValues: [],
        showDropdown: false,
        options: [
          // {
          //   id: 0,
          //   title: "Applied",
          //   selected: true
          // },
          {
            id: 1,
            title: "Registered",
            selected: true
          },
          {
            id: 3,
            title: "Checked In",
            selected: false
          },
          {
            id: 6,
            title: "Incomplete",
            selected: false
          }
        ]
      },
      sameRequirements: {
        isInline: true,
        required: false,
        label: "Same Requirements",
        style: "custom",
        value: "0",
        error: "",
        options: [
          { id: "0", text: "Select..." },
          { id: "1", text: "Gender" }
        ]
      },
      groupSimilarities: {
        isInline: true,
        required: false,
        label: "Group Similarities",
        style: "custom",
        value: "0",
        error: "",
        options: [
          { id: "0", text: "Select..." },
          { id: "1", text: "Age" },
          { id: "2", text: "State" }
        ]
      },
      evenlyDistributeDorms: {
        isInline: true,
        required: true,
        value: "0",
        error: "",
        label: "Evenly Distribute Dorms?",
        yesValue: "1",
        noValue: "0"
      },
      pairRoommateRequests: {
        isInline: true,
        required: true,
        label: "Pair Roommate Requests?",
        value: "0",
        error: "",
        yesValue: "1",
        noValue: "0"
      },
      action: {
        label: "",
        style: "custom",
        value: "1",
        error: "",
        options: [
          { id: "1", text: "Delete current Dorm List and Reassign" }
          // { id: "2", text: "Append Only Unassigned Campers" }
        ]
      }
    },
    actions: {
      onSubmit: async () => {
        popupDormGenerator.value.controls.totalNumber.error = "";
        popupDormGenerator.value.controls.maxParticipants.error = "";
        popupDormGenerator.value.controls.sameRequirements.error = "";
        popupDormGenerator.value.controls.groupSimilarities.error = "";
        popupDormGenerator.value.controls.evenlyDistributeDorms.error = "";
        popupDormGenerator.value.controls.pairRoommateRequests.error = "";
        popupDormGenerator.value.controls.participantTypes.error = "";
        popupDormGenerator.value.controls.status.error = "";
        const selectedStatus = popupDormGenerator.value.controls.status.options
          .filter(item => {
            return item.selected && item.selected === true;
          })
          .map(item => item.id)
          .join(",");
        const inputData = {
          totalNumber:
            parseInt(popupDormGenerator.value.controls.totalNumber.value) || 0,
          maxParticipants:
            parseInt(popupDormGenerator.value.controls.maxParticipants.value) ||
            0,
          sameRequirements:
            parseInt(
              popupDormGenerator.value.controls.sameRequirements.value
            ) || 0,
          groupSimilarities:
            parseInt(
              popupDormGenerator.value.controls.groupSimilarities.value
            ) || 0,
          evenlyDistributeDorms:
            parseInt(
              popupDormGenerator.value.controls.evenlyDistributeDorms.value
            ) || 0,
          pairRoommateRequests:
            parseInt(
              popupDormGenerator.value.controls.pairRoommateRequests.value
            ) || 0,
          action: parseInt(popupDormGenerator.value.controls.action.value) || 0,
          pType:
            parseInt(
              popupDormGenerator.value.controls.participantTypes.value
            ) || 0,
          status: selectedStatus
        };
        let hasError = false;
        if (inputData.totalNumber < 1) {
          hasError = true;
          popupDormGenerator.value.controls.totalNumber.error =
            "Total Number is required";
        }
        if (
          popupDormGenerator.value.controls.evenlyDistributeDorms.value === ""
        ) {
          hasError = true;
          popupDormGenerator.value.controls.evenlyDistributeDorms.error =
            "Please select Evenly Distribute Dorms";
        }
        if (
          popupDormGenerator.value.controls.pairRoommateRequests.value === ""
        ) {
          hasError = true;
          popupDormGenerator.value.controls.pairRoommateRequests.error =
            "Please select Pair Roommate Requests";
        }
        if (popupDormGenerator.value.controls.participantTypes.value === "") {
          hasError = true;
          popupDormGenerator.value.controls.participantTypes.error =
            "Please select Participant Type";
        }
        if (inputData.status === "") {
          hasError = true;
          popupDormGenerator.value.controls.status.error =
            "Please select status";
        }
        if (!hasError) {
          ApiHelper.setDataLoading(true);
          pageData.value.roommateRequests = [];
          const selectedEventId = parseInt(context.root.$route.params.eventId);
          const result = await ApiHelper.callApi(
            "post",
            `/events/${selectedEventId}/cabinsGenerator`,
            {
              ...inputData
            }
          );
          ApiHelper.setDataLoading(false);

          if (result.status === 1) {
            // show excluded reasons
            let message = result.message;
            const openDorms = result.data.dorms.find(
              (item: any) => item.dormName == "Open Dorm"
            );
            const li = [];
            if (openDorms) {
              for (const p of openDorms.participants) {
                if (p.excludedReason) {
                  li.push(`<li>${p.name}: ${p.excludedReason}</li>`);
                }
              }
            }
            const reasons = `
              <ul class='excluded-participants'>
                ${li.join("")}
              </ul>
            `;
            if (li.length) {
              message += `, excluded participants: ${reasons}`;
            }

            context.root.$swal.fire({
              title: "Dorm Generator",
              html: message,
              timer: 5000
            });

            popupDormGenerator.value.show = false;
            popupDormGenerator.value.actions.onReset();
            dormGeneratorData.show = true;
            dormGeneratorDataRef.value.setData(
              result.data.dorms,
              result.data.roommateRequests?.items || [],
              inputData,
              popupDormGenerator.value.controls.participantTypes.options,
              result.data.pGroupList || []
            );
            dormGeneratorData.maxParticipant = result.data.maxPerDorm;
            context.root.$router.push({
              path: `/events/${context.root.$route.params.eventId}/cabins/dormgenerate`
            });
          } else {
            if (result.isConfirm) {
              popupDormGenerator.value.confirmModal.title = result.message;
              popupDormGenerator.value.confirmModal.items =
                result.conflictPairs;
              popupDormGenerator.value.showConfirm = true;
              popupDormGenerator.value.show = false;
            } else {
              ApiHelper.showErrorMessage(result.message, "Dorm Generator");
              popupDormGenerator.value.actions.onReset();
              popupDormGenerator.value.show = false;
            }
          }
        }
      },
      onConfirm: async () => {
        const selectedStatus = popupDormGenerator.value.controls.status.options
          .filter(item => {
            return item.selected && item.selected === true;
          })
          .map(item => item.id)
          .join(",");
        const inputData = {
          totalNumber:
            parseInt(popupDormGenerator.value.controls.totalNumber.value) || 0,
          maxParticipants:
            parseInt(popupDormGenerator.value.controls.maxParticipants.value) ||
            0,
          sameRequirements:
            parseInt(
              popupDormGenerator.value.controls.sameRequirements.value
            ) || 0,
          groupSimilarities:
            parseInt(
              popupDormGenerator.value.controls.groupSimilarities.value
            ) || 0,
          evenlyDistributeDorms:
            parseInt(
              popupDormGenerator.value.controls.evenlyDistributeDorms.value
            ) || 0,
          pairRoommateRequests:
            parseInt(
              popupDormGenerator.value.controls.pairRoommateRequests.value
            ) || 0,
          action: parseInt(popupDormGenerator.value.controls.action.value) || 0,
          pType:
            parseInt(
              popupDormGenerator.value.controls.participantTypes.value
            ) || 0,
          status: selectedStatus
        };
        ApiHelper.setDataLoading(true);
        const selectedEventId = parseInt(context.root.$route.params.eventId);
        const result = await ApiHelper.callApi(
          "post",
          `/events/${selectedEventId}/cabinsGenerator`,
          {
            ...inputData,
            ignoredPair: true,
            conflictParticipants: popupDormGenerator.value.confirmModal.items
          }
        );
        ApiHelper.setDataLoading(false);
        if (result.status === 1) {
          // let message = result.message;
          // result.data.dorms.forEach((item: any, index: number) => {
          //   item.participants.forEach((p: any, index: number) => {
          //     if (p.excludedReason) {
          //       message = message + `</br> ${p.names[0]}: ${p.excludedReason}`;
          //     }
          //   });
          // });

          // ApiHelper.showSuccessMessage(message, "Dorm Generator");
          // popupDormGenerator.value.show = false;
          // popupDormGenerator.value.actions.onReset();
          // dormGeneratorData.show = true;
          // dormGeneratorDataRef.value.setData(result.data.dorms, []);
          // // dormGeneratorData.data = result.data.dorms;
          // dormGeneratorData.maxParticipant = result.data.maxPariticipantPerDorm;

          // let message = result.message;
          // result.data.dorms.forEach((item: any, index: number) => {
          //   item.participants.forEach((p: any, index: number) => {
          //     if (p.excludedReason) {
          //       message = message + `</br> ${p.names[0]}: ${p.excludedReason}`;
          //     }
          //   });
          // });
          ApiHelper.showSuccessMessage(result.message, "Dorm Generator");

          popupDormGenerator.value.show = false;
          popupDormGenerator.value.actions.onReset();
          dormGeneratorData.show = true;
          dormGeneratorDataRef.value.setData(
            result.data.dorms,
            result.data.roommateRequests?.items || [],
            inputData,
            popupDormGenerator.value.controls.participantTypes.options,
            result.data.pGroupList || []
          );
          dormGeneratorData.maxParticipant = result.data.maxPerDorm;
          context.root.$router.push({
            path: `/events/${context.root.$route.params.eventId}/cabins/dormgenerate`
          });
        } else {
          ApiHelper.showErrorMessage(result.message, "Dorm Generator");
        }
      },
      onReset: () => {
        popupDormGenerator.value.show = false;
        popupDormGenerator.value.showConfirm = false;
        popupDormGenerator.value.controls.totalNumber.value = "";
        popupDormGenerator.value.controls.maxParticipants.value = "";
        popupDormGenerator.value.controls.sameRequirements.value = "0";
        popupDormGenerator.value.controls.groupSimilarities.value = "0";
        popupDormGenerator.value.controls.evenlyDistributeDorms.value = "0";
        popupDormGenerator.value.controls.pairRoommateRequests.value = "0";
        popupDormGenerator.value.controls.participantTypes.value = "";
        popupDormGenerator.value.controls.status.options.map(item => {
          if (item.id === 0 || item.id === 1) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
        popupDormGenerator.value.showConfirm = false;
        popupDormGenerator.value.controls.action.value = "1";
        //reset error
        popupDormGenerator.value.controls.totalNumber.error = "";
        popupDormGenerator.value.controls.maxParticipants.error = "";
        popupDormGenerator.value.controls.sameRequirements.error = "";
        popupDormGenerator.value.controls.groupSimilarities.error = "";
        popupDormGenerator.value.controls.evenlyDistributeDorms.error = "";
        popupDormGenerator.value.controls.pairRoommateRequests.error = "";
        popupDormGenerator.value.controls.participantTypes.error = "";
        popupDormGenerator.value.controls.action.error = "";
        popupDormGenerator.value.controls.status.error = "";
      },
      onChangeConfirmBox: (obj: any) => {
        popupDormGenerator.value.confirmModal.items.map((pair: any) => {
          if (obj.item.p1Id === pair.p1Id && obj.item.p2Id === pair.p2Id) {
            pair.selected = obj.value;
          }
        });
        console.log(obj);
      },
      onConfirmSelectAll: (value: any) => {
        popupDormGenerator.value.confirmModal.items.map((item: any) => {
          item.selected = value;
        });
      }
      // onLoad: async () => {
      //   const selectedEventId = parseInt(context.root.$route.params.eventId);
      //   const types: any = await ApiHelper.loadPTypes(selectedEventId);
      //   popupDormGenerator.value.controls.participantTypes.options = types;
      // }
    }
  });

  const hideLines = () => {
    for (const item of lines.value) {
      item.line?.hide();
    }
  };

  const deleteLines = () => {
    for (const item of lines.value) {
      item.line?.remove();
    }
    lines.value = [];
  };

  const drawLines = () => {
    deleteLines();
    for (const p of allParticipants.value) {
      const participantId = p.participantId;
      for (const roommatePId of p.roommatesIds) {
        const relatedLine = lines.value.find(item =>
          [
            `${participantId}-${roommatePId}`,
            `${roommatePId}-${participantId}`
          ].includes(item.key)
        );
        if (!relatedLine) {
          const start = $(`#participant${participantId} .achor-point`).get(0);
          const end = $(`#participant${roommatePId} .achor-point`).get(0);
          if (start && end) {
            const key = `${participantId}-${roommatePId}`;
            const line = LeaderLine.setLine(start, end, LINE_DEFAULT_OPTIONS);
            lines.value.push({ key, line });
          }
        }
      }
    }
    $(".leader-line").addClass("lines-in-cabin");
  };

  const resetLines = async (sleep = true) => {
    if (sleep) {
      await ApiHelper.sleep(700);
    }

    // re-draw lines
    const newLines = [];
    for (const item of lines.value) {
      const participantIds = item.key.split("-");
      const fromId = participantIds[0];
      const toId = participantIds[1];
      const start = $(`#participant${fromId} .achor-point`).get(0);
      const end = $(`#participant${toId} .achor-point`).get(0);
      item.line?.remove();
      if (start && end) {
        item.line = LeaderLine.setLine(start, end, LINE_DEFAULT_OPTIONS);
        newLines.push(item);
      }
    }
    $(".leader-line").addClass("lines-in-cabin");
    lines.value = newLines;
  };

  const hilightRelatedLines = (participantId: number, hilight: boolean) => {
    if (!participantId) return;

    // get requests that this participant has sent
    const sentRequest = pageData.value.roommateRequests.filter(
      (item: any) => item.invitedParticipantId == participantId
    );
    if (sentRequest.length) {
      const relatedLineKeys = sentRequest
        .map(
          (item: any) =>
            `${item.invitedParticipantId}-${item.toParticipantId},${item.toParticipantId}-${item.invitedParticipantId}`
        )
        .join(",")
        .split(",");
      const relatedLines = lines.value.filter(item =>
        relatedLineKeys.includes(item.key)
      );

      for (const item of relatedLines) {
        item.line?.setOptions(
          hilight ? LINE_HOVER_OPTION : LINE_DEFAULT_OPTIONS
        );
      }
    }
  };

  const onScroll = async () => {
    // reposition for roommates lines
    for (const item of lines.value) {
      const participantIds = item.key.split("-");
      const fromId = participantIds[0];
      const toId = participantIds[1];
      const start = $(`#participant${fromId} .achor-point`).get(0);
      const end = $(`#participant${toId} .achor-point`).get(0);
      if (start && end) {
        item.line?.position();
      }
    }
  };

  const showAddNewCabin = async () => {
    popupNewItem.value.formData.controls.name.value = "";
    popupNewItem.value.formData.controls.name.error = "";
    popupNewItem.value.formData.controls.event.value = "";
    popupNewItem.value.formData.controls.event.error = "";
    popupNewItem.value.show = true;

    // get available predefined cabins
    try {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/entities/cabins",
        {},
        {
          available: 1,
          tsStart: pageData.value.tsStart || "",
          tsEnd: pageData.value.tsEnd || ""
        },
        ApiType.CORE
      );
      if (result.status == 1) {
        pageData.value.entityCabins.options = (result.data.cabins || []).map(
          (item: any) => ({
            id: item.cabinId,
            title: item.cabinName,
            selected: false
          })
        );
        const cabinNames = pageData.value.items.map(item =>
          item.name.toLocaleLowerCase()
        );
        pageData.value.entityCabins.options = pageData.value.entityCabins.options.filter(
          (item: any) => {
            // not show cabin has same names with the current list
            if (cabinNames.includes(item.title.toLocaleLowerCase())) {
              return false;
            }
            return true;
          }
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const entityCabinsChange = () => {
    const selected = pageData.value.entityCabins.options.find(
      (item: any) => item.selected
    );
    if (selected) {
      formData.value.controls.name.error = "";
      formData.value.controls.name.value = "";
      formData.value.controls.event.value = "";
      const eventACIRef: any = context.refs.eventACIRef;
      if (eventACIRef.key) {
        eventACIRef.key = "";
      }
    }
  };

  // init data
  (async () => {
    pageData.value.filter.status = ApiHelper.getParticipantStatusOptions();
    loadData(1);

    // participant types
    const types: any = await ApiHelper.loadPTypes(selectedEventId);
    popupDormGenerator.value.controls.participantTypes.options = types;
  })();

  return {
    loadData,
    updateCabinStatus,
    popupNewItem,
    popupDormGenerator,
    dormGeneratorData,
    updateSortValue,
    headerData,
    pageData,
    formData,
    selectAllStatuses,
    resetStatuses,
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    getFiltersData,
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab,
    // allParticipants,
    lines,
    resetLines,
    hilightRelatedLines,
    onScroll,
    showAddNewCabin,
    entityCabinsChange
  };
}
export type PopupDormGeneratorConfirm = {
  show: boolean;
  showConfirmRoommate: boolean;
  data: any[];
  dragElement: any;
  dropList: any[];
  dragDorm: string;
  dropDorm: string;
  maxParticipant: number;
  roommateRequests: [];
  isLoading: boolean;
  inputData?: any;
  participantTypes?: any;
  pGroupList?: any;
};
