import { ApiHelper } from "@/helpers/index";
import { SelectOption } from "@/types";
import Vue from "vue";
import moment from "moment";
import axios from "axios";
import { saveAs } from "file-saver";
import { routerData } from "@/router";
import { v4 as uuidv4 } from "uuid";

export enum ParticipantStatus {
  Applied = 0,
  Accepted = 1,
  Registered = 1,
  Denied = 2,
  CheckedIn = 3,
  Waitlist = 4,
  RemovedFromPortal = 5,
  Incomplete = 6,
  Cancelled = 10
}

export enum TransactionType {
  DebitCredit = 1,
  AccountBucket = 2,
  Cash = 3,
  Check = 4,
  Refund = 5,
  Scholarship = 6,
  Discount = 7,
  Transfer = 8,
  WriteOff = 9
}

export enum TagTypeUse {
  BLANK = "",
  FINANCE = "finance",
  PROFILE = "profile",
  SEARCH = "search"
}

export enum MedicalIncidentType {
  MedicationDispensed = 1,
  MedicalIncident = 2
}

export enum ApiType {
  DEFAULT = "default",
  CORE = "core",
  CAMPAIGN = "campaign"
}

export enum TodoStatus {
  Incomplete = 0,
  Pending = 1,
  Complete = 2
}

export enum Genders {
  Male = 1,
  Female = 2
}

export enum DormGroup {
  ByAge = 1,
  ByState = 2,
  MarginAge = 3
}

// Http endpoint
// const httpEndpoint = process.env.VUE_APP_API_HTTP || "http://localhost:3017";
const httpCoreEndpoint =
  process.env.VUE_APP_COREAPI_HTTP || "http://localhost:3019";
const httpCampaignEndpoint =
  process.env.VUE_APP_CAMPAIGNAPI_HTTP || "http://localhost:3021";

export const getApiUrl = async (forceRun = false) => {
  try {
    const userData = ApiHelper.getLoggedUser();
    const entityId = userData?.defaultEntityID || 0;
    if (entityId == 0) return;

    const key = `adminApiURL_${entityId}`;
    if (!forceRun) {
      const savedURL = sessionStorage.getItem(key);
      if (savedURL) {
        return savedURL;
      }
    }
    const response = await axios.get(
      `${httpCoreEndpoint}/entities/adminEndPoint`,
      {
        params: {
          entityId
        }
      }
    );
    if (response.data.status == 1) {
      const apiURL = response.data.data.apiEndpointUrl || "";
      if (apiURL != "") {
        ApiHelper.saveApiUrl(apiURL, entityId);
        // sessionStorage.setItem(key, apiURL);
      }

      return apiURL;
    }
  } catch (error) {
    // console.log(error);
  }

  return "";
};

export const saveApiUrl = (apiURL: string, entityId: number) => {
  if (apiURL == "") return;
  const key = `adminApiURL_${entityId}`;
  sessionStorage.setItem(key, apiURL);
};

const LOGGED_USER_DATA = "CAMP_LOGGED_USER";
const SWITCH_PROFILE_DATA = "SWITCH_PROFILE_DATA";

export const setDataLoading = (loading: boolean) => {
  Vue.set(routerData.isDataLoading, "value", loading);
  if (!loading) {
    $(".logo.logo-inactive").removeClass("opacity-0");
    setTimeout(() => {
      $(".logo.logo-inactive").addClass("opacity-0");
    }, 1000);
  }
};

export const setDataLastLoadTS = () => {
  Vue.set(routerData.lastCallTS, "value", new Date());
};

export const saveLoggedUser = (info: any) => {
  ApiHelper.setStorageItem(LOGGED_USER_DATA, info);
};

export const saveSwitchProfileData = (info: any) => {
  ApiHelper.setStorageItem(SWITCH_PROFILE_DATA, info);
};

export const getSwitchProfileUser = () => {
  const info = {
    profileId: 0,
    firstName: "",
    lastName: "",
    type: ""
  };

  const value =
    ApiHelper.getStorageItem(SWITCH_PROFILE_DATA, {
      profileId: 0,
      firstName: "",
      lastName: "",
      type: ""
    }) || {};
  info.profileId = value.profileId || 0;
  info.firstName = value.firstName || "";
  info.lastName = value.lastName || "";
  return info;
};

export const getRoleName = (role: string) => {
  let roleName = "";
  switch (role) {
    case "1":
      roleName = "ADMIN";
      break;
    case "2":
      roleName = "REGISTRATION";
      break;
  }
  return roleName;
};
export const getLoggedUser = () => {
  const userInfo = {
    id: "",
    username: "",
    token: "",
    apiToken: "",
    type: "",
    mailUrl: "",
    mailToken: "",
    campaignAPIKey: ""
  };
  const value = ApiHelper.getStorageItem(LOGGED_USER_DATA, userInfo) || {};
  return { ...userInfo, ...value };
};

export const userLogout = () => {
  ApiHelper.setStorageItem(LOGGED_USER_DATA, {
    id: "",
    username: "",
    token: "",
    apiToken: "",
    type: "",
    mailUrl: "",
    mailToken: "",
    campaignAPIKey: ""
  });
};

export const setStorageItem = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getStorageItem = (key: string, defaultValue?: any) => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  } else {
    return defaultValue || null;
  }
};

export const dateToYYYYMMDD = (date: Date) => {
  // alternative implementations in https://stackoverflow.com/q/23593052/1850609
  return (
    date &&
    new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
};

export const convertYYYYMMDDtoDateString = (dateString: string) => {
  if (dateString.length == 8) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return year + "-" + month + "-" + day;
  } else {
    return undefined;
  }
};

export const convertYYYYMMDDtoDDMMYYString = (dateString: string) => {
  if (dateString.length == 8) {
    const year = dateString.substring(2, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return month + "/" + day + "/" + year;
  } else {
    return undefined;
  }
};

export const getFilterTextByValue = (
  options: SelectOption[],
  value: string
) => {
  let selectedValue = "";
  options.forEach(item => {
    if (item.id === value) {
      selectedValue = item.text;
    }
  });
  return selectedValue;
};

export const convertDatetimeToDateID = (date: Date | undefined) => {
  if (date) {
    return ApiHelper.replaceAll(date.toISOString().substr(0, 10), "-", "");
  }
  return "";
};

export const convertDateToDateID = (date: string) => {
  return ApiHelper.replaceAll(date, "-", "");
};

export const replaceAll = (string: string, find: string, replace: string) => {
  return string.replace(new RegExp(find, "g"), replace);
};

export const dollarFormat = (
  value: number,
  useK = false,
  options = { useBrackets: false }
) => {
  const configOptions = options || {};
  const useBrackets = configOptions.useBrackets || false;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  if (!useK) {
    if (useBrackets) {
      return value < 0
        ? `(${formatter.format(0 - value)})`
        : formatter.format(value);
    }
    return formatter.format(value);
  } else {
    let newVal = value;
    if (newVal >= 1000 || newVal <= -1000) {
      newVal = newVal / 1000;
      if (useBrackets) {
        return value < 0
          ? `(${formatter.format(0 - newVal) + "k"})`
          : formatter.format(newVal) + "k";
      }
      return formatter.format(newVal) + "k";
    }
    if (useBrackets) {
      return value < 0
        ? `(${formatter.format(0 - value)})`
        : formatter.format(value);
    }
    return formatter.format(value);
  }
};

export const numberFormat = (value: number, useK = false) => {
  if (!useK) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  } else {
    let newVal = value;
    if (newVal > 999) {
      newVal = newVal / 1000;
      return new Intl.NumberFormat().format(newVal) + "k";
    }
    return new Intl.NumberFormat().format(newVal);
  }
};

export const getAgeFromDob = (dob: Date) => {
  if (dob) {
    const nowDate = new Date();
    const total = nowDate.getTime() - new Date(dob).getTime();
    return (total / (365 * 24 * 60 * 60 * 1000)).toFixed(0);
  } else {
    return 0;
  }
};

export const getGenderName = (gender: number) => {
  let genderName = "";
  switch (gender) {
    case 1:
      genderName = "Male";
      break;
    case 2:
      genderName = "Female";
      break;
    case 3:
      genderName = "Undefined";
      break;
    default:
      genderName = "-";
      break;
  }
  return genderName;
};

export const getSubProfileTypeName = (type: number) => {
  let name = "";
  switch (type) {
    case 1:
      name = "Child";
      break;
    case 2:
      name = "Spouse";
      break;
    case 3:
      name = "Sibling";
      break;
    case 4:
      name = "Friend";
      break;
    case 5:
      name = "Parent/Guardian";
      break;
    default:
      name = "Other";
      break;
  }
  return name;
};

export const getBalanceOptions = () => {
  return [
    {
      id: "0-1000",
      text: "Less than 1,000"
    },
    {
      id: "1000-2000",
      text: "From 1,000 - 2,000"
    },
    {
      id: "2000-",
      text: "Large than 2,000"
    }
  ];
};
export const getGenderOptions = () => {
  return [
    {
      id: "1",
      text: "Male",
      selected: false
    },
    {
      id: "2",
      text: "Female",
      selected: false
    }
    /*
    {
      id: "3",
      text: "Undefined",
      selected: false
    },
    */
  ];
};
export const getParticipantFilterOptions = () => {
  return [
    {
      id: "1",
      text: "Open"
    },
    {
      id: "2",
      text: "Close"
    },
    {
      id: "3",
      text: "Purchase"
    },
    {
      id: "3",
      text: "Requested"
    },
    {
      id: "4",
      text: "Assigned"
    },
    {
      id: "5",
      text: "Purchase Pending"
    }
  ];
};
export const getFromAgeOptions = () => {
  return [
    {
      id: "0",
      text: "-"
    },
    {
      id: "10",
      text: "10"
    },
    {
      id: "20",
      text: "20"
    },
    {
      id: "30",
      text: "30"
    },
    {
      id: "40",
      text: "40"
    },
    {
      id: "50",
      text: "50"
    }
  ];
};
export const getToAgeOptions = () => {
  return [
    {
      id: "200",
      text: "-"
    },
    {
      id: "20",
      text: "20"
    },
    {
      id: "30",
      text: "30"
    },
    {
      id: "40",
      text: "40"
    },
    {
      id: "50",
      text: "50"
    },
    {
      id: "60",
      text: "60"
    },
    {
      id: "70",
      text: "70"
    }
  ];
};

export const getTagsOptions = async (options: any = {}) => {
  const requestObject: any = {};
  if (options.take != undefined) {
    requestObject.take = options.take;
  }
  if (options.key != undefined) {
    requestObject.key = options.key;
  }
  if (options.getAll != undefined) {
    requestObject.getAll = options.getAll;
  }
  if (options.type != undefined) {
    requestObject.typeUse = options.type;
  }
  const result = await ApiHelper.callApi(
    "get",
    "/tags/search",
    {},
    requestObject
  );
  if (result.status === 1) {
    const options: SelectOption[] = result.data.tags.map((item: any) => {
      return {
        id: item.id || "",
        text: item.text || ""
      };
    });
    return options;
  }
  return [];
};

export const getEntityApplicationOptions = async () => {
  const result = await ApiHelper.callApi("get", "/applications/search", {}, {});
  let options: SelectOption[] = [];
  if (result.status === 1) {
    const applications = result.data.applications || [];
    options = applications.map((item: any) => {
      return {
        id: item.id || "",
        text: item.name || ""
      };
    });
  }
  return options;
};

export const getProfileFamilyOptions = async (options: any = {}) => {
  const requestObject: any = {};
  if (options.take != undefined) {
    requestObject.take = options.take;
  }
  if (options.familyName != undefined) {
    requestObject.familyName = options.familyName;
  }
  if (options.getAll != undefined) {
    requestObject.getAll = options.getAll;
  }
  const result = await ApiHelper.callApi(
    "get",
    "/profiles/families",
    {},
    requestObject
  );
  if (result.status === 1) {
    const options: SelectOption[] = result.data.families.map((item: any) => {
      return {
        id: item.familyId || "",
        text: item.familyName || ""
      };
    });
    return options;
  }

  return [];
};

export const getTagTypeOptions = async (options: any = {}) => {
  const requestObject: any = {};
  if (options.take != undefined) {
    requestObject.take = options.take;
  }
  if (options.key != undefined) {
    requestObject.key = options.key;
  }
  if (options.getAll != undefined) {
    requestObject.getAll = options.getAll;
  }
  const result = await ApiHelper.callApi(
    "get",
    "/tags/types",
    {},
    requestObject
  );
  if (result.status === 1) {
    const options: SelectOption[] = [];
    result.data.types.map((item: any) => {
      options.push({
        id: item.typeId || "",
        text: item.typeName || ""
      });
    });
    return options;
  }

  return [];
};

export const getContentTypeOptions = async (options: any = {}) => {
  const requestObject: any = {};
  if (options.take != undefined) {
    requestObject.take = options.take;
  }
  if (options.key != undefined) {
    requestObject.key = options.key;
  }
  if (options.getAll != undefined) {
    requestObject.getAll = options.getAll;
  }
  const result = await ApiHelper.callApi(
    "get",
    "/tags/contenttypes",
    {},
    requestObject
  );
  if (result.status === 1) {
    const options: SelectOption[] = [];
    result.data.items.map((item: any) => {
      options.push({
        id: item.contentTypeId || "",
        text: item.contentTypeName || ""
      });
    });
    return options;
  }

  return [];
};

export const getTagOptions = async (options: any = {}) => {
  const requestObject: any = {};
  if (options.take != undefined) {
    requestObject.take = options.take;
  }
  if (options.key != undefined) {
    requestObject.key = options.key;
  }
  if (options.getAll != undefined) {
    requestObject.getAll = options.getAll;
  }
  const result = await ApiHelper.callApi("get", "/tags", {}, requestObject);
  if (result.status === 1) {
    const options: SelectOption[] = [];
    result.data.tags.map((item: any) => {
      options.push({
        id: item.tagId || "",
        text: item.tagName || ""
      });
    });
    return options;
  }

  return [];
};

export const getEventOptions = async (options: any = {}) => {
  const requestObject: any = {};
  if (options.take != undefined) {
    requestObject.take = options.take;
  }
  if (options.key != undefined) {
    requestObject.key = options.key;
  }
  if (options.getAll != undefined) {
    requestObject.getAll = options.getAll;
  }
  if (options.profileId != undefined) {
    requestObject.profileId = options.profileId;
  }
  if (options.familyId != undefined) {
    requestObject.familyId = options.familyId;
  }
  const result = await ApiHelper.callApi(
    "get",
    "/events/search",
    {},
    requestObject
  );
  if (result.status === 1) {
    const options: SelectOption[] = [];
    result.data.events.map((item: any) => {
      options.push({
        id: item.id || "",
        text: item.name || ""
      });
    });
    return options;
  }

  return [];
};

export const getPtypeOptions = async (options: any = {}) => {
  const requestObject: any = {};
  if (options.take != undefined) {
    requestObject.take = options.take;
  }
  if (options.key != undefined) {
    requestObject.key = options.key;
  }
  if (options.getAll != undefined) {
    requestObject.getAll = options.getAll;
  }

  const result = await ApiHelper.callApi(
    "get",
    "/participants/ptypes",
    {},
    requestObject
  );
  if (result.status === 1) {
    const options: SelectOption[] = [];
    result.data.ptypes.map((item: any) => {
      options.push({
        id: item.id || "",
        text: item.name || "",
        selected: false
      });
    });
    return options;
  }

  return [];
};

export const getProfileOptions = async (options: any = {}) => {
  const requestObject: any = {};
  if (options.take != undefined) {
    requestObject.take = options.take;
  }
  if (options.key != undefined) {
    requestObject.key = options.key;
  }
  if (options.inIds != undefined) {
    requestObject.inIds = options.inIds;
  }
  if (options.getAll != undefined) {
    requestObject.getAll = options.getAll;
  }
  if (options.includeDenied != undefined) {
    requestObject.includeDenied = options.includeDenied;
  }
  if (options.eventIdFilter != undefined) {
    requestObject.eventIdFilter = options.eventIdFilter;
  }
  const result = await ApiHelper.callApi(
    "get",
    "/profiles/search",
    {},
    requestObject
  );
  if (result.status === 1) {
    const options: SelectOption[] = [];
    result.data.profiles.map((item: any) => {
      options.push({
        id: item.id || "",
        text: ApiHelper.getFullName(item.firstName || "", item.lastName || "")
      });
    });
    return options;
  }

  return [];
};

export const getTransactionTypeOptions = async (options: any = {}) => {
  const requestObject: any = {};
  if (options.take != undefined) {
    requestObject.take = options.take;
  }
  if (options.key != undefined) {
    requestObject.key = options.key;
  }
  const result = await ApiHelper.callApi(
    "get",
    "/transactions/types",
    {},
    requestObject
  );
  if (result.status === 1) {
    const options: SelectOption[] = [];
    result.data.types.map((item: any) => {
      options.push({
        id: item.transactionTypeId || "",
        text: item.transactionTypeName || ""
      });
    });
    return options;
  }

  return [];
};

export const getAgeByDOB = (dob: string) => {
  if (dob != "") {
    const dobDate = new Date(dob);
    const today = new Date();
    const age = today.getUTCFullYear() - dobDate.getUTCFullYear();
    return age > 0 ? age : "";
  }

  return "";
};

export const getParticipantStatusText = (status: number) => {
  switch (status) {
    case -2:
      return "Updated";
    case -1:
      return "Created";
    // case 0:
    //   return "Applied";
    case 1:
      return "Registered";
    case 2:
      return "Denied";
    case 3:
      return "Checked In";
    case 4:
      return "Waitlist";
    case 5:
      // Removed participant from portal
      return "Removed";
    case 6:
      return "Incomplete";
    case 7:
      return "Started";
    case 8:
      return "Refunded";
    case 9:
      return "Transfered";
    case 10:
      return "Cancelled";
    default:
      return "";
  }
};

export const getEventStatusText = (status: number) => {
  switch (status) {
    case 0:
      return "Inactive";
    case 1:
      return "Active";
    case 2:
      return "Archive";
  }
};

export const getParticipantStatusOptions = (getOptions: any = {}) => {
  const options: SelectOption[] = [
    {
      id: "1",
      text: "Registered",
      selected: false
    },
    {
      id: "3",
      text: "Checked In",
      selected: false
    },
    {
      id: "4",
      text: "Waitlist",
      selected: false
    },
    {
      id: "6",
      text: "Incomplete",
      selected: false
    }
  ];
  const getDeniedStatus = getOptions.getDeniedStatus || false;
  if (getDeniedStatus) {
    options.push({
      id: "10",
      text: "Cancelled",
      selected: false
    });
    options.push({
      id: "2",
      text: "Denied",
      selected: false
    });
  }
  const getStartedStatus = getOptions.getStartedStatus || false;
  if (getStartedStatus) {
    options.push({
      id: "7",
      text: "Started",
      selected: false
    });
  }
  const getRefundStatus = getOptions.getRefundStatus || false;
  if (getRefundStatus) {
    options.push({
      id: "8",
      text: "Refunded",
      selected: false
    });
  }
  const getCreatedStatus = getOptions.getCreatedStatus || false;
  if (getCreatedStatus) {
    options.push({
      id: "-1",
      text: "Created",
      selected: false
    });
  }
  const getUpdatedStatus = getOptions.getUpdatedStatus || false;
  if (getUpdatedStatus) {
    options.push({
      id: "-2",
      text: "Updated",
      selected: false
    });
  }
  const getDeletedStatus = getOptions.getDeletedStatus || false;
  if (getDeletedStatus) {
    options.push({
      id: "-3",
      text: "Deleted",
      selected: false
    });
  }
  const getTransferedStatus = getOptions.getTransferedStatus || false;
  if (getTransferedStatus) {
    options.push({
      id: "9",
      text: "Transfered",
      selected: false
    });
  }
  return options;
};

export const getCabinStatusOptions = () => {
  const options: SelectOption[] = [
    {
      id: "0",
      text: "Pending",
      selected: false
    },
    {
      id: "1",
      text: "Approved",
      selected: false
    },
    {
      id: "2",
      text: "Denied",
      selected: false
    }
  ];
  return options;
};

export const getActionTypeOptions = () => {
  const options: SelectOption[] = [
    {
      id: "admin",
      text: "Admin"
    },
    {
      id: "creation",
      text: "Creation"
    },
    {
      id: "discount",
      text: "Discount"
    },
    {
      id: "forms",
      text: "Forms"
    },
    {
      id: "payment",
      text: "Payment"
    },
    {
      id: "Remove",
      text: "Remove"
    }
  ];
  return options;
};

export const convertSelectedOptionsToString = (options: SelectOption[]) => {
  const value = options
    .filter((item: any) => item.selected)
    .map((item: any) => item.id)
    .join(",");
  return value;
};

export const showErrorMessage = (
  message: string,
  title = "",
  autoClose = true,
  actions: any = []
) => {
  // Vue.swal(title || "Error", message, "error");

  const data: any = routerData.globalMessage;
  if (data.timer) {
    clearTimeout(data.timer);
  }
  Vue.set(routerData.globalMessage, "value", message);
  Vue.set(routerData.globalMessage, "actions", actions);
  $(".global-message")
    .removeClass("success-message error-message")
    .addClass("error-message")
    .animate(
      {
        height: "113px"
      },
      100,
      () => {
        $(".global-message").addClass("error-message");
        if (autoClose) {
          // auto close after 5 seconds
          const timer = setTimeout(() => {
            $(".global-message").animate({ height: "0" }, 100, () => {
              Vue.set(routerData.globalMessage, "value", "");
              Vue.set(routerData.globalMessage, "actions", []);
            });
          }, 5000);
          Vue.set(routerData.globalMessage, "timer", timer);
        }
      }
    );
};

export const showSuccessMessage = (
  message: string,
  title = "",
  autoClose = true,
  actions: any = []
) => {
  // Vue.swal(title || "Success", message, "success");

  const data: any = routerData.globalMessage;
  if (data.timer) {
    clearTimeout(data.timer);
  }
  Vue.set(routerData.globalMessage, "value", message);
  Vue.set(routerData.globalMessage, "actions", actions);
  $(".global-message")
    .removeClass("success-message error-message")
    .addClass("success-message")
    .animate(
      {
        height: "113px"
      },
      100,
      () => {
        $(".global-message").addClass("success-message");
        if (autoClose) {
          // auto close after 5 seconds
          const timer = setTimeout(() => {
            $(".global-message").animate({ height: "0" }, 100, () => {
              Vue.set(routerData.globalMessage, "value", "");
              Vue.set(routerData.globalMessage, "actions", []);
            });
          }, 2000);
          Vue.set(routerData.globalMessage, "timer", timer);
        }
      }
    );
};

export const getCabinsOptions = async (eventId?: number, options: any = {}) => {
  const requestObject: any = {};
  if (eventId) {
    requestObject.eventId = eventId;
  }
  if (options.take != undefined) {
    requestObject.take = options.take;
  }
  if (options.key != undefined) {
    requestObject.name = options.key;
  }
  if (options.getAll != undefined) {
    requestObject.getAll = options.getAll;
  }
  const result = await ApiHelper.callApi("get", "/cabins", {}, requestObject);

  const cabins = [];
  let includeUnassignedOption = true;
  if (options.includeUnassignedOption != undefined) {
    includeUnassignedOption = options.includeUnassignedOption;
  }
  if (includeUnassignedOption) {
    cabins.push({
      id: 0,
      text: "Un-assigned to cabins",
      selected: false
    });
  }
  if (result.status === 1) {
    result.data.cabins.map((item: any) => {
      cabins.push({
        id: item.id || "",
        text: item.name || "",
        selected: false,
        data: item
      });
    });
  }

  return cabins;
};

export const loadPTypes = async (eventId: number) => {
  const result = await ApiHelper.callApi(
    "get",
    `/participants/ptypes/event/${eventId}`
  );
  const types: { id: any; text: any }[] = [];
  result.map((item: any) => {
    types.push({ id: item.id, text: item.name });
  });
  return types;
};

export const getPercent = (numberVal: number, totalVal: number) => {
  const percent =
    totalVal != 0 ? parseInt(((numberVal * 100) / totalVal).toFixed(0)) : 0;
  return percent > 100 ? 100 : percent;
};

export const getFullName = (firstName: string, lastName: string) => {
  const ret = [];
  if (firstName) {
    ret.push(firstName);
  }
  if (lastName) {
    ret.push(lastName);
  }

  return ret.join(" ");
};

export const getSimpleName = (fullName: string): string => {
  const name = fullName.split(" ");
  let simpleName = "";
  if (name && name.length > 1) {
    simpleName = name[0].charAt(0) + name[1].charAt(0);
  } else {
    simpleName = name[0].charAt(0);
  }
  return simpleName.toUpperCase();
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getFamilyTypeOptions = async () => {
  const options: SelectOption[] = [];
  options.push({
    id: "1",
    text: getSubProfileTypeName(1)
  });
  options.push({
    id: "2",
    text: getSubProfileTypeName(2)
  });
  options.push({
    id: "3",
    text: getSubProfileTypeName(3)
  });
  options.push({
    id: "4",
    text: getSubProfileTypeName(4)
  });
  options.push({
    id: "5",
    text: getSubProfileTypeName(5)
  });
  options.push({
    id: "6",
    text: getSubProfileTypeName(6)
  });
  return options;
};

export const getErrorMessage = (err: any) => {
  let errors = [];
  let errorMessage = "";
  if (err.networkError || null) {
    const networkErrorData = err.networkError.result || {};
    errors = networkErrorData.errors || [{}];
    errorMessage = errors[0].message || JSON.stringify(errors[0]);
  } else {
    errorMessage = JSON.stringify(err);
  }
  return errorMessage;
};

export const handleApiResponse = async (promise: any) => {
  try {
    return await promise.then((res: any) => {
      if (res.data) {
        return {
          status: 1,
          message: "Success",
          data: res.data
        };
      } else {
        const errors = res.errors || [];
        console.log("errors", errors);
        let errorMessage = "";
        if (errors && errors.length > 0) {
          errorMessage = errors[0].message || "";
        } else {
          errorMessage = JSON.stringify(errors);
        }
        return {
          status: 0,
          message: errorMessage || JSON.stringify(res),
          data: {}
        };
      }
    });
  } catch (error) {
    const networkError = error.networkError || {};
    const errorResult = networkError.result || {};
    const graphQLErrors = errorResult.errors || error.graphQLErrors || [];
    const errors = graphQLErrors;
    const message =
      errors.length > 0 ? errors[0].message : JSON.stringify(error);
    let errorMessage = "";
    if (errors && errors.length > 0) {
      errorMessage = errors[0].message || "";
    } else {
      errorMessage = JSON.stringify(errors);
    }

    return {
      status:
        message === `Access denied! You don't have permission for this action!`
          ? -1
          : 0,
      message: message,
      data: {}
    };
  }
};

export const gotoPage = (context: any, route: any) => {
  context.root.$router.push(route).catch((error: any) => {
    // console.log("gotoPage: error", error);
  });
};

export const replaceRouter = (context: any, route: any) => {
  context.root.$router.replace(route).catch((error: any) => {
    // console.log("gotoPage: error", error);
  });
};

export const formatLocalDate = (date: string) => {
  return moment(
    date.replace("T00:00:00.000Z", "T00:00:00.000" + moment().format("Z"))
  ).toDate();
};

export const countOccurrences = (array: any[], value: any): number => {
  return array.reduce((a, v) => (v === value ? a + 1 : a), 0);
};

export const getPortalUrl = (): string => {
  return process.env.CAMP360_PORTAL_URL || "https://camp360.dev-us.com/newapi";
};

export const validateHexColor = (str: string): boolean => {
  const pattern = new RegExp("^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$");
  return pattern.test(str);
};

export const callMailApi = async (endpoint: string, data: any) => {
  const userData = ApiHelper.getLoggedUser();
  const result = axios({
    method: "post",
    url: userData.mailUrl + endpoint,
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: userData.mailToken
    }
  })
    .then((response: any) => {
      return {
        status: 1,
        message: "Success",
        data: response.data
      };
    })
    .catch(error => {
      console.log("error");
      return {
        status: 0,
        message: JSON.stringify(error),
        data: error
      };
    });

  return result;
};

export const callApi = async (
  method: "post" | "get" | "put" | "delete" | "patch",
  endpoint: string,
  data: any = {},
  params?: any,
  mode?: string,
  load?: boolean
) => {
  const userData = ApiHelper.getLoggedUser();
  let apiRoute = "";

  //TODO: define array of coreapi endpoint to call
  const coreFunctions = "/auth/login";
  if (coreFunctions.indexOf(endpoint) > -1 || mode === ApiType.CORE) {
    apiRoute = httpCoreEndpoint + endpoint;
  } else if (mode === ApiType.CAMPAIGN) {
    apiRoute = httpCampaignEndpoint + endpoint;
  } else {
    const httpEndpoint = await getApiUrl();
    apiRoute = httpEndpoint + endpoint;
  }
  if (apiRoute == "") return;

  if (load) {
    ApiHelper.setDataLoading(true);
  }
  try {
    const result = await axios({
      method: method,
      url: apiRoute,
      data: JSON.stringify(data),
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: userData.token ? "Bearer " + userData.token : undefined,
        "x-api-key":
          mode === ApiType.CAMPAIGN ? userData.campaignAPIKey : undefined
      }
    });

    if (!routerData.lastCallTS) {
      ApiHelper.setDataLastLoadTS();
    }

    return result.data;
  } catch (error) {
    // stop loader function
    ApiHelper.setDataLoading(false);
    const errorResponse = error.response || {};
    const errorData = errorResponse.data || {};
    const errorMessage =
      errorData.message || error.message || JSON.stringify(error);
    return {
      status: errorResponse.status || 0,
      message:
        errorMessage ||
        errorResponse.statusText ||
        "Something was wrong. Please try it later.",
      data: error
    };
  }
};

export const randomFormId = () => {
  const randomId = Math.floor(Math.random() * 10000000);
  return randomId;
};

export const randomNumber = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const convertObjectToArray = (value: any) => {
  const list = Object.keys(value).map(function(personNamedIndex) {
    const item = value[personNamedIndex];
    return item;
  });
  return list;
};

export const uploadProfileFile = async (
  profileId: number,
  fieldName: string,
  file: File
) => {
  const base64 = await ApiHelper.convertFileToBase64(file);
  const result = await ApiHelper.callApi(
    "post",
    "/profiles/" + profileId + "/files",
    {
      base64: base64,
      fileName: file.name
    },
    {}
  );
  return result;
};

export const convertFileToBase64 = async (file: File) => {
  const toBase64 = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  const base64: string = await toBase64
    .then(res => {
      return res + "";
    })
    .catch(error => {
      return "";
    });
  const fileInfo: any = file;
  if (!fileInfo.type) {
    const extension = fileInfo.name.split(".").pop();
    if (extension === "docx") {
      return base64.replace(
        "data:application/octet-stream;",
        "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;"
      );
    }
    if (extension === "doc") {
      return base64.replace(
        "data:application/octet-stream;",
        "data:application/msword;"
      );
    }
  }
  return base64;
};

export const stripTags = (value: string) => {
  if (!value) {
    return value;
  }
  const strippedString = value.replace(/(<([^>]+)>)/gi, "");
  return strippedString;
};

export const convertIdsToArray = (value: string) => {
  const ids = value
    ? value
        .split(",")
        .map(val => {
          return parseInt(val) || 0;
        })
        .filter(val => {
          return val;
        })
    : [];
  return ids;
};

export const getDisplayDate = (date: Date | undefined) => {
  return date ? moment(date).format("MM/DD/YY") : "-";
};

export const getDisplayDatetime = (date: Date | undefined) => {
  return date ? moment(date).format("MM/DD/YY hh:mmA") : "-";
};

export const getRoommateStatusText = (status: number) => {
  switch (status) {
    case 0:
      return "Pending";
    case 1:
      return "Accepted";
    case 2:
      return "Rejected";
    default:
      return "";
  }
};

export const isDomain = (domain: string) => {
  if (/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(domain)) {
    return true;
  }
  return false;
};

export const htmlParse = (InputVal: any) => {
  const parsedHTML: any = $.parseHTML(InputVal);
  let returnedContent = "";
  if (parsedHTML !== null && parsedHTML.length > 0) {
    const firstHtml: any = parsedHTML[0];
    if (
      firstHtml &&
      firstHtml.hasOwnProperty &&
      firstHtml.textContent === null
    ) {
      returnedContent = "";
    } else {
      returnedContent = firstHtml.textContent;
    }
    return returnedContent;
  } else {
    return "";
  }
};

export const htmlCheck = (InputVal: any) => {
  if (InputVal.length > ApiHelper.htmlParse(InputVal).length) {
    return true;
  } else {
    return false;
  }
};

export const getPasswordInfo = (password: string) => {
  const data = {
    hasCapital: false,
    has8characters: false,
    hasNumber: false,
    nonHtml: false,
    valid: false
  };
  data.hasCapital = !!password.match(/[A-Z]/);
  data.hasNumber = !!password.match(/\d/);
  data.has8characters = password.length >= 8;
  data.nonHtml = !ApiHelper.htmlCheck(password);
  data.valid =
    data.hasCapital && data.hasNumber && data.has8characters && data.nonHtml;
  return data;
};

export const getAllowedFunctions = async (forceRun = false) => {
  const userData = ApiHelper.getLoggedUser();
  const entityId = userData?.defaultEntityID || 0;
  if (entityId == 0) return [];

  if (
    userData.allowedFunctionIds &&
    userData.allowedFunctionIds.length > 0 &&
    !forceRun
  ) {
    return userData.allowedFunctionIds;
  }

  const key = `allowedFunctions_${entityId}_${userData.id}`;
  const savedData = sessionStorage.getItem(key);
  if (!forceRun && savedData) {
    return JSON.parse(savedData);
  }
  let allowFunctions: number[] = [];
  try {
    const result = await ApiHelper.callApi(
      "get",
      `/users/${userData.id}/allowedFunctions`,
      {},
      {},
      "core"
    );
    if (result.status == 1) {
      allowFunctions = result.data.allowedFunctions || [];
      // update refresh token
      const newToken = result.data.newToken || "";
      if (newToken) {
        const userData = ApiHelper.getLoggedUser();
        userData.token = newToken;
        userData.allowedFunctionIds = allowFunctions;
        ApiHelper.setStorageItem(LOGGED_USER_DATA, userData);
      }
      sessionStorage.setItem(key, JSON.stringify(allowFunctions));
    }
  } catch (error) {
    // console.log(error);
  }

  return allowFunctions;
};

export const removeHtml = (value: string) => {
  if (!value) {
    return value;
  }
  const strippedString = value
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/\r\n|\r|\n/g, "<br/>");
  return strippedString;
};

export const generateCsv = async (
  data: any,
  headers: any,
  fileName: string,
  stringify = true
) => {
  const replacer = (key: any, value: any) => (value === null ? "N/A" : value);
  const header = Object.keys(headers);
  const csv = data.map((row: any) =>
    header
      .map(fieldName => {
        if (!stringify) {
          return replacer(fieldName, row[fieldName]);
        }

        return JSON.stringify(row[fieldName], replacer);
      })
      .join(",")
  );

  csv.unshift(headers);
  const BOM = "\uFEFF";
  const csvArray = BOM + csv.join("\r\n");
  const blob = new Blob([csvArray], { type: "text/csv;charset=utf-8" });
  saveAs(blob, `${fileName}.csv`);
};

export const uploadFile = async (
  fieldName: string,
  file: File,
  profileId = 0
) => {
  const base64 = await ApiHelper.convertFileToBase64(file);
  const result = await ApiHelper.callApi("post", "/s3files/upload", {
    base64: base64,
    group: "uploads",
    id: profileId,
    fieldName: fieldName,
    fileName: file.name
  });
  if (result.status == 1) {
    const { url } = result.data;
    return {
      status: 1,
      message: "Success",
      data: { fileUrl: url }
    };
  } else {
    return {
      status: 0,
      message: result.message || "Cant upload"
    };
  }
};

export const uploadFileFromBase64 = async (
  base64: string,
  group: string,
  profileId = 0
) => {
  const result = await ApiHelper.callApi("post", "/s3files/upload", {
    base64: base64,
    group: group,
    id: profileId,
    fieldName:
      ApiHelper.randomNumber(100000, 1000000) + "-" + new Date().getTime(),
    fileName:
      ApiHelper.randomNumber(100000, 1000000) + "-" + new Date().getTime()
  });
  if (result.status == 1) {
    const { url } = result.data;
    return {
      status: 1,
      message: "Success",
      data: { fileUrl: url }
    };
  } else {
    return {
      status: 0,
      message: result.message || "Cant upload",
      data: { fileUrl: "" }
    };
  }
};

export const getFileExt = (file: string) => {
  const re: any = /(?:\.([^.]+))?$/;
  const ext: any = re.exec(file)[1];
  return ext || "";
};

export const getFileName = (fullPath: string) => {
  const filename = fullPath.replace(/^.*[\\/]/, "");
  return filename;
};

export const routerReplace = (
  context: any,
  name: string,
  query?: {},
  params?: {}
) => {
  context.root.$router
    .replace({
      name: name,
      params: {},
      query: query
    })
    .catch((err: any) => {
      console.log("update routers error");
    });
};

export const getStatusText = (status: number) => {
  switch (status) {
    case 1:
      return "Active";
    case 2:
      return "Deleted";
    case 0:
      return "Archived";
    default:
      return "Unknown";
  }
};

export const getRange = (from: string, to: string) => {
  const ret = [];
  if (from) {
    ret.push(from);
  }
  if (to) {
    ret.push(to);
  }
  return ret.join(" - ");
};

export const getMedicationRouteOptions = () => {
  return [
    {
      id: "1",
      text: "Mouth (PO)"
    },
    {
      id: "2",
      text: "Per rectum (PR)"
    },
    {
      id: "3",
      text: "Sublingually (SL)"
    },
    {
      id: "4",
      text: "Intramuscularly (IM)"
    },
    {
      id: "5",
      text: "Intravenously (IV)"
    },
    {
      id: "6",
      text: "Subcutaneously (SQ)"
    }
  ];
};

export const getFrequencyOptions = () => {
  return [
    {
      id: "1",
      text: "Before meals"
    },
    {
      id: "2",
      text: "After meals"
    },
    {
      id: "3",
      text: "As needed; freely"
    },
    {
      id: "4",
      text: "When necessary"
    },
    {
      id: "5",
      text: "Immediately; at once"
    },
    {
      id: "6",
      text: "Twice a day"
    },
    {
      id: "7",
      text: "Three times a day"
    },
    {
      id: "8",
      text: "Four times a day"
    },
    {
      id: "9",
      text: "Minute"
    },
    {
      id: "10",
      text: "Hour"
    },
    {
      id: "11",
      text: "Every hour"
    },
    {
      id: "12",
      text: "Every 2 hours"
    },
    {
      id: "13",
      text: "Every 3 hours"
    },
    {
      id: "14",
      text: "Every 4 hours"
    },
    {
      id: "15",
      text: "Every 6 hours"
    },
    {
      id: "16",
      text: "Every 8 hours"
    },
    {
      id: "17",
      text: "Every 12 hours"
    }
  ];
};

export const getIncidentTypeOptions = () => {
  return [
    {
      id: "1",
      text: "Medication Dispensed"
    },
    {
      id: "2",
      text: "Medical Incident"
    }
  ];
};

export const getMedicationFrequencyOptions = () => {
  return [
    {
      id: "Before meals",
      text: "Before meals"
    },
    {
      id: "After meals",
      text: "After meals"
    },
    {
      id: "As needed; freely",
      text: "As needed; freely"
    },
    {
      id: "When necessary",
      text: "When necessary"
    },
    {
      id: "Immediately; at once",
      text: "Immediately; at once"
    },
    {
      id: "Twice a day",
      text: "Twice a day"
    },
    {
      id: "Three times a day",
      text: "Three times a day"
    },
    {
      id: "Four times a day",
      text: "Four times a day"
    },
    {
      id: "Minute",
      text: "Minute"
    },
    {
      id: "Hour",
      text: "Hour"
    },
    {
      id: "Every hour",
      text: "Every hour"
    },
    {
      id: "Every 2 hours",
      text: "Every 2 hours"
    },
    {
      id: "Every 3 hours",
      text: "Every 3 hours"
    },
    {
      id: "Every 4 hours",
      text: "Every 4 hours"
    },
    {
      id: "Every 6 hours",
      text: "Every 6 hours"
    },
    {
      id: "Every 8 hours",
      text: "Every 8 hours"
    },
    {
      id: "Every 12 hours",
      text: "Every 12 hours"
    }
  ];
};

export const getMedicationDosageOptions = () => {
  return [
    {
      id: "Mouth (PO)",
      text: "Mouth (PO)"
    },
    {
      id: "Per rectum (PR)",
      text: "Per rectum (PR)"
    },
    {
      id: "Sublingually (SL)",
      text: "Sublingually (SL)"
    },
    {
      id: "Intramuscularly (IM)",
      text: "Intramuscularly (IM)"
    },
    {
      id: "Intravenously (IV)",
      text: "Intravenously (IV)"
    },
    {
      id: "Subcutaneously (SQ)",
      text: "Subcutaneously (SQ)"
    }
  ];
};

export const getMedicationDispenseMethods = async () => {
  let options: SelectOption[] = [];
  const result = await ApiHelper.callApi(
    "get",
    "/medicals/dispenseMethods",
    {},
    {}
  );
  if (result.status === 1) {
    options = result.data.items.map((item: any) => {
      return {
        id: item.id || "",
        text: item.dispenseName || ""
      };
    });
  }

  return options;
};

export const getDispenseMethods = () => {
  // use for form builder
  return [
    {
      id: 1,
      text: "Capsule(s)"
    },
    {
      id: 2,
      text: "Chewable(s)"
    },
    {
      id: 3,
      text: "Cream/ointment"
    },
    {
      id: 4,
      text: "Drop(s)"
    },
    {
      id: 5,
      text: "Patch(s)"
    },
    {
      id: 6,
      text: "Puff(s)"
    },
    {
      id: 7,
      text: "Shot(s)"
    },
    {
      id: 8,
      text: "Spray(s)"
    },
    {
      id: 9,
      text: "Tablet(s)"
    },
    {
      id: 10,
      text: "Teaspoon(s)"
    },
    {
      id: 11,
      text: "Other"
    }
  ];
};

export const getMedicationDosages = () => {
  const options: {
    title: string;
    value: number;
    disabled?: boolean;
  }[] = [
    {
      title: "Breakfast",
      value: 1,
      disabled: false
    },
    {
      title: "Lunch",
      value: 2,
      disabled: false
    },
    {
      title: "Dinner",
      value: 3,
      disabled: false
    },
    {
      title: "Bedtime",
      value: 4,
      disabled: false
    },
    {
      title: "As Needed",
      value: 5,
      disabled: false
    },
    {
      title: "Other",
      value: 6,
      disabled: false
    }
  ];

  return options;
};

export const getSymptomTexts = (symptomsList: any) => {
  if ((symptomsList || []).length == 0) return "";
  const ret = symptomsList.map((t: any) => t.symptomName).join(", ");
  return ret;
};

export const getSymptomOptions = async (options: any = {}) => {
  const requestObject: any = {};
  if (options.take != undefined) {
    requestObject.take = options.take;
  }
  if (options.symptomName != undefined) {
    requestObject.symptomName = options.symptomName;
  }
  if (options.getAll != undefined) {
    requestObject.getAll = options.getAll;
  }
  const result = await ApiHelper.callApi(
    "get",
    "/medicals/symptoms",
    {},
    requestObject
  );
  if (result.status === 1) {
    const options: SelectOption[] = result.data.items.map((item: any) => {
      return {
        id: item.symptomId || "",
        text: item.symptomName || ""
      };
    });
    return options;
  }

  return [];
};

export const getMedicationStatusText = (status: number): string => {
  switch (status) {
    case 0:
      return "Entered";
    case 1:
      return "Verified";
    case 2:
      return "Expired";
  }
  return "";
};

export const getCabinStatusText = (status: number) => {
  switch (status) {
    case 0:
      return "Pending";
    case 1:
      return "Approved";
    case 2:
      return "Denied";
    default:
      return "";
  }
};

export const sleep = (ms: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

export const multiSText = (length: number) => {
  if (length > 1) {
    return "s";
  }
  return "";
};

export const getStateOptions = () => {
  return [
    { id: "AL", text: "Alabama" },
    { id: "AK", text: "Alaska" },
    { id: "AZ", text: "Arizona" },
    { id: "AR", text: "Arkansas" },
    { id: "CA", text: "California" },
    { id: "CO", text: "Colorado" },
    { id: "CT", text: "Connecticut" },
    { id: "DE", text: "Delaware" },
    { id: "DC", text: "District Of Columbia" },
    { id: "FL", text: "Florida" },
    { id: "GA", text: "Georgia" },
    { id: "HI", text: "Hawaii" },
    { id: "ID", text: "Idaho" },
    { id: "IL", text: "Illinois" },
    { id: "IN", text: "Indiana" },
    { id: "IA", text: "Iowa" },
    { id: "KS", text: "Kansas" },
    { id: "KY", text: "Kentucky" },
    { id: "LA", text: "Louisiana" },
    { id: "ME", text: "Maine" },
    { id: "MD", text: "Maryland" },
    { id: "MA", text: "Massachusetts" },
    { id: "MI", text: "Michigan" },
    { id: "MN", text: "Minnesota" },
    { id: "MS", text: "Mississippi" },
    { id: "MO", text: "Missouri" },
    { id: "MT", text: "Montana" },
    { id: "NE", text: "Nebraska" },
    { id: "NV", text: "Nevada" },
    { id: "NH", text: "New Hampshire" },
    { id: "NJ", text: "New Jersey" },
    { id: "NM", text: "New Mexico" },
    { id: "NY", text: "New York" },
    { id: "NC", text: "North Carolina" },
    { id: "ND", text: "North Dakota" },
    { id: "OH", text: "Ohio" },
    { id: "OK", text: "Oklahoma" },
    { id: "OR", text: "Oregon" },
    { id: "PA", text: "Pennsylvania" },
    { id: "RI", text: "Rhode Island" },
    { id: "SC", text: "South Carolina" },
    { id: "SD", text: "South Dakota" },
    { id: "TN", text: "Tennessee" },
    { id: "TX", text: "Texas" },
    { id: "UT", text: "Utah" },
    { id: "VT", text: "Vermont" },
    { id: "VA", text: "Virginia" },
    { id: "WA", text: "Washington" },
    { id: "WV", text: "West Virginia" },
    { id: "WI", text: "Wisconsin" },
    { id: "WY", text: "Wyoming" }
  ].map(option => {
    option.text = option.id;
    return option;
  });
};

export const getTransactionDescription = (desc: string) => {
  const cancelArr = [];
  const paymentArr = [];
  const otherArr = [];
  const arr = desc.split(", ");
  const finalArr = [];
  for (const item of arr) {
    if (item.startsWith("Cancelled for ")) {
      cancelArr.push(item.replace("Cancelled for ", ""));
    } else if (item.startsWith("Payment for ")) {
      paymentArr.push(item.replace("Payment for ", ""));
    } else {
      otherArr.push(item);
    }
  }
  if (cancelArr.length) {
    finalArr.push("Cancelled for " + ApiHelper.getJoinStrings(cancelArr));
  }
  if (paymentArr.length) {
    finalArr.push("Payment for " + ApiHelper.getJoinStrings(paymentArr));
  }
  // console.log("otherArr", otherArr);
  for (const item of otherArr) {
    finalArr.push(item);
  }
  return finalArr.join(", ");
};

export const getJoinStrings = (arr: string[]) => {
  const input = arr;
  const last = input.pop();
  const result = input.join(", ") + " and " + last;
  return result;
};

export const downloadFileUrl = (fileUrl: string) => {
  if (!fileUrl) return;

  const link = document.createElement("a") as HTMLAnchorElement;

  link.setAttribute("target", "_blank");
  link.setAttribute("href", fileUrl);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getSelectedItems = (
  selectedItems: SelectOption[],
  searchItems: SelectOption[],
  otherItem?: SelectOption
) => {
  let mergeItems = selectedItems;
  if (otherItem) {
    const selectedItem: SelectOption | undefined = selectedItems.find(
      (item2: any) => {
        return item2.id == otherItem.id;
      }
    );
    if (otherItem.selected) {
      if (!selectedItem) {
        mergeItems = [otherItem, ...mergeItems];
      }
    } else {
      if (selectedItem) {
        selectedItem.selected = false;
      }
    }
  }
  searchItems.map((item: any) => {
    const selectedItem: SelectOption | undefined = selectedItems.find(
      (item2: any) => {
        return item2.id == item.id;
      }
    );
    if (item.selected) {
      if (!selectedItem) {
        mergeItems.push(item);
      }
    } else {
      if (selectedItem) {
        selectedItem.selected = false;
      }
    }
  });
  return mergeItems.filter(item => item.selected);
};

export const getTerminalOptions = async () => {
  const result = await callApi("get", "/store/terminal?getAll=1", {}, {});
  if (result.status === 1) {
    const options: SelectOption[] = result.data.map((item: any) => {
      return {
        id: item.id || "",
        text: item.terminalName || ""
      };
    });
    return options;
  }
  return [];
};

export const getUserOptions = async () => {
  const result = await callApi("get", "/store/users?getAll=1", {}, {});
  if (result.status === 1) {
    const options: SelectOption[] = result.data.map((item: any) => {
      return {
        id: item.id || "",
        text: `${item.firstName} ${item.lastName}` || ""
      };
    });
    return options;
  }
  return [];
};

export const getCategoryOptions = async () => {
  const result = await callApi("get", "/store/p_categories", {}, {});
  const options: SelectOption[] = result.map((item: any) => {
    return {
      id: item.id || "",
      text: item.pcName || ""
    };
  });
  return options;
};

export const isImgLink = (url: string) => {
  return (
    url.match(/^http[^\\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) !== null
  );
};

export const uuid = () => {
  return uuidv4();
};

export const getDueDateOptions = () => {
  return [
    {
      id: "30",
      text: "1 month prior",
      selected: false
    },
    {
      id: "28",
      text: "4 weeks prior",
      selected: false
    },
    {
      id: "21",
      text: "3 weeks prior",
      selected: false
    },
    {
      id: "14",
      text: "2 weeks prior",
      selected: false
    },
    {
      id: "7",
      text: "1 week prior",
      selected: false
    },
    {
      id: "6",
      text: "6 days prior",
      selected: false
    },
    {
      id: "5",
      text: "5 days prior",
      selected: false
    },
    {
      id: "4",
      text: "4 days prior",
      selected: false
    },
    {
      id: "3",
      text: "3 days prior",
      selected: false
    },
    {
      id: "2",
      text: "2 days prior",
      selected: false
    },
    {
      id: "1",
      text: "1 day prior",
      selected: false
    }
  ];
};

export const synzUserViewLogs = async (context: any) => {
  const result = await callApi(
    "post",
    "/entities/userLogs",
    {
      settingCardRoute: context.root.$route.name
    },
    {}
  );
  const logs = result.data.logs || [];
  ApiHelper.setStorageItem("USER_VIEW_LOGS", logs);
  return logs;
};

export const getUserViewLogTime = (routeName: string) => {
  const logs = ApiHelper.getStorageItem("USER_VIEW_LOGS") || [];
  const logItem = logs.find((item: any) => item.settingCardRoute === routeName);

  if (logItem && logItem.lastViewTs) {
    return moment(logItem.lastViewTs).calendar();
  }
  return "";
};

export const getPaymentTypesOptions = async (options: any = {}) => {
  const requestObject: any = {};
  if (options.key != undefined) {
    requestObject.key = options.key;
  }
  if (options.getAll != undefined) {
    requestObject.getAll = options.getAll;
  }

  const result = await ApiHelper.callApi(
    "get",
    "/transactions/paymentTypes",
    {},
    requestObject
  );
  if (result.status === 1) {
    const options: SelectOption[] = [];
    result.data.items.map((item: any) => {
      options.push({
        id: item.id || "",
        text: item.name || "",
        selected: false
      });
    });
    return options;
  }

  return [];
};

export const getEntityConfig = async (force = false) => {
  let showCostCenter = ApiHelper.getStorageItem("SHOW_COST_CENTER", false);
  let showGlCodes = ApiHelper.getStorageItem("SHOW_GL_CODES", false);
  let openApplicationPortal = ApiHelper.getStorageItem(
    "OPEN_APPLICATION_PORTAL",
    false
  );
  const result = await ApiHelper.callApi(
    "get",
    "/entities/customization",
    {},
    {},
    ApiType.CORE
  );
  if (result.status === 1) {
    const resultData = result.data || {};
    if (resultData) {
      const jsonData = resultData || {};
      showCostCenter = !!jsonData.showFinancialCodes;
      showGlCodes = !!jsonData.showFinancialCodes;
      openApplicationPortal = !!jsonData.openApplicationPortal;
    }
  }

  return {
    showCostCenter: showCostCenter,
    showGlCodes: showGlCodes,
    openApplicationPortal: openApplicationPortal
  };
};
