var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-box"},[_c('ul',[_c('router-link',{attrs:{"to":{ name: 'FamilyDetails', params: { Id: _vm.$route.params.Id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ active: isActive || isExactActive }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#users","alt":"Members"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Members")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'FamilyFiles', params: { Id: _vm.$route.params.Id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ active: isActive || isExactActive }},[_c('a',{staticClass:"box__item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#folder","alt":"Files"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Files")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'FamilyEvents', params: { Id: _vm.$route.params.Id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ active: isActive || isExactActive }},[_c('a',{staticClass:"box__item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#map","alt":"Events"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Events")])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }