
















import { defineComponent, ref } from "@vue/composition-api";
import OrderByButton from "@/components/Common/OrderByButton.vue";
export default defineComponent({
  name: "TableHeaderColumn",
  components: {
    OrderByButton
  },
  props: {
    label: String,
    sortKey: String,
    sortData: Object,
    onChangeSortValue: Function
  },
  setup(props, context) {
    const onClickSorting = () => {
      if (props.onChangeSortValue) {
        const currentOrder = props.sortData ? props.sortData.order || "" : "";
        const currentDirection = props.sortData
          ? props.sortData.direction || "2"
          : "2";
        if (props.sortKey !== currentOrder) {
          props.onChangeSortValue(props.sortKey, "2");
        } else {
          props.onChangeSortValue(
            props.sortKey,
            currentDirection === "1" ? "2" : "1"
          );
        }
      }
    };
    return {
      onClickSorting
    };
  }
});
