var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade show",staticStyle:{"display":"block","background-color":"rgba(0, 0, 0, 0.3)"},attrs:{"tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",class:{
      'modal-lg': _vm.size === 'large' || _vm.size === 'very-large',
      'modal-xlg': _vm.size === 'xlarge',
      'modal-portal': _vm.size == 'portal'
    },style:(_vm.size === 'very-large'
        ? 'max-width: 100%; max-height: 100%; height: 100%; margin: 0'
        : ''),attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content modal-container",class:{
        isLoading: _vm.isLoading,
        hideScroll: _vm.hideScroll
      },style:(_vm.size === 'very-large'
          ? 'width: 100%; height: 100%; max-height: 100%'
          : '')},[_c('div',{staticClass:"modal-loader"}),_vm._t("header",[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.title || "..."))]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.onClose}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),_c('div',{staticClass:"modal-body",class:{
          hasScroll: _vm.hasScroll,
          bgGray: _vm.bgGray
        }},[_vm._t("body",[_vm._v(" default body ")])],2),(!_vm.hideFooter)?_c('div',{staticClass:"modal-footer"},[_vm._t("footer",[_vm._v(" default footer ")])],2):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }