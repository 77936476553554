var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SettingsGroupEditPage position-relative"},[_c('Header',{attrs:{"title":_vm.headerData.title,"sub-title":_vm.headerData.subTitle}},[_c('div',{staticClass:"head_right",attrs:{"slot":"head_right"},slot:"head_right"},[_c('div',{staticClass:"head__button"},[_c('button',{staticClass:"button__save",on:{"click":_vm.formData.actions.onSubmit}},[_vm._v(" "+_vm._s(_vm.formData.selectedId ? "Update" : "Add")+" ")])])])]),_c('div',{staticClass:"content-inside v1-page has3"},[_c('div',{staticClass:"content_add"},[_c('div',{staticClass:"content__form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-6"},[_c('FormInput',{attrs:{"data":_vm.formData.controls.name}})],1)]),_c('section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-12"},[_c('FormMultiSelect',{attrs:{"data":_vm.formData.controls.users,"selection-label":'Select User(s)'}})],1)])]),_c('section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-12"},[_c('div',{staticClass:"group",class:{
                  isRequired: _vm.formData.controls.functions.required,
                  hasError: _vm.formData.controls.functions.error !== ''
                }},[_c('label',{staticClass:"label__full"},[_vm._v(_vm._s(_vm.formData.controls.functions.label))]),_c('div',{staticClass:"group__select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.controls.functions.value),expression:"formData.controls.functions.value"}],staticClass:"input__full text-uppercase mb-1",attrs:{"disabled":_vm.formData.controls.functions.disabled},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formData.controls.functions, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.changeOption();
                      _vm.formData.controls.functions.error = '';}]}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(_vm._s("Choose a Function".toUpperCase()))]),_vm._l((_vm.formData.controls.functions
                        .options),function(value,key){return [_c('option',{key:'function-option-' + key,class:{
                          optionGroup:
                            value.functionId === value.functionGroup,
                          highlight: value.selected
                        },attrs:{"disabled":value.selected},domProps:{"value":value.functionId}},[_vm._v(" "+_vm._s(value.functionName.toUpperCase())+" ")])]})],2),_c('span')]),(_vm.formData.controls.functions.error)?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.formData.controls.functions.error)+" ")]):_vm._e(),_c('div',{staticClass:"text-uppercase"},[_vm._l((_vm.formData.controls.functions.options.filter(
                      function (item) {
                        return item.selected;
                      }
                    )),function(value,key){return [_c('div',{key:'selected-option-' + key,staticClass:"select2-selection__choice"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                          !_vm.formData.controls.functions.disabled &&
                            !value.ignore
                        ),expression:"\n                          !formData.controls.functions.disabled &&\n                            !value.ignore\n                        "}],staticClass:"select2-selection__choice__remove",attrs:{"role":"presentation"},on:{"click":function($event){_vm.removeOption(value);
                          _vm.$forceUpdate();}}},[_vm._v("×")]),_vm._v(" "+_vm._s(value.functionName)+" ")])]})],2)])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }