// import "./registerServiceWorker";
import App from "./App.vue";
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import router from "./router";
import vClickOutside from "v-click-outside";
import VueApexCharts from "vue-apexcharts";
import VeeValidate from "vee-validate";
Vue.use(vClickOutside);
Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674"
};

Vue.use(VueSweetalert2, options);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue/src";
import Inputmask from "inputmask";

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueApexCharts);
Vue.use(VeeValidate, { fieldsBagName: "formFields" });
Vue.component("apexchart", VueApexCharts);

Vue.directive("mask", {
  bind: function(el, binding) {
    const maskOpts = binding.value;
    maskOpts.showMaskOnHover = false;
    maskOpts.autoUnmask = true;
    maskOpts.clearIncomplete = true;
    Inputmask(maskOpts).mask(el);
  },
  unbind: function(el) {
    Inputmask.remove(el);
  }
});
Vue.directive("inputmask", {
  bind: function(el, binding) {
    const maskOpts = binding.value;
    maskOpts.showMaskOnHover = false;
    maskOpts.autoUnmask = false;
    maskOpts.clearIncomplete = false;
    Inputmask(maskOpts).mask(el);
  },
  unbind: function(el) {
    Inputmask.remove(el);
  }
});

// import DraggablePlugin from "@hipsjs/shopify-draggable-vue";
// import Flowy from "./components/Flowy/components/Flowy.vue";
// import FlowyNode from "./components/Flowy/components/FlowyNode.vue";
// import FlowyBlock from "./components/Flowy/components/FlowyBlock.vue";
// import FlowyNewBlock from "./components/Flowy/components/FlowyNewBlock.vue";
// import FlowyDragHandle from "./components/Flowy/components/FlowyDragHandle";
// import DemoBlock from "./components/Flowy/demo_components/DemoBlock.vue";
// import DemoNode from "./components/Flowy/demo_components/DemoNode.vue";
/*
Vue.use(DraggablePlugin, {
  handle: ".flowy-drag-handle",
  // draggableClass: '.draggable-item',
  // dragHandleClass: '.drag-handle',
  delay: 150,
  distance: 0,
  tresholdDistance: 2,
  // draggable: `.draggable-item`,
  // ignoreHandleClassList: ['drag-ignore-handle', 'scrollable'],
  mirror: {
    constrainDimensions: true,
  },
  appendTo: 'body',
  scrollable: {
    speed: 20,
    sensitivity: 80,
  },
});
*/

// Vue.component("Flowy", Flowy);
// Vue.component("FlowyNode", FlowyNode);
// Vue.component("FlowyBlock", FlowyBlock);
// Vue.component("FlowyNewBlock", FlowyNewBlock);
// Vue.component("FlowyDragHandle", FlowyDragHandle);
// Vue.component("DemoBlock", DemoBlock);
// Vue.component("DemoNode", DemoNode);

new Vue({
  router,
  //apolloProvider: createProvider(),
  render: h => h(App)
}).$mount("#app");
