import { ref } from "@vue/composition-api";

import { ApiHelper } from "@/helpers";
import { FormText } from "@/types";
import FormSelect from "@/types/FormSelect";
import { ApiType } from "@/helpers/ApiHelper";
import FormToggle from "@/types/FormToogle";
type SettingsData = {
  token: string;
  apiKey: string;
  color: string;
  colorFields: any[];
  fontFields: any[];
  cssFile: any[];
  logo: {
    value: string;
    preview: string;
    base64: string;
    label: string;
    error: string;
    required: boolean;
    isDeleted: boolean;
  };
};
export function useSettingsPortalCustomizationStoreStore(context: any) {
  const headerData = ref({
    title: "Portal Customization",
    subTitle: "Settings"
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      portalTitle: FormText;
      applyTitle: FormText;
      address: FormText;
      city: FormText;
      state: FormSelect;
      zipcode: FormText;
      fundBucketName: FormText;
      logoHeight: FormText;
      logoWidth: FormText;
      showFinancialCodes: FormToggle;
      showCostCenter: FormToggle;
      showGlCodes: FormToggle;
      openApplicationPortal: FormToggle;
    };
  }>({
    isLoading: true,
    controls: {
      portalTitle: {
        required: true,
        error: "",
        label: "Participant Portal Title",
        placeholder: "Participant Portal Title",
        type: "text",
        style: "custom",
        value: "",
        maxlength: 150
      },
      applyTitle: {
        required: true,
        error: "",
        label: "Application Portal Title ",
        placeholder: "Application Portal Title ",
        type: "text",
        style: "custom",
        value: "",
        maxlength: 150
      },
      address: {
        label: "Address",
        placeholder: "Enter Address",
        maxlength: 150,
        error: "",
        value: "",
        style: "custom"
      },
      city: {
        label: "City",
        placeholder: "Enter City",
        error: "",
        type: "date",
        value: "",
        style: "custom"
      },
      state: {
        error: "",
        value: "",
        label: "State",
        placeholder: "Select...",
        style: "custom",
        options: ApiHelper.getStateOptions()
      },
      zipcode: {
        label: "Zip Code",
        placeholder: "Enter Zip Code",
        maxlength: 5,
        error: "",
        value: "",
        style: "custom"
      },
      fundBucketName: {
        required: false,
        error: "",
        label: "Fund Bucket Name",
        placeholder: "Fund Bucket Name",
        type: "text",
        style: "custom",
        value: "",
        maxlength: 150
      },
      logoHeight: {
        required: false,
        disabled: true,
        error: "",
        label: "Logo Height",
        placeholder: "Logo Height",
        type: "text",
        style: "custom",
        value: "",
        maxlength: 4
      },
      logoWidth: {
        required: false,
        disabled: true,
        error: "",
        label: "Logo Width",
        placeholder: "Logo Width",
        type: "text",
        style: "custom",
        value: "",
        maxlength: 4
      },
      showCostCenter: {
        label: "Show Cost Centers",
        value: false,
        error: ""
      },
      showGlCodes: {
        label: "Show GL Codes",
        value: false,
        error: ""
      },
      showFinancialCodes: {
        label: "Show Financial Codes",
        value: false,
        error: ""
      },
      openApplicationPortal: {
        label: "Open Application Portal",
        value: false,
        error: ""
      }
    }
  });

  const settingsData = ref<SettingsData>({
    token: "",
    apiKey: "AIzaSyDAo7BqJwgmhSgj6wTOOkQKrFK7nFINRuE",
    color: "#1CA085",
    colorFields: [
      {
        name: "Text Color",
        class: "PrimaryColor",
        value: "#0073f9",
        limit: false,
        swatches: [
          ["#0073f9", "#285185", "#000000", "#a9a9a9"],
          ["#262220", "#6F4849", "#41403C", "#342628"],
          ["#541412", "#192F01", "#B74803", "#AA210F"]
        ]
      },
      {
        name: "Button Color",
        class: "SecondaryColor",
        value: "#000000",
        limit: false,
        swatches: [
          ["#000000", "#ffffff", "#0073f9", "#FF6C02"],
          ["#C34F5A", "#FFA500", "#FCD752", "#5A8100"],
          ["#178CA4", "#18B7BE", "#8CA9D3", "#E8ECEB"]
        ]
      }
    ],
    fontFields: [
      {
        label: "Font Name",
        class: "FontName",
        value: "Roboto",
        options: {
          families: ["Roboto", "Open Sans", "Lato", "Noto Sans", "Raleway"],
          limit: 10,
          sort: "alphabetical"
        }
      }
    ],
    logo: {
      value: "",
      preview: "",
      label: "",
      base64: "",
      isDeleted: false,
      error: "",
      required: false
    },
    cssFile: []
  });

  const pageData = ref<{
    isLoading: boolean;
    hasError: boolean;
    message: string;
  }>({
    isLoading: true,
    hasError: false,
    message: ""
  });

  const loadData = async () => {
    pageData.value.hasError = false;
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "get",
      "/entities/customization",
      {},
      {},
      ApiType.CORE
    );
    ApiHelper.setDataLoading(false);

    if (result.status === 1) {
      pageData.value.hasError = false;
      const resultData = result.data || null;
      if (resultData) {
        const jsonData = resultData || {};
        settingsData.value.colorFields.map((item: any) => {
          if (jsonData[item.class] !== undefined) {
            item.value = jsonData[item.class];
          }
          return item;
        });
        settingsData.value.fontFields.map((item: any) => {
          if (
            jsonData[item.class] !== undefined &&
            item.options.families.includes(jsonData[item.class])
          ) {
            item.value = jsonData[item.class];
          }
          return item;
        });
        settingsData.value.logo.preview = jsonData.Logo || "";
        formData.value.controls.portalTitle.value = jsonData.PortalTitle || "";
        formData.value.controls.applyTitle.value = jsonData.ApplyTitle || "";
        formData.value.controls.address.value = jsonData.address || "";
        formData.value.controls.city.value = jsonData.city || "";
        formData.value.controls.state.value = jsonData.state || "";
        formData.value.controls.zipcode.value = jsonData.zipcode || "";
        formData.value.controls.fundBucketName.value =
          jsonData.fundBucketName || "";
        formData.value.controls.logoWidth.value = jsonData.logoWidth || "";
        formData.value.controls.logoHeight.value = jsonData.logoHeight || "";
        formData.value.controls.showCostCenter.value = !!jsonData.showCostCenter;
        formData.value.controls.showGlCodes.value = !!jsonData.showGlCodes;
        formData.value.controls.showFinancialCodes.value = !!jsonData.showFinancialCodes;
        formData.value.controls.openApplicationPortal.value = !!jsonData.openApplicationPortal;
      }
    } else {
      pageData.value.hasError = true;
      pageData.value.message = result.message;
      // ApiHelper.showErrorMessage(result.message);
    }
  };

  const fontSelect = (event: any) => {
    settingsData.value.fontFields[0].value = event.family;
  };
  const createCSSjson = async () => {
    const primaryColor = settingsData.value.colorFields.find(
      (item: any) => item.class === "PrimaryColor"
    ) || {
      value: ""
    };
    const secondaryColor = settingsData.value.colorFields.find(
      (item: any) => item.class === "SecondaryColor"
    ) || {
      value: ""
    };
    const backgroundColor = settingsData.value.colorFields.find(
      (item: any) => item.class === "BackgroundColor"
    ) || {
      value: ""
    };
    const fontName = settingsData.value.fontFields.find(
      (item: any) => item.class === "FontName"
    ) || {
      value: ""
    };
    let hasError = false;
    let errorMessage = "";
    if (primaryColor.value === "") {
      hasError = true;
      errorMessage = "Text Color is required!";
    } else if (!ApiHelper.validateHexColor(primaryColor.value)) {
      hasError = true;
      errorMessage = "Text Color is invalid!";
    }
    if (secondaryColor.value === "") {
      hasError = true;
      errorMessage = "Button Color is required!";
    } else if (!ApiHelper.validateHexColor(secondaryColor.value)) {
      hasError = true;
      errorMessage = "Button Color is invalid!";
    }
    // if (backgroundColor.value === "") {
    //   hasError = true;
    //   errorMessage = "Background Color is required!";
    // } else if (!ApiHelper.validateHexColor(backgroundColor.value)) {
    //   hasError = true;
    //   errorMessage = "Background Color is invalid!";
    // }
    if (fontName.value === "") {
      hasError = true;
      errorMessage = "Font Name is required!";
    }
    settingsData.value.token = new Date().toISOString();

    formData.value.controls.portalTitle.error = "";
    formData.value.controls.applyTitle.error = "";
    if (formData.value.controls.portalTitle.value === "") {
      hasError = true;
      formData.value.controls.portalTitle.error = "Portal Title is required";
    }
    if (formData.value.controls.applyTitle.value === "") {
      hasError = true;
      formData.value.controls.applyTitle.error = "Apply Title is required";
    }

    if (!hasError) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "put",
        "/entities/customization",
        {
          json: JSON.stringify([
            {
              name: "PrimaryColor",
              value: primaryColor.value
            },
            {
              name: "SecondaryColor",
              value: secondaryColor.value
            },
            {
              name: "BackgroundColor",
              value: backgroundColor.value
            },
            {
              name: "FontName",
              value: fontName.value
            }
          ]),
          logoBase64: settingsData.value.logo.base64,
          deleteLogo: settingsData.value.logo.isDeleted,
          portalTitle: formData.value.controls.portalTitle.value,
          applyTitle: formData.value.controls.applyTitle.value,
          address: formData.value.controls.address.value,
          city: formData.value.controls.city.value,
          state: formData.value.controls.state.value,
          zipcode: formData.value.controls.zipcode.value,
          fundBucketName: formData.value.controls.fundBucketName.value,
          logoHeight: formData.value.controls.logoHeight.value,
          logoWidth: formData.value.controls.logoWidth.value,
          showFinancialCodes: formData.value.controls.showFinancialCodes.value
            ? 1
            : 0,
          showCostCenter: formData.value.controls.showCostCenter.value ? 1 : 0,
          showGlCodes: formData.value.controls.showGlCodes.value ? 1 : 0,
          openApplicationPortal: formData.value.controls.openApplicationPortal
            .value
            ? 1
            : 0
        },
        {},
        ApiType.CORE
      );
      ApiHelper.setDataLoading(false);
      if (result.status == 1) {
        ApiHelper.showSuccessMessage("Saved");
        const children = context.root.$children || [];
        const layout = children.length > 0 ? children[0] : undefined;
        if (layout && layout.$children.length > 0) {
          await layout.$children[0].loadEntityConfig();
        }
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    } else {
      if (errorMessage) {
        ApiHelper.showErrorMessage(errorMessage, "Oops");
      }
    }
  };

  (async () => {
    await ApiHelper.synzUserViewLogs(context);
  })();

  return {
    formData,
    headerData,
    // methods
    fontSelect,
    createCSSjson,
    loadData,
    // data
    settingsData,
    pageData
  };
}
