










































import { defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";
import { FormText } from "@/types";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import FormInput from "@/components/Form/FormInput.vue";
import Vue from "vue";
import $ from "jquery";

export default defineComponent({
  name: "PopupEditCostCenter",
  props: {
    defaultName: String,
    defaultNumber: {
      type: String,
      default: ""
    }
  },
  components: {
    FormInput,
    FormNumber,
    Modal,
    VScroller,
    FormSelect
  },
  setup(props, context) {
    const selectedId = 0;
    const componentData = ref({
      isLoading: false
    });
    const formData = ref<{
      isLoading: boolean;
      controls: {
        number: FormText;
        name: FormText;
      };
      validateAll: any;
      actions: {
        onSubmit: any;
      };
    }>({
      isLoading: true,
      controls: {
        number: {
          required: true,
          label: "Number",
          placeholder: "Number",
          style: "custom",
          value: props.defaultNumber || "",
          error: "",
          maxlength: 100
        },
        name: {
          required: false,
          label: "Cost center name",
          placeholder: "Cost center name",
          style: "custom",
          value: props.defaultName || "",
          error: "",
          maxlength: 150
        }
      },
      validateAll: () => {
        let hasError = false;
        if (formData.value.controls.number.value === "") {
          formData.value.controls.number.error = "Number is required";
          hasError = true;
        }
        return hasError;
      },
      actions: {
        onSubmit: async (force = false) => {
          const hasError = formData.value.validateAll();
          if (hasError) {
            return;
          }
          const result = await ApiHelper.callApi(
            "put",
            `/accounting/costcenters/${selectedId}`,
            {
              number: formData.value.controls.number.value,
              forceUpdate: force ? 1 : 0,
              name: formData.value.controls.name.value
            },
            {}
          );
          if (result.status !== 1) {
            const responseData = result.data || {};
            const total = responseData.total || 0;
            if (total) {
              if (!force) {
                const isConfirmed = await Vue.swal({
                  html: `The Number has tied with <strong>${total}</strong> events. Are you sure you want to update the new number?`,
                  showCancelButton: true,
                  confirmButtonText: "Yes, do it!",
                  showCloseButton: true,
                  closeButtonHtml:
                    '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
                }).then(result => {
                  setTimeout(function() {
                    $(".swal2-backdrop-hide").addClass("d-none");
                  }, 200);
                  return result.isConfirmed;
                });
                if (!isConfirmed) {
                  return;
                }
                await formData.value.actions.onSubmit(true);
              } else {
                ApiHelper.showErrorMessage(result.message, "Oops");
              }
            } else {
              ApiHelper.showErrorMessage(result.message, "Oops");
              return;
            }
          }
          context.emit("callback", [
            {
              id: formData.value.controls.number.value,
              text:
                formData.value.controls.number.value ||
                formData.value.controls.name.value
            }
          ]);
          ApiHelper.showSuccessMessage(selectedId ? "Updated" : "Added");
        }
      }
    });
    return {
      formData,
      componentData,
      ApiHelper: ApiHelper
    };
  }
});
