var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-[#E5E5E5]"},[_c('div',{staticClass:"m-auto max-w-[1400px] "},[_c('div',{staticClass:"flex ptb-[44px]"},[_c('h2',{staticClass:"flex-1 text-base font-bold"},[_vm._v(" ADD ONS: STORE POS ")]),_c('div',{staticClass:"flex"},[(_vm.plusLink != undefined)?_c('router-link',{key:_vm.plusLink,attrs:{"to":_vm.plusLink}},[_c('div',{staticClass:"mr-4 hover:cursor-pointer"},[_c('i',{staticClass:"fa fa-plus"})])]):_vm._e(),_vm._m(0)],1)]),_c('VposTabs',{attrs:{"tabs":[
        {
          label: 'PRODUCTS',
          route: '/pos/storeproducts'
        },
        {
          label: 'ORDERS',
          route: '/pos/storeorders'
        },
        {
          label: 'USERS',
          route: '/pos/storeusers'
        },
        {
          label: 'TERMINALS',
          route: '/pos/storeterminals'
        }
      ]}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('i',{staticClass:"fa fa-search hover:cursor-pointer"})])}]

export { render, staticRenderFns }