































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { computed, defineComponent } from "@vue/composition-api";
import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import HeaderFilters from "@/components/Common/HeaderFilters.vue";
import Pager from "@/components/Common/Pager.vue";
import Modal from "@/components/Common/Modal.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import HeaderActions from "@/components/Common/HeaderActions.vue";
import { ApiHelper } from "@/helpers/index";
import BackButton from "@/components/Common/BackButton.vue";
import { useProfileMedicalsStore } from "@/stores/Profile/ProfileMedicalsStore";
import SidebarProfileEvents from "@/components/Profile/SidebarProfileEvents.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import TransferModal from "@/components/Financial/TransferModal.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import DropdownParticipantStatus from "@/components/Participant/DropdownParticipantStatus.vue";
import FormButton from "@/components/Form/FormButton.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import NavProfileEvents from "@/components/Profile/NavProfileEvents.vue";
import NavProfileDetails from "@/components/Profile/NavProfileDetails.vue";
import VScroller from "@/components/Common/VScroller.vue";

export default defineComponent({
  name: "EventMedicalsPage",
  components: {
    TableHeaderColumn,
    BackButton,
    HeaderEventDetails,
    SidebarEventDetails,
    VAlertMessage,
    Loading,
    Modal,
    Select2,
    HeaderFilters,
    HeaderActions,
    Avatar,
    FilterCheckboxesColumn,
    FilterSearchColumn,
    FilterNumberRangeColumn,
    Pager,
    SidebarProfileEvents,
    TransferModal,
    FilterDateColumn,
    DropdownParticipantStatus,
    FormButton,
    FormInput,
    FormSelect,
    HeaderProfileDetails,
    NavProfileEvents,
    NavProfileDetails,
    VScroller
  },
  setup(props, context) {
    const {
      updateRouters,
      sidebarData,
      isArchived,
      // sort
      updateSortValue,
      headerData,
      pageData,
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      deleteItem,
      updateMedicationStatus,
      sendIncidentEmail,
      showSendIncidentEmail,
      exportPdf
    } = useProfileMedicalsStore(context);
    const setIsPaying = (status: boolean) => {
      pageData.value.paying = status;
    };
    const allowEdit = computed(() => {
      const $this: any = context.root;
      const participantId = context.root.$route.query.participantId || 0;
      const profileId = context.root.$route.params.profileId || 0;
      if (participantId > 0) {
        // medical for participant
        if (
          !(
            $this.$allowedFunctions.includes($this.$userFunctions.EditEvent) ||
            $this.$allowedFunctions.includes(
              $this.$userFunctions.CheckInPermissions
            )
          )
        ) {
          return false;
        }
      } else if (profileId > 0) {
        // medical for profile
        if (
          !$this.$allowedFunctions.includes($this.$userFunctions.EditPerson)
        ) {
          return false;
        }
      }
      return true;
    });
    return {
      updateRouters,
      sidebarData,
      isArchived,
      // sort
      updateSortValue,
      headerData,
      pageData,
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      getFiltersData,
      setActiveFilterTab,
      updateFilterValue,
      closeFilterTab,
      ApiHelper,
      deleteItem,
      setIsPaying,
      allowEdit,
      updateMedicationStatus,
      sendIncidentEmail,
      showSendIncidentEmail,
      exportPdf
    };
  },
  updated() {
    if (this.pageData.isAddNewSuccess) {
      const sidebar: any = this.$refs.childComponentHeaderRef;
      if (sidebar) {
        sidebar.loadData();
        this.pageData.isAddNewSuccess = false;
      }
    }
  }
});
