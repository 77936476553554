import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormEmail } from "@/types";
import Vue from "vue";

export function useUserForgotPasswordStore(context: any) {
  const forgotPasswordInput = ref({
    email: "",
    entityId: 0
  });
  const syncFormData = (formControls: any) => {
    forgotPasswordInput.value.email = formControls.email.value;
    forgotPasswordInput.value.entityId =
      parseInt(context.root.$route.query.entityId) || 0;
  };
  const validateForm = (formControls: any) => {
    let hasError = false;
    if (formControls.email.value === "") {
      hasError = true;
      formControls.email.error = "Email is required!";
    } else {
      if (!ApiHelper.validateEmail(formControls.email.value)) {
        hasError = true;
        formControls.email.error = "Please enter a valid email address";
      }
    }

    return !hasError;
  };

  const resetForm = (formData: any) => {
    formData.controls.email.error = "";
    formData.controls.email.value = "";
  };

  const submitForm = async (dataInput: any, formData: any) => {
    formData.isLoading = true;
    const result = await ApiHelper.callApi(
      "post",
      "/auth/forgotpassword",
      dataInput,
      {},
      "core"
    );
    formData.isLoading = false;
    if (result.status == 1) {
      ApiHelper.showSuccessMessage(
        `The reset password email has sent to ${dataInput.email}!`
      );
      resetForm(formData);
      ApiHelper.gotoPage(context, { name: "Login" });
      await Vue.swal({
        text: `The reset password email has sent to ${dataInput.email}!`,
        confirmButtonText: "OK",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      });
    } else {
      ApiHelper.showErrorMessage(result.message);
    }
  };

  const formData = ref<{
    isLoading: boolean;
    clickMessage: any;
    controls: {
      email: FormEmail;
    };
    actions: {
      onSubmit: any;
    };
  }>({
    isLoading: false,
    clickMessage: "",
    controls: {
      email: {
        name: "email",
        type: "email",
        label: `We'll send a recovery link to`,
        placeholder: "EMAIL ADDRESS",
        error: "",
        value: context.root.$route.query.email || ""
      }
    },
    actions: {
      onSubmit: async () => {
        syncFormData(formData.value.controls);
        if (validateForm(formData.value.controls)) {
          formData.value.clickMessage =
            "If your email was found in our system, we have sent you Password Reset instructions.";
          await submitForm(forgotPasswordInput.value, formData.value);
        }
      }
    }
  });

  const pageData = ref({
    form: formData
  });

  return {
    pageData
  };
}
