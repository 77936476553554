










































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";

import HeaderActions from "@/components/Common/HeaderActions.vue";
import BackButton from "@/components/Common/BackButton.vue";
import { ApiHelper } from "@/helpers";
import NavProfileDetails from "@/components/Profile/NavProfileDetails.vue";
import NavProfileEvents from "@/components/Profile/NavProfileEvents.vue";
import DropdownBox from "@/components/Common/DropdownBox.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import Avatar from "vue-avatar/src/Avatar.vue";

export default defineComponent({
  name: "HeaderProfileDetails",
  props: {
    profileId: String,
    participantId: String,
    forceProfileName: String,
    forceProfilePhoto: String,
    backRoute: Object,
    title: String,
    filters: Object,
    onIconClick: Function,
    noPadding: {
      type: Boolean,
      defaultValue: false
    },
    noZIndex: {
      type: Boolean,
      defaultValue: false
    }
  },
  components: {
    AutoCompleteInput,
    DropdownBox,
    NavProfileEvents,
    HeaderTabs,
    HeaderActions,
    NavProfileDetails,
    BackButton,
    Avatar
  },
  setup(props, context) {
    const pageTitle = ref<string>("");
    const logo = ref<string>("");
    const showDropdown = ref<boolean>(false);
    const breadcrumbs = ref<{ title: string; route: any }[]>([]);
    const keyword = ref("");
    const events = ref<
      {
        show: boolean;
        profileId: number;
        participantId: number;
        fullName: string;
        typeId: number;
        typeName: string;
      }[]
    >([]);
    const loadData = () => {
      (async () => {
        try {
          let participantId = 0;
          let eventId = 0;
          const profileId = parseInt(props.profileId + "") || 0;
          if (props.participantId) {
            participantId = parseInt(props.participantId) || 0;
          }
          if (participantId) {
            const result = await ApiHelper.callApi(
              "get",
              "/participants/" + participantId,
              {},
              {}
            );
            if (result.status === 1) {
              pageTitle.value =
                (result.data.firstName || "") +
                " " +
                (result.data.lastName || "");
              logo.value = result.data.logo || "";
              eventId = result.data.eventId;
              breadcrumbs.value.push({
                title: result.data.eventName || "",
                route: {
                  name: "EventDetails",
                  params: {
                    eventId: result.data.eventId + ""
                  }
                }
              });
            }

            ApiHelper.callApi(
              "get",
              "/participants",
              {},
              {
                eventId: eventId,
                getAll: 1
              }
            ).then(result2 => {
              if (result2.status == 1) {
                events.value = [];
                (result2.data.participants || []).map((item2: any) => {
                  if (item2.id != participantId) {
                    events.value.push({
                      show: true,
                      profileId: item2.profileId,
                      participantId: item2.id,
                      fullName: item2.firstName + " " + item2.lastName,
                      typeId: item2.participantTypeId,
                      typeName: item2.participantTypeName
                    });
                  }
                });
              }
            });
          } else {
            const result = await ApiHelper.callApi(
              "get",
              "/profiles/" + props.profileId + "/info",
              {},
              {}
            );
            if (result.status !== 1) {
              ApiHelper.gotoPage(context, {
                name: "Profiles"
              });
              return;
            }
            const profileData = result.data || {};
            pageTitle.value = ApiHelper.getFullName(
              profileData.firstName,
              profileData.lastName
            );
            logo.value = profileData.logo || "";
          }
        } catch (err) {
          pageTitle.value = "";
        }
      })();
    };
    loadData();
    const isShowFilters = ref<boolean>(false);
    const hideFilters = () => {
      isShowFilters.value = false;
    };
    const toggleFilters = () => {
      if (props.filters && props.filters.list.length > 0) {
        isShowFilters.value = !isShowFilters.value;
      } else {
        isShowFilters.value = false;
      }
    };

    const searchParticipants = (key: string) => {
      events.value.map(item => {
        if (
          item.fullName.toLocaleLowerCase().search(key.toLocaleLowerCase()) > -1
        ) {
          item.show = true;
        } else {
          item.show = false;
        }
      });
    };

    const doSearch = () => {
      searchParticipants(keyword.value);
    };

    const sidebarData = ref<{
      show: boolean;
      isArchived: boolean;
      isLoading: boolean;
      name: string;
      logo: string;
      totalLinkedProfiles: number;
      totalEvents: number;
      totalMedicals: number;
      totalNotes: number;
      totalFiles: number;
      totalTodos: number;
      foundProfiles: any;
      showAci: boolean;
      familyID: number;
      status: number;
    }>({
      show: false,
      isArchived: false,
      isLoading: true,
      name: "",
      logo: "",
      totalLinkedProfiles: 0,
      totalEvents: 0,
      totalMedicals: 0,
      totalNotes: 0,
      totalFiles: 0,
      totalTodos: 0,
      foundProfiles: [],
      showAci: true,
      familyID: 0,
      status: 0
    });

    const isACILoading = ref<boolean>(false);
    const suggestProfiles = async (key: string) => {
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/search",
        {},
        {
          order: 2,
          direction: 1,
          ignoreIds: props.profileId + "",
          key: key
        }
      );
      if (result.status === 1) {
        sidebarData.value.foundProfiles = result.data.profiles.map(
          (item: any) => ({
            id: item.id,
            text: ApiHelper.getFullName(item.firstName, item.lastName),
            data: item
          })
        );
      }
      isACILoading.value = false;
    };

    const selectProfile = (item: any) => {
      if (item.data) {
        const profileId = item.data.id || 0;
        // goto profile details page
        if (context.root.$route.name == "ProfileTribes") {
          ApiHelper.gotoPage(context, {
            name: context.root.$route.name,
            params: { profileId: profileId.toString() },
            query: {
              family: item.data.family || 0
            }
          });
        } else {
          ApiHelper.gotoPage(context, {
            name: context.root.$route.name,
            params: { profileId: profileId.toString() }
          });
        }
      }
    };

    const openPreview = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();
      const result = await ApiHelper.callApi(
        "get",
        `/profiles/${props.profileId}/preview`
      );
      if (result.status == 1) {
        const portalLink = result.data.portalLink;
        window.open(portalLink || "", "_blank")!.focus();
      }
    };

    const pageData = ref<{
      showCostCenter?: boolean;
      showGlCodes?: boolean;
      openApplicationPortal?: boolean;
    }>({});
    (async () => {
      if (
        typeof pageData.value.showGlCodes == "undefined" &&
        typeof pageData.value.showCostCenter == "undefined"
      ) {
        const configData = await ApiHelper.getEntityConfig(true);
        pageData.value.showGlCodes = configData.showGlCodes;
        pageData.value.showCostCenter = configData.showCostCenter;
        pageData.value.openApplicationPortal = configData.openApplicationPortal;
      }
    })();

    return {
      pageData,
      openPreview,
      sidebarData,
      isACILoading,
      suggestProfiles,
      selectProfile,
      doSearch,
      keyword,
      events,
      showDropdown,
      breadcrumbs,
      isShowFilters,
      hideFilters,
      toggleFilters,
      pageTitle,
      logo
    };
  }
});
