































































































































































































import { defineComponent, ref } from "@vue/composition-api";

import Header from "@/components/Common/Header.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import PopupPayment from "@/components/Financial/PopupPayment.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import { useFinancialPlansStore } from "@/stores/Financial/FinancialPlansStore";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
export default defineComponent({
  name: "FinancialPlans",
  components: {
    TableHeaderColumn,
    Loading,
    Header,
    Pager,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    PopupPayment
  },
  setup(props, context) {
    const {
      loadData,
      pageData,
      headerData,
      updateRouters,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      // pager
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      updateStatusFilter
    } = useFinancialPlansStore(context);

    return {
      loadData,
      pageData,
      headerData,
      updateRouters,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      // pager
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      updateStatusFilter
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.updateStatusFilter();
    this.loadList();
  }
});
