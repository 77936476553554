









































































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Header from "@/components/Common/Header.vue";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import Pager from "@/components/Common/Pager.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import { useAccountingCostCentersStore } from "@/stores/Financial/Accounting/AccountingCostCenter/AccountingCostCentersStore";
import Modal from "@/components/Common/Modal.vue";
import FormButton from "@/components/Form/FormButton.vue";
import FormDatePicker3 from "@/components/Form/FormDatePicker3.vue";
import DropdownParticipantStatus from "@/components/Participant/DropdownParticipantStatus.vue";

// type PageData = {
//   isLoading: boolean;
// };

export default defineComponent({
  name: "FinancialAccounting",
  components: {
    Pager,
    Header,
    HeaderTabs,
    FilterSearchColumn,
    TableHeaderColumn,
    Loading,
    Modal,
    FormButton,
    FormDatePicker3,
    DropdownParticipantStatus
  },
  setup(props, context) {
    const {
      updateRouters,
      updateItemStatus,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      exportCSV
    } = useAccountingCostCentersStore(context);

    return {
      updateRouters,
      updateItemStatus,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      ApiHelper: ApiHelper,
      exportCSV
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
