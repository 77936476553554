import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormText } from "@/types";
import Vue from "vue";

export function useAccountingCostCenterEditStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const headerData = ref({
    id: selectedId,
    title: selectedId > 0 ? "" : "Add Cost Center",
    subTitle: "Cost Centers"
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      number: FormText;
      name: FormText;
    };
    validateAll: any;
    actions: {
      onSubmit: any;
    };
  }>({
    isLoading: true,
    controls: {
      number: {
        required: true,
        label: "Number",
        placeholder: "Number",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100
      },
      name: {
        required: false,
        label: "Cost center name",
        placeholder: "Cost center name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 150
      }
    },
    validateAll: () => {
      let hasError = false;
      if (formData.value.controls.number.value === "") {
        formData.value.controls.number.error = "Number is required";
        hasError = true;
      }
      return hasError;
    },
    actions: {
      onSubmit: async (force = false) => {
        const hasError = formData.value.validateAll();
        if (hasError) {
          return;
        }
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/costcenters/${selectedId}`,
          {
            number: formData.value.controls.number.value,
            forceUpdate: force ? 1 : 0,
            name: formData.value.controls.name.value
          },
          {}
        );
        if (result.status !== 1) {
          const responseData = result.data || {};
          const total = responseData.total || 0;
          if (total) {
            if (!force) {
              const isConfirmed = await Vue.swal({
                html: `The Number has tied with <strong>${total}</strong> events. Are you sure you want to update the new number?`,
                showCancelButton: true,
                confirmButtonText: "Yes, do it!",
                showCloseButton: true,
                closeButtonHtml:
                  '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
              }).then(result => {
                setTimeout(function() {
                  $(".swal2-backdrop-hide").addClass("d-none");
                }, 200);
                return result.isConfirmed;
              });
              if (!isConfirmed) {
                return;
              }
              await formData.value.actions.onSubmit(true);
            } else {
              ApiHelper.showErrorMessage(result.message, "Oops");
            }
          } else {
            ApiHelper.showErrorMessage(result.message, "Oops");
            return;
          }
        }
        ApiHelper.showSuccessMessage(selectedId ? "Updated" : "Added");
        ApiHelper.gotoPage(context, {
          name: "FinancialAccounting"
        });
      }
    }
  });

  const pageData = ref<{
    isLoading: boolean;
  }>({
    isLoading: true
  });

  const loadData = async () => {
    if (selectedId > 0) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        `/accounting/costcenters/${selectedId}`,
        {},
        {}
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const resultData = result.data || null;
        if (resultData) {
          headerData.value.title = resultData.costCenterNumber || "";
          headerData.value.subTitle = "Cost Centers";
          formData.value.controls.number.value =
            resultData.costCenterNumber || "";
          formData.value.controls.name.value = resultData.costCenterName || "";
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "FinancialAccounting" });
      }
    } else {
      ApiHelper.setDataLoading(false);
    }
  };

  return {
    loadData,
    headerData,
    pageData,
    formData
  };
}
