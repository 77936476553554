var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ProfilesPage MasterPage"},[_c('div',{attrs:{"id":"content"}},[_c('HeaderProfiles',{attrs:{"title":"People","total":_vm.pageData.pager.total,"filters":_vm.getFiltersData(),"tag-filter":_vm.tagFilter,"userSettings":_vm.userSettings,"hide-tag-filter":false},on:{"updateProfilesCustomView":_vm.updateProfilesCustomView}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideHeadActions),expression:"hideHeadActions"}],staticClass:"head_achecked",class:{
          has__value: _vm.getCheckedItems().length > 0,
          active: _vm.pageData.showHeadActions
        },attrs:{"slot":"head_achecked"},on:{"click":_vm.toggleHeadActions},slot:"head_achecked"},[_c('div',{staticClass:"action__selected"},[_c('span',[_vm._v(_vm._s(_vm.getCheckedItems().length))]),_vm._v(" selected: ")]),_c('div',{staticClass:"action__current color__title"},[_vm._v("Actions")]),_c('ul',{staticClass:"action__list w-100"},[(_vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson))?_c('li',{on:{"click":function($event){return _vm.showPopupArchives()}}},[_c('span',[_vm._v("Archive "),_c('small',[_vm._v("(hide/delete)")])]),_c('img',{attrs:{"src":require("../../assets/images/icon-tooltip.png"),"alt":"Icon"}})]):_vm._e(),(_vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson))?_c('li',{on:{"click":function($event){return _vm.doTagSelectedItems()}}},[_c('span',[_vm._v("Tag")]),_c('img',{attrs:{"src":require("../../assets/images/icon-tooltip.png"),"alt":"Icon"}})]):_vm._e(),(_vm.$allowedFunctions.includes(_vm.$userFunctions.Message))?_c('li',{on:{"click":function () {
                _vm.popupNewMessage.formData.controls.message.error = '';
                _vm.popupNewMessage.formData.controls.message.value = '';
                _vm.popupNewMessage.show = true;
              }}},[_c('span',[_vm._v("Message")])]):_vm._e(),(_vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson))?_c('li',{on:{"click":function($event){_vm.updateSelectedProfiles();
              _vm.mainProfile.firstName = '';
              _vm.popupNewTribe.formData.controls.profile.error = '';
              _vm.popupNewTribe.formData.controls.profile.value = '';
              _vm.popupNewTribe.formData.controls.name.error = '';
              _vm.popupNewTribe.formData.controls.name.value = '';
              _vm.popupNewTribe.show = true;}}},[_c('span',[_vm._v("TRIBE/FAMILY UP")]),_c('img',{attrs:{"src":require("../../assets/images/icon-tooltip.png"),"alt":"Icon"}})]):_vm._e(),(
              _vm.showMergeButton() &&
                _vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson)
            )?_c('li',{on:{"click":_vm.mergeParticipants}},[_c('span',[_vm._v("MERGE")]),_c('img',{attrs:{"src":require("../../assets/images/icon-tooltip.png"),"alt":"Icon"}})]):_vm._e()])])]),_c('ProfilesTable',{ref:"profilesTable",attrs:{"pageData":_vm.pageData,"gotoPage":_vm.gotoPage,"getCheckedItems":_vm.getCheckedItems,"togglePagerItems":_vm.togglePagerItems,"hideHeadActions":_vm.hideHeadActions,"onViewDetails":_vm.onViewDetails,"onClickPrev":_vm.onClickPrev,"onClickNext":_vm.onClickNext,"onPagerChange":_vm.onPagerChange,"setActiveFilterTab":_vm.setActiveFilterTab,"closeFilterTab":_vm.closeFilterTab,"updateFilterValue":_vm.updateFilterValue,"select-all-genders":_vm.selectAllGenders,"reset-genders":_vm.resetGenders,"select-all-events":_vm.selectAllEvents,"reset-events":_vm.resetEvents,"select-all-families":_vm.selectAllFamilies,"reset-families":_vm.resetFamilies,"show-status-dropdown":_vm.showStatusDropdown,"update-sort-value":_vm.updateSortValue,"update-routers":_vm.updateRouters,"selectItem":_vm.selectItem,"userSettings":_vm.userSettings}})],1),(_vm.profilePanel.isShow)?_c('ProfilePanel',{attrs:{"data":_vm.profilePanel.data,"is-loading":!_vm.profilePanel.isShow,"on-close":_vm.onClosePanel,"on-update-profile-avatar":_vm.onUpdateProfileAvatar},on:{"close":function($event){_vm.profilePanel.isShow = false}}}):_vm._e(),(_vm.popupTags.show)?_c('Modal',{attrs:{"title":"Tag the people","on-close":_vm.closePopupTags}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',[_vm._v("You're about to tag "+_vm._s(_vm.getCheckedItems().length)+" users")]),_c('div',{staticClass:"pt-3",staticStyle:{"min-height":"200px"}},[_c('FormTags',{attrs:{"typeUse":'profile',"on-remove-tag":_vm.onRemoveTag,"on-suggest-tags":_vm.onSuggestTags,"on-add-tag":_vm.onAddTag,"on-remove-last-tag":_vm.onRemoveLastTag,"on-select-tag":_vm.onSelectTag,"suggest-on-focus":true,"data":_vm.popupTags.controls.tags},on:{"focus":function (key) {
              _vm.onSuggestTags(key);
            }}})],1)]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"form-buttons"},[_c('input',{staticClass:"btn btn-primary",attrs:{"type":"submit","value":"Submit"},on:{"click":function($event){_vm.doSubmitTagSelectedProfiles(_vm.tags, function () {
              _vm.loadTags();
              var parent = _vm.$parent;
              if (parent.loadPopularTags) {
                parent.loadPopularTags();
              }
            })}}})])])]):_vm._e(),(_vm.popupArchives.show)?_c('Modal',{staticClass:"archive-modal",attrs:{"title":"Archive/Delete","on-close":_vm.closePopupArchives}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',[_vm._v("Selected people:")]),_c('ol',_vm._l((_vm.getCheckedItems()),function(item){return _c('li',{key:item.id},[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")])}),0)]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"btn-group align-items-center"},[(_vm.pageData.canDeleteProfile)?_c('label',{staticClass:"slider-control mr-3",class:_vm.toggleValue},[_c('input',{class:{ hasValue: _vm.toggleValue != 'archive' },attrs:{"type":"checkbox","id":"togBtn"},domProps:{"checked":_vm.toggleValue != 'archive'},on:{"change":function($event){return _vm.pActiveSwitch()}}}),_c('div',{staticClass:"slider round"})]):_vm._e(),_c('input',{staticClass:"btn btn-danger",attrs:{"type":"submit"},domProps:{"value":_vm.pageData.canDeleteProfile ? 'Submit' : 'Archive'},on:{"click":function($event){return _vm.doDeleteSelectedItems(_vm.$root)}}})])])]):_vm._e(),_c('Modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.popupNewMessage.show),expression:"popupNewMessage.show"}]},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"modal-header",attrs:{"slot":"header"},slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Create new message")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function () {
              _vm.popupNewMessage.show = false;
            }}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),(_vm.popupNewMessage.formData)?_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',[_c('div',[_vm._v("Selected people:")]),_c('ol',_vm._l((_vm.getCheckedItems()),function(item){return _c('li',{key:item.id},[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")])}),0)]),_c('div',[_c('div',{staticClass:"form-group",class:{
            hasError: _vm.popupNewMessage.formData.controls.message.error !== ''
          }},[_c('label',{staticClass:"label__full isRequired",attrs:{"for":_vm.popupNewMessage.formData.controls.message.name}},[_vm._v("Message")]),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.popupNewMessage.formData.controls.message.value),expression:"popupNewMessage.formData.controls.message.value",modifiers:{"trim":true}}],staticClass:"form-control",class:{
              'border-danger': _vm.popupNewMessage.formData.controls.message.error
            },attrs:{"id":_vm.popupNewMessage.formData.controls.message.name,"placeholder":_vm.popupNewMessage.formData.controls.message.placeholder},domProps:{"value":(_vm.popupNewMessage.formData.controls.message.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.popupNewMessage.formData.controls.message, "value", $event.target.value.trim())},function($event){_vm.popupNewMessage.formData.controls.message.error = ''}],"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(_vm.popupNewMessage.formData.controls.message.error !== '')?_c('span',{staticClass:"error text-danger"},[_vm._v(_vm._s(_vm.popupNewMessage.formData.controls.message.error))]):_vm._e()])])]):_vm._e(),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"form-buttons"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"},on:{"click":_vm.popupNewMessage.onSubmit}},[_vm._v(" Send message ")]),_c('button',{staticClass:"btn btn-danger",staticStyle:{"margin-left":"10px"},attrs:{"type":"submit"},on:{"click":function($event){_vm.popupNewMessage.show = false}}},[_vm._v(" Cancel ")])])])]),_c('Modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.popupNewTribe.show),expression:"popupNewTribe.show"}]},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"modal-header",attrs:{"slot":"header"},slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Tribe/Family Up")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function () {
              _vm.popupNewTribe.show = false;
            }}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),(_vm.popupNewTribe.formData)?_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('VScroller',{staticClass:"box_content pr-2 pb-2",staticStyle:{"max-height":"calc(100vh - 300px)"}},[_c('div',[_c('div',{staticClass:"form-group",class:{
              hasError: _vm.popupNewTribe.formData.controls.profile.error !== ''
            }},[_c('label',{staticClass:"label__full isRequired",attrs:{"for":_vm.popupNewTribe.formData.controls.profile.name}},[_vm._v(_vm._s(_vm.popupNewTribe.formData.controls.profile.label))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.popupNewTribe.formData.controls.profile.value),expression:"popupNewTribe.formData.controls.profile.value"}],staticClass:"form-control",class:{
                'border-danger': _vm.popupNewTribe.formData.controls.profile.error
              },attrs:{"id":_vm.popupNewTribe.formData.controls.profile.name,"placeholder":_vm.popupNewTribe.formData.controls.profile.placeholder},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.popupNewTribe.formData.controls.profile, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.popupNewTribe.formData.controls.profile.error = '';
                _vm.onChangeMainProfile();}]}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Select Person")]),_vm._l((_vm.selectedProfiles),function(item){return _c('option',{key:'profile-item' + item.id,domProps:{"value":item.id.toString()}},[_vm._v(" "+_vm._s(item.firstName + " " + item.lastName)+" ")])})],2),(_vm.popupNewTribe.formData.controls.profile.error !== '')?_c('span',{staticClass:"error text-danger"},[_vm._v(_vm._s(_vm.popupNewTribe.formData.controls.profile.error))]):_vm._e()])]),_c('div',[_c('div',{staticClass:"form-group",class:{
              hasError: _vm.popupNewTribe.formData.controls.name.error !== ''
            }},[_c('label',{staticClass:"label__full isRequired",attrs:{"for":_vm.popupNewTribe.formData.controls.name.name}},[_vm._v(_vm._s(_vm.popupNewTribe.formData.controls.name.label))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.popupNewTribe.formData.controls.name.value),expression:"popupNewTribe.formData.controls.name.value"}],staticClass:"form-control",class:{
                'border-danger': _vm.popupNewTribe.formData.controls.name.error
              },attrs:{"id":_vm.popupNewTribe.formData.controls.name.name,"placeholder":_vm.popupNewTribe.formData.controls.name.placeholder},domProps:{"value":(_vm.popupNewTribe.formData.controls.name.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.popupNewTribe.formData.controls.name, "value", $event.target.value)},function($event){_vm.popupNewTribe.formData.controls.name.error = ''}]}}),(_vm.popupNewTribe.formData.controls.name.error !== '')?_c('span',{staticClass:"error text-danger"},[_vm._v(_vm._s(_vm.popupNewTribe.formData.controls.name.error))]):_vm._e()])]),(_vm.popupNewTribe.formData.controls.profile.value !== '')?_c('div',[_c('h5',[_vm._v("Members")]),_vm._l((_vm.selectedProfiles),function(item){return _c('div',{key:'selected-profile-item' + item.id},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                item.id.toString() !==
                  _vm.popupNewTribe.formData.controls.profile.value
              ),expression:"\n                item.id.toString() !==\n                  popupNewTribe.formData.controls.profile.value\n              "}],staticClass:"row",attrs:{"dataId":item.id}},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Profile")]),_c('input',{staticClass:"form-control",attrs:{"readonly":""},domProps:{"value":item.firstName + ' ' + item.lastName}})])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.mainProfile.firstName ? ((_vm.mainProfile.firstName) + "'s") : "Select Type"))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(item.type),expression:"item.type"}],staticClass:"form-control",class:{
                      'border-danger': item.typeError
                    },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(item, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){item.typeError = ''}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Select Type")]),_vm._l((_vm.familyTypeOptions),function(item){return _c('option',{key:'family-type-option-' + item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.text)+" ")])})],2),(item.typeError)?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(item.typeError)+" ")]):_vm._e()])])])])}),_c('div',[(_vm.popupNewTribe.formData.controls.participants.error !== '')?_c('span',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.popupNewTribe.formData.controls.participants.error)+" ")]):_vm._e()])],2):_vm._e()])],1):_vm._e(),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"form-buttons"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"},on:{"click":_vm.popupNewTribe.onSubmit}},[_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-danger",staticStyle:{"margin-left":"10px"},attrs:{"type":"submit"},on:{"click":function($event){_vm.popupNewTribe.show = false}}},[_vm._v(" Cancel ")])])])]),(_vm.popupMergeParticipants.show)?_c('PopupMergeParticipants',{attrs:{"profileIds":_vm.popupMergeParticipants.profileIds},on:{"callback":function () {
        _vm.popupMergeParticipants.show = false;
        _vm.selectedItems = [];
        _vm.loadList();
      },"dismiss":function () {
        _vm.popupMergeParticipants.show = false;
      }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }