import { ApiHelper } from "@/helpers";
import Inputmask from "inputmask";

declare const window: any;

export const registerAllergies = () => {
  window.fbControls.push((controlClass: any) => {
    class AllergiesControl extends controlClass {
      build() {
        const id = this.id;
        const returnHTML: any = [];
        const userData = this.config.userData || [];
        const noneOptionChecked =
          (this.config.noneOptionChecked || false) == true;
        if (!userData.length) {
          // init
          userData.push({
            allergiesId: 0,
            allergiesName: "",
            allergiesCategory: "",
            allergiesSeverity: ""
          });
        }

        const requiredAttr = this.required
          ? 'required="required" aria-required="true"'
          : "";
        const requireTag = this.required
          ? '<span class="formbuilder-required">*</span>'
          : "";
        for (const data of userData) {
          // saved data
          const allergiesId = data.allergiesId || 0;
          const allergiesNameVal = data.allergiesName || "";
          const allergiesCategoryVal = data.allergiesCategory || "";
          const allergiesSeverityVal = data.allergiesSeverity || "";

          // ids
          const randomId = Math.floor(Math.random() * 10000000);
          const allergiesNameId = `${id}-allergies-name-${randomId}`;
          const allergiesCategoryId = `${id}-allergies-category-${randomId}`;
          const allergiesSeverityId = `${id}-allergies-severity-${randomId}`;

          const severityOptions: any = [];
          for (const s of ["Inhale", "Ingest", "On-Contact"]) {
            severityOptions.push(
              `<option value="${s}" 
                ${allergiesSeverityVal == s ? "selected" : ""}>
                ${s}
              </option>`
            );
          }

          const formgroup = $(".todo-edit-page").length ? "" : "form-group";
          returnHTML.push(`
            <div class="allergies-info row" data-allergies-id="${allergiesId}">
              <span class="remove-allergies-btn" title="Remove">x</span>
              <div class="formbuilder-text ${formgroup} field-text-${allergiesNameId} col-6">
                <label for="${allergiesNameId}" class="formbuilder-text-label field-label">Allergy Name ${requireTag}</label>
                <input type="text" value="${allergiesNameVal}" class="form-control" name="${allergiesNameId}" inputmask="none" id="${allergiesNameId}" ${requiredAttr} maxlength="255">
              </div>
              <div class="formbuilder-select ${formgroup} field-select-${allergiesCategoryId} col-6">
                <label for="${allergiesCategoryId}" class="formbuilder-select-label field-label">
                  Allergy Category ${requireTag}
                </label>
                <select class="form-control allergies-category" name="${allergiesCategoryId}" id="${allergiesCategoryId}" ${requiredAttr}>
                  <option value="">Select Category</option>
                  <option value="Food" ${
                    allergiesCategoryVal == "Food" ? "selected" : ""
                  }>Food</option>
                  <option value="Environmental" ${
                    allergiesCategoryVal == "Environmental" ? "selected" : ""
                  }>Environmental</option>
                  <option value="Other" ${
                    allergiesCategoryVal == "Other" ? "selected" : ""
                  }>Other</option>
                </select>
              </div>
              <div class="formbuilder-select ${formgroup} field-select-${allergiesSeverityId} col-6 allergies-severity-section 
                ${allergiesCategoryVal == "Food" ? "" : "d-none"}">
                <label for="${allergiesSeverityId}" class="formbuilder-select-label field-label">
                  Severity ${requireTag}
                </label>
                <select class="form-control" name="${allergiesSeverityId}" id="${allergiesSeverityId}" ${requiredAttr}>
                  <option value="">Select Severity</option>
                  ${severityOptions.join("")}
                </select>
              </div>
            </div>
          `);
        }

        return `
          <div class="checkbox-group">
            <div class="formbuilder-checkbox">
              <input name="allergies-${id}-none-option" id="allergies-${id}-none-option" type="checkbox" class="allergies-none-option" 
                ${noneOptionChecked ? "checked" : ""}
              >
              <label for="allergies-${id}-none-option">N/A</label>
            </div>
          </div>
          ${returnHTML.join(`<hr class="allergies-spliter" />`)}
          <div class="allergies-addmore"><span class="allergies-addmore-btn">More Allergies</span></div>
        `;
      }
      onRender() {
        const field = $(`.field-${this.id}`);
        const noneOptionChecked =
          (this.config.noneOptionChecked || false) == true;
        if (noneOptionChecked) {
          field.addClass("none-allergies");
        }

        // add more allergies
        $(document)
          .off("click", ".allergies-addmore-btn")
          .on("click", ".allergies-addmore-btn", e => {
            const button = $(e.target);
            const allergiesField = button.closest(".formbuilder-allergies");
            const randomId = Math.floor(Math.random() * 10000000);
            const sectionId = `allergies-info-${randomId}`;
            const allergiesSection = allergiesField
              .find(".allergies-info")
              .last();

            // just allow add more if filled for last allergies
            const lastAllergiesName =
              allergiesSection
                .find(`input[type=text][id*="allergies-name"]`)
                .val() || "";
            const lastAllergiesCategory =
              allergiesSection.find(`select[id*="allergies-category"]`).val() ||
              "";
            const lastAllergiesSeverity =
              allergiesSection.find(`select[id*="allergies-severity"]`).val() ||
              "";
            if (
              `${lastAllergiesName}`.trim() == "" ||
              lastAllergiesCategory == "" ||
              (lastAllergiesCategory == "Food" && lastAllergiesSeverity == "")
            ) {
              return;
            }

            const newSection = allergiesSection.clone();
            newSection.removeAttr("data-allergies-id").addClass(sectionId);
            allergiesSection.after(`
              <hr class="allergies-spliter" />
              ${newSection.prop("outerHTML")}
            `);
            // new ids/val for new section
            const allergiesNameId = `${this.id}-allergies-name-${randomId}`;
            const allergiesCategoryId = `${this.id}-allergies-category-${randomId}`;
            const allergiesSeverityId = `${this.id}-allergies-severity-${randomId}`;
            $(`.${sectionId} > div[class*="formbuilder-"]`).each((i, obj) => {
              const objClass = $(obj)?.attr("class") || "";
              // allergies name
              if (objClass.indexOf("allergies-name") != -1) {
                $(obj).attr(
                  "class",
                  `formbuilder-text form-group field-text-${allergiesNameId} col-6`
                );
                $(obj)
                  .find("label")
                  .attr("for", allergiesNameId);
                $(obj)
                  .find("input[type=text]")
                  .attr({
                    id: allergiesNameId,
                    name: allergiesNameId,
                    value: ""
                  });
              }

              // allergies category
              if (objClass.indexOf("allergies-category") != -1) {
                $(obj).attr(
                  "class",
                  `formbuilder-select form-group field-select-${allergiesCategoryId} col-6`
                );
                $(obj)
                  .find("label")
                  .attr("for", allergiesCategoryId);
                $(obj)
                  .find("select")
                  .attr({
                    id: allergiesCategoryId,
                    name: allergiesCategoryId
                  })
                  .val("");
              }

              // allergies severity
              if (objClass.indexOf("allergies-severity") != -1) {
                $(obj).attr(
                  "class",
                  `formbuilder-select form-group field-select-${allergiesSeverityId} col-6 allergies-severity-section d-none`
                );
                $(obj)
                  .find("label")
                  .attr("for", allergiesSeverityId);
                $(obj)
                  .find("select")
                  .attr({
                    id: allergiesSeverityId,
                    name: allergiesSeverityId
                  })
                  .val("");
              }
            });
            $(
              `.${sectionId} .text-danger, .${sectionId} .border-danger`
            ).removeClass("text-danger border-danger");
          });

        // has or has not allergies
        $(document)
          .off("change", ".allergies-none-option")
          .on("change", ".allergies-none-option", e => {
            const cb = $(e.target);
            const allergiesField = cb.closest(".formbuilder-allergies");
            if (allergiesField.closest(".todo-edit-page").length) return;

            if (cb.prop("checked") == true) {
              allergiesField.addClass("none-allergies");
            } else {
              allergiesField.removeClass("none-allergies");
            }
          });

        $(document)
          .off("change", ".allergies-category")
          .on("change", ".allergies-category", e => {
            const select = $(e.target);
            const infoSection = select.closest(".allergies-info");
            const severitySection = infoSection
              .find(".allergies-severity-section")
              .addClass("d-none");
            if (select.val() == "Food") {
              severitySection.removeClass("d-none");
            }
          });

        $(document)
          .off("click", ".remove-allergies-btn")
          .on("click", ".remove-allergies-btn", e => {
            const btn = $(e.target);
            const allergiesField = btn.closest(".formbuilder-allergies");
            const spliter = btn.closest(".allergies-info").prev();
            if (spliter.hasClass("allergies-spliter")) {
              spliter.remove();
            }

            const info = btn.closest(".allergies-info");
            const allergiesId = info.data("allergies-id") || 0;
            if (allergiesId > 0) {
              // update deleted medicalIds
              const deletedIds = (
                allergiesField.attr("data-deleted-allergiesids") || ""
              )
                .split(",")
                .filter(id => id != "");
              deletedIds.push(allergiesId);
              allergiesField.attr(
                "data-deleted-allergiesids",
                deletedIds.join(",")
              );
            }
            btn.closest(".allergies-info").remove();
          });
      }
    }

    // register this control
    controlClass.register("allergies", AllergiesControl);
    return AllergiesControl;
  });
};

export const registerMedication = () => {
  window.fbControls.push((controlClass: any) => {
    class MedicationControl extends controlClass {
      build() {
        const id = this.id;
        const returnHTML: any = [];
        const userData = this.config.userData || [];
        const noneOptionChecked =
          (this.config.noneOptionChecked || false) == true;
        if (!userData.length) {
          // init
          userData.push({
            medicalId: 0,
            medicationName: "",
            // medicationRoute: "",
            // frequency: "",
            // notes: "",
            medicationStrength: "",
            dispenseMethod: "",
            dosages: "",
            dosageOther: "",
            prescribingDoctor: "",
            specialInstructions: "",
            attachPrescription: ""
          });
        }
        for (const data of userData) {
          // saved data
          const medicalId = data.medicalId || 0;
          const medicationNameVal = data.medicationName || "";
          // const medicationRouteVal = data.medicationRoute || "";
          // const frequencyVal = data.frequency || "";
          // const notesVal = data.notes || "";
          const strengthVal = data.medicationStrength || "";
          const dispenseMethodVal = data.dispenseMethod || "";
          const dosagesVal = data.dosages || "";
          const dosageOtherVal = data.dosageOther || "";
          const specialInstructionsval = data.specialInstructions || "";
          const prescribingDoctorVal = data.prescribingDoctor || "";
          const prescriptionName = data.prescriptionName || "";
          const prescriptionVal = data.prescription || "";
          let files: any = [];
          if (prescriptionName != "" && prescriptionVal != "") {
            files = [
              {
                name: prescriptionName,
                path: prescriptionVal
              }
            ];
          }

          // ids
          const randomId = Math.floor(Math.random() * 10000000);
          const medicationName = `${id}-medication-name-${randomId}`;
          // const medicationRoute = `${id}-medication-route-${randomId}`;
          // const frequency = `${id}-frequency-${randomId}`;
          // const notes = `${id}-notes-${randomId}`;
          const strengthId = `${id}-medication-strength-${randomId}`;
          const dispenseMethodId = `${id}-medication-dispense-method-${randomId}`;
          const dosagesId = `${id}-medication-dosages-${randomId}`;
          const dosageOtherId = `${id}-medication-other-dosage-${randomId}`;
          const instructionsId = `${id}-medication-instructions-${randomId}`;
          const prescribingDoctorId = `${id}-prescribing-doctor-${randomId}`;
          const attachPrescriptionId = `${id}-attach-prescription-${randomId}`;

          const requiredAttr = this.required
            ? 'required="required" aria-required="true"'
            : "";
          const requireTag = this.required
            ? '<span class="formbuilder-required">*</span>'
            : "";
          const dispenseMethodOptions: any = ApiHelper.getDispenseMethods();
          const dispenseMethodOptionsHTML = [
            `<option value="">Select Dispense Method</option>`
          ];
          for (const i in dispenseMethodOptions) {
            dispenseMethodOptionsHTML.push(`
              <option value="${
                dispenseMethodOptions[i].id
              }" id="${dispenseMethodId}-${i}"
                ${
                  dispenseMethodOptions[i].id == dispenseMethodVal
                    ? "selected"
                    : ""
                }
              >
                ${dispenseMethodOptions[i].text}
              </option>
            `);
          }
          const dosagesOptions: any = ApiHelper.getMedicationDosages();
          const dosagesOptionsHTML: any = [];
          for (const i in dosagesOptions) {
            dosagesOptionsHTML.push(`
              <div class="formbuilder-checkbox-inline form-check">
                <input class="form-check-input" name="${dosagesId}[]" multiple-selections="true" id="${dosagesId}-${i}" 
                  value="${dosagesOptions[i].value}" 
                  type="checkbox" 
                  ${
                    dosagesVal.includes(`${dosagesOptions[i].value}`)
                      ? "checked"
                      : ""
                  } 
                />
                <label class="form-check-label" for="${dosagesId}-${i}">
                  ${dosagesOptions[i].title}
                </label>
              </div>
            `);
          }
          // const routeOptions = ApiHelper.getMedicationDosageOptions();
          // const routeOptionsHTML = [`<option value="">Select Route</option>`];
          // const frequencyOptions = ApiHelper.getMedicationFrequencyOptions();
          // const frequencyOptionsHTML = [
          //   `<option value="">Select Frequency</option>`
          // ];
          // for (const i in routeOptions) {
          //   routeOptionsHTML.push(`
          //   <option value="${routeOptions[i].text}"
          //     id="${medicationRoute}-${i}"
          //     ${routeOptions[i].text == medicationRouteVal ? "selected" : ""}
          //   >
          //     ${routeOptions[i].text}
          //   </option>
          // `);
          // }
          // for (const i in frequencyOptions) {
          //   frequencyOptionsHTML.push(`
          //   <option value="${frequencyOptions[i].text}"
          //     id="${frequency}-${i}"
          //     ${frequencyOptions[i].text == frequencyVal ? "selected" : ""}
          //   >
          //     ${frequencyOptions[i].text}
          //   </option>
          // `);
          // }

          // <div class="formbuilder-select ${formgroup} field-select-${medicationRoute} col-6">
          //   <label for="${medicationRoute}" class="formbuilder-select-label field-label">
          //     Medication Route${requireTag}
          //   </label>
          //   <select class="form-control" name="${medicationRoute}" id="${medicationRoute}" ${requiredAttr}>
          //     ${routeOptionsHTML.join("")}
          //   </select>
          // </div>
          // <div class="formbuilder-select ${formgroup} field-select-${frequency} col-6">
          //   <label for="${frequency}" class="formbuilder-select-label field-label">
          //     Frequency${requireTag}
          //   </label>
          //   <select class="form-control" name="${frequency}" id="${frequency}" ${requiredAttr}>
          //     ${frequencyOptionsHTML.join("")}
          //   </select>
          // </div>
          // <div class="formbuilder-text ${formgroup} field-text-${notes} col-6 medication-notes">
          //   <label for="${notes}" class="formbuilder-text-label field-label">Medication notes</label>
          //   <input type="text" value="${notesVal}" class="form-control" name="${notes}" inputmask="none" id="${notes}">
          // </div>

          const formgroup = $(".todo-edit-page").length ? "" : "form-group";
          const dosageOtherHide = (dosagesVal || []).includes("6")
            ? ""
            : "d-none";
          returnHTML.push(`
            <div class="medication-info row" data-medical-id="${medicalId}">
              <span class="remove-medication-btn" title="Remove">x</span>
              <div class="formbuilder-text ${formgroup} field-text-${medicationName} col-4">
                <label for="${medicationName}" class="formbuilder-text-label field-label">Medication name${requireTag}</label>
                <input type="text" value="${medicationNameVal}" class="form-control" name="${medicationName}" inputmask="none" id="${medicationName}" ${requiredAttr} maxlength="150">
              </div>
              <div class="formbuilder-text ${formgroup} field-text-${strengthId} col-4">
                <label for="${strengthId}" class="formbuilder-text-label field-label">Strength</label>
                <input type="text" value="${strengthVal}" class="form-control" name="${strengthId}" inputmask="none" id="${strengthId}" maxlength="50">
              </div>
              <div class="formbuilder-select ${formgroup} field-select-${dispenseMethodId} col-4">
                <label for="${dispenseMethodId}" class="formbuilder-select-label field-label">
                  Dispense Method${requireTag}
                </label>
                <select class="form-control input__full" name="${dispenseMethodId}" id="${dispenseMethodId}" ${requiredAttr}>
                  ${dispenseMethodOptionsHTML.join("")}
                </select>
              </div>
              <div class="formbuilder-checkbox-group ${formgroup} field-checkbox-group-${dosagesId} col-8 medication-dosages-cb">
                <label for="${dosagesId}" class="formbuilder-select-label field-label">
                  Dosages${requireTag}
                </label>
                <div class="checkbox-group">
                  ${dosagesOptionsHTML.join("")}
                </div>
              
                <div class="formbuilder-text form-group field-text-${dosageOtherId} col-4 ${dosageOtherHide}">
                <label class="formbuilder-text-label field-label"></label>
                <input type="text" value="${dosageOtherVal}" placeholder="Other" class="form-control" name="${dosageOtherId}" id="${dosageOtherId}" maxlength="50" ${requiredAttr} />
              </div>

              </div>
              
              <div class="formbuilder-text ${formgroup} field-text-${prescribingDoctorId} col-12 medication-prescribing-doctor">
                <label for="${prescribingDoctorId}" class="formbuilder-text-label field-label">Prescribing Doctor ${requireTag}</label>
                <input type="text" value="${prescribingDoctorVal}" class="form-control" name="${prescribingDoctorId}" id="${prescribingDoctorId}" ${requiredAttr}>
              </div>
              <div class="formbuilder-text ${formgroup} field-text-${instructionsId} col-12 medication-instructions">
                <label for="${instructionsId}" class="formbuilder-text-label field-label">Special Instructions</label>
                <textarea class="form-control" name="${instructionsId}" id="${instructionsId}">${specialInstructionsval}</textarea>
              </div>
              <div class="formbuilder-text ${formgroup} field-text-${attachPrescriptionId} col-6 medication-attach-prescription">
                <label>
                  <input type="file" class="form-control d-none" name="${attachPrescriptionId}" id="${attachPrescriptionId}" multiple="false" 
                    files='${JSON.stringify(files)}' 
                  />
                  <span>Attach Prescription</span>
                </label>
              </div>
            </div>
          `);
        }
        return `
          <div class="checkbox-group">
            <div class="formbuilder-checkbox">
              <input name="${id}-none-option" id="${id}-none-option" type="checkbox" 
                class="medication-none-option" ${
                  noneOptionChecked ? "checked" : ""
                }>
              <label for="${id}-none-option">N/A</label>
            </div>
          </div>
          ${returnHTML.join(`<hr class="medication-spliter" />`)}
          <div class="medication-addmore"><span class="addmore-btn">More Medication</span></div>
        `;
      }
      onRender() {
        const field = $(`.field-${this.id}`);
        const noneOptionChecked =
          (this.config.noneOptionChecked || false) == true;
        if (noneOptionChecked) {
          field.addClass("none-medication");
        }

        // exit if on todo edit page
        if ($(".todo-edit-page").length) return;

        $(document)
          .off("click", ".addmore-btn")
          .on("click", ".addmore-btn", e => {
            if ($(".todo-edit-page").length) return;

            const button = $(e.target);
            const fieldMedication = button.closest(".formbuilder-medication");

            const randomId = Math.floor(Math.random() * 10000000);
            const sectionId = `medication-info-${randomId}`;
            const medicationSection = fieldMedication
              .find(".medication-info")
              .last();

            // just allow add more if filled for last medication
            const lastMedicationName =
              medicationSection
                .find(`input[type=text][id*="medication-name"]`)
                .val() || "";
            // const lastMedicationRoute =
            //   medicationSection.find(`select[id*="medication-route"]`).val() ||
            //   "";
            // const lastFrequency =
            //   medicationSection.find(`select[id*="frequency"]`).val() || "";
            const lastDispenseMethod =
              medicationSection
                .find(`select[id*="medication-dispense-method"]`)
                .val() || "";
            const lastDosages = medicationSection
              .find(`.medication-dosages-cb input[type=checkbox]:checked`)
              .map((i, e) => $(e).val())
              .toArray();
            const lastPrescribingDoctor =
              medicationSection
                .find(`input[type=text][id*="prescribing-doctor"]`)
                .val() || "";
            if (
              `${lastMedicationName}`.trim() == "" ||
              // lastMedicationRoute == "" ||
              // lastFrequency == "" ||
              lastDispenseMethod == "" ||
              !lastDosages.length ||
              `${lastPrescribingDoctor}`.trim() == ""
            ) {
              return;
            }

            const newSection = medicationSection.clone();
            newSection.removeAttr("data-medical-id").addClass(sectionId);
            medicationSection.after(`
              <hr class="medication-spliter" />
              ${newSection.prop("outerHTML")}
            `);
            // new ids/val for new section
            const medicationName = `${this.id}-medication-name-${randomId}`;
            // const medicationRoute = `${this.id}-medication-route-${randomId}`;
            // const frequency = `${this.id}-frequency-${randomId}`;
            // const notes = `${this.id}-notes-${randomId}`;
            const strengthId = `${this.id}-medication-strength-${randomId}`;
            const dispenseMethodId = `${this.id}-medication-dispense-method-${randomId}`;
            const dosagesId = `${this.id}-medication-dosages-${randomId}`;
            const instructionsId = `${this.id}-medication-instructions-${randomId}`;
            const prescribingDoctorId = `${this.id}-prescribing-doctor-${randomId}`;
            const attachPrescriptionId = `${this.id}-attach-prescription-${randomId}`;

            $(`.${sectionId} > div[class*="formbuilder-"]`).each((i, obj) => {
              const objClass = $(obj)?.attr("class") || "";
              // medication name
              if (objClass.indexOf("medication-name") != -1) {
                $(obj).attr(
                  "class",
                  `formbuilder-text form-group field-text-${medicationName} col-4`
                );
                $(obj)
                  .find("label")
                  .attr("for", medicationName);
                $(obj)
                  .find("input[type=text]")
                  .attr({
                    id: medicationName,
                    name: medicationName,
                    value: ""
                  });
              }

              // medication strength
              if (objClass.indexOf("medication-strength") != -1) {
                $(obj).attr(
                  "class",
                  `formbuilder-text form-group field-text-${strengthId} col-4`
                );
                $(obj)
                  .find("label")
                  .attr("for", strengthId);
                $(obj)
                  .find("input[type=text]")
                  .attr({
                    id: strengthId,
                    name: strengthId,
                    value: ""
                  });
              }

              // Dispense Method
              if (objClass.indexOf("medication-dispense-method") != -1) {
                $(obj).attr(
                  "class",
                  `formbuilder-select form-group field-select-${dispenseMethodId} col-4`
                );
                $(obj)
                  .find("label")
                  .attr("for", dispenseMethodId);
                $(obj)
                  .find("select")
                  .attr({
                    id: dispenseMethodId,
                    name: dispenseMethodId
                  })
                  .val("");
              }

              // dosages
              if (objClass.indexOf("medication-dosages") != -1) {
                $(obj).attr(
                  "class",
                  `formbuilder-checkbox-group form-group field-checkbox-group-${dosagesId} col-8 medication-dosages-cb`
                );
                $(obj)
                  .find("label")
                  .attr("for", dosagesId);
                $(obj)
                  .find(".form-check")
                  .each((i, e) => {
                    $(e)
                      .find("input.form-check-input")
                      .prop("checked", false)
                      .attr({
                        id: `${dosagesId}-${i}`,
                        name: `${dosagesId}[]`
                      });
                    $(e)
                      .find(".form-check-label")
                      .attr("for", `${dosagesId}-${i}`);
                  });
              }

              if (objClass.indexOf("medication-other-dosage") != -1) {
                $(obj)
                  .addClass("d-none")
                  .find("input[type=text]")
                  .val("");
              }

              // Special Instructions
              if (objClass.indexOf("medication-instructions") != -1) {
                $(obj).attr(
                  "class",
                  `formbuilder-text form-group field-text-${instructionsId} col-12 medication-instructions`
                );
                $(obj)
                  .find("label")
                  .attr("for", instructionsId);
                $(obj)
                  .find("textarea")
                  .attr({
                    id: instructionsId,
                    name: instructionsId
                  })
                  .val("");
              }

              // medication route
              // if (objClass.indexOf("medication-route") != -1) {
              //   $(obj).attr(
              //     "class",
              //     `formbuilder-select form-group field-select-${medicationRoute} col-6`
              //   );
              //   $(obj)
              //     .find("label")
              //     .attr("for", medicationRoute);
              //   $(obj)
              //     .find("select")
              //     .attr({
              //       id: medicationRoute,
              //       name: medicationRoute
              //     })
              //     .val("");
              // }

              // frequency
              // if (objClass.indexOf("frequency") != -1) {
              //   $(obj).attr(
              //     "class",
              //     `formbuilder-select form-group field-select-${frequency} col-6`
              //   );
              //   $(obj)
              //     .find("label")
              //     .attr("for", frequency);
              //   $(obj)
              //     .find("select")
              //     .attr({
              //       id: frequency,
              //       name: frequency
              //     })
              //     .val("");
              // }

              // medication notes
              // if (objClass.indexOf("-notes") != -1) {
              //   $(obj).attr(
              //     "class",
              //     `formbuilder-text form-group field-text-${notes} col-6 medication-notes`
              //   );
              //   $(obj)
              //     .find("label")
              //     .attr("for", notes);
              //   $(obj)
              //     .find("input[type=text]")
              //     .attr({
              //       id: notes,
              //       name: notes,
              //       value: ""
              //     });
              // }

              // prescribing-doctor
              if (objClass.indexOf("-prescribing-doctor") != -1) {
                $(obj).attr(
                  "class",
                  `formbuilder-text form-group field-text-${prescribingDoctorId} col-12 medication-prescribing-doctor`
                );
                $(obj)
                  .find("label")
                  .attr("for", prescribingDoctorId);
                $(obj)
                  .find("input[type=text]")
                  .attr({
                    id: prescribingDoctorId,
                    name: prescribingDoctorId,
                    value: ""
                  });
              }

              if (objClass.indexOf("-attach-prescription") != -1) {
                $(obj).attr(
                  "class",
                  `formbuilder-text form-group field-text-${attachPrescriptionId} col-6 medication-attach-prescription`
                );
                $(obj)
                  .find(".file-preview")
                  .remove();
                $(obj)
                  .find("input[type=file]")
                  .removeAttr("file-path")
                  .removeAttr("file-name")
                  .attr({
                    id: attachPrescriptionId,
                    name: attachPrescriptionId,
                    value: undefined,
                    files: "[]"
                  });
              }
            });
            $(
              `.${sectionId} .text-danger, .${sectionId} .border-danger`
            ).removeClass("text-danger border-danger");
          });

        // has or has not medication information
        field
          .find(".medication-none-option")
          .off("change")
          .on("change", e => {
            if ($(".todo-edit-page").length) return;

            const obj = $(e.target);
            if (obj.prop("checked") == true) {
              field.addClass("none-medication");
            } else {
              field.removeClass("none-medication");
            }
          });

        $(document)
          .off("click", ".remove-medication-btn")
          .on("click", ".remove-medication-btn", e => {
            if ($(".todo-edit-page").length) return;

            const btn = $(e.target);
            const spliter = btn.closest(".medication-info").prev();
            if (spliter.hasClass("medication-spliter")) {
              spliter.remove();
            }
            btn.closest(".medication-info").remove();
          });

        $(document)
          .off("change", "input.form-check-input[name*='medication-dosages']")
          .on(
            "change",
            "input.form-check-input[name*='medication-dosages']",
            obj => {
              if ($(".todo-edit-page").length) return;

              const cb = $(obj.target);
              const medicationInfo = cb.closest(".medication-info");
              const groupCb = cb.closest(".medication-dosages-cb");
              const dosageOther = medicationInfo.find(
                ".formbuilder-text[class*='medication-other-dosage']"
              );
              const dosagesVal = groupCb
                .find(`input[type=checkbox]:checked`)
                .map((i, e) => $(e).val())
                .toArray();
              if (dosagesVal.includes("6")) {
                // show "other" option
                dosageOther.removeClass("d-none");
              } else {
                // hide "other" option
                dosageOther.addClass("d-none");
              }
            }
          );
      }
    }

    // register this control
    controlClass.register("medication", MedicationControl);
    return MedicationControl;
  });
};

export const registerTransportation = () => {
  window.fbControls.push((controlClass: any) => {
    class TransportationControl extends controlClass {
      build() {
        const id = this.id;
        const userData = this.config.userData || {
          arriving: {
            value: "",
            whoDrivingYou: "",
            airlines: "",
            flightNumber: "",
            dateTime: "",
            other: ""
          },
          departing: {
            value: "",
            whoDrivingYou: "",
            airlines: "",
            flightNumber: "",
            dateTime: "",
            other: ""
          }
        };
        const arriving = userData.arriving;
        const departing = userData.departing;
        const randomId = Math.floor(Math.random() * 10000000);
        const textCarId = `text-${id}-car-selected`;
        const arrivingTextAirplaneId = `arriving-text-${id}-airplane-selected`;
        const departingTextAirplaneId = `departing-text-${id}-airplane-selected`;
        const arrivalRadioName = `${id}-${randomId}`;
        const departureRadioName = `${id}-${randomId}-1`;
        const requiredAttr = this.required
          ? 'required="required" aria-required="true"'
          : "";
        const requireTag = this.required
          ? '<span class="formbuilder-required">*</span>'
          : "";

        return `
          <div class="formbuilder-radio-group form-group arrival-section trans-info-section">
            <label class="field-label">
              ${this.config.arrivingLabel || ""}
            </label>
            <div class="radio-group">
              <div class="formbuilder-radio">
                <input name="${arrivalRadioName}" id="${arrivalRadioName}-0" value="Car" 
                  ${arriving.value == "Car" ? "checked='checked'" : ""} 
                  type="radio" ${requiredAttr}>
                <label for="${arrivalRadioName}-0">Car</label>
              </div>
              <div class="formbuilder-radio">
                <input name="${arrivalRadioName}" class="" id="${arrivalRadioName}-1" value="Train" 
                  ${arriving.value == "Train" ? "checked='checked'" : ""} 
                  type="radio" ${requiredAttr}>
                <label for="${arrivalRadioName}-1">Train</label>
              </div>
              <div class="formbuilder-radio">
                <input name="${arrivalRadioName}" class="" id="${arrivalRadioName}-2" value="Airplane" 
                  ${arriving.value == "Airplane" ? "checked='checked'" : ""} 
                  type="radio" ${requiredAttr}>
                <label for="${arrivalRadioName}-2">Airplane</label>
              </div>
              <div class="formbuilder-radio">
                <input name="${arrivalRadioName}" class="" id="${arrivalRadioName}-3" value="Other" 
                  ${arriving.value == "Other" ? "checked='checked'" : ""} 
                  type="radio" ${requiredAttr}>
                <label for="${arrivalRadioName}-3" class="mb-0">
                  <input type="text" placeholder="Other" class="form-control transportation-other" 
                    value="${arriving.other || ""}" />
                </label>
              </div>
            </div>
            <div class="formbuilder-text form-group field-text-${id}-car-selected text-car-selected 
              ${arriving.value == "Car" ? "" : "d-none"}">
              <label for="${textCarId}" class="formbuilder-text-label field-label">
                <span>Please indicate who will be driving you</span>
                ${requireTag}
              </label>
              <input type="text" class="form-control" name="${textCarId}" id="${textCarId}" 
                value="${arriving.whoDrivingYou || ""}" ${requiredAttr}>
            </div>
            <div class="row text-airplane-selected 
              ${arriving.value == "Airplane" ? "" : "d-none"}">
              <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                <label for="${arrivingTextAirplaneId}-airlines" class="formbuilder-text-label field-label">
                  <span>Please enter your airlines</span>
                  ${requireTag}
                </label>
                <input type="text" class="form-control" name="${arrivingTextAirplaneId}-airlines" id="${arrivingTextAirplaneId}-airlines" 
                  value="${arriving.airlines || ""}" ${requiredAttr}>
              </div>
              <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected"> 
                <label for="${arrivingTextAirplaneId}-flightNumber" class="formbuilder-text-label field-label">
                  <span>Your flight number</span>
                  ${requireTag}
                </label>
                <input type="text" class="form-control" name="${arrivingTextAirplaneId}-flightNumber" id="${arrivingTextAirplaneId}-flightNumber" 
                  value="${arriving.flightNumber || ""}" ${requiredAttr}>
              </div>
              <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                <label for="${arrivingTextAirplaneId}-dateTime" class="formbuilder-text-label field-label">
                  <span>Date and time of arrival</span>
                  ${requireTag}
                </label>
                <input type="text" class="form-control" name="${arrivingTextAirplaneId}-dateTime" id="${arrivingTextAirplaneId}-dateTime" 
                  value="${arriving.dateTime || ""}" ${requiredAttr}>
              </div>
            </div>
          </div>

          <div class="formbuilder-radio-group form-group departure-section trans-info-section">
            <label class="field-label">
              ${this.config.departingLabel || ""}
            </label>
            <div class="radio-group">
              <div class="formbuilder-radio">
                <input name="${departureRadioName}" id="${departureRadioName}-0" value="Car" 
                  ${departing.value == "Car" ? "checked='checked'" : ""} 
                  type="radio" ${requiredAttr}>
                <label for="${departureRadioName}-0">Car</label>
              </div>
              <div class="formbuilder-radio">
                <input name="${departureRadioName}" class="" id="${departureRadioName}-1" value="Train" 
                  ${departing.value == "Train" ? "checked='checked'" : ""} 
                  type="radio" ${requiredAttr}>
                <label for="${departureRadioName}-1">Train</label>
              </div>
              <div class="formbuilder-radio">
                <input name="${departureRadioName}" class="" id="${departureRadioName}-2" value="Airplane" 
                  ${departing.value == "Airplane" ? "checked='checked'" : ""} 
                  type="radio" ${requiredAttr}>
                <label for="${departureRadioName}-2">Airplane</label>
              </div>
              <div class="formbuilder-radio">
                <input name="${departureRadioName}" class="" id="${departureRadioName}-3" value="Other" 
                  ${departing.value == "Other" ? "checked='checked'" : ""} 
                  type="radio" ${requiredAttr}>
                <label for="${departureRadioName}-3" class="mb-0">
                  <input type="text" placeholder="Other" class="form-control transportation-other" 
                    value="${departing.other || ""}" />
                </label>
              </div>
            </div>
            <div class="formbuilder-text form-group field-text-${id}-car-selected text-car-selected 
              ${departing.value == "Car" ? "" : "d-none"}">
              <label for="${textCarId}" class="formbuilder-text-label field-label">
                <span>Please indicate who will be driving you</span>
                ${requireTag}
              </label>
              <input type="text" class="form-control" name="${textCarId}" id="${textCarId}" 
                value="${departing.whoDrivingYou || ""}"
                ${requiredAttr}>
            </div>
            <div class="row text-airplane-selected 
              ${departing.value == "Airplane" ? "" : "d-none"}">
              <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                <label for="${departingTextAirplaneId}-airlines" class="formbuilder-text-label field-label">
                  <span>Please enter your airlines</span>
                  ${requireTag}
                </label>
                <input type="text" class="form-control" name="${departingTextAirplaneId}-airlines" id="${departingTextAirplaneId}-airlines" 
                  value="${departing.airlines || ""}"
                  ${requiredAttr}>
              </div>
              <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                <label for="${departingTextAirplaneId}-flightNumber" class="formbuilder-text-label field-label">
                  <span>Your flight number</span>
                  ${requireTag}
                </label>
                <input type="text" class="form-control" name="${departingTextAirplaneId}-flightNumber" id="${departingTextAirplaneId}-flightNumber" 
                  value="${departing.flightNumber || ""}"
                  ${requiredAttr}>
              </div>
              <div class="col-4 formbuilder-text form-group field-text-${id}-airplane-selected">
                <label for="${departingTextAirplaneId}-dateTime" class="formbuilder-text-label field-label">
                  <span>Date and time of departure</span>
                  ${requireTag}
                </label>
                <input type="text" class="form-control" name="${departingTextAirplaneId}-dateTime" id="${departingTextAirplaneId}-dateTime" 
                  value="${departing.dateTime || ""}"
                  ${requiredAttr}>
              </div>
            </div>
          </div>
        `;
      }
      onRender() {
        $(document)
          .off("click", ".trans-info-section input[type=radio]")
          .on("click", ".trans-info-section input[type=radio]", e => {
            const radio = $(e.target);
            const infoSection = radio.closest(".trans-info-section");
            if (infoSection.closest(".todo-edit-page").length) {
              return;
            }
            const textCar = infoSection
              .find(".text-car-selected")
              .addClass("d-none");
            const textAirplane = infoSection
              .find(".text-airplane-selected")
              .addClass("d-none");
            if (radio.val() == "Car") {
              textCar.removeClass("d-none");
            } else if (radio.val() == "Airplane") {
              textAirplane.removeClass("d-none");
            }

            if (radio.val() != "Other") {
              infoSection
                .find(`input[type=text][class*="transportation-other"]`)
                .val("");
            }
          });

        $(document)
          .off("keyup", `.trans-info-section .transportation-other`)
          .on("keyup", `.trans-info-section .transportation-other`, e => {
            const txt: any = $(e.target);
            const infoSection = txt.closest(".trans-info-section");
            if ((txt?.val().trim() || "") != "") {
              infoSection.find("input[type=radio][value='Other']").click();
            }
          });
      }
    }

    // register this control
    controlClass.register("transportation", TransportationControl);
    return TransportationControl;
  });
};

export const registerAddressBlock = () => {
  window.fbControls.push((controlClass: any) => {
    class AddressBlockControl extends controlClass {
      build() {
        const id = this.id;
        const returnHTML: any = [];
        const userData = this.config.userData || [];

        if (!userData.length) {
          // init
          userData.push({
            lAddressInput: "",
            lAddressInput2: "",
            lCityInput: "",
            lStateInput: "",
            lZipInput: ""
          });
        }
        for (const data of userData) {
          // saved data
          const lAddressInputVal = data.lAddressInput || "";
          const lAddressInput2Val = data.lAddressInput2 || "";
          const lCityInputVal = data.lCityInput || "";
          const lStateInputVal = data.lStateInput || "";
          const lZipInputVal = data.lZipInput || "";

          // ids
          const randomId = Math.floor(Math.random() * 10000000);
          const lAddressInput = `${id}-address-block-address-${randomId}`;
          const lAddressInput2 = `${id}-address-block-address2-${randomId}`;
          const lCityInput = `${id}-address-block-city-${randomId}`;
          const lStateInput = `${id}-address-block-state-${randomId}`;
          const lZipInput = `${id}-address-block-zip-${randomId}`;

          const requiredAttr = this.required
            ? 'required="required" aria-required="true"'
            : "";
          const requireTag = this.required
            ? '<span class="formbuilder-required">*</span>'
            : "";

          const stateOptions = ApiHelper.getStateOptions();
          const stateOptionsHTML = [`<option value="">Select...</option>`];

          for (const i in stateOptions) {
            stateOptionsHTML.push(`
            <option value="${stateOptions[i].text}"
              id="${lStateInput}-${i}"
              ${stateOptions[i].text == lStateInputVal ? "selected" : ""}
            >
              ${stateOptions[i].text}
            </option>
          `);
          }
          const formgroup = $(".todo-edit-page").length ? "" : "form-group";
          returnHTML.push(`
            <div class="address-block-info row">
              <span class="remove-address-block-btn" title="Remove">x</span>
              <div class="formbuilder-text ${formgroup} field-text-${lAddressInput} col-6">
                <label for="${lAddressInput}" class="formbuilder-text-label field-label">Address 1${requireTag}</label>
                <input style="margin-left:-7px;" type="text" value="${lAddressInputVal}" class="form-control" name="${lAddressInput}" inputmask="none" id="${lAddressInput}" ${requiredAttr} maxlength="255">
              </div>
              <div class="formbuilder-text ${formgroup} field-text-${lAddressInput2} col-5">
                <label class="formbuilder-text-label">Address 2</label>
                <input type="text" value="${lAddressInput2Val}" class="form-control" name="${lAddressInput2}" inputmask="none" id="${lAddressInput2}" maxlength="255">
              </div>
              <div class="formbuilder-text ${formgroup} field-text-${lCityInput} col-4">
                <label for="${lCityInput}" class="formbuilder-text-label field-label">City${requireTag}</label>
                <input type="text" value="${lCityInputVal}" class="form-control" name="${lCityInput}" inputmask="none" id="${lCityInput}" ${requiredAttr} maxlength="255">
              </div>
              <div class="formbuilder-select ${formgroup} field-select-${lStateInput} col-4">
                <label for="${lStateInput}" class="formbuilder-select-label field-label">
                  State${requireTag}
                </label>
                <select class="form-control input__full" name="${lStateInput}" id="${lStateInput}" ${requiredAttr}>
                  ${stateOptionsHTML.join("")}
                </select>
              </div>
              <div class="formbuilder-text ${formgroup} field-text-${lZipInput} col-4">
                <label for="${lZipInput}" class="formbuilder-text-label field-label">Zip${requireTag}</label>
                <input type="text" value="${lZipInputVal}" class="form-control" id="${lZipInput}" ${requiredAttr} inputmask="zipnumber" maxlength="5">
              </div>
            </div>
          `);
        }
        return `
          <div class="checkbox-group">
             
          </div>
          ${returnHTML.join(`<hr class="address-block-spliter" />`)}
        `;
      }
      onRender() {
        $(`input[inputmask=zipnumber]`).each(function(i, obj) {
          Inputmask("99999", {
            autoUnmask: true
          }).mask(obj);
        });
      }
    }

    // register this control
    controlClass.register("addressBlock", AddressBlockControl);
    return AddressBlockControl;
  });
};
