import { ref } from "@vue/composition-api";
import { FormSelect, FormText, ProfileNewInput, SelectOption } from "@/types";

import { ApiHelper } from "@/helpers";
import $ from "jquery";
import moment from "moment";
import FormTags from "@/types/FormTags";
import Vue from "vue";
import { replaceRouter } from "@/helpers/ApiHelper";

export function useProfilesAddStore(context: any) {
  const isACILoading = ref<boolean>(false);
  const dataInput = ref<ProfileNewInput>({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    dob: undefined,
    registrationDate: undefined,
    phone: "",
    receiveMessage: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    photo: "",
    photoS3url: "",
    linkSubProfiles: [],
    tags: []
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      firstName: FormText;
      lastName: FormText;
      email: FormText;
      gender: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      dob: {
        label?: string;
        error: string;
        type: string;
        value: Date | undefined;
        disabledBefore: Date | undefined;
        disabledAfter: Date | undefined;
      };
      registrationDate: {
        label?: string;
        error: string;
        type: string;
        value: Date | undefined;
      };
      phone: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      receiveMessage: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      address: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      city: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      state: FormSelect;
      zipcode: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      photo: {
        label?: string;
        error: string;
        type: string;
        value: string;
        preview: string;
        s3url: string;
        base64: string;
      };
      createUser: {
        label?: string;
        error: string;
        type: string;
        value: string;
        yesValue: string;
        noValue: string;
        defaultValue: string;
      };
      password: {
        label?: string;
        placeholder?: string;
        style?: string;
        error: string;
        mode: string;
        type: string;
        value: string;
      };
      tribes: {
        label?: string;
        error: string;
        type: string;
        options: SelectOption[];
        value: string[];
      };
      tags: FormTags;
    };
    foundFamilyByAddress: boolean;
    foundFamily: any;
    confirmRelation: any;
    confirmRelationtypeID: number;
    confirmRelationtypeIDTmp: number;
    familyMembers: object[];
    foundFamilyMembers: object[];
    useSameEmail: {
      forceUsing: boolean;
      familyOnwerId: number;
      familyId: number;
    };
  }>({
    isLoading: false,
    controls: {
      firstName: {
        label: "First Name",
        required: true,
        placeholder: "Enter first name",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 150
      },
      lastName: {
        label: "Last Name",
        required: true,
        placeholder: "Enter last name",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 150
      },
      email: {
        label: "Email",
        required: true,
        placeholder: "Enter an email address",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 200
      },
      gender: {
        error: "",
        type: "radios",
        value: ""
      },
      dob: {
        label: "Birthdate",
        error: "",
        type: "date",
        value: undefined,
        disabledBefore: moment()
          .subtract(100, "years")
          .toDate(),
        disabledAfter: new Date()
      },
      registrationDate: {
        error: "",
        type: "date",
        value: undefined
      },
      phone: {
        error: "",
        type: "text",
        value: ""
      },
      receiveMessage: {
        error: "",
        type: "radios",
        value: "yes"
      },
      address: {
        error: "",
        type: "text",
        value: ""
      },
      city: {
        error: "",
        type: "text",
        value: ""
      },
      state: {
        error: "",
        value: "",
        label: "State",
        placeholder: "Select...",
        style: "custom",
        notRequired: true,
        options: ApiHelper.getStateOptions()
      },
      zipcode: {
        error: "",
        type: "text",
        value: ""
      },
      photo: {
        error: "",
        type: "text",
        value: "",
        preview: "",
        s3url: "",
        base64: ""
      },
      tribes: {
        error: "",
        type: "multiselect",
        options: [],
        value: []
      },
      createUser: {
        label: "Allow admin permissions?",
        error: "",
        type: "radios",
        value: "no",
        yesValue: "yes",
        noValue: "no",
        defaultValue: ""
      },
      password: {
        error: "",
        style: "custom",
        mode: "password",
        label: "Password",
        placeholder: "Password",
        value: "",
        type: "password"
      },
      tags: {
        error: "",
        label: "Tags",
        placeholder: "Enter a tag",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: []
      }
    },
    foundFamilyByAddress: false,
    foundFamily: {
      show: false
    },
    confirmRelation: {
      show: false
    },
    confirmRelationtypeID: 0,
    confirmRelationtypeIDTmp: 6,
    familyMembers: [],
    foundFamilyMembers: [],
    useSameEmail: {
      forceUsing: false,
      familyOnwerId: 0,
      familyId: 0
    }
  });
  const familyTypeOptions = ref<any[]>([]);

  // (async () => {
  //   formData.value.controls.tribes.options = await ApiHelper.getProfileOptions("");
  // })();

  const onSubmit = async (root: any) => {
    formData.value.controls.firstName.value = ApiHelper.stripTags(
      formData.value.controls.firstName.value
    );
    formData.value.controls.lastName.value = ApiHelper.stripTags(
      formData.value.controls.lastName.value
    );
    formData.value.controls.email.value = ApiHelper.stripTags(
      formData.value.controls.email.value
    );
    formData.value.controls.photo.value = ApiHelper.stripTags(
      formData.value.controls.photo.value
    );
    formData.value.controls.address.value = ApiHelper.stripTags(
      formData.value.controls.address.value
    );
    formData.value.controls.city.value = ApiHelper.stripTags(
      formData.value.controls.city.value
    );
    formData.value.controls.state.value = ApiHelper.stripTags(
      formData.value.controls.state.value
    );
    formData.value.controls.zipcode.value = ApiHelper.stripTags(
      formData.value.controls.zipcode.value
    );

    dataInput.value.firstName = formData.value.controls.firstName.value;
    dataInput.value.lastName = formData.value.controls.lastName.value;
    dataInput.value.email = formData.value.controls.email.value;
    dataInput.value.gender = formData.value.controls.gender.value;
    dataInput.value.dob = formData.value.controls.dob.value
      ? moment(
          moment(formData.value.controls.dob.value).format("YYYY-MM-DD") +
            "T00:00:00+0000"
        ).toDate()
      : undefined;
    dataInput.value.registrationDate =
      formData.value.controls.registrationDate.value;
    dataInput.value.phone = formData.value.controls.phone.value;
    dataInput.value.receiveMessage =
      formData.value.controls.receiveMessage.value;
    dataInput.value.address = formData.value.controls.address.value;
    dataInput.value.city = formData.value.controls.city.value;
    dataInput.value.state = formData.value.controls.state.value;
    dataInput.value.zipcode = formData.value.controls.zipcode.value;
    // dataInput.value.photo = formData.value.controls.photo.value;
    dataInput.value.photoS3url = formData.value.controls.photo.s3url;
    dataInput.value.linkSubProfiles.forEach(item => {
      item.isDelete = true;
    });
    dataInput.value.tags = formData.value.controls.tags.value.map(tag => {
      return {
        id: parseInt(tag.id),
        text: tag.text
      };
    });
    const selectedIds = dataInput.value.linkSubProfiles.map(item =>
      (item.profileId || 0).toString()
    );
    formData.value.controls.tribes.value.forEach(value => {
      if (!selectedIds.includes(value.toString())) {
        dataInput.value.linkSubProfiles.push({
          id: 0,
          profileId: parseInt(value),
          type: 1,
          isDelete: false
        });
      }
    });

    dataInput.value.linkSubProfiles.forEach(item => {
      if (
        formData.value.controls.tribes.value.includes(
          (item.profileId || 0).toString()
        )
      ) {
        item.isDelete = false;
      }
    });

    let hasError = false;
    formData.value.controls.tribes.error = "";
    if (dataInput.value.firstName === "") {
      hasError = true;
      formData.value.controls.firstName.error = "First Name is required!";
    }
    if (dataInput.value.lastName === "") {
      hasError = true;
      formData.value.controls.lastName.error = "Last Name is required!";
    }
    if (dataInput.value.email === "") {
      hasError = true;
      formData.value.controls.email.error = "Email is required!";
    } else {
      if (!ApiHelper.validateEmail(dataInput.value.email)) {
        hasError = true;
        formData.value.controls.email.error = "Email is invalid!";
      }
    }
    if (formData.value.controls.phone.value) {
      const phone: any = $("input[inputmask=phonenumber]");
      if (
        phone.length &&
        phone.get(0).inputmask &&
        phone.get(0).inputmask.isComplete() == false
      ) {
        hasError = true;
        formData.value.controls.phone.error = "Phone is invalid";
      }
    }
    if (dataInput.value.dob !== undefined && dataInput.value.dob) {
      const nowDate = new Date();
      if (dataInput.value.dob.getTime() > nowDate.getTime()) {
        hasError = true;
        formData.value.controls.dob.error = "DOB is invalid!";
      }
    }

    // should select relation type for all link
    formData.value.familyMembers.map((item: any) => {
      if (item.relationTypeID == 0) {
        hasError = true;
        formData.value.controls.tribes.error =
          "Please select all relation types that this new profile is linked to above family members";
      }
      return item;
    });

    if (formData.value.controls.createUser.value === "yes") {
      /* if (formData.value.controls.email.value.trim() === '') {
        hasError = true;
        formData.value.controls.email.error = 'Email is required'
      } */
      if (formData.value.controls.password.value.trim() === "") {
        hasError = true;
        formData.value.controls.password.error = "Password is required";
      }
    }

    if (!hasError) {
      formData.value.isLoading = true;

      let familyID = 0;
      // collect data for dataInput.value.linkSubProfiles
      dataInput.value.linkSubProfiles = formData.value.familyMembers.map(
        (item: any) => {
          // Auto associate particpant family based on first family member added
          const tmpFamilyID = item.familyID || 0;
          // if (tmpFamilyID > familyID) {
          if (familyID == 0) {
            familyID = tmpFamilyID;
          }
          return {
            id: 0,
            profileId: parseInt(item.id),
            type: parseInt(item.relationTypeID)
          };
        }
      );

      // if force use same email in same family
      // try to link this profile to family owner has members using this email
      if (
        formData.value.useSameEmail.forceUsing &&
        formData.value.useSameEmail.familyId > 0 &&
        formData.value.useSameEmail.familyOnwerId > 0
      ) {
        if (familyID == 0 || familyID == formData.value.useSameEmail.familyId) {
          const inLink = dataInput.value.linkSubProfiles.find(
            item => item.profileId == formData.value.useSameEmail.familyOnwerId
          );
          if (!inLink) {
            dataInput.value.linkSubProfiles.push({
              id: 0,
              profileId: formData.value.useSameEmail.familyOnwerId,
              type: 6
            });
            familyID = formData.value.useSameEmail.familyId;
          }
        }
      }

      const requestObj = {
        firstName: dataInput.value.firstName,
        lastName: dataInput.value.lastName,
        email: dataInput.value.email,
        phone: dataInput.value.phone,
        receiveMessage: dataInput.value.receiveMessage === "yes" ? 1 : 0,
        gender: parseInt(dataInput.value.gender),
        dob: dataInput.value.dob || undefined,
        registrationDate: dataInput.value.registrationDate || undefined,
        address: dataInput.value.address,
        city: dataInput.value.city,
        state: dataInput.value.state,
        zip: dataInput.value.zipcode,
        // logoBase64: dataInput.value.photo,
        photoS3url: dataInput.value.photoS3url,
        entityId: 1,
        linkSubProfiles: dataInput.value.linkSubProfiles,
        linkToProfileID: parseInt(formData.value.foundFamily.id || 0),
        linkToRelationtypeID: formData.value.confirmRelationtypeID,
        familyID: familyID,
        tags: dataInput.value.tags,
        type: formData.value.controls.createUser.value === "yes" ? 1 : 2,
        password:
          formData.value.controls.createUser.value === "yes"
            ? formData.value.controls.password.value
            : undefined,
        forceUseSameEmail: formData.value.useSameEmail.forceUsing
      };

      const result = await ApiHelper.callApi("post", "/profiles", requestObj);
      const resultMessage = result.message || "";
      formData.value.isLoading = false;
      if (result.status == 1) {
        const newProfileId = result.data.profileId || 0;
        const isActive = result.data.isActive || 0; // only re-enable profile
        if (isActive) {
          ApiHelper.showSuccessMessage(
            resultMessage || "Re-enabled Profile #" + newProfileId
          );
        } else {
          // Show promt remind reset password
          if (familyID > 0) {
            ApiHelper.showSuccessMessage("Created new person #" + newProfileId);
            ApiHelper.replaceRouter(context, {
              name: "Profiles",
              params: {},
              query: {}
            });
            // update userStats
            const children = root.$children || [];
            const layout = children.length > 0 ? children[0] : undefined;
            if (layout && layout.$children.length > 0) {
              // layout.$children[0].totalPeople = layout.$children[0].totalPeople + 1;
              layout.$children[0].loadData();
            }
          } else {
            ApiHelper.replaceRouter(context, {
              name: "ProfileSettings",
              params: {
                profileId: `${newProfileId}`
              },
              query: {
                init: "1"
              }
            });
            ApiHelper.showSuccessMessage(
              "Please create or send an auto generated password to grant this person access to their participant portal."
            );
          }
        }
      } else {
        const errorCode = result.errorCode || "";

        // reset
        formData.value.useSameEmail.forceUsing = false;
        formData.value.useSameEmail.familyOnwerId = 0;
        formData.value.useSameEmail.familyId = 0;
        switch (errorCode) {
          case "email_existed":
            {
              const returnedFamilyId = result.data?.familyId || 0;

              if (
                requestObj.familyID > 0 &&
                returnedFamilyId > 0 &&
                requestObj.familyID != returnedFamilyId
              ) {
                // show error because user linked to a family, and is trying to use existed email in system not in this family
                ApiHelper.showErrorMessage("Email already existed.");
              } else {
                // confirm if admin wants to use same email for profiles in same family
                const confirm = await context.root.$swal({
                  html: result.message,
                  icon: "error",
                  title: "Oops",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, do it!"
                });
                if (confirm.isConfirmed) {
                  // recall save function
                  formData.value.useSameEmail.forceUsing = true;
                  formData.value.useSameEmail.familyOnwerId =
                    result.data?.familyOnwerId || 0;
                  formData.value.useSameEmail.familyId = returnedFamilyId;
                  await onSubmit(root);
                }
              }
            }
            break;
          case "email_archived":
            {
              // confirm if admin wants to use same email for profiles in same family
              const confirm = await context.root.$swal({
                html: result.message,
                icon: "error",
                title: "Oops",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, do it!"
              });
              if (confirm.isConfirmed) {
                // recall save function
                formData.value.useSameEmail.forceUsing = true;
                await onSubmit(root);
              }
            }
            break;
          default:
            ApiHelper.showErrorMessage(result.message);
            break;
        }
      }
    }
  };

  const removeFieldError = (name: string) => {
    switch (name) {
      case "firstName":
        formData.value.controls.firstName.error = "";
        break;
      case "lastName":
        formData.value.controls.lastName.error = "";
        break;
      case "email":
        formData.value.controls.email.error = "";
        break;
      case "gender":
        formData.value.controls.gender.error = "";
        break;
      case "dob":
        formData.value.controls.dob.error = "";
        break;
      case "registrationDate":
        formData.value.controls.registrationDate.error = "";
        break;
      case "phone":
        formData.value.controls.phone.error = "";
        break;
      case "address":
        formData.value.controls.address.error = "";
        break;
      case "city":
        formData.value.controls.city.error = "";
        break;
      case "state":
        formData.value.controls.state.error = "";
        break;
      case "zipcode":
        formData.value.controls.zipcode.error = "";
        break;
      case "photo":
        formData.value.controls.photo.error = "";
        break;
      case "tribes":
        formData.value.controls.tribes.error = "";
        break;
    }
  };

  const onChangeFile = async (base64: string) => {
    formData.value.controls.photo.value = base64;
    formData.value.controls.photo.preview = base64;
  };

  const onResetFile = async (base64: string) => {
    formData.value.controls.photo.preview = ""; // formData.value.controls.photo.value;
    formData.value.controls.photo.value = "";
  };

  const closeFamilyFound = (e?: Event | undefined) => {
    formData.value.foundFamilyByAddress = false;
    // formData.value.foundFamily = {};
    formData.value.foundFamily.show = false;
    formData.value.confirmRelation.show = false;
    if (e && e.type == "click") {
      $("#address").focus();
    }
  };

  const updateFamilyMembers = (selected: any, type = "add") => {
    if (type == "remove") {
      context.root.$delete(
        formData.value.familyMembers,
        formData.value.familyMembers.findIndex(
          (item: any) => item.id == selected.id
        )
      );
    } else {
      const selectedFamilyMembers = JSON.parse(
        selected.familyMembersJSON || "[]"
      );
      if (selectedFamilyMembers.length) {
        // selected profile had a family, try to link with other members in this family
        for (const item of selectedFamilyMembers) {
          // make sure no select twice a profile
          const currentSelectedMembers = formData.value.familyMembers.map(
            (item: any) => item.id
          );
          if (!currentSelectedMembers.includes(item.profileid)) {
            formData.value.familyMembers.push({
              id: item.profileid || 0,
              firstName: item.p_fname || "",
              lastName: item.p_lname || "",
              relationTypeID: 0,
              familyID: item.familyID || 0
            });
          }
        }
      } else {
        formData.value.familyMembers.push({
          id: selected.id || 0,
          firstName: selected.firstName || "",
          lastName: selected.lastName || "",
          relationTypeID: selected.relationTypeID || 0,
          familyID: selected.familyID || 0
        });
      }
    }
  };

  const getSuggestedMemberText = (item: any) => {
    const tmp = [item.firstName, item.lastName];
    if (item.age) {
      tmp.push(`(${item.age})`);
    }
    let ret = tmp.join(" ");
    if (item.gender == 1) {
      ret += ", Male";
    } else if (item.gender == 2) {
      ret += ", Female";
    }
    ret += "   ";
    if (item.city) {
      ret += ` ${item.city}`;
    }
    if (item.state) {
      ret += ` ${item.state}`;
    }
    if (item.zip) {
      ret += ` ${item.zip}`;
    }

    return ret;
  };

  const suggestFamilyMember = async (key: string) => {
    formData.value.foundFamilyMembers = [];
    formData.value.familyMembers = [];
    isACILoading.value = true;
    const result = await ApiHelper.callApi(
      "get",
      "/families/members/search",
      {},
      {
        key: key,
        selectedIDs: formData.value.familyMembers
          .map((item: any) => item.id)
          .join(","),
        getInfo: "IncludeFamilyMembers"
      }
    );
    if (result.status === 1) {
      formData.value.foundFamilyMembers = result.data.families.map(
        (item: any) => ({
          id: item.id,
          text: getSuggestedMemberText(item),
          data: item
        })
      );
    }
    isACILoading.value = false;
  };

  const timer = ref<any>(null);
  const suggestFamily = async (by: string) => {
    clearTimeout(timer.value);
    timer.value = setTimeout(async () => {
      // should inputted a profile name fistly
      const firstName = formData.value.controls.firstName.value;
      const lastName = formData.value.controls.lastName.value;
      if (firstName == "" || formData.value.controls.address.value == "") {
        closeFamilyFound();
        return;
      }

      const address =
        by == "by-address" ? formData.value.controls.address.value : "";
      const key = by == "by-key" ? "" : "";

      const result = await ApiHelper.callApi(
        "get",
        "/families/find",
        {},
        {
          address: address,
          key: key
        }
      );
      if (result.status === 1) {
        const data = result.data.profile || undefined;
        if (data) {
          formData.value.foundFamilyByAddress = true;
          formData.value.foundFamily = data;
          formData.value.foundFamily.show = true;
        } else {
          closeFamilyFound();
        }
      }
    }, 300);
  };

  const showConfirmRelation = () => {
    formData.value.foundFamily.show = false;
    formData.value.confirmRelation.show = true;
  };

  const closeAllFamilyConfirm = () => {
    formData.value.foundFamily.show = false;
    formData.value.confirmRelation.show = false;
  };

  const confirmRelationtype = () => {
    formData.value.confirmRelationtypeID = parseInt(
      formData.value.confirmRelationtypeIDTmp.toString()
    );
    closeFamilyFound();
    $("#address").focus();
    updateFamilyMembers({
      ...formData.value.foundFamily,
      relationTypeID: formData.value.confirmRelationtypeID
    });
  };

  // init data
  (async () => {
    familyTypeOptions.value = await ApiHelper.getFamilyTypeOptions();
  })();

  const changeProfileAvatar = async (s3url: string) => {
    formData.value.controls.photo.value = s3url;
    formData.value.controls.photo.preview = s3url;
    formData.value.controls.photo.s3url = s3url;
  };

  const onSuggestTags = async (key: string) => {
    const notInIds = formData.value.controls.tags.value
      .map(item => item.id)
      .join(",");
    const result = await ApiHelper.callApi(
      "get",
      "/tags/search",
      {},
      {
        key: key,
        notInIds: notInIds,
        typeUse: "profile"
      }
    );
    if (result?.status === 1) {
      formData.value.controls.tags.suggestTags = result.data.tags.map(
        (item: any) => {
          return {
            id: item.id,
            text: item.text,
            data: item
          };
        }
      );
    }
  };

  const onAddTag = () => {
    const key = formData.value.controls.tags.key.trim();
    if (key) {
      formData.value.controls.tags.value.push({
        id: 0,
        text: key,
        data: {}
      });
      formData.value.controls.tags.key = "";
    }
  };

  const onRemoveLastTag = (event: any) => {
    if (
      formData.value.controls.tags.value.length > 0 &&
      formData.value.controls.tags.key === ""
    ) {
      const index = formData.value.controls.tags.value.length - 1;
      formData.value.controls.tags.value.splice(index, 1);
    }
  };
  const onRemoveTag = (index: number) => {
    if (formData.value.controls.tags.value.length > index) {
      formData.value.controls.tags.value.splice(index, 1);
    }
  };

  const onSelectTag = (item: any) => {
    formData.value.controls.tags.key = "";
    formData.value.controls.tags.suggestTags = [];
    formData.value.controls.tags.value.push({
      id: item.id,
      text: item.text,
      data: item.data || {}
    });
  };

  return {
    onSuggestTags,
    onAddTag,
    onRemoveTag,
    onSelectTag,
    onRemoveLastTag,
    changeProfileAvatar,
    formData,
    removeFieldError,
    onResetFile,
    onChangeFile,
    onSubmit,
    suggestFamily,
    closeFamilyFound,
    showConfirmRelation,
    closeAllFamilyConfirm,
    confirmRelationtype,
    updateFamilyMembers,
    suggestFamilyMember,
    familyTypeOptions,
    isACILoading
  };
}
