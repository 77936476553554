import { ApiHelper } from "@/helpers";
import { FormSelect, FormText } from "@/types";
import StoreTerminalInput from "@/types/StoreTerminalInput";
import { ref } from "@vue/composition-api";

export function useVposTerminalAddStore(context: any) {
  const isACILoading = ref<boolean>(false);
  const selectedUserId = parseInt(context.root.$route.params.id);
  const dataInput = ref<StoreTerminalInput>({
    terminalName: "",
    inventoryLocation: 1,
    status: 1
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      terminalName: FormText;
      status: FormSelect;
      inventoryLocation: FormSelect;
    };
  }>({
    isLoading: false,
    controls: {
      terminalName: {
        label: "Terminal Name",
        required: true,
        placeholder: "Enter Terminal Name",
        error: "",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 150
      },
      inventoryLocation: {
        label: "Inventory Location",
        required: true,
        placeholder: "Inventory Location",
        error: "",
        value: "1",
        style: "custom",
        options: [
          {
            id: 1,
            text: "Yes"
          },
          {
            id: 0,
            text: "No"
          }
        ]
      },
      status: {
        label: "Status",
        required: true,
        placeholder: "Select Status",
        error: "",
        value: "1",
        style: "custom",
        options: [
          {
            id: 1,
            text: "ACTIVE"
          },
          {
            id: 2,
            text: "INACTIVE"
          }
        ]
      }
    }
  });

  const initData = async () => {
    if (!isNaN(selectedUserId)) {
      const result = await ApiHelper.callApi(
        "get",
        `/store/terminal/${selectedUserId}`,
        {},
        {}
      );
      // if (result.status === 1) {
      formData.value.controls.terminalName.value = result.data.terminalName;
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
      formData.value.controls.inventoryLocation.value = result.data
        ?.inventoryLocation
        ? 1
        : 0;
      formData.value.controls.status.value = result.data?.status;
      // }
    }
  };

  const getPageTitle = () => {
    return isNaN(selectedUserId) ? "ADD" : "EDIT";
  };

  const validateData = () => {
    let hasError = false;
    if (formData.value.controls.terminalName.value === "") {
      formData.value.controls.terminalName.error = "Terminal Name Required";
      hasError = true;
    }
    // eslint-disable-next-line no-constant-condition
    if (formData.value.controls.status.value === "") {
      formData.value.controls.status.error = "Status Required";
      hasError = true;
    }
    // eslint-disable-next-line no-constant-condition
    if (formData.value.controls.inventoryLocation.value === "") {
      formData.value.controls.status.error = "Inventory Location Required";
      hasError = true;
    }
    // eslint-disable-next-line no-constant-condition
    if (formData.value.controls.status.value === "") {
      formData.value.controls.status.error = "Status Required";
      hasError = true;
    }
    return hasError;
  };

  const onSubmit = async () => {
    formData.value.isLoading = true;
    const hasError = validateData();
    if (hasError) {
      formData.value.isLoading = false;
    } else {
      dataInput.value.terminalName = formData.value.controls.terminalName.value;
      dataInput.value.inventoryLocation = Number(
        formData.value.controls.inventoryLocation.value
      );
      dataInput.value.status = Number(formData.value.controls.status.value);
      const synonym = isNaN(selectedUserId) ? "post" : "put";
      if (!isNaN(selectedUserId)) {
        dataInput.value.id = selectedUserId;
      }
      const result = await ApiHelper.callApi(
        synonym,
        "/store/terminal",
        dataInput.value
      );
      if (result.status === 1) {
        ApiHelper.showSuccessMessage(result.message, "Store Terminal");
        ApiHelper.gotoPage(context, "/pos/storeterminals");
      } else {
        ApiHelper.showErrorMessage("Can not create Terminal", "Oops");
      }
      formData.value.isLoading = false;
    }
  };

  return {
    selectedUserId,
    initData,
    dataInput,
    formData,
    getPageTitle,
    onSubmit
  };
}
