import { ref } from "@vue/composition-api";

import { ApiHelper, ProfileHelper } from "@/helpers";
import moment from "moment";
import { dollarFormat } from "@/helpers/ApiHelper";
import { PagerItem, SelectOption } from "@/types";

type BarData = {
  isLoaded: boolean;
  data: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string;
      stack: string;
      data: number[];
    }[];
  };
  options: object;
};

export function useProfileFinancialStore(context: any) {
  const query = context.root.$route.query;
  const selectedProfileId = parseInt(
    context.root.$route.params.profileId || "0"
  );
  const pageData = ref<{
    isLoading: boolean;
    showCostCenter?: boolean;
    showGlCodes?: boolean;
    isAddTransaction: number;
    isInitLoading: boolean;
    tab: string;
    profile: object | undefined;
    percentTransactions: number;
    percentPlans: number;
    transactions: {
      skip: number;
      take: number;
      items: any;
      activeTab: string;
      sort: {
        order: string;
        direction: string;
      };
      filter: {
        key: string;
        events: SelectOption[];
        unassignedEvent: any;
        users: SelectOption[];
        unassignedUsers: any;
        amount: string;
        minAmount: string;
        maxAmount: string;
        date: string;
        minDate: string;
        maxDate: string;
        types: SelectOption[];
        paymentTypes: SelectOption[];
      };
      setActiveFilterTab: Function;
      closeFilterTab: Function;
      updateSortValue: Function;
      updateFilterValue: Function;
      searchTypes: Function;
      loadList: Function;
      pager: {
        showPagerItems: boolean;
        page: number;
        totalPages: number;
        total: number;
        items: PagerItem[];
      };
      selectedTypes: SelectOption[];
      searchTypesValue: {
        value: string;
      };
      searchTypesLoading: boolean;
      searchEventsLoading: boolean;
      searchEventsValue: {
        value: string;
      };
      searchEvents: Function;
      selectedEvents: SelectOption[];
      searchUsersValue: {
        value: string;
      };
      searchUsers: Function;
      selectedUsers: SelectOption[];
      searchUsersLoading: boolean;
      searchPaymentTypesValue: {
        value: string;
      };
      searchPaymentTypes: Function;
      selectedPaymentTypes: SelectOption[];
      searchPaymentTypesLoading: boolean;
    };
    totalTransactions: number;
    paymentPlans: any[];
    totalPlans: number;
    paying: boolean;
    transferVisible: boolean;
    selectedIds: any;
    paymentType: string;
    refundInfo: any;
    selectedFunding: any;
    totalFundBucket: number;
    fundBuckets: {
      skip: number;
      take: number;
      items: any;
      sort: {
        order: string;
        direction: string;
      };
      pager: {
        showPagerItems: boolean;
        page: number;
        totalPages: number;
        total: number;
        items: PagerItem[];
      };
    };
    selectedFundbuckets: any;
    totalPaids: {
      paidAmount: number;
      plansPaidAmount: number;
      fundBucketPaidAmount: number;
      cashBalance: number;
      paidAmountFormatted: string;
      plansPaidAmountFormatted: string;
      fundBucketPaidAmountFormatted: string;
      cashBalanceFormatted: string;
    };
    detailsVisible: number;
    isPriorEvent: number;
    cashBalance: {
      skip: number;
      take: number;
      items: any;
      sort: {
        order: string;
        direction: string;
      };
      pager: {
        showPagerItems: boolean;
        page: number;
        totalPages: number;
        total: number;
        items: PagerItem[];
      };
    };
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
      totalAmount: string;
    };
  }>({
    isLoading: true,
    isAddTransaction: 1,
    isInitLoading: false,
    tab: query.tab || "plan",
    profile: {},
    percentTransactions: 0,
    percentPlans: 0,
    transactions: {
      skip: 0,
      take: 8,
      items: [],
      sort: {
        order: "7",
        direction: "2"
      },
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      },
      filter: {
        key: "",
        events: [],
        unassignedEvent: null,
        users: [],
        unassignedUsers: null,
        amount: "",
        minAmount: "",
        maxAmount: "",
        types: [],
        date: "",
        minDate: "",
        maxDate: "",
        paymentTypes: []
      },
      activeTab: "",
      searchTypes: async (searchValue: string) => {
        pageData.value.transactions.searchTypesLoading = true;
        if (searchValue == "") {
          // load top 20 types
          pageData.value.transactions.filter.types = await ApiHelper.getTransactionTypeOptions(
            {
              getAll: 1
            }
          );
        } else {
          pageData.value.transactions.filter.types = await ApiHelper.getTransactionTypeOptions(
            {
              key: searchValue
            }
          );
        }
        const typeIds = (context.root.$route.query.types || "")
          .split(",")
          .map((id: string) => parseInt(id));
        pageData.value.transactions.filter.types = pageData.value.transactions.filter.types.map(
          (item: any) => ({
            ...item,
            selected: typeIds.includes(item.id)
          })
        );
        pageData.value.transactions.searchTypesLoading = false;
      },
      closeFilterTab: () => {
        pageData.value.transactions.loadList(1);
        pageData.value.transactions.activeTab = "";
      },
      searchTypesValue: {
        value: ""
      },
      searchTypesLoading: false,
      updateFilterValue: (name: string, value: string) => {
        switch (name) {
          case "date": {
            pageData.value.transactions.filter.date = value;
            const dateArr = value.split("-");
            pageData.value.transactions.filter.minDate = dateArr[0];
            pageData.value.transactions.filter.maxDate =
              dateArr[1] !== undefined ? dateArr[1] : "";
            break;
          }
          case "amount": {
            pageData.value.transactions.filter.amount = value;
            const amountArr = value.split("-");
            pageData.value.transactions.filter.minAmount = amountArr[0];
            pageData.value.transactions.filter.maxAmount =
              amountArr[1] !== undefined ? amountArr[1] : "";
            break;
          }
        }
        pageData.value.transactions.pager.page = 1;
        pageData.value.transactions.loadList(
          pageData.value.transactions.pager.page
        );
      },
      updateSortValue: (sort: string, direction: string) => {
        pageData.value.transactions.sort.order = sort;
        pageData.value.transactions.sort.direction = direction;
        pageData.value.transactions.loadList(
          pageData.value.transactions.pager.page
        );
      },
      selectedTypes: [],
      setActiveFilterTab: async (tab: string) => {
        if (pageData.value.transactions.activeTab !== tab) {
          pageData.value.transactions.activeTab = tab;
        } else {
          pageData.value.transactions.activeTab = "";
        }
        if (pageData.value.transactions.activeTab == "types") {
          if (pageData.value.transactions.filter.types.length === 0) {
            await pageData.value.transactions.searchTypes(
              pageData.value.transactions.searchTypesValue.value
            );
          }
        }
      },
      searchEventsLoading: false,
      searchEventsValue: {
        value: ""
      },
      selectedEvents: [],
      searchEvents: async (searchValue: string) => {
        pageData.value.transactions.searchEventsLoading = true;
        const eventIds = (context.root.$route.query.events || "")
          .split(",")
          .map((id: string) => parseInt(id));
        if (searchValue == "") {
          pageData.value.transactions.filter.events = await ApiHelper.getEventOptions(
            {
              getAll: 1
            }
          );
          pageData.value.transactions.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          pageData.value.transactions.filter.events = await ApiHelper.getEventOptions(
            {
              key: searchValue
            }
          );
          if (eventIds.includes(0)) {
            pageData.value.transactions.filter.unassignedEvent = {
              id: "0",
              text: "UN-ASSIGNED TO EVENTS",
              selected: eventIds.includes(0)
            };
          } else {
            pageData.value.transactions.filter.unassignedEvent = null;
          }
        }
        pageData.value.transactions.filter.events = pageData.value.transactions.filter.events.map(
          (item: any) => ({
            ...item,
            selected: eventIds.includes(item.id)
          })
        );
        pageData.value.transactions.searchEventsLoading = false;
      },
      searchUsersLoading: false,
      searchUsersValue: {
        value: ""
      },
      selectedUsers: [],
      searchUsers: async (searchValue: string) => {
        pageData.value.transactions.searchUsersLoading = true;
        const userIds = (context.root.$route.query.users || "")
          .split(",")
          .map((id: string) => parseInt(id));
        if (searchValue == "") {
          const result = await ApiHelper.callApi(
            "get",
            `/transactions/search/user`,
            {},
            {}
          );
          if (result.status === 1) {
            pageData.value.transactions.filter.users = [];
            result.data.users.map((item: any) => {
              pageData.value.transactions.filter.users.push({
                id: item.id || "",
                text: item.name || ""
              });
            });
          }
          pageData.value.transactions.filter.unassignedUsers = {
            id: "0",
            text: "ONLINE",
            selected: userIds.includes(0)
          };
        } else {
          const result = await ApiHelper.callApi(
            "get",
            `/transactions/search/user`,
            {},
            { key: searchValue }
          );
          if (result.status === 1) {
            pageData.value.transactions.filter.users = [];
            result.data.users.map((item: any) => {
              pageData.value.transactions.filter.users.push({
                id: item.id || "",
                text: item.name || ""
              });
            });
          }
          if (userIds.includes(0)) {
            pageData.value.transactions.filter.unassignedUsers = {
              id: "0",
              text: "ONLINE",
              selected: userIds.includes(0)
            };
          } else {
            pageData.value.transactions.filter.unassignedUsers = null;
          }
        }
        pageData.value.transactions.filter.users = pageData.value.transactions.filter.users.map(
          (item: any) => ({
            ...item,
            selected: userIds.includes(item.id)
          })
        );
        pageData.value.transactions.searchUsersLoading = false;
      },
      searchPaymentTypesLoading: false,
      searchPaymentTypesValue: {
        value: query.searchPaymentTypes || ""
      },
      selectedPaymentTypes: [],
      searchPaymentTypes: async (searchValue: string) => {
        pageData.value.transactions.searchPaymentTypesLoading = true;
        pageData.value.transactions.filter.paymentTypes = await ApiHelper.getPaymentTypesOptions(
          {
            key: searchValue,
            getAll: 1
          }
        );
        pageData.value.transactions.filter.paymentTypes = pageData.value.transactions.filter.paymentTypes.map(
          (item: any) => ({
            ...item
          })
        );
        pageData.value.transactions.searchPaymentTypesLoading = false;
      },
      loadList: async (pageIndex: number) => {
        const query = context.root.$route.query;
        const page = Number.parseInt(pageIndex + "", 10);
        ApiHelper.setDataLoading(true);
        pageData.value.transactions.pager.page = page || 1;
        pageData.value.transactions.skip =
          (pageData.value.transactions.pager.page - 1) *
          pageData.value.transactions.take;
        const events = query.eventId
          ? query.eventId
          : ApiHelper.convertSelectedOptionsToString(
              pageData.value.transactions.selectedEvents
            ) || "";
        const users =
          ApiHelper.convertSelectedOptionsToString(
            pageData.value.transactions.selectedUsers
          ) || "";
        const paymentTypes =
          ApiHelper.convertSelectedOptionsToString(
            pageData.value.transactions.selectedPaymentTypes
          ) || undefined;
        const result = await ApiHelper.callApi(
          "get",
          "/transactions",
          {},
          {
            skip: pageData.value.transactions.skip,
            take: pageData.value.transactions.take,
            key: pageData.value.transactions.filter.key.trim(),
            order: parseInt(pageData.value.transactions.sort.order),
            direction: parseInt(pageData.value.transactions.sort.direction),
            events,
            users,
            paymentTypes,
            minAmount: pageData.value.transactions.filter.minAmount || "",
            maxAmount: pageData.value.transactions.filter.maxAmount || "",
            minDate: pageData.value.transactions.filter.minDate || "",
            maxDate: pageData.value.transactions.filter.maxDate || "",
            types:
              ApiHelper.convertSelectedOptionsToString(
                pageData.value.transactions.selectedTypes
              ) || "",
            profiles: selectedProfileId,
            callFrom: "ProfileFinancial",
            view: 2
          }
        );
        pageData.value.isLoading = false;
        if (result.status === 1) {
          const totalCount = result.data.totalCount || 0;
          pageData.value.transactions.pager.totalPages = Math.ceil(
            totalCount / pageData.value.transactions.take
          );
          pageData.value.transactions.pager.total = totalCount;
          const pagerList = [];
          for (
            let i = 0;
            i < pageData.value.transactions.pager.totalPages;
            i++
          ) {
            const pagerItem: PagerItem = {
              label: i + 1 + "",
              value: i + 1,
              active: i + 1 === pageData.value.transactions.pager.page
            };
            pagerList.push(pagerItem);
          }
          pageData.value.transactions.pager.items = pagerList;
          pageData.value.transactions.items = result.data.items.map(
            (item: any) => {
              const discountInfo = item.discountInfo || [];
              // group by discountId
              const discounts: any = [];
              for (const discount of discountInfo) {
                // for (const t of discount) {
                const inList = discounts.find(
                  (d: any) => d.discountId == discount.discountId
                );
                if (!inList) {
                  discounts.push({
                    discountId: discount.discountId,
                    discountName: discount.discountName,
                    discountCode: discount.discountCode || "",
                    discountAmount: discount.discountAmount,
                    discountAmountFormatted: dollarFormat(
                      discount.discountAmount
                    )
                  });
                } else {
                  inList.discountAmount += discount.discountAmount;
                  inList.discountAmount = parseFloat(
                    inList.discountAmount.toFixed(2)
                  );
                  inList.discountAmountFormatted = dollarFormat(
                    inList.discountAmount
                  );
                }
                // }
              }

              return {
                ...item,
                discountInfo: discounts
              };
            }
          );
          ApiHelper.setDataLoading(false);
        } else {
          ApiHelper.setDataLoading(false);
          pageData.value.isLoading = false;
          ApiHelper.showErrorMessage(result.message);
        }
      }
    },
    totalTransactions: 0,
    paymentPlans: [],
    totalPlans: 0,
    paying: false,
    transferVisible: false,
    selectedIds: [],
    paymentType: "manual",
    refundInfo: [],
    selectedFunding: {},
    fundBuckets: {
      skip: 0,
      take: 8,
      items: [],
      sort: {
        order: "7",
        direction: "2"
      },
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      }
    },
    totalFundBucket: 0,
    selectedFundbuckets: [],
    totalPaids: {
      paidAmount: 0,
      plansPaidAmount: 0,
      fundBucketPaidAmount: 0,
      cashBalance: 0,
      paidAmountFormatted: "",
      plansPaidAmountFormatted: "",
      fundBucketPaidAmountFormatted: "",
      cashBalanceFormatted: ""
    },
    detailsVisible: -1,
    isPriorEvent: 0,
    cashBalance: {
      skip: 0,
      take: 8,
      items: [],
      sort: {
        order: "7",
        direction: "2"
      },
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      }
    },
    activeTab: "",
    sort: {
      order: parseInt(query.view || 1) == 1 ? "7" : "0",
      direction: parseInt(query.view || 1) == 1 ? "2" : "0"
    },
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      totalAmount: "",
      items: []
    }
  });

  const sidebarData = ref({
    isLoading: true,
    logo: "",
    name: "",
    profileId: 0,
    participantId: 0,
    status: 0,
    eventId: 0,
    eventName: "",
    pTypeName: "",
    paidAmount: 0,
    paidAmountFormatted: "",
    financials: {
      totalPaid: 0
    }
  });

  const barData = ref<BarData>({
    isLoaded: false,
    data: {
      labels: [],
      datasets: []
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        intersect: false
      },
      plugins: {
        title: {
          display: false
        },
        tooltip: {
          display: false,
          mode: "index",
          intersect: false
        }
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: true,
            barPercentage: 0.1,
            ticks: {
              fontColor: "#b9b9b8",
              fontSize: 9,
              padding: 16,
              fontStyle: "bold"
            },
            gridLines: {
              lineWidth: 0,
              drawBorder: false,
              display: false
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              display: false
            },
            ticks: {
              display: false
            }
          }
        ]
      }
    }
  });

  const chartData = ref<{
    isLoaded: boolean;
    data: {
      labels: any[];
      datasets: {
        label: string;
        stack: string;
        type: string;
        backgroundColor: string;
        data: any[];
      }[];
    };
  }>({
    isLoaded: false,
    data: {
      datasets: [],
      labels: []
    }
  });
  const isArchived = ref(false);
  const popupData = ref({
    profileId: parseInt(context.root.$route.params.profileId) || 0,
    eventId: parseInt(context.root.$route.query.eventId) || 0,
    show: false
  });
  const apexKey = ref(ApiHelper.randomFormId());

  const loadData = async () => {
    // reset graph chart
    chartData.value.isLoaded = false;
    ApiHelper.setDataLoading(true);
    try {
      const getInfo = ["totalFundBucket", "totalCashBalance"];
      const participantId = parseInt(context.root.$route.query.id) || 0;
      const eventId = parseInt(context.root.$route.query.eventId) || 0;
      if (participantId > 0 && eventId > 0) {
        getInfo.push("isPriorEvent");
      }

      const result = await ApiHelper.callApi(
        "get",
        "/finances/dashboard",
        {},
        {
          profileId: selectedProfileId,
          eventId,
          getInfo: getInfo.join(",")
        }
      );
      ApiHelper.setDataLoading(false);
      if (result.status !== 1) {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, {
          name: "Profiles"
        });
        chartData.value.isLoaded = true;
        return;
      }

      chartData.value.data.datasets = [];
      chartData.value.data.labels = [];

      const last12months = result.data.chartData || [];
      chartData.value.data.labels = last12months.map((value: any) => {
        return value.label;
      });
      chartData.value.data.datasets.push({
        label: "New Charges",
        backgroundColor: "#000000",
        stack: "New Charges",
        type: "bar",
        data: last12months.map((value: any) => {
          return value.newChargeTotal;
        })
      });
      chartData.value.data.datasets.push({
        label: "Payments",
        backgroundColor: "#5e886d",
        stack: "Payments",
        type: "bar",
        data: last12months.map((value: any) => {
          return value.paymentTotal;
        })
      });
      chartData.value.data.datasets.push({
        label: "Balance",
        backgroundColor: "#000000",
        stack: "Balance",
        type: "line",
        data: last12months.map((value: any) => {
          return value.totalOwed;
        })
      });

      chartData.value.isLoaded = true;

      pageData.value.percentTransactions = Math.ceil(
        result.data.percentTransactions || 0
      );
      pageData.value.percentPlans = Math.floor(result.data.percentPlans || 0);

      // load payment plans
      pageData.value.paymentPlans = (result.data.plans || []).map(
        (item: any) => {
          item.amountFormatted = ApiHelper.dollarFormat(item.amount);
          item.balanceFormatted = ApiHelper.dollarFormat(item.balance);
          item.transactionTSFormatted = moment(item.transactionTS).format(
            "MMM DD, YYYY"
          );
          return item;
        }
      );
      pageData.value.totalTransactions = result.data.totalTransactions || 0;
      pageData.value.totalPlans = result.data.totalPlans || 0;
      // load chart
      const item = result.data || {};

      const tsStart = new Date(item.tsStart);
      const tsEnd = new Date(item.tsEnd);
      const linkEventParticipants = item.linkEventParticipants || [];
      let pCurrent = 0;
      let pTotal = 0;
      let pPercent = 0;
      let eAges = "";
      if (linkEventParticipants.length > 0) {
        pCurrent = 0;
        pPercent = 0;
        pTotal = item.linkEventParticipants[0].capacity || 0;
        eAges = item.linkEventParticipants[0].age || "";
      }

      const totalIncomes: {
        transM: number;
        transY: number;
        totalM: string;
        eventTotalInc: number;
      }[] = item.totalIncomes.map((value: any) => {
        return {
          transM: value.transM || 0,
          transY: value.transY || 0,
          totalM: value.totalM || "",
          totalInc: value.totalInc || 0
        };
      });
      barData.value.data.labels = totalIncomes.map((value: any) => {
        return value.totalM;
      });
      barData.value.data.datasets.push({
        label: "Revenue",
        backgroundColor: "#5e886d",
        stack: "Stack 0",
        data: totalIncomes.map((value: any) => {
          return value.totalInc;
        })
      });
      barData.value.isLoaded = true;

      const profileData = {
        id: item.id,
        isChecked: false,
        name: item.name,
        participants: {
          current: pCurrent,
          total: pTotal,
          percent: pPercent
        },
        ages: eAges,
        date: tsStart.toLocaleDateString() + " - " + tsEnd.toLocaleDateString(),
        totalIncomes: totalIncomes,
        financial: {
          ...item.financial,
          totalPaidFormatted: ApiHelper.dollarFormat(
            item.financial.totalPaid || 0,
            false,
            { useBrackets: true }
          ),
          totalBalanceFormatted: ApiHelper.dollarFormat(
            item.financial.totalBalance || 0,
            false,
            { useBrackets: true }
          )
        }
      };
      pageData.value.profile = profileData;
      pageData.value.totalFundBucket = result.data.totalFundBucket || 0;
      pageData.value.totalPaids = result.data.totalPaids;
      pageData.value.isPriorEvent = result.data.isPriorEvent || 0;
      pageData.value.isAddTransaction = result.data.isAddTransaction;
    } catch (err) {
      ApiHelper.setDataLoading(false);
      chartData.value.isLoaded = true;
      pageData.value.profile = undefined;
    }
  };

  const getTransactions = async (page: number) => {
    ApiHelper.setDataLoading(true);
    pageData.value.transactions.pager.page = page || 1;
    pageData.value.transactions.skip =
      (pageData.value.transactions.pager.page - 1) *
      pageData.value.transactions.take;

    const result = await ApiHelper.callApi(
      "get",
      "/transactions",
      {},
      {
        skip: pageData.value.transactions.skip,
        take: pageData.value.transactions.take,
        order: parseInt(pageData.value.transactions.sort.order),
        direction: parseInt(pageData.value.transactions.sort.direction),
        events: context.root.$route.query.eventId || "",
        profiles: selectedProfileId
      }
    );
    if (result.status === 1) {
      const totalCount = result.data.totalCount || 0;
      pageData.value.transactions.pager.totalPages = Math.ceil(
        totalCount / pageData.value.transactions.take
      );
      pageData.value.transactions.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.transactions.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.transactions.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.transactions.pager.items = pagerList;
      pageData.value.transactions.items = result.data.items.map((item: any) => {
        const discountInfo = item.discountInfo || [];
        // group by discountId
        const discounts: any = [];
        for (const discount of discountInfo) {
          for (const t of discount) {
            const inList = discounts.find(
              (d: any) => d.discountId == t.discountId
            );
            if (!inList) {
              discounts.push({
                discountId: t.discountId,
                discountName: t.discountName,
                discountCode: t.discountCode,
                discountAmount: t.discountAmount,
                discountAmountFormatted: dollarFormat(t.discountAmount)
              });
            } else {
              inList.discountAmount += t.discountAmount;
              inList.discountAmount = parseFloat(
                inList.discountAmount.toFixed(2)
              );
              inList.discountAmountFormatted = dollarFormat(
                inList.discountAmount
              );
            }
          }
        }

        return {
          ...item,
          discountInfo: discounts
        };
      });
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
    } else {
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      ApiHelper.showErrorMessage(result.message);
    }
  };

  const gotoPage = (page: string, type = "transactions") => {
    if (type == "transactions") {
      pageData.value.transactions.pager.page = parseInt(page);
      // updateRouters();
      pageData.value.transactions.loadList(
        pageData.value.transactions.pager.page
      );
    } else if (type == "fund_bucket") {
      pageData.value.fundBuckets.pager.page = parseInt(page);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getFundBucket(pageData.value.fundBuckets.pager.page);
    }
  };

  const onPagerChange = (event: any) => {
    pageData.value.transactions.pager.page = event.target.value;
    // updateRouters();
    pageData.value.transactions.loadList(
      pageData.value.transactions.pager.page
    );
  };

  const onClickPrev = (type = "transactions") => {
    if (type == "transactions") {
      if (pageData.value.transactions.pager.page > 1) {
        pageData.value.transactions.pager.page -= 1;
        // updateRouters();
        pageData.value.transactions.loadList(
          pageData.value.transactions.pager.page
        );
      }
    } else if (type == "fund_bucket") {
      pageData.value.fundBuckets.pager.page -= 1;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getFundBucket(pageData.value.fundBuckets.pager.page);
    }
  };

  const onClickNext = (type = "transactions") => {
    if (type == "transactions") {
      if (
        pageData.value.transactions.pager.page <
        pageData.value.transactions.pager.totalPages
      ) {
        pageData.value.transactions.pager.page += 1;
        // updateRouters();
        pageData.value.transactions.loadList(
          pageData.value.transactions.pager.page
        );
      }
    } else if (type == "fund_bucket") {
      if (
        pageData.value.fundBuckets.pager.page <
        pageData.value.fundBuckets.pager.totalPages
      ) {
        pageData.value.fundBuckets.pager.page += 1;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getFundBucket(pageData.value.fundBuckets.pager.page);
      }
    }
  };

  const togglePagerItems = () => {
    pageData.value.transactions.pager.showPagerItems = !pageData.value
      .transactions.pager.showPagerItems;
  };

  const getFundBucket = async (page: number) => {
    try {
      ApiHelper.setDataLoading(true);
      pageData.value.fundBuckets.pager.page = page || 1;
      pageData.value.fundBuckets.skip =
        (pageData.value.fundBuckets.pager.page - 1) *
        pageData.value.fundBuckets.take;

      const result = await ApiHelper.callApi(
        "get",
        `/finances/fundtransactions`,
        {},
        {
          skip: pageData.value.fundBuckets.skip,
          take: pageData.value.fundBuckets.take,
          profileId: selectedProfileId,
          getInfo: "transactions",
          eventId: context.root.$route.query.eventId || undefined
        }
      );
      if (result.status == 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.fundBuckets.pager.totalPages = Math.ceil(
          totalCount / pageData.value.fundBuckets.take
        );
        pageData.value.fundBuckets.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.fundBuckets.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.fundBuckets.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.fundBuckets.pager.items = pagerList;

        pageData.value.fundBuckets.items = result.data.fundBuckets || [];
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        pageData.value.fundBuckets.items = [];
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const removeTransaction = async (item: any) => {
    const confirm = await context.root.$swal.fire({
      html: `
        You are about to delete this transaction <strong>#${item.transactionId}</strong>. Are you sure?
        <div class="alert-notes">
          <small>
            Note: Applied discounts along with this transaction if any also are deleted.
          </small>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      customClass: {
        container: "swal2-actions-p0"
      },
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    });
    if (confirm.isConfirmed) {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "delete",
        "/transactions/" + item.transactionId,
        {},
        {}
      );
      ApiHelper.setDataLoading(false);
      if (result.status == 1) {
        ApiHelper.showSuccessMessage("Deleted successfully", "Deleted");
        // pageData.value.transactions.loadList(1);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await reloadData();
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    }
  };

  const reloadData = async () => {
    await loadData();
    pageData.value.tab = "transaction";
    pageData.value.transactions.loadList(1);
    apexKey.value = ApiHelper.randomFormId();
    const navProfileDetailsRef: any = context.refs.navProfileDetailsRef;
    if (navProfileDetailsRef && navProfileDetailsRef.loadData) {
      navProfileDetailsRef.loadData();
    }
  };

  const toggleTransactionDetails = async (item: any, index: number) => {
    if (pageData.value.detailsVisible == index) {
      pageData.value.detailsVisible = -1;
    } else {
      pageData.value.detailsVisible = index;
    }
    if (pageData.value.detailsVisible != -1 && !("details" in item)) {
      // get item details
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/transactions/details",
        {},
        {
          transactionId: item.transactionId
        }
      );
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      if (result.status === 1) {
        item.details = {
          transactionDetails: result.data.transactionDetails || [],
          transactionLines: result.data.transactionLines || [],
          transactionInfos: result.data.transactionInfos || [],
          refundLines: result.data.refundLines || [],
          events: result.data.events || []
        };
      } else {
        ApiHelper.showErrorMessage("Transaction not found", "Oops");
      }
    }
  };

  const getCashBalance = async (page: number) => {
    try {
      ApiHelper.setDataLoading(true);
      // pageData.value.fundBuckets.pager.page = page || 1;
      // pageData.value.fundBuckets.skip =
      //   (pageData.value.fundBuckets.pager.page - 1) *
      //   pageData.value.fundBuckets.take;

      const result = await ApiHelper.callApi(
        "get",
        `/finances/cashBalance`,
        {},
        {
          getAll: 1,
          profileId: selectedProfileId
        }
      );
      pageData.value.isLoading = false;
      if (result.status == 1) {
        // const totalCount = result.data.totalCount || 0;
        // pageData.value.fundBuckets.pager.totalPages = Math.ceil(
        //   totalCount / pageData.value.fundBuckets.take
        // );
        // pageData.value.fundBuckets.pager.total = totalCount;
        // const pagerList = [];
        // for (let i = 0; i < pageData.value.fundBuckets.pager.totalPages; i++) {
        //   const pagerItem: PagerItem = {
        //     label: i + 1 + "",
        //     value: i + 1,
        //     active: i + 1 === pageData.value.fundBuckets.pager.page
        //   };
        //   pagerList.push(pagerItem);
        // }
        // pageData.value.fundBuckets.pager.items = pagerList;

        pageData.value.cashBalance.items = result.data.items || [];
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        pageData.value.cashBalance.items = [];
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const updateRouters = () => {
    pageData.value.pager.page = 1;
    pageData.value.transactions.loadList(1);
    pageData.value.selectedFundbuckets = [];
  };

  // filters
  const closeFilterTab = () => {
    loadData();
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
    if (pageData.value.activeTab == "events") {
      if (pageData.value.transactions.filter.events.length === 0) {
        await pageData.value.transactions.searchEvents(
          pageData.value.transactions.searchEventsValue.value
        );
      }
    }
    if (pageData.value.activeTab == "types") {
      if (pageData.value.transactions.filter.types.length === 0) {
        await pageData.value.transactions.searchTypes(
          pageData.value.transactions.searchTypesValue.value
        );
      }
    }
    // Search users
    if (pageData.value.activeTab == "users") {
      if (pageData.value.transactions.filter.users.length === 0) {
        await pageData.value.transactions.searchUsers(
          pageData.value.transactions.searchUsersValue.value
        );
      }
    }
    // Search payment type
    if (pageData.value.activeTab == "paymentTypes") {
      if (pageData.value.transactions.filter.paymentTypes.length === 0) {
        await pageData.value.transactions.searchPaymentTypes(
          pageData.value.transactions.searchPaymentTypesValue.value
        );
      }
    }
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.transactions.filter.key) {
      filters.push({
        label: "KEY",
        key: pageData.value.transactions.filter.key + Math.random(),
        value: pageData.value.transactions.filter.key,
        reset: () => {
          pageData.value.transactions.filter.key = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const updateFilterValue = (name: string, value: string) => {
    // pageData.value.filter.t = "";
    switch (name) {
      case "key": {
        pageData.value.transactions.filter.key = value;
        break;
      }
      case "date": {
        pageData.value.transactions.filter.date = value;
        const dateArr = value.split("-");
        pageData.value.transactions.filter.minDate = dateArr[0];
        pageData.value.transactions.filter.maxDate =
          dateArr[1] !== undefined ? dateArr[1] : "";
        // pageData.value.transactions.filter.t = ApiHelper.randomFormId().toString();
        break;
      }
      case "amount": {
        pageData.value.transactions.filter.amount = value;
        const amountArr = value.split("-");
        pageData.value.transactions.filter.minAmount = amountArr[0];
        pageData.value.transactions.filter.maxAmount =
          amountArr[1] !== undefined ? amountArr[1] : "";
        break;
      }
    }
    pageData.value.pager.page = 1;
    pageData.value.transactions.loadList(1);
    pageData.value.selectedFundbuckets = [];
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.transactions.sort.order = sort;
    pageData.value.transactions.sort.direction = direction;
    // updateRouters();
    pageData.value.transactions.loadList(1);
    pageData.value.selectedFundbuckets = [];
  };

  // init data
  (async () => {
    pageData.value.isInitLoading = true;
    const profileInfo = await ProfileHelper.getProfileInfo(selectedProfileId);
    isArchived.value = profileInfo.status === 2;

    await loadData();

    if ((query.addPayment || 0) == 1) {
      popupData.value.show = true;
    }
    pageData.value.isInitLoading = false;
  })();

  (async () => {
    const participantId =
      Number.parseInt(context.root.$route.query.id, 10) || 0;
    if (participantId > 0) {
      const result = await ApiHelper.callApi(
        "get",
        "/participants/" + participantId,
        {},
        {}
      );
      if (result.status === 1) {
        sidebarData.value.name = result.data.eventName;
        sidebarData.value.profileId = result.data.profileId || 0;
        sidebarData.value.participantId = result.data.participantId || 0;
        sidebarData.value.eventId = result.data.eventId || 0;
        sidebarData.value.eventName = result.data.eventName || "";
        sidebarData.value.pTypeName = result.data.participantTypeName || "";
        sidebarData.value.status = result.data.participantStatus || 0;
        sidebarData.value.paidAmount = result.data.totalPaid || 0;
        sidebarData.value.paidAmountFormatted = dollarFormat(
          sidebarData.value.paidAmount
        );
        sidebarData.value.financials = {
          totalPaid: sidebarData.value.paidAmount
        };

        sidebarData.value.isLoading = false;
      }
    }
  })();

  (async () => {
    if (
      typeof pageData.value.showGlCodes == "undefined" &&
      typeof pageData.value.showCostCenter == "undefined"
    ) {
      const configData = await ApiHelper.getEntityConfig(true);
      pageData.value.showGlCodes = configData.showGlCodes;
      pageData.value.showCostCenter = configData.showCostCenter;
    }
  })();

  (async () => {
    if (pageData.value.tab == "transaction") {
      pageData.value.transactions.loadList(1);
      pageData.value.selectedFundbuckets = [];
    }
  })();

  return {
    sidebarData,
    chartData,
    isArchived,
    loadData,
    barData,
    pageData,
    popupData,
    getTransactions,
    gotoPage,
    onPagerChange,
    onClickPrev,
    onClickNext,
    togglePagerItems,
    getFundBucket,
    removeTransaction,
    reloadData,
    apexKey,
    toggleTransactionDetails,
    getCashBalance,
    // filters
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    updateSortValue,
    updateRouters
  };
}
