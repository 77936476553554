





































































import { defineComponent, ref } from "@vue/composition-api";
import Loader from "@/components/Loader.vue";

export default defineComponent({
  name: "EventGroupRowTooltip",
  components: {
    Loader
  },
  props: {
    list: {
      type: Object,
      defaultValue: {}
    },
    idParent: Number,
    loading: Boolean,
    isEventActive: Boolean,
    maxRows: Number,
    indexValue: Number,
    type: String,
    nodataText: String,
    addTopPopOverToolTip: {
      type: Boolean,
      default: false
    },
  },
  setup(props, context) {
    const limitRows = ref(props.maxRows);
    const renderlist = () => {
      let list = [];
      if (props.list?.familyEventsActive) {
        list = props.list.familyEvents || [];
        if (props.isEventActive) {
          list = props.list.familyEventsActive || [];
        }
      }

      const maxRows = limitRows.value || 0;
      if (list.length <= maxRows) {
        return list;
      }

      return list.slice(0, maxRows);
    };

    const moreMsg = () => {
      let list = [];
      if (props.list?.familyEventsActive) {
        list = props.list.familyEvents || [];
        if (props.isEventActive) {
          list = props.list.familyEventsActive || [];
        }
      }
      const maxRows = limitRows.value || 0;
      if (list.length <= maxRows) {
        return "";
      }

      return `+ ${list.length - maxRows} More`;
    };

    return {
      limitRows,
      renderlist,
      moreMsg
    };
  }
});
