













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import ErrorView from "./Error.vue";
import Vue from "vue";
import { ApiHelper } from "@/helpers";
import router, { routerData } from "@/router";
import VScroller from "@/components/Common/VScroller.vue";
import GlobalSearch from "@/components/Common/GlobalSearch.vue";
import $ from "jquery";
import SettingsProfile from "@/views/Settings/SettingsProfile.vue";
import PortalCustomization from "@/views/Settings/SettingsPortalCustomization/PortalCustomization.vue";
import SettingsDomains from "@/views/Settings/SettingsDomains/SettingsDomains.vue";
import StripeCreds from "@/views/Settings/SettingsStripeCreds/StripeCreds.vue";
import SettingsBilling from "@/views/Settings/SettingsBilling/SettingsBilling.vue";
import { ApiType, sleep, TransactionType } from "@/helpers/ApiHelper";
import Loading from "@/components/Common/Loading.vue";
import Modal from "@/components/Common/Modal.vue";
import FormButton from "@/components/Form/FormButton.vue";
import FormDatePicker3 from "@/components/Form/FormDatePicker3.vue";
import FormDate from "@/types/FormDate";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import moment from "moment";
import { FormSelect } from "@/types";
import FormSelectOptions from "@/components/Form/FormSelect.vue";
import VueGtag from "vue-gtag";
import AttendeeCountModal from "@/views/Report/AttendeeCountModal.vue";
import AllergiesReportModal from "@/views/Report/AllergiesReportModal.vue";

window.$ = window.jQuery = require("jquery");

export default defineComponent({
  name: "LayoutDefault",
  data() {
    return {
      itemSbox: {
        style: {
          width: "",
          opacity: "",
          visibility: ""
        }
      }
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Camp360 Admin Portal";
      }
    }
  },
  components: {
    ErrorView,
    GlobalSearch,
    VScroller,
    SettingsProfile,
    PortalCustomization,
    SettingsDomains,
    StripeCreds,
    SettingsBilling,
    Loading,
    Modal,
    FormButton,
    FormDatePicker3,
    AutoCompleteInput,
    FormSelectOptions,
    AttendeeCountModal,
    AllergiesReportModal
  },
  setup(props, context) {
    const activeMenu = ref<string>("");

    const totalMessages = ref<number>(0);
    const totalEvents = ref<number>(0);
    const totalPeople = ref<number>(0);
    const popularTags = ref([]);
    const isLoading = ref(false);
    const lastRoute = ref<any>(undefined);
    const universalSearch = ref<{
      visible: boolean;
      searchKey: string;
      timer: any;
      foundItems: any;
      isLoading: boolean;
      isTyping: boolean;
    }>({
      visible: false,
      searchKey: "",
      timer: null,
      foundItems: [],
      isLoading: false,
      isTyping: false
    });
    const settingPanel = ref<{
      visible: boolean;
      search: string;
      showSearch: boolean;
      currentMenuItem: any;
      menuItems: any;
    }>({
      visible: false,
      showSearch: false,
      search: "",
      currentMenuItem: {
        componentName: ""
      },
      menuItems: []
    });
    const favorite = ref<{
      inputVisible: boolean;
      value: string;
      actionLabel: string;
      viewIdCurrent: number;
      showInput: Function;
      onClickOutsideFavorite: Function;
      onHover: Function;
      onBlur: Function;
      viewOptions: any;
      leftPanelVisible: boolean;
      saving: boolean;
      disabledClick: boolean;
    }>({
      inputVisible: false,
      value: "",
      actionLabel: "SAVE TO FAVORITES",
      viewIdCurrent: 0,
      showInput: () => {
        favorite.value.inputVisible = true;
        setTimeout(() => {
          $(".favorite-content input[type=text]").focus();
        }, 100);
      },
      onClickOutsideFavorite: (e: any) => {
        if ($(e.target).closest(".swal2-container").length) {
          return;
        }
        favorite.value.inputVisible = false;
        favorite.value.value = "";
      },
      onHover: () => {
        if (favorite.value.disabledClick) return;
        $(".favorite-section").addClass("favorite-hover");
      },
      onBlur: () => {
        favorite.value.disabledClick = false;
        $(".favorite-section").removeClass("favorite-hover");
      },
      viewOptions: [],
      leftPanelVisible: false,
      saving: false,
      disabledClick: false
    });

    const pageData = ref<{
      isLoading: boolean;
      exportModalVisible: string;
      controls: {
        startDate: FormDate;
        endDate: FormDate;
        exportType: FormSelect;
      };
      foundDiscounts: any;
      isACILoading: boolean;
      selectedDiscount: string;
      attendeeCountModalVisible: boolean;
      showCostCenter?: boolean;
      showGlCodes?: boolean;
      openApplicationPortal?: boolean;
      allergiesReportModalVisible: boolean;
    }>({
      isLoading: false,
      exportModalVisible: "",
      controls: {
        startDate: {
          error: "",
          format: "MM/DD/YYYY",
          placeholder: "MM/DD/YYYY",
          label: "From Date",
          type: "date",
          value: "",
          style: "custom"
        },
        endDate: {
          error: "",
          format: "MM/DD/YYYY",
          placeholder: "MM/DD/YYYY",
          label: "To Date",
          type: "date",
          value: "",
          style: "custom"
        },
        exportType: {
          error: "",
          label: "Export Type",
          // placeholder: "Select Export Type",
          type: "select",
          value: "PDF",
          // style: "custom",
          required: false,
          options: [
            {
              id: "PDF",
              text: "PDF"
            },
            {
              id: "CSV",
              text: "CSV"
            }
          ]
        }
      },
      foundDiscounts: [],
      isACILoading: false,
      selectedDiscount: "",
      attendeeCountModalVisible: false,
      allergiesReportModalVisible: false
    });

    const loadPopularTags = () => {
      (async () => {
        try {
          const result = await ApiHelper.callApi("get", "/users/stats", {}, {});
          if (result.status === 1) {
            totalEvents.value = result.data.totalEvents || 0;
            totalMessages.value = result.data.totalMessages || 0;
            totalPeople.value = result.data.totalPeople || 0;
            popularTags.value = result.data.tags || [];
          }
        } catch (err) {
          console.log("err", err);
        }
      })();
    };
    const setActiveMenu = (menu: string) => {
      activeMenu.value = menu;
    };
    const closeMenu = () => {
      activeMenu.value = "";
    };

    const doLogout = () => {
      ApiHelper.userLogout();
      ApiHelper.gotoPage(context, { name: "Login" });
      ApiHelper.setStorageItem("USER_VIEW_LOGS", []);
    };

    const boldString = (str: string, substr: string) => {
      // if (str.toLowerCase().indexOf(substr.toLowerCase()) != -1) {
      //   const strRegExp = new RegExp(substr, "g");
      //   return str.replace(strRegExp, "<b>" + substr + "</b>");
      // }
      // return substr;
      if (str.toLowerCase().indexOf(substr.toLowerCase()) == -1) {
        return str;
      }
      const n = str.toUpperCase();
      const q = substr.toUpperCase();
      const x = n.indexOf(q);
      const l = q.length;
      return (
        str.substr(0, x) + "<b>" + str.substr(x, l) + "</b>" + str.substr(x + l)
      );
    };

    const suggestItems = async (key: string, isFocus = false) => {
      // animate effect
      if (universalSearch.value.searchKey == "") {
        $(".universal-search").removeClass("show-results");
      } else {
        $(".universal-search").addClass("show-results");
      }

      if (universalSearch.value.timer) {
        clearTimeout(universalSearch.value.timer);
      }
      universalSearch.value.isTyping = true;
      universalSearch.value.timer = setTimeout(async () => {
        const searchLoading = $(".universal-search-loading");
        try {
          universalSearch.value.isLoading = true;
          searchLoading.fadeIn();
          const result = await ApiHelper.callApi(
            "get",
            "/entities/items/search",
            {},
            {
              key: universalSearch.value.searchKey
            }
          );
          searchLoading.fadeOut("fast");
          if (result.status === 1) {
            const data: any = result.data || [];
            const tags = data.tags || [];
            const events = data.events || [];
            universalSearch.value.foundItems = [
              ...(data.profiles || []),
              ...tags,
              ...events,
              ...(data.families || []),
              ...(data.transactions || []),
              ...(data.plans || []),
              ...(data.costCenters || []),
              ...(data.postingCodes || [])
            ].map((item: any) => {
              if (item.type == "profile") {
                item.typeName = "participant";
                if (item.phone) {
                  item.phone =
                    "(" +
                    item.phone.substring(0, 3) +
                    ") " +
                    item.phone.substring(3, 6) +
                    " - " +
                    item.phone.substring(6, 10);
                }
              } else {
                item.typeName = item.type;
              }

              return item;
            });
          }
        } catch (error) {
          console.log(error);
        } finally {
          universalSearch.value.isLoading = false;
          universalSearch.value.isTyping = false;
          searchLoading.fadeOut("fast");
        }
      }, 500);
    };

    const closeSearch = () => {
      universalSearch.value.visible = false;
      universalSearch.value.searchKey = "";
      universalSearch.value.foundItems = [];
      $(".universal-search").removeClass("search-visible show-results");
      clearTimeout(universalSearch.value.timer);
    };

    const toggleSearchPanel = () => {
      settingPanel.value.visible = false;
      universalSearch.value.visible = !universalSearch.value.visible;
      if (universalSearch.value.visible) {
        $(".universal-search").addClass("search-visible");
        $(".search-area-content input[type=text]").focus();
      } else {
        closeSearch();
      }
    };

    const onClickOutsideSearch = (e: any) => {
      if ($(e.target).closest(".search-button").length) {
        return;
      }
      closeSearch();
    };

    const getLinkObject = (item: any) => {
      if (item.type == "profile") {
        return {
          name: "ProfileDashboard",
          params: {
            profileId: item.id.toString()
          }
        };
      } else if (item.type == "event") {
        return {
          name: "EventOverview",
          params: {
            eventId: item.id.toString()
          }
        };
      } else if (item.type === "family") {
        return {
          name: "FamilyDetails",
          params: {
            Id: item.id.toString()
          }
        };
      } else if (item.type == "tag") {
        return {
          name: "SettingsTagEdit",
          params: {
            id: item.id.toString()
          }
        };
      } else if (item.type == "transaction") {
        return {
          name: "FinancialTransactionDetails",
          query: {
            transactionId: item.title
          }
        };
      } else if (item.type == "plan") {
        return {
          name: "FinancialPlanDetails",
          query: {
            planId: `${item.planId}`
          }
        };
      } else if (item.type == "cost center") {
        return {
          name: "FinancialCostCenterEdit",
          params: {
            id: `${item.id}`
          }
        };
      } else if (item.type == "posting code") {
        return {
          name: "FinancialPostingEdit",
          params: {
            id: `${item.id}`
          }
        };
      }
    };

    const isSettingPage = () => {
      const $root: any = context.root || {};
      return [
        "Settings",
        "SettingsBilling",
        "SettingsApplications",
        "SettingsApplicationsDetails",
        "SettingsApplicationsEdit",
        "SettingsParticipantForms",
        "SettingsParticipantFormsDetails",
        "SettingsParticipantFormsEdit",
        "SettingsParticipantTypes",
        "SettingsParticipantTypesDetails",
        "SettingsParticipantTypesEdit",
        "SettingsProfile",
        "PortalCustomization",
        "StripeCreds",
        "SettingsDomains",
        "SettingsUsers",
        "SettingsUserDetails",
        "SettingsUserEdit",
        "SettingsGroups",
        "SettingsGroupEdit",
        "SettingsTags",
        "SettingsTagEdit",
        "SettingsTagTypes",
        "SettingsTagTypeEdit",
        "SettingsContentTypes",
        "SettingsContentTypeEdit",
        "SettingsTagContents",
        "SettingsTagContentEdit",
        "SettingsCabins",
        "SettingsCabinEdit",
        "SettingsMailingAddress",
        "SettingsDiscounts",
        "SettingsDiscountEdit",
        "SettingsEventGroups",
        "SettingsEventGroupEdit"
      ].includes($root.$route.name);
    };
    const toggleSettingPanel = (e: any) => {
      if (favorite.value.leftPanelVisible) {
        favorite.value.leftPanelVisible = false;
        return;
      }

      if (isSettingPage()) {
        if (lastRoute.value) {
          ApiHelper.gotoPage(context, lastRoute.value);
          settingPanel.value.visible = false;
          lastRoute.value = undefined;
          settingPanel.value.currentMenuItem = {
            componentName: ""
          };
          return;
        }
      }
      settingPanel.value.visible = !settingPanel.value.visible;
      if (settingPanel.value.visible == false) {
        settingPanel.value.currentMenuItem = {
          componentName: ""
        };
      } else {
        // default loading for top menu item
        if (settingPanel.value.menuItems.length) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          loadSettingContent(settingPanel.value.menuItems[0]);
        }
      }
    };

    const loadData = () => {
      (async () => {
        try {
          const result = await ApiHelper.callApi("get", "/users/stats", {}, {});
          if (result.status === 1) {
            totalEvents.value = result.data.totalEvents || 0;
            totalMessages.value = result.data.totalMessages || 0;
            totalPeople.value = result.data.totalPeople || 0;
            popularTags.value = result.data.tags || [];
          }
        } catch (err) {
          totalEvents.value = 0;
          totalPeople.value = 0;
        }
      })();

      // get setting menu items
    };

    const loadSettingContent = (item: any, isFirst = true) => {
      if ((item.componentName || "") != "") {
        // load component
        settingPanel.value.currentMenuItem = item;
        if (!isFirst) {
          settingPanel.value.visible = true;
        }
      } else {
        settingPanel.value.currentMenuItem = item;
        // go to route
        settingPanel.value.visible = false;
        context.root.$router
          .push({
            name: item.settingCardRoute
          })
          .catch((error: any) => {
            // do nothing
          });
      }
    };

    const getSettingCards = async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/entities/settingCards",
        {},
        {},
        ApiType.CORE
      );
      if (result.status === 1) {
        settingPanel.value.menuItems = result.data.items || [];

        // setup google analytic
        const gaAdmin = result.data.gaAdmin || "";
        if (gaAdmin != "") {
          Vue.use(
            VueGtag,
            {
              config: { id: gaAdmin }
            },
            router
          );
        }
      }
    };

    const onClickOutsideSettingPanel = (e: any) => {
      if (
        $(e.target).closest(".setting-menu-item").length ||
        $(e.target).closest(".swal2-container").length
      ) {
        return;
      }
      if (settingPanel.value.visible) {
        settingPanel.value.visible = false;
        settingPanel.value.currentMenuItem = {
          componentName: ""
        };
      }
    };

    const removeViewItem = async (viewId: number) => {
      const isAgreed = await Vue.swal({
        html: "Are you sure you want to remove this favorite?",
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });

      if (isAgreed) {
        if (viewId == 0) return;
        const result = await ApiHelper.callApi(
          "delete",
          `/entities/views/${viewId}`
        );
        if (result.status == 1) {
          favorite.value.viewOptions = favorite.value.viewOptions.filter(
            (item: any) => item.viewId != viewId
          );
          favorite.value.actionLabel = "SAVE TO FAVORITES";
          favorite.value.viewIdCurrent = 0;
        }
      }
    };

    const saveView = async (isEnter: false) => {
      const viewName = favorite.value.value;
      if (viewName == "") return;

      // build params
      const routeQuery = context.root.$route.query;
      const routeParams = context.root.$route.params || {};
      const routeName = context.root.$route.name;
      let screenId = context.root.$route.name;
      switch (screenId) {
        case "EventDetails":
        case "EventOverview":
        case "EventTimeline":
        case "EventDashboard":
        case "EventSchedule":
        case "EventCabins":
        case "EventCabinsDormGenerate":
        case "EventCabinDetails":
        case "EventAtCamp":
        case "EventMedical":
        case "EventTransportation":
        case "EventGroups":
        case "EventFinancial":
        case "EventSettings":
        case "EventSettingsApplications":
          screenId = `${screenId}_${routeParams.eventId}`;
          break;
        case "ProfileDetails":
        case "ProfileDashboard":
        case "ProfileMedicals":
        case "ProfileApplication":
        case "ProfileNotes":
        case "ProfileFiles":
        case "ProfileAtCamp":
        case "ProfileEdit":
        case "ProfileTodos":
        case "ProfileAllergies":
        case "ProfileTribes":
        case "ProfileFinances":
        case "ProfileRoommates":
        case "ProfileSettings":
        case "ProfileMessages":
          screenId = `${screenId}_${routeParams.profileId}`;
          break;
      }

      // make sure remove viewId
      if (routeQuery.viewId) {
        delete routeQuery.viewId;
      }
      const params: any = {
        screenId: screenId,
        name: routeName,
        params: routeParams,
        query: routeQuery
      };

      let filtersData = [];
      try {
        const mainContentRef: any = context.refs.mainContentRef;
        if (mainContentRef) {
          if (mainContentRef.getFiltersData) {
            filtersData = mainContentRef.getFiltersData()?.list || [];
            params.filtersData = filtersData;
          } else {
            // inside a master component
            const insideMasterRef = mainContentRef.$refs?.insideMasterRef;
            if (insideMasterRef && insideMasterRef.getFiltersData) {
              filtersData = insideMasterRef.getFiltersData()?.list || [];
              params.filtersData = filtersData;
            }
          }
        }
      } catch (error) {
        // do nothing
      }

      try {
        isLoading.value = true;
        favorite.value.saving = true;
        favorite.value.disabledClick = true;
        const result = await ApiHelper.callApi("post", "/entities/views", {
          viewName,
          // type: viewType,
          params: JSON.stringify(params)
        });
        if ((result.status || 0) == 1) {
          $(".saved-favorite-ico").fadeIn("slow", async () => {
            await sleep(300);
            favorite.value.value = "";
            favorite.value.inputVisible = false;
            favorite.value.saving = false;
            $(".favorite-section").removeClass("favorite-hover");
            if (isEnter) {
              favorite.value.disabledClick = false;
            }
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            getViews();
          });
        } else {
          favorite.value.saving = false;
          favorite.value.disabledClick = false;
          await ApiHelper.showErrorMessage(result.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const getViews = async () => {
      try {
        // pageData.value.isLoading = true;
        const result = await ApiHelper.callApi("get", "/entities/views");
        if (result.status == 1) {
          favorite.value.viewOptions = result.data.views || [];
          const routeParams = context.root.$route.params || {};
          let screenId = context.root.$route.name || "";
          switch (screenId) {
            case "EventDetails":
            case "EventOverview":
            case "EventTimeline":
            case "EventDashboard":
            case "EventSchedule":
            case "EventCabins":
            case "EventCabinsDormGenerate":
            case "EventCabinDetails":
            case "EventAtCamp":
            case "EventMedical":
            case "EventTransportation":
            case "EventGroups":
            case "EventFinancial":
            case "EventSettings":
            case "EventSettingsApplications":
              screenId = `${screenId}_${routeParams.eventId}`;
              break;
            case "ProfileDetails":
            case "ProfileDashboard":
            case "ProfileMedicals":
            case "ProfileApplication":
            case "ProfileNotes":
            case "ProfileFiles":
            case "ProfileAtCamp":
            case "ProfileEdit":
            case "ProfileTodos":
            case "ProfileAllergies":
            case "ProfileTribes":
            case "ProfileFinances":
            case "ProfileRoommates":
            case "ProfileSettings":
            case "ProfileMessages":
              screenId = `${screenId}_${routeParams.profileId}`;
              break;
          }
          for (const item of favorite.value.viewOptions) {
            const params = JSON.parse(item.params || "{}");
            const filtersData = params.filtersData || [];

            item.filtersData = filtersData;
            if (
              JSON.stringify(context.root.$route.query) ==
                JSON.stringify(params.query) &&
              screenId === (params.screenId || params.name)
            ) {
              favorite.value.actionLabel = "REMOVE FROM FAVORITES";
              favorite.value.viewIdCurrent = item.viewId;
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        // pageData.value.isLoading = false;
      }
    };

    const onClickOutsideFavoritePanel = (e: any) => {
      if ($(e.target).closest(".sidebar_favorite").length) {
        return;
      }
      if (favorite.value.leftPanelVisible) {
        favorite.value.leftPanelVisible = false;
      }
    };

    const toggleFavoritePanel = (e: any) => {
      favorite.value.leftPanelVisible = !favorite.value.leftPanelVisible;
    };

    const loadView = async (item: any) => {
      favorite.value.leftPanelVisible = false;
      const savedView = JSON.parse(item.params || "{}");
      if (savedView.name) {
        context.root.$router.push(savedView).catch((error: any) => {
          // do nothing
        });
      }
    };

    // init data
    (async () => {
      loadData();
      getSettingCards();
      if (isSettingPage()) {
        settingPanel.value.visible = false;
      }
      getViews();
    })();

    const openSearchBox = () => {
      setTimeout(() => {
        toggleSearchPanel();
      }, 100);
    };

    const closeGlobalMessage = () => {
      const globalMessageHeight = parseInt(
        `${$(".global-message").height() || 0}`
      );
      if (globalMessageHeight > 0) {
        $(".global-message").animate({ height: "0" }, 100);
      }
    };

    const onClickOutsideMessage = (e: any) => {
      closeGlobalMessage();
    };

    const showExportModal = (type: string) => {
      if (!type) return;
      let startLabel = "From Date";
      let endLabel = "To Date";
      if (type == "balancesDue") {
        startLabel = "Event From Date";
        endLabel = "Event To Date";
      }
      pageData.value.controls.startDate.label = startLabel;
      pageData.value.controls.endDate.label = endLabel;
      pageData.value.controls.startDate.value = "";
      pageData.value.controls.endDate.value = "";
      pageData.value.controls.exportType.value = "PDF";
      pageData.value.exportModalVisible = type;
    };

    const getExportTitle = () => {
      const type = pageData.value.exportModalVisible;
      if (!type) return;

      let ret = "";
      if (type == "discountsGiven") {
        ret = "Discounts Given Export";
      } else if (type == "balancesDue") {
        ret = "Balances Due Export";
      } else if (type == "costcenters") {
        ret = "Cost Centers Export";
      }

      return ret;
    };

    const exportDiscountsGiven = async () => {
      try {
        pageData.value.isLoading = true;
        const start = moment(pageData.value.controls.startDate.value);
        const end = moment(pageData.value.controls.endDate.value);
        const exportType = pageData.value.controls.exportType.value || "PDF";
        const result = await ApiHelper.callApi(
          "get",
          "/accounting/reports/discountsGiven",
          {},
          {
            exportDate: start.isValid() ? start.format("YYYY-MM-DD") : "",
            exportToDate: end.isValid() ? end.format("YYYY-MM-DD") : "",
            discountName: pageData.value.selectedDiscount || "",
            exportType
          }
        );

        if (result.status === 1) {
          const data = result.data || {};
          const pdfUrl = data.pdfUrl || "";
          // const grandTotal = data.grandTotal || 0;
          const grandTotalFormatted = data.grandTotalFormatted || "";
          if (exportType == "PDF") {
            if (pdfUrl) {
              window.open(pdfUrl, "_blank")!.focus();
            }
          } else if (exportType == "CSV") {
            const exportData: any = data.data || [];
            const csvData: any = [];
            for (const discount of exportData) {
              const discountName = discount.discountName;
              csvData.push([discountName]);
              for (const event of discount.items) {
                csvData.push(["", event.eventName]);
                for (const item of event.items) {
                  csvData.push([
                    "",
                    "",
                    item.participantId,
                    item.fullName,
                    item.transactionAmountFormatted
                  ]);
                }
                csvData.push(["", "", "", "SUBTOTAL", event.subTotalFormatted]);
              }
            }
            csvData.push(["", "", "", "GRAND TOTAL", grandTotalFormatted]);
            const headers = [
              "DISCOUNT NAME",
              "EVENT NAME",
              "PARTICIPANT ID",
              "PARTICIPANT NAME",
              "AMOUNT"
            ];
            const fileName = ["DiscountGiven"];
            if (start.isValid()) {
              fileName.push(start.format("MMDDYYYY"));
            }
            if (end.isValid()) {
              fileName.push(end.format("MMDDYYYY"));
            }
            fileName.push(moment().format("MMDDYYYY_HHMMSS"));
            await ApiHelper.generateCsv(csvData, headers, fileName.join("_"));
          }

          pageData.value.exportModalVisible = "";
        }
      } catch (error) {
        console.log(error);
      } finally {
        pageData.value.isLoading = false;
      }
    };

    const exportBalancesDue = async () => {
      try {
        pageData.value.isLoading = true;
        const start = moment(pageData.value.controls.startDate.value);
        const end = moment(pageData.value.controls.endDate.value);
        const exportType = pageData.value.controls.exportType.value || "PDF";
        const result = await ApiHelper.callApi(
          "get",
          "/accounting/reports/balancesDue",
          {},
          {
            exportDate: start.isValid() ? start.format("YYYY-MM-DD") : "",
            exportToDate: end.isValid() ? end.format("YYYY-MM-DD") : "",
            exportType
          }
        );

        if (result.status === 1) {
          const data = result.data || {};
          const pdfUrl = data.pdfUrl || "";
          const grandTotalFormatted = data.grandTotalFormatted || "";

          if (exportType == "PDF") {
            if (pdfUrl) {
              window.open(pdfUrl, "_blank")!.focus();
            }
          } else if (exportType == "CSV") {
            const exportData: any = (data.data || []).map((item: any) => {
              return [
                `"${item.familyId}"`,
                `"${item.familyName.replace(/"/g, '""')}"`,
                `"${item.fullName}"`,
                `"${item.eventName}"`,
                `"${item.pdatetimeFormatted || ""}"`,
                `"${item.phoneFormatted || ""}"`,
                `"${item.balanceFormatted}"`
              ];
            });
            exportData.push([
              "",
              "",
              "",
              "",
              "",
              `"${"GRAND TOTAL"}"`,
              `"${grandTotalFormatted}"`
            ]);

            const headers = [
              "ID",
              "FAMILY NAME",
              "ATTENDEE NAME",
              "EVENT",
              "ENROLLMENT DATE",
              "PHONE",
              "BALANCE"
            ];
            const fileName = ["BalancesDue"];
            if (start.isValid()) {
              fileName.push(start.format("MMDDYYYY"));
            }
            if (end.isValid()) {
              fileName.push(end.format("MMDDYYYY"));
            }
            fileName.push(moment().format("MMDDYYYY_HHMMSS"));
            await ApiHelper.generateCsv(
              exportData,
              headers,
              fileName.join("_"),
              false
            );
          }

          pageData.value.exportModalVisible = "";
        }
      } catch (error) {
        console.log(error);
      } finally {
        pageData.value.isLoading = false;
      }
    };

    const exportCostcenters = async () => {
      try {
        pageData.value.isLoading = true;
        const start = moment(pageData.value.controls.startDate.value);
        const end = moment(pageData.value.controls.endDate.value);
        const result = await ApiHelper.callApi(
          "get",
          "/accounting/reports/coscenters",
          {},
          {
            exportDate: start.isValid() ? start.format("YYYY-MM-DD") : "",
            exportToDate: end.isValid() ? end.format("YYYY-MM-DD") : ""
          }
        );

        if (result.status === 1) {
          const deferred = result.data.deferred;
          const exportData = (result.data.items || []).map((item: any) => {
            return [
              item.postingCode || "",
              item.postingCodeDesc || "",
              item.isDiscount
                ? `-${item.transactionAmountFormatted}`
                : item.transactionAmountFormatted,
              item.glCode || "",
              item.costCenterNumber || "",
              item.transactionTsFormatted
            ];
          });

          // append deferred credit
          exportData.push([
            "Deferred Revenue",
            "",
            deferred.credit ? deferred.creditFormatted : "",
            "",
            "",
            ""
          ]);

          const headers = [
            "POSTING CODE",
            "POSTING CODE DESCRIPTION",
            "ACTIVITY",
            "GL ACCOUNT",
            "COST CENTER NUMBER",
            "DATE OF ACTIVITY"
          ];
          const fileName = ["posting_costcenters"];
          if (pageData.value.controls.startDate.value) {
            fileName.push(
              moment(pageData.value.controls.startDate.value).format("MMDDYYYY")
            );
          }
          if (pageData.value.controls.endDate.value) {
            fileName.push(
              moment(pageData.value.controls.endDate.value).format("MMDDYYYY")
            );
          }
          fileName.push(moment().format("MMDDYYYY_HHMMSS"));
          await ApiHelper.generateCsv(exportData, headers, fileName.join("_"));

          pageData.value.exportModalVisible = "";
        }
      } catch (error) {
        console.log(error);
      } finally {
        pageData.value.isLoading = false;
      }
    };

    const doExport = () => {
      const type = pageData.value.exportModalVisible;
      if (!type) return;

      // validate
      if (
        pageData.value.controls.startDate.value &&
        pageData.value.controls.endDate.value &&
        pageData.value.controls.endDate.value <
          pageData.value.controls.startDate.value
      ) {
        pageData.value.controls.endDate.error = "To Date is invalid";
        return;
      }

      if (type == "discountsGiven") {
        exportDiscountsGiven();
      } else if (type == "balancesDue") {
        exportBalancesDue();
      } else if (type == "costcenters") {
        exportCostcenters();
      }
    };

    const showSettingMenu = (name: string) => {
      const key = settingPanel.value.search;
      if (!key) {
        return true;
      }
      return name.toLocaleLowerCase().search(key.toLocaleLowerCase()) > -1;
    };

    const suggestDiscounts = async (key: string) => {
      // reset
      pageData.value.foundDiscounts = [];

      if (key !== "") {
        // searching
        pageData.value.isACILoading = true;
        const result = await ApiHelper.callApi(
          "get",
          "/events/discountSearch",
          {},
          {
            key: key,
            take: 25,
            uniqueDiscountName: 1
          }
        );
        if (result.status === 1) {
          pageData.value.foundDiscounts = (result.data.discounts || []).map(
            (item: any) => ({
              // id: item.discountName,
              text: item.discountName,
              html: `${item.discountName} <span style="color: #a7a9a7; font-size: 90%;">${item.discountCode}</span>`,
              data: item
            })
          );
        }
        pageData.value.isACILoading = false;
      }
    };

    const selectDiscount = (data: any) => {
      if (data == undefined) {
        pageData.value.selectedDiscount = "";
        return;
      }

      pageData.value.selectedDiscount = data.discountName;
    };

    const getLastVisit = (routeName: string) => {
      const time = ApiHelper.getUserViewLogTime(routeName);
      return time;
    };

    const setIsLoading = (status: boolean) => {
      pageData.value.isLoading = status;
    };

    const loadEntityConfig = async () => {
      const configData = await ApiHelper.getEntityConfig(true);
      pageData.value.showGlCodes = configData.showGlCodes;
      pageData.value.showCostCenter = configData.showCostCenter;
      pageData.value.openApplicationPortal = configData.openApplicationPortal;
    };

    (async () => {
      await loadEntityConfig();
    })();

    return {
      loadEntityConfig,
      getLastVisit,
      showSettingMenu,
      lastRoute,
      isSettingPage,
      openSearchBox,
      loadData,
      loadPopularTags,
      doLogout,
      setActiveMenu,
      closeMenu,
      activeMenu,
      totalMessages,
      totalEvents,
      totalPeople,
      popularTags,
      suggestItems,
      universalSearch,
      boldString,
      isLoading,
      toggleSearchPanel,
      onClickOutsideSearch,
      getLinkObject,
      closeSearch,
      toggleSettingPanel,
      settingPanel,
      loadSettingContent,
      onClickOutsideSettingPanel,
      favorite,
      saveView,
      getViews,
      removeViewItem,
      onClickOutsideFavoritePanel,
      toggleFavoritePanel,
      loadView,
      onClickOutsideMessage,
      closeGlobalMessage,
      pageData,
      getExportTitle,
      doExport,
      showExportModal,
      suggestDiscounts,
      selectDiscount,
      setIsLoading
    };
  },
  methods: {
    toggleSubmenus() {
      /*
      const sidebar: any = this.$refs.sidebar;
      if (sidebar) {
        sidebar.classList.add("hideSubmenus");
        setTimeout(() => {
          sidebar.classList.remove("hideSubmenus");
        }, 100);
      }
      */
    },
    hoverON(e: any) {
      $("#LayoutDefault").addClass("hoverOn");
      /*
      if (
        $(".setting-panel:visible").length > 0 &&
        e.target.nextElementSibling
      ) {
        $(
          ".setting-panel:visible .item__smenu, .setting-panel:visible .item__top"
        ).fadeOut("fast", function() {
          if (
            !$(".setting-panel:visible .setting-panel-items").hasClass("active")
          ) {
            $(".setting-panel:visible .setting-panel-items").addClass("active");
            $(".setting-panel:visible .newMenu .item")
              .html(e.target.nextElementSibling.innerHTML)
              .fadeIn(200);
          } else {
            $(".setting-panel:visible .newMenu .item")
              .html(e.target.nextElementSibling.innerHTML)
              .fadeIn(200);
          }
        });
      } else if ($("li:hover").length == 0) {
        $(".item__sbox").each(function() {
          $(this).removeClass("active");
        });
        if (
          !$(".inbox-sidebar").hasClass("active") &&
          !$(".setting-panel-items").hasClass("active")
        ) {
          $(".inbox-sidebar").removeClass("active");
          $(".item:hover .item__sbox").addClass("active");
        }
      }
      */
    },
    hoverOFF(e: any) {
      $("#LayoutDefault").removeClass("hoverOn");
      /*
      setTimeout(function() {
        if ($(".item:hover").length == 0) {
          if ($(".setting-panel:visible").length > 0) {
            $(".setting-panel:visible .newMenu .item").fadeOut(
              "fast",
              function() {
                $(".setting-panel:visible .newMenu .item").html("");
                $(".setting-panel:visible .setting-panel-items").removeClass(
                  "active"
                );
                $(
                  ".setting-panel:visible .item__smenu, .setting-panel:visible .item__top"
                ).fadeIn(200);
              }
            );
          } else {
            $(".item__sbox").each(function() {
              $(this).removeClass("active");
            });
          }
        }
      }, 200);
      */
    },
    finishedLoading() {
      $(".item__icon .logo-inactive").fadeIn(100);
      $(".item__icon .logo").fadeOut(100);
      setTimeout(() => {
        $(".item__icon .logo-inactive").fadeOut(100);
        $(".item__icon .logo").fadeIn(100);
      }, 1000);
    }
  },
  updated() {
    this.$nextTick();
    this.toggleSubmenus();
    // this.loadData();
  },
  computed: {
    message() {
      const data: any = routerData;
      return data.errorData.message;
    },
    redirectName() {
      const data: any = routerData;
      return data.errorData.redirectName;
    },
    redirectQuery() {
      const data: any = routerData;
      return data.errorData.redirectQuery;
    },
    globalMessage() {
      const data: any = routerData;
      return data.globalMessage.value || "";
    },
    globalMessageActions() {
      const data: any = routerData;
      return data.globalMessage.actions || [];
    },
    isDataLoading() {
      const data: any = routerData;
      return data.isDataLoading.value || false;
    }
  },
  beforeRouteUpdate(to, from, next) {
    const settingPages = [
      "Settings",
      "SettingsBilling",
      "SettingsApplications",
      "SettingsApplicationsDetails",
      "SettingsApplicationsEdit",
      "SettingsParticipantForms",
      "SettingsParticipantFormsDetails",
      "SettingsParticipantFormsEdit",
      "SettingsParticipantTypes",
      "SettingsParticipantTypesDetails",
      "SettingsParticipantTypesEdit",
      "SettingsProfile",
      "PortalCustomization",
      "StripeCreds",
      "SettingsDomains",
      "SettingsUsers",
      "SettingsUserDetails",
      "SettingsUserEdit",
      "SettingsGroups",
      "SettingsGroupEdit",
      "SettingsTags",
      "SettingsTagEdit",
      "SettingsTagTypes",
      "SettingsTagTypeEdit",
      "SettingsContentTypes",
      "SettingsContentTypeEdit",
      "SettingsTagContents",
      "SettingsTagContentEdit",
      "SettingsCabins",
      "SettingsCabinEdit",
      "SettingsDiscounts",
      "SettingsDiscountEdit"
    ];
    // Reset information favorite
    this.favorite.viewIdCurrent = 0;
    this.favorite.actionLabel = "SAVE TO FAVORITES";

    const routeParams = to.params || {};
    let screenId = to.name || "";
    switch (screenId) {
      case "EventDetails":
      case "EventOverview":
      case "EventTimeline":
      case "EventDashboard":
      case "EventSchedule":
      case "EventCabins":
      case "EventCabinsDormGenerate":
      case "EventCabinDetails":
      case "EventAtCamp":
      case "EventMedical":
      case "EventTransportation":
      case "EventGroups":
      case "EventFinancial":
      case "EventSettings":
      case "EventSettingsApplications":
        screenId = `${screenId}_${routeParams.eventId}`;
        break;
      case "ProfileDetails":
      case "ProfileDashboard":
      case "ProfileMedicals":
      case "ProfileApplication":
      case "ProfileNotes":
      case "ProfileFiles":
      case "ProfileAtCamp":
      case "ProfileEdit":
      case "ProfileTodos":
      case "ProfileAllergies":
      case "ProfileTribes":
      case "ProfileFinances":
      case "ProfileRoommates":
      case "ProfileSettings":
      case "ProfileMessages":
        screenId = `${screenId}_${routeParams.profileId}`;
        break;
    }

    for (const item of this.favorite.viewOptions) {
      const params = JSON.parse(item.params || "{}");
      if (
        JSON.stringify(to.query) == JSON.stringify(params.query) &&
        screenId === (params.screenId || params.name)
      ) {
        this.favorite.actionLabel = "REMOVE FROM FAVORITES";
        this.favorite.viewIdCurrent = item.viewId;
      }
    }

    if (from && !settingPages.includes(from.name || "")) {
      this.lastRoute = from;
    }
    this.loadEntityConfig();
    next();
  }
});
