import { ApiHelper } from "@/helpers/index";

export const tagProfiles = async (profileIds: number[], tags: any[]) => {
  const result = await ApiHelper.callApi(
    "put",
    "/tags/profiles",
    {
      profileIds: profileIds.join("-"),
      tags: tags.map(item => {
        return {
          id: item.id,
          text: item.text
        };
      })
    },
    {}
  );
  return result;
};

export const deleteSelectedProfiles = async (
  profileIds: number[],
  type = "archive"
) => {
  // default is archive (p_active = 2)
  let status = 2;
  if (type == "delete") {
    status = 3;
  }
  const result = await ApiHelper.callApi(
    "patch",
    "/profiles/status",
    profileIds.map(id => {
      return {
        profileId: id,
        status
      };
    }),
    {}
  );
  return result;
};

export const getProfileInfo = async (profileId: number) => {
  const result = await ApiHelper.callApi(
    "get",
    "/profiles/" + profileId + "/info",
    {},
    {}
  );
  if (result.status === 1) {
    return (
      result.data || {
        id: 0,
        firstName: "",
        lastName: "",
        logo: "",
        status: 0,
        age: 0
      }
    );
  }
  return {
    id: 0,
    firstName: "",
    lastName: "",
    logo: "",
    status: 0,
    age: 0
  };
};
