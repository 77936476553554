


























































































































































import { defineComponent, ref } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Header from "@/components/Common/Header.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import { useSettingsGroupsStore } from "@/stores/Settings/Groups/SettingsGroupsStore";
import SecurityGroupRowTooltip from "@/components/SecurityGroupRowTooltip.vue";

export default defineComponent({
  name: "SettingsGroups",
  components: {
    Header,
    Loading,
    Pager,
    TableHeaderColumn,
    SecurityGroupRowTooltip
  },
  setup(props, context) {
    const {
      doUpdateStatus,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData
    } = useSettingsGroupsStore(context);

    const isTooltipOpen = ref(false);

    const usersTooltipVisibleIndex = ref(-1);
    const usersTooltipLoading = ref<boolean>(false);
    const usersTooltipList = ref([]);

    const showTooltipUsers = async (item: any, index: number) => {
      if (!isTooltipOpen.value) {
        usersTooltipVisibleIndex.value = index;
        usersTooltipLoading.value = true;
        const result = await ApiHelper.callApi(
          "get",
          "/users",
          {},
          {
            groupId: item.id
          },
          "core"
        );
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          usersTooltipVisibleIndex.value = -1;
          return false;
        }
        usersTooltipList.value = result.data.users.map((user: any) => ({
          id: user.id,
          text: ApiHelper.getFullName(user.firstName, user.lastName)
        }));

        usersTooltipLoading.value = false;
        isTooltipOpen.value = true;
      }
    };

    const hideTooltipUsers = () => {
      isTooltipOpen.value = false;
      usersTooltipLoading.value = false;
      usersTooltipVisibleIndex.value = -1;
    };

    // FUNCTIONS
    const functionsTooltipVisibleIndex = ref(-1);
    const functionsTooltipLoading = ref<boolean>(false);
    const functionsTooltipList = ref([]);

    const showTooltipFunctions = async (item: any, index: number) => {
      if (!isTooltipOpen.value) {
        functionsTooltipVisibleIndex.value = index;
        functionsTooltipLoading.value = true;
        const result = await ApiHelper.callApi(
          "get",
          "/securities/functions",
          {},
          {
            groupId: item.id
          },
          "core"
        );
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          functionsTooltipVisibleIndex.value = -1;
          return false;
        }
        functionsTooltipList.value = result.data.functions.map((func: any) => ({
          id: func.functionId,
          text: func.functionName
        }));

        functionsTooltipLoading.value = false;
        isTooltipOpen.value = true;
      }
    };

    const hideTooltipFunctions = () => {
      isTooltipOpen.value = false;
      functionsTooltipLoading.value = false;
      functionsTooltipVisibleIndex.value = -1;
    };

    return {
      usersTooltipVisibleIndex,
      isTooltipOpen,
      usersTooltipLoading,
      usersTooltipList,
      showTooltipUsers,
      hideTooltipUsers,
      // functions
      functionsTooltipVisibleIndex,
      functionsTooltipLoading,
      functionsTooltipList,
      showTooltipFunctions,
      hideTooltipFunctions,

      doUpdateStatus,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      ApiHelper: ApiHelper
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
