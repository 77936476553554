






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormInput from "@/components/Form/FormPhone.vue";
import FormToggle from "@/components/Form/FormToggle.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import TransferModal from "@/components/Financial/TransferModal.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import { ApiHelper } from "@/helpers";
import directives from "@/helpers/directives";
import $ from "jquery";
import VScroller from "@/components/Common/VScroller.vue";

import {
  FormSelect as FormSelectType,
  FormNumber as FormNumberType,
  FormTextarea as FormTextareaType,
  FormText
} from "@/types";

import { SelectOption } from "@/types";
import {
  dollarFormat,
  getFullName,
  ParticipantStatus,
  sleep,
  TransactionType
} from "@/helpers/ApiHelper";
import Inputmask from "inputmask";
declare const Stripe: any;

export default defineComponent({
  name: "PopupPayment",
  props: {
    eventId: Number,
    eventName: {
      type: String,
      default: ""
    },
    profileId: Number,
    onDismiss: Function,
    onCallback: Function,
    type: {
      type: String,
      default: "manual"
    },
    outstandingItems: {
      type: Array,
      default: () => {
        return [];
      }
    },
    callFrom: {
      type: String,
      default: ""
    },
    refundInfo: {
      type: Array,
      default: () => {
        return [];
      }
    },
    participantId: Number,
    selectedFunding: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  directives,
  components: {
    Modal,
    FormSelect,
    FormNumber,
    FormTextarea,
    FormInput,
    FormToggle,
    AutoCompleteInput,
    TransferModal,
    Select2,
    VScroller
  },
  setup(props, context) {
    const participants = ref<any[]>([]);
    const events = ref<any[]>([]);
    const ignoreEventSelect = ref(false);
    const campStoreCreditsRefund = ref<boolean>(false);
    const inputData = ref({
      eventId: props.eventId,
      profileId: props.profileId,
      participantId: 0,
      amount: 0,
      transactionDescription: "",
      transactionTypeId: 0
    });
    const paymentData = ref({
      paymentErrMessage: "",
      stripeError: 0,
      paymentType: "",
      paymentInfoLoading: false,
      paying: false,
      stripeToken: "",
      stripeLast4: "",
      cardName: "",
      cardNumber: "",
      expMoth: "",
      expyear: "",
      cvc: "",
      zipcode: "",
      desc: "",
      customAmount: "",
      addonServiceId: 0,
      errors: {
        cardName: "",
        cardNumber: "",
        expMoth: "",
        expyear: "",
        cvc: "",
        zipcode: "",
        addonServiceId: ""
      }
    });
    const currentBalance = ref<number>(0);
    const formData = ref<{
      isLoading: boolean;
      controls: {
        event: FormSelectType;
        participant: FormSelectType;
        amount: FormNumberType;
        amountLabel: string;
        isAddAmount: boolean;
        type: FormSelectType;
        description: FormTextareaType;
        discount: any;
        refund: {
          error: string;
        };
        toggleTransfer: {
          error: string;
          label: string;
          value: boolean;
        };
      };
      foundDiscounts: any;
      isACILoading: boolean;
      selectedDiscount: string;
      transferVisible: boolean;
      transferInfo: {
        participantId: number;
        profileId: number;
        paidAmount: number;
        paidAmountFormatted: string;
        fullName: string;
        eventName: string;
        pTypeName: string;
        overpayment: number;
      };
    }>({
      isLoading: false,
      controls: {
        event: {
          error: "",
          style: "custom",
          label: "Event",
          placeholder: "Select Event",
          type: "select",
          required: true,
          value: props.eventId ? props.eventId.toString() : "",
          options: []
        },
        participant: {
          error: "",
          required: true,
          style: "custom",
          label: "Participant",
          placeholder: "Select Participant",
          type: "select",
          value: "",
          options: []
        },
        amount: {
          error: "",
          required: true,
          type: "number",
          style: "custom",
          label: "Amount",
          placeholder: "Enter Amount",
          value: ""
        },
        amountLabel: "",
        isAddAmount: false,
        type: {
          error: "",
          required: true,
          style: "custom",
          type: "select",
          label: "Transaction Type",
          placeholder: "Select Type",
          value: "",
          options: []
        },
        description: {
          style: "custom",
          required: true,
          type: "textarea",
          error: "",
          label: "Description",
          placeholder: "Transaction Description",
          value: ""
        },
        discount: {
          error: "",
          required: false,
          type: "text",
          style: "custom",
          label: "Discount Code",
          placeholder: "Discount Code",
          value: ""
        },
        refund: {
          error: ""
        },
        toggleTransfer: {
          error: "",
          label: "Transfer",
          value: false
        }
      },
      foundDiscounts: [],
      isACILoading: false,
      selectedDiscount: "",
      transferVisible: false,
      transferInfo: {
        participantId: 0,
        profileId: 0,
        paidAmount: 0,
        paidAmountFormatted: "",
        fullName: "",
        eventName: "",
        pTypeName: "",
        overpayment: 0
      }
    });

    const paymentInfo = ref<any>({});
    const creditCharge = ref(false);
    const creditChargeCustomAmount = ref(false);
    const creditChargeCustomError = ref(false);
    const selectedToPay = ref<any>([]);
    // const selectedToPaybk = ref<any>([]);
    const dynamicCosts = ref<any>([]);
    const discountsHasCode = ref<any>([]);
    const discountCode = ref<string>("");
    const applyingDiscount = ref(false);
    const availableDiscounts = ref<any>([]);
    const usedDiscounts = ref<any>([]);
    const diffFamily = ref(false);
    const appliedDiscounts = ref<any>([]);
    const outstandingBalanceItems = ref<any>([]);
    const payForPlan = ref<string>("payonly");
    const transactionIds = ref<any>([]);
    const isApplyingDiscount = ref(false);
    const showOnlyRefund = ref(false);
    const familyOwner = ref<any>({});
    const participantInfo = ref<any>({});
    const singleSubscription = ref<number>(0);

    const fullPaid = (participant: any) => {
      if (
        (participant?.transactionAmount || 0) > 0 &&
        (participant?.balance || 0) <= 0
      ) {
        return true;
      }

      return false;
    };

    const partialPaid = (participant: any) => {
      if (
        (participant?.transactionAmount || 0) > 0 &&
        (participant?.balance || 0) > 0
      ) {
        return true;
      }

      return false;
    };

    const updateAmountLabel = (amount: number) => {
      if (formData.value.controls.isAddAmount) {
        formData.value.controls.amount.value =
          amount > 0 ? amount.toFixed(2) : "";
      } else if (amount > 0) {
        formData.value.controls.amountLabel = amount.toFixed(2);
      }
    };

    const chargeByCard = () => {
      return (
        parseInt(formData.value.controls.type.value) ==
        TransactionType.DebitCredit
      );
    };
    const payingPartialAmount = () => {
      let totalCost = parseFloat(formData.value.controls.amount.value) || 0;
      const selectedAddOns = selectedToPay.value.filter(
        (item: any) => item.sourceType == 2
      );
      for (const item of selectedAddOns) {
        totalCost += parseFloat(item.cost) || 0;
      }

      return chargeByCard() && (paymentInfo.value.totalCost || 0) != totalCost;
    };

    const isTransfer = () => {
      return (
        (props.selectedFunding.eventId || 0) > 0 &&
        formData.value.controls.toggleTransfer.value == true
      );
    };

    const validateForm = (formData: any) => {
      const transactionType = parseInt(formData.controls.type.value) || 0;
      const amount = parseFloat(formData.controls.amount.value) || 0;
      const selectedAddOns = dynamicCosts.value.filter((t: any) => t.checked);
      const justApplyDiscount =
        !selectedAddOns.length && appliedDiscounts.value.length && amount == 0
          ? true
          : false;

      inputData.value.eventId =
        Number.parseInt(formData.controls.event.value, 10) || 0;
      inputData.value.participantId =
        Number.parseInt(formData.controls.participant.value, 10) || 0;
      inputData.value.amount = amount;
      inputData.value.transactionTypeId = transactionType;
      inputData.value.transactionDescription =
        formData.controls.description.value || "";
      let hasError = false;

      // reset
      for (const item of dynamicCosts.value) {
        item.error = "";
      }

      if (inputData.value.eventId < 1) {
        hasError = true;
        formData.controls.event.error = "Event is required!";
      } else {
        formData.controls.event.error = "";
      }
      if (inputData.value.participantId < 1) {
        hasError = true;
        formData.controls.participant.error = "Participant is required!";
      } else {
        formData.controls.participant.error = "";
      }
      // amount
      if (
        formData.controls.amount.required &&
        amount === 0 &&
        !justApplyDiscount
      ) {
        hasError = true;
        formData.controls.amount.error = "Amount is required!";
      } else {
        formData.controls.amount.error = "";
      }
      // use scholarship, but set amount = 0, and no using any discounts
      if (
        transactionType == TransactionType.Scholarship &&
        appliedDiscounts.value.length == 0 &&
        amount == 0
      ) {
        hasError = true;
        formData.controls.amount.error = "Amount is required!";
      }

      if (
        formData.controls.description.required &&
        inputData.value.transactionDescription === ""
      ) {
        hasError = true;
        formData.controls.description.error = "Description is required!";
      } else {
        formData.controls.description.error = "";
      }
      if (
        transactionType === 0 &&
        formData.controls.toggleTransfer.value == false
      ) {
        hasError = true;
        formData.controls.type.error = "Transaction Type is required!";
      } else {
        formData.controls.type.error = "";
      }

      // make sure selected add-ons have a value
      for (const item of dynamicCosts.value) {
        if ((item.checked || false) && parseFloat(item.inputVal || 0) == 0) {
          item.error = "Amount is required";
          hasError = true;
        }
      }

      if (isTransfer()) {
        // make sure transfered amount is not greater than current balance of this funding
        const currentBalance = props.selectedFunding.currentBalance || 0;
        if (inputData.value.amount > currentBalance) {
          hasError = true;
          formData.controls.amount.error =
            "Transfered amount should not be greater than current balance";
        }
      }

      paymentData.value.paymentErrMessage = "";
      paymentData.value.errors = {
        cardName: "",
        cardNumber: "",
        expMoth: "",
        expyear: "",
        cvc: "",
        zipcode: "",
        addonServiceId: ""
      };

      if (transactionType == TransactionType.DebitCredit) {
        if (justApplyDiscount) {
          hasError = true;
          formData.controls.amount.error = "Amount is required!";
          ApiHelper.showErrorMessage(
            `Please change to "scholarship" type if you want to apply a full discount/scholarship`
          );
        }

        // let valid = true;
        if (amount > 0 && paymentData.value.paymentType == "") {
          paymentData.value.paymentErrMessage =
            "Please select a payment option";
          // valid = false;
          hasError = true;
        }
        if (paymentData.value.cardName == "") {
          paymentData.value.errors.cardName = "Name is required";
          // valid = false;
          hasError = true;
        }
        if (paymentData.value.cardNumber == "") {
          paymentData.value.errors.cardNumber = "Card Number is required";
          // valid = false;
          hasError = true;
        }
        if (paymentData.value.expMoth == "") {
          paymentData.value.errors.expMoth = "Exp Month is required";
          // valid = false;
          hasError = true;
        }
        if (paymentData.value.expyear == "") {
          paymentData.value.errors.expyear = "Exp Year is required";
          // valid = false;
          hasError = true;
        }
        if (paymentData.value.cvc == "") {
          paymentData.value.errors.cvc = "CVC is required";
          // valid = false;
          hasError = true;
        }
        if (
          paymentData.value.zipcode != "" &&
          paymentData.value.zipcode.length < 5
        ) {
          paymentData.value.errors.zipcode = "Zipcode is invalid";
          // valid = false;
          hasError = true;
        }
      }

      return hasError ? false : true;
    };

    const onSubmit = async () => {
      if (props.onDismiss) {
        props.onDismiss();
      }
      if (props.onCallback) {
        props.onCallback();
      }
    };
    const closePopup = () => {
      if (props.onDismiss) {
        props.onDismiss();
      }
    };

    const getDynamicCosts = async (eventId: number, participantId: number) => {
      if (eventId > 0 && participantId > 0) {
        const pType = participants.value.find(
          item => item.participantId == participantId
        );
        const pTypeId = pType?.participantTypeId || 0;
        const eventResponse = await ApiHelper.callApi(
          "get",
          `/events/${eventId}`,
          {},
          {
            getInfo: "dynamicCosts",
            pTypeId: pTypeId,
            participantId
          }
        );
        if (eventResponse.status == 1) {
          dynamicCosts.value = (
            eventResponse.data.moreInfo.dynamicCosts || []
          ).map((item: any) => ({
            ...item,
            checked: false,
            inputVal: !item.isFundBucket ? item.serviceCost : "",
            error: ""
          }));
          await sleep(300);
          Inputmask("price", { autoUnmask: true }).mask(
            $(".fund-txt.dollar_amount")
          );
        } else {
          dynamicCosts.value = [];
        }
      } else {
        dynamicCosts.value = [];
      }
      // paymentData.value.addonServiceId = 0;
    };

    const getOutStandingBalanceItems = async (participantId: number) => {
      outstandingBalanceItems.value = [];
      const result = await ApiHelper.callApi(
        "get",
        `/balances/outstanding`,
        {},
        { participantId }
      );
      if (result.status == 1) {
        outstandingBalanceItems.value = result.data.items || [];
      }
    };

    const resetPaymentInfo = () => {
      paymentData.value.stripeToken = "";
      paymentData.value.stripeLast4 = "";
      paymentData.value.cardName = "";
      paymentData.value.cardNumber = "";
      paymentData.value.expMoth = "";
      paymentData.value.expyear = "";
      paymentData.value.cvc = "";
      paymentData.value.stripeError = 0;
      paymentData.value.zipcode = "";
      paymentData.value.paymentErrMessage = "";
    };

    const onChangeEvent = async () => {
      formData.value.controls.amountLabel = "";
      formData.value.controls.isAddAmount = false;
      formData.value.isLoading = true;
      formData.value.controls.participant.value = "";
      formData.value.controls.type.value = "";
      formData.value.controls.type.error = "";
      resetPaymentInfo();
      paymentInfo.value = {};
      selectedToPay.value = [];
      creditCharge.value = false;
      appliedDiscounts.value = [];
      discountCode.value = "";
      showOnlyRefund.value = false;
      formData.value.controls.amount.required = true;
      formData.value.controls.description.required = true;
      participantInfo.value = {};

      const selectedId = formData.value.controls.event.value;
      const result = await ApiHelper.callApi(
        "get",
        "/finances/participants",
        {},
        {
          eventId: Number.parseInt(selectedId, 10),
          profileId: props.profileId
        }
      );
      if (result.status === 1) {
        participantInfo.value = result.data.participantInfo || {};
        participants.value = result.data.participants;
        const options: SelectOption[] = participants.value.map((item: any) => {
          const fullName = ApiHelper.getFullName(item.firstName, item.lastName);
          return {
            id: item.participantId || "",
            text: props.profileId
              ? fullName + " -- " + item.participantTypeName
              : fullName
          };
        });
        formData.value.controls.participant.options = options;
        formData.value.controls.participant.error = "";
        formData.value.controls.amount.value = "";
        formData.value.controls.amount.error = "";
        formData.value.controls.description.error = "";
        if (props.profileId && participants.value.length == 1) {
          formData.value.controls.participant.value =
            participants.value[0].participantId.toString() || "";
          const findParticipant = participants.value.find(item => {
            return (
              formData.value.controls.participant.value ==
              item.participantId.toString()
            );
          });
          if (findParticipant) {
            formData.value.controls.amountLabel =
              (findParticipant.balance || 0) > 0
                ? (findParticipant.balance || 0).toFixed(2)
                : "";

            // formData.value.controls.amount.value =
            //   (findParticipant.balance || 0) > 0
            //     ? (findParticipant.balance || 0).toFixed(2)
            //     : "";
            inputData.value.profileId = findParticipant.profileId;
            formData.value.controls.amount.max =
              (findParticipant.balance || 0) > 0
                ? findParticipant.balance.toFixed(2)
                : "";
            formData.value.controls.amount.min = 0;
            currentBalance.value = findParticipant.balance;

            // get outstanding balance item (need to check if this participant is in a plan)
            if (
              (findParticipant.balance || 0) > 0 &&
              findParticipant.pRegistrationStep == 3
            ) {
              await getOutStandingBalanceItems(findParticipant.participantId);
            }
          }
        }

        // get event settings
        const eventId = parseInt(selectedId);
        const participantId = parseInt(
          formData.value.controls.participant.value
        );
        getDynamicCosts(eventId, participantId);
      }

      formData.value.isLoading = false;
    };

    const onChangeParticipant = async () => {
      let transactionType = parseInt(formData.value.controls.type.value);
      formData.value.controls.amountLabel = "";
      formData.value.controls.isAddAmount = false;
      formData.value.controls.amount.value = "";
      paymentInfo.value = {};
      selectedToPay.value = [];
      appliedDiscounts.value = [];
      discountCode.value = "";
      showOnlyRefund.value = false;
      formData.value.controls.amount.required = true;
      formData.value.controls.description.required =
        transactionType != TransactionType.Scholarship ? true : false;
      payForPlan.value = "payonly";
      outstandingBalanceItems.value = [];

      const findParticipant = participants.value.find(item => {
        return (
          formData.value.controls.participant.value ==
          item.participantId.toString()
        );
      });
      if (findParticipant) {
        formData.value.controls.type.error = "";
        formData.value.controls.amount.error = "";
        formData.value.controls.description.error = "";
        let balance = findParticipant.balance;
        if (findParticipant.participantStatus == ParticipantStatus.Denied) {
          // show only refund option if denied this participant
          showOnlyRefund.value = true;
          formData.value.controls.type.value = `${TransactionType.Refund}`;
          creditCharge.value = false;
        }

        transactionType = parseInt(formData.value.controls.type.value);
        if (transactionType == TransactionType.Refund) {
          // reset if there is no payment made by this participant
          const paidTotal =
            findParticipant.transactionAmount - findParticipant.refundAmount;
          if (paidTotal <= 0) {
            formData.value.controls.type.value = "";
            resetPaymentInfo();
            paymentInfo.value = {};
            selectedToPay.value = [];
            creditCharge.value = false;
            appliedDiscounts.value = [];
            discountCode.value = "";
            return;
          }

          // make sure not auto fill for amount if this participant was refunded before
          if ((findParticipant.refundAmount || 0) > 0) {
            balance = 0;
          }
        }
        if (balance < 0) {
          // balance = 0 - balance;
          balance = 0;
        }
        // formData.value.controls.amount.value = balance
        //   ? balance.toFixed(2)
        //   : "";
        formData.value.controls.amountLabel = balance ? balance.toFixed(2) : "";
        const amount = parseFloat(formData.value.controls.amount.value) || 0;
        inputData.value.profileId = findParticipant.profileId;
        formData.value.controls.amount.max = findParticipant.balance
          ? findParticipant.balance.toFixed(2)
          : "";
        formData.value.controls.amount.min = 0;

        // get dynamic cost
        const eventId = parseInt(formData.value.controls.event.value);
        const participantId = parseInt(
          formData.value.controls.participant.value
        );
        getDynamicCosts(eventId, participantId);

        // get outstanding balance item (need to check if this participant is in a plan)
        if (
          (findParticipant.balance || 0) > 0 &&
          findParticipant.pRegistrationStep == 3
        ) {
          await getOutStandingBalanceItems(findParticipant.participantId);
        }

        // auto get payment info if selected transaction type is credit, and has input amount > 0
        if (
          parseInt(formData.value.controls.type.value) ==
            TransactionType.DebitCredit &&
          amount > 0
        ) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          onChangeTransactionType();
        }
      }
    };

    const hideDropdown = () => {
      if (
        formData.value.controls.participant.value &&
        participants.value.length == 1 &&
        props.profileId &&
        !props.profileId
      ) {
        return true;
      }
      return false;
    };

    const buildCustomSelectedToPay = (amount: any) => {
      if (amount == 0) return [];

      let eventId = 0;
      let profileId = 0;
      if (props.callFrom == "ProfileFinancial") {
        eventId = parseInt(formData.value.controls.event.value);
        profileId = props.profileId || 0;
      } else if (props.callFrom == "Transactions") {
        eventId = parseInt(formData.value.controls.event.value);
        profileId = inputData.value.profileId || 0;
      } else if (
        ["EventFinancialPage", "ParticipantFinancialPage"].includes(
          props.callFrom
        )
      ) {
        eventId = props.eventId || 0;
        profileId = inputData.value.profileId || 0;
      }
      if (eventId == 0 || profileId == 0) return [];

      const participant = participants.value.find(
        item => item.profileId == profileId
      );
      if (!participant) return [];

      const event = events.value.find(item => item.eventId == eventId);
      if (!event) return [];

      const ret = [
        {
          sourceType: 1,
          eventId: eventId,
          evName: event.eventName,
          profileId: profileId,
          firstName: participant.firstName || "",
          lastName: participant.lastName || "",
          participantId: participant.participantId || 0,
          participantTypeId: participant.participantTypeId || 0,
          participantCost: amount,
          balance: amount,
          pRegistrationStep: 1,
          additionalCosts: []
        }
      ];

      return ret;
    };

    const buildCustomPaymentInfo = (amount: any) => {
      const amountFormatted = dollarFormat(amount);
      const ret: any = {
        chargeDays: 0,
        chargeMonths: 0,
        deposit: 0,
        depositFormatted: "$0.00",
        firstCharge: amount,
        firstChargeDate: "",
        firstChargeFormatted: amountFormatted,
        lastChargeDate: "",
        participantAmount: amount,
        participantAmountFormatted: amountFormatted,
        participants: [],
        recurringAmount: 0,
        recurringAmountFormatted: "",
        totalAdditionalCosts: 0,
        totalAdditionalCostsFormatted: "$0.00",
        totalCost: amount,
        totalCostFormatted: amountFormatted
      };
      return ret;
    };

    const doPay = async () => {
      let ret = false;
      try {
        paymentData.value.paying = true;
        await context.emit("setIsPaying", true);

        if (paymentInfo.value.stripePublishableKey) {
          delete paymentInfo.value.stripePublishableKey;
        }
        let tmpPaymentInfo: any = paymentInfo.value;
        let selected: any = JSON.parse(JSON.stringify(selectedToPay.value));
        let customCharge = creditChargeCustomAmount.value;

        const amount = parseFloat(formData.value.controls.amount.value) || 0;
        // override when user pays partial for a balance
        if (payingPartialAmount()) {
          selected = buildCustomSelectedToPay(amount);
          tmpPaymentInfo = buildCustomPaymentInfo(amount);
          customCharge = true;
        }

        let discounts = JSON.parse(JSON.stringify(appliedDiscounts.value));
        const discountHasNoCode = discounts.filter(
          (item: any) => item.discountCode == "" && item.discountType == 1
        );
        discounts = discounts.filter(
          (item: any) => !(item.discountCode == "" && item.discountType == 1)
        );

        const requestObj = {
          applications: selected.map((item: any) => {
            let balance = item.balance || 0;
            let additionalCosts =
              item.pRegistrationStep == 1 ? item.additionalCosts || [] : [];
            additionalCosts = [...additionalCosts];
            if (item.pRegistrationStep == 1 && (item.totalFunds || 0) > 0) {
              additionalCosts.push({
                linkEventServiceId: 0,
                addonServiceId: 5,
                serviceName: "Total Camp Store Funds",
                cost: item.totalFunds,
                isFundBucket: 1,
                costFormatted: ApiHelper.dollarFormat(item.totalFunds),
                isTotalFunds: true
              });
            }
            let participants = [];
            let isFundBucket = 0;
            if ((item.sourceType || 1) == 1) {
              const discounts = JSON.parse(
                JSON.stringify(appliedDiscounts.value)
              );
              if ((item.participants || []).length) {
                participants =
                  (item.participants || []).map((p: any) => ({
                    eventId: p.eventId || 0,
                    eventName: p.evName || "",
                    participantId: p.participantId,
                    profileId: p.profileId,
                    firstName: p.firstName || "",
                    lastName: p.lastName || "",
                    participantCost: p.participantCost,
                    participantAmountFormatted: ApiHelper.dollarFormat(
                      p.participantCost
                    ),
                    pRegistrationStep: p.pRegistrationStep,
                    planId: p.planId || "",
                    balance: p.balance || 0,
                    balanceFormatted: p.balanceFormatted,
                    additionalCosts: [],
                    discountInfo: discounts.filter((t: any) =>
                      (t.appliedFor || []).includes(p.participantId)
                    )
                  })) || [];
              } else if (item.pRegistrationStep == 3) {
                const discountInfo = discounts.filter((t: any) =>
                  (t.appliedFor || []).includes(item.participantId)
                );
                // let balance = item.balance || 0;

                // partial paid, and apply more discount after paid
                if (props.type == "manual" && discountInfo.length) {
                  // update item balance/ participantAmount/ totalCost
                  for (const discountItem of discountInfo) {
                    const amount = parseFloat(
                      (balance - discountItem.discountAmount).toFixed(2)
                    );
                    if (amount >= 0) {
                      balance = amount;
                    }
                  }
                }

                participants = [
                  {
                    eventId: item.eventId || 0,
                    eventName: item.evName || "",
                    participantId: item.participantId,
                    profileId: item.profileId,
                    firstName: item.firstName || "",
                    lastName: item.lastName || "",
                    participantCost: item.participantCost,
                    participantAmountFormatted: ApiHelper.dollarFormat(
                      item.participantCost
                    ),
                    pRegistrationStep: item.pRegistrationStep,
                    planId: item.planId || "",
                    balance,
                    balanceFormatted: dollarFormat(balance),
                    additionalCosts: [],
                    discountInfo
                  }
                ];
              }
            } else if ((item.sourceType || 1) == 2) {
              const relatedBucket = dynamicCosts.value.find(
                (t: any) => t.addonServiceId == (item.addonServiceId || 0)
              );
              if (relatedBucket && relatedBucket.isFundBucket) {
                isFundBucket = 1;
              }
            }

            // apply discount
            const discountInfo: any = [];
            if (item.pRegistrationStep == 1) {
              item.planId = selectedToPay.value[0]["planId"] || "";
              // if using scholarship as a discount before
              if (
                (item.paidAmount || 0) == 0 &&
                (item.participantDiscountCost || 0) > 0
              ) {
                item.participantCost -= item.participantDiscountCost;
                item.participantCost = parseFloat(
                  item.participantCost.toFixed(2)
                );
              }

              // apply discount without discount code firstly
              const inApplied = discountHasNoCode.find((t: any) =>
                (t.appliedFor || []).includes(item.participantId)
              );
              if ((item.discountAmount || 0) > 0 && inApplied) {
                const discountAmount = item.discountAmount;
                item.participantCost -= discountAmount;
                item.participantCost = parseFloat(
                  item.participantCost.toFixed(2)
                );
                item.balance -= discountAmount;
                item.balance = parseFloat(item.balance.toFixed(2));
                item.totalCost -= discountAmount;
                item.totalCost = parseFloat(item.totalCost.toFixed(2));
                item.totalCostFormatted = ApiHelper.dollarFormat(
                  item.totalCost
                );
                const itemDiscount = { ...item.discount };
                delete itemDiscount.totalUsed;
                discountInfo.push(itemDiscount);
              }

              // apply discount if input a discount code
              if (discounts.length) {
                for (const discount of discounts) {
                  if (
                    (discount.eventId == item.eventId &&
                      discount.pTypeId == item.participantTypeId &&
                      (discount.appliedFor || []).includes(
                        item.participantId
                      )) == false
                  ) {
                    continue;
                  }

                  const discountAmount = discount?.discountAmount || 0;
                  if (
                    discountAmount > 0 &&
                    (discount.maxUse == 0 ||
                      (discount.maxUse > 0 &&
                        discount.maxUse > discount.totalUsed))
                  ) {
                    // apply if pass the condition
                    item.participantCost -= discountAmount;
                    item.participantCost = parseFloat(
                      item.participantCost.toFixed(2)
                    );
                    // item.costFormatted = ApiHelper.dollarFormat(item.cost);
                    item.balance -= discountAmount;
                    item.balance = parseFloat(item.balance.toFixed(2));
                    // item.totalCostFormatted = ApiHelper.dollarFormat(
                    //   item.totalCost
                    // );

                    // no send totalUsed in discountInfo
                    const itemDiscount = { ...discount };
                    delete itemDiscount.totalUsed;
                    delete itemDiscount.appliedFor;
                    discountInfo.push(itemDiscount);

                    // update totalUsed
                    discount.totalUsed += 1;
                  }
                }
              }
            }

            return {
              sourceType: item.sourceType || 1,
              addonServiceId: item.addonServiceId || 0,
              isFundBucket,
              profileId: item.profileId || 0,
              firstName: item.firstName || "",
              lastName: item.lastName || "",
              participantId: item.participantId || 0,
              eventId: item.eventId || 0,
              eventName: item.evName || "",
              serviceName: item.serviceName || "",
              participantTypeId: item.participantTypeId || 0,
              participantAmount:
                item.pRegistrationStep == 1
                  ? parseFloat(item.participantCost)
                  : parseFloat(item.balance),
              pRegistrationStep: item.pRegistrationStep,
              planId: item.planId || "",
              additionalCosts,
              totalCost: item.balance,
              participants,
              discountInfo
            };
          }),
          paymentType: paymentData.value.paymentType,
          cardInfo: {
            token: paymentData.value.stripeToken,
            cardName: paymentData.value.cardName,
            number: paymentData.value.cardNumber,
            expMonth: paymentData.value.expMoth,
            expYear: paymentData.value.expyear,
            zipCode: paymentData.value.zipcode,
            last4: paymentData.value.stripeLast4
          },
          paymentInfo: tmpPaymentInfo,
          paymentDesc:
            formData.value.controls.description.value || paymentData.value.desc,
          creditChargeCustomAmount: customCharge,
          payingPartialAmount: payingPartialAmount()
        };

        const response = await ApiHelper.callApi(
          "post",
          `/balances/payBalances`,
          requestObj
        );
        if (response.status == 1) {
          ret = true;
          resetPaymentInfo();

          // close modal and refresh page
          await context.emit("setIsPaying", false);

          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          if (!showTransferModal()) {
            ApiHelper.showSuccessMessage("Pay successfully");
            await context.emit("closePaymentModal", {
              clearSelectedToPay: true
            });
          }
          context.emit("resetSelectedIds");
        } else {
          paymentData.value.paymentErrMessage =
            "Something went wrong with your credit card. Please check your card information and try again.";
        }
      } catch (error) {
        console.log(error);
      } finally {
        // this.enablePaymentForm();
        paymentData.value.paying = false;
        await context.emit("setIsPaying", false);
      }
      return ret;
    };

    const payNow = async () => {
      if (paymentData.value.paying) {
        return;
      }

      // reset
      paymentData.value.paymentErrMessage = "";
      creditChargeCustomError.value = false;
      paymentData.value.errors = {
        cardName: "",
        cardNumber: "",
        expMoth: "",
        expyear: "",
        cvc: "",
        zipcode: "",
        addonServiceId: ""
      };
      // validate
      let valid = true;
      const totalCost = paymentInfo.value.totalCost || 0;

      if (totalCost > 0 && paymentData.value.paymentType == "") {
        paymentData.value.paymentErrMessage = "Please select a payment option";
        valid = false;
      }
      if (paymentData.value.cardName == "") {
        paymentData.value.errors.cardName = "Name is required";
        valid = false;
      }
      if (paymentData.value.cardNumber == "") {
        paymentData.value.errors.cardNumber = "Card Number is required";
        valid = false;
      }
      if (paymentData.value.expMoth == "") {
        paymentData.value.errors.expMoth = "Exp Month is required";
        valid = false;
      }
      if (paymentData.value.expyear == "") {
        paymentData.value.errors.expyear = "Exp Year is required";
        valid = false;
      }
      if (paymentData.value.cvc == "") {
        paymentData.value.errors.cvc = "CVC is required";
        valid = false;
      }
      if (
        paymentData.value.zipcode != "" &&
        paymentData.value.zipcode.length < 5
      ) {
        paymentData.value.errors.zipcode = "Zipcode is invalid";
        valid = false;
      }

      if (!valid) return;

      if (
        paymentData.value.paymentType != "" &&
        ((selectedToPay.value.length &&
          (paymentInfo.value.totalCost || 0) > 0) ||
          payingPartialAmount())
      ) {
        paymentData.value.stripeToken = "";
        paymentData.value.paying = true;
        await context.emit("setIsPaying", true);

        try {
          Stripe.card.createToken(
            $("#stripeFrm"),
            async (status: any, response: any) => {
              //Stripe token failure...
              if (response.error) {
                let msg = "card name";
                if (response.error.param == "number") {
                  paymentData.value.stripeError = 2;
                  msg = "card number";
                } else if (response.error.param == "exp_month") {
                  paymentData.value.stripeError = 3;
                  msg = "month";
                } else if (response.error.param == "exp_year") {
                  paymentData.value.stripeError = 4;
                  msg = "year";
                } else if (response.error.param == "cvc") {
                  paymentData.value.stripeError = 5;
                  msg = "cvc";
                } else {
                  paymentData.value.stripeError = 1;
                }
              } else {
                paymentData.value.stripeToken = response.id;
                paymentData.value.stripeLast4 = response.card.last4;
                paymentData.value.stripeError = 0;
              }

              if (
                paymentData.value.stripeToken != "" &&
                paymentData.value.stripeError == 0
              ) {
                await doPay();
              } else {
                paymentData.value.paying = false;
                await context.emit("setIsPaying", false);
              }
            }
          );
        } catch (e) {
          await context.emit("setIsPaying", false);
          ApiHelper.showErrorMessage(
            "Your payment is unable to be accepted at this time. Please contact your camp administrator."
          );
        }
      }
    };

    const getPaymentInfo = async (
      selectedItems: any = [],
      options = {
        autoDiscount: false
      }
    ) => {
      let selected = selectedToPay.value;
      if (selectedItems.length > 0) {
        selected = selectedItems;
      }

      // if set auto apply discount
      if (options.autoDiscount) {
        for (const item of selected) {
          if (item.sourceType == 2) continue;
          const discountAmount = item.discountAmount || 0;
          if (discountAmount > 0) {
            item.participantCost -= discountAmount;
            item.participantCost = parseFloat(item.participantCost.toFixed(2));
            item.balance -= discountAmount;
            item.balance = parseFloat(item.balance.toFixed(2));
            item.totalCost -= discountAmount;
            item.totalCost = parseFloat(item.totalCost.toFixed(2));
            item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
          }
        }
      }

      if (selected.length > 0) {
        try {
          await context.emit("setIsPaying", true);
          let totalCost = 0;
          for (const i in selected) {
            const item: any = selected[i];
            if ((item.sourceType || 1) == 1) {
              // total of registration cost
              if (item.pRegistrationStep == 1) {
                totalCost += item.participantCost;

                // if using scholarship as a discount before
                if (
                  (item.paidAmount || 0) == 0 &&
                  (item.participantDiscountCost || 0) > 0
                ) {
                  totalCost -= item.participantDiscountCost;
                }
              } else if (item.pRegistrationStep > 1) {
                if (item.participants && item.participants.length) {
                  let totalBalance = 0;
                  for (const p of item.participants) {
                    totalBalance += p.balance || 0;
                  }
                  totalBalance = parseFloat(totalBalance.toFixed(2));
                  totalCost += totalBalance;
                } else {
                  totalCost += item.balance;
                }
              }
            } else if ((item.sourceType || 1) == 2) {
              totalCost += parseFloat(item.cost || 0) || 0;
            }
          }
          totalCost = parseFloat(totalCost.toFixed(2));

          let totalFund = 0;
          if (totalCost == 0) {
            // check if selected a fund?
            for (const item of selected) {
              if (
                (item.sourceType || 1) == 1 &&
                [1].includes(item.pRegistrationStep)
              ) {
                totalFund += parseFloat(item.totalFunds || 0);
                for (const fund of item.additionalCosts || []) {
                  totalFund += fund.cost;
                }
              }
              // else if ((item.sourceType || 1) == 2) {
              //   totalFund += isNaN(parseFloat(item.cost))
              //     ? 0
              //     : parseFloat(item.cost);
              // }
            }
            totalFund = parseFloat(totalFund.toFixed(2));
          }

          if (totalCost > 0 || totalCost + totalFund > 0) {
            const eventIds = selected.map((item: any) => {
              let tempIds = item.eventId || 0;
              if (item.participants && item.participants.length) {
                tempIds = item.participants
                  .map((p: any) => p.eventId)
                  .join(",");
              }
              return tempIds;
            });

            paymentData.value.paymentInfoLoading = true;
            const requestObj = {
              eventIds: eventIds.join(","),
              total: totalCost,
              hasDeposit: 0,
              participantsJSON: JSON.stringify(
                selected.map((item: any) => {
                  let total = 0;
                  let additionalCosts: any = [];
                  let participantIds = [];
                  let profileIds = [];
                  if (item.sourceType == 2) {
                    // fund bucket
                    total = parseFloat(item.cost);
                    additionalCosts = [];
                  } else {
                    // normal registrations
                    if (item.pRegistrationStep == 1) {
                      total = item.participantCost;
                      participantIds.push(item.participantId);
                      profileIds.push(item.profileId);

                      // if using scholarship as a discount before
                      if (
                        (item.paidAmount || 0) == 0 &&
                        (item.participantDiscountCost || 0) > 0
                      ) {
                        total -= item.participantDiscountCost;
                      }
                    } else if (item.pRegistrationStep > 1) {
                      if (item.participants && item.participants.length) {
                        let totalBalance = 0;
                        for (const p of item.participants) {
                          totalBalance += p.balance || 0;
                        }
                        totalBalance = parseFloat(totalBalance.toFixed(2));
                        total = totalBalance;
                        participantIds = item.participants.map(
                          (p: any) => p.participantId
                        );
                        profileIds = item.participants.map(
                          (p: any) => p.profileId
                        );
                      } else {
                        total = item.balance;
                        participantIds.push(item.participantId);
                        profileIds.push(item.profileId);
                      }
                    }

                    additionalCosts =
                      item.pRegistrationStep > 1
                        ? []
                        : (item.additionalCosts || []).map((item: any) => ({
                            serviceName: item.serviceName,
                            cost: item.cost,
                            chargeMonthly: item.chargeMonthly || 0
                          }));

                    // add total funds
                    if (
                      item.pRegistrationStep == 1 &&
                      (item.totalFunds || 0) > 0
                    ) {
                      additionalCosts.push({
                        serviceName: "Total Camp Store Funds",
                        cost: item.totalFunds,
                        isTotalFunds: true
                      });
                    }
                  }

                  return {
                    sourceType: item.sourceType,
                    eventId: item.eventId,
                    pTypeId: item.participantTypeId || 0,
                    participantIds: participantIds.join(","),
                    profileIds: profileIds.join(","),
                    total,
                    additionalCosts
                  };
                })
              )
            };

            const response = await ApiHelper.callApi(
              "get",
              `/balances/paymentInfo`,
              {},
              requestObj
            );

            if (response.status == 1) {
              paymentInfo.value = response.data.paymentInfo || {};
              usedDiscounts.value = response.data.usedDiscounts || [];
              discountsHasCode.value = response.data.discountsHasCode || [];

              // no allow pay monthly more if in selected to pay had a partial before
              const partialItem = selected.find(
                (item: any) =>
                  (item.pRegistrationStep || 0) == 3 ||
                  (item.pRegistrationStep || 0) == 4
              );

              if (
                partialItem ||
                (paymentInfo.value.recurringAmount || 0) == 0
              ) {
                paymentData.value.paymentType = "full";
                paymentInfo.value.recurringAmount = 0;
                paymentInfo.value.recurringAmountFormatted = "$0.00";
                paymentInfo.value.chargeMonths = 0;
                paymentInfo.value.chargeDays = 0;
                paymentInfo.value.deposit = 0;
                paymentInfo.value.depositFormatted = "$0.00";
                paymentInfo.value.firstChargeDate = "";
                paymentInfo.value.lastChargeDate = "";
              }

              // set stripe public key
              if ((paymentInfo.value.stripePublishableKey || "") != "") {
                if (typeof Stripe == "undefined") {
                  // load stripe
                  await $.getScript("https://js.stripe.com/v2/", function() {
                    // do nothing
                  }).fail(function() {
                    // console.log("Stripe load failes");
                  });
                }
                Stripe.setPublishableKey(
                  paymentInfo.value.stripePublishableKey
                );
              }
            } else {
              await context.emit("setIsPaying", false);
              const errorCode = response.errorCode || "";
              if (errorCode == "DIFF_FAMILY") {
                diffFamily.value = true;
              }
            }
          } else {
            paymentInfo.value = {};
          }
        } catch (error) {
          console.log(error);
        } finally {
          paymentData.value.paymentInfoLoading = false;
          await context.emit("setIsPaying", false);
        }
      }
    };

    const getPaymentInfoForCustom = () => {
      const transactionType = parseInt(formData.value.controls.type.value);
      if (transactionType != TransactionType.DebitCredit) return;

      if (
        paymentData.value.customAmount == "" ||
        (paymentData.value.customAmount != "" &&
          parseFloat(paymentData.value.customAmount) <= 0)
      ) {
        return false;
      }
      selectedToPay.value = buildCustomSelectedToPay(
        parseFloat(paymentData.value.customAmount)
      );
      if (selectedToPay.value.length == 0) return;

      getPaymentInfo(selectedToPay.value);
    };

    const fundCheckboxUpdate = async (item: any) => {
      if (
        !formData.value.controls.amount.value &&
        formData.value.controls.amountLabel
      ) {
        return;
      }
      const inputVal = parseFloat(item.inputVal) || 0;
      if (!item.checked) {
        item.error = "";
      }
      // check if should require amount or not
      // if have selected add-ons, no need to require amount
      const selectedAddOns = dynamicCosts.value.filter(
        (t: any) => t.checked && parseFloat(t.inputVal) > 0
      );
      formData.value.controls.amount.required = selectedAddOns.length
        ? false
        : true;
      if (!formData.value.controls.amount.required) {
        formData.value.controls.amount.error = "";
      }
      const eventId = parseInt(formData.value.controls.event.value);
      const eventName =
        formData.value.controls.event.options.find(t => t.id == eventId)
          ?.text || "";
      const participantId = parseInt(formData.value.controls.participant.value);
      const participant = participants.value.find(
        item => item.participantId == participantId
      );
      const transactionTypeId =
        parseInt(formData.value.controls.type.value) || 0;
      if (transactionTypeId != TransactionType.DebitCredit) {
        return;
      }
      // update selectedToPay
      selectedToPay.value = selectedToPay.value.filter(
        (t: any) => (t.addonServiceId || 0) != item.addonServiceId
      );
      if (item.checked && inputVal > 0) {
        selectedToPay.value.push({
          sourceType: 2,
          eventId,
          evName: eventName,
          participantId,
          participantTypeId: participant.participantTypeId || 0,
          profileId: participant.profileId,
          cost: inputVal,
          balance: inputVal,
          addonServiceId: item.addonServiceId,
          serviceName: item.serviceName,
          firstName: participant.firstName || "",
          lastName: participant.lastName || ""
        });
      }
    };

    const onChangeTransactionType = async () => {
      const participantId =
        parseInt(formData.value.controls.participant.value) || 0;
      const findParticipant = participants.value.find(
        item => item.participantId == participantId
      );

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const doneApp = await checkIfDoneApp(findParticipant);
      if (!doneApp) {
        formData.value.controls.type.value = "";
        return;
      }

      // reset
      formData.value.controls.amountLabel = "";
      paymentData.value.paymentErrMessage = "";
      paymentData.value.errors = {
        cardName: "",
        cardNumber: "",
        expMoth: "",
        expyear: "",
        cvc: "",
        zipcode: "",
        addonServiceId: ""
      };
      formData.value.controls.amount.error = "";
      let eventId = 0;
      let profileId = 0;
      if (props.callFrom == "ProfileFinancial") {
        eventId = parseInt(formData.value.controls.event.value);
        profileId = props.profileId || 0;
      } else if (props.callFrom == "Transactions") {
        eventId = parseInt(formData.value.controls.event.value);
        profileId = inputData.value.profileId || 0;
      } else if (
        [
          "EventFinancialPage",
          "EventParticipantsPage",
          "ParticipantFinancialPage"
        ].includes(props.callFrom)
      ) {
        eventId = props.eventId || 0;
        profileId = inputData.value.profileId || 0;
      }

      if (eventId == 0 || profileId == 0) return;

      const transactionType = parseInt(formData.value.controls.type.value);
      // const inputtedAmount =
      //   parseFloat(formData.value.controls.amount.value) || 0;

      // require/not require some fields
      formData.value.controls.amount.required = true;
      formData.value.controls.description.required = true;
      if (transactionType == TransactionType.Scholarship) {
        // no need to require amount if using a scholarship
        formData.value.controls.amount.required = false;
        // no need description
        formData.value.controls.description.required = false;
      }

      // reset all discounts
      // if type is writeoff/refund
      if (
        [TransactionType.Refund, TransactionType.WriteOff].includes(
          transactionType
        ) ||
        fullPaid(findParticipant)
      ) {
        appliedDiscounts.value = [];
      }

      if (transactionType == TransactionType.DebitCredit) {
        // const participantId = parseInt(
        //   formData.value.controls.participant.value
        // );
        const result = await ApiHelper.callApi(
          "get",
          `/balances/outstanding`,
          {},
          {
            participantId
          }
        );
        if (result.status == 1) {
          if (result.data.items.length) {
            const outstandingItem = result.data.items[0];
            if ((outstandingItem.participants || []).length) {
              selectedToPay.value = outstandingItem.participants.filter(
                (item: any) => item.participantId == participantId
              );
              payForPlan.value = "payonly";
            } else {
              selectedToPay.value = [outstandingItem];
            }
            updateAmountLabel(selectedToPay.value[0].balance || 0);
          }

          if (selectedToPay.value.length) {
            creditCharge.value = true;
            // no require a description if has balance in this case
            formData.value.controls.description.required = false;
            formData.value.controls.description.error = "";

            // apply auto discount
            // const selected = JSON.parse(JSON.stringify(selectedToPay.value));
            // let totalDiscountNoCode = 0;
            // const appliedFor = [];

            // if (inputtedAmount > 0) {
            //   for (const item of selected) {
            //     const discountAmount = item.discountAmount || 0;
            //     if (discountAmount > 0) {
            //       // totalDiscountNoCode += discountAmount;
            //       item.participantCost -= discountAmount;
            //       item.participantCost = parseFloat(
            //         item.participantCost.toFixed(2)
            //       );
            //       item.balance -= discountAmount;
            //       item.balance = parseFloat(item.balance.toFixed(2));
            //       item.totalCost -= discountAmount;
            //       item.totalCost = parseFloat(item.totalCost.toFixed(2));
            //       item.totalCostFormatted = ApiHelper.dollarFormat(
            //         item.totalCost
            //       );
            //       // appliedFor.push(item.participantId);

            //       // save applied discount
            //       const discount = item.discount || null;
            //       if (discount) {
            //         appliedDiscounts.value.push({
            //           eventId: discount.eventId,
            //           pTypeId: discount.pTypeId,
            //           discountCode: "",
            //           discountAmount: discount.discountAmount,
            //           discountId: discount.discountId,
            //           discountName: discount.discountName,
            //           discountType: discount.discountType,
            //           appliedFor: [item.participantId]
            //         });
            //       }
            //     }
            //   }
            // }

            // // save applied discount
            // if (totalDiscountNoCode > 0) {
            //   appliedDiscounts.value.push({
            //     discountId: 0,
            //     discountCode: "",
            //     discountAmount: parseFloat(totalDiscountNoCode.toFixed(2)),
            //     appliedFor
            //   });
            // }
            const selected = JSON.parse(JSON.stringify(selectedToPay.value));
            // re-apply discounts
            if (
              findParticipant &&
              !fullPaid(findParticipant)
              // && !partialPaid(findParticipant)
            ) {
              for (const item of selected) {
                for (const discount of appliedDiscounts.value) {
                  const inList = (discount.appliedFor || []).find(
                    (id: number) => id == item.participantId
                  );
                  if (inList) {
                    if (!partialPaid(findParticipant)) {
                      item.participantCost -= discount.discountAmount;
                      item.participantCost = parseFloat(
                        item.participantCost.toFixed(2)
                      );
                      item.balance -= discount.discountAmount;
                      item.balance = parseFloat(item.balance.toFixed(2));
                      item.totalCost -= discount.discountAmount;
                      item.totalCost = parseFloat(item.totalCost.toFixed(2));
                      item.totalCostFormatted = ApiHelper.dollarFormat(
                        item.totalCost
                      );
                    } else {
                      // partial paid, and apply more discount after paid
                      const amount = parseFloat(
                        (item.balance - discount.discountAmount).toFixed(2)
                      );
                      if (amount >= 0) {
                        item.balance -= discount.discountAmount;
                        item.balance = parseFloat(item.balance.toFixed(2));
                        item.totalCost -= discount.discountAmount;
                        item.totalCost = parseFloat(item.totalCost.toFixed(2));
                        item.totalCostFormatted = ApiHelper.dollarFormat(
                          item.totalCost
                        );
                      }
                    }
                  }
                }
              }
            }

            await getPaymentInfo(selected);

            // fill amount if balance > 0
            // if ((paymentInfo.value.totalCost || 0) > 0) {
            //   formData.value.controls.amount.value =
            //     paymentInfo.value.totalCost;
            // }
            if (formData.value.controls.isAddAmount) {
              formData.value.controls.amount.value =
                paymentInfo.value.totalCost || 0;
            }
          } else {
            // cannot charge because this selected profile has balance = 0 for selected event
            // new: still show payment model to input a custom amount
            creditCharge.value = true;
            creditChargeCustomAmount.value = true;
            paymentData.value.desc = formData.value.controls.description.value;
            paymentData.value.customAmount =
              formData.value.controls.amount.value;
            formData.value.controls.description.required = true;
            if (paymentData.value.customAmount != "") {
              getPaymentInfoForCustom();
            }
          }

          // if selected addons, get paymentInfo also
          const selectedAddOns = dynamicCosts.value.filter(
            (t: any) => t.checked && parseFloat(t.inputVal) > 0
          );
          if (selectedAddOns.length) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            // await fundCheckboxChange(selectedAddOns[0]);
            for (const i in selectedAddOns) {
              await fundCheckboxUpdate(selectedAddOns[i]);
            }
            const selected = JSON.parse(JSON.stringify(selectedToPay.value));
            // apply discounts
            const discountHasCode = appliedDiscounts.value.filter(
              (item: any) => item.discountCode != ""
            );
            for (const item of selected) {
              if (item.sourceType == 2) continue;
              const discountAmount = item.discountAmount || 0;
              if (discountAmount > 0) {
                item.participantCost -= discountAmount;
                item.participantCost = parseFloat(
                  item.participantCost.toFixed(2)
                );
                item.balance -= discountAmount;
                item.balance = parseFloat(item.balance.toFixed(2));
                item.totalCost -= discountAmount;
                item.totalCost = parseFloat(item.totalCost.toFixed(2));
                item.totalCostFormatted = ApiHelper.dollarFormat(
                  item.totalCost
                );
              }
              for (const discountItem of discountHasCode) {
                item.participantCost -= discountItem.discountAmount;
                item.participantCost = parseFloat(
                  item.participantCost.toFixed(2)
                );
                item.balance -= discountItem.discountAmount;
                item.balance = parseFloat(item.balance.toFixed(2));
                item.totalCost -= discountItem.discountAmount;
                item.totalCost = parseFloat(item.totalCost.toFixed(2));
                item.totalCostFormatted = ApiHelper.dollarFormat(
                  item.totalCost
                );
              }
            }
            await getPaymentInfo(selected);
          }
        }
      } else {
        // reset
        creditCharge.value = false;
        creditChargeCustomAmount.value = false;
        paymentData.value.customAmount = "";
        selectedToPay.value = [];
        if (
          transactionType == TransactionType.Refund ||
          transactionType == TransactionType.Scholarship
        ) {
          dynamicCosts.value = dynamicCosts.value.map((item: any) => ({
            ...item,
            inputVal: item.isFundBucket ? "" : item.serviceCost,
            checked: false,
            error: ""
          }));
        }

        if (findParticipant) {
          if (transactionType == TransactionType.Refund) {
            // make sure not auto fill for amount if this participant was refunded before
            if ((findParticipant.refundAmount || 0) > 0) {
              formData.value.controls.amount.value = "";
            }

            // update amountLabel is total paid
            const totalPaid =
              (findParticipant.transactionAmount || 0) +
              (findParticipant.fundingAmount || 0) -
              (findParticipant.refundAmount || 0);
            updateAmountLabel(parseFloat(totalPaid.toFixed(2)));
          } else {
            const balance = findParticipant.balance || 0;
            updateAmountLabel(balance);

            if (fullPaid(findParticipant)) {
              // || partialPaid(findParticipant)
              return;
            }

            if (transactionType == TransactionType.WriteOff) {
              return;
            }

            // re-apply discounts
            let amount = parseFloat(formData.value.controls.amount.value) || 0;
            if (amount > 0 && appliedDiscounts.value.length) {
              for (const discount of appliedDiscounts.value) {
                const inList = (discount.appliedFor || []).find(
                  (id: number) => id == findParticipant.participantId
                );
                if (inList) {
                  amount -= discount.discountAmount || 0;
                  amount = parseFloat(amount.toFixed(2));
                }
              }

              if (
                !partialPaid(findParticipant) ||
                (partialPaid(findParticipant) && amount >= 0)
              ) {
                formData.value.controls.amount.value = `${amount}`;
              }
            }
          }
        }
      }
    };

    const allowAddTransaction = () => {
      if (props.type == "refund") return true;

      // if (props.callFrom == "ProfileFinancial") {
      //   const transactionType = parseInt(formData.value.controls.type.value);
      //   const amount = parseFloat(formData.value.controls.amount.value);
      //   if (transactionType == TransactionType.Scholarship) {
      //     // no need to check amount when using scholarship
      //     return true;
      //   }
      //   if (formData.value.controls.amount.required && amount <= 0) {
      //     return false;
      //   }
      // }

      return true;
    };

    const getTotalRefund = () => {
      // calculate based on refundInfo
      let refundInfo = JSON.parse(JSON.stringify(props.refundInfo));
      refundInfo = refundInfo
        .map((item: any) => ({
          ...item,
          details: item.details.filter((p: any) => p.checked)
        }))
        .filter((item: any) => item.details.length > 0);
      let refundAmount = 0;
      if (refundInfo.length) {
        for (const item of refundInfo) {
          for (const detail of item.details) {
            refundAmount +=
              detail.refundAmount == "" || isNaN(detail.refundAmount)
                ? 0
                : parseFloat(detail.refundAmount);
          }
        }
      }
      refundAmount = parseFloat(refundAmount.toFixed(2));

      return {
        refundAmount,
        refundAmountFormatted: dollarFormat(refundAmount)
      };
    };

    const doRefund = async () => {
      // validate
      let valid = true;
      formData.value.controls.description.error = "";
      // require a description
      if (formData.value.controls.description.value == "") {
        valid = false;
        formData.value.controls.description.error = "Description is required!";
      }
      // refund amount cannot greatert than paid amount
      const propsRefundInfo: any = props.refundInfo[0];
      let hasChecked = false;
      for (const groupped of propsRefundInfo.groupped) {
        for (const detail of groupped.items) {
          // reset
          detail.hasError = false;
          // validate
          if (detail.checked) {
            hasChecked = true;
            if (
              detail.refundAmount == "" ||
              detail.refundAmount == 0 ||
              isNaN(detail.refundAmount) ||
              detail.refundAmount > detail.totalPaid
            ) {
              detail.hasError = true;
              if (detail.refundAmount > detail.totalPaid) {
                ApiHelper.showErrorMessage(
                  "Refund amount cannot be more than the paid amount",
                  "Oops"
                );
              }
              valid = false;
            }
          }
        }
      }
      if (!hasChecked) {
        valid = false;
        formData.value.controls.refund.error = "Participant is required";
      }
      if (!valid) return;

      try {
        formData.value.isLoading = true;
        context.emit("setIsPaying", true);
        let refundInfo = JSON.parse(JSON.stringify(props.refundInfo));
        refundInfo = refundInfo
          .map((item: any) => {
            const groupped: any = [];
            for (const group of item.groupped) {
              const groupItems = group.items
                .filter((t: any) => t.checked)
                .map((t: any) => ({
                  ...t,
                  refundAmount: parseFloat(t.refundAmount)
                }));
              if (groupItems.length) {
                groupped.push({
                  transactionId: group.transactionId,
                  transactionTypeId: group.transactionTypeId || 0,
                  items: groupItems
                });
              }
            }

            return {
              ...item,
              details: [],
              refundLines: [],
              groupped
            };
          })
          .filter((item: any) => item.groupped.length > 0);
        if (refundInfo.length == 0) return;

        const requestObject = {
          isRefund: true,
          amount: getTotalRefund().refundAmount,
          chargeId: refundInfo[0].transactionId,
          eventId: 0,
          profileId: 0,
          participantId: 0,
          transactionTypeId: refundInfo[0].transactionTypeId,
          transactionDescription: formData.value.controls.description.value,
          refundInfo
        };

        const result = await ApiHelper.callApi(
          "post",
          "/transactions",
          requestObject,
          {}
        );
        if (result.status === 1) {
          ApiHelper.showSuccessMessage("Refunded successfully");
          if (props.onDismiss) {
            props.onDismiss();
          }
          if (props.onCallback) {
            props.onCallback();
          }
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      } catch (error) {
        // console.log(error);
      } finally {
        formData.value.isLoading = false;
        context.emit("resetSelectedIds");
        context.emit("setIsPaying", false);
      }
    };

    const addTransaction = async () => {
      if (props.type == "refund") {
        doRefund();
        return;
      }

      const transactionType = parseInt(formData.value.controls.type.value);
      const findParticipant = participants.value.find(item => {
        return (
          formData.value.controls.participant.value ==
          item.participantId.toString()
        );
      });

      // some alert messages
      // participant was denied, and refund full
      if (
        participants.value.length == 0 &&
        (participantInfo.value.pStatus || 0) == ParticipantStatus.Denied &&
        (participantInfo.value.paidAmount || 0) > 0 &&
        (participantInfo.value.paidAmount || 0) ==
          (participantInfo.value.refundAmount || 0)
      ) {
        await context.root.$swal({
          html:
            "Cannot add transaction. This participant was denied, and refunded in full for this event.",
          icon: "warning",
          confirmButtonColor: "#3085d6"
        });
        return;
      }

      // prevent add transaction when paid in full
      const amount = parseFloat(formData.value.controls.amount.value) || 0;
      if (
        transactionType != TransactionType.Refund &&
        // transactionType != TransactionType.WriteOff &&
        amount > 0 &&
        (findParticipant?.transactionAmount || 0) > 0 &&
        (findParticipant?.balance || 0) <= 0
      ) {
        await context.root.$swal({
          html: `${ApiHelper.getFullName(
            findParticipant.firstName,
            findParticipant.lastName
          )} is paid in full for this event.`,
          icon: "warning",
          confirmButtonColor: "#3085d6"
        });
        return;
      }

      // prevent overpayment for an event
      if (payForPlan.value == "payonly") {
        // just paying for a participant
        let finalBalance = findParticipant?.balance || 0;
        let totalDiscounts = 0;
        for (const item of appliedDiscounts.value) {
          totalDiscounts += item.discountAmount;
        }
        totalDiscounts = parseFloat(totalDiscounts.toFixed(2));
        finalBalance -= totalDiscounts;
        if (
          transactionType != TransactionType.Refund &&
          // transactionType != TransactionType.WriteOff &&
          ((!fullPaid(findParticipant) &&
            !partialPaid(findParticipant) &&
            amount > finalBalance) ||
            (fullPaid(findParticipant) && amount > 0))
        ) {
          let errorMessage = `Cannot pay overpayment for this event.`;
          if (transactionType == TransactionType.WriteOff) {
            errorMessage = "Write off amount is greater than due amount.";
          }
          ApiHelper.showErrorMessage(errorMessage);
          return;
        }
      } else if (payForPlan.value == "payfull") {
        // pay for all participants in plan
      }

      // prevent if refund amount > paid amount
      if (findParticipant) {
        let totalPaid =
          (findParticipant.transactionAmount || 0) +
          (findParticipant.fundingAmount || 0) -
          (findParticipant.refundAmount || 0);
        totalPaid = parseFloat(totalPaid.toFixed(2));
        if (
          transactionType == TransactionType.Refund &&
          (amount || 0) > 0 &&
          amount > totalPaid
        ) {
          ApiHelper.showErrorMessage(
            "Refund amount is greater than paid amount"
          );
          return;
        }
      }

      const isValid = validateForm(formData.value);
      if (!isValid) {
        return;
      }

      // make sure participant has finished application before add payment
      if (
        findParticipant &&
        findParticipant.pRegistrationStep == 0 &&
        isTransfer() == false
      ) {
        // should fill application before payment
        const confirm = await context.root.$swal({
          html:
            "Payment is not authorized until the participant's application is complete. Would you like to complete the application?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Complete Application",
          customClass: {
            container: "swal2-actions-p0",
            denyButton: "swal2-blue-btn"
          }
        });
        if (confirm.isConfirmed) {
          context.emit("showPopupEditApplication", {
            profileId: findParticipant.profileId,
            participantId: findParticipant.participantId
          });
        }
        return;
      }

      try {
        if (transactionType != TransactionType.DebitCredit) {
          formData.value.isLoading = true;
          context.emit("setIsPaying", true);
          const addOns = dynamicCosts.value
            .filter(
              (item: any) =>
                (item.checked || false) == true &&
                parseFloat(item.inputVal || 0) > 0
            )
            .map((item: any) => ({
              ...item,
              inputVal: parseFloat(item.inputVal)
            }));

          const justApplyDiscount =
            !addOns.length &&
            appliedDiscounts.value.length &&
            inputData.value.amount == 0
              ? true
              : false;

          const requestObject: any = {
            amount: inputData.value.amount,
            eventId: inputData.value.eventId,
            participantId: inputData.value.participantId,
            profileId: inputData.value.profileId,
            transactionDescription: inputData.value.transactionDescription,
            transactionTypeId:
              isTransfer() == false
                ? inputData.value.transactionTypeId
                : TransactionType.Transfer,
            // addonServiceId: paymentData.value.addonServiceId,
            addOns,
            justApplyDiscount: justApplyDiscount ? 1 : 0,
            fullPaid: findParticipant && fullPaid(findParticipant) ? 1 : 0
          };
          if (isTransfer()) {
            requestObject.transferInfo = {
              transactionId: props.selectedFunding.transactionId,
              campStoreCredit: inputData.value.amount,
              campStoreCreditFormatted: dollarFormat(inputData.value.amount),
              fromParticipantId: inputData.value.participantId,
              fromEventId: inputData.value.eventId,
              fromProfileId: inputData.value.profileId,
              familyId: familyOwner.value.familyId,
              familyOwnerId: familyOwner.value.profileId,
              familyOwnerFullName: ApiHelper.getFullName(
                familyOwner.value.firstName,
                familyOwner.value.lastName
              ),
              currentBalance: props.selectedFunding.currentBalance
            };
          }

          if (appliedDiscounts.value.length) {
            // if applied a discount, use final amount for this transaction
            // requestObject.amount = appliedDiscounts.value.finalAmount;
            // requestObject.discount = appliedDiscounts.value.filter(
            //   (item: any) => item.discountCode != ""
            // );

            // const discountHasNoCode = appliedDiscounts.value.find(
            //   (item: any) => item.discountCode == ""
            // );
            // if (
            //   discountHasNoCode
            //   // && (findParticipant.discountAmount || 0) > 0
            // ) {
            //   const itemDiscount = { ...findParticipant.discount };
            //   delete itemDiscount.totalUsed;
            //   requestObject.discount.unshift(itemDiscount);
            // }
            requestObject.discount = appliedDiscounts.value
              .filter((item: any) =>
                (item.appliedFor || []).includes(inputData.value.participantId)
              )
              .map((item: any) => {
                delete item.totalUsed;
                delete item.appliedFor;
                return item;
              });
          }

          const result = await ApiHelper.callApi(
            "post",
            "/transactions",
            requestObject,
            {}
          );
          formData.value.isLoading = false;
          context.emit("setIsPaying", false);
          if (result.status === 1) {
            let message = "Created a new payment";
            if (transactionType == TransactionType.Refund) {
              message = "Added refund successfully";
            } else if (isTransfer()) {
              message = "Transfered successfully";
            }

            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            if (!showTransferModal()) {
              ApiHelper.showSuccessMessage(message);
              if (props.onDismiss) {
                props.onDismiss();
              }
            }
            if (props.onCallback) {
              props.onCallback();
            }
            context.emit("resetSelectedIds");
          } else {
            ApiHelper.showErrorMessage(result.message);
          }
        } else {
          // pay by debit/credit
          payNow();
        }
      } catch (error) {
        // console.log(error);
      } finally {
        formData.value.isLoading = false;
      }
    };

    const onChangeAmount = async () => {
      const transactionType = parseInt(formData.value.controls.type.value);
      const amount = parseFloat(formData.value.controls.amount.value);
      if (transactionType != TransactionType.Scholarship && amount <= 0) {
        appliedDiscounts.value = [];
      }

      if (
        creditChargeCustomAmount.value == true ||
        paymentData.value.addonServiceId > 0
      ) {
        // charge a custom amount when balance = 0
        paymentData.value.customAmount = formData.value.controls.amount.value;
        if (paymentData.value.customAmount != "") {
          await getPaymentInfoForCustom();
          if (paymentData.value.addonServiceId > 0) {
            paymentData.value.paymentType = "full";
          }
        }
      } else if (payingPartialAmount()) {
        // charge a custom amount when balance still > 0
        paymentData.value.paymentType = "full";
        formData.value.controls.description.required = true;
      } else if (chargeByCard()) {
        formData.value.controls.description.required = false;
      }
    };

    const onChangeFundBucket = () => {
      // reset amount
      formData.value.controls.amount.value = "";
      paymentInfo.value = {};
      const transactionType = parseInt(formData.value.controls.type.value);
      const participantId = formData.value.controls.participant.value;

      if (paymentData.value.addonServiceId > 0) {
        // auto switch to pay full if want to pay for a fund bucket
        paymentData.value.paymentType = "full";
        appliedDiscounts.value = [];
      } else {
        if (transactionType == TransactionType.DebitCredit) {
          onChangeTransactionType();
        } else {
          const participant = participants.value.find(
            t => t.participantId == participantId
          );
          const balance = participant?.balance || 0;
          if (balance > 0) {
            formData.value.controls.amount.value = balance;
          }
        }
      }
    };

    const getRefundInfo = async () => {
      const refundInfo: any = props.refundInfo[0];
      const requestObject: any = {
        getInfo: "refundInfo",
        transactionTypeId: refundInfo.transactionTypeId,
        transactionIds: refundInfo.transactionIds || undefined
      };
      if ((refundInfo.externalPlanId || "") != "") {
        requestObject.planId = refundInfo.transactionId;
        requestObject.externalPlanId = refundInfo.externalPlanId;
        refundInfo.transactionTypeId = TransactionType.DebitCredit;
      } else {
        requestObject.transactionId = refundInfo.transactionId;
      }

      try {
        context.emit("setIsPaying", true);
        let result: any = {};
        const isFundBucketRefund = refundInfo.fundBucketRefund || false;
        if (isFundBucketRefund) {
          // case get refund info of a fundbucket (can have many related differents transactions)
          result = await ApiHelper.callApi(
            "get",
            `/finances/fundtransactions`,
            {},
            {
              getAll: 1,
              profileId: refundInfo.profileId,
              participantId: refundInfo.participantId,
              servicesIds: refundInfo.addonServiceId,
              getInfo: "transactions"
            }
          );
        } else {
          // refund for a normal transaction/plan
          result = await ApiHelper.callApi(
            "get",
            "/transactions/details",
            {},
            requestObject
          );
        }

        if (result.status == 1) {
          const details = !isFundBucketRefund
            ? result.data || []
            : result.data.fundBuckets[0].transactions;
          const detailLines = details.detailLines || [];
          refundInfo.details = detailLines.map((p: any) => ({
            ...p,
            totalPaidFormatted: dollarFormat(p.totalPaid),
            refundAmount: p.totalPaid || 0,
            checked:
              ((p.pStatus || 0) == ParticipantStatus.Denied ||
                detailLines.length == 1) &&
              (p.totalPaid || 0) > 0
                ? true
                : false,
            hasError: false
          }));

          const participantIds = refundInfo.details.filter(
            (item: any) => item.participantId > 0
          );
          campStoreCreditsRefund.value =
            participantIds.length == 0 ? true : false;

          // calculate total paid for details
          let totalPaid = 0;
          for (const item of refundInfo.details) {
            totalPaid += item.totalPaid;
          }
          totalPaid = parseFloat(totalPaid.toFixed(2));
          refundInfo.totalPaid = totalPaid;
          refundInfo.totalPaidFormatted = dollarFormat(totalPaid);

          // if partial refuned before, calculate to show amount can refund more
          refundInfo.refundedAmount = 0;
          refundInfo.canRefundAmount = refundInfo.totalPaid;
          refundInfo.refundLines = details.refundLines || [];
          if (refundInfo.refundLines.length) {
            let refundedAmount = 0;
            for (const item of refundInfo.refundLines) {
              refundedAmount += item.refundAmount;
            }
            refundedAmount = parseFloat(refundedAmount.toFixed(2));
            refundInfo.refundedAmount = refundedAmount;
            refundInfo.refundedAmountFormatted = dollarFormat(refundedAmount);
            refundInfo.canRefundAmount = parseFloat(
              (refundInfo.totalPaid - refundInfo.refundedAmount).toFixed(2)
            );
            refundInfo.canRefundAmountFormatted = dollarFormat(
              refundInfo.canRefundAmount
            );

            // calculate to show amount can refund more
            for (const item of refundInfo.details) {
              const relatedRefund = refundInfo.refundLines.filter(
                (t: any) =>
                  t.participantId == item.participantId &&
                  t.profileId == item.profileId &&
                  t.addonServiceId == item.addonServiceId &&
                  t.parentId == item.transactionId
              );

              if (relatedRefund.length) {
                for (const t of relatedRefund) {
                  item.totalPaid -= t.refundAmount;
                  item.totalPaid = parseFloat(item.totalPaid.toFixed(2));
                  item.totalPaidFormatted = dollarFormat(item.totalPaid);
                  item.refundAmount = item.totalPaid;
                }
              }

              // uncheck, and disable if cannot refund
              if (item.totalPaid == 0) {
                item.checked = false;
              }
            }
          }

          // group details by transaction id
          const groupped: any = [];
          for (const item of refundInfo.details) {
            const transactionId =
              item.externalTransactionID || item.transactionId;
            const inGroup = groupped.find(
              (t: any) => t.transactionId == transactionId
            );
            if (!inGroup) {
              const groupItems = refundInfo.details.filter(
                (detail: any) =>
                  (detail.externalTransactionID || detail.transactionId) ==
                  transactionId
              );
              const group: any = {
                transactionId,
                items: groupItems
              };
              if (isFundBucketRefund && groupItems.length) {
                group.transactionTypeId = groupItems[0].transactiontypeId || 0;
              }

              groupped.push(group);
            }
          }
          refundInfo.groupped = groupped;
        }
      } catch (error) {
        // console.log(error);
      } finally {
        context.emit("setIsPaying", false);
      }
    };

    const updateSelectedToPay = async () => {
      appliedDiscounts.value = [];
      if (payForPlan.value == "payfull") {
        // selectedToPaybk.value = selectedToPay.value;
        selectedToPay.value = outstandingBalanceItems.value;
      } else {
        // selectedToPay.value = selectedToPaybk.value;
        const participantId = parseInt(
          formData.value.controls.participant.value
        );
        selectedToPay.value = outstandingBalanceItems.value[0].participants.filter(
          (item: any) => item.participantId == participantId
        );
      }

      if (selectedToPay.value.length) {
        await getPaymentInfo(selectedToPay.value);
      }

      // update amountLabel
      const totalCost = paymentInfo.value.totalCost || 0;
      formData.value.controls.amount.value = "";
      formData.value.controls.amountLabel = totalCost.toFixed(2);
      formData.value.controls.isAddAmount = false;
    };

    const showInPlanNotes = () => {
      if (
        parseInt(formData.value.controls.type.value) ==
          TransactionType.DebitCredit &&
        outstandingBalanceItems.value.length &&
        (outstandingBalanceItems.value[0].participants || []).length > 1
      ) {
        return true;
      }
      return false;
    };

    const fundTxtChange = (item: any) => {
      if (parseFloat(item.inputVal) > 0) {
        // auto select for this funding line
        item.checked = true;
        item.error = "";
      } else {
        item.checked = false;
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fundCheckboxChange(item, false);
    };

    const fundCheckboxChange = async (item: any, checkInputValue = true) => {
      if (
        !formData.value.controls.amount.value &&
        formData.value.controls.amountLabel
      ) {
        return;
      }
      const inputVal = parseFloat(item.inputVal) || 0;
      if (!item.checked) {
        item.error = "";
      }
      if (checkInputValue && inputVal == 0) return;

      // check if should require amount or not
      // if have selected add-ons, no need to require amount
      const selectedAddOns = dynamicCosts.value.filter(
        (t: any) => t.checked && parseFloat(t.inputVal) > 0
      );
      formData.value.controls.amount.required = selectedAddOns.length
        ? false
        : true;
      if (!formData.value.controls.amount.required) {
        formData.value.controls.amount.error = "";
      }

      const eventId = parseInt(formData.value.controls.event.value);
      const eventName =
        formData.value.controls.event.options.find(t => t.id == eventId)
          ?.text || "";
      const participantId = parseInt(formData.value.controls.participant.value);
      const participant = participants.value.find(
        item => item.participantId == participantId
      );
      const transactionType = parseInt(formData.value.controls.type.value) || 0;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      checkIfRequireDesc();

      if (transactionType != TransactionType.DebitCredit) {
        return;
      }

      // update selectedToPay
      selectedToPay.value = selectedToPay.value.filter(
        (t: any) => (t.addonServiceId || 0) != item.addonServiceId
      );
      if (item.checked && inputVal > 0) {
        selectedToPay.value.push({
          sourceType: 2,
          eventId,
          evName: eventName,
          participantId,
          participantTypeId: participant.participantTypeId || 0,
          profileId: participant.profileId,
          cost: inputVal,
          balance: inputVal,
          addonServiceId: item.addonServiceId,
          serviceName: item.serviceName,
          firstName: participant.firstName || "",
          lastName: participant.lastName || ""
        });
      }
      const selected = JSON.parse(JSON.stringify(selectedToPay.value));

      // apply discounts
      const discountHasCode = appliedDiscounts.value.filter(
        (item: any) => item.discountCode != ""
      );
      for (const item of selected) {
        if (item.sourceType == 2) continue;
        const discountAmount = item.discountAmount || 0;
        if (discountAmount > 0) {
          item.participantCost -= discountAmount;
          item.participantCost = parseFloat(item.participantCost.toFixed(2));
          item.balance -= discountAmount;
          item.balance = parseFloat(item.balance.toFixed(2));
          item.totalCost -= discountAmount;
          item.totalCost = parseFloat(item.totalCost.toFixed(2));
          item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
        }
        for (const discountItem of discountHasCode) {
          item.participantCost -= discountItem.discountAmount;
          item.participantCost = parseFloat(item.participantCost.toFixed(2));
          item.balance -= discountItem.discountAmount;
          item.balance = parseFloat(item.balance.toFixed(2));
          item.totalCost -= discountItem.discountAmount;
          item.totalCost = parseFloat(item.totalCost.toFixed(2));
          item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
        }
      }
      await getPaymentInfo(selected);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      checkIfRequireDesc();
    };

    const showFunding = () => {
      const transactionType = parseInt(formData.value.controls.type.value) || 0;
      if (
        dynamicCosts.value.length &&
        ![
          TransactionType.Refund,
          TransactionType.Scholarship,
          TransactionType.WriteOff
        ].includes(transactionType) &&
        formData.value.controls.toggleTransfer.value == false
      ) {
        return true;
      }
      return false;
    };

    const onClickAddAmount = async () => {
      formData.value.controls.amount.value =
        formData.value.controls.amountLabel;
      formData.value.controls.amountLabel = "";
      formData.value.controls.isAddAmount = true;

      if (payForPlan.value == "payfull") {
        formData.value.controls.amount.error = "";
        return;
      }

      onChangeTransactionType();
    };

    const allowInputAmount = () => {
      const transactionTypeId =
        parseInt(formData.value.controls.type.value) || 0;
      if (
        transactionTypeId == TransactionType.DebitCredit &&
        payForPlan.value == "payfull"
      ) {
        return false;
      }

      return true;
    };

    const checkIfRequireDesc = () => {
      const amount = parseFloat(formData.value.controls.amount.value) || 0;
      const transactionType = parseInt(formData.value.controls.type.value);
      const selectedAddOns = dynamicCosts.value.filter(
        (t: any) => t.checked && parseFloat(t.inputVal) > 0
      );
      // no need to force description if just pay for addon
      if (amount == 0 && selectedAddOns.length) {
        formData.value.controls.description.required = false;
        formData.value.controls.description.error = "";
      } else {
        formData.value.controls.description.required =
          transactionType == TransactionType.DebitCredit &&
          selectedToPay.value.length
            ? false
            : true;
      }
    };

    const checkIfDoneApp = async (participant: any) => {
      if (participant && participant.pRegistrationStep == 0) {
        // should fill application before payment
        const confirm = await context.root.$swal({
          html:
            "Payment is not authorized until the participant's application is complete. Would you like to complete the application?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Complete Application",
          customClass: {
            container: "swal2-actions-p0",
            denyButton: "swal2-blue-btn"
          }
        });
        if (confirm.isConfirmed) {
          context.emit("showPopupEditApplication", {
            profileId: participant.profileId,
            participantId: participant.participantId
          });
        }

        return false;
      }

      return true;
    };

    // init data
    (async () => {
      if (props.type == "refund") {
        await getRefundInfo();
        setTimeout(() => {
          Inputmask("price", { autoUnmask: true }).mask(
            $(".add-payment-modal .refund-amount-txt")
          );
        }, 100);
        return;
      }

      // call from unpaid list
      if (props.type == "stripe" && props.outstandingItems.length) {
        selectedToPay.value = props.outstandingItems;
        // const selected = JSON.parse(JSON.stringify(selectedToPay.value));
        // apply auto discount
        // let totalDiscountNoCode = 0;
        // const appliedFor = [];
        // for (const item of selected) {
        //   const discountAmount = item.discountAmount || 0;
        //   if (discountAmount > 0) {
        //     // totalDiscountNoCode += discountAmount;
        //     item.participantCost -= discountAmount;
        //     item.participantCost = parseFloat(item.participantCost.toFixed(2));
        //     item.balance -= discountAmount;
        //     item.balance = parseFloat(item.balance.toFixed(2));
        //     item.totalCost -= discountAmount;
        //     item.totalCost = parseFloat(item.totalCost.toFixed(2));
        //     item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);

        //     // appliedFor.push(item.participantId);

        //     const discount = item.discount || null;
        //     if (discount) {
        //       appliedDiscounts.value.push({
        //         eventId: discount.eventId,
        //         pTypeId: discount.pTypeId,
        //         discountCode: "",
        //         discountAmount: discount.discountAmount,
        //         discountId: discount.discountId,
        //         discountName: discount.discountName,
        //         discountType: discount.discountType,
        //         appliedFor: [item.participantId]
        //       });
        //     }
        //   }
        // }

        // save applied discount
        // if (totalDiscountNoCode > 0) {
        //   appliedDiscounts.value.push({
        //     discountId: 0,
        //     discountCode: "",
        //     discountAmount: parseFloat(totalDiscountNoCode.toFixed(2)),
        //     appliedFor
        //   });
        // }
        const selected = JSON.parse(JSON.stringify(selectedToPay.value));
        await getPaymentInfo(selected);
        return;
      }

      formData.value.isLoading = true;
      // reset section
      formData.value.controls.type.value = "";
      formData.value.controls.event.value = "";
      formData.value.controls.participant.value = "";
      formData.value.controls.amount.value = "";
      formData.value.controls.description.value = "";
      participantInfo.value = {};

      context.emit("setIsPaying", true);
      const result = await ApiHelper.callApi(
        "get",
        "/finances/events",
        {},
        {
          // skip: 0,
          // take: 50,
          getAll: 1,
          profileId: props.profileId
        }
      );

      if (result.status == 1) {
        discountsHasCode.value = result.data.discountsHasCode || [];
      }
      events.value = result.data.events || [];
      singleSubscription.value = result.data.singleSubscription || 0;
      const eventIds = events.value.map(item => item.eventId);

      if (props.eventId && eventIds.includes(props.eventId)) {
        const participantsResponse = await ApiHelper.callApi(
          "get",
          "/finances/participants",
          {},
          {
            eventId: props.eventId,
            profileId: props.profileId
          }
        );
        formData.value.isLoading = false;

        // events.value = result.data.events || [];
        const eventOptions: SelectOption[] = events.value.map((item: any) => {
          return {
            id: item.eventId || "",
            text: item.eventName || ""
          };
        });
        formData.value.controls.event.options = eventOptions;
        if (participantsResponse.status === 1) {
          formData.value.controls.event.disabled = true;
          formData.value.controls.event.value = `${props.eventId}`;
          participants.value = participantsResponse.data.participants || [];
          participantInfo.value =
            participantsResponse.data.participantInfo || {};

          const options: SelectOption[] = participants.value.map(
            (item: any) => {
              const fullName = ApiHelper.getFullName(
                item.firstName,
                item.lastName
              );
              return {
                id: item.participantId || "",
                text: props.profileId
                  ? fullName + " -- " + item.participantTypeName
                  : fullName
              };
            }
          );

          formData.value.controls.participant.error = "";
          formData.value.controls.amount.value = "";
          formData.value.controls.amount.error = "";
          formData.value.controls.participant.options = options;
          if (props.profileId && participants.value.length == 1) {
            formData.value.controls.participant.value =
              participants.value[0].participantId.toString() || "";
            const findParticipant = participants.value.find(item => {
              return (
                formData.value.controls.participant.value ==
                item.participantId.toString()
              );
            });
            if (findParticipant) {
              formData.value.controls.amount.value = findParticipant.balance.toString();
              inputData.value.profileId = findParticipant.profileId;
              formData.value.controls.amount.max = findParticipant.balance.toString();
              formData.value.controls.amount.min = 0;
              onChangeParticipant();
            }
          }
        } else {
          formData.value.controls.event.value = "";
          ignoreEventSelect.value = true;
        }
      } else {
        // events.value = result.data.events || [];
        const eventOptions: SelectOption[] = events.value.map((item: any) => {
          return {
            id: item.eventId || "",
            text: item.eventName || ""
          };
        });
        formData.value.controls.event.options = eventOptions;
      }

      context.emit("setIsPaying", false);

      // if passed a prop participantId
      if (props.participantId && participants.value.length) {
        const participantId = props.participantId;
        formData.value.controls.participant.value = participantId.toString();
        const findParticipant = participants.value.find(item => {
          return item.participantId == participantId;
        });
        if (findParticipant) {
          inputData.value.profileId = findParticipant.profileId;
          if (findParticipant.balance > 0) {
            formData.value.controls.amount.value = findParticipant.balance.toString();
            formData.value.controls.amount.max = findParticipant.balance.toString();
            formData.value.controls.amount.min = 0;
          }
          onChangeParticipant();
        }

        if (props.callFrom == "ParticipantFinancialPage") {
          formData.value.controls.participant.disabled = true;
        }
      }

      const typeOptions: SelectOption[] = result.data.transactionTypes
        .filter(
          (item: any) => item.transactionTypeId != TransactionType.Transfer
        )
        .map((item: any) => {
          return {
            id: item.transactionTypeId || "",
            text: item.transactionTypeName || ""
          };
        });
      // remove Funding Bucket option
      formData.value.controls.type.options = typeOptions.filter(item => {
        return item.text != "Funding Bucket";
      });
      formData.value.isLoading = false;
    })();

    const showDiscountSection = (type = "stripe") => {
      if (type == "manual") {
        const eventId = parseInt(formData.value.controls.event.value);
        const participantId =
          parseInt(formData.value.controls.participant.value) || 0;
        const transactionTypeId =
          parseInt(formData.value.controls.type.value) || 0;
        const participant = participants.value.find(
          item => item.participantId == participantId
        );
        const amount = parseFloat(formData.value.controls.amount.value) || 0;
        const cost = parseFloat(
          (
            participant?.participantCost -
            (participant?.participantDiscountCost || 0)
          ).toFixed(2)
        );

        // specify some cases not show discount section in case put transaction manually
        if (
          eventId == 0 ||
          participantId == 0 ||
          transactionTypeId == 0 ||
          [TransactionType.Refund, TransactionType.WriteOff].includes(
            transactionTypeId
          ) ||
          // (participant?.participantDiscountCost || 0) > 0 ||
          cost <= 0 ||
          (!fullPaid(participant) &&
            !partialPaid(participant) &&
            amount == 0) ||
          (fullPaid(participant) &&
            transactionTypeId != TransactionType.Scholarship)
          // (participant?.pRegistrationStep || 0) > 1
          // [2].includes(participant?.pRegistrationStep || 0)
        ) {
          discountCode.value = "";
          return false;
        }

        // make sure just show discount section if event has discount setting
        if (discountsHasCode.length == 0) {
          return false;
        } else {
          const existedDiscount = discountsHasCode.value.find(
            (item: any) =>
              item.eventId == eventId &&
              item.pTypeId == participant.participantTypeId
          );
          if (!existedDiscount) {
            return false;
          }
        }
      } else if (type == "stripe") {
        // hide if has no any items have step = 1
        const notPaid = selectedToPay.value.filter(
          (item: any) => item.pRegistrationStep == 1
        );
        if (notPaid.length == 0) {
          return false;
        }

        if (discountsHasCode.value.length == 0) return false;

        let show = false;
        for (const item of selectedToPay.value) {
          const existedDiscount = discountsHasCode.value.find(
            (discount: any) =>
              item.eventId == discount.eventId &&
              item.participantTypeId == discount.pTypeId
          );
          if (existedDiscount) {
            show = true;
            break;
          }
        }

        return show;
      }

      return true;
    };

    const applyDiscount = async (discountIds: number[] = []) => {
      if (
        (discountCode.value == "" && !discountIds.length) ||
        isApplyingDiscount.value == true
      ) {
        return;
      }

      if (discountCode.value) {
        // exit if applied this code
        const inApplied = appliedDiscounts.value.find(
          (item: any) =>
            item.discountCode.toLowerCase() == discountCode.value.toLowerCase()
        );
        if (inApplied) {
          formData.value.controls.discount.error =
            "Discount Code already exists. Please enter new information.";
          return;
        }
      }

      if (props.type == "manual") {
        let amount = parseFloat(formData.value.controls.amount.value) || 0;
        const eventId = parseInt(formData.value.controls.event.value);
        const participantId = parseInt(
          formData.value.controls.participant.value
        );
        const participantIds = [participantId];
        const transactionTypeId =
          parseInt(formData.value.controls.type.value) || 0;
        const participant = participants.value.find(
          item => item.participantId == participantId
        );

        if (
          !fullPaid(participant) &&
          !partialPaid(participant) &&
          amount == 0
        ) {
          return;
        }

        // && ![2].includes(participant?.pRegistrationStep || 0)
        if (participant) {
          isApplyingDiscount.value = true;
          formData.value.controls.discount.error = "";
          const pTypeId = participant?.participantTypeId || 0;
          const eventPType = [
            {
              eventId,
              pTypeId
            }
          ];
          if (payForPlan.value == "payfull") {
            for (const item of selectedToPay.value[0].participants) {
              if ((item.participantDiscountCost || 0) > 0) continue;

              const inList = eventPType.find(
                t =>
                  t.eventId == item.eventId &&
                  t.pTypeId == item.participantTypeId
              );
              if (!inList) {
                eventPType.push({
                  eventId: item.eventId,
                  pTypeId: item.participantTypeId
                });
              }
            }
          }
          const response = await ApiHelper.callApi(
            "get",
            `/events/getAvailableDiscounts`,
            {},
            {
              participants: JSON.stringify(eventPType),
              discountCode: discountCode.value,
              discountIds: discountIds.join(",")
            }
          );
          if (response.status == 1) {
            availableDiscounts.value = response.data.availableDiscounts || [];
            if (availableDiscounts.value.length) {
              formData.value.controls.discount.error = "";
              const discounts = JSON.parse(
                JSON.stringify(availableDiscounts.value)
              );
              if (payForPlan.value != "payfull") {
                // apply discount if input a discount code
                const discount = discounts.find(
                  (d: any) => d.eventId == eventId && d.pTypeId == pTypeId
                );
                const discountAmount = discount?.discountAmount || 0;
                if (
                  discountAmount > 0 &&
                  (discount.maxUse == 0 ||
                    (discount.maxUse > 0 &&
                      discount.maxUse > discount.totalUsed))
                ) {
                  amount -= discountAmount;
                  amount = parseFloat(amount.toFixed(2));

                  // paid but still want to apply a discount?
                  let paidCanApply = false;
                  if (fullPaid(participant) || partialPaid(participant)) {
                    let cost =
                      (participant.participantCost || 0) -
                      (participant.participantDiscountCost || 0);
                    if (cost > 0) {
                      for (const item of appliedDiscounts.value) {
                        if (item.appliedFor.includes(participantId)) {
                          cost -= item.discountAmount;
                        }
                      }
                      cost -= discountAmount;
                      if (cost >= 0) {
                        paidCanApply = true;
                      }
                    }
                  }

                  if (
                    (!fullPaid(participant) &&
                      !partialPaid(participant) &&
                      amount >= 0) ||
                    paidCanApply
                  ) {
                    const inApplied = appliedDiscounts.value.find(
                      (t: any) =>
                        t.discountId == discount.discountId &&
                        t.eventId == discount.eventId &&
                        t.pTypeId == discount.pTypeId &&
                        participantIds.includes(t.appliedFor[0])
                      // t.discountCode.toLowerCase() == discount.discountCode.toLowerCase()
                    );
                    if (!inApplied) {
                      appliedDiscounts.value.push({
                        ...discount,
                        appliedFor: [participantId]
                      });
                    }

                    // update totalUsed
                    discount.totalUsed += 1;

                    // if pay by credit card, apply discount to selectedToPay
                    if (transactionTypeId == TransactionType.DebitCredit) {
                      const selected = JSON.parse(
                        JSON.stringify(selectedToPay.value)
                      );
                      const item = selected[0];

                      // apply discount
                      // const discountHasCode = appliedDiscounts.value.filter(
                      //   (item: any) => item.discountCode != ""
                      // );
                      if (!fullPaid(participant)) {
                        if (!partialPaid(participant)) {
                          for (const discountItem of appliedDiscounts.value) {
                            item.participantCost -= discountItem.discountAmount;
                            item.participantCost = parseFloat(
                              item.participantCost.toFixed(2)
                            );
                            // item.costFormatted = ApiHelper.dollarFormat(item.cost);
                            item.balance -= discountItem.discountAmount;
                            item.balance = parseFloat(item.balance.toFixed(2));
                            item.totalCost -= discountItem.discountAmount;
                            item.totalCost = parseFloat(
                              item.totalCost.toFixed(2)
                            );
                            item.totalCostFormatted = ApiHelper.dollarFormat(
                              item.totalCost
                            );
                          }
                        } else {
                          // partial paid, and apply more discount after paid
                          for (const discountItem of appliedDiscounts.value) {
                            const amount = parseFloat(
                              (
                                item.balance - discountItem.discountAmount
                              ).toFixed(2)
                            );
                            if (amount >= 0) {
                              item.balance -= discountItem.discountAmount;
                              item.balance = parseFloat(
                                item.balance.toFixed(2)
                              );
                              item.totalCost -= discountItem.discountAmount;
                              item.totalCost = parseFloat(
                                item.totalCost.toFixed(2)
                              );
                              item.totalCostFormatted = ApiHelper.dollarFormat(
                                item.totalCost
                              );
                            }
                          }
                        }

                        // get new paymentInfo
                        await getPaymentInfo(selected);
                      }
                    } else if (
                      transactionTypeId == TransactionType.Scholarship
                    ) {
                      formData.value.controls.amount.error = "";
                    }

                    // update amount textbox
                    if (
                      (!fullPaid(participant) && !partialPaid(participant)) ||
                      (partialPaid(participant) && amount >= 0)
                    ) {
                      formData.value.controls.amount.value = `${amount}`;
                    }

                    // show success message
                    context.root.$swal.fire({
                      text:
                        discountCode.value != ""
                          ? `Applied discount code ${discountCode.value.toUpperCase()} successfully`
                          : "Applied discount successfully",
                      timer: 5000
                    });

                    // clear inputted discount code
                    discountCode.value = "";
                  } else {
                    // prevent if applying discount and make amount < 0
                    context.root.$swal.fire({
                      html:
                        discountCode.value != ""
                          ? `Can't apply the discount code: ${discountCode.value.toUpperCase()}.<br />It puts the amount in the negative.`
                          : `Can't apply the discount.<br />It puts the amount in the negative.`,
                      timer: 5000
                    });
                    discountCode.value = "";
                  }
                } else {
                  formData.value.controls.discount.error =
                    "Discount code not found";
                }
              } else {
                // apply discounts after partial paid for a group of participants (in a plan)
                // check if returned available discounts can apply for selectedToPay
                const selected = JSON.parse(
                  JSON.stringify(selectedToPay.value)
                );
                let canApplyDiscountCode = false;
                for (const item of selected[0].participants || []) {
                  const discount = discounts.find(
                    (d: any) =>
                      d.eventId == item.eventId &&
                      d.pTypeId == item.participantTypeId
                  );
                  if (discount) {
                    canApplyDiscountCode = true;
                    break;
                  }
                }
                if (canApplyDiscountCode) {
                  // collect discounts can apply and store in appliedDiscounts
                  for (const item of selected[0].participants || []) {
                    const discount = discounts.find(
                      (d: any) =>
                        d.eventId == item.eventId &&
                        d.pTypeId == item.participantTypeId
                    );
                    const discountAmount = discount?.discountAmount || 0;
                    if (
                      discountAmount > 0 &&
                      (discount.maxUse == 0 ||
                        (discount.maxUse > 0 &&
                          discount.maxUse > discount.totalUsed))
                    ) {
                      // make sure cost is not negative if apply this discount
                      let cost = item.participantCost || 0;
                      for (const t of appliedDiscounts.value) {
                        if ((t.appliedFor || []).includes(item.participantId)) {
                          cost -= t.discountAmount;
                          cost = parseFloat(cost.toFixed(2));
                        }
                      }
                      cost -= discountAmount;
                      cost = parseFloat(cost.toFixed(2));
                      if (cost >= 0) {
                        const inApplied = appliedDiscounts.value.find(
                          (t: any) =>
                            t.discountId == discount.discountId &&
                            t.eventId == discount.eventId &&
                            t.pTypeId == discount.pTypeId &&
                            (t.appliedFor || []).includes(item.participantId)
                        );

                        if (!inApplied) {
                          appliedDiscounts.value.push({
                            ...discount,
                            appliedFor: [item.participantId]
                          });
                        }

                        // update totalUsed
                        discount.totalUsed += 1;
                      }
                    }
                  }

                  // show success message
                  const inApplied = appliedDiscounts.value.find(
                    (item: any) =>
                      (item.discountCode != "" &&
                        item.discountCode.toLowerCase() ==
                          discountCode.value.toLowerCase()) ||
                      discountIds.includes(item.discountId)
                  );
                  if (inApplied) {
                    context.root.$swal.fire({
                      text:
                        discountCode.value != ""
                          ? `Applied discount code ${discountCode.value.toUpperCase()} successfully`
                          : `Applied discount successfully`,
                      timer: 5000
                    });
                  } else {
                    // prevent if applying discount and make amount < 0
                    context.root.$swal.fire({
                      html:
                        discountCode.value != ""
                          ? `Can't apply the discount code: ${discountCode.value.toUpperCase()}.<br />It puts the amount in the negative.`
                          : `Can't apply the discount.<br />It puts the amount in the negative.`,
                      timer: 5000
                    });
                  }
                  // clear inputted discount code
                  discountCode.value = "";
                } else {
                  // cannot apply this discount code for event/ptype in selectedToPay
                  paymentData.value.paymentErrMessage =
                    "Discount code not found";
                }
              }
            } else {
              // cannot apply this discount code for event/ptype in selectedToPay
              formData.value.controls.discount.error =
                "Discount code not found";
            }
          } else {
            const errorCode = response.errorCode || "";
            if (errorCode == "DISCOUNT_NOT_FOUND") {
              formData.value.controls.discount.error =
                "Discount code not found";
            }
          }
          isApplyingDiscount.value = false;
        }
        return;
      }

      // case stripe
      if (selectedToPay.value.length > 0) {
        try {
          applyingDiscount.value = true;
          const response = await ApiHelper.callApi(
            "get",
            `/events/getAvailableDiscounts`,
            {},
            {
              participants: JSON.stringify(
                selectedToPay.value
                  .filter((item: any) => item.pRegistrationStep == 1)
                  .map((item: any) => {
                    return {
                      eventId: item.eventId,
                      pTypeId: item.participantTypeId
                    };
                  })
              ),
              discountCode: discountCode.value,
              discountIds: discountIds.join(",")
            }
          );

          // getPaymentInfo
          if (response.status == 1) {
            availableDiscounts.value = response.data.availableDiscounts || [];
            if (availableDiscounts.value.length) {
              const selected = JSON.parse(JSON.stringify(selectedToPay.value));
              const discounts = JSON.parse(
                JSON.stringify(availableDiscounts.value)
              );

              // check if returned available discounts can apply for selectedToPay
              let canApplyDiscountCode = false;
              for (const item of selected) {
                const discount = discounts.find(
                  (d: any) =>
                    d.eventId == item.eventId &&
                    d.pTypeId == item.participantTypeId
                );
                if (discount) {
                  canApplyDiscountCode = true;
                  break;
                }
              }
              if (canApplyDiscountCode) {
                // apply auto discount
                // const discountHasNoCode = appliedDiscounts.value.filter(
                //   (item: any) =>
                //     item.discountCode == "" && item.discountType == 1
                // );
                // for (const item of selected) {
                //   const discountAmount = item.discountAmount || 0;
                //   const inApplied = discountHasNoCode.find((t: any) =>
                //     (t.appliedFor || []).includes(item.participantId)
                //   );
                //   if (discountAmount > 0 && inApplied) {
                //     item.participantCost -= discountAmount;
                //     item.participantCost = parseFloat(
                //       item.participantCost.toFixed(2)
                //     );
                //     item.balance -= discountAmount;
                //     item.balance = parseFloat(item.balance.toFixed(2));
                //     item.totalCost -= discountAmount;
                //     item.totalCost = parseFloat(item.totalCost.toFixed(2));
                //     item.totalCostFormatted = ApiHelper.dollarFormat(
                //       item.totalCost
                //     );
                //   }
                // }

                // collect discounts can apply and store in appliedDiscounts
                for (const item of selected) {
                  const discount = discounts.find(
                    (d: any) =>
                      d.eventId == item.eventId &&
                      d.pTypeId == item.participantTypeId
                  );
                  const discountAmount = discount?.discountAmount || 0;
                  if (
                    discountAmount > 0 &&
                    (discount.maxUse == 0 ||
                      (discount.maxUse > 0 &&
                        discount.maxUse > discount.totalUsed))
                  ) {
                    const inApplied = appliedDiscounts.value.find(
                      (t: any) =>
                        t.discountId == discount.discountId &&
                        t.eventId == discount.eventId &&
                        t.pTypeId == discount.pTypeId &&
                        (t.appliedFor || []).includes(item.participantId)
                      // t.discountCode.toLowerCase() == discount.discountCode.toLowerCase() &&
                    );

                    if (!inApplied) {
                      // discount.appliedFor = [item.participantId];
                      appliedDiscounts.value.push({
                        ...discount,
                        appliedFor: [item.participantId]
                      });
                    }
                    // else if (
                    //   !inApplied.appliedFor.includes(item.participantId)
                    // ) {
                    //   inApplied.appliedFor.push(item.participantId);
                    // }

                    // update totalUsed
                    discount.totalUsed += 1;
                  }
                }

                // apply discount based on appliedDiscounts
                // const discountHasCode = appliedDiscounts.value.filter(
                //   (item: any) =>
                //     !(item.discountCode == "" && item.discountType == 1)
                // );
                for (const discountItem of appliedDiscounts.value) {
                  const relatedSelected = selected.filter((item: any) =>
                    discountItem.appliedFor.includes(item.participantId)
                  );

                  const finalAppliedFor = [];
                  for (const item of relatedSelected) {
                    const amount =
                      item.participantCost - discountItem.discountAmount;
                    // prevent if applying discount and make amount < 0
                    if (amount >= 0) {
                      finalAppliedFor.push(item.participantId);

                      item.participantCost -= discountItem.discountAmount;
                      item.participantCost = parseFloat(
                        item.participantCost.toFixed(2)
                      );
                      item.balance -= discountItem.discountAmount;
                      item.balance = parseFloat(item.balance.toFixed(2));
                      item.totalCost -= discountItem.discountAmount;
                      item.totalCost = parseFloat(item.totalCost.toFixed(2));
                      item.totalCostFormatted = ApiHelper.dollarFormat(
                        item.totalCost
                      );
                    }
                  }
                  discountItem.appliedFor = finalAppliedFor;
                }

                // remove discounts has empty appliedFor
                appliedDiscounts.value = appliedDiscounts.value.filter(
                  (item: any) => item.appliedFor.length
                );

                // get new paymentInfo
                await getPaymentInfo(selected);

                paymentData.value.paymentErrMessage = "";

                // show success message
                const inApplied = appliedDiscounts.value.find(
                  (item: any) =>
                    (item.discountCode != "" &&
                      item.discountCode.toLowerCase() ==
                        discountCode.value.toLowerCase()) ||
                    discountIds.includes(item.discountId)
                );
                if (inApplied) {
                  context.root.$swal.fire({
                    text:
                      discountCode.value != ""
                        ? `Applied discount code ${discountCode.value.toUpperCase()} successfully`
                        : `Applied discount successfully`,
                    timer: 5000
                  });
                } else {
                  // prevent if applying discount and make amount < 0
                  context.root.$swal.fire({
                    html:
                      discountCode.value != ""
                        ? `Can't apply the discount code: ${discountCode.value.toUpperCase()}.<br />It puts the amount in the negative.`
                        : `Can't apply the discount.<br />It puts the amount in the negative.`,
                    timer: 5000
                  });
                }
                // clear inputted discount code
                discountCode.value = "";
              } else {
                // cannot apply this discount code for event/ptype in selectedToPay
                paymentData.value.paymentErrMessage = "Discount code not found";
              }
            }
          } else {
            const errorCode = response.errorCode || "";
            if (errorCode == "DISCOUNT_NOT_FOUND") {
              paymentData.value.paymentErrMessage = "Discount code not found";
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          applyingDiscount.value = false;
        }
      }
    };

    const disableApplyDiscount = () => {
      if (discountCode.value) {
        // exit if applied this code
        const inApplied = appliedDiscounts.value.find(
          (item: any) =>
            item.discountCode.toLowerCase() == discountCode.value.toLowerCase()
        );
        if (inApplied) {
          return true;
        }
      }
      return false;
    };

    const inTransactionIds = (id: string) => {
      if (transactionIds.value.includes(id)) {
        return true;
      } else {
        transactionIds.value.push(id);
        return false;
      }
    };

    const refundLineEventName = (detail: any) => {
      // if ((detail.addonServiceId || 0) == 5) {
      //   return "(Camp Store Funds)";
      // }

      // return `${detail.eventName} / ${detail.pTypeName}`;

      const refundInfo: any = props.refundInfo[0];
      const isFundBucketRefund = refundInfo.fundBucketRefund || false;
      let ret =
        detail.serviceName || `${detail.eventName} / ${detail.pTypeName}`;
      if (isFundBucketRefund) {
        ret = `${detail.eventName} / ${detail.pTypeName}`;
      }

      return ret;
    };

    const removeDiscountCode = async (discount: any) => {
      if (props.type == "manual") {
        const transactionTypeId =
          parseInt(formData.value.controls.type.value) || 0;
        const participantId = parseInt(
          formData.value.controls.participant.value
        );
        const participantIds = [participantId];
        const participant = participants.value.find(
          item => item.participantId == participantId
        );
        const amount = parseFloat(formData.value.controls.amount.value) || 0;

        discountCode.value = "";
        appliedDiscounts.value = appliedDiscounts.value.filter(
          (t: any) =>
            !(
              // t.discountId == discount.discountId &&
              // t.eventId == discount.eventId &&
              // t.pTypeId == discount.pTypeId &&
              // participantIds.includes(t.appliedFor[0])
              (
                t.eventId == discount.eventId &&
                t.pTypeId == discount.pTypeId &&
                t.discountCode.toLowerCase() ==
                  discount.discountCode.toLowerCase() &&
                t.discountName.toLowerCase() ==
                  discount.discountName.toLowerCase()
              )
            )
          // t.discountCode.toLowerCase() != discount.discountCode.toLowerCase()
        );

        let currentAmount = amount + discount.discountAmount;
        currentAmount = parseFloat(currentAmount.toFixed(2));
        if (
          fullPaid(participant) ||
          (partialPaid(participant) && participant.balance < currentAmount) ||
          (transactionTypeId == TransactionType.Scholarship &&
            amount == 0 &&
            formData.value.controls.amountLabel != "")
        ) {
          return;
        }

        // re-update for amount
        if (formData.value.controls.amountLabel == "") {
          formData.value.controls.amount.value = currentAmount.toFixed(2);
        }

        if (transactionTypeId == TransactionType.DebitCredit) {
          // remove auto discount in selectedToPay
          // if ((discount.discountId || 0) == 0 && discount.discountCode == "") {
          //   for (const participantId of discount.appliedFor) {
          //     const related = selectedToPay.value.find(
          //       (t: any) => (t.participantId || 0) == participantId
          //     );
          //     if (related) {
          //       delete related.discount;
          //       related.discountAmount = 0;
          //       related.discountAmountFormatted = dollarFormat(0);
          //     }
          //   }
          // }

          const selected = JSON.parse(JSON.stringify(selectedToPay.value));
          const item = selected[0];
          for (const discountItem of appliedDiscounts.value) {
            item.participantCost -= discountItem.discountAmount;
            item.participantCost = parseFloat(item.participantCost.toFixed(2));
            item.totalCost -= discountItem.discountAmount;
            item.totalCost = parseFloat(item.totalCost.toFixed(2));
            item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);

            // partial paid, and apply more discount after paid
            if (partialPaid(participant)) {
              const amount = parseFloat(
                (item.balance - discountItem.discountAmount).toFixed(2)
              );
              if (amount >= 0) {
                item.balance -= discountItem.discountAmount;
                item.balance = parseFloat(item.balance.toFixed(2));
              }
            }
          }

          // get new paymentInfo
          await getPaymentInfo(selected);
        }
      } else if (props.type == "stripe") {
        appliedDiscounts.value = appliedDiscounts.value.filter(
          (t: any) =>
            !(
              t.eventId == discount.eventId &&
              t.pTypeId == discount.pTypeId &&
              t.discountCode.toLowerCase() ==
                discount.discountCode.toLowerCase() &&
              t.discountName.toLowerCase() ==
                discount.discountName.toLowerCase()
            )
        );
        const selected = JSON.parse(JSON.stringify(selectedToPay.value));

        // apply discount based on appliedDiscounts
        for (const discountItem of appliedDiscounts.value) {
          const relatedSelected = selected.filter((item: any) =>
            discountItem.appliedFor.includes(item.participantId)
          );
          for (const item of relatedSelected) {
            item.participantCost -= discountItem.discountAmount;
            item.participantCost = parseFloat(item.participantCost.toFixed(2));
            item.totalCost -= discountItem.discountAmount;
            item.totalCost = parseFloat(item.totalCost.toFixed(2));
            item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
          }
        }

        // get new paymentInfo
        await getPaymentInfo(selected);
      }
    };

    const showPaymentTypes = () => {
      // check for some cases not need to show payment types
      // hide if pay partial
      if (payingPartialAmount()) {
        return false;
      }

      return true;
    };

    const hideRefundOption = () => {
      // hide if there is no payment made by this participant
      const participant = participants.value.find(item => {
        return (
          formData.value.controls.participant.value ==
          item.participantId.toString()
        );
      });
      if (participant) {
        const paidTotal =
          participant.transactionAmount - participant.refundAmount;
        if (paidTotal <= 0) {
          return true;
        }
      }

      return false;
    };

    const toggleTransferChange = async () => {
      if (formData.value.controls.toggleTransfer.value == true) {
        const selectedFunding = props.selectedFunding;
        // show current info of selected funding
        formData.value.controls.event.value = props.selectedFunding.eventId;
        await onChangeEvent();
        formData.value.controls.participant.value =
          props.selectedFunding.participantId;
        formData.value.controls.amount.value =
          props.selectedFunding.transactionAmount;
        context.emit("setIsPaying", true);
        const result = await ApiHelper.callApi(
          "get",
          "/families/getOwnerByProfile",
          {},
          { profileId: selectedFunding.profileId }
        );
        if (result.status == 1) {
          familyOwner.value = result.data || {};
          // formData.value.controls.type.value = `${TransactionType.Transfer}`;
        }
        context.emit("setIsPaying", false);
      } else {
        // formData.value.controls.type.value = "";
        // formData.value.controls.amount.value = "";
      }
    };

    const suggestDiscounts = async (key: string) => {
      // reset
      formData.value.foundDiscounts = [];
      const eventId = parseInt(formData.value.controls.event.value) || 0;
      const participantId = formData.value.controls.participant.value;
      const participant = participants.value.find(
        t => t.participantId == participantId
      );
      const pTypeId = participant?.participantTypeId || 0;
      let eventPType = [];
      let participantIds = [parseInt(participantId)];

      if (eventId != 0 && pTypeId != 0) {
        eventPType.push({
          eventId,
          pTypeId
        });
      } else if (props.type == "stripe" && selectedToPay.value.length) {
        const unPaid = selectedToPay.value.filter(
          (item: any) =>
            item.sourceType == 1 && [0, 1].includes(item.pRegistrationStep)
        );
        eventPType = unPaid.map((item: any) => ({
          eventId: item.eventId,
          pTypeId: item.participantTypeId
        }));
        participantIds = unPaid.map((item: any) => item.participantId || 0);
      }

      if (!eventPType.length) return;

      // searching
      formData.value.isACILoading = true;
      const result = await ApiHelper.callApi(
        "get",
        "/events/getAvailableDiscounts",
        {},
        {
          // getAllDiscounts: 1,
          // allowEmptyDiscountCode: 1,
          // searchDiscountsLike: 1,
          participants: JSON.stringify(eventPType),
          discountCode: key,
          callFrom: "add_transaction"
        }
      );
      if (result.status === 1) {
        // not show applied discounts
        const availableDiscounts = (
          result.data.availableDiscounts || []
        ).filter((item: any) => {
          // not show discounts in current applied discounts list
          const inApplied = appliedDiscounts.value.find(
            (t: any) =>
              t.discountId == item.discountId &&
              t.eventId == item.eventId &&
              t.pTypeId == item.pTypeId &&
              participantIds.includes(t.appliedFor[0])
          );
          if (inApplied) {
            return false;
          }

          // ignore for some applied discounts (applied before)
          if (props.type == "manual") {
            const appliedDiscounts = participant.appliedDiscounts || [];
            const inList = appliedDiscounts.find(
              (t: any) => t.discountId == item.discountId
            );
            if (inList) {
              return false;
            }
          }

          return true;
        });

        // group by discount name/discount code
        const finalDiscounts: any = [];
        for (const item of availableDiscounts) {
          const inList = finalDiscounts.find(
            (t: any) =>
              t.discountCode.toLowerCase() == item.discountCode.toLowerCase() &&
              t.discountName.toLowerCase() == item.discountName.toLowerCase()
          );
          if (!inList) {
            finalDiscounts.push({
              discountCode: item.discountCode,
              discountName: item.discountName,
              discountIds: [item.discountId]
            });
          } else {
            inList.discountIds.push(item.discountId);
          }
        }

        formData.value.foundDiscounts = finalDiscounts.map((item: any) => ({
          // id: item.discountId,
          text: item.discountName,
          html: `${item.discountName} <span style="color: #a7a9a7; font-size: 90%; margin-left: 5px;">${item.discountCode}</span>`,
          data: item
        }));
      }
      formData.value.isACILoading = false;
    };

    const selectDiscount = async (data: any) => {
      if (data == undefined) {
        // formData.value.selectedDiscount = "";
        return;
      }

      discountCode.value = data.discountCode;
      const discountIds = data.discountIds || [];
      await applyDiscount(discountCode.value == "" ? discountIds : []);
      const aciDiscountsRef: any = context.refs.aciDiscountsRef;
      if (aciDiscountsRef) {
        aciDiscountsRef.key = "";
      }

      // formData.value.selectedDiscount = data.discountName;
    };

    const getAppliedDiscountInfo = () => {
      // pay by stripe: show applied discounts info
      // group by discount code/discount name
      const finalDiscounts: any = [];
      for (const item of appliedDiscounts.value) {
        const inList = finalDiscounts.find(
          (t: any) =>
            t.eventId == item.eventId &&
            t.pTypeId == item.pTypeId &&
            t.discountCode.toLowerCase() == item.discountCode.toLowerCase() &&
            t.discountName.toLowerCase() == item.discountName.toLowerCase()
        );
        if (!inList) {
          finalDiscounts.push({
            eventId: item.eventId,
            pTypeId: item.pTypeId,
            discountCode: item.discountCode,
            discountName: item.discountName,
            discountAmount: item.discountAmount,
            discountAmountFormatted: dollarFormat(item.discountAmount)
          });
        } else {
          inList.discountAmount += item.discountAmount;
          inList.discountAmount = parseFloat(inList.discountAmount.toFixed(2));
          inList.discountAmountFormatted = dollarFormat(inList.discountAmount);
        }
      }

      return finalDiscounts;
    };

    const getTotalAmount = () => {
      const participantId = parseInt(formData.value.controls.participant.value);
      const participant = participants.value.find(
        t => t.participantId == participantId
      );
      let amount = parseFloat(formData.value.controls.amountLabel);
      const originalAmount = amount;
      if (
        !fullPaid(participant)
        // && !partialPaid(participant)
      ) {
        // let discountTotal = 0;
        let tmpAmount = 0;
        let canApplyDiscount = false;
        appliedDiscounts.value.map((item: any) => {
          // discountTotal += item.discountAmount;
          amount -= item.discountAmount;
          amount = parseFloat(amount.toFixed(2));
          if (amount >= 0) {
            tmpAmount = amount;
            canApplyDiscount = true;
          }
        });
        // amount -= discountTotal;
        if (partialPaid(participant) && amount < 0) {
          // partial paid, and apply more discount after paid
          // keep at step amount still positive
          amount = canApplyDiscount ? tmpAmount : originalAmount;
        }
      }
      return ApiHelper.dollarFormat(amount);
    };

    const showTransferModal = () => {
      // check if need to show a transfer modal if applying a discount after paid
      const participantId = parseInt(formData.value.controls.participant.value);
      const amount = parseFloat(formData.value.controls.amount.value) || 0;
      const participant = participants.value.find(
        t => t.participantId == participantId
      );

      if (
        props.type != "manual" ||
        !appliedDiscounts.value.length ||
        payForPlan.value != "payonly" ||
        !participant
      ) {
        return false;
      }

      const discountInfo = [
        ...participant.appliedDiscounts,
        ...appliedDiscounts.value
      ];
      let discountTotal = 0;
      for (const discount of discountInfo) {
        discountTotal += discount.discountAmount || 0;
      }
      discountTotal = parseFloat(discountTotal.toFixed(2));
      let totalPaid =
        participant.transactionAmount +
        amount -
        participant.refundAmount -
        participant.transferAmount;
      totalPaid = parseFloat(totalPaid.toFixed(2));
      const balance =
        participant.participantCost +
        (participant.additional_cost || 0) -
        discountTotal -
        totalPaid;

      if (balance >= 0) return false;

      // show transfer modal
      const paidAmount = parseFloat((balance * -1).toFixed(2));
      const eventId = parseInt(formData.value.controls.event.value);
      const eventName =
        formData.value.controls.event.options.find(t => t.id == eventId)
          ?.text || "";
      formData.value.transferInfo = {
        participantId: participant.participantId,
        profileId: participant.profileId,
        paidAmount,
        paidAmountFormatted: dollarFormat(paidAmount),
        fullName: getFullName(participant.firstName, participant.lastName),
        eventName,
        pTypeName: participant.participantTypeName || "",
        overpayment: 1
      };
      formData.value.transferVisible = true;
      ApiHelper.showSuccessMessage(
        "Participant paid for event. Please chose how you would like this credit handled"
      );

      return true;
    };

    const closeTransferModal = () => {
      formData.value.transferVisible = false;
      const transactionType = parseInt(formData.value.controls.type.value) || 0;
      if (transactionType != TransactionType.DebitCredit) {
        closePopup();
      } else {
        context.emit("closePaymentModal", {
          clearSelectedToPay: true
        });
      }
    };

    const reloadDataAfterTransfer = () => {
      const transactionType = parseInt(formData.value.controls.type.value) || 0;
      if (transactionType != TransactionType.DebitCredit && props.onCallback) {
        props.onCallback();
      }
    };
    const completeApp = (participantId: number) => {
      participants.value = participants.value.map(item => {
        if (
          formData.value.controls.participant.value == `${item.participantId}`
        ) {
          item.pRegistrationStep = 1;
        }
        return item;
      });
    };
    return {
      completeApp,
      getTotalAmount,
      ignoreEventSelect,
      participants,
      hideDropdown,
      onChangeEvent,
      onChangeParticipant,
      closePopup,
      formData,
      onSubmit,
      paymentData,
      paymentInfo,
      payNow,
      onChangeTransactionType,
      creditCharge,
      creditChargeCustomAmount,
      selectedToPay,
      getPaymentInfoForCustom,
      creditChargeCustomError,
      dynamicCosts,
      allowAddTransaction,
      addTransaction,
      onChangeAmount,
      onChangeFundBucket,
      getTotalRefund,
      campStoreCreditsRefund,
      showDiscountSection,
      discountCode,
      applyDiscount,
      disableApplyDiscount,
      usedDiscounts,
      diffFamily,
      appliedDiscounts,
      outstandingBalanceItems,
      ApiHelper,
      payForPlan,
      updateSelectedToPay,
      showInPlanNotes,
      inTransactionIds,
      refundLineEventName,
      removeDiscountCode,
      showPaymentTypes,
      hideRefundOption,
      showOnlyRefund,
      fundTxtChange,
      fundCheckboxChange,
      showFunding,
      toggleTransferChange,
      onClickAddAmount,
      familyOwner,
      isTransfer,
      singleSubscription,
      suggestDiscounts,
      selectDiscount,
      getAppliedDiscountInfo,
      allowInputAmount,
      updateAmountLabel,
      fullPaid,
      closeTransferModal,
      reloadDataAfterTransfer
    };
  },
  mounted() {
    Inputmask.extendAliases({
      price: {
        prefix: "$",
        groupSeparator: ".",
        alias: "numeric",
        placeholder: "0",
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        clearMaskOnLostFocus: false,
        allowMinus: false
      }
    });

    $(document).ready(function() {
      Inputmask("price", { autoUnmask: true }).mask(
        $(".add-payment-modal .dollar_amount")
      );
    });
  }
});
