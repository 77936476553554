








































































































































































import { defineComponent } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import { useFamilyEventsStore } from "@/stores/Family/FamilyEventsStore";
import Pager from "@/components/Common/Pager.vue";
import Header from "@/components/Common/Header.vue";
import FormItem from "@/components/Form/FormItem.vue";
import FormButton from "@/components/Form/FormButton.vue";
import { ApiHelper } from "@/helpers";
import VScroller from "@/components/Common/VScroller.vue";
import NavFamilyDetails from "@/components/Family/NavFamilyDetails.vue";

type Profile = {
  id: string;
  firstName: string;
  lastName: string;
  tags: {
    id: number;
    name: string;
  }[];
};

export default defineComponent({
  name: "FamilyEvents",
  components: {
    NavFamilyDetails,
    FormItem,
    FormButton,
    VAlertMessage,
    Loading,
    Header,
    Pager,
    VScroller
  },
  setup(props, context) {
    const {
      headerData,
      pageData,
      // pager
      gotoPage,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext
    } = useFamilyEventsStore(context);

    const getParticipanFullNames = (item: any) => {
      return item.map((t: any) => t.fullName).join(", ");
    };

    return {
      headerData,
      pageData,
      // pager
      gotoPage,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      ApiHelper,
      getParticipanFullNames
    };
  }
});
