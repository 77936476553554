var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group",class:{
    hasError: _vm.data.error !== '',
    'd-flex': _vm.data.isInline,
    'pb-2': _vm.data.isInline,
    isRequired: _vm.data.required
  }},[_c('label',{staticClass:"label__full",class:{
      'w-50': _vm.data.isInline
    },attrs:{"for":_vm.randomId}},[_vm._v(_vm._s(_vm.data.label))]),_c('div',{staticClass:"group__radio",class:{
      'w-50': _vm.data.isInline
    }},[_c('div',{staticClass:"radio__item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.value),expression:"data.value"}],attrs:{"type":"radio","id":_vm.randomId + 'receiveMessageYes',"disabled":_vm.data.disabled},domProps:{"value":_vm.data.yesValue,"checked":_vm._q(_vm.data.value,_vm.data.yesValue)},on:{"change":[function($event){return _vm.$set(_vm.data, "value", _vm.data.yesValue)},function($event){_vm.data.error = ''}]}}),_c('label',{attrs:{"for":_vm.randomId + 'receiveMessageYes'}},[_c('span'),_vm._v("Yes")])]),((_vm.data.hideNoOption || false) == false)?_c('div',{staticClass:"radio__item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.value),expression:"data.value"}],attrs:{"type":"radio","id":_vm.randomId + 'receiveMessageNo',"disabled":_vm.data.disabled},domProps:{"value":_vm.data.noValue,"checked":_vm._q(_vm.data.value,_vm.data.noValue)},on:{"change":[function($event){return _vm.$set(_vm.data, "value", _vm.data.noValue)},function($event){_vm.data.error = ''}]}}),_c('label',{attrs:{"for":_vm.randomId + 'receiveMessageNo'}},[_c('span'),_vm._v("No")])]):_vm._e(),(_vm.data.error)?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }