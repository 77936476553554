var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"accept-charset":"utf-8","autocomplete":"new-password"},on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"SettingsProfilePage position-relative"},[_vm._m(0),_c('div',{staticClass:"content-inside v1-page"},[_c('div',{staticClass:"content_add full__width"},[_c('div',{staticClass:"content__form"},[_c('div',{attrs:{"action":"event-add_submit"}},[_c('div',{},[_c('div',{staticClass:"group"},[_c('section',{staticClass:"settings-section"},[_c('h3',{staticClass:"section-title"},[_vm._v("Basic Information")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormInput',{attrs:{"data":_vm.formData.controls.firstName}})],1),_c('div',{staticClass:"col col-6"},[_c('FormInput',{attrs:{"data":_vm.formData.controls.lastName}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormEmail',{attrs:{"data":_vm.formData.controls.email}})],1)])]),_c('section',{staticClass:"settings-section"},[_c('h3',{staticClass:"section-title"},[_vm._v("Password")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormPassword',{attrs:{"data":_vm.formData.controls.currentPassword}})],1),_c('div',{staticClass:"col col-6"},[_c('div',{staticClass:"div-password-rule"},[_c('ul',{staticClass:"password-rules",class:{
                            checking:
                              _vm.formData.controls.newPassword.value &&
                              _vm.formData.controls.currentPassword.value
                          }},[_c('li',{class:{
                              valid: _vm.passwordData.has8characters,
                              invalid: !_vm.passwordData.has8characters
                            }},[_vm._v(" PASSWORD MUST BE AT LEAST 8 CHARACTERS ")]),_c('li',{class:{
                              valid: _vm.passwordData.hasCapital,
                              invalid: !_vm.passwordData.hasCapital
                            }},[_vm._v(" PASSWORD MUST HAVE 1 UPPERCASE LETTER ")]),_c('li',{class:{
                              valid: _vm.passwordData.hasNumber,
                              invalid: !_vm.passwordData.hasNumber
                            }},[_vm._v(" PASSWORD MUST AT LEAST HAVE 1 NUMBER ")]),_c('li',{class:{
                              valid: _vm.passwordData.nonHtml,
                              invalid: !_vm.passwordData.nonHtml
                            }},[_vm._v(" NON HTML CONTENT ")]),_c('li',{class:{
                              valid:
                                _vm.formData.controls.newPassword.value ==
                                _vm.formData.controls.confirmPassword.value,
                              invalid:
                                _vm.formData.controls.newPassword.value !=
                                _vm.formData.controls.confirmPassword.value
                            }},[_vm._v(" PASSWORD MUST MATCH ")])])])])]),_c('div',{staticClass:"padding"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormPassword',{attrs:{"data":_vm.formData.controls.newPassword},on:{"input":function () {
                            _vm.formData.controls.currentPassword.error = '';
                            _vm.checkStrongPassword(
                              _vm.formData.controls.newPassword.value
                            );
                          }}})],1),_c('div',{staticClass:"col col-6"},[_c('FormPassword',{attrs:{"data":_vm.formData.controls.confirmPassword},on:{"input":function () {
                            _vm.formData.controls.currentPassword.error = '';
                          }}})],1)])]),_c('section',{staticClass:"settings-section"},[_c('h3',{staticClass:"section-title"},[_vm._v("People Details")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormSelect',{attrs:{"data":_vm.formData.controls.defaultMainPage}})],1),_c('div',{staticClass:"col col-6"},[_c('FormSelect',{attrs:{"data":_vm.formData.controls.timezones}})],1)])])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-head hasPaddingTop"},[_c('div',{staticClass:"head_left"},[_c('h2',{staticClass:"head_heading"},[_vm._v(" Profile ")])]),_c('div',{staticClass:"head_right"},[_c('div',{staticClass:"head__button"},[_c('button',{staticClass:"btn btn-lg btn-outline-primary",attrs:{"type":"submit"}},[_vm._v(" Save ")])])])])}]

export { render, staticRenderFns }