






























































































































































































import { defineComponent } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormInput from "@/components/Form/FormPhone.vue";
import directives from "@/helpers/directives";

export default defineComponent({
  name: "TransactionDetails",
  props: {
    details: Object,
    showCostCenter: {
      type: Boolean,
      defaultValue: false
    },
    showGlCodes: {
      type: Boolean,
      defaultValue: false
    }
  },
  directives,
  components: {
    Modal,
    FormSelect,
    FormNumber,
    FormTextarea,
    FormInput
  },
  setup(props, context) {
    return {};
  }
});
