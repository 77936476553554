







































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "GlobalSearch",
  props: {
    type: String,
    hasSearchItems: {
      type: Boolean,
      defaultValue: false
    }
  },
  components: {},
  setup(props, context) {
    const pageData = ref<{
      isProcessing: boolean;
      isSearched: boolean;
      title: string;
      items: any[];
      controls: {
        keyword: {
          value: string;
          placeholder: string;
        };
      };
    }>({
      isProcessing: false,
      isSearched: false,
      title: "",
      items: [],
      controls: {
        keyword: {
          value: "",
          placeholder: "BEGIN TYPING TO SEARCH FOR ANYTHING..."
        }
      }
    });
    const timer = ref<any>(null);
    const show = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const hint = ref<string>("");
    const focusing = ref<boolean>(false);
    const boldString = (str: string, substr: string) => {
      if (str.toLowerCase().indexOf(substr.toLowerCase()) == -1) {
        return str;
      }
      const n = str.toUpperCase();
      const q = substr.toUpperCase();
      const x = n.indexOf(q);
      const l = q.length;
      return (
        str.substr(0, x) + "<b>" + str.substr(x, l) + "</b>" + str.substr(x + l)
      );
    };
    const doSearch = async (key: string) => {
      pageData.value.isProcessing = true;
      try {
        pageData.value.isSearched = false;
        const result = await ApiHelper.callApi(
          "get",
          "/entities/items/search",
          {},
          {
            key: key
          }
        );
        if (result.status === 1) {
          const data: any = result.data || [];
          const profiles = (data.profiles || []).map((item: any) => {
            const phone = item.phone || "";
            const email = item.email || "";
            if (phone != "") {
              item.col1 = item.phone;
            } else {
              item.col1 = item.phone;
            }
            if (email != "") {
              item.title += `, ${email}`;
            }

            return item;
          });
          const tags = data.tags || [];
          const events = data.events || [];
          pageData.value.items = [
            ...profiles,
            ...tags,
            ...events,
            ...(data.families || [])
          ].map((item: any) => ({
            ...item,
            html: `
              <span>
                  ${boldString(item.title, key)}
                  ${
                    (item.type == "profile" && (item.pActive || 1) == 2) ||
                    (item.type == "event" && (item.status || 1) == 2)
                      ? "<span>(Archived)</span>"
                      : ""
                  }
                </span>
            `,
            data: item
          }));
        }
        pageData.value.isSearched = true;
        props.hasSearchItems = true;
      } catch (error) {
        props.hasSearchItems = false;
        pageData.value.isSearched = false;
        console.log(error);
      } finally {
        pageData.value.isProcessing = false;
      }
    };
    const onInput = async () => {
      if (timer.value) {
        clearTimeout(timer.value);
      }
      if (pageData.value.controls.keyword.value == "") {
        show.value = false;
        hint.value = "";
        /*if (props.alwaysEmitInput) {
          loading.value = true;
          await doSearch(pageData.value.controls.keyword.value );
          loading.value = false;
        }*/
      } else {
        show.value = false;
        timer.value = setTimeout(async () => {
          if (pageData.value.controls.keyword.value.length < 3) {
            hint.value = "Please type at least 3 characters to search";
          } else {
            hint.value = "";
            loading.value = true;
            await doSearch(pageData.value.controls.keyword.value);
            loading.value = false;
          }
          if (focusing.value == true) {
            show.value = true;
          }
        }, 300);
      }
    };

    return {
      pageData,
      onInput
    };
  }
});
