var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"dropdown-container"},[_c('a',{staticClass:"dropdown-select",class:{
      active: _vm.show,
      green: _vm.green,
      'pl-0': _vm.green,
      isLoading: _vm.isLoading
    },on:{"click":_vm.doClick}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"dropdown-box"},[_c('div',{staticClass:"dropdown-content",class:{
        leftToRight: _vm.leftToRight
      }},[_vm._t("dropdown_content",[_vm._v(" Nothing ")])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }