





















































import { defineComponent, ref } from "@vue/composition-api";
import HeaderActions from "@/components/Common/HeaderActions.vue";
import HeaderFilters from "@/components/Common/HeaderFilters.vue";
import BackButton from "@/components/Common/BackButton.vue";

export default defineComponent({
  name: "Header",
  props: {
    hideBackButton: {
      type: Boolean,
      default: false
    },
    title: String,
    subTitle: String,
    filters: Object,
    backRoute: Object,
    showTotal: Boolean,
    total: {
      type: Number,
      default: 0
    },
    totalAmount: String,
    btnName: String,
    paymentType: String,
    selectedIds: {
      type: Number,
      default: 0
    },
    getViewOptions: {
      type: Boolean,
      default: true
    },
    hasPaddingTop: {
      type: Boolean,
      default: false
    },
    filtersPosition: {
      type: String,
      default: ""
    }
  },
  components: {
    HeaderActions,
    HeaderFilters,
    BackButton
  }
});
