var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SettingsUserEditPage position-relative"},[_c('Header',{attrs:{"title":_vm.headerData.title,"sub-title":_vm.headerData.subTitle}},[_c('div',{staticClass:"head_right",attrs:{"slot":"head_right"},slot:"head_right"},[_c('div',{staticClass:"head__button"},[_c('button',{staticClass:"button__save",on:{"click":_vm.formData.actions.onSubmit}},[_vm._v(" Save ")])])])]),_c('div',{staticClass:"content-inside v1-page has3"},[_c('div',{staticClass:"content_add"},[_c('div',{staticClass:"content__form"},[_c('section',{staticClass:"settings-section"},[_c('h3',{staticClass:"section-title"},[_vm._v("Basic Information")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-6"},[_c('FormInput',{attrs:{"data":_vm.formData.controls.email}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-6"},[_c('FormInput',{attrs:{"data":_vm.formData.controls.firstName}})],1),_c('div',{staticClass:"col col-12 col-md-6"},[_c('FormInput',{attrs:{"data":_vm.formData.controls.lastName}})],1)])]),_c('section',{staticClass:"settings-section"},[_c('h3',{staticClass:"section-title"},[_vm._v("Permissions")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-12"},[_c('FormMultiSelect',{attrs:{"data":_vm.formData.controls.groups,"selection-label":"Select Group(s)"}})],1)])]),_c('section',{staticClass:"settings-section"},[_c('h3',{staticClass:"section-title"},[_vm._v("Password")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6 pb-4"},[_c('div',{staticStyle:{"border":"1px solid #eee","padding":"10px","max-width":"100%","min-height":"100%","background-color":"#FFFAF0"}},[_c('ul',{staticClass:"password-rules",class:{
                    checking: _vm.formData.controls.password.value
                  }},[_c('li',{class:{
                      valid: _vm.passwordData.has8characters,
                      invalid: !_vm.passwordData.has8characters
                    }},[_vm._v(" PASSWORD MUST BE AT LEAST 8 CHARACTERS ")]),_c('li',{class:{
                      valid: _vm.passwordData.hasCapital,
                      invalid: !_vm.passwordData.hasCapital
                    }},[_vm._v(" PASSWORD MUST HAVE 1 UPPERCASE LETTER ")]),_c('li',{class:{
                      valid: _vm.passwordData.hasNumber,
                      invalid: !_vm.passwordData.hasNumber
                    }},[_vm._v(" PASSWORD MUST AT LEAST HAVE 1 NUMBER ")]),_c('li',{class:{
                      valid: _vm.passwordData.nonHtml,
                      invalid: !_vm.passwordData.nonHtml
                    }},[_vm._v(" NON HTML CONTENT ")]),_c('li',{class:{
                      valid:
                        _vm.formData.controls.password.value ==
                        _vm.formData.controls.confirmPassword.value,
                      invalid:
                        _vm.formData.controls.password.value !=
                        _vm.formData.controls.confirmPassword.value
                    }},[_vm._v(" PASSWORD MUST MATCH ")])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.isUserLogin),expression:"formData.isUserLogin"}],staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormPassword',{attrs:{"data":_vm.formData.controls.currentPassword}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormPassword',{attrs:{"data":_vm.formData.controls.password},on:{"input":function($event){return _vm.checkStrongPassword(_vm.formData.controls.password.value)}}})],1),_c('div',{staticClass:"col col-6"},[_c('FormPassword',{attrs:{"data":_vm.formData.controls.confirmPassword}})],1)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }