






























































































































































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import directives from "@/helpers/directives";
import BackButton from "@/components/Common/BackButton.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormToggle from "@/components/Form/FormToggle.vue";
import { userAccountingPostingCodeEditStore } from "@/stores/Financial/Accounting/AccountingPostingCode/AccountingPostingCodeEditStore";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormTags from "@/components/Form/FormTags.vue";
import PopupEditCostCenter from "@/components/Event/PopupEditCostCenter.vue";

export default defineComponent({
  name: "AccountingPostingEdit",
  components: {
    FormTextarea,
    FormSelect,
    FormInput,
    Loading,
    BackButton,
    FormToggle,
    FormTags,
    PopupEditCostCenter
  },
  directives: directives,
  setup(props, context) {
    const {
      loadData,
      headerData,
      pageData,
      formData,
      isAllowAddNewCostCenter,
      onAddNewCostCenter,
      popupCostCenter,
      onRemoveCostCenter,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onSelectCostCenter,
      onTabPostingCostCenter
    } = userAccountingPostingCodeEditStore(context);

    return {
      loadData,
      headerData,
      pageData,
      formData,
      isAllowAddNewCostCenter,
      onAddNewCostCenter,
      popupCostCenter,
      onRemoveCostCenter,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onSelectCostCenter,
      onTabPostingCostCenter
    };
  }
});
