import { ApiHelper } from "@/helpers/index";

export const getFamilyInfo = async (id: number) => {
  try {
    const result = await ApiHelper.callApi(
      "get",
      "/families/" + id + "/info",
      {},
      {},
      "",
      false
    );
    if (result.status === 1) {
      return result.data || {};
    }
    return {
      familyId: 0,
      familyName: "<<unknown>>",
      entityId: 0
    };
  } catch (err) {
    console.log(err);
  }

  return null;
};
