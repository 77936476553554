import { ref } from "@vue/composition-api";
import { ApiHelper, ProfileHelper } from "@/helpers";
import { FormText } from "@/types";

export function useProfileNoteEditStore(context: any) {
  const selectedProfileId = parseInt(context.root.$route.params.profileId) || 0;
  const selectedNoteId = parseInt(context.root.$route.params.noteId) || 0;
  const itemEditInput = ref<{
    message: string;
    isfamilyUsed: number;
  }>({
    message: "",
    isfamilyUsed: 0
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      message: FormText;
      isfamilyUsed: {
        error: string;
        label: string;
        labelOn?: string;
        labelOff?: string;
        size?: string;
        value: boolean;
      };
    };
  }>({
    isLoading: true,
    controls: {
      message: {
        error: "",
        label: "Message",
        placeholder: "Enter Message",
        type: "text",
        value: "",
        style: "custom",
        required: true,
        maxlength: 250
      },
      isfamilyUsed: {
        error: "",
        label: "Type",
        labelOn: "Family",
        labelOff: "Individual",
        size: "large",
        value: false
      }
    }
  });
  const onSubmit = async (event: any) => {
    event.preventDefault();
    itemEditInput.value.message = ApiHelper.removeHtml(
      formData.value.controls.message.value
    );
    itemEditInput.value.isfamilyUsed = formData.value.controls.isfamilyUsed
      .value
      ? 1
      : 0;
    let hasError = false;
    if (itemEditInput.value.message === "") {
      hasError = true;
      formData.value.controls.message.error = "Message is required";
    }
    if (!hasError) {
      formData.value.isLoading = true;
      let result;
      if (selectedNoteId) {
        result = await ApiHelper.callApi(
          "put",
          "/messages/" + selectedNoteId,
          itemEditInput.value,
          {}
        );
      } else {
        result = await ApiHelper.callApi(
          "post",
          "/messages",
          {
            ...itemEditInput.value,
            profileId: selectedProfileId,
            typeId: 2
          },
          {}
        );
      }

      formData.value.isLoading = false;
      if (result.status == 1) {
        const fromProfileId =
          parseInt(context.root.$route.query.fromProfileId) || 0;
        ApiHelper.showSuccessMessage("Saved!");
        ApiHelper.gotoPage(context, {
          name: "ProfileNotes",
          params: {
            profileId:
              fromProfileId > 0 ? fromProfileId : selectedProfileId.toString()
          }
        });
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    }
  };

  (async () => {
    if (selectedNoteId) {
      const result = await ApiHelper.callApi(
        "get",
        "/messages/" + selectedNoteId,
        {},
        {}
      );
      formData.value.isLoading = false;
      if (result.status === 1) {
        const item = result.data || {};
        const strippedString = item.message.replace(/<br\s*[\\/]?>/gi, "\n");
        formData.value.controls.message.value = strippedString || "";
        formData.value.controls.isfamilyUsed.value = !!item.isfamilyUsed;
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    } else {
      formData.value.isLoading = false;
    }
  })();

  const removeFieldError = (name: string) => {
    // do nothing
  };

  const isArchived = ref(false);
  (async () => {
    const profileInfo = await ProfileHelper.getProfileInfo(selectedProfileId);
    isArchived.value = profileInfo.status === 2;
  })();

  return {
    isArchived,
    formData,
    removeFieldError,
    onSubmit
  };
}
