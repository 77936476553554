


























































































































































import { defineComponent, getCurrentInstance, ref } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Header from "@/components/Common/Header.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import Modal from "@/components/Common/Modal.vue";
import FormPhoto from "@/components/Form/FormPhoto.vue";
import Vue from "vue";
import $ from "jquery";
import { saveAs } from "file-saver";
import { useFamilyFilesStore } from "@/stores/Family/FamilyFilesStore";
import NavFamilyDetails from "@/components/Family/NavFamilyDetails.vue";

export default defineComponent({
  name: "FamilyFiles",
  components: {
    NavFamilyDetails,
    Header,
    Loading,
    Pager,
    TableHeaderColumn,
    Modal,
    FormPhoto
  },
  setup(props, context) {
    const instance = getCurrentInstance();
    const selectedProfileId = parseInt(context.root.$route.params.profileId);
    const {
      doResetPassword,
      doUpdateStatus,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData
    } = useFamilyFilesStore(context);
    const showPopup = ref(false);
    const inputFile = ref({});
    const formData = ref({
      isProcessing: false,
      controls: {
        photo: {
          error: "",
          type: "upload",
          value: "",
          preview: "",
          s3url: "",
          path: "",
          delete: false
        }
      }
    });

    const updateSidebarTotalFiles = () => {
      const parent: any = instance?.parent || {};
      if (parent && parent.refs.SidebarProfileRef) {
        parent.refs.SidebarProfileRef.sidebarData.totalFiles =
          pageData.value.pager.total;
      }
    };

    const changeProfileAvatar = async (s3url: string) => {
      pageData.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "post",
        "/profiles/" + selectedProfileId + "/files",
        {
          s3url: s3url
        },
        {}
      );
      if (result.status === 1) {
        showPopup.value = false;
        ApiHelper.showSuccessMessage("Added image");
        await loadList();
        updateSidebarTotalFiles();
      } else {
        pageData.value.isLoading = false;
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    };

    const onFileChange = async (e: any) => {
      const files = e.target.files || [];
      for (const file of files) {
        if (file.size > 5 * 1024 * 1024) {
          ApiHelper.showErrorMessage(
            file.name + " is greater than 5MB. Please use another file",
            "Oops"
          );
          return;
        }
      }

      pageData.value.isLoading = true;
      for (const file of files) {
        if (file) {
          const ext = ApiHelper.getFileExt(file.name);
          if (
            ["doc", "docx", "pdf", "jpg", "jpeg"].includes(ext.toLowerCase())
          ) {
            const result = await ApiHelper.uploadProfileFile(
              selectedProfileId,
              file.name,
              file
            );
            if (result.status === 1) {
              pageData.value.isLoading = false;
              ApiHelper.showSuccessMessage("Added file");
            }
          } else {
            ApiHelper.showErrorMessage(
              "Please select a PDF/DOC/JPG file to upload!",
              "Oops"
            );
            pageData.value.isLoading = false;
            return;
          }
        }
      }
      $("#uploadFiles").val("");
      await loadList();
      updateSidebarTotalFiles();
    };

    const getProfileNameFromSidebar = ($parent: any) => {
      const SidebarProfileRef = $parent.$refs.SidebarProfileRef || {};
      const sidebarData = SidebarProfileRef.sidebarData || {};
      return sidebarData.name || "";
    };

    const onRemoveItem = (item: any, $parent: any) => {
      (async () => {
        const isAgreed = await Vue.swal({
          html: "Are you sure you want to delete this file?",
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          return result.isConfirmed;
        });

        if (isAgreed) {
          pageData.value.isLoading = true;
          const result = await ApiHelper.callApi(
            "delete",
            `/profiles/${selectedProfileId}/files/${item.id}`,
            {},
            {}
          );
          pageData.value.isLoading = false;
          if (result.status == 1) {
            ApiHelper.showSuccessMessage("Removed");
            await loadList();
            updateSidebarTotalFiles();
          } else {
            ApiHelper.showErrorMessage(result.message);
          }
        }
      })();
    };

    const downloadFile = (file: any) => {
      saveAs(file.filePath, file.fileName);
    };

    return {
      downloadFile,
      onRemoveItem,
      getProfileNameFromSidebar,
      inputFile,
      onFileChange,
      selectedProfileId,
      showPopup,
      formData,
      changeProfileAvatar,
      doResetPassword,
      doUpdateStatus,
      headerData,
      pageData,
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      ApiHelper: ApiHelper
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
