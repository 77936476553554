




















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "FormInput",
  props: {
    data: Object
  },
  setup() {
    return {};
  }
});
