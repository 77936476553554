




































































































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "FormSelect",
  props: {
    data: Object,
    onChange: Function,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const randomId = ref<number>(ApiHelper.randomFormId());
    const doChange = () => {
      if (props.data) {
        props.data.error = "";
      }
      if (props.onChange) {
        props.onChange();
      }
    };
    return {
      randomId: randomId.value.toString(),
      doChange
    };
  }
});
