import { ref } from "@vue/composition-api";
import {
  EventNewInput,
  FormSelect,
  FormText,
  LinkEventParticipant,
  SelectOption
} from "@/types";
import { ApiHelper } from "@/helpers";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import FormTags from "@/types/FormTags";
import Vue from "vue";

type FormData = {
  eventStatus: number;
  isLoading: boolean;
  controls: {
    name: FormText;
    tsStart: {
      label?: string;
      error: string;
      type: string;
      value: Date | undefined | string;
      disabled?: boolean;
    };
    tsEnd: {
      label?: string;
      error: string;
      type: string;
      value: Date | undefined | string;
      disabled?: boolean;
    };
    location: {
      label?: string;
      error: string;
      type: string;
      value: string;
      disabled?: boolean;
    };
    address: FormText;
    city: FormText;
    state: FormSelect;
    zipcode: FormText;
    entity: {
      label?: string;
      error: string;
      type: string;
      options: SelectOption[];
      value: string;
    };
    participantTypes: {
      label?: string;
      error: string;
      type: string;
      options: SelectOption[];
      value: string[];
    };
    services: {
      label?: string;
      error: string;
      type: string;
      options: SelectOption[];
      value: string[];
    };
    costcenter: FormSelect;
    applications: {
      label?: string;
      error: string;
      type: string;
      options: SelectOption[];
      value: string[];
    };
    forcePayment: {
      error: string;
      label: string;
      value: boolean;
      disabled?: boolean;
    };
    tags: FormTags;
    eventCostCenters: {
      error: string;
      label: string;
      textOverflow: boolean;
      placeholder: string;
      type: string;
      key: string;
      value: { id: string; text: string }[];
      options: any;
      suggestTags: any;
      isFirstFocus: boolean;
    };
    eventTypes: {
      error: string;
      label: string;
      textOverflow: boolean;
      placeholder: string;
      type: string;
      key: string;
      value: { id: string; text: string }[];
      options: any;
      suggestTags: any;
      isFirstFocus: boolean;
    };
  };
  foundLocations: object[];
  foundPTypes: object[];
  arrGenders: SelectOption[];
  isAddNewRow: number;
};

export function useEventSettingsStore(context: any) {
  const isACILoading = ref<boolean>(false);
  const selectedEventId = parseInt(context.root.$route.params.eventId) || 0;
  const linkEventParticipants = ref<LinkEventParticipant[]>([]);
  const linkEventServices = ref<any[]>([]);
  // const existedLinkEventParticipants = ref<ExistedLinkEventParticipant[]>([]);
  const formInput = ref<EventNewInput>({
    name: "",
    tsStart: undefined,
    tsEnd: undefined,
    location: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    applications: [],
    participantTypes: [],
    entityId: 0,
    tags: []
  });
  const formData = ref<FormData>({
    eventStatus: 1,
    isLoading: true,
    controls: {
      name: {
        label: "Event Name",
        placeholder: "Enter Event name",
        maxlength: 150,
        style: "custom",
        required: true,
        error: "",
        type: "text",
        value: ""
      },
      tsStart: {
        label: "Event Start Date",
        error: "",
        type: "date",
        value: undefined
      },
      tsEnd: {
        label: "Event End Date",
        error: "",
        type: "date",
        value: undefined
      },
      location: {
        error: "",
        type: "date",
        value: ""
      },
      address: {
        label: "Address",
        placeholder: "Enter Address",
        maxlength: 150,
        error: "",
        value: "",
        style: "custom"
      },
      city: {
        label: "City",
        placeholder: "Enter City",
        error: "",
        type: "date",
        value: "",
        style: "custom"
      },
      state: {
        error: "",
        value: "",
        label: "State",
        placeholder: "Select...",
        style: "custom",
        options: ApiHelper.getStateOptions()
      },
      zipcode: {
        error: "",
        type: "date",
        value: ""
      },
      entity: {
        error: "",
        type: "date",
        options: [],
        value: "1"
      },
      participantTypes: {
        error: "",
        type: "multiselect",
        options: [],
        value: []
      },
      services: {
        error: "",
        type: "multiselect",
        options: [],
        value: []
      },
      applications: {
        error: "",
        type: "select2",
        options: [],
        value: []
      },
      costcenter: {
        notRequired: true,
        label: "Cost Center",
        placeholder: "Please choose cost center",
        style: "custom",
        error: "",
        type: "select",
        options: [],
        value: ""
      },
      forcePayment: {
        error: "",
        label: "Force Payment",
        value: false
      },
      tags: {
        error: "",
        label: "Tags",
        placeholder: "Enter a tag",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: []
      },
      eventCostCenters: {
        error: "",
        label: "Cost Center Number",
        textOverflow: true,
        placeholder: "Cost Center Number",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: [],
        isFirstFocus: true
      },
      eventTypes: {
        error: "",
        label: "Event Type",
        textOverflow: true,
        placeholder: "Event Type",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: [],
        isFirstFocus: true
      }
    },
    foundLocations: [],
    foundPTypes: [],
    arrGenders: ApiHelper.getGenderOptions(),
    isAddNewRow: 1
  });

  const popupCostCenter = ref<{
    show: boolean;
    key: string;
    callback: Function;
  }>({
    show: false,
    key: "",
    callback: (item: any) => {
      // nothing
    }
  });

  const popupEventType = ref<{
    show: boolean;
    key: string;
    callback: Function;
  }>({
    show: false,
    key: "",
    callback: (item: any) => {
      // nothing
    }
  });

  const onSubmit = async ($parent: any) => {
    let hasError = false;
    // reset
    formData.value.controls.participantTypes.error = "";
    formData.value.controls.services.error = "";
    formData.value.controls.tsStart.error = "";
    formData.value.controls.tsEnd.error = "";
    formData.value.controls.name.value = ApiHelper.stripTags(
      formData.value.controls.name.value
    );
    formData.value.controls.address.value = ApiHelper.stripTags(
      formData.value.controls.address.value
    );
    formData.value.controls.city.value = ApiHelper.stripTags(
      formData.value.controls.city.value
    );
    formData.value.controls.state.value = ApiHelper.stripTags(
      formData.value.controls.state.value
    );

    formData.value.controls.zipcode.value = ApiHelper.stripTags(
      formData.value.controls.zipcode.value
    );
    formInput.value.name = formData.value.controls.name.value;
    if (formData.value.controls.tsStart.value) {
      formInput.value.tsStart = moment(
        moment(formData.value.controls.tsStart.value).format("YYYY-MM-DD") +
          "T00:00:00+0000"
      ).toDate();
    } else {
      formInput.value.tsStart = undefined;
    }
    if (formData.value.controls.tsEnd.value) {
      formInput.value.tsEnd = moment(
        moment(formData.value.controls.tsEnd.value).format("YYYY-MM-DD") +
          "T23:59:59+0000"
      ).toDate();
    } else {
      formInput.value.tsEnd = undefined;
    }
    formInput.value.location = formData.value.controls.location.value;
    formInput.value.address = formData.value.controls.address.value;
    formInput.value.city = formData.value.controls.city.value;
    formInput.value.state = formData.value.controls.state.value;
    formInput.value.zipcode = formData.value.controls.zipcode.value;
    formInput.value.entityId = parseInt(formData.value.controls.entity.value);
    formInput.value.participantTypes = formData.value.controls.participantTypes.value.map(
      value => {
        return parseInt(value);
      }
    );

    formInput.value.tags = formData.value.controls.tags.value.map(tag => {
      return {
        id: parseInt(tag.id),
        text: tag.text
      };
    });

    if (formInput.value.name === "") {
      hasError = true;
      formData.value.controls.name.error = "Event name is required!";
    }

    if (
      formInput.value.tsStart !== undefined ||
      formInput.value.tsEnd !== undefined
    ) {
      if (!formInput.value.tsStart) {
        hasError = true;
        formData.value.controls.tsStart.error = "Start Date is required";
      }
      if (!formInput.value.tsEnd) {
        hasError = true;
        formData.value.controls.tsEnd.error = "End Date is required";
      }
    }

    if (
      formInput.value.tsStart !== undefined &&
      formInput.value.tsEnd !== undefined
    ) {
      if (formInput.value.tsStart > formInput.value.tsEnd) {
        hasError = true;
        formData.value.controls.tsEnd.error = "End Date is invalid";
      }
    }
    // validate participant types
    const pTypes = linkEventParticipants.value.filter(
      item => item.participantTypeName.trim() != ""
    );

    // validate participant types
    const services = linkEventServices.value.filter(
      item => item.serviceName.trim() != ""
    );

    if (!hasError) {
      formData.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "put",
        "/events/" + selectedEventId,
        {
          name: formInput.value.name,
          tsStart: formInput.value.tsStart,
          tsEnd: formInput.value.tsEnd,
          locationName: formInput.value.location,
          // applicationIds: formInput.value.applications.join(","),
          address: formInput.value.address,
          city: formInput.value.city,
          state: formInput.value.state,
          zipcode: formInput.value.zipcode,
          pTypes: pTypes.length ? JSON.stringify(pTypes) : "",
          addons: services.length ? JSON.stringify(services) : "",
          forcepayment: formData.value.controls.forcePayment.value ? 1 : 0,
          costcenterId: parseInt(formData.value.controls.costcenter.value) || 0,
          locationId: formInput.value.locationId,
          tags: formInput.value.tags,
          costCenterNumber: formData.value.controls.eventCostCenters.value
            .map((t: any) => t.id)
            .join(","),
          eventTypeId:
            parseInt(formData.value.controls.eventTypes.value[0]?.id) || 0
        }
      );

      formData.value.isLoading = false;
      if (result.status == 1) {
        ApiHelper.showSuccessMessage("Updated");
        const parent = $parent;
        if (parent.$refs.SidebarEventDetailsRef) {
          parent.$refs.SidebarEventDetailsRef.loadData();
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops..");
      }
    }
  };

  const removeFieldError = (name: string) => {
    switch (name) {
      case "name":
        formData.value.controls.name.error = "";
        break;
      case "tsStart":
        formData.value.controls.tsStart.error = "";
        break;
      case "tsEnd":
        formData.value.controls.tsEnd.error = "";
        break;
      case "location":
        formData.value.controls.location.error = "";
        break;
      case "address":
        formData.value.controls.address.error = "";
        break;
      case "city":
        formData.value.controls.city.error = "";
        break;
      case "state":
        formData.value.controls.state.error = "";
        break;
      case "zipcode":
        formData.value.controls.zipcode.error = "";
        break;
      case "entity":
        formData.value.controls.entity.error = "";
        break;
      case "participantTypes":
        formData.value.controls.participantTypes.error = "";
        break;
    }
  };

  const getPTypesHtml = (item: any) => {
    let ret = `${item.name}`;
    if (item.capacity || item.typeages || item.gender) {
      const moreInfo = [];
      if (item.capacity) {
        moreInfo.push(`Capacity: ${item.capacity}`);
      }
      if (item.typeages) {
        moreInfo.push(`Age: ${item.typeages}`);
      }
      if (item.gender) {
        moreInfo.push(`Gender: ${ApiHelper.getGenderName(item.gender)}`);
      }
      ret = `${item.name} - (${moreInfo.join(", ")})`;
    } else {
      ret = `${item.name}`;
    }

    return ret;
  };

  const initCustomFields = (item?: any): object[] => {
    let cf: any = [];
    if (item && item.cfIds && item.cfIds != "") {
      const cfIds = item.cfIds.split("---");
      const cfNames = item.cfNames.split("---");
      const cfValues = item.cf_values.split("---");
      if (cfIds.length && cfNames.length && cfValues.length) {
        for (const i in cfIds) {
          cf.push({
            id: cfIds[i],
            title: cfNames[i],
            value: cfValues[i],
            show: true
          });
        }
      }
    } else {
      if (linkEventParticipants.value[0]) {
        cf = linkEventParticipants.value[0].customFields || [];
        if (cf.length) {
          cf = cf.map((item: any) => ({
            ...item,
            id: 0,
            value: "",
            show: true
          }));

          return cf;
        }
      }
    }

    return cf;
  };

  const addPTypeRow = () => {
    linkEventParticipants.value.push({
      uuid: uuidv4(),
      id: 0,
      eventId: 0,
      participantTypeName: "",
      participantTypeId: 0,
      capacity: 0,
      capacityMale: 0,
      capacityFemale: 0,
      cost: 0,
      gender: 0,
      age: "",
      active: 0,
      customFields: initCustomFields(),
      showRemoveCntDown: false,
      cntDown: 3
    });
  };

  const suggestPTypes = async (key: string, item: any, index: number) => {
    // reset
    formData.value.foundPTypes = [];
    item.participantTypeName = key;

    // add new row once they get down to the last row
    if (index == linkEventParticipants.value.length - 1) {
      addPTypeRow();
    }

    if (key !== "") {
      // searching
      item.id = -1;

      const selectedPTypeNames = linkEventParticipants.value
        .filter(item => item.participantTypeName != "" && item.id != -1)
        .map(item => item.participantTypeName);
      try {
        const result = await ApiHelper.callApi(
          "get",
          "/participants/ptypes/search",
          {},
          {
            key: key,
            take: 25,
            selectedNames: selectedPTypeNames.length
              ? JSON.stringify(selectedPTypeNames)
              : ""
          }
        );
        if (result.status === 1) {
          formData.value.foundPTypes = result.data.ptypes.map((item: any) => {
            const cost = (item.cost || 0).toFixed(2);
            item.cost = parseFloat(cost);
            return {
              id: item.id,
              text: item.name,
              html: getPTypesHtml(item),
              data: item
            };
          });
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        item.id = 0;
      }
    }
  };

  const selectPType = (data: any, item: any) => {
    item.id = data.id || 0;
    item.participantTypeId = data.id || 0;
    item.participantTypeName = data.name || "";
    item.capacity = data.capacity || 0;
    item.capacityMale = data.capacityMale || 0;
    item.capacityFemale = data.capacityFemale || 0;
    item.gender = data.gender || 0;
    item.cost = data.cost || 0;
    item.age = data.typeages || "";
  };

  const removePTypes = async (pType: any) => {
    const newLines = linkEventParticipants.value.filter(
      (item: any) => item.uuid != pType.uuid
    );
    linkEventParticipants.value = newLines;
  };

  const popupNewPTypeCol = ref<{
    isProcessing: boolean;
    show: boolean;
    newColName: string;
    removeFieldError: any;
    errMessage: string;
    currentCFList: any;
    selectedCF: any;
    showModal: any;
    onSubmit: any;
    updateSelectedCF: any;
    showRemoveCntDown: any;
    delayCountDown: any;
    cancelRemovePType: any;
  }>({
    isProcessing: false,
    show: false,
    newColName: "",
    removeFieldError: (name: string) => {
      popupNewPTypeCol.value.errMessage = "";
    },
    errMessage: "",
    currentCFList: [],
    selectedCF: [],
    showModal: () => {
      popupNewPTypeCol.value.newColName = "";
      popupNewPTypeCol.value.show = true;
    },
    onSubmit: async () => {
      // validate
      let hasError = false;
      if (popupNewPTypeCol.value.newColName == "") {
        hasError = true;
      }

      if (!hasError) {
        popupNewPTypeCol.value.isProcessing = true;
        // check existed
        const tmp = linkEventParticipants.value[0].customFields || [];
        const existedCF = tmp.find(
          (item: any) =>
            item.title.toLowerCase() ==
            popupNewPTypeCol.value.newColName.toLowerCase()
        );
        if (!tmp.length || !existedCF) {
          // persist a custom field
          const result = await ApiHelper.callApi(
            "post",
            "/events/ptypeCustomField",
            {
              name: popupNewPTypeCol.value.newColName,
              type: 1
            }
          );
          if (result.status !== 1) {
            popupNewPTypeCol.value.show = false;
            popupNewPTypeCol.value.isProcessing = false;
            ApiHelper.showErrorMessage(result.message, "Oops");
            return;
          }
          // add new column
          for (const item of linkEventParticipants.value) {
            const cf = [...item.customFields];
            cf?.push({
              id: result.data.id,
              title: popupNewPTypeCol.value.newColName,
              value: "",
              show: true
            });
            item.customFields = cf;
          }

          // update current custom fields list
          const existed = popupNewPTypeCol.value.currentCFList.find(
            (item: any) =>
              item.cfName.toLowerCase() ==
              popupNewPTypeCol.value.newColName.toLowerCase()
          );
          if (!existed) {
            popupNewPTypeCol.value.currentCFList.push({
              cfId: result.data.id,
              cfName: popupNewPTypeCol.value.newColName
            });
            popupNewPTypeCol.value.selectedCF.push(result.data.id);
          }
        }
        popupNewPTypeCol.value.show = false;
        popupNewPTypeCol.value.isProcessing = false;
      }
    },
    updateSelectedCF: () => {
      const selectedTitles = popupNewPTypeCol.value.currentCFList
        .filter((item: any) =>
          popupNewPTypeCol.value.selectedCF.includes(item.cfId)
        )
        .map((t: any) => t.cfName.toLowerCase());
      for (const item of linkEventParticipants.value) {
        item.customFields = item.customFields.map((cf: any) => ({
          ...cf,
          show: selectedTitles.includes(cf.title.toLowerCase()) ? true : false
        }));
      }
    },
    showRemoveCntDown: (item: any) => {
      item.showRemoveCntDown = true;
      item.cntDown = 3;
      popupNewPTypeCol.value.delayCountDown(item);
    },
    delayCountDown: (item: any) => {
      item.removeTimer = setTimeout(async () => {
        item.cntDown--;
        if (item.cntDown > 0) {
          popupNewPTypeCol.value.delayCountDown(item);
        } else {
          await removePTypes(item);
          for (const ptype of linkEventParticipants.value) {
            const aci = context.refs[`aci-${ptype.uuid}`];
            if (typeof aci[0] != "undefined") {
              aci[0].key = ptype.participantTypeName;
              aci[0].$forceUpdate();
            }
          }
          // add more row?
          if (linkEventParticipants.value.length == 0) {
            addPTypeRow();
          } else {
            const lastRow =
              linkEventParticipants.value[
                linkEventParticipants.value.length - 1
              ];
            if (lastRow.participantTypeName != "") {
              addPTypeRow();
            }
          }
        }
      }, 1000);
    },
    cancelRemovePType: (item: any) => {
      clearTimeout(item.removeTimer);
      item.showRemoveCntDown = false;
      item.cntDown = 3;
    }
  });

  // auto fill for capacity
  const capacityInput = (column: string, item: any) => {
    try {
      const capacity = parseInt(item.capacity) || 0;
      const capacityMale = parseInt(item.capacityMale) || 0;
      const capacityFemale = parseInt(item.capacityFemale) || 0;
      if (
        !isNaN(capacityMale) &&
        capacityMale > 0 &&
        !isNaN(capacityFemale) &&
        capacityFemale > 0
      ) {
        if (column == "capacityMale" || column == "capacityFemale") {
          item.capacityMale = capacityMale;
          item.capacityFemale = capacityFemale;
          item.capacity = capacityFemale + capacityMale;
        }
        if (column == "capacity") {
          item.capacity = capacity;
          item.capacityMale = 0;
          item.capacityFemale = 0;
        }
        context.root.$forceUpdate();
      } else {
        if (column == "capacityMale") {
          item.capacityMale = capacityMale;
          if (capacity === 0) {
            item.capacity = capacityFemale + capacityMale;
          } else if (capacity < capacityMale + capacityFemale) {
            item.capacity = capacityMale + capacityFemale;
          }
        }
        if (column == "capacityFemale") {
          item.capacityFemale = capacityFemale;
        }
        if (column == "capacity") {
          item.capacity = capacity;
          item.capacityMale = 0;
          item.capacityFemale = 0;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePtypesList = (ptypesList: any) => {
    const pTypeIds = ptypesList.map((item: any) => item.participantTypeId);
    linkEventParticipants.value = linkEventParticipants.value.filter(item =>
      pTypeIds.includes(item.participantTypeId)
    );

    for (const item of ptypesList) {
      const relatedType = linkEventParticipants.value.find(
        t => t.participantTypeId == item.participantTypeId
      );
      if (!relatedType) {
        linkEventParticipants.value.push({
          uuid: uuidv4(),
          id: item.id || 0,
          linkId: item.linkId || 0,
          eventId: selectedEventId,
          participantTypeName: item.participantTypeName || "",
          participantTypeId: item.participantTypeId || 0,
          capacity: item.capacity || 0,
          capacityMale: item.capacityMale || 0,
          capacityFemale: item.capacityFemale || 0,
          cost: item.cost || 0,
          gender: item.gender || 0,
          age: item.age || "",
          applications: item.applications || "",
          totalParticipants: item.totalParticipants || 0,
          customFields: item.cf || [],
          showRemoveCntDown: false,
          cntDown: 3
        });
      } else {
        relatedType.participantTypeName = item.participantTypeName || "";
        relatedType.cost = item.cost || 0;
      }
    }
  };

  // cost centers
  const onSuggestCostCenters = async (key: string) => {
    formData.value.controls.eventCostCenters.isFirstFocus = true;
    const costCenters = formData.value.controls.eventCostCenters;
    const notInIds = costCenters.value.map((item: any) => item.id).join(",");
    const result = await ApiHelper.callApi(
      "get",
      "/accounting/costcenters",
      {},
      {
        getAll: 1,
        order: 1,
        direction: 1,
        key,
        notInIds
      }
    );
    if (result.status === 1) {
      costCenters.suggestTags = result.data.items.map((item: any) => {
        return {
          id: item.costCenterNumber,
          text: item.costCenterName || item.costCenterNumber,
          data: item
        };
      });
    }
  };

  const onRemoveLastCostCenter = () => {
    const costCenters = formData.value.controls.eventCostCenters;
    if (costCenters.value.length > 0 && costCenters.key === "") {
      const index = costCenters.value.length - 1;
      costCenters.value.splice(index, 1);
    }
  };
  const onRemoveCostCenter = (index: number) => {
    const costCenters = formData.value.controls.eventCostCenters;
    if (costCenters.value.length > index) {
      costCenters.value.splice(index, 1);
    }
  };

  const onSelectCostCenter = (item: any) => {
    const costCenters = formData.value.controls.eventCostCenters;
    costCenters.key = "";
    costCenters.suggestTags = [];
    costCenters.value.push({
      id: item.id,
      text: item.id
    });
  };

  const onAddNewCostCenter = async (key: string) => {
    if (key.trim() == "") return;
    popupCostCenter.value.key = key;
    popupCostCenter.value.show = true;
    return;

    const costCenterRef: any = context.refs.eventCostCenterRef;
    const costCenters = formData.value.controls.eventCostCenters;

    // auto selecting if cost center number existed in suggested items
    const existed = costCenters.suggestTags.find(
      (item: any) => `${item.id}`.toLowerCase() == key.toLowerCase()
    );
    if (existed) {
      // auto select
      costCenters.value.push({
        id: existed.id,
        text: existed.text
      });
      costCenters.key = "";
      if (costCenterRef) {
        costCenterRef.show = false;
      }

      return;
    }

    // add new cost center number
    const result = await ApiHelper.callApi(
      "put",
      `/accounting/costcenters/0`,
      {
        number: key
      },
      {}
    );
    if (result.status == 1) {
      // update cost centers for current addon
      costCenters.value.push({
        id: key,
        text: key
      });
      costCenters.key = "";
      if (costCenterRef) {
        costCenterRef.show = false;
      }
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      return;
    }
  };

  const suggestLocation = async (key: string) => {
    formData.value.controls.location.value = key;
    isACILoading.value = true;
    const result = await ApiHelper.callApi(
      "get",
      "/locations/search",
      {},
      {
        key: key
      }
    );
    if (result.status === 1) {
      formData.value.foundLocations = result.data.locations.map(
        (item: any) => ({
          id: item.id,
          text: item.name,
          data: item
        })
      );
      // formInput.value.locationId = 0;
    }
    isACILoading.value = false;
  };

  const updateLocation = (data: any, type = "add") => {
    formInput.value.locationId = data.id || 0;
    formData.value.controls.location.value = data.name;
    formData.value.controls.address.value = data.address;
    formData.value.controls.city.value = data.city;
    formData.value.controls.state.value = data.state;
    formData.value.controls.zipcode.value = data.zipcode;

    // auto suggest cost center number
    const costCenterNumber = data.costCenterNumber || "";
    if (costCenterNumber != "") {
      formData.value.controls.eventCostCenters.value = [
        {
          id: costCenterNumber,
          text: costCenterNumber
        }
      ];
    }
  };

  const onSuggestTags = async (key: string) => {
    const notInIds = formData.value.controls.tags.value
      .map(item => item.id)
      .join(",");
    const result = await ApiHelper.callApi(
      "get",
      "/tags/search",
      {},
      {
        // typeUse: "finance",
        key: key,
        notInIds: notInIds
      }
    );
    if (result?.status === 1) {
      formData.value.controls.tags.suggestTags = result.data.tags.map(
        (item: any) => {
          return {
            id: item.id,
            text: item.text,
            data: item
          };
        }
      );
    }
  };

  const onAddTag = () => {
    const key = formData.value.controls.tags.key.trim();
    if (key) {
      formData.value.controls.tags.value.push({
        id: 0,
        text: key,
        data: {}
      });
      formData.value.controls.tags.key = "";
    }
  };

  const onRemoveLastTag = (event: any) => {
    if (
      formData.value.controls.tags.value.length > 0 &&
      formData.value.controls.tags.key === ""
    ) {
      const index = formData.value.controls.tags.value.length - 1;
      formData.value.controls.tags.value.splice(index, 1);
    }
  };
  const onRemoveTag = (index: number) => {
    if (formData.value.controls.tags.value.length > index) {
      formData.value.controls.tags.value.splice(index, 1);
    }
  };

  const onSelectTag = (item: any) => {
    formData.value.controls.tags.key = "";
    formData.value.controls.tags.suggestTags = [];
    formData.value.controls.tags.value.push({
      id: item.id,
      text: item.text,
      data: item.data || {}
    });
  };

  const onTabPostingCostCenter = (
    e: any,
    type: string,
    item: any,
    index: number,
    callback: Function,
    allowTab = ""
  ) => {
    if (type == "costcenter") {
      if (item.key.trim() != "" && (item.suggestTags || []).length == 0) {
        if (!allowTab) {
          e.preventDefault();
        }
        if (callback) {
          callback();
        }
        setTimeout(() => {
          $(".edit-cost-center-modal input")
            .first()
            .focus();
        }, 300);
      }
    } else if (type == "posting") {
      if (
        item.postingCodes.key.trim() != "" &&
        (item.postingCodes.suggestTags || []).length == 0
      ) {
        if (!allowTab) {
          e.preventDefault();
        }
        if (callback) {
          callback();

          setTimeout(() => {
            $(".new-posting-code-modal input")
              .first()
              .focus();
          }, 300);
        }
      }
    }
  };

  const isAllowAddNewEventType = (item: any) => {
    if ((item.suggestTags || []).length == 0 && item.key != "") {
      return true;
    }

    return false;
  };

  const onAddNewEventType = async (key: string) => {
    if (key.trim() == "") return;
    popupEventType.value.key = key;
    popupEventType.value.show = true;
    return;
  };

  const onRemoveEventType = (index: number) => {
    const eventTypes = formData.value.controls.eventTypes;
    if (eventTypes.value.length > index) {
      eventTypes.value.splice(index, 1);
    }
  };

  const onSuggestEventType = async (key: string) => {
    // formData.value.controls.eventCostCenters.isFirstFocus = true;
    const eventTypes = formData.value.controls.eventTypes;
    const notInEventTypeIds = eventTypes.value
      .map((item: any) => item.id)
      .join(",");
    const result = await ApiHelper.callApi(
      "get",
      "/events/eventTypes",
      {},
      {
        getAll: 1,
        order: 1,
        direction: 1,
        eventTypeName: key,
        notInEventTypeIds
      }
    );
    if (result.status === 1) {
      eventTypes.suggestTags = result.data.items.map((item: any) => {
        let itemText = item.eventTypeName;
        if (item.costCenterNumber) {
          itemText += ` - ${item.costCenterNumber}`;
        }
        return {
          id: item.eventTypeId,
          text: itemText,
          data: item
        };
      });
    }
  };

  const onRemoveLastEventType = () => {
    const eventTypes = formData.value.controls.eventTypes;
    if (eventTypes.value.length > 0 && eventTypes.key === "") {
      const index = eventTypes.value.length - 1;
      eventTypes.value.splice(index, 1);
    }
  };

  const onSelectEventType = (item: any) => {
    const eventTypes = formData.value.controls.eventTypes;
    eventTypes.key = "";
    eventTypes.suggestTags = [];
    eventTypes.value.push({
      id: item.id,
      text: item.text
    });
  };

  // init data
  (async () => {
    // formData.value.controls.entity.options = await ApiHelper.getEntityOptions();
    const result = await ApiHelper.callApi(
      "get",
      "/events/" + selectedEventId,
      {}
    );

    const eventData = result.data || {};
    const eventId = Number.parseInt(eventData.id || "0") || 0;
    if (!eventId) {
      ApiHelper.showErrorMessage("Event #" + selectedEventId + " is not found");
      ApiHelper.gotoPage(context, { name: "Events" });
      return;
    }
    const eventStatus = eventData.status || 0;
    const isArchived = eventStatus == 2;
    formData.value.eventStatus = eventStatus;
    formData.value.isLoading = false;
    formData.value.controls.name.value = ApiHelper.stripTags(
      eventData.name || ""
    );
    formData.value.controls.name.disabled = isArchived;
    formData.value.controls.location.value = ApiHelper.stripTags(
      eventData.locationName || ""
    );
    formData.value.controls.location.disabled = isArchived;
    formData.value.controls.address.value = ApiHelper.stripTags(
      eventData.address || ""
    );
    formData.value.controls.address.disabled = isArchived;
    formData.value.controls.city.value = ApiHelper.stripTags(
      eventData.city || ""
    );
    formData.value.controls.city.disabled = isArchived;
    formData.value.controls.state.value = ApiHelper.stripTags(
      eventData.state || ""
    );
    formData.value.controls.state.disabled = isArchived;
    formData.value.controls.zipcode.value = ApiHelper.stripTags(
      eventData.zipcode || ""
    );
    formData.value.controls.zipcode.disabled = isArchived;
    formData.value.controls.tsStart.value = eventData.tsStartFormatted
      ? moment(eventData.tsStartFormatted).toDate()
      : undefined;
    formData.value.controls.tsStart.disabled = isArchived;
    formData.value.controls.tsEnd.value = eventData.tsEndFormatted
      ? moment(eventData.tsEndFormatted).toDate()
      : undefined;
    formData.value.controls.tsEnd.disabled = isArchived;
    formData.value.controls.forcePayment.value = !!eventData.forcepayment;
    formData.value.controls.forcePayment.disabled = isArchived;
    formData.value.controls.costcenter.value = eventData.costcenterId || "";
    formData.value.controls.tags.value = eventData.tags.map((item: any) => {
      return {
        id: item.id,
        text: item.name || "",
        data: item
      };
    });
    formData.value.controls.tags.disabled = isArchived;
    formInput.value.locationId = eventData.locationId || 0;

    const costCenterNumber = eventData.costCenterNumber || "";
    if (costCenterNumber) {
      formData.value.controls.eventCostCenters.value = [
        {
          id: costCenterNumber,
          text: costCenterNumber
        }
      ];
    }

    // event type
    const eventType = eventData.eventType || null;
    if ((eventType?.eventTypeId || 0) > 0) {
      let itemText = eventType.eventTypeName;
      if (eventType.costCenterNumber) {
        itemText += ` - ${eventType.costCenterNumber}`;
      }
      formData.value.controls.eventTypes.value = [
        {
          id: eventType.eventTypeId,
          text: itemText
        }
      ];
    }

    // parse pTypesJSON
    const pTypes = JSON.parse(eventData.pTypesJSON);
    await pTypes.map((item: any) => {
      linkEventParticipants.value.push({
        uuid: uuidv4(),
        id: item.id || 0,
        linkId: item.linkId || 0,
        eventId: selectedEventId,
        participantTypeName: item.participantTypeName || "",
        participantTypeId: item.participantTypeId || 0,
        capacity: item.capacity,
        capacityMale: item.capacityMale,
        capacityFemale: item.capacityFemale,
        cost: item.cost || 0,
        gender: item.gender || 0,
        age: item.age || "",
        applications: item.applications || "",
        totalParticipants: item.totalParticipants || 0,
        customFields: initCustomFields(item),
        showRemoveCntDown: false,
        cntDown: 3
      });

      return item;
    });
    // parse addonsJSON
    const addons = JSON.parse(eventData.addonsJSON);
    await addons.map((item: any) => {
      linkEventServices.value.push({
        uuid: uuidv4(),
        id: item.id || 0,
        addonServiceId: item.addonServiceId || 0,
        eventId: selectedEventId,
        serviceName: item.serviceName || "",
        serviceDesc: item.serviceDesc || "",
        serviceCost: item.serviceCost || 0,
        isFundBucket: item.isFundBucket || false,
        applications: item.applications || "",
        totalParticipants: item.totalParticipants || 0,
        showRemoveCntDown: false,
        cntDown: 3
      });

      return item;
    });
    linkEventServices.value.push({
      uuid: uuidv4(),
      id: 0,
      addonServiceId: 0,
      eventId: selectedEventId,
      serviceName: "",
      serviceDesc: "",
      serviceCost: "",
      linkAddonCost: "",
      isFundBucket: false,
      applications: "",
      showRemoveCntDown: false,
      totalParticipants: 0,
      cntDown: 3
    });

    // add more rows
    addPTypeRow();

    // current participant type custom fields are using
    popupNewPTypeCol.value.currentCFList = JSON.parse(
      eventData.pTypesCFJSON || "[]"
    );
    popupNewPTypeCol.value.selectedCF = popupNewPTypeCol.value.currentCFList.map(
      (item: any) => item.cfId
    );
  })();

  (async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/entities/costcenters",
      {},
      {
        take: 50
      }
    );
    if (result.status === 1) {
      formData.value.controls.costcenter.options = result.data.costcenters.map(
        (item: any) => {
          return {
            id: item.id,
            text: item.name
          };
        }
      );
    }
  })();

  (async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/tags",
      {},
      {
        take: 20,
        typeUse: "finance"
      }
    );
    if (result.status == 1) {
      formData.value.controls.tags.options = result?.data.tags.map(
        (item: any) => {
          return {
            id: item.tagId,
            text: item.tagName
          };
        }
      );
    }
  })();

  const pageData = ref<{
    showGlCodes?: boolean;
    showCostCenter?: boolean;
  }>({});
  (async () => {
    if (
      typeof pageData.value.showGlCodes == "undefined" &&
      typeof pageData.value.showCostCenter == "undefined"
    ) {
      const configData = await ApiHelper.getEntityConfig(true);
      pageData.value.showGlCodes = configData.showGlCodes;
      pageData.value.showCostCenter = configData.showCostCenter;
    }
  })();

  return {
    pageData,
    popupCostCenter,
    onSuggestTags,
    onAddTag,
    onRemoveTag,
    onSelectTag,
    onRemoveLastTag,
    updateLocation,
    suggestLocation,
    formData,
    linkEventServices,
    linkEventParticipants,
    // showLinkParticipant,
    removeFieldError,
    onSubmit,
    suggestPTypes,
    selectPType,
    popupNewPTypeCol,
    capacityInput,
    isACILoading,
    updatePtypesList,
    onSuggestCostCenters,
    onRemoveLastCostCenter,
    onRemoveCostCenter,
    onSelectCostCenter,
    onAddNewCostCenter,
    onTabPostingCostCenter,
    isAllowAddNewEventType,
    onAddNewEventType,
    popupEventType,
    onRemoveEventType,
    onSuggestEventType,
    onRemoveLastEventType,
    onSelectEventType
  };
}
