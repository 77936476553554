





















































































































































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import FormUpload from "@/components/Form/FormUpload.vue";
import { useSettingsStripeCredsStoreStore } from "@/stores/Settings/StripeCreds/SettingsStripeCredsStore";
import FormInput from "@/components/Form/FormInput.vue";
import FormPassword from "@/components/Form/FormPassword.vue";

export default defineComponent({
  name: "StripeCreds",
  components: {
    FormPassword,
    FormInput,
    FormUpload,
    Loading
  },
  mounted() {
    this.loadData();
  },
  setup(props, context) {
    const {
      formData,
      // methods
      updateStripeCreds,
      loadData,
      // data
      headerData,
      pageData
    } = useSettingsStripeCredsStoreStore(context);

    return {
      formData,
      updateStripeCreds,
      loadData,
      headerData,
      pageData
    };
  }
});
