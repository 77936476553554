


















































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import Header from "@/components/Common/Header.vue";
import Modal from "@/components/Common/Modal.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import { useSettingsParticipantTypesStore } from "@/stores/Settings/ParticipantTypes/SettingsParticipantTypesStore";
import directives from "@/helpers/directives";
import { ApiHelper } from "@/helpers";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormUpload from "@/components/Form/FormUpload.vue";
import { SelectOption } from "@/types";
import FormTags from "@/components/Form/FormTags.vue";
import FormSelect from "@/components/Form/FormSelect.vue";

export default defineComponent({
  name: "EventCabinDetailsPage",
  components: {
    FormUpload,
    FormInput,
    TableHeaderColumn,
    Header,
    VAlertMessage,
    SidebarEventDetails,
    HeaderEventDetails,
    Loading,
    Modal,
    Pager,
    Avatar,
    Select2,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    FilterSelectColumn,
    FilterAgesColumn,
    FilterDateColumn,
    FormTags,
    FormSelect
  },
  directives: directives,
  setup(props, context) {
    const {
      loadList,
      // sort
      updateSortValue,
      popupNewItem,
      pActiveSwitch,
      toggleValue,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab
    } = useSettingsParticipantTypesStore(context);
    const ageOptions = ref<SelectOption[]>([]);

    const checkRangeAge = () => {
      popupNewItem.value.formData.controls.ageTo.error = "";
      if (
        parseInt(popupNewItem.value.formData.controls.ageFrom.value) >=
        parseInt(popupNewItem.value.formData.controls.ageTo.value)
      ) {
        popupNewItem.value.formData.controls.ageTo.error = "Oop";
      } else {
        popupNewItem.value.formData.controls.ages.value =
          popupNewItem.value.formData.controls.ageFrom.value +
          "-" +
          popupNewItem.value.formData.controls.ageTo.value;
      }
    };

    const checkAddPostingCode = () => {
      if (
        !popupNewItem.value.formData.controls.glCode.value &&
        !popupNewItem.value.formData.controls.glName.value &&
        !popupNewItem.value.formData.controls.desc.value
      ) {
        popupNewItem.value.formData.controls.glCode.error = "";
        popupNewItem.value.formData.controls.glName.error = "";
        popupNewItem.value.formData.controls.desc.error = "";
      }
      // if (popupNewItem.value.formData.controls.postingcode.value) {
      //   popupNewItem.value.formData.controls.postingcode.error = "";
      // }
      if (popupNewItem.value.formData.controls.glCode.value) {
        popupNewItem.value.formData.controls.glCode.error = "";
      }
      if (popupNewItem.value.formData.controls.glName.value) {
        popupNewItem.value.formData.controls.glName.error = "";
      }
      if (popupNewItem.value.formData.controls.desc.value) {
        popupNewItem.value.formData.controls.desc.error = "";
      }
    };

    // posting codes
    const onSuggestPostingCodes = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        // componentData.value.totalDataApplyTop = 0;
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/finances/postingCodes",
        {},
        {
          key,
          notInIds
        }
      );
      if (result.status === 1) {
        selectedItem.suggestTags = result.data.items
          .filter((item: any) => item.postingCodeDesc != "")
          .map((item: any) => {
            return {
              id: item.postingCode,
              // text: `${item.postingCode} / ${item.glCode}`,
              text: item.postingCodeDesc,
              data: item
            };
          });
      }
    };

    const resetPostingCode = () => {
      popupNewItem.value.formData.controls.glCode.error = "";
      popupNewItem.value.formData.controls.glName.error = "";
      popupNewItem.value.formData.controls.desc.error = "";
      popupNewItem.value.formData.controls.postingCodes.error = "";
      popupNewItem.value.formData.controls.glCode.disabled = true;
      popupNewItem.value.formData.controls.glName.disabled = true;
      popupNewItem.value.formData.controls.desc.disabled = true;
    };

    const onSelectPostingCode = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        text: `${item.data.postingCode}`
      });
      resetPostingCode();
      popupNewItem.value.formData.controls.glCode.value = item.data.glCode;
      popupNewItem.value.formData.controls.glName.value = item.data.glName;
      popupNewItem.value.formData.controls.desc.value =
        item.data.postingCodeDesc;
    };

    const onRemovePostingCodes = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.key = "";
        selectedItem.value.splice(index, 1);
        popupNewItem.value.formData.controls.glCode.value = "";
        popupNewItem.value.formData.controls.glName.value = "";
        popupNewItem.value.formData.controls.desc.value = "";
        popupNewItem.value.formData.controls.postingCodes.value = [];
        resetPostingCode();
      }
    };

    const showNewPostingCodeModal = (postingCode: string) => {
      popupNewItem.value.formData.controls.postingCodes.value = [
        {
          id: 0,
          text: `${postingCode}`
        }
      ];
      const postingCodeRef: any = context.refs.postingCodeRef;
      postingCodeRef.show = false;
      resetPostingCode();
      popupNewItem.value.formData.controls.glCode.disabled = false;
      popupNewItem.value.formData.controls.glName.disabled = false;
      popupNewItem.value.formData.controls.desc.disabled = false;
    };

    (async () => {
      ageOptions.value = [];
      for (let age = 1; age < 100; age++) {
        ageOptions.value.push({ id: age, text: "" + age });
      }
    })();
    return {
      loadList,
      // sort
      updateSortValue,
      popupNewItem,
      pActiveSwitch,
      toggleValue,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab,
      stripTags: ApiHelper.stripTags,
      ageOptions,
      checkRangeAge,
      checkAddPostingCode,
      //--- PostingCode
      onSuggestPostingCodes,
      onSelectPostingCode,
      onRemovePostingCodes,
      showNewPostingCodeModal
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
