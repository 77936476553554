































































import { defineComponent, ref } from "@vue/composition-api";

import Loading from "@/components/Common/Loading.vue";
import EmailEditor from "vue-email-editor/src/components/EmailEditor.vue";
import { useMessagingCampaignsTemplateStore } from "@/stores/Messaging/Campaign/MessagingCampaignsTemplateStore";

export default defineComponent({
  name: "MessagingCampaignsTemplatePage",
  components: {
    Loading,
    EmailEditor
  },
  setup(props, context) {
    const {
      formData,
      removeFieldError,
      onSubmit
    } = useMessagingCampaignsTemplateStore(context);

    return {
      formData,
      removeFieldError,
      onSubmit
    };
  },
  updated() {
    this.editorLoaded();
  },
  methods: {
    loadTemplate() {
      const emailEditor: any = this.$refs.emailEditor || undefined;
      const editor = emailEditor ? emailEditor.editor || undefined : undefined;

      if (editor) {
        editor.loadTemplate(24113);
      }
    },
    editorLoaded() {
      const emailEditor: any = this.$refs.emailEditor || undefined;
      const editor = emailEditor ? emailEditor.editor || undefined : undefined;

      if (editor) {
        const jsonData = JSON.parse(this.formData.controls.content.value) || {};
        if (jsonData.counters || false) {
          editor.loadDesign(jsonData);
        } else {
          editor.loadTemplate(24113);
        }
      }
    },
    saveDesign() {
      const emailEditor: any = this.$refs.emailEditor || undefined;
      const editor = emailEditor ? emailEditor.editor || undefined : undefined;
      if (editor) {
        editor.saveDesign((design: any) => {
          this.formData.controls.content.value = JSON.stringify(design);
          this.onSubmit();
        });
      }
    },
    exportHtml() {
      const emailEditor: any = this.$refs.emailEditor || undefined;
      const editor = emailEditor ? emailEditor.editor || undefined : undefined;
      if (editor) {
        editor.exportHtml((data: any) => {
          console.log("exportHtml", data);
        });
      }
    }
  }
});
