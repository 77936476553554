






import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "EventSettingsApplications",
  components: {},
  setup(props, context) {
    return {};
  }
});
