import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormText, FormPassword } from "@/types";

export function useUserLoginStore(context: any) {
  const loginInput = ref({
    hasError: false,
    username: "",
    password: "",
    popupType: "",
    popupTitle: "",
    popupSummary: "",
    entityId: 0
  });
  const syncFormData = (formControls: any) => {
    loginInput.value.username = formControls.username.value;
    loginInput.value.password = formControls.password.value;
    loginInput.value.entityId =
      parseInt(context.root.$route.query.entityId) || 0;
  };
  const validateForm = (formControls: any) => {
    let hasError = false;
    if (formControls.username.value === "") {
      hasError = true;
      formControls.username.error = "Email is required!";
    } else if (!ApiHelper.validateEmail(formControls.username.value)) {
      hasError = true;
      formControls.username.error = "Email is invalid!";
    }
    if (formControls.password.value === "") {
      hasError = true;
      formControls.password.error = "Password is required!";
    }
    return !hasError;
  };

  const resetForm = (formData: any) => {
    formData.controls.username.error = "";
    formData.controls.username.value = "";
    formData.controls.password.error = "";
    formData.controls.password.value = "";
  };

  const submitForm = async (dataInput: any, formData: any) => {
    syncFormData(formData.controls);
    formData.isLoading = true;
    const result: any = await ApiHelper.callApi("post", "/auth/login", {
      username: loginInput.value.username,
      password: loginInput.value.password,
      entityId: loginInput.value.entityId
    });
    if (result.status == 1 || (result.token || "") != "") {
      loginInput.value.hasError = false;
      // const token = result.token || "";
      const type = result.type || "";
      ApiHelper.saveSwitchProfileData(null);
      ApiHelper.saveLoggedUser(result);
      if (type === "0") {
        ApiHelper.gotoPage(context, { name: "Participants" });
      } else {
        const apiURL = result.apiEndpointUrl || "";
        const entityId = result.defaultEntityID || 0;
        ApiHelper.saveApiUrl(apiURL, entityId);

        loginInput.value.popupType = `loader`;
        loginInput.value.popupTitle = `Welcome ${result.firstName ||
          loginInput.value.username}.`;
        loginInput.value.popupSummary = `Loading your data...`;
        if (typeof context.parent.isDisabled != undefined) {
          context.parent.isDisabled = true;
        }
        if (context.root.$route.query.redirect) {
          ApiHelper.gotoPage(context, {
            path: context.root.$route.query.redirect,
            query: {
              rt: "1"
            }
          });
        } else {
          setTimeout(() => {
            const defaultMainPage = result.defaultMainPage || "";
            const routes = context.root.$router
              .getRoutes()
              .map((item: any) => item.name);
            if (
              defaultMainPage != "" &&
              routes.find((routeName: string) => routeName == defaultMainPage)
            ) {
              ApiHelper.gotoPage(context, {
                name: defaultMainPage,
                query: {
                  rt: "1"
                }
              });
            } else {
              // default
              ApiHelper.gotoPage(context, {
                name: "Profiles",
                query: {
                  rt: "1"
                }
              });
            }
          }, 2000);
        }
      }
      resetForm(formData);
    } else {
      loginInput.value.hasError = true;
      formData.isLoading = false;
      ApiHelper.showErrorMessage(result.message);
    }
  };

  const formData = ref<{
    isLoading: boolean;
    controls: {
      username: FormText;
      password: FormPassword;
    };
    actions: {
      onSubmit: any;
    };
  }>({
    isLoading: false,
    controls: {
      username: {
        name: "username",
        type: "text",
        label: `Email`,
        placeholder: "EMAIL ADDRESS",
        error: "",
        value: context.root.$route.query.username || ""
      },
      password: {
        name: "password",
        type: "password",
        label: `Password`,
        placeholder: "PASSWORD",
        error: "",
        value: ""
      }
    },
    actions: {
      onSubmit: async (event: any) => {
        event.preventDefault();
        syncFormData(formData.value.controls);
        if (validateForm(formData.value.controls)) {
          await submitForm(loginInput.value, formData.value);
        }
      }
    }
  });

  // (async () => {
  //   formData.value.isLoading = true;
  //   loginInput.value.popupType = `loader`;
  //   loginInput.value.popupTitle = `Welcome John.`;
  //   loginInput.value.popupSummary = `Loading your data...`;
  // })();

  const pageData = ref({
    form: formData
  });

  return {
    loginInput,
    pageData
  };
}
