























































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";

import { useSettingsParticipantTypesEditStore } from "@/stores/Settings/ParticipantTypes/SettingsParticipantTypesEditStore";
import Loading from "@/components/Common/Loading.vue";
import directives from "@/helpers/directives";
import { ApiHelper } from "@/helpers";
import FormInput from "@/components/Form/FormInput.vue";
import FormUpload from "@/components/Form/FormUpload.vue";
import { SelectOption } from "@/types";
import Select2 from "v-select2-component/src/Select2.vue";
import FormTags from "@/components/Form/FormTags.vue";
import FormSelect from "@/components/Form/FormSelect.vue";

export default defineComponent({
  name: "SettingsParticipantTypesEditPage",
  components: {
    FormSelect,
    FormInput,
    FormUpload,
    Loading,
    Select2,
    FormTags
  },
  directives: directives,
  setup(props, context) {
    const {
      formData,
      onFormSubmit,
      headerData,
      pageData
    } = useSettingsParticipantTypesEditStore(context);
    const checkRangeAge = () => {
      formData.value.controls.ageTo.error = "";
      if (
        parseInt(formData.value.controls.ageFrom.value) >=
        parseInt(formData.value.controls.ageTo.value)
      ) {
        formData.value.controls.ageTo.error = "Oop";
      } else {
        formData.value.controls.ages.value =
          formData.value.controls.ageFrom.value +
          "-" +
          formData.value.controls.ageTo.value;
      }
    };
    const ageOptions = ref<SelectOption[]>([]);
    (async () => {
      ageOptions.value = [];
      for (let age = 1; age < 100; age++) {
        ageOptions.value.push({ id: age, text: "" + age });
      }
    })();

    // posting codes
    const onSuggestPostingCodes = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        // componentData.value.totalDataApplyTop = 0;
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/finances/postingCodes",
        {},
        {
          key,
          notInIds
        }
      );
      if (result.status === 1) {
        selectedItem.suggestTags = result.data.items
          .filter((item: any) => item.postingCodeDesc != "")
          .map((item: any) => {
            return {
              id: item.postingCode,
              // text: `${item.postingCode} / ${item.glCode}`,
              text: item.postingCodeDesc,
              data: item
            };
          });
      }
    };

    const resetPostingCode = () => {
      formData.value.controls.glCode.error = "";
      formData.value.controls.glName.error = "";
      formData.value.controls.desc.error = "";
      formData.value.controls.postingCodes.error = "";
      formData.value.controls.glCode.disabled = false;
      formData.value.controls.glName.disabled = false;
      formData.value.controls.desc.disabled = false;
    };

    const onSelectPostingCode = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        text: `${item.data.postingCode}`
      });
      resetPostingCode();
      formData.value.controls.glCode.value = item.data.glCode;
      formData.value.controls.glName.value = item.data.glName;
      formData.value.controls.desc.value = item.data.postingCodeDesc;
      formData.value.controls.glCode.disabled = true;
      formData.value.controls.glName.disabled = true;
      formData.value.controls.desc.disabled = true;
    };

    const onRemovePostingCodes = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.key = "";
        selectedItem.value.splice(index, 1);
        formData.value.controls.glCode.value = "";
        formData.value.controls.glName.value = "";
        formData.value.controls.desc.value = "";
        formData.value.controls.postingCodes.value = [];
        resetPostingCode();
        formData.value.controls.glCode.disabled = true;
        formData.value.controls.glName.disabled = true;
        formData.value.controls.desc.disabled = true;
      }
    };

    const showNewPostingCodeModal = (postingCode: string) => {
      formData.value.controls.postingCodes.value = [
        {
          id: 0,
          text: `${postingCode}`
        }
      ];
      const postingCodeRef: any = context.refs.postingCodeRef;
      postingCodeRef.show = false;
      resetPostingCode();
    };

    return {
      formData,
      onFormSubmit,
      headerData,
      pageData,
      stripTags: ApiHelper.stripTags,
      ageOptions,
      checkRangeAge,
      onSuggestPostingCodes,
      onSelectPostingCode,
      onRemovePostingCodes,
      showNewPostingCodeModal
    };
  }
});
