import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { FormSelect } from "@/types";

export function useSettingsApplicationsEditStore(context: any) {
  const isACILoading = ref<boolean>(false);
  let selectedAppId = parseInt(context.root.$route.params.appId) || 0;
  const headerData = ref({
    title: "",
    subTitle: "Settings - Applications",
    recycled: 0
  });

  const pageData = ref<{
    isLoading: boolean;
    saving: boolean;
    details: {
      jsonData: string;
    };
    error: {
      appName: string;
    };
    presetFieldsData: object[];
    modules: any;
    ignoreIds: string;
    activeModuleId: string;
    isRenderingForm: boolean;
    newModuleVisible: boolean;
    previewApplicationVisible: boolean;
    allModules: any;
    searchModuleName: string;
    selectedModuleId: number;
    currentSysModuleName: string;
    usedElementsKey: string;
    usedElementsData: any;
    costcenter: FormSelect;
    showCostCenter: boolean;
  }>({
    isLoading: true,
    saving: false,
    details: {
      jsonData: ""
    },
    error: {
      appName: ""
    },
    presetFieldsData: [],
    modules: [],
    ignoreIds: "",
    activeModuleId: "",
    isRenderingForm: false,
    newModuleVisible: false,
    previewApplicationVisible: false,
    allModules: [],
    searchModuleName: "",
    selectedModuleId: 0,
    currentSysModuleName: "",
    usedElementsKey: "",
    usedElementsData: [],
    costcenter: {
      notRequired: true,
      label: "Cost Center",
      placeholder: "Please choose cost center",
      style: "custom",
      error: "",
      type: "select",
      options: [],
      value: ""
    },
    showCostCenter: false
  });

  const getPresetFields = async (options: any) => {
    const result = await ApiHelper.callApi(
      "get",
      "/applications/presetFields",
      {},
      options
    );
    if (result.status === 1) {
      pageData.value.isLoading = false;
      return result.data.presetFields || [];
    }
    return [];
  };

  const loadData = async () => {
    ApiHelper.setDataLoading(true);
    try {
      if (selectedAppId > 0) {
        // get app
        const result = await ApiHelper.callApi(
          "get",
          `/applications/${selectedAppId}`
        );
        if (result.status == 1) {
          headerData.value.title = result.data.appName || "";
          headerData.value.recycled = result.data.appRecycled || 0;
          pageData.value.modules = (result.data.modules || []).map(
            (item: any) => {
              const appmoduleTS = moment(item.appmoduleTS);
              let lastUpdated = "N/A";
              if (appmoduleTS.isValid()) {
                lastUpdated = appmoduleTS.format("MM/DD/YY");
              }
              const moduleVersionParam = JSON.parse(
                item.moduleVersionParam || "{}"
              );
              return {
                ...item,
                formBuilder: null,
                searchFieldTimeout: null,
                loadInAppsContentTimeout: null,
                jsonData: JSON.parse(item.moduleVersionJson || "[]"),
                moduleSetting: JSON.parse(item.moduleSetting || "{}"),
                lastUpdated,
                moduleVersionParam
              };
            }
          );
          pageData.value.ignoreIds = pageData.value.modules
            .map((item: any) => item.moduleId)
            .join(",");
          // default value for cost center of campstore module
          pageData.value.costcenter.value =
            result.data.campStoreCostCenterId || "";
          pageData.value.showCostCenter = result.data.showCostCenter || false;
        } else {
          const message = result.message || "";
          if (message) {
            ApiHelper.showErrorMessage(message, "Oops");
          }
          const errorCode = result.errorCode || "";
          if (errorCode == "not_found") {
            ApiHelper.gotoPage(context, {
              name: "SettingsApplications"
            });
          }
        }
      } else {
        // check if enable a costcenter
        const result = await ApiHelper.callApi(
          "get",
          "/entities/settings",
          {},
          {
            getInfo: "costcenter"
          }
        );
        if (result.status == 1) {
          pageData.value.showCostCenter =
            (result.data.costcenter || 0) == 1 ? true : false;
        }
      }

      if (pageData.value.modules.length) {
        const firstModule = pageData.value.modules[0] || {};
        // get app preset fields
        pageData.value.isLoading = true;
        pageData.value.presetFieldsData = await getPresetFields({
          type: "app",
          getTopElements: 1,
          notInModuleId: firstModule.moduleId
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const removeFieldError = (name: string) => {
    if (name == "appName") {
      pageData.value.error.appName = "";
    }
  };

  const linkEvents = ref<any[]>([]);
  const addEventPTypeRow = () => {
    linkEvents.value.push({
      uuid: uuidv4(),
      eventID: "",
      eventName: "",
      participantTypeID: "",
      participantTypeName: ""
    });
  };

  const removePType = async (pType: any) => {
    const newLines = linkEvents.value.filter(
      (item: any) => item.uuid != pType.uuid
    );
    linkEvents.value = newLines;
  };

  const modalLinkEvent = ref<{
    foundEvents: object[];
    isProcessing: boolean;
    show: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
    suggestEvents: any;
    selectEvent: any;
    applySelectedItems: any;
    removePType: any;
    endItemMessage?: string;
  }>({
    foundEvents: [],
    isProcessing: false,
    show: false,
    formData: {},
    endItemMessage: "",
    removeFieldError: (name: string) => {
      // switch (name) {
      //   case "email":
      //     formData.value.controls.email.error = "";
      //     break;
      //   case "firstName":
      //     formData.value.controls.firstName.error = "";
      //     break;
      // }
    },
    onSubmit: async () => {
      // validate
      const hasError = false;
      const selectedPTypeIDs = linkEvents.value.filter(
        (item: any) => item.eventID != ""
      );

      if (!hasError && selectedPTypeIDs.length) {
        try {
          const result = await ApiHelper.callApi(
            "post",
            "/applications/linkToPtypes",
            {
              appId: selectedAppId,
              pTypes: selectedPTypeIDs.map((item: any) => ({
                eventId: item.eventID,
                eventName: item.eventName,
                participantTypeId: item.participantTypeID || 0,
                participantTypeName: item.participantTypeName
              }))
            }
          );
          if (result.status == 1) {
            modalLinkEvent.value.show = false;
          } else {
            // ApiHelper.showErrorMessage(result.message);
            console.log(result);
          }
        } catch (error) {
          console.log(error);
        }
      } else if (selectedPTypeIDs.length == 0) {
        modalLinkEvent.value.show = false;
      }

      context.root.$router.push({ name: "SettingsApplications" }).catch(() => {
        // do nothing
      });
    },
    suggestEvents: async (key: string, onFocus = false) => {
      if (onFocus && key) {
        // ignore search
        return;
      }
      modalLinkEvent.value.foundEvents = [];
      if (onFocus) {
        await ApiHelper.sleep(100);
      }

      modalLinkEvent.value.endItemMessage = key
        ? ""
        : "Top 5 last event are listed, search to see more event";
      const selectedPTypeIDs = linkEvents.value
        .filter((item: any) => item.participantTypeID != "")
        .map((item: any) => {
          return item.eventID + "-" + item.participantTypeID;
        });
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/events/eventsAndPTypes",
        {},
        {
          appId: selectedAppId,
          key: key,
          notInPTypeIds: selectedPTypeIDs.join(",")
        }
      );
      if (result.status == 1 && result.data.length) {
        modalLinkEvent.value.foundEvents = result.data.map((item: any) => ({
          id: item.LINK_Event_Participant,
          html: `${item.ev_name} - ${item.participant_typename}`,
          text: item.ev_name,
          selected: false,
          data: item
        }));
      }
      isACILoading.value = false;

      // const data = JSON.parse(result.data.getEventsAndPTypes.jsonData);
      // if (data.length) {
      //   modalLinkEvent.value.foundEvents = data.map((item: any) => ({
      //     id: item.LINK_Event_Participant,
      //     html: `${item.ev_name} - ${item.participant_typename}`,
      //     text: item.ev_name,
      //     data: item
      //   }));
      // }
    },
    selectEvent: (data: any) => {
      linkEvents.value.push({
        uuid: uuidv4(),
        eventID: data.eventID || "",
        eventName: data.ev_name || "",
        participantTypeID: data.participant_typeid || "",
        participantTypeName: data.participant_typename || ""
      });
      // item.eventID = data.eventID || "";
      // item.eventName = data.ev_name || "";
      // item.participantTypeID = data.participant_typeid || "";
      // item.participantTypeName = data.participant_typename || "";
    },
    applySelectedItems: () => {
      modalLinkEvent.value.foundEvents
        .filter((item: any) => item.selected)
        .map((item: any) => {
          const data = item.data;
          linkEvents.value.push({
            uuid: uuidv4(),
            eventID: data.eventID || "",
            eventName: data.ev_name || "",
            participantTypeID: data.participant_typeid || "",
            participantTypeName: data.participant_typename || ""
          });
        });
      modalLinkEvent.value.foundEvents = [];
    },
    removePType: async (pType: any) => {
      await removePType(pType);
      // for (const item of linkEvents.value) {
      //   const aci = context.refs[`aci-${item.uuid}`];
      //   if (typeof aci[0] != "undefined") {
      //     aci[0].key = item.eventName;
      //     aci[0].$forceUpdate();
      //   }
      // }
      // add more row?
      // if (linkEvents.value.length == 0) {
      //   addEventPTypeRow();
      // } else {
      //   const lastRow = linkEvents.value[linkEvents.value.length - 1];
      //   if (lastRow.participantTypeName != "") {
      //     addEventPTypeRow();
      //   }
      // }
    }
  });

  const saveApp = async () => {
    // reset
    let hasError = false;
    pageData.value.modules = pageData.value.modules.map((item: any) => ({
      ...item,
      errorMessage: ""
    }));

    // validate app name
    if (headerData.value.title == "") {
      hasError = true;
      pageData.value.error.appName = "Application name is required!";
    }
    if (pageData.value.modules.length == 0) {
      ApiHelper.showErrorMessage("Application Module is required");
      return;
    }
    // check if module name is duplicated
    let duplicatedOne = [];
    for (const item of pageData.value.modules) {
      duplicatedOne = pageData.value.modules.filter(
        (m: any) =>
          m.moduleName.toLowerCase() == item.moduleName.toLowerCase() &&
          m.moduleId != item.moduleId
      );
      if (duplicatedOne.length) {
        item.errorMessage = "Duplicated name";
        hasError = true;
        break;
      }
    }
    if (duplicatedOne.length) {
      for (const item of pageData.value.modules) {
        const inDuplicated = duplicatedOne.find(
          (m: any) => m.moduleId == item.moduleId
        );
        if (inDuplicated) {
          item.errorMessage = "Duplicated name";
        }
      }
    }

    if (hasError) {
      return;
    }

    try {
      pageData.value.saving = true;
      ApiHelper.setDataLoading(true);
      // update module jsonData
      pageData.value.modules = pageData.value.modules.map((module: any) => {
        if (module.formBuilder) {
          module.jsonData = module.formBuilder.actions.getData("json");
          if (typeof module.jsonData == "string") {
            module.jsonData = JSON.parse(module.jsonData);
          }
          // total elements
          const elements = module.formBuilder.actions.getData();
          module.totalElements = elements.length;
        }

        return module;
      });

      // apply sorting if not yet
      pageData.value.modules = pageData.value.modules.map(
        (module: any, i: number) => ({
          ...module,
          displayOrder: i
        })
      );

      const reqObject = {
        appName: headerData.value.title,
        appId: selectedAppId,
        appRecycled: headerData.value.recycled,
        modules: pageData.value.modules.map((item: any) => {
          let moduleKey = item.moduleName.toLowerCase().replace(/ /g, "_");
          if (moduleKey == "participant_details") {
            moduleKey = "profile_details";
          }
          const moduleSettings = item.moduleSetting;
          if (moduleKey == "parent_guardian") {
            moduleSettings.minRequirement = moduleSettings.minRequirement
              ? Number.parseInt(`${moduleSettings.minRequirement}`, 10) || 0
              : 0;
            moduleSettings.required = moduleSettings.required || false;
          }
          return {
            moduleId: `${item.moduleId}`.includes("new") ? 0 : item.moduleId,
            moduleName: item.moduleName,
            moduleKey: moduleKey,
            moduleVersion: item.moduleVersion || 0,
            moduleVersionJson: JSON.stringify(item.jsonData || []),
            moduleSetting: JSON.stringify(moduleSettings),
            formPosition: 0,
            displayOrder: item.displayOrder || 0,
            totalElements: item.totalElements || 0
          };
        }),
        campStoreCostCenterId: pageData.value.costcenter.value || 0,
        ignoreIds: pageData.value.ignoreIds
      };

      const result = await ApiHelper.callApi(
        "post",
        "/applications/modules",
        reqObject
      );
      if (result.status == 1) {
        // re-assign appID
        selectedAppId = result.data.appId;

        // reload existed links (case edit)
        linkEvents.value = [];

        // update moduleId
        for (const module of pageData.value.modules) {
          const returnedModule = result.data.modules.find(
            (m: any) =>
              m.moduleName.toLowerCase() == module.moduleName.toLowerCase()
          );
          if (returnedModule) {
            if (pageData.value.activeModuleId == module.moduleId) {
              // keep active this module
              pageData.value.activeModuleId = returnedModule.moduleId;
            }
            module.moduleId = returnedModule.moduleId;
            module.moduleVersionParam = returnedModule.moduleVersionParam;
          }
        }

        ApiHelper.setDataLoading(false);

        // just show for case create app
        if ((parseInt(context.root.$route.params.appId) || 0) == 0) {
          modalLinkEvent.value.show = true;
        } else {
          ApiHelper.showSuccessMessage("Saved application successfully");
          context.root.$router
            .push({ name: "SettingsApplications" })
            .catch(() => {
              // do nothing
            });
        }
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      pageData.value.saving = false;
      ApiHelper.setDataLoading(false);
    }
  };

  const onSaveModule = async (evt: any, formData: any, module: any) => {
    module.errorMessage = "";

    // validate module name
    let valid = true;
    if (module.moduleName == "") {
      module.errorMessage = "Module name is required";
      valid = false;
    }
    if (!valid) return;
    try {
      ApiHelper.setDataLoading(true);
      let totalElements = 0;
      if (module.formBuilder) {
        const elements = module.formBuilder.actions.getData();
        totalElements = elements.length;
      }

      const reqObject = {
        moduleName: module.moduleName,
        moduleKey: module.moduleName.toLowerCase().replace(/ /g, "_"),
        moduleVersion: 0,
        json: formData,
        appId: selectedAppId,
        totalElements
      };
      let result: any = null;
      if (`${module.moduleId}`.indexOf("new") != -1) {
        // add
        result = await ApiHelper.callApi("post", "/appmodules", reqObject);
        if (result.data.moduleId) {
          module.moduleId = result.data.moduleId;
          pageData.value.activeModuleId = module.moduleId;
        }
      } else {
        // update
        result = await ApiHelper.callApi(
          "put",
          `/appmodules/${module.moduleId}`,
          reqObject
        );
      }

      if (result.status == 1) {
        ApiHelper.showSuccessMessage("Module saved successfully!");
        module.errorMessage = "";

        // update stats for this module
        if (result.data.moduleVersionTS) {
          // last update
          module.lastUpdated = moment(result.data.moduleVersionTS).format(
            "MM/DD/YY"
          );
        }
        module.moduleVersionParam = result.data?.moduleVersionParam || {};
      } else {
        const errorCode = result.code || "";
        if (errorCode == "keyExisted" || errorCode == "nameExisted") {
          module.errorMessage = "Module name already exists";
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const loadInApps = async (fieldId: number) => {
    const result = await ApiHelper.callApi(
      "get",
      "/applications/inApps",
      {},
      {
        fieldId,
        type: "app"
      }
    );
    if (result.status === 1) {
      return result.data;
    }

    return [];
  };

  return {
    saveApp,
    onSaveModule,
    headerData,
    pageData,
    loadData,
    removeFieldError,
    modalLinkEvent,
    linkEvents,
    loadInApps,
    addEventPTypeRow,
    getPresetFields,
    isACILoading
  };
}
