

































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Pager from "../Common/Pager.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import moment from "moment";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import { ApiHelper } from "@/helpers";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
export default defineComponent({
  name: "TimelineTable",
  components: {
    Pager,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterDateColumn,
    TableHeaderColumn
  },
  props: {
    pageData: Object,
    showStatusDropdown: Function,
    updateItemStatus: Function,
    updateSortValue: Function,
    // filters
    selectAllTypes: Function,
    resetTypes: Function,

    selectAllGenders: Function,
    resetGenders: Function,
    selectAllEvents: Function,
    resetEvents: Function,
    selectAllFamilies: Function,
    resetFamilies: Function,
    // pager
    gotoPage: Function,
    getCheckedItems: Function,
    togglePagerItems: Function,
    hideHeadActions: Function,
    onViewDetails: Function,
    onClickPrev: Function,
    onClickNext: Function,
    onPagerChange: Function,
    setActiveFilterTab: Function,
    closeFilterTab: Function,
    updateFilterValue: Function,
    updateRouters: Function,
    showTotal: {
      type: Boolean,
      defaultValue: true
    }
  },
  setup(props, context) {
    const isProfileActions = ref<boolean>(false);
    const type = props.pageData?.itemsType || "";
    if (type == "profile_actions") {
      isProfileActions.value = true;
    }

    return {
      isProfileActions,
      moment,
      ApiHelper
    };
  }
});
