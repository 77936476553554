



































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterSelectColumn from "@/components/Table/FilterSelectColumn.vue";
import FilterAgesColumn from "@/components/Table/FilterAgesColumn.vue";
import Header from "@/components/Common/Header.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import Modal from "@/components/Common/Modal.vue";
import { useSettingsApplicationsStore } from "@/stores/Settings/Applications/SettingsApplicationsStore";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormButton from "@/components/Form/FormButton.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import VScroller from "@/components/Common/VScroller.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import { ApiHelper } from "@/helpers";
import moment from "moment";
import PopupPreviewApplication from "@/components/App/PopupPreviewApplication.vue";

export default defineComponent({
  name: "SettingsApplicationsPage",
  components: {
    TableHeaderColumn,
    FormSelect,
    Header,
    VAlertMessage,
    SidebarEventDetails,
    HeaderEventDetails,
    Loading,
    Modal,
    Pager,
    Avatar,
    Select2,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    FilterSelectColumn,
    FilterAgesColumn,
    FilterDateColumn,
    AutoCompleteInput,
    FormButton,
    VScroller,
    PopupPreviewApplication
  },
  setup(props, context) {
    const {
      updateRouters,
      campLink,
      linkEvents,
      modalLinkEvent,
      // page
      headerData,
      pageData,
      // filters
      setActiveFilterTab,
      closeFilterTab,
      applyFilters,
      updateFilterValue,
      // --- pager
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      updateSortValue,
      // --- header
      getFiltersData,
      suggestApps,
      selectApp,
      isACILoading,
      removeApplication
    } = useSettingsApplicationsStore(context);

    const popupPreview = ref<{
      show: boolean;
      title: string;
      recycled: number;
      modules: any[];
      siderbar: any[];
      ignoreIds: any[];
    }>({
      show: false,
      title: "",
      recycled: 0,
      modules: [],
      siderbar: [],
      ignoreIds: []
    });
    const previewApp = async (selectedAppId: number) => {
      pageData.value.isLoading = true;
      if (selectedAppId > 0) {
        // get app
        const result = await ApiHelper.callApi(
          "get",
          `/applications/${selectedAppId}`
        );
        if (result.status == 1) {
          popupPreview.value.title = result.data.appName || "";
          popupPreview.value.modules = (result.data.modules || []).map(
            (item: any) => {
              const appmoduleTS = moment(item.appmoduleTS);
              let lastUpdated = "N/A";
              if (appmoduleTS.isValid()) {
                lastUpdated = appmoduleTS.format("MM/DD/YY");
              }
              const moduleVersionParam = JSON.parse(
                item.moduleVersionParam || "{}"
              );
              return {
                ...item,
                formBuilder: null,
                searchFieldTimeout: null,
                loadInAppsContentTimeout: null,
                jsonData: JSON.parse(item.moduleVersionJson || "[]"),
                moduleSetting: JSON.parse(item.moduleSetting || "{}"),
                lastUpdated,
                moduleVersionParam
              };
            }
          );
          const appmenu = popupPreview.value.modules.map(
            (item: any, key: number) => {
              return {
                entityAppModuleId: key,
                moduleId: `${item.moduleId}`.includes("new")
                  ? 0
                  : item.moduleId,
                stepName: item.moduleName,
                stepkeyword: item.moduleKey,
                appModuleJson: JSON.stringify(item.jsonData || []),
                isSystem: item.isSystem
              };
            }
          );
          popupPreview.value.siderbar = appmenu.map((item: any) => {
            item.formRenderInstance = undefined;
            return item;
          });
          popupPreview.value.show = true;
        }
      } else {
        ApiHelper.showErrorMessage(`Cant preview this application`);
      }
      pageData.value.isLoading = false;
    };

    return {
      previewApp,
      popupPreview,
      updateRouters,
      campLink,
      linkEvents,
      modalLinkEvent,
      // page
      headerData,
      pageData,
      // filters
      setActiveFilterTab,
      closeFilterTab,
      applyFilters,
      updateFilterValue,
      updateSortValue,
      // --- pager
      loadList,
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      suggestApps,
      selectApp,
      isACILoading,
      removeApplication
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
