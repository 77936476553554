






















import Vue from "vue";

export default Vue.extend({
  name: "Pager",
  props: {
    tabs: Array
  }
});
