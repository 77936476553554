var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ProfileDetailsPage"},[_c('form',{attrs:{"accept-charset":"utf-8","autocomplete":"new-password"},on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"ParticipantMedicalPage"},[_c('HeaderProfileDetails',{attrs:{"title":"Note","profile-id":_vm.$route.params.profileId}},[_c('div',{staticClass:"head_right",attrs:{"slot":"head_right"},slot:"head_right"},[_c('div',{staticClass:"head__button"},[(_vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson))?_c('button',{staticClass:"btn btn-lg btn-outline-primary",attrs:{"type":"submit"}},[_vm._v(" Save ")]):_vm._e(),_c('router-link',{staticClass:"btn btn-lg btn-outline-danger",staticStyle:{"margin-left":"10px"},attrs:{"to":{
                name: 'ProfileNotes',
                params: {
                  profileId: _vm.$route.query.fromProfileId
                    ? _vm.$route.query.fromProfileId
                    : _vm.$route.params.profileId
                }
              }}},[_vm._v(" Back to list ")])],1)])]),_c('div',{staticClass:"content-inside mw-1300 v1-page"},[_c('div',{staticClass:"content_add full__width"},[_c('div',{staticClass:"content__form"},[_c('div',{attrs:{"action":"event-add_submit"}},[_c('div',{},[_c('div',{staticClass:"group"},[_c('section',{staticClass:"settings-section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12"},[_c('FormTextarea',{attrs:{"data":_vm.formData.controls.message}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_c('FormToggle',{attrs:{"data":_vm.formData.controls.isfamilyUsed}})],1)])])])])])])])])],1)]),(_vm.$route.query.participantId)?_c('SidebarProfileEvents',{attrs:{"data":{},"showSidebar":true,"participantId":_vm.$route.query.participantId}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }