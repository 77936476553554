var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MessagingTemplatesDetailsPage"},[_c('div',{attrs:{"id":"content"}},[_c('Header',{attrs:{"title":_vm.pageData.details.name,"sub-title":'Templates'}},[_c('div',{staticClass:"head_right",attrs:{"slot":"head_right"},slot:"head_right"},[_c('div',{staticClass:"head__button"},[_c('router-link',{staticClass:"btn btn-lg btn-outline-primary",attrs:{"to":{
              name: 'MessagingTemplatesEdit',
              params: { id: _vm.$route.params.id }
            }}},[_vm._v(" Edit ")]),_c('router-link',{staticClass:"btn btn-lg btn-outline-danger",staticStyle:{"margin-left":"10px"},attrs:{"to":{ name: 'MessagingTemplates' }}},[_vm._v(" Back to list ")])],1)])]),_c('div',{staticClass:"content-inside mw-1300"},[_c('div',{staticClass:"detailedTable"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-4"},[_vm._v(" Name ")]),_c('div',{staticClass:"col col-8"},[_vm._v(" "+_vm._s(_vm.pageData.details.name || "")+" ")])]),_c('div',{staticClass:"row hide"},[_c('div',{staticClass:"col col-12"},[_c('span',[_vm._v("Content")]),_c('div',{staticClass:"btn-group",staticStyle:{"margin-left":"20px"}},[_c('a',{staticClass:"btn btn-sm",class:{
                  'btn-primary': _vm.mode === 'mobile',
                  'btn-secondary': _vm.mode !== 'mobile'
                },on:{"click":function($event){_vm.mode = 'mobile'}}},[_vm._v("Mobile")]),_c('a',{staticClass:"btn btn-sm",class:{
                  'btn-primary': _vm.mode === 'desktop',
                  'btn-secondary': _vm.mode !== 'desktop'
                },on:{"click":function($event){_vm.mode = 'desktop'}}},[_vm._v("Desktop")])])]),_c('div',{staticClass:"col col-12",staticStyle:{"text-align":"center","padding-top":"20px"}},[_c('div',[_c('iframe',{class:{
                  mobileMode: _vm.mode === 'mobile',
                  desktopMode: _vm.mode === 'desktop'
                },staticStyle:{"border":"1px solid #eee","min-height":"50vh","margin":"auto"},attrs:{"srcdoc":_vm.getTemplateHtml()}})])])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }