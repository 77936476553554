var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SidebarProfileDetailsComponent"},[(!_vm.sidebarData.isLoading)?_c('div',{staticClass:"profileDetails sidebar-profiles-details",attrs:{"id":"menu-participants"}},[_c('div',{staticClass:"menu-box"},[_c('div',{staticClass:"box_head"},[_c('div',{staticClass:"box__icon"},[_c('Avatar',{staticStyle:{"margin":"auto"},attrs:{"src":_vm.sidebarData.logo,"username":_vm.sidebarData.name}})],1),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"box__name",staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"textOverflow profile-fullname"},[_vm._v(_vm._s(_vm.sidebarData.name || "People Details"))]),_c('span',{staticClass:"down-arrow",on:{"click":_vm.toggleAci}}),_c('AutoCompleteInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.sidebarData.showAci),expression:"sidebarData.showAci"}],staticClass:"sidebar-profiles-aci",attrs:{"placeholder":'Type a person name...',"options":_vm.sidebarData.foundProfiles,"nowrap":true,"showLineTitle":true,"isACILoading":_vm.isACILoading || false},on:{"input":function (key) { return _vm.suggestProfiles(key); },"select":function (item) { return _vm.selectProfile(item); }}})],1)]),_c('VScroller',{staticClass:"box_content"},[_c('ul',{attrs:{"slot":"default"},slot:"default"},[_c('router-link',{attrs:{"to":{
              name: 'ProfileDetails',
              params: {
                profileId: _vm.profileId.toString()
              },
              query: _vm.linkQuery
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-1.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Dashboard")])])])]}}],null,false,2144266175)}),_c('router-link',{attrs:{"to":{
              name: 'ProfileEdit',
              params: {
                profileId: _vm.profileId.toString()
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-2.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Details")])])])]}}],null,false,3998664826)}),_c('router-link',{attrs:{"to":{
              name: 'ProfileEvents',
              params: {
                profileId: _vm.profileId.toString()
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-3.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Events")]),_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalEvents)+" ")])])])]}}],null,false,2288318191)}),_c('router-link',{attrs:{"to":{
              name: 'ProfileTribes',
              params: {
                profileId: _vm.profileId.toString()
              },
              query: {
                family: (_vm.selectedFamilyId || _vm.sidebarData.familyID || 0) + ''
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-4.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Family/Friends")]),_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalLinkedProfiles)+" ")])])])]}}],null,false,1828056297)}),_c('router-link',{attrs:{"to":{
              name: 'ProfileFiles',
              params: {
                profileId: _vm.profileId.toString()
              },
              query: _vm.linkQuery
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-5.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Files")]),_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalFiles)+" ")])])])]}}],null,false,3035342665)}),_c('router-link',{attrs:{"to":{
              name: 'ProfileTodos',
              params: {
                profileId: _vm.profileId.toString()
              },
              query: _vm.linkQuery
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon/icon-todos.png"),"alt":"To Dos"}})]),_c('div',{staticClass:"item__title"},[_vm._v("To Dos")]),_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalTodos)+" ")])])])]}}],null,false,720199817)}),_c('router-link',{attrs:{"to":{
              name: 'ProfileMedicals',
              params: {
                profileId: _vm.profileId.toString()
              },
              query: _vm.linkQuery
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-6.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Medical")]),_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalMedicals)+" ")])])])]}}],null,false,516592089)}),_c('router-link',{attrs:{"to":{
              name: 'ProfileAtCamp',
              params: {
                profileId: _vm.profileId.toString()
              },
              query: _vm.linkQuery
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-7.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("@Camp")])])])]}}],null,false,3584296066)}),(_vm.$allowedFunctions.includes(_vm.$userFunctions.Transactions))?_c('router-link',{attrs:{"to":{
              name: 'ProfileFinancial',
              params: {
                profileId: _vm.profileId.toString()
              },
              query: {
                eventId: _vm.$route.query.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-8.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Financial")])])])]}}],null,false,3579799547)}):_vm._e(),(_vm.$allowedFunctions.includes(_vm.$userFunctions.Message))?_c('router-link',{attrs:{"to":{
              name: 'ProfileMessages',
              params: {
                profileId: _vm.profileId.toString()
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{staticStyle:{"min-width":"22px"},attrs:{"src":require("../../assets/images/icon-comment.png"),"alt":"Message"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Message")])])])]}}],null,false,2030500036)}):_vm._e(),_c('router-link',{attrs:{"to":{
              name: 'ProfileNotes',
              params: {
                profileId: _vm.profileId.toString()
              },
              query: _vm.linkQuery
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon/icon-note-16.png"),"alt":"Notes"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Notes")]),_c('div',{staticClass:"title__number"},[_vm._v(" "+_vm._s(_vm.sidebarData.totalNotes)+" ")])])])]}}],null,false,1936602160)}),_c('router-link',{attrs:{"to":{
              name: 'ProfileSettings',
              params: {
                profileId: _vm.profileId.toString()
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-9.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Settings")])])])]}}],null,false,2421250102)})],1)])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }