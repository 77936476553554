
















































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import DateSelect from "@/components/modules/SubModules/DateSelect.vue";
import $ from "jquery";
import Inputmask from "inputmask";
import moment from "moment";
import Vue from "vue";
import SignaturePad from "signature_pad";

declare const window: any;
require("formBuilder/dist/form-render.min.js");
import directives from "@/helpers/directives";

export default defineComponent({
  name: "PopupEditApplication",
  props: {
    profileId: {
      type: Number,
      defaultValue: 0
    },
    participantId: {
      type: Number,
      defaultValue: 0
    },
    requireFinishApp: {
      type: Boolean,
      defaultValue: false
    },
    isAddingParticipant: {
      type: Boolean,
      defaultValue: false
    }
  },
  components: {
    TableHeaderColumn,
    Modal,
    VScroller,
    DateSelect
  },
  directives: directives,
  setup(props, context) {
    const _validator = ref<any>(null);
    const componentData = ref({
      app: {},
      appMenu: [],
      isLoading: false,
      totalPercent: 0,
      questionsNeeded: 0,
      questionsDone: 0,
      birthdaySelectUID: "",
      birthday: ""
    });
    const siderbar = ref<any[]>([]);
    const app = ref<{
      firstName?: string;
      lastName?: string;
      prevEmail?: string;
      email?: string;
      phone?: string;
      gender?: string;
      address?: string;
      city?: string;
      state?: string;
      zip?: string;
      birthday?: string;
      pBirthdayM?: string;
      pBirthdayD?: string;
      pBirthdayY?: string;
      eventId: number;
      participantTypeId: number;
      profileId: number;
      steps: any[];
      participantId: number;
      pAge?: number;
      ages?: string;
      limitAges?: string;
      participantJSON?: string;
      addOns: any[];
      dynamicAdditionalCosts: any[];
    }>({
      profileId: 0,
      participantId: 0,
      eventId: 0,
      participantTypeId: 0,
      addOns: [],
      steps: [],
      dynamicAdditionalCosts: []
    });
    const isFamilyOwner = ref(false);

    const loadData = async () => {
      componentData.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "get",
        "/participants/application",
        {},
        {
          profileId: props.profileId || "",
          participantId: props.participantId || ""
        }
      );
      if (result.status !== 1) {
        componentData.value.isLoading = false;
        ApiHelper.showErrorMessage(result.message, "Oops");
        return;
      }
      const appInfo = result.data.app || {};
      const appMenu = result.data.appMenu || [];
      const participant = result.data.participantInfo?.participant || {};
      componentData.value.app = appInfo;
      componentData.value.appMenu = appMenu;
      // data for profile details module
      const stateOptions = ApiHelper.getStateOptions();
      let pState = participant.state || "";
      if (
        pState &&
        !stateOptions.find(state => {
          return state.id == pState;
        })
      ) {
        pState = "";
      }
      app.value = {
        ...appInfo,
        limitAges: app.value.ages,
        state: pState
      };
      // data for profile details module
      app.value.firstName = participant.firstName || "";
      app.value.lastName = participant.lastName || "";
      app.value.email = participant.email || "";
      app.value.phone = participant.phone || "";
      app.value.gender = participant.gender || "";
      app.value.address = participant.address || "";
      app.value.city = participant.city || "";
      app.value.state = pState;
      app.value.zip = participant.zip || "";
      app.value.pBirthdayM = "";
      app.value.pBirthdayD = "";
      app.value.pBirthdayY = "";
      if (participant.dobFormatted != "") {
        const dobArray = participant.dobFormatted.split("-");
        const pBirthdayM = dobArray[1];
        const pBirthdayD = dobArray[2];
        const pBirthdayY = dobArray[0];
        app.value.birthday = `${pBirthdayY}/${pBirthdayM}/${pBirthdayD}`;
      }

      siderbar.value = componentData.value.appMenu.map((item: any) => {
        item.formRenderInstance = undefined;
        return item;
      });

      isFamilyOwner.value = result.data.isFamilyOwner || false;

      componentData.value.isLoading = false;
    };

    const validateForm = () => {
      let valid = true;
      const form = $("#formBuilder");
      form
        .find(".rendered-form .border-danger, .rendered-form .text-danger")
        .removeClass("border-danger text-danger");
      form.find("input[type=radio][required]").each(function(index, control) {
        const _input = $(control);
        if (_input.attr("type") == "radio") {
          const radioName = _input.attr("name");
          if (
            $('input[type=radio][name="' + radioName + '"]:checked').length ==
              0 &&
            $('input[type=radio][name="' + radioName + '[]"]:checked').length ==
              0
          ) {
            $(control).addClass("border-danger");
            $(control)
              .parents(".form-group")
              .addClass("text-danger");
            valid = false;
          } else {
            if (
              $('input[type=radio][name="' + radioName + '"]:checked').val() ==
              ""
            ) {
              $(control).addClass("border-danger");
              $(control)
                .parents(".form-group")
                .addClass("text-danger");
              valid = false;
            }
          }
        }
      });
      form
        .find(
          "input[type=text][required], input[type=email][required], input[type=date][required]"
        )
        .each(function(index, control) {
          if ($(control).val() == "") {
            $(control).addClass("border-danger");
            $(control)
              .parents(".form-group")
              .addClass("text-danger");
            valid = false;
          }
        });
      form.find("textarea[required]").each(function(index, control) {
        if ($(control).val() == "") {
          $(control).addClass("border-danger");
          $(control)
            .parents(".form-group")
            .addClass("text-danger");
          valid = false;
        }
      });
      form.find(".formbuilder-radio-group").each(function(index, control) {
        if (
          $(control).find("input[type=radio][required]").length > 0 &&
          $(control).find("input[type=radio][required]:checked").length == 0
        ) {
          $(control).addClass("border-danger");
          $(control)
            .parents(".form-group")
            .addClass("text-danger");
          valid = false;
        }
      });
      form.find("select[required]").each(function(index, control) {
        alert($(control).val());
        if ($(control).val() == 0 || $(control).val() == "") {
          $(control).addClass("border-danger");
          $(control)
            .parent()
            .addClass("text-danger");
          valid = false;
        }
      });
      return valid;
    };

    const validateStepForm = (form: any) => {
      let valid = true;
      form
        .find(".rendered-form .border-danger, .rendered-form .text-danger")
        .removeClass("border-danger text-danger");
      form
        .find("input[type=radio][required]")
        .each((index: number, control: any) => {
          const _input = $(control);
          if (_input.attr("type") == "radio") {
            const radioName = _input.attr("name");
            if (
              $('input[type=radio][name="' + radioName + '"]:checked').length ==
                0 &&
              $('input[type=radio][name="' + radioName + '[]"]:checked')
                .length == 0
            ) {
              $(control).addClass("border-danger");
              $(control)
                .parents(".form-group")
                .addClass("text-danger");
              valid = false;
            } else {
              if (
                $(
                  'input[type=radio][name="' + radioName + '"]:checked'
                ).val() == ""
              ) {
                $(control).addClass("border-danger");
                $(control)
                  .parents(".form-group")
                  .addClass("text-danger");
                valid = false;
              }
            }
          }
        });
      form
        .find(
          "input[type=text][required], input[type=email][required], input[type=date][required]"
        )
        .each((index: number, control: any) => {
          if ($(control).val() == "") {
            $(control).addClass("border-danger");
            $(control)
              .parents(".form-group")
              .addClass("text-danger");
            valid = false;
          }
        });
      form.find("textarea[required]").each((index: number, control: any) => {
        if ($(control).val() == "") {
          $(control).addClass("border-danger");
          $(control)
            .parents(".form-group")
            .addClass("text-danger");
          valid = false;
        }
      });
      form
        .find(".formbuilder-radio-group")
        .each((index: number, control: any) => {
          if (
            $(control).find("input[type=radio][required]").length > 0 &&
            $(control).find("input[type=radio][required]:checked").length == 0
          ) {
            $(control).addClass("border-danger");
            $(control)
              .parents(".form-group")
              .addClass("text-danger");
            valid = false;
          }
        });
      form.find("select[required]").each((index: number, control: any) => {
        if ($(control).val() == 0 || $(control).val() == "") {
          $(control).addClass("border-danger");
          $(control)
            .parent()
            .addClass("text-danger");
          valid = false;
        }
      });
      return valid;
    };

    const getUserData = async (form: any, item: any) => {
      const jsonObj: any = [];
      const data = item.formRenderInstance.userData;
      const formContent = JSON.parse(item.appModuleJson);
      const fileFields = formContent.filter(
        (content: any) => content.type === "file"
      );
      const formData: any = {};
      for (const fileField of fileFields) {
        const existData = {
          files: form.find(`input[name="${fileField.name}"]`).attr("files"),
          filePath: form
            .find(`input[name="${fileField.name}"]`)
            .attr("file-path"),
          fileName: form
            .find(`input[name="${fileField.name}"]`)
            .attr("file-name")
        };
        // set to existing value
        formData[fileField.name] = existData;
      }
      const formArray: any = item.formRenderInstance.userData;
      const formControls: any[] = [];
      for (const item of formArray) {
        let tmp: any = item;
        if (tmp.type === "file") {
          const foundControl = formData[item.name] || {};
          tmp.files = foundControl.files || undefined;
        }
        if (tmp.type === "signature") {
          const signatureData =
            $("canvas#" + item.name).attr("data-signature") || "";
          const signatureNew = $("canvas#" + item.name).attr("data-new") || "";
          if (signatureNew === "1") {
            if (signatureData) {
              const result = await ApiHelper.uploadFileFromBase64(
                signatureData,
                "profiles/" + app.value.profileId + "/signatures",
                app.value.profileId
              );
              if (result.status === 1) {
                tmp.signature = result.data.fileUrl;
                $("canvas#" + item.name).attr("data-new", "");
              } else {
                tmp.signature = signatureData;
              }
            } else {
              tmp.signature = signatureData;
            }
          } else {
            tmp.signature = signatureData;
          }
        }
        if (tmp.type === "dob") {
          const dateContainer = $("#date-" + item.name);
          const selDay = dateContainer.find(".sel-day").val();
          const selMonth = dateContainer.find(".sel-month").val();
          const selYear = dateContainer.find(".sel-year").val();
          const date = moment(selYear + "-" + selMonth + "-" + selDay);
          if (date.isValid()) {
            tmp = { ...tmp, userData: [date.format("YYYY-MM-DD")] };
          }
        }
        formControls.push(tmp);
      }

      form
        .find(".form-group:not(.answer):not(.blank)")
        .each((i: number, val: any) => {
          const formItem: any = $(val);
          const item: any = {};
          item["label"] = (formItem.find("label").html() || "").split(
            "<span"
          )[0];

          if (formItem.hasClass("formbuilder-radio-group")) {
            item["field"] = formItem
              .find("input[type=radio]:checked")
              .attr("name");
            item["value"] = formItem.find("input[type=radio]:checked").val();
          } else if (formItem.hasClass("formbuilder-checkbox-group")) {
            item["field"] = formItem
              .find("input")
              .attr("name")
              .replace("[]", "");
            const theField = data.find((f: any) => f.name == item.field);
            theField.values.map((checkbox: any, key: number) => {
              const isChecked = $("#" + item.field + "-" + key).is(":checked");
              checkbox.selected = isChecked;
            });
            if (theField) {
              item.userData = theField.userData;
              item.values = theField.values;
            }
          } else if (formItem.hasClass("formbuilder-textarea")) {
            item["field"] = formItem.find("textarea").attr("name");
            item["value"] = formItem.find("textarea").val();
          } else if (formItem.hasClass("formbuilder-select")) {
            item["field"] = formItem.find("select").attr("name");
            item["value"] = formItem.find("option:selected").val();
          } else {
            item["field"] = formItem.find("input").attr("name");
            item["value"] = formItem.find("input").val();
          }

          jsonObj.push(item);
        });

      const valid = validateForm();
      return {
        valid: valid,
        // json: data
        json: formControls
      };
    };

    const getPercentStepComplete = (formRenderInstance: any) => {
      const data = formRenderInstance.userData || [];
      const ret = {
        questionsNeeded: 0,
        questionsDone: 0,
        percentComplete: 0
      };
      if (data.length) {
        const requires = data.filter((item: any) => item.required);
        ret.questionsNeeded = requires.length;
        for (const item of requires) {
          if (
            ["text", "textarea", "date"].includes(item.type) &&
            typeof (item.userData || [""])[0] == "string" &&
            (item.userData || [""])[0] != ""
          ) {
            ret.questionsDone += 1;
          }
          if (["dob"].includes(item.type)) {
            const dateContainer = $("#date-" + item.name);
            if (
              dateContainer.find(".sel-day").val() &&
              dateContainer.find(".sel-month").val() &&
              dateContainer.find(".sel-year").val()
            ) {
              ret.questionsDone += 1;
            }
          }
          if (
            ["select", "checkbox-group", "radio-group"].includes(item.type) &&
            typeof item.userData != "undefined"
          ) {
            ret.questionsDone += 1;
          }
        }
        if (ret.questionsNeeded > 0) {
          ret.percentComplete = parseInt(
            ((ret.questionsDone / ret.questionsNeeded) * 100).toFixed(2)
          );
        }
      }
      // case form have no required fields
      if (ret.questionsNeeded == 0) {
        ret.percentComplete = 100;
      }

      return ret;
    };

    const getTotalPercent = async () => {
      try {
        let questionsNeeded = 0;
        let questionsDone = 0;
        const totalModules = siderbar.value.filter(
          (item: any) =>
            (item.isSystem || false) == false ||
            item.stepKeyword == "profile_details"
        ).length;
        if (totalModules == 0) {
          componentData.value.totalPercent = 100;
          componentData.value.questionsNeeded = 0;
          componentData.value.questionsDone = 0;
          return componentData.value.totalPercent;
        }

        // specify percent for each module
        const modulePercent = parseFloat((100 / totalModules).toFixed(2));

        // increase or decrease % for first module to make sure total percent of modules is 100%
        let firstModulePercent = modulePercent;
        const firstModuleKey =
          siderbar.value.filter(
            (module: any) => (module.isSystem || false) == false
          )[0]?.stepKeyword || "";
        const totalModulePercent = modulePercent * totalModules;
        if (100 > totalModulePercent) {
          firstModulePercent += parseFloat(
            (100 - totalModulePercent).toFixed(2)
          );
        } else if (100 < totalModulePercent) {
          firstModulePercent -= parseFloat(
            (totalModulePercent - 100).toFixed(2)
          );
        }
        firstModulePercent = parseFloat(firstModulePercent.toFixed(2));

        // specify percent stats for profile details
        const pPercentComplete: any = {
          questionsNeeded: 0,
          questionsDone: 0,
          percentComplete: 0
        };

        const profileDetailModule = siderbar.value.find(
          (module: any) => module.stepKeyword == "profile_details"
        );
        if (profileDetailModule) {
          const pDetailsFields = [
            "fNameInput",
            "lNameInput",
            "genderInput",
            "lAddressInput",
            "lCityInput",
            "lStateInput",
            "lZipInput"
          ];
          pPercentComplete.questionsNeeded += pDetailsFields.length + 1; //1 for birthday field
          for (const id of pDetailsFields) {
            const fieldControl: any = $(`#${id}`);

            switch (id) {
              case "lZipInput":
                {
                  const value = fieldControl.val() || "";
                  if (value.length > 4) {
                    pPercentComplete.questionsDone += 1;
                  }
                }
                break;
              default:
                if (fieldControl && (fieldControl.val() || "").trim() != "") {
                  pPercentComplete.questionsDone += 1;
                }
                break;
            }
          }
          const birthdaySelect: any = context.refs.birthdaySelect;
          // const [birthdayValid] = await Promise.all([birthdaySelect.$validator.validateAll()]);
          const monthVal = $(
            `#monthInput-${componentData.value.birthdaySelectUID}`
          ).val();
          const dayVal = $(
            `#dayInput-${componentData.value.birthdaySelectUID}`
          ).val();
          const yearVal = $(
            `#yearInput-${componentData.value.birthdaySelectUID}`
          ).val();
          const birthdayValid =
            monthVal != "" && dayVal != "" && yearVal != "" ? true : false;
          if (birthdayValid) {
            pPercentComplete.questionsDone += 1;
          }

          if (pPercentComplete.questionsNeeded > 0) {
            pPercentComplete.percentComplete = parseInt(
              (
                (pPercentComplete.questionsDone /
                  pPercentComplete.questionsNeeded) *
                100
              ).toFixed(2)
            );
          } else if (pPercentComplete.questionsNeeded == 0) {
            pPercentComplete.percentComplete = 100;
          }
        }
        // specify percent stats for each normal module
        for (const module of siderbar.value) {
          if (
            module.formRenderInstance &&
            (module.isSystem || false) == false
          ) {
            const percentComplete = {
              questionsNeeded: 0,
              questionsDone: 0,
              percentComplete: 0
            };
            const data = module.formRenderInstance.userData;
            if (data.length) {
              const fRequiredFields = data.filter((item: any) => item.required);
              percentComplete.questionsNeeded += fRequiredFields.length;
              for (const item of fRequiredFields) {
                if (
                  ["text", "textarea", "date"].includes(item.type) &&
                  typeof item.userData[0] == "string" &&
                  item.userData[0] != ""
                ) {
                  percentComplete.questionsDone += 1;
                }
                if (["dob"].includes(item.type)) {
                  const dateContainer = $("#date-" + item.name);
                  if (
                    dateContainer.find(".sel-day").val() &&
                    dateContainer.find(".sel-month").val() &&
                    dateContainer.find(".sel-year").val()
                  ) {
                    percentComplete.questionsDone += 1;
                  }
                }
                if (
                  ["select", "checkbox-group", "radio-group"].includes(
                    item.type
                  ) &&
                  typeof item.userData != "undefined"
                ) {
                  percentComplete.questionsDone += 1;
                }

                if (["signature"].includes(item.type)) {
                  const signatureCanvas = $("#" + item.name);
                  const strSignature = signatureCanvas.attr("data-signature");
                  if (strSignature) {
                    percentComplete.questionsDone += 1;
                  }
                }
              }
              // percent for this module
              if (percentComplete.questionsNeeded > 0) {
                percentComplete.percentComplete = parseInt(
                  (
                    (percentComplete.questionsDone /
                      percentComplete.questionsNeeded) *
                    100
                  ).toFixed(2)
                );
              } else if (percentComplete.questionsNeeded == 0) {
                percentComplete.percentComplete = 100;
              }
            }
            module.percentComplete = percentComplete;
          }
        }

        // final total percent
        let totalPercent = 0;
        for (const module of siderbar.value) {
          if ((module.isSystem || false) == false) {
            const percentComplete = module.percentComplete;
            const finalModulePercent =
              module.stepKeyword == firstModuleKey
                ? firstModulePercent
                : modulePercent;
            totalPercent +=
              (percentComplete.percentComplete * finalModulePercent) / 100;
            questionsNeeded += percentComplete.questionsNeeded;
            questionsDone += percentComplete.questionsDone;
          }
        }
        // include percent that calcualted for profile details
        if (profileDetailModule) {
          const finalModulePercent =
            profileDetailModule.stepKeyword == firstModuleKey
              ? firstModulePercent
              : modulePercent;
          totalPercent +=
            (pPercentComplete.percentComplete * finalModulePercent) / 100;
          questionsNeeded += pPercentComplete.questionsNeeded;
          questionsDone += pPercentComplete.questionsDone;
        }
        componentData.value.totalPercent = parseInt(totalPercent.toFixed(2));
        componentData.value.questionsNeeded = questionsNeeded;
        componentData.value.questionsDone = questionsDone;

        return totalPercent;
      } catch (err) {
        // do nothing
      }

      return 0;
    };

    const scrollToFirstError = () => {
      // specify first error item in application
      const firstError: any = $(".app-modules-content .text-danger").first();
      if (firstError && firstError.length) {
        $("html, body").animate(
          {
            scrollTop: firstError.offset().top - 10
          },
          300
        );
      }
    };

    const saveParticipant = async () => {
      if (
        !_validator.value.errors.has("emailInput") &&
        app.value.email != "" &&
        app.value.prevEmail != "" &&
        app.value.prevEmail != app.value.prevEmail
      ) {
        // user confirm wants to change email
        const confirm = await Vue.swal({
          text: `Are you sure you want to change email to ${app.value.email}?`,
          confirmButtonText: "Confirm",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        });
        if (!confirm.isConfirmed) {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          componentData.value.isLoading = false;
          return false;
        }
      }
      componentData.value.isLoading = true;
      const requestObject: any = {
        eventId: app.value.eventId,
        participantTypeId: app.value.participantTypeId,
        profileId: app.value.profileId,
        steps: app.value.steps,
        participantId: app.value.participantId,
        updateAppOnly: true,
        percentComplete: {
          questionsNeeded: componentData.value.questionsNeeded,
          questionsDone: componentData.value.questionsDone,
          percentComplete: componentData.value.totalPercent
        },
        isAddingParticipant: props.isAddingParticipant || undefined
      };

      // send profile details info if application has this module
      const profileDetailModule = siderbar.value.find(
        (module: any) => module.stepKeyword == "profile_details"
      );
      if (profileDetailModule) {
        const birthdaySelect: any = context.refs.birthdaySelect;
        const [birthdayValid, pDetailsValid] = await Promise.all([
          birthdaySelect ? birthdaySelect[0].$validator.validateAll() : null,
          _validator.value.validateAll()
        ]);

        requestObject.profileDetails = {
          firstName: !_validator.value.errors.has("fNameInput")
            ? app.value.firstName
            : undefined,
          lastName: !_validator.value.errors.has("lNameInput")
            ? app.value.lastName
            : undefined,
          email: !_validator.value.errors.has("emailInput")
            ? app.value.email
            : undefined,
          dob: birthdayValid ? app.value.birthday : undefined,
          phone: app.value.phone,
          gender: !_validator.value.errors.has("genderInput")
            ? app.value.gender
            : "",
          address: !_validator.value.errors.has("lAddressInput")
            ? app.value.address
            : "",
          city: !_validator.value.errors.has("lCityInput")
            ? app.value.city
            : "",
          state: !_validator.value.errors.has("lStateInput")
            ? app.value.state
            : "",
          zipcode: !_validator.value.errors.has("lZipInput")
            ? app.value.zip
            : ""
        };
      }
      const result = await ApiHelper.callApi(
        "patch",
        `/participants/${app.value.participantId}/application`,
        requestObject
      );
      componentData.value.isLoading = false;
      if (result.status !== 1) {
        ApiHelper.showErrorMessage(result.message || "Cant save", "Oops");
        return false;
      }
      ApiHelper.showSuccessMessage("Saved application");
      if (props.requireFinishApp) {
        context.emit("callback");
      } else {
        context.emit("dismiss");
      }
      return true;
    };

    const onSubmit = async () => {
      let minAge = 0;
      let maxAge = 0;
      let memberAge = app.value.pAge || 0;
      const profileDetailModule = siderbar.value.find(
        (module: any) => module.stepKeyword == "profile_details"
      );
      if (profileDetailModule) {
        const birthdaySelect: any = context.refs.birthdaySelect;
        const [birthdayValid] = await Promise.all([
          birthdaySelect ? birthdaySelect[0].$validator.validateAll() : null,
          _validator.value.validateAll()
        ]);
        memberAge = birthdayValid
          ? moment().diff(componentData.value.birthday, "years")
          : 0;
      }

      const eventAges = app.value.limitAges || "";
      const arr = (eventAges || "").split("-");
      let hasAgeRange = false;
      if (arr.length == 2) {
        minAge = Number.parseInt(arr[0], 10) || 0;
        maxAge = Number.parseInt(arr[1], 10) || 0;
        hasAgeRange = true;
      } else if (eventAges) {
        const limitAge = Number.parseInt(eventAges, 10) || 0;
        if (limitAge > 0) {
          minAge = limitAge;
        }
      }
      let ageError = "";

      if (hasAgeRange && minAge > 0 && maxAge == 0) {
        // format e.g: 25- (participant age should less than 25)
        if (memberAge > minAge) {
          ageError =
            "Ages range: <strong>" +
            eventAges +
            "</strong>; Age should be less or equal than <strong>" +
            minAge +
            "</strong>, would you like to proceed?";
        }
      } else {
        if (minAge) {
          if (memberAge < minAge) {
            ageError =
              "Ages range: <strong>" +
              eventAges +
              "</strong>; Age should be greater than <strong>" +
              minAge +
              "</strong>, would you like to proceed?";
          }
        }
        if (maxAge) {
          if (memberAge > maxAge) {
            ageError =
              "Ages range: <strong>" +
              eventAges +
              "</strong>; Age should be less than <strong>" +
              maxAge +
              "</strong>, would you like to proceed?";
          }
        }
      }

      if (ageError != "") {
        const isConfirmed = await Vue.swal({
          title: "Are you sure?",
          html: ageError,
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
        }).then(result => {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          return result.isConfirmed;
        });
        if (!isConfirmed) {
          return;
        }
      }

      let valid = true;
      // validate profile details
      const pDetailsModule = siderbar.value.find(
        item => item.isSystem == 1 && item.stepKeyword == "profile_details"
      );
      if (pDetailsModule) {
        _validator.value.validateAll();
        const birthdaySelect: any = context.refs.birthdaySelect;
        if (birthdaySelect) {
          birthdaySelect[0].$validator.validateAll();
        }
      }

      // don't allow to save if user clears first name/last name
      if (
        _validator.value.errors.has("fNameInput") ||
        _validator.value.errors.has("lNameInput")
      ) {
        // show warning message
        let message = "";
        let fNameValid = true;
        let lNameValid = true;
        const refLnameInput: any = $(context.refs.lNameInput);
        const refFnameInput: any = $(context.refs.fNameInput);
        let scrollToPosition = 0;
        if (_validator.value.errors.has("fNameInput")) {
          message = "First Name is required!";
          fNameValid = false;
          if (refFnameInput) {
            scrollToPosition = refFnameInput.offset().top;
          }
        } else if (_validator.value.errors.has("lNameInput")) {
          message = "Last Name is required!";
          lNameValid = false;
          if (refLnameInput) {
            scrollToPosition = refLnameInput.offset().top;
          }
        }
        await Vue.swal({
          text: message,
          timer: 5000
        });
        $("html, body").animate(
          {
            scrollTop: scrollToPosition - 70
          },
          500,
          function() {
            setTimeout(() => {
              if (fNameValid == false) {
                refLnameInput.focus();
              } else if (lNameValid == false) {
                refLnameInput.focus();
              }
            }, 100);
          }
        );
        valid = false;
        return false;
      }

      if (_validator.value.errors.has("emailInput")) {
        await Vue.swal({
          text: "Email is invalid!",
          timer: 5000
        });
        const refEmailInput: any = $(context.refs.emailInput);
        if (refEmailInput) {
          $("html, body").animate(
            {
              scrollTop: refEmailInput.offset().top - 70
            },
            500,
            function() {
              setTimeout(() => {
                $("#emailInput").focus();
              }, 300);
            }
          );
        }

        return false;
      }
      // prevent clear email of family owner
      if (app.value.email == "" && isFamilyOwner.value == true) {
        await Vue.swal({
          text: "Cannot clear email of a family owner",
          timer: 5000
        });
        const refEmailInput: any = $(context.refs.emailInput);
        if (refEmailInput) {
          $("html, body").animate(
            {
              scrollTop: refEmailInput.offset().top - 70
            },
            500,
            function() {
              setTimeout(() => {
                $("#emailInput").focus();
              }, 100);
            }
          );
        }
        return false;
      }

      // app.value.steps = [];
      let stepsComplete = true;
      const finalSiderbar = siderbar.value.filter(
        (item: any) => (item.isSystem || false) == false
      );
      app.value.steps = [];
      componentData.value.isLoading = true;
      for (const item of finalSiderbar) {
        const tmpForm = $("#popupModuleBuilder" + item.entityAppModuleId);
        validateStepForm(tmpForm);
        const userData = await getUserData(tmpForm, item);
        const percentComplete = getPercentStepComplete(item.formRenderInstance);
        if (percentComplete.percentComplete !== 100) {
          stepsComplete = false;
        }
        app.value.steps.push({
          percentComplete: percentComplete,
          entityAppModuleId: item.entityAppModuleId,
          moduleId: item.moduleId,
          json: JSON.stringify(userData.json)
        });
      }

      componentData.value.isLoading = false;
      // save participant
      await getTotalPercent();
      let saveParticipantResult = false;
      if (componentData.value.totalPercent != 100) {
        componentData.value.isLoading = false;
        // show warning message
        if (props.requireFinishApp) {
          ApiHelper.showErrorMessage("Please finish the app form");
          return false;
        }
        const confirm = await Vue.swal({
          text: "You have not completed the application yet",
          confirmButtonText: "SAVE PROGRESS",
          showCancelButton: true,
          cancelButtonText: "Return to Application",
          showCloseButton: true,
          closeButtonHtml:
            '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">',
          focusCancel: true,
          customClass: {
            cancelButton: "cancel-btn"
          }
        });
        if (confirm.isConfirmed) {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          saveParticipantResult = await saveParticipant();
        } else {
          setTimeout(function() {
            $(".swal2-backdrop-hide").addClass("d-none");
          }, 200);
          scrollToFirstError();
        }
      } else {
        saveParticipantResult = await saveParticipant();
        if (saveParticipantResult) {
          const _parent: any = context.parent;
          const popupPaymentRef: any = _parent.$refs.popupPaymentRef;
          if (popupPaymentRef) {
            popupPaymentRef.completeApp(props.participantId);
          }
        }
      }
    };

    const isNoData = () => {
      const modules = siderbar.value.filter(
        item => !(item.isSystem && item.stepKeyword != "profile_details")
      );
      return modules.length === 0;
    };

    const getStateOptions = () => {
      return ApiHelper.getStateOptions();
    };
    const applyCustomWidth = (renderedForm: any) => {
      renderedForm.find("> div").addClass("col-12");
      // apply custom width for each module
      renderedForm
        .find("[class*='custom-w-']")
        .each(function(i: number, obj: any) {
          const classList = obj.className.split(" ");
          const customW = classList.find(
            (item: any) => item.indexOf("custom-w-") != -1
          );
          const customWClass = customW ? customW.replace("custom-w-", "") : "";
          if (customWClass != "") {
            const type = $(obj).prop("type");
            let parentNode = null;
            if (type == "checkbox" || type == "radio") {
              parentNode = $(obj).closest(".form-group");
            } else {
              parentNode = $(obj).parent();
            }
            if (parentNode.length) {
              parentNode.addClass(customWClass);
              if (customWClass != "col-12") {
                parentNode.removeClass("col-12");
              }
              $(obj).removeClass(customWClass);
            }
          }
        });
    };
    return {
      _validator,
      applyCustomWidth,
      loadData,
      getStateOptions,
      isNoData,
      siderbar,
      app,
      onSubmit,
      componentData,
      ApiHelper: ApiHelper
    };
  },
  async mounted() {
    this._validator = this.$validator;
    const _app = this.app;
    const profileId = _app.profileId;
    window.fbControls = [];
    window.fbControls.push((controlClass: any) => {
      class ControlSignature extends controlClass {
        build() {
          return `
          <div>
            <div class="signature-container signature-pad">
              <img src="${this.config.signature}" height="65" width="352" class="defaultImage" style="position: absolute" />
              <canvas id="${this.config.name}" style="touch-action: none; background: white;" height="65" width="352"></canvas>
              <div class="signatureButtons">
                <button class="btn-save"></button>
                <button class="btn-undo"></button>
                <button class="btn-clear"></button>
              </div>
              <div class="otherButtons">
                <label for="file_${this.config.name}" class="button">Upload Signature</label>
                <input type="file" class="file-upload"
                 accept="image/x-png,image/gif,image/jpeg"
                 id="file_${this.config.name}"
                 target="${this.config.name}"
                 />
              </div>
            </div>
          </div>
        `;
        }
        onRender() {
          const canvas: any = document.getElementById(this.config.name);
          const signaturePad = new SignaturePad(canvas, {
            backgroundColor: "rgb(255, 255, 255)"
          });
          signaturePad.off();
          const signatureCanvas = $("#" + this.config.name);
          const container = signatureCanvas.parents(".signature-container");
          if (this.config.signature) {
            signatureCanvas.attr("data-signature", this.config.signature);
            container.find(".defaultImage").show();
            container.find(".btn-undo").hide();
          } else {
            container.find(".defaultImage").hide();
            container.find(".btn-undo").show();
          }
          signaturePad.onEnd = () => {
            container.find(".defaultImage").hide();
            const signatureData = signaturePad.toDataURL();
            signatureCanvas.attr("data-signature", signatureData);
            signatureCanvas.attr("data-new", 1);
          };

          container.find(".btn-save").on("click", () => {
            ApiHelper.showSuccessMessage("Saved");
          });
          container.find(".file-upload").on("change", async (e: any) => {
            const files = e.target.files || [];
            const _this = $(e.target);
            if (files.length > 0) {
              const signatureCanvas2 = $("#" + _this.attr("target"));
              const file = files[0];
              const base64 = await ApiHelper.convertFileToBase64(file);
              const result = await ApiHelper.uploadFileFromBase64(
                base64,
                "profiles/" + profileId + "/signatures",
                profileId
              );
              container.find(".defaultImage").attr("src", "");
              if (result.status === 1) {
                container
                  .find(".defaultImage")
                  .attr("src", result.data.fileUrl)
                  .show();
                container.find(".btn-undo").hide();
                signatureCanvas2.attr("data-signature", result.data.fileUrl);
                signatureCanvas2.attr("data-new", 0);
                signaturePad.clear();
              }
            }
          });
          container.find(".btn-undo").on("click", () => {
            const data = signaturePad.toData();
            if (data) {
              data.pop(); // remove the last dot or line
              signaturePad.fromData(data);
            }
            if (data.length > 0) {
              const signatureData = signaturePad.toDataURL();
              signatureCanvas.attr("data-signature", signatureData);
            } else {
              signatureCanvas.attr("data-signature", "");
            }
            signatureCanvas.attr("data-new", 1);
          });
          container.find(".btn-clear").on("click", () => {
            container.find(".defaultImage").hide();
            container.find(".btn-undo").show();
            signaturePad.clear();
            signatureCanvas.attr("data-signature", "");
            signatureCanvas.attr("data-new", 1);
          });
        }
      }
      controlClass.register("signature", ControlSignature);
      return ControlSignature;
    });
    window.fbControls.push((controlClass: any) => {
      class ControlDob extends controlClass {
        build() {
          const months: number[] = [];
          for (let i = 1; i < 13; i++) {
            months.push(i);
          }
          const days: number[] = [];
          for (let i = 1; i < 32; i++) {
            days.push(i);
          }
          const years: number[] = [];
          for (let i = 1971; i < new Date().getFullYear() + 1; i++) {
            years.push(i);
          }
          return `
          <div
          id="date-${this.config.name}"
          data-userData="${JSON.stringify(this.config.userData)}"
          class="row form-group ${
            this.config.className
          } shadow-none pr-0 d-flex" style="padding:0; margin: 0; border: none">
            <input id="${this.config.name}" type="date" style="display: none" ${
            (this.config.required || "") === "required"
              ? 'aria-required="true" required="required"'
              : ""
          } />
            <div class="col-lg-4 col-12 pl-0">
              <select type="text" class="form-control px-3 text-center sel-month">
                <option value="">Month</option>
                ${months.map(
                  value => `<option value="${value}">${value}</option>`
                )}
              </select>
            </div>
            <div class="col-lg-4 col-12 p-0">
              <select class="form-control px-3 text-center sel-day input__full">
                <option value="">Day</option>
                ${days.map(
                  value => `<option value="${value}">${value}</option>`
                )}
              </select>
            </div>
            <div class="col-lg-4 col-12 pr-0">
              <select type="text" class="form-control px-3 text-center sel-year">
                <option value="">Year</option>
                ${years.map(
                  value => `<option value="${value}">${value}</option>`
                )}
              </select>
            </div>
          </div>
        `;
        }

        onRender() {
          const userData = this.config.userData || [];
          const dateString = userData.length > 0 ? userData[0] : "";
          const arr = dateString.split("-");
          if (arr.length === 3) {
            const defaultYear = parseInt(arr[0]);
            const defaultMonth = parseInt(arr[1]);
            const defaultDay = parseInt(arr[2]);
            if (defaultYear && defaultMonth && defaultDay) {
              $("#" + this.config.name).val(
                moment(dateString).format("YYYY-MM-DD")
              );
              $("#date-" + this.config.name)
                .find(".sel-year")
                .val(defaultYear);
              $("#date-" + this.config.name)
                .find(".sel-month")
                .val(defaultMonth);
              $("#date-" + this.config.name)
                .find(".sel-day")
                .val(defaultDay);
            }
          }
          $("#date-" + this.config.name).on(
            "change",
            ".sel-year,.sel-month,.sel-day",
            () => {
              const dateContainer = $("#date-" + this.config.name);
              const year = dateContainer.find(".sel-year").val();
              const month = dateContainer.find(".sel-month").val();
              const day = dateContainer.find(".sel-day").val();
              if (month && year && day) {
                const newDate = moment(year + "-" + month + "-" + day).format(
                  "YYYY-MM-DD"
                );
                $("#" + this.config.name).val(newDate);
              } else {
                $("#" + this.config.name).val("");
              }
            }
          );
        }
      }

      controlClass.register("dob", ControlDob);
      return ControlDob;
    });

    await this.loadData();

    // upload file
    function updateFilePreview(fileSelector: any) {
      const files: any = JSON.parse(fileSelector.attr("files") || "[]") || [];
      let previewHtml = "";
      for (const file of files) {
        const fileName = file.name || "";
        const filePath = file.path;
        const ext = ApiHelper.getFileExt(fileName).toLowerCase();
        if (ext == "jpg" || ext == "png" || ext == "gif") {
          previewHtml +=
            '<li><a href="' +
            filePath +
            '" target="_blank"><img src="' +
            filePath +
            '" alt="' +
            fileName +
            '" /></a> <a href="javascript:void(0)" class="delete-file" data-file="' +
            fileName +
            '">Delete</a></li>';
        } else {
          previewHtml +=
            '<li><a href="' +
            filePath +
            '" target="_blank">' +
            fileName +
            '</a> <a href="javascript:void(0)"  class="delete-file" data-file="' +
            fileName +
            '">Delete</a></li>';
        }
      }
      if (fileSelector.parent().find(".file-preview").length > 0) {
        fileSelector
          .parent()
          .find(".file-preview ul")
          .html(previewHtml);
      } else {
        fileSelector
          .parent()
          .append(
            '<div class="file-preview"><ul>' + previewHtml + "</ul></div>"
          );
      }
    }

    await ApiHelper.sleep(50);
    for (const item of this.siderbar) {
      if ((item.isSystem || false) == false) {
        if (!item.formRenderInstance) {
          const moduleBuilderId = `popupModuleBuilder${item.entityAppModuleId}`;
          const popupModuleBuilder: any = $(
            "#popupModuleBuilder" + item.entityAppModuleId
          );
          const appModuleJson = JSON.parse(item.appModuleJson || "[]");
          item.formRenderInstance = await popupModuleBuilder.formRender({
            formData: item.appModuleJson,
            notify: {
              error: (message: any) => {
                return message;
              },
              success: (message: any) => {
                // apply mask
                popupModuleBuilder
                  .find("input[inputmask=phonenumber]")
                  .each((i: number, obj: any) => {
                    Inputmask("(999) 999-9999", {
                      autoUnmask: true
                    }).mask(obj);
                  });
                const files = popupModuleBuilder.find(
                  "input[type=file]:not(.file-upload)"
                );
                files.each((index: number, value: any) => {
                  const $file = $(value);
                  $file.attr(
                    "accept",
                    "application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/x-png,image/gif,image/jpeg"
                  );
                  updateFilePreview($file);
                });

                popupModuleBuilder.on("click", ".delete-file", (e: any) => {
                  const _this = $(e.target);
                  this.$swal({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!"
                  }).then((result: any) => {
                    if (result.isConfirmed) {
                      const fileName = _this.attr("data-file");
                      const fileSelector = _this
                        .parents(".form-group")
                        .find("input[type=file]");
                      const arrFiles = JSON.parse(
                        fileSelector.attr("files") || "[]"
                      );
                      fileSelector.attr(
                        "files",
                        JSON.stringify(
                          arrFiles.filter((file: any) => {
                            return file.name != fileName;
                          })
                        )
                      );
                      updateFilePreview(fileSelector);
                    }
                  });
                });

                popupModuleBuilder.on(
                  "change",
                  "input[type=file]:not(.file-upload)",
                  (e: any) => {
                    const _this: any = $("#" + e.target.id);
                    const files = e.target.files || [];
                    const arrFiles = JSON.parse(_this.attr("files") || "[]");
                    for (const file of files) {
                      if (file.size > 5 * 1024 * 1024) {
                        ApiHelper.showErrorMessage(
                          file.name +
                            " is greater than 5MB. Please use another file",
                          "Oops"
                        );
                      } else {
                        if (file) {
                          this.loading = true;
                          ApiHelper.uploadFile(file.name, file)
                            .then((result: any) => {
                              _this.attr("file-path", result.data.fileUrl);
                              _this.attr("file-name", file.name);
                              arrFiles.push({
                                path: result.data.fileUrl,
                                name: file.name
                              });
                              _this.attr("files", JSON.stringify(arrFiles));
                              _this.val(undefined);
                              updateFilePreview(_this);
                              this.loading = false;
                            })
                            .catch(error => {
                              ApiHelper.showErrorMessage(
                                JSON.stringify(error),
                                "Oops"
                              );
                              this.loading = true;
                            });
                        }
                      }
                    }
                  }
                );

                popupModuleBuilder
                  .find(".formbuilder-radio input[type=radio]")
                  .removeClass("form-control")
                  .addClass("form-check-input");
                popupModuleBuilder
                  .find(".formbuilder-radio label")
                  .addClass("form-check-label");
                popupModuleBuilder
                  .find(".formbuilder-radio")
                  .addClass("form-check");
                popupModuleBuilder
                  .find(
                    ".formbuilder-radio-inline label, .formbuilder-checkbox label, .formbuilder-checkbox-inline label"
                  )
                  .addClass("form-check-label");
                popupModuleBuilder
                  .find(
                    ".formbuilder-radio-inline input[type=radio], .formbuilder-checkbox input[type=checkbox], .formbuilder-checkbox-inline input[type=checkbox]"
                  )
                  .addClass("form-check-input");
                popupModuleBuilder
                  .find(
                    ".formbuilder-radio-inline, .formbuilder-checkbox-inline, .formbuilder-checkbox"
                  )
                  .addClass("form-check");
                popupModuleBuilder.on(
                  "change input paste",
                  "input,textarea,select",
                  (e: any) => {
                    const parent = $(e.target).parents(".form-group");
                    parent.find(".text-danger").removeClass("text-danger");
                    parent.find(".border-danger").removeClass("border-danger");
                    parent.removeClass("text-danger");
                  }
                );
                popupModuleBuilder.on(
                  "paste blur focusout",
                  "input,textarea",
                  (e: any) => {
                    let value: any = $(e.target).val() || "";
                    value = value.trim();
                    $(e.target).val(ApiHelper.stripTags(value));
                  }
                );

                return message;
              },
              warning: (message: any) => {
                return message;
              }
            }
          });
          const renderedForm = popupModuleBuilder.find(
            "> .rendered-form:first-child"
          );
          if (renderedForm.length) {
            renderedForm.addClass("row");
            this.applyCustomWidth(renderedForm);
          }

          // apply custom sub labels for text fields
          const fieldsHaveSubLabels = appModuleJson.filter(
            (t: any) =>
              (t.type || "") == "text" && (t.subLabel || "").trim() != ""
          );
          if (fieldsHaveSubLabels.length) {
            for (const fieldData of fieldsHaveSubLabels) {
              const field = $(
                `#${moduleBuilderId} .formbuilder-text.field-${fieldData.name}`
              );
              if (field.length == 0) continue;
              const fieldLabel = field.find("label.formbuilder-text-label");
              if (
                (fieldData.label || "").trim() != "" ||
                fieldData.required ||
                false
              ) {
                fieldLabel.addClass("has-label-text");
              }
              fieldLabel.append(
                `<span class="formbuilder-text-sublabel">(${fieldData.subLabel})</span>`
              );
            }
          }
        }
      }
    }

    // apply mask
    $("#popupProfileDetails")
      .find("input[inputmask=phonenumber]")
      .each((i: number, obj: any) => {
        Inputmask("(999) 999-9999", {
          autoUnmask: true
        }).mask(obj);
      });
    // $("#profileDetails input, #profileDetails select").prop("disabled", true);
  }
});
