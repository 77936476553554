






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Header from "@/components/Common/Header.vue";
import { useFinancialTransactionsStore } from "@/stores/Financial/FinancialTransactionsStore";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import PopupPayment from "@/components/Financial/PopupPayment.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import { ApiHelper } from "@/helpers";
import PopupEditApplication from "@/components/Participant/PopupEditApplication.vue";
import TransactionDetails from "@/components/Financial/TransactionDetails.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import HeaderFilters from "@/components/Common/HeaderFilters.vue";
import Modal from "@/components/Common/Modal.vue";
import FormButton from "@/components/Form/FormButton.vue";
import FormDatePicker3 from "@/components/Form/FormDatePicker3.vue";
import $ from "jquery";
import BackButton from "@/components/Common/BackButton.vue";

export default defineComponent({
  name: "FinancialTransactions",
  components: {
    TableHeaderColumn,
    Loading,
    Header,
    Pager,
    PopupPayment,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    FilterDateColumn,
    PopupEditApplication,
    TransactionDetails,
    HeaderTabs,
    HeaderFilters,
    Modal,
    FormButton,
    FormDatePicker3,
    BackButton
  },
  setup(props, context) {
    const {
      showOption,
      loadData,
      pageData,
      headerData,
      updateRouters,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      // pager
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      inSelectedIds,
      updateRefundInfo,
      updateSelectedIds,
      resetSelectedIds,
      allowRefund,
      toggleTransactionDetails,
      userSettings,
      updateTransactionsCustomView,
      showColumn,
      exportTransactions,
      isRefundService,
      getColSpan,
      filterViewChange,
      filterTransactionsPopup,
      doTransactionFilter,
      showTransactionPopup,
      isTieEvent
    } = useFinancialTransactionsStore(context);

    const popupData = ref({
      profileId: 0,
      eventId: 0,
      show: false
    });

    const popupEditApplication = ref({
      show: false,
      profileId: 0,
      participantId: 0
    });

    const customizeVisible = ref<boolean>(false);

    const closePaymentModal = (options: any) => {
      popupData.value.show = false;
      loadData(1);
    };

    const setIsPaying = (status: boolean) => {
      pageData.value.paying = status;
    };

    const showPopupEditApplication = (options: any) => {
      popupEditApplication.value.profileId = options.profileId;
      popupEditApplication.value.participantId = options.participantId;
      popupEditApplication.value.show = true;
    };

    const toggleCustomize = () => {
      customizeVisible.value = !customizeVisible.value;
    };

    const hideCustomize = (e: any) => {
      if ($(e.target).hasClass("tab-custom-view")) {
        return;
      }
      customizeVisible.value = false;
    };

    return {
      showOption,
      loadData,
      popupData,
      pageData,
      headerData,
      updateRouters,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      // pager
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      setIsPaying,
      closePaymentModal,
      inSelectedIds,
      updateRefundInfo,
      updateSelectedIds,
      resetSelectedIds,
      allowRefund,
      getSelectedItems: ApiHelper.getSelectedItems,
      popupEditApplication,
      showPopupEditApplication,
      isRefundService,
      toggleTransactionDetails,
      userSettings,
      toggleCustomize,
      customizeVisible,
      hideCustomize,
      updateTransactionsCustomView,
      showColumn,
      exportTransactions,
      getColSpan,
      filterViewChange,
      ApiHelper,
      filterTransactionsPopup,
      doTransactionFilter,
      showTransactionPopup,
      isTieEvent
    };
  },
  methods: {
    refresh() {
      this.$forceUpdate();
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
