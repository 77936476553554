




















































import { defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";
import { FormSelect as FormSelectType } from "@/types";
import FormSelect from "@/components/Form/FormSelect.vue";
import moment from "moment";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";

export default defineComponent({
  name: "CopyEventSettings",
  props: {},
  components: {
    Modal,
    VScroller,
    FormSelect,
    AutoCompleteInput
  },
  setup(props, context) {
    const componentData = ref({
      eventId: 0,
      tags: [],
      discounts: [],
      services: [],
      ptypes: [],
      groupItems: [],
      details: {
        id: 0,
        name: "",
        state: "",
        city: "",
        address: "",
        zipcode: "",
        tsStart: "",
        tsStartFormatted: "",
        tsEnd: "",
        tsEndFormatted: "",
        locationId: 0,
        locationName: "",
        forcepayment: 0,
        pTypesJSON: "",
        pTypesCFJSON: "",
        costCenterNumber: "",
        eventType: null
      },
      isLoading: false
    });
    const formData = ref<{
      controls: {
        foundEvents: any;
        event: FormSelectType;
      };
    }>({
      controls: {
        foundEvents: [],
        event: {
          placeholder: "Select event",
          error: "",
          options: [],
          value: "",
          label: "Event",
          notRequired: true
        }
      }
    });
    const loadData = async () => {
      componentData.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "get",
        "/events/settings",
        {},
        {
          status: "0,1,2,3"
        }
      );
      componentData.value.isLoading = false;
      if (result.status === 1) {
        const eventOptions = result?.data.events.map(
          (item: { id: number; name: string }) => {
            return {
              id: item.id,
              text: item.name,
              selected: false
            };
          }
        );
        formData.value.controls.event.options = eventOptions;
      }
    };

    const selectEventSettings = async (item: any) => {
      if (item.id) {
        componentData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "get",
          `/events/${item.id}/settings`,
          {},
          {}
        );
        componentData.value.isLoading = false;
        if (result.status === 1) {
          const eventData = result?.data || {};
          componentData.value.eventId = eventData.id || 0;
          componentData.value.details.id = eventData.id || 0;
          componentData.value.details.address = eventData.address || "";
          componentData.value.details.city = eventData.city || "";
          componentData.value.details.name = eventData.name || "";
          componentData.value.details.state = eventData.state || "";
          componentData.value.details.locationName =
            eventData.locationName || "";
          componentData.value.details.tsEnd = eventData.tsEnd || "";
          componentData.value.details.tsStart = eventData.tsStart || "";
          componentData.value.details.zipcode = eventData.zipcode || "";
          componentData.value.details.tsStartFormatted =
            eventData.tsStartFormatted || "";
          componentData.value.details.tsEndFormatted =
            eventData.tsEndFormatted || "";
          componentData.value.details.forcepayment =
            eventData.forcepayment || 0;
          componentData.value.details.locationId = eventData.locationId || 0;
          componentData.value.discounts = [];
          // Convert avaibleDate discount
          if (eventData.discounts) {
            componentData.value.discounts = eventData.discounts.map(
              (item: any) => {
                item.availableToDateFormatted = item.availableToDateFormatted
                  ? moment(item.availableToDateFormatted).toDate()
                  : undefined;
                return item;
              }
            );
          }
          componentData.value.ptypes = eventData.ptypes || [];
          componentData.value.services = eventData.services || [];
          componentData.value.tags = eventData.tags || [];
          componentData.value.groupItems = eventData.groupItems || [];
          componentData.value.details.pTypesJSON = eventData.pTypesJSON || "[]";
          componentData.value.details.pTypesCFJSON =
            eventData.pTypesCFJSON || "[]";
          componentData.value.details.costCenterNumber =
            eventData.costCenterNumber || "";
          componentData.value.details.eventType = eventData.eventType || null;
        }
      } else {
        // formData.value.controls.event.value = "";
        componentData.value.eventId = 0;
      }
    };

    const onSubmit = () => {
      ApiHelper.showSuccessMessage("Copied event settings");
      context.emit("callback", {
        ...componentData.value.details,
        tags: componentData.value.tags,
        services: componentData.value.services,
        discounts: componentData.value.discounts,
        ptypes: componentData.value.ptypes,
        groupItems: componentData.value.groupItems
      });
    };

    const suggestEvents = async (key: string) => {
      // isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/events/settings",
        {},
        {
          status: "0,1,2,3",
          key: key
        }
      );
      if (result.status === 1) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formData.value.controls.foundEvents = result.data.events.map(
          (item: any) => ({
            id: item.id,
            text: item.name,
            data: item
          })
        );
      }
      // isACILoading.value = false;
    };

    // (async () => {
    //   await loadData();
    // })();
    return {
      selectEventSettings,
      formData,
      onSubmit,
      componentData,
      ApiHelper: ApiHelper,
      suggestEvents
    };
  }
});
