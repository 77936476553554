


















































































































import { defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";
import { FormText } from "@/types";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import FormInput from "@/components/Form/FormInput.vue";
import Vue from "vue";
import $ from "jquery";
import PopupEditCostCenter from "@/components/Event/PopupEditCostCenter.vue";
import FormTags from "@/components/Form/FormTags.vue";

export default defineComponent({
  name: "PopupEditEventType",
  props: {
    defaultName: String
  },
  components: {
    FormInput,
    FormNumber,
    Modal,
    VScroller,
    FormSelect,
    PopupEditCostCenter,
    FormTags
  },
  setup(props, context) {
    const selectedId = 0;
    const componentData = ref({
      isLoading: false,
      showCostCenter: false,
      showGlCodes: false
    });
    const formData = ref<{
      isLoading: boolean;
      controls: {
        name: FormText;
        costCenter: {
          error: string;
          label: string;
          textOverflow: boolean;
          placeholder: string;
          type: string;
          key: string;
          value: { id: string; text: string }[];
          options: any;
          suggestTags: any;
          isFirstFocus: boolean;
        };
      };
      validateAll: any;
      actions: {
        onSubmit: any;
      };
    }>({
      isLoading: true,
      controls: {
        name: {
          required: true,
          label: "Event Type Name",
          placeholder: "Event Type Name",
          style: "custom",
          value: props.defaultName || "",
          error: "",
          maxlength: 150
        },
        costCenter: {
          error: "",
          label: "Cost Center Number",
          textOverflow: true,
          placeholder: "Cost Center Number",
          type: "tags",
          key: "",
          value: [],
          options: [],
          suggestTags: [],
          isFirstFocus: true
        }
      },
      validateAll: () => {
        let hasError = false;
        if (formData.value.controls.name.value == "") {
          formData.value.controls.name.error = "Event Type Name is required";
          hasError = true;
        }
        return hasError;
      },
      actions: {
        onSubmit: async () => {
          const hasError = formData.value.validateAll();
          if (hasError) {
            return;
          }
          const costCenterNumber = formData.value.controls.costCenter.value
            .map((t: any) => t.id)
            .join(",");
          const result = await ApiHelper.callApi(
            "post",
            `/events/eventType`,
            {
              eventTypeName: formData.value.controls.name.value,
              costCenterNumber
            },
            {}
          );
          if (result.status !== 1) {
            ApiHelper.showErrorMessage(result.message, "Oops");
            return;
          }

          let itemText = formData.value.controls.name.value;
          if (costCenterNumber) {
            itemText += ` - ${costCenterNumber}`;
          }
          context.emit("callback", [
            {
              id: result.data.eventTypeId,
              text: itemText,
              data: {
                costCenterNumber
              }
            }
          ]);
          ApiHelper.showSuccessMessage(
            selectedId ? "Updated" : "Added new event type"
          );
        }
      }
    });

    const popupCostCenter = ref<{
      show: boolean;
      key: string;
      callback: Function;
    }>({
      show: false,
      key: "",
      callback: (item: any) => {
        // nothing
      }
    });

    const isAllowAddNewCostCenter = (item: any, onlyNumber = true) => {
      const regExp = /[a-zA-Z]/g;
      if (
        (item.suggestTags || []).length == 0 &&
        item.key != "" &&
        (!regExp.test(item.key) || !onlyNumber)
      ) {
        return true;
      }

      return false;
    };

    const onAddNewCostCenter = async (key: string) => {
      if (key.trim() == "") return;
      popupCostCenter.value.key = key;
      popupCostCenter.value.show = true;
    };

    const onSuggestCostCenters = async (key: string) => {
      formData.value.controls.costCenter.isFirstFocus = true;
      const costCenters = formData.value.controls.costCenter;
      const notInIds = costCenters.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/costcenters",
        {},
        {
          getAll: 1,
          order: 1,
          direction: 1,
          key,
          notInIds
        }
      );
      if (result.status === 1) {
        costCenters.suggestTags = result.data.items.map((item: any) => {
          return {
            id: item.costCenterNumber,
            text: item.costCenterName || item.costCenterNumber,
            data: item
          };
        });
      }
    };

    const onRemoveLastCostCenter = () => {
      const costCenters = formData.value.controls.costCenter;
      if (costCenters.value.length > 0 && costCenters.key === "") {
        const index = costCenters.value.length - 1;
        costCenters.value.splice(index, 1);
      }
    };

    const onRemoveCostCenter = (index: number) => {
      const costCenter = formData.value.controls.costCenter;
      if (costCenter.value.length > index) {
        costCenter.value.splice(index, 1);
      }
    };

    const onSelectCostCenter = (item: any) => {
      const costCenters = formData.value.controls.costCenter;
      costCenters.key = "";
      costCenters.suggestTags = [];
      costCenters.value.push({
        id: item.id,
        text: item.id
      });
    };

    const onTabPostingCostCenter = (
      e: any,
      type: string,
      item: any,
      index: number,
      callback: Function,
      allowTab = ""
    ) => {
      if (type == "costcenter") {
        if (item.key.trim() != "" && (item.suggestTags || []).length == 0) {
          if (!allowTab) {
            e.preventDefault();
          }
          if (callback) {
            callback();
          }
          setTimeout(() => {
            $(".edit-cost-center-modal input")
              .first()
              .focus();
          }, 300);
        }
      }
    };

    (async () => {
      const configData = await ApiHelper.getEntityConfig(true);
      componentData.value.showGlCodes = configData.showGlCodes;
      componentData.value.showCostCenter = configData.showCostCenter;
    })();

    return {
      formData,
      componentData,
      ApiHelper,
      isAllowAddNewCostCenter,
      onAddNewCostCenter,
      popupCostCenter,
      onRemoveCostCenter,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onSelectCostCenter,
      onTabPostingCostCenter
    };
  }
});
