import { ref } from "@vue/composition-api";
import { ApiHelper, EventHelper } from "@/helpers";
import { AlertMessageError, PagerItem, SelectOption } from "@/types";
import { EventListRows } from "@/helpers/estimateNoOfListRows";

export function useVposOrderDetailsStore(context: any) {
  const selectedOrderId = parseInt(context.root.$route.params.id);
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    sort: {
      order: string;
      direction: string;
    };
    orderId: number;
    customer: string;
    items: {
      id: number;
      productName: string;
      productSku: string;
      quantity: number;
      price: number;
      isChecked: boolean;
    }[];
    activeTab: string;
    arrStatuses: SelectOption[];
    filter: {
      status: string;
    };
    selectedIds: any;
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      total: number;
      totalCount: number;
      totalPages: number;
      items: PagerItem[];
    };
  }>({
    isLoading: true,
    skip: 0,
    take: EventListRows(),
    activeTab: "",
    arrStatuses: [],
    filter: {
      status: "COMPLETED"
    },
    // sort defaut by event start date
    sort: {
      order: "4",
      direction: "2"
    },
    orderId: 0,
    customer: "",
    items: [],
    showHeadActions: false,
    selectedIds: [],
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalCount: 0,
      totalPages: 1,
      items: []
    }
  });

  const errors = ref<AlertMessageError[]>([]);
  const loadData = async (page: number, routeReplace = true) => {
    pageData.value.isLoading = true;
    pageData.value.pager.page = page;
    pageData.value.skip = (page - 1) * pageData.value.take;

    try {
      const requestObj = {
        id: Number(selectedOrderId),
        skip: pageData.value.skip || 0,
        take: pageData.value.take || 8,
        order: parseInt(pageData.value.sort.order) || 1,
        direction: parseInt(pageData.value.sort.direction) || 1,
        callFrom: "storeorder_details_page"
      };

      // update route path
      if (routeReplace) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateRouteQuery(page, requestObj);
      }
      const result = await ApiHelper.callApi(
        "get",
        "/store/orders/details",
        {},
        requestObj
      );

      pageData.value.isLoading = false;

      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;

        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        pageData.value.pager.totalCount = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        pageData.value.items = result.data.orderItems.map((item: any) => {
          return {
            ...item,
            priceFormatted: ApiHelper.dollarFormat(item.price)
          };
        });
        pageData.value.orderId = result.data.id;
        pageData.value.customer = result.data.customer;
      } else if (result.status === 401) {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "Login" });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    } catch (err) {
      console.log("loadData:err", err);
      pageData.value.isLoading = false;
      errors.value = [err];
    }
  };

  const updateRouteQuery = (page: number, requestObj: any) => {
    const queryObject: any = { page, ...requestObj };
    delete queryObject.skip;
    delete queryObject.take;
    for (const i in queryObject) {
      if (queryObject[i] == "") {
        delete queryObject[i];
      }
    }
    context.root.$router
      .replace({
        query: queryObject
      })
      .catch(() => {
        // nothing
      });
  };

  const gotoPage = (page: string) => {
    loadData(parseInt(page));
  };

  const onPagerChange = (event: any) => {
    loadData(parseInt(event.target.value));
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      loadData(pageData.value.pager.page - 1);
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      loadData(pageData.value.pager.page + 1);
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const toggleHeadActions = () => {
    pageData.value.showHeadActions = !pageData.value.showHeadActions;
  };

  const hideHeadActions = () => {
    pageData.value.showHeadActions = false;
  };

  const getCheckedItems = () => {
    return pageData.value.items.filter(item => item.isChecked);
  };

  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "1":
        pageData.value.sort.order = "1";
        break;
      case "2":
        pageData.value.sort.order = "2";
        break;
      case "3":
        pageData.value.sort.order = "3";
        break;
      case "4":
        pageData.value.sort.order = "4";
        break;
    }
    loadData(1);
  };

  const getFiltersData = () => {
    const filters = [];
    const selectedStatuses = pageData.value.arrStatuses
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedStatuses.length > 0) {
      filters.push({
        label: "STATUS",
        key: selectedStatuses.join("-") + Math.random(),
        value: selectedStatuses.join(", "),
        reset: () => {
          pageData.value.arrStatuses.map((item: any) => {
            item.selected = false;
          });
          loadData(1);
        }
      });
    }
    return {
      list: filters
    };
  };

  // init data
  (async () => {
    // set init value if set on url
    const queryObject = context.root.$route.query;
    const currentPage = parseInt(`${queryObject.page || 1}`);
    pageData.value.filter.status = queryObject.key || "";
    if (queryObject.order) {
      pageData.value.sort.order = queryObject.order;
    }
    if (queryObject.direction) {
      pageData.value.sort.direction = queryObject.direction;
    }
    loadData(currentPage, false);
  })();

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    loadData(1);
  };

  const inSelectedIds = (item: any) => {
    const existedItem = pageData.value.selectedIds.find(
      (id: any) => id == item.transactionId
    );
    if (existedItem) {
      return true;
    }
    return false;
  };

  const allowRefund = (item: any) => {
    return true;
  };

  return {
    errors,
    pageData,
    // pager
    gotoPage,
    getCheckedItems,
    togglePagerItems,
    toggleHeadActions,
    hideHeadActions,
    onClickPrev,
    onClickNext,
    onPagerChange,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    getFiltersData,
    // sort
    updateSortValue,
    inSelectedIds,
    allowRefund
  };
}
