import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormText, PagerItem, FormSelect } from "@/types";
import { ParticipantTypesListRows } from "@/helpers/estimateNoOfListRows";
import Vue from "vue";

export function useSettingsParticipantTypesStore(context: any) {
  const headerData = ref({
    title: "Participant Types",
    subTitle: "Settings"
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      name: FormText;
      photo: {
        value: string;
        preview: string;
        base64: string;
        label: string;
        error: string;
        required: boolean;
      };
      ages: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      ageFrom: {
        error: string;
        value: number;
      };
      ageTo: {
        error: string;
        value: number;
      };
      cost: {
        label?: string;
        error: string;
        type: string;
        value: string;
      };
      postingcode: FormText;
      glCode: FormText;
      glName: FormText;
      desc: FormText;
      postingCodes: {
        error: string;
        label: string;
        textOverflow: boolean;
        placeholder: string;
        type: string;
        key: string;
        value: any[];
        options: any[];
        suggestTags: any[];
      };
      requirePhoto: FormSelect;
    };
  }>({
    isLoading: true,
    controls: {
      name: {
        label: "Name",
        placeholder: "Name",
        required: true,
        error: "",
        type: "text",
        value: ""
      },
      photo: {
        label: "Photo",
        preview: "",
        required: true,
        base64: "",
        error: "",
        value: ""
      },
      ages: {
        error: "",
        type: "text",
        value: ""
      },
      ageFrom: {
        error: "",
        value: 0
      },
      ageTo: {
        error: "",
        value: 0
      },
      cost: {
        error: "",
        type: "text",
        value: ""
      },
      postingcode: {
        required: false,
        label: "Posting Code",
        placeholder: "Posting Code",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100
      },
      glCode: {
        required: false,
        label: "GL Code",
        placeholder: "GL Code",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100,
        disabled: true
      },
      glName: {
        required: false,
        label: "GL Name",
        placeholder: "GL Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100,
        disabled: true
      },
      desc: {
        required: false,
        label: "Posting Code Name",
        placeholder: "Posting Code Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 255,
        disabled: true
      },
      postingCodes: {
        error: "",
        label: "",
        textOverflow: true,
        placeholder: "Posting Code",
        type: "tags",
        key: "",
        value: [],
        options: [],
        suggestTags: []
      },
      requirePhoto: {
        label: "Require Photo?",
        error: "",
        type: "dropdown",
        value: "0",
        required: false,
        options: [
          {
            id: "1",
            text: "Yes"
          },
          {
            id: "0",
            text: "No"
          }
        ]
      }
    }
  });

  const formDataDelete = ref<{
    isLoading: boolean;
    controls: {
      name: string;
      id: number;
      active: number;
    };
  }>({
    isLoading: true,
    controls: {
      name: "",
      id: 0,
      active: 1
    }
  });

  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: {
      id: number;
      isChecked: boolean;
      logo: string;
      firstName: string;
      lastName: string;
      age: string;
      gender: string;
      balance: string;
      events: string;
      familyName: string;
    }[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      name: string;
      active: string;
      age: string;
      cost: string;
      minCost: string;
      maxCost: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      totalCount: number;
      items: PagerItem[];
    };
  }>({
    isLoading: true,
    skip: 0,
    take: ParticipantTypesListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {
      name: context.root.$route.query.name || "",
      active: "",
      cost:
        (context.root.$route.query.minCost || "") +
        "-" +
        (context.root.$route.query.maxCost || ""),
      minCost: context.root.$route.query.minCost || "",
      maxCost: context.root.$route.query.maxCost || "",
      age: ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalCount: 0,
      totalPages: 1,
      items: []
    }
  });
  const toggleValue = ref<string>("archive");

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: context.root.$route.name,
        query: {
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          key: pageData.value.filter.name || undefined,
          minCost: pageData.value.filter.minCost || undefined,
          maxCost: pageData.value.filter.maxCost || undefined
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/participants/ptypes",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          key: context.root.$route.query.key || "",
          minCost: pageData.value.filter.minCost || "",
          maxCost: pageData.value.filter.maxCost || ""
        }
      );
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;

        const list = result.data.ptypes.map((item: any) => {
          return {
            id: parseInt(item.id),
            isChecked: false,
            name: item.name || "",
            photo: item.photo || "",
            cost: ApiHelper.dollarFormat(item.cost || 0),
            ages: item.typeages || "",
            active: item.active ? "Yes" : "No",
            postingcode: item.postingcode,
            requirephoto: item.requirephoto ? "Yes" : "No"
          };
        });

        pageData.value.items = list;
      } else if (result.status === -1) {
        ApiHelper.gotoPage(context, {
          name: "Login",
          query: { destination: "/settings/participant-types" }
        });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops..");
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };
  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };
  // filters
  const setActiveFilterTab = (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "name":
        pageData.value.filter.name = value;
        break;
      case "cost": {
        pageData.value.filter.cost = value;
        const costArr = value.split("-");
        pageData.value.filter.minCost = costArr[0];
        pageData.value.filter.maxCost =
          costArr[1] !== undefined ? costArr[1] : "";
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];

    if (pageData.value.filter.name !== "") {
      filters.push({
        label: "NAME",
        key: pageData.value.filter.name + Math.random(),
        value: pageData.value.filter.name,
        reset: () => {
          pageData.value.filter.name = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    if (
      pageData.value.filter.minCost !== "" ||
      pageData.value.filter.maxCost !== ""
    ) {
      const displayValue =
        (pageData.value.filter.minCost != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minCost, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (pageData.value.filter.maxCost != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxCost, 10),
              false,
              { useBrackets: true }
            )
          : "");

      filters.push({
        label: "COST",
        key: pageData.value.filter.cost + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.cost = "";
          pageData.value.filter.minCost = "";
          pageData.value.filter.maxCost = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  const popupNewItem = ref<{
    show: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
  }>({
    show: false,
    formData: formData,
    removeFieldError: async () => {
      // nothing
    },
    onSubmit: async () => {
      let hasError = false;
      if (formData.value.controls.name.value === "") {
        hasError = true;
        formData.value.controls.name.error = "Name is required!";
      } else {
        formData.value.controls.name.error = "";
      }

      // if (!formData.value.controls.photo.base64) {
      //   hasError = true;
      //   formData.value.controls.photo.error = "Photo is required!";
      // }

      // if (
      //   formData.value.controls.photo.value &&
      //   !ApiHelper.isImgLink(formData.value.controls.photo.value)
      // ) {
      //   hasError = true;
      //   formData.value.controls.photo.error = "Url is invalid!";
      // }
      // if (formData.value.controls.ages.value === "") {
      //   hasError = true;
      //   formData.value.controls.ages.error = "Age is required!";
      // } else {
      //   formData.value.controls.ages.error = "";
      // }
      // Hanle wrong age
      if (
        parseInt(popupNewItem.value.formData.controls.ageFrom.value) >
        parseInt(popupNewItem.value.formData.controls.ageTo.value)
      ) {
        popupNewItem.value.formData.controls.ageTo.error = "Oop";
        hasError = true;
      }
      const cost = Number.parseFloat(formData.value.controls.cost.value) || 0;
      if (cost < 0) {
        hasError = true;
        formData.value.controls.cost.error = "Cost must greater than 0";
      } else {
        formData.value.controls.cost.error = "";
      }
      const postingCode = formData.value.controls.postingCodes.value.length
        ? formData.value.controls.postingCodes.value[0].text || ""
        : "";
      if (
        postingCode != "" ||
        formData.value.controls.glCode.value ||
        formData.value.controls.glName.value ||
        formData.value.controls.desc.value
      ) {
        if (!postingCode) {
          popupNewItem.value.formData.controls.postingCodes.error =
            "Posting code is required!";
          hasError = true;
        }
        if (!popupNewItem.value.formData.controls.glCode.value) {
          popupNewItem.value.formData.controls.glCode.error =
            "GL code is required!";
          hasError = true;
        }
        if (!popupNewItem.value.formData.controls.glName.value) {
          popupNewItem.value.formData.controls.glName.error =
            "GL name is required!";
          hasError = true;
        }
        if (!popupNewItem.value.formData.controls.desc.value) {
          popupNewItem.value.formData.controls.desc.error =
            "Name code is required!";
          hasError = true;
        }
      }

      if (!hasError) {
        ApiHelper.setDataLoading(true);
        const result = await ApiHelper.callApi(
          "post",
          "/participants/ptypes",
          {
            name: formData.value.controls.name.value,
            photoBase64: formData.value.controls.photo.base64,
            typeages: formData.value.controls.ages.value,
            entityId: 1,
            cost: cost,
            postingcode: postingCode,
            requirephoto: formData.value.controls.requirePhoto.value
          },
          {}
        );
        ApiHelper.setDataLoading(false);
        if (result.status == 1) {
          popupNewItem.value.show = false;
          formData.value.controls.name.value = "";
          formData.value.controls.cost.value = "";
          formData.value.controls.ages.value = "";
          formData.value.controls.photo.base64 = "";
          formData.value.controls.photo.preview = "";
          formData.value.controls.ageFrom.value = 0;
          formData.value.controls.ageTo.value = 0;
          // Call API Add posting code/gl
          if (
            postingCode &&
            formData.value.controls.glCode.value &&
            formData.value.controls.glName.value &&
            formData.value.controls.desc.value
          ) {
            await ApiHelper.callApi(
              "put",
              `/accounting/postingcodes/0`,
              {
                code: postingCode,
                glCode: formData.value.controls.glCode.value,
                glName: formData.value.controls.glName.value,
                desc: formData.value.controls.desc.value
              },
              {}
            );
          }
          formData.value.controls.postingCodes.value = [];
          formData.value.controls.glCode.value = "";
          formData.value.controls.glName.value = "";
          formData.value.controls.desc.value = "";

          // const newId = parseInt(result.data.id);
          ApiHelper.showSuccessMessage("Added");
          loadData(1);
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      }
    }
  });

  const pActiveSwitch = () => {
    toggleValue.value = toggleValue.value == "archive" ? "delete" : "archive";
  };

  // init data
  (async () => {
    loadData(1);
  })();

  (async () => {
    await ApiHelper.synzUserViewLogs(context);
  })();

  return {
    loadList,
    // sort
    updateSortValue,
    popupNewItem,
    pActiveSwitch,
    toggleValue,
    // page
    headerData,
    pageData,
    // filters
    // --- pager
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData,
    // --- filters
    updateFilterValue,
    setActiveFilterTab,
    closeFilterTab
  };
}
