var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"size":"large","is-loading":_vm.componentData.isLoading}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"modal-header",attrs:{"slot":"header"},slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Send Mail")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.$emit('dismiss')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),_c('VScroller',{staticClass:"content_add",staticStyle:{"max-height":"calc(100vh - 300px)","padding-bottom":"10px"},attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"p-0"},[_c('div',{staticClass:"row px-0"},[_c('div',{staticClass:"col-6"},[_c('FormToggle',{attrs:{"data":_vm.formData.controls.type}})],1),(_vm.formData.controls.type.value)?_c('div',{staticClass:"col-6"},[_c('FormSelect',{attrs:{"onChange":_vm.selectTemplate,"data":_vm.formData.controls.template}})],1):_vm._e(),(_vm.componentData.templateHtml.includes('###CONTENT###'))?_c('div',{staticClass:"col-6 d-none"},[_c('FormTextarea',{attrs:{"data":_vm.formData.controls.content}})],1):_vm._e(),_vm._l((_vm.formData.controls.contents),function(item,index){return _c('div',{key:("content-" + index),staticClass:"col-6"},[_c('FormTextarea',{attrs:{"data":item}})],1)})],2)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label__full text-uppercase"},[_vm._v(" Selected Participants ")]),_c('div',[_c('span',{domProps:{"textContent":_vm._s(
            _vm.participants
              .map(
                function (item) { return ((item.firstName) + " " + (item.lastName) + " <" + (item.email) + ">"); }
              )
              .join(', ')
          )}})])]),(_vm.formData.controls.type.value && _vm.componentData.templateHtml)?_c('div',[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label__full text-uppercase"},[_vm._v(" Template Preview ")]),_c('iframe',{staticClass:"w-100",staticStyle:{"border":"3px solid #eee","min-height":"50vh"},attrs:{"srcdoc":_vm.getPreviewContent()}})])]):_vm._e(),(!_vm.formData.controls.type.value)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"col col-12"},[_c('FormInput',{attrs:{"data":_vm.formData.controls.subject}})],1),_c('div',{staticClass:"col col-12"},[_c('FormTextarea',{attrs:{"data":_vm.formData.controls.body}})],1)]):_vm._e()]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"form-buttons"},[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"disabled":(_vm.formData.controls.type.value && !_vm.componentData.templateHtml) ||
            (!_vm.formData.controls.type.value &&
              !(
                _vm.formData.controls.subject.value &&
                _vm.formData.controls.body.value
              ))},on:{"click":_vm.onSubmit}},[_vm._v(" Send ")]),_c('button',{staticClass:"btn btn-danger",attrs:{"type":"submit"},on:{"click":function($event){return _vm.$emit('dismiss')}}},[_vm._v(" Cancel ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }