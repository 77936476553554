import { ref } from "@vue/composition-api";

import { ApiHelper } from "@/helpers";
export function useSettingsParticipantTypesDetailsStore(context: any) {
  const selectedTypeId = parseInt(context.root.$route.params.typeId) || 0;
  const headerData = ref({
    title: "-",
    subTitle: "-"
  });

  const pageData = ref<{
    isLoading: boolean;
    details: {
      name: string;
      photo: string;
      campName: string;
      active: string;
      emailContent: string;
      portalContent: string;
      cost: string;
      ages: string;
      postingcode: string;
      requirephoto: string;
    };
  }>({
    isLoading: true,
    details: {
      name: "",
      photo: "",
      campName: "",
      active: "",
      emailContent: "",
      portalContent: "",
      cost: "",
      ages: "",
      postingcode: "",
      requirephoto: ""
    }
  });

  const loadData = (page: number) => {
    ApiHelper.setDataLoading(true);
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/participants/ptypes/" + selectedTypeId,
        {},
        {}
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const resultData = result.data || null;
        if (resultData) {
          headerData.value.title = resultData.name || "";
          headerData.value.subTitle = "Settings - Participant Types";
          // page data
          pageData.value.details.name = resultData.name || "";
          pageData.value.details.photo = resultData.photo || "";
          pageData.value.details.campName = resultData.campName || "";
          pageData.value.details.active = resultData.active ? "Yes" : "No";
          pageData.value.details.emailContent = resultData.emailContent || "";
          pageData.value.details.portalContent = resultData.portalContent || "";
          pageData.value.details.requirephoto = resultData.requirephoto
            ? "Yes"
            : "No";
          pageData.value.details.cost = ApiHelper.dollarFormat(
            resultData.cost || 0
          );
          pageData.value.details.ages = resultData.typeages || "";
          pageData.value.details.postingcode = resultData.postingcode || "";
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "SettingsParticipantTypes" });
      }
    })();
  };

  // init data
  (async () => {
    loadData(1);
  })();

  return {
    headerData,
    pageData
  };
}
