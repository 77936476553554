












































































import { defineComponent } from "@vue/composition-api";

import DatePicker from "vue2-datepicker";
import Loading from "@/components/Common/Loading.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FormButton from "@/components/Form/FormButton.vue";
import { useMessagingCampaignsEditStore } from "@/stores/Messaging/Campaign/MessagingCampaignsEditStore";

export default defineComponent({
  name: "MessagingCampaignsEditPage",
  components: {
    DatePicker,
    Loading,
    Select2,
    FormButton
  },
  setup(props, context) {
    const {
      formData,
      removeFieldError,
      onSubmit
    } = useMessagingCampaignsEditStore(context);
    return {
      formData,
      removeFieldError,
      onSubmit
    };
  }
});
