






import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "EventGroupDetails",
  components: {},
  setup(props, context) {
    return {};
  }
});
