






















































import { defineComponent } from "@vue/composition-api";
import FormItem from "@/components/Form/FormItem.vue";
import FormButton from "@/components/Form/FormButton.vue";
import { useUserLoginStore } from "@/stores/User/UserLoginStore";
import Loading from "@/components/Common/Loading.vue";
import FormPassword from "@/components/Form/FormPassword.vue";
import FormInput from "@/components/Form/FormInput.vue";

export default defineComponent({
  name: "UserLoginPage",
  components: {
    FormPassword,
    FormInput,
    Loading,
    FormButton,
    FormItem
  },
  setup(props, context) {
    const { pageData, loginInput } = useUserLoginStore(context);
    return {
      loginInput,
      pageData
    };
  }
});
