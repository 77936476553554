













































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "FormCheckboxes",
  props: {
    data: Object,
    onChange: Function,
    triggerMouseEnter: Function,
    selectionLabel: String
  },
  setup(props) {
    const randomId = ref<number>(ApiHelper.randomFormId());
    const doChange = (item: any) => {
      if (props.data) {
        props.data.error = "";
      }
      if (props.onChange) {
        props.onChange(item);
      }
    };
    const onMouseEnter = (event: any) => {
      if (props.triggerMouseEnter) {
        props.triggerMouseEnter();
      }
    };

    const onClickOutside = () => {
      if (props.data) {
        props.data.showDropdown = false;
      }
    };

    return {
      onClickOutside,
      randomId: randomId.value.toString(),
      doChange,
      onMouseEnter
    };
  }
});
