






import Vue from "vue";

export default Vue.extend({
  name: "ComingSoon",
  props: {
    msg: String
  }
});
