













































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import HeaderTabs from "@/components/Common/HeaderTabs.vue";
import Avatar from "vue-avatar/src/Avatar.vue";

import VScroller from "@/components/Common/VScroller.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import { ApiHelper } from "@/helpers";
import $ from "jquery";

export default defineComponent({
  name: "NavProfileDetails",
  props: {
    profileId: String,
    title: String
  },
  components: {
    VScroller,
    HeaderTabs,
    Avatar,
    AutoCompleteInput
  },
  setup(props, context) {
    const isACILoading = ref<boolean>(false);
    const selectedFamilyId =
      Number.parseInt(context.root.$route.query.family + "", 10) || undefined;
    const selectedEventId =
      Number.parseInt(context.root.$route.query.eventId + "", 10) || undefined;
    const profileId = parseInt(props.profileId || "0") || 0;

    const sidebarData = ref<{
      isArchived: boolean;
      isLoading: boolean;
      name: string;
      logo: string;
      totalLinkedProfiles: number;
      totalEvents: number;
      totalMedicals: number;
      totalNotes: number;
      totalFiles: number;
      totalTodos: number;
      foundProfiles: any;
      showAci: boolean;
      familyID: number;
      status: number;
    }>({
      isArchived: false,
      isLoading: true,
      name: "",
      logo: "",
      totalLinkedProfiles: 0,
      totalEvents: 0,
      totalMedicals: 0,
      totalNotes: 0,
      totalFiles: 0,
      totalTodos: 0,
      foundProfiles: [],
      showAci: false,
      familyID: 0,
      status: 0
    });
    const linkQuery: any = ref<{}>({});

    const gotoLink = (route: any) => {
      context.root.$router.replace(route).catch(() => {
        console.log("err");
      });
    };

    const suggestProfiles = async (key: string) => {
      //TODO: review
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/search",
        {},
        {
          order: 2,
          direction: 1,
          ignoreIds: profileId.toString(),
          key: key
        }
      );
      if (result.status === 1) {
        sidebarData.value.foundProfiles = result.data.profiles.map(
          (item: any) => ({
            id: item.id,
            text: ApiHelper.getFullName(item.firstName, item.lastName),
            data: item
          })
        );
      }
      isACILoading.value = false;
    };

    const selectProfile = (item: any) => {
      const profileID = item.data.id || 0;
      // goto profile details page
      ApiHelper.gotoPage(context, {
        name: "ProfileDetails",
        params: { profileId: profileID.toString() }
      });
    };

    const toggleAci = () => {
      sidebarData.value.showAci = !sidebarData.value.showAci;
      if (sidebarData.value.showAci) {
        setTimeout(() => {
          $(context.root.$el)
            .find(".sidebar-profiles-aci input.search-box")
            .focus();
        }, 100);
      }
    };

    const onClickOutside = () => {
      sidebarData.value.showAci = false;
    };

    // init data
    const loadData = () => {
      (async () => {
        const result = await ApiHelper.callApi(
          "get",
          "/profiles/" + profileId + "/stats",
          {},
          {
            familyId: selectedFamilyId,
            eventId: selectedEventId
          }
        );
        sidebarData.value.isLoading = false;

        if (result.status == 1) {
          const profileData = result.data;
          if (profileData.id) {
            sidebarData.value.logo = profileData.logo || "";
            sidebarData.value.name =
              profileData.firstName + " " + profileData.lastName;
            sidebarData.value.totalLinkedProfiles =
              profileData.totalTribes || 0;
            sidebarData.value.totalMedicals = profileData.totalMedicals || 0;
            sidebarData.value.totalEvents = profileData.totalEvents || 0;
            sidebarData.value.totalNotes = profileData.totalNotes || 0;
            sidebarData.value.totalFiles = profileData.totalFiles || 0;
            sidebarData.value.totalTodos = profileData.totalTodos || 0;
            sidebarData.value.status = profileData.active || 0;
            if (sidebarData.value.status == 2) {
              sidebarData.value.isArchived = true;
            }
            if (profileData.familyId) {
              sidebarData.value.familyID = profileData.familyId || 0;
            }
          } else {
            ApiHelper.showErrorMessage("No profile found", "Error");
            ApiHelper.gotoPage(context, { name: "Profiles" });
          }
        }

        if (typeof context.root.$route.query.eventId != "undefined") {
          linkQuery.value.eventId = context.root.$route.query.eventId;
        }
      })();
    };

    return {
      selectedFamilyId,
      loadData,
      sidebarData,
      gotoLink,
      suggestProfiles,
      selectProfile,
      toggleAci,
      onClickOutside,
      linkQuery,
      isACILoading
    };
  },
  mounted() {
    this.loadData();
  }
});
