


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import ImageUploader from "vue-image-upload-resize/src/components/ImageUploader.vue";
import { useProfileEditStore } from "@/stores/Profile/ProfileEditStore";
import SidebarProfileDetails from "@/components/Profile/SidebarProfileDetails.vue";
import DatePicker from "vue2-datepicker";
import Loading from "@/components/Common/Loading.vue";
import Select2 from "v-select2-component/src/Select2.vue";
import FormTags from "@/components/Form/FormTags.vue";
import BackButton from "@/components/Common/BackButton.vue";
import { ApiHelper } from "@/helpers";
import $ from "jquery";
import FormDate from "@/components/Form/FormDate.vue";
import directives from "@/helpers/directives";
import FormYesNo from "@/components/Form/FormYesNo.vue";
import FormPassword from "@/components/Form/FormPassword.vue";
import FormPhoto from "@/components/Form/FormPhoto.vue";
import Inputmask from "inputmask";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormDatePicker from "@/components/Form/FormDatePicker.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormEmail from "@/components/Form/FormEmail.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import VScroller from "@/components/Common/VScroller.vue";
export default defineComponent({
  name: "ProfileEditPage",
  components: {
    FormDate,
    ImageUploader,
    SidebarProfileDetails,
    DatePicker,
    Loading,
    Select2,
    FormTags,
    BackButton,
    FormYesNo,
    FormPassword,
    FormPhoto,
    FormSelect,
    FormDatePicker,
    FormInput,
    FormEmail,
    HeaderProfileDetails,
    VScroller
  },
  directives: directives,
  setup(props, context) {
    const {
      changeProfileAvatar,
      isArchived,
      onAddTag,
      onSelectTag,
      onRemoveLastTag,
      onRemoveTag,
      onSuggestTags,
      formData,
      removeFieldError,
      onChangeFile,
      onResetFile,
      onSubmit,
      disabledBeforeTodayAndAfterAWeek
    } = useProfileEditStore(context);
    return {
      changeProfileAvatar,
      isArchived,
      stripTags: ApiHelper.stripTags,
      onAddTag,
      onSelectTag,
      onRemoveTag,
      onRemoveLastTag,
      onSuggestTags,
      formData,
      removeFieldError,
      onChangeFile,
      onResetFile,
      onSubmit,
      disabledBeforeTodayAndAfterAWeek
    };
  },
  mounted() {
    const _formData = this.formData;
    // apply mask for phone
    $(`input[inputmask=phonenumber]`).each(function(i, obj) {
      Inputmask("(999) 999-9999", {
        autoUnmask: true,
        onBeforePaste: (pastedValue: string, opts: any) => {
          _formData.controls.phone.value = pastedValue;
          $(obj).val(pastedValue);
          return pastedValue;
        }
      }).mask(obj);
    });
  }
});
