




















































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "FormYesNo",
  props: {
    data: Object
  },
  setup() {
    const randomId = ref<number>(ApiHelper.randomFormId());
    return {
      randomId: randomId.value.toString()
    };
  }
});
