




































































































































import { defineComponent, ref } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import directives from "@/helpers/directives";
import BackButton from "@/components/Common/BackButton.vue";
import FormInput from "@/components/Form/FormInput.vue";
import { useSettingsUserEditStore } from "@/stores/Settings/Users/SettingsUserEditStore";
import FormYesNo from "@/components/Form/FormYesNo.vue";
import FormPassword from "@/components/Form/FormPassword.vue";
import { ApiHelper } from "@/helpers";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
import Header from "@/components/Common/Header.vue";

export default defineComponent({
  name: "SettingsParticipantTypesEditPage",
  components: {
    Header,
    FormMultiSelect,
    FormPassword,
    FormYesNo,
    FormInput,
    Loading,
    BackButton
  },
  directives: directives,
  setup(props, context) {
    const {
      loadData,
      headerData,
      pageData,
      formData
    } = useSettingsUserEditStore(context);
    const passwordData = ref({
      hasCapital: false,
      has8characters: false,
      hasNumber: false,
      nonHtml: false,
      valid: false
    });
    const checkStrongPassword = (password: string) => {
      passwordData.value = ApiHelper.getPasswordInfo(password);
      formData.value.controls.password.valid = passwordData.value.valid;
    };
    return {
      passwordData,
      checkStrongPassword,
      loadData,
      headerData,
      pageData,
      formData
    };
  }
});
