import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormText } from "@/types";

export function useSettingsGroupEditStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const headerData = ref({
    title: selectedId > 0 ? "Edit Security Group" : "Add Security Group",
    subTitle: "System Groups"
  });
  const formData = ref<{
    isLoading: boolean;
    selectedId: number;
    controls: {
      name: FormText;
      users: {
        label: string;
        placeholder: string;
        style: string;
        showDropdown: boolean;
        disabled?: boolean;
        value: string;
        error: string;
        defaultValues: number[];
        options: any[];
      };
      functions: {
        required: boolean;
        label: string;
        placeholder: string;
        style: string;
        showDropdown: boolean;
        disabled?: boolean;
        defaultValues: number[];
        value: string;
        error: string;
        options: any[];
      };
    };
    validateAll: any;
    actions: {
      onSubmit: any;
    };
  }>({
    isLoading: true,
    selectedId: parseInt(context.root.$route.params.id) || 0,
    controls: {
      name: {
        required: true,
        label: "Group Name",
        placeholder: "Enter the group name",
        style: "custom",
        value: "",
        error: ""
      },
      users: {
        label: "Users",
        placeholder: "Enter the group name",
        style: "custom",
        showDropdown: false,
        defaultValues: [],
        value: "",
        error: "",
        options: []
      },
      functions: {
        required: true,
        label: "Functions",
        placeholder: "Enter the group name",
        style: "custom",
        defaultValues: [],
        value: "",
        error: "",
        options: [],
        showDropdown: false
      }
    },
    validateAll: () => {
      let hasError = false;
      formData.value.controls.name.error = "";
      formData.value.controls.users.error = "";
      formData.value.controls.functions.error = "";
      if (formData.value.controls.name.value === "") {
        formData.value.controls.name.error = "Group Name is required";
        hasError = true;
      }
      const selectedFunctions = formData.value.controls.functions.options.filter(
        (item: any) => item.selected
      );
      if (selectedFunctions.length === 0) {
        formData.value.controls.functions.error = "Function is required";
        hasError = true;
      }
      return hasError;
    },
    actions: {
      onSubmit: async () => {
        const hasError = formData.value.validateAll();
        if (hasError) {
          return;
        }
        const result = await ApiHelper.callApi(
          "put",
          "/securities/groups/" + selectedId + "/edit",
          {
            name: formData.value.controls.name.value,
            users: formData.value.controls.users.options,
            functions: formData.value.controls.functions.options
          },
          {},
          "core"
        );
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
        ApiHelper.showSuccessMessage(selectedId ? "Updated" : "Added");
        ApiHelper.gotoPage(context, {
          name: "SettingsGroups"
        });
      }
    }
  });

  const pageData = ref<{
    isLoading: boolean;
  }>({
    isLoading: true
  });

  const loadData = async () => {
    if (selectedId > 0) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/securities/groups/" + selectedId,
        {},
        {},
        "core"
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const resultData = result.data || null;
        if (resultData) {
          formData.value.controls.name.value = resultData.name || "";
          formData.value.controls.name.disabled = !!resultData.isSystem;
          formData.value.controls.functions.disabled = !!resultData.isSystem;
          formData.value.controls.users.defaultValues =
            resultData.userIds || [];
          formData.value.controls.functions.defaultValues =
            resultData.functionIds || [];
        }
      } else {
        const message = result.message || "";
        if (message) {
          ApiHelper.showErrorMessage(message, "Oops");
        }
        const errorCode = result.errorCode || "";
        if (errorCode == "not_found") {
          ApiHelper.gotoPage(context, {
            name: "SettingsGroups"
          });
        }
      }
    } else {
      ApiHelper.setDataLoading(false);
    }
  };

  const changeOption = () => {
    const foundItem = formData.value.controls.functions.options.find(
      (item: any) => {
        return item.functionId === formData.value.controls.functions.value;
      }
    );
    if (foundItem) {
      if (foundItem.functionId === foundItem.functionGroup) {
        // find all child options
        const childOptions = formData.value.controls.functions.options.filter(
          (item: any) => {
            return item.functionGroup === foundItem.functionId;
          }
        );
        childOptions.map((item: any) => {
          item.selected = true;
        });
        if (childOptions.length > 1) {
          foundItem.ignore = true;
        } else {
          foundItem.ignore = false;
        }
      } else {
        foundItem.selected = true;
      }
    }
    formData.value.controls.functions.value = "";
  };
  const removeOption = (option: any) => {
    const foundItem = formData.value.controls.functions.options.find(
      (item: any) => {
        return item.functionId === option.functionId;
      }
    );
    if (foundItem) {
      foundItem.selected = false;
      const sameChildOptions = formData.value.controls.functions.options.filter(
        (item: any) => {
          return (
            item.functionGroup === foundItem.functionGroup &&
            item.functionId !== item.functionGroup &&
            item.selected
          );
        }
      );
      if (sameChildOptions.length === 0) {
        const parentOption = formData.value.controls.functions.options.find(
          (item: any) => {
            return item.functionId === foundItem.functionGroup;
          }
        );
        if (parentOption) {
          parentOption.selected = false;
        }
      }

      if (foundItem.functionId !== foundItem.functionGroup) {
        const parentItem = formData.value.controls.functions.options.find(
          (item: any) => {
            return (
              item.functionGroup === foundItem.functionGroup &&
              item.functionGroup == item.functionId
            );
          }
        );
        parentItem.selected = false;
      }
    }
  };

  const loadFunctions = async () => {
    const result = await ApiHelper.callApi(
      "get",
      "/securities/functions",
      {},
      {},
      "core"
    );
    if (result.status === 1) {
      formData.value.controls.functions.options = result.data.functions || [];
      formData.value.controls.functions.options.map(item => {
        if (
          formData.value.controls.functions.defaultValues.includes(
            item.functionId
          )
        ) {
          item.selected = true;
          if (
            item.functionId === item.functionGroup &&
            formData.value.controls.functions.options.filter(item2 => {
              return (
                item2.functionGroup === item.functionId &&
                item2.functionGroup !== item2.functionId
              );
            }).length > 0
          ) {
            item.ignore = true;
          }
        }
      });
    }
  };

  const loadUsers = async () => {
    const result = await ApiHelper.callApi("get", "/users", {}, {}, "core");
    if (result.status === 1) {
      formData.value.controls.users.options = result.data.users || [];
      formData.value.controls.users.options.map(item => {
        item.title = ApiHelper.getFullName(item.firstName, item.lastName);
        if (formData.value.controls.users.defaultValues.includes(item.id)) {
          item.selected = true;
        }
      });
    }
  };

  (async () => {
    await loadData();
    await loadFunctions();
    await loadUsers();
  })();

  const getSelectedFunctions = () => {
    return formData.value.controls.functions.options.filter(
      item => item.selected
    );
  };

  return {
    getSelectedFunctions,
    changeOption,
    removeOption,
    loadData,
    headerData,
    pageData,
    formData
  };
}
