var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data.style === 'custom')?_c('div',{staticClass:"group",class:{
      isRequired: _vm.data.required,
      hasError: _vm.data.error !== '',
      'd-flex': _vm.data.isInline,
      'pb-2': _vm.data.isInline
    }},[_c('label',{staticClass:"label__full",class:{
        'w-50': _vm.data.isInline
      },attrs:{"for":_vm.randomId}},[_vm._v(" "+_vm._s(_vm.forceLabel || _vm.data.label)+" ")]),_c('div',{staticClass:"group__input",class:{
        'w-50': _vm.data.isInline
      }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.data.value),expression:"data.value",modifiers:{"trim":true}}],staticClass:"input__full",class:{
          border: _vm.data.error,
          'border-danger': _vm.data.error && !_vm.noneBackground,
          'border-danger-setting': _vm.noneBackground
        },attrs:{"id":_vm.randomId,"type":"text","maxlength":_vm.data.maxlength || 255,"placeholder":_vm.forcePlaceholder || _vm.data.placeholder,"disabled":_vm.data.disabled || _vm.disabled},domProps:{"value":(_vm.data.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "value", $event.target.value.trim())},function () {
            _vm.data.error = '';
            _vm.$emit('input');
          }],"focusout":function($event){_vm.data.value = _vm.stripTags(_vm.data.value)},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span'),(_vm.data.error && !_vm.hideErrorMessage)?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e()])]):_c('div',{staticClass:"form-group",class:{
      isRequired: _vm.data.required,
      hasError: _vm.data.error !== ''
    }},[(_vm.data.label)?_c('label',{staticClass:"label__full",class:{
        isRequired: _vm.data.required,
        'pt-0': _vm.data.noPadding
      }},[_vm._v(_vm._s(_vm.forceLabel || _vm.data.label))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.data.value),expression:"data.value",modifiers:{"trim":true}}],staticClass:"form-control",class:{
        'border-danger': _vm.data.error && !_vm.noneBackground,
        'border-danger-setting': _vm.noneBackground
      },attrs:{"placeholder":_vm.forcePlaceholder || _vm.data.placeholder,"maxlength":_vm.data.maxlength || 255,"disabled":_vm.data.disabled || _vm.disabled},domProps:{"value":(_vm.data.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "value", $event.target.value.trim())},function () {
          _vm.data.error = '';
          _vm.$emit('input');
        }],"focusout":function($event){_vm.data.value = _vm.stripTags(_vm.data.value)},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.data.error !== '' && !_vm.hideErrorMessage)?_c('span',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }