



























































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import directives from "@/helpers/directives";
import BackButton from "@/components/Common/BackButton.vue";
import FormInput from "@/components/Form/FormInput.vue";
import { useSettingsCabinEditStore } from "@/stores/Settings/EntityCabins/SettingsCabinEditStore";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormToggle from "@/components/Form/FormToggle.vue";
import Header from "@/components/Common/Header.vue";

export default defineComponent({
  name: "SettingsCabinEdit",
  components: {
    Header,
    FormSelect,
    FormInput,
    Loading,
    BackButton,
    FormToggle
  },
  directives: directives,
  setup(props, context) {
    const { headerData, pageData, saveCabin } = useSettingsCabinEditStore(
      context
    );

    return {
      headerData,
      pageData,
      saveCabin
    };
  }
});
