








































































































import { defineComponent, ref } from "@vue/composition-api";
import FormItem from "@/components/Form/FormItem.vue";
import FormButton from "@/components/Form/FormButton.vue";
import Loading from "@/components/Common/Loading.vue";
import { ApiHelper } from "@/helpers";
import FormPassword from "@/components/Form/FormPassword.vue";
import { useUserPasswordResetStore } from "@/stores/User/UserPasswordResetStore";

export default defineComponent({
  name: "UserPasswordReset",
  components: {
    FormPassword,
    Loading,
    FormItem,
    FormButton
  },
  setup(props, context) {
    const { pageData, formData } = useUserPasswordResetStore(context);
    const passwordData = ref({
      hasCapital: false,
      has8characters: false,
      hasNumber: false,
      nonHtml: false,
      valid: false
    });
    const checkStrongPassword = (password: string) => {
      passwordData.value = ApiHelper.getPasswordInfo(password);
      formData.value.controls.password.valid = passwordData.value.valid;
    };
    return {
      passwordData,
      checkStrongPassword,
      random: ApiHelper.randomFormId(),
      pageData,
      formData
    };
  }
});
