import { render, staticRenderFns } from "./NavProfileEvents.vue?vue&type=template&id=547f12ba&scoped=true&v-show=showSidebar&"
import script from "./NavProfileEvents.vue?vue&type=script&lang=ts&"
export * from "./NavProfileEvents.vue?vue&type=script&lang=ts&"
import style0 from "./NavProfileEvents.vue?vue&type=style&index=0&id=547f12ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "547f12ba",
  null
  
)

export default component.exports