import { render, staticRenderFns } from "./RevenueSpendCategoryEdit.vue?vue&type=template&id=f90c6df4&scoped=true&"
import script from "./RevenueSpendCategoryEdit.vue?vue&type=script&lang=ts&"
export * from "./RevenueSpendCategoryEdit.vue?vue&type=script&lang=ts&"
import style0 from "./RevenueSpendCategoryEdit.vue?vue&type=style&index=0&id=f90c6df4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f90c6df4",
  null
  
)

export default component.exports