import { EventDetailsSidebar, SelectOption } from "@/types";
import { ApiHelper } from "@/helpers/index";

export const getEventDetailsSidebarData = async (eventId: number) => {
  /*
  
  const result = await apolloClient
    .query({
      query: gql`
        query($id: Float!) {
          event(id: $id) {
            applications {
              id
              name
            }
          }
          getEvent(id: $id) {
            id
            name
            stats
            logo
            tsStart
            totalCabin
          }
          pTypeStats(id: $id) {
            TypeName
            ParticipantCountTotal
            Capacity
            ParticipantMTotal
            ParticipantFTotal
          }
        }
      `,
      variables: {
        id: eventId
      }
    })
    .then((res: any) => {
      if (res.data) {
        const eventApplications = res.data.event.applications || [];
        const event = res.data.getEvent;
        const info: EventDetailsSidebar = {
          name: event.name || "",
          stats: event.stats || "",
          logo: event.logo || "",
          tsStart: event.tsStart || "",
          totalCabin: event.totalCabin || 0,
          pTypeStats: res.data.pTypeStats || [],
          hasApplications: eventApplications.length > 0 ? true : false
        };
        return {
          status: 1,
          message: "OK",
          data: info
        };
      } else {
        return {
          status: 0,
          message: JSON.stringify(res),
          data: null
        };
      }
    })
    .catch((err: any) => {
      return {
        status: 0,
        message: JSON.stringify(err),
        data: null
      };
    });
  */
  const result = await ApiHelper.callApi(
    "get",
    "/events/" + eventId + "/stats",
    {},
    {}
  );
  if (result.status === 1) {
    const eventApplications = result.data.applications || [];
    const event = result.data;
    const info: EventDetailsSidebar = {
      name: event.name || "",
      stats: event.stats || "",
      logo: event.logo || "",
      tsStart: event.tsStart || "",
      totalCabins: event.totalCabins || 0,
      totalMedicals: event.totalMedicals || 0,
      pTypeStats: result.data.pTypeStats || [],
      hasApplications: eventApplications.length > 0 ? true : false,
      eventDashboard: event.eventDashboard
    };
    return {
      status: 1,
      message: "OK",
      data: info
    };
  }

  return {
    status: 0,
    message: result.message,
    data: {
      stats: "",
      tsStart: "",
      hasApplications: false,
      name: "",
      logo: "",
      totalCabins: 0,
      totalMedicals: 0,
      pTypeStats: [],
      eventDashboard: {
        active: 0,
        percent: 0,
        total: 0,
        totalCost7d: 0,
        totalCost30d: 0,
        totalOwed7d: 0,
        totalOwned30d: 0,
        totalPaid7d: 0,
        totalPaid30d: 0,
        totalParticipants: 0
      }
    }
  };
};

export const getEventOptions = async () => {
  /*
  
  const result = await apolloClient
    .query({
      query: gql`
        query events($skip: Int!, $take: Int!) {
          events(skip: $skip, take: $take) {
            id
            name
          }
        }
      `,
      variables: {
        skip: 0,
        take: 50
      }
    })
    .catch(() => {
      return {
        data: {
          events: []
        }
      };
    });
  */
  const result = await ApiHelper.callApi("get", "/events/search", {}, {});
  if (result.status === 1) {
    const eventList: SelectOption[] = result.data.events.map((item: any) => {
      return {
        id: item.id || "",
        text: item.name || ""
      };
    });
    return eventList;
  }
  return [];
};

export const getEventStatusOptions = () => {
  const options: SelectOption[] = [
    {
      id: "1",
      text: "Active"
    },
    {
      id: "0",
      text: "Archived"
    },
    {
      id: "2",
      text: "Deleted"
    }
  ];

  return options;
};
