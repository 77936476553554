



















import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "OrderByButton",
  props: {
    onSorting: Function,
    sortKey: String,
    sortData: Object,
    title: {
      type: String,
      default: ""
    }
  },
  components: {}
});
