var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-page w-100"},[_c('div',{attrs:{"id":"content"}},[_c('div',{attrs:{"id":"headerContainer"}},[_c('div',{staticClass:"content-head"},[_vm._m(0),_c('div',{staticClass:"head_right"},[_c('div',{staticClass:"head__box hide__ipad"},[_c('div',{staticClass:"head__filter__other"}),_c('span',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.pageData.controls.summaryDropdown.hideOptions),expression:"pageData.controls.summaryDropdown.hideOptions"}],staticClass:"custom-dropdown summary-options"},[_c('span',{staticClass:"custom-dropdown-selected",on:{"click":function($event){return _vm.pageData.controls.summaryDropdown.toggleOptions()}}},[_c('span',{attrs:{"title":_vm.pageData.controls.summaryDropdown.selectedText()}},[_vm._v(" "+_vm._s(_vm.pageData.controls.summaryDropdown.selectedText())+" ")]),_c('img',{attrs:{"src":require("@/assets/images/dashboard-arrow-down.svg"),"alt":"Icon"}})]),_c('div',{staticClass:"views-options",class:{
                  active: _vm.pageData.controls.summaryDropdown.optionsVisible
                }},[_c('ul',_vm._l((_vm.pageData.controls.summaryDropdown
                      .options),function(item,index){return _c('li',{key:index},[_c('span',{staticClass:"view-name textOverflow",class:{
                        'selected-option':
                          item.id == _vm.pageData.controls.summaryDropdown.value
                      },attrs:{"title":item.text},on:{"click":function($event){return _vm.selectedSummaryOptions(item.id)}}},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0)])]),_c('span',{on:{"click":_vm.exportCSV}},[_c('img',{attrs:{"src":require("@/assets/images/dashboard-export.svg"),"alt":"Icon"}})])])])])]),_c('div',{staticClass:"content-inside mw-1300 pb-5"},[_c('h3',{staticClass:"head_heading__small"},[_vm._v(" Financial ")]),_vm._m(1),_c('div',{staticClass:"content-dashboard"},[_c('div',{staticClass:"content_total row"},[_c('div',{staticClass:"col col-3"},[_c('div',{staticClass:"first-total"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard-participants.svg"),"alt":"Icon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.pageData.dashboardStats.totalRegistrationsFormatted)+" ")])]),_c('div',{staticClass:"second-total new-registrations-total"},[_c('span',{staticClass:"big-number",staticStyle:{"color":"#3F5447"}},[_vm._v(" "+_vm._s(_vm.pageData.dashboardStats.data24h.newRegistrationsFormatted)+" ")]),_vm._m(2)])]),_c('div',{staticClass:"col col-3"},[_c('div',{staticClass:"first-total"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard-revenue-total.svg"),"alt":"Icon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.pageData.dashboardStats.totalRevenueFormatted)+" ")])]),_c('div',{staticClass:"second-total revenue-total"},[(_vm.pageData.dashboardStats.data24h.revenue < 0)?_c('span',{staticClass:"big-number",class:{
                  'text-danger': _vm.pageData.dashboardStats.data24h.revenue < 0
                }},[_vm._v(" "+_vm._s(_vm.pageData.dashboardStats.data24h.revenueFormatted)+" ")]):_c('span',{staticClass:"big-number",staticStyle:{"color":"#21A300"}},[_vm._v(" "+_vm._s(_vm.pageData.dashboardStats.data24h.revenueFormatted)+" ")]),_vm._m(3)])]),_c('div',{staticClass:"col col-3"},[_vm._m(4),_c('div',{staticClass:"second-total todo-total"},[_c('span',{staticClass:"big-number",staticStyle:{"color":"#5C357A"}},[_vm._v(" "+_vm._s(_vm.pageData.dashboardStats.data24h.completedTodosFormatted)+" ")]),_vm._m(5)])]),_c('div',{staticClass:"col col-3"},[_vm._m(6),_c('div',{staticClass:"second-total event-capacity-total"},[_c('span',{staticClass:"big-number",staticStyle:{"color":"#5C357A"}},[_vm._v(" "+_vm._s(_vm.pageData.dashboardStats.eventCapacity ? ((_vm.pageData.dashboardStats.eventCapacity) + "%") : "0%")+" ")]),_vm._m(7)])])]),(_vm.pageData.dashboard)?_c('div',{staticClass:"content_finances mw-1300"},[_c('div',{staticClass:"dashboard-chart pt-3"},[_c('div',{staticClass:"chart__bottom"},[_c('div',{staticClass:"dashboard-chart__body"},[(_vm.chartData.isLoaded)?_c('div',[_c('ApexDashboard2',{attrs:{"bar-data":_vm.chartData}})],1):_vm._e()])])])]):_vm._e(),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"content_month__detail"},[_c('div',{staticClass:"activity-list"},[_c('h3',{staticClass:"group__title"},[_vm._v("ACTIVITY")]),_c('div',{staticClass:"content__table__scroll"},[_c('div',{staticClass:"content_table activity-list-content"},[_c('div',{staticClass:"table__body no-stretch h-auto"},[(
                      !_vm.pageData.isLoading &&
                        _vm.pageData.activities.items.length == 0
                    )?_c('div',{staticClass:"noData"},[_vm._v(" No Records Found ")]):_vm._e(),_vm._l((_vm.pageData.activities.items),function(item,index){return _c('div',{key:index,staticClass:"items row",attrs:{"data-id":item.id}},[_c('div',{staticClass:"item has__avatar col col-3"},[_c('div',{staticClass:"item__avatar"},[_c('Avatar',{attrs:{"src":item.logo,"username":item.fullName || '',"size":31}})],1),_c('span',{staticClass:"textOverflow main-title",attrs:{"title":item.fullName}},[_vm._v(" "+_vm._s(item.fullName)+" ")])]),_c('div',{staticClass:"item col col-3"},[_vm._v(" "+_vm._s(item.timeAgo)+" ")]),_c('div',{staticClass:"item col col-6 flex-nowrap flex-row"},[_c('img',{staticClass:"activity-ico-type",attrs:{"src":require("@/assets/images/activity-type-logined.svg"),"alt":"Icon"}}),_c('span',{staticClass:"activity-desc text-truncate",attrs:{"title":item.logDescription}},[_vm._v(" "+_vm._s(item.logDescription)+" ")])])])})],2)])]),_c('div',{staticClass:"clearfix"})])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head_left"},[_c('h2',{staticClass:"head_heading"},[_vm._v("Dashboard")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"head_heading__other"},[_c('img',{attrs:{"src":require("@/assets/images/icon-part-8.png"),"alt":"Icon"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"big-number-desc"},[_vm._v("NEW "),_c('br'),_vm._v("REGISTRATIONS "),_c('br'),_vm._v("(24h)")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"big-number-desc"},[_vm._v("REVENUE "),_c('br'),_vm._v("(24h)")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"first-total"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard-todo-completed.svg"),"alt":"Icon"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"big-number-desc"},[_vm._v("TO-DOs "),_c('br'),_vm._v("COMPLETED "),_c('br'),_vm._v("(24h)")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"first-total"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard-event-capacity.svg"),"alt":"Icon"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"big-number-desc"},[_vm._v("EVENT "),_c('br'),_vm._v("CAPACITY")])}]

export { render, staticRenderFns }