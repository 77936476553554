import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormSelect, FormText } from "@/types";
import { ApiType } from "@/helpers/ApiHelper";

export function useSettingsCabinEditStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const headerData = ref({
    title: selectedId > 0 ? "Edit Cabin" : "Add Cabin",
    subTitle: "Cabins"
  });

  const pageData = ref<{
    isLoading: boolean;
    controls: {
      name: FormText;
      genders: FormSelect;
      // maxAttendeesInChain: FormText;
      // maxYearInAttendees: FormText;
      // maxRequestsPerAttendees: FormText;
      // maxGradesInAttendees: FormText;
    };
    rmAllowAcross: boolean;
  }>({
    isLoading: true,
    controls: {
      name: {
        required: true,
        label: "Cabin Name",
        placeholder: "Cabin name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100
      },
      genders: {
        error: "",
        label: "Gender",
        placeholder: "Select Gender",
        type: "select",
        value: "",
        style: "custom",
        required: false,
        notRequired: true,
        options: ApiHelper.getGenderOptions()
      }
      // maxAttendeesInChain: {
      //   required: false,
      //   label: "Max attendees in chain",
      //   placeholder: "",
      //   style: "custom",
      //   value: "",
      //   error: "",
      //   maxlength: 100
      // },
      // maxYearInAttendees: {
      //   required: false,
      //   label: "Max years between attendees",
      //   placeholder: "",
      //   style: "custom",
      //   value: "",
      //   error: "",
      //   maxlength: 100
      // },
      // maxRequestsPerAttendees: {
      //   required: false,
      //   label: "Max requests per attendee",
      //   placeholder: "",
      //   style: "custom",
      //   value: "",
      //   error: "",
      //   maxlength: 100
      // },
      // maxGradesInAttendees: {
      //   required: false,
      //   label: "Max grades between attendees",
      //   placeholder: "",
      //   style: "custom",
      //   value: "",
      //   error: "",
      //   maxlength: 100
      // }
    },
    rmAllowAcross: false
  });

  const validateAll = () => {
    let hasError = false;
    if (pageData.value.controls.name.value === "") {
      pageData.value.controls.name.error = "Cabin name is required";
      hasError = true;
    }
    return hasError;
  };

  const saveCabin = async () => {
    const hasError = validateAll();
    if (hasError) {
      return;
    }

    const result = await ApiHelper.callApi(
      "put",
      `/entities/cabins/${selectedId}`,
      {
        cabinName: pageData.value.controls.name.value,
        gender: pageData.value.controls.genders.value
        // maxAttendeesInChain: pageData.value.controls.maxAttendeesInChain.value,
        // maxYearInAttendees: pageData.value.controls.maxYearInAttendees.value,
        // maxRequestsPerAttendees:
        //  pageData.value.controls.maxRequestsPerAttendees.value,
        // maxGradesInAttendees:
        //   pageData.value.controls.maxGradesInAttendees.value,
        // rmAllowAcross: pageData.value.rmAllowAcross
      },
      {},
      ApiType.CORE
    );
    if (result.status == 1) {
      ApiHelper.showSuccessMessage(selectedId ? "Updated" : "Added");
      ApiHelper.gotoPage(context, {
        name: "SettingsCabins"
      });
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops");
      return;
    }
  };

  const loadData = async () => {
    if (selectedId > 0) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        `/entities/cabins/${selectedId}`,
        {},
        {},
        ApiType.CORE
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const cabin = result.data.cabin || null;
        if (cabin) {
          pageData.value.controls.name.value = cabin.cabinName || "";
          pageData.value.controls.genders.value = cabin.gender || "";
          // pageData.value.controls.maxAttendeesInChain.value =
          //   cabin.maxAttendeesInChain || "";
          // pageData.value.controls.maxYearInAttendees.value =
          //   cabin.maxYearInAttendees || "";
          // pageData.value.controls.maxRequestsPerAttendees.value =
          //   cabin.maxRequestsPerAttendees || "";
          // pageData.value.controls.maxGradesInAttendees.value =
          //   cabin.maxGradesInAttendees || "";
          // pageData.value.rmAllowAcross =
          //   cabin.rmAllowAcross == "true" ? true : false;
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "SettingsCabins" });
      }
    } else {
      ApiHelper.setDataLoading(false);
    }
  };

  // init data
  (async () => {
    loadData();
  })();

  return {
    loadData,
    headerData,
    pageData,
    saveCabin
  };
}
