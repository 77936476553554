




























import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "FormToggle",
  props: {
    data: Object,
    disabled: {
      type: Boolean,
      defaultValue: false
    }
  },
  setup(props, context) {
    const randomId = ref<number>(ApiHelper.randomFormId());

    const onChange = () => {
      context.emit("onChange");
    };

    return {
      randomId: randomId.value.toString(),
      onChange
    };
  }
});
