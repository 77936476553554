




















import Vue from "vue";

export default Vue.extend({
  name: "Loading",
  props: {
    msg: String,
    title: String,
    summary: String,
    type: {
      type: String,
      default: ""
    },
    isFixed: {
      type: Boolean,
      default: true
    }
  }
});
