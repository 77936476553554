
























































































































































































































































































































































































































































































































































































































































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import Pager from "../Common/Pager.vue";
import FilterSearchColumn from "../Table/FilterSearchColumn.vue";
import FilterSelectColumn from "../Table/FilterSelectColumn.vue";
import FilterAgesColumn from "../Table/FilterAgesColumn.vue";
import FilterNumberRangeColumn from "../Table/FilterNumberRangeColumn.vue";
import Avatar from "vue-avatar/src/Avatar.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterSearchSuggestColumn from "@/components/Table/FilterSearchSuggestColumn.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import NoData from "@/components/Common/NoData.vue";

export default defineComponent({
  name: "ProfilesTable",
  components: {
    NoData,
    TableHeaderColumn,
    Avatar,
    Pager,
    FilterSearchColumn,
    FilterAgesColumn,
    FilterSelectColumn,
    FilterNumberRangeColumn,
    FilterCheckboxesColumn,
    FilterSearchSuggestColumn
  },
  props: {
    pageData: Object,
    showStatusDropdown: Function,
    // filters
    selectItem: Function,
    selectAllGenders: Function,
    resetGenders: Function,
    selectAllEvents: Function,
    resetEvents: Function,
    selectAllFamilies: Function,
    resetFamilies: Function,
    // pager
    gotoPage: Function,
    getCheckedItems: Function,
    togglePagerItems: Function,
    hideHeadActions: Function,
    onViewDetails: Function,
    onClickPrev: Function,
    onClickNext: Function,
    onPagerChange: Function,
    setActiveFilterTab: Function,
    closeFilterTab: Function,
    updateFilterValue: Function,
    updateSortValue: Function,
    updateRouters: Function,
    userSettings: {
      type: Object,
      default: () => ({
        profilesCustomView: []
      })
    }
  },
  setup(props, context) {
    const showCustomViewItem = (itemName: string) => {
      if (context.root.$route.name != "ProfilesCustom") {
        return true;
      }

      const profilesCustomView = props.userSettings.profilesCustomView || [];
      const relatedItem: any = profilesCustomView.find(
        (item: any) => item.name == itemName
      );
      if (relatedItem) {
        return relatedItem.checked || false;
      }

      return true;
    };

    const showAge = () => {
      return showCustomViewItem("Age");
    };

    const showGender = () => {
      return showCustomViewItem("Gender");
    };

    const showFamily = () => {
      return showCustomViewItem("Family");
    };

    const showEmail = () => {
      return showCustomViewItem("Email");
    };

    const showPhone = () => {
      return showCustomViewItem("Phone");
    };

    const showEvents = () => {
      return showCustomViewItem("Event");
    };

    const showBalance = () => {
      return showCustomViewItem("Balance");
    };

    const getPreviewWidth = () => {
      if (context.root.$route.name != "ProfilesCustom") return "";

      let width = 1;
      if (showAge() == false) {
        width += 1;
      }
      if (showGender() == false) {
        width += 1;
      }
      if (showEvents() == false) {
        width += 2;
      }
      if (showBalance() == false) {
        width += 2;
      }
      return `col-${width}`;
    };

    const allowSelection = computed(() => {
      const $this: any = context.root;
      if (
        !$this.$allowedFunctions.includes($this.$userFunctions.EditPerson) &&
        !$this.$allowedFunctions.includes($this.$userFunctions.Message)
      ) {
        return false;
      }

      return true;
    });

    return {
      showAge,
      showGender,
      showEvents,
      showBalance,
      getPreviewWidth,
      allowSelection,
      showFamily,
      showPhone,
      showEmail
    };
  }
});
