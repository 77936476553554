
















































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "DropdownStatus",
  props: {
    options: Array,
    showStatusDropdown: Function,
    updateStatus: Function,
    pageData: Object,
    item: Object
  },
  components: {},
  setup(props) {
    const onClickOutside = () => {
      if (props && props.pageData && props.item) {
        const currentOpenItemId = props.pageData.openItemId;
        const currentItemId = props.item.id;
        if (currentOpenItemId === currentItemId) {
          if (props.showStatusDropdown) {
            props.showStatusDropdown(0);
          }
        }
      }
    };
    return {
      onClickOutside
    };
  }
});
