import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

import { FormText } from "@/types";

export function useMessagingCampaignsEditStore(
  context: any
) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const campaignEditInput = ref<{
    name: string;
  }>({
    name: ""
  });
  const formData = ref<{
    isLoading: boolean;
    controls: {
      name: FormText;
    };
  }>({
    isLoading: true,
    controls: {
      name: {
        error: "",
        type: "text",
        value: ""
      }
    }
  });
  const onSubmit = async () => {
    campaignEditInput.value.name = formData.value.controls.name.value;

    let hasError = false;
    if (campaignEditInput.value.name === "") {
      hasError = true;
      formData.value.controls.name.error = "Campaign name is required!";
    }

    if (!hasError) {
      // formData.value.isLoading = true;
      // const result = await ApiHelper.handleApiResponse(
      //   apolloClient.mutate({
      //     mutation: gql`
      //       mutation updateCampaign(
      //         $id: Float!
      //         $updateData: CampaignEditInput!
      //       ) {
      //         updateCampaign(id: $id, updateData: $updateData) {
      //           id
      //           name
      //         }
      //       }
      //     `,
      //     variables: {
      //       id: selectedId,
      //       updateData: campaignEditInput.value
      //     }
      //   })
      // );
      // formData.value.isLoading = false;
      // if (result.status == 1) {
      //   ApiHelper.showSuccessMessage("Saved!");
      //   ApiHelper.gotoPage(context, { name: "MessagingCampaigns" });
      // } else {
      //   ApiHelper.showErrorMessage(result.message);
      // }
    }
  };

  (async () => {
    /*const result = await ApiHelper.handleApiResponse(
      apolloClient.query({
        query: gql`
          query campaign($id: Float!) {
            campaign(id: $id) {
              id
              name
              content
              params
            }
          }
        `,
        variables: {
          id: selectedId
        }
      })
    );
    if (result.status === 1) {
      const campaignItem = result.data.campaign || {};
      formData.value.controls.name.value = campaignItem.name || "...";
    } else {
      ApiHelper.showErrorMessage(result.message);
    }*/
    formData.value.isLoading = false;
  })();

  const removeFieldError = (name: string) => {
    switch (name) {
      case "name":
        formData.value.controls.name.error = "";
        break;
    }
  };
  return {
    formData,
    removeFieldError,
    onSubmit
  };
}
