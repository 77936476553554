




















































































import { defineComponent, ref } from "@vue/composition-api";

import FormButton from "@/components/Form/FormButton.vue";
import Loading from "@/components/Common/Loading.vue";
import { useMessagingTemplatesDetailsStore } from "@/stores/Messaging/Template/MessagingTemplatesDetailsStore";
import Header from "@/components/Common/Header.vue";

export default defineComponent({
  name: "MessagingTemplatesDetailsPage",
  components: {
    Loading,
    FormButton,
    Header
  },
  setup(props, context) {
    const mode = ref("desktop");
    const { pageData } = useMessagingTemplatesDetailsStore(context);
    const getTemplateHtml = () => {
      return `<!doctype html>
      <html lang="en">
        <head>
          <meta charset="utf-8">
          <style type="text/css">
            ${pageData.value.details.css}
          </style>
        </head>
        <body>

          ${pageData.value.details.content}
        </body>
      </html>`;
    };
    return {
      getTemplateHtml,
      mode,
      pageData
    };
  }
});
