
















































import { defineComponent, ref } from "@vue/composition-api";
import VposTabs from "./VPosTabs.vue";
import HeaderActions from "@/components/Common/HeaderActions.vue";

export default defineComponent({
  name: "HeaderVPosComponent",
  props: {
    totalEvents: Number,
    title: String,
    plusLink: String,
    tab: String,
    filters: Object,
    hideTotal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VposTabs,
    HeaderActions
  },
  setup(props, context) {
    const isShowFilters = ref<boolean>(false);
    const hideFilters = () => {
      isShowFilters.value = false;
    };
    const toggleFilters = () => {
      if (props.filters && props.filters.list.length > 0) {
        isShowFilters.value = !isShowFilters.value;
      } else {
        isShowFilters.value = false;
      }
    };
    return {
      isShowFilters,
      hideFilters,
      toggleFilters
    };
  }
});
