var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"group",class:{
    isRequired: _vm.data.required,
    hasError: _vm.data.error !== ''
  }},[_c('label',{staticClass:"label__full",attrs:{"for":_vm.randomId}},[_vm._v(_vm._s(_vm.data.label))]),_c('div',{on:{"mouseenter":_vm.onMouseEnter}},_vm._l((_vm.data.options),function(option,index){return _c('div',{key:'checkbox-option-' + _vm.randomId + '-' + index,staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"id":'checkbox-option-' + _vm.randomId + '-' + index,"disabled":option.disabled || _vm.data.disabled,"type":"checkbox"},domProps:{"checked":_vm.data.value === option.value,"value":option.value},on:{"change":function () {
            _vm.data.value === option.value
              ? (_vm.data.value = '')
              : (_vm.data.value = option.value);
            _vm.doChange(option);
          }}}),_c('label',{staticClass:"form-check-label",attrs:{"for":'checkbox-option-' + _vm.randomId + '-' + index}},[_vm._v(" "+_vm._s(option.title)+" ")])])}),0),(_vm.data.error)?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }