






































































import Vue from "vue";

export default Vue.extend({
  name: "Modal",
  props: {
    isLoading: Boolean,
    onClose: Function,
    hideFooter: Boolean,
    hideScroll: Boolean,
    hasScroll: {
      type: Boolean,
      default: false
    },
    bgGray: {
      type: Boolean,
      default: false
    },
    title: String,
    size: String
  }
});
