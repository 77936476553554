











































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import directives from "@/helpers/directives";
import BackButton from "@/components/Common/BackButton.vue";
import FormInput from "@/components/Form/FormInput.vue";
import { useSettingsTagEditStore } from "@/stores/Settings/Tags/SettingsTagEditStore";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormToggle from "@/components/Form/FormToggle.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import Header from "@/components/Common/Header.vue";

export default defineComponent({
  name: "SettingsTagEdit",
  components: {
    Header,
    FormSelect,
    FormInput,
    Loading,
    BackButton,
    FormToggle,
    FormTextarea
  },
  directives: directives,
  setup(props, context) {
    const {
      loadData,
      headerData,
      pageData,
      formData
    } = useSettingsTagEditStore(context);

    return {
      loadData,
      headerData,
      pageData,
      formData
    };
  }
});
