





























































import Vue from "vue";
import Modal from "@/components/Common/Modal.vue";
import FormButton from "@/components/Form/FormButton.vue";
import { ref } from "@vue/composition-api";
import { FormSelect } from "@/types";
import FormSelectOptions from "@/components/Form/FormSelect.vue";
import moment from "moment";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
// import FormDatePicker3 from "@/components/Form/FormDatePicker3.vue";
import FormDate from "@/types/FormDate";
import { ApiHelper } from "@/helpers";

export default Vue.extend({
  name: "AllergiesReportModal",
  props: {},
  components: {
    Modal,
    FormButton,
    FormSelectOptions,
    FormMultiSelect
    // FormDatePicker3
  },
  setup(props, context) {
    const pageData = ref<{
      isLoading: boolean;
      controls: {
        eventYears: FormSelect;
        asOfDate: FormDate;
        events: any;
      };
    }>({
      isLoading: false,
      controls: {
        eventYears: {
          error: "",
          label: "Event Year",
          type: "select",
          value: "",
          required: true,
          options: []
        },
        asOfDate: {
          error: "",
          format: "MM/DD/YYYY",
          placeholder: "MM/DD/YYYY",
          label: "As Of Date",
          type: "date",
          value: "",
          style: "custom"
        },
        events: {
          isInline: true,
          required: false,
          label: "Event",
          style: "custom",
          value: "",
          error: "",
          defaultValues: [],
          showDropdown: false,
          options: []
        }
      }
    });

    const getEventOptions = async (startYear: number) => {
      const events = await ApiHelper.callApi(
        "get",
        "/reports/events",
        {},
        {
          getAllEvents: 1,
          startYear
        }
      );
      if (events.status == 1) {
        pageData.value.controls.events.options = events.data.events.map(
          (item: any) => ({
            id: item.eventId,
            title: item.eventName,
            selected: false
          })
        );
      }
    };

    const eventYearsChange = async () => {
      pageData.value.controls.events.options = [];
      const startYear = parseInt(pageData.value.controls.eventYears.value) || 0;
      await getEventOptions(startYear);
    };

    const doExport = async () => {
      try {
        pageData.value.isLoading = true;
        context.emit("setIsLoading", true);
        const startYear =
          parseInt(pageData.value.controls.eventYears.value) || 0;
        const eventIds = pageData.value.controls.events.options
          .filter((item: any) => (item.selected || false) == true)
          .map((item: any) => item.id);
        // const start = moment(pageData.value.controls.asOfDate.value);
        // const asOfDate = start.isValid() ? start.format("YYYY/MM/DD") : "";

        const result = await ApiHelper.callApi(
          "get",
          "/reports/allergies",
          {},
          {
            startYear,
            eventIds: eventIds.join(",")
            // asOfDate
          }
        );

        if (result.status === 1) {
          const exportData = [];
          for (const item of result.data.items || []) {
            exportData.push([
              item.fullName,
              item.eventName,
              item.pTypeName,
              item.cabinName,
              item.allergyName,
              item.categoryName,
              item.severityName
            ]);
          }

          const headers = [
            "PARTICIPANT",
            "EVENT",
            "TYPE",
            "CABIN",
            "ALLERGY NAME",
            "ALLERGY CATEGORY",
            "ALLERGY SEVERITY"
          ];
          const fileName = ["Allergies"];
          fileName.push(moment().format("MMDDYYYY_HHMMSS"));
          await ApiHelper.generateCsv(exportData, headers, fileName.join("_"));

          // close modal
          context.emit("close");
        }
      } catch (error) {
        console.log(error);
      } finally {
        pageData.value.isLoading = false;
        context.emit("setIsLoading", false);
      }
    };

    // init data
    (async () => {
      pageData.value.controls.eventYears.options = [];
      const currentYear = moment().year();
      for (let i = currentYear - 5; i <= currentYear + 2; i++) {
        pageData.value.controls.eventYears.options.push({
          id: i,
          text: `${i}`
        });
      }
      pageData.value.controls.eventYears.value = `${currentYear}`;

      // get event of selected year
      await getEventOptions(currentYear);
    })();

    return {
      pageData,
      doExport,
      eventYearsChange
    };
  }
});
