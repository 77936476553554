





























































import { defineComponent, ref } from "@vue/composition-api";
import ImageUploader from "vue-image-upload-resize/src/components/ImageUploader.vue";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "FormUpload",
  components: {
    ImageUploader
  },
  props: {
    data: Object
  },
  setup(props, context) {
    const randomId = ref<number>(ApiHelper.randomFormId());
    const onChangeFile = async (base64: string) => {
      if (props.data && props.data.error) {
        props.data.error = "";
      }
      if (props.data) {
        props.data.preview = base64;
        props.data.base64 = base64;
        props.data.isDeleted = false;
        const img = new Image();
        img.src = base64;
        img.onload = () => {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          context.emit("updateSize", imgWidth, imgHeight);
        };
      }
    };
    return {
      randomId,
      onChangeFile
    };
  }
});
