
















import { ref, defineComponent } from "@vue/composition-api";

import Loading from "@/components/Common/Loading.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import ComingSoon from "@/components/Common/ComingSoon.vue";

type PageData = {
  isLoading: boolean;
  pageTitle: string;
};

export default defineComponent({
  name: "EventSchedulePage",
  components: {
    ComingSoon,
    HeaderEventDetails,
    SidebarEventDetails,
    Loading
  },
  setup(props, context) {
    const pageData = ref<PageData>({
      isLoading: false,
      pageTitle: ""
    });
    return {
      pageData
    };
  }
});
