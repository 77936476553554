



















































































import { defineComponent, ref } from "@vue/composition-api";
import OrderByButton from "@/components/Common/OrderByButton.vue";
import directives from "@/helpers/directives";
export default defineComponent({
  name: "FilterNumberRangeColumn",
  components: {
    OrderByButton
  },
  directives: directives,
  props: {
    label: String,
    model: String,
    data: Object,
    activeTab: String,
    activeValue: String,
    setActiveFilterTab: Function,
    closeFilterTab: Function,
    onChangeValue: Function,
    sortKey: String,
    sortData: Object,
    onChangeSortValue: Function,
    allowNegativeNumber: {
      type: Boolean,
      defaultValue: false
    }
  },
  setup(props, context) {
    const splitString = props.allowNegativeNumber ? "@" : "-";
    const getDefaultMinValue = () => {
      const range = props.model || "";
      const arr = range.split(splitString);
      if (arr.length === 2) {
        return arr[0] || "";
      } else {
        return "";
      }
    };

    const getDefaultMaxValue = () => {
      const range = props.model || "";
      const arr = range.split(splitString);
      if (arr.length === 2) {
        return arr[1].toString() || "";
      } else {
        return "";
      }
    };

    const minValue = ref<string>(getDefaultMinValue());
    const maxValue = ref<string>(getDefaultMaxValue());
    const minError = ref<string>("");
    const maxError = ref<string>("");
    const searchValue = ref<string>("");
    const timer = ref<any>(null);

    const updateMinMaxValues = () => {
      minValue.value = getDefaultMinValue();
      maxValue.value = getDefaultMaxValue();
    };

    const searchTimeOut = () => {
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = null;
      }
      timer.value = setTimeout(() => {
        if (props.onChangeValue) {
          props.onChangeValue(props.activeValue, searchValue.value);
        }
      }, 800);
    };

    const onMinInput = (event: any) => {
      minError.value = "";
      maxError.value = "";
      minValue.value = event.target.value;
      searchValue.value = minValue.value + splitString + maxValue.value;
    };

    const onMaxInput = (event: any) => {
      minError.value = "";
      maxError.value = "";
      maxValue.value = event.target.value;
      searchValue.value = minValue.value + splitString + maxValue.value;
    };

    const doClose = () => {
      if (props.setActiveFilterTab) {
        props.setActiveFilterTab("");
        maxError.value = "";
      }
    };
    const doFilter = () => {
      if (minValue.value && maxValue.value) {
        const min = Number.parseInt(minValue.value, 10) || 0;
        const max = Number.parseInt(maxValue.value, 10) || 0;
        if (min > max) {
          maxError.value = "Max Value must larger than Min Value";
          return;
        }
      }

      if (props.onChangeValue) {
        searchValue.value = minValue.value + splitString + maxValue.value;
        props.onChangeValue(props.activeValue, searchValue.value);
        doClose();
      }
    };

    const doReset = () => {
      minError.value = "";
      maxError.value = "";
      searchValue.value = "";
      minValue.value = "";
      maxValue.value = "";
      if (props.setActiveFilterTab) {
        props.setActiveFilterTab("");
      }
      if (props.onChangeValue) {
        props.onChangeValue(props.activeValue, searchValue.value);
      }
    };

    const onKeyup = (event: any) => {
      if (event.keyCode === 13 && props.onChangeValue) {
        props.onChangeValue(props.activeValue, searchValue.value);
        doClose();
      }
    };

    const onClickOutside = () => {
      updateMinMaxValues();
      if (props.activeTab === props.activeValue) {
        doClose();
      }
    };
    const onClickSorting = () => {
      if (props.onChangeSortValue) {
        const currentOrder = props.sortData ? props.sortData.order || "" : "";
        const currentDirection = props.sortData
          ? props.sortData.direction || "2"
          : "2";
        if (props.sortKey !== currentOrder) {
          props.onChangeSortValue(props.sortKey, "2");
        } else {
          props.onChangeSortValue(
            props.sortKey,
            currentDirection === "1" ? "2" : "1"
          );
        }
      }
    };

    return {
      minError,
      maxError,
      updateMinMaxValues,
      onClickSorting,
      onClickOutside,
      minValue,
      maxValue,
      onMinInput,
      onMaxInput,
      onKeyup,
      doFilter,
      doReset,
      doClose,
      searchTimeOut,
      getDefaultMaxValue,
      getDefaultMinValue
    };
  }
});
