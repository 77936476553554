import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormSelect, PagerItem } from "@/types";
import { TodosListRows } from "@/helpers/estimateNoOfListRows";
import { dollarFormat } from "../../helpers/ApiHelper";

export function useProfileTodosStore(context: any) {
  const selectedProfileId = context.root.$route.params.profileId
    ? Number.parseInt(context.root.$route.params.profileId, 10)
    : 0;
  const sidebarData = ref({
    isLoading: true,
    logo: "",
    name: "",
    profileId: 0,
    participantId: 0,
    status: 0,
    eventId: 0,
    eventName: "",
    pTypeName: "",
    paidAmount: 0,
    paidAmountFormatted: "",
    financials: {
      totalPaid: 0
    }
  });
  const headerData = ref({
    title: "To Dos",
    subTitle: ""
  });
  const formData = ref<{
    controls: {
      status: FormSelect;
    };
  }>({
    controls: {
      status: {
        label: "Status",
        value: "1",
        error: "",
        options: [
          {
            id: 0,
            text: "Incomplete"
          },
          {
            id: 1,
            text: "Pending"
          },
          {
            id: 2,
            text: "Complete"
          }
        ]
      }
    }
  });
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      key: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    foundApps: object[];
    endItemMessage: string;
    paying: boolean;
    transferVisible: boolean;
  }>({
    isLoading: true,
    skip: 0,
    take: TodosListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "2"
    },
    filter: {
      key: ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    foundApps: [],
    endItemMessage: "",
    paying: false,
    transferVisible: false
  });

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: "ProfileTodos",
        params: {
          profileId: selectedProfileId.toString()
        },
        query: {
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          key: pageData.value.filter.key || undefined,
          id: context.root.$route.query.id
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
  };

  const getStatusText = (status: number) => {
    switch (status) {
      case 1:
        return "Pending";
      case 2:
        return "Complete";
      default:
        return "Incomplete";
    }
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        `/participants/todos`,
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          profileId: selectedProfileId,
          onlyAllergies: context.root.$route.query.onlyAllergies || 0,
          participantId: context.root.$route.query.id
            ? Number.parseInt(context.root.$route.query.id, 10)
            : undefined,
          eventId: context.root.$route.query.eventId
            ? Number.parseInt(context.root.$route.query.eventId, 10)
            : undefined
        }
      );
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
      if (result.status === 1) {
        const totalCount = result.data.totalCount || 0;
        pageData.value.pager.totalPages = Math.ceil(
          totalCount / pageData.value.take
        );
        pageData.value.pager.total = totalCount;
        const pagerList = [];
        for (let i = 0; i < pageData.value.pager.totalPages; i++) {
          const pagerItem: PagerItem = {
            label: i + 1 + "",
            value: i + 1,
            active: i + 1 === pageData.value.pager.page
          };
          pagerList.push(pagerItem);
        }
        pageData.value.pager.items = pagerList;
        const list = result.data.todos.map((item: any) => {
          return {
            id: item.formId,
            isChecked: false,
            participant: ApiHelper.getFullName(
              item.firstName || "",
              item.lastName || ""
            ),
            formName: item.formName || "",
            formContent: item.formContent || "",
            eventName: item.eventName || "",
            participantTypeName: item.participantTypeName || "",
            dueDateText: item.dueDateText || "",
            dueDateFormatted: item.dueDateFormatted || "",
            linkEpfStatus: item.linkEpfStatus || 0,
            linkEpfId: item.linkEpfId || 0,
            profileId: item.profileId || 0,
            participantId: item.participantId || 0,
            formId: item.formId || 0,
            status: getStatusText(item.linkEpfStatus),
            participantStatus: item.participantStatus,
            isRecycled: item.isRecycled || 0
          };
        });

        pageData.value.items = list;
      } else if (result.status === -1) {
        ApiHelper.gotoPage(context, {
          name: "Login",
          query: { destination: "/settings/users" }
        });
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops..");
        ApiHelper.gotoPage(context, {
          name: "Profiles"
        });
      }
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };
  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const getFiltersData = () => {
    const filters = [];

    if (pageData.value.filter.key !== "") {
      filters.push({
        label: "KEYWORD",
        key: pageData.value.filter.key + Math.random(),
        value: pageData.value.filter.key,
        reset: () => {
          pageData.value.filter.key = "";
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }

    return {
      list: filters
    };
  };

  // init data
  (async () => {
    loadData(1);
    pageData.value.filter.key = context.root.$route.query.key || "";
  })();

  (async () => {
    const participantId =
      Number.parseInt(context.root.$route.query.id, 10) || 0;
    if (participantId > 0) {
      const result = await ApiHelper.callApi(
        "get",
        "/participants/" + participantId,
        {},
        {}
      );
      if (result.status === 1) {
        headerData.value.subTitle =
          ApiHelper.getFullName(
            result.data.firstName || "",
            result.data.lastName || ""
          ) +
            " - " +
            result.data.eventName || "";
        sidebarData.value.name = result.data.eventName;
        sidebarData.value.profileId = result.data.profileId || 0;
        sidebarData.value.participantId = result.data.participantId || 0;
        sidebarData.value.eventId = result.data.eventId || 0;
        sidebarData.value.eventName = result.data.eventName || "";
        sidebarData.value.pTypeName = result.data.participantTypeName || "";
        sidebarData.value.status = result.data.participantStatus || 0;
        sidebarData.value.paidAmount = result.data.totalPaid || 0;
        sidebarData.value.paidAmountFormatted = dollarFormat(
          sidebarData.value.paidAmount
        );
        sidebarData.value.financials = {
          totalPaid: sidebarData.value.paidAmount
        };
        sidebarData.value.isLoading = false;
      }
    } else {
      const result = await ApiHelper.callApi(
        "get",
        "/profiles/" + selectedProfileId + "/info",
        {},
        {}
      );
      if (result.status === 1) {
        headerData.value.subTitle = ApiHelper.getFullName(
          result.data.firstName || "",
          result.data.lastName || ""
        );
      }
    }
  })();

  return {
    formData,
    sidebarData,
    // page
    headerData,
    pageData,
    // filters
    // --- pager
    loadList,
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // --- header
    getFiltersData
  };
}
