import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormText, FormSelect, PagerItem } from "@/types";

export function useSettingsEventGroupEditStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const headerData = ref({
    title: selectedId > 0 ? "Edit Group" : "Add Group",
    subTitle: "Groups"
  });
  const formData = ref<{
    isLoading: boolean;
    selectedId: number;
    controls: {
      name: FormText;
      contributionAmount: FormText;
      active: FormSelect;
      // events: {
      //   label: string;
      //   placeholder: string;
      //   style: string;
      //   showDropdown: boolean;
      //   disabled?: boolean;
      //   value: string;
      //   error: string;
      //   defaultValues: number[];
      //   options: any[];
      // };
      events: FormSelect;
      pTypes: FormSelect;
      capacityFemale: any;
      capacityMale: any;
      capacityTotal: any;
    };
    validateAll: any;
    actions: {
      onSubmit: any;
    };
    isACILoading: boolean;
    foundEventPTypes: any;
    eventPTypes: any;
    registrations: {
      skip: number;
      take: number;
      items: any[];
      activeTab: string;
      sort: {
        order: string;
        direction: string;
      };
      filter: {
        //
      };
      showHeadActions: boolean;
      pager: {
        showPagerItems: boolean;
        page: number;
        totalPages: number;
        total: number;
        items: PagerItem[];
      };
    };
    regCnt: number;
    regFemaleCnt: number;
    regMaleCnt: number;
  }>({
    isLoading: true,
    selectedId: parseInt(context.root.$route.params.id) || 0,
    controls: {
      name: {
        required: true,
        label: "Group Name",
        placeholder: "Enter the group name",
        style: "custom",
        value: "",
        error: ""
      },
      contributionAmount: {
        required: true,
        label: "Contribution Amount",
        placeholder: "Enter the contribution amount",
        style: "custom",
        value: "",
        error: ""
      },
      active: {
        label: "Active?",
        error: "",
        type: "dropdown",
        value: "1",
        required: false,
        style: "custom",
        options: [
          {
            id: "1",
            text: "Yes"
          },
          {
            id: "0",
            text: "No"
          }
        ]
      },
      events: {
        label: "Event",
        error: "",
        type: "dropdown",
        value: "",
        required: true,
        options: [],
        style: "custom",
        placeholder: "Select Event"
      },
      pTypes: {
        label: "Participant Type",
        error: "",
        type: "dropdown",
        value: "",
        required: true,
        options: [],
        style: "custom",
        placeholder: "Select Type"
      },
      // events: {
      //   label: "Events",
      //   placeholder: "Enter the group name",
      //   style: "custom",
      //   showDropdown: false,
      //   defaultValues: [],
      //   value: "",
      //   error: "",
      //   options: []
      // },
      capacityFemale: {
        value: "",
        error: ""
      },
      capacityMale: {
        value: "",
        error: ""
      },
      capacityTotal: {
        value: "",
        error: ""
      }
    },
    validateAll: () => {
      let hasError = false;
      formData.value.controls.name.error = "";
      formData.value.controls.events.error = "";
      // formData.value.controls.functions.error = "";
      if (formData.value.controls.name.value === "") {
        formData.value.controls.name.error = "Group Name is required";
        hasError = true;
      }
      if (!formData.value.controls.contributionAmount.value) {
        formData.value.controls.contributionAmount.error =
          "Contribution Amount is required";
        hasError = true;
      }
      return hasError;
    },
    actions: {
      onSubmit: async () => {
        /*
        const hasError = formData.value.validateAll();
        if (hasError) {
          return;
        }
        const foundItems: any[] = [];
        // formData.value.controls.events.options.map(item => {
        //   if (item.selected === true) {
        //     foundItems.push({
        //       eventId: item.eventId
        //     });
        //   }
        // });
        const result = await ApiHelper.callApi(
          "put",
          "/entities/eventGroups/" + selectedId,
          {
            groupName: formData.value.controls.name.value,
            contributionAmount:
              formData.value.controls.contributionAmount.value,
            linkEvents: JSON.stringify(foundItems),
            active: formData.value.controls.active.value
          },
          {},
          "core"
        );
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(
            "Group event already exists. Please enter new information.",
            "Oops"
          );
          return;
        }
        ApiHelper.showSuccessMessage(selectedId ? "Updated" : "Added");
        ApiHelper.gotoPage(context, {
          name: "SettingsEventGroups"
        }); */

        // const eventId = parseInt(formData.value.controls.events.value) || 0;

        formData.value.controls.name.error = "";
        formData.value.controls.active.error = "";
        formData.value.controls.pTypes.error = "";
        formData.value.controls.capacityFemale.error = "";
        formData.value.controls.capacityMale.error = "";
        formData.value.controls.capacityTotal.error = "";
        const groupName = formData.value.controls.name.value || "";

        // const pTypeId = parseInt(formData.value.controls.pTypes.value) || 0;
        // const selectedPType = (
        //   formData.value.controls.pTypes.options || []
        // ).find((t: any) => t.id == pTypeId);

        // const capacityFemale = isNaN(
        //   parseInt(formData.value.controls.capacityFemale.value)
        // )
        //   ? null
        //   : parseInt(formData.value.controls.capacityFemale.value);
        // const capacityMale = isNaN(
        //   parseInt(formData.value.controls.capacityMale.value)
        // )
        //   ? null
        //   : parseInt(formData.value.controls.capacityMale.value);
        // const capacityTotal = isNaN(
        //   parseInt(formData.value.controls.capacityTotal.value)
        // )
        //   ? null
        //   : parseInt(formData.value.controls.capacityTotal.value);

        let hasError = false;
        if (groupName === "") {
          hasError = true;
          formData.value.controls.name.error = "Name is required";
        }

        // if (eventId == 0) {
        //   hasError = true;
        //   formData.value.controls.events.error = "Event is required";
        // }
        // if (pTypeId == 0) {
        //   hasError = true;
        //   formData.value.controls.pTypes.error = "Type is required";
        // }

        // if (capacityTotal == 0) {
        //   hasError = true;
        //   formData.value.controls.capacityTotal.error =
        //     "Total capacity is required";
        // }

        // some capacity setting should based on participant type capacity
        if (formData.value.eventPTypes.length) {
          for (const item of formData.value.eventPTypes) {
            const capacityFemale = isNaN(parseInt(item.capacityFemale.value))
              ? null
              : parseInt(item.capacityFemale.value);
            const capacityMale = isNaN(parseInt(item.capacityMale.value))
              ? null
              : parseInt(item.capacityMale.value);
            const capacityTotal = isNaN(parseInt(item.capacityTotal.value))
              ? null
              : parseInt(item.capacityTotal.value);

            // check group capacity female
            // if (!hasError) {
            if (parseInt(item.pTypeCapacity.capacityFemale) >= 0) {
              if (capacityFemale == null) {
                hasError = true;
                const message = "Capacity Female is required";
                item.capacityFemale.error = message;
                // ApiHelper.showErrorMessage(message);
              } else if (
                capacityFemale > parseInt(item.pTypeCapacity.capacityFemale)
              ) {
                // set a group capacity female over ptype capacity female
                hasError = true;
                const message = `Capacity Female should not greater than capacity female of "${item.pTypeName}"`;
                item.capacityFemale.error = message;
                // ApiHelper.showErrorMessage(message);
              }
            }
            // }

            // check group capacity male
            // if (!hasError) {
            if (parseInt(item.pTypeCapacity.capacityMale) >= 0) {
              if (capacityMale == null) {
                hasError = true;
                const message = "Capacity Male is required";
                item.capacityMale.error = message;
                // ApiHelper.showErrorMessage(message);
              } else if (
                capacityMale > parseInt(item.pTypeCapacity.capacityMale)
              ) {
                // set a group capacity male over ptype capacity male
                hasError = true;
                const message = `Capacity Male should not greater than capacity male of "${item.pTypeName}"`;
                item.capacityMale.error = message;
                // ApiHelper.showErrorMessage(message);
              }
            }
            // }

            // check group capacity total
            // if (!hasError) {
            if (parseInt(item.pTypeCapacity.capacityTotal) >= 0) {
              if (capacityTotal == null) {
                hasError = true;
                const message = "Total capacity is required";
                item.capacityTotal.error = message;
                // ApiHelper.showErrorMessage(message);
              } else if (
                capacityTotal > parseInt(item.pTypeCapacity.capacityTotal)
              ) {
                // set a group capacity over ptype capacity
                hasError = true;
                const message = `Total capacity should not greater than capacity of "${item.pTypeName}"`;
                item.capacityTotal.error = message;
                // ApiHelper.showErrorMessage(message);
              }
            }
            // }

            if (capacityFemale != null && capacityMale != null) {
              const total = capacityFemale + capacityMale;
              if (parseInt(total.toFixed(2)) != capacityTotal) {
                hasError = true;
                const message = "Total capacity is not match";
                item.capacityTotal.error = message;
                // ApiHelper.showErrorMessage(message);
              }
            }
          }
        }

        if (hasError) {
          return false;
        }

        const eventPTypes = formData.value.eventPTypes.map((item: any) => ({
          eventId: item.eventId,
          pTypeId: item.pTypeId,
          capacityFemale: isNaN(parseInt(item.capacityFemale.value))
            ? null
            : parseInt(item.capacityFemale.value),
          capacityMale: isNaN(parseInt(item.capacityMale.value))
            ? null
            : parseInt(item.capacityMale.value),
          capacityTotal: isNaN(parseInt(item.capacityTotal.value))
            ? null
            : parseInt(item.capacityTotal.value)
        }));

        if (selectedId > 0) {
          formData.value.isLoading = true;
          const result = await ApiHelper.callApi(
            "put",
            "/events/registrationGroups/" + selectedId,
            {
              groupName,
              active: parseInt(formData.value.controls.active.value) || 0,
              eventPTypes: JSON.stringify(eventPTypes),
              type: "group_setting"
            },
            {}
          );
          formData.value.isLoading = false;
          if (result.status !== 1) {
            ApiHelper.showErrorMessage(result.message, "Oops");
            return false;
          }
          ApiHelper.showSuccessMessage("Updated");
        } else {
          formData.value.isLoading = true;
          const result = await ApiHelper.callApi(
            "post",
            "/events/registrationGroups",
            {
              groupName,
              active: parseInt(formData.value.controls.active.value) || 0,
              eventPTypes: JSON.stringify(eventPTypes),
              type: "group_setting"
            },
            {}
          );
          formData.value.isLoading = false;
          if (result.status !== 1) {
            ApiHelper.showErrorMessage(result.message, "Oops");
            return false;
          }
          ApiHelper.showSuccessMessage("Added");
        }

        ApiHelper.gotoPage(context, {
          name: "SettingsEventGroups"
        });

        return true;
      }
    },
    isACILoading: false,
    foundEventPTypes: [],
    eventPTypes: [],
    registrations: {
      skip: 0,
      take: 8,
      activeTab: "",
      sort: {
        order: context.root.$route.query.order,
        direction: context.root.$route.query.direction
      },
      filter: {
        //
      },
      items: [],
      showHeadActions: false,
      pager: {
        showPagerItems: false,
        page: 1,
        total: 0,
        totalPages: 1,
        items: []
      }
    },
    regCnt: 0,
    regFemaleCnt: 0,
    regMaleCnt: 0
  });

  const loadData = async () => {
    if (selectedId > 0) {
      formData.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "get",
        "/events/registrationGroups/" + selectedId,
        {},
        {}
      );
      formData.value.isLoading = false;
      if (result.status === 1) {
        const resultData = result.data.group || null;
        if (resultData) {
          formData.value.controls.name.value = resultData.groupName || "";
          headerData.value.title = resultData.groupName || "";
          formData.value.controls.active.value = resultData.isActive || "0";
          formData.value.eventPTypes = (result.data.eventPTypes || []).map(
            (item: any) => ({
              eventId: item.eventId,
              eventName: item.eventName,
              pTypeId: item.pTypeId,
              pTypeName: item.pTypeName,
              capacityFemale: {
                error: "",
                value: item.capacityFemale == null ? "" : item.capacityFemale
              },
              capacityMale: {
                error: "",
                value: item.capacityMale == null ? "" : item.capacityMale
              },
              capacityTotal: {
                error: "",
                value: item.capacityTotal == null ? "" : item.capacityTotal
              },
              pTypeCapacity: {
                // ptype capacity
                capacityTotal: item.pTypeCapacity,
                capacityFemale: item.pTypeCapacityFemale,
                capacityMale: item.pTypeCapacityMale
              },
              regCnt: item.regCnt || 0
            })
          );
          formData.value.registrations = result.data.registrations || [];
          formData.value.regCnt = result.data.regCnt || 0;
          formData.value.regFemaleCnt = result.data.regFemaleCnt || 0;
          formData.value.regMaleCnt = result.data.regMaleCnt || 0;

          // formData.value.controls.events.value = resultData.eventId || "";
          // formData.value.controls.capacityTotal.value =
          //   resultData.capacityTotal != null ? resultData.capacityTotal : "";
          // formData.value.controls.capacityFemale.value =
          //   resultData.capacityFemale != null ? resultData.capacityFemale : "";
          // formData.value.controls.capacityMale.value =
          //   resultData.capacityMale != null ? resultData.capacityMale : "";

          // if (resultData.eventId) {
          //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
          //   const pTypes = await getPTypes(resultData.eventId);
          //   if (pTypes.status == 1) {
          //     formData.value.controls.pTypes.options = (
          //       pTypes.data.ptypes || []
          //     ).map((item: any) => ({
          //       id: item.value,
          //       // eslint-disable-next-line @typescript-eslint/no-use-before-define
          //       text: getPTypeText(item),
          //       data: item
          //     }));
          //   }
          // }
          // formData.value.controls.pTypes.value = resultData.pTypeId;

          // formData.value.controls.contributionAmount.value =
          //   resultData.contributionAmount || "0";
          // formData.value.controls.events.defaultValues = [];
          // for (const ev of resultData.linkEvents) {
          //   formData.value.controls.events.defaultValues.push(
          //     parseInt(ev.eventId)
          //   );
          // }
        }
      } else {
        const message = result.message || "";
        if (message) {
          ApiHelper.showErrorMessage(message, "Oops");
        }
        const errorCode = result.errorCode || "";
        if (errorCode == "not_found") {
          ApiHelper.gotoPage(context, {
            name: "SettingsEventGroups"
          });
        }
      }
    } else {
      formData.value.isLoading = false;
    }
  };

  const getPTypes = async (eventId: number) => {
    if (!eventId) return [];
    const result = await ApiHelper.callApi(
      "get",
      `/events/${eventId}/ptypes`,
      {},
      {}
    );
    return result;
  };

  const eventChange = async () => {
    // reset
    formData.value.controls.pTypes.options = [];
    formData.value.controls.pTypes.value = "";

    const eventId = parseInt(formData.value.controls.events.value);
    const result = await getPTypes(eventId);
    if (result.status == 1) {
      formData.value.controls.pTypes.options = (result.data.ptypes || []).map(
        (item: any) => ({
          id: item.value,
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          text: getPTypeText(item),
          data: item
        })
      );
    }
  };

  const getPTypeText = (item: any) => {
    let ret = item.text;
    if (item.capacity || item.capacityFemale || item.capacityMale) {
      const list = [];
      if (item.capacity != null) {
        list.push(`Capacity: ${item.capacity}`);
      }
      if (item.capacityFemale != null) {
        list.push(`F: ${item.capacityFemale}`);
      }
      if (item.capacityMale != null) {
        list.push(`M: ${item.capacityMale}`);
      }
      ret += ` (${list.join(", ")})`;
    }

    return ret;
  };

  const capacityChange = (item: any, type = "") => {
    if (type == "female" || type == "male") {
      const capacityFemale = isNaN(parseInt(item.capacityFemale.value))
        ? null
        : parseInt(item.capacityFemale.value);
      const capacityMale = isNaN(parseInt(item.capacityMale.value))
        ? null
        : parseInt(item.capacityMale.value);

      if (capacityFemale != null && capacityMale != null) {
        const capacityTotal = parseInt(
          (capacityFemale + capacityMale).toFixed(2)
        );
        item.capacityTotal.error = "";
        item.capacityTotal.value = capacityTotal;
      }
    }
  };

  const getPTypeHtml = (item: any) => {
    let ret =
      item.eventName && item.pTypeName
        ? `${item.eventName} / ${item.pTypeName}`
        : "";
    if (item.capacityTotal || item.capacityFemale || item.capacityMale) {
      const list = [];
      if (item.capacityTotal != null) {
        list.push(`Capacity: ${item.capacityTotal}`);
      }
      if (item.capacityFemale != null) {
        list.push(`F: ${item.capacityFemale}`);
      }
      if (item.capacityMale != null) {
        list.push(`M: ${item.capacityMale}`);
      }
      if (list.length) {
        ret += ` <small class='capacity-info'>(${list.join(", ")})</small>`;
      }
    }

    return ret;
  };

  const suggestEventPTypes = async (key: string, onFocus = false) => {
    if (!key) return;
    formData.value.foundEventPTypes = [];
    const selected = formData.value.eventPTypes.map((item: any) => {
      return item.eventId + "-" + item.pTypeId;
    });
    formData.value.isACILoading = true;
    const result = await ApiHelper.callApi(
      "get",
      "/events/eventPTypesSearch",
      {},
      {
        key,
        notInPTypeIds: selected.join(","),
        notInRegGroup: 1
      }
    );
    if (result.status == 1) {
      formData.value.foundEventPTypes = (result.data.items || []).map(
        (item: any) => ({
          // id: item.LINK_Event_Participant,
          html: getPTypeHtml(item),
          data: item
        })
      );
    }
    formData.value.isACILoading = false;
  };

  const selectEventPType = (selected: any) => {
    const data = selected.data;
    if ((data?.eventId || 0) == 0) return;

    formData.value.eventPTypes.push({
      eventId: data.eventId,
      eventName: data.eventName,
      pTypeId: data.pTypeId,
      pTypeName: data.pTypeName,
      capacityFemale: {
        error: "",
        value: ""
      },
      capacityMale: {
        error: "",
        value: ""
      },
      capacityTotal: {
        error: "",
        value: ""
      },
      pTypeCapacity: {
        capacityTotal: data.capacityTotal,
        capacityFemale: data.capacityFemale,
        capacityMale: data.capacityMale
      }
    });
  };

  const removeLink = async (link: any) => {
    // const confirm = await context.root.$swal({
    //   html: "Are you sure you want to delete this link?",
    //   showCancelButton: true,
    //   confirmButtonText: "Yes, do it!"
    // });
    // if (!confirm.isConfirmed) {
    //   return;
    // }

    formData.value.eventPTypes = formData.value.eventPTypes.filter(
      (item: any) =>
        !(item.eventId == link.eventId && item.pTypeId == link.pTypeId)
    );
  };

  // init data
  (async () => {
    await loadData();
  })();

  return {
    loadData,
    headerData,
    formData,
    getPTypes,
    eventChange,
    capacityChange,
    suggestEventPTypes,
    selectEventPType,
    getPTypeHtml,
    removeLink
  };
}
