import { render, staticRenderFns } from "./FormPhone.vue?vue&type=template&id=05a1d089&scoped=true&"
import script from "./FormPhone.vue?vue&type=script&lang=ts&"
export * from "./FormPhone.vue?vue&type=script&lang=ts&"
import style0 from "./FormPhone.vue?vue&type=style&index=0&id=05a1d089&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05a1d089",
  null
  
)

export default component.exports