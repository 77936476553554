var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}]},[_c('div',{staticClass:"tag-input"},[_vm._l((_vm.tags),function(tag,key){return _c('div',{key:'tag-item-' + key,staticClass:"tag-input__tag"},[_vm._v(" "+_vm._s(tag.text)+" "),_c('span',{staticClass:"btn-delete",on:{"click":function($event){return _vm.onRemove(tag)}}},[_vm._v("x")])])}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.key),expression:"key",modifiers:{"trim":true}}],class:[
        'tag-input__text',
        _vm.inputClass,
        {
          'input-loader': _vm.loading
        }
      ],attrs:{"type":"text","placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":"150"},domProps:{"value":(_vm.key)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.key=$event.target.value.trim()},_vm.onInput],"focusout":function($event){_vm.key = _vm.stripTags(_vm.key)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.selectLineItem($event)},"focus":_vm.onFocus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.onTab($event)},"blur":function($event){return _vm.$forceUpdate()}}})],2),(_vm.data && _vm.data.error !== undefined && _vm.data.error !== '' && _vm.showError)?_c('div',{staticClass:"error text-danger"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]):_vm._e(),_c('div',{staticClass:"autocomplete-input"},[(_vm.show && !_vm.loading && !_vm.isACILoading)?_c('div',{staticClass:"box_content",class:{
        allowAddNew: _vm.allowAddNew
      }},[(_vm.hint != '')?_c('div',{staticClass:"hint"},[_vm._v(_vm._s(_vm.hint))]):(_vm.options.length)?_c('VScroller',[_c('ul',{staticClass:"results-box",class:{ nowrap: _vm.nowrap },attrs:{"slot":"default"},slot:"default"},[_vm._l((_vm.options),function(item,index){return _c('li',{key:index,attrs:{"data-id":item.id,"title":_vm.showLineTitle ? item.text : ''},on:{"click":function($event){return _vm.onSelect(item)}}},[(item.html != undefined)?_c('div',{domProps:{"innerHTML":_vm._s(item.html)}}):_c('div',{domProps:{"innerHTML":_vm._s(item.text)}})])}),(_vm.endItemMessage != '')?_c('li',{staticClass:"end-custom-message"},[_vm._v(" "+_vm._s(_vm.endItemMessage)+" ")]):_vm._e()],2)]):_c('div',{staticClass:"noData2"},[_vm._v(" No Records Found ")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }