










import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "ApexDashboard",
  props: {
    barData: Object
  },
  setup(props, context) {
    const fullMonths = ref<any>({
      Jan: "January",
      Feb: "February",
      Mar: "March",
      Apr: "April",
      May: "May",
      Jun: "June",
      Jul: "July",
      Aug: "August",
      Sep: "September",
      Oct: "October",
      Nov: "November",
      Dec: "December"
    });
    const shortMonths = ref<string[]>([
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ]);

    const chartOptions = ref<any>({
      plotOptions: {
        bar: {
          columnWidth: "50%"
        }
      },

      chart: {
        height: "350px",
        defaultLocale: "en",
        type: "line",
        // stacked: true,
        events: {
          click: (event: any, chartContext: any, config: any) => {
            // nothing
          }
        },
        /*dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },*/
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000
          }
        },
        toolbar: { show: false },
        zoom: { enabled: false }
      },
      colors: ["#42c7c8", "#0ea1ff", "#666"],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        width: 3,
        dashArray: [0, 0]
        // dashArray: [7, 0, 7],
      },
      markers: {
        size: 6,
        fillOpacity: 0,
        hover: {
          size: 7
        }
      },
      xaxis: {
        categories: [], // this.categories(),
        position: "bottom",
        axisBorder: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        labels: {
          show: true,
          style: {
            colors: "#c1c1c1",
            fontSize: "11px",
            fontWeight: 600
          }
        },
        axisTicks: { show: false }
      },

      yaxis: {
        show: false,
        forceNiceScale: true,
        tickAmount: 10
        // min: {{setMin}},
        // max: {{setMax}}
      },
      // grid: { show: false },

      tooltip: {
        x: {
          show: true,
          formatter: (value: any) => {
            const selectedMonth = (
              chartOptions.value.xaxis.categories[value - 1] || ""
            ).split("-")[0];
            return fullMonths.value[selectedMonth];
          }
        },
        y: {
          formatter: function(value: any) {
            let formatedAmount = value;
            if (!isNaN(value)) {
              formatedAmount = ApiHelper.dollarFormat(value, false, {
                useBrackets: true
              });
            }
            return formatedAmount;
          }
        }
      },
      grid: {
        show: false,
        padding: {
          left: 30,
          right: 30
        }
      },
      legend: {
        show: true,
        horizontalAlign: "center",
        onItemClick: {
          toggleDataSeries: true
        },
        position: "top",
        markers: {
          width: 40,
          height: 15,
          radius: 0,
          strokeWidth: 1,
          strokeColor: ["#42c7c8", "#0ea1ff", "#666"]
        }
      }
    });

    const chartSeries = ref([]);

    const chartData = ref({
      categories: [],
      label: "",
      data: [],
      series: []
    });

    const loadData = () => {
      if (props.barData) {
        const barData = props.barData || {
          data: {
            labels: [],
            datasets: [
              {
                label: "",
                data: []
              }
            ]
          }
        };
        chartOptions.value.xaxis.categories = barData.data.labels.map(
          (label: any) => {
            const selectedMonth = label.split("/")[0];
            return (
              shortMonths.value[selectedMonth - 1] + "-" + label.split("/")[1]
            );
          }
        );
        chartSeries.value = barData.data.datasets.map((item: any) => {
          return {
            name: item.label,
            type: item.type,
            data: item.data
          };
        });
      }
    };

    loadData();

    return {
      fullMonths,
      shortMonths,
      chartOptions,
      chartSeries,
      chartData
    };
  }
});
