var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FamilyDetailsPage"},[_c('Header',{attrs:{"back-route":{ name: 'ProfilesFamily' },"filters":_vm.getFiltersData()}},[_c('h3',{staticClass:"head_heading__small",attrs:{"slot":"head_heading__small"},slot:"head_heading__small"},[(_vm.popupEditFamily.show)?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.popupEditFamily.controls.name.value),expression:"popupEditFamily.controls.name.value",modifiers:{"trim":true}}],domProps:{"value":(_vm.popupEditFamily.controls.name.value)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.popupEditFamily.actions.onSubmit()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.popupEditFamily.controls.name, "value", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',[_c('img',{staticClass:"ml-2",staticStyle:{"width":"16px"},attrs:{"src":require("../../assets/images/remove.png"),"alt":"Close"},on:{"click":function($event){_vm.popupEditFamily.show = false}}})])]):_c('span',[_vm._v(" "+_vm._s(_vm.headerData.subTitle || "")+" "),(_vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson))?_c('img',{staticClass:"ml-2",attrs:{"src":require("../../assets/images/icon/icon-edit.png"),"alt":"Edit"},on:{"click":function () {
              _vm.popupEditFamily.controls.name.value = _vm.headerData.subTitle;
              _vm.popupEditFamily.show = true;
            }}}):_vm._e()])]),_c('h2',{staticClass:"head_heading head_heading__other",attrs:{"slot":"head_heading__other"},slot:"head_heading__other"},[_vm._v(" "+_vm._s(_vm.headerData.title || "")+" "),(_vm.pageData.pager.total > 0)?_c('span',{staticClass:"heading__number"},[_vm._v(" "+_vm._s(_vm.pageData.pager.total)+" ")]):_vm._e(),(_vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson))?_c('a',{staticClass:"addNewItem",on:{"click":function($event){_vm.popupNewItem.show = true;
          _vm.popupNewItem.formData.controls.type.value = '';
          _vm.popupNewItem.formData.controls.type.error = '';
          _vm.popupNewItem.formData.controls.profile.value = '';
          _vm.popupNewItem.formData.controls.profile.error = '';}}},[_c('img',{attrs:{"src":require("../../assets/images/icon-part.png"),"alt":"Icon"}}),_c('span',[_vm._v("Associate Member")])]):_vm._e()]),_c('div',{staticClass:"pb-1",attrs:{"slot":"head__nav"},slot:"head__nav"},[_c('NavFamilyDetails')],1)]),_c('div',{staticClass:"content-inside"},[_c('div',{staticClass:"content__table__scroll pt60"},[_c('div',{staticClass:"content_table table__items"},[_c('div',{staticClass:"table__head"},[_c('div',{staticClass:"items row"},[_c('div',{staticClass:"item col col-3"},[_c('FilterSearchColumn',{attrs:{"label":'People',"active-tab":_vm.pageData.activeTab,"active-value":'profile',"data":{
                  options: _vm.pageData.arrProfiles
                },"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.filter.profile,"on-change-value":_vm.updateFilterValue,"sort-data":_vm.pageData.sort,"sort-key":'1',"on-change-sort-value":_vm.updateSortValue}})],1),_c('div',{staticClass:"item col col-2 table__center has__arrow"},[_c('FilterNumberRangeColumn',{attrs:{"label":'Age',"placeholder":'Age',"active-tab":_vm.pageData.activeTab,"active-value":'age',"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.filter.age,"on-change-value":_vm.updateFilterValue,"data":{},"sort-data":_vm.pageData.sort,"sort-key":'2',"on-change-sort-value":_vm.updateSortValue}})],1),_c('div',{staticClass:"item col col-2 has__arrow table__center"},[_c('FilterCheckboxesColumn',{attrs:{"label":'Gender',"active-tab":_vm.pageData.activeTab,"active-value":'gender',"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.filter.gender,"close-filter-tab":_vm.closeFilterTab,"on-select-all":_vm.selectAllGenders,"on-reset-value":_vm.resetGenders,"on-change-value":function () {
                    _vm.gotoPage(1);
                  },"sort-data":_vm.pageData.sort,"sort-key":'3',"on-change-sort-value":_vm.updateSortValue}})],1),_c('div',{staticClass:"item col col-2 table__center has__arrow"},[_c('FilterCheckboxesColumn',{attrs:{"label":'Events (Total / Active)',"active-tab":_vm.pageData.activeTab,"active-value":'events',"set-active-filter-tab":_vm.setActiveFilterTab,"options":_vm.pageData.filter.events,"close-filter-tab":_vm.closeFilterTab,"on-select-all":_vm.selectAllEvents,"on-reset-value":_vm.resetEvents,"on-change-value":function () {
                    _vm.gotoPage(1);
                  },"sort-data":_vm.pageData.sort,"sort-key":'4',"on-change-sort-value":_vm.updateSortValue}})],1),_c('div',{staticClass:"item col col-2 table__center"},[_c('FilterNumberRangeColumn',{attrs:{"label":'Balance',"placeholder":'Balance',"active-tab":_vm.pageData.activeTab,"active-value":'balance',"set-active-filter-tab":_vm.setActiveFilterTab,"close-filter-tab":_vm.closeFilterTab,"model":_vm.pageData.filter.balance,"on-change-value":_vm.updateFilterValue,"data":{},"sort-data":_vm.pageData.sort,"sort-key":'5',"on-change-sort-value":_vm.updateSortValue,"allow-negative-number":true}})],1),_vm._m(0)])]),_c('div',{staticClass:"table__body",class:{
            'no-stretch': _vm.pageData.items.length < 6,
            checked: _vm.getCheckedItems().length > 0
          }},[(!_vm.pageData.isLoading && _vm.pageData.items.length < 1)?_c('div',{staticClass:"noData"},[_vm._v(" Nothing to see here quite yet...zero members. ")]):_vm._e(),_vm._l((_vm.pageData.items),function(item,index){return _c('div',{key:index,staticClass:"items row",class:{ checked: item.isChecked }},[_c('router-link',{staticClass:"item col col-3 has__avatar text-link",attrs:{"to":{
                name: 'ProfileDetails',
                params: {
                  profileId: item.id.toString()
                }
              }}},[_c('div',{staticClass:"item__avatar"},[_c('Avatar',{attrs:{"src":item.logo,"username":item.fullName || '-',"size":31}})],1),_c('span',[_vm._v(" "+_vm._s(item.fullName || "-")+" "),(item.type != '')?_c('div',[_c('small',[_vm._v(_vm._s(item.type))])]):_vm._e()])]),_c('div',{staticClass:"item col col-2 table__center"},[_c('span',[_vm._v(_vm._s(item.age || "N/A"))])]),_c('div',{staticClass:"item col col-2 table__center"},[_c('span',[_vm._v(_vm._s(item.gender || "-"))])]),_c('div',{staticClass:"item col col-2 table__center"},[_c('span',[(item.totalEvents)?_c('router-link',{staticClass:"text-link",attrs:{"to":{
                    name: 'ProfileEvents',
                    params: {
                      profileId: item.id
                    }
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('u',[_vm._v(_vm._s(item.totalEvents || 0)+" Total")])])]}}],null,true)}):_vm._e(),(!item.totalEvents)?_c('u',[_vm._v(_vm._s(item.totalEvents || 0)+" Total")]):_vm._e(),_vm._v(" / "),(item.activeEvents)?_c('router-link',{staticClass:"text-link",attrs:{"to":{
                    name: 'ProfileEvents',
                    params: {
                      profileId: item.id
                    }
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('u',[_vm._v(_vm._s(item.activeEvents || 0)+" Active")])])]}}],null,true)}):_vm._e(),(!item.activeEvents)?_c('u',[_vm._v(_vm._s(item.activeEvents || 0)+" Active")]):_vm._e()],1)]),_c('div',{staticClass:"item col col-2 table__center"},[_c('span',{class:{ 'text-danger': item.balance < 0 }},[_vm._v(" "+_vm._s(item.balanceFormatted || "-")+" ")])]),_c('div',{staticClass:"item col col-1 table__center"},[(
                  item.isParent == 0 &&
                    _vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson)
                )?_c('a',{staticClass:"remove-link mr-2",attrs:{"href":"javascript:void(0)","title":"Edit"},on:{"click":function($event){return _vm.openEditPopup(item)}}},[_c('img',{attrs:{"src":require("../../assets/images/icon/icon-edit.png"),"alt":"Edit"}})]):_vm._e(),(
                  item.isParent == 0 &&
                    _vm.$allowedFunctions.includes(_vm.$userFunctions.EditPerson)
                )?_c('a',{staticClass:"remove-link",attrs:{"href":"javascript:void(0)","title":"Remove"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('img',{attrs:{"src":require("../../assets/images/delete.png"),"alt":"Remove"}})]):_vm._e()])],1)})],2)])]),_c('Pager',{attrs:{"goto-page":_vm.gotoPage,"on-click-next":_vm.onClickNext,"page-data":_vm.pageData,"on-click-prev":_vm.onClickPrev,"toggle-pager-items":_vm.togglePagerItems}})],1),(_vm.popupNewItem.show)?_c('Modal',{attrs:{"title":"Associate member","on-close":function () {
        _vm.popupNewItem.show = false;
      }}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('AutoCompleteInput',{attrs:{"placeholder":'Type a person name...',"options":_vm.popupNewItem.formData.controls.profile.options,"data":_vm.popupNewItem.formData.controls.profile,"isACILoading":_vm.isACILoading || false},on:{"input":function (key) { return _vm.searchProfiles(key); },"select":_vm.selectProfile}}),(_vm.popupNewItem.formData.controls.profile.value === '0')?_c('FormItem',{attrs:{"data":_vm.popupNewItem.formData.controls.email}}):_vm._e(),(_vm.popupNewItem.formData.controls.profile.value === '0')?_c('FormItem',{attrs:{"data":_vm.popupNewItem.formData.controls.firstName}}):_vm._e(),(_vm.popupNewItem.formData.controls.profile.value === '0')?_c('FormItem',{attrs:{"data":_vm.popupNewItem.formData.controls.lastName}}):_vm._e(),_c('FormItem',{attrs:{"data":_vm.popupNewItem.formData.controls.type}})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('FormButton',{attrs:{"is-processing":_vm.popupNewItem.isProcessing,"on-submit":function () {
            _vm.popupNewItem.onSubmit(_vm.$parent);
          },"label":"Submit","type":"primary"}}),_c('FormButton',{attrs:{"on-submit":function () {
            _vm.popupNewItem.show = false;
          },"label":"Cancel","type":"danger"}})],1)]):_vm._e(),(_vm.popupEditTribe.show)?_c('Modal',{attrs:{"title":"Edit relation type","on-close":function () {
        _vm.popupEditTribe.show = false;
      }}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('FormInput',{attrs:{"data":_vm.popupEditTribe.controls.name}}),_c('FormSelect',{attrs:{"data":_vm.popupEditTribe.controls.type}})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('FormButton',{attrs:{"on-submit":_vm.popupEditTribe.actions.onSubmit,"label":"Submit","type":"primary"}}),_c('FormButton',{attrs:{"on-submit":function () {
            _vm.popupEditTribe.show = false;
          },"label":"Cancel","type":"danger"}})],1)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item col col-1 table__center item__none__icon"},[_c('div',{staticClass:"item__filter"},[_c('div',{staticClass:"filter__gray"})])])}]

export { render, staticRenderFns }