import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormSelect, FormText } from "@/types";

export function useSettingsTagTypeEditStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const headerData = ref({
    title: selectedId > 0 ? "" : "Add Tag Type",
    subTitle: "Tag Types"
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      name: FormText;
      typeUse: FormSelect;
    };
    validateAll: any;
    actions: {
      onSubmit: any;
    };
  }>({
    isLoading: true,
    controls: {
      name: {
        required: true,
        label: "Name",
        placeholder: "Name",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100
      },
      typeUse: {
        required: true,
        label: "Type Use",
        placeholder: "Select Use",
        style: "custom",
        value: "",
        error: "",
        options: [
          // {
          //   id: "finance",
          //   text: "Finance"
          // },
          {
            id: "profile",
            text: "Profile"
          },
          {
            id: "search",
            text: "Search"
          }
        ]
      }
    },
    validateAll: () => {
      let hasError = false;
      if (formData.value.controls.name.value === "") {
        formData.value.controls.name.error = "Name is required";
        hasError = true;
      }
      if (formData.value.controls.typeUse.value === "") {
        formData.value.controls.typeUse.error = "Type is required";
        hasError = true;
      }
      return hasError;
    },
    actions: {
      onSubmit: async () => {
        const hasError = formData.value.validateAll();
        if (hasError) {
          return;
        }
        const result = await ApiHelper.callApi(
          "put",
          "/tags/types/" + selectedId + "",
          {
            name: formData.value.controls.name.value,
            typeUse: formData.value.controls.typeUse.value
          },
          {}
        );
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
        ApiHelper.showSuccessMessage(selectedId ? "Updated" : "Added");
        ApiHelper.gotoPage(context, {
          name: "SettingsTagTypes"
        });
      }
    }
  });

  const pageData = ref<{
    isLoading: boolean;
  }>({
    isLoading: true
  });

  const loadData = async () => {
    if (selectedId > 0) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/tags/types/" + selectedId,
        {},
        {}
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const resultData = result.data || null;
        if (resultData) {
          headerData.value.title = resultData.typeName || "";
          headerData.value.subTitle = "Tags";
          formData.value.controls.name.value = resultData.typeName || "";
          formData.value.controls.typeUse.value = resultData.typeUse || "";
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "SettingsTagTypes" });
      }
    } else {
      ApiHelper.setDataLoading(false);
    }
  };

  return {
    loadData,
    headerData,
    pageData,
    formData
  };
}
