














































import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "NavFamilyDetails",
  props: {},
  components: {},
  setup(props) {
    return {};
  }
});
