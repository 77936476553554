



















import { ref, defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import ComingSoon from "@/components/Common/ComingSoon.vue";

type PageData = {
  isLoading: boolean;
  pageTitle: string;
};

export default defineComponent({
  name: "EventTransportationPage",
  components: {
    ComingSoon,
    HeaderEventDetails,
    Loading
  },
  setup(props, context) {
    const pageData = ref<PageData>({
      pageTitle: "",
      isLoading: false
    });
    const getEventNameFromSidebar = ($ref: any) => {
      const SidebarEventDetailsRef = $ref || {};
      const sidebarData = SidebarEventDetailsRef.sidebarData || {};
      return sidebarData.name || "";
    };

    return {
      getEventNameFromSidebar,
      pageData
    };
  }
});
