import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormText, SelectOption } from "@/types";
import FormSelect from "@/types/FormSelect";
import FormTextarea from "@/types/FormTextarea";
import { ProfileListRows } from "@/helpers/estimateNoOfListRows";

type Profile = {
  id: number;
  isChecked: boolean;
  logo: string;
  firstName: string;
  lastName: string;
  age: string;
  gender: string;
  balance: string;
  events: string;
};

type PagerItem = {
  label: string;
  value: number;
  active: boolean;
};

export function useProfilesFamilyStore(context: any) {
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: Profile[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      family: string;
      people: string;
      events: SelectOption[];
      unassignedEvent: any;
      score: string;
      balance: string;
      minBalance: string;
      maxBalance: string;
      minScore: string;
      maxScore: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    searchEventsValue: {
      value: string;
    };
    searchEvents: Function;
    searchEventsLoading: boolean;
  }>({
    isLoading: true,
    skip: 0,
    take: ProfileListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {
      family: context.root.$route.query.family || "",
      people: context.root.$route.query.people || "",
      events: [],
      unassignedEvent: null,
      score:
        (context.root.$route.query.minScore || "") +
        "-" +
        (context.root.$route.query.maxScore || ""),
      balance:
        (context.root.$route.query.minBalance || "") +
        "@" +
        (context.root.$route.query.maxBalance || ""),
      minBalance: context.root.$route.query.minBalance || "",
      maxBalance: context.root.$route.query.maxBalance || "",
      minScore: context.root.$route.query.minScore || "",
      maxScore: context.root.$route.query.maxScore || ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    searchEventsLoading: false,
    searchEventsValue: {
      value: ""
    },
    searchEvents: async (searchValue: string) => {
      pageData.value.searchEventsLoading = true;
      const eventIds = (context.root.$route.query.events || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1
        });
        const noEvent = {
          id: 0,
          text: "UN-ASSIGNED TO EVENTS"
        };
        pageData.value.filter.events.unshift(noEvent);
        // pageData.value.filter.unassignedEvent = {
        //   id: "0",
        //   text: "UN-ASSIGNED TO EVENTS",
        //   selected: eventIds.includes(0)
        // };
      } else {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue
        });
        // if (eventIds.includes(0)) {
        //   pageData.value.filter.unassignedEvent = {
        //     id: "0",
        //     text: "UN-ASSIGNED TO EVENTS",
        //     selected: eventIds.includes(0)
        //   };
        // } else {
        pageData.value.filter.unassignedEvent = null;
        // }
      }
      pageData.value.filter.events = pageData.value.filter.events.map(item => ({
        ...item,
        selected: eventIds.includes(item.id)
      }));
      pageData.value.searchEventsLoading = false;
    }
  });

  const updateRouters = () => {
    const eventOptions = [];
    if (pageData.value.filter.unassignedEvent) {
      eventOptions.push(pageData.value.filter.unassignedEvent);
    }
    pageData.value.filter.events.map((item: any) => {
      eventOptions.push(item);
    });
    context.root.$router
      .replace({
        name: "ProfilesFamily",
        query: {
          page: pageData.value.pager.page + "" || undefined,
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          family: pageData.value.filter.family || undefined,
          people: pageData.value.filter.people || undefined,
          events:
            ApiHelper.convertSelectedOptionsToString(eventOptions) || undefined,
          searchEvents: pageData.value.searchEventsValue.value || undefined,
          minBalance: pageData.value.filter.minBalance || undefined,
          maxBalance: pageData.value.filter.maxBalance || undefined,
          minScore: pageData.value.filter.minScore || undefined,
          maxScore: pageData.value.filter.maxScore || undefined
        }
      })
      .catch(() => {
        // nothing
      });
  };

  const loadList = () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;

    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/families",
        {},
        {
          skip: pageData.value.skip,
          take: pageData.value.take,
          order: parseInt(pageData.value.sort.order),
          direction: parseInt(pageData.value.sort.direction),
          key: pageData.value.filter.family,
          people: pageData.value.filter.people,
          event: ApiHelper.convertSelectedOptionsToString(
            pageData.value.filter.events
          ),
          minBalance: pageData.value.filter.minBalance || "",
          maxBalance: pageData.value.filter.maxBalance || "",
          minScore: "",
          maxScore: ""
        }
      );
      if (result.status === -1) {
        ApiHelper.gotoPage(context, { name: "Login" });
      } else if (result.status !== 1) {
        ApiHelper.showErrorMessage(result.message);
        return;
      }
      const totalCount = result.data.totalCount || 0;
      pageData.value.pager.totalPages = Math.ceil(
        totalCount / pageData.value.take
      );
      pageData.value.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      pageData.value.items = result.data.families.map((item: any) => ({
        ...item,
        balanceFormatted: ApiHelper.dollarFormat(item.balance || 0, false, {
          useBrackets: true
        })
      }));
      ApiHelper.setDataLoading(false);
      pageData.value.isLoading = false;
    })();
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };

  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = event.target.value;
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const toggleHeadActions = () => {
    pageData.value.showHeadActions = !pageData.value.showHeadActions;
  };

  const hideHeadActions = () => {
    pageData.value.showHeadActions = false;
  };

  const getCheckedItems = () => {
    return pageData.value.items.filter(item => item.isChecked);
  };

  // filter functions
  const updateFilterValue = (name: string, value: string) => {
    let arr;
    switch (name) {
      case "family":
        pageData.value.filter.family = value;
        break;
      case "people":
        pageData.value.filter.people = value;
        break;
      case "score":
        pageData.value.filter.score = value;
        arr = value.split("-");
        pageData.value.filter.minScore = arr[0];
        pageData.value.filter.maxScore = arr[1] !== undefined ? arr[1] : "";
        break;
      case "balance": {
        pageData.value.filter.balance = value;
        arr = value.split("@");
        pageData.value.filter.minBalance = arr[0];
        pageData.value.filter.maxBalance = arr[1] !== undefined ? arr[1] : "";
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.family !== "") {
      filters.push({
        label: "FAMILY",
        key: pageData.value.filter.family + Math.random(),
        value: pageData.value.filter.family,
        reset: () => {
          pageData.value.filter.family = "";
          updateRouters();
        }
      });
    }
    if (pageData.value.filter.people !== "") {
      filters.push({
        label: "PEOPLE",
        key: pageData.value.filter.people + Math.random(),
        value: pageData.value.filter.people,
        reset: () => {
          pageData.value.filter.people = "";
          updateRouters();
        }
      });
    }
    const selectedEvents: any[] = [];
    if (
      pageData.value.filter.unassignedEvent &&
      pageData.value.filter.unassignedEvent.selected
    ) {
      selectedEvents.push(pageData.value.filter.unassignedEvent.text);
    }
    pageData.value.filter.events
      .filter((item: any) => item.selected)
      .map((item: any) => {
        selectedEvents.push(item.text);
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.filter.events.map((item: any) => {
            item.selected = false;
          });
          // pageData.value.filter.unassignedEvent.selected = false;
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    if (
      pageData.value.filter.minScore !== "" ||
      pageData.value.filter.maxScore !== ""
    ) {
      filters.push({
        label: "SCORE",
        key: pageData.value.filter.score + Math.random(),
        value: pageData.value.filter.score,
        reset: () => {
          pageData.value.filter.score = "";
          pageData.value.filter.minScore = "";
          pageData.value.filter.maxScore = "";
          updateRouters();
        }
      });
    }

    if (
      pageData.value.filter.minBalance !== "" ||
      pageData.value.filter.maxBalance !== ""
    ) {
      const displayValue =
        (pageData.value.filter.minBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minBalance, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (pageData.value.filter.maxBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxBalance, 10),
              false,
              { useBrackets: true }
            )
          : "");
      filters.push({
        label: "BALANCE",
        key: pageData.value.filter.balance + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.balance = "";
          pageData.value.filter.minBalance = "";
          pageData.value.filter.maxBalance = "";
          updateRouters();
        }
      });
    }
    return {
      list: filters
    };
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }

    if (pageData.value.activeTab == "events") {
      if (pageData.value.filter.events.length === 0) {
        await pageData.value.searchEvents(
          pageData.value.searchEventsValue.value
        );
      }
    }
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  // filters
  const selectAllEvents = () => {
    pageData.value.filter.events.map((item: any) => {
      item.selected = true;
    });
    updateRouters();
  };

  const resetEvents = () => {
    // pageData.value.filter.unassignedEvent.selected = false;
    pageData.value.filter.events.map((item: any) => {
      item.selected = false;
    });
    updateRouters();
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    loadData(1);
  };

  // init data
  (async () => {
    loadData(1);
  })();

  const selectedProfiles = ref<any[]>([]);
  const selectedTribeProfiles = ref<any[]>([]);
  const familyTypeOptions = ref<any[]>([]);
  const tribeData = ref({
    family: 0,
    oldFamilies: "",
    name: "",
    participants: ""
  });

  const resetTribeData = (formData: any) => {
    tribeData.value.name = "";
    formData.controls.name.error = "";
    formData.controls.name.value = "";
  };

  const syncTribeData = (formData: any) => {
    tribeData.value.family = parseInt(formData.controls.family.value) || 0;
    tribeData.value.name = formData.controls.name.value;
    tribeData.value.participants = selectedTribeProfiles.value
      .map(item => {
        return item.id + ":" + (item.type || 0);
      })
      .join(",");
  };

  (async () => {
    familyTypeOptions.value = await ApiHelper.getFamilyTypeOptions();
  })();

  (async () => {
    pageData.value.searchEventsValue.value =
      context.root.$route.query.searchEvents || "";
    if (context.root.$route.query.events) {
      await pageData.value.searchEvents(pageData.value.searchEventsValue.value);
    }
  })();

  const formNewTribeData = ref<{
    isLoading: boolean;
    controls: {
      family: FormSelect;
      name: FormText;
      participants: FormTextarea;
    };
  }>({
    isLoading: true,
    controls: {
      family: {
        name: "pmTribeProfile",
        error: "",
        label: "Family",
        placeholder: "Select Family",
        type: "select",
        options: [],
        value: ""
      },
      name: {
        name: "pmTribeName",
        error: "",
        label: "Tribe/Family Name",
        placeholder: "Enter Name",
        type: "text",
        value: ""
      },
      participants: {
        name: "pmTribeParticipants",
        error: "",
        label: "Participants",
        placeholder: "Enter Participants",
        type: "textarea",
        value: ""
      }
    }
  });

  const popupNewTribe = ref<{
    show: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
  }>({
    show: false,
    formData: formNewTribeData,
    removeFieldError: async () => {
      // nothing
    },
    onSubmit: async () => {
      let hasError = false;
      syncTribeData(formNewTribeData.value);
      if (tribeData.value.family === 0) {
        hasError = true;
        formNewTribeData.value.controls.family.error = "Person is required!";
      } else {
        formNewTribeData.value.controls.family.error = "";
      }
      if (tribeData.value.name === "") {
        hasError = true;
        formNewTribeData.value.controls.name.error = "Name is required!";
      } else {
        formNewTribeData.value.controls.name.error = "";
      }
      if (!hasError) {
        const result = await ApiHelper.callApi(
          "post",
          "/families/merge",
          tribeData.value,
          {}
        );
        if (result.status == 1) {
          popupNewTribe.value.show = false;
          resetTribeData(formNewTribeData.value);
          loadData(1);
          ApiHelper.showSuccessMessage("Saved");
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      }
    }
  });

  const updateSelectedProfiles = () => {
    formNewTribeData.value.controls.family.value = "";
    formNewTribeData.value.controls.name.value = "";
    selectedProfiles.value = getCheckedItems();
    selectedTribeProfiles.value = [];
  };
  const updateSelectedParticipants = () => {
    const profiles: any[] = [];

    const otherFamilies = selectedProfiles.value.filter(
      (family: any) =>
        family.familyId.toString() !==
        formNewTribeData.value.controls.family.value
    );
    tribeData.value.oldFamilies = otherFamilies
      .map((item: any) => {
        return item.familyId;
      })
      .join(",");
    selectedProfiles.value.map((family: any) => {
      if (
        family.familyId.toString() ===
        formNewTribeData.value.controls.family.value
      ) {
        formNewTribeData.value.controls.name.value = family.familyName;
      }
      family.profiles.map((profile: any) => {
        profiles.push({ ...profile, type: profile.relationTypeId || "" });
      });
    });
    selectedTribeProfiles.value = profiles.filter(
      (v, i, a) => a.findIndex(t => t.id === v.id) === i
    );
  };
  return {
    // sort
    updateSortValue,
    pageData,
    // popups
    popupNewTribe,
    selectedProfiles,
    selectedTribeProfiles,
    updateSelectedProfiles,
    familyTypeOptions,
    updateSelectedParticipants,
    // filters
    updateFilterValue,
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    selectAllEvents,
    resetEvents,
    // pager
    updateRouters,
    loadList,
    gotoPage,
    getCheckedItems,
    togglePagerItems,
    toggleHeadActions,
    hideHeadActions,
    onClickPrev,
    onClickNext,
    onPagerChange
  };
}
