import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { FormSelect, FormText, FormTextarea } from "@/types";
import FormToggle from "@/types/FormToogle";

export function useSettingsTagEditStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const headerData = ref({
    title: selectedId > 0 ? "" : "Add Tag",
    subTitle: "Tags"
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      name: FormText;
      type: FormSelect;
      active: FormToggle;
      // tagCode: FormText;
      tagDesc: FormText;
    };
    validateAll: any;
    actions: {
      onSubmit: any;
    };
  }>({
    isLoading: true,
    controls: {
      name: {
        required: true,
        label: "Tag Code",
        placeholder: "Tag Code",
        style: "custom",
        value: "",
        error: "",
        maxlength: 100
      },
      type: {
        required: true,
        label: "Type",
        placeholder: "Select Type",
        style: "custom",
        value: "",
        error: "",
        options: []
      },
      active: {
        error: "",
        label: "Active",
        value: false,
        labelOn: "Yes",
        labelOff: "No"
      },
      // tagCode: {
      //   required: true,
      //   label: "Tag Code",
      //   placeholder: "Tag Code",
      //   style: "custom",
      //   value: "",
      //   error: "",
      //   maxlength: 50
      // },
      tagDesc: {
        error: "",
        label: "Tag Description",
        placeholder: "Tag Description",
        type: "text",
        value: "",
        style: "custom",
        maxlength: 250
      }
    },
    validateAll: () => {
      let hasError = false;
      if (formData.value.controls.name.value === "") {
        formData.value.controls.name.error = "Tag code is required";
        hasError = true;
      }
      if (formData.value.controls.type.value === "") {
        formData.value.controls.type.error = "Type is required";
        hasError = true;
      }
      // if (
      //   formData.value.controls.tagCode.required &&
      //   formData.value.controls.tagCode.value == ""
      // ) {
      //   formData.value.controls.tagCode.error = "Tag code is required";
      //   hasError = true;
      // }
      return hasError;
    },
    actions: {
      onSubmit: async () => {
        const hasError = formData.value.validateAll();
        if (hasError) {
          return;
        }
        const result = await ApiHelper.callApi(
          "put",
          "/tags/" + selectedId + "",
          {
            name: formData.value.controls.name.value,
            type: formData.value.controls.type.value || 0,
            active: formData.value.controls.active.value ? 1 : 0,
            // tagCode: formData.value.controls.tagCode.value,
            tagDesc: formData.value.controls.tagDesc.value
          },
          {}
        );
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
        ApiHelper.showSuccessMessage(selectedId ? "Updated" : "Added");
        ApiHelper.gotoPage(context, {
          name: "SettingsTags"
        });
      }
    }
  });

  const pageData = ref<{
    isLoading: boolean;
  }>({
    isLoading: true
  });

  const loadData = async () => {
    if (selectedId > 0) {
      ApiHelper.setDataLoading(true);
      const result = await ApiHelper.callApi(
        "get",
        "/tags/" + selectedId,
        {},
        {}
      );
      ApiHelper.setDataLoading(false);
      if (result.status === 1) {
        const resultData = result.data || null;
        if (resultData) {
          headerData.value.title = resultData.tagName || "";
          headerData.value.subTitle = "Tags";
          formData.value.controls.name.value = resultData.tagName || "";
          formData.value.controls.type.value = resultData.typeId || "";
          formData.value.controls.active.value = !!resultData.isActive;
          // formData.value.controls.tagCode.value = resultData.tagCode || "";
          formData.value.controls.tagDesc.value = resultData.tagDesc || "";
        }
      } else {
        ApiHelper.showErrorMessage(result.message, "Oops");
        ApiHelper.gotoPage(context, { name: "SettingsTags" });
      }
    } else {
      ApiHelper.setDataLoading(false);
    }
  };

  // init data
  (async () => {
    loadData();
    const result = await ApiHelper.callApi(
      "get",
      "/tags/types",
      {},
      { status: "1" }
    );
    if (result.status === 1) {
      formData.value.controls.type.options = result.data.types.map(
        (item: any) => {
          return {
            id: item.typeId,
            text: item.typeName
          };
        }
      );
    }
  })();

  return {
    loadData,
    headerData,
    pageData,
    formData
  };
}
