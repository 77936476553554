











import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BackButton",
  props: {
    route: {
      type: Object,
      default: () => ({
        name: ""
      })
    },
    absolutePosition: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {};
  }
});
