
































































































import { defineComponent } from "@vue/composition-api";
import { useUserResetPasswordStore } from "@/stores/User/UserResetPasswordStore";
import FormItem from "@/components/Form/FormItem.vue";
import FormButton from "@/components/Form/FormButton.vue";
import Loading from "@/components/Common/Loading.vue";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "UserResetPasswordPage",
  components: {
    Loading,
    FormItem,
    FormButton
  },
  setup(props, context) {
    const { pageData, formData } = useUserResetPasswordStore(context);
    return {
      random: ApiHelper.randomFormId(),
      pageData,
      formData
    };
  }
});
