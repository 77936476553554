import { render, staticRenderFns } from "./Profiles.vue?vue&type=template&id=3ace0705&scoped=true&xmlns%3AhasRightPanel=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./Profiles.vue?vue&type=script&lang=ts&"
export * from "./Profiles.vue?vue&type=script&lang=ts&"
import style0 from "./Profiles.vue?vue&type=style&index=0&id=3ace0705&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ace0705",
  null
  
)

export default component.exports