



































































































import { ApiHelper } from "@/helpers";
import { FormText } from "@/types";
import { defineComponent, ref } from "@vue/composition-api";
import VScroller from "@/components/Common/VScroller.vue";
import FormInput from "@/components/Form/FormInput.vue";

export default defineComponent({
  name: "EventSync",
  props: {
    eventId: {
      type: Number,
      default: 0
    }
  },
  components: { VScroller, FormInput },
  setup(props, context) {
    const pageData = ref<{
      isLoading: boolean;
      activeSync: string;
      syncKey: string;
      controls: {
        syncName: FormText;
      };
      syncTimer: any;
      syncsList: any;
      selectedName: string;
    }>({
      isLoading: false,
      activeSync: "search",
      syncKey: "",
      controls: {
        syncName: {
          required: true,
          label: "Name your EventSync",
          placeholder: "Name your EventSync",
          style: "custom",
          value: "",
          error: "",
          maxlength: 50
        }
      },
      syncTimer: null,
      syncsList: [],
      selectedName: ""
    });

    const showSyncPanel = (type: string) => {
      pageData.value.activeSync = type;
      if (type == "add") {
        pageData.value.controls.syncName.value = "";
      }
    };

    const addSync = async () => {
      const syncName = pageData.value.controls.syncName.value || "";
      if (!syncName) return;

      pageData.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "post",
        "/entities/syncs",
        {
          syncName
        },
        {}
      );
      pageData.value.isLoading = false;
      if (result.status !== 1) {
        ApiHelper.showErrorMessage(result.message, "Oops");
        return false;
      }
      pageData.value.activeSync = "search";
      ApiHelper.showSuccessMessage("Added");
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await getSyncsList();
    };

    const getSyncsList = async () => {
      pageData.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "get",
        "/entities/syncs",
        {},
        {
          key: pageData.value.syncKey
        }
      );
      pageData.value.isLoading = false;
      if (result.status == 1) {
        pageData.value.syncsList = result.data.items || [];
        const selected = pageData.value.syncsList.find((item: any) =>
          (item.eventIds || "").split(",").includes(`${props.eventId}`)
        );
        if (selected) {
          pageData.value.selectedName = selected.syncName;
        }
      } else if (result.message) {
        ApiHelper.showErrorMessage(result.message, "Oops");
      }
    };

    const syncSearch = () => {
      if (pageData.value.syncTimer) {
        clearTimeout(pageData.value.syncTimer);
      }
      pageData.value.syncTimer = setTimeout(async () => {
        await getSyncsList();
      }, 300);
    };

    const setSyncItem = async (type: string, item: any) => {
      if (type == "discount") {
        const syncName =
          pageData.value.selectedName == item.syncName ? "" : item.syncName;
        pageData.value.selectedName = syncName;

        // call api to set this setting
        pageData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "post",
          "/events/syncLinks",
          {
            eventId: props.eventId,
            syncId: item.syncId,
            syncName,
            syncDiscounts: 1
          },
          {}
        );
        context.emit("resetSync", syncName == "" ? 0 : 1);
        pageData.value.isLoading = false;
      }
    };

    const onClickOutside = () => {
      context.emit("onClickOutside");
    };

    const isSelected = (index: number) => {
      const item = pageData.value.syncsList[index];
      if ((item.syncDiscount || 0) == 1) {
        return true;
      }

      return false;
    };

    // init data
    (async () => {
      await getSyncsList();
    })();

    return {
      onClickOutside,
      pageData,
      showSyncPanel,
      addSync,
      syncSearch,
      setSyncItem,
      isSelected
    };
  }
});
