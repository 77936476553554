












import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "ApexDashboard2",
  props: {
    barData: Object
  },
  setup(props, context) {
    const fullMonths = ref<any>({
      Jan: "January",
      Feb: "February",
      Mar: "March",
      Apr: "April",
      May: "May",
      Jun: "June",
      Jul: "July",
      Aug: "August",
      Sep: "September",
      Oct: "October",
      Nov: "November",
      Dec: "December"
    });
    const shortMonths = ref<string[]>([
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ]);

    const chartOptions = ref<any>({
      chart: {
        height: 300,
        defaultLocale: "en",
        type: "line",
        // stacked: true,
        events: {
          click: (event: any, chartContext: any, config: any) => {
            // nothing
          }
        },
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000
          }
        },
        toolbar: { show: false },
        zoom: { enabled: false }
      },
      dataLabels: {
        enabled: false
      },
      forecastDataPoints: {
        count: 3,
        dashArray: 7
      },
      stroke: {
        width: [10, 4],
        curve: "smooth",
        dashArray: [0, 7]
      },
      colors: ["#3F5447", "#D8D8D8"],
      fill: {
        type: ["gradient", "solid"],
        gradient: {
          shade: "dark",
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
          colorStops: [
            {
              offset: 0,
              color: "#A4CEB4",
              opacity: 1
            },
            {
              offset: 73,
              color: "#3F5447",
              opacity: 1
            },
            {
              offset: 73,
              color: "#BBBBBB",
              opacity: 1
            },
            {
              offset: 100,
              color: "#3F5447",
              opacity: 1
            }
          ]
        }
      },
      yaxis: {
        show: false
        // forceNiceScale: true,
        // tickAmount: 10
        // min: {{setMin}},
        // max: {{setMax}}
      },

      markers: {
        size: [12, 0.1],
        colors: ["#D9D9D9", "#BBBBBB"],

        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3
        }
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              height: 200
            },
            forecastDataPoints: {
              dashArray: 5
            },
            stroke: {
              width: [4, 2],
              curve: "smooth",
              dashArray: [0, 5]
            },
            markers: {
              size: [6, 0.1],
              hover: {
                size: 7,
                sizeOffset: 2
              }
            }
          }
        }
      ],
      xaxis: {
        categories: [], // this.categories(),
        labels: {
          show: true,
          // hideOverlappingLabels: true,
          style: {
            colors: "#BFBFBF",
            fontSize: "10px",
            fontWeight: 700,
            fontFamily: "Roboto, sans-serif",
            cssClass: "apexcharts-xaxis-label"
          }
        },
        tooltip: {
          offsetY: 0,
          style: {
            fontSize: "10px"
          }
        }
      },
      // grid: { show: false },

      tooltip: {
        x: {
          show: true,
          formatter: (value: any) => {
            const selectedMonth = (
              chartOptions.value.xaxis.categories[value - 1] || ""
            ).split("-")[0];
            return fullMonths.value[selectedMonth];
          }
        },
        y: {
          formatter: function(value: any) {
            const isInt =
              typeof value === "number" &&
              isFinite(value) &&
              Math.floor(value) === value;

            let formatedAmount = value;
            if (!isNaN(value) && !isInt) {
              formatedAmount = ApiHelper.dollarFormat(value, false, {
                useBrackets: true
              });
            } else {
              formatedAmount = parseInt(formatedAmount) / 100;
            }
            return formatedAmount;
          }
        }
      },
      grid: {
        show: false
      },
      legend: {
        show: false
      }
    });

    const chartSeries = ref([]);

    const chartData = ref({
      categories: [],
      label: "",
      data: [],
      series: []
    });

    const loadData = () => {
      if (props.barData) {
        const barData = props.barData || {
          data: {
            labels: [],
            datasets: [
              {
                label: "",
                data: []
              }
            ]
          }
        };

        chartOptions.value.xaxis.categories = barData.data.labels.map(
          (label: any) => {
            const selectedMonth = label.split("/")[0];
            return (
              shortMonths.value[selectedMonth - 1] + "-" + label.split("/")[1]
            );
          }
        );
        chartSeries.value = barData.data.datasets.map((item: any) => {
          return {
            name: item.name,
            type: item.type,
            data: item.data
          };
        });
      }
    };

    loadData();

    return {
      fullMonths,
      shortMonths,
      chartOptions,
      chartSeries,
      chartData
    };
  }
});
