








import { defineComponent, computed } from "@vue/composition-api";

export type AlertMessageError = Error | string;

type Props = {
  errors: AlertMessageError[];
};

export default defineComponent({
  name: "Profiles",
  components: {},
  props: {
    errors: Array
  },
  setup(props: Props) {
    const messages = computed(() => {
      if (!props.errors) {
        return [];
      }

      props.errors.map(error => {
        if (typeof error === "string") {
          return error;
        }

        return error.message;
      });
    });

    return {
      messages
    };
  }
});
