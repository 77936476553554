






























































































































import { defineComponent } from "@vue/composition-api";

import VAlertMessage from "@/components/VAlertMessage.vue";
import Loading from "@/components/Common/Loading.vue";
import HeaderEventDetails from "@/components/Event/HeaderEventDetails.vue";
import SidebarEventDetails from "@/components/Event/SidebarEventDetails.vue";
import { useEventDashboardStore } from "@/stores/Event/EventDashboardStore";
import TimelineTable from "@/components/Timeline/TimelineTable.vue";

type PageData = {
  isLoading: boolean;
};

export default defineComponent({
  name: "EventDashboardPage",
  components: {
    HeaderEventDetails,
    SidebarEventDetails,
    VAlertMessage,
    Loading,
    TimelineTable
  },
  setup(props, context) {
    const {
      errors,
      pageData,
      // filters
      updateFilterValue,
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange,
      updateRouters,
      updateSortValue
    } = useEventDashboardStore(context);
    return {
      errors,
      pageData,
      // filters
      updateFilterValue,
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange,
      updateRouters,
      updateSortValue
    };
  }
});
