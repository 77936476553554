



















import { ref, defineComponent } from "@vue/composition-api";
import VAlertMessage, {
  AlertMessageError
} from "@/components/VAlertMessage.vue";
import ProfileTabs from "@/components/Profile/ProfileTabs.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import SidebarProfileDetails from "@/components/Profile/SidebarProfileDetails.vue";
import Loading from "@/components/Common/Loading.vue";
import { ApiHelper } from "@/helpers";

type Profile = {
  id: string;
  firstName: string;
  lastName: string;
  tags: {
    id: number;
    name: string;
  }[];
};

export default defineComponent({
  name: "ProfileEventsPage",
  components: {
    VAlertMessage,
    ProfileTabs,
    Loading,
    SidebarProfileDetails,
    HeaderProfileDetails
  },
  setup(props, context) {
    const pageData = ref<{ isLoading: boolean }>({
      isLoading: true
    });
    const errors = ref<AlertMessageError[]>([]);
    const profile = ref<Profile>({
      id: "-",
      firstName: "-",
      lastName: "-",
      tags: []
    });
    (async () => {
      try {
        const result = await ApiHelper.callApi(
          "get",
          "/profiles/" + context.root.$route.params.profileId + "/info",
          {},
          {}
        );
        pageData.value.isLoading = false;
        if (result.status === 1) {
          profile.value = result.data || {};
        }
      } catch (err) {
        pageData.value.isLoading = false;
        // if (!isApolloError(err)) {
        //   errors.value = [err];
        //   return;
        // }

        // if (
        //   err.graphQLErrors &&
        //   err.graphQLErrors.findIndex(
        //     gerr =>
        //       gerr.message ===
        //       "Access denied! You don't have permission for this action!"
        //   ) !== -1
        // ) {
        //   context.root.$router.push({ name: "Login" });
        //   return;
        // }

        errors.value = [err];
      }
    })();

    return {
      errors,
      pageData,
      profile
    };
  }
});
