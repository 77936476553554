import { ref } from "@vue/composition-api";

import moment from "moment";
import { ApiHelper } from "@/helpers";

type PageData = {
  isLoading: boolean;
  tab: string;
  dashboard: any;
  totalRevenue: {
    total: number;
    percent: string;
  };
  yoYGrowth: {
    total: number;
    percent: string;
  };
  avgCostPerParticipant: {
    total: number;
    percent: string;
  };
  discountsGiven: {
    total: number;
    percent: string;
  };
  missedOpportunities: {
    total: number;
    percent: string;
  };
  totalMostOwedItems: number;
  mostOwedItems: {
    title: string;
    total: string;
  }[];
  delinquentItems: {
    title: string;
    total: string;
  }[];
  last12months: {
    month: string;
    year: string;
    label: string;
    total: number;
  }[];
  filter: {
    filterBy: string;
  };
  filterSelectVisible: boolean;
  topTags: object[];
  totalTransactions: number;
  transactions: {
    transactionId: number;
    description?: string;
    profileId?: number;
    firstName?: string;
    lastName?: string;
    planId?: number;
    eventId?: number;
    eventName?: string;
    transactionAmount?: number;
    transactionTs?: Date;
    typeName?: string;
    sourceId?: number;
    serviceName?: string;
  }[];
  paymentPlans: any[];
};
type BarData = {
  isLoaded: boolean;
  data: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColor: string;
      stack: string;
      data: number[];
    }[];
  };
  options: object;
};
export function useFinancialDashboardStore(context: any) {
  const pageData = ref<PageData>({
    isLoading: true,
    tab: "plan",
    dashboard: {
      financial: {
        avgCost: {
          total: 0,
          percent: 0
        },
        discountGiven: {
          percent: 0,
          total: 0
        },
        yoyGrowth: {
          percent: 0,
          total: 0
        }
      }
    },
    totalRevenue: {
      total: 0,
      percent: "-"
    },
    yoYGrowth: {
      total: 0,
      percent: "-"
    },
    avgCostPerParticipant: {
      total: 0,
      percent: "-"
    },
    discountsGiven: {
      total: 0,
      percent: "-"
    },
    missedOpportunities: {
      total: 0,
      percent: "-"
    },
    totalMostOwedItems: 0,
    mostOwedItems: [],
    delinquentItems: [],
    last12months: [],
    filterSelectVisible: false,
    filter: {
      filterBy: "camp_view"
    },
    topTags: [],
    totalTransactions: 0,
    transactions: [],
    paymentPlans: []
  });
  const barData = ref<BarData>({
    isLoaded: false,
    data: {
      labels: [],
      datasets: []
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        intersect: false
      },
      plugins: {
        title: {
          display: false
        },
        tooltip: {
          display: false,
          mode: "index",
          intersect: false
        }
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: true,
            barPercentage: 0.1,
            ticks: {
              fontColor: "#b9b9b8",
              fontSize: 9,
              padding: 16,
              fontStyle: "bold"
            },
            gridLines: {
              lineWidth: 0,
              drawBorder: false,
              display: false
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              display: false
            },
            ticks: {
              display: false
            }
          }
        ]
      }
    }
  });

  const chartData = ref<{
    isLoaded: boolean;
    data: {
      labels: any[];
      datasets: {
        label: string;
        stack: string;
        type: string;
        backgroundColor: string;
        data: any[];
      }[];
    };
  }>({
    isLoaded: false,
    data: {
      datasets: [],
      labels: []
    }
  });

  const loadData = () => {
    ApiHelper.setDataLoading(true);
    pageData.value.isLoading = true;
    barData.value.isLoaded = false;
    (async () => {
      ApiHelper.callApi(
        "get",
        "/finances/dashboard",
        {},
        { callFrom: "FinancialDashboard" }
      ).then(response => {
        const last12months = response.data.chartData || [];
        chartData.value.data.labels = last12months.map((value: any) => {
          return value.label;
        });
        chartData.value.data.datasets.push({
          label: "New Charges",
          backgroundColor: "#000000",
          stack: "New Charges",
          type: "bar",
          data: last12months.map((value: any) => {
            return value.newChargeTotal;
          })
        });
        chartData.value.data.datasets.push({
          label: "Payments",
          backgroundColor: "#5e886d",
          stack: "Payments",
          type: "bar",
          data: last12months.map((value: any) => {
            return value.paymentTotal;
          })
        });
        chartData.value.data.datasets.push({
          label: "Balance",
          backgroundColor: "#000000",
          stack: "Balance",
          type: "line",
          data: last12months.map((value: any) => {
            return value.totalOwed;
          })
        });

        chartData.value.isLoaded = true;

        // load transactions
        pageData.value.transactions = (response.data.transactions || []).map(
          (item: any) => {
            item.transactionTsFormatted = moment(item.transactionTs).format(
              "MMM DD, YYYY"
            );
            item.balanceFormatted = ApiHelper.dollarFormat(item.balance);
            item.transactionAmountFormatted = ApiHelper.dollarFormat(
              item.transactionAmount
            );
            return item;
          }
        );
        pageData.value.totalTransactions = response.data.totalTransactions || 0;
        // load payment plans
        pageData.value.paymentPlans = (response.data.plans || []).map(
          (item: any) => {
            item.amountFormatted = ApiHelper.dollarFormat(item.amount);
            item.balanceFormatted = ApiHelper.dollarFormat(item.balance);
            item.transactionTSFormatted = moment(item.transactionTS).format(
              "MMM DD, YYYY"
            );
            return item;
          }
        );
        // load chart
        const item = response.data || {};

        const tsStart = new Date(item.tsStart);
        const tsEnd = new Date(item.tsEnd);
        const linkEventParticipants = item.linkEventParticipants || [];
        let pCurrent = 0;
        let pTotal = 0;
        let pPercent = 0;
        let eAges = "";
        if (linkEventParticipants.length > 0) {
          pCurrent = 0;
          pPercent = 0;
          pTotal = item.linkEventParticipants[0].capacity || 0;
          eAges = item.linkEventParticipants[0].age || "";
        }
        pageData.value.totalRevenue.total = item.financial?.totalPaid || 0;

        const totalIncomes: {
          transM: number;
          transY: number;
          totalM: string;
          eventTotalInc: number;
        }[] = item.totalIncomes.map((value: any) => {
          return {
            transM: value.transM || 0,
            transY: value.transY || 0,
            totalM: value.totalM || "",
            totalInc: value.totalInc || 0
          };
        });
        barData.value.data.labels = totalIncomes.map((value: any) => {
          return value.totalM;
        });
        barData.value.data.datasets.push({
          label: "Revenue",
          backgroundColor: "#5e886d",
          stack: "Stack 0",
          data: totalIncomes.map((value: any) => {
            return value.totalInc;
          })
        });
        barData.value.isLoaded = true;

        const dashboardData = {
          id: item.id,
          isChecked: false,
          name: item.name,
          participants: {
            current: pCurrent,
            total: pTotal,
            percent: pPercent
          },
          ages: eAges,
          date:
            tsStart.toLocaleDateString() + " - " + tsEnd.toLocaleDateString(),
          totalEventIncomes: totalIncomes,
          financial: item.financial
        };

        pageData.value.dashboard = dashboardData;
        pageData.value.isLoading = false;
        ApiHelper.setDataLoading(false);
      });
    })();
    (async () => {
      const result = await ApiHelper.callApi(
        "get",
        "/balances/outstanding",
        {},
        {
          take: 5,
          skip: 0,
          order: 4,
          direction: 2
        }
      );
      if (result.status === 1) {
        pageData.value.mostOwedItems = result.data.items || [];
        pageData.value.totalMostOwedItems = result.data.totalCount || 0;
      }
    })();
    pageData.value.isLoading = false;
  };

  const toggleFilterSelect = () => {
    pageData.value.filterSelectVisible = !pageData.value.filterSelectVisible;
  };

  const closeFilterSelect = () => {
    pageData.value.filterSelectVisible = false;
  };

  const updateFilterView = (filterBy: string) => {
    pageData.value.filter.filterBy = filterBy;
    loadData();
  };

  const getFilterView = (): string => {
    let ret = "";
    if (pageData.value.filter.filterBy == "camp_view") {
      ret = "Camp view";
    } else if (pageData.value.filter.filterBy == "events_view") {
      ret = "My events view";
    } else if (!isNaN(parseInt(pageData.value.filter.filterBy))) {
      const tagName: any = pageData.value.topTags.find(
        (item: any) => item.id == parseInt(pageData.value.filter.filterBy)
      );
      if (tagName) {
        ret = tagName.name || "";
      }
    }
    return ret;
  };

  // init data
  (async () => {
    loadData();
    // getTopTags();
  })();

  return {
    pageData,
    chartData,
    barData,
    toggleFilterSelect,
    closeFilterSelect,
    updateFilterView,
    getFilterView
  };
}
