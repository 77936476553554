


























































































































import { defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";
import { FormSelect as FormSelectType, FormText } from "@/types";
import FormSelect from "@/components/Form/FormSelect.vue";
import { ApiType } from "@/helpers/ApiHelper";
import FormToggleType from "@/types/FormToogle";
import FormToggle from "@/components/Form/FormToggle.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";

export default defineComponent({
  name: "PopupSendMail",
  props: {
    participants: Array
  },
  components: {
    FormTextarea,
    FormInput,
    FormToggle,
    Modal,
    VScroller,
    FormSelect
  },
  setup(props, context) {
    const componentData = ref({
      templateId: 0,
      templateName: "",
      templateHtml: "",
      templatePreview: "",
      dataParticipant: "",
      dataEvent: "",
      dataContent: "",
      isLoading: false
    });
    const formData = ref<{
      controls: {
        type: FormToggleType;
        template: FormSelectType;
        subject: FormText;
        body: FormText;
        content: FormText;
        contents: FormText[];
        participant: FormText;
        event: FormText;
      };
    }>({
      controls: {
        type: {
          label: "Type",
          labelOn: "Template",
          labelOff: "Custom",
          value: true,
          error: "",
          size: "large"
        },
        template: {
          placeholder: "Select template",
          error: "",
          options: [],
          value: "",
          label: "Template",
          notRequired: true,
          style: "custom"
        },
        subject: {
          error: "",
          label: "Subject",
          value: "",
          maxlength: 150,
          required: true,
          style: "custom"
        },
        body: {
          error: "",
          label: "Content",
          type: "",
          value: "",
          required: true,
          style: "custom"
        },
        content: {
          error: "",
          label: "Content",
          placeholder: "Content",
          type: "",
          value: "",
          required: true,
          style: "custom"
        },
        contents: [],
        participant: {
          error: "",
          label: "Participant",
          placeholder: "Participant",
          type: "",
          value: "",
          required: true,
          style: "custom"
        },
        event: {
          error: "",
          label: "Event",
          placeholder: "Event",
          type: "",
          value: "",
          required: true,
          style: "custom"
        }
      }
    });
    const loadData = async () => {
      componentData.value.isLoading = true;
      const result = await ApiHelper.callApi(
        "get",
        "/campaigns/templates",
        {},
        {},
        ApiType.CAMPAIGN
      );
      componentData.value.isLoading = false;
      if (result.status === 1) {
        const eventOptions = result?.data.templates.map(
          (item: { id: number; name: string }) => {
            return {
              id: item.id,
              text: item.name,
              selected: false,
              data: item
            };
          }
        );
        formData.value.controls.template.options = eventOptions;
      }
    };

    const selectTemplate = () => {
      const selectedItem = formData.value.controls.template.options.find(
        item => {
          return item.id === formData.value.controls.template.value;
        }
      );
      formData.value.controls.participant.value = "";
      formData.value.controls.event.value = "";
      formData.value.controls.content.value = "";
      formData.value.controls.contents = [];
      componentData.value.templateHtml = selectedItem?.data.content || "";
      componentData.value.templatePreview = componentData.value.templateHtml;
      componentData.value.templateName = selectedItem?.data.name || "";
      componentData.value.templateId = selectedItem?.data.id || 0;
      const arr =
        componentData.value.templateHtml.match(/###CONTENT###/g) || [];
      arr.map((item, index) => {
        componentData.value.templateHtml = componentData.value.templateHtml.replace(
          `###CONTENT###`,
          `###CONTENT${index + 1}###`
        );
        formData.value.controls.contents.push({
          error: "",
          label: "Content " + (index + 1),
          placeholder: "Content",
          type: "",
          value: "",
          required: true,
          style: "custom"
        });
      });
      const participants = props.participants || [];
      if (participants.length) {
        // const firstParticipant: any = participants[0];
        // componentData.value.templateHtml = componentData.value.templateHtml.replaceAll(
        //   `###EMAIL###`,
        //   participants.map((item: any) => item.email).join(", ")
        // );
        // componentData.value.templateHtml = componentData.value.templateHtml.replaceAll(
        //   `###PERSON_NAME###`,
        //   participants
        //     .map((item: any) => {
        //       return `${item.firstName} ${item.lastName}`;
        //     })
        //     .join(", ")
        // );
        // componentData.value.templateHtml = componentData.value.templateHtml.replaceAll(
        //   `###EVENT_NAME###`,
        //   firstParticipant.eventName
        // );
        // componentData.value.templateHtml = componentData.value.templateHtml.replaceAll(
        //   `###EVENT_START_DATE###`,
        //   firstParticipant.eventStartDate
        // );
        // componentData.value.templateHtml = componentData.value.templateHtml.replaceAll(
        //   `###EVENT_END_DATE###`,
        //   firstParticipant.eventEndDate
        // );
      }
    };

    const onSubmit = async () => {
      let hasError = false;
      formData.value.controls.contents.map(item => {
        if (!item.value) {
          hasError = true;
        }
      });
      if (hasError) {
        ApiHelper.showErrorMessage("Content is required");
        return false;
      }
      if (formData.value.controls.type.value) {
        if (formData.value.controls.template.value) {
          componentData.value.isLoading = true;
          const participants = props.participants || [];
          const arr: any[] = [];
          participants.map((item: any) => {
            let templateHtml = componentData.value.templateHtml.replaceAll(
              `###EMAIL###`,
              item.email
            );
            formData.value.controls.contents.map((item, index) => {
              if (item.value) {
                templateHtml = templateHtml.replace(
                  `###CONTENT${index + 1}###`,
                  item.value
                );
              }
            });
            templateHtml = templateHtml.replaceAll(
              `###PERSON_NAME###`,
              `${item.firstName} ${item.lastName}`
            );
            templateHtml = templateHtml.replaceAll(
              `###EVENT_NAME###`,
              item.eventName
            );
            templateHtml = templateHtml.replaceAll(
              `###EVENT_START_DATE###`,
              item.eventStartDate
            );
            templateHtml = templateHtml.replaceAll(
              `###EVENT_END_DATE###`,
              item.eventEndDate
            );
            templateHtml = templateHtml.replaceAll(`###EMAIL###`, item.email);
            arr.push(
              ApiHelper.callApi(
                "post",
                "/campaigns/templates/test",
                {
                  message: templateHtml,
                  subject: componentData.value.templateName,
                  tos: item.email
                },
                {},
                ApiType.CAMPAIGN
              )
            );
          });
          if (participants.length == 0) {
            ApiHelper.showErrorMessage(`Cant send email`);
          } else {
            const results = await Promise.all(arr);
            componentData.value.isLoading = false;
            ApiHelper.showSuccessMessage("Sent an email");
            context.emit("callback");
          }

          // const result = await ApiHelper.callApi(
          //   "post",
          //   "/campaigns/templates/test",
          //   {
          //     message: componentData.value.templatePreview,
          //     subject: componentData.value.templateName,
          //     tos: (props.participants || [])
          //       .map((item: any) => item.email)
          //       .join(",")
          //   },
          //   {},
          //   ApiType.CAMPAIGN
          // );
          // componentData.value.isLoading = false;
          // if (result.status == 1) {
          //   ApiHelper.showSuccessMessage("Sent an email");
          //   context.emit("callback");
          // } else {
          //   ApiHelper.showErrorMessage(result.message || `Cant send email`);
          // }
        } else {
          ApiHelper.showErrorMessage("Not found template");
        }
      } else {
        componentData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "post",
          "/campaigns/templates/test",
          {
            message: formData.value.controls.body.value,
            subject: formData.value.controls.subject.value,
            tos: (props.participants || [])
              .map((item: any) => item.email)
              .join(",")
          },
          {},
          ApiType.CAMPAIGN
        );
        componentData.value.isLoading = false;
        if (result.status == 1) {
          ApiHelper.showSuccessMessage("Sent an email");
          context.emit("callback");
        } else {
          ApiHelper.showErrorMessage(result.message || `Cant send email`);
        }
      }
    };

    const getPreviewContent = () => {
      let content = componentData.value.templateHtml;
      const participants = props.participants || [];
      if (participants.length) {
        const firstParticipant: any = participants[0];
        formData.value.controls.contents.map((item, index) => {
          if (item.value) {
            content = content.replace(`###CONTENT${index + 1}###`, item.value);
          }
        });
        content = content.replaceAll(
          "###PERSON_NAME###",
          participants
            .map((item: any) => {
              return `${item.firstName} ${item.lastName}`;
            })
            .join(", ")
        );
        content = content.replaceAll(
          "###EMAIL###",
          participants
            .map((item: any) => {
              return `${item.email}`;
            })
            .join(", ")
        );
        content = content.replaceAll(
          "###EVENT_NAME###",
          firstParticipant.eventName
        );
        content = content.replaceAll(
          `###EVENT_START_DATE###`,
          firstParticipant.eventStartDate
        );
        content = content.replaceAll(
          `###EVENT_END_DATE###`,
          firstParticipant.eventEndDate
        );
      }

      componentData.value.templatePreview = content;
      return content;
    };

    (async () => {
      await loadData();
    })();

    return {
      selectTemplate,
      formData,
      getPreviewContent,
      onSubmit,
      componentData,
      ApiHelper: ApiHelper
    };
  }
});
