





















import { defineComponent } from "@vue/composition-api";


export default defineComponent({
  name: "FinancialUnpaid",
  components: {},
  setup(props, context) {
    
    return {};
  }
});
