





















































































































































































import { defineComponent } from "@vue/composition-api";

import Loading from "@/components/Common/Loading.vue";
import Header from "@/components/Common/Header.vue";
import DropdownStatus from "@/components/Common/DropdownStatus.vue";
import { useMessagingTemplatesStore } from "@/stores/Messaging/Template/MessagingTemplatesStore";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import Pager from "@/components/Common/Pager.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";

export default defineComponent({
  name: "MessagingTemplatesPage",
  components: {
    TableHeaderColumn,
    Header,
    Loading,
    DropdownStatus,
    Pager,
    FilterSearchColumn,
    FilterDateColumn
  },
  setup(props, context) {
    const {
      loadList,
      showStatusDropdown,
      updateStatus,
      updateRouters,
      deleteItem,
      // sort
      updateSortValue,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab
    } = useMessagingTemplatesStore(context);
    return {
      loadList,
      showStatusDropdown,
      updateStatus,
      updateRouters,
      deleteItem,
      // sort
      updateSortValue,
      // page
      headerData,
      pageData,
      // filters
      // --- pager
      gotoPage,
      onClickNext,
      onClickPrev,
      togglePagerItems,
      // --- header
      getFiltersData,
      // --- filters
      updateFilterValue,
      setActiveFilterTab,
      closeFilterTab
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
