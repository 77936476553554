



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import VAlertMessage from "@/components/VAlertMessage.vue";
import ProfileTabs from "@/components/Profile/ProfileTabs.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import SidebarProfileDetails from "@/components/Profile/SidebarProfileDetails.vue";
import Loading from "@/components/Common/Loading.vue";
import { useProfileFinancialStore } from "@/stores/Profile/ProfileFinancialStore";
import ApexChart from "@/components/Chart/ApexChart.vue";
import ApexDashboard from "@/components/Chart/ApexDashboard.vue";
import PopupPayment from "@/components/Financial/PopupPayment.vue";
import { ApiHelper } from "@/helpers";
import { TransactionType } from "@/helpers/ApiHelper";
import SidebarProfileEvents from "@/components/Profile/SidebarProfileEvents.vue";
import Pager from "@/components/Common/Pager.vue";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import PopupEditApplication from "@/components/Participant/PopupEditApplication.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import TransactionDetails from "@/components/Financial/TransactionDetails.vue";
import NavProfileEvents from "@/components/Profile/NavProfileEvents.vue";
import NavProfileDetails from "@/components/Profile/NavProfileDetails.vue";
import TransferModal from "@/components/Financial/TransferModal.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";

export default defineComponent({
  name: "ProfileFinancial",
  components: {
    NavProfileDetails,
    NavProfileEvents,
    TableHeaderColumn,
    PopupPayment,
    VAlertMessage,
    ProfileTabs,
    Loading,
    SidebarProfileDetails,
    HeaderProfileDetails,
    ApexChart,
    ApexDashboard,
    SidebarProfileEvents,
    Pager,
    PopupEditApplication,
    FilterDateColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    TransactionDetails,
    TransferModal,
    FilterSearchColumn
  },
  setup(props, context: any) {
    // const apexKey = ref(ApiHelper.randomFormId());
    const {
      sidebarData,
      chartData,
      isArchived,
      barData,
      pageData,
      loadData,
      popupData,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      getFundBucket,
      removeTransaction,
      reloadData,
      apexKey,
      toggleTransactionDetails,
      getCashBalance,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      updateRouters
    } = useProfileFinancialStore(context);

    const popupEditApplication = ref({
      show: false,
      profileId: 0,
      participantId: 0
    });

    const closePaymentModal = (options: any) => {
      // if (options.clearSelectedToPay != undefined) {
      //   pageData.value.selectedToPay = [];
      // }
      popupData.value.show = false;
      loadData();
      pageData.value.transactions.loadList(1);
    };

    const setIsPaying = (status: boolean) => {
      pageData.value.paying = status;
    };

    const inSelectedIds = (item: any) => {
      const selectedId = item.transactionId || item.planId || "";
      const existedItem = pageData.value.selectedIds.find(
        (id: any) => id == selectedId
      );
      if (existedItem) {
        return true;
      }
      return false;
    };

    const updateRefundInfo = (item: any) => {
      const selectedId = item.transactionId || item.planId || "";

      // just allow select an item at a time (see as a radio button)
      pageData.value.selectedIds = pageData.value.selectedIds.filter(
        (id: string) => id == selectedId
      );
      pageData.value.refundInfo = [];
      if (inSelectedIds(item)) {
        pageData.value.refundInfo.push({
          transactionId: selectedId,
          transactionAmount: item.transactionAmount,
          transactionAmountFormatted: item.transactionAmountFormatted,
          externalPlanId: item.planId ? item.externalId : "",
          transactionTypeId: item.transactionTypeId,
          details: []
        });
      }
    };

    const updateSelectedFunding = (item: any) => {
      const selectedIds = pageData.value.selectedIds || [];
      if (
        item.transactionTypeId == TransactionType.AccountBucket &&
        selectedIds.length
      ) {
        pageData.value.selectedFunding = {
          transactionId: item.transactionId || "",
          transactionAmount: item.transactionAmount,
          transactionAmountFormatted: item.transactionAmountFormatted,
          transactionTypeId: item.transactionTypeId,
          eventId: item.eventId || 0,
          eventName: item.eventName || "",
          profileId: item.profileId,
          participantId: item.participantIds,
          fullName: item.fullName,
          currentBalance: item.transactionAmount
        };
      } else {
        pageData.value.selectedFunding = {};
      }
    };

    const allowRefund = (item: any) => {
      if ((item.planId || "") != "") {
        // case check to allow refund for a plan
        let valid = true;
        if (item.transactionAmount == item.refundedAmount) {
          // if refund all the paid amount, so cannot refund more
          valid = false;
        }

        return valid;
      } else {
        const transactionId = item.transactionId;
        const transactionTypeId = item.transactionTypeId || 0;
        let valid = true;
        // if (!isNaN(parseFloat(transactionId))) {
        //   // no allow if is a number
        //   valid = false;
        // }
        if (
          transactionId.indexOf("re_") != -1 ||
          item.transactionTypeId == TransactionType.Refund
        ) {
          // detect this is a refund id
          valid = false;
        }
        if (item.transactionAmount == item.refundedAmount) {
          // if refund all the paid amount, so cannot refund more
          valid = false;
        }
        if ((item.scholarshipUsingDiscount || 0) == 1) {
          valid = false;
        }
        if (transactionTypeId == TransactionType.WriteOff) {
          valid = false;
        }

        return valid;
      }
    };

    // const reloadData = async () => {
    //   await loadData();
    //   pageData.value.tab = "transaction";
    //   pageData.value.transactions.loadList(1);
    //   apexKey.value = ApiHelper.randomFormId();
    // };

    const showTab = (tabName: string) => {
      if (pageData.value.tab != tabName) {
        // reset selectedIds
        pageData.value.selectedIds = [];
      }

      if (tabName == "plan") {
        pageData.value.tab = "plan";
        pageData.value.selectedFundbuckets = [];
      } else if (tabName == "transaction") {
        pageData.value.tab = "transaction";
        pageData.value.transactions.loadList(1);
        pageData.value.selectedFundbuckets = [];
      } else if (tabName == "fund_bucket") {
        pageData.value.tab = "fund_bucket";
        getFundBucket(1);
      } else if (tabName == "cash_balance") {
        pageData.value.tab = "cash_balance";
        getCashBalance(1);
      }
    };

    const showPopupEditApplication = (options: any) => {
      popupEditApplication.value.profileId = options.profileId;
      popupEditApplication.value.participantId = options.participantId;
      popupEditApplication.value.show = true;
    };

    const inSelectedFundbuckets = (item: any) => {
      const selectedId = `fb-${item.addonServiceId}-${item.participantId}`;
      const existedItem = pageData.value.selectedFundbuckets.find(
        (id: any) => id == selectedId
      );
      if (existedItem) {
        return true;
      }
      return false;
    };

    const updateFundBucketRefundInfo = (item: any) => {
      const selectedId = `fb-${item.addonServiceId}-${item.participantId}`;

      // just allow select an item at a time (see as a radio button)
      pageData.value.selectedFundbuckets = pageData.value.selectedFundbuckets.filter(
        (id: string) => id == selectedId
      );
      pageData.value.refundInfo = [];
      if (inSelectedFundbuckets(item)) {
        pageData.value.refundInfo.push({
          fundBucketRefund: true,
          serviceName: item.serviceName,
          profileId: item.profileId,
          participantId: item.participantId,
          addonServiceId: item.addonServiceId,
          transactionId: 0,
          transactionAmount: item.totalFunds,
          transactionAmountFormatted: item.totalFundsFormatted,
          externalPlanId: "",
          transactionTypeId: 0,
          details: []
          // transactionId: selectedId,
          // transactionAmount: item.transactionAmount,
          // transactionAmountFormatted: item.transactionAmountFormatted,
          // externalPlanId: item.planId ? item.externalId : "",
          // transactionTypeId: item.transactionTypeId,
        });
      }
    };

    const allowRefundFundBucket = (item: any) => {
      let allow = false;
      if (item.totalFunds > 0) {
        allow = true;
      }

      return allow;
    };

    return {
      sidebarData,
      chartData,
      isArchived,
      loadData,
      popupData,
      barData,
      pageData,
      setIsPaying,
      closePaymentModal,
      ApiHelper: ApiHelper,
      allowRefund,
      inSelectedIds,
      updateRefundInfo,
      updateSelectedFunding,
      reloadData,
      apexKey,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      showTab,
      getFundBucket,
      popupEditApplication,
      showPopupEditApplication,
      removeTransaction,
      getSelectedItems: ApiHelper.getSelectedItems,
      inSelectedFundbuckets,
      updateFundBucketRefundInfo,
      allowRefundFundBucket,
      toggleTransactionDetails,
      getCashBalance,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      updateRouters
    };
  },
  mounted() {
    const query = this.$route.query;
    if ((query.payment || "") == "true") {
      this.pageData.paymentType = "manual";
      this.popupData.show = true;
    }
  }
});
