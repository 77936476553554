













































































































































































































import { defineComponent } from "@vue/composition-api";

import { useMessagingCampaignsDetailsStore } from "@/stores/Messaging/Campaign/MessagingCampaignsDetailsStore";
import FormButton from "@/components/Form/FormButton.vue";
import Loading from "@/components/Common/Loading.vue";
import VScroller from "@/components/Common/VScroller.vue";
import Header from "@/components/Common/Header.vue";
import Flowy from "@/components/Messaging/flowy.vue";

export default defineComponent({
  name: "MessagingCampaignsDetailsPage",
  components: {
    Flowy,
    Loading,
    FormButton,
    VScroller,
    Header
  },
  setup(props, context) {
    const { loadPageData, pageData } = useMessagingCampaignsDetailsStore(
      context
    );
    return {
      loadPageData,
      pageData
    };
  },
  async mounted() {
    await this.loadPageData();
  }
});
