

































































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "DormGenerateConfirm",
  props: {
    data: Object
  },
  setup(props, context) {
    const randomId = ref<number>(ApiHelper.randomFormId());
    const selected = 4;
    const changeCallBack = (event: any, selected: any) => {
      context.emit("change", { value: event, item: selected });
    };
    const onSelectAll = (value: any) => {
      props.data?.map((item: any) => {
        item.selected = value;
      });
      context.emit("selectall", value);
    };
    return {
      randomId: randomId.value.toString(),
      selected,
      changeCallBack,
      onSelectAll,
      options: [
        { item: 1, name: "Accept Sender's Profile" },
        { item: 2, name: "Accept Recevier's Profile" },
        { item: 3, name: "Ignore Pair" },
        { item: 4, name: "Exclude" }
      ]
    };
  }
});
