














































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import $ from "jquery";
import { ApiHelper } from "@/helpers";
import Modal from "@/components/Common/Modal.vue";
import VScroller from "@/components/Common/VScroller.vue";

declare const initSettingSubmit: any;

export default defineComponent({
  name: "FormPhoto",
  components: { Modal, VScroller },
  props: {
    data: Object,
    profileId: {
      type: Number,
      defaultValue: 0
    },
    buttonTitle: {
      type: String,
      defaultValue: "Update Photo"
    },
    disabled: {
      type: Boolean,
      defaultValue: false
    },
    preventChange: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const apiUrl = ref("");
    const showPopup = ref(false);
    const onChangeFile = async (s3url: string) => {
      // if (props.data) {
      //   props.data.preview = s3url;
      // }
      context.emit("change", s3url, "url");
    };

    const onSettingSubmit = async (settingSubmit: any) => {
      const croppedImg: any = $(settingSubmit.croppedImg[0]);
      await onChangeFile(croppedImg.attr("src"));
    };
    const resetImage = () => {
      if (props.data) {
        props.data.delete = true;
      }
    };
    const loadCroppic = async () => {
      apiUrl.value = await ApiHelper.getApiUrl();
      $.getScript("plugins/upload.js", function(data, textStatus, jqxhr) {
        initSettingSubmit(onSettingSubmit, resetImage);
      });
    };

    const removeAndOpenPopup = (remove: boolean) => {
      if (remove) {
        $(".cropControlRemoveCroppedImage").trigger("click");
      }
      setTimeout(() => {
        $(".setting-upload-logo").trigger("click");
      }, 500);
    };

    const dropHandler = (ev: any) => {
      ev.preventDefault();
      showPopup.value = true;
      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...ev.dataTransfer.items].forEach((item, i) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            const file = item.getAsFile();
            // Now let's create a DataTransfer to get a FileList
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            $(".cropControlReset").trigger("click");
            $(".setting-upload-logo-dragging").trigger("click");
            $("#setting-upload-logo").prop("disabled", false);
            $("#setting-upload-logo").prop("files", dataTransfer.files);
            $("#setting-upload-logo").trigger("change");
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        [...ev.dataTransfer.files].forEach((file, i) => {
          // console.log(`… file[${i}].name = ${file.name}`);
        });
      }
    };

    const dragOverHandler = (event: any) => {
      event.preventDefault();
    };
    return {
      dropHandler,
      dragOverHandler,
      removeAndOpenPopup,
      showPopup,
      apiUrl,
      resetImage,
      loadCroppic,
      cropUrl:
        "/s3files/resize" +
        (props.profileId ? "?profileId=" + props.profileId : ""),
      uploadUrl: ""
    };
  },
  async mounted() {
    const _resetImage = this.resetImage;
    await this.loadCroppic();
    $(document).on("click", ".cropControlRemoveCroppedImage", function() {
      _resetImage();
    });
  }
});
