


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import directives from "@/helpers/directives";
import Vue from "vue";
import moment from "moment";
import FormTags from "@/components/Form/FormTags.vue";
import { FormText } from "@/types";
import Modal from "@/components/Common/Modal.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import { v4 as uuidv4 } from "uuid";
import FormDatePicker2 from "../Form/FormDatePicker2.vue";
import FormInput from "@/components/Form/FormInput.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import FormButton from "@/components/Form/FormButton.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import $ from "jquery";

export default defineComponent({
  name: "EventDiscounts",
  props: {
    eventId: Number,
    isTopBottom: Function,
    data: Object,
    costcenters: {
      type: Array,
      defaultValue: []
    },
    disabled: {
      type: Boolean,
      defaultValue: false
    },
    showCostCenter: {
      type: Boolean,
      defaultValue: false
    },
    showGlCodes: {
      type: Boolean,
      defaultValue: false
    },
    eventCoscenterNumber: {
      type: Array,
      default: []
    },
    isAllowAddNewCostCenter: Function,
    isAllowAddNewPostingCode: Function
  },
  directives: directives,
  components: {
    FormInput,
    FormTags,
    Modal,
    FormTextarea,
    FormDatePicker2,
    AutoCompleteInput,
    FormNumber,
    FormButton
  },
  setup(props, context) {
    const isACILoading = ref<boolean>(false);
    const componentData = ref<{
      items: any[];
      allowAdd: boolean;
      allowTop: boolean;
      firstCost: boolean;
      totalDataApplyTop: number;
      allowTopCostCenter: boolean;
      totalDataApplyTopCostCenter: number;
      allowTopPostCode: boolean;
      totalDataApplyTopPostCode: number;
      isLoading: boolean;
      editingIndex: number;
      controls: {
        postingCode: FormText;
        postingCodeDesc: FormText;
        costCenterNumber: FormText;
        costCenterName: FormText;
        glName: FormText;
      };
      itemEdit: {
        item: any;
        index: number;
      };
    }>({
      items: [],
      allowAdd: true,
      allowTop: false,
      firstCost: true,
      totalDataApplyTop: 0,
      allowTopCostCenter: false,
      totalDataApplyTopCostCenter: 0,
      allowTopPostCode: false,
      totalDataApplyTopPostCode: 0,
      isLoading: false,
      editingIndex: -1,
      controls: {
        postingCode: {
          required: true,
          label: "Posting Code",
          placeholder: "Posting Code",
          style: "custom",
          value: "",
          error: "",
          maxlength: 30
        },
        postingCodeDesc: {
          required: true,
          // label: "Description",
          label: "Posting Code Name",
          // placeholder: "Description",
          placeholder: "Posting Code Name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 255
        },
        costCenterNumber: {
          required: true,
          label: "Number",
          placeholder: "Number",
          style: "custom",
          value: "",
          error: "",
          maxlength: 100
        },
        costCenterName: {
          required: false,
          label: "Cost center name",
          placeholder: "Cost center name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 150
        },
        glName: {
          required: true,
          label: "GL Name",
          placeholder: "GL Name",
          style: "custom",
          value: "",
          error: "",
          maxlength: 100
        }
      },
      itemEdit: {
        item: [],
        index: -1
      }
    });
    const amountTypeInput = ref("amount");
    const foundPTypes = ref<any[]>([]);
    const newPostingCode = ref<{
      modalVisible: boolean;
      postingCode: string;
      currentAddOn: any;
    }>({
      modalVisible: false,
      postingCode: "",
      currentAddOn: {}
    });

    const newCostCenter = ref<{
      modalVisible: boolean;
      isProcessing: boolean;
      item: any;
      index: number;
    }>({
      modalVisible: false,
      isProcessing: false,
      item: {},
      index: 0
    });

    const glCodes = ref<any>({
      required: true,
      error: "",
      label: "GL Code",
      placeholder: "Select or input a GL Code",
      type: "tags",
      key: "",
      value: [],
      options: [],
      suggestTags: []
    });

    const resetFirtfocus = () => {
      componentData.value.firstCost = true;
    };

    const loadData = () => {
      (async () => {
        componentData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "get",
          "/events/" + props.eventId + "/discounts",
          {},
          {}
        );
        componentData.value.isLoading = false;
        if (result.status === 1) {
          componentData.value.allowAdd = true;
          componentData.value.editingIndex = -1;
          componentData.value.items = (result.data.discounts || []).map(
            (item: any) => {
              const tags = item.tags || [];
              const costCenters = item.costCenterNumber
                ? [item.costCenterNumber]
                : [];
              const availableToDate = item.availableToDateFormatted
                ? moment(item.availableToDateFormatted).toDate()
                : undefined;

              // item.availableToDateFormatted || undefined;
              return {
                id: item.discountId,
                name: {
                  value: item.discountName || "",
                  error: ""
                },
                costcenter: {
                  value: item.costcenterId || "",
                  error: ""
                },
                maxUse: {
                  value: item.maxUse + "",
                  error: ""
                },
                discountType: {
                  value: item.discountType ? item.discountType + "" : "",
                  error: ""
                },
                discountAmount: {
                  value: item.discountAmount.toFixed(2),
                  error: ""
                },
                discountPercent: {
                  value: item.discountPercent.toFixed(2),
                  error: ""
                },
                code: {
                  value: item.discountCode || "",
                  error: ""
                },
                availableDate: {
                  value: availableToDate,
                  error: ""
                },
                ptype: {
                  value: item.participantTypeId + "",
                  error: ""
                },
                active: {
                  value: !!item.isActive,
                  error: ""
                },
                tags: {
                  error: "",
                  label: "",
                  textOverflow: true,
                  placeholder: "Enter a tag",
                  type: "tags",
                  key: "",
                  value: tags.map((tag: any) => {
                    return {
                      id: tag.id,
                      text: tag.name || "",
                      data: tag
                    };
                  }),
                  options: [],
                  suggestTags: []
                },
                postingCodes: {
                  error: "",
                  label: "",
                  textOverflow: true,
                  placeholder: "Posting / GL Code",
                  type: "tags",
                  key: "",
                  value: item.postingCode
                    ? [
                        {
                          id: item.postingCode,
                          text: `${item.postingCode} / ${item.glCode}`
                        }
                      ]
                    : [],
                  options: [],
                  suggestTags: []
                },
                costCenters: {
                  error: "",
                  label: "",
                  textOverflow: true,
                  placeholder: "Cost Center Number",
                  type: "tags",
                  key: "",
                  firstFocus: true,
                  value: costCenters.map((code: any) => {
                    return {
                      id: code,
                      text: code
                    };
                  }),
                  options: [],
                  suggestTags: []
                },
                totalUsed: item.totalUsed || 0,
                isEditing: false,
                transactionType: {
                  value: item.transactionType || 1,
                  error: ""
                }
              };
            }
          );
        }
      })();
    };

    const addNewRow = () => {
      componentData.value.items.map((value: any) => {
        value.isEditing = false;
      });
      componentData.value.allowAdd = false;
      componentData.value.items.push({
        uuid: uuidv4(),
        id: 0,
        name: {
          value: "",
          error: ""
        },
        costcenter: {
          value: "",
          error: ""
        },
        maxUse: {
          value: "0",
          error: ""
        },
        discountType: {
          value: "",
          error: ""
        },
        discountAmount: {
          value: "",
          error: ""
        },
        discountPercent: {
          value: "",
          error: ""
        },
        code: {
          value: "",
          error: ""
        },
        availableDate: {
          value: "",
          error: ""
        },
        ptype: {
          value: "0",
          error: ""
        },
        active: {
          value: true,
          error: ""
        },
        tags: {
          error: "",
          label: "",
          textOverflow: true,
          placeholder: "Enter a tag",
          type: "tags",
          key: "",
          value: [],
          options: [],
          suggestTags: []
        },
        postingCodes: {
          error: "",
          label: "",
          textOverflow: true,
          placeholder: "Posting / GL Code",
          type: "tags",
          key: "",
          value: [],
          options: [],
          suggestTags: []
        },
        costCenters: {
          error: "",
          label: "",
          textOverflow: true,
          placeholder: "Cost Center Number",
          type: "tags",
          key: "",
          firstFocus: true,
          value: [],
          options: [],
          suggestTags: []
        },
        totalUsed: 0,
        isEditing: true,
        transactionType: {
          value: 1,
          error: ""
        }
      });
      componentData.value.editingIndex = componentData.value.items.length - 1;
    };

    const getSelectPtypeCost = (ptypeId: number) => {
      let cost = 0;
      if (props.data && props.data.ptypes) {
        const findPtype = props.data.ptypes.find((item: any) => {
          return item.id === ptypeId;
        });
        if (findPtype) {
          cost = findPtype.cost || 0;
        }
      }
      return cost;
    };

    const doCancelItem = (index: number, item: any) => {
      componentData.value.editingIndex = -1;
      componentData.value.allowAdd = true;
      item.isEditing = false;
      loadData();
    };

    const doSaveItem = async (index: number, item: any) => {
      item.name.error = "";
      item.code.error = "";
      item.discountAmount.error = "";
      item.discountPercent.error = "";
      item.discountType.error = "";
      item.availableDate.error = "";
      item.active.error = "";
      item.ptype.error = "";
      item.maxUse.error = "";

      let hasError = false;
      if (item.name.value === "") {
        item.name.error = "Name is required";
        hasError = true;
      }

      const name = item.name.value || "";
      const code = item.code.value || "";
      const ptype = item.ptype.value || "";
      if (name === "") {
        item.name.error = "Name is required";
        hasError = true;
      }
      const existedItems =
        componentData.value.items.filter((value: any) => !value.isEditing) ||
        [];
      if (name) {
        const sameName = existedItems.find((value: any) => {
          // return value.name.value === name;
          return (
            value.name.value.toUpperCase() === name.toUpperCase() &&
            parseInt(ptype) === parseInt(value.ptype.value)
          );
        });
        if (sameName) {
          item.name.error = "Name already exists.";
          hasError = true;
          ApiHelper.showErrorMessage(
            "Discount name already exists. Please enter new information.",
            "Oops"
          );
        }
      }
      if (code) {
        const sameCode = existedItems.find((value: any) => {
          return (
            value.code.value.toLowerCase() === code.toLowerCase() &&
            value.ptype.value == ptype
          );
        });
        if (sameCode) {
          item.code.error = "Code already exists.";
          hasError = true;
        }
      }
      // if (item.availableDate.value === "") {
      //   item.availableDate.error = "Date is required";
      //   hasError = true;
      // }
      if (item.availableDate.value) {
        const selectedData = moment(item.availableDate.value).format(
          "YYYY-MM-DD"
        );
        const now = moment().format("YYYY-MM-DD");
        if (!(selectedData > now)) {
          item.availableDate.error = "Date is invalid";
          hasError = true;
        }
      }
      if (
        item.code.value === "" &&
        !item.availableDate.value &&
        parseInt(item.discountType.value) != 2
      ) {
        item.code.error = "Code is required";
        item.availableDate.error = "Date is invalid";
        hasError = true;
      }
      const discountType = Number.parseInt(item.discountType.value, 10) || 0;
      if (discountType === 0) {
        item.discountType.error = "Type is required";
        hasError = true;
      }
      const discountAmount = Number.parseFloat(item.discountAmount.value) || 0;
      const discountPercent =
        Number.parseFloat(item.discountPercent.value) || 0;

      if (amountTypeInput.value === "amount") {
        const ptypeCost = getSelectPtypeCost(parseInt(item.ptype.value) || 0);
        if (discountAmount === 0) {
          item.discountAmount.error = "Discount Amount must greater than 0";
          hasError = true;
        }
        if (discountAmount > ptypeCost) {
          item.discountAmount.error =
            "Discount Amount must less than " +
            ApiHelper.dollarFormat(ptypeCost);
          hasError = true;
        }
      }
      if (amountTypeInput.value === "rate") {
        if (discountPercent == 0) {
          item.discountPercent.error = "Discount Percent must greater than 0";
          hasError = true;
        }
        if (discountPercent > 100) {
          item.discountPercent.error = "Discount Percent must less than 100%";
          hasError = true;
        }
      }
      const ptypeId = Number.parseFloat(item.ptype.value) || 0;
      const maxUse = Number.parseFloat(item.maxUse.value) || 0;
      if (ptypeId === 0) {
        item.ptype.error = "Type is required";
        hasError = true;
      }
      if (hasError) {
        return;
      }

      // auto select cost center
      if (
        item.costCenters.value.length == 0 &&
        item.costCenters.key != "" &&
        item.costCenters.suggestTags.length
      ) {
        const related = item.costCenters.suggestTags.find(
          (t: any) => t.id.toLowerCase() == item.costCenters.key.toLowerCase()
        );
        if (related) {
          item.costCenters.value = [
            {
              id: related.id,
              text: related.text
            }
          ];
        }
      }

      if (item.id > 0) {
        // prevent if adding more than an auto discount
        if (item.code.value == "" && discountType == 1) {
          const existedAutoDiscount = existedItems.find(
            (t: any) =>
              parseInt(t.ptype.value) == ptypeId &&
              parseInt(t.discountType.value) == 1 &&
              t.code.value == ""
          );

          if (existedAutoDiscount) {
            const message =
              "Auto discount already exists for this participant type";
            item.code.error = message;
            ApiHelper.showErrorMessage(message, "Oops");
            return;
          }
        }

        componentData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "put",
          "/events/" + props.eventId + "/discounts/" + item.id,
          {
            discountName: item.name.value,
            discountCode: item.code.value,
            discountType: discountType,
            discountAmount: discountAmount,
            discountPercent: discountPercent,
            availableToDate: item.availableDate.value
              ? moment(
                  moment(item.availableDate.value).format("YYYY-MM-DD") +
                    "T00:00:00.000Z"
                ).toDate()
              : "",
            active: item.active.value ? 1 : 0,
            costcenterId: parseInt(item.costcenter.value) || 0,
            maxUse: maxUse,
            tags: item.tags.value.map((tag: any) => {
              return {
                id: parseInt(tag.id),
                text: tag.text
              };
            }),
            ptypeId: ptypeId,
            postingCode: item.postingCodes.value
              .map((t: any) => t.id)
              .join(","),
            costCenterNumber: item.costCenters.value
              .map((t: any) => t.id)
              .join(","),
            transactionType: parseInt(item.transactionType.value || 1)
          },
          {}
        );
        componentData.value.isLoading = false;
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
        componentData.value.allowAdd = true;
        item.isEditing = false;
        componentData.value.editingIndex = -1;
        ApiHelper.showSuccessMessage("Updated");
        loadData();
      } else {
        // Validate discountName
        const sameName = existedItems.find((value: any) => {
          return (
            value.name.value.toUpperCase() === name.toUpperCase() &&
            parseInt(item.ptype.value) === parseInt(value.ptype.value)
          );
        });
        if (sameName) {
          item.name.error = "Name already exists.";
          ApiHelper.showErrorMessage(
            "Discount name already exists. Please enter new information.",
            "Oops"
          );
          hasError = true;
        }

        // prevent if adding more than an auto discount
        if (item.code.value == "" && discountType == 1) {
          const existedAutoDiscount = existedItems.find(
            (t: any) =>
              parseInt(t.ptype.value) == ptypeId &&
              parseInt(t.discountType.value) == 1 &&
              t.code.value == ""
          );

          if (existedAutoDiscount) {
            const message =
              "Auto discount already exists for this participant type";
            item.code.error = message;
            ApiHelper.showErrorMessage(message, "Oops");
            hasError = true;
          }
        }

        if (!hasError) {
          componentData.value.isLoading = true;
          const result = await ApiHelper.callApi(
            "post",
            "/events/" + props.eventId + "/discounts",
            {
              discountName: item.name.value,
              discountCode: item.code.value,
              discountAmount: discountAmount,
              discountType: discountType,
              discountPercent: discountPercent,
              availableToDate: item.availableDate.value
                ? moment(
                    moment(item.availableDate.value).format("YYYY-MM-DD") +
                      "T00:00:00.000Z"
                  ).toDate()
                : "",
              costcenterId: parseInt(item.costcenter.value) || 0,
              active: item.active.value ? 1 : 0,
              maxUse: maxUse,
              tags: item.tags.value.map((tag: any) => {
                return {
                  id: parseInt(tag.id),
                  text: tag.text
                };
              }),
              ptypeId: ptypeId,
              postingCode: item.postingCodes.value
                .map((t: any) => t.id)
                .join(","),
              costCenterNumber: item.costCenters.value
                .map((t: any) => t.id)
                .join(","),
              transactionType: parseInt(item.transactionType.value || 1)
            },
            {}
          );
          componentData.value.isLoading = false;
          if (result.status !== 1) {
            ApiHelper.showErrorMessage(result.message, "Oops");
            return;
          }
          componentData.value.allowAdd = true;
          item.isEditing = false;
          componentData.value.editingIndex = -1;
          ApiHelper.showSuccessMessage("Added");
          loadData();
        }
      }
    };

    const doDeleteItem = async (index: number, item: any) => {
      const isAgreed = await Vue.swal({
        html: "Are you sure you want to delete this discount?",
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });

      if (isAgreed) {
        if (item.id == 0) {
          return;
        }
        componentData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "delete",
          "/events/" + props.eventId + "/discounts/" + item.id,
          {},
          {}
        );
        componentData.value.isLoading = false;
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
        componentData.value.editingIndex = -1;
        componentData.value.allowAdd = true;
        ApiHelper.showSuccessMessage("Deleted");
        loadData();
      } else {
        componentData.value.editingIndex = -1;
        componentData.value.allowAdd = true;
      }
    };
    const doEditItem = (index: number, item: any) => {
      componentData.value.items.map((value: any) => {
        value.isEditing = false;
      });
      componentData.value.editingIndex = index;
      componentData.value.allowAdd = false;
      item.isEditing = true;
    };

    const amountTypeSwitch = () => {
      amountTypeInput.value =
        amountTypeInput.value == "amount" ? "rate" : "amount";
    };

    const changeDiscountAmount = (item: any) => {
      const ptypeCost = getSelectPtypeCost(parseInt(item.ptype.value) || 0);
      item.discountAmount.error = "";
      item.discountPercent.error = "";
      const discountAmount = parseFloat(item.discountAmount.value) || 0;

      if (discountAmount > ptypeCost) {
        item.discountAmount.error =
          "Discount amount must less than " + ApiHelper.dollarFormat(ptypeCost);
      }
      if (ptypeCost > 0) {
        item.discountPercent.value = (
          (discountAmount / ptypeCost) *
          100
        ).toFixed(2);
      }
    };

    const changeDiscountPercent = (item: any) => {
      const ptypeCost = getSelectPtypeCost(parseInt(item.ptype.value) || 0);
      item.discountAmount.error = "";
      item.discountPercent.error = "";
      let discountPercent = parseFloat(item.discountPercent.value) || 0;
      if (discountPercent > 100) {
        discountPercent = 100;
        item.discountPercent.value = discountPercent;
      }
      const discountAmount = ptypeCost * (discountPercent / 100);
      item.discountAmount.value = discountAmount.toFixed(2);
      if (discountAmount > ptypeCost) {
        item.discountAmount.error =
          "Discount amount must less than " + ApiHelper.dollarFormat(ptypeCost);
      }
    };

    const getPTypesHtml = (item: any) => {
      let ret = `${item.discountName}`;
      const moreInfo = [];
      if (item.discountCode) {
        moreInfo.push(`Code: ${item.discountCode}`);
      }
      if (moreInfo.length) {
        ret = `${ret} - (${moreInfo.join(", ")})`;
      }

      return ret;
    };

    const suggestPTypes = async (key: string, item: any, index: number) => {
      // reset
      foundPTypes.value = [];
      item.name.value = key;

      if (key !== "") {
        // searching
        // item.id = -1;
        const selectedPTypeNames = componentData.value.items
          ? componentData.value.items
              .filter(option => (option.id || 0) > 0)
              .map(option => option.id)
          : [];
        isACILoading.value = true;
        const result = await ApiHelper.callApi(
          "get",
          "/events/discountSearch",
          {},
          {
            key: key,
            take: 25,
            selectedIds: selectedPTypeNames.join(",")
          }
        );
        if (result.status === 1) {
          foundPTypes.value = (result.data.discounts || []).map(
            (item: any) => ({
              id: item.discountName,
              text: item.discountName,
              html: getPTypesHtml(item),
              data: item
            })
          );
        }
        isACILoading.value = false;
      }
    };

    const selectPType = (data: any, item: any) => {
      if (data) {
        item.name.value = data.discountName;
        item.code.value = data.discountCode;

        const postingCode = data.postingCode || "";
        const glCode = data.glCode || "";
        const costCenterNumber = data.costCenterNumber || "";
        item.postingCodes.value = [];
        if (postingCode != "" && glCode != "") {
          item.postingCodes.value = [
            {
              id: postingCode,
              text: `${postingCode} / ${glCode}`
            }
          ];
        }
        item.costCenters.value = [];
        if (costCenterNumber != "") {
          item.costCenters.value = [
            {
              id: costCenterNumber,
              text: costCenterNumber
            }
          ];
        }
        componentData.value.itemEdit.item = item;
      }
    };

    const onSuggestTags = async (key: string, selectedItem: any) => {
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/tags/search",
        {},
        {
          // typeUse: "finance",
          key: key,
          notInIds: notInIds
        }
      );
      if (result?.status === 1) {
        selectedItem.suggestTags = result.data.tags.map((item: any) => {
          return {
            id: item.id,
            text: item.text,
            data: item
          };
        });
      }
    };

    const onAddTag = (selectedItem: any) => {
      const key = selectedItem.key.trim();
      if (key) {
        selectedItem.value.push({
          id: 0,
          text: key
        });
        selectedItem.key = "";
      }
    };

    const onRemoveLastTag = (selectedItem: any) => {
      if (selectedItem.value.length > 0 && selectedItem.key === "") {
        const index = selectedItem.value.length - 1;
        selectedItem.value.splice(index, 1);
      }
    };
    const onRemoveTag = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectTag = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        text: item.text,
        data: item.data || {}
      });
    };

    // posting codes
    const onSuggestPostingCodes = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        componentData.value.totalDataApplyTopPostCode = 0;
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/finances/postingCodes",
        {},
        {
          key,
          notInIds
        }
      );
      if (result.status === 1) {
        componentData.value.totalDataApplyTopPostCode =
          result.data.items.length;
        selectedItem.suggestTags = result.data.items
          .filter((item: any) => item.postingCodeDesc != "")
          .map((item: any) => {
            return {
              id: item.postingCode,
              // text: `${item.postingCode} / ${item.glCode}`,
              text: item.postingCodeDesc,
              data: item
            };
          });
      }
    };

    const onRemoveLastPostingCode = (selectedItem: any) => {
      // if (selectedItem.value.length > 0 && selectedItem.key === "") {
      //   const index = selectedItem.value.length - 1;
      //   selectedItem.value.splice(index, 1);
      // }
    };
    const onRemovePostingCodes = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectPostingCode = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value.push({
        id: item.id,
        // text: item.text
        text: `${item.data.postingCode} / ${item.data.glCode}`
      });
    };

    const showNewPostingCodeModal = (
      postingCode: string,
      selectedAddon: any,
      index: number
    ) => {
      if (postingCode == "") return;

      const postingCodeRef: any = context.refs.postingCodeRef;

      // auto selecting if existed in suggested items
      const existed = selectedAddon.postingCodes.suggestTags.find(
        (item: any) => `${item.id}`.toLowerCase() == postingCode.toLowerCase()
      );
      if (existed) {
        // auto select
        selectedAddon.postingCodes.value.push({
          id: existed.id,
          text: existed.text
        });
        selectedAddon.postingCodes.key = "";
        if (postingCodeRef[index]) {
          postingCodeRef[index].show = false;
        }

        return;
      }
      componentData.value.controls.postingCode.value = postingCode;
      newPostingCode.value.modalVisible = true;
      newPostingCode.value.postingCode = postingCode;
      newPostingCode.value.currentAddOn = selectedAddon;
      if (postingCodeRef[index]) {
        postingCodeRef[index].show = false;
      }
    };

    const onSuggestGLCode = async () => {
      const notInIds = glCodes.value.value
        .map((item: any) => item.id)
        .join(",");
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/glCodes",
        {},
        {
          gl: glCodes.value.key || "",
          notInIds
        }
      );
      if (result.status === 1) {
        glCodes.value.suggestTags = result.data.items.map((code: string) => {
          return {
            id: code,
            text: code
          };
        });
      }
    };

    const onSelectGLCode = (selectedValue: any) => {
      glCodes.value.error = "";
      glCodes.value.key = selectedValue.id;
      glCodes.value.suggestTags = [];
    };

    const savePostingCode = async () => {
      // const postingCode = newPostingCode.value.postingCode || "";
      const postingCode = componentData.value.controls.postingCode.value;
      const glCode = glCodes.value.key || "";
      const addonServiceId = newPostingCode.value.currentAddOn.id || 0;
      // reset
      glCodes.value.error = "";

      // validate
      let valid = true;
      // if (postingCode == "") return;
      if (glCode == "") {
        valid = false;
        glCodes.value.error = "GL Code is required!";
      }
      if (componentData.value.controls.postingCode.value == "") {
        valid = false;
        componentData.value.controls.postingCode.error =
          "Posting Code is required!";
      }
      if (componentData.value.controls.postingCodeDesc.value == "") {
        valid = false;
        componentData.value.controls.postingCodeDesc.error =
          "Posting Code Name is required!";
      }
      const glName = componentData.value.controls.glName.value;
      if (glName == "") {
        valid = false;
        componentData.value.controls.glName.error = "GL Name is required!";
      }

      if (valid) {
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/postingcodes/0`,
          {
            code: postingCode,
            glCode,
            glName,
            desc: componentData.value.controls.postingCodeDesc.value
          },
          {}
        );
        if (result.status == 1) {
          // update posting codes for current addon
          const currentAddOn = componentData.value.items.find(
            item => item.id == addonServiceId
          );
          if (currentAddOn) {
            currentAddOn.postingCodes.value.push({
              id: postingCode,
              text: `${postingCode} / ${glCode}`
            });
            currentAddOn.postingCodes.key = "";
          }
          newPostingCode.value.postingCode = "";
          componentData.value.controls.postingCodeDesc.value = "";
          glCodes.value.key = "";
          glCodes.value.value = [];
          glCodes.value.suggestTags = [];
          newPostingCode.value.modalVisible = false;
          newPostingCode.value.currentAddOn = {};
        } else {
          ApiHelper.showErrorMessage(
            result.message || `Can't create new Posting Code`
          );
        }
      }
    };

    // cost centers
    const onSuggestCostCenters = async (key: string, selectedItem: any) => {
      if (!key) {
        selectedItem.suggestTags = [];
        componentData.value.totalDataApplyTopCostCenter = 0;
        // return;
      }
      const notInIds = selectedItem.value.map((item: any) => item.id).join(",");
      isACILoading.value = true;
      const result = await ApiHelper.callApi(
        "get",
        "/accounting/costcenters",
        {},
        {
          getAll: 1,
          order: 1,
          direction: 1,
          key,
          notInIds
        }
      );
      isACILoading.value = false;
      if (result.status === 1) {
        componentData.value.totalDataApplyTopCostCenter =
          result.data.items.length;
        selectedItem.suggestTags = result.data.items.map((item: any) => {
          return {
            id: item.costCenterNumber,
            text: item.costCenterNumber,
            data: item
          };
        });
      }
    };

    const onRemoveLastCostCenter = (selectedItem: any) => {
      if (selectedItem.value.length > 0 && selectedItem.key === "") {
        const index = selectedItem.value.length - 1;
        selectedItem.value.splice(index, 1);
      }
    };
    const onRemoveCostCenter = (index: number, selectedItem: any) => {
      if (selectedItem.value.length > index) {
        selectedItem.value.splice(index, 1);
      }
    };

    const onSelectCostCenter = (item: any, selectedItem: any) => {
      selectedItem.key = "";
      selectedItem.suggestTags = [];
      selectedItem.value = [];
      selectedItem.value.push({
        id: item.id,
        text: item.text
      });
      // context.emit("onSelectCostCenter", item);
    };

    const resetFormAddCostCenter = () => {
      newCostCenter.value.modalVisible = false;
      newCostCenter.value.isProcessing = false;
      componentData.value.controls.costCenterNumber.value = "";
      componentData.value.controls.costCenterName.value = "";
      componentData.value.controls.costCenterNumber.error = "";
    };

    const onAddNewCostCenter = async (
      key: string,
      selectedItem: any,
      index: number
    ) => {
      // Call from popup add new cost
      if (
        componentData.value.controls.costCenterNumber.value === "" &&
        key.trim() == ""
      ) {
        componentData.value.controls.costCenterNumber.error =
          "Number is required";
        newCostCenter.value.isProcessing = false;
        return;
      }
      if (key.trim() == "") return;

      if (!newCostCenter.value.isProcessing) {
        newCostCenter.value.modalVisible = true;
        newCostCenter.value.item = selectedItem;
        newCostCenter.value.index = index;
        // Handle name or number
        if (!isNaN(key as any)) {
          componentData.value.controls.costCenterNumber.value = key;
        } else {
          componentData.value.controls.costCenterName.value = key;
        }
      } else {
        // Add new cost center
        const costCenterRef: any = context.refs.costCenterRef;
        // auto selecting if cost center number existed in suggested items
        const existed = selectedItem.suggestTags.find(
          (item: any) => `${item.id}`.toLowerCase() == key.toLowerCase()
        );
        if (existed) {
          // auto select
          selectedItem.value.push({
            id: existed.id,
            text: existed.text
          });
          selectedItem.key = "";
          if (costCenterRef[index]) {
            costCenterRef[index].show = false;
          }
          context.emit("onSelectCostCenter", existed);

          return;
        }
        // call api add new cost center number
        const result = await ApiHelper.callApi(
          "put",
          `/accounting/costcenters/0`,
          {
            number: componentData.value.controls.costCenterNumber.value,
            name: componentData.value.controls.costCenterName.value
          },
          {}
        );

        if (result.status == 1) {
          // Reset data after call api success
          resetFormAddCostCenter();
          // update cost centers for current addon
          selectedItem.value.push({
            id: key,
            text: key
          });
          selectedItem.key = "";
          if (costCenterRef[index]) {
            costCenterRef[index].show = false;
          }
          context.emit("onSelectCostCenter", {
            id: key,
            text: key
          });
        } else {
          // Reset button submit
          newCostCenter.value.isProcessing = false;
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
      }
    };

    const isEditingDiscount = () => {
      const isEditing = componentData.value.items.find(
        item => (item.isEditing || false) == true
      );
      if (isEditing) {
        return true;
      }

      return false;
    };

    const onFocusCoscenter = (index: number, item: any) => {
      const eventCoscenterNumber: any = props.eventCoscenterNumber;
      if (
        eventCoscenterNumber.length &&
        item.value.length == 0 &&
        item.key == "" &&
        componentData.value.firstCost
      ) {
        componentData.value.firstCost = false;
        // auto fill coscenter number
        // item.key = eventCoscenterNumber[0].id;
        // auto select
        item.value = [
          {
            id: eventCoscenterNumber[0].id,
            text: eventCoscenterNumber[0].id
          }
        ];
      }
    };

    const getPtypeName = (id: string) => {
      const options: any[] =
        props.data && props.data.ptypes ? props.data.ptypes : [];
      const optionValue = options.find(item => {
        return item.id + "" === id;
      });
      if (optionValue) {
        return optionValue.name;
      }
      return "";
    };

    const checkDiscountType = (item: any) => {
      item.discountType.error = "";
      if (parseInt(item.discountType.value) === 2) {
        item.code.error = "";
        item.availableDate.error = "";
      }
    };

    return {
      onSuggestTags,
      onAddTag,
      onRemoveLastTag,
      onRemoveTag,
      onSelectTag,
      changeDiscountAmount,
      changeDiscountPercent,
      loadData,
      addNewRow,
      doCancelItem,
      doSaveItem,
      doDeleteItem,
      doEditItem,
      componentData,
      stripTags: ApiHelper.stripTags,
      amountTypeSwitch,
      amountTypeInput,
      onSuggestPostingCodes,
      onRemoveLastPostingCode,
      onRemovePostingCodes,
      onSelectPostingCode,
      showNewPostingCodeModal,
      newPostingCode,
      newCostCenter,
      glCodes,
      onSuggestGLCode,
      onSelectGLCode,
      savePostingCode,
      onSuggestCostCenters,
      onRemoveLastCostCenter,
      onRemoveCostCenter,
      onSelectCostCenter,
      onAddNewCostCenter,
      isEditingDiscount,
      onFocusCoscenter,
      isACILoading,
      foundPTypes,
      suggestPTypes,
      selectPType,
      ApiHelper: ApiHelper,
      getPtypeName,
      resetFirtfocus,
      resetFormAddCostCenter,
      checkDiscountType
    };
  },
  async mounted() {
    // init data
    await this.loadData();
  },
  methods: {
    hoverEnter(event: any) {
      if (event.clientY > 570 && this.componentData.totalDataApplyTop > 3) {
        this.componentData.allowTop = true;
      } else {
        this.componentData.allowTop = false;
      }
    },
    hoverEnterCostCenter(event: any) {
      if (
        event.clientY > 570 &&
        this.componentData.totalDataApplyTopCostCenter > 3
      ) {
        this.componentData.allowTopCostCenter = true;
      } else {
        this.componentData.allowTopCostCenter = false;
      }
    },
    hoverEnterPostCode(event: any) {
      if (
        event.clientY > 570 &&
        this.componentData.totalDataApplyTopPostCode > 3
      ) {
        this.componentData.allowTopPostCode = true;
      } else {
        this.componentData.allowTopPostCode = false;
      }
    },
    checkAllowAdd() {
      this.$emit("button-added");
    },
    checkAllowEdit(index: number, item: any) {
      this.componentData.itemEdit.index = index;
      this.componentData.itemEdit.item = item;
      this.$emit("button-edited");
    },
    allowEdit() {
      this.doEditItem(
        this.componentData.itemEdit.index,
        this.componentData.itemEdit.item
      );
      // this.componentData.itemEdit.index = -1;
      // this.componentData.itemEdit.item = [];
    },
    checkAllowClose(index: number, item: any) {
      this.$emit("button-deleted");
      this.doCancelItem(index, item);
    },
    checkDoSaveItem(index: number, item: any) {
      this.$emit("button-deleted");
      this.doSaveItem(index, item);
    }
  }
});
