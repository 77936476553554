






















import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Camp360 Admin Portal";
      }
    }
  },
  name: "UserMasterPage",
  components: {},
  setup(props, context) {
    const isDisabled = ref(false);
    return {
      isDisabled
    };
  }
});
