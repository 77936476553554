































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Header from "@/components/Common/Header.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import PopupPayment from "@/components/Financial/PopupPayment.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import { useReportsStore } from "@/stores/Report/ReportsStore";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import FormInput from "@/components/Form/FormInput.vue";
import Modal from "@/components/Common/Modal.vue";

export default defineComponent({
  name: "Reports",
  components: {
    FormInput,
    Loading,
    Header,
    Pager,
    TableHeaderColumn,
    PopupPayment,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    FilterDateColumn,
    Modal
  },
  setup(props, context) {
    const {
      popupDuplicateReport,
      // actions
      duplicateReport,
      exportReport,
      deleteReport,
      // page
      loadData,
      pageData,
      headerData,
      updateRouters,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      // pager
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems
    } = useReportsStore(context);

    const popupData = ref({
      profileId: 0,
      eventId: 0,
      show: false
    });

    return {
      popupDuplicateReport,
      // actions
      duplicateReport,
      exportReport,
      deleteReport,
      // page
      loadData,
      popupData,
      pageData,
      headerData,
      updateRouters,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      // pager
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
