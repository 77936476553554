








import { defineComponent } from "@vue/composition-api";


export default defineComponent({
  name: "MessagingInboxDetailsPage",
  components: {},
  setup(props, context) {
    

    return {};
  },
  beforeRouteUpdate(to, from, next) {
    next();
  }
});
