var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"item__status have__promt",class:{ active: _vm.item.id == _vm.pageData.openItemId }},[_c('div',{staticClass:"status__current",on:{"click":function($event){return _vm.showStatusDropdown(_vm.item.id)}}},[_c('span',{class:{
        status__green: _vm.item.status == 0,
        status__blue: _vm.item.status == 1,
        status__red: _vm.item.status == 2
      }})]),_c('div',{staticClass:"status__box"},[_c('div',{staticClass:"box__popover"},[(_vm.options)?_c('ul',_vm._l((_vm.options),function(option){
      var _obj;
return _c('li',{key:option.value,on:{"click":function () {
              _vm.updateStatus(_vm.item.id, option.value);
              _vm.item.status = option.value;
              _vm.showStatusDropdown(0);
            }}},[_c('div',{staticClass:"li__icon"},[_c('span',{staticClass:"icon",class:( _obj = {}, _obj[option.class] = true, _obj )})]),_c('div',{staticClass:"li__text"},[_vm._v(_vm._s(option.text))])])}),0):_vm._e()])]),_c('div',{staticClass:"status__percent"},[_vm._v(" "+_vm._s(_vm.item.percent || "")+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }