































































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "FormEmail",
  props: {
    data: Object,
    hideErrorMessage: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const randomId = ref<number>(ApiHelper.randomFormId());
    return {
      randomId: randomId.value.toString(),
      stripTags: ApiHelper.stripTags
    };
  }
});
