import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";

export function useReportEditStore(context: any) {
  const selectedId = parseInt(context.root.$route.params.id) || 0;
  const headerData = ref({
    title: "",
    subTitle: "Reports",
    error: "",
    saving: false
  });

  const pageData = ref<{
    isLoading: boolean;
    presetFieldsData: any[];
    presetFieldsDataGrouped: any[];
    activeModuleId: number;
    selectedElements: any[];
    selectedElementsError: string;
    elementConditions: any[];
    dropArea: string;
    clonedElement: any;
    events: any[];
    eventsError: string;
    selectedEvents: any[];
    selectAll: boolean;
    whereMessage: string;
    previewReportVisible: boolean;
  }>({
    isLoading: false,
    presetFieldsData: [],
    presetFieldsDataGrouped: [],
    activeModuleId: 0,
    selectedElements: [],
    selectedElementsError: "",
    elementConditions: [],
    dropArea: "",
    clonedElement: {},
    events: [],
    eventsError: "",
    selectedEvents: [],
    selectAll: false,
    whereMessage: "",
    previewReportVisible: false
  });

  const getPresetFields = async (options: any) => {
    const result = await ApiHelper.callApi(
      "get",
      "/applications/presetFields",
      {},
      options
    );
    if (result.status === 1) {
      return result.data.presetFields || [];
    }
    return [];
  };

  const loadData = async () => {
    ApiHelper.setDataLoading(true);
    try {
      // get elements
      pageData.value.presetFieldsData = await getPresetFields({
        type: "app",
        // order by module name
        order: "2",
        isSystem: "0,1"
      });
      // group elements by modules
      if (pageData.value.presetFieldsData.length) {
        const presetFieldsDataGrouped = [];
        const moduleIds: any = [];
        const moduleFields = pageData.value.presetFieldsData.filter(
          item => item.isSystem == 0
        );
        const systemFields = pageData.value.presetFieldsData.filter(
          item => item.isSystem == 1
        );
        for (const item of moduleFields) {
          if (item.moduleId && !moduleIds.includes(item.moduleId)) {
            moduleIds.push(item.moduleId);
            const elements = moduleFields.filter(
              f => f.moduleId == item.moduleId
            );
            presetFieldsDataGrouped.push({
              moduleId: item.moduleId,
              moduleName: item.moduleName,
              elements
            });
          }
        }
        pageData.value.presetFieldsDataGrouped = presetFieldsDataGrouped;

        // prepend system fields
        if (systemFields.length > 0) {
          pageData.value.presetFieldsDataGrouped.unshift({
            moduleId: 0,
            moduleName: "System fields",
            elements: systemFields
          });
        }
      }

      if (selectedId >= 0) {
        // get report info
        const query: any = context.root.$route.query;
        const result = await ApiHelper.callApi(
          "get",
          `/reports/${selectedId}`,
          {},
          {
            isCoreReport: parseInt(query.isCoreReport || 0)
          }
        );
        if (result.status == 1) {
          headerData.value.title = result.data.name || "";
          pageData.value.events = result.data.events || [];

          const reportDefinition = result.data.reportDefinition || {};
          // check selected events
          if ((reportDefinition.Events || "") != "") {
            pageData.value.selectedEvents = reportDefinition.Events.split(",");
            pageData.value.selectAll =
              pageData.value.selectedEvents.length ==
              pageData.value.events.length;
          }

          // check selected elements
          for (const item of reportDefinition.Columns || []) {
            const relatedItem = pageData.value.presetFieldsData.find(
              f => f.eAppFieldId == item.eAppFieldId
            );

            if (relatedItem) {
              pageData.value.selectedElements.push({
                ...relatedItem,
                customLabel: item.customLabel || ""
              });
            }
          }

          // check selected elements conditions
          for (const item of reportDefinition.Conditions || []) {
            const relatedItem = pageData.value.presetFieldsData.find(
              f => f.eAppFieldId == item.eAppFieldId
            );
            if (relatedItem) {
              pageData.value.elementConditions.push({
                ...relatedItem,
                operator: item.operator || "",
                condition: item.condition || ""
              });
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      ApiHelper.setDataLoading(false);
    }
  };

  const saveReport = async () => {
    // reset
    let hasError = false;
    pageData.value.whereMessage = "";
    headerData.value.error = "";
    pageData.value.elementConditions = pageData.value.elementConditions.map(
      item => ({
        ...item,
        hasError: false
      })
    );

    // validate report name
    if (headerData.value.title == "") {
      hasError = true;
      headerData.value.error = "Report name is required!";
    }

    if (pageData.value.selectedElements.length == 0) {
      hasError = true;
      pageData.value.selectedElementsError = "Element is required!";
    }

    if (pageData.value.selectedEvents.length == 0) {
      hasError = true;
      pageData.value.eventsError = "Event is required!";
    }
    if (pageData.value.elementConditions.length) {
      const itemHasNoCondition = pageData.value.elementConditions.filter(
        item => (item.operator || "") == ""
      );
      if (itemHasNoCondition.length) {
        pageData.value.whereMessage = "Please select operator!";
        pageData.value.elementConditions = pageData.value.elementConditions.map(
          item => ({
            ...item,
            hasError: itemHasNoCondition.find(
              t => t.eAppFieldId == item.eAppFieldId
            )
              ? true
              : false
          })
        );
        hasError = true;
      }
    }

    if (hasError) {
      return;
    }

    try {
      headerData.value.saving = true;

      // build reportDefinition
      const reportDefinition = {
        Columns: pageData.value.selectedElements.map((item, i: number) => ({
          eAppFieldId: item.eAppFieldId,
          moduleId: item.moduleId,
          elementKey: item.eAppFieldKey,
          elementLabel: item.eAppFieldName,
          customLabel: item.customLabel,
          moduleVerson: item.moduleVerson,
          position: i
        })),
        Conditions: pageData.value.elementConditions.map((item, i: number) => ({
          eAppFieldId: item.eAppFieldId,
          moduleId: item.moduleId,
          elementKey: item.eAppFieldKey,
          elementLabel: item.eAppFieldName,
          moduleVerson: item.moduleVerson,
          position: i,
          operator: item.operator || "",
          condition: item.condition || ""
        })),
        Events: pageData.value.selectedEvents.join(",")
      };

      if (selectedId) {
        // update
        const result = await ApiHelper.callApi(
          "put",
          "/reports/" + selectedId,
          {
            reportName: headerData.value.title,
            reportDefinitionItem: reportDefinition
          }
        );
        if (result.status == 1) {
          ApiHelper.gotoPage(context, { name: "Reports" });
          ApiHelper.showSuccessMessage("Updated");
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      } else {
        // add new
        const result = await ApiHelper.callApi("post", "/reports", {
          reportName: headerData.value.title,
          reportDefinitionItem: reportDefinition
        });
        if (result.status == 1) {
          ApiHelper.gotoPage(context, { name: "Reports" });
          ApiHelper.showSuccessMessage("Added #" + result.data.reportId);
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      headerData.value.saving = false;
    }
  };

  return {
    saveReport,
    headerData,
    pageData,
    loadData,
    getPresetFields
  };
}
