
















































































import { defineComponent } from "@vue/composition-api";

import $ from "jquery";
import { useSettingsParticipantFormsDetailsStore } from "@/stores/Settings/ParticipantForms/SettingsParticipantFormsDetailsStore";
import Loading from "@/components/Common/Loading.vue";
import BackButton from "@/components/Common/BackButton.vue";
import SignaturePad from "signature_pad";
import { ApiHelper } from "@/helpers";
import {
  registerAllergies,
  registerMedication,
  registerTransportation,
  registerAddressBlock
} from "@/helpers/FbControls";
import Header from "@/components/Common/Header.vue";
declare const window: any;
require("formBuilder/dist/form-render.min.js");

require("jquery-ui-sortable");
export default defineComponent({
  name: "SettingsParticipantFormsDetailsPage",
  components: {
    Header,
    Loading,
    BackButton
  },
  setup(props, context) {
    const { headerData, pageData } = useSettingsParticipantFormsDetailsStore(
      context
    );
    return {
      headerData,
      pageData
    };
  },
  updated() {
    const devBuilder: any = $(this.$refs.formBuilder);
    const formRenderData = devBuilder.data("json") || [];
    formRenderData.map((control: any) => {
      if (control.type == "file" && control.subtype == "fineuploader") {
        control.subtype = "file";
      }
    });
    const updateFileDownload = (fileSelector: any) => {
      const downloadUrl = fileSelector.attr("download") || "";
      if (downloadUrl) {
        const label = fileSelector.parent().find(".formbuilder-file-label");
        label.append(
          `<strong class="pl-3"><a class="download-link" target="_blank" download href="${downloadUrl}">Download template file</a></strong>`
        );
      }
    };
    devBuilder.formRender({
      formData: JSON.stringify(formRenderData),
      notify: {
        error: (message: any) => {
          return message;
        },
        success: (message: any) => {
          const files = devBuilder.find("input[type=file]");
          files.each((index: number, value: any) => {
            const $file = $(value);
            $file.attr(
              "accept",
              "application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/x-png,image/gif,image/jpeg"
            );
            updateFileDownload($file);
          });

          // update for Conditional Options item
          const conditionalOptions = devBuilder.find(
            ".formbuilder-conditionalOptions"
          );
          if (conditionalOptions.length) {
            conditionalOptions.each((i: number, obj: any) => {
              const field = $(obj);
              field
                .addClass("formbuilder-radio-group")
                .find(`input[type=radio]`)
                .each((j: number, objRadio: any) => {
                  $(objRadio).on("click", () => {
                    const val = `${$(objRadio)?.val() || ""}`.toLowerCase();
                    const id = $(objRadio)?.prop("name") || "";
                    field.attr("data-value", val);
                    if (val == "no") {
                      // clear explain textbox
                      $(`#explain-${id}`).val("");
                    }
                  });
                });
            });
          }

          // case: not allow select multiple for the checkbox group
          devBuilder.on("click", "input[name*=checkbox-group-]", (e: any) => {
            const cb = $(e.target);
            const multiple = cb.attr("multiple-selections") || "true";
            const cbId = cb.prop("id") || "";
            if (multiple == "false" && cbId != "") {
              cb.closest(".checkbox-group")
                .find(`input[type=checkbox]:not(#${cbId})`)
                .prop("checked", false);
            }
          });

          // remove <br> tag in .formbuilder-required
          $(".formbuilder-required").each((i, obj) => {
            $(obj)
              .closest("label")
              .addClass("field-label");
            const prev = $(obj).prev();
            if (prev.length && prev.prop("tagName") == "BR") {
              prev.remove();
            }
          });

          return message;
        },
        warning: (message: any) => {
          return message;
        }
      }
    });
  },
  mounted() {
    window.fbControls = [];
    window.fbControls.push((controlClass: any) => {
      class ControlSignature extends controlClass {
        build() {
          return `
                    <div>
                        <div style="background-color: #eee; padding: 10px;">
                          <canvas id="${this.config.name}" style="touch-action: none; background: white;" height="65" width="352"></canvas>
                        </div>
                    </div>`;
        }
        onRender() {
          const canvas: any = document.getElementById(this.config.name);
          new SignaturePad(canvas, {
            backgroundColor: "rgb(255, 255, 255)"
          });
        }
      }
      controlClass.register("signature", ControlSignature);
      return ControlSignature;
    });

    window.fbControls.push((controlClass: any) => {
      class ControlConditionalOptions extends controlClass {
        build() {
          const id = this.id;
          const required = this.required || false;
          const requiredAttr = required
            ? 'required="required" aria-required="true"'
            : "";
          return `
            <div class="radio-group">
              <div class="formbuilder-radio">
                <input name="${id}" id="${id}-0" ${requiredAttr} value="Yes" type="radio"><label for="${id}-0">Yes</label>
              </div>
              <div class="formbuilder-radio">
                <input name="${id}" id="${id}-1" ${requiredAttr} value="No" type="radio"><label for="${id}-1">No</label>
              </div>
            </div>
          `;
        }
        onRender() {
          // const id = this.id;
          // const field = $(`.field-${id}`);
          // const userData = this.config.userData || [];
          // if (field.length) {
          //   field.addClass("formbuilder-radio-group");
          //   if ((userData[0] || "") != "") {
          //     field
          //       .attr("data-value", userData[0].toLowerCase())
          //       .find(`input[type=radio][value=${userData[0]}]`)
          //       .prop("checked", true);
          //   }
          //   field.find(`input[type=radio]`).each((i, obj) => {
          //     $(obj).on("click", () => {
          //       const val = `${$(obj)?.val() || ""}`.toLowerCase();
          //       field.attr("data-value", val);
          //       if (val == "no") {
          //         // clear explain textbox
          //         $(`#explain-${id}`).val("");
          //       }
          //     });
          //   });
          // }
        }
      }

      // register this control
      controlClass.register("conditionalOptions", ControlConditionalOptions);
      return ControlConditionalOptions;
    });

    registerMedication();
    registerAllergies();
    registerTransportation();
    registerAddressBlock();
  }
});
