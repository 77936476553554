











































































import { defineComponent } from "@vue/composition-api";
import Loading from "@/components/Common/Loading.vue";
import directives from "@/helpers/directives";
import BackButton from "@/components/Common/BackButton.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormToggle from "@/components/Form/FormToggle.vue";
import Header from "@/components/Common/Header.vue";
import { useSettingsDiscountEditStore } from "@/stores/Settings/Discounts/SettingsDiscountEditStore";
import FormDatePicker from "@/components/Form/FormDatePicker.vue";
import FormNumber from "@/components/Form/FormNumber.vue";

export default defineComponent({
  name: "SettingsDiscountEdit",
  components: {
    FormNumber,
    FormDatePicker,
    Header,
    FormSelect,
    FormInput,
    Loading,
    BackButton,
    FormToggle
  },
  directives: directives,
  setup(props, context) {
    const selectedId = parseInt(context.root.$route.params.id) || 0;
    const {
      loadPtypeOptions,
      headerData,
      pageData,
      onSubmit
    } = useSettingsDiscountEditStore(context);

    return {
      selectedId,
      loadPtypeOptions,
      headerData,
      pageData,
      onSubmit
    };
  }
});
