









































import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "HeaderFilters",
  props: {
    filters: Object,
    position: {
      type: String,
      defaultValue: ""
    }
  },
  components: {},
  setup(props, context) {
    const isShowFilters = ref<boolean>(false);
    const hideFilters = () => {
      isShowFilters.value = false;
    };
    const toggleFilters = () => {
      if (props.filters && props.filters.list.length > 0) {
        isShowFilters.value = !isShowFilters.value;
      } else {
        isShowFilters.value = false;
      }
    };
    return {
      isShowFilters,
      hideFilters,
      toggleFilters
    };
  }
});
