






































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import directives from "@/helpers/directives";
import Vue from "vue";
import moment from "moment";
import FormTags from "@/components/Form/FormTags.vue";
import Modal from "@/components/Common/Modal.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import { v4 as uuidv4 } from "uuid";
import FormDatePicker2 from "../Form/FormDatePicker2.vue";
import FormInput from "@/components/Form/FormInput.vue";
import AutoCompleteInput from "@/components/AutoCompleteInput.vue";
import FormButton from "@/components/Form/FormButton.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import $ from "jquery";

export default defineComponent({
  name: "EventGroups",
  props: {
    eventId: Number,
    isTopBottom: Function,
    data: Object,
    disabled: {
      type: Boolean,
      defaultValue: false
    }
  },
  directives: directives,
  components: {
    FormInput,
    FormTags,
    Modal,
    FormTextarea,
    FormDatePicker2,
    AutoCompleteInput,
    FormNumber,
    FormButton
  },
  setup(props, context) {
    const isACILoading = ref<boolean>(false);
    const componentData = ref<{
      items: any[];
      allowAdd: boolean;
      allowTop: boolean;
      totalDataApplyTop: number;
      isLoading: boolean;
      editingIndex: number;
      controls: {};
      itemEdit: {
        item: any;
        index: number;
      };
      portalLink: string;
    }>({
      items: [],
      allowAdd: true,
      allowTop: false,
      totalDataApplyTop: 0,
      isLoading: false,
      editingIndex: -1,
      controls: {},
      itemEdit: {
        item: [],
        index: -1
      },
      portalLink: ""
    });
    const foundGroups = ref<any[]>([]);

    const loadData = async () => {
      const eventId = parseInt(`${props.eventId || 0}`);
      if (!eventId) {
        const copiedGroupItems = props.data?.copiedGroupItems || [];
        if (copiedGroupItems.length) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          addRows(copiedGroupItems);
        }
        return;
      }

      componentData.value.isLoading = true;
      const [result] = await Promise.all([
        ApiHelper.callApi(
          "get",
          "/events/registrationGroups",
          {},
          {
            eventIds: `${props.eventId}`,
            getAll: 1
          }
        )
      ]);
      componentData.value.isLoading = false;
      if (result.status === 1) {
        componentData.value.allowAdd = true;
        componentData.value.editingIndex = -1;
        componentData.value.portalLink = result.data.portalLink || "";
        componentData.value.items = (result.data.items || []).map(
          (item: any) => {
            return {
              id: item.groupId,
              linkId: item.linkId || 0,
              name: {
                value: item.groupName || "",
                error: ""
              },
              ptype: {
                value: item.pTypeId + "",
                error: ""
              },
              active: {
                value: !!item.isActive,
                error: ""
              },
              capacityFemale: {
                value: isNaN(parseInt(item.capacityFemale))
                  ? ""
                  : item.capacityFemale + "",
                error: ""
              },
              capacityMale: {
                value: isNaN(parseInt(item.capacityMale))
                  ? ""
                  : item.capacityMale + "",
                error: ""
              },
              capacityTotal: {
                value: isNaN(parseInt(item.capacityTotal))
                  ? ""
                  : item.capacityTotal + "",
                error: ""
              },
              groupUuid: item.uuid || "",
              regCnt: item.regCnt || 0,
              regPercent: item.regPercent || 0,
              regFemaleCnt: item.regFemaleCnt || 0,
              regMaleCnt: item.regMaleCnt || 0,
              isEditing: false
            };
          }
        );
      }

      // if (pTypes.status == 1 && props.data) {
      //   props.data.ptypes = (pTypes.data.ptypes || []).map((item: any) => ({
      //     id: item.participantTypeId,
      //     name: item.participantTypeName,
      //     cost: item.cost,
      //     capacity: item.capacity,
      //     capacityFemale: item.capacityFemale,
      //     capacityMale: item.capacityMale
      //   }));
      // }
    };

    const addNewRow = () => {
      componentData.value.items.map((value: any) => {
        value.isEditing = false;
      });
      componentData.value.allowAdd = false;
      componentData.value.items.push({
        uuid: uuidv4(),
        id: 0,
        name: {
          value: "",
          error: ""
        },
        ptype: {
          value: "0",
          error: ""
        },
        active: {
          value: true,
          error: ""
        },
        capacityFemale: {
          value: "",
          error: ""
        },
        capacityMale: {
          value: "",
          error: ""
        },
        capacityTotal: {
          value: "",
          error: ""
        },
        groupUuid: "",
        isEditing: true
      });
      componentData.value.editingIndex = componentData.value.items.length - 1;
    };

    const addRows = (items = []) => {
      if (!items.length) return;

      componentData.value.allowAdd = true;
      componentData.value.editingIndex = -1;
      componentData.value.items = (items || []).map((item: any) => {
        return {
          uuid: uuidv4(),
          id: 0,
          name: {
            value: item.groupName || "",
            error: ""
          },
          ptype: {
            value: item.pTypeId || "0",
            error: ""
          },
          active: {
            value: true,
            error: ""
          },
          capacityFemale: {
            value: isNaN(parseInt(item.capacityFemale))
              ? ""
              : item.capacityFemale,
            error: ""
          },
          capacityMale: {
            value: isNaN(parseInt(item.capacityMale)) ? "" : item.capacityMale,
            error: ""
          },
          capacityTotal: {
            value: isNaN(parseInt(item.capacityTotal))
              ? ""
              : item.capacityTotal,
            error: ""
          },
          groupUuid: "",
          regCnt: 0,
          isEditing: false
        };
      });
    };

    const doCancelItem = (index: number, item: any) => {
      componentData.value.editingIndex = -1;
      componentData.value.allowAdd = true;
      item.isEditing = false;

      const eventId = parseInt(`${props.eventId || 0}`);
      if (!eventId) {
        // add event page
        componentData.value.items = componentData.value.items.filter(
          t => t.name.value != ""
        );
        return;
      }

      // edit event page
      loadData();
    };

    const doSaveItem = async (index: number, item: any) => {
      const eventId = parseInt(`${props.eventId || 0}`);
      item.name.error = "";
      item.active.error = "";
      item.ptype.error = "";
      item.capacityFemale.error = "";
      item.capacityMale.error = "";
      item.capacityTotal.error = "";
      const groupName = item.name.value || "";
      let pTypeId = parseInt(item.ptype.value) || 0;
      let selectedPType = (props.data?.ptypes || []).find(
        (t: any) => t.id == pTypeId
      );

      if (eventId == 0) {
        // add event page
        pTypeId = item.ptype.value; // uuid
        selectedPType = (props.data?.ptypes || []).find(
          (t: any) => (t.uuid || "") == pTypeId
        );
      }

      const capacityFemale = isNaN(parseInt(item.capacityFemale.value))
        ? null
        : parseInt(item.capacityFemale.value);
      const capacityMale = isNaN(parseInt(item.capacityMale.value))
        ? null
        : parseInt(item.capacityMale.value);
      const capacityTotal = isNaN(parseInt(item.capacityTotal.value))
        ? null
        : parseInt(item.capacityTotal.value);

      let hasError = false;
      if (groupName === "") {
        hasError = true;
        item.name.error = "Name is required";
      }
      if (
        (eventId > 0 && pTypeId == 0) ||
        (eventId == 0 && item.ptype.value == "0")
      ) {
        hasError = true;
        item.ptype.error = "Type is required";
      }

      if (capacityTotal == 0) {
        hasError = true;
        item.capacityTotal.error = "Total capacity is required";
      }

      // some capacity setting should based on participant type capacity
      if (selectedPType) {
        // check group capacity female
        if (!hasError) {
          if (parseInt(selectedPType.capacityFemale) >= 0) {
            if (capacityFemale == null) {
              hasError = true;
              const message = "Capacity Female is required";
              item.capacityFemale.error = message;
              ApiHelper.showErrorMessage(message);
            } else if (
              capacityFemale > parseInt(selectedPType.capacityFemale)
            ) {
              // set a group capacity female over ptype capacity female
              hasError = true;
              const message = `Capacity Female should not greater than capacity female of "${selectedPType.name}"`;
              item.capacityFemale.error = message;
              ApiHelper.showErrorMessage(message);
            }
          }
        }

        // check group capacity male
        if (!hasError) {
          if (parseInt(selectedPType.capacityMale) >= 0) {
            if (capacityMale == null) {
              hasError = true;
              const message = "Capacity Male is required";
              item.capacityMale.error = message;
              ApiHelper.showErrorMessage(message);
            } else if (capacityMale > parseInt(selectedPType.capacityMale)) {
              // set a group capacity male over ptype capacity male
              hasError = true;
              const message = `Capacity Male should not greater than capacity male of "${selectedPType.name}"`;
              item.capacityMale.error = message;
              ApiHelper.showErrorMessage(message);
            }
          }
        }

        // check group capacity total
        if (!hasError) {
          if (parseInt(selectedPType.capacity) >= 0) {
            if (capacityTotal == null) {
              hasError = true;
              const message = "Total capacity is required";
              item.capacityTotal.error = message;
              ApiHelper.showErrorMessage(message);
            } else if (capacityTotal > parseInt(selectedPType.capacity)) {
              // set a group capacity over ptype capacity
              hasError = true;
              const message = `Total capacity should not greater than capacity of "${selectedPType.name}"`;
              item.capacityTotal.error = message;
              ApiHelper.showErrorMessage(message);
            }
          }
        }
      }

      if (capacityFemale != null && capacityMale != null) {
        const total = capacityFemale + capacityMale;
        if (parseInt(total.toFixed(2)) != capacityTotal) {
          hasError = true;
          const message = "Total capacity is not match";
          item.capacityTotal.error = message;
          ApiHelper.showErrorMessage(message);
        }
      }

      const existedItems = componentData.value.items.filter(
        (value: any) => !value.isEditing
      );
      if (groupName && selectedPType) {
        const sameName = existedItems.find((value: any) => {
          return (
            value.name.value.toUpperCase() === groupName.toUpperCase() &&
            pTypeId == parseInt(value.ptype.value)
          );
        });
        if (sameName) {
          item.name.error = "Name already exists.";
          hasError = true;
          ApiHelper.showErrorMessage(
            "Event / Type link with this group already exists. Please enter new information.",
            "Oops"
          );
        }
      }

      if (hasError) {
        return false;
      }

      if (eventId == 0) {
        componentData.value.allowAdd = true;
        item.isEditing = false;
        componentData.value.editingIndex = -1;
        return true;
      }

      if (item.linkId > 0) {
        componentData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "put",
          `/events/registrationGroups/links`,
          {
            groupName: item.name.value,
            linkId: item.linkId || 0,
            eventId: props.eventId,
            pTypeId,
            // active: item.active.value ? 1 : 0,
            capacityFemale,
            capacityMale,
            capacityTotal
          },
          {}
        );
        componentData.value.isLoading = false;
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          return false;
        }
        componentData.value.allowAdd = true;
        item.isEditing = false;
        componentData.value.editingIndex = -1;
        ApiHelper.showSuccessMessage("Updated");
        loadData();
      } else {
        componentData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "post",
          `/events/registrationGroups/links`,
          {
            groupName: item.name.value,
            pTypeId,
            eventId: props.eventId,
            // active: item.active.value ? 1 : 0,
            capacityFemale,
            capacityMale,
            capacityTotal
          },
          {}
        );
        componentData.value.isLoading = false;
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          return false;
        }
        componentData.value.allowAdd = true;
        item.isEditing = false;
        componentData.value.editingIndex = -1;
        ApiHelper.showSuccessMessage("Added");
        loadData();
      }

      return true;
    };

    const doDeleteItem = async (index: number, item: any) => {
      const isAgreed = await Vue.swal({
        html: "Are you sure you want to delete this group link?",
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        showCloseButton: true,
        closeButtonHtml:
          '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
      }).then(result => {
        setTimeout(function() {
          $(".swal2-backdrop-hide").addClass("d-none");
        }, 200);
        return result.isConfirmed;
      });

      if (isAgreed) {
        if ((item.linkId || 0) == 0) {
          const uuid = item.uuid || "";
          if (uuid) {
            componentData.value.items = componentData.value.items.filter(
              t => t.uuid != uuid
            );
          }
          return;
        }
        componentData.value.isLoading = true;
        const result = await ApiHelper.callApi(
          "delete",
          `/events/registrationGroups/links`,
          {},
          {
            groupId: item.id,
            linkId: item.linkId
          }
        );
        componentData.value.isLoading = false;
        if (result.status !== 1) {
          ApiHelper.showErrorMessage(result.message, "Oops");
          return;
        }
        componentData.value.editingIndex = -1;
        componentData.value.allowAdd = true;
        ApiHelper.showSuccessMessage("Deleted");
        loadData();
      } else {
        componentData.value.editingIndex = -1;
        componentData.value.allowAdd = true;
      }
    };

    const doEditItem = (index: number, item: any) => {
      componentData.value.items.map((value: any) => {
        value.isEditing = false;
      });
      componentData.value.editingIndex = index;
      componentData.value.allowAdd = false;
      item.isEditing = true;
    };

    const suggestGroups = async (key: string, item: any, index: number) => {
      // reset
      foundGroups.value = [];
      item.name.value = key;

      if (key !== "") {
        // searching
        // const selectedGroupIds = componentData.value.items.length
        //   ? componentData.value.items
        //       .filter(t => (t.id || 0) > 0)
        //       .map(t => t.id)
        //   : [];
        isACILoading.value = true;
        const result = await ApiHelper.callApi(
          "get",
          "/events/registrationGroupsSearch",
          {},
          {
            key: key
            // take: 25,
            // selectedIds: selectedGroupIds.join(",")
          }
        );
        if (result.status === 1) {
          foundGroups.value = (result.data.items || []).map((t: any) => ({
            id: t.groupName,
            text: t.groupName,
            html: t.groupName,
            data: t
          }));
        }
        isACILoading.value = false;
      }
    };

    const selectGroup = (data: any, item: any) => {
      if (data) {
        item.name.value = data.groupName;
        componentData.value.itemEdit.item = item;
      }
    };

    const getPtypeName = (id: string) => {
      const options: any[] =
        props.data && props.data.ptypes ? props.data.ptypes : [];
      const optionValue = options.find(item => {
        return item.id == id;
      });
      if (optionValue) {
        return optionValue.name;
      }
      return "";
    };

    const getGroupLink = (uuid: string) => {
      if (!uuid) return "";
      return `${componentData.value.portalLink}/#/?group=${uuid}`;
    };

    const capacityChange = (item: any, type = "") => {
      if (type == "female" || type == "male") {
        const capacityFemale = isNaN(parseInt(item.capacityFemale.value))
          ? null
          : parseInt(item.capacityFemale.value);
        const capacityMale = isNaN(parseInt(item.capacityMale.value))
          ? null
          : parseInt(item.capacityMale.value);

        if (capacityFemale != null && capacityMale != null) {
          const capacityTotal = parseInt(
            (capacityFemale + capacityMale).toFixed(2)
          );
          item.capacityTotal.error = "";
          item.capacityTotal.value = capacityTotal;
        }
      }
    };

    // init data
    (async () => {
      loadData();
    })();

    return {
      loadData,
      addNewRow,
      doCancelItem,
      doSaveItem,
      doDeleteItem,
      doEditItem,
      componentData,
      stripTags: ApiHelper.stripTags,
      isACILoading,
      foundGroups,
      suggestGroups,
      selectGroup,
      ApiHelper: ApiHelper,
      getPtypeName,
      getGroupLink,
      capacityChange,
      addRows
    };
  },
  methods: {
    refresh() {
      this.$forceUpdate();
    },
    hoverEnter(event: any) {
      if (event.clientY > 570 && this.componentData.totalDataApplyTop > 3) {
        this.componentData.allowTop = true;
      } else {
        this.componentData.allowTop = false;
      }
    },
    checkAllowAdd() {
      this.$emit("button-added");
    },
    checkAllowEdit(index: number, item: any) {
      this.componentData.itemEdit.index = index;
      this.componentData.itemEdit.item = item;
      this.$emit("button-edited");
    },
    allowEdit() {
      this.doEditItem(
        this.componentData.itemEdit.index,
        this.componentData.itemEdit.item
      );
    },
    checkAllowClose(index: number, item: any) {
      this.$emit("button-deleted");
      this.doCancelItem(index, item);
    },
    async checkDoSaveItem(index: number, item: any) {
      const result = await this.doSaveItem(index, item);
      if (result == true) {
        this.$emit("button-deleted");
      }
    }
  }
});
