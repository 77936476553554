





































































































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Header from "@/components/Common/Header.vue";
import Loading from "@/components/Common/Loading.vue";
import { ApiHelper } from "@/helpers";
import { TransactionType } from "@/helpers/ApiHelper";
import PopupPayment from "@/components/Financial/PopupPayment.vue";

export default defineComponent({
  name: "FinancialTransactionDetails",
  components: {
    Loading,
    Header,
    PopupPayment
  },
  setup(props, context) {
    const selectedTransactionId = context.root.$route.query.transactionId || "";
    const headerData = ref({
      subTitle: "Transactions",
      title: "#" + selectedTransactionId
    });
    const pageData = ref<{
      isLoading: boolean;
      paying: boolean;
      exporting: boolean;
      paymentType: string;
      refundInfo: any;
      showCostCenter?: boolean;
      showGlCodes?: boolean;
    }>({
      isLoading: false,
      paying: false,
      exporting: false,
      paymentType: "manual",
      refundInfo: []
    });
    const transactionDetails = ref<any>([]);
    const transactionLines = ref([]);
    const transactionInfos = ref([]);
    const refundLines = ref<any>([]);
    const events = ref<any>([]);
    const popupData = ref({
      profileId: 0,
      eventId: 0,
      show: false
    });

    const loadData = async (exportData = 0) => {
      const query = context.root.$route.query;
      if (exportData == 0) {
        pageData.value.isLoading = true;
      }
      const result = await ApiHelper.callApi(
        "get",
        "/transactions/details",
        {},
        {
          transactionId: selectedTransactionId,
          transactionTypeId: query.transactionTypeId || 0,
          // tieEvent: query.tieEvent,
          // profileId: query.profileId || 0,
          transactionIds: query.transactionIds,
          participantIds: query.participantIds,
          exportData
        }
      );
      if (exportData == 0) {
        pageData.value.isLoading = false;
      }

      if (result.status === 1) {
        if (exportData) {
          const data = result.data || {};
          const pdfUrl = data.pdfUrl || "";
          if (pdfUrl) {
            window.open(pdfUrl, "_blank")!.focus();
          }
          // ApiHelper.showSuccessMessage(`Exported PDF`);
          return;
        }

        transactionDetails.value = result.data.transactionDetails || [];
        transactionLines.value = result.data.transactionLines || [];
        transactionInfos.value = result.data.transactionInfos || [];
        refundLines.value = result.data.refundLines || [];
        events.value = result.data.events || [];
      } else {
        ApiHelper.showErrorMessage("Transaction not found", "Oops");
        ApiHelper.gotoPage(context, { name: "FinancialTransactions" });
      }
    };

    const allowRefund = () => {
      let valid = true;
      if (transactionDetails.value.length) {
        const transaction: any = transactionDetails.value[0];
        const transactionId = transaction.externalTransactionId || "";
        const transactionTypeId = transaction.transactionTypeId || 0;

        if (
          transactionId.indexOf("re_") != -1 ||
          transaction.transactionTypeId == TransactionType.Refund
        ) {
          // detect this is a refund id
          valid = false;
        }

        let refundAmount = 0;
        for (const item of refundLines.value) {
          refundAmount += item.refundAmount || 0;
        }
        refundAmount = parseFloat(refundAmount.toFixed(2));
        if (transaction.paidAmount == refundAmount) {
          // if refund all the paid amount, so cannot refund more
          valid = false;
        }
        if ((transaction.scholarshipUsingDiscount || 0) == 1) {
          valid = false;
        }
        if (
          transactionTypeId == TransactionType.WriteOff ||
          transactionTypeId == TransactionType.Transfer
        ) {
          valid = false;
        }
      }

      return valid;
    };

    const closePaymentModal = (options: any) => {
      popupData.value.show = false;
      loadData();
    };

    const setIsPaying = (status: boolean) => {
      pageData.value.paying = status;
    };

    const updateRefundInfo = (item: any) => {
      if (!transactionDetails.value.length) return;
      const transaction: any = transactionDetails.value[0];
      pageData.value.refundInfo = [
        {
          transactionId: transaction.externalTransactionId,
          transactionAmount: transaction.paidAmount,
          transactionAmountFormatted: transaction.paidAmountFormatted,
          transactionTypeId: transaction.transactionTypeId,
          details: []
        }
      ];
    };

    const exportPDF = async () => {
      pageData.value.exporting = true;
      await loadData(1);
      pageData.value.exporting = false;
    };

    (async () => {
      if (
        typeof pageData.value.showGlCodes == "undefined" &&
        typeof pageData.value.showCostCenter == "undefined"
      ) {
        const configData = await ApiHelper.getEntityConfig(true);
        pageData.value.showGlCodes = configData.showGlCodes;
        pageData.value.showCostCenter = configData.showCostCenter;
      }
    })();

    return {
      headerData,
      loadData,
      pageData,
      transactionDetails,
      transactionLines,
      refundLines,
      events,
      ApiHelper,
      allowRefund,
      popupData,
      closePaymentModal,
      setIsPaying,
      updateRefundInfo,
      exportPDF
    };
  },
  mounted() {
    this.loadData();
  },
  beforeRouteUpdate(to, from, next) {
    next();
  }
});
