

















































































import { defineComponent, ref } from "@vue/composition-api";
import OrderByButton from "@/components/Common/OrderByButton.vue";
import { ApiHelper } from "@/helpers";
import VScroller from "@/components/Common/VScroller.vue";

export default defineComponent({
  name: "FilterSearchSuggestColumn",
  components: {
    OrderByButton,
    VScroller
  },
  props: {
    label: String,
    placeholder: String,
    model: String,
    data: Object,
    activeTab: String,
    activeValue: String,
    setActiveFilterTab: Function,
    closeFilterTab: Function,
    onChangeValue: Function,
    sortKey: String,
    sortKey2: String,
    sortData: Object,
    onChangeSortValue: Function,
    sortKeyTitle: {
      type: String,
      default: ""
    },
    sortKey2Title: {
      type: String,
      default: ""
    },
    minCharacters: {
      type: Number,
      default: 0
    }
  },
  watch: {
    model: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.searchValue = val;
        }
      }
    }
  },
  setup(props, context) {
    const searchValue = ref<string>(props.model || "");
    const timer = ref<any>(null);
    const componentData = ref<{
      show: boolean;
      loading: boolean;
      isProcessing: boolean;
      isSearched: boolean;
      hint: string;
      items: any[];
    }>({
      show: false,
      loading: false,
      isProcessing: false,
      isSearched: false,
      hint: "",
      items: []
    });

    const searchTimeOut = () => {
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = null;
      }
      timer.value = setTimeout(() => {
        if (props.onChangeValue) {
          props.onChangeValue(props.activeValue, searchValue.value);
        }
      }, 800);
    };

    const doSuggest = async (key: string) => {
      componentData.value.isProcessing = true;
      try {
        componentData.value.isSearched = false;
        const result = await ApiHelper.callApi(
          "get",
          "/profiles/search",
          {},
          {
            key: key
          }
        );
        if (result.status === 1) {
          const data: any = result.data || {};
          const profiles = data.profiles || [];
          componentData.value.items = [...profiles].map((item: any) => ({
            ...item,
            html: `${item.firstName} ${item.lastName}`,
            data: item
          }));
        }
        componentData.value.isSearched = true;
      } catch (error) {
        componentData.value.isSearched = false;
      } finally {
        componentData.value.isProcessing = false;
      }
    };

    const onInput = (event: any) => {
      searchValue.value = event.target.value;

      if (timer.value) {
        clearTimeout(timer.value);
      }
      if (searchValue.value == "") {
        componentData.value.show = false;
        componentData.value.hint = "";
        componentData.value.items = [];
        componentData.value.isSearched = false;
      } else {
        componentData.value.show = true;
        timer.value = setTimeout(async () => {
          if (searchValue.value.length < 3) {
            componentData.value.hint =
              "Please type at least 3 characters to search";
          } else {
            componentData.value.hint = "";
            componentData.value.loading = true;
            await doSuggest(searchValue.value);
            componentData.value.loading = false;
          }
        }, 300);
      }
    };

    const doClose = () => {
      if (props.setActiveFilterTab) {
        props.setActiveFilterTab("");
      }
    };
    const doSearch = () => {
      if (
        searchValue.value.trim() == "" ||
        (props.minCharacters && searchValue.value.length < props.minCharacters)
      )
        return;

      if (props.onChangeValue) {
        props.onChangeValue(props.activeValue, searchValue.value);
        doClose();
      }
    };

    const doReset = () => {
      searchValue.value = "";
      componentData.value.items = [];
      componentData.value.show = false;
      componentData.value.isProcessing = false;
      componentData.value.isSearched = false;
      componentData.value.hint = "";
      if (props.onChangeValue) {
        props.onChangeValue(props.activeValue, searchValue.value);
      }
    };

    const onKeyup = (event: any) => {
      if (
        searchValue.value.trim() == "" ||
        (props.minCharacters && searchValue.value.length < props.minCharacters)
      )
        return;

      if (event.keyCode === 13 && props.onChangeValue) {
        props.onChangeValue(props.activeValue, searchValue.value);
        doClose();
      }
    };
    const onClickOutside = () => {
      if (props.activeTab === props.activeValue) {
        doClose();
      }
    };

    const onClickSorting = () => {
      if (props.onChangeSortValue) {
        const currentOrder = props.sortData ? props.sortData.order || "" : "";
        const currentDirection = props.sortData
          ? props.sortData.direction || "2"
          : "2";
        if (props.sortKey !== currentOrder) {
          props.onChangeSortValue(props.sortKey, "2");
        } else {
          props.onChangeSortValue(
            props.sortKey,
            currentDirection === "1" ? "2" : "1"
          );
        }
      }
    };

    const onClickSorting2 = () => {
      if (props.onChangeSortValue) {
        const currentOrder = props.sortData ? props.sortData.order || "" : "";
        const currentDirection = props.sortData
          ? props.sortData.direction || "2"
          : "2";
        if (props.sortKey2 !== currentOrder) {
          props.onChangeSortValue(props.sortKey2, "2");
        } else {
          props.onChangeSortValue(
            props.sortKey2,
            currentDirection === "1" ? "2" : "1"
          );
        }
      }
    };

    const onSelect = (item: any) => {
      ApiHelper.gotoPage(context, {
        name: "ProfileDashboard",
        params: {
          profileId: `${item.id}`
        }
      });
    };

    return {
      onSelect,
      componentData,
      onClickSorting,
      onClickOutside,
      searchValue,
      onInput,
      onKeyup,
      doSearch,
      doReset,
      doClose,
      searchTimeOut,
      onClickSorting2
    };
  }
});
