
































































































































import { defineComponent } from "@vue/composition-api";

import { useSettingsParticipantTypesDetailsStore } from "@/stores/Settings/ParticipantTypes/SettingsParticipantTypesDetailsStore";
import Loading from "@/components/Common/Loading.vue";
import BackButton from "@/components/Common/BackButton.vue";

export default defineComponent({
  name: "SettingsParticipantTypesDetailsPage",
  components: {
    Loading,
    BackButton
  },
  setup(props, context) {
    const { headerData, pageData } = useSettingsParticipantTypesDetailsStore(
      context
    );
    return {
      headerData,
      pageData
    };
  }
});
