






















































































































































































































































































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import Modal from "@/components/Common/Modal.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormNumber from "@/components/Form/FormNumber.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormInput from "@/components/Form/FormPhone.vue";
import { ApiHelper } from "@/helpers";
import directives from "@/helpers/directives";
import $ from "jquery";
import { dollarFormat, TransactionType } from "@/helpers/ApiHelper";
declare const Stripe: any;

export default defineComponent({
  name: "TransferModal",
  props: {
    participantInfo: Object
  },
  directives,
  components: {
    Modal,
    FormSelect,
    FormNumber,
    FormTextarea,
    FormInput
  },
  setup(props, context) {
    const loading = ref(false);
    const registrations = ref<any>([]);
    const selectedParticipantId = ref<number | string>(0);
    const selectedParticipant = ref<any>({});
    const transferInfo = ref({
      amount: 0,
      amountFormatted: "",
      payMore: 0,
      payMoreFormatted: "",
      campStoreCredit: 0,
      campStoreCreditFormatted: ""
    });
    const familyId = ref(0);
    const familyOwnerId = ref(0);
    const familyOwner = ref<any>({});
    const paymentInfo = ref<any>({});
    const paymentData = ref({
      paymentErrMessage: "",
      stripeError: 0,
      paymentType: "",
      paymentInfoLoading: false,
      paying: false,
      stripeToken: "",
      stripeLast4: "",
      cardName: "",
      cardNumber: "",
      expMoth: "",
      expyear: "",
      cvc: "",
      zipcode: "",
      desc: "",
      customAmount: "",
      addonServiceId: 0,
      errors: {
        cardName: "",
        cardNumber: "",
        expMoth: "",
        expyear: "",
        cvc: "",
        zipcode: "",
        addonServiceId: ""
      }
    });
    const stripePublishableKey = ref("");
    const campStoreProcess = ref<number>(1);
    const transactionsCanRefund = ref<any>([]);
    const refundInfo = ref<any>([]);
    const fromFullName = ref<string>("");

    const validateStripeForm = () => {
      // reset
      paymentData.value.paymentErrMessage = "";
      paymentData.value.errors = {
        cardName: "",
        cardNumber: "",
        expMoth: "",
        expyear: "",
        cvc: "",
        zipcode: "",
        addonServiceId: ""
      };
      let valid = true;
      if (paymentData.value.cardName == "") {
        paymentData.value.errors.cardName = "Name is required";
        valid = false;
      }
      if (paymentData.value.cardNumber == "") {
        paymentData.value.errors.cardNumber = "Card Number is required";
        valid = false;
      }
      if (paymentData.value.expMoth == "") {
        paymentData.value.errors.expMoth = "Exp Month is required";
        valid = false;
      }
      if (paymentData.value.expyear == "") {
        paymentData.value.errors.expyear = "Exp Year is required";
        valid = false;
      }
      if (paymentData.value.cvc == "") {
        paymentData.value.errors.cvc = "CVC is required";
        valid = false;
      }
      if (
        paymentData.value.zipcode != "" &&
        paymentData.value.zipcode.length < 5
      ) {
        paymentData.value.errors.zipcode = "Zipcode is invalid";
        valid = false;
      }
      return valid;
    };

    const transfer = async () => {
      if (selectedParticipantId.value == "campStoreProcess_2") {
        // selected option "Refund completely"
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        doRefund();
        return;
      }

      const toParticipantId = selectedParticipant.value.participantId || 0;
      const fromParticipantId = props.participantInfo?.participantId || 0;
      if (
        (registrations.value.length > 0 &&
          !selectedParticipantId.value &&
          toParticipantId == 0) ||
        fromParticipantId == 0 ||
        paymentData.value.paying
      )
        return;

      // validate
      let valid = true;
      if (transferInfo.value.payMore > 0) {
        // paymore is optional, just validate if user input card number/card name
        if (
          paymentData.value.cardName != "" ||
          paymentData.value.cardNumber != ""
        ) {
          valid = validateStripeForm();
        } else {
          // reset paymore (set = 0)
          transferInfo.value.payMore = 0;
        }
      }

      if (!valid) return;

      // create stripe token if pay more
      if (transferInfo.value.payMore > 0) {
        paymentData.value.stripeToken = "";
        paymentData.value.paying = true;
        await context.emit("setIsPaying", true);
        Stripe.card.createToken(
          $("#stripeFrm"),
          async (status: any, response: any) => {
            //Stripe token failure...
            if (response.error) {
              let msg = "card name";
              if (response.error.param == "number") {
                paymentData.value.stripeError = 2;
                msg = "card number";
              } else if (response.error.param == "exp_month") {
                paymentData.value.stripeError = 3;
                msg = "month";
              } else if (response.error.param == "exp_year") {
                paymentData.value.stripeError = 4;
                msg = "year";
              } else if (response.error.param == "cvc") {
                paymentData.value.stripeError = 5;
                msg = "cvc";
              } else {
                paymentData.value.stripeError = 1;
              }
            } else {
              paymentData.value.stripeToken = response.id;
              paymentData.value.stripeLast4 = response.card.last4;
              paymentData.value.stripeError = 0;
            }

            if (
              paymentData.value.stripeToken != "" &&
              paymentData.value.stripeError == 0
            ) {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              await doTransfer();
            } else {
              paymentData.value.paying = false;
              await context.emit("setIsPaying", false);
            }
          }
        );
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await doTransfer();
      }
    };

    const doTransfer = async () => {
      try {
        paymentData.value.paying = true;
        await context.emit("setIsPaying", true);
        let toFullName = selectedParticipant.value?.fullName || "";
        let transferToFamilyOnwer = false;
        const paidAmount = props.participantInfo?.paidAmount || 0;
        if (
          (registrations.value.length == 0 &&
            transferInfo.value.campStoreCredit > 0) ||
          selectedParticipantId.value == "campStoreProcess_1"
        ) {
          // if transfer to camp store fund for family onwer
          transferToFamilyOnwer = true;
          // toFullName = familyOwner.value.fullName || "";
          toFullName = fromFullName.value || "";
          transferInfo.value.campStoreCredit = paidAmount;
          transferInfo.value.campStoreCreditFormatted = dollarFormat(
            transferInfo.value.campStoreCredit
          );
        }

        const requestObj = {
          ...transferInfo.value,
          // from participant
          fromParticipantId: props.participantInfo?.participantId || 0,
          fromEventId: props.participantInfo?.eventId || 0,
          fromProfileId: props.participantInfo?.profileId || 0,
          fromFullName: fromFullName.value || "",
          // to participant
          toParticipantId: selectedParticipant.value.participantId,
          toFullName,
          toEventName: selectedParticipant.value.eventName,
          toEventId: selectedParticipant.value.eventId,
          toProfileId: selectedParticipant.value.profileId,
          // family info
          familyId: familyId.value,
          familyOwnerId: familyOwnerId.value,
          familyOwnerFullName: familyOwner.value.fullName || "",
          // card info
          cardInfo: {
            token: paymentData.value.stripeToken,
            cardName: paymentData.value.cardName,
            number: paymentData.value.cardNumber,
            expMonth: paymentData.value.expMoth,
            expYear: paymentData.value.expyear,
            zipCode: paymentData.value.zipcode,
            last4: paymentData.value.stripeLast4
          }
        };

        const result = await ApiHelper.callApi(
          "post",
          "/events/paymentTransfer",
          requestObj,
          {}
        );
        if (result.status === 1) {
          const root: any = context.root || {};
          const allowedFunctions = root.$allowedFunctions || [];
          const userFunctions = root.$userFunctions;
          await context.emit("setIsPaying", false);
          context.emit("dismiss");
          context.emit("reloadData");
          context.emit("reUpdateStatusDenied");
          const confirm = await context.root.$swal({
            title: "Success",
            html: "Transferred successfully",
            icon: "success",
            showDenyButton:
              !transferToFamilyOnwer &&
              allowedFunctions.includes(userFunctions.Transactions)
                ? true
                : false,
            denyButtonText: "Check transferred payments",
            denyButtonColor: "#3085d6",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK"
          });
          if (confirm.isDenied) {
            // check transferred payments
            context.root.$router.push({
              name: "ProfileFinancial",
              params: { profileId: selectedParticipant.value.profileId },
              query: {
                id: `${selectedParticipant.value.participantId}`,
                tab: "transaction"
              }
            });
          }
          // else {
          //   context.emit("dismiss");
          //   context.emit("reloadData");
          // }
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        paymentData.value.paying = false;
        await context.emit("setIsPaying", false);
      }
    };

    const resetTransferInfo = () => {
      // reset transfer info
      transferInfo.value = {
        amount: 0,
        amountFormatted: "",
        payMore: 0,
        payMoreFormatted: "",
        campStoreCredit: 0,
        campStoreCreditFormatted: ""
      };

      // reset card info
      paymentData.value.stripeToken = "";
      paymentData.value.stripeLast4 = "";
      paymentData.value.cardName = "";
      paymentData.value.cardNumber = "";
      paymentData.value.expMoth = "";
      paymentData.value.expyear = "";
      paymentData.value.cvc = "";
      paymentData.value.stripeError = 0;
      paymentData.value.zipcode = "";
      paymentData.value.paymentErrMessage = "";
      paymentData.value.errors = {
        cardName: "",
        cardNumber: "",
        expMoth: "",
        expyear: "",
        cvc: "",
        zipcode: "",
        addonServiceId: ""
      };
    };

    const selectParticipant = async () => {
      // reset transfer info
      resetTransferInfo();

      const paidAmount = props.participantInfo?.paidAmount || 0;
      if (
        ["campStoreProcess_1", "campStoreProcess_2"].includes(
          `${selectedParticipantId.value}`
        )
      ) {
        selectedParticipant.value = {};
        transferInfo.value.campStoreCredit = paidAmount;
        transferInfo.value.campStoreCreditFormatted = dollarFormat(
          transferInfo.value.campStoreCredit
        );
        if (selectedParticipantId.value == "campStoreProcess_2") {
          // refund completely or just overpayment amount
          const overpayment = props.participantInfo?.overpayment || 0;
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getRefundInfo(overpayment ? paidAmount : 0);
        }
        return;
      }

      selectedParticipant.value = registrations.value.find(
        (item: any) => item.participantId == selectedParticipantId.value
      );
      const balance = selectedParticipant.value.balance;

      // get transfer info
      if (balance > paidAmount) {
        // if transfer to registration has amount > paid amount
        transferInfo.value.amount = paidAmount;
        transferInfo.value.payMore = parseFloat(
          (balance - paidAmount).toFixed(2)
        );
        transferInfo.value.payMoreFormatted = dollarFormat(
          transferInfo.value.payMore
        );
      } else if (balance < paidAmount) {
        // if transfer to registration has amount < paid amount
        transferInfo.value.amount = balance;
        transferInfo.value.campStoreCredit = parseFloat(
          (paidAmount - balance).toFixed(2)
        );
        transferInfo.value.campStoreCreditFormatted = dollarFormat(
          transferInfo.value.campStoreCredit
        );
      } else {
        transferInfo.value.amount = paidAmount;
      }
      transferInfo.value.amountFormatted = dollarFormat(
        transferInfo.value.amount
      );

      // load stripe
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      loadStripe();
    };

    const loadStripe = async () => {
      if (stripePublishableKey.value != "") {
        if (typeof Stripe == "undefined") {
          // load stripe
          await $.getScript("https://js.stripe.com/v2/", function() {
            // do nothing
          }).fail(function() {
            // console.log("Stripe load failes");
          });
        }
        Stripe.setPublishableKey(stripePublishableKey.value);
      }
    };

    const disabledTransferButton = computed(() => {
      // const paidAmount = props.participantInfo?.paidAmount || 0;
      if (
        // if selected a registration
        (selectedParticipant.value.participantId || 0) > 0 ||
        // selected credit to balance/refund
        ["campStoreProcess_1", "campStoreProcess_2"].includes(
          `${selectedParticipantId.value}`
        )
        // no registrations, but paid amount > 0, transfer to family owner
        // (!loading.value && registrations.value.length == 0 && paidAmount > 0)
      ) {
        return false;
      }

      return true;
    });

    const doRefund = async () => {
      try {
        context.emit("setIsPaying", true);
        paymentData.value.paying = true;
        const requestObj = {
          isRefund: true,
          amount: transferInfo.value.campStoreCredit,
          eventId: 0,
          profileId: 0,
          participantId: 0,
          transactionDescription: "Refund completely",
          refundInfo: refundInfo.value
        };
        const result = await ApiHelper.callApi(
          "post",
          "/transactions",
          requestObj,
          {}
        );
        context.emit("setIsPaying", false);
        if (result.status === 1) {
          ApiHelper.showSuccessMessage("Refunded successfully");
          context.emit("dismiss");
          context.emit("reloadData");
          context.emit("reUpdateStatusDenied");
        } else {
          ApiHelper.showErrorMessage(result.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        paymentData.value.paying = false;
        context.emit("setIsPaying", false);
      }
    };

    const getRefundInfo = async (refundAmount = 0) => {
      // get refund info in case user wants to "Refund completely" if no registrations to transfer
      refundInfo.value = [];
      const ret: any = [];
      context.emit("setIsPaying", true);
      const participantId = props.participantInfo?.participantId || 0;
      let limitAmount = refundAmount;
      const isLimitAmount = limitAmount > 0;
      for (const item of transactionsCanRefund.value) {
        const refundInfo: any = {};
        const result = await ApiHelper.callApi(
          "get",
          "/transactions/details",
          {},
          {
            getInfo: "refundInfo",
            transactionTypeId: item.transactionTypeId,
            transactionId: item.transactionId,
            transactionIds: item.transactionIds || undefined
          }
        );
        if (result.status == 1) {
          const details = result.data || [];
          // just get details line for this participant
          const detailLines = (details.detailLines || []).filter(
            (t: any) => t.participantId == participantId
          );
          refundInfo.details = detailLines.map((p: any) => ({
            ...p,
            totalPaidFormatted: dollarFormat(p.totalPaid),
            refundAmount: p.totalPaid || 0
          }));

          refundInfo.refundLines = details.refundLines || [];
          if (refundInfo.refundLines.length) {
            // calculate to show amount can refund more
            for (const item of refundInfo.details) {
              const relatedRefund = refundInfo.refundLines.filter(
                (t: any) =>
                  t.participantId == item.participantId &&
                  t.profileId == item.profileId &&
                  t.addonServiceId == item.addonServiceId &&
                  t.parentId == item.transactionId
              );

              if (relatedRefund.length) {
                for (const t of relatedRefund) {
                  item.totalPaid -= t.refundAmount;
                  item.totalPaid = parseFloat(item.totalPaid.toFixed(2));
                  item.totalPaidFormatted = dollarFormat(item.totalPaid);
                  item.refundAmount = item.totalPaid;
                }
              }
            }
          }

          // limit amount to refund
          if (isLimitAmount) {
            const finalLines = [];
            for (const item of refundInfo.details) {
              if (item.refundAmount <= limitAmount) {
                finalLines.push(item);
                limitAmount -= item.refundAmount;
                limitAmount = parseFloat(limitAmount.toFixed(2));
                if (limitAmount == 0) {
                  break;
                }
              } else {
                item.refundAmount = limitAmount;
                limitAmount = 0;
                finalLines.push(item);
                break;
              }
            }
            refundInfo.details = finalLines;
          }

          // group details by transaction id
          const groupped: any = [];
          for (const item of refundInfo.details) {
            const transactionId =
              item.externalTransactionID || item.transactionId;
            const inGroup = groupped.find(
              (t: any) => t.transactionId == transactionId
            );
            if (!inGroup) {
              const itemsList = refundInfo.details.filter(
                (detail: any) =>
                  (detail.externalTransactionID || detail.transactionId) ==
                    transactionId && item.totalPaid > 0
              );
              groupped.push({
                transactionId,
                transactionTypeId: itemsList[0].transactionTypeId || 0,
                items: itemsList
              });
            }
          }
          refundInfo.groupped = groupped;

          ret.push(refundInfo);

          // exit if reach to limitted refund amount
          if (isLimitAmount && limitAmount == 0) {
            break;
          }
        }
      }

      let groupped: any = [];
      for (const item of ret) {
        groupped = [...groupped, ...item.groupped];
      }
      refundInfo.value = [
        {
          groupped
        }
      ];
      context.emit("setIsPaying", false);
    };

    // init data
    (async () => {
      try {
        loading.value = true;
        const profileId = parseInt(props.participantInfo?.profileId || 0);
        const participantId = props.participantInfo?.participantId || 0;
        const paidAmount = props.participantInfo?.paidAmount || 0;
        transactionsCanRefund.value = [];
        if (profileId > 0) {
          const response = await ApiHelper.callApi(
            "get",
            `/events/transferInfo`,
            {},
            {
              profileId,
              participantId
            }
          );
          if (response.status == 1) {
            fromFullName.value = response.data.fromFullName || "";
            registrations.value = (response.data.registrations || []).map(
              (item: any) => ({
                ...item,
                checked: false
              })
            );
            familyId.value = response.data.familyId || 0;
            familyOwnerId.value = response.data.familyOwnerId || 0;
            familyOwner.value = response.data.familyOwner || {};
            stripePublishableKey.value =
              response.data.stripePublishableKey || "";

            // transfer to camp store fund to "from" profile if there are no registrations
            if (registrations.value.length == 0) {
              transferInfo.value.campStoreCredit = paidAmount;
              transferInfo.value.campStoreCreditFormatted = dollarFormat(
                transferInfo.value.campStoreCredit
              );

              // default select "Credit to balance"
              campStoreProcess.value = 1;
              selectedParticipantId.value == "campStoreProcess_1";
            }
            transactionsCanRefund.value =
              response.data.transactionsCanRefund || [];
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        loading.value = false;
      }
    })();

    return {
      ApiHelper,
      registrations,
      selectedParticipantId,
      selectParticipant,
      selectedParticipant,
      transfer,
      transferInfo,
      paymentData,
      paymentInfo,
      loading,
      disabledTransferButton,
      campStoreProcess,
      getRefundInfo
    };
  }
});
