var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sidebarData.isLoading),expression:"!sidebarData.isLoading"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"SidebarProfileEventsComponent"},[(!_vm.sidebarData.isLoading)?_c('div',{staticClass:"profileDetails sidebar-profiles-details",class:{ active: !_vm.participantId },staticStyle:{"z-index":"999"},attrs:{"id":"menu-participants"}},[_c('div',{staticClass:"menu-box"},[_c('div',{staticClass:"box_head"},[_c('div',{staticClass:"box__icon"},[_c('Avatar',{staticStyle:{"margin":"auto"},attrs:{"username":_vm.sidebarData.name}})],1),_c('div',{staticClass:"box__name",staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"textOverflow profile-fullname"},[_vm._v(" "+_vm._s(_vm.sidebarData.name || "Details")+" ")])])]),_c('VScroller',{staticClass:"box_content"},[_c('ul',{attrs:{"slot":"default"},slot:"default"},[_c('router-link',{attrs:{"to":{
              name: 'ProfileApplication',
              params: {
                profileId: _vm.sidebarData.profileId + ''
              },
              query: {
                id: _vm.sidebarData.participantId + '',
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#edit2","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Application")])])])]}}],null,false,1526939337)}),_c('router-link',{attrs:{"to":{
              name: 'ProfileRoommates',
              params: {
                profileId: _vm.sidebarData.profileId + ''
              },
              query: {
                id: _vm.sidebarData.participantId + '',
                eventId: _vm.eventId,
                pTypeId: _vm.data.pTypeId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#users","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Roommates")])])])]}}],null,false,525167662)}),_c('router-link',{attrs:{"to":{
              name: 'ProfileTodos',
              params: {
                profileId: _vm.sidebarData.profileId + ''
              },
              query: {
                id: _vm.sidebarData.participantId + '',
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#folder","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("To Dos")])])])]}}],null,false,1747629960)}),(_vm.$allowedFunctions.includes(_vm.$userFunctions.Transactions))?_c('router-link',{attrs:{"to":{
              name: 'ProfileFinances',
              params: {
                profileId: _vm.sidebarData.profileId + ''
              },
              query: {
                id: _vm.sidebarData.participantId + '',
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#credit-card","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Finances")])])])]}}],null,false,2042509106)}):_vm._e(),_c('router-link',{attrs:{"to":{
              name: 'ProfileMedicals',
              params: {
                profileId: _vm.sidebarData.profileId + ''
              },
              query: {
                participantId: _vm.sidebarData.participantId + '',
                eventId: _vm.eventId
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var route = ref.route;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{class:{
                active: isActive || isExactActive
              }},[_c('a',{staticClass:"box__item",attrs:{"href":href,"title":""},on:{"click":navigate}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/svg/nav/icons.svg") + "#life_buoy","alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Medical/Incidents")])])])]}}],null,false,570813796)}),(_vm.showTransfer)?_c('li',[_c('a',{staticClass:"box__item",attrs:{"href":"#","title":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.transfer($event)}}},[_c('div',{staticClass:"item__icon"},[_c('img',{attrs:{"src":require("../../assets/images/icon-part-1.png"),"alt":"Icon"}})]),_c('div',{staticClass:"item__title"},[_vm._v("Transfer")])])]):_vm._e()],1)])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }