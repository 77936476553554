


































import { defineComponent } from "@vue/composition-api";
import SidebarProfileDetails from "@/components/Profile/SidebarProfileDetails.vue";
export default defineComponent({
  name: "ProfileDetailsMasterPage",
  components: {
    SidebarProfileDetails
  },
  setup() {
    return {};
  }
});
