






import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "FinancialMaster",
  components: {},
  setup(props, context) {
    return {};
  }
});
