import { ref } from "@vue/composition-api";
import { ApiHelper } from "@/helpers";
import { PagerItem, SelectOption } from "@/types";
import { SettingsListRows } from "@/helpers/estimateNoOfListRows";
import Vue from "vue";
import { ApiType } from "@/helpers/ApiHelper";

export function useSettingsCabinsStore(context: any) {
  const headerData = ref({
    title: "Cabins",
    subTitle: "Settings"
  });
  const query = context.root.$route.query;
  const pageData = ref<{
    isLoading: boolean;
    skip: number;
    take: number;
    items: any[];
    activeTab: string;
    sort: {
      order: string;
      direction: string;
    };
    filter: {
      events: SelectOption[];
      cabin: string;
    };
    showHeadActions: boolean;
    pager: {
      showPagerItems: boolean;
      page: number;
      totalPages: number;
      total: number;
      items: PagerItem[];
    };
    searchEventsLoading: boolean;
    searchEventsValue: {
      value: string;
    };
    selectedEvents: SelectOption[];
  }>({
    isLoading: true,
    skip: 0,
    take: SettingsListRows(),
    activeTab: "",
    sort: {
      order: "1",
      direction: "1"
    },
    filter: {
      events: [],
      cabin: query.cabin || ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    searchEventsLoading: false,
    searchEventsValue: {
      value: query.searchEventsValue || ""
    },
    selectedEvents: (query.eventIds || "")
      .split(",")
      .filter((id: string) => parseInt(id) > 0)
      .map((id: number) => ({
        id,
        text: ""
      }))
  });

  const updateRouters = () => {
    ApiHelper.routerReplace(context, "SettingsCabins", {
      page: pageData.value.pager.page + "",
      order: pageData.value.sort.order,
      direction: pageData.value.sort.direction,
      cabin: pageData.value.filter.cabin || "",
      eventIds: pageData.value.selectedEvents
        .map((item: any) => item.id)
        .join(","),
      searchEventsValue: pageData.value.searchEventsValue.value
    });
  };

  const loadList = async () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    const result = await ApiHelper.callApi(
      "get",
      "/entities/cabins",
      {},
      {
        skip: pageData.value.skip,
        take: pageData.value.take,
        order: parseInt(pageData.value.sort.order),
        direction: parseInt(pageData.value.sort.direction),
        cabin: pageData.value.filter.cabin || "",
        eventIds: pageData.value.selectedEvents.map(item => item.id).join(",")
      },
      ApiType.CORE
    );
    ApiHelper.setDataLoading(false);
    pageData.value.isLoading = false;
    if (result.status === 1) {
      // const totalCount = result.data.totalCount || 0;
      const totalCount = result.data.cabins.length;
      pageData.value.pager.totalPages = Math.ceil(
        totalCount / pageData.value.take
      );
      pageData.value.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      pageData.value.items = result.data.cabins || [];
    } else {
      ApiHelper.showErrorMessage(result.message, "Oops..");
    }
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    loadList();
  };
  // pager
  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  // filters
  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }

    if (pageData.value.activeTab == "events") {
      if (pageData.value.searchEventsValue.value != "") return;
      pageData.value.searchEventsLoading = true;
      pageData.value.filter.events = [];
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await searchEvents("");
      pageData.value.searchEventsLoading = false;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await updateSelectedEvents();
    }
  };

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
    loadData(pageData.value.pager.page);
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "cabin": {
        pageData.value.filter.cabin = value;
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
    loadData(pageData.value.pager.page);
  };

  const getFiltersData = () => {
    const filters: any = [];

    if (pageData.value.filter.cabin !== "") {
      filters.push({
        label: "CABIN",
        key: pageData.value.filter.cabin + Math.random(),
        value: pageData.value.filter.cabin,
        reset: () => {
          pageData.value.filter.cabin = "";
          pageData.value.activeTab = "";
          updateRouters();
          loadData(1);
        }
      });
    }

    const selectedEvents = pageData.value.selectedEvents.map((item: any) => {
      return item.text;
    });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.selectedEvents = [];
          pageData.value.filter.events.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
          loadData(1);
        }
      });
    }

    return {
      list: filters
    };
  };

  const deleteCabin = async (item: any) => {
    const confirm = await context.root.$swal({
      // title: "Are you sure?",
      html: `Are you sure you want to remove cabin <strong>${item.cabinName}</strong>? Linked event cabins also are removed too.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!"
    });
    if (!confirm.isConfirmed) {
      return false;
    }

    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "delete",
      `/entities/cabins/${item.cabinId}`,
      {},
      {},
      ApiType.CORE
    );
    ApiHelper.setDataLoading(false);
    if (result.status == 1) {
      ApiHelper.showSuccessMessage("Deleted successfully", "Deleted");
      loadData(1);
    } else {
      ApiHelper.showErrorMessage(result.message);
    }
  };

  const selectAllEvents = () => {
    // if (pageData.value.filter.unassignedEvent) {
    //   pageData.value.filter.unassignedEvent.selected = true;
    // }
    pageData.value.filter.events = pageData.value.filter.events.map(
      (item: any) => {
        item.selected = true;
        return item;
      }
    );
    pageData.value.selectedEvents = [
      // pageData.value.filter.unassignedEvent || {
      //   id: 0,
      //   text: "",
      //   selected: false
      // },
      ...pageData.value.filter.events
    ].filter(item => item.selected);
    updateRouters();
    loadData(1);
  };

  const resetEvents = () => {
    pageData.value.filter.events = [];
    // pageData.value.filter.unassignedEvent = null;
    pageData.value.selectedEvents = [];
    pageData.value.pager.page = 1;
    updateRouters();
    loadData(1);
  };

  const searchEvents = async (searchValue: string) => {
    pageData.value.searchEventsLoading = true;
    let list: any = [];
    if (!pageData.value.filter.events.length) {
      list = await ApiHelper.getEventOptions({
        getAll: 1
      });
    } else {
      list = [...pageData.value.filter.events];
    }
    // show/hide items related search key
    const key = searchValue.toLowerCase();
    list = list.map((item: any) => ({
      ...item,
      hide: key == "" ? false : item.text.toLocaleLowerCase().indexOf(key) == -1
    }));
    pageData.value.filter.events = list;
    const eventIds = (context.root.$route.query.eventIds || "")
      .split(",")
      .map((id: string) => parseInt(id));
    pageData.value.filter.events = pageData.value.filter.events.map(item => ({
      ...item,
      selected: eventIds.includes(item.id)
    }));
    pageData.value.searchEventsLoading = false;
  };

  const updateSelectedEvents = async (init = false) => {
    if (init) {
      const searchValue = context.root.$route.query.searchEventsValue || "";
      // if (searchValue != "") {
      await searchEvents(searchValue);
      // }
    }
    pageData.value.selectedEvents = [...pageData.value.filter.events].filter(
      item => item.selected
    );
  };

  // init data
  (async () => {
    loadData(1);
    updateSelectedEvents(true);
  })();

  (async () => {
    await ApiHelper.synzUserViewLogs(context);
  })();

  return {
    updateRouters,
    deleteCabin,
    // page
    headerData,
    pageData,
    // filters
    // --- pager
    loadList,
    gotoPage,
    onClickNext,
    onClickPrev,
    togglePagerItems,
    // filters
    getFiltersData,
    setActiveFilterTab,
    closeFilterTab,
    updateFilterValue,
    updateSortValue,
    selectAllEvents,
    resetEvents,
    searchEvents,
    updateSelectedEvents,
    loadData
  };
}
