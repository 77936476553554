















































































import { defineComponent, ref } from "@vue/composition-api";
import $ from "jquery";
import VScroller from "@/components/Common/VScroller.vue";
import { ApiHelper } from "@/helpers";

export default defineComponent({
  name: "AddOns",
  components: {
    VScroller
  },
  props: {
    //
  },
  setup(props, context) {
    //
  }
});
