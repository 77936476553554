import { ref } from "@vue/composition-api";
import { ApiHelper, ProfileHelper } from "@/helpers";
import {
  AlertMessageError,
  FormEmail,
  FormText,
  PagerItem,
  SelectOption
} from "@/types";
import FormSelect from "@/types/FormSelect";
import Vue from "vue";

type Profile = {
  id: number;
  status: number;
  isChecked: boolean;
  logo: string;
  firstName: string;
  lastName: string;
  age: string;
  gender: string;
  balance: string;
  events: string;
  familyName: string;
};

type PageData = {
  isLoading: boolean;
  skip: number;
  take: number;
  items: Profile[];
  activeTab: string;
  arrTags: SelectOption[];
  arrProfiles: SelectOption[];
  arrBalances: SelectOption[];
  openItemId: number;
  sort: {
    order: string;
    direction: string;
  };
  filter: {
    profile: string;
    family: SelectOption[];
    age: string;
    gender: SelectOption[];
    events: SelectOption[];
    unassignedEvent: any;
    balance: string;
    minAge: string;
    maxAge: string;
    minBalance: string;
    maxBalance: string;
  };
  showHeadActions: boolean;
  pager: {
    showPagerItems: boolean;
    page: number;
    totalPages: number;
    total: number;
    items: PagerItem[];
  };
  roomates: any;
  // events
  searchEventsValue: {
    value: string;
  };
  searchEvents: Function;
  selectedEvents: SelectOption[];
  searchEventsLoading: boolean;
  roommateStatusEditVisible: boolean;
  isProcessingRoommateStatus: boolean;
  roommateStatusList: {
    required: boolean;
    label: string;
    placeholder: string;
    error: string;
    type: string;
    value: string;
    options: any;
  };
  selectedRoommateRequest: any;
};

export function useProfilesTribesStore(context: any) {
  const selectedProfileId = parseInt(context.root.$route.params.profileId) || 0;
  const ownerProfileId = ref(0);
  const familyId = ref(parseInt(context.root.$route.query.family) || 0);
  const isArchived = ref(false);

  const headerData = ref({
    title: "Family/Friends",
    subTitle: ""
  });

  const pageData = ref<PageData>({
    isLoading: true,
    skip: 0,
    take: 8,
    activeTab: "",
    arrTags: [],
    arrProfiles: [],
    arrBalances: [],
    openItemId: 0,
    sort: {
      order: context.root.$route.query.order || "6",
      direction: context.root.$route.query.direction || "1"
    },
    filter: {
      profile: context.root.$route.query.profile || "",
      family: [],
      gender: [],
      events: [],
      unassignedEvent: null,
      age: `${context.root.$route.query.minAge || ""}-${context.root.$route
        .query.maxAge || ""}`,
      minAge: context.root.$route.query.minAge || "",
      maxAge: context.root.$route.query.maxAge || "",
      balance: `${context.root.$route.query.minBalance || ""}@${context.root
        .$route.query.maxBalance || ""}`,
      minBalance: context.root.$route.query.minBalance || "",
      maxBalance: context.root.$route.query.maxBalance || ""
    },
    items: [],
    showHeadActions: false,
    pager: {
      showPagerItems: false,
      page: 1,
      total: 0,
      totalPages: 1,
      items: []
    },
    roomates: [],
    searchEventsValue: {
      value: ""
    },
    searchEvents: async (searchValue: string) => {
      pageData.value.searchEventsLoading = true;
      const eventIds = (context.root.$route.query.event || "")
        .split(",")
        .map((id: string) => parseInt(id));
      if (searchValue == "") {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          getAll: 1
        });
        pageData.value.filter.unassignedEvent = {
          id: "0",
          text: "UN-ASSIGNED TO EVENTS",
          selected: eventIds.includes(0)
        };
      } else {
        pageData.value.filter.events = await ApiHelper.getEventOptions({
          key: searchValue
        });
        if (eventIds.includes(0)) {
          pageData.value.filter.unassignedEvent = {
            id: "0",
            text: "UN-ASSIGNED TO EVENTS",
            selected: eventIds.includes(0)
          };
        } else {
          pageData.value.filter.unassignedEvent = null;
        }
      }
      pageData.value.filter.events = pageData.value.filter.events.map(
        (item: any) => ({
          ...item,
          selected: eventIds.includes(item.id)
        })
      );
      pageData.value.searchEventsLoading = false;
    },
    selectedEvents: [],
    searchEventsLoading: false,
    roommateStatusEditVisible: false,
    isProcessingRoommateStatus: false,
    roommateStatusList: {
      required: false,
      label: "Select status",
      placeholder: "Select...",
      error: "",
      type: "select",
      value: "",
      options: [
        {
          id: 0,
          text: ApiHelper.getRoommateStatusText(0)
        },
        {
          id: 1,
          text: ApiHelper.getRoommateStatusText(1)
        },
        {
          id: 2,
          text: ApiHelper.getRoommateStatusText(2)
        }
      ]
    },
    selectedRoommateRequest: {}
  });

  const formData = ref<{
    isLoading: boolean;
    controls: {
      profile: FormSelect;
      email: FormEmail;
      firstName: FormText;
      lastName: FormText;
      type: FormSelect;
    };
  }>({
    isLoading: true,
    controls: {
      profile: {
        label: "Profile",
        placeholder: "Select a profile",
        error: "",
        type: "select",
        value: "",
        options: []
      },
      email: {
        label: "Email",
        error: "",
        type: "email",
        value: ""
      },
      firstName: {
        label: "First Name",
        error: "",
        type: "text",
        value: ""
      },
      lastName: {
        label: "Last Name",
        error: "",
        type: "text",
        value: ""
      },
      type: {
        required: true,
        label: "Relation type",
        placeholder: "Select...",
        error: "",
        type: "select",
        value: "",
        options: []
      }
    }
  });

  const errors = ref<AlertMessageError[]>([]);

  const updateRouters = () => {
    context.root.$router
      .replace({
        name: "ProfileTribes",
        params: {
          profileId: selectedProfileId.toString()
        },
        query: {
          family: context.root.$route.query.family,
          page: pageData.value.pager.page + "",
          order: pageData.value.sort.order,
          direction: pageData.value.sort.direction,
          profile: pageData.value.filter.profile || undefined,
          event:
            ApiHelper.convertSelectedOptionsToString(
              pageData.value.selectedEvents
            ) || undefined,
          gender: ApiHelper.convertSelectedOptionsToString(
            pageData.value.filter.gender
          ),
          minAge: pageData.value.filter.minAge || undefined,
          maxAge: pageData.value.filter.maxAge || undefined,
          minBalance: pageData.value.filter.minBalance || undefined,
          maxBalance: pageData.value.filter.maxBalance || undefined,
          searchEvents: pageData.value.searchEventsValue.value || undefined
        }
      })
      .catch((err: any) => {
        console.log("update routers error");
      });
    //
  };

  const loadList = async () => {
    pageData.value.pager.page = context.root.$route.query.page
      ? parseInt(context.root.$route.query.page)
      : 1;
    ApiHelper.setDataLoading(true);
    pageData.value.skip = (pageData.value.pager.page - 1) * pageData.value.take;
    const result = await ApiHelper.callApi(
      "get",
      `/profiles/${selectedProfileId}/tribes`,
      {},
      {
        skip: pageData.value.skip,
        take: pageData.value.take,
        order: parseInt(pageData.value.sort.order),
        direction: parseInt(pageData.value.sort.direction),
        familyId: (context.root.$route.query.family || 0) + "",
        event: context.root.$route.query.event || "",
        key: pageData.value.filter.profile || "",
        gender: ApiHelper.convertSelectedOptionsToString(
          pageData.value.filter.gender
        ),
        minAge: pageData.value.filter.minAge || "",
        maxAge: pageData.value.filter.maxAge || "",
        minBalance: pageData.value.filter.minBalance || "",
        maxBalance: pageData.value.filter.maxBalance || ""
      }
    );
    ApiHelper.setDataLoading(false);
    pageData.value.isLoading = false;
    if (result.status === 1) {
      // family members
      const familyMembers = result.data.familyMembers;
      const totalCount = familyMembers.totalCount || 0;
      pageData.value.pager.totalPages = Math.ceil(
        totalCount / pageData.value.take
      );
      pageData.value.pager.total = totalCount;
      const pagerList = [];
      for (let i = 0; i < pageData.value.pager.totalPages; i++) {
        const pagerItem: PagerItem = {
          label: i + 1 + "",
          value: i + 1,
          active: i + 1 === pageData.value.pager.page
        };
        pagerList.push(pagerItem);
      }
      pageData.value.pager.items = pagerList;
      const selectedProfileId =
        parseInt(context.root.$route.params.profileId) || 0;
      pageData.value.items = familyMembers.items
        .filter((item: any) => item.id !== selectedProfileId)
        .map((item: any) => {
          return {
            id: parseInt(item.id),
            status: item.isActive,
            isChecked: false,
            fullName: [item.firstName, item.lastName].join(" "),
            firstName: item.firstName,
            lastName: item.lastName,
            logo: item.logo,
            age: item.age,
            gender: ApiHelper.getGenderName(item.gender),
            balance: item.balance || 0,
            balanceFormatted: ApiHelper.dollarFormat(item.balance, false, {
              useBrackets: true
            }),
            activeEvents: item.activeEvents,
            totalEvents: item.totalEvents,
            type: item.type,
            linkId: item.linkId || 0,
            typeId: item.typeId || 0,
            isParent: item.isParent
          };
        });
      const ownerProfile = pageData.value.items.find((item: any) => {
        return item.isParent == 1;
      });
      if (ownerProfile) {
        const fullName = ApiHelper.getFullName(
          ownerProfile.firstName,
          ownerProfile.lastName
        );
        formData.value.controls.type.label = `${fullName}'s`;
        ownerProfileId.value = ownerProfile.id;
      } else {
        ownerProfileId.value = selectedProfileId;
      }
      // roomates
      pageData.value.roomates = result.data.roomates.items;
    } else if (result.status === -1) {
      ApiHelper.gotoPage(context, {
        name: "Login",
        query: { destination: "/settings/users" }
      });
    } else {
      const errorCode = result.errorCode || "";
      ApiHelper.showErrorMessage(result.message, "Oops..");
      if (["FAMILY_NOT_FOUND", "NOT_IN_FAMILY"].includes(errorCode)) {
        ApiHelper.gotoPage(context, {
          name: "ProfileDetails",
          params: {
            profileId: `${selectedProfileId}`
          }
        });
      }
    }
  };

  const loadData = (page: number) => {
    pageData.value.pager.page = page;
    (async () => {
      await loadList();
    })();
  };

  const gotoPage = (page: string) => {
    pageData.value.pager.page = parseInt(page);
    updateRouters();
  };

  const onPagerChange = (event: any) => {
    pageData.value.pager.page = parseInt(event.target.value);
    updateRouters();
  };

  const onClickPrev = () => {
    if (pageData.value.pager.page > 1) {
      pageData.value.pager.page -= 1;
      updateRouters();
    }
  };

  const onClickNext = () => {
    if (pageData.value.pager.page < pageData.value.pager.totalPages) {
      pageData.value.pager.page += 1;
      updateRouters();
    }
  };

  const togglePagerItems = () => {
    pageData.value.pager.showPagerItems = !pageData.value.pager.showPagerItems;
  };

  const getCheckedItems = () => {
    return pageData.value.items.filter(item => item.isChecked);
  };

  const closeFilterTab = () => {
    loadData(1);
    pageData.value.activeTab = "";
  };

  const setActiveFilterTab = async (tab: string) => {
    if (pageData.value.activeTab !== tab) {
      pageData.value.activeTab = tab;
    } else {
      pageData.value.activeTab = "";
    }

    if (pageData.value.activeTab == "events") {
      if (pageData.value.filter.events.length === 0) {
        await pageData.value.searchEvents(
          pageData.value.searchEventsValue.value
        );
      }
    }
  };

  const getFiltersData = () => {
    const filters = [];
    if (pageData.value.filter.profile !== "") {
      filters.push({
        label: "PERSON",
        key: pageData.value.filter.profile + Math.random(),
        value: pageData.value.filter.profile,
        reset: () => {
          pageData.value.filter.profile = "";
          updateRouters();
        }
      });
    }

    if (
      pageData.value.filter.minAge !== "" ||
      pageData.value.filter.maxAge !== ""
    ) {
      filters.push({
        label: "AGE",
        key: pageData.value.filter.age + Math.random(),
        value: pageData.value.filter.age,
        reset: () => {
          pageData.value.filter.age = "";
          pageData.value.filter.minAge = "";
          pageData.value.filter.maxAge = "";
          updateRouters();
        }
      });
    }
    const selectedEvents = pageData.value.selectedEvents
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedEvents.length > 0) {
      filters.push({
        label: "EVENT",
        key: selectedEvents.join("-") + Math.random(),
        value: selectedEvents.join(", "),
        reset: () => {
          pageData.value.searchEventsValue.value = "";
          pageData.value.filter.unassignedEvent = null;
          pageData.value.filter.events = [];
          pageData.value.selectedEvents = [];
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    const selectedGenders = pageData.value.filter.gender
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.text;
      });
    if (selectedGenders.length > 0) {
      filters.push({
        label: "GENDER",
        key: selectedGenders.join("-") + Math.random(),
        value: selectedGenders.join(", "),
        reset: () => {
          pageData.value.filter.gender.map((item: any) => {
            item.selected = false;
          });
          pageData.value.activeTab = "";
          updateRouters();
        }
      });
    }
    if (
      pageData.value.filter.minBalance !== "" ||
      pageData.value.filter.maxBalance !== ""
    ) {
      const displayValue =
        (pageData.value.filter.minBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.minBalance, 10),
              false,
              { useBrackets: true }
            )
          : "") +
        " - " +
        (pageData.value.filter.maxBalance != ""
          ? ApiHelper.dollarFormat(
              Number.parseInt(pageData.value.filter.maxBalance, 10),
              false,
              { useBrackets: true }
            )
          : "");
      filters.push({
        label: "BALANCE",
        key: pageData.value.filter.balance + Math.random(),
        value: displayValue,
        reset: () => {
          pageData.value.filter.balance = "";
          pageData.value.filter.minBalance = "";
          pageData.value.filter.maxBalance = "";
          updateRouters();
        }
      });
    }
    return {
      list: filters
    };
  };

  const applyFilters = () => {
    loadData(1);
  };

  const updateFilterValue = (name: string, value: string) => {
    switch (name) {
      case "profile":
        pageData.value.filter.profile = value;
        break;
      case "age": {
        pageData.value.filter.age = value;
        const ageArr = value.split("-");
        pageData.value.filter.minAge = ageArr[0];
        pageData.value.filter.maxAge = ageArr[1] !== undefined ? ageArr[1] : "";
        break;
      }
      case "balance": {
        pageData.value.filter.balance = value;
        const balanceArr = value.split("@");
        pageData.value.filter.minBalance = balanceArr[0];
        pageData.value.filter.maxBalance =
          balanceArr[1] !== undefined ? balanceArr[1] : "";
        break;
      }
    }
    pageData.value.pager.page = 1;
    updateRouters();
  };

  // pageData.value.filter.gender = ApiHelper.getGenderOptions();
  const selectAllGenders = () => {
    pageData.value.filter.gender.map((item: any) => {
      item.selected = true;
    });
    pageData.value.pager.page = 1;
    updateRouters();
  };
  const resetGenders = () => {
    pageData.value.filter.gender.map((item: any) => {
      item.selected = false;
    });
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const selectAllEvents = () => {
    pageData.value.filter.events.map((item: any) => {
      item.selected = true;
    });
    pageData.value.pager.page = 1;
    updateRouters();
  };
  const resetEvents = () => {
    pageData.value.filter.events.map((item: any) => {
      item.selected = false;
    });
    pageData.value.pager.page = 1;
    updateRouters();
  };

  const showStatusDropdown = (id: number) => {
    if (id !== pageData.value.openItemId) {
      pageData.value.openItemId = id;
    } else {
      pageData.value.openItemId = 0;
    }
  };

  const updateRoommateStatus = async () => {
    if (
      pageData.value.selectedRoommateRequest.requestStatus ==
      pageData.value.roommateStatusList.value
    ) {
      return;
    }

    try {
      pageData.value.isProcessingRoommateStatus = true;
      const selected = pageData.value.selectedRoommateRequest;
      const inList = pageData.value.roomates.find(
        (item: any) => item.requestId == selected.requestId
      );
      if (inList) {
        const result = await ApiHelper.callApi(
          "patch",
          "/profiles/roommateStatus",
          {
            requestId: selected.requestId,
            eventId: selected.eventId,
            ptypeId: selected.ptypeId,
            invitedProfileId: selected.invitedProfileId,
            profileId: selected.profileId,
            toParticipantId: selected.toParticipantId || 0,
            invitedCode: selected.invitedCode || "",
            requestStatus: pageData.value.roommateStatusList.value
          }
        );
        if (result.status == 1) {
          ApiHelper.showSuccessMessage(
            "Changed roommate request status successfully."
          );
          inList.requestStatus = pageData.value.roommateStatusList.value;
          pageData.value.roommateStatusEditVisible = false;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      pageData.value.isProcessingRoommateStatus = false;
    }
  };

  // init data
  (async () => {
    pageData.value.filter.gender = ApiHelper.getGenderOptions();
    const genderIds = (context.root.$route.query.gender || "").split(",");
    pageData.value.filter.gender.forEach((item: any) => {
      if (genderIds.indexOf(item.id) > -1) {
        item.selected = true;
      }
    });

    await loadData(1);
    pageData.value.arrBalances = ApiHelper.getBalanceOptions();
    pageData.value.arrBalances = ApiHelper.getBalanceOptions();
  })();

  (async () => {
    pageData.value.searchEventsValue.value =
      context.root.$route.query.searchEvents || "";
    if (context.root.$route.query.event) {
      await pageData.value.searchEvents(pageData.value.searchEventsValue.value);
      pageData.value.selectedEvents = pageData.value.filter.events.filter(
        (item: any) => item.selected
      );
    }
  })();

  const updateSortValue = (sort: string, direction: string) => {
    pageData.value.sort.order = sort;
    pageData.value.sort.direction = direction;
    updateRouters();
  };

  const linkNewInput = ref({
    profileId: 0,
    subprofileId: 0,
    familyId: 0,
    email: "",
    firstName: "",
    lastName: "",
    typeId: 0
  });

  const formValidateAll = () => {
    let hasError = false;
    let message = "";
    formData.value.controls.profile.error = "";
    formData.value.controls.email.error = "";
    formData.value.controls.firstName.error = "";
    formData.value.controls.lastName.error = "";
    formData.value.controls.type.error = "";

    linkNewInput.value.profileId = ownerProfileId.value;
    linkNewInput.value.familyId = familyId.value;

    if (formData.value.controls.profile.value === "") {
      linkNewInput.value.subprofileId = -1;
    } else {
      linkNewInput.value.subprofileId = parseInt(
        formData.value.controls.profile.value
      );
    }

    linkNewInput.value.email = formData.value.controls.email.value;
    linkNewInput.value.firstName = formData.value.controls.firstName.value;
    linkNewInput.value.lastName = formData.value.controls.lastName.value;
    linkNewInput.value.typeId =
      parseInt(formData.value.controls.type.value) || 0;

    if (linkNewInput.value.subprofileId === -1) {
      hasError = true;
      formData.value.controls.profile.error = message =
        "Please select a person";
    }
    if (linkNewInput.value.subprofileId === 0) {
      if (linkNewInput.value.email === "") {
        hasError = true;
        formData.value.controls.email.error = message = "Email is required";
      } else if (!ApiHelper.validateEmail(linkNewInput.value.email)) {
        hasError = true;
        formData.value.controls.email.error = message = "Email is invalid";
      }
      if (linkNewInput.value.firstName === "") {
        hasError = true;
        formData.value.controls.firstName.error = message =
          "First Name is required";
      }
      if (linkNewInput.value.lastName === "") {
        hasError = true;
        formData.value.controls.lastName.error = message =
          "Last Name is required";
      }
    }

    if (!linkNewInput.value.typeId) {
      hasError = true;
      formData.value.controls.type.error = message = "Type is required";
    } else {
      formData.value.controls.type.error = "";
    }

    return { hasError, message };
  };

  const resetFormData = () => {
    formData.value.controls.profile.value = "";
    formData.value.controls.email.value = "";
    formData.value.controls.firstName.value = "";
    formData.value.controls.lastName.value = "";
    formData.value.controls.type.value = "";
  };

  const popupNewItem = ref<{
    isProcessing: boolean;
    show: boolean;
    formData: any;
    onSubmit: any;
    removeFieldError: any;
    isAddNewSuccess: boolean;
  }>({
    isProcessing: false,
    show: false,
    formData: formData,
    removeFieldError: (name: string) => {
      switch (name) {
        case "email":
          formData.value.controls.email.error = "";
          break;
        case "firstName":
          formData.value.controls.firstName.error = "";
          break;
      }
    },
    isAddNewSuccess: false,
    onSubmit: async ($parent: any) => {
      const { hasError, message } = formValidateAll();
      if (!hasError) {
        const result = await ApiHelper.callApi(
          "post",
          "/profiles/tribes",
          linkNewInput.value,
          {}
        );
        if (result.status == 1) {
          popupNewItem.value.show = false;
          popupNewItem.value.isAddNewSuccess = true;
          resetFormData();
          ApiHelper.showSuccessMessage("Added");

          context.root.$router
            .replace({
              name: context.root.$route.name,
              params: { profileId: selectedProfileId.toString() },
              query: { family: result.data.id.toString() }
            })
            .catch(() => {
              // nothing
            });

          loadData(1);
          const parent = $parent;
          if (parent.$refs.SidebarProfileRef) {
            parent.$refs.SidebarProfileRef.loadData();
          }
        } else {
          ApiHelper.showErrorMessage(result.message, "Oops");
        }
      }
    }
  });

  (async () => {
    formData.value.controls.type.options = await ApiHelper.getFamilyTypeOptions();
  })();

  const deleteItem = async (item: any, $parent: any) => {
    const isConfirm = await Vue.swal({
      html: "Are you sure you want to delete this member?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      showCloseButton: true,
      closeButtonHtml:
        '<img data-v-269b7732="" src="/img/icons/icon-arrow-down.png" class="move-down" style="height: 7px; width: 12px;">'
    }).then(result => {
      setTimeout(function() {
        $(".swal2-backdrop-hide").addClass("d-none");
      }, 200);
      return result.isConfirmed;
    });
    if (!isConfirm) {
      return false;
    }
    ApiHelper.setDataLoading(true);
    const result = await ApiHelper.callApi(
      "delete",
      "/families/" + familyId.value + "/member",
      {
        profileId: item.id
      },
      {}
    );
    ApiHelper.setDataLoading(false);
    if (result.status == 1) {
      // Update data header profile
      popupNewItem.value.isAddNewSuccess = true;
      await loadData(1);
      ApiHelper.showSuccessMessage("The link has been deleted.", "Deleted");
      const parent = $parent;
      if (parent.$refs.SidebarProfileRef) {
        parent.$refs.SidebarProfileRef.loadData();
      }
    } else {
      const message = result.message || "";
      if (message === "Link Not Found") {
        ApiHelper.showSuccessMessage("The link has been deleted.", "Deleted");
        await loadData(1);
      } else {
        ApiHelper.showErrorMessage(result.message);
      }
    }
  };

  (async () => {
    const profileInfo = await ProfileHelper.getProfileInfo(selectedProfileId);
    isArchived.value = profileInfo.status === 2;
    const fullName = ApiHelper.getFullName(
      profileInfo.firstName,
      profileInfo.lastName
    );
    formData.value.controls.type.label = `${fullName}'s`;
    headerData.value.subTitle = fullName;
  })();

  return {
    loadList,
    updateRouters,
    loadData,
    isArchived,
    popupNewItem,
    // sort
    updateSortValue,
    errors,
    headerData,
    pageData,
    showStatusDropdown,
    // filters
    selectAllGenders,
    resetGenders,
    selectAllEvents,
    resetEvents,
    // selectAllFamilies,
    // resetFamilies,
    // pager
    gotoPage,
    getCheckedItems,
    togglePagerItems,
    onClickPrev,
    onClickNext,
    onPagerChange,
    setActiveFilterTab,
    closeFilterTab,
    getFiltersData,
    applyFilters,
    updateFilterValue,
    // popups
    deleteItem,
    updateRoommateStatus
  };
}
