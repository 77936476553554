









































































































import { ref, defineComponent } from "@vue/composition-api";
import VAlertMessage, {
  AlertMessageError
} from "@/components/VAlertMessage.vue";
import ProfileTabs from "@/components/Profile/ProfileTabs.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import SidebarProfileDetails from "@/components/Profile/SidebarProfileDetails.vue";
import Loading from "@/components/Common/Loading.vue";
import { useProfileDashboardStore } from "@/stores/Profile/ProfilesDashboardStore";
import Pager from "@/components/Common/Pager.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import TimelineTable from "@/components/Timeline/TimelineTable.vue";
import { ApiHelper } from "@/helpers";
import moment from "moment";

export default defineComponent({
  name: "ProfileDashboardPage",
  components: {
    TimelineTable,
    VAlertMessage,
    ProfileTabs,
    Loading,
    SidebarProfileDetails,
    HeaderProfileDetails,
    Pager,
    FilterSearchColumn,
    FilterNumberRangeColumn,
    FilterCheckboxesColumn
  },
  setup(props, context) {
    const {
      isArchived,
      errors,
      pageData,
      // filters
      updateFilterValue,
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange,
      openPreview,
      updateRouters,
      updateSortValue
    } = useProfileDashboardStore(context);
    return {
      isArchived,
      errors,
      pageData,
      // filters
      updateFilterValue,
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      // pager
      gotoPage,
      getCheckedItems,
      togglePagerItems,
      toggleHeadActions,
      hideHeadActions,
      onClickPrev,
      onClickNext,
      onPagerChange,
      ApiHelper,
      moment,
      openPreview,
      updateRouters,
      updateSortValue
    };
  }
});
