import { render, staticRenderFns } from "./SidebarProfileEvents.vue?vue&type=template&id=1a3c7272&scoped=true&v-show=showSidebar&"
import script from "./SidebarProfileEvents.vue?vue&type=script&lang=ts&"
export * from "./SidebarProfileEvents.vue?vue&type=script&lang=ts&"
import style0 from "./SidebarProfileEvents.vue?vue&type=style&index=0&id=1a3c7272&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a3c7272",
  null
  
)

export default component.exports