import { render, staticRenderFns } from "./ProfilesFamily.vue?vue&type=template&id=de232ffe&scoped=true&xmlns%3AhasRightPanel=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./ProfilesFamily.vue?vue&type=script&lang=ts&"
export * from "./ProfilesFamily.vue?vue&type=script&lang=ts&"
import style0 from "./ProfilesFamily.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ProfilesFamily.vue?vue&type=style&index=1&id=de232ffe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de232ffe",
  null
  
)

export default component.exports