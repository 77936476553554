

































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import Header from "@/components/Common/Header.vue";
import Loading from "@/components/Common/Loading.vue";
import Pager from "@/components/Common/Pager.vue";
import FilterSearchColumn from "@/components/Table/FilterSearchColumn.vue";
import FilterCheckboxesColumn from "@/components/Table/FilterCheckboxesColumn.vue";
import FilterNumberRangeColumn from "@/components/Table/FilterNumberRangeColumn.vue";
import FilterDateColumn from "@/components/Table/FilterDateColumn.vue";
import { useProfileFinancesStore } from "@/stores/Profile/ProfileFinancesStore";
import TableHeaderColumn from "@/components/Table/TableHeaderColumn.vue";
import SidebarProfileEvents from "@/components/Profile/SidebarProfileEvents.vue";
import TransferModal from "@/components/Financial/TransferModal.vue";
import PopupPayment from "@/components/Financial/PopupPayment.vue";
import PopupEditApplication from "@/components/Participant/PopupEditApplication.vue";
import HeaderProfileDetails from "@/components/Profile/HeaderProfileDetails.vue";
import NavProfileEvents from "@/components/Profile/NavProfileEvents.vue";
import NavProfileDetails from "@/components/Profile/NavProfileDetails.vue";
import TransactionDetails from "@/components/Financial/TransactionDetails.vue";

export default defineComponent({
  name: "ProfileFinances",
  components: {
    TableHeaderColumn,
    Loading,
    Header,
    Pager,
    FilterSearchColumn,
    FilterCheckboxesColumn,
    FilterNumberRangeColumn,
    FilterDateColumn,
    SidebarProfileEvents,
    TransferModal,
    PopupPayment,
    PopupEditApplication,
    HeaderProfileDetails,
    NavProfileEvents,
    NavProfileDetails,
    TransactionDetails
  },
  setup(props, context) {
    const {
      sidebarData,
      loadData,
      pageData,
      headerData,
      updateRouters,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      // pager
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      popupData,
      closePaymentModal,
      toggleTransactionDetails
    } = useProfileFinancesStore(context);

    const popupEditApplication = ref({
      show: false,
      profileId: 0,
      participantId: 0
    });

    const setIsPaying = (status: boolean) => {
      pageData.value.paying = status;
    };

    const showPopupEditApplication = (options: any) => {
      popupEditApplication.value.profileId = options.profileId;
      popupEditApplication.value.participantId = options.participantId;
      popupEditApplication.value.show = true;
    };

    const isRefundService = (item: any) => {
      if (
        item.transactionId.indexOf("re_") != -1
        // && (item.serviceNames || "") != ""
      ) {
        return true;
      }

      return false;
    };

    return {
      sidebarData,
      loadData,
      pageData,
      headerData,
      updateRouters,
      // filters
      getFiltersData,
      setActiveFilterTab,
      closeFilterTab,
      updateFilterValue,
      updateSortValue,
      // pager
      loadList,
      gotoPage,
      onPagerChange,
      onClickPrev,
      onClickNext,
      togglePagerItems,
      setIsPaying,
      popupData,
      closePaymentModal,
      popupEditApplication,
      showPopupEditApplication,
      toggleTransactionDetails,
      isRefundService
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadList();
  }
});
