import VueRouter, { RouteConfig } from "vue-router";

import EventDashboard from "../views/Event/EventDashboard.vue";
import EventGroupDetails from "../views/Event/EventGroupDetails.vue";
import EventGroups from "../views/Event/EventGroups.vue";
import EventSettings from "../views/Event/EventSettings.vue";
import EventSettingsApplications from "../views/Event/EventSettingsApplications.vue";
import EventTimeline from "../views/Event/EventTimeline.vue";
import Events from "../views/Event/Events.vue";
import LayoutDefault from "../views/LayoutDefault.vue";
import Login from "../views/Login.vue";
import ProfileDashboard from "../views/Profile/ProfileDashboard.vue";
import ProfileEdit from "../views/Profile/ProfileEdit.vue";
import ProfileEvents from "../views/Profile/ProfileEvents.vue";
import ProfileSettings from "../views/Profile/ProfileSettings.vue";
import ProfileTribes from "../views/Profile/ProfileTribes.vue";
import Profiles from "../views/Profile/Profiles.vue";
import Vue from "vue";
import SettingsApplications from "@/views/Settings/SettingsApplications/SettingsApplications.vue";
import SettingsBilling from "@/views/Settings/SettingsBilling/SettingsBilling.vue";
import SettingsParticipantForms from "@/views/Settings/SettingsParticipantForms/SettingsParticipantForms.vue";
import SettingsParticipantTypes from "@/views/Settings/SettingsParticipantTypes/SettingsParticipantTypes.vue";
import SettingsProfile from "@/views/Settings/SettingsProfile.vue";
import PortalCustomization from "@/views/Settings/SettingsPortalCustomization/PortalCustomization.vue";
import StripeCreds from "@/views/Settings/SettingsStripeCreds/StripeCreds.vue";
import ProfileAdd from "@/views/Profile/ProfileAdd.vue";
import ProfilesFamily from "@/views/Profile/ProfilesFamily.vue";
import EventDetails from "@/views/Event/EventDetails.vue";
import EventFinancial from "@/views/Event/EventFinancial.vue";
import EventsAdd from "@/views/Event/EventsAdd.vue";
import EventSchedule from "@/views/Event/EventSchedule.vue";
import EventCabins from "@/views/Event/EventCabins.vue";
import EventCabinsDormGenerate from "@/views/Event/EventCabinsDormGenerate.vue";
import EventTransportation from "@/views/Event/EventTransportation.vue";
import EventMedical from "@/views/Event/EventMedical.vue";
import EventAtCamp from "@/views/Event/EventAtCamp.vue";
import ProfileAtCamp from "@/views/Profile/ProfileAtCamp.vue";
import ProfileFinancial from "@/views/Profile/ProfileFinancial.vue";
import ProfileFiles from "@/views/Profile/ProfileFiles/ProfileFiles.vue";
import ProfileMedicals from "@/views/Profile/ProfileMedicals.vue";
import ProfileAllergies from "@/views/Profile/ProfileAllergies.vue";
import ProfileMedicalEdit from "@/views/Profile/ProfileMedicalsEdit.vue";
import EventCabinDetails from "@/views/Event/EventCabinDetails.vue";
import ProfileDetailsMaster from "@/views/Profile/ProfileDetailsMaster.vue";
import EventDetailsMaster from "@/views/Event/EventDetailsMaster.vue";
import SettingsMaster from "@/views/Settings/SettingsMaster.vue";
import SettingsApplicationsEdit from "@/views/Settings/SettingsApplications/SettingsApplicationsEdit.vue";
import SettingsApplicationsDetails from "@/views/Settings/SettingsApplications/SettingsApplicationsDetails.vue";
import SettingsParticipantFormsDetails from "@/views/Settings/SettingsParticipantForms/SettingsParticipantFormsDetails.vue";
import SettingsParticipantFormsEdit from "@/views/Settings/SettingsParticipantForms/SettingsParticipantFormsEdit.vue";
import SettingsParticipantTypesDetails from "@/views/Settings/SettingsParticipantTypes/SettingsParticipantTypesDetails.vue";
import SettingsParticipantTypesEdit from "@/views/Settings/SettingsParticipantTypes/SettingsParticipantTypesEdit.vue";
import UserMaster from "@/views/User/UserMaster.vue";
import UserForgotPassword from "@/views/User/UserForgotPassword.vue";
import UserResetPassword from "@/views/User/UserResetPassword.vue";
import UserLogin from "@/views/User/UserLogin.vue";
import MessagingDashboard from "@/views/Messaging/MessagingDashboard.vue";
import MessagingCampaignsAdd from "@/views/Messaging/Campaigns/MessagingCampaignsAdd.vue";
import MessagingCampaignsAddWorkflow from "@/views/Messaging/Campaigns/MessagingCampaignsAddWorkflow.vue";
import MessagingCampaignsPreview from "@/views/Messaging/Campaigns/MessagingCampaignsPreview.vue";
import MessagingCampaigns from "@/views/Messaging/Campaigns/MessagingCampaigns.vue";
import MessagingInbox from "@/views/Messaging/Inbox/MessagingInbox.vue";
import MessagingSettings from "@/views/Messaging/MessagingSettings.vue";
import MessagingInboxDetails from "@/views/Messaging/Inbox/MessagingInboxDetails.vue";
import MessagingMaster from "@/views/Messaging/MessagingMaster.vue";
import MessagingCampaignsDetails from "@/views/Messaging/Campaigns/MessagingCampaignsDetails.vue";
import MessagingCampaignsEdit from "@/views/Messaging/Campaigns/MessagingCampaignsEdit.vue";
import MessagingCampaignsTemplate from "@/views/Messaging/Campaigns/MessagingCampaignsTemplate.vue";
import MessagingCampaignsTemplate2 from "@/views/Messaging/Campaigns/MessagingCampaignsTemplate2.vue";
import MessagingTemplates from "@/views/Messaging/Templates/MessagingTemplates.vue";
import MessagingTemplatesAdd from "@/views/Messaging/Templates/MessagingTemplatesAdd.vue";
import MessagingTemplatesDetails from "@/views/Messaging/Templates/MessagingTemplatesDetails.vue";
import MessagingTemplatesEdit from "@/views/Messaging/Templates/MessagingTemplatesEdit.vue";
import NotFound from "@/views/404.vue";
import FinancialDashboard from "@/views/Financial/FinancialDashboard.vue";
import FinancialMaster from "@/views/Financial/FinancialMaster.vue";
import FinancialTransactions from "@/views/Financial/FinancialTransactions.vue";
import FinancialUnpaid from "@/views/Financial/FinancialUnpaid.vue";
import FinancialPlans from "@/views/Financial/FinancialPlans.vue";
import OutstandingBalances from "@/views/Financial/OutstandingBalances.vue";
import CategoryBalance from "@/views/Financial/CategoryBalance.vue";
import FamilyDetailsMaster from "@/views/Family/FamilyDetailsMaster.vue";
import FamilyDetails from "@/views/Family/FamilyDetails.vue";
import FamilyEvents from "@/views/Family/FamilyEvents.vue";
import ReportsMaster from "@/views/Report/ReportsMaster.vue";
import Reports from "@/views/Report/Reports.vue";
import ReportEdit from "@/views/Report/ReportEdit.vue";
import ReportDetail from "@/views/Report/ReportDetail.vue";
import SettingsDomains from "@/views/Settings/SettingsDomains/SettingsDomains.vue";
import { ApiHelper } from "@/helpers";
import FinancialTransactionDetails from "@/views/Financial/FinancialTransactionDetails.vue";
import FinancialPlanDetails from "@/views/Financial/FinancialPlanDetails.vue";
import SettingsUsers from "@/views/Settings/SettingsUsers/SettingsUsers.vue";
import SettingsUserDetails from "@/views/Settings/SettingsUsers/SettingsUserDetails.vue";
import SettingsUserEdit from "@/views/Settings/SettingsUsers/SettingsUserEdit.vue";
import UserPasswordReset from "@/views/User/UserPasswordReset.vue";
import ProfileRoommates from "@/views/Profile/ProfileRoommates.vue";
import ProfileTodos from "@/views/Profile/ProfileTodos.vue";
import ProfileApplication from "@/views/Profile/ProfileApplication.vue";
import ProfileFinances from "@/views/Profile/ProfileFinances.vue";
import ProfileMessages from "@/views/Profile/ProfileMessages.vue";
import SettingsGroups from "@/views/Settings/SettingsGroups/SettingsGroups.vue";
import SettingsGroupEdit from "@/views/Settings/SettingsGroups/SettingsGroupEdit.vue";
import SettingsEventGroups from "@/views/Settings/SettingsEventGroups/SettingsEventGroups.vue";
import SettingsEventGroupEdit from "@/views/Settings/SettingsEventGroups/SettingsEventGroupEdit.vue";
import CostCentersSummary from "@/views/Report/CostCenters/CostCentersSummary.vue";
import CostCenterTransactions from "@/views/Report/CostCenters/CostCenterTransactions.vue";
import Dashboard from "@/views/Dashboard.vue";
import ProfileNotes from "@/views/Profile/ProfileNotes.vue";
import ProfileNoteEdit from "@/views/Profile/ProfileNoteEdit.vue";
import SettingsTags from "@/views/Settings/SettingsTags/SettingsTags.vue";
import SettingsTagEdit from "@/views/Settings/SettingsTags/SettingsTagEdit.vue";
import SettingsTagTypes from "@/views/Settings/SettingsTagTypes/SettingsTagTypes.vue";
import SettingsTagTypeEdit from "@/views/Settings/SettingsTagTypes/SettingsTagTypeEdit.vue";
// import SettingsContentTypes from "@/views/Settings/SettingsContentTypes/SettingsContentTypes.vue";
// import SettingsContentTypeEdit from "@/views/Settings/SettingsContentTypes/SettingsContentTypeEdit.vue";
// import SettingsContents from "@/views/Settings/SettingsTagContents/SettingsTagContents.vue";
// import SettingsContentEdit from "@/views/Settings/SettingsTagContents/SettingsTagContentEdit.vue";
import FamilyFiles from "@/views/Family/FamilyFiles.vue";
import MedicalMaster from "@/views/Medical/MedicalMaster.vue";
import MedicalDashboard from "@/views/Medical/MedicalDashboard.vue";
import EventsOverview from "@/views/Event/EventsOverview.vue";
import EventsCustom from "@/views/Event/EventsCustom.vue";
import SettingsCabins from "@/views/Settings/SettingsCabins/SettingsCabins.vue";
import SettingsCabinEdit from "@/views/Settings/SettingsCabins/SettingsCabinEdit.vue";
import StoreUsers from "@/views/vpos/users/StoreUsers.vue";
import StoreUserAdd from "@/views/vpos/users/StoreUserAdd.vue";
import StoreProducts from "@/views/vpos/product/StoreProducts.vue";
import StoreProductAdd from "@/views/vpos/product/StoreProductAdd.vue";
import StoreTerminals from "@/views/vpos/terminal/StoreTerminals.vue";
import StoreTerminalAdd from "@/views/vpos/terminal/StoreTerminalAdd.vue";
import StoreOrders from "@/views/vpos/orders/StoreOrders.vue";
import StoreOrderDetails from "@/views/vpos/orders/StoreOrderDetails.vue";
import ReportMedication from "@/views/Report/ReportMedication.vue";
import ReportMedicalGeneral from "@/views/Report/ReportMedicalGeneral.vue";
import StoreOrderCreate from "@/views/vpos/orders/StoreOrderCreate.vue";
import FinancialAccounting from "@/views/Financial/Accounting/FinancialAccounting.vue";
import FinancialCostCenter from "@/views/Financial/Accounting/FinancialCostCenter.vue";
import FinancialPosting from "@/views/Financial/Accounting/FinancialPosting.vue";
import AccountingCostCenterEdit from "@/views/Financial/Accounting/AccountingCostCenterEdit.vue";
import AccountingPostingEdit from "@/views/Financial/Accounting/AccountingPostingEdit.vue";
import RevenueSpendCategory from "@/views/Financial/Accounting/RevenueSpendCategory.vue";
import RevenueSpendCategoryEdit from "@/views/Financial/Accounting/RevenueSpendCategoryEdit.vue";
import SettingsMailingAddress from "@/views/Settings/SettingsPortalCustomization/SettingsMailingAddress.vue";
import SettingsDiscounts from "@/views/Settings/SettingsDiscounts/SettingsDiscounts.vue";
import SettingsDiscountEdit from "@/views/Settings/SettingsDiscounts/SettingsDiscountEdit.vue";

export const routerData = new Vue({
  data: {
    errorData: {},
    globalMessage: {},
    isDataLoading: {
      value: false
    },
    lastCallTS: new Date()
  }
});

const resetRouterData = () => {
  Vue.set(routerData.errorData, "message", "");
  Vue.set(routerData.errorData, "redirectName", "");
  Vue.set(routerData.errorData, "redirectQuery", {});
  const win = window as any;
  win.errorData = null;
};

Vue.use(VueRouter);

// declare user functions
let initData = false;
const userFunctions = {
  People: 1,
  Events: 2,
  AddEvent: 23,
  EditEvent: 25,
  AddParticipant: 27,
  ViewEvents: 24,
  Message: 3,
  Financial: 4,
  Reports: 5,
  Settings: 6,
  AddPerson: 7,
  EditPerson: 26,
  ViewPeople: 8,
  Dashboard: 9,
  UnpaidBalances: 10,
  Plans: 11,
  Transactions: 12,
  ViewReports: 13,
  AddReport: 14,
  ParticipantForms: 15,
  Applications: 16,
  // Profile: 17,
  Domains: 18,
  PortalCustomization: 19,
  StripeConfiguration: 20,
  Users: 21,
  Groups: 22,
  AddPayment: 28,
  Refund: 29,
  Tags: 30,
  EditTags: 31,
  ViewTags: 32,
  CheckInPermissions: 33,
  Medical: 34
};
Vue.prototype.$userFunctions = userFunctions;
Vue.prototype.$allowedFunctions = [];

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Login",
    component: Login,
    redirect: "/login"
  },
  {
    path: "/user",
    name: "User",
    component: UserMaster,
    children: [
      {
        path: "/login",
        name: "UserLogin",
        component: UserLogin
      },
      {
        path: "/forgotpassword",
        name: "UserForgotPassword",
        component: UserForgotPassword
      },
      {
        path: "/resetpassword",
        name: "UserResetPassword",
        component: UserResetPassword
      },
      {
        path: "/passwordreset",
        name: "UserPasswordReset",
        component: UserPasswordReset
      }
    ]
  },
  {
    path: "/",
    name: "Layout",
    component: LayoutDefault,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
          requiresAuth: true
        },
        component: Dashboard
      },
      {
        path: "/profiles/person",
        name: "Profiles",
        meta: {
          requiresAuth: true
        },
        component: Profiles
      },
      {
        path: "/profiles/event/:eventId",
        name: "ProfilesEvent",
        meta: {
          requiresAuth: true
        },
        component: Profiles
      },
      // {
      //   path: "/profiles/default",
      //   name: "ProfilesDefault",
      //   meta: {
      //     requiresAuth: true
      //   },
      //   component: Profiles
      // },
      {
        path: "/profiles/family",
        name: "ProfilesFamily",
        meta: {
          requiresAuth: true
        },
        component: ProfilesFamily
      },
      {
        path: "/profiles/custom",
        name: "ProfilesCustom",
        meta: {
          requiresAuth: true
        },
        component: Profiles
      },
      {
        path: "/profiles/add",
        name: "ProfileAdd",
        meta: {
          requiresAuth: true
        },
        component: ProfileAdd
      },
      {
        path: "/profiles/:profileId",
        name: "ProfileDetailsMaster",
        meta: {
          requiresAuth: true
        },
        component: ProfileDetailsMaster,
        children: [
          {
            path: "/profiles/:profileId/dashboard",
            name: "ProfileDashboard",
            component: ProfileDashboard
          },
          {
            path: "/profiles/:profileId/medicals",
            name: "ProfileMedicals",
            component: ProfileMedicals
          },
          {
            path: "/profiles/:profileId/medicals/:medicalId/edit",
            name: "ProfileMedicalEdit",
            component: ProfileMedicalEdit
          },
          {
            path: "/profiles/:profileId/notes",
            name: "ProfileNotes",
            component: ProfileNotes
          },
          {
            path: "/profiles/:profileId/notes/:noteId/edit",
            name: "ProfileNoteEdit",
            component: ProfileNoteEdit
          },
          {
            path: "/profiles/:profileId/files",
            name: "ProfileFiles",
            component: ProfileFiles
          },
          {
            path: "/profiles/:profileId/atcamp",
            name: "ProfileAtCamp",
            component: ProfileAtCamp
          },
          {
            path: "/profiles/:profileId/financial",
            name: "ProfileFinancial",
            component: ProfileFinancial
          },
          {
            path: "/profiles/:profileId/details",
            name: "ProfileDetails",
            component: ProfileDashboard
          },
          {
            path: "/profiles/:profileId/application",
            name: "ProfileApplication",
            component: ProfileApplication
          },
          {
            path: "/profiles/:profileId/edit",
            name: "ProfileEdit",
            component: ProfileEdit
          },
          {
            path: "/profiles/:profileId/events",
            name: "ProfileEvents",
            component: ProfileEvents
          },
          {
            path: "/profiles/:profileId/todos",
            name: "ProfileTodos",
            component: ProfileTodos
          },
          {
            path: "/profiles/:profileId/allergies",
            name: "ProfileAllergies",
            component: ProfileAllergies
          },
          {
            path: "/profiles/:profileId/tribes",
            name: "ProfileTribes",
            component: ProfileTribes
          },
          {
            path: "/profiles/:profileId/finances",
            name: "ProfileFinances",
            component: ProfileFinances
          },
          {
            path: "/profiles/:profileId/roommates",
            name: "ProfileRoommates",
            component: ProfileRoommates
          },
          {
            path: "/profiles/:profileId/settings",
            name: "ProfileSettings",
            component: ProfileSettings
          },
          {
            path: "/profiles/:profileId/messages",
            name: "ProfileMessages",
            component: ProfileMessages
          }
        ]
      },
      {
        path: "/events",
        name: "Events",
        meta: {
          requiresAuth: true
        },
        component: Events
      },
      {
        path: "/events/add",
        name: "EventsAdd",
        meta: {
          requiresAuth: true
        },
        component: EventsAdd
      },
      {
        path: "/events/custom",
        name: "EventsCustom",
        meta: {
          requiresAuth: true
        },
        component: EventsCustom
      },
      {
        path: "/events/overview",
        name: "EventsOverview",
        meta: {
          requiresAuth: true
        },
        component: EventsOverview
      },
      {
        path: "/events/:eventId",
        name: "EventDetailsMaster",
        meta: {
          requiresAuth: true
        },
        component: EventDetailsMaster,
        children: [
          {
            path: "/events/:eventId/overview",
            name: "EventOverview",
            component: EventDetails
          },
          /*{
            path: "/events/:eventId/apps",
            name: "EventApplications",
            component: EventApplications
          },
          {
            path: "/events/:eventId/apps/:appId/details",
            name: "EventApplicationsDetails",
            component: EventApplicationsDetails
          },
          {
            path: "/events/:eventId/apps/:appId/edit",
            name: "EventApplicationsEdit",
            component: EventApplicationsEdit
          },
          {
            path: "/events/:eventId/timelineOverview",
            name: "EventTimelineOverview",
            component: EventTimelineOverview
          },
          */
          {
            path: "/events/:eventId/details",
            name: "EventDetails",
            component: EventDetails
          },
          {
            path: "/events/:eventId/timeline",
            name: "EventTimeline",
            component: EventTimeline
          },
          {
            path: "/events/:eventId/dashboard",
            name: "EventDashboard",
            component: EventDashboard
          },
          {
            path: "/events/:eventId/schedule",
            name: "EventSchedule",
            component: EventSchedule
          },
          {
            path: "/events/:eventId/cabins",
            name: "EventCabins",
            component: EventCabins
          },
          {
            path: "/events/:eventId/cabins/dormgenerate",
            name: "EventCabinsDormGenerate",
            component: EventCabinsDormGenerate
          },
          {
            path: "/events/:eventId/cabins/:cabinId",
            name: "EventCabinDetails",
            component: EventCabinDetails
          },
          {
            path: "/events/:eventId/medical",
            name: "EventMedical",
            component: EventMedical
          },
          {
            path: "/events/:eventId/atcamp",
            name: "EventAtCamp",
            component: EventAtCamp
          },
          {
            path: "/events/:eventId/transportation",
            name: "EventTransportation",
            component: EventTransportation
          },
          {
            path: "/events/:eventId/groups",
            name: "EventGroups",
            component: EventGroups
          },
          {
            path: "/events/:eventId/groups/:groupId",
            name: "EventGroupDetails",
            component: EventGroupDetails
          },
          {
            path: "/events/:eventId/financial",
            name: "EventFinancial",
            component: EventFinancial
          },
          {
            path: "/events/:eventId/settings",
            name: "EventSettings",
            component: EventSettings
          },
          {
            path: "/events/:eventId/settings/applications",
            name: "EventSettingsApplications",
            component: EventSettingsApplications
          }
        ]
      },
      {
        path: "/messaging",
        name: "Messaging",
        meta: {
          requiresAuth: true
        },
        component: MessagingMaster,
        children: [
          {
            path: "/messaging/",
            name: "MessagingDashboard",
            component: MessagingDashboard
          },
          {
            path: "/messaging/dashboard",
            name: "MessagingDashboard",
            component: MessagingDashboard
          },
          {
            path: "/messaging/inbox",
            name: "MessagingInbox",
            component: MessagingInbox
          },
          {
            path: "/messaging/inbox/:id",
            name: "MessagingInboxDetails",
            component: MessagingInboxDetails
          },
          {
            path: "/messaging/campaigns",
            name: "MessagingCampaigns",
            component: MessagingCampaigns
          },
          {
            path: "/messaging/campaigns/add",
            name: "MessagingCampaignsAdd",
            component: MessagingCampaignsAdd
          },
          {
            path: "/messaging/campaigns/addworkflow",
            name: "MessagingCampaignsAddWorkflow",
            component: MessagingCampaignsAddWorkflow
          },
          {
            path: "/messaging/campaigns/preview",
            name: "MessagingCampaignsPreview",
            component: MessagingCampaignsPreview
          },
          {
            path: "/messaging/campaigns/:id/details",
            name: "MessagingCampaignsDetails",
            component: MessagingCampaignsDetails
          },
          {
            path: "/messaging/campaigns/:id/edit",
            name: "MessagingCampaignsEdit",
            component: MessagingCampaignsEdit
          },
          {
            path: "/messaging/templates",
            name: "MessagingTemplates",
            component: MessagingTemplates
          },
          {
            path: "/messaging/templates/add",
            name: "MessagingTemplatesAdd",
            component: MessagingTemplatesAdd
          },
          {
            path: "/messaging/templates/:id/details",
            name: "MessagingTemplatesDetails",
            component: MessagingTemplatesDetails
          },
          {
            path: "/messaging/templates/:id/edit",
            name: "MessagingTemplatesEdit",
            component: MessagingTemplatesEdit
          },
          {
            path: "/messaging/campaigns/:id/template",
            name: "MessagingCampaignsTemplate",
            component: MessagingCampaignsTemplate
          },
          {
            path: "/messaging/campaigns/:id/template2",
            name: "MessagingCampaignsTemplate2",
            component: MessagingCampaignsTemplate2
          },
          {
            path: "/messaging/settings",
            name: "MessagingSettings",
            component: MessagingSettings
          }
        ]
      },
      {
        path: "/financial",
        name: "Financial",
        meta: {
          requiresAuth: true
        },
        component: FinancialMaster,
        redirect: "/financial/dashboard",
        children: [
          {
            path: "/financial/dashboard",
            name: "FinancialDashboard",
            component: FinancialDashboard
          },
          // {
          //   path: "/financial/add",
          //   name: "FinancialAdd",
          //   component: FinancialAdd
          // },
          {
            path: "/financial/transactions",
            name: "FinancialTransactions",
            component: FinancialTransactions
          },
          {
            path: "/financial/transactions/details",
            name: "FinancialTransactionDetails",
            component: FinancialTransactionDetails
          },
          {
            path: "/financial/plans",
            name: "FinancialPlans",
            component: FinancialPlans
          },
          {
            path: "/financial/plans/details",
            name: "FinancialPlanDetails",
            component: FinancialPlanDetails
          },
          {
            path: "/financial/unpaid",
            name: "FinancialUnpaid",
            component: FinancialUnpaid
          },
          {
            path: "/financial/outstanding",
            name: "OutstandingBalances",
            component: OutstandingBalances
          },
          {
            path: "/financial/categoryBalance",
            name: "CategoryBalance",
            component: CategoryBalance
          },
          {
            path: "/financial/accounting",
            name: "FinancialAccounting",
            component: FinancialAccounting
          },
          {
            path: "/financial/costcenters",
            name: "FinancialCostCenters",
            component: FinancialCostCenter
          },
          {
            path: "/financial/costcenter/:id/edit",
            name: "FinancialCostCenterEdit",
            component: AccountingCostCenterEdit
          },
          {
            path: "/financial/posting",
            name: "FinancialPosting",
            component: FinancialPosting
          },
          {
            path: "/financial/posting/:id/edit",
            name: "FinancialPostingEdit",
            component: AccountingPostingEdit
          },
          {
            path: "/financial/transactionPostings",
            name: "TransactionPostings",
            component: FinancialPosting
          },
          {
            path: "/financial/transactionPostings/:id/edit",
            name: "TransactionPostingsEdit",
            component: AccountingPostingEdit
          },
          {
            path: "/financial/revenueSpendCategory",
            name: "RevenueSpendCategory",
            component: RevenueSpendCategory
          },
          {
            path: "/financial/revenueSpendCategory/:id/edit",
            name: "RevenueSpendCategoryEdit",
            component: RevenueSpendCategoryEdit
          }
        ]
      },
      {
        path: "/reports",
        name: "ReportsMaster",
        meta: {
          requiresAuth: true
        },
        component: ReportsMaster,
        redirect: {
          name: "Reports"
        },
        children: [
          {
            path: "/reports/default",
            name: "Reports",
            component: Reports
          },
          // {
          //   path: "/reports/add",
          //   name: "ReportsAdd",
          //   component: ReportsAdd
          // },
          {
            path: "/reports/:id/edit",
            name: "ReportEdit",
            component: ReportEdit
          },
          {
            path: "/reports/:id/details",
            name: "ReportDetail",
            component: ReportDetail
          },
          // {
          //   path: "/costcenterssummary",
          //   name: "CostCentersSummary",
          //   component: CostCentersSummary
          // },
          // {
          //   path: "/costcentertransactions/:id",
          //   name: "CostCenterTransactions",
          //   component: CostCenterTransactions
          // },
          // {
          //   path: "/reports/medication",
          //   name: "ReportMedication",
          //   component: ReportMedication
          // },
          {
            path: "/reports/medicalGeneral",
            name: "ReportMedicalGeneral",
            component: ReportMedicalGeneral
          }
        ]
      },
      {
        path: "/settings",
        name: "Settings",
        meta: {
          requiresAuth: true
        },
        component: SettingsMaster,
        children: [
          {
            path: "/settings/billing",
            name: "SettingsBilling",
            component: SettingsBilling
          },
          {
            path: "/settings/applications",
            name: "SettingsApplications",
            component: SettingsApplications
          },
          {
            path: "/settings/applications/:appId/details",
            name: "SettingsApplicationsDetails",
            component: SettingsApplicationsDetails
          },
          {
            path: "/settings/applications/:appId/edit",
            name: "SettingsApplicationsEdit",
            component: SettingsApplicationsEdit
          },
          {
            path: "/settings/participant-forms",
            name: "SettingsParticipantForms",
            component: SettingsParticipantForms
          },
          {
            path: "/settings/participant-forms/:formId/details",
            name: "SettingsParticipantFormsDetails",
            component: SettingsParticipantFormsDetails
          },
          {
            path: "/settings/participant-forms/:formId/edit",
            name: "SettingsParticipantFormsEdit",
            component: SettingsParticipantFormsEdit
          },
          {
            path: "/settings/participant-types",
            name: "SettingsParticipantTypes",
            component: SettingsParticipantTypes
          },
          {
            path: "/settings/participant-types/:typeId/details",
            name: "SettingsParticipantTypesDetails",
            component: SettingsParticipantTypesDetails
          },
          {
            path: "/settings/participant-types/:typeId/edit",
            name: "SettingsParticipantTypesEdit",
            component: SettingsParticipantTypesEdit
          },
          {
            path: "/settings/profile",
            name: "SettingsProfile",
            component: SettingsProfile
          },
          {
            path: "/settings/customization",
            name: "PortalCustomization",
            component: PortalCustomization
          },
          {
            path: "/settings/stripecreds",
            name: "StripeCreds",
            component: StripeCreds
          },
          {
            path: "/settings/domains",
            name: "SettingsDomains",
            component: SettingsDomains
          },
          // users
          {
            path: "/settings/users",
            name: "SettingsUsers",
            component: SettingsUsers
          },
          {
            path: "/settings/users/:id/details",
            name: "SettingsUserDetails",
            component: SettingsUserDetails
          },
          {
            path: "/settings/users/:id/edit",
            name: "SettingsUserEdit",
            component: SettingsUserEdit
          },
          // groups system
          {
            path: "/settings/groups",
            name: "SettingsGroups",
            component: SettingsGroups
          },
          {
            path: "/settings/groups/:id/edit",
            name: "SettingsGroupEdit",
            component: SettingsGroupEdit
          },
          // event groups
          {
            path: "/settings/event-groups",
            name: "SettingsEventGroups",
            component: SettingsEventGroups
          },
          {
            path: "/settings/event-groups/:id/edit",
            name: "SettingsEventGroupEdit",
            component: SettingsEventGroupEdit
          },
          // tags
          {
            path: "/settings/tags",
            name: "SettingsTags",
            component: SettingsTags
          },
          {
            path: "/settings/tag/:id/edit",
            name: "SettingsTagEdit",
            component: SettingsTagEdit
          },
          // tag types
          {
            path: "/settings/tagtypes",
            name: "SettingsTagTypes",
            component: SettingsTagTypes
          },
          {
            path: "/settings/tagtype/:id/edit",
            name: "SettingsTagTypeEdit",
            component: SettingsTagTypeEdit
          },
          // content types
          // {
          //   path: "/settings/contenttypes",
          //   name: "SettingsContentTypes",
          //   component: SettingsContentTypes
          // },
          // {
          //   path: "/settings/contenttype/:id/edit",
          //   name: "SettingsContentTypeEdit",
          //   component: SettingsContentTypeEdit
          // },
          // contents
          // {
          //   path: "/settings/tagcontents",
          //   name: "SettingsTagContents",
          //   component: SettingsContents
          // },
          // {
          //   path: "/settings/tagcontents/:id/edit",
          //   name: "SettingsTagContentEdit",
          //   component: SettingsContentEdit
          // },
          // entity cabins
          {
            path: "/settings/cabins",
            name: "SettingsCabins",
            component: SettingsCabins
          },
          {
            path: "/settings/cabins/:id/edit",
            name: "SettingsCabinEdit",
            component: SettingsCabinEdit
          },
          // entity Mailing Address
          {
            path: "/settings/mailingaddress",
            name: "SettingsMailingAddress",
            component: SettingsMailingAddress
          },
          {
            path: "/settings/discounts",
            name: "SettingsDiscounts",
            component: SettingsDiscounts
          },
          {
            path: "/settings/discount/:id/edit",
            name: "SettingsDiscountEdit",
            component: SettingsDiscountEdit
          }
        ]
      },
      {
        path: "/family/:Id",
        name: "FamilyDetailsMaster",
        meta: {
          requiresAuth: true
        },
        component: FamilyDetailsMaster,
        children: [
          {
            path: "/family/:Id/details",
            name: "FamilyDetails",
            component: FamilyDetails
          },
          {
            path: "/family/:Id/events",
            name: "FamilyEvents",
            component: FamilyEvents
          },
          {
            path: "/family/:Id/files",
            name: "FamilyFiles",
            component: FamilyFiles
          }
        ]
      },
      {
        path: "/medical",
        name: "Medical",
        meta: {
          requiresAuth: true
        },
        component: MedicalMaster,
        children: [
          {
            path: "/medical/dashboard",
            name: "MedicalDashboard",
            component: MedicalDashboard
          }
        ]
      },
      {
        path: "/pos/storeusers",
        name: "StoreUsers",
        meta: {
          requiresAuth: true
        },
        component: StoreUsers
      },
      {
        path: "/pos/storeusers/edit",
        name: "StoreUserEdit",
        component: StoreUserAdd
      },
      {
        path: "/pos/storeproducts",
        name: "StoreProduct",
        meta: {
          requiresAuth: true
        },
        component: StoreProducts
      },
      {
        path: "/pos/storeproducts/edit",
        name: "StoreProductAdd",
        component: StoreProductAdd
      },
      {
        path: "/pos/storeproducts/edit/:id",
        name: "StoreProductEdit",
        component: StoreProductAdd
      },
      {
        path: "/pos/storeorders",
        name: "StoreOrders",
        component: StoreOrders
      },
      {
        path: "/pos/storeterminals/edit",
        name: "StoreTerminalAdd",
        component: StoreTerminalAdd
      },
      {
        path: "/pos/storeterminals/edit/:id",
        name: "StoreTerminalEdit",
        component: StoreTerminalAdd
      },
      {
        path: "/pos/storeterminals",
        name: "StoreTerminals",
        component: StoreTerminals
      },
      {
        path: "/pos/storeorders/details",
        name: "StoreOrderDetails",
        component: StoreOrderDetails
      },
      {
        path: "/pos/storeorders/create",
        name: "StoreOrderCreate",
        component: StoreOrderCreate
      }
    ]
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  resetRouterData();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const loggedUser = ApiHelper.getLoggedUser();
    if (!loggedUser.id) {
      next({
        name: "UserLogin",
        query: { redirect: to.fullPath }
      });
    } else {
      // get allowed functions
      let allowedFunctions: number[] = [];
      if (to.query.rt === "1") {
        initData = false;
      }
      if (!initData) {
        allowedFunctions = await ApiHelper.getAllowedFunctions(true);
        initData = true;
      } else {
        allowedFunctions = await ApiHelper.getAllowedFunctions(false);
      }
      Vue.prototype.$allowedFunctions = allowedFunctions;

      let canAccess = true;
      switch (to.name) {
        case "Profiles":
          if (!allowedFunctions.includes(userFunctions.ViewPeople)) {
            canAccess = false;
          }
          break;
        case "ProfileAdd":
          if (!allowedFunctions.includes(userFunctions.AddPerson)) {
            canAccess = false;
          }
          break;
        case "Events":
        case "EventDetails":
          if (!allowedFunctions.includes(userFunctions.ViewEvents)) {
            canAccess = false;
          }
          break;
        case "EventsAdd":
          if (!allowedFunctions.includes(userFunctions.AddEvent)) {
            canAccess = false;
          }
          break;
        case "MessagingDashboard":
        case "MessagingInbox":
        case "MessagingCampaigns":
        case "MessagingCampaignsAdd":
        case "MessagingTemplates":
        case "MessagingTemplatesAdd":
        case "MessagingSettings":
        case "ProfileMessages":
          if (!allowedFunctions.includes(userFunctions.Message)) {
            canAccess = false;
          }
          break;
        case "FinancialDashboard":
          if (!allowedFunctions.includes(userFunctions.Dashboard)) {
            canAccess = false;
          }
          break;
        case "OutstandingBalances":
          if (!allowedFunctions.includes(userFunctions.UnpaidBalances)) {
            canAccess = false;
          }
          break;
        case "FinancialPlans":
          if (!allowedFunctions.includes(userFunctions.Plans)) {
            canAccess = false;
          }
          break;
        case "FinancialTransactions":
        case "ProfileFinances":
        case "EventFinancial":
        case "ProfileFinancial":
          if (!allowedFunctions.includes(userFunctions.Transactions)) {
            canAccess = false;
          }
          break;
        case "Reports":
          if (!allowedFunctions.includes(userFunctions.ViewReports)) {
            canAccess = false;
          }
          break;
        case "ReportEdit":
          if (!allowedFunctions.includes(userFunctions.AddReport)) {
            canAccess = false;
          }
          break;
        case "SettingsParticipantForms":
        case "SettingsParticipantFormsEdit":
        case "SettingsParticipantFormsDetails":
          if (!allowedFunctions.includes(userFunctions.ParticipantForms)) {
            canAccess = false;
          }
          break;
        case "SettingsApplications":
        case "SettingsApplicationsDetails":
        case "SettingsApplicationsEdit":
          if (!allowedFunctions.includes(userFunctions.Applications)) {
            canAccess = false;
          }
          break;
        case "SettingsDomains":
          if (!allowedFunctions.includes(userFunctions.Domains)) {
            canAccess = false;
          }
          break;
        case "PortalCustomization":
          if (!allowedFunctions.includes(userFunctions.PortalCustomization)) {
            canAccess = false;
          }
          break;
        case "StripeCreds":
          if (!allowedFunctions.includes(userFunctions.StripeConfiguration)) {
            canAccess = false;
          }
          break;
        case "SettingsUsers":
        case "SettingsUserDetails":
        case "SettingsUserEdit":
          if (!allowedFunctions.includes(userFunctions.Users)) {
            canAccess = false;
          }
          break;
        case "SettingsGroups":
        case "SettingsGroupEdit":
          if (!allowedFunctions.includes(userFunctions.Groups)) {
            canAccess = false;
          }
          break;
        case "SettingsTags":
        case "SettingsTagTypes":
        case "SettingsContentTypes":
        case "SettingsTagContents":
          if (!allowedFunctions.includes(userFunctions.ViewTags)) {
            canAccess = false;
          }
          break;
        case "SettingsTagEdit":
        case "SettingsTagTypeEdit":
        case "SettingsContentTypeEdit":
        case "SettingsTagContentEdit":
          if (!allowedFunctions.includes(userFunctions.EditTags)) {
            canAccess = false;
          }
          break;
        case "Medical":
          if (!allowedFunctions.includes(userFunctions.Medical)) {
            canAccess = false;
          }
          break;
      }

      if (!canAccess) {
        Vue.set(routerData.errorData, "message", "You have no access to this.");
        Vue.set(routerData.errorData, "redirectName", "Dashboard");
        Vue.set(routerData.errorData, "redirectQuery", {});
        const win = window as any;
        win.errorData = routerData.errorData;
        next();
        return;
      }

      next();
    }
  } else {
    next();
  }
});

export default router;
